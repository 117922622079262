<template>
    <div class="item-tournament-bracket">
        <div class="block-place">
            <p>{{item.place}}</p>
        </div>

        <div class="block-user">
            <div class="wrap-img" :class="(item.user.pro) ? 'user-pro-img' : ''">
                <img class="img" v-if="item.user.photo && typeof item.user.photo == 'object'" :src="item.user.photo[40]">
                <img class="img"  v-else-if="item.user.photo" :src="item.user.photo">
                <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
<!--                <div v-if="now_date < date"  class="online"></div>-->
            </div>

            <div class="user-info">
                <div class="block-name" :class="(item.user.pro) ? 'user-pro' : ''">
                    {{item.user.name+" "+((item.user.last_name) ? item.user.last_name : '')}}
                </div>

<!--                <div class="block-info">-->
<!--                    <div class="rating">-->
<!--                        <img  src="/dist/images/frontend/fire.svg" alt />-->
<!--                        <p class="stat">{{item.user.rating_points}}</p>-->
<!--                    </div>-->
<!--                    <div class="location">-->
<!--                        <img :src="item.user.location_img">-->
<!--                        <p>{{$getLocation(item.user.location)}}</p>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>

        <div
            v-for="(item, key) in item.result"
            :key="key"
            class="block-result"
        >
            <p>{{item}}</p>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ItemTournamentBracket',
        data() {
            return {

            }

        },
        watch: {

        },
        props: {
            item: {
                type: Object
            }
        },
        computed:{
            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.getters.getLanguage;
            }
        },
        methods: {

        },
        components: {

        }
    }
</script>

<style lang="scss" scoped>
.item-tournament-bracket {
    display: flex;
    align-items: center;

    .block-place {
        background-color: #131420;
        position: relative;
        z-index: 10;
    }

    .block-result {
        background-color: #171827;
        position: relative;

        &:hover {
            background-color: #282A40;

            &:after{
                content: '';
                position: absolute;
                bottom: 0;
                width: 70px;
                height: 10000px;
                background-color: #282A40;
                opacity: 0.5;
                pointer-events: none;
            }

            &:before {
                content: '';
                position: absolute;
                right: 0;
                width: 10000px;
                height: 44px;
                background-color: #282A40;
                opacity: 0.5;
                pointer-events: none;
            }

            p {
                color: #fff;
            }
        }
    }

    .block-place,
    .block-result {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        min-width: 70px;
        height: 44px;
        border-right: 1px solid #282A40;
        border-bottom: 1px solid #282A40;

        p {
            cursor: default;
            color: #667085;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            margin: 0;
            position: relative;
            z-index: 10;
        }
    }

    .block-user {
        min-width: 220px;
        width: 220px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 12px;
        border-right: 1px solid #282A40;
        border-bottom: 1px solid #282A40;
        background-color: #171827;
        position: relative;
        z-index: 10;

        .wrap-img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            position: relative;
            margin-right: 8px;

            .img {
                width: 22px;
                height: 22px;
                border-radius: 50%;
            }

            .photo {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
            }

            .online {
                border-radius: 50%;
                background-color: #00c569;
                width: 6px;
                height: 6px;
                position: absolute;
                right: 0;
                bottom: 1px;
                border: 1px solid #202020;
            }
        }

        .user-info {
            display: flex;
            align-items: center;
            width: 100%;

            .block-name {
                width: 100%;
                color: #FFF;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
    }


}
</style>