<template>
    <div class="wrapper-socials">
        <v-btn
                v-if="selectArr.length"
                :disabled="open"
                class="btn-link"
                text
                v-ripple="false"
                v-on:click="open = !open"
        >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.99997 5.93933V12.0446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.0556 8.99203H5.94446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9047 0.666626H5.0952C2.37298 0.666626 0.666626 2.59336 0.666626 5.32092V12.679C0.666626 15.4066 2.36504 17.3333 5.0952 17.3333H12.9047C15.6349 17.3333 17.3333 15.4066 17.3333 12.679V5.32092C17.3333 2.59336 15.6349 0.666626 12.9047 0.666626Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.99997 5.93933V12.0446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.0556 8.99203H5.94446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9047 0.666626H5.0952C2.37298 0.666626 0.666626 2.59336 0.666626 5.32092V12.679C0.666626 15.4066 2.36504 17.3333 5.0952 17.3333H12.9047C15.6349 17.3333 17.3333 15.4066 17.3333 12.679V5.32092C17.3333 2.59336 15.6349 0.666626 12.9047 0.666626Z" stroke="#26a9f4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{__('Add social network')}}
        </v-btn>

        <FormSocialItem @add-social="addSocial" :selectArr="selectArr" v-if="open" v-bind:open.sync="open"></FormSocialItem>

        <div v-for="(social, key) in filterSocials" :key="key">
            <SocialItem @remove-social="removeSocial" v-bind:index="key" v-bind:value.sync="social"></SocialItem>
        </div>
    </div>
</template>

<script>
    import SocialItem from "./SocialItem";
    import FormSocialItem from "./FormSocialItem";
    export default {
        name:'SettingsSocial',
        data() {
            return {
                new_social: {},
                open:false
            }
        },
        props: {
            socials: {
                type: Object,
            }
        },
        methods: {
            addSocial(social) {
                this.$emit('add-social', social);
            },
            removeSocial(social) {
                this.$emit("remove-social", social)
            }
        },

        computed: {
          filterSocials: function () {
              let result = {};
              Object.keys(this.socials).forEach((key) => {
                  if (this.socials[key]) {
                      result[key] = this.socials[key]
                  }
              });
              return result
          },

          filterSelect: function () {
             return Object.keys(this.filterSocials)

          },

          selectArr: function () {
              return this.$socials.filter(item => {
                  return (this.$filterIndexArray(this.filterSelect, item.name) < 0) ? true : false;
              });
          }
        },

        components: {
            SocialItem,
            FormSocialItem
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper-socials {
        width: 100%;

        .v-btn.btn-link {
            color: #26A9F4!important;
            padding: 0;

            svg {
                margin-right: 12px;
                transition-duration: .3s;
                border-radius: 5px;

                &:last-child {
                    display: none;
                }
            }

            &:hover {
                svg {
                    background-color: #26a9f4;
                    color: #fff;
                    border-radius: 5px;
                    transition-duration: .3s;
                    position: relative;

                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }

          &.v-btn:not(.v-btn--round).v-size--default {
            padding: 0;
            min-width: auto;
          }

            &.theme--light.v-btn.v-btn--disabled, .theme--light.v-btn.v-btn--disabled .v-btn__loading, .theme--light.v-btn.v-btn--disabled .v-icon {
                color:  rgba(104, 134, 175, 0.6)!important;

                svg {
                    color: rgba(104, 134, 175, 0.6);
                }
            }
        }
    }
</style>