<template>
    <v-skeleton-loader
            ref="skeleton"
            ::boilerplate="boilerplate"
            :type="type"
            :tile="tile"
    ></v-skeleton-loader>


</template>

<script>
    export default {
        data: function() {
            return {
                boilerplate: false,
                tile: false,
                type: 'list-item-avatar-two-line@'+this.count,
                types: [],
            }
        },
        props: {
            count:{
                type: Number,
                default: 1
            }
        }
    }
</script>

<style>
    .skeleton .v-skeleton-loader__list-item-avatar-two-line{
        background-color: #F3F6FE!important;
        padding-right: 0;
    }


</style>
