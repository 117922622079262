<template>
  <div class="history-trans">

      <div style="width: 100%">
          <div class="history-head" v-if="histories">
              <h1>{{__('System Operations')}}</h1>
              <div class="wrap-btn">
                  <div class="wrap-btn-sort">
                      <v-btn text v-ripple="false" class="btn-search" @click="modal_sorting = !modal_sorting" :class="(modal_sorting || filter.length) ? 'btn-search--active': ''">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27778 3.63981C6.27778 5.0963 5.0963 6.2787 3.63889 6.2787C2.18148 6.2787 1 5.0963 1 3.63981C1 2.18241 2.18148 1 3.63889 1C5.0963 1 6.27778 2.18241 6.27778 3.63981Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M9.93269 3.63984H16.2781" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0006 12.8666C11.0006 11.4091 12.182 10.2277 13.6394 10.2277C15.0969 10.2277 16.2783 11.4091 16.2783 12.8666C16.2783 14.324 15.0969 15.5054 13.6394 15.5054C12.182 15.5054 11.0006 14.324 11.0006 12.8666Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.34574 12.8666H1.00037" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </v-btn>
                      <div v-if="filter.length" class="block-count">{{filter.length}}</div>
                  </div>

                  <div class="wrap-btn-search">
                      <v-btn text v-ripple="false" class="btn-search" @click="changeClass()">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </v-btn>
                  </div>

                  <div class="search" ref="block_search">
                      <div class="search__icon">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </div>
                      <div class="search__input">
                          <SearchDefault
                                  v-model="search"
                                  style="width: 100%"
                                  :placeholder="__('Search ...')"
                                  class_search="games_search"
                                  v-on:keyup.enter="(sorting) ? '' : searchHistory()"
                                  @searching="(sorting) ? '' : searchHistory()"
                          ></SearchDefault>
                      </div>
                      <div class="cancel-icon" @click="(sorting) ? search = null : cancelSearch()">
                          <svg v-if="show" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.59548 4.00097L7.87669 0.719756C8.04111 0.555319 8.04111 0.288726 7.87669 0.124304C7.71225 -0.0401327 7.44564 -0.0401327 7.28122 0.124304L3.99999 3.40552L0.71878 0.124304C0.554343 -0.0401327 0.287749 -0.0401327 0.123328 0.124304C-0.0410937 0.288742 -0.0411093 0.555335 0.123328 0.719756L3.40454 4.00097L0.123328 7.28218C-0.0411093 7.44662 -0.0411093 7.71321 0.123328 7.87763C0.287765 8.04207 0.554358 8.04206 0.71878 7.87763L3.99999 4.59642L7.28119 7.87763C7.44563 8.04207 7.71223 8.04206 7.87666 7.87763C8.04108 7.7132 8.04108 7.4466 7.87666 7.28218L4.59548 4.00097Z" fill="#607EA7"/>
                          </svg>
                      </div>
                  </div>
              </div>
          </div>

          <div class="history-list" v-if="histories">
              <TransactionList :form.sync="form" :next_page_url.sync="next_page_url" :payments.sync="payments" :histories.sync="histories"></TransactionList>
          </div>
          <div v-else>
              <div class="skeleton-transaction">
                  <div class="head">
                      <SkeletonButton></SkeletonButton>
                  </div>
                  <div class="subhead">
                  </div>
                  <div class="body">
                      <SkeletonText :count="10"></SkeletonText>
                  </div>
              </div>
          </div>
      </div>

      <div class="history-filter" :class="(sorting) ? 'active': ''" v-if="histories">
        <TransactionSorting v-if="modal_sorting" :modal_sorting.sync="modal_sorting" @updateTransactions="updateTransactions" @updateParams="updateParams" :search.sync="search" :sorting.sync="sorting" :form.sync="form" :filter.sync="filter"></TransactionSorting>
      </div>
  </div>
</template>

<script>
    import TransactionSorting from "./TransactionHistory/TransactionSorting";
    import TransactionList from "./TransactionHistory/TransactionList";
    import SearchDefault from "../modules/SearchDefault.vue";
    import SkeletonButton from "../modules/SkeletonButton";
    import SkeletonText from "../modules/SkeletonText";
    export default {
      name: 'TransactionHistory',

      data() {
        return {
          search: null,
          show: false,
          sorting: false,
          payments: null,
          histories: null,
          next_page_url: null,
          loadingPage: false,
          form: {
              type: null,
              income: null,
              wallet: null,
              date_from: null,
              date_to: null,
          },
          modal_sorting: false,
          filter:[],
          active_search: false,
        }
      },

      watch: {
          search(val) {
              if (val) {
                  this.show = true;
              } else {
                  this.show = false;
              }
          },
      },

      props: {
          progress: {
              type: Number
          },
      },

      methods: {

          changeClass() {
              this.$refs.block_search.classList.toggle('active');
              this.active_search = !this.active_search;
          },

          searchHistory() {
              let response = {
                  search: this.search,
                  type: this.form.type,
                  income: this.form.income,
                  wallet: this.form.wallet,
                  date_from: this.form.date_from,
                  date_to: this.form.date_to,
              };
              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction", response)
                  .then((response) => {
                      return response.json();
                  })
                  .then((response) => {
                      this.updateTransactions(response.data);
                  })
                  .catch((err) => {
                      let response = this.httpHandler(err);
                      if (response.error) {
                          this.formValidate = response.error.message;
                      }
                  });
          },

          clearInput() {
              this.search = null;
          },

          cancelSearch() {
              let response = {
                  search: null,
                  type: this.form.type,
                  income: this.form.income,
                  wallet: this.form.wallet,
                  date_from: this.form.date_from,
                  date_to: this.form.date_to,
              };
              this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction", response)
                  .then((response) => {
                      return response.json();
                  })
                  .then((response) => {
                      this.search = null;
                      this.updateTransactions(response.data);
                      this.active_search = false;
                      this.$refs.block_search.classList.toggle('active');
                  })
                  .catch((err) => {
                      let response = this.httpHandler(err);
                      if (response.error) {
                          this.formValidate = response.error.message;
                      }
                  });
          },

        updateTransactions(response) {
          if(response) {
            this.histories = response.histories;
            this.next_page_url = response.histories.next_page_url;
          }
        },

          updateParams(response) {
            this.form = response;
          },

          scrollTransactions: function () {
              const progress =
                  document.documentElement.scrollTop /
                  (document.documentElement.scrollHeight -
                      document.documentElement.clientHeight);
              if (!this.loadingPage && this.next_page_url) {
                  let bottomOfWindow = 0.7 <= progress;
                  if (bottomOfWindow) {
                      this.loadingPage = true;
                      let response = this.form;
                      response.search = this.search;
                      this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get'](this.next_page_url, response)
                      .then((response) => {
                          return response.json();
                      })
                      .then((response) => {
                          response = response.data;
                          let histories = this.histories.data;
                          this.histories.data = histories.concat(response.histories.data);
                          this.next_page_url = response.histories.next_page_url;
                          this.loadingPage = false;
                      })
                      .catch((err) => {
                          this.httpHandler(err);
                      });
                  }
              }
          },
      },

      created() {
          let num = 0;
          let timeout = setInterval(() => {
              num += 1;
              this.$emit('update:progress', num);
              if(num > 50) {
                  clearInterval(timeout)
              }
          },15);
        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction")
          .then((response) => {
            return response.json();
          })
          .then((response) => {
              if(this.$checkAuth(response)) {
                  //   response = {
                  //   "data": {
                  //     "payments": [
                  //       {
                  //         "name": "Bitcoin",
                  //         "img": "/dist/images/frontend/btc.svg",
                  //         "name_class": "btc",
                  //         "currency": "btc",
                  //         "id": 1,
                  //       },
                  //       {
                  //         "name": "Ethereum",
                  //         "img": "/dist/images/frontend/eth.svg",
                  //         "name_class": "eth",
                  //         "currency": "eth",
                  //         "id": 2,
                  //       },
                  //       {
                  //         "name": "Tether ERC20",
                  //         "img": "/dist/images/frontend/usdt.svg",
                  //         "name_class": "usdt",
                  //         "currency": "usdt",
                  //         "id": 3,
                  //       }
                  //     ],
                  //     "histories": {
                  //       "current_page": 1,
                  //           "data": [
                  //         {
                  //           "id": 330,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "20.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "lvl": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 8,
                  //           "transfer_user_id": 1,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2020-10-02 06:47:43",
                  //           "game": null,
                  //           "enemy": {
                  //               "id": 1,
                  //               "public_id": "volkonsky",
                  //               "name": "Vladyslav",
                  //               "last_name": "Volkonsky",
                  //               "photo": null,
                  //               "bg_color": "#f84138",
                  //               "location": "UA",
                  //               "location_img": "/dist/images/flags/ua.png",
                  //               "online": "2021/02/19 06:07:27",
                  //               "rating_points": 77751,
                  //               "vip": 0
                  //           }
                  //         },
                  //         {
                  //           "id": 330,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "20.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "lvl": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 4,
                  //           "transfer_user_id": 1,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2020-10-02 06:47:43",
                  //           "game": null,
                  //           "enemy": {
                  //             "id": 1,
                  //             "public_id": "volkonsky",
                  //             "name": "Vladyslav",
                  //             "last_name": "Volkonsky",
                  //             "photo": null,
                  //             "bg_color": "#f84138",
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //             "online": "2021/02/19 06:07:27",
                  //             "rating_points": 77751,
                  //             "vip": 0
                  //           }
                  //         },
                  //         {
                  //           "id": 331,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "1250.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 1,
                  //           "transfer_user_id": null,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": 2,
                  //           "tx": "0x732ba48eb3e0b18bf6ddf8152288560bc40a932da44e7a27ffb19a2388001127",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2022-10-01 06:47:43",
                  //           "game": null,
                  //           "enemy": null
                  //         },
                  //         {
                  //           "id": 334,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 2,
                  //           "amount": "9.80000000",
                  //           "amount_unit": "19.80000000",
                  //           "rate_amount": "10.00000000",
                  //           "income": 1,
                  //           "commission": "0.20000000",
                  //           "opp_type": 3,
                  //           "transfer_user_id": null,
                  //           "game_played_id": 2,
                  //           "game_id": 1,
                  //           "payment_id": null,
                  //           "tx": null,
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2020-10-03 08:53:57",
                  //           "game": {
                  //             "id": 1,
                  //             "logo": "/assets/games/l-1599220044KwESVrH81n.png",
                  //             "name": "2048 Online - Real time edition"
                  //           },
                  //           "enemy": null
                  //         },
                  //         {
                  //           "id": 334,
                  //           "user_id": 2,
                  //           "frozen": 1,
                  //           "wallet": 2,
                  //           "amount": "9.80000000",
                  //           "amount_unit": "19.80000000",
                  //           "rate_amount": "10.00000000",
                  //           "income": 1,
                  //           "commission": "0.20000000",
                  //           "opp_type": 3,
                  //           "transfer_user_id": null,
                  //           "game_played_id": 2,
                  //           "game_id": 1,
                  //           "payment_id": null,
                  //           "tx": null,
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2020-10-03 08:53:57",
                  //           "game": {
                  //             "id": 1,
                  //             "logo": "/assets/games/l-1599220044KwESVrH81n.png",
                  //             "name": "2048 Online - Real time edition"
                  //           },
                  //           "enemy": null
                  //         },
                  //         {
                  //           "id": 331,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "1250.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 5,
                  //           "transfer_user_id": null,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "quest_id": null,
                  //           "achiev_id": 1,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2022-10-01 06:47:43",
                  //           "game": null,
                  //           "enemy": null,
                  //           "achievement": {
                  //             "id": 1,
                  //             "name": "Welcome",
                  //             "icon": "/dist/assets/achivements/eivon.svg",
                  //             "description": "Register complete"
                  //           }
                  //         },
                  //         {
                  //           "id": 331,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "1250.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 6,
                  //           "transfer_user_id": null,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": null,
                  //           "tx": 15,
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2022-10-01 06:47:43",
                  //             "game": {
                  //                 "id": 1,
                  //                 "logo": "/assets/games/l-1599220044KwESVrH81n.png",
                  //                 "name": "2048 Online - Real time edition"
                  //             },
                  //           "enemy": null
                  //         },
                  //         {
                  //           "id": 331,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "120.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 2,
                  //           "commission": "0.00000000",
                  //           "opp_type": 2,
                  //           "transfer_user_id": null,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2022-10-01 06:47:43",
                  //           "game": null,
                  //           "enemy": null
                  //         },
                  //         {
                  //           "id": 331,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "1250.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 7,
                  //           "transfer_user_id": null,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "quest_id": null,
                  //           "achiev_id": 1,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2022-10-01 06:47:43",
                  //           "game": null,
                  //           "enemy": null,
                  //           "quest": {
                  //             "id": 1,
                  //             "name": "10 часов в игре",
                  //             "icon": "/dist/assets/achivements/eivon.svg",
                  //             "description": "Для прохождения данного квеста необходмо сыграть в игры в совокупности 10 часов, на любой с балансов! Для прохождения данного квеста необходмо сыграть в игры в совокупности 10 часов, на любой с балансов!"
                  //           },
                  //         },
                  //         {
                  //           "id": 331,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 2,
                  //           "amount": "1250.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 2,
                  //           "commission": "0.00000000",
                  //           "opp_type": 7,
                  //           "transfer_user_id": null,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "quest_id": null,
                  //           "achiev_id": 1,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2022-10-01 06:47:43",
                  //           "game": null,
                  //           "enemy": null,
                  //           "quest": {
                  //             "id": 1,
                  //             "name": "10 часов в игре",
                  //             "icon": "/dist/assets/achivements/eivon.svg",
                  //             "description": "Для прохождения данного квеста необходмо сыграть в игры в совокупности 10 часов, на любой с балансов! Для прохождения данного квеста необходмо сыграть в игры в совокупности 10 часов, на любой с балансов!"
                  //           },
                  //         },
                  //         {
                  //           "id": 330,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "20.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "lvl": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 9,
                  //           "transfer_user_id": 1,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2020-10-02 06:47:43",
                  //           "game": null,
                  //           "enemy": null,
                  //         },
                  //         {
                  //           "id": 330,
                  //           "user_id": 2,
                  //           "frozen": 0,
                  //           "wallet": 1,
                  //           "amount": "20.00000000",
                  //           "amount_unit": null,
                  //           "rate_amount": "0.00000000",
                  //           "income": 1,
                  //           "lvl": 1,
                  //           "commission": "0.00000000",
                  //           "opp_type": 10,
                  //           "transfer_user_id": 1,
                  //           "game_played_id": null,
                  //           "game_id": null,
                  //           "payment_id": null,
                  //           "tx": "Bonus",
                  //           "status": 1,
                  //           "confirm": 0,
                  //           "created_at": "2020-10-02 06:47:43",
                  //           "game": null,
                  //           "enemy": null,
                  //         },
                  //       ],
                  //           "first_page_url": "https://mindplay.loc/ajax/transaction?page=1",
                  //           "from": 1,
                  //           "last_page": 44,
                  //           "last_page_url": "https://mindplay.loc/ajax/transaction?page=44",
                  //           "next_page_url": "https://mindplay.loc/ajax/transaction?page=2",
                  //           "path": "https://mindplay.loc/ajax/transaction",
                  //           "per_page": 3,
                  //           "prev_page_url": null,
                  //           "to": 3,
                  //           "total": 130
                  //     }
                  //   },
                  //   "status": 200
                  // }
                  this.payments = response.data.payments;
                  this.histories = response.data.histories;
                  this.next_page_url = response.data.histories.next_page_url;
                  this.loadingPage = false;
                  clearInterval(timeout);
                  this.$emit('update:progress', 100);
                  setTimeout(() => {
                      this.$emit('update:progress', 0);
                  }, 500);
              }
          })
          .catch((err) => {
            this.httpHandler(err);
          });

          this.$addListener(window,'scroll', () => {
              this.scrollTransactions();
          });

      },

      components: {
        TransactionSorting,
        TransactionList,
        // LayoutNavAuth,
          SearchDefault,
          SkeletonButton,
          SkeletonText
      },
    }
</script>

<style lang="scss">

    .history-trans {
        /*padding: 20px 20px 0;*/
        position: relative;
        overflow: hidden;
        border-radius: 4px;
    }

    .dark {
        .history-head {
            background: #171827;

            .wrap-btn {
                .v-btn.btn-search,
                .v-btn.btn-sorting {
                    background: #171827;
                    border: 1px solid #171827;
                    color: #96AFD1;

                    &:before {
                        /*background-color: #181818!important;*/
                        /*color: #26a9f4!important;*/
                    }

                    &:hover {
                        background-color: #0B0C1A!important;
                        /*color: #26a9f4!important;*/
                    }
                }

                .search {
                    border-left: 1px solid rgba(255, 255, 255, 0.1);
                    background: #171827;
                    &__icon {
                        svg {
                            color: #96AFD1;
                        }
                    }

                    .cancel-icon {
                        svg {
                            color: #96AFD1;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .dark {
            .history-head {
                .wrap-btn {
                    .search {
                        border-bottom: 1px solid #2B2B2B;
                    }
                }
            }
        }
    }

    .history-head {
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        padding: 0 20px;
        height: 64px;
        align-items: center;
        display: flex;

        h1 {
            white-space: nowrap;
            font-size: 18px;
            line-height: 24px
        }

        .wrap-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            .search {
                display: flex;
                align-items: center;
                padding-left: 20px;
                margin-left: 20px;
                border-left: 1px solid #E7F5FF;

                &__icon {
                    /*margin-bottom: 3px;*/
                    svg  {
                        margin-right: 0;
                    }
                }

                .cancel-icon {
                    cursor: pointer;
                    width: 10px;

                    svg {
                        transition: .3s;
                        margin-right: 0;
                    }

                    &:hover {
                        svg {
                            transform: scale(1.15);
                            transition: .3s;
                        }
                    }
                }
            }

            .wrap-btn-search {
                 display: none;
            }
        }



        .wrap-btn-sort {
            position: relative;
            .block-count {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background: #26A9F4;
                border: 2px solid #FFFFFF;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                font-size: 12px;
                line-height: 16px;
                position: absolute;
                top: -7px;
                right: -7px;
            }
        }

        .v-btn.btn-search,
        .v-btn.btn-sorting {
            background: #fff;
            border-radius: 8px;
            transition: .3s;

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 40px;
                padding: 0;
            }

            &:hover {
                background-color: #E7F5FF;
                transition: .3s;
            }

            &.v-btn:before {
                background-color: #E7F5FF;
                transition: .3s;
            }

        }

        .v-btn.btn-search.active {
            background-color: #E7F5FF;
        }

        .v-btn.btn-search--active {
            background-color: #E7F5FF;
        }
    }



    .history-list {
        width: 100%;
        /*border-radius: 8px 8px 0 0;*/
        overflow: hidden;
    }

    .history-filter {
        position: absolute;
        top: 64px;
        right: 0;
        width: 320px;
        background: #fff;
        /*border-radius: 8px;*/
        padding: 0;
        /*height: 100%*/
        /*margin-left: 20px;*/
        transform: translateX(100%);
        z-index: -1;
        transition: .3s;
    }

    .history-filter.active {
        transform: translateY(0);
        z-index: +1;
        transition: .3s;
    }

    @media screen and (max-width: 950px){
        .history-head {
            padding: 0 15px;
        }
    }

    @media screen and (max-width: 600px){
        .history-head {
            position: relative;
            h1 {
                font-size: 16px;
                line-height: 20px;
            }

            .wrap-btn {
                .search {
                    position: absolute;
                    left: 0;
                    bottom: -41px;
                    width: 100%;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    padding: 0 20px 4px 10px;
                    height: 40px;
                    transform: translateY(-40px);
                    transition: .3s;
                    opacity: 0;
                    margin: 0;
                    border: none;
                    border-bottom: 1px solid #ECF4FF;
                    z-index: -1;

                    &__input {
                        width: 100%;
                    }
                }

                .search.active {
                    transform: translateY(0);
                    height: 40px;
                    transition: .3s;
                    opacity: 1;
                    z-index: 10;
                }

                .wrap-btn-search {
                    margin-left: 10px;
                    display: block;
                }
            }
        }
    }



</style>
