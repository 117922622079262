<template>
  <div class="wrapper-balance">
    <LayoutNavAuth>
        <h1 v-if="currentTab== 'deposit'">{{__('Balance replenishment')}}</h1>
        <h1 v-else-if="currentTab== 'withdraw'">{{__('Withdraw funds')}}</h1>
        <h1 v-else-if="currentTab== 'transfer'">{{__('Money transaction')}}</h1>

        <div slot="right"  class="wrap-tabs pa-0">
            <tabs
                :tabs="side_tabs"
                :currentTab="currentTab"
                :wrapper-class="'buttons-tabs'"
                :tab-class="'buttons-tabs__item'"
                :tab-active-class="'buttons-tabs__item_active'"
                :line-class="'buttons-tabs__active-line'"
                @onClick="sideChange"
            />
        </div>

        <div class="wrap-select">
            <DropdownDefault
                    class_modal="modal_game"
                    color="select_game"
                    v-bind:dropdown="side_tabs"
                    v-bind:value.sync="side_tabs.value"
                    @updateValue="sideChange"
            >
            </DropdownDefault>
        </div>
        <div slot="line" class="loading"  :style="{width: progress + '%'}"></div>
    </LayoutNavAuth>
    <div class="dashboard-content" v-if="payments">
<!--        <div class="wrap-head"  :class="currentTab== 'operations' ? 'wrap-head&#45;&#45;operation' : ''" :style="((currentTab== 'operations') ? {width: '100%'} : {width: 'calc(100% - 395px)'})">-->
<!--            <h1 v-if="currentTab== 'deposit'">{{__('Balance replenishment')}}</h1>-->
<!--            <h1 v-else-if="currentTab== 'withdraw'">{{__('Withdraw funds')}}</h1>-->
<!--            <h1 v-else-if="currentTab== 'transfer'">{{__('Money transaction')}}</h1>-->
<!--        </div>-->

        <div class="wrapper-balance__body">
            <div class="wrap-history pa-0">
<!--                <ModalDefault v-if="successWithdraws && currentTab == 'withdraw'" :modal_cancel.sync="successWithdraws" :width="610">-->
<!--                    <div class="wrap-modal">-->
<!--                        <div class="wrap-modal__head">-->
<!--                            {{__('Success Withdraws')}}-->
<!--                        </div>-->
<!--                        <div class="wrap-modal__body">-->
<!--                            <Payment v-bind:payments="withdrawPayments" v-bind:payment_id="successWithdraws.payment_id">-->
<!--                                <template v-slot:default="payment">-->
<!--                                    <h5>{{__('Withdrawal request has been successfully created')}}</h5>-->
<!--                                    <p class="block-row"><span>{{__('Date of creation:')}}</span> {{successWithdraws.created_at | formatFullDate}}</p>-->
<!--                                    <p class="block-row"><span>{{__('Amount:')}}</span> {{primNum(successWithdraws.amount, payment.sprintf, 2)}} {{payment.currency.toUpperCase()}}</p>-->
<!--                                    <p class="block-row"><span>{{__('Address:')}}</span> {{successWithdraws.tx}}</p>-->
<!--                                    <div class="wrap-btn d-flex justify-content-center" style="margin: 20px auto 0">-->
<!--                                        <BaseButton width="140" v-on:click="successWithdraws = null">-->
<!--                                            <span>{{__('Ok')}}</span>-->
<!--                                        </BaseButton>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                            </Payment>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </ModalDefault>-->
                <div v-if="payments" class="d-flex">
                    <div style="width: 100%">
                        <Transfers v-if="currentTab== 'transfer'" :url.sync="url" v-bind:payments="replenishPayments" v-bind:user_balance="user_balance"  v-bind:modal_confirmation_2fa.sync="modal_confirmation_2fa" @updateTransfer="updateTransfer"></Transfers>
<!--                        <Withdraws v-if="currentTab== 'withdraw' && user_balance" :url.sync="url" v-bind:payment="active_payment" v-bind:payments="payments" v-bind:user_balance="user_balance" v-bind:modal_confirmation_2fa.sync="modal_confirmation_2fa" @updateWithdraws="updateWithdraws"></Withdraws>-->
                        <Withdraws v-if="currentTab== 'withdraw' && silver && user_balance" v-bind:silver="silver" :url.sync="url" :wallets="wallets" v-bind:payments="withdrawPayments" v-bind:user_balance="user_balance" v-bind:modal_confirmation_2fa.sync="modal_confirmation_2fa" @updateWithdraws="updateWithdraws"></Withdraws>
                        <Merchant v-else-if="currentTab== 'deposit' && deposit" v-bind:transaction.sync="deposit" v-bind:payments="replenishPayments" @cancelTransactions="cancelTransactions"></Merchant>
                        <Deposits v-else-if="currentTab== 'deposit' && silver" v-bind:silver="silver" v-bind:payments="replenishPayments" :usdtPayments="replenishUsdtPayments" v-bind:transactions.sync="show_transactions" @pushDeposit="pushDeposit"></Deposits>
                        <TransactionHistory v-else-if="currentTab == 'operations'" v-bind:progress.sync="progress"></TransactionHistory>
                    </div>
                    <div class="sidebar-balance" v-if="currentTab != 'operations'">
                        <h5 class="sidebar-balance__title">{{__('Important information')}}</h5>
                        <div class="sidebar-balance__body" v-if="currentTab == 'deposit'">
                            <p class="sidebar-balance__text">{{__('After clicking on the Create deposit button, an application will be created, which you need')}} <span>{{__('pay within 1 hour.')}}</span></p>
                            <p class="sidebar-balance__text">{{__('After payment, coins are credited automatically. If your payment was successful but the coins were not displayed on the balance')}} <router-link :to="{name: 'chat', params: {id: 'support', lang: $store.getters.getLang}}">{{__('write to us.')}}</router-link></p>
                            <p class="sidebar-balance__link" v-if="currentTab== 'deposit'">{{__('Please check that you are visiting')}}</p>
                            <div class="block-link" v-if="currentTab== 'deposit'">
                                <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.57211 5.60687V7.55592H1.44545C0.648217 7.55592 0 8.21959 0 9.03583V17.1868C0 18.003 0.648217 18.6667 1.44545 18.6667H12.5546C13.3518 18.6667 14 18.003 14 17.1868V9.03201C14 8.21577 13.3518 7.5521 12.5546 7.5521H12.5173V5.60305C12.5173 2.51355 10.0623 0 7.0447 0C4.02714 0.00381419 1.57211 2.51737 1.57211 5.60687ZM7.85684 13.4717C7.74508 13.5556 7.71527 13.6357 7.71527 13.7692C7.719 14.3681 7.719 14.9631 7.719 15.5619C7.73018 15.8098 7.60724 16.0539 7.39117 16.1646C6.88079 16.4315 6.37041 16.0578 6.37041 15.5619V15.5581C6.37041 14.9593 6.37041 14.3604 6.37414 13.7616C6.37414 13.6396 6.34806 13.5595 6.24375 13.4794C5.71474 13.0789 5.53965 12.3961 5.80415 11.7973C6.0612 11.2137 6.69079 10.8705 7.28313 10.9925C7.94252 11.1298 8.40075 11.6714 8.4082 12.3465C8.4082 12.8157 8.22565 13.1971 7.85684 13.4717ZM7.0447 2.45634C5.34965 2.45634 3.96754 3.86759 3.96754 5.60687V7.55592H10.1219V5.60687C10.1219 3.87141 8.74348 2.45634 7.0447 2.45634Z" fill="#01AC5A"/>
                                </svg>
                                <p><span>https:</span>//www.mindplays.com</p>
                            </div>

                            <svg class="icon-svg" width="113" height="100" viewBox="0 0 122 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M79.5 0.0078125C35.6637 0.0078125 0 35.6701 0 79.5034C0 123.338 35.6637 159 79.5 159C123.336 159 159 123.338 159 79.5034C159 35.6701 123.336 0.0078125 79.5 0.0078125ZM79.5 144.546C43.6339 144.546 14.4545 115.368 14.4545 79.5039C14.4545 43.6398 43.6339 14.4624 79.5 14.4624C115.366 14.4624 144.545 43.6398 144.545 79.5034C144.545 115.369 115.366 144.546 79.5 144.546Z" fill="#26A9F4" fill-opacity="0.2"/>
                                <path d="M79.5001 62.6404C75.5087 62.6404 72.2728 65.8763 72.2728 69.8677V118.041C72.2728 122.033 75.5087 125.269 79.5001 125.269C83.4915 125.269 86.7274 122.033 86.7274 118.041V69.8677C86.7274 65.8768 83.4915 62.6404 79.5001 62.6404Z" fill="#26A9F4" fill-opacity="0.2"/>
                                <path d="M79.5001 33.733C77.5969 33.733 75.7366 34.5039 74.388 35.8482C73.0437 37.1925 72.2728 39.0571 72.2728 40.9603C72.2728 42.8635 73.0437 44.7233 74.388 46.0724C75.7323 47.4167 77.5969 48.1876 79.5001 48.1876C81.4033 48.1876 83.2631 47.4167 84.6117 46.0724C85.956 44.7233 86.7274 42.8635 86.7274 40.9603C86.7274 39.0571 85.956 37.1925 84.6117 35.8482C83.2631 34.5035 81.4033 33.733 79.5001 33.733Z" fill="#26A9F4" fill-opacity="0.2"/>
                            </svg>
                        </div>

                        <div class="sidebar-balance__body" v-if="currentTab == 'withdraw'">
                            <p class="sidebar-balance__text">{{__('As a rule, the withdrawal of funds is carried out within 24 hours. For example, the speed at which funds are sent depends on the payment system you choose.')}}</p>
                            <p class="sidebar-balance__text">{{__('If we have noticed suspicious activity on your account. In order to avoid attempts to hack your account, we may ask you to go through a simple procedure')}} <span>{{__('verification')}}.</span></p>

                            <svg class="icon-svg" width="113" height="100" viewBox="0 0 122 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M79.5 0.0078125C35.6637 0.0078125 0 35.6701 0 79.5034C0 123.338 35.6637 159 79.5 159C123.336 159 159 123.338 159 79.5034C159 35.6701 123.336 0.0078125 79.5 0.0078125ZM79.5 144.546C43.6339 144.546 14.4545 115.368 14.4545 79.5039C14.4545 43.6398 43.6339 14.4624 79.5 14.4624C115.366 14.4624 144.545 43.6398 144.545 79.5034C144.545 115.369 115.366 144.546 79.5 144.546Z" fill="#26A9F4" fill-opacity="0.2"/>
                                <path d="M79.5001 62.6404C75.5087 62.6404 72.2728 65.8763 72.2728 69.8677V118.041C72.2728 122.033 75.5087 125.269 79.5001 125.269C83.4915 125.269 86.7274 122.033 86.7274 118.041V69.8677C86.7274 65.8768 83.4915 62.6404 79.5001 62.6404Z" fill="#26A9F4" fill-opacity="0.2"/>
                                <path d="M79.5001 33.733C77.5969 33.733 75.7366 34.5039 74.388 35.8482C73.0437 37.1925 72.2728 39.0571 72.2728 40.9603C72.2728 42.8635 73.0437 44.7233 74.388 46.0724C75.7323 47.4167 77.5969 48.1876 79.5001 48.1876C81.4033 48.1876 83.2631 47.4167 84.6117 46.0724C85.956 44.7233 86.7274 42.8635 86.7274 40.9603C86.7274 39.0571 85.956 37.1925 84.6117 35.8482C83.2631 34.5035 81.4033 33.733 79.5001 33.733Z" fill="#26A9F4" fill-opacity="0.2"/>
                            </svg>
                        </div>

                        <div class="sidebar-balance__body" v-if="currentTab == 'transfer'">
                            <p class="sidebar-balance__text">{{__('Transfer between user accounts is performed instantly! Please be extremely careful before sending your funds.')}}</p>
                            <p class="sidebar-balance__text">{{__('To use this functionality in the security settings of your account, you must')}} <router-link :to="{name: 'settings', params: {'current': 'privacy', lang: $store.getters.getLang}}">{{__('activate 2FA')}}</router-link></p>
                            <p class="sidebar-balance__text">{{__('If we have noticed suspicious activity on your account. In order to avoid attempts to hack your account, we may ask you to go through a simple procedure')}} <span>{{__('verification')}}.</span></p>

                            <svg class="icon-svg" width="113" height="100" viewBox="0 0 122 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M79.5 0.0078125C35.6637 0.0078125 0 35.6701 0 79.5034C0 123.338 35.6637 159 79.5 159C123.336 159 159 123.338 159 79.5034C159 35.6701 123.336 0.0078125 79.5 0.0078125ZM79.5 144.546C43.6339 144.546 14.4545 115.368 14.4545 79.5039C14.4545 43.6398 43.6339 14.4624 79.5 14.4624C115.366 14.4624 144.545 43.6398 144.545 79.5034C144.545 115.369 115.366 144.546 79.5 144.546Z" fill="#26A9F4" fill-opacity="0.2"/>
                                <path d="M79.5001 62.6404C75.5087 62.6404 72.2728 65.8763 72.2728 69.8677V118.041C72.2728 122.033 75.5087 125.269 79.5001 125.269C83.4915 125.269 86.7274 122.033 86.7274 118.041V69.8677C86.7274 65.8768 83.4915 62.6404 79.5001 62.6404Z" fill="#26A9F4" fill-opacity="0.2"/>
                                <path d="M79.5001 33.733C77.5969 33.733 75.7366 34.5039 74.388 35.8482C73.0437 37.1925 72.2728 39.0571 72.2728 40.9603C72.2728 42.8635 73.0437 44.7233 74.388 46.0724C75.7323 47.4167 77.5969 48.1876 79.5001 48.1876C81.4033 48.1876 83.2631 47.4167 84.6117 46.0724C85.956 44.7233 86.7274 42.8635 86.7274 40.9603C86.7274 39.0571 85.956 37.1925 84.6117 35.8482C83.2631 34.5035 81.4033 33.733 79.5001 33.733Z" fill="#26A9F4" fill-opacity="0.2"/>
                            </svg>

                        </div>
                    </div>
                </div>
                <div v-else>{{__('loading')}}</div>

                <div class="wrap-table-trans" v-if="currentTab != 'operations'">
                    <div class="wrap-history__head">
                        <span v-if="currentTab== 'withdraw'">{{__('Withdraw history')}}</span>
                        <span v-else-if="currentTab== 'transfer'">{{__('Transfer history')}}</span>
                        <span v-else>{{__('Deposit history')}}</span>


                        <div class="wrap-btn">
                            <div class="wrap-btn-sort">
                                <v-btn text v-ripple="false" class="btn-search" @click="sorting_modal = !sorting_modal" :class="(sorting_modal || filter.length) ? 'btn-search--active': ''">
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27778 3.63981C6.27778 5.0963 5.0963 6.2787 3.63889 6.2787C2.18148 6.2787 1 5.0963 1 3.63981C1 2.18241 2.18148 1 3.63889 1C5.0963 1 6.27778 2.18241 6.27778 3.63981Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M9.93269 3.63984H16.2781" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0006 12.8666C11.0006 11.4091 12.182 10.2277 13.6394 10.2277C15.0969 10.2277 16.2783 11.4091 16.2783 12.8666C16.2783 14.324 15.0969 15.5054 13.6394 15.5054C12.182 15.5054 11.0006 14.324 11.0006 12.8666Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M7.34574 12.8666H1.00037" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </v-btn>
                                <div v-if="filter.length" class="block-count">{{filter.length}}</div>
                            </div>

                            <div class="wrap-btn-search">
                                <v-btn text v-ripple="false" class="btn-search" @click="changeClass()">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </v-btn>
                            </div>


                            <div class="search" ref="block_search">
                                <div class="search__icon">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="search__input">
                                    <SearchDefault
                                            style="width: 100%"
                                            :placeholder="__('Search ...')"
                                            class_search="games_search"
                                            v-model="search"
                                            v-on:keyup.enter="getTransactions(currentTab)"
                                            @searching="getTransactions(currentTab)"
                                    ></SearchDefault>
                                </div>
                                <div class="cancel-icon" @click="cancelSearch(currentTab)">
                                    <svg v-if="show" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.59548 4.00097L7.87669 0.719756C8.04111 0.555319 8.04111 0.288726 7.87669 0.124304C7.71225 -0.0401327 7.44564 -0.0401327 7.28122 0.124304L3.99999 3.40552L0.71878 0.124304C0.554343 -0.0401327 0.287749 -0.0401327 0.123328 0.124304C-0.0410937 0.288742 -0.0411093 0.555335 0.123328 0.719756L3.40454 4.00097L0.123328 7.28218C-0.0411093 7.44662 -0.0411093 7.71321 0.123328 7.87763C0.287765 8.04207 0.554358 8.04206 0.71878 7.87763L3.99999 4.59642L7.28119 7.87763C7.44563 8.04207 7.71223 8.04206 7.87666 7.87763C8.04108 7.7132 8.04108 7.4466 7.87666 7.28218L4.59548 4.00097Z" fill="currentColor"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="transactions" style="margin-bottom: 15px">
                        <div class="transaction-head">
                            <span v-if="currentTab == 'transfer'">{{__('Operation ID')}}</span>
                            <span v-else>{{__('Payment System')}}</span>
                            <span>{{__('Amount')}}</span>
                            <span v-if="currentTab == 'transfer'">{{__('Opperation')}}</span>
                            <span v-if="currentTab != 'transfer'">{{__('Amount Coin')}}</span>
                            <span>{{__('Status')}}</span>
                            <span>{{__('Date and time UTC')}}</span>
                        </div>
                        <div class="transaction-body" :style="((currentTab == 'deposit' || currentTab == 'transfer') ? {minHeight: 'calc(100vh - 644px)'} : {minHeight: 'calc(100vh - 733px)'})"  v-if="transactions.data.length" ref="transaction_body">
                            <TransactionItem v-for="(transaction, key) in transactions.data" v-bind:transaction="transaction" :key="key" v-bind:currentTab="currentTab" v-bind:payment="(transaction.payment_id) ? getPaymentWithdraw(transaction.payment_id) : null" @cancelTransactions="cancelTransactions"></TransactionItem>
                        </div>

                        <div v-else class="block-empty" :style="((currentTab== 'deposit' || currentTab == 'transfer') ? {height: 'calc(100vh - 644px)'} : {height: 'calc(100vh - 733px)'})">
                            <img width="30" src="/dist/images/frontend/icon-deposit-nav.svg" alt />
                            <p>{{__('You dont have history')}}:(</p>
                        </div>
                    </div>

                    <div v-else :style="((currentTab== 'deposit' || currentTab == 'transfer') ? {height: 'calc(100vh - 595px)'} : {height: 'calc(100vh - 684px)'})" style="border-radius: 0 0 4px 4px; margin-bottom: 15px">
                        <div class="skeleton-transaction">
                            <div class="subhead">
                            </div>
                            <div class="body">
                                <SkeletonText :count="6"></SkeletonText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal2FAConfirmation v-if="modal_confirmation_2fa" :url.sync="url" v-bind:response.sync="modal_confirmation_2fa" @updateWithdraws="updateWithdraws" @updateTransfer="updateTransfer"></Modal2FAConfirmation>
        <ModalDefault v-if="modal_cancel" v-bind:modal_cancel.sync="modal_cancel" :width="610">
            <div class="wrap-modal">
                <div class="wrap-modal__head">
                    {{__('Сancel payment')}}
                </div>
                <div class="wrap-modal__body">
                    <h5>{{__('Are you sure you want to cancel the transaction?')}}</h5>
                    <Payment v-bind:payments="payments" v-bind:payment_id="modal_cancel.payment_id">
                        <template v-slot:default="payment">
                            <p class="block-row"><span>{{__('Operation ID')}}:</span> #{{modal_cancel.id}}</p>
                            <p class="block-row"><span>{{__('Date of creation:')}}</span> {{modal_cancel.created_at | formatFullDate}}</p>
                            <p class="block-row"><span>{{__('Amount:')}}</span> {{primNum(((modal_cancel.amount > 0) ? modal_cancel.amount : modal_cancel.amount_unit), payment.sprintf)}} {{payment.currency.toUpperCase()}}</p>
                            <p class="block-row">
                                <span>{{__('Address:')}}</span>
                                <a :href="$paymentLink[payment.currency]+modal_cancel.tx" target="_blank">{{modal_cancel.tx}}</a>
                            </p>
                        </template>
                    </Payment>
                    <div class="wrap-btn">
                        <v-btn
                                text
                                class="btn-cancel mr-5"
                                v-on:click="modal_cancel = false"
                                v-ripple="{ class:'secondary--text'}"
                        >
                            <span>{{__('No')}}</span>
                        </v-btn>

                        <BaseButton
                            width="100"
                            v-on:click="CancelTransactions(modal_cancel, currentTab)"
                            :loading="submitDisabled"
                        >
                            <span>{{__('Yes')}}</span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </ModalDefault>
      <BalancesSorting v-if="sorting_modal" v-bind:filter.sync="filter"  @updateTransactions="updateTransactions" :search.sync="search" :sorting_modal.sync="sorting_modal" v-bind:payments="payments" v-bind:sorting_balance.sync="sorting"></BalancesSorting>
    </div>
      <div v-else class="dashboard-content">
          <div class="skeleton-balance">
              <div class="skeleton-balance__head">
                  <SkeletonButton></SkeletonButton>
              </div>
              <div class="skeleton-balance__body">
                  <div class="left">
                      <div class="wrap-selects">
                          <div class="wrap-selects__left">
                              <SkeletonText></SkeletonText>
                              <SkeletonButton></SkeletonButton>
                          </div>
                          <div class="wrap-selects__right">
                              <SkeletonText></SkeletonText>
                              <SkeletonButton></SkeletonButton>
                          </div>
                      </div>
                      <div class="wrap-selects">
                          <div class="wrap-selects__left">
                              <SkeletonText :count="2"></SkeletonText>
                          </div>
                          <div class="wrap-selects__right">
                              <SkeletonText :count="2"></SkeletonText>
                          </div>
                      </div>
                      <div class="wrap-input">
                          <SkeletonText :count="2"></SkeletonText>
                      </div>
                      <div class="wrap-bottom">
                          <SkeletonButton></SkeletonButton>
                          <SkeletonText :count="2"></SkeletonText>
                      </div>
                  </div>
                  <div class="right">
                      <SkeletonText :count="8"></SkeletonText>
                  </div>
              </div>
              <div class="skeleton-balance__bottom">
                  <div class="head">
                      <SkeletonButton></SkeletonButton>
                  </div>
                  <div class="subhead">
                  </div>
                  <div class="body">
                      <SkeletonText :count="6"></SkeletonText>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
    import Modal2FAConfirmation from  "./Balances/Modal2FAConfirmation"
    import Deposits from './Balances/Deposits';
    import Withdraws from './Balances/Withdraws';
    import Transfers from './Balances/Transfers';
    import Merchant from './Balances/Merchant';
    import TransactionItem from './Balances/TransactionItem';
    import Payment from '../modules/Payment';
    import ModalDefault from "../modules/ModalDefault";
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    import TransactionHistory from "../pages/TransactionHistory";
    import SearchDefault from "../modules/SearchDefault.vue";
    import BalancesSorting from "./Balances/BalancesSorting";
    import DropdownDefault from "../modules/DropdownDefault";
    import _ from 'lodash';
    import SkeletonText from "../modules/SkeletonText";
    import SkeletonButton from "../modules/SkeletonButton";

    export default {
        name: 'Balances',
        data () {
            return {
                progress: 0,
                search: null,
                show: false,
                active: false,
                silver: null,
                commission: '2.9',
                modal_cancel: false,
                modal_success: false,
                modal_confirmation_2fa: false,
                show_transactions: false,
                sorting_modal: false,
                filter: [],
                side_tabs: [
                    {
                        'title': 'Deposit Funds',
                        'value': 'deposit'
                    },
                    {
                        'title': 'Withdraw',
                        'value': 'withdraw'
                    },
                    // {
                    //     'title': 'Internal transfer',
                    //     'value': 'transfer'
                    // },
                    {
                        'title': 'System Operations',
                        'value': 'operations'
                    }
                ],
                payments: null,
                headers_transactions: [
                    'Operation ID', 'Payment System', 'Amount', 'Amount Coin', 'Address','Status', 'Date and time UTC'
                ],
                transactions: null,
                deposit: null,
                user_balance: null,
                wallets: null,
                successWithdraws: null,
                submitDisabled: false,
                url: null,
                sorting: {
                    income: null,
                    wallet: null,
                    date_from: null,
                    date_to: null,
                    payment_item: null,
                    payment: null,
                    status: null,
                },
                next_page_url: null,
                loading_page: false,
            }
        },
        watch: {
            search(val) {
                if (val) {
                    this.show = true;
                } else {
                    this.show = false;
                }
            }
        },
        created() {
            this.$http
                .get("ajax/payments_info")
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        let payments = response.data.reverse();

                        this.silver= this.auth.silver;

                        // let SiverCoin = {
                        //     "id": 0,
                        //     "name": "MIND Coin",
                        //     "img": "/dist/images/frontend/light-coin.svg",
                        //     "name_class": "silver",
                        //     "currency": "MIND",
                        //     "sprintf": 2,
                        //     "sort": 0,
                        //     "replenish": 0,
                        //     "min_replenish": 1,
                        //     "active": 1,
                        //     "course_usd": this.silver.course_lite,
                        //     "withdraw": 1,
                        //     "min_withdraw": 1,
                        //     "replenish_pay_commission": "percent",
                        //     "withdraw_pay_commission": "percent",
                        //     "replenish_commission": this.silver.percent_lite,
                        //     "withdraw_commission": this.silver.percent_lite,
                        //     "cryptocurrency": 1,
                        // };
                        // let newPayments = [];
                        // this.payments= newPayments.concat(SiverCoin, payments);
                        //
                        this.payments = payments;
                        // this.active_payment = payments[0];

                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });

            if (this.currentTab != 'operations') {
                this.getTransactions(this.currentTab);

                this.$addListener(window,'scroll', () => {
                    this.scrollHistory(this.currentTab);
                });
            }

            if (this.currentTab == 'deposit' || this.currentTab == 'withdraw') {
                this.getBalanceInfo(this.currentTab);
            }

        },

        computed: {
            currentTab() {
                return (this.$route.params.current) ? this.$route.params.current : this.side_tabs[0].value
            },
            // paymentMerchant(){
            //     if(this.deposit) {
            //         return this.deposit[0];
            //     } else {
            //         return false;
            //     }
            // },
            auth() {
                return this.$store.state.auth.user;
            },
            replenishPayments() {
                return _.filter(this.payments, {replenish: 1});
            },
            replenishUsdtPayments() {
                // return _.filter(this.replenishPayments, {id: 24});
                let usdtPaymentsArray = [];
                this.replenishPayments.forEach(item => {
                    if (item.id != 24) {
                        usdtPaymentsArray.push(item);
                    }
                })

                return usdtPaymentsArray;
            },
            withdrawPayments() {
                return _.filter(this.payments, {withdraw: 1});
            }
        },
        methods: {
            pushDeposit(deposit) {
                this.deposit= deposit;
                this.transactions.data.unshift(deposit);
            },
            changeClass() {
                this.$refs.block_search.classList.toggle('active');
                this.active_search = !this.active_search;
            },

            // clearInput() {
            //   this.search = null;
            // },
            //
            // cancelSearch() {
            //     let response = {
            //         search: null,
            //         type: null,
            //         income: null,
            //         wallet: null,
            //         date_from: null,
            //         date_to: null,
            //     };
            //     this.$http.post("ajax/transaction", response)
            //         .then((response) => {
            //             return response.json();
            //         })
            //         .then((response) => {
            //             this.search = null;
            //             // this.$emit('updateTransactions', response.data);
            //         })
            //         .catch((err) => {
            //             let response = this.httpHandler(err);
            //             if (response.error) {
            //                 this.formValidate = response.error.message;
            //             }
            //         });
            // },
            //
            // paidTransactions(transaction) {
            //     let response= {
            //         id: transaction.id
            //     };
            //     this.submitDisabled= true;
            //     this.$http
            //         .post("ajax/deposit/paid", response)
            //         .then((response) => {
            //             return response.json();
            //         })
            //         .then((response) => {
            //             if(this.$checkAuth(response)) {
            //                 // response= {
            //                 //     "data": {
            //                 //         "id": 2,
            //                 //         "user_id": 1,
            //                 //         "frozen": 0,
            //                 //         "wallet": 1,
            //                 //         "amount": "4330.00000000",
            //                 //         "amount_unit": "5300.00000000",
            //                 //         "rate_amount": "0.00000000",
            //                 //         "income": 1,
            //                 //         "commission": "0.00000000",
            //                 //         "opp_type": 1,
            //                 //         "game_played_id": null,
            //                 //         "game_id": null,
            //                 //         "payment_id": 1,
            //                 //         "tx": "14dGw2EEQx78SgXjWuFjFixQXWCVMYY1zQ",
            //                 //         "status": 2,
            //                 //         "created_at": "2021-02-22 20:03:39"
            //                 //     }
            //                 // };
            //
            //                 response = response.data;
            //                 // let index = this.$filterIndexArray(this.deposit, response.id);
            //                 //
            //                 // if (index >= 0) {
            //                 //     this.deposit[index] = response;
            //                 // }
            //
            //                 this.deposit= response;
            //
            //                 this.submitDisabled = false;
            //             }
            //         })
            //         .catch((err) => {
            //             this.httpHandler(err);
            //             this.modal_cancel= false;
            //             this.submitDisabled= false;
            //         });
            //
            // },
            CancelTransactions(transaction, type='withdraw') {
                let response= {
                    id: transaction.id
                };
                this.submitDisabled= true;
                this.$http
                    .post("ajax/"+type+"/cancel", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {

                            if(this.$checkAuth(response)) {
                                response = response.data;
                                let id= this.modal_cancel.id;

                                this.modal_cancel = false;
                                this.submitDisabled = false;
                                if (type == 'withdraw') {
                                    _.remove(this.transactions.data, {id: id});

                                    // if(response.info) {
                                    //     this.updateWithdraws(response.info);
                                    // }
                                } else {
                                    // if (response.deposit) {
                                    //     this.deposit = response.deposit;
                                    // }
                                    this.deposit= null;
                                    if (response.deposit_transaction) {
                                        this.deposit_transaction = response.deposit_transaction;
                                    }
                                }
                                this.getTransactions(this.currentTab)
                            }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                            this.modal_cancel= false;
                            this.submitDisabled= false;
                        });

            },
            cancelTransactions(type) {
                this.modal_cancel= type;
            },
            // openPayment(payment) {
            //     this.active_payment= payment;
            //     this.successWithdraws= null;
            // },
            showTransactions(show) {
                this.show_transactions= show;
                this.successWithdraws= null;
            },
            updateWithdraws(response, transaction= false) {
                if(response.user_balance) {
                    this.user_balance= response.user_balance;
                }
                if(response.withdraw_transaction) {
                    this.transactions= response.withdraw_transaction;
                }
                if(response.with) {
                    this.transactions.data.unshift(response.with);
                }
                if(transaction) {
                    this.successWithdraws= transaction;
                    this.transactions.data.unshift(transaction);
                }
            },

            updateTransfer() {
                // response.forEach((item) => {
                //     this.transactions.data.push(item);
                // });

                // this.transactions.data = response;
                this.getTransactions(this.currentTab);
            },
            /*getTransactions(currentTab) {
                this.transactions= null;
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);

                this.$http
                    .get("ajax/"+currentTab)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            response = response.data;

                            if (response.deposit) {
                                this.deposit = response.deposit;
                            }
                            if (response.deposit_transaction) {
                                this.transactions = response.deposit_transaction;
                            }
                            if (response.user_balance) {
                                this.user_balance = response.user_balance;
                            }
                            if (response.withdraw_transaction) {
                                this.transactions = response.withdraw_transaction;
                            }
                            if (response.transfer_transaction) {
                                this.transactions = response.transfer_transaction;
                            }
                            clearInterval(timeout);
                            this.progress = 100;
                            setTimeout(() => {
                                this.progress = 0;
                            }, 500);
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            },*/
            getBalanceInfo(newTab) {
                this.$http
                    .get("ajax/" + newTab)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if (this.$checkAuth(response)) {
                            response = response.data;

                            if (response.deposit) {
                                this.deposit = response.deposit;
                            }

                            if (response.user_balance) {
                                this.user_balance = response.user_balance;
                            }
                            if (response.wallets) {
                                this.wallets = response.wallets;
                            }
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            },
            getTransactions(newTab) {
                let response = this.sorting;
                response.search = this.search;
                let type;
                if(newTab=='deposit') {
                    type = 1;
                } else if (newTab=='withdraw') {
                    type = 2;
                } else if (newTab=='transfer') {
                    type = 8;
                } else {
                    type = null;
                }
                response.type = type;
                this.transactions= null;
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction/history", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            // response= {
                            //     "data": {
                            //         "current_page": 1,
                            //         "data": [
                            //             {
                            //                 "achiev_id": null,
                            //                 "amount": "250.00000000",
                            //                 "amount_unit": null,
                            //                 "commission": "0.00000000",
                            //                 "confirm": 0,
                            //                 "created_at": "2020-11-03 08:24:48",
                            //                 "frozen": 0,
                            //                 "game_id": null,
                            //                 "game_played_id": null,
                            //                 "id": 642,
                            //                 "income": 1,
                            //                 "lvl": null,
                            //                 "opp_type": 1,
                            //                 "payment_id": null,
                            //                 "quest_id": null,
                            //                 "rate_amount": "0.00000000",
                            //                 "status": 1,
                            //                 "transfer_user_id": null,
                            //                 "tx": "Bonus",
                            //                 "user_id": 1967725861,
                            //                 "wallet": 2,
                            //             },
                            //             {
                            //                 "amount": "1.10000000",
                            //                 "amount_unit": "110.00000000",
                            //                 "commission": "0.00000000",
                            //                 "confirm": 0,
                            //                 "created_at": "2021-10-07 13:45:57",
                            //                 "frozen": 0,
                            //                 "game_id": null,
                            //                 "game_played_id": null,
                            //                 "id": 8047,
                            //                 "income": 2,
                            //                 "lvl": null,
                            //                 "opp_type": 2,
                            //                 "payment_id": 0,
                            //                 "quest_id": null,
                            //                 "rate_amount": "1.10000000",
                            //                 "status": 1,
                            //                 "transfer_user_id": null,
                            //                 "tx": "Silver Coin",
                            //                 "user_id": 2,
                            //                 "wallet": 1  ,
                            //             },
                            //             {
                            //                 "id": 7217,
                            //                 "user_id": 4,
                            //                 "frozen": 0,
                            //                 "wallet": 1,
                            //                 "amount": "10.00000000",
                            //                 "amount_unit": "0.00023746",
                            //                 "rate_amount": "10.00000000",
                            //                 "income": 2,
                            //                 "transfer_user_id": null,
                            //                 "commission": "0.00000000",
                            //                 "lvl": null,
                            //                 "opp_type": 2,
                            //                 "game_played_id": null,
                            //                 "achiev_id": null,
                            //                 "quest_id": null,
                            //                 "game_id": null,
                            //                 "payment_id": 1,
                            //                 "tx": "adsdsasad",
                            //                 "status": 2,
                            //                 "confirm": 0,
                            //                 "created_at": "2021-09-22 10:57:38"
                            //             },
                            //             {
                            //                 "id": 7216,
                            //                 "user_id": 4,
                            //                 "frozen": 0,
                            //                 "wallet": 1,
                            //                 "amount": "0.00000000",
                            //                 "amount_unit": "0.00376304",
                            //                 "rate_amount": "11.00000000",
                            //                 "income": 2,
                            //                 "transfer_user_id": null,
                            //                 "commission": "0.00000000",
                            //                 "lvl": null,
                            //                 "opp_type": 2,
                            //                 "game_played_id": null,
                            //                 "achiev_id": null,
                            //                 "quest_id": null,
                            //                 "game_id": null,
                            //                 "payment_id": 2,
                            //                 "tx": "jkjkjjhjjjjjjh",
                            //                 "status": 3,
                            //                 "confirm": 0,
                            //                 "created_at": "2021-09-22 10:55:44"
                            //             },
                            //             {
                            //                 "id": 4434,
                            //                 "user_id": 4,
                            //                 "frozen": 0,
                            //                 "wallet": 1,
                            //                 "amount": "839.27000000",
                            //                 "amount_unit": "839.27000000",
                            //                 "rate_amount": "839.27000000",
                            //                 "income": 2,
                            //                 "transfer_user_id": null,
                            //                 "commission": "0.00000000",
                            //                 "lvl": null,
                            //                 "opp_type": 2,
                            //                 "game_played_id": null,
                            //                 "achiev_id": null,
                            //                 "quest_id": null,
                            //                 "game_id": null,
                            //                 "payment_id": 1,
                            //                 "tx": "update",
                            //                 "status": 1,
                            //                 "confirm": 0,
                            //                 "created_at": "2021-06-28 13:41:47"
                            //             },
                            //             {
                            //                 "id": 4435,
                            //                 "user_id": 4,
                            //                 "frozen": 0,
                            //                 "wallet": 2,
                            //                 "amount": "391.78000000",
                            //                 "amount_unit": "391.78000000",
                            //                 "rate_amount": "391.78000000",
                            //                 "income": 2,
                            //                 "transfer_user_id": null,
                            //                 "commission": "0.00000000",
                            //                 "lvl": null,
                            //                 "opp_type": 2,
                            //                 "game_played_id": null,
                            //                 "achiev_id": null,
                            //                 "quest_id": null,
                            //                 "game_id": null,
                            //                 "payment_id": 1,
                            //                 "tx": "update",
                            //                 "status": 1,
                            //                 "confirm": 0,
                            //                 "created_at": "2021-06-28 13:41:47"
                            //             },
                            //             {
                            //                 "id": 2830,
                            //                 "user_id": 4,
                            //                 "frozen": 0,
                            //                 "wallet": 1,
                            //                 "amount": "0.00000000",
                            //                 "amount_unit": "10.00000000",
                            //                 "rate_amount": "10.00000000",
                            //                 "income": 2,
                            //                 "transfer_user_id": null,
                            //                 "commission": "0.00000000",
                            //                 "lvl": null,
                            //                 "opp_type": 2,
                            //                 "game_played_id": null,
                            //                 "achiev_id": null,
                            //                 "quest_id": null,
                            //                 "game_id": null,
                            //                 "payment_id": 3,
                            //                 "tx": "sdadssaddasdsasdadsadsasad",
                            //                 "status": 4,
                            //                 "confirm": 5,
                            //                 "created_at": "2021-05-13 11:31:09"
                            //             }
                            //         ],
                            //         "first_page_url": "https://mindplays.com/ajax/transaction/history?page=1",
                            //         "from": 1,
                            //         "last_page": 1,
                            //         "last_page_url": "https://mindplays.com/ajax/transaction/history?page=1",
                            //         "next_page_url": null,
                            //         "path": "https://mindplays.com/ajax/transaction/history",
                            //         "per_page": 24,
                            //         "prev_page_url": null,
                            //         "to": 5,
                            //         "total": 5
                            //     },
                            //     "auth": true,
                            //     "status": 200
                            // };
                            response = response.data;
                            if (response.deposit) {
                                this.deposit = response.deposit;
                            }
                            this.transactions = response;
                            this.next_page_url = response.next_page_url;
                            clearInterval(timeout);
                            this.progress = 100;
                            setTimeout(() => {
                                this.progress = 0;
                            }, 500);
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            },
            getPayment(payment_id) {
                return this.$filterArray(this.payments, payment_id, 'id');
            },
            getPaymentWithdraw(payment_id) {
                return this.$filterArray(this.withdrawPayments, payment_id, 'id');
            },
            sideChange(newTab) {
                this.search = null;
                this.sorting.wallet = null;
                this.sorting.date_from = null;
                this.sorting.date_to = null;
                this.sorting.payment_item = null;
                this.sorting.payment = null;
                this.sorting.status = null;
                this.sorting.income = null;
                this.filter = [];
                if(newTab != this.currentTab) {
                    if (newTab != 'operations') {
                        this.getTransactions(newTab);
                    }
                    if (newTab == 'deposit' || newTab == 'withdraw') {
                        this.getBalanceInfo(newTab);
                    }
                }
                this.successWithdraws= null;
                this.$router.push({name: 'balances_current', params: {'current': newTab, lang: this.$store.getters.getLang}});

                if (newTab != 'operations') {
                    this.$addListener(window, 'scroll', () => {
                        this.scrollHistory(newTab);
                    });
                }
            },

            updateTransactions(response) {
                if(response) {
                    this.transactions = response;
                    this.next_page_url = response.next_page_url;
                }
            },
            cancelSearch(newTab) {
                this.search = null;
                this.getTransactions(newTab);
                this.$refs.block_search.classList.toggle('active');
                this.active_search = false;
            },
            scrollHistory(newTab) {
                const progress = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
                if(!this.loading_page && this.next_page_url) {
                    let bottomOfWindow = 0.7 <= progress;
                    if (bottomOfWindow) {
                        this.loading_page = true;
                        let response = this.sorting;
                        response.search = this.search;
                        let type;
                        if(newTab=='deposit') {
                            type = 1;
                        } else if (newTab=='withdraw') {
                            type = 2;
                        } else if (newTab=='transfer') {
                            type = 8;
                        } else {
                            type = null;
                        }
                        response.type = type;
                        this.$http.post(this.next_page_url, response)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if(this.$checkAuth(response)) {
                                    response = response.data;
                                    let transactions = this.transactions.data;
                                    this.transactions.data = transactions.concat(response.data);
                                    this.next_page_url = response.next_page_url;
                                    this.loadingPage = false;
                                }
                            })
                            .catch((err) => {
                                this.httpHandler(err);
                            });
                    }
                }

            },
        },
        components: {
            ModalDefault,
            Modal2FAConfirmation,
            Deposits,
            Withdraws,
            Transfers,
            Merchant,
            // Transactions,
            // Table,
            // Payment,
            // ScrollPagination,
            Payment,
            LayoutNavAuth,
            TransactionItem,
            TransactionHistory,
            SearchDefault,
            BalancesSorting,
            DropdownDefault,
            SkeletonText,
            SkeletonButton
        },

    }
</script>

<style lang="scss">

    .dark {
        .wrapper-balance {
            h1 {
                color: #fff;
            }
            .wrap-history {
                .sidebar-balance {

                    &__title {
                        color: #fff;
                    }

                    &__body {
                        background: #171827;
                    }

                    &__text {
                        color: #96AFD1;
                    }

                    &__link {
                        color: #fff;
                    }

                    .block-link {
                        background: #181818;
                        border-color: transparent;

                        p {
                            border-left: 1px solid #002b50;
                            color: #96AFD1;
                        }
                    }
                }

                .wrap-table-trans {
                    background: #171827;

                    .transaction-head {
                        background: #181818;

                        span {
                            color: #fff;
                        }
                    }

                    .block-empty {
                        p {
                            color: #fff;
                        }

                        img {
                            filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(150%) contrast(105%);
                        }
                    }
                }

                &__head {
                    background: #171827;
                    border-bottom: 1px solid #2B2B2B;

                    span {
                        color: #fff;
                    }

                    svg {
                        color: #96AFD1;
                    }

                    .wrap-btn {
                        .v-btn.btn-search,
                        .v-btn.btn-sorting {
                            background: #171827;
                            border: 1px solid #171827;
                            color: #96AFD1;

                            &:before {
                                /*background-color: #181818!important;*/
                                /*color: #26a9f4!important;*/
                            }

                            &:hover {
                                background-color: #0B0C1A!important;
                                /*color: #26a9f4!important;*/
                            }
                        }

                        .search {
                            border-left: 1px solid rgba(255, 255, 255, 0.1);
                            background: #171827;
                            &__icon {
                                svg {
                                    color: #96AFD1;
                                }
                            }

                            .cancel-icon {
                                svg {
                                    color: #96AFD1;
                                }
                            }
                        }
                    }

                }
            }

            .theme--light.v-card {
                background: #171827;
                border-color: transparent;
            }

            .v-sheet.v-card {
                border-bottom: 1px solid #2B2B2B;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .dark {
            .wrapper-balance {
                .wrap-history {
                    &__head {
                        .wrap-btn {
                            .search {
                                border-bottom: 1px solid #2B2B2B;
                            }
                        }
                    }
                }
            }
        }
    }

  .wrapper-balance {
    margin-right: 0;
    /*padding: 0 20px;*/

    &__head {
      //box-shadow: 0px 2px 0px #F2F3FB;
      border-radius: 8px;
      padding: 25px 20px 25px 20px;
      display: flex;
      justify-content: space-between;
      //border-bottom: 2px solid #ECF4FF;
      position: relative;
      align-items: flex-end;
      margin-bottom: 20px;

      &:after {
        border-radius: 8px;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.3;
        background: linear-gradient(90deg, #9CDFF9 0%, #87D6F5 31.01%, #C5DDF0 45.02%, #BDDBFC 64.06%, #D8E3F0 100%, #C5D6EA 100%);
      }

      .block-title {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        .icon-deposit {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 70px;
          height: 70px;
          background: rgba(0,196,67,.4);
          margin-right: 25px;
          border-radius: 18px;
        }

        .icon-withdraw {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 70px;
          height: 70px;
          background: rgba(255, 75, 85, 0.4);
          margin-right: 25px;
          border-radius: 18px;

          svg{
            transform: rotate(180deg);
          }
        }

        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #002B50;
          margin-bottom: 0;
        }

        span {
          display: block;
          font-size: 14px;
          line-height: 18px;
          color: #6886AF;
          width: 78%;
        }
      }
    }

    &__body {
      display: flex;
      margin-top: 20px;
    }


    h2 {
      font-weight: 400;;
      font-size: 18px;
      line-height: 24px;
      color: #002B50;
      margin-top: -5px;
      margin-bottom: 16px;
    }

    .block-payments {
      width: 320px;
      flex-shrink: 0;

      .wrap-block-payments {
        height: calc(100vh - 168px);
        background: #F9F9F9;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
        overflow: auto;
        border-radius: 8px;
        padding: 0;
        transition: height .3s;


        &--fixed {
          transition: height .3s;
          position: fixed;
          top: 88px;
          right: auto;
          max-width: 320px;
          width: 100%;
          margin-top: 0;
          height: calc(100vh - 108px);
        }
      }

      &__head {
        display: flex;
        align-items: center;
        padding: 20px;
        /*border-bottom: 2px solid #ecf4ff;*/
        background-color: #fff;

        svg {
          margin-right: 18px;
        }


        p {
          font-size: 16px;
          line-height: 22px;
          color: #002B50;
          margin: 0;
        }
      }

      .active {
        /*background: linear-gradient(90.1deg, #E7EFFF 0.08%, rgba(231, 239, 255, 0) 99.92%);*/
        /*border-left: 2px solid #26A9F4;*/
        background: rgba(2, 110, 237, 0.1);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
      }

      .payment-item {
        display: flex;
        align-items: center;
        min-height: 60px;
        padding: 0 10px 0 12px;
        transition-duration: .3s;
        border-bottom: 1px solid rgba(0, 43, 80, 0.1);
        cursor: pointer;

        &:hover {
          /*background: linear-gradient(90.1deg, #E7EFFF 0.08%, rgba(231, 239, 255, 0) 99.92%);*/
          background: rgba(2, 110, 237, 0.1);
          transition-duration: .3s;
        }

        p {
          margin: 0;
        }

        &__icon {
          height: 40px;
          margin-right: 14px;
        }

        &__name {
          color: #002B50;
          margin-bottom: 0!important;
          font-size: 15px;
        }

        &__subtitle {
          display: flex;
          font-size: 12px;
          line-height: 18px;
          color: #6886AF;
          align-items: center;

          svg {
            margin: 0 5px;
          }
        }

      }
    }

    .wrap-head {
      display: flex;
      justify-content: space-between;
      /*background-color: #fff;*/
      /*border-radius: 8px 8px 0 0;*/
      padding: 20px 0;
      /*border-bottom: 1px solid rgba(0, 0, 0, 0.08);*/
      width: calc(100% - 395px);

        &--operation {
            padding: 0;
            margin-top: 20px;

            h5 {
                white-space: nowrap;
            }
        }

        h1 {
            font-size: 18px;
            line-height: 24px
        }

        .wrap-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            .search {
                display: flex;
                align-items: center;
                padding-left: 20px;
                margin-left: 20px;
                border-left: 1px solid #E7F5FF;
                position: relative;

                &__icon {
                    /*margin-bottom: 3px;*/
                    svg  {
                        margin-right: 0;
                    }
                }

                .cancel-icon {
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                    top: 8px;

                    svg {
                        transition: .3s;
                        margin-right: 0;
                        color: #607EA7;
                    }

                    &:hover {
                        svg {
                            transform: scale(1.15);
                            transition: .3s;
                            color: #0F9AFF;
                        }
                    }
                }
            }
        }

        .v-btn.btn-search,
        .v-btn.btn-sorting {
            background: #fff;
            border-radius: 8px;
            transition: .3s;

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 40px;
                padding: 0;
            }

            &:hover {
                background-color: #E7F5FF;
                transition: .3s;
            }

            &.v-btn:before {
                background-color: #E7F5FF;
                transition: .3s;
            }
        }

        .v-btn.btn-search--active {
            background-color: #E7F5FF;
        }
    }

    .wrap-tabs {
      border-radius: 0;
    }

    .wrap-history {
      padding: 0 12px 0 16px;
      width: 100%;


      .sidebar-balance {
        margin-left: 20px;
        max-width: 400px;
        width: 100%;
        flex-shrink: 0;
        margin-top: -44px;
        height: auto;
        margin-bottom: 64px;

        &__title {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.001em;
          color: #000000;
          margin-bottom: 20px;
        }

        &__body {
            background-color: #fff;
            border-radius: 4px;
            padding: 20px;
            height: 100%;
            position: relative;

            .icon-svg {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        &__text {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.001em;
          color: #6886AF;
          margin-bottom: 10px;

          span {
            color: #26A9F4;
          }

          a {
              color: #26A9F4;

              &:hover {
                  text-decoration: underline;
              }
          }
        }

        &__link {
          font-size: 14px;
          line-height: 16px;
          color: #5E6875;
          margin: 15px 0;

          span {
            font-weight: bold;
          }
        }

        .block-link {
          padding: 5px 20px 5px 12px;
          display: flex;
          align-items: center;
          background: #FFFFFF;
          border: 1px solid #D4DEE7;
          box-sizing: border-box;
          border-radius: 8px;
          height: 40px;
          width: fit-content;


            p {
             font-size: 16px;
             line-height: 18px;
             color: #5E6875;
             margin: 0 0 0 15px;
             padding-left: 15px;
             border-left: 1px solid #D4DEE7;
             height: 100%;
             display: flex;
             align-items: center;

             span {
               color: #01AC5A;
             }
           }
        }
      }

      .wrap-table-trans {
        background-color: #FFFFFF;
        border-radius: 4px;

        .transaction-head {
          padding: 0 70px 0 20px;
          height: 36px;
          align-items: center;
          display: flex;
          justify-content: space-between;
          background-color: #ECF2FE;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #6886AF;
            width: 20%;
            display: flex;
            align-items: center;

            &:last-child {
              justify-content: flex-end;
            }
          }
        }

        .transaction-body {
            border-radius: 0 0 4px 4px;
        }


        .block-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 80px 0 80px;
            border-radius: 0 0 4px 4px;

            img {
                margin-bottom: 5px;
            }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin: 7px 0 0;
            color: #002B50;
            text-align: center;
          }
        }
      }

      &__head {
        background: #fff;
        padding: 0 20px;
        height: 64px;
        margin-bottom: 0;
        font-weight: 400;;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-right: 20px;
            color: #002b50;
        }

          .wrap-btn {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .search {
                  display: flex;
                  align-items: center;
                  padding-left: 20px;
                  margin-left: 20px;
                  border-left: 1px solid #E7F5FF;
                  position: relative;

                  &__icon {
                      svg  {
                          margin-right: 0;
                          color: #002b50;
                      }
                  }

                  .cancel-icon {
                      cursor: pointer;
                      position: absolute;
                      right: 0;
                      top: 8px;


                      svg {
                          transition: .3s;
                          margin-right: 0;
                          color: #607EA7;
                      }

                      &:hover {
                          svg {
                              transform: scale(1.15);
                              transition: .3s;
                              color: #0F9AFF;
                          }
                      }
                  }
              }
              .wrap-btn-search {
                  display: none;
              }
          }

          .wrap-btn-sort {
              position: relative;
              color: #002b50;
              .block-count {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  background: #26A9F4;
                  border: 2px solid #FFFFFF;
                  width: 22px;
                  height: 22px;
                  border-radius: 50%;
                  font-size: 12px;
                  line-height: 16px;
                  position: absolute;
                  top: -7px;
                  right: -7px;
              }
          }

          .v-btn.btn-search,
          .v-btn.btn-sorting {
              background: #fff;
              border-radius: 8px;
              transition: .3s;

              &.v-btn:not(.v-btn--round).v-size--default {
                  height: 40px;
                  min-width: 40px;
                  padding: 0;
              }

              &:hover {
                  background-color: #E7F5FF;
                  transition: .3s;
              }

              &.v-btn:before {
                  background-color: #E7F5FF;
                  transition: .3s;
              }

              svg {
                  margin: 0;
              }
          }

          .v-btn.btn-search--active {
              background-color: #E7F5FF;
          }

          .icon-deposit {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              background: rgba(0,196,67,.3);
              margin-right: 25px;
              border-radius: 4px;
          }

          .icon-withdraw {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              background: rgba(255, 75, 85, 0.3);
              margin-right: 25px;
              border-radius: 4px;

              svg{
                  transform: rotate(180deg);
              }
          }
      }
    }

    .table {
      margin-right: 0;
      margin-bottom: 0;

      .pic {
        margin: 0 5px 0 0;
      }

      th {
        border-bottom: 1px solid #EBEBEB;
        padding: 30px 0px;
        padding-left: 26px;

          &:last-child {
            padding-right: 26px;
            padding-left: 0;
              text-align: right;
          }
      }

      tr {
        padding: 18px 26px;
        border-bottom: 1px solid #ecf4ff;

      }

      td {
        font-size: 14px;
        color: #002B50;
        padding: 22px 26px;

        &:last-child {
          text-align: right;
        }
      }

        .block-success {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: #CDFFCD;
            border-radius: 10px;
            color: #00C443;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        .block-expectation {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: rgba(246, 164, 1, 0.1);
            border-radius: 10px;
            color: #F6A401;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        .block-rejected {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: rgba(255, 75, 85, 0.1);
            border-radius: 10px;
            color: #FF4B55;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        .block-real {
            display: flex;
            align-items: center;
            font-weight: 400;;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #00C443;
        }

        .block-lite {
            display: flex;
            align-items: center;
            font-weight: 400;;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #6886AF;
        }
    }
  }

  .dark {
      .wrap-modal {
          background: #171827;
          border-radius: 4px;
          &__head {
              color: #fff;
              border-bottom: 1px solid #2B2B2B;
          }

          h5 {
              color: #fff;
          }

          .block-row {
              color: #fff;

              span {
                  color: #96AFD1;
              }

              a {
                  color: #26a9f4;
              }
          }
      }
  }

  .wrap-modal {


      &__head {
          border-bottom: 1px solid #F0F6FE;
          padding: 20px 25px;
          font-size: 20px;
          line-height: 27px;
          color: #002B50;
      }

      &__body {
          padding: 20px;
      }

      h5 {
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #002B50;
          margin-bottom: 20px;
      }

      .block-row {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #002B50;
          margin-bottom: 6px;

          span {
              font-size: 14px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: #6886AF;
              min-width: 160px;
          }

          a {
              font-size: 14px;
              line-height: 19px;
              display: flex;
              align-items: center;
              text-transform: capitalize;
              color: #002B50;

          }

          .block-id {
              font-size: 12px;
              line-height: 16px;
              color: #6886AF;
          }
          .block-img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 10px;


              img {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
              }

              .photo {
                  min-width: 30px;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  display: flex;
                  color: #FFFFFF;
                  justify-content: center;
                  align-items: center;
                  text-decoration: none;
                  font-size: 12px;
                  font-weight: bold;
                  text-transform: uppercase;
              }
          }

          .icon-money {
              height: 14px;
              margin-right: 5px;
          }
      }
      .wrap-btn {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;

          .v-btn.btn-cancel.v-btn:not(.v-btn--round).v-size--default {
              min-width: 100px;
          }
      }
  }

  @media screen and (max-width: 1024px) {
      .wrapper-balance {
          .wrap-history {
              .sidebar-balance {
                  max-width: 320px;
                  &__body {

                  }

                  &__text {
                      font-size: 12px;
                      line-height: 16px;
                      margin-bottom: 8px;
                  }
              }
          }
      }
      .wrap-modal {

          .block-row {
              span {
                  min-width: fit-content;
                  margin-right: 5px;
              }
              a {
                  display: block;
                  font-size: 14px;
                  line-height: 19px;
                  display: flex;
                  align-items: center;
                  text-transform: capitalize;
                  color: #002B50;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
              }
          }
      }
  }

  @media screen and (max-width: 950px) {
      .wrapper-balance {

          .wrap-history {

              &__head {
                  padding: 0 15px;
              }
              .wrap-table-trans {
                  .transaction-head {
                      padding: 0 60px 0 15px;
                      span {
                          width: 25%;
                          &:last-child {
                              display: none;
                          }

                          &:nth-child(4) {
                              justify-content: flex-end;
                          }
                      }
                  }
              }
              .sidebar-balance {
                  display: none;
              }
          }

          .wrap-head {
              width: 100% !important;
          }
      }
  }

  @media screen and (max-width: 768px){
      .wrap-modal {
          &__head {
              padding: 12px 15px;
              font-size: 16px;
              line-height: 22px;
          }

          &__body {
              padding: 15px;
          }

          h5 {
              font-size: 14px;
              line-height: 18px;
              margin-bottom: 12px;
          }

          .block-row {
              font-size: 12px;
              line-height: 18px;
              margin-bottom: 6px;

              span {
                  font-size: 12px;
                  line-height: 18px;
              }

              a {
                  font-size: 12px;
                  line-height: 17px;
              }
          }
          .wrap-btn {
              display: flex;
              justify-content: flex-end;
              margin-top: 24px;

              .v-btn.btn-cancel.v-btn:not(.v-btn--round).v-size--default {
                  min-width: 100px;
              }
          }
      }
  }

  @media screen and (max-width: 600px) {
      .wrapper-balance {

          .wrap-head {
              padding: 15px 0;

              &--operation {
                  margin-top: 15px;
                  padding: 0;
              }

              h1 {
                  font-size: 16px;
                  line-height: 22px;
              }
          }
          .wrap-history {

              &__head {
                  position: relative;
                  .wrap-btn {
                      .search {
                          position: absolute;
                          left: 0;
                          bottom: -41px;
                          width: 100%;
                          background-color: #fff;
                          display: flex;
                          align-items: center;
                          padding: 0 20px 4px 10px;
                          height: 40px;
                          transform: translateY(-40px);
                          transition: .3s;
                          opacity: 0;
                          margin: 0;
                          border: none;
                          border-bottom: 1px solid #ECF4FF;
                          z-index: -1;

                          &__input {
                              width: 100%;
                          }
                      }

                      .search.active {
                          transform: translateY(0);
                          height: 40px;
                          transition: .3s;
                          opacity: 1;
                          z-index: 10;
                      }

                      .wrap-btn-search {
                          margin-left: 10px;
                          display: block;
                      }
                  }
              }
              .wrap-table-trans {
                  .transaction-head {
                      span {
                          width: 30%;
                          &:nth-child(3) {
                              display: none;
                          }

                          &:nth-child(2) {
                              width: 20%;
                          }

                          &:nth-child(4) {
                              justify-content: flex-end;
                              width: 40%;
                          }
                      }
                  }
              }
          }
      }
  }

    @media screen and (max-width: 500px){
        .wrap-modal {
            .wrap-btn {
                display: flex;
                justify-content: center;
                margin-top: 20px;
            }
        }
    }

</style>
