<template>
    <div class="form-card">
        <div class="form-card__head">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.916748 5.66667C0.916748 4.5621 1.81218 3.66667 2.91675 3.66667H19.0834C20.188 3.66667 21.0834 4.5621 21.0834 5.66667V16.3333C21.0834 17.4379 20.188 18.3333 19.0834 18.3333H2.91675C1.81218 18.3333 0.916748 17.4379 0.916748 16.3333V5.66667Z" stroke="#26A9F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M0.916748 9.16667H21.0834" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>{{__('Add a bank card')}}</span>
        </div>

        <div class="form-card__body">
            <div class="form-number">
                <v-text-field
                        color="light-blue"
                        :label='__("Cardholder number")'
                        hide-details="auto"
                        v-mask="'#### #### #### ####'"
                        v-model="form.card"
                        @change="updateValidate('card')"
                        @input="formValidate.card = null"
                ></v-text-field>
                <img v-if="mastercard" src="/dist/images/frontend/Mastercard.png" alt />
                <img v-if="visa"  src="/dist/images/frontend/visa.png" alt />
                <div>
                    <p v-for="(value, key) in formValidate.card" :key="key">{{value}}</p>
                </div>
            </div>

            <div class="form-data">
                <div class="form-data__month">
                    <v-text-field
                            color="light-blue"
                            :label='__("MM")'
                            hide-details="auto"
                            v-mask="'##'"
                            v-model="form.card_month"
                            @change="updateValidate('card_month')"
                            @input="formValidate.card_month = null"
                    ></v-text-field>
                    <div>
                        <p v-for="(value, key) in formValidate.card_month" :key="key">{{value}}</p>
                    </div>
                </div>
                <span>/</span>
                <div class="form-data__year">
                    <v-text-field
                            color="light-blue"
                            :label='__("YY")'
                            hide-details="auto"
                            v-mask="'##'"
                            v-model="form.card_year"
                            @change="updateValidate('card_year')"
                            @input="formValidate.card_year = null"
                    ></v-text-field>
                    <div>
                        <p v-for="(value, key) in formValidate.card_year" :key="key">{{value}}</p>
                    </div>
                </div>
                <div class="form-data__currency">
                    <div style="width: 100%">
                        <p>{{__('Card currency')}}</p>
                        <DropdownDefault
                                style="width: 100%"
                                color="select_color"
                                v-bind:value.sync="form.card_currency"
                                v-bind:dropdown="cardCurrency"
                        />
                    </div>
                </div>
            </div>

            <div class="form-name">
                <div class="form-name__first">
                    <v-text-field
                            color="light-blue"
                            :label='__("First Name")'
                            hide-details="auto"
                            v-model="form.first_name"
                            @change="updateValidate('first_name')"
                            @input="formValidate.first_name = null"
                    ></v-text-field>
                    <div>
                        <p v-for="(value, key) in formValidate.first_name" :key="key">{{value}}</p>
                    </div>
                </div>
                <div class="form-name__last">
                    <v-text-field
                            color="light-blue"
                            :label='__("Last Name")'
                            hide-details="auto"
                            v-model="form.last_name"
                            @change="updateValidate('last_name')"
                            @input="formValidate.last_name = null"
                    ></v-text-field>
                    <div>
                        <p v-for="(value, key) in formValidate.last_name" :key="key">{{value}}</p>
                    </div>
                </div>
            </div>

            <div class="block-birthday">
                <v-menu
                        v-if="inputDate == null || inputDate == ''"
                        v-model="date"
                        :close-on-content-click="false"
                        max-width="290"
                >
                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                    </template>
                    <v-date-picker
                            ref="picker"
                            color="blue lighten-1"
                            :min="minYear"
                            :max="maxYear"
                            v-model="form.birthday"
                            @change="changeCalendar"
                    ></v-date-picker>
                </v-menu>

                <v-menu
                        v-else
                        v-model="date_menu"
                        :close-on-content-click="false"
                        max-width="290"
                >
                    <template v-slot:activator="{ on }">
                  <span
                          class="calendar"
                          prepend-icon="mdi-calendar"
                          :value="computedDate"
                          clearable
                          readonly
                          v-on="on"
                  >
                      <img src="/dist/images/frontend/Calendar.svg" alt />
                  </span>
                    </template>
                    <v-date-picker
                            color="blue lighten-1"
                            :min="minYear"
                            :max="maxYear"
                            v-model="form.birthday"
                            @change="changeCalendar"
                    ></v-date-picker>
                </v-menu>

                <v-text-field
                        color="light-blue"
                        :label='__("Birthday")'
                        v-mask="'##.##.####'"
                        v-model="inputDate"
                        clearable
                        @input="changeInput"
                        @change="updateDate"
                        ref="birthday"
                ></v-text-field>

                <!--                        placeholder="DD.MM.YYYY"-->
                <div class="error-text" v-if="errorDate && this.inputDate && !this.majority">{{__('No 18 years old')}}</div>
                <div class="error-text" v-else-if="errorDate && this.inputDate && this.majority">{{__('Date does not exist')}}</div>
                <div v-else-if="formValidate.birthday && !errorDate">
                    <p v-for="(value, key) in formValidate.birthday" :key="key">{{value}}</p>
                </div>
            </div>

            <div class="form-location">
                <div class="location-country">
                    <DropdownCountry
                            :label='__("Country")'
                            v-bind:value.sync="form.country"
                            @change="updateValidate('country')"
                            v-bind:errors="formValidate.country"
                            @updateCountry="updateCountry"
                    ></DropdownCountry>
                </div>
                <div class="location-city">
                    <DropdownCities
                            :label='__("City")'
                            v-bind:value.sync="form.city"
                            v-bind:country="form.country"
                            @change="updateValidate('city')"
                            v-bind:errors="formValidate.city"
                    ></DropdownCities>
                </div>
            </div>
        </div>
        <div class="wrap-btn">
            <BaseButton :loading="submitDisabled" @click="onSubmit">
                <span>{{__('Save changes')}}</span>
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import DropdownCountry from "../../modules/DropdownCountry";
    import DropdownCities from "../../modules/DropdownCities";
    import DropdownDefault from "../../modules/DropdownDefault";
    import {maxLength, minLength, maxValue, minValue, numeric, required} from "vuelidate/lib/validators";
    import {mask} from 'vue-the-mask';
    import moment from "moment";
    export default {
        data() {
            return {
                form: {
                    card: null,
                    card_month: null,
                    card_year: null,
                    card_currency: null,
                    first_name: null,
                    last_name: null,
                    country: null,
                    city: null,
                    birthday: null,
                },
                formValidate: {
                    card: null,
                    card_month: null,
                    card_year: null,
                    card_currency: null,
                    first_name: null,
                    last_name: null,
                    country: null,
                    city: null,
                    birthday: null,
                },
                date_menu: false,
                date: false,
                inputDate: null,
                errorDate: false,
                cardCurrency: [
                    {
                        title: this.__("USD"),
                        value: 0,
                    },
                    {
                        title: this.__("RUB"),
                        value: 1,
                    },
                    {
                        title: this.__("UAH"),
                        value: 2,
                    },
                ],
                visa: false,
                mastercard: false,
                submitDisabled: false,
            }
        },

        props: {
            modal_security: {
                type: [Boolean, Object]
            }
        },

        watch: {
            date (val) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
            },

            "form.card":function(val) {
                if (val) {
                    let num_1 = val.toString().slice(0,1);
                    let num_2 = val.toString().slice(0,2);
                    if (num_1 == '4') {
                        this.visa = true;
                    }  else if (num_1 == '5' && num_2.length < 2 || num_2.length >= 2 && (num_2 == '51' || num_2 == '52' || num_2 == '53' || num_2 == '54' || num_2 == '55')) {
                        this.mastercard = true;
                    } else {
                        this.visa = false;
                        this.mastercard = false;
                    }
                }

            },
            "form.country": function (val) {
                if(val) {
                    this.formValidate.country = null;
                }
            },
            "form.city": function (val) {
                if(val) {
                    this.formValidate.city = null;
                }
            }
        },
        validations() {
            return {
                form: {
                    card: {
                        required,
                        minLength: minLength(5),
                    },
                    card_month: {
                        required,
                        numeric,
                        minValue: minValue(1),
                        maxValue: maxValue(12),
                    },
                    card_year: {
                        required,
                        numeric,
                        minValue: minValue(this.nowYear)
                    },
                    card_currency: {
                      required,
                    },
                    first_name: {
                        required,
                        maxLength: maxLength(50),
                        minLength: minLength(2),
                    },
                    last_name: {
                        required,
                        maxLength: maxLength(50),
                        minLength: minLength(2),
                    },
                    country: {
                        required,
                    },
                    city: {
                        required,
                    },

                    birthday: {
                        required,
                    },
                }
            }
        },

        computed: {
            computedDate() {
                return this.form.birthday ? moment(this.form.birthday).format('DD.MM.YYYY') : ''
            },

            minYear: function () {
                return (new Date().getFullYear() - 100).toString();

            },
            maxYear: function () {
                let a = new Date().getTime() - 567648000000;
                return moment(a).format('YYYY-MM-DD');

            },
            nowYear() {
                let year =  new Date().getFullYear().toString();
                return year.slice(2)
            },
            auth() {
                return this.$store.state.auth.user;
            },
        },

        methods: {
            onSubmit() {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        card: [],
                        card_month: [],
                        card_year: [],
                        card_currency: [],
                        first_name: [],
                        last_name: [],
                        country: [],
                        city: [],
                        birthday: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else if (!this.validationDate() && this.inputDate) {
                    this.errorDate= true;
                }
                else {
                    let form = this.$cleanObject(this.form);
                    if(form.card_currency == 0) {
                        form.card_currency = 'USD';
                    } else if (form.card_currency == 1) {
                        form.card_currency = 'RUB';
                    } else if (form.card_currency == 2) {
                        form.card_currency = 'UAH';
                    }

                    // const response = this.$cleanObject(this.form);
                    const response = form;


                    if(this.auth.google_two_step == 1) {
                        this.$emit('update:modal_security', response);
                        this.form.card = null;
                        this.form.card_month = null;
                        this.form.card_year = null;
                        this.form.first_name = null;
                        this.form.last_name = null;
                        this.form.city = null;
                        this.form.country = null;
                        this.form.birthday = null;
                        this.inputDate = null;
                    } else {
                        this.$emit('sendCode');
                        this.$emit('update:modal_security', response);
                        this.form.card = null;
                        this.form.card_month = null;
                        this.form.card_year = null;
                        this.form.first_name = null;
                        this.form.last_name = null;
                        this.form.city = null;
                        this.form.country = null;
                        this.form.birthday = null;
                        this.inputDate = null;
                    }
                }
            },

            updateCountry(val) {
                this.form.country = val;
                this.form.city = null;
            },
            updateValidate (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },
            updateDate: function (value) {
                this.errorDate = false;
                if (this.validationDate()) {
                    let dateParts = value.split(".");
                    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                    this.form.birthday = moment(new Date(dateObject)).format('YYYY-MM-DD');
                } else if (this.inputDate == '') {
                    this.form.birthday = moment(new Date()).format('YYYY-MM-DD');
                    this.errorDate = false;
                } else {

                    this.errorDate = true
                }
            },

            changeCalendar: function () {
                this.date_menu = false;
                this.date = false;
                this.inputDate = this.computedDate;
            },
            validationDate: function () {
                let date = this.inputDate;
                let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
                // pattern.test(date);
                if (date) {
                    let year = (+date.split('.')[2])
                    let minData = (new Date().getFullYear() - 100);
                    let maxDate = (new Date().getFullYear() - 18);
                    let check =  (year >= minData && year <= maxDate) ? true : false;

                    if (check) {
                        this.majority = true
                    } else {
                        this.majority = false
                    }

                    return (pattern.test(date)) && check;
                }
            },
            changeInput: function (value) {
                if (value) {
                    this.errorDate = false;
                    this.formValidate.birthday = null;
                }
            },
        },
        components: {
            DropdownCountry,
            DropdownCities,
            DropdownDefault
        },
        directives: {mask}
    }
</script>

<style lang="scss" scoped>
    .form-card {
        padding: 20px;
        &__head {
            display: flex;
            margin-bottom: 18px;

            svg {
                margin-right: 20px;
            }
            span {
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }

        &__body {
            margin-left: 42px;
            margin-bottom: 30px;

            .form-number {
                width: 60%;
                margin-bottom: 22px;
                position: relative;

                img {
                    position: absolute;
                    right: 0;
                    bottom: 8px;
                }
                p {
                    position: absolute;
                    color: #FF4B55;
                    font-size: 10px;
                    margin: 0;
                }
            }

            .form-data {
                display: flex;
                align-items: center;
                margin-bottom: 22px;

                &__month {
                    width: 40px;
                    margin-top: 13px;

                    p {
                        position: absolute;
                        color: #FF4B55;
                        font-size: 10px;
                        margin: 0;
                    }
                }

                span {
                    font-size: 18px;
                    line-height: 18px;
                    letter-spacing: 0.4px;
                    color: #6886AF;
                    margin: 13px 8px 0;
                }

                &__year {
                    width: 40px;
                    margin-right: 30px;
                    margin-top: 13px;

                    p {
                        position: absolute;
                        color: #FF4B55;
                        font-size: 10px;
                        margin: 0;
                    }
                }

                &__currency {
                    p {
                        font-size: 13px;
                        line-height: 16px;
                        color: #6886AF;
                        margin-bottom: -8px;
                    }
                }
            }

            .form-name {
                display: flex;
                margin-bottom: 22px;
                &__first {
                    max-width: 220px;
                    width: 100%;
                    margin-right: 28px;

                    p {
                        position: absolute;
                        color: #FF4B55;
                        font-size: 10px;
                        margin: 0;
                    }
                }
                &__last {
                    max-width: 220px;
                    width: 100%;

                    p {
                        position: absolute;
                        color: #FF4B55;
                        font-size: 10px;
                        margin: 0;
                    }
                }
            }

            .block-birthday {
                max-width: 220px;
                margin-bottom: 22px;
                width: 100%;
                z-index: 1;
                position: relative;
                display: flex;
                align-items: center;

                .calendar {
                    color: #96AFD1;
                    position: absolute;
                    z-index: 100;
                    top: 5px;
                    right: 0px;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.1);
                        color: #26A9F4;
                    }
                }
                .error-text {
                    position: absolute;
                    bottom: -18px;
                    left: 0;
                    font-size: 12px;
                    color: #FF4B55;
                }
                p {
                    position: absolute;
                    color: #FF4B55;
                    font-size: 10px;
                    margin: 0;
                    left: 0;
                    bottom: -16px;
                }
            }

            .form-location {
                display: flex;
                .location-country {
                    max-width: 220px;
                    width: 100%;
                    margin-right: 28px;
                }

                .location-city {
                    max-width: 220px;
                    width: 100%;
                }
            }
        }

        .wrap-btn {
            display: flex;
            margin-left: 42px;
        }
    }

    @media screen and (max-width: 600px){
        .form-card {
            &__body {
                .form-number {
                    width: 100%;
                }
                .form-name {
                    flex-direction: column;

                    &__first {
                        max-width: 100%;
                        margin-bottom: 22px;
                    }

                    &__last {
                        max-width: 100%;
                    }
                }
                .block-birthday {
                    max-width: 250px;
                }
                .form-location {
                    flex-direction: column;

                    .location-country {
                        max-width: 100%;
                        margin-bottom: 22px;
                    }
                    .location-city {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .dark {
        .form-card {
            &__head {
                span {
                    color: #fff;
                }
            }
        }
    }
</style>