<template>
    <div class="block-nPlayGame">
        <div class="block-nPlayGame__left">
            <div class="timer">
                <div class="wrapper">
                    <div class="pie spinner"></div>
                    <div class="pie filler"></div>
                    <div class="mask"></div>
                </div>
                <img src="/dist/assets/games/game2048.png" alt />
                <span>{{time}}</span>
            </div>

            <div class="text">
                <p>{{__('Return to the game')}}!</p>
                <span>{{__('You have not completed the game! Come back! Or, otherwise, we will be forced to defend your gambling!')}}</span>
            </div>
        </div>

        <div class="block-nPlayGame__right">
            <a :href="auth.url_game ? auth.url_game : '#'">{{__('Return to the game')}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                time: 60,
            }
        },
        created() {
            this.interval= setInterval(()=> {
                this.time -= 1;
                if(this.time <= 0) {
                    clearInterval(this.interval);

                }
            }, 1000);
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        components: {
        }
    }
</script>

<style lang="scss" scoped>
    .block-nPlayGame {
        background-color: #fff;
        border-radius: 4px;
        padding: 10px;
        position: fixed;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1200px;
        width: 100%;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

         &__left {
             display: flex;
             align-items: center;

             .timer {
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 margin-right: 25px;

                 .wrapper {
                     position: relative;
                     background: white;
                     width: 60px;
                     height: 60px;
                     border-radius: 50%;
                     /*border: 2px solid #E7EFFF;*/
                     /*margin-right: 4px;*/

                     &:after {
                         content: "";
                         display: block;
                         width: 54px;
                         height: 54px;
                         border-radius: 50%;
                         background: rgba(0, 43, 80, 0.55);
                         position: absolute;
                         top: 3px;
                         left: 3px;
                         z-index: 999;
                     }
                 }

                 span {
                     font-family: "UniNeueBook";
                     font-weight: 400;
                     font-size: 24px;
                     line-height: 36px;
                     font-weight: bold;
                     color: #FFFFFF;
                     position: absolute;
                     z-index: 1000;
                 }

                 img {
                     width: 54px;
                     height: 54px;
                     border-radius: 50%;
                     position: absolute;
                     z-index: 998;
                 }
             }

             .text {
                 margin-right: 15px;
                 p {
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 19px;
                     color: #F12B2C;
                     margin: 0;
                 }
                 span {
                     font-weight: 500;
                     font-size: 14px;
                     line-height: 19px;
                     color: #364F6A;
                 }
             }
         }

        &__right {
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 16px;
                font-size: 14px;
                line-height: 14px;
                color: #fff;
                background-color: #26A9F4;
                height: 40px;
                border-radius: 25px;

                &:hover {
                    background: #29a6ed;
                }
            }
        }
    }
    @mixin timer($item, $duration, $size, $color, $border, $hover: running) {

        #{$item} {
            width: $size;
            height: $size;
        }

        #{$item} .pie {
            width: 50%;
            height: 100%;
            transform-origin: 100% 50%;
            position: absolute;
            background: #F12B2C;
        }

        #{$item} .spinner {
            border-radius: 100% 0 0 100% / 50% 0 0 50%;
            z-index: 200;
            border-right: none;
            animation: rota $duration + s linear 1;
            animation-fill-mode: forwards;
        }

        #{$item}:hover .spinner,
        #{$item}:hover .filler,
        #{$item}:hover .mask {
            animation-play-state: $hover;
        }

        #{$item} .filler {
            border-radius: 0 100% 100% 0 / 0 50% 50% 0;
            left: 50%;
            opacity: 0;
            z-index: 100;
            animation: opa $duration + s steps(1,end) 1 reverse;
            animation-fill-mode: forwards;
            border-left: none;
        }

        #{$item} .mask {
            border-radius: 20px 0 0 20px;
            width: 50%;
            height: 100%;
            position: absolute;
            background: inherit;
            opacity: 1;
            z-index: 300;
            animation: opa $duration + s steps(1,end) 1;
            animation-fill-mode: forwards;
        }

        @keyframes rota {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        @keyframes opa {
            0% { opacity: 1; }
            50%, 100% { opacity: 0; }
        }
    }

    @include timer('.wrapper', 60, 84px, #08C, '5px solid rgba(0,0,0,0.5)');
</style>