export default {
    state: {
        modal_auth: (window.reset) ? {'password_reset': window.reset} : null,
        auth: null,
        next_page: true,
        modal_next: null,
        countries: null,
        nav_search: false,
        seo: JSON.parse(window.seo),
        updateMess: false,
        connect: null,
        template: (localStorage.template) ? localStorage.template : 'dark',
        ready_play: {},
        connectionStable: 1
    },
    getters: {
        getSeo(state){
            return state.seo;
        },
        getConnect(state){
            return state.connect;
        },
        getTheme(state) {
            return state.template;
        },
        getReadyToPlay(state) {
            return state.ready_play;
        },
        getConnectionStable(state) {
            return state.connectionStable;
        }
    },
    actions: {
        asyncAuth(context, payload) {
            context.commit('updateAuth', payload);
        },

        asyncModalAuth(context, payload) {
            context.commit('updateModalAuth', payload);
        },
        asyncCountries(context, payload) {
            context.commit('updateCountries', payload.data);
        },
        asyncConnect(context, payload) {
            context.commit('updateConnect', payload);
        },
        asyncReadyToPlay(context, payload) {
            context.commit('updateReadyToPlay', payload);
        },
        asyncConnectionStable(context, payload) {
            context.commit('updateConnectionStable', payload);
        },
    },
    mutations: {
        updateMess(state, data) {
            this.state.preference.updateMess= data;
        },
        updateAuth(state, data) {
            this.state.preference.auth= data;
        },
        updateModalAuth(state, data) {
            this.state.preference.modal_auth= data;
        },
        updateNextPage(state, data) {
            this.state.preference.next_page= data;
        },
        updateModalNext(state, data) {
            this.state.preference.modal_next= data;
        },
        updateCountries(state, data) {
            this.state.preference.countries= data;
        },
        updateSearch(state, data) {
            this.state.preference.nav_search= data;
        },
        updateConnect(state, data) {
            this.state.preference.connect= data;
        },
        updateTheme(state, data) {
            this.state.preference.template = data;
        },
        updateReadyToPlay(state, data) {
            this.state.preference.ready_play = data;
        },
        updateConnectionStable(state, data) {
            this.state.preference.connectionStable = data;
        }
    }
}
