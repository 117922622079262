export default function auth ({ next, store }){
    if(!store.getters.authUser && !window.userAuth){
        return next({
            name: 'auth',
            params: {'page': 'login'}
        });
    }
    
    return next();
}
