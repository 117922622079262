<template>
  <div class="nft-all">
    <NftHeader></NftHeader>
    <div class="dashboard-content">
      <router-view></router-view>
    </div>
  </div>
</template>


<script>
  import NftHeader from "./Nft/NftHeader";
export default {
  name: "Nft",
  data() {
    return {

    };
  },

  watch: {

  },
  created() {

  },
  computed: {

  },
  methods: {

  },
  components: {
    NftHeader
  },
};
</script>