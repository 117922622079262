<template>
    <div>
        <div class="wrap-name">
        <span class="information-icon information-icon--name">
            <img width="16px" src="/dist/images/frontend/information-name.svg" alt />
        </span>
            <InputLabelTextDefault
                    :label='__("Application Name")'
                    color="main"
                    maxlength="50"
                    v-model="itemForm[infoTab].name"
                    v-on:input="updateInfo('name')"
                    v-bind:errors="formValidate.name"
            ></InputLabelTextDefault>
        </div>

        <div class="wrap-rules" :class="((formValidate.description && formValidate.description.length) ? 'errors' : '')">
            <span class="information-icon information-icon--rules">
         <img src="/dist/images/frontend/information-describe-icon.svg" alt />
      </span>

            <div style="width: 100%">
                <div class="wrap-rules__head">
                    <p>{{__('Description of the game')}}</p>
                    <span>Осталось {{1500 - ((itemForm[infoTab].description) ? itemForm[infoTab].description.length : 0)}} символа</span>
                </div>
                <InputLabelTextDefault
                  color="developers"
                  type="textarea"
                  maxlength="1500"
                  v-model="itemForm[infoTab].description"
                  v-on:input="updateInfo('description')"
                  v-bind:errors="formValidate.description"
                ></InputLabelTextDefault>
            </div>
        </div>

        <div class="wrap-rules mt-4 wrap-rules-b" :class="((formValidate.rules && formValidate.rules.length) ? 'errors' : '')">
      <span class="information-icon information-icon--rules">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.425 7.26433C16.2643 7.10362 15.975 7.10362 15.8143 7.26433C15.6536 7.42505 15.6536 7.71434 15.8143 7.87505L16.5214 8.58219H13.5964C13.3714 8.58219 13.1786 8.77505 13.1786 9.00005C13.1786 9.22505 13.3714 9.41791 13.5964 9.41791H16.5214L15.8143 10.1251C15.6536 10.2858 15.6536 10.5751 15.8143 10.7358C15.9107 10.8322 16.0072 10.8643 16.1036 10.8643C16.2 10.8643 16.3286 10.8322 16.3929 10.7358L17.8072 9.32148C17.9679 9.16077 17.9679 8.87148 17.8072 8.71076L16.425 7.26433Z" fill="#6886AF"/>
          <path d="M10.125 15.8143L9.41785 16.5214V13.5964C9.41785 13.3714 9.225 13.1786 9 13.1786C8.775 13.1786 8.58214 13.3714 8.58214 13.5964V16.5214L7.875 15.8143C7.71428 15.6536 7.425 15.6536 7.26428 15.8143C7.16785 15.9107 7.13571 16.0072 7.13571 16.1036C7.13571 16.2 7.16785 16.3286 7.26428 16.3929L8.67857 17.8072C8.83928 17.9679 9.12857 17.9679 9.28928 17.8072L10.7036 16.3929C10.8643 16.2322 10.8643 15.9429 10.7036 15.7822C10.575 15.6536 10.2857 15.6536 10.125 15.8143Z" fill="#6886AF"/>
          <path d="M9.28929 0.128592C9.12857 -0.0321219 8.83929 -0.0321219 8.67857 0.128592L7.26429 1.54288C7.10357 1.70359 7.10357 1.99288 7.26429 2.15359C7.425 2.31431 7.71429 2.31431 7.875 2.15359L8.58214 1.44645V4.37145C8.58214 4.59645 8.775 4.78931 9 4.78931C9.225 4.78931 9.41786 4.59645 9.41786 4.37145V1.44645L10.125 2.15359C10.2857 2.31431 10.575 2.31431 10.7357 2.15359C10.8321 2.05716 10.8643 1.96074 10.8643 1.86431C10.8643 1.76788 10.8321 1.63931 10.7357 1.57502L9.28929 0.128592Z" fill="#6886AF"/>
          <path d="M4.40358 8.58229H1.47858L2.18572 7.87515C2.34643 7.71444 2.34643 7.42515 2.18572 7.26443C2.08929 7.16801 1.99286 7.13586 1.89643 7.13586C1.8 7.13586 1.67143 7.16801 1.60715 7.26443L0.192862 8.67872C0.0321481 8.83944 0.0321481 9.12872 0.192862 9.28944L1.60715 10.7037C1.76786 10.8644 2.05715 10.8644 2.21786 10.7037C2.37858 10.543 2.37858 10.2537 2.21786 10.093L1.51072 9.38586H4.43572C4.66072 9.38586 4.85358 9.19301 4.85358 8.96801C4.82143 8.77515 4.62858 8.58229 4.40358 8.58229Z" fill="#6886AF"/>
          <path d="M9.00002 10.3178C9.72785 10.3178 10.3179 9.72782 10.3179 8.99999C10.3179 8.27215 9.72785 7.68213 9.00002 7.68213C8.27218 7.68213 7.68216 8.27215 7.68216 8.99999C7.68216 9.72782 8.27218 10.3178 9.00002 10.3178Z" fill="#6886AF"/>
        </svg>
      </span>

            <div style="width: 100%">
                <p>{{__('Game control')}}</p>
                <ckeditor
                        :editor="editor"
                        v-model="itemForm[infoTab].rules"
                        :config="editorConfig"
                        @input="updateInfo('rules')"
                >
                </ckeditor>
              <div>
                <p class="desc-error" v-for="(value, key) in formValidate.rules" :key="key">{{value}}</p>
              </div>
            </div>
        </div>
<!--        <div class="wrap-btn">-->
<!--            <div v-if="statusSubmit">-->
<!--                <BaseButton-->

<!--                        :loading="submitDisabled"-->
<!--                        v-on:click="onSubmit"-->
<!--                >-->
<!--                    <span>Save changes</span>-->
<!--                </BaseButton>-->
<!--                &lt;!&ndash; <v-btn v-if="submitDisabled" default disabled>{{__('loading')}}...</v-btn> &ndash;&gt;-->

<!--                &lt;!&ndash;      <v-btn v-else-if="validateSubmit" color="default" disabled>{{__('No validate')}}</v-btn>&ndash;&gt;-->
<!--            </div>-->

<!--            <p v-if="submitSuccess">-->
<!--                <i class="fas fa-check"></i> Saved!-->
<!--            </p>-->
<!--        </div>-->
    </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import { maxLength, minLength, required } from "vuelidate/lib/validators";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        name: "GameInfoInformations",
        data() {
            let itemForm= {};
            let form= this.$cleanObject(this.form);
            let language= this.$store.state.language.list;

            for (let i = 0; i < language.length; i++) {
                let lang= language[i].lang;
                let formLang= form[lang];
                if(formLang) {
                    itemForm[lang]= {
                        name: (formLang.name) ? formLang.name : null,
                        description: (formLang.description) ? formLang.description : null,
                        rules: (formLang.rules) ? formLang.rules : '',
                    };
                } else {
                    itemForm[lang]= {
                        name: null,
                        description: null,
                        rules: '',
                    }
                }

            }

            return {
                itemForm: itemForm,
                formValidate: {
                    name: null,
                    // rules: null,
                    description: null,
                },
                gallery: this.$sortObject(this.developers.gallery, "sort"),
                validate: this.validateMessage,
                submitDisabled: false,
                submitSuccess: false,
                validateSubmit: false,
                slideChanged:null,
                editor: ClassicEditor,
                editorConfig: {
                    removePlugins: [ 'ImageUpload', 'MediaEmbed' ],
                    // toolbar: {
                    //     items: [
                    //         'heading', '|',
                    //         'bold', 'italic', 'link', 'numberedList', 'bulletedList', '|',
                    //         'outdent', 'indent', '|','blockQuote' , '|',
                    //         'undo', 'redo', '|',
                    //     ]
                    // },
                },
                symbol: 1500
            };
        },
        props: {
            developers: {
                type: Object,
            },
            form: {
                type: Object,
            },
            infoTab: {
                type: String
            }
        },
        watch: {
            infoTab(val) {
                let valid = this.$v['itemForm'][val];

                let massages = {
                    name: [],
                    rules: [],
                    description: [],

                };
                this.formValidate = this.$formValidate(massages, valid);
            }
        },
        computed: {

        },
        validations() {
            let rules= {
                itemForm: {}
            };

            rules['itemForm'][this.infoTab]= {
                name: {
                    required,
                    maxLength: maxLength(50),
                    minLength: minLength(3),
                },
                rules: {
                    required,
                    maxLength: maxLength(100000),
                    minLength: minLength(20),
                },
                description: {
                    required,
                    maxLength: maxLength(1500),
                    minLength: minLength(20),
                },
            };
            return rules;
        },
        // computed: {
        //     statusSubmit: function () {
        //         let changed= false,
        //             description= this.developers.description;
        //         if(
        //             this.name != this.developers.name ||
        //             // this.category != this.developers.category_id ||
        //             (description && (this.rules != description.rules ||
        //                 this.description != this.developers.description /*||
        //     this.agreement != description.agreement ||
        //     this.policy != description.policy*/) || !description && this.rules)
        //         ) {
        //             changed= true;
        //         }
        //
        //         return changed;
        //     }
        // },
        methods: {
            updateInfo(type) {
              this.updateValidate(type);

              console.log("JSON.stringify(this.itemForm)");
              console.log(JSON.stringify(this.itemForm));

                this.$emit('updateForm', this.itemForm);
            },

            updateValidate(type) {
                this.itemForm[this.infoTab].invalid= this.$v['itemForm'][this.infoTab].$invalid;

                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v['itemForm'][this.infoTab][type],
                    type
                );
            },

            countGallery: function (type) {
                let gallery = this.gallery;

                let files = gallery.filter((item) => {
                    return item.type == type;
                });
                return files.length ? files.length : 0;
            },
            onSubmit: function () {
                let valid = this.$v['itemForm'][this.infoTab];

                if (valid.$invalid) {
                    let massages = {
                        name: [],
                        rules: [],
                        description: [],

                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;

                    const response = {
                        name: this.name,
                        rules: this.rules,
                        descriptions: this.description,
                    };
                    this.$http.post("ajax/developers/" + this.$route.params.id + "/info", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.submitSuccess = true;
                                let developers = this.$cleanObject(this.developers);
                                developers["name"] = this.name;
                                // developers["category_id"] = this.category;
                                developers["description"]= this.description,
                                    developers['description'] = {
                                        rules: this.rules,
                                        // agreement: this.agreement,
                                        // policy: this.policy,
                                    }
                                this.$emit("update:developers", developers);
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
                setTimeout(()=> {
                    this.submitSuccess= null;
                }, 2000);
            },
        },
        components: {
            // DefaultSlider,
            InputLabelTextDefault,
            // DropdownDefault,
            // GameInfoResumable,
        },
    };
</script>

<style lang="scss" scoped>
    .wrap-rules-b{
        position: relative;
    }
    .wrap-rules-b .desc-error{
        position: absolute;
        /* top: 0; */
        left: 50px;
        bottom: -16px;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #F25958!important;
        margin-bottom: 0;
        z-index: +1;
    }

    .dark {
        .wrap-rules {
            p {
                color: #96AFD1;
            }

            span {
                color: #96AFD1;
            }
        }
    }

    .information-icon {
        margin: 16px 28px 0 0;
        min-width: 20px;
        display: flex;
        justify-content: center;

        img,
        svg {
            filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
        }

        &--name {
        }

        &--gallery {
            margin: 0 28px 0 0;
        }

        &--rules {
            margin: 0 28px 0 0;

            img {
                padding-bottom: 100px;
                filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
            }
        }
    }

    .wrap-name {
        margin-bottom: 27px;
        display: flex;
    }

    .wrap-category {
        margin-bottom: 27px;
        display: flex;
        max-width: 400px;
        width: 100%;

        p {
            position: relative;
            z-index: 1;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: -8px;
        }
    }

    .wrap-rules {
        display: flex;
        margin: 8px 0;
        width: 100%;

        .ck.ck-editor {
            width: 100%;
        }

        p,
        span {
            z-index: 1;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: 12px;
        }

        &__head {
            display: flex;
            justify-content: space-between;
        }
    }

    .wrap-row {
        margin-bottom: 6px;

        .desc {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-left: 48px;

            a {
                color: #26a9f4;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .hide {
            display: block;
            margin-top: 12px;
        }
    }

    .wrap-gallery {
        margin-top: 18px;
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #6886AF;
            margin: 0;
        }

        .block-gallery {
            margin-left: 48px;
        }
        .block-empty {
            width: 100%;
            height: 200px;
            background: #F0F6FE;
            border-radius: 6px;
            margin: 6px auto 0;
        }
    }

    //.v-btn:not(.v-btn--round).v-size--default {
    //  height: 40px;
    //  min-width: 64px;
    //  width: 124px;
    //  padding: 0 16px;
    //}

    .wrap-btn {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 40px 0 20px;
        min-height: 40px;

        p {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 10px;
            border-color: transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #00C443;

            i {
                font-size: 12px;
                margin: 0 6px 0 20px;
            }
        }
    }
</style>
