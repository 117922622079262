<template>
  <div class="block-deleted">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5156 16.2581V19.3548C32.5156 22.3424 34.9475 24.7742 37.935 24.7742C40.9225 24.7742 43.3543 22.3424 43.3543 19.3548V16.2581H32.5156Z" fill="#FF4B55"/>
      <path d="M6.19345 34.0645C5.35046 34.0645 4.65527 33.4321 4.57551 32.5936L3.11671 20.3498C3.10348 20.2303 3.09668 20.115 3.09668 19.999C3.09668 18.3632 4.4277 17.0322 6.06341 17.0322H6.32349C7.9592 17.0322 9.29023 18.3632 9.29023 19.999C9.29023 20.115 9.28342 20.2303 9.27019 20.3456L7.80989 32.6114C7.73163 33.432 7.03645 34.0645 6.19345 34.0645Z" fill="#FF4B55"/>
      <path d="M6.19367 40.2581C4.91331 40.2581 3.87109 39.2159 3.87109 37.9355C3.87109 36.6551 4.91331 35.6129 6.19367 35.6129C7.47404 35.6129 8.51625 36.6551 8.51625 37.9355C8.51625 39.2159 7.47404 40.2581 6.19367 40.2581Z" fill="#FF4B55"/>
      <path d="M46.4516 26.3226V19.3548C46.4516 17.2205 44.715 15.4839 42.5806 15.4839H37.1613V0H8.19556L0 8.19556V43.3548H30.6124L30.7145 44.4798C30.8974 46.4864 32.5539 48 34.5699 48H41.301C43.317 48 44.9735 46.4864 45.1557 44.4798L46.3843 30.9677H46.4516C47.3056 30.9677 48 30.2733 48 29.4194V27.871C48 27.017 47.3056 26.3226 46.4516 26.3226ZM44.9032 19.3548V26.3226H30.9677V19.3548C30.9677 18.0745 32.01 17.0323 33.2903 17.0323H42.5806C43.861 17.0323 44.9032 18.0745 44.9032 19.3548ZM29.4194 30.9677H29.4866L29.9297 35.8352C28.1798 37.6599 25.7605 38.7097 23.2258 38.7097C18.1028 38.7097 13.9355 34.5423 13.9355 29.4194C13.9355 24.2964 18.1028 20.129 23.2258 20.129C25.5299 20.129 27.713 20.9769 29.4194 22.5083V26.3226C28.5654 26.3226 27.871 27.017 27.871 27.871V29.4194C27.871 30.2733 28.5654 30.9677 29.4194 30.9677ZM2.64315 7.74194L7.74194 2.64315V7.74194H2.64315ZM4.64516 9.29032H9.29032V4.64516H32.5161V12.3871H4.64516V9.29032ZM1.54839 41.8064V9.29032H3.09677V13.9355H34.0645V3.09677H9.29032V1.54839H35.6129V15.4839H33.2903C31.156 15.4839 29.4194 17.2205 29.4194 19.3548V20.5362C27.6124 19.2728 25.4686 18.5806 23.2258 18.5806C17.2489 18.5806 12.3871 23.4424 12.3871 29.4194C12.3871 35.3963 17.2489 40.2581 23.2258 40.2581C25.7514 40.2581 28.1775 39.3599 30.1055 37.7744L30.4714 41.8064H1.54839ZM43.6134 44.3388C43.5042 45.5436 42.5103 46.4516 41.301 46.4516H34.5699C33.3606 46.4516 32.3668 45.5436 32.2576 44.3388L31.0415 30.9677H44.8288L43.6134 44.3388ZM46.4516 29.4194H29.4194V27.871H46.4516V29.4194Z" fill="#DAE6F1"/>
      <path d="M10.8389 15.4839H27.8711V17.0323H10.8389V15.4839Z" fill="#DAE6F1"/>
      <path d="M15.4834 28.6451H26.3221V30.1935H15.4834V28.6451Z" fill="#DAE6F1"/>
      <path d="M17.8066 25.5484H26.3228V27.0968H17.8066V25.5484Z" fill="#DAE6F1"/>
      <path d="M17.8066 31.7419H26.3228V33.2903H17.8066V31.7419Z" fill="#DAE6F1"/>
      <path d="M19.3545 34.8387H26.3222V36.3871H19.3545V34.8387Z" fill="#DAE6F1"/>
      <path d="M19.3545 22.4516H26.3222V24H19.3545V22.4516Z" fill="#DAE6F1"/>
    </svg>

    <p>If you delete this application, you can no longer restore it!</p>
<!--    <v-btn-->

<!--      text-->
<!--      class="white&#45;&#45;text"-->
<!--      v-on:click="open_delete= true"-->
<!--    >Delete application</v-btn>-->

    <BaseButton
      class_btn="redBtn"
      v-on:click="open_delete= true"
    >
      <span>Delete application</span>
    </BaseButton>

    <ModalConfirmActionDefault
      v-if="open_delete"
      v-bind:developers="developers"
      v-bind:open="open_delete"
      text="You definitely want to delete?"
      v-bind:result.sync="result_delete"
      v-on:update:result="deleteApp()"
    ></ModalConfirmActionDefault>
  </div>
</template>

<script>
import ModalConfirmActionDefault from "../../modules/ModalConfirmActionDefault";

export default {
  name: "GameInfoDeleted",
  data() {
    return {
      // developers: null,
      categories: null,
      result_delete: null,
      open_delete: false,
    };
  },

  props: {
    developers: {
      type: Object,
    },
  },
  methods: {
    sideChange(newTab) {
      this.$router.push({
        name: "dev_info_current",
        params: { id: this.$route.params.id, current: newTab, lang: this.$store.getters.getLang},
      });
      // history.pushState({}, null, '/dashboard/profile/'+newTab);
      // this.currentTab = newTab;
    },
    pageNotFound() {
      this.$router.push({ name: "not_found", params: {lang: this.$store.getters.getLang}});
    },
    deleteApp() {
      this.open_delete = false;
      if (this.result_delete) {
        this.$http.get("ajax/developers/" + this.$route.params.id + "/delete")
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
              this.$router.push({name: "developers", params: {lang: this.$store.getters.getLang}});
            }
          })
          .catch((err) => {
            this.httpHandler(err);
          });
      }
    },
  },

  components: {
    ModalConfirmActionDefault,
  },
};
</script>
<style lang="scss" scoped>
.block-deleted {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;

  svg {
    margin-bottom: 28px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6886AF;
    margin-bottom: 27px;
    max-width: 290px;
  }

  .v-btn {
    background: #FF4B55;
    border-radius: 6px;
    text-transform: inherit;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    box-shadow: none;
    padding: 0;
    outline: none;
    transition-duration: .3s;

    &:hover {
      background-color: #e94f4e;
      transition-duration: .3s;
    }
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 150px;
    padding: 0 16px;
  }
}
</style>
