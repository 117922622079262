<template>
    <div class="wrap-controls-game">
        <v-dialog v-model="dialog" max-width="400px">
            <div class="controls-game">
                <div class="controls-game__head">
                    <div class="d-flex align-center">
                        <p>{{__('QR Code')}}</p>
                    </div>
                    <v-btn text icon class="icon" @click="dialog = false">
                        <img src="/dist/images/frontend/close.svg" alt="/" />
                    </v-btn>
                </div>
                <div class="controls-game__body">
                    <span>{{__('Open up your mobile app in your phone and scan the following QR-barcode')}}</span>
                    <div class="block-qr">
                        <VueQrcode :value="affiliate.link" :options="{ width: 180, margin: 2 }"></VueQrcode>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>

</template>
<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    export default {
        data(){
            return {
                dialog: true,
            }
        },
        props: {
            modal_qr_link: {
                type: Boolean,
            },
            affiliate: {
                type: Object,
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:modal_qr_link", false);
            },
        },
        components: {
            VueQrcode
        }
    }
</script>

<style lang="scss" scoped>

    .controls-game {
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #F0F6FE;
            border-radius: 4px 4px 0px 0px;
            padding: 20px 24px;
            position: relative;


            svg {
                margin-right: 12px;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #002b50;
                margin-bottom: 0;
            }

            .icon {
                position: absolute;
                top: 10px;
                right: -8px;
                outline: none;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__body {
            padding: 20px 15px 30px;
            background-color: #ffffff;
            border-radius: 0 0 4px 4px;
            max-height: 400px;
            overflow-y: auto;

            span {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #002b50;
                margin-bottom: 20px;
                display: block;

            }

            .block-qr {
                display: flex;
                justify-content: center;

                canvas {
                    filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
                }
            }
        }
    }
</style>