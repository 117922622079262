<template>
  <div class="wrapper" :class="scroll ? 'active' : ''">
    <div class="tabs">
      <div >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Peoples",
  data() {
    return {
      search: null,
      disabledUpdate: false
    };
  },
  props: {
    sorting: {
      type: Object,
    },
    user_search: {
      type: String,
    },
    class_search: {
      type: String,
    },
    game: {
      type: Object,
    },
    currentTab: {
      type: String
    },
    side_tabs: {
      type: Array
    },
    scroll: {
      type: Boolean,
    }

  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    updateList() {
      this.$emit('updateList',);
      this.disabledUpdate= true;

      setTimeout(()=> {
        this.disabledUpdate= false;
      }, 5000);
    },
    sideChange(newTab) {
      this.$emit('sideChange', newTab);
    },
    searchOpponent() {
      this.$emit("update:user_search", this.search);
    },

    changeClass() {
      this.$emit('changeClass')
    },

    showSorting() {
      this.$refs.sort.classList.toggle('active');
    }
  },
  components: {
  },

};
</script>

<style lang="scss" scoped>

.wrapper {
    display: flex;
    justify-content: space-between;
}

.tabs {
  width: 100%;
  padding: 0;
  //margin: 0 2px 0 0;
  //border-radius: 8px;
  height: fit-content;
}
.wrap-tabs {
  background-color: #fff;
  //border-radius: 8px 8px 0 0;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px 11px 0;
  //box-shadow: 0 2px 0 #f2f3fb;
  //width: 100%;
  border-bottom: 2px solid #ECF4FF;

  .block-left {
    width: 100%;
    position: relative;
    transform: translateY(13px);
    display: flex;
    align-items: flex-start;

    .search-img {
      height: 20px;
      cursor: pointer;
      margin-left: 20px;
      transition-duration: .3s;

      &:hover {
        //filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(15%) contrast(100%);
        transform: scale(1.1);
        transition-duration: .3s;
      }
    }
  }
}

.search {
  display: flex;
  align-items: center;
  margin-right: 10px;
  max-width: 500px;
  width: 100%;
  padding-bottom: 4px;
  margin-top: -3px;
  border-bottom: 1px solid #DAE6F1;
  transition-duration: .3s;

  &:focus-within {
    border-bottom: 1px solid #26A9F4;
    transition-duration: .3s;
  }

  &__input {
    width: 100%;
  }

  &__icon {
    margin: 17px 16px 0 0;
  }
}

.block-sorting {
  flex-shrink: 3;
  margin-left: 16px;
  max-width: 415px;
  width: 100%;
  min-width: 300px;
}

.btn-update-disabled {
  text-transform: inherit;
  padding: 10px;
  border-radius: 6px;
  transition-duration: 0.3s;
  background-color: transparent;
  //margin-right: 10px;
  outline: none;
  opacity: .4;

  &.theme--light.v-btn.v-btn--disabled {
    color: #4EBEFF!important;
  }

  &.v-btn:not(.v-btn--round).v-size--default {
    height: fit-content;
    min-width: 40px;
    padding: 0;
  }

  img {
    margin-right: 10px;
  }
}

.btn-update {
  text-transform: inherit;
  padding: 10px;
  border-radius: 6px;
  transition-duration: 0.3s;
  background-color: transparent;
  outline: none;
  color: #4EBEFF!important;

  &.v-btn:before {
    background-color: transparent;
  }

  &.v-btn:not(.v-btn--round).v-size--default {
    height: fit-content;
    min-width: 40px;
    padding: 0;
  }
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  img {
    margin-right: 10px;
  }
}


.create {
  text-transform: inherit;
  padding: 10px;
  border-radius: 6px;
  transition-duration: 0.3s;
  background-color: #4ebeff;
  width: 140px;
  outline: none;
  margin-left: 10px;

  span {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin-right: 2px;
  }

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 64px;
    // width: 124px;
    padding: 0 10px;
  }
  &:hover {
    background-color: #26A9F4;
  }
}

.v-btn.not-create {
  width: 140px;
  background-color: #F0F6FE;
  text-transform: inherit;
  padding:0 10px;
  border-radius: 6px;
  transition-duration: 0.3s;
  color: #96AFD1;
  margin-left: 10px;

  span {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    margin-right: 2px;
    color: #96AFD1;
  }

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 64px;
    // width: 124px;
    padding: 0 10px;
  }

  &:hover {
    background-color: #E7EFFF;
  }
}

.btn-sorting {
  background-color: #F0F6FE;
  text-transform: inherit;
  padding: 10px;
  width: 55px;
  border-radius: 6px;
  transition-duration: 0.3s;
  outline: none;
  margin: 0 0 8px 10px;
  display: none;
  color: #96AFD1;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 55px;
    // width: 124px;
    padding: 0 10px;
    color: #96AFD1;
  }
}

.wrap-input{
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  width: 0px;
  height: 40px;
  background-color: transparent;
  line-height: 38px;
  padding-right: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.1s ease;
}

@media screen and (max-width: 1200px) {
  .block-sorting {
    min-width: 250px;
    max-width: 250px;
  }

  .v-btn.create {
    font-size: 12px;
    width: 120px;
    span {
      font-size: 18px;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      height: 36px;
    }
  }

  .v-btn.not-create {
    font-size: 12px;
    width: 120px;

    span {
      font-size: 18px;
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      height: 36px;
    }
  }

  .block-tabs {
    margin-bottom: -17px;
    min-width: 260px;
  }

  .search-img {
    height: 16px;
  }

  .search-img.active-img {
    height: 16px;
  }

  .wrap-input {
    height: 30px;
    line-height: 27px;
  }

  .wrap-input.active {
    height: 30px;
  }
  .input {
    font-size: 12px;
    height: 30px;
    top: 0;
  }

  input.input.active {
    height: 30px;
    top: 0;
  }


  .tabs__item.default-tabs__item {
    font-size: 12px;
  }
}
  @media screen and (max-width: 1024px) {
      .block-sorting {
        border-radius: 0;
        display: none;
        transform: translateX(100%);
        position: absolute;
        right: 2px;
        top: 60px;
        /*min-width: 300px;*/
        /*max-width: 300px;*/
      }
    
    .active {
      display: block;
      transform: translateX(0%);
    }

    .tabs {
      border-radius: 6px 6px 0 0;
    }

    .btn-sorting {
      display: block;
    }
    .wrap-input.active {
      top: -14px;
    }
  }

@media screen and (max-width: 768px) {

  .wrapper-input {
    position: absolute;
    background-color: red;
    max-width: 780px;
    width: 100%;
    top: 73px;
    left: -10px;
    z-index: 100;
  }
  .wrap-input {
    border-radius: 0;
  }

  .search-img.active-img {
    transform: translateY(0);
  }

  .block-sorting {
    padding: 15px 10px;
  }
}

@media screen and (max-width: 525px) {

  .block-sorting {
    min-width: 200px;
    max-width: 200px;
    top: 54px;
    padding: 10px;
  }

  .v-btn.create,
  .v-btn.btn-sorting,
  .v-btn.not-create {
    font-size: 8px;

    span {
      font-size: 14px;
    }

    &.v-btn:not(.v-btn--round).v-size--default {
      height: 30px;
    }
  }
  .v-btn.create,
  .v-btn.not-create {
    max-width: 90px;
  }

  .v-btn.btn-sorting {
    width: 40px;
    margin-left: 6px;

    &.v-btn:not(.v-btn--round).v-size--default {
      height: 30px;
      min-width: 40px;
      // width: 124px;
      padding: 5px;
    }
  }
  .block-tabs {
    margin-bottom: -16px;
    min-width: 205px;
  }
  .search-img {
    margin-top: 6px;
    height: 14px;
  }

  .search-img.active-img {
    height: 14px;
  }
  .wrapper-input {
    top: 65px;

  }
}
@media screen and (max-width: 425px) {
  .v-btn.create,
  .v-btn.not-create {
    max-width: 80px;
  }

  .v-btn.btn-sorting {
    width: 30px;

    &.v-btn:not(.v-btn--round).v-size--default {
      height: 30px;
      min-width: 30px;
      // width: 124px;
      padding: 5px;
    }
  }
  .block-tabs {
    margin-bottom: -16px;
    min-width: 190px;
  }
}
</style>
