<template>
    <div class="api-page">
        <div class="left-menu">
            <div class="content-logo">
                <router-link :to="{name: 'home', params: {lang: $store.getters.getLang}}"><img src="/dist/images/frontend/LogoMP.svg" alt="mindplays"></router-link>
            </div>
            <div class="content-menu" >
                <div class="menu-top active" ref="d" v-on:click="dev">
                    <div class="name">
                        <a href="#get-developer-d">{{__('General Info')}}</a>
                    </div>
                    <v-tabs v-model="activeTabD" vertical >
                        <v-tab @click="goToAnchorD('#get-developer-d')" >{{__('Personal offer')}}</v-tab>
                        <v-tab @click="goToAnchorD('#api-info')" >{{__('API Information')}}</v-tab>
                        <v-tab @click="goToAnchorD('#error-messages')" >{{__('Error Messages')}}</v-tab>
                    </v-tabs>
                </div>

                <div class="menu-top " ref="s" v-on:click="single">
                    <div class="name">
                        <a href="#get-developers">{{__('Single game')}}</a>
                    </div>
                    <v-tabs v-model="activeTab" vertical >
                        <v-tab @click="goToAnchor('#get-developers')" >{{__('Launching')}}</v-tab>
                        <v-tab @click="goToAnchor('#get_info-game')" >{{__('Info Game')}}</v-tab>
                        <v-tab @click="goToAnchor('#close-game')">{{__('Close Game')}}</v-tab>
                        <v-tab @click="goToAnchor('#result-game')">{{__('Finish Game')}}</v-tab>
                        <v-tab @click="goToAnchor('#check-user')">{{__('Check User')}}</v-tab>
                    </v-tabs>
                </div>

                <div class="menu-top" ref="m" v-on:click="miltiA">
                    <div class="name">
                        <a href="#get-developers-m">{{__('Multiplayer game')}}</a>
                    </div>
                    <v-tabs v-model="activeTabM" vertical>
                        <v-tab @click="goToAnchorM('#get-developers-m')">{{__('Launching')}}</v-tab>
                        <v-tab @click="goToAnchorM('#get_info-game-m')">{{__('User info')}}</v-tab>
                        <v-tab @click="goToAnchorM('#start-game-m')">{{__('Start user game')}}</v-tab>
                        <v-tab @click="goToAnchorM('#close-game-m')">{{__('Close user game')}}</v-tab>
                        <v-tab @click="goToAnchorM('#finish-game-m')">{{__('Finish game')}}</v-tab>
                        <v-tab @click="goToAnchorM('#check-game-m')">{{__('Сheck user')}}</v-tab>
                    </v-tabs>
                </div>

            </div>
            <DropdownLanguages/>
        </div>
        <div ref="dev" class="content-page content-dev active">
            <div class="content-code"></div>
            <div class="content">
                <div class="overflow-hidden content-section" id="get-developers-d">
                    <h2 id="get-developer-d" class="anchor-itemD" >{{__('Personal Offer')}}</h2>
                    <p>{{__('You have experience in game development, we offer an amazing opportunity - to develop a game for the "MindPlays" platform with the possibility of receiving a stable income in the form of commissions from each game played. You can develop games for different platforms and OS. Check out our documentation and get income on a permanent basis!')}}</p>
                    <p class="desc">
                        {{__('Discover a completely new quality of gaming with MINDPLAYS')}}
                        <br />{{__('where excitement is backed up by rewards, and the game itself is an ideal hobby that allows you to make great money!')}}
                    </p>
                    <p>
                        <a class="adv">{{__('Build Your Own Permanent Passive Income - Develop a Game for MindPlays')}}</a>
                    </p>
                </div>
                <div class="overflow-hidden content-section api-info-content "  >
                    <h2 id="api-info" class="anchor-itemD" >{{__('API Information')}}</h2>
                    <ul>
                        <li>
                            <p>{{__('The base endpoint is:')}} <span class="href">{{__('https://mindplays.com/api/v1/')}}</span></p>
                        </li>
                        <li>
                            <p>{{__('All endpoints return either a JSON object or array.')}}</p>
                        </li>
                        <li>
                            <p>{{__('Data is returned in')}} <span class="href">{{__('ascending')}}</span> {{__('order. Oldest first, newest last.')}} </p>
                        </li>
                        <li>
                            <p>{{__('All time and timestamp related fields are in')}} <span class="href">{{__('milliseconds')}}.</span> </p>
                        </li>
                    </ul>
                    <h2>{{__('HTTP Return Codes')}}</h2>
                    <ul>
                        <li>
                            <p>HTTP <span>4XX</span> {{__('return codes are used for malformed requests; the issue is on the senders side.')}}</p>
                        </li>
                        <li>
                            <p>HTTP <span>403</span>{{__('return code is used when the WAF Limit (Web Application Firewall) has been violated.')}} </p>
                        </li>
                        <li>
                            <p>HTTP <span>418</span>{{__('return code is used when an IP has been auto-banned for continuing to send requests after receiving 429 codes.')}} </p>
                        </li>
                        <li>
                            <p>HTTP <span>422</span>{{__('return code validation errors')}}</p>
                        </li>
                        <li>
                            <p>HTTP <span>429</span> {{__('return code is used when breaking a request rate limit.')}}</p>
                        </li>
                        <li>
                            <p>HTTP <span>5XX</span> {{__('return codes are used for internal errors; the issue is on Mindplays side. It is important to NOT treat this as a failure operation; the execution status is UNKNOWN and could have been a success')}}.</p>
                        </li>
                    </ul>
                    <h2>{{__('General Information on Endpoints')}}</h2>
                    <ul>
                        <li>
                            <p>{{__('For')}} <span>GET</span> {{__('endpoints, parameters must be sent as a')}}  <span>query string.</span> </p>
                        </li>
                        <li>
                            <p>{{__('For')}} <span>POST</span>, <span>PUT</span>, {{__('and')}} <span>DELETE</span>{{__('endpoints, the parameters may be sent as a query string or in the request body with content type')}} <span>application/x-www-form-urlencoded</span>. {{__('You may mix parameters between both the')}}<span>query string</span> {{__('and')}} <span>request body</span> {{__('if you wish to do so.')}} </p>
                        </li>
                        <li>
                            <p>{{__('Parameters may be sent in any order.')}}</p>
                        </li>
                        <li>
                            <p>{{__('If a parameter sent in both the')}} <span>query string</span>{{__('and')}}  <span>request body</span>, {{__('the')}} <span>query string</span>{{__('parameter will be used.')}} </p>
                        </li>
                    </ul>
                </div>
                <div class="overflow-hidden content-section api-info-content error-messages">
                    <h2 id="error-messages" class="anchor-itemD" >{{__('Error Messages')}}</h2>
                    <p>{{__('Errors consist of two parts: an error status and a message. Status are universal, but messages can vary.')}}</p>
                    <ul>
                        <p class="name">{{__('422 VALIDATION')}}</p>
                        <li>
                            <p>{{__('Input validation error. We are unable to accept and process your request due to missing or incorrect data')}}</p>
                        </li>
                        <pre>
					<code class="bash">

                         <CodeEditor
                                 :wrap_code="true"
                                 width="100%"
                                 font_size="12px"
                                 v-model="validation"
                                 :hide_header="true"
                                 spellcheck="false"
                         >
                         </CodeEditor>
					</code>
				</pre>
                    </ul>
                </div>
                <div class="overflow-hidden content-section api-info-content error-messages">
                    <ul>
                        <p class="name">{{__('404 MISSING')}}</p>
                        <li>
                            <p>{{__('This error occurs when there is no created or actual data on the platform itself. For example: this match or game does not exist on the platform')}}</p>
                        </li>
                        <pre>
					<code class="bash">

                         <CodeEditor
                                 :wrap_code="true"
                                 width="100%"
                                 font_size="12px"
                                 v-model="missing"
                                 :hide_header="true"
                                 spellcheck="false"
                         >
                         </CodeEditor>
					</code>
				</pre>
                    </ul>
                </div>
                <div class="overflow-hidden content-section api-info-content error-messages">
                    <ul>
                        <p class="name">{{__('400 WRONG DATA')}}</p>
                        <li>
                            <p>{{__('The transmitted data after being processed by the MINDPLAYS platform is incorrect! A similar error occurs if the game sent an incorrect count, key, or any other data')}}</p>
                        </li>
                        <pre>
					<code class="bash">

                         <CodeEditor
                                 :wrap_code="true"
                                 width="100%"
                                 font_size="12px"
                                 v-model="wrong"
                                 :hide_header="true"
                                 spellcheck="false"
                         >
                         </CodeEditor>
					</code>
				</pre>
                    </ul>
                </div>
            </div>
            <div class="content-code"></div>
        </div>

        <div ref="single" class="content-page content-single">
            <div class="content-code"></div>
            <div class="content">
                <div class="overflow-hidden content-section" id="content-get_info-game">
                    <h2 id="get-developers" class="anchor-item">{{__('Launching a game in an iFrame')}}</h2>

                    <p>{{__('As soon as the players have confirmed their participation in the match, the MindPlays service sends the players to a page with an iFrame. The iFrame window is called with the following GET parameters:')}}</p>

                    <p><span class="red--text bold">$secret</span> - {{__('can be found on the developer page for each game it has its own')}}</p>
                    <p><span class="red--text bold">$game_id</span> - {{__('game id, it can be found on the developer page in the game settings')}}</p>

                    <h4>{{__('Get parameters')}}</h4>
                    <p class="send"><span>hash</span> md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'. $secret)</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Field</th>
                            <th>Type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>user_id</td>
                            <td>String, Number</td>
                            <td>user id in the MP system</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>String, Number</td>
                            <td>id of this room</td>
                        </tr>
                        <tr>
                            <td>battle_id</td>
                            <td>String, Number</td>
                            <td>the identifier of the given match</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>String</td>
                            <td>Hash MD5</td>
                        </tr>
                        </tbody>
                    </table>

                    <h2 id="get_info-game" class="anchor-item"> {{__('Getting information about players')}}</h2>
                    <p>{{__('As soon as the user was redirected to the game page.')}}</p>
                    <p>{{__('You need to send an API request to get information about each of the players:')}}</p>

                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/info_game</p>
                    <pre>
					<code class="bash">

                         <CodeEditor
                                 :wrap_code="true"
                                 width="100%"
                                 font_size="12px"
                                 v-model="forFevelopers"
                                 :hide_header="true"
                                 spellcheck="false"
                         >
                         </CodeEditor>
					</code>
				</pre>
                    <h4>{{__('Request parameters')}}</h4>
                    <p class="send"><span>hash</span> md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'. $secret)</p>

                    <table>
                        <thead>
                        <tr>
                            <th>Field</th>
                            <th>Type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>game_id</td>
                            <td>String, Number</td>
                            <td>can be found on the developer page for each game it has its own</td>
                        </tr>
                        <tr>
                            <td>user_id</td>
                            <td>String, Number</td>
                            <td>user id in the MP system</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>String, Number</td>
                            <td>id of this room</td>
                        </tr>
                        <tr>
                            <td>battle_id</td>
                            <td>String, Number</td>
                            <td>the identifier of the given match</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>String</td>
                            <td>Hash MD5</td>
                        </tr>
                        <tr>
                            <td>timestamp</td>
                            <td>String, Number</td>
                            <td>timestamp</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>{{__('After receiving full information about the game and the user, if the MP did not return an error. You can let the user play')}}</p>
                </div>

                <div class="overflow-hidden content-section" id="content-close-game">
                    <h2 id="close-game" class="anchor-item">{{__('Closing a match on error')}}</h2>
                    <p>{{__('If there is some kind of error in the game and the match cannot be completed.')}}</p>
                    <p>{{__('You need to send an API request to close this match')}}</p>

                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/close_game</p>
                    <pre>
					<code class="bash">
                        <CodeEditor
                                :wrap_code="true"
                                font_size="12px"
                                v-model="infoGame"
                                :hide_header="true"
                                spellcheck="false"
                        >
                         </CodeEditor>
					</code>
				</pre>
                    <h4>{{__('Request parameters')}}</h4>
                    <p class="send"><span>hash</span> md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'. $secret)</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Field</th>
                            <th>Type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>game_id</td>
                            <td>String, Number</td>
                            <td>can be found on the developer page for each game it has its own</td>
                        </tr>
                        <tr>
                            <td>user_id</td>
                            <td>String, Number</td>
                            <td>user id in the MP system</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>String, Number</td>
                            <td>id of this room</td>
                        </tr>
                        <tr>
                            <td>battle_id</td>
                            <td>String, Number</td>
                            <td>the identifier of the given match</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>String</td>
                            <td>Hash MD5</td>
                        </tr>
                        <tr>
                            <td>timestamp</td>
                            <td>String, Number</td>
                            <td>timestamp</td>
                        </tr>
                        <tr>
                            <td>comment</td>
                            <td>String</td>
                            <td>comment on why the match was canceled </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="overflow-hidden content-section" id="content-result-game">
                    <h2 id="result-game" class="anchor-item">{{__('Finishing the game in an iFrame')}}</h2>
                    <p>{{__('At the end of the game, you must send a request with the results of the match')}}</p>
                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/result_game</p>
                    <pre>
					<code class="bash">
                        <CodeEditor
                                :wrap_code="true"
                                font_size="12px"
                                v-model="finishGame"
                                :hide_header="true"
                                spellcheck="false"
                        >
                         </CodeEditor>
					</code>
				</pre>
                    <h4>{{__('Get parameters')}}</h4>
                    <p class="send"><span>hash</span> md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'. $secret)</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Field</th>
                            <th>Type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>game_id</td>
                            <td>String, Number</td>
                            <td>can be found on the developer page for each game it has its own</td>
                        </tr>
                        <tr>
                            <td>user_id</td>
                            <td>String, Number</td>
                            <td>user id in the MP system</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>String, Number</td>
                            <td>id of this room</td>
                        </tr>
                        <tr>
                            <td>battle_id</td>
                            <td>String, Number</td>
                            <td>the identifier of the given match</td>
                        </tr>
                        <tr>
                            <td>result_amount</td>
                            <td>Number</td>
                            <td>the final balance of the user after the game (the initial balance was available in the info_game request)</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>String</td>
                            <td>Hash MD5</td>
                        </tr>
                        <tr>
                            <td>start_timestamp</td>
                            <td>String, Number</td>
                            <td>Unix game start time</td>
                        </tr>
                        <tr>
                            <td>finish_timestamp</td>
                            <td>String, Number</td>
                            <td>Unix game end time</td>
                        </tr>
                        <tr>
                            <td>timestamp</td>
                            <td>String, Number</td>
                            <td>timestamp</td>
                        </tr>
                        <tr>
                            <td>data</td>
                            <td>Array</td>
                            <td>* an array of data about the actions of the current user against other players, in other words, these are all operations performed by the user and his means against other players</td>
                        </tr>
                        </tbody>
                    </table>

                    <h4>{{__('* Array example "data"')}}</h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Field</th>
                            <th>Type</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>operation_type</td>
                            <td>String, Number</td>
                            <td>
                                type of transaction
                                <ul>
                                    <li>1 - plus operation</li>
                                    <li>2 - minus</li>
                                    <li>3 - draw operation </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>String, Number</td>
                            <td>funds that have been transferred from one player to another</td>
                        </tr>
                        <tr>
                            <td>opponent_id</td>
                            <td>String, Number</td>
                            <td>enemy id in the MP system</td>
                        </tr>
                        <tr>
                            <td>comment</td>
                            <td>String</td>
                            <td>(optional)comment on the current operation</td>
                        </tr>
                        </tbody>
                    </table>
                    <p class="send">
                        {{__('Plus and minus operations during the game should be displayed in the “data” array. The total value of “result_amount” should correspond to the sum: start of the game ± operations in the array date = result_amount!')}}
                    </p>
                    <p class="send">
                        {{__('Otherwise, the game will be removed from the MindPlays catalog!')}}
                    </p>
                </div>
                <div class="overflow-hidden content-section" id="content-check-user">
                    <h2 id="check-user" class="anchor-item">{{__('Check user')}}</h2>
                    <p>{{__('MindPlays can send a POST request at any time to get information about the user')}}</p>
                    <p class="request"><span>POST</span> https://{game.url}/api/v1/check_user</p>

                    <pre>
                        <code class="bash">
                            <CodeEditor
                                    :wrap_code="true"
                                    font_size="12px"
                                    v-model="checkUser"
                                    :hide_header="true"
                                    spellcheck="false"
                            >
                         </CodeEditor>
                         </code>
                    </pre>
                </div>
            </div>
            <div class="content-code"></div>
        </div>

        <div ref="miltia" class="content-page content-multi" >
            <div class="content-code"></div>
            <div class="content">
                <div class="overflow-hidden content-section" >
                    <h2 id="get-developers-m" class="anchor-itemM">{{__('Launching a game in an iFrame')}}</h2>
                    <p>{{__('When a user joins the game, iframe is added into the link with additional parameters')}}</p>
                    <p>{{__('Parameters')}}:</p>
                    <p>user_id=159962<br />
                        room_id=11779791<br />
                        battle_id=531567<br />
                        hash=96bb157ddb07fa8ff4b42e4a2564cb60<br />
                    </p>
                    <p>{{__('Generation of hash')}}<br />
                        <span class="send">hash = md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'. $secret)</span>
                    </p>

                    <h2 id="get_info-game-m" class="anchor-itemM">{{__('User info')}}</h2>
                    <p>{{__('The user gets into wait room, if the game needs information about the user, the game can send a request ( info_user )')}}</p>
                    <p>{{__('This is just information about the user. In order to start the game a "start_game" request needs to be sent.')}}</p>
                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/multiplayer/info_user</p>
                    <pre>
					<code class="bash">
                         <CodeEditor
                                 :wrap_code="true"
                                 font_size="12px"
                                 v-model="userInfoM"
                                 spellcheck="false"
                                 :hide_header="true"
                         >
                         </CodeEditor>
                    </code>
				</pre>
                    <table>
                        <thead>
                        <tr>
                            <th>params</th>
                            <th>validation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>game_id</td>
                            <td>required, numeric</td>

                        </tr>
                        <tr>
                            <td>user_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>timestamp</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>required, string</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>{{__('timestamp - time mark')}}</p>
                    <p>{{__('Generation of hash')}}:<br />
                        <span class="send">hash = md5($game_id.':'.$user_id.':'.$timestamp.':'. $secret)</span>
                    </p>
                </div>

                <div class="overflow-hidden content-section" >
                    <h2 id="start-game-m" class="anchor-itemM">{{__('Start user game')}}</h2>
                    <p>{{__('In order to start the game, a request start_game needs to be sent, and only after the answer on each user comes in, the decision is made whether each user can or cant join the match and start playing')}}</p>
                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/multiplayer/start_game</p>
                    <pre>
					<code class="bash">
                        <CodeEditor
                                font_size="12px"
                                v-model="startUser"
                                :hide_header="true"
                                spellcheck="false"
                        >
                         </CodeEditor>
					</code>
				</pre>
                    <table>
                        <thead>
                        <tr>
                            <th>params</th>
                            <th>validation</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>game_id</td>
                                <td>required, numeric</td>

                            </tr>
                            <tr>
                                <td>users</td>
                                <td>required, array</td>
                            </tr>
                            <tr>
                                <td>room_id</td>
                                <td>required, numeric</td>
                            </tr>
                            <tr>
                                <td>timestamp</td>
                                <td>required, numeric</td>
                            </tr>
                            <tr>
                                <td>hash</td>
                                <td>required, string</td>
                            </tr>
                            </tbody>
                        </table>
                    <p>{{__('users - this is a massive user_id and their battle_id')}} <br />
                       <span>'user_id' => 'required|numeric',<br /></span>
                       <span>'battle_id' => 'required|numeric',</span>
                    </p>
                    <p>{{__('timestamp - time mark')}}</p>
                    <p>{{__('Generation of hash')}}:<br />
                        <span class="send">hash = md5($game_id.':'.$room_id.':'.$timestamp.':'. $secret)</span>
                    </p>
                    <p>{{__('In the answer you will receive a massive where you will see data on each user and a decision whether the user can play or can not play (join the match), "success" is responsible for it')}} </p>
                    <p>"success"<br />
                        <span>{{__('true - user can play')}}<br /></span>
                        <span>{{__('false - user cant play')}}</span>
                    </p>
                </div>
                <div class="overflow-hidden content-section" >
                    <h2 id="close-game-m" class="anchor-itemM">{{__('Close user game')}}</h2>
                    <p>{{__('If the player has not interacted with other players or the player had some problems like bad connection, loss of connection, the match needs to be closed')}}</p>
                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/multiplayer/close_game`</p>
                    <h4>{{__('Request parameters')}}</h4>
                    <p class="send"><span>hash</span> md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'. $secret)</p>
                    <pre>
					<code class="bash">
                        <CodeEditor
                                :wrap_code="true"
                                font_size="12px"
                                v-model="closeGame"
                                :hide_header="true"
                                spellcheck="false"
                        >
                         </CodeEditor>
					</code>
				</pre>
                    <table>
                        <thead>
                        <tr>
                            <th>params</th>
                            <th>validation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>game_id</td>
                            <td>required, numeric</td>

                        </tr>
                        <tr>
                            <td>user_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>battle_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>timestamp</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>comment</td>
                            <td>required, string</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>required, string</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="overflow-hidden content-section" >
                    <h2 id="finish-game-m" class="anchor-itemM">{{__('Finish game')}}</h2>
                    <p>{{__('After the player finishes the game, the game needs to send the match result on this exact user')}}</p>
                    <p class="request"><span>POST</span> https://mindplays.com/api/v1/multiplayer/result_game</p>

                    <pre>
                        <code class="bash">
                            <CodeEditor
                                    :wrap_code="true"
                                    font_size="12px"
                                    v-model="finishGameM"
                                    :hide_header="true"
                                    spellcheck="false"
                            >
                         </CodeEditor>
                         </code>
                    </pre>
                    <table>
                        <thead>
                        <tr>
                            <th>params</th>
                            <th>validation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>game_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>user_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>room_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>battle_id</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>timestamp</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>hash</td>
                            <td>required, string</td>
                        </tr>
                        <tr>
                            <td>result_amount</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>start_timestamp</td>
                            <td>required, numeric</td>
                        </tr>
                        <tr>
                            <td>finish_timestamp</td>
                            <td>required, numeric, gte:start_timestamp </td>
                        </tr>
                        <tr>
                            <td>data</td>
                            <td>required</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>{{__('start_timestamp - Unix-time of game start')}}</p>
                    <p>{{__('finish_timestamp - Unix-time of game end')}}</p>
                    <p>{{__('result_amount - total/result balance after the game is completed (initial balance is available through start_game request before game starts)')}}</p>
                    <p>{{__('Generation of hash')}}<br/>
                        <span class="send">hash = md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'. $secret)</span>
                    </p>
                    <p>{{__('data - * data massive on user actions towards other players, in other words these are all operations made by the user and their money towards other players')}}
                    </p>
                    <p>data = [<br/>
                        <span class="tabul">[<br/></span>
                        <span class="tabulX">{{__('operation_type - type of operation (1 - plus operation) (2 - minus operation)')}}<br/></span>
                        <span class="tabulX">{{__('amount - the amount of currency that is transferred from one player to another player')}}<br/></span>
                        <span class="tabulX">{{__('opponent_id - id of the opponent on MINDPLAYS')}}<br/></span>
                        <span class="tabulX"> {{__('battle_id - identificator of the opponents exact match')}}<br/></span>
                        <span class="tabulX">{{__('comment - comment for the ongoing operation')}}<br/></span>
                        <span class="tabul"> ],<br/></span>
                        <span class="tabul">[<br/></span>
                        <span class="tabulX">...<br/></span>
                        <span class="tabul">]<br/></span>
                        <span>]<br/></span>
                    </p>

                </div>
                <div class="overflow-hidden content-section" >
                    <h2 id="check-game-m" class="anchor-itemM">{{__('Сheck user')}}</h2>
                    <p>{{__('MindPlays can send a POST request to get info about the user')}}</p>
                    <p class="request"><span>POST</span> https:// {game.url} /api/v1/check_user`</p>

                    <pre>
                        <code class="bash">
                            <CodeEditor
                                    :wrap_code="true"
                                    font_size="12px"
                                    v-model="checkUserM"
                                    :hide_header="true"
                                    spellcheck="false"
                            >
                         </CodeEditor>
                         </code>
                    </pre>
                    <p>{{__('With parameters')}}</p>
                    <p>
                        {{__('user_id - id of user on MINDPLAYS')}}<br/>
                        {{__('game_id - can be found on the developer page, individual for each game')}}<br/>
                        {{__('room_id - exact room id')}}<br/>
                        {{__('battle_id - exact match id')}}<br/>
                        {{__('timestamp - time mark')}}<br/>
                        <span class="send">hash - md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'. $secret)</span>
                    </p>
                </div>
            </div>
            <div class="content-code"></div>
        </div>

        <div class="left-menu-mob">
            <div
                    class="overlay"
                    ref="overlay"
            >
                <nav class="overlayMenu">
                    <div class="logo" v-on:click="navToggle">
                        <router-link :to="{name: 'home'}"><img src="/dist/images/frontend/LogoMP.svg" alt="mindplays"></router-link>
                    </div>
                    <div class="menu-mob">
                        <div class="content-menu" ref="navToggle" >
                            <div class="menu-top" ref="dD" v-on:click="dev">
                                <div class="name">
                                    <a>{{__('General Info')}}</a>
                                </div>
                                <div v-on:click="navToggle">
                                    <v-tabs v-model="activeTabD" vertical  >
                                        <v-tab @click="goToAnchorD('#get-developers-d')" >{{__('Personal offer')}}</v-tab>
                                        <v-tab @click="goToAnchorD('#api-info')" >{{__('API Information')}}</v-tab>
                                        <v-tab @click="goToAnchorD('#error-messages')" >{{__('Error Messages')}}</v-tab>
                                    </v-tabs>
                                </div>

                            </div>
                            <div class="menu-top" ref="sG" v-on:click="single">
                                <div class="name">
                                    <a>{{__('Single game')}}</a>
                                </div>
                                <div v-on:click="navToggle">
                                    <v-tabs v-model="activeTab" vertical  >
                                        <v-tab  @click="goToAnchor('#get-developers')" >{{__('Launching')}}</v-tab>
                                        <v-tab @click="goToAnchor('#get_info-game')" >{{__('Info Game')}}</v-tab>
                                        <v-tab @click="goToAnchor('#close-game')">{{__('Close Game')}}</v-tab>
                                        <v-tab @click="goToAnchor('#result-game')">{{__('Finish Game')}}</v-tab>
                                        <v-tab @click="goToAnchor('#check-user')">{{__('Check User')}}</v-tab>
                                    </v-tabs>
                                </div>

                            </div>

                            <div class="menu-top" ref="mG" v-on:click="miltiA">
                                <div class="name">
                                    <a>{{__('Multiplayer game')}}</a>
                                </div>
                                <div v-on:click="navToggle">
                                    <v-tabs v-model="activeTabM" vertical >
                                        <v-tab @click="goToAnchorM('#get-developers-m')">{{__('Launching')}}</v-tab>
                                        <v-tab @click="goToAnchorM('#get_info-game-m')">{{__('User info')}}</v-tab>
                                        <v-tab @click="goToAnchorM('#start-game-m')">{{__('Start user game')}}</v-tab>
                                        <v-tab @click="goToAnchorM('#close-game-m')">{{__('Close user game')}}</v-tab>
                                        <v-tab @click="goToAnchorM('#finish-game-m')">{{__('Finish game')}}</v-tab>
                                        <v-tab @click="goToAnchorM('#check-game-m')">{{__('Сheck user')}}</v-tab>
                                    </v-tabs>
                                </div>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div class="navBurger" role="navigation" ref="navToggle" v-on:click="navToggle"></div>
        </div>

    </div>
</template>

<script>
    import DropdownLanguages from "../modules/DropdownLanguages";
    import CodeEditor from 'simple-code-editor';
    export default {
  name: 'apiPage',

    data: function() {
        return {
            activeTab: 0,
            activeTabD: 0,
            activeTabM: 0,
            activatedAnchorFlag: false,
            activatedAnchorFlagM: false,
            activatedAnchorFlagD: false,
            wrong:'' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "hash does not match"\n' +
                '  }\n' +
                '},\n'+

                '\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "Opponent id error"\n' +
                '  }\n' +
                '},\n'+

                '\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "Error single opponent game"\n' +
                '  }\n' +
                '},\n'+

                '\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "Error single income opponent game"\n' +
                '  }\n' +
                '},',
            missing:'' +
                '{\n' +
                ' "status": 404,\n' +
                ' "error": {\n' +
                '     "message": "Game not found"\n' +
                '  }\n' +
                '},\n'+

                '\n' +
                '{\n' +
                ' "status": 404,\n' +
                ' "error": {\n' +
                '     "message": "Game played not found"\n' +
                '  }\n' +
                '},',
            validation:'' +
                '{\n' +
                ' "status": 422,\n' +
                ' "error": {\n' +
                '     "message": "The game id must be a number."\n' +
                '  }\n' +
                '},\n'+

                '\n' +
                '{\n' +
                ' "status": 422,\n' +
                ' "error": {\n' +
                '     "message": "The user id field is required."\n' +
                '  }\n' +
                '},\n'+

                '\n' +
                '{\n' +
                ' "status": 422,\n' +
                ' "error": {\n' +
                '     "message": "The timestamp field is required."\n' +
                '  }\n' +
                '},',

            checkUserM:'' +
                '#POST https://mindplays.com/api/v1/multiplayer/result_game\n' +
                '\n' +
                '{\n' +
                '  "user_id": \'1\',\n' +
                '  "game_id": "1",\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1203486345\',\n' +
                '  "timestamp": \'1611161955\'\n' +
                '  "result_amount": \'0.50\'\n' +
                '  "start_timestamp": \'1598141690\'\n' +
                '  "finish_timestamp": \'1598451690\'\n' +
                '  "hash": \'f52f1935e6180545f203f470aa02983a\'\n' +
                '  "data": [\n' +
                '    {\n' +
                '       "operation_type": \'2\',\n' +
                '       "amount": "0.4",\n' +
                '       "opponent_id": \'987654321\',\n' +
                '       "battle_id": \'13123132\',\n' +
                '       "comment": \'text ....\'\n' +
                '    },\n' +
                '    {\n' +
                '       "operation_type": \'1\',\n' +
                '       "amount": "0.1",\n' +
                '       "opponent_id": \'12345678\',\n' +
                '       "battle_id": \'13123132\',\n' +
                '       "comment": \'text ....\'\n' +
                '    }\n' +
                '  ]\n' +
                '}\n' +
                '\n' +
                '# An example of the expected response if the player is still playing\n' +
                '\n' +
                '{\n' +
                '  "user_id": \'1\',\n' +
                '  "game_id": "1",\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1203486345\',\n' +
                '  "timestamp": \'1611161955\'\n' +
                '  "result_amount": null\n' +
                '  "start_timestamp": \'1598141690\'\n' +
                '  "finish_timestamp": null\n' +
                '  "hash": \'f52f1935e6180545f203f470aa02983a\'\n' +
                '  "data": null\n' +
                '}\n' +
                '\n' +
                '# Result error\n' +
                '\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "Game played not found"\n' +
                ' }\n' +
                '}',
            finishGameM:'' +
                '#POST https://mindplays.com/api/v1/multiplayer/result_game\n' +
                '\n' +
                '{\n' +
                '  "data": {\n' +
                '     "status": true,\n' +
                '     "msg": "",\n' +
                '     "game_id": \'123456\',\n' +
                '     "room_id": \'133203351\',\n' +
                '     "battle_id": \'1019136407\',\n' +
                '     "user_id": \'1\',\n' +
                '   },\n' +
                ' "status": 200,\n' +
                '\n' +
                '# Result error\n' +
                '\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "Game played not found"\n' +
                ' }\n' +
                '}',
            closeGame:'' +
                '#POST https://mindplays.com/api/v1/multiplayer/close_game\n' +
                '\n' +
                '{\n' +
                '  "data": {\n' +
                '     "status": true,\n' +
                '     "msg": "",\n' +
                '     "game_id": \'669963\',\n' +
                '     "room_id": \'1231779791\',\n' +
                '     "user_id": \'1\',\n' +
                '     "battle_id": \'1019136407\',\n' +
                '   },\n' +
                ' "auth": true,\n' +
                ' "status": 200,\n' +
                '}',
            startUser:'' +
                '#POST https://mindplays.com/api/v1/multiplayer/start_game\n' +
                '\n' +
                '{\n' +
                '  "data": {\n' +
                '      "users": [\n' +
                '         {\n' +
                '            "success": false,\n' +
                '            "msg": "The battle id field is required.",\n' +
                '            "user": "12345678",\n' +
                '            "battle_id": {\n' +
                '                "user_id": "1122334455"\n' +
                '            }\n' +
                '         },\n' +
                '         {\n' +
                '            "success": false,\n' +
                '            "msg": "The battle id field is required.",\n' +
                '            "user": {\n' +
                '                "battle_id": "dasda"\n' +
                '            },\n' +
                '            "battle_id": "dasda",\n' +
                '         },\n' +
                '         {\n' +
                '            "user": "123456789",\n' +
                '            "battle_id": "937684056",\n' +
                '            "success": true,\n' +
                '            "msg": "",\n' +
                '            "lang": "en",\n' +
                '            "pro": 0,\n' +
                '            "name": "anonymous",\n' +
                '            "photo": "https://mindplays.com/assets/users/a-1.jpg",\n' +
                '            "bg_color": "#31cc41",\n' +
                '            "location": "GB",\n' +
                '            "location_img": "https://mindplays.com/dist/images/flags/gb.png",\n' +
                '            "rank": 72,\n' +
                '            "rating_points": 11,\n' +
                '         },\n' +
                '      ],\n' +
                '      "game": {\n' +
                '         "status": true,\n' +
                '         "msg": "",\n' +
                '         "game_id": "123456",\n' +
                '         "room_id": "1234567890",\n' +
                '         "amount": "2.00000000",\n' +
                '         "game": {\n' +
                '            "attributes": [],\n' +
                '            "wallet": 2,\n' +
                '            "max_players": 3,\n' +
                '         }\n' +
                '      }\n' +
                '  },\n' +
                '  "auth": true,\n' +
                '  "status": 200\n' +
                '}',



            userInfoM:'' +
                '#POST https://mindplays.com/api/v1/multiplayer/info_user\n' +
                '\n' +
                '{\n' +
                    ' "data": {\n' +
                    '    "status": true,\n' +
                    '    "msg": "",\n' +
                    '    "game_id": "123456",\n' +
                    '    "user_id": "1122334455",\n' +
                    '    "max_players": 2,\n' +
                    '    "user": {\n' +
                    '       "lang": "en",\n' +
                    '       "name": "anonymous",\n' +
                    '       "photo": "https://mindplays.com/assets/users/a-1.jpg",\n' +
                    '       "bg_color": "#29a3a3",\n' +
                    '       "location": "GB",\n' +
                    '       "location_img": "https://mindplays.com/dist/images/flags/gb.png",\n' +
                    '       "rating_points": 5398\n' +
                    '    }\n' +
                    '  },\n' +
                    '  "auth": true,\n' +
                    '  "status": 200,\n' +
                '}',

            forFevelopers: '' +
                '#POST https://mindplays.com/api/v1/info_game\n' +
                '\n' +
                '# Query example:\n' +
                '{\n' +
                '  "game_id": 1,\n' +
                '  "user_id": \'1\',\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1611161955\',\n' +
                '  "hash": \'e566a5dde72bbc7b6d50ae2cdc2ad0eb\',\n' +
                '  "timestamp": \'1645098696\'\n' +
                '};\n' +
                '\n' +
                '# Result success example:\n' +
                '{\n' +
                ' "data": {\n' +
                '   "status": true,\n' +
                '   "msg": "",\n' +
                '   "game_id": "1",\n' +
                '   "room_id": "1804403095",\n' +
                '   "battle_id": "1611161955",\n' +
                '   "user_id": "1",\n' +
                '   "amount": "0.2",\n' +
                '   "lang": "en",\n' +
                '   "game": {\n' +
                '     "attributes": [\n' +
                '        {\n' +
                '          "key": "map",\n' +
                '          "option": "poolday"\n' +
                '        },\n' +
                '        {\n' +
                '          "key": "weapon",\n' +
                '          "option": "m_16"\n' +
                '        }\n' +
                '     ],\n' +
                '     "wallet": 1\n' +
                '   },\n' +
                '   "user": {\n' +
                '     "name": "anonymous",\n' +
                '     "photo": "https://mindplays.com/assets/users/a-1.jpg",\n' +
                '     "location": "United Kingdom",\n' +
                '     "location_img": "https://mindplays.com/dist/images/flags/de.png",\n'+
                '     "rank": 5,\n' +
                '     "rating_points": 4114\n' +
                '   }\n' +
                ' },\n' +
                ' "status": 200\n' +
                '}\n' +
                '\n' +
                '# Result error\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "hash does not match"\n' +
                ' }\n' +
                '}',


            infoGame: '' +
                '#POST https://mindplays.com/api/v1/close_game\n' +
                '\n' +
                '# Query example:\n' +
                '{\n' +
                '  "game_id": 1,\n' +
                '  "user_id": \'1\',\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1611161955\',\n' +
                '  "comment": \'Error waiting opponent\',\n' +
                '  "hash": \'e566a5dde72bbc7b6d50ae2cdc2ad0eb\',\n' +
                '  "timestamp": \'1645098696\'\n' +
                '};\n' +
                '\n' +
                '# Result success example:\n' +
                '{\n' +
                ' "data": {\n' +
                '   "status": true,\n' +
                '   "msg": "",\n' +
                '   "game_id": "1",\n' +
                '   "room_id": "1804403095",\n' +
                '   "battle_id": "1611161955",\n' +
                '   "user_id": "1",\n' +
                ' "status": 200,\n' +
                '}\n' +
                '# Result error:\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "hash does not match"\n' +
                ' }\n' +
                '}',

            finishGame: '' +
                '##POST https://mindplays.com/api/v1/result_game\n' +
                '\n' +
                '# Query example:\n' +
                '{\n' +
                '  "game_id": 1,\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1611161955\',\n' +
                '  "timestamp": \'1645098696\'\n' +
                '  "timestamp": \'1645098696\'\n' +
                '  "start_timestamp": \'1645113850\'\n' +
                '  "finish_timestamp": \'1645113897\'\n' +
                '  "hash": \'cb2033cc0ec1ede9ff62c54bb044becc\',\n' +
                '  "user_id": \'270461179\',\n' +
                '  "data": [\n' +
                '{\n' +
                '  "operation_type": \'1\',\n' +
                '  "amount": \'0.10000000\',\n' +
                '  "opponent_id": \'1770728449\',\n' +
                '  "comment": \'win\',\n' +
                '}\n' +
                '],\n' +
                '  "result_amount": \'0.4\',\n' +
                '};\n' +
                '\n' +
                '# Result success example:\n' +
                '{\n' +
                ' "data": {\n' +
                '   "status": "true",\n' +
                '   "msg": "",\n' +
                '   "game_id": "672268",\n' +
                '   "room_id": "1027284534",\n' +
                '   "battle_id": "1299795348",\n' +
                '},\n' +
                ' "status": 200,\n' +
                '}\n' +
                '# Result error:\n' +
                '{\n' +
                ' "status": 400,\n' +
                ' "error": {\n' +
                '     "message": "hash does not match"\n' +
                ' }\n' +
                '}',

            checkUser: '' +
                '#POST https://{game.url}/api/v1/check_user\n' +
                '\n' +
                '# Get params:\n' +
                '{\n' +
                '  "game_id": 1,\n' +
                '  "user_id": \'1\',\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1611161955\'\n' +
                '  "hash": \'e566a5dde72bbc7b6d50ae2cdc2ad0eb\',\n' +
                '  "timestamp": \'1645098696\'\n' +
                '};\n' +
                '\n' +
                '# An example of the expected response if the player has finished the game:\n' +
                '{\n' +
                '  "user_id": \'1\',\n' +
                '  "game_id": \'1\',\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1203486345\',\n' +
                '  "timestamp": \'1611161955\',\n' +
                '  "result_amount": \'0.50\',\n' +
                '  "start_timestamp": \'1598141690\',\n' +
                '  "finish_timestamp": \'1598451690\',\n' +
                '  "hash": \'f52f1935e6180545f203f470aa02983a\',\n' +
                '  "data": [\n' +
                '       {\n' +
                '        "operation_type": \'2\',\n' +
                '        "amount": \'0.41\',\n' +
                '        "opponent_id": \'2\',\n' +
                '        "comment": \'kill all\',\n' +
                '       }\n' +
                '   ]\n' +
                '}\n' +
                '\n' +
                '# An example of the expected response if the player is still playing\n' +
                '{\n' +
                '  "user_id": \'1\',\n' +
                '  "game_id": \'1\',\n' +
                '  "room_id": \'1804403095\',\n' +
                '  "battle_id": \'1203486345\',\n' +
                '  "timestamp": \'1611161955\',\n' +
                '  "result_amount": null,\n' +
                '  "start_timestamp": \'1598141690\',\n' +
                '  "finish_timestamp": null,\n' +
                '  "hash": \'f52f1935e6180545f203f470aa02983a\',\n' +
                '  "data": null,\n' +
                ' }\n' +
                '\n' +
                '# Example errors from the game\n' +
                '{\n' +
                ' "status": 200,\n' +
                ' "error": {\n' +
                '     "message": "hash does not match"\n' +
                ' }\n' +
                '}',
        }
  },
  watch: {

  },

    methods:{
        navToggle: function () {
            this.$refs.navToggle.classList.toggle('active');
            this.$refs.overlay.classList.toggle('open');
        },
        dev: function () {
            this.$refs.dev.classList.add('active');
            this.$refs.d.classList.add('active');
            this.$refs.dD.classList.add('active');
            this.$refs.s.classList.remove('active');
            this.$refs.sG.classList.remove('active');
            this.$refs.single.classList.remove('active');
            this.$refs.m.classList.remove('active');
            this.$refs.mG.classList.remove('active');
            this.$refs.miltia.classList.remove('active');
        },
        single: function () {
            this.$refs.single.classList.add('active');
            this.$refs.s.classList.add('active');
            this.$refs.sG.classList.add('active');
            this.$refs.dev.classList.remove('active');
            this.$refs.d.classList.remove('active');
            this.$refs.dD.classList.remove('active');
            this.$refs.m.classList.remove('active');
            this.$refs.mG.classList.remove('active');
            this.$refs.miltia.classList.remove('active');
        },
        miltiA: function () {
            this.$refs.miltia.classList.add('active');
            this.$refs.m.classList.add('active');
            this.$refs.mG.classList.add('active');
            this.$refs.s.classList.remove('active');
            this.$refs.sG.classList.remove('active');
            this.$refs.single.classList.remove('active');
            this.$refs.dev.classList.remove('active');
            this.$refs.d.classList.remove('active');
            this.$refs.dD.classList.remove('active');
        },
        goToAnchor(anchor) {
            this.activatedAnchorFlag = true;
            this.activeAnchor = anchor;
            this.$vuetify.goTo(anchor);
            setTimeout(() => {
                this.activatedAnchorFlag = false;
                this.activeAnchor = null;
            }, 1000);
        },
        changeActiveTab() {
            let scrollBlocks = document.getElementsByClassName('anchor-item');
            let rootHeight = document.documentElement.clientHeight;

            for(let i = 0; i < scrollBlocks.length; i++) {

                if (!this.activatedAnchorFlag) {
                    let positionTop = scrollBlocks[i].getBoundingClientRect().top;
                    let percentInVisible = scrollBlocks[i].getBoundingClientRect().bottom - rootHeight / 100 * 95;

                    if (positionTop >= -100 && positionTop <= 100) {
                        this.activeTab = i;
                    }

                    if (scrollBlocks[i].getBoundingClientRect().height > rootHeight) {

                        if (percentInVisible >= -100 && percentInVisible <= 100) {
                            this.activeTab = i;
                        }

                    }
                }

            }

        },
        goToAnchorM(anchor) {
            this.activatedAnchorFlag = true;
            this.activeAnchorM = anchor;
            this.$vuetify.goTo(anchor);
            setTimeout(() => {
                this.activatedAnchorFlag = false;
                this.activeAnchorM = null;
            }, 1000);
        },
        changeActiveTabM() {
            let scrollBlocks = document.getElementsByClassName('anchor-itemM');
            let rootHeight = document.documentElement.clientHeight;

            for(let i = 0; i < scrollBlocks.length; i++) {

                if (!this.activatedAnchorFlag) {
                    let positionTop = scrollBlocks[i].getBoundingClientRect().top;
                    let percentInVisible = scrollBlocks[i].getBoundingClientRect().bottom - rootHeight / 100 * 95;

                    if (positionTop >= -100 && positionTop <= 100) {
                        this.activeTabM = i;
                    }

                    if (scrollBlocks[i].getBoundingClientRect().height > rootHeight) {

                        if (percentInVisible >= -100 && percentInVisible <= 100) {
                            this.activeTabM = i;
                        }

                    }
                }

            }

        },
        goToAnchorD(anchor) {
            this.activatedAnchorFlag = true;
            this.activeAnchorD = anchor;
            this.$vuetify.goTo(anchor);
            setTimeout(() => {
                this.activatedAnchorFlag = false;
                this.activeAnchorD = null;
            }, 1000);
        },
        changeActiveTabD() {
            let scrollBlocks = document.getElementsByClassName('anchor-itemD');
            let rootHeight = document.documentElement.clientHeight;

            for(let i = 0; i < scrollBlocks.length; i++) {

                if (!this.activatedAnchorFlag) {
                    let positionTop = scrollBlocks[i].getBoundingClientRect().top;
                    let percentInVisible = scrollBlocks[i].getBoundingClientRect().bottom - rootHeight / 100 * 95;

                    if (positionTop >= -100 && positionTop <= 100) {
                        this.activeTabD = i;
                    }

                    if (scrollBlocks[i].getBoundingClientRect().height > rootHeight) {

                        if (percentInVisible >= -100 && percentInVisible <= 100) {
                            this.activeTabD = i;
                        }

                    }
                }

            }

        },

    },
  computed: {

  },
        created() {
            document.addEventListener('scroll', this.changeActiveTab);
            document.addEventListener('scroll', this.changeActiveTabM);
            document.addEventListener('scroll', this.changeActiveTabD);
        },
        destroyed() {
            document.removeEventListener('scroll', this.changeActiveTab);
            document.removeEventListener('scroll', this.changeActiveTabM);
            document.removeEventListener('scroll', this.changeActiveTabD);
        },

  mounted() {

  },
  components: {
      CodeEditor,
      DropdownLanguages
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .api-page .content p{
        line-height: 1.6;
        margin-bottom: 16px;
        font-size: 14px;
        color: #777A7A;
    }


    .api-page .atom_one_dark.hljs, .atom_one_dark .hljs{
        background-color: #323f4c!important;
    }
    .code_editor .header{
        height: 8px!important;
    }
    .v-application code{
        background-color: #343434!important;
    }
    .api-page .code_editor{
        margin-top: -30px;
    }
    .api-page .content-menu .v-tabs{
        padding: 0px 0!important;

    }
    .api-page .content-menu .v-tabs > .v-tabs-bar{
        background-color: rgb(244, 245, 248);
    }
    .api-page .content-menu .v-tab{
        color: #777A7A;
        letter-spacing: 0.025em;
        line-height: normal;
        font-size: 12px;
        text-transform: capitalize;
        justify-content: flex-start;
    }
    .api-page .content-menu .v-tabs-slider-wrapper{
        display: none;
    }
    .api-page .content-menu .v-tab--active{
        color: #00a8e3;
        background-color: #ffffff;
    }
    .api-page .menu-top.active .name a{
        color: #ffffff;
    }
    .api-page .menu-top .name a{
        padding-left: 20px;
        font-size: 16px;
        color: #ffffff;

    }
    .api-page .menu-top .v-tabs{
        display: none;
        margin: 0!important;
    }
    .api-page .menu-top.active .v-tabs{
        display: block;
        margin: 0!important;
    }

    .api-page .content-single{
        display: none;
    }
    .api-page .content-single.active{
        display: block;
    }
    .api-page .content-multi{
        display: none;
    }
    .api-page .content-multi.active{
        display: block;
    }

    .api-page li{
        display: block;
    }

    .api-page .left-menu {
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        bottom: 0;
        width: 245px;
        box-sizing: border-box;
        background-color: #343434;
        overflow-x: hidden;
        font-size: 18px;
    }





    .api-page .content-menu {
        margin: 10px auto 10px;
        padding: 0 0 10px;
        /*font-family: 'Roboto Condensed', sans-serif;*/
    }

    .api-page .content-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 28px;
    }

    .api-page .content-menu ul li {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 42px;
    }

    .api-page .content-menu ul li:hover,
    .api-page .content-menu ul li.active {
        background-color: #DCDEE9;
    }

    .api-page .content-menu ul li:hover a,
    .api-page .content-menu ul li.active a {
        color: #00a8e3;
    }

    .api-page .content-menu ul li a {
        padding: 0.5em 13.25% 0em;
        color: #777A7A;
        letter-spacing: 0.025em;
        line-height: 1.2;
        text-transform: capitalize;
    }



    .api-page .content-page {
        position: relative;
        box-sizing: border-box;
        margin-left: 245px;
        z-index: 2;
        background-color: #fff;
        min-height: 100%;
        padding-bottom: 1px;
    }

    .api-page .content-code {
        width: 50%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #343434;
        border-color: #343434;
    }

    .api-page .content {
        position: relative;
        z-index: 30;
    }

    .api-page .content h1,
    .api-page .content h2,
    .api-page .content h3,
    .api-page .content h4,
    .api-page .content h5,
    .api-page .content h6,
    .api-page .content p,
    .api-page .content table,
    .api-page .content aside,
    .api-page .content dl {
        margin-right: 50%;
        padding: 0 28px;
        box-sizing: border-box;
        display: block;
        max-width: 680px;
    }

    .api-page .content ul,
    .api-page .content ol {
        padding: 0 44px;
    }

    .api-page .content h2,
    .api-page .content h3,
    .api-page .content h4,
    .api-page .content h5,
    .api-page .content h6 {
        font-size: 15px;
        padding-top: 2.5em;
        margin-bottom: 0.8em;
        color: #034c8f;
        text-transform: uppercase;
        /*font-family: 'Roboto Condensed', sans-serif;*/
    }

    .api-page .content h2 {
        font-size: 1.333em;
    }

    .api-page .content h4 {
        color: #00a8e3;
        margin-top: 0;
        text-transform: none;
        font-size: 14px;
        margin-bottom: 0.2em;
    }

    .api-page .content-page .content p,
    .api-page .content-page .content pre {
        max-width: 680px;
    }

    .api-page .content pre,
    .api-page .content blockquote {
        color: #fff;
        padding: 0;
        margin: 0;
        width: 50%;
        float: right;
        clear: right;
        box-sizing: border-box;
    }

    .api-page .content pre code,
    .api-page .content pre {
        font-size: 12px;
        line-height: 1.5;
    }

    .api-page .content blockquote,
    .api-page .content pre,
    .api-page .content pre code {
        padding-top: 0;
        margin-top: 0;
    }

    .api-page .content pre code {
        margin-top: -2em;
    }

    .api-page .content table {
        font-size: 0.825em;
        margin-bottom: 1.5em;
        border-collapse: collapse;
        border-spacing: 0;
    }

    .api-page .content table tr:last-child {
        border-bottom: 1px solid #ccc;
    }

    .api-page .content table th {
        font-size: 0.925em;
        padding: 5px 18px 5px 0;
        border-bottom: 1px solid #ccc;
        vertical-align: bottom;
        text-align: left;
        line-height: 1.6;
    }

    .api-page .content table td {
        padding: 5px 18px 5px 0;
        text-align: left;
        vertical-align: top;
        line-height: 1.6;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #777A7A;
    }



    .api-page body.one-content-column-version .content h1,
    .api-page body.one-content-column-version .content h2,
    .api-page  body.one-content-column-version .content h3,
    .api-page body.one-content-column-version .content h4,
    .api-page  body.one-content-column-version .content h5,
    .api-page body.one-content-column-version .content h6,
    .api-page body.one-content-column-version .content p,
    .api-page  body.one-content-column-version .content table,
    .api-page body.one-content-column-version .content ul,
    .api-page body.one-content-column-version .content ol,
    .api-page body.one-content-column-version .content aside,
    .api-page body.one-content-column-version .content dl {
        margin-right: 0;
        max-width: 100%;
    }

    .api-page body.one-content-column-version .content-page .content p,
    .api-page body.one-content-column-version .content-page .content pre {
        max-width: 100%;
    }

    .api-page body.one-content-column-version .content-page {
        background-color: #323f4c;
    }

    .api-page body.one-content-column-version .content h1:first-child,
    .api-page body.one-content-column-version .content h2,
    .api-page body.one-content-column-version .content h3,
    .api-page body.one-content-column-version .content h4,
    .api-page body.one-content-column-version .content h5,
    .api-page body.one-content-column-version .content h6 {
        color: #59C3C3;
    }

    .api-page body.one-content-column-version p {
        color: #D6F0F0;
    }

    .api-page body.one-content-column-version .content table td {
        color: #D6F0F0;
    }

    .api-page body.one-content-column-version .content thead {
        color: #417179;
    }



    @media only screen and (max-width:980px) {

        .api-page .content h1,
        .api-page .content h2,
        .api-page .content h3,
        .api-page .content h4,
        .api-page .content h5,
        .api-page .content h6,
        .api-page .content p,
        .api-page .content table,
        .api-page .content ul,
        .api-page .content ol,
        .api-page .content aside,
        .api-page .content dl {
            margin-right: 0;
        }

        .api-page .content-code {
            display: none;
        }

        .api-page .content pre,
        .api-page .content blockquote {
            margin: 20px 0;
            padding: 15px 0px 0px;
            display: block;
            width: auto;
            float: none;
        }

        .api-page .content pre code {
            margin-top: 0;
        }
    }

    @media only screen and (max-width:680px) {
        .api-page .left-menu {
            position: relative;
            width: auto;
        }

        .api-page .left-menu .content-menu {
            display: none;
        }

        .api-page .content-page {
            margin-left: 0;
        }
    }



    .api-page .cf:before,
    .api-page .cf:after {
        content: "";
        display: block;
    }

    .api-page .cf:after {
        clear: both;
    }

    .api-page .ie6 .cf {
        zoom: 1
    }

    .api-page .content table td.text-bold {
        font-size: 0.925em;
        font-weight: 400;
        color: #000;
        border-bottom: 1px solid #ccc;
    }

    .api-page .text-bold {
        font-weight: 400;
    }

    .api-page .content table .text-center,
    .api-page .text-center {
        text-align: center;
    }

    .api-page .request {
        color: #1bad1b;
    }

    .api-page .send {
        color: #ad1b1b;
    }

    .api-page .languages{
        border-top: 1px solid #DCDEE9;
        text-align: center;
        padding-top: 15px;
        margin-top: 10px;
    }
    .api-page .languages .btn {
        padding: 3px 5px 2px;
        background: #a6bcd2;
        color: #fff;
        border-radius: 3px;
        text-decoration: none;
        font-weight: 400;
    }
    .api-page .languages .btn.active {
        background: none;
        color: #000;
    }

    .api-page .content-logo a {
        text-decoration: none;
        color: #fff;
        font-size: 12px;
    }


    /*.hljs {*/
    /*    display:block;*/
    /*    overflow-x:auto;*/
    /*    padding:0.5em;*/
    /*    background:#323F4C;*/
    /*}*/
    /*.hljs {*/
    /*    color:#fff;*/
    /*}*/
    /*.hljs-strong,.hljs-emphasis {*/
    /*    color:#a8a8a2*/
    /*}*/
    /*.hljs-bullet,.hljs-quote,.hljs-link,.hljs-number,.hljs-regexp,.hljs-literal {*/
    /*    color:#6896ba*/
    /*}*/
    /*.hljs-code,.hljs-selector-class {*/
    /*    color:#a6e22e*/
    /*}*/
    /*.hljs-emphasis {*/
    /*    font-style:italic*/
    /*}*/
    /*.hljs-keyword,.hljs-selector-tag,.hljs-section,.hljs-attribute,.hljs-name,.hljs-variable {*/
    /*    color:#cb7832*/
    /*}*/
    /*.hljs-params {*/
    /*    color:#b9b9b9*/
    /*}*/
    /*.hljs-string {*/
    /*    color:#6a8759*/
    /*}*/
    /*.hljs-subst,.hljs-type,.hljs-built_in,.hljs-builtin-name,.hljs-symbol,.hljs-selector-id,.hljs-selector-attr,.hljs-selector-pseudo,.hljs-template-tag,.hljs-template-variable,.hljs-addition {*/
    /*    color:#e0c46c*/
    /*}*/
    /*.hljs-comment,.hljs-deletion,.hljs-meta {*/
    /*    color:#7f7f7f*/
    /*}*/

    .navBurger {
        display: none;
        position: absolute;
        left: 20px;
        top: 34px;
        cursor: pointer;
        background: #ffffff;
        box-sizing: border-box;
        background-clip: content-box;
        width: 20px;
        padding-left: 0px;
        padding-right: 0px;
        height: 15px;
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        /*-webkit-transition: all 0.1s ease-in-out;*/
        /*transition: all 0.1s ease-in-out;*/
        z-index: 111;
    }
    .navBurger:before, .navBurger:after {
        content: "";
        position: absolute;
        background: #ffffff;
        height: 2px;
        width: 20px;
        left: 0px;
        will-change: rotate;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.1s ease;
        transition: all 0.1s ease;
    }
    .navBurger:before {
        top: -6px;
    }
    .navBurger:after {
        bottom: -6px;
    }
    .navBurger.active {
        margin-top: 0;
        left: 20px;
        top: 34px;
        position: fixed;
        background: transparent;
        background-clip: content-box;
    }
    .navBurger.active:before {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 0px;
        background: #ffffff;
        width: 100%;
    }
    .navBurger.active:after {
        bottom: 0px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #ffffff;
        width: 100%;
    }
    .overlay {
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform-origin: left;
        transform: scale(0);
        visibility: hidden;
        transition: all 0.2s ease-in-out;
        z-index: 111;
        background: #323e4c;
    }
    .overlay.open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
    .overlay .menu{
        margin-top: 10px;
        text-align: center;
    }
    .overlay .menu a{
        display: block;
        font-size: 12px;
    }
    .left-menu-mob{

    }
    .overlay .logo {
        max-width: 180px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
    }

    @media only screen and (max-width : 680px){
        .navBurger{
            display: block;
        }


    }


</style>
