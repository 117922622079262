<template>
  <div>
    <div class="list"
         v-on:click="(message.deleted || !messages_edit) ? '' : edit= !edit"
         :class="(edit && !message.deleted) ? 'check' : ''"
         @contextmenu="contextmenu"
         v-on:dblclick="resendMessages"
         ref="message"
         style="white-space: pre-line;"
    >
      <!--    <v-list color="transparent" class="pa-0" v-if="!group_messages[index-1] || group_messages[index-1].id== message.id">-->
      <!--      <v-list-item class="px-0">-->
      <!--        <div class="wrap-img">-->
      <!--          <div class="block-img">-->
      <!--            <img v-if="user.photo" :src="'/assets/users/'+user.photo" />-->
      <!--            <span v-else class="photo" :style="{'background': user.bg_color}">{{user.name.substr(0,1)}}</span>-->
      <!--          </div>-->
      <!--          <div class="online" v-if="!user.online">online</div>-->
      <!--        </div>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>{{user.name +' '+((user.last_name) ? user.last_name : '')}}</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->
      <!--    </v-list>-->
      <div class="send-my" v-if="message.opp_type== 1">
        <div class="send-del lighten-1" v-if="message.deleted">
          {{__('Removed')}}
        </div>
        <div v-else>
          <div v-if="message.resend_id" class="resend">
            <p v-if="message.user">Переслано от: <router-link :to="{name: 'profile', params: {id: message.user.public_id, lang: $store.getters.getLang}}">{{message.user.name+" "+message.user.last_name}}</router-link></p>
            <p v-else>Переслано от: {{(message.resend_id== 'support') ? 'Support center' : 'System notice'}}</p>
            <div v-if="message.resend" >{{decodeEmoji(message.resend.message)}} <span>{{message.resend.created_at}}</span></div>
            <div v-else>{{decodeEmoji(message.message)}} <span>{{message.message_at}}</span></div>
          </div>
          <div v-if="!message.resend_id || message.resend_id && message.resend">{{decodeEmoji(message.message)}}</div>
        </div>
        <div class=" d-flex">
          <span class="time">{{message.created_at | formatTime}}</span>
          <!--          <input type="checkbox"  class="send-input" />-->
          <span class="body-send" v-if="message.read== 0"><i class="fas fa-check"></i></span>
          <span class="body-send" v-else><img src="/dist/images/frontend/all-done.svg" alt=""></span>
        </div>
        <div class="wrap-checkbox d-flex edit-checkbox">
          <v-checkbox class="send-input" color="blue" :value="edit" v-if="!message.deleted && messages_edit"></v-checkbox>
        </div>
      </div>
      <div class="body-left" v-else>
        <div class="send">
          <span class="body-send" v-if="message.read== 0"><i class="fas fa-check"></i></span>
          <span class="body-send" v-else><img src="/dist/images/frontend/all-done.svg" alt=""></span>
          <div class="wrap-checkbox d-flex checkbox-edit">
            <v-checkbox class="send-input" color="blue" :value="edit" v-if="!message.deleted && messages_edit"></v-checkbox>
          </div>
            <span class="time">{{message.created_at | formatTime}}</span>
        </div>
        <div class="send-delete lighten-1" v-if="message.deleted">
          {{__('Removed')}}
        </div>
        <div class="my-send" v-else>
          <div v-if="message.resend_id" class="resend">
            <p v-if="message.user"><router-link :to="{name: 'profile', params: {id: message.user.public_id, lang: $store.getters.getLang}}">{{message.user.name+" "+message.user.last_name}}</router-link></p>
            <p v-else>{{__('Forwarded from:')}} {{(message.resend_id== 'support') ? 'Support center' : 'System notice'}}</p>
            <div v-if="message.resend">{{decodeEmoji(message.resend.message)}} <span>{{message.resend.created_at}}</span></div>
            <div v-else>{{decodeEmoji(message.message)}} <span>{{message.message_at}}</span></div>
          </div>
          <div v-if="!message.resend_id || message.resend_id && message.resend">{{decodeEmoji(message.message)}}</div>
        </div>
      </div>
    </div>
    <v-menu

      v-model="show_menu"
      absolute
      :position-x="x"
      :position-y="y"
      offset-y
    >
      <v-list class="messenger-drop">
        <v-list-item
          v-for="(option, key) in ((message.opp_type==1) ? options_list.user : options_list.auth)"
          :key="key"
          @click="action(option)"
        >
          <span>{{option.name}}</span>
        </v-list-item>
      </v-list>
    </v-menu>
    <ModalForward v-if="modal_forward" v-bind:edit_list="[message.id]" v-bind:modal_cancel.sync="modal_forward" @openMessages="openMessages"></ModalForward>
    <ModalDefault class_name="del-mess" v-if="modal_deleted" v-bind:modal_cancel.sync="modal_deleted">
      <div class="text-center" >
        <div><h2>{{__('Are you sure you want to delete the post?')}}</h2></div>
        <div class="text-center text-window">
          <v-checkbox
            v-model="deleted"
            v-if="!(['notice', 'support']).includes(user.to)"
          >
            <template v-slot:label>
              <div class="check-text">
                {{__('Delete from me and from')}} {{user.name}}
              </div>
            </template>
          </v-checkbox>
          <p>{{__('This action cannot be undone!')}}</p>
          <div class="btn-window">
            <v-btn class="btn-info-ok btn-del" v-on:click="deletedMessage">{{__('Delete')}}</v-btn>
            <v-btn class="btn-info-ok btn-secondary" v-on:click="modal_deleted= false">{{__('Cancel')}}</v-btn>
          </div>
        </div>
      </div>
    </ModalDefault>
  </div>
</template>

<script>
    import ModalForward from './ModalForward';
    import ModalDefault from "../../modules/ModalDefault";

    import { emojis } from 'vue-chat-emoji'

    export default {
        name: 'MessengerMessagesListItem',
        data () {
            let forward= [
                {
                    name: this.__('Forward'),
                    value: 'forward'
                },
                {
                    name: this.__('Deleted'),
                    value: 'deleted'
                },
            ],
                message= [
                    {
                        name: this.__('Edit'),
                        value: 'edit_message',
                    },
                    {
                        name: this.__('Forward'),
                        value: 'forward'
                    },
                    {
                        name: this.__('Deleted'),
                        value: 'deleted'
                    },
                ];


            return {
                modal_forward: false,
                edit: false,
                show_menu: false,
                deleted: false,
                x: 0,
                y: 0,
                modal_deleted: false,
                options_list: {
                    user:   [
                        {
                            name: this.__('Forward'),
                            value: 'forward'
                        },
                        // {
                        //     name: 'Жалоба',
                        //     value: 'appeal'
                        // },
                        {
                            name: this.__('Deleted'),
                            value: 'deleted'
                        },
                    ],
                    auth: (((this.message.message_id!= null) || (this.message.resend_id== null && this.message.message_id== null)) ? message : forward),
                }
            }
        },
        props: {
            user: {
                type: Object
            },
            message: {
                type: Object
            },
            index: {
                type: Number
            },
            options: {
                type: Object
            },
            messages_edit: {
                type: Boolean
            },
            context_id: {
                type: Number
            }
        },
        watch: {
            edit(val) {
                this.$emit('editMessages', this.message.id, val);
            },
            messages_edit(val) {
                if(!val) {
                    this.edit= false
                }
            },
            context_id(val) {
                if(val != this.message.id) {
                    this.show_menu= false;
                }
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            },
        },
        asyncComputed: {

        },
        created() {

        },
        methods: {
            resendMessages() {
                let message= this.message;
                if(!message.deleted) {
                    let option= {
                        name: 'Resend',
                        value: 'resend_message',
                        message: message,
                        user: (message.opp_type== 1) ? this.user : this.auth
                    };
                    this.$emit('actionOptions', option);
                }
            },
            openMessages() {
                this.modal_forward= false;
            },
            contextmenu(e) {
                e.preventDefault();
                if(!this.message.deleted && !this.messages_edit) {
                    this.show_menu= false;
                    this.x = e.clientX;
                    this.y = e.clientY;
                    this.$emit('openContextmenu', this.message.id);
                    this.$nextTick(() => {
                        this.show_menu = true
                    })
                } else {
                    this.$emit('openContextmenu', null);
                }
            },
            action(option) {
                this.menu = false;
                option.message= this.message;
                switch (option.value) {
                    case 'edit_message':
                        this.$emit('actionOptions', option);
                    break;
                    case 'forward':
                        this.modal_forward= true;
                        break;
                    case 'deleted':
                        this.modal_deleted= true;
                    break;
                }
            },
            deletedMessage() {
                let option= {
                    value: 'deleted_message',
                    message: [this.message.id],
                    deleted: this.deleted
                };
                this.deleted= false;
                this.$emit('actionOptions', option);
                this.modal_deleted= false;
            },
            decodeEmoji(mess) {
                return emojis.decodeEmoji(mess);
            },
        },
        components: {
            ModalDefault,
            ModalForward
        },

    }
</script>

<style>
  .resend {
    padding-left: 10px;
  }
</style>