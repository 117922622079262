<template>
  <v-dialog v-model="dialog" max-width="500px">
    <div class="reviews-game">
      <div class="reviews-game__head">
        <p>{{__('All reviews')}}</p>
        <div class="wrap-btn">
          <v-btn text icon class="icon" @click="dialog = false">
            <img src="/dist/images/frontend/close.svg" alt="/" />
          </v-btn>
        </div>
      </div>
      <div class="reviews-game__body" @scroll="scrollReviews" ref="reviews">
        <div v-if="comments && comments.data.length" class="wrapper-reviews">
          <ReviewsItem v-for="(item, key) in comments.data" :key="key" :item="item"></ReviewsItem>
        </div>
        <div v-else class="reviews-game-result">{{__('no result')}}</div>
      </div>
    </div>
  </v-dialog>
</template>
<script>

import ReviewsItem from "./ReviewsItem";

export default {
  name:"ModalAllReviews",
  data(){
    return {
      dialog: true,
      loadingPage: false,
      next_page_url: this.comments.next_page_url,
    }
  },
  props: {
    all_reviews_modal: {
      type: Boolean,
    },
    comments: {
      type: Object,
    },

  },
  watch: {
    dialog() {
      this.cancelModal();
    },
  },
  methods: {
    cancelModal() {
      this.$emit("update:all_reviews_modal", false);
    },

    scrollReviews: function () {
      let reviews = this.$refs.reviews;
      const progress = reviews.scrollTop / (reviews.scrollHeight - reviews.clientHeight);
      if (!this.loadingPage && this.next_page_url) {
        let bottomOfWindow = 0.7 <= progress;
        if (bottomOfWindow) {
          this.loadingPage = true;
          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get'](this.next_page_url)
              .then((response) => {
                return response.json();
              })
              .then((response) => {
                if(this.$checkAuth(response)) {
                    response = response.data;
                    let comments = this.comments.data;
                    // this.payments = response.data.payments;
                    // this.histories = response.histories;
                    this.comments.data = comments.concat(response.data);
                    this.next_page_url = response.next_page_url;
                    this.loadingPage = false;
                }
              })
              .catch((err) => {
                this.httpHandler(err);
              });
        }
      }
    },
  },
  components: {
    ReviewsItem
  }
}
</script>

<style lang="scss" scoped>

  .dark {
    .reviews-game {
      &__head {
        background-color: #171827;
        border-bottom: 1px solid #2B2B2B;

        p {
          color: #fff;
        }
      }

      &__body {
        background-color: #171827;
      }
    }
  }

.reviews-game {

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #F0F6FE;
    border-radius: 8px 8px 0px 0px;
    padding: 20px 24px;
    position: relative;

    .wrap-btn {
      position: absolute;
      top: 10px;
      right: -2px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #002b50;
      margin-bottom: 0;
    }
  }

  &__body {
    //padding: 16px 24px 27px;
    max-height: 400px;
    overflow: auto;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
  }
}
</style>