<template>
    <li class="list">
        <div class="top-list">
            <div>
                <div class="number">
                    {{Number(position) + 1}}
                </div>
                <div class="img">
                    <router-link :to="{name: 'profile', params: {id: item.public_id, lang: $store.getters.getLang}}" class="">
                        <div class="wrap-img" :class="(item.pro) ? 'user-pro-img' : ''">
                            <img class="img-img" v-if="item.photo && typeof item.photo == 'object'" :src="item.photo[40]">
                            <img class="img-img"  v-else-if="item.photo" :src="item.photo">
                            <span v-else class="photo" :style="{'background': item.bg_color}">{{item.name.substr(0,1)}}</span>
                            <div v-if="now_date < date" class="online"></div>
                        </div>
                    </router-link>
                </div>
                <div class="user-name">
                    <p class="name">
                        <router-link :to="{name: 'profile', params: {id: item.public_id, lang: $store.getters.getLang}}" :class="(item.pro) ? 'user-pro' : ''" class="mb-0">{{item.name}}</router-link>
                    </p>
                    <div class="user">
                        <div>
                            <img  src="/dist/images/frontend/fire.svg" alt />
                            <p class="stat">+{{item.rating_points}}</p>
                        </div>
                        <div>
                            <img class="flag" :src="item.location_img">
                            <p class="country">{{$getLocation(item.location)}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.subscribe== 0 && auth">
                <BaseButton class_btn="btn-liteGrey" v-on:click="sendSubscribe(2)" :loading="submitDisabled">
                    <span>{{__('Cancel request')}}</span>
                </BaseButton>
            </div>

            <div v-else-if="item.subscribe== 1 && auth">
                <BaseButton class_btn="btn-liteGrey"   v-on:click="sendSubscribe(2)" :loading="submitDisabled">
                    <span>{{__('Unfriend')}}</span>
                </BaseButton>
            </div>

            <div v-else-if="item.subscribe== 2 && auth">
                <BaseButton  v-on:click="sendSubscribe(0)"  :loading="submitDisabled">
                    <span>{{__('Add friend')}}</span>
                </BaseButton>
            </div>

            <div v-else-if="item.subscribe== 3 && auth">
                <BaseButton  v-on:click="sendSubscribe(1)"  :loading="submitDisabled">
                    <span>{{__('Confirm request')}}</span>
                </BaseButton>
            </div>

            <div v-else-if="!auth">
                <BaseButton  v-on:click="pushRegister">
                    <span>{{__('Add friend')}}</span>
                </BaseButton>
            </div>
            <div v-else>

            </div>
        </div>
        <div class="body-list">
            <div v-if="item.status== 3" class="body-failed">
                <progress style="width: 100%" :value="item.progress" :max="quest.progress_end"></progress>
            </div>
            <div v-else>
                <progress style="width: 100%" :value="item.progress" :max="quest.progress_end"></progress>
            </div>
        </div>
    </li>
</template>

<script>
    import moment from "moment";

    export default {
        data() {
            return {
                submitDisabled: false,
                now_date: moment.utc(new Date()).format('X'),
                date: (this.item.online) ? moment.utc(this.item.online).format('X') : null,
            }
        },
        props: {
            item: {
                type: Object
            },
            quest: {
                type: Object
            },
            position: {
                type: [Number, String]
            }
        },
        computed:{
            auth() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');

            },
            sendSubscribe: function (type) {
                let response= {
                    type: type,
                    public_id: this.item.public_id
                };
                this.submitDisabled= true;
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/friends/subscribe", response)
                // this.$http.post("ajax/friends/subscribe", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response)=> {
                        if(this.$checkAuth(response)) {
                            // response = {
                            //     "data": {
                            //         "friends": 0,
                            //         "friend_req": 0
                            //     },
                            //     "auth": true,
                            //     "status": 200
                            // };
                            response = response.data;
                            // let user = this.$cleanObject(this.item);
                            this.item.subscribe = response.friends;
                            // this.$emit('update:item', user);
                            this.submitDisabled = false;

                            this.$store.dispatch("asyncFriendsReqCount", response.friend_req);
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled= false;
                        this.httpHandler(err);
                    });
            },
        }
    }
</script>

<style lang="scss"></style>