<template>
<div>
    <Profile v-if="auth || profile"></Profile>
    <HomeGuest v-else></HomeGuest>
  </div>
</template>

<script>
    import Profile from "./ProfileNew";
    import HomeGuest from "./HomeGuest";

    export default {
        name: 'Home',
        data () {
            return {

            }
        },
        created() {
            window.addEventListener("DOMContentLoaded", () => {
                if (window.matchMedia("(display-mode: standalone)").matches) {
                    // From browser to standalone
                    console.log('HOME: From browser to standalone - is PWA');
                } else {
                    // From standalone to browser
                    console.log('HOME: From standalone to browser - isBrowser');
                }
            });
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            },
            getLangs() {
                return this.$store.getters.getLangs
            },
            profile() {
                // if (this.$route.name !== 'login') {
                    let id = this.$route.params.id;

                    if(id) {
                        return true;
                    } else {
                        let lang= this.$route.params.lang;

                        if(lang) {
                            return this.getLangs.map(function (e) { return String(e['lang']); }).indexOf(String(lang)) < 0;
                        } else {
                            return false;
                        }
                    }
                // } else {
                //     return false
                // }
            },
        },
        asyncComputed: {

        },
        methods: {
        },
        components: {
            Profile,
            HomeGuest,
        },

    }
</script>
