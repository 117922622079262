<template>
  <div class="block-new-attribute">
    <div class="wrap-input">
      <div class="block-name">
<!--        <v-btn  v-ripple="false" icon text v-on:click="toggle= !toggle">-->
        <v-btn class="chevron-btn"  v-ripple="false" icon text v-on:click="showOption()">
          <img :class="(toggle) ? 'active-img' : ''" src="/dist/images/frontend/chevron-grey.svg" alt />
        </v-btn>
        <InputLabelTextDefault
          color="main"
          v-model="name"
          v-bind:errors="formValidate.name"
          @input="formValidate.name = null"
          @change="updateValidate('name')"
          v-on:click="showBlock()"
          :label='__("Attribute name")'
          v-on:input="updateAttributes(name,'name')"
        ></InputLabelTextDefault>
      </div>
      <div class="block-key">
        <InputLabelTextDefault
          color="main"
          v-model="key"
          v-bind:errors="formValidate.key"
          @input="formValidate.key = null"
          @change="updateValidate('key')"
          :label='__("Attribute key")'
          v-on:input="updateAttributes(key,'key')"
        ></InputLabelTextDefault>
        <v-btn class="btn-delete"  v-ripple="false" icon text v-on:click="deleteAttributes">
          <img src="/dist/images/frontend/icon-del-red.svg" alt />
        </v-btn>
      </div>
    </div>
    <div :class="(toggle) ? 'active' : ''" class="wrap-option" ref="options">
      <GameAttributesOptions v-for="(option, key) in this.attribute.options" :key="key" v-bind:index="key" v-bind:option.sync="option" v-bind:options="attribute.options" @updateOption="updateOption" @deleteOption="deleteOption"/>
      <v-btn class="add-btn" text  v-ripple="false" v-on:click="addOptions">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.99997 5.93933V12.0446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0556 8.99203H5.94446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9047 0.666626H5.0952C2.37298 0.666626 0.666626 2.59336 0.666626 5.32092V12.679C0.666626 15.4066 2.36504 17.3333 5.0952 17.3333H12.9047C15.6349 17.3333 17.3333 15.4066 17.3333 12.679V5.32092C17.3333 2.59336 15.6349 0.666626 12.9047 0.666626Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          {{__('Add option')}}
      </v-btn>
    </div>
  </div>
</template>

<script>
    import {maxLength, minLength, required} from "vuelidate/lib/validators";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import GameAttributesOptions from "./GameAttributesOptions";



    export default {
        name: 'GameAttributesItem',
        data: function() {
            return {
                name: this.attribute.name,
                key: this.attribute.key,
                formValidate: {
                    name: null,
                    key: null,
                },
                toggle: true,
                valid_option: false,
            }
        },
        props: {
            attribute: {
                type: Object
            },
            option_template: {
                type: Object
            },
            index_attr: {
                type: Number
            },
            attributes: {
                type: Array
            }
        },
        watch: {
            attribute: function (val) {
                this.name= val.name;
                this.key= val.key;
            },
        },
        validations() {
            return {
                name: {
                    required,
                    minValue: minLength(1),
                    maxValue: maxLength(100)
                },
                key: {
                    required,
                    minValue: minLength(1),
                    maxValue: maxLength(100)
                }
            }
        },
        computed: {

        },
        asyncComputed: {

        },
        methods: {
            updateValidate(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            addOptions: function () {
                let attributes= this.$cleanObject(this.attributes);
                attributes[this.index_attr].options.push(this.option_template);

                this.$emit('updateAttributes', attributes);
            },
            updateAttributes(val, type) {
                let attributes= this.$cleanObject(this.attributes);
                attributes[this.index_attr][type]= val;
                attributes[this.index_attr].invalid= this.$v.$invalid;
                this.$emit('updateAttributes', attributes);
            },
            updateOption(response, invalid) {
                let attributes= this.$cleanObject(this.attributes);
                attributes[this.index_attr].options[response.index][response.type]= response.value;
                attributes[this.index_attr].options[response.index].invalid= invalid;

                this.$emit('updateAttributes', attributes);
            },
            deleteOption(index) {
                let attributes= this.$cleanObject(this.attributes);
                attributes[this.index_attr].options.splice(index, 1);
                this.$emit('updateAttributes', attributes);
            },
            deleteAttributes() {
                this.$emit('deleteAttributes', this.index_attr);
            },
            showOption() {
              let option = this.$refs.options;
              let heightOption = 0;

              if(!this.toggle) {

                option.style.height= option.offsetHeight + 'px';
                setTimeout(()=> {
                  option.style.height='0px';
                },50);

                setTimeout(() => {
                  option.style.display = 'none';
                  option.style.height= '';
                }, 500);

              } else {
                option.style.display='block';
                heightOption = option.offsetHeight;
                option.style.display = 'none';
                option.style.height='0px';
                option.style.display='block';
                setTimeout(()=> {
                  option.style.height = heightOption + 'px';
                },50);

                setTimeout(() => {
                  option.style.height= '';
                }, 500);

              }
              this.toggle = !this.toggle;

            },
            showBlock() {
                let option = this.$refs.options;
                let heightOption = 0;

                if(this.toggle) {
                    option.style.display = 'block';
                    heightOption = option.offsetHeight;
                    option.style.display = 'none';
                    option.style.height = '0px';
                    option.style.display = 'block';
                    setTimeout(() => {
                        option.style.height = heightOption + 'px';
                    }, 50);

                    setTimeout(() => {
                        option.style.height = '';
                    }, 500);
                }
                this.toggle = false;
            }
        },
        components: {
            InputLabelTextDefault,
            GameAttributesOptions
        },

      mounted() {

      }
    }
</script>

<style lang="scss" scoped>

.dark {
    .block-name {
        .chevron-btn {
            border: 1px solid #fff;

            img {
                filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
            }
        }
    }
}

  .block-new-attribute {
    margin-bottom: 27px;
  }

  .wrap-input {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;
  }

  .block-name {
    display: flex;
    align-items: center;
    padding-right: 25px;
    width: 67%;

    .chevron-btn {
      border: 1px solid #6886AF;
      margin: 4px 28px 0 2px;

      &.v-btn--icon.v-size--default {
          height: 18px;
          width: 18px;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .block-key {
    display: flex;
    width: 34%;

      .btn-delete {
          margin: 2px 0 0 12px;
          &:hover {
              transform: scale(1.1);
          }
      }
  }

  .v-btn {
    outline: none;

    img {
      transition-duration: 0.2s;
    }
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 0;
    padding: 0 8px;
  }

  .v-btn:before {
    background-color: transparent;
  }

  .v-btn.add-btn {
    outline: none;
    text-transform: inherit;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #4EBEFF;
    /*margin: 3px 0 4px;*/
    margin: 8px 0 -8px 37px;
    transition-duration: .3s;

      svg {
          margin-right: 10px;
          transition-duration: .3s;
          border-radius: 5px;
      }

      &:hover {
          color: #26A9F4;
          transition-duration: .3s;

          svg {
              background-color: #26a9f4;
              color: #fff;
              border-radius: 5px;
              transition-duration: .3s;
          }
      }
  }

  .add {
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #4EBEFF;
    color: #4EBEFF;
    font-size: 22px;
    margin-right: 6px;
    line-height: 19px;
    transition-duration: .3s;
  }

  .wrap-option {
    overflow: hidden;
    transition: 500ms height;
    display: none;
  }

  .active {
    /*display: none;*/

  }

  .active-img {
    transform: rotate(-90deg);
    transition-duration: .2s;
  }



</style>
