<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:modal_bun', false)">
        <div class="modal-inaccessible_quest">

            <v-btn text icon class="icon ma-0" @click="cancelModal">
                <img src="/dist/images/frontend/close.svg" alt="/" />
            </v-btn>

            <div class="modal-inaccessible_quest__body">
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M40 60C40 55 37.5 52.5 36.25 51.25C35 50 30 47.5 26.25 50C26.25 46.25 23.75 42.5 20 41.25C16.25 40 11.25 41.25 11.25 41.25C11.25 26.25 20 21.25 20 21.25C20 21.25 20.185 27.2075 26.25 31.25C30 33.75 32.5 33.75 32.5 33.75V20C35.2613 20 37.5 22.2387 37.5 25H42.5C42.5 22.2387 44.7387 20 47.5 20V33.75C47.5 33.75 50 33.75 53.75 31.25C59.815 27.2075 60 21.25 60 21.25C60 21.25 68.75 26.25 68.75 41.25C68.75 41.25 63.75 40 60 41.25C56.25 42.5 53.75 46.25 53.75 50C50 47.5 45 50 43.75 51.25C42.5 52.5 40 55 40 60Z" fill="#3A4249"/>
                    <path d="M40 1.25C18.5987 1.25 1.25 18.5987 1.25 40C1.25 61.4013 18.5987 78.75 40 78.75C61.4013 78.75 78.75 61.4013 78.75 40C78.75 18.5987 61.4013 1.25 40 1.25ZM6.25 40C6.25 31.58 9.34 23.8863 14.44 17.975L62.025 65.56C56.1125 70.6588 48.42 73.75 40 73.75C21.36 73.75 6.25 58.64 6.25 40ZM65.56 62.025L17.975 14.44C23.8863 9.34125 31.5788 6.25 40 6.25C58.64 6.25 73.75 21.36 73.75 40C73.75 48.42 70.6588 56.1137 65.56 62.025Z" fill="#AA494A"/>
                </svg>

                <p>{{__('You cannot apply to participate. To participate in the tournament, more than 7 days must pass after disqualification')}}</p>
            </div>
            <div class="wrap-btn">
                <BaseButton
                    class_btn="btn-guest"
                    width="120"
                    @click="cancelModal"
                >
                    <span>{{__('Ок')}}</span>
                </BaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: 'ModalBunParticipate',
        data() {
            return {
                modal: true,
            };
        },
        props: {
            modal_bun: {
                type: Boolean,
            }

        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {},

        methods: {
            cancelModal() {
                this.$emit("update:modal_bun", false);
            },
        },
        components: {},
    };
</script>

<style lang='scss' scoped>
    .modal-inaccessible_quest {
        text-align: center;
        padding: 40px 25px;
        background: #171827;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;

        .icon {
            position: absolute;
            top: 4px;
            right: 4px;
            outline: none;

            img {
                height: 12px;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            svg {
                margin-bottom: 25px;
            }

            p {
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
                margin: 0;
            }
        }

        .wrap-btn {
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 500px) {
        .modal-inaccessible_quest {
            padding: 30px 20px;

            &__body {
                margin-bottom: 25px;
                img {
                    height: 130px;
                    margin-bottom: 20px;
                }
            }
        }
    }

</style>