<template>
    <div>
        <v-dialog v-model="dialog" max-width="320px">
            <div class="wrap-sorting">
                <!--    <div class="search">-->
                <!--      <div class="search__icon">-->
                <!--        <img src="/dist/images/frontend/search.svg" alt />-->
                <!--      </div>-->
                <!--      <div class="search__input">-->
                <!--        <InputLabelTextDefault-->
                <!--            v-model="search"-->
                <!--            @change="updateValidate('search')"-->
                <!--            v-bind:errors="formValidate.search"-->
                <!--            class_input="search-sorting"-->
                <!--            placeholder="Search..."-->
                <!--        ></InputLabelTextDefault>-->
                <!--      </div>-->
                <!--    </div>-->
                <div class="wrap-sorting-head">
                    <div class="wrap-sorting-head__icon">
                        <img src="/dist/images/frontend/filter-icon.svg" alt />
                    </div>
                    <h5>{{__('Filters')}}</h5>
                </div>
                <div class="wrap-sorting-body">
                    <!--        <div class="map">-->
                    <!--          <div class="map__icon">-->
                    <!--            <img src="/dist/images/frontend/map-icon.svg" alt />-->
                    <!--          </div>-->
                    <!--            <div class="map__input">-->
                    <!--                <InputLabelTextDefault-->
                    <!--                    label="Operation ID"-->
                    <!--                    color="main"-->
                    <!--                    v-model="operation"-->
                    <!--                    class="mr-8"-->
                    <!--                    type="number"-->
                    <!--                ></InputLabelTextDefault>-->
                    <!--            </div>-->
                    <!--        </div>-->
                    <div class="region betting mb-4">
                        <div class="betting__head">
                            <div class="result__icon">
                                <img src="/dist/images/frontend/game_result-icon.svg" alt />
                            </div>
                            <p>{{__('Result game')}}</p>
                        </div>
                        <div class="result__input">
                            <DropdownDefault
                                    style="width: 100%"
                                    color="select_color"
                                    v-bind:value.sync="income"
                                    v-bind:dropdown="sortType"
                                    @change="updateValidate('income')"
                                    v-bind:errors="formValidate.income"
                            ></DropdownDefault>
                        </div>
                    </div>
                    <div class="betting">
                        <div class="betting__head">
                            <div class="map__icon mb-1">
                                <img src="/dist/images/frontend/coin-icon.svg" alt />
                            </div>
                            <p>{{__('Coin')}}</p>
                        </div>
                        <v-radio-group row class="wrap-radio">
                            <v-checkbox v-model="wallet" color="blue" value="1" :label="__('Gold')"></v-checkbox>
                            <v-checkbox v-model="wallet" color="blue" value="2" :label="__('Silver')"></v-checkbox>
                        </v-radio-group>
                    </div>
                    <div class="betting date">
                        <div class="betting__head">
                            <div class="map__icon mb-1">
                                <img src="/dist/images/frontend/date-icon.svg" alt />
                            </div>
                            <p>{{__('Date')}}</p>
                        </div>
                        <div class="wrap-date">
                            <div class="block-birthday">
                                <!--            <InputLabelTextDefault-->
                                <!--                label="Date"-->
                                <!--                color="main"-->
                                <!--                class="mr-8"-->
                                <!--                type="number"-->
                                <!--            ></InputLabelTextDefault>-->
                                <v-menu
                                        v-if="inputDateFrom == null || inputDateFrom == ''"
                                        v-model="date"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            ref="picker"
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="date_from"
                                            @change="changeCalendar"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                        v-else
                                        v-model="date_menu"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="date_from"
                                            @change="changeCalendar"
                                    ></v-date-picker>
                                </v-menu>

                                <v-text-field
                                        color="light-blue"
                                        :label="__('From')"

                                        v-mask="'##.##.####'"
                                        v-model="inputDateFrom"
                                        clearable
                                        @input="changeInput"
                                        @change="updateDate"
                                        ref="birthday"
                                ></v-text-field>
                                <div class="information">{{__('(DD.MM.YYYY)')}}</div>
                            </div>



                            <div class="block-birthday">
                                <v-menu
                                        v-if="inputDateTo == null || inputDateTo == ''"
                                        v-model="dateTo"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDateTo"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            ref="picker"
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="date_to"
                                            @change="changeCalendarTo"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                        v-else
                                        v-model="date_menuTo"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDateTo"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="date_to"
                                            @change="changeCalendarTo"
                                    ></v-date-picker>
                                </v-menu>

                                <v-text-field
                                        color="light-blue"
                                        :label="__('To')"
                                        v-mask="'##.##.####'"
                                        v-model="inputDateTo"
                                        clearable
                                        @input="changeInput"
                                        @change="updateDateTo"
                                        ref="birthday"
                                ></v-text-field>
                                <div class="information">{{__('(DD.MM.YYYY)')}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-btn">
                        <div style="width: 48%">
                            <BaseButton
                                    v-on:click="cancelFilters()"
                                    :loading="submitDisableded"

                                    class_btn="btn-liteGrey"
                            >
                                <span>{{__('Clear')}}</span>
                            </BaseButton>
                        </div>

                        <div style="width: 48%">
                            <BaseButton
                                    v-on:click="onSubmit"
                                    :loading="submitDisabled"

                            >
                                <span>{{__('Apply')}}</span>
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    // import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownDefault from "../../modules/DropdownDefault";
    import {maxLength, minLength, numeric} from "vuelidate/lib/validators";
    import moment from "moment";
    import {mask} from 'vue-the-mask';

    export default {
        name: "TransactionSorting",
        data() {
            return {
                // search: null,

                income: this.sorting.income,
                wallet: this.sorting.wallet,
                date_from: this.sorting.date_from,
                date_to: this.sorting.date_to,

                submitDisabled: false,
                submitDisableded: false,
                dialog: true,

                formValidate: {
                    search: null,
                    income: null,
                    wallet: null,
                    date_from: null,
                    date_to: null,
                },

                date: false,
                dateTo: false,
                inputDateFrom: this.sorting.date_from ? moment(this.sorting.date_from).format('DD.MM.YYYY') : '',
                inputDateTo: this.sorting.date_to ? moment(this.sorting.date_to).format('DD.MM.YYYY') : '',
                date_menu: false,
                date_menuTo: false,

                sortType: [
                    {
                        title: this.__("All result"),
                        value: 0,
                    },
                    {
                        title: this.__("Winner"),
                        value: 1,
                    },
                    {
                        title: this.__("Loose"),
                        value: 2,
                    },
                    {
                        title: this.__("Draw"),
                        value: 3,
                    }
                ],
            };
        },

        validations: {
            sorting: {
                search: {
                    maxLength: maxLength(100),
                    minLength: minLength(1),
                },
                income: {
                    numeric
                },
            },
        },

        props: {
            // search: {
            //   type: String,
            // },
            color: {
                type: String,
            },

            sorting: {
                type: Object,
            },
            search: {
                type: String
            },
            modal_sorting: {
                type: Boolean,
            },
            filter: {
                type: Array,
            }
        },

        watch: {
            dialog() {
                this.$emit("update:modal_sorting", false);
            },
        },

        computed: {
            minYear: function () {
                return (new Date().getFullYear() - 100).toString();

            },
            maxYear: function () {
                // let a = new Date().getTime() - 567648000000;
                let a = new Date().getTime();
                return moment(a).format('YYYY-MM-DD');
                // return (new Date().getFullYear() - 18).toString();
            },
            computedDate() {
                return this.date_from ? moment(this.date_from).format('DD.MM.YYYY') : ''
            },
            computedDateTo() {
                return this.date_to ? moment(this.date_to).format('DD.MM.YYYY') : ''
            },
        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.sorting[type],
                    type
                );
            },
            onSubmit: function () {
                let valid = this.$v.sorting;

                if (valid.$invalid) {
                    let massages = {
                        income: [],
                        wallet: [],
                        date_from: [],
                        date_to: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {

                    let income = this.income;

                    if (this.income == 0) {
                        income = null;
                    } else {
                        income = this.income
                    }

                    // let wallet = this.wallet;
                    //
                    // if (wallet.length > 1) {
                    //     wallet = null;
                    // } else {
                    //     wallet = Number(wallet[0]);
                    // }

                    let form = {
                        income: income,
                        wallet: this.wallet,
                        date_from: this.date_from,
                        date_to: this.date_to,
                    };



                    let count = [];
                    Object.keys(form).forEach((key) => {
                        if(form[key] && form[key].length != 0) {
                            count.push(form[key]);
                        }
                    });
                    this.$emit('update:filter', count);
                    this.$emit('sortingPlayed', form);

                    this.$emit('update:modal_sorting', false);
                }
            },

            cancelFilters: function () {

                let form = {
                    income: null,
                    wallet: [],
                    date_from: null,
                    date_to: null,
                };

                let count = [];
                Object.keys(form).forEach((key) => {
                    if(form[key] && form[key].length) {
                        count.push(form[key]);
                    }
                });
                this.$emit('update:filter', count);
                this.$emit('sortingPlayed', form);

                this.$emit('update:modal_sorting', false);

            },
            changeCalendar: function () {
                this.date_menu = false;
                this.date = false;
                this.inputDateFrom = this.computedDate;
            },
            changeCalendarTo: function () {
                this.date_menuTo = false;
                this.dateTo = false;
                this.inputDateTo = this.computedDateTo;
            },
            changeInput: function (value) {
                if (value) {
                    this.errorDate = false;
                }
            },
            updateDate: function (value) {
                this.errorDate = false;
                if (this.validationDate()) {
                    let dateParts = value.split(".");
                    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                    this.date_from = moment(new Date(dateObject)).format('YYYY-MM-DD');
                } else if (this.inputDateFrom == '') {
                    this.date_from = moment(new Date()).format('YYYY-MM-DD');
                    this.errorDate = false;
                } else {

                    this.errorDate = true
                }
            },
            updateDateTo: function (value) {
                this.errorDate = false;
                if (this.validationDate()) {
                    let dateParts = value.split(".");
                    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                    this.date_to = moment(new Date(dateObject)).format('YYYY-MM-DD');
                } else if (this.inputDateTo == '') {
                    this.date_to = moment(new Date()).format('YYYY-MM-DD');
                    this.errorDate = false;
                } else {

                    this.errorDate = true
                }
            },
            validationDate: function () {
                let date = this.inputDateFrom;
                let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
                // pattern.test(date);
                if (date) {
                    let year = (+date.split('.')[2])
                    let minData = (new Date().getFullYear() - 100);
                    let maxDate = (new Date().getFullYear() - 18);
                    let check =  (year >= minData && year <= maxDate) ? true : false;

                    if (check) {
                        this.majority = true
                    } else {
                        this.majority = false
                    }

                    return (pattern.test(date)) && check;
                }
            },
            validationDateTo: function () {
                let date = this.inputDateTo;
                let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
                // pattern.test(date);
                if (date) {
                    let year = (+date.split('.')[2])
                    let minData = (new Date().getFullYear() - 100);
                    let maxDate = (new Date().getFullYear() - 18);
                    let check =  (year >= minData && year <= maxDate) ? true : false;

                    if (check) {
                        this.majority = true
                    } else {
                        this.majority = false
                    }

                    return (pattern.test(date)) && check;
                }
            },
        },
        components: {
            // InputLabelTextDefault,
            DropdownDefault
        },
        directives: {mask}
    };
</script>


<style lang="scss">

    .dark {
        .wrap-sorting {
            background: #171827;
            .wrap-sorting-head {
                background: #171827;
                border-bottom: 1px solid #2B2B2B;

                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                h5 {
                    color: #fff;
                }
            }

            .wrap-sorting-body {
                background: #171827;
            }

            .result__icon {
                img {
                    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                }
            }

            .map__icon {
                img {
                    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                }
            }

            .betting {

                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }
                p {
                    color: #fff;
                }
            }
        }
    }

    .wrap-sorting {
        /*position: fixed;*/
        /*top: 87px;*/
        /*right: 0;*/
        max-width: 320px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;


        .wrap-sorting-head {
            padding: 20px;
            display: flex;
            align-items: center;
            //box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 0px 0px;
            border-bottom: 2px solid #ECF4FF;
            background: #fff;

            &__icon {
                margin-right: 17px;
                display: flex;
                align-items: center;
            }

            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }

        .search {
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            padding: 10px 0 15px;
            box-shadow: 0px 2px 0px #F2F3FB;

            &__input {
                width: 100%;
            }

            &__icon {
                margin: 17px 14px 0 20px;
            }
        }

        .wrap-sorting-body {
            padding: 20px;
            background-color: #fff;
            border-radius: 0 0 4px 4px;
        }

        .result__icon {
            margin: 0 18px 0 0;
        }
        .result__input {
            margin-left: 38px;
        }

        .map {
            display: flex;
            align-items: center;
            margin:0 0 26px 0;
            position: relative;

            &__icon {
                margin: 0 18px 0 0;
            }

            &__input {
                width: 100%;
                position: relative;

                p {
                    position: absolute;
                    top: -7px;
                    left: 0;
                    z-index: 10;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #6886AF;
                }
            }
        }

        /*.region {*/
        /*  margin-bottom: 20px;*/
        /*  p {*/
        /*    font-weight: 400;*/
        /*    font-size: 14px;*/
        /*    line-height: 19px;*/
        /*    margin-bottom: 10px;*/
        /*    color: #002b50;*/
        /*  }*/
        /*}*/
        .region {
            display: flex;
            position: relative;
            flex-direction: column;

            .betting__head {
                margin-bottom: 0;
            }

            &__icon {
                margin: 0 16px 0 0;
            }

            &__input {
                /*width: 100%;*/
                margin-left: 35px;
            }
            .v-input .v-label {
                font-size: 16px;
            }
        }

        .age {
            margin-bottom: 20px;

            &__icon {
                margin: 5px 16px 0 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            &__inputs {
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                margin-left: 36px;
            }
        }

        .gender {
            margin-bottom: 0;

            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
            }

            &__icon {
                margin: 7px 16px 0 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            .wrap-checkbox {
                margin-left: 36px;

                .v-input {
                    margin-right: 50px;
                }
            }
        }

        .betting {
            margin-bottom: 5px;

            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
            }

            &__icon {
                margin: 7px 16px 0 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }
            .wrap-radio {
                margin-left: 35px;

                &.v-input--radio-group.v-input--radio-group--row .v-radio {
                    margin-right: 40px;
                }

                .v-input {
                    margin-right: 30px;
                }
            }
        }

        .v-label {
            font-size: 14px;
        }

        .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;
        }

        .v-input--radio-group.v-input--radio-group--row .v-radio {
            margin-right: 0;
            margin-bottom: 10px;

            &:last-child {
                margin-right: 4px;
            }
        }

        .wrap-date {
            display: flex;
            justify-content: space-between;
            margin-left: 35px;
            margin-top: 14px;

            .v-text-field .v-label--active {
                transform: translateY(-15px) scale(.75)
            }

        }
        .block-birthday {
            max-width: 180px;
            width: 100%;
            z-index: 1;
            position: relative;
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 30px;
            }

            .calendar {
                color: #96AFD1;
                position: absolute;
                z-index: 100;
                top: 5px;
                right: 0;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                    color: #26A9F4;
                }
            }
            .information {
                position: absolute;
                bottom: -18px;
                left: 0;
                font-size: 11px;
                color: #6886af;
            }
        }

        .wrap-btn {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .v-btn {
                width: 48%;
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 100px;
                    width: 48%;
                    padding: 0;

                }
            }

            .btn-secondary {
                margin-right: 15px;
            }

        }
    }

    @media screen and (max-width: 1350px) {
        .wrap-sorting {
            width: 280px;
        }
    }

    @media screen and (max-width: 920px) {

        .wrap-sorting {
            .sort,
            .region,
            .age {
                margin-bottom: 10px;

                p {
                    margin-bottom: 5px;
                }
            }
            .male {
                p {
                    margin-bottom: 5px;
                }
            }
        }
    }

    @media screen and (max-width: 525px) {

        .wrap-sorting {
            .sort,
            .region,
            .age {
                margin-bottom: 6px;

                /*p {*/
                /*    margin-bottom: 1px;*/
                /*    font-size: 10px;*/
                /*}*/
            }
            .male {
                /*p {*/
                /*    margin-bottom: 1px;*/
                /*    font-size: 10px;*/
                /*}*/
            }

            .btn-cab {
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    min-width: 64px;
                    width: 100px;
                    padding: 0;
                    margin: 0 auto;
                    font-size: 12px;
                }
            }

            .v-input--radio-group.v-input--radio-group--row .v-radio {
                margin-bottom: 0px;
            }
            .v-label {
                font-size: 12px;
            }
        }
    }
</style>