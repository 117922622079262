<template>
  <div>
      <LayoutNavAuth v-if="page=='developers'">
          <h1>{{__('To Developers')}}</h1>
          <div class="loading"  :style="{width: progress + '%'}"></div>
      </LayoutNavAuth>
    <div class="d-flex wrapper-developer dashboard-content" v-if="page=='developers'">
      <div class="pa-0 block-body">
        <div v-if="game_platforms">
          <GamesList v-bind:developer_create_game.sync="developer_create_game" :games.sync="games" :next_page_url.sync="next_page_url"></GamesList>
        </div>
        <div v-else>{{__('loading')}}</div>
      </div>
      <div class="block-sidebar" v-if="statistic">
        <DevelopersSidebar v-bind:statistic.sync="statistic"></DevelopersSidebar>
      </div>
    </div>

    <div v-else>
       <GameInfo v-if="game_platforms"></GameInfo>
       <div v-else>{{__('loading')}}</div>
    </div>
    <ModalDeveloperCreateGame v-if="developer_create_game" v-bind:developer_create_game.sync="developer_create_game"></ModalDeveloperCreateGame>
  </div>
</template>

<script>
import GamesList from "./Developers/GamesList";
import GameInfo from "./Developers/GameInfo";
import DevelopersSidebar from "./Developers/DevelopersSidebar";
import ModalDeveloperCreateGame from "./Developers/ModalDeveloperCreateGame";
import LayoutNavAuth from "../modules/LayoutNavAuth";

export default {
  name: "Home",
  data() {
    return {
      developer_create_game: false,
      games: null,
      statistic: null,
      next_page_url: null,
      progress: 0,
    };
  },
  computed: {
    page: function () {
      return this.$route.name;
    },
    game_platforms: function () {
      return this.$store.getters.platforms;
    },
  },
    beforeRouteUpdate (to, from, next) {
        // обрабатываем изменение параметров маршрута...
        // не забываем вызвать next()
        this.createdGame();
        return next();
    },
    created() {

        this.createdGame()

        this.$http
            .get("ajax/platform")
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if(this.$checkAuth(response)) {
                    this.$store.dispatch("asyncPlatforms", response);
                }
            })
            .catch((err) => {
                this.httpHandler(err);
            });

        this.$http
            .get("ajax/category")
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if(this.$checkAuth(response)) {
                    this.$store.dispatch("asyncCategories", response);
                }
            })
            .catch((err) => {
                this.httpHandler(err);
            });
    },
  asyncComputed: {},

  methods: {
      createdGame: function (){
          let timeout = setInterval(() => {
              this.progress += 1;
              if(this.progress > 50) {
                  clearInterval(timeout)
              }
          },15);
          this.$http.get("ajax/developers")
              .then((response) => {
                  return response.json();
              })
              .then((response) => {
                  if(this.$checkAuth(response)) {
                      this.games = response.data.data;
                      this.next_page_url = response.data.next_page_url;
                      this.statistic = response.data.statistic;

                      clearInterval(timeout);
                      this.progress = 100;
                      setTimeout(() => {
                          this.progress = 0;
                      }, 500);
                  }
              })
              .catch((err) => {
                  this.httpHandler(err);
              });
      }

  },
  components: {
    GamesList,
    GameInfo,
    DevelopersSidebar,
    ModalDeveloperCreateGame,
    LayoutNavAuth
  },
};
</script>

<style lang="scss">

.block-body {
    width: 100%;
}

.wrapper-developer {
    padding: 20px 0 0;

    .block-sidebar {
        width: 320px;
        flex-shrink: 0;
        margin-left: 20px;
    }
}


@media screen and (max-width: 1240px) {
    .wrapper-developer {
        padding: 20px 20px 0;
    }
}

@media screen and (max-width: 950px) {
    .wrapper-developer {
        .block-sidebar {
            display: none;
        }
    }
}
@media screen and (max-width: 768px) {
    .wrapper-developer {
        padding: 15px 15px 0;
    }
}

</style>


