<template>
  <div>
    <div
      class="edit-ok"
      v-if="options.messages_edit"
      @click="$emit('actionOptions',{
        name: 'Edit',
        value: 'edit'
      })"
    >
      <img src="/dist/images/frontend/icon-cancel.svg" alt="">
    </div>
    <v-menu offset-y left open-on-click :close-on-content-click="false" v-model="menu" v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-list color="transparent" class="pa-0">
          <v-list-item-action v-on="on" v-bind="attrs" class="ma-0">
            <!--                    <v-btn text>-->
            <v-list-item class="top-btn">
              <v-btn>
                ...
              </v-btn>
            </v-list-item>
          </v-list-item-action>
        </v-list>
      </template>
      <v-list class="modal-profile top-btn-list">
        <v-list-item
          v-for="(option, key) in options_list"
          :key="key"
          @click="action(option)"
        >
          <span>{{option.name}}</span>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
    export default {
        name: "MessengerMessagesOption",
        data: function () {
            return {
                menu: false,
                options_list: [
                    {
                        name: this.__('Edit'),
                        value: 'edit'
                    },
                    // {
                    //     name: 'Notifications',
                    //     value: 'notifications'
                    // },
                    // {
                    //     name: 'Жалоба',
                    //     value: 'appeal'
                    // },
                    {
                        name: this.__('Deleted History'),
                        value: 'deleted_history'
                    },
                    // {
                    //     name: 'Deleted Chat',
                    //     value: 'deleted_chat'
                    // },
                ]
            };
        },
        props: {
            options: {
                type: Object
            }
        },
        methods: {
            action(option) {
                this.menu = false;

                this.$emit('actionOptions', option);
            },
            editClose() {

            }
        },
        computed: {

        },
    };
</script>
