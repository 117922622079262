<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:result', false)">
        <div class="modal-confirm-tournament">
            <v-btn text icon class="icon ma-0" @click="cancelModal">
                <img src="/dist/images/frontend/close.svg" alt="/" />
            </v-btn>

            <div class="modal-confirm-tournament__body">
                <img src="/dist/images/frontend/confirm-tournament-img.png" alt />

                <div class="block-title">
                    <p>{{__('Buying a place in a tournament')}}</p>
                </div>

                <div class="block-cost">
                    <span class="block-cost__title">{{__('Confirm your purchase')}}</span>
                    <div  class="block-cost__desc">
                        <img v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />

                        <span>{{tournament.price}}</span>
                    </div>

                </div>
            </div>
            <div class="wrap-btn">
                <div style="width: 47%">
                    <BaseButton
                        class_btn="btn-liteGrey-quest"
                        @click="cancelModal"
                    >
                        <span>{{__('Cancel')}}</span>
                    </BaseButton>
                </div>

                <div style="width: 47%">
                    <BaseButton
                        class_btn="btn-guest"
                        v-on:click="$emit('buyTournament')"
                        :loading="submitDisabled"
                    >
                        <span>{{__('Confirm')}}</span>
                    </BaseButton>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "ModalConfirmTournament",
        data() {
            return {
                modal: true,
            };
        },
        props: {
            open_confirm: {
                type: Boolean,
            },
            tournament: {
                type: Object
            },
            submitDisabled: {
                type: Boolean
            }
        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {},

        methods: {
            cancelModal() {
                this.$emit("update:open_confirm", false);
            },
        },
        components: {},
    };
</script>

<style lang='scss' scoped>

    .modal-confirm-tournament {
        text-align: center;
        padding: 40px 60px 45px;
        background: #171827;
        box-shadow: 0 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;

        .icon {
            position: absolute;
            top: 4px;
            right: 4px;
            outline: none;

            img {
                height: 12px;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            img {
                margin-bottom: 20px;
            }

            .block-title {
                border-radius: 8px;
                background: #181818;
                height: 36px;
                margin-bottom: 14px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    color: #01DF72;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                    margin: 0;
                }
            }

            .block-cost {
                display: flex;
                align-items: center;
                justify-content: center;

                &__title {
                    color: #FFF;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                    letter-spacing: 0.014px;
                    margin: 0 10px 0 0;
                }

                &__desc {
                    display: flex;
                    align-items: center;
                    img {
                        height: 18px;
                        margin: 0 6px 0 0;
                    }

                    span {
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 14px;
                    }
                }
            }
        }

        .wrap-btn {
            display: flex;
            justify-content: space-between;
        }
    }

    @media screen and (max-width: 500px) {
        .modal-confirm-tournament {
            padding: 35px 20px 25px;
        }
    }
</style>