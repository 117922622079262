<template>
  <div>
    <div v-if="games" class="wrap-developers-page">
      <div class="wrap-developers-head">
        <div class="developers-desc">
          <h3>{{__('Develop games and earn commissions!')}}</h3>
          <p>{{__('MindPlays platform shares commission income between game developers for the platform! See')}}
          <router-link :to="{name: 'api'}">{{__('Api Documentation')}}</router-link>
          </p>

          <div class="wrap-btn">
            <BaseButton :width="165"  v-on:click="$emit('update:developer_create_game', true)">
              <span>{{__('Create Game Now')}}</span>
            </BaseButton>
          </div>
        </div>
        <div class="developers-info">
          <div class="d-flex developers-info__right">
            <div class="developers-info__text">
                <h3>35%</h3>
              <div>
                  <span>{{__('from each game played')}}</span>
                  <p>{{__('from the commission of the MINDPLAYS platform')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="games.length">
        <GamesItem v-for="(item, key) in games" :key="key" v-bind:item="item"></GamesItem>
      </div>
      <div v-else class="wrap-notgames">
        <img src="/dist/images/frontend/no-games.svg" alt />
        <div>
          <p>{{__('Develop games and earn commissions!')}}</p>
          <p>{{__('MindPlays platform shares commission income between game developers for the platform')}}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{__('loading...')}}</p>
    </div>
  </div>
</template>

<script>
import GamesItem from "./GamesItem";
// import ScrollingContent from '../../modules/ScrollingContent'

export default {
  name: "GamesList",
  data() {
    return {
      loadingPage: false,
      side_tabs: [
        {
          'title': 'My stats',
          'value': 'stats'
        },
        {
          'title': 'My games',
          'value': 'games'
        },
      ],
      currentTab: 'games',
    };
  },

    props: {
      games: {
          type: Array
      }
    },

  computed: {},
  asyncComputed: {},
  created: function () {
    // this.$http.get("ajax/developers")
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((response) => {
    //     this.games = response.data.data;
    //     this.next_page_url = response.data.next_page_url;
    //   })
    //   .catch((err) => {
    //       this.httpHandler(err);
    //   });

      this.$removeAllListeners(window,'scroll');

    this.$addListener(window,'scroll', () => {
      this.scrollGames();
    });
  },
  methods: {
    scrollGames: function () {
      const progress =
        document.documentElement.scrollTop /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);
      if (!this.loadingPage && this.next_page_url) {
        let bottomOfWindow = 0.7 <= progress;
        if (bottomOfWindow) {
          this.loadingPage = true;
          this.$http.get(this.next_page_url)
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              if(this.$checkAuth(response)) {
                  let games = this.games;
                  this.games = games.concat(response.data.data);
                  this.next_page_url = response.next_page_url;
                  this.loadingPage = false;
              }
            })
            .catch((err) => {
                this.httpHandler(err);
            });
        }
      }
    },
    sideChange(newTab) {
      if (newTab != this.currentTab) {
        this.currentTab = newTab;
      }
    },
  },
  components: {
    GamesItem,
    // ScrollingContent
  },
};
</script>

<style lang="scss" scoped>

.wrap-developers-page {

}

.dark {
  .wrap-developers-head {
    border-bottom: none;

    &:after {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.3;
    }

    .developers-desc {
      h3 {
        color: #fff;
      }

      p {
        color: #96AFD1;
      }
    }

    .developers-info {
      p {
        color: #fff;
      }
    }
  }

  .wrap-notgames {
    p {
      color: #fff;
    }
  }
}

.wrap-developers-head {
  height: 180px;
  padding: 0 20px;
  border-bottom: 2px solid #ECF4FF;
  margin-bottom: 20px;
  border-radius: 4px;
  position: relative;
  //background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after {
      border-radius: 4px;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    background: linear-gradient(90deg, #9CDFF9 0%, #87D6F5 31.01%, #C5DDF0 45.02%, #BDDBFC 64.06%, #D8E3F0 100%, #C5D6EA 100%);
  }

  .developers-desc {
    max-width: 580px;
    width: 100%;
    position: relative;
    z-index: +1;
    h3 {
      font-size: 24px;
      line-height: 32px;
      color: #002B50;
      margin-bottom: 6px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      color: #6886AF;
      margin-bottom: 12px;

      a {
        color: #26A9F4;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .wrap-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }
  }

  .developers-info {
    display: flex;
    height: fit-content;
    position: relative;
    z-index: +1;

      &__right  {
          display: flex;

          p {
              margin: 0;
              font-size: 12px;
          }
      }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      font-size: 14px;
      line-height: 117.8%;
      text-transform: uppercase;
      color: #00C443;
      text-align: center;
      display: block;
    }
    p {
      font-size: 12px;
      line-height: 117.8%;
      color: #002B50;
      width: 100%;
      margin-top: 2px;
      text-align: center;
    }

    h3 {
      font-weight: 300;
      margin-bottom: 8px;
      font-size: 60px;
      line-height: 50px;
      letter-spacing: -0.05em;
      color: #0F9AFF;
    }

    .block-tabs {
      position: relative;
      z-index: 10;
      transform: translate(20px, 41px);

    }
  }


}
  .developers-head {
    width: 100%;
    position: relative;
    border-radius: 4px;
    margin-bottom: 9px;

    img {
      max-height: 355px;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    .developers-desc {
      max-width: 290px;
      width: 100%;
      position: absolute;
      top: 44px;
      left: 50px;

      h3 {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
        margin-bottom: 18px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 26px;
        border-radius: 6px;
      }

      .v-btn.btn-cab {
        margin: 0;
        &.v-btn:not(.v-btn--round).v-size--default {
          height: 40px;
          min-width: 165px;
          padding: 0 12px;
        }
      }
    }
  }

  .developers-head-small {
    width: 100%;
    position: relative;
    border-radius: 4px;
    margin-bottom: 9px;

    img {
      max-height: 130px;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    .developers-desc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 24px;
      left: 40px;
      right: 40px;
      h3 {
        max-width: 470px;
        width: 100%;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
        margin-bottom: 8px;
      }

      p {
        max-width: 470px;
        width: 100%;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 0;
        border-radius: 6px;
      }

      .v-btn.btn-cab {
        margin: 0;
        &.v-btn:not(.v-btn--round).v-size--default {
          height: 40px;
          min-width: 165px;
          padding: 0 12px;
        }
      }
        .wrap-btn {
            width: 100%;
            display: flex;
            justify-content: flex-start;
        }
    }
  }

  .developers-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding: 20px 25px 22px;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    margin-bottom: 2px;

    span {
      color: #6886AF;
    }
  }
.wrap-notgames {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #96afd1;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 768px) {
    .wrap-developers-head {
        padding: 10px;
        margin-bottom: 15px;

        .developers-desc {

            h3 {
                line-height: 28px;
            }
        }

    }
}

@media screen and (max-width: 650px) {
    .wrap-developers-head {
        align-items: center;
        flex-direction: column-reverse;
        height: fit-content;

        .developers-desc {

            h3 {
                font-size: 20px;
                line-height: 22px;
            }

            p {
                font-size: 14px;
            }
        }
        .developers-desc {
            max-width: 100%;
        }

        .developers-info {
            /*display: none;*/
            width: 100%;
            margin-bottom: 10px;
            &__text {
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                h3 {
                    margin: 0 12px 0 0;
                    font-weight: 300;
                    font-size: 50px;
                    line-height: 50px;
                    letter-spacing: -0.05em;
                    color: #0F9AFF

                }
                span {
                    font-size: 14px;
                    line-height: 117.8%;
                    text-transform: uppercase;
                    color: #00C443;
                    text-align: left;
                    display: block;
                }
                p {
                    font-size: 14px;
                    line-height: 117.8%;
                    color: #002B50;
                    width: 100%;
                    margin-top: 2px;
                    text-align: left;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .wrap-developers-head {
        align-items: center;
        flex-direction: column-reverse;
        height: fit-content;

        .developers-desc {

            h3 {
                font-size: 18px;
                line-height: 20px;
                text-align: center;
            }

            p {
                font-size: 12px;
                text-align: center;
            }

            .wrap-btn {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
        .developers-desc {
            max-width: 100%;
        }

        .developers-info {
            /*display: none;*/
            width: 100%;
            margin-bottom: 10px;
            justify-content: center;
            &__text {
                flex-direction: row;
                justify-content: center;
                align-items: center;

                h3 {
                    margin: 0 8px 0 0;
                    font-weight: 300;
                    font-size: 44px;
                    line-height: 44px;
                    letter-spacing: -0.05em;
                    color: #0F9AFF

                }
                span {
                    font-size: 12px;
                    line-height: 117.8%;
                    text-transform: uppercase;
                    color: #00C443;
                    text-align: left;
                    display: block;
                }
                p {
                    font-size: 12px;
                    line-height: 117.8%;
                    color: #002B50;
                    width: 100%;
                    margin-top: 2px;
                    text-align: left;
                }
            }
        }
    }
}

</style>
