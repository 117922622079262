<template>
  <div class="wrap-deposits">
    <div class="wrap-deposits__body">
      <div class="wrapper-payment-select">
        <div class="wrapper-payment-select__item">
          <span>{{__('Choose a payment system')}}</span>
          <v-select
                  dense
                  filled
                  solo
                  v-model="payment"
                  :items="(form.wallet == 1) ? usdtPayments : payments"
                  :filter="customFilter"
                  item-color="white"
                  :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:selection="data">
              <div class="img" :class="data.item.name_class"></div>
              {{ data.item.name }}
              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
            </template>
            <template v-slot:item="data">
              <template>
                <div class="wrap-item-select">
                  <div class="img" :class="data.item.name_class"></div>
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </template>
            </template>
          </v-select>
        </div>

        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.71 1.29L15.71 5.29C15.8488 5.43063 15.9428 5.6092 15.9801 5.80319C16.0175 5.99718 15.9966 6.19789 15.92 6.38C15.845 6.56262 15.7176 6.71895 15.5539 6.82928C15.3901 6.93961 15.1974 6.99902 15 7H1C0.734784 7 0.48043 6.89465 0.292894 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73479 0.105357 5.48043 0.292894 5.2929C0.48043 5.10536 0.734784 5 1 5H12.59L10.29 2.71C10.1017 2.5217 9.99591 2.2663 9.99591 2C9.99591 1.7337 10.1017 1.47831 10.29 1.29C10.4783 1.1017 10.7337 0.995911 11 0.995911C11.1319 0.995911 11.2624 1.02188 11.3842 1.07234C11.5061 1.1228 11.6168 1.19676 11.71 1.29Z" fill="#96AFD1"/>
          <path d="M11.7113 10.7061L15.7118 6.70883C15.8506 6.56831 15.9446 6.38986 15.9819 6.196C16.0193 6.00214 15.9984 5.80156 15.9218 5.61958C15.8468 5.43708 15.7194 5.28086 15.5556 5.1706C15.3919 5.06035 15.1992 5.00099 15.0017 5H1.00011C0.734867 5 0.480485 5.10529 0.292927 5.29269C0.105369 5.4801 0 5.73428 0 5.99932C0 6.26435 0.105369 6.51853 0.292927 6.70594C0.480485 6.89335 0.734867 6.99864 1.00011 6.99864H12.5914L10.2912 9.28708C10.1028 9.47525 9.99704 9.73047 9.99704 9.99659C9.99704 10.2627 10.1028 10.5179 10.2912 10.7061C10.4795 10.8943 10.7349 11 11.0012 11C11.1331 11 11.2637 10.974 11.3855 10.9236C11.5074 10.8732 11.6181 10.7993 11.7113 10.7061Z" fill="#96AFD1"/>
        </svg>

        <div class="wrapper-payment-select__item">
          <span>{{__('What coin do you want to buy?')}}</span>
          <v-select
            disabled
            dense
            filled
            solo
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="form.wallet"
            :items="coins"
            :filter="customFilter"
            item-color="white"
            @change="calcSum(calc_give, 'give')"
          >
            <template v-slot:selection="data">
              <img height="40" class="payment-item__icon mr-4" v-html="data.item.img" :src="data.item.img">
              {{ data.item.name }}
<!--              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />-->
            </template>
            <template v-slot:item="data">
              <template>
                <div class="wrap-item-select">
                  <img class="payment-item__icon" v-html="data.item.img" :src="data.item.img">
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </template>
            </template>
          </v-select>
        </div>
      </div>
      <div class="wrapper-inputs">
        <div class="wrapper-inputs__item">
          <div class="d-flex justify-content-between">
            <p class="text text--label">{{__('Amount to deposit')}}</p>
            <p class="text text--label" @click="addMin()" style="cursor: pointer">{{__('Min')}} {{payment.min_replenish}} {{payment.currency.toUpperCase()}}</p>
          </div>
          <InputLabelTextDefault
                  v-model="calc_give"
                  color="balance"
                  :placeholder="__('0')"
                  v-bind:errors="formValidate.amount_coin"
                  @input="calcSum(calc_give, 'give')"
                  ref="calc_give"
                  type="number"
          >
            <template v-slot:right>
              <div class="currency">
                {{payment.currency.toUpperCase()}}
              </div>
            </template>
          </InputLabelTextDefault>
        </div>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.71 9.29L17.71 5.29C17.6168 5.19676 17.5061 5.1228 17.3843 5.07234C17.2624 5.02188 17.1319 4.99591 17 4.99591C16.7337 4.99591 16.4783 5.1017 16.29 5.29C16.1017 5.47831 15.9959 5.7337 15.9959 6C15.9959 6.2663 16.1017 6.5217 16.29 6.71L18.59 9H7.00002C6.7348 9 6.48045 9.10536 6.29291 9.2929C6.10537 9.48043 6.00002 9.73479 6.00002 10C6.00002 10.2652 6.10537 10.5196 6.29291 10.7071C6.48045 10.8946 6.7348 11 7.00002 11H21C21.1974 10.999 21.3902 10.9396 21.5539 10.8293C21.7176 10.7189 21.845 10.5626 21.92 10.38C21.9966 10.1979 22.0175 9.99718 21.9801 9.80319C21.9428 9.6092 21.8488 9.43063 21.71 9.29ZM17 13H3.00002C2.80259 13.001 2.60988 13.0604 2.44616 13.1707C2.28244 13.2811 2.15504 13.4374 2.08002 13.62C2.00344 13.8021 1.98252 14.0028 2.01989 14.1968C2.05726 14.3908 2.15126 14.5694 2.29002 14.71L6.29002 18.71C6.38298 18.8037 6.49358 18.8781 6.61544 18.9289C6.7373 18.9797 6.86801 19.0058 7.00002 19.0058C7.13203 19.0058 7.26274 18.9797 7.38459 18.9289C7.50645 18.8781 7.61705 18.8037 7.71002 18.71C7.80375 18.617 7.87814 18.5064 7.92891 18.3846C7.97968 18.2627 8.00582 18.132 8.00582 18C8.00582 17.868 7.97968 17.7373 7.92891 17.6154C7.87814 17.4936 7.80375 17.383 7.71002 17.29L5.41002 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8947 14.5196 18 14.2652 18 14C18 13.7348 17.8947 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13Z" fill="#96AFD1"/>
        </svg>

        <div class="wrapper-inputs__item">
          <p class="text text--label">{{__('You receive')}}</p>
          <InputLabelTextDefault
                  style="width: 100%"
                  v-model="calc_get"
                  :placeholder="__('0')"
                  color="balance"
                  disabled
                  type="number"
          >
            <template v-slot:right>
              <div class="currency">
                <span v-if="form.wallet==1">{{__('Gold coin')}}</span>
                <span v-else-if="form.wallet==2">{{__('Silver coin')}}</span>
              </div>
            </template>
          </InputLabelTextDefault>
        </div>
      </div>
      <div class="block-bottom">
        <BaseButton
                width="175"
                v-on:click="onSubmit"
                :loading="submitDisabled"
        >
          <span>{{__('Top up account')}}</span>
        </BaseButton>
        <div class="block-bottom__info">
          <p style="color: #00C443" v-if="payment.pay_commission== 2">{{__('Will be charged')}} {{(Number(calc_give)+commission).toFixed(2)}} {{payment.currency.toUpperCase()}}</p>
          <p>
            {{__('Payment system commission')}}
            <span v-if="payment.replenish_pay_commission== 'percent'">{{payment.replenish_commission}}%</span>
            <span v-else>{{commission}} {{payment.currency.toUpperCase()}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    // import Switcher from "../../modules/Switcher";
    import {decimal, required, minValue} from "vuelidate/lib/validators";
    export default {
        name: 'Deposits',
        data () {
            return {
                state: false,
                form: {
                    wallet: 2,
                    amount: 0,
                    amount_coin: 0,
                },
              payment: this.payments[0],
              calc_give: null,
                calc_get: null,
                formValidate: {
                    wallet: null,
                    amount: null,
                },
                submitDisabled: false,
                coins: [
                    // {
                    //     name: "USDT Coin",
                    //     img: "/dist/images/frontend/money.svg",
                    //     value: 1
                    // },
                    {
                        name: "MIND Coin",
                        img: "/dist/images/frontend/light-coin.svg",
                        value: 2
                    }
                ]
            }
        },
        props: {
            silver: {
                type: Object
            },
            transactions: {
                type: Boolean
            },
            payments: {
                type: Array
            },
            usdtPayments: {
              type: Array
            }
        },
        validations() {
            return {
                form: {
                    wallet: {
                        required,
                    },
                    amount_coin: {
                        required,
                        decimal,
                        minValue: minValue(this.payment.min_replenish)
                    },
                    amount: {
                        required,
                        decimal
                    },
                }
            }
        },
        computed: {
            commission() {
                let commission= 0;

                if(this.payment.replenish_pay_commission== 'percent') {
                    commission= (this.calc_give/100)*this.payment.replenish_commission;
                } else {
                    commission= this.payment.replenish_commission;
                }
                return Number(Number(commission).toFixed(2));
            }
        },
        watch:{
            payment: function () {
                this.calc_give= this.payment.min_replenish;
                this.calcSum(this.calc_give, 'give');
                // this.$emit('update:active_payment', val);

            },
            calc_give(val) {
              if(!val) {
                this.formValidate.amount_coin = null;
              }
            },
            'form.wallet'(val) {
              if (val == 1 && this.payment.id == 24) {
                this.payment = this.usdtPayments[0];
              }
            }
        },
        methods: {
          addMin() {
            this.calc_give = this.payment.min_replenish;
          },
            swipe (direction) {
                this.swipeDirection = direction
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },
            onSubmit: function () {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        wallet: [],
                        amount:[],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = this.form;
                    response.payment_id = this.payment.id;

                    this.$http.post("ajax/deposit/create", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                // response= {
                                //     "data": {
                                //         "address": "14dGw2EEQx78SgXjWuFjFixQXWCVMYY1zQ",
                                //         "amount": "0.1",
                                //         "payment_id": 2,
                                //         "tag": false,
                                //         "time": "2021/02/22 21:03:39",
                                //         "id": 1020
                                //     },
                                //     "status": 200
                                // };


                                if (response.data.action) {
                                    let form = document.createElement('form');
                                    form.action = response.data.action;
                                    form.method = response.data.method;
                                    Object.keys(response.data.form).forEach(key => {
                                        form.innerHTML += '<input type="hidden" name="' + key + '" value="' + response.data.form[key] + '">';
                                    });
                                    document.body.append(form);
                                    form.submit();
                                } else {
                                    this.$emit('pushDeposit', response.data.deposit)
                                }
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
            calcSum(val, type= 'give') {
                let payment= this.payment;
                if(type== 'give') {
                    let val_give= val;
                    if(payment.pay_commission== 1) {
                        val_give= Number(val)- this.commission;
                    }
                    let calc_get= Number((val_give*payment.course_usd)*1);

                    if(this.form.wallet== 2) {
                        calc_get= calc_get/this.silver.course_lite;

                        calc_get-= (calc_get/100*this.silver.percent_lite);
                    }

                    calc_get= this.primNum(calc_get, 2);
                    this.calc_get= calc_get;
                    this.form.amount= calc_get;
                    this.form.amount_coin= val;
                } else {
                    // let calc_give= Number(this.primNum(val/payment.course_usd / ((this.form.wallet== 1) ? 1 : 1/this.silver.course_lite), payment.sprintf);
                    //
                    // console.log('calc_give');
                    // console.log(calc_give);
                    // console.log(this.commission);
                    // if(payment.pay_commission== 1) {
                    //     calc_give+= this.commission;
                    // }
                    // console.log('calc_givecalc_give');
                    // console.log(calc_give);
                    //
                    // this.calc_give= calc_give;
                    // this.form.amount= this.calc_get;
                    // this.form.amount_coin= calc_give;

                }
                if (this.calc_give) {
                  this.updateValidate('amount_coin');
                }
            },
            customFilter (item, queryText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();
                return textOne.indexOf(searchText) > -1
            },
        },

        components: {
            InputLabelTextDefault,
            // Switcher
        },

    }
</script>

<style lang="scss">

  .dark {
    .wrap-deposits {
      &__body {
        background: #171827;

        .wrapper-payment-select {
          span {
            color: #96AFD1;
          }
        }

        .block-bottom {
          p {
            color: #96AFD1;
          }
        }
      }
      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
        background: #212232;
        border-color: transparent;

        &:hover {
          border-color: #0F9AFF;
          background: #181818!important;
        }
      }

      .text {
        &--label {
          color: #96AFD1;
        }
      }

      .currency {
        color: #96AFD1;
      }
    }
  }

  .wrap-deposits {
    background-color: #FFFFFF;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    &__head {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid #ecf4ff;

      svg {
        margin-right: 18px;
      }


      p {
        font-size: 16px;
        line-height: 22px;
        color: #002B50;
        margin: 0!important;
      }
    }


    &__body {
      padding: 20px;

      .wrapper-payment-select {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &__item {
          width: 50%;
        }

        svg {
          margin: 20px 20px 0;
          flex-shrink: 0;
        }

        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #6886AF;
          margin-bottom: 12px;
          display: block;
        }
      }

      .wrapper-inputs {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &__item {
          width: 50%;
        }

        svg {
          margin: 20px 26px 0;
        }
      }

      .wrapper-block-info {
        display: flex;
        align-items: center;
        margin-bottom: 26px;

        .block-info {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }

          p {
            font-size: 14px;
            line-height: 19px;
            color: #6886AF;
            margin-bottom: 2px;
          }

          span {
            font-size: 20px;
            line-height: 27px;
            color: #26A9F4;
          }
        }
      }

      .block-bottom {
        display: flex;
        align-items: center;
        margin-top: 40px;

        &__info {
          margin-left: 20px;
        }

        p {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.001em;
          color: #6886AF;
          margin: 0;
          
          a {
            color: #26A9F4;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }


    .text {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #002B50;
      margin:0 0 10px;

      &--desc {
        color: #6886AF;
        margin-bottom: 10px;
      }

      &--label {
        color: #6886AF;
        margin-bottom: -5px;
        position: relative;
        z-index: +1;
      }
    }

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
      background: #FFFFFF;
      /*height: 64px;*/
      padding: 12px 19px 12px 17px;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #002B50;
      position: relative;

      .v-icon.v-icon {
        font-size: 0;
      }
    }
    .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
      background: transparent;
    }

    .chevron-icon {
      position: absolute;
      right: 0;
      top: 16px;
      transition-duration: .3s;
    }

    .v-select--is-menu-active {
      .chevron-icon {
        transform: rotate(180deg);
        transition-duration: .3s;
      }
    }

    .v-input--selection-controls {
      margin-top: 0px;
      padding-top: 4px;
      margin-bottom: 10px;
    }

    .v-input--radio-group.v-input--radio-group--row .v-radio {
      margin-right: 38px;
    }

    .currency {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 25%;
      font-weight: 400;;
      font-size: 14px;
      line-height: 18px;
      color: #6886AF;
      cursor: default;
      white-space: nowrap;
    }

    .wrap-price__view {
      position: relative;
      display: flex;
      align-items: center;
      background: #f0f6fe;
      border-radius: 8px;
      min-width: 180px;
      height: 40px;
      margin-left: 10px;
      margin-bottom: 20px;

      .block-price-view {
        display: flex;
        width: 100%;
        align-items: center;


        .price-text {
          margin-left: 12px;
          display: flex;
          width: 100%;
          justify-content: center;

          &__wrap {
            display: flex;
            align-items: center;


            span {
              min-width: 80px;
              font-size: 14px;
            }
          }

          .pic {
            margin-top: 1px;
            height: 14px;
            margin-right: 6px;
          }
        }
        &.active + .price-btn {
          left: calc(100% - 40px);
        }
      }
      .price-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background-color: #d8e5f7;
        border-radius: 8px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        transition: 0.7s;
        pointer-events: none;
      }
    }

    .text-charged {
      margin: 21px 0 0;
      font-weight: 400;;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #6886AF;

      /*span {*/
      /*  color: #00C443;*/
      /*  font-weight: bold;*/
      /*}*/
    }
    .text-commission {
      font-weight: 400;;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #6886AF;
      margin: 0 0 21px;

      /*span {*/
      /*  font-weight: bold;*/
      /*}*/
    }

    .v-btn.btn-cab {
      &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
        min-width: 154px;
        padding: 0 12px;
      }
    }
    .img {
      height: 40px;
      min-width: 40px;
      margin-right: 16px;
      border-radius: 50%;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .img.mind {
      background-image: url(/dist/images/frontend/mind_bep.svg);
      background-size: 32px;
    }

    .img.btc{
      background-color: #FF7A00;
      background-image: url(/dist/images/frontend/wallets/white-btc.svg);
    }
    .img.bsc-usd_bep20 {
      background-color: #f3ba2f;
      background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
    }
    .img.bnb{
      background-color: #f3ba2f;
      background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
    }

    .img.ada{
      background-color: #2A76D0;
      background-image: url(/dist/images/frontend/wallets/white-ada.svg);
    }
    .img.dot{
      background-color: #666;
      background-image: url(/dist/images/frontend/wallets/white-dot.svg);
    }
    //
    //.img.usdt {
    //  background-color: #4FD984;
    //  background-image: url(../../../public/images/wallets/white-usdt.svg);
    //}
    .img.ltc {
      background-color: #BBBBBB;
      background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
    }
    .img.doge {
      background-color: #D4BE00;
      background-image: url(/dist/images/frontend/wallets/white-doge.svg);
    }
    .img.usdt_trc20 {
      background-color: #C3332D;
      background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
    }
    .img.eth {
      background-color: #4A84FF;
      background-image: url(/dist/images/frontend/wallets/white-eth.svg);
    }
    .img.xrp {
      background-position: 10px center;
      background-color: #2DA9E8;
      background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
    }
    .img.bch {
      background-color: #8DC351;
      background-image: url(/dist/images/frontend/wallets/white-bch.svg);
    }
    .img.trx {
      background-color: #C3332D;
      background-image: url(/dist/images/frontend/wallets/white-trx.svg);
    }
    .img.link {
      background-color: #2A5ADA;
      background-image: url(/dist/images/frontend/wallets/white-link.svg);
    }
    .img.payeer {
      background-color: #2A93FF;
      background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
    }
    .img.pm {
      background-color: #FF0000;
      background-image: url(/dist/images/frontend/wallets/white-pm.svg);
    }
    .img.etc {
      background-color: #1B8600;
      background-image: url(/dist/images/frontend/wallets/white-etc.svg);
    }
    .img.adv {
      background-color: #0f2d38;
      background-image: url(/dist/images/frontend/wallets/advcash.svg);
      background-size: 20px;
      background-position: 11px center;
    }
    .img.dai{
      background-image: url(/dist/images/frontend/wallets/white-dai.svg);
      background-color: #F9AC13;
    }
    .img.dash{
      background-image: url(/dist/images/frontend/wallets/dash.png);
      /*background-color: #F9AC13;*/
      background-size: 100%;
    }

    .img.mc_uah,
    .img.mc_usd,
    .img.mc_rub {
      background-image: url(/dist/images/frontend/wallets/mc_visa.svg);
      background-color: #333333;
      background-size: 30px;
    }

    .img.visa_uah,
    .img.visa_usd,
    .img.visa_rub {
      background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
      background-color: #00579F;
      background-size: 26px;
    }
  }

  .wrap-item-select {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .payment-item__icon {
      height: 40px;
      width: 40px;
      margin-right: 16px;
    }

    .menu-item {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #002B50;
    }
    .img {
      height: 40px;
      min-width: 40px;
      margin-right: 16px;
      border-radius: 50%;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .img.silver {
      background-image: url(/dist/images/frontend/light-coin.svg);
      background-size: 40px;
    }

    .img.mind {
      background-image: url(/dist/images/frontend/mind_bep.svg);
      background-size: 32px;
    }

    .img.btc{
      background-color: #FF7A00;
      background-image: url(/dist/images/frontend/wallets/white-btc.svg);
    }
    .img.bsc-usd_bep20 {
      background-color: #f3ba2f;
      background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
    }
    .img.bnb{
      background-color: #f3ba2f;
      background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
    }

    .img.ada{
      background-color: #2A76D0;
      background-image: url(/dist/images/frontend/wallets/white-ada.svg);
    }
    .img.dot{
      background-color: #666;
      background-image: url(/dist/images/frontend/wallets/white-dot.svg);
    }
    //
    //.img.usdt {
    //  background-color: #4FD984;
    //  background-image: url(../../../public/images/wallets/white-usdt.svg);
    //}
    .img.ltc {
      background-color: #BBBBBB;
      background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
    }
    .img.doge {
      background-color: #D4BE00;
      background-image: url(/dist/images/frontend/wallets/white-doge.svg);
    }
    .img.usdt_trc20 {
      background-color: #C3332D;
      background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
    }
    .img.eth {
      background-color: #4A84FF;
      background-image: url(/dist/images/frontend/wallets/white-eth.svg);
    }
    .img.xrp {
      background-position: 10px center;
      background-color: #2DA9E8;
      background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
    }
    .img.bch {
      background-color: #8DC351;
      background-image: url(/dist/images/frontend/wallets/white-bch.svg);
    }
    .img.trx {
      background-color: #C3332D;
      background-image: url(/dist/images/frontend/wallets/white-trx.svg);
    }
    .img.link {
      background-color: #2A5ADA;
      background-image: url(/dist/images/frontend/wallets/white-link.svg);
    }
    .img.payeer {
      background-color: #2A93FF;
      background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
    }
    .img.pm {
      background-color: #FF0000;
      background-image: url(/dist/images/frontend/wallets/white-pm.svg);
    }
    .img.etc {
      background-color: #1B8600;
      background-image: url(/dist/images/frontend/wallets/white-etc.svg);
    }
    .img.adv {
      background-color: #0f2d38;
      background-image: url(/dist/images/frontend/wallets/advcash.svg);
      background-size: 20px;
      background-position: 11px center;
    }
    .img.dai{
      background-image: url(/dist/images/frontend/wallets/white-dai.svg);
      background-color: #F9AC13;
    }
    .img.dash{
      background-image: url(/dist/images/frontend/wallets/dash.png);
      /*background-color: #F9AC13;*/
      background-size: 100%;
    }

    .img.mc_uah,
    .img.mc_usd,
    .img.mc_rub {
      background-image: url(/dist/images/frontend/wallets/mc_visa.svg);
      background-color: #333333;
      background-size: 30px;
    }

    .img.visa_uah,
    .img.visa_usd,
    .img.visa_rub {
      background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
      background-color: #00579F;
      background-size: 26px;
    }
  }




  @media screen and (max-width: 600px){
    .wrap-deposits {
      &__body {
        padding: 15px;
        .wrapper-payment-select {
          flex-direction: column;
          margin-bottom: 10px;

          svg {
            display: none;
          }

          &__item {
            width: 100%;
          }
        }

        .wrapper-inputs {
          flex-direction: column;
          margin-bottom: 20px;

          svg {
            display: none;
          }

          &__item {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px){

    .wrap-deposits {
      .img {
        height: 30px;
        min-width: 30px;
        margin-right: 8px;
        border-radius: 50%;
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
      }
      .img.xrp {
        background-position: 7px center;
      }

      .img.adv {
        background-size: 16px;
        background-position: 8px center;
      }

      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
        padding: 8px 12px 8px 10px;

        .v-icon.v-icon {
          font-size: 0;
        }

        .payment-item__icon {
          height: 30px;
          width: 30px;
          margin-right: 8px!important;
        }
      }
      &__body {
        .block-bottom {
          flex-direction: column;
          margin-top: 20px;

          &__info {
            margin: 10px 0 0;

            p {
              text-align: center;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .wrapper-payment-select {
          span {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .text {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .v-menu__content {
      .v-list-item {
        padding: 0 10px!important;
      }
    }

    .wrap-item-select {
      padding: 10px 0;
      .payment-item__icon {
        height: 30px;
        width: 30px;
        margin-right: 8px;
      }

      .menu-item {
        font-size: 14px;
        line-height: 16px;

      }
      .img {
        height: 30px;
        min-width: 30px;
        margin-right: 8px;
        border-radius: 50%;
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
      }
      .img.xrp {
        background-position: 7px center;
      }

      .img.adv {
        background-size: 16px;
        background-position: 8px center;
      }
    }
  }

</style>
