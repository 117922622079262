<template>
    <div class="page-myorders">
        <LayoutNavAuth>
            <div class="wrap-tabs pa-0">
                <tabs
                        :tabs="side_tabs"
                        :currentTab="currentTab"
                        :wrapper-class="'default-tabs'"
                        :tab-class="'default-tabs__item'"
                        :tab-active-class="'default-tabs__item_active'"
                        :line-class="'default-tabs__active-line'"
                        @onClick="sideChange"
                />
            </div>
            <div class="wrap-select">
                <DropdownDefault
                        class_modal="modal_game"
                        color="select_game"
                        v-bind:dropdown="side_tabs"
                        v-bind:value.sync="side_tabs.value"
                        @updateValue="sideChange"
                />
            </div>

            <div class="loading"  :style="{width: progress + '%'}"></div>
        </LayoutNavAuth>

        <div class="dashboard-content">
            <div class="page-myorders__head">
                <v-btn
                        text
                        v-ripple="false"
                        class="btn-back"
                >
                    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.90929 7.81789C4.03006 7.69737 4.03025 7.5018 3.90974 7.38101L0.707886 4.1725C0.587442 4.05204 0.587457 3.85608 0.708118 3.73541L3.90974 0.527127C4.03026 0.406358 4.03006 0.210786 3.90929 0.0902487C3.78852 -0.0302577 3.59294 -0.0300569 3.47243 0.0906966L0.271024 3.29876C0.0903256 3.47949 0 3.71681 0 3.95416C0 4.19137 0.0902946 4.42863 0.270807 4.60913L3.47243 7.81744C3.59294 7.93819 3.78852 7.9384 3.90929 7.81789Z" fill="currentColor"/>
                    </svg>
                </v-btn>
                <span>{{__('Back to Store')}}</span>
            </div>

            <div class="page-myorders__body">
                <div class="list-orders">
                    <ItemMyOrders></ItemMyOrders>
                    <ItemMyOrders></ItemMyOrders>
                    <ItemMyOrders></ItemMyOrders>
                    <ItemMyOrders></ItemMyOrders>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutNavAuth from "../../modules/LayoutNavAuth";
    import DropdownDefault from "../../modules/DropdownDefault";
    import ItemMyOrders from "./ItemMyOrders";
    export default {
        data() {
            return {
                side_tabs: [
                    {
                        'title': 'Merch store',
                        'value': 'store'
                    },
                    {
                        'title': 'My orders',
                        'value': 'orders'
                    }
                ],
                loading_page: false,
                progress: 0,
            }
        },
        computed: {
            currentTab: function () {
                return (this.$route.params.current) ? this.$route.params.current : 'store'
            },
        },
        methods: {
            sideChange(newTab) {
                if (newTab != this.currentTab) {
                    this.$router.push({name: 'quests_current', params: {'current': newTab, lang: this.$store.getters.getLang}});
                    // this.data = false;
                    this.getQuests(newTab);
                }
            },
        },
        components: {
            LayoutNavAuth,
            DropdownDefault,
            ItemMyOrders
        }
    }
</script>

<style lang="scss" scoped>
    .page-myorders {
        color: #fff;

        &__head {
            margin: 20px 0;
            display: flex;
            align-items: center;

            .btn-back {
                color: #fff;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #171827;
                border: 1px solid #252525;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-right: 10px;

                &:hover {
                    color: #26A9F4;
                    background-color: #262626;
                }

            }

            .v-btn:before {
                background-color: #262626;
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 24px;
                min-width: 24px;
                padding: 0;
            }

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #FFFFFF;
                cursor: default;
                margin: 0;
                display: block;
            }
        }

        &__body {
            .list-orders {
                padding: 24px 0 30px;
                background: #171827;
                box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
            }
        }
    }
</style>