<template>
    <div class="form-crypto">
        <div class="form-crypto__head">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.421 7.35923C19.7785 8.69142 19.8449 10.0865 19.614 11.4492C19.363 12.9307 18.7676 14.3326 17.8757 15.5419C16.9838 16.7513 15.8204 17.7342 14.4791 18.4117C13.1378 19.0892 11.6562 19.4422 10.1536 19.4423L10.1536 19.6923L10.1532 19.4423C8.77021 19.4446 7.40308 19.148 6.14543 18.5727C4.88779 17.9974 3.76935 17.1571 2.86672 16.1093C1.96409 15.0615 1.29861 13.831 0.915856 12.502C0.533102 11.1731 0.442119 9.77707 0.649141 8.40968C0.856162 7.04229 1.3563 5.7358 2.11528 4.5797C2.87427 3.42361 3.87418 2.44523 5.04651 1.71157C6.21885 0.977922 7.53591 0.506334 8.90749 0.329115C10.1599 0.167298 11.4307 0.2544 12.6472 0.584089C12.2609 1.13121 11.9921 1.75287 11.8581 2.4098C10.3136 2.05611 8.69473 2.19136 7.22714 2.80054C5.67552 3.44461 4.37966 4.58249 3.5404 6.03782C2.70113 7.49315 2.36533 9.18467 2.58503 10.8502C2.80473 12.5158 3.56766 14.0624 4.75559 15.2503C5.94352 16.4382 7.49011 17.2012 9.15567 17.4209C10.8212 17.6406 12.5127 17.3048 13.9681 16.4655C15.4234 15.6262 16.5613 14.3304 17.2053 12.7788C17.8145 11.3112 17.9498 9.69229 17.5961 8.14777C18.2527 8.01386 18.8741 7.7452 19.421 7.35923Z" fill="#26A9F4" stroke="transparent" stroke-width="0.5"/>
                <path d="M10.1537 5.48077L10.1538 5.48077C10.7395 5.48105 11.3012 5.71369 11.7157 6.12765L13.8693 8.28134C13.8694 8.28136 13.8694 8.28138 13.8694 8.2814C14.2838 8.69609 14.5166 9.25836 14.5166 9.84462C14.5166 10.4309 14.2838 10.9931 13.8694 11.4078C13.8694 11.4079 13.8694 11.4079 13.8693 11.4079L11.7156 13.5616C11.3009 13.9761 10.7386 14.2089 10.1523 14.2089C9.56604 14.2089 9.00375 13.9761 8.58905 13.5616L6.43532 11.4079C6.02089 10.9932 5.78809 10.4309 5.78809 9.84462C5.78809 9.25836 6.02087 8.69609 6.43526 8.2814C6.43528 8.28138 6.4353 8.28136 6.43532 8.28134L8.58901 6.12765C8.79454 5.92234 9.03851 5.75954 9.30698 5.64855C9.57545 5.53756 9.86316 5.48055 10.1537 5.48077ZM9.97709 12.1768L10.1539 12.3536L10.3306 12.1768L12.4845 10.0229L12.6613 9.84616L12.4845 9.66938L10.3306 7.51554L10.1539 7.33876L9.97709 7.51554L7.82325 9.66938L7.64647 9.84616L7.82325 10.0229L9.97709 12.1768Z" fill="#26A9F4" stroke="transparent" stroke-width="0.5"/>
                <path d="M15.8114 4.29743L15.7741 4.22289L15.6996 4.18562L14.5365 3.60409C14.5365 3.60409 14.5365 3.60408 14.5365 3.60408C14.4958 3.5837 14.4615 3.55238 14.4375 3.51362C14.4136 3.47485 14.4009 3.43018 14.4009 3.38461C14.4009 3.33904 14.4136 3.29437 14.4375 3.25561C14.4615 3.21685 14.4958 3.18552 14.5365 3.16514C14.5365 3.16514 14.5365 3.16514 14.5365 3.16514L15.6996 2.5836L15.7741 2.54634L15.8114 2.4718L16.3929 1.30873C16.3929 1.30873 16.3929 1.30873 16.3929 1.30872C16.4133 1.26797 16.4446 1.2337 16.4834 1.20974C16.5222 1.18578 16.5668 1.1731 16.6124 1.1731C16.658 1.1731 16.7026 1.18579 16.7414 1.20974C16.7802 1.2337 16.8115 1.26797 16.8319 1.30872C16.8319 1.30873 16.8319 1.30873 16.8319 1.30873L17.4134 2.4718L17.4507 2.54634L17.5252 2.5836L18.6883 3.16514C18.6883 3.16514 18.6883 3.16514 18.6883 3.16514C18.729 3.18552 18.7633 3.21685 18.7873 3.25561C18.8112 3.29437 18.8239 3.33904 18.8239 3.38461C18.8239 3.43018 18.8112 3.47485 18.7873 3.51362C18.7633 3.55238 18.729 3.58371 18.6883 3.60409L17.5252 4.18562L17.4507 4.22289L17.4134 4.29743L16.8319 5.46049C16.8319 5.4605 16.8319 5.4605 16.8319 5.4605C16.8115 5.50126 16.7802 5.53553 16.7414 5.55948C16.7026 5.58344 16.658 5.59613 16.6124 5.59613C16.5668 5.59613 16.5222 5.58344 16.4834 5.55948C16.4446 5.53553 16.4133 5.50126 16.3929 5.4605C16.3929 5.4605 16.3929 5.4605 16.3929 5.46049L15.8114 4.29743Z" fill="#26A9F4" stroke="transparent" stroke-width="0.5"/>
            </svg>



            <span>{{__('Add a wallet for cryptocurrency')}}</span>
        </div>
        <div class="form-crypto__body">
            <div v-if="payments && payments.length" class="form-currency">
                <span>{{__('Withdraw currency')}}</span>
                <v-select
                        dense
                        filled
                        solo
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="wallet"
                        :items="payments"
                        :filter="customFilter"
                        item-color="white"
                >
                    <template v-slot:selection="data">
                        <div class="img" :class="data.item.name_class"></div>
                        {{ data.item.name }}
                        <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
                    </template>
                    <template v-slot:item="data">
                        <template>
                            <div class="wrap-item-select">
                                <div class="img" :class="data.item.name_class"></div>
                                <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                            </div>
                        </template>
                    </template>
                </v-select>
            </div>

            <div v-else class="block-select-empty">
                <v-tooltip
                        v-model="show"
                        bottom
                        color="transparent"
                        nudge-top="10"
                        style="z-index: 100"

                >
                    <template v-slot:activator="{ on, attrs }">
                        <div class="block-select" v-on="on" v-bind="attrs">
                            <div class="d-flex align-center">
                                <div class="img btc"></div>
                                <p>Bitcoin</p>
                            </div>

                            <img src="/dist/images/frontend/chevron-down.svg" alt />
                        </div>
                    </template>
                    <div class="select-info">
                        <p class="ma-0">{{__('You have saved all the data for payment systems')}}</p>
                    </div>
                </v-tooltip>
            </div>

            <div class="wrap-form-wallet" v-if="wallet.currency== 'xrp'">
                <div class="wrap-form-wallet__item">
                    <v-text-field
                            color="light-blue"
                            :label='__("Crypto wallet")'
                            hide-details="auto"
                            v-model="address"
                            v-bind:errors="formValidate.address"
                            @change="updateValidate('address')"
                            @input="formValidate.address = null"
                    ></v-text-field>
                    <div>
                        <p v-for="(value, key) in formValidate.address" :key="key">{{value}}</p>
                    </div>
                </div>

                <div class="wrap-form-wallet__item">
                    <v-text-field
                            color="light-blue"
                            :label='__("Tag")'
                            hide-details="auto"
                            v-model="tag"
                            v-bind:errors="formValidate.tag"
                            @change="updateValidate('tag')"
                            @input="formValidate.tag = null"
                    ></v-text-field>
                    <div>
                        <p v-for="(value, key) in formValidate.tag" :key="key">{{value}}</p>
                    </div>
                </div>
            </div>

            <div class="form-wallet" v-else>
                <v-text-field
                        color="light-blue"
                        :label='__("Crypto wallet")'
                        hide-details="auto"
                        v-model="address"
                        v-bind:errors="formValidate.address"
                        @change="updateValidate('address')"
                        @input="formValidate.address = null"
                ></v-text-field>
                <div>
                    <p v-for="(value, key) in formValidate.address" :key="key">{{value}}</p>
                </div>
            </div>
        </div>
        <div class="wrap-btn">
            <BaseButton v-on:click="addWallet">
                <span>{{__('Save changes')}}</span>
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import {minLength, required} from "vuelidate/lib/validators";

    export default {
        data() {
            return {
                show: false,
                wallet: this.payments[0],
                address: null,
                tag: null,
                formValidate: {
                    tag: null,
                    address: null,
                },
            }
        },
        validations() {
            return {
                address: {
                    required,
                    minLength: minLength(5),
                },
                tag: {
                    required: ((this.wallet.currency== 'xrp')? required : false),
                    minLength: minLength(5),
                },
            }
        },
        props: {
            payments: {
                type: Array
            },
            modal_security: {
                type: [Boolean, Object]
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            customFilter (item, queryText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();
                return textOne.indexOf(searchText) > -1
            },
            updateValidate(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            addWallet() {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        address:[],
                        tag:[],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    let response= {
                        "payment_id": this.wallet.id,
                        "address": this.address,
                        "tag": this.tag
                    };
                    if(this.auth.google_two_step == 1) {
                        this.$emit('update:modal_security', response);
                        this.address = null;
                        this.tag = null;
                    } else {
                        this.$emit('sendCode');
                        this.$emit('update:modal_security', response);
                        this.address = null;
                        this.tag = null;
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    .form-crypto {
        padding: 20px;

        &__head {
            display: flex;
            margin-bottom: 18px;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 20px;
            }

            span {
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }
        &__body {
            margin-left: 42px;
            margin-bottom: 30px;

            .block-select-empty {
                .block-select {
                    width: 60%;
                    height: 58px;
                    padding: 8px 19px 8px 16px;
                    border: 1px solid #EBEBEB;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    opacity: .5;

                    p {
                        margin: 0;
                    }
                }
            }

            .form-currency {
                margin-bottom: 15px;
                width: 60%;
                span {
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #6886AF;
                    margin-bottom: 10px;
                    margin-left: 2px;
                    display: block;
                }
                .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
                    background: #FFFFFF;
                    /*height: 48px;*/
                    padding: 8px 19px 8px 16px;
                    border: 1px solid #EBEBEB;
                    box-sizing: border-box;
                    border-radius: 4px;
                    box-shadow: none;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #002B50;
                    position: relative;

                    .v-icon.v-icon {
                        font-size: 0;
                    }
                }
                .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
                    background: transparent;
                }

                .chevron-icon {
                    position: absolute;
                    right: 0;
                    top: 17px;
                    transition-duration: .3s;
                }

                .v-select--is-menu-active {
                    .chevron-icon {
                        transform: rotate(180deg);
                        transition-duration: .3s;
                    }
                }

                .v-input--selection-controls {
                    margin-top: 0px;
                    padding-top: 4px;
                    margin-bottom: 10px;
                }

                .v-input--radio-group.v-input--radio-group--row .v-radio {
                    margin-right: 38px;
                }

                .menu-item {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #002B50;
                }
            }

            .wrap-form-wallet {
                display: flex;
                align-items: center;
                margin-bottom: 22px;
                width: 100%;

                &__item {
                    &:first-child {
                        width: 55%;
                        margin-right: 30px;
                    }

                    &:last-child {
                        width: 45%;
                    }

                    p {
                        position: absolute;
                        color: #FF4B55;
                        font-size: 10px;
                        margin: 0;
                    }
                }
            }

            .form-wallet {
                width: 60%;
                margin-bottom: 22px;

                p {
                    position: absolute;
                    color: #FF4B55;
                    font-size: 10px;
                    margin: 0;
                }
            }
        }
        .wrap-btn {
            display: flex;
            margin-left: 42px;
        }
        .img {
            height: 40px;
            min-width: 40px;
            margin-right: 16px;
            border-radius: 50%;
            background-size: 18px;
            background-repeat: no-repeat;
            background-position: center;
        }

        .img.silver {
            background-image: url(/dist/images/frontend/light-coin.svg);
            background-size: 40px;
        }

        .img.btc{
            background-color: #FF7A00;
            background-image: url(/dist/images/frontend/wallets/white-btc.svg);
        }
        .img.bsc-usd_bep20 {
            background-color: #f3ba2f;
            background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
        }
        .img.bnb{
            background-color: #f3ba2f;
            background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
        }

        .img.ada{
            background-color: #2A76D0;
            background-image: url(/dist/images/frontend/wallets/white-ada.svg);
        }
        .img.dot{
            background-color: #666;
            background-image: url(/dist/images/frontend/wallets/white-dot.svg);
        }
        //
        //.img.usdt {
        //  background-color: #4FD984;
        //  background-image: url(../../../public/images/wallets/white-usdt.svg);
        //}
        .img.ltc {
            background-color: #BBBBBB;
            background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
        }
        .img.doge {
            background-color: #D4BE00;
            background-image: url(/dist/images/frontend/wallets/white-doge.svg);
        }
        .img.usdt_trc20 {
            background-color: #C3332D;
            background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
        }
        .img.eth {
            background-color: #4A84FF;
            background-image: url(/dist/images/frontend/wallets/white-eth.svg);
        }
        .img.xrp {
            background-position: 10px center;
            background-color: #2DA9E8;
            background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
        }
        .img.bch {
            background-color: #8DC351;
            background-image: url(/dist/images/frontend/wallets/white-bch.svg);
        }
        .img.trx {
            background-color: #C3332D;
            background-image: url(/dist/images/frontend/wallets/white-trx.svg);
        }
        .img.link {
            background-color: #2A5ADA;
            background-image: url(/dist/images/frontend/wallets/white-link.svg);
        }
        .img.payeer {
            background-color: #2A93FF;
            background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
        }
        .img.pm {
            background-color: #FF0000;
            background-image: url(/dist/images/frontend/wallets/white-pm.svg);
        }
        .img.etc {
            background-color: #1B8600;
            background-image: url(/dist/images/frontend/wallets/white-etc.svg);
        }
        .img.adv {
            background-color: #0f2d38;
            background-image: url(/dist/images/frontend/wallets/advcash.svg);
            background-size: 20px;
            background-position: 11px center;
        }
        .img.dai{
            background-image: url(/dist/images/frontend/wallets/white-dai.svg);
            background-color: #F9AC13;
        }
        .img.dash{
            background-image: url(/dist/images/frontend/wallets/dash.png);
            /*background-color: #F9AC13;*/
            background-size: 100%;
        }

        .img.mc_uah,
        .img.mc_usd,
        .img.mc_rub {
            background-image: url(/dist/images/frontend/wallets/mc.svg);
            background-color: #333333;
            background-size: 24px;
        }

        .img.visa_uah,
        .img.visa_usd,
        .img.visa_rub {
            background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
            background-color: #00579F;
            background-size: 26px;
        }
    }

    .select-info {
        padding: 10px;
        background: rgb(255, 255, 255);
        border: 1px solid #ECF4FF;
        border-radius: 6px;

        p {
            font-size: 13px;
            line-height: 18px;
            color: #002B50;
            margin: 0;
        }
    }

    @media screen and (max-width: 768px){
        .form-crypto {
            &__body {
                .wrap-form-wallet {
                    flex-direction: column;
                    align-items: flex-start;

                    &__item {
                        &:first-child {
                            width: 60%;
                            margin: 0 0 20px 0;
                        }
                        &:last-child {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px){
        .form-crypto {
            &__body {
                .form-currency {
                    width: 100%;
                }
                .form-wallet {
                    width: 100%;
                }

                .wrap-form-wallet {
                    &__item {
                        &:first-child {
                            width: 100%;
                        }
                        &:last-child {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .dark {
        .form-crypto {

            &__head {
                span {
                    color: #fff;
                }
            }

            &__body {
                .form-currency {
                    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
                        background: #212121;
                        border: 1px solid rgba(255, 255, 255, 0.07);;
                    }
                }
            }
        }
        .wrap-item-select .menu-item {
            color: #fff;
        }
    }

</style>