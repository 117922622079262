<template>
    <div class="modal-gallery-game">
        <v-dialog v-model="dialog" max-width="500px">
            <div class="controls-game">
                <!--<div class="controls-game__head">-->
                    <!--<v-btn text icon class="icon" @click="dialog = false">-->
                        <!--<img src="/dist/images/frontend/close.svg" alt="/" />-->
                    <!--</v-btn>-->
                <!--</div>-->
                <!--<div class="controls-game__body"></div>-->

                <DefaultSlider v-bind:scroll.sync="scroll" v-bind:slides="game.gallery"></DefaultSlider>

            </div>
        </v-dialog>
    </div>
</template>

<script>
import DefaultSlider from "../../modules/DefaultSlider";
    export default {
        data() {
            return {
                dialog: true,

                settings: {
                    "arrows":true,
                    "dots": false,
                    "infinite": false,
                    "autoplay": false,
                    "speed": 500,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "initialSlide": 0,

                }
            }
        },

        props: {
            gallery_game_modal: {
                type: Boolean
            },
            game: {
                type: Object,
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:gallery_game_modal", false);
            },
        },
        components: {
            DefaultSlider,
        }
    }
</script>

<style lang="scss">

    .modal-gallery-game {
        overflow: hidden;
    }

        .v-overlay--active {
            .v-overlay__scrim {
                opacity: 0.7!important;
            }
        }
</style>