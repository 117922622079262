<template>
  <div>
    <div class="wrapper-item">
<!--      <router-link :to="{name: 'profile', props: {id: management.public_id}}">-->
        <v-list>
          <v-list-item>
<!--            <div class="wrapper-item__img"  style="position: relative">-->
<!--                <router-link :to="{name: 'profile', props: {id: management.public_id}}">-->
<!--                  <v-list-item-avatar class="ma-0" size="48px">-->
<!--                    <img-->
<!--                      :src="'/assets/users/'+((management.photo) ? management.photo : 'a-default.svg')"-->
<!--                    />-->
<!--                  </v-list-item-avatar>-->
<!--                </router-link>-->
<!--            </div>-->
              <div style="position: relative">
                  <div class="block-img">
                      <router-link :to="{name: 'profile', params: {id: management.public_id, lang: $store.getters.getLang}}">
                          <div class="wrap-img">
                              <img v-if="management.photo && typeof management.photo == 'object'" :src="management.photo.original">
                              <img v-else-if="management.photo" :src="management.photo">
                              <span v-else class="photo" :style="{'background': management.bg_color}">{{management.name.substr(0,1)}}</span>
                          </div>
                      </router-link>
                      <div v-if="!management.online" class="online"></div>
                  </div>
              </div>
            <v-list-item-content>
                <router-link :to="{name: 'profile', params: {id: management.public_id, lang: $store.getters.getLang}}">
                    <v-list-item-title>
                        {{management.name+" "+((management.last_name) ? management.last_name : '')}}
                    </v-list-item-title>
                </router-link>
              <v-list-item-subtitle v-if="role=='admin'">
<!--                <img width="14" class="mr-2" :src="'/dist/images/flags/'+management.location_img" />-->
<!--                <span>{{management.location}}</span>-->
                Administrator
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                Tester
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
<!--      </router-link>-->


<!--      <div class="border"></div>-->
      <div>
        <div v-if="role=='admin'" class="block-btn">
              <img class="disabled-icon" src="/dist/images/frontend/icon-del.svg" alt />
<!--          <p class="mb-0 status">{{__('Administrator')}}</p>-->
<!--          <p class="mb-0 text-right" >It is you</p>-->
        </div>
        <div v-else class="block-btn">
<!--          <p class="mb-0 status">Testers</p>-->
          <a
            v-if="!submitDisabled"
            v-on:click="$emit('update:management_event', {command: 'remove', friend_id: management.id}), submitDisabled= true"
            class="btn-link"
          >
            <img src="/dist/images/frontend/icon-del-red.svg" alt />
          </a>
          <a v-else class="grey--text">loading...</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManagementItemList",
  data() {
    return {
      submitDisabled: false,
    };
  },
  props: {
    management: {
      type: Object,
    },
    role: {
      type: String,
      default: "user",
    },
    management_event: {
      type: Object,
    },
  },
  methods: {},
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>

.dark {
    .wrapper-item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);

        .v-list {
            background: transparent;
        }

        .v-list-item {
            &__title {
                color: #fff;
            }
            &__subtitle {
                color: #96AFD1!important;
            }
        }
    }
}

.wrapper-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DAE6F1;
  padding-right: 10px;

    a {
        text-decoration: none;

        &:hover {

            .v-list-item__title {
                text-decoration: underline;
                color: #26A9F4;
            }
        }
    }

    .block-img {
        position: relative;
        width: 40px;
        height: 40px;
        margin-right: 10px;

        a {
            text-decoration: none;
        }

        .online {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 3px;
            bottom: 1px;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            background-color: #00C569;
        }

        .wrap-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .photo {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }

  .v-list-item {
    padding: 0 10px 0 0;

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #002B50;
      text-decoration: none;
    }

    &__content {
      padding: 0;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #6886AF!important;
    }
  }

  .block-btn {
    display: flex;
    align-items: flex-end;

      .disabled-icon {
          opacity: 0.5;
      }

      a {
          text-decoration: none;
          display: flex;
          align-items: flex-end;
          transition-duration: .3s;

          &:hover {
              transform: scale(1.1);
              transition-duration: .3s;
          }
      }
  }
}

@media screen and (max-width: 500px) {
    .wrapper-item {
        .v-list-item {
            &__title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 160px;
            }
        }
    }
}
</style>
