<template>
  <div class="wrapper-attributes">
    <div class="block-create-attributes">
      <span>
        <p class="wrapper-attributes__title">{{__('Attributes')}}</p>
        <p class="wrapper-attributes__desc">{{__('Add required parameters if your game requires it when creating a match!')}}</p>
      </span>
      <div class="wrapper-attributes__info">
        <span class="information-icon">
         <img src="/dist/images/frontend/information-describe-icon.svg" alt />
        </span>

        <div class="info-text">
          <p>{{__('Description of attributes')}}</p>
          <span>{{__('Attributes - a special function that adds a choice of game mode and modifications (changing the game field size or gameplay logic)')}}</span>
        </div>
      </div>
    </div>
    <div>
      <GameAttributesItem v-for="(attribute, key) in attributes" :key="key" v-bind:index_attr="key" v-bind:option_template="option_template" v-bind:attributes="attributes" v-bind:attribute.sync="attribute" @updateAttributes="updateAttributes" @deleteAttributes="deleteAttributes"></GameAttributesItem>
    </div>
    <v-btn class="border-btn" text v-ripple="false" v-on:click="addAttribute" v-if="!attributes || attributes && attributes.length < 5">
      {{__('Create new attribute')}}
    </v-btn>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
                class="border-btn btn-disabled"
                text
                v-on="on"
                v-bind="attrs"
                v-ripple="false"
        >
          {{__('Create new attribute')}}
        </v-btn>
      </template>
      <span>{{__('No more than 5')}}</span>
    </v-tooltip>
  </div>
</template>

<script>
    import GameAttributesItem from './GameAttributesItem';

    export default {
        name: 'GameAttributes',
        data: function() {
            return {
                option_template: {
                    // img: null,
                    name: null,
                    key: null,
                    // description: null,
                    invalid: true
                }
            }
        },
        props: {
            attributes: {
                type: Array,
            }
        },
        computed: {},

        created () {
          if ((this.attributes && this.attributes.length == 0) || !this.attributes) {
            this.addAttribute();
          }
        },

        asyncComputed: {

        },
        methods: {
            addAttribute() {
                let attributes= (this.attributes) ? this.$cleanObject(this.attributes) : [];
                let option= this.option_template;

                let attribute= {
                    name: null,
                    key: null,
                    options: [option, option]
                };

                attributes.push(attribute);
                this.$emit('updateAttributes', attributes)
            },
            updateAttributes(attributes) {
                this.$emit('updateAttributes', attributes)
            },
            deleteAttributes(index) {
                let attributes= this.$cleanObject(this.attributes);
                attributes.splice(index, 1);
                this.$emit('updateAttributes', attributes);
            }
        },
        components: {
            GameAttributesItem
        },
    }
</script>

<style lang="scss" scoped>

  .dark {
    .wrapper-attributes {
       &__title {
         color: #fff;
       }
      &__desc {
        color: #96AFD1;
      }

      &__info {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);

        .information-icon {
          img {
            filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
          }
        }

        .info-text {
          p {
            color: #96AFD1;
          }

          span {
            color: #fff;
          }
        }
      }
    }
  }

  .wrapper-attributes {

    .block-create-attributes {
      /*display: flex;*/
      /*align-items: center;*/
      /*justify-content: space-between;*/
      margin-bottom: 30px;
      margin-top: -5px;
    }

    &__title {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #002B50;
      margin-bottom: 5px;
    }

    &__desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #6886AF;
      margin: 0 0 20px;
    }

    &__info {
      display: flex;
      margin-bottom: 8px;
      width: 100%;
      align-items: flex-start;
      padding-bottom: 15px;
      margin-bottom: 25px;
      border-bottom: 1px solid #ECEEF4;

      .information-icon {
        margin: 0 28px 0 0;
        min-width: 20px;
        display: flex;
        justify-content: center;
      }

      .info-text {
        p {
          z-index: 1;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #6886AF;
          margin-bottom: 8px;
        }

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 19px;
          color: #002B50;
        }
      }



    }
    /*.btn-disabled {*/
      /*color: rgba(0,0,0,.26) !important;*/
      /*cursor: default;*/
      /*&:hover {*/
        /*color: rgba(0,0,0,.26) !important;*/
      /*}*/
    /*}*/

    /*.btn-secondary {*/
      /*width: 100%;*/
      /*&.v-btn:not(.v-btn--round).v-size--default {*/
        /*height: 40px;*/
        /*padding: 0 20px;*/
        /*margin: 0;*/
      /*}*/
    /*}*/
  }
  /*.v-btn:not(.v-btn--round).v-size--default {*/
    /*height: 40px;*/
    /*padding: 0 20px;*/
    /*margin: 0;*/
  /*}*/
</style>
