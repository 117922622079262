<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:success_mess', false)">
        <div class="modal-success_mess">
            <div class="frame">
                <input type="checkbox" id="cb">
                <label for="cb" class="button">{{__('Sending...')}}</label>
                <label for="cb" @click="cancelModal" class="button reset">{{__('Close')}}</label>
                <div class="circle"></div>
                <div class="circle-outer"></div>
                <svg class="icon mail">
                    <polyline points="119,1 119,69 1,69 1,1"></polyline>
                    <polyline points="119,1 60,45 1,1 119,1"></polyline>
                </svg>
                <svg class="icon plane">
                    <polyline points="119,1 1,59 106,80 119,1"></polyline>
                    <polyline points="119,1 40,67 43,105 69,73"></polyline>
                </svg>

                <div class="block-text">
                    <svg class="block-text__icon" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5241 6.85251C22.2889 3.00055 29.0666 0.94458 35.9999 0.94458C45.2972 0.94458 54.2137 4.63792 60.7879 11.2121C67.3621 17.7863 71.0555 26.7028 71.0555 36.0001C71.0555 42.9335 68.9995 49.7111 65.1476 55.476C61.2956 61.2408 55.8207 65.734 49.4151 68.3873C43.0095 71.0405 35.961 71.7348 29.1609 70.3821C22.3608 69.0295 16.1145 65.6908 11.2119 60.7882C6.30928 55.8856 2.97056 49.6393 1.61793 42.8391C0.265308 36.039 0.959525 28.9905 3.6128 22.585C6.26607 16.1794 10.7592 10.7045 16.5241 6.85251ZM18.4377 62.2838C23.6361 65.7573 29.7478 67.6113 35.9999 67.6113C44.3837 67.6113 52.4241 64.2808 58.3523 58.3526C64.2806 52.4243 67.611 44.3839 67.611 36.0001C67.611 29.7481 65.7571 23.6364 62.2836 18.4379C58.8101 13.2395 53.8731 9.18785 48.097 6.79528C42.3208 4.40271 35.9648 3.7767 29.8329 4.99642C23.7009 6.21615 18.0684 9.22681 13.6475 13.6477C9.22658 18.0686 6.21592 23.7012 4.9962 29.8331C3.77647 35.9651 4.40248 42.321 6.79505 48.0972C9.18762 53.8734 13.2393 58.8104 18.4377 62.2838Z" fill="#24E07A"/>
                        <path d="M57.8692 23.2434L57.8696 23.2438C58.031 23.4039 58.1592 23.5943 58.2466 23.8042C58.334 24.0141 58.3791 24.2392 58.3791 24.4665C58.3791 24.6939 58.334 24.919 58.2466 25.1289C58.1592 25.3387 58.031 25.5292 57.8696 25.6893L57.8686 25.6903L30.4217 53.0714L14.1378 36.8311C13.8253 36.4901 13.6598 36.0395 13.6776 35.577C13.6954 35.1115 13.8974 34.6722 14.2392 34.3556C14.581 34.039 15.0346 33.8712 15.5001 33.8891C15.9656 33.9069 16.405 34.1089 16.7215 34.4507L16.728 34.4578L16.7348 34.4645L30.0682 47.7979L30.4209 48.1506L30.7745 47.7987L55.4409 23.2434C55.7636 22.9226 56.2001 22.7426 56.6551 22.7426C57.11 22.7426 57.5465 22.9226 57.8692 23.2434Z" fill="#24E07A"/>
                    </svg>
                    <p>{{__('Your message has been sent successfully!')}}</p>
                    <span>{{__('We will study your request and be sure to respond to your email address within 24 hours')}}</span>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                modal: true,
            };
        },
        props: {
            success_mess: {
                type: Boolean,
            }

        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {},

        mounted() {
            setTimeout(() => {
                document.getElementById('cb').setAttribute("checked", true);
            }, 500)
        },

        methods: {
            cancelModal() {
                this.$emit("update:success_mess", false);
            },
        },
        components: {},
    };
</script>

<style lang='scss' scoped>
    .modal-success_mess {
        text-align: center;
        padding: 45px 25px 30px;
        background: #171827;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;
        max-width: 400px;

        .frame {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 400px;
            height: 400px;
            margin-top: -200px;
            margin-left: -200px;
            box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            background: #171827;
            font-family: 'Open Sans', Helvetica, sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            overflow: hidden;
        }

        .block-text {
            padding: 80px 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: 0;

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 10px;
            }

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #96AFD1;
            }

            &__icon {
                margin-bottom: 25px;
            }
        }

        .circle {
            position: absolute;
            width: 200px;
            height: 200px;
            top: 65px;
            left: 100px;
            background: #181818;
            border-radius: 50%;
        }

        .circle-outer {
            @extend .circle;
            box-sizing: border-box;
            background: none;
            border: 4px solid #181818;
        }

        .icon {
            position: absolute;
            z-index: 2;
            top: 130px;
            left: 140px;
            transform: translate3d(0,0,0);
            fill: none;
            stroke-width: 2px;
            stroke: #ecf0f1;
            stroke-linecap: square;
            stroke-dasharray: 325 325;

            &.mail {
                width: 120px;
                height: 70px;
                stroke-dashoffset: 0;
            }

            &.plane {
                width: 120px;
                height: 110px;
                stroke-dashoffset: 325;
            }
        }

        #cb {
            display: none;
        }

        .button {
            position: absolute;
            z-index: 10;
            width: 200px;
            height: 40px;
            top: 320px;
            left: 100px;
            background: #ecf0f1;
            color: #2c3e50;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
            cursor: pointer;

            &.reset {
                opacity: 0;
                z-index: 5;
            }

            &:hover {
                background: #26A9F4;
                color: #fff;
            }

        }

        #cb:checked ~ .block-text {
            animation: reset 1s ease-in-out 3.7s;
            animation-fill-mode: both;
        }

        #cb:checked ~ .button {
            animation: button 1.5s ease-in-out 1.7s;
            animation-fill-mode: both;
        }
        #cb:checked ~ .reset {
            animation: reset 1s ease-in-out 3.7s;
            animation-fill-mode: both;
        }

        #cb:checked ~ .circle {
            animation: circle 1s ease-in-out;
            animation-fill-mode: both;
        }

        #cb:checked ~ .circle-outer {
            animation: circle .8s ease-in-out .2s;
            animation-fill-mode: both;
        }

        #cb:checked ~ .mail {
            stroke-dashoffset: 326;
            transition: stroke-dashoffset 1s ease-in-out;
        }

        #cb:checked ~ .plane {
            stroke-dashoffset: 0;
            transition: stroke-dashoffset 1s ease-in-out .6s;
            animation: fly 2.4s ease-in-out;
            animation-fill-mode: both;
        }

        @keyframes fly {
            0%, 50% {
                transform: translate3d(0,0,0) scale(1);
            }
            60% {
                transform: translate3d(-10px,5px,0) scale(1.05);
            }
            70% {
                opacity: 1;
            }
            85% {
                opacity: 0;
            }
            100% {
                transform: translate3d(300px,-150px,0) scale(0.4);
                opacity: 0;
            }
        }

        @keyframes circle {
            0% {
                transform: translate3d(0,0,0) scale(1);
            }
            20% {
                transform: scale(1.1);
            }
            100% {
                transform: scale(0);
            }
        }

        @keyframes button {
            0% {
                transform: scale(1);
            }
            30% {
                background: #26A9F4;
                color: transparent;
                width: 200px;
                left: 100px;
            }
            50%,60% {
                width: 40px;
                left: 180px;
                transform: scale(1);
            }
            70% {
                transform: scale(1.1);
            }
            100% {
                width: 40px;
                left: 180px;
                background: #26A9F4;
                color: transparent;
                transform: scale(0);
            }
        }

        @keyframes reset {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .modal-success_mess {
            padding: 35px 20px 25px;

            .frame {
                width: 320px;
                height: 320px;
                margin-top: -160px;
                margin-left: -160px;

                .block-text {
                    padding-top: 35px;

                    &__icon {
                        height: 65px;
                        width: 65px;
                        margin-bottom: 20px;
                    }
                }
            }

            .circle {
                position: absolute;
                width: 150px;
                height: 150px;
                top: 50px;
                left: 85px;
                background: #181818;
                border-radius: 50%;
            }


            .icon {
                top: 90px;
                left: 98px;
                transform: scale(0.75);
            }

            .button {
                width: 150px;
                height: 40px;
                top: 250px;
                left: 85px;
            }
        }

        @keyframes button {
            0% {
                transform: scale(1);
            }
            30% {
                background: #26A9F4;
                color: transparent;
                width: 150px;
                left: 85px;
            }
            50%,60% {
                width: 40px;
                left: 140px;
                transform: scale(1);
            }
            70% {
                transform: scale(1.1);
            }
            100% {
                width: 40px;
                left: 140px;
                background: #26A9F4;
                color: transparent;
                transform: scale(0);
            }
        }

        @keyframes fly {
            0%, 50% {
                transform: translate3d(0,0,0) scale(0.85);
            }
            60% {
                transform: translate3d(-10px,5px,0) scale(0.9);
            }
            70% {
                opacity: 1;
            }
            85% {
                opacity: 0;
            }
            100% {
                transform: translate3d(300px,-150px,0) scale(0.3);
                opacity: 0;
            }
        }
    }

</style>