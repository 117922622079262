<template>
    <div class="block-statistics">
<!--        <div class="block-honors">-->
<!--            <h5>{{__('Awards')}}</h5>-->
<!--            <h5>+{{profile.achievements.count}}</h5>-->
<!--            <v-btn-->
<!--                    text-->
<!--                    v-ripple="false"-->
<!--                    class="border-white"-->
<!--                    @click="achievements_modal = !achievements_modal"-->
<!--            >-->
<!--                {{__('View awards')}}-->
<!--            </v-btn>-->
<!--            <svg width="105" height="124" viewBox="0 0 105 124" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M90.9176 53.9625C101.05 63.7358 106.844 75.7192 106.844 92.1536C106.844 126.393 82.8932 153.354 53.4989 153.354C24.0782 153.354 0 126.374 0 92.1536C0 73.3071 3.65663 61.6595 14.3907 42.2991C17.7872 36.173 26.0344 38.404 26.6734 45.6217C27.2476 52.1073 29.0105 57.6279 31.5792 61.8603C29.8659 38.9902 38.7559 13.2695 60.6763 1.05004C65.7512 -1.77895 71.4142 3.42589 70.0937 9.70556C66.6714 25.9797 70.1975 34.7352 79.4145 44.1674C79.6394 44.3975 80.7876 45.3443 82.3063 46.5965C85.0567 48.8643 89.0219 52.1339 90.9176 53.9625ZM17.5005 67.4392C15.6189 72.3318 13.3553 78.2174 13.3553 92.1535C13.3553 117.863 31.3924 138.074 53.4987 138.074C75.5649 138.074 93.4885 117.897 93.4885 92.1535C93.4885 80.6684 89.6464 72.7228 82.3087 65.6449C81.1505 64.5277 78.4356 62.2721 75.8803 60.149C73.4642 58.1416 71.1908 56.2528 70.5106 55.5566C61.6617 46.5012 56.2519 36.8583 55.5171 24C51.0431 28.99 40.7624 44.5029 46.557 75.2036C47.4553 79.9629 44.2854 84.4564 40.0297 84.4564C31.586 84.4564 23.2395 77.0683 18.1384 65.7587C17.9337 66.3128 17.7197 66.8693 17.5005 67.4392Z" fill="white" fill-opacity="0.2"/>-->
<!--            </svg>-->

<!--        </div>-->

        <div class="block-stat">
            <div class="wrapper-block-info">
                <div class="block-info">
                    <div class="block-info__icon">
                        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.6339 0.000370618L12.2554 0.157381C11.9519 0.171132 11.8092 0.539141 12.024 0.753985L12.9774 1.70737L8.15645 6.52826L5.54612 3.91794C5.22993 3.60175 4.71729 3.60175 4.40111 3.91794L0.272364 8.04668C-0.090825 8.40987 -0.0907805 8.99874 0.272453 9.36184C0.635597 9.72489 1.22433 9.72485 1.58743 9.36175L4.97361 5.97557L7.58434 8.58629C7.90053 8.90248 8.41321 8.90248 8.72939 8.58629L14.2927 3.02261L15.2461 3.97595C15.4609 4.19079 15.8289 4.04811 15.8432 3.74457L15.9996 0.366124C16.009 0.160697 15.8393 -0.00900314 15.6339 0.000370618Z" fill="#3EB7FD"/>
                            <path d="M12.9775 1.7074L14.2928 3.02269L12.7778 4.53783C12.7778 4.53783 13.3114 3.12261 12.5664 2.11856L12.9775 1.7074Z" fill="#26A9F4"/>
                            <path d="M4.36165 6.58764L2.24694 8.70235C1.88742 9.06187 1.30452 9.06187 0.945003 8.70235C0.940626 8.69797 0.936248 8.69359 0.931871 8.68922C0.572352 8.3297 0.572352 7.7468 0.931871 7.38728L3.04658 5.27257C3.4061 4.91305 3.989 4.91305 4.34852 5.27257L4.36165 5.2857C4.72117 5.64522 4.72117 6.22812 4.36165 6.58764Z" fill="#6AC9FF"/>
                            <path d="M2.06602 7.58166C2.06151 7.57715 2.05704 7.57269 2.05253 7.56818C1.6931 7.20875 1.6931 6.62599 2.05253 6.2666L2.27635 6.04278C2.63548 5.68366 3.21745 5.68344 3.57714 6.04194C3.58152 6.04632 3.58594 6.0507 3.59032 6.05507C3.95081 6.41437 3.95134 6.99793 3.59147 7.35784L3.3676 7.58171C3.00817 7.94109 2.42545 7.94109 2.06602 7.58166Z" fill="#6AC9FF"/>
                            <path d="M12.3645 3.62242C12.3689 3.6268 12.3733 3.63118 12.3776 3.6356C12.7373 3.99521 12.7373 4.57823 12.3777 4.93784L8.72931 8.58635C8.41312 8.90254 7.90048 8.90258 7.58425 8.58635L6.51516 7.51726C6.15201 7.15411 6.15201 6.56534 6.51516 6.20219C6.8783 5.83905 7.46708 5.83905 7.83022 6.20219L8.15636 6.52833L11.0623 3.62247C11.4219 3.26282 12.0049 3.26281 12.3645 3.62242Z" fill="#6AC9FF"/>
                            <path d="M11.4793 5.83639L10.3608 6.95483C10.0015 7.31422 9.41879 7.31422 9.05945 6.95483L9.04539 6.94077C8.68604 6.58142 8.68604 5.99879 9.04539 5.6394L10.1638 4.52096C10.5233 4.16153 11.1058 4.16184 11.4653 4.52114C11.4697 4.52552 11.4741 4.52989 11.4785 4.53427C11.8383 4.89379 11.8389 5.47673 11.4793 5.83639Z" fill="#6AC9FF"/>
                        </svg>
                    </div>
                    <div class="block-info__text">
                        <span>{{__('Total played')}}</span>
                        <p>{{profile.info.total_played}}</p>
                    </div>
                </div>

                <div class="block-info" >
                    <div class="block-info__icon" >
                        <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.77884 0H6.22389C5.73508 0 5.33515 0.399933 5.33515 0.888739C5.33515 1.37755 5.73508 1.77748 6.22389 1.77748H9.77884C10.2677 1.77748 10.6676 1.37755 10.6676 0.888739C10.6676 0.399933 10.2677 0 9.77884 0ZM8.00137 11.5536C8.49017 11.5536 8.8901 11.1537 8.8901 10.6649V7.10991C8.8901 6.62111 8.49017 6.22117 8.00137 6.22117C7.51256 6.22117 7.11263 6.62111 7.11263 7.10991V10.6649C7.11263 11.1537 7.51256 11.5536 8.00137 11.5536ZM14.2492 5.67904L14.9157 5.01249C15.2535 4.67477 15.2623 4.11486 14.9157 3.76825L14.9068 3.75937C14.5602 3.41276 14.0092 3.42165 13.6626 3.75937L12.9961 4.42592C11.6185 3.32388 9.88547 2.66622 8.00135 2.66622C3.7354 2.66622 0.109341 6.18563 0.00269276 10.4516C-0.112843 14.9664 3.50433 18.6635 8.00135 18.6635C12.4273 18.6635 16 15.0819 16 10.6649C16 8.78074 15.3423 7.0477 14.2492 5.67904ZM8.00137 16.886C4.56194 16.886 1.78019 14.1043 1.78019 10.6649C1.78019 7.22545 4.56194 4.4437 8.00137 4.4437C11.4408 4.4437 14.2225 7.22545 14.2225 10.6649C14.2225 14.1043 11.4408 16.886 8.00137 16.886Z" fill="#96AFD1"/>
                        </svg>
                    </div>
                    <div class="block-info__text">
                        <span>{{__('Game time')}}</span>
                        <p style="color: #96AFD1">{{profile.info.second_play_time ? ConvertTime(profile.info.second_play_time) : 0}}</p>
                    </div>
                </div>

                <div class="block-info">
                    <div class="block-info__icon" >
                        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2147 0.0623882C12.1516 0.0669057 12.0907 0.0868866 12.0371 0.120582C11.9836 0.154278 11.9392 0.20064 11.9079 0.25557L6.9079 8.91466C6.87419 8.9733 6.85653 9.03977 6.85669 9.1074C6.85685 9.17503 6.87483 9.24144 6.90882 9.29991C6.94281 9.35839 6.99161 9.40688 7.0503 9.4405C7.10899 9.47411 7.1755 9.49164 7.24313 9.49137C8.47872 9.49135 9.71209 9.49137 10.9477 9.49137L9.37666 15.3578C9.35272 15.4479 9.36227 15.5436 9.40353 15.6272C9.44478 15.7107 9.51495 15.7764 9.601 15.8122C9.68706 15.8479 9.78315 15.8512 9.87146 15.8215C9.95977 15.7917 10.0343 15.731 10.0812 15.6505L15.0812 6.98852C15.1148 6.9301 15.1324 6.86389 15.1324 6.7965C15.1324 6.72911 15.1146 6.6629 15.081 6.60451C15.0473 6.54613 14.9989 6.49762 14.9406 6.4638C14.8824 6.42998 14.8162 6.41205 14.7488 6.41182C13.5129 6.41183 12.2773 6.41182 11.0414 6.41182L12.6125 0.545343C12.6282 0.486242 12.6296 0.424256 12.6166 0.364501C12.6035 0.304747 12.5765 0.248934 12.5376 0.201726C12.4987 0.154518 12.4492 0.117249 12.393 0.0930168C12.3369 0.068784 12.2757 0.0582927 12.2147 0.0623882ZM11.1153 3.1675L10.1693 6.69875C10.1543 6.75571 10.1525 6.81534 10.1642 6.87307C10.1759 6.9308 10.2006 6.98511 10.2366 7.03176C10.2725 7.07841 10.3187 7.1162 10.3716 7.1422C10.4244 7.1682 10.4825 7.1817 10.5414 7.18171C11.7214 7.18171 12.9012 7.18172 14.0812 7.18171L10.8738 12.7328L11.8198 9.20727C11.8354 9.15013 11.8375 9.09017 11.8261 9.03207C11.8146 8.97397 11.79 8.91932 11.7539 8.87231C11.7179 8.82531 11.6716 8.78723 11.6185 8.76107C11.5653 8.73492 11.5069 8.72137 11.4477 8.72148C10.267 8.72148 9.08854 8.72146 7.9079 8.72148L11.1153 3.1675Z" fill="#00C443"/>
                            <path d="M3.93438 4.38574C2.87569 4.38574 2.01013 5.25348 2.01013 6.31139C2.01013 7.36931 2.87569 8.23424 3.93438 8.23424C4.99306 8.23424 5.85863 7.36931 5.85863 6.31139C5.85863 5.25348 4.99306 4.38574 3.93438 4.38574ZM3.93438 5.15545C4.57688 5.15545 5.08836 5.66935 5.08836 6.31139C5.08836 6.95344 4.57688 7.46453 3.93438 7.46453C3.29186 7.46453 2.77755 6.95344 2.77755 6.31139C2.77755 5.66935 3.29186 5.15545 3.93438 5.15545Z" fill="#00C443"/>
                            <path d="M3.93442 9.00391C1.80953 9.00391 0.0859375 10.7257 0.0859375 12.8519V13.2384C0.0861161 13.289 0.0962492 13.339 0.115758 13.3857C0.135267 13.4323 0.16377 13.4747 0.199638 13.5103C0.235507 13.5459 0.278038 13.5741 0.324804 13.5933C0.371569 13.6125 0.421652 13.6223 0.472192 13.6221H7.39663C7.44717 13.6223 7.49726 13.6125 7.54403 13.5933C7.59079 13.5741 7.63333 13.5459 7.6692 13.5103C7.70507 13.4747 7.73358 13.4323 7.75309 13.3857C7.7726 13.339 7.78273 13.289 7.78291 13.2384V12.8519C7.78291 10.7257 6.05931 9.00391 3.93442 9.00391ZM3.93442 9.77406C5.6463 9.77406 7.01321 11.139 7.01321 12.8519H0.855625C0.855625 11.139 2.22252 9.77406 3.93442 9.77406Z" fill="#00C443"/>
                            <path d="M17.7881 4.38574C16.7302 4.38574 15.8624 5.25348 15.8624 6.31139C15.8624 7.36931 16.7302 8.23424 17.7881 8.23424C18.846 8.23424 19.7109 7.36931 19.7109 6.31139C19.7109 5.25348 18.846 4.38574 17.7881 4.38574ZM17.7881 5.15545C18.4301 5.15545 18.9412 5.66935 18.9412 6.31139C18.9412 6.95344 18.4301 7.46453 17.7881 7.46453C17.146 7.46453 16.6321 6.95344 16.6321 6.31139C16.6321 5.66935 17.146 5.15545 17.7881 5.15545Z" fill="#00C443"/>
                            <path d="M17.7881 9.00391C15.6625 9.00391 13.9382 10.7257 13.9382 12.8519V13.2384C13.9386 13.3401 13.9791 13.4374 14.051 13.5093C14.1228 13.5812 14.2202 13.6218 14.3218 13.6221H21.2516C21.3533 13.6218 21.4506 13.5812 21.5225 13.5093C21.5943 13.4374 21.6348 13.3401 21.6352 13.2384V12.8519C21.6352 10.7257 19.9138 9.00391 17.7881 9.00391ZM17.7881 9.7741C19.5007 9.7741 20.8652 11.139 20.8652 12.8519H14.7082C14.7082 11.139 16.0756 9.7741 17.7881 9.7741Z" fill="#00C443"/>
                        </svg>
                    </div>
                    <div class="block-info__text">
                        <span>{{__('Rivals')}}</span>
                        <p style="color: #00C443">{{profile.info.rivals}}</p>
                    </div>
                </div>

                <div class="block-info block-info-new">
                    <a
                            text
                            v-ripple="false"
                            class="border-white"
                            @click="achievements_modal = !achievements_modal"
                    >

                        {{__('Awards')}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2656 7.01562C14.7439 8.29239 15.5894 9.85788 15.5894 12.0048C15.5894 16.4778 12.0947 20 7.80589 20C3.51319 20 0 16.4754 0 12.0048C0 9.54277 0.53353 8.02115 2.09971 5.49195C2.59529 4.69164 3.79862 4.98309 3.89185 5.92601C3.97563 6.77327 4.23285 7.49447 4.60764 8.04738C4.35766 5.05968 5.65478 1.69957 8.85313 0.103241C9.5936 -0.266334 10.4199 0.413617 10.2272 1.23398C9.72786 3.36001 10.2423 4.50381 11.5872 5.73601C11.62 5.76607 11.7875 5.88976 12.0091 6.05335C12.4104 6.34962 12.989 6.77674 13.2656 7.01562ZM2.55349 8.77621C2.27895 9.41536 1.94867 10.1842 1.94867 12.0048C1.94867 15.3635 4.58043 18.0038 7.80589 18.0038C11.0255 18.0038 13.6407 15.368 13.6407 12.0048C13.6407 10.5044 13.0801 9.46644 12.0095 8.5418C11.8405 8.39585 11.4444 8.10118 11.0715 7.82383C10.719 7.56159 10.3873 7.31483 10.2881 7.22389C8.99694 6.04091 8.20761 4.78117 8.1004 3.10139C7.4476 3.75327 5.94758 5.77985 6.79305 9.79053C6.92412 10.4123 6.46161 10.9993 5.84067 10.9993C4.60867 10.9993 3.39085 10.0341 2.64656 8.55667C2.6167 8.62906 2.58547 8.70176 2.55349 8.77621Z" fill="#00AEFF"/>
                        </svg>
                        <span>+{{profile.achievements.count}}</span>
                    </a>
<!--                    <div class="block-info__icon" style="background: #E0ECFD"  :style="((profile.info.max_win_real) ? {background: 'rgba(255, 223, 142, 0.36)'} : '#E0ECFD')">-->
<!--                        <img v-if="profile.info.max_win_real" src="/dist/images/frontend/money.svg" alt />-->
<!--                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />-->
<!--                    </div>-->
<!--                    <div class="block-info__text">-->
<!--                        <span>{{__('Max Winnings')}}</span>-->
<!--                        <p v-if="profile.info.max_win_real" style="color: #FDAE02">{{parseFloat(profile.info.max_win_real).toFixed(2)}}</p>-->
<!--                        <p v-else style="color: #607EA7">{{profile.info.max_win_lite ? parseFloat(profile.info.max_win_lite).toFixed(2) : 0}}</p>-->
<!--                    </div>-->
                </div>
            </div>
            <p class="apex-title">{{__('Game activity')}}</p>
            <span class="apex-info">{{__('in 30 days')}}</span>
            <div class="block-apexchart" v-if="profile.info.charts.length">
                <apexchart type="area" height="150" :options="chartOptions1" :series="series1"></apexchart>
            </div>
            <div v-else class="apexchart-empty">
                <p>{{__('there is no data')}}</p>
                <svg width="625" height="108" viewBox="0 0 622 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="-3" width="690" height="123">
                        <rect y="-3" width="690" height="123" fill="white"/>
                    </mask>
                    <g mask="url(#mask0)">
                        <path d="M5.68403 123H619.88C628.45 123 635.089 115.505 634.055 106.998C633.868 105.46 633.432 103.962 632.763 102.563L604.365 43.1528C602.487 39.2258 599.38 36.0184 595.515 34.0172L582.774 27.4208C573.992 22.8742 563.205 25.4284 557.395 33.4306L548.539 45.6274C539.567 57.9832 520.618 56.1337 514.205 42.2763L506.927 26.5498C501.145 14.0562 484.763 11.0035 474.869 20.5761L460.263 34.708C458.626 36.2918 457.272 38.1435 456.258 40.1832L440.697 71.507C437.351 78.2416 427.801 78.3999 424.233 71.78C420.722 65.2637 411.366 65.2881 407.889 71.8226L398.329 89.7874C396.431 93.3533 393.499 96.2611 389.917 98.1279L387.949 99.1531C378.891 103.874 367.731 101.01 362.065 92.5112L360.929 90.8072C354.557 81.2487 341.461 79.0107 332.276 85.9107L327.412 89.5645C322.612 93.1703 316.447 94.4293 310.617 92.9939L295.996 89.3935C291.166 88.2042 286.952 85.2577 284.178 81.1292L268.563 57.8946C265.965 54.0278 262.098 51.1895 257.63 49.8696L244.841 46.0919C238.25 44.1449 231.121 45.7184 225.962 50.259L224.707 51.363C217.145 58.0184 205.813 58.0107 198.26 51.345L184.88 39.5366C183.055 37.9264 180.952 36.6628 178.674 35.8077L166.81 31.3544C158.411 28.2021 148.941 31.0276 143.643 38.2666L128.512 58.9397C126.557 61.6102 123.97 63.7539 120.984 65.1787L99.6882 75.3372C95.2447 77.4569 90.1755 77.8631 85.4511 76.4782L68.8842 71.6217C62.3979 69.7203 55.39 71.2238 50.2545 75.6185L36.7022 87.2158C30.9409 92.1461 22.8867 93.3941 15.9034 90.4385L12.5766 89.0305C0.91659 84.0956 -12 92.6547 -12 105.316C-12 115.083 -4.08258 123 5.68403 123Z" fill="url(#paint0_linear)" fill-opacity="0.3" stroke="#E6E6E6"/>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear" x1="94.8939" y1="33.7092" x2="96.1821" y2="123.803" gradientUnits="userSpaceOnUse">
                            <stop offset="0.0249497" stop-color="#C6C6C6"/>
                            <stop offset="0.973912" stop-color="#D9D9D9" stop-opacity="0"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
        <ModalAchievements v-if="achievements_modal && profile.achievements.data.length" v-bind:achievements_modal.sync="achievements_modal" v-bind:profile="profile"></ModalAchievements>
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";
    import ModalAchievements from "./ModalAchievements";

    export default {
        data() {
            return {
                achievements_modal: false,
                series1: [{
                    name: '',
                    data: this.profile ? this.profile.info.charts : []
                }],
                chartOptions1: {
                    colors: ['#26A9F4'],
                    height: 1,
                    fill: {
                        type: 'gradient',
                        gradient: {
                            // shade: 'dark',
                            type: "vertical",
                            // shadeIntensity: 0.5,
                            gradientToColors: [
                                ['rgba(38, 169, 244, 0.5)'],
                                ['rgba(38, 169, 244, 0)']
                            ],
                            // inverseColors: true,
                            // opacityFrom: 1,
                            // opacityTo: 1,
                            // stops: [0, 50, 100],
                            // colorStops: []
                        }
                    },
                    chart: {
                        height: 55,
                        type: 'area',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: "1",
                    },
                    xaxis: {
                        type: 'datetime',
                        // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                    },
                    tooltip: {
                        x: {
                            format: 'dd MMM yyyy'
                        }
                    },
                    // legend: {
                    //   horizontalAlign: 'left'
                    // },
                    yaxis: {
                        show: false,
                    },
                    grid: {
                        show: false,
                        // row: {
                        //   colors: ['#F44336', '#E91E63', '#9C27B0']
                        // },
                        // column: {
                        //   colors: ['#F44336', '#E91E63', '#9C27B0']
                        // }
                    }
                },
            }
        },
        props: {
            profile: {
                type: Object
            }
        },
        methods: {
            ConvertTime(seconds)  {
                let days = Math.floor(seconds / (24*60*60));
                seconds -= days * (24*60*60);
                let hours = Math.floor(seconds / (60*60));
                seconds -= hours * (60*60);
                let minutes = Math.floor(seconds / (60));
                seconds -= minutes * (60);
                return ((0<days)?(days+"d "):"") +((0<hours)?(hours+"h "):"") +((0<minutes)?(minutes+"min"):"");
            }
        },
        components: {
            apexchart: VueApexCharts,
            ModalAchievements
        }
    }
</script>

<style lang="scss" scoped>
    .dark .block-statistics {
        .block-stat {
            background: #171827;

            .apex-title {
                color: #fff;
            }

            .wrapper-block-info {
                background: linear-gradient(90deg, rgba(23, 24, 39, 1) 0%, rgba(23, 24, 39, 0.90) 88.54%, rgba(23, 24, 39, 0.0) 100%);

                .block-info {
                    &__text {
                        span {
                            color: #96AFD1;
                        }
                    }
                }
            }
        }
    }

    .block-statistics {
        /*margin-bottom: 20px;*/
        display: flex;
        align-items: center;
        width: calc(100% - 272px);
        margin-left: 20px;

        .block-honors {
            background: linear-gradient(92.11deg, #026EED 0%, #00AEFF 100%);
            border-radius: 4px;
            width: 222px;
            height: 144px;
            position: relative;
            padding: 20px 10px 10px;
            margin-right: 20px;
            flex-shrink: 0;

            h5 {
                font-weight: bold;
                font-size: 24px;
                line-height: 29px;
                display: flex;
                align-items: center;
                letter-spacing: 0.04em;
                color: #FFFFFF;
                text-shadow: 0px 2px 4px rgba(255, 255, 255, 0.15);
                margin: 0 0 8px 12px;
            }

            svg {
                position: absolute;
                right: 0;
                bottom: 0;
                pointer-events: none

            }
        }

        .block-stat {
            background-color: #fff;
            box-shadow: 0px 2px 8px rgba(134, 140, 144, 0.05);
            border-radius: 8px;
            width: 100%;
            height: 144px;
            position: relative;
            overflow: hidden;

            .wrapper-block-info {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 88.54%, rgba(255, 255, 255, 0) 100%);
                padding: 20px 0 0 20px;
                margin-bottom: 20px;
                max-width: 385px;
                width: 100%;
                height: 144px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-radius: 4px 0 0 4px;

                .block-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    height: 44px;
                    width: 50%;
                    cursor: default;

                    &__icon {
                        background: #0B0C1A;
                        border-radius: 8px;
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        flex-shrink: 0;

                        img {
                            height: 16px;
                        }
                    }

                    &__text {
                        margin-top: -2px;
                        span {
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: 0.01em;
                            color: #6886AF;
                            white-space: nowrap;
                        }

                        p {
                            font-size: 18px;
                            line-height: 24px;
                            margin: -2px 0 0;
                            color: #26A9F4;
                        }
                    }
                }
            }

            .apex-title {
                font-size: 12px;
                line-height: 16px;
                color: #002B50;
                margin: 0;
                position: absolute;
                top: 21px;
                right: 20px;
            }

            .apex-info {
                font-size: 12px;
                line-height: 16px;
                color: #96AFD1;
                position: absolute;
                top: 36px;
                right: 20px;
            }

            .block-apexchart {
                margin: 0 -10px  -12px;
                position: relative;
                transform: translateY(26px);
            }

             .apexchart-empty {
                 position: absolute;
                 bottom: 0;
                 right: 0;
                 left: 0;

                 p {
                     position: absolute;
                     bottom: 46px;
                     right: 114px;
                     font-size: 12px;
                     line-height: 16px;
                     letter-spacing: -0.0015em;
                     text-transform: lowercase;
                     color: #8D8D8D;
                     margin: 0;
                 }
             }
        }
    }

    @media screen and (max-width: 1100px) {
        .block-statistics {
            .block-stat {
                .apex-title {
                    display: none;
                }
                .apex-info {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        .block-statistics {
            .block-stat {
                .apex-title {
                    display: block;
                }
                .apex-info {
                    display: block;
                }
            }
        }
    }

    @media screen and (max-width: 780px) {
        .block-statistics {
            .block-stat {
                .apex-title {
                    display: none;
                }
                .apex-info {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        .block-statistics {
            flex-direction: column-reverse;

            .block-stat {
                .apex-title {
                    display: block;
                }
                .apex-info {
                    display: block;
                }
            }

            .block-honors {
                width: 100%;
                margin: 20px 0 0;
                height: 100px;

                svg {
                    height: 90px;
                    width: 80px;
                }

                .v-btn.border-white {
                    position: absolute;
                    max-width: 200px;
                    top: 35px;
                    right: 100px;
                }
            }
        }
    }

    @media screen and (max-width: 550px) {
        .block-statistics {
            .block-stat {
                .apex-title {
                    display: none;
                }
                .apex-info {
                    display: none;
                }

                .wrapper-block-info {
                    max-width: 100%;
                    .block-info {

                        &__icon {
                            width: 38px;
                            height: 38px;
                            margin-right: 8px;
                        }

                        &__text {
                            span {
                                font-size: 12px;
                            }

                            p {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        .block-statistics {

            .block-honors {
                width: 100%;
                margin: 20px 0 0;
                height: 144px;

                svg {
                    height: 124px;
                    width: 105px;
                }

                .v-btn.border-white {
                    position: relative;
                    max-width: 100%;
                    top: inherit;
                    right: inherit;
                }
            }
        }
    }

    @media screen and (max-width: 400px){
        .block-statistics {
            .block-stat {


                .wrapper-block-info {
                    padding: 20px 0 0 10px;
                }
            }
        }
    }

</style>