import Vue from 'vue';
import App from './App.vue';
import 'vue-awesome/icons/flag';
import 'vue-awesome/icons';
import Icon from 'vue-awesome/components/Icon';
import store from './store';
import './assets/css/main.scss';
import Routes from './routes/routes';
import VueResource from 'vue-resource';
import AsyncComputed from 'vue-async-computed';
import { VclFacebook, VclInstagram, VueContentLoading, VclTable  } from 'vue-content-loading';
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';
import vuetify from "./plugins/vuetify";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import MarqueeText from 'vue-marquee-text-component';
import BaseButton from "./components/modules/BaseButton";
import VueTabsWithActiveLine from "./components/modules/VueTabsWithActiveLine";
import VueStar from './assets/js/VueStar';
import AOS from "aos";
import "aos/dist/aos.css";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/custom/world.geo.json";
// import VueYandexMetrika from 'vue-yandex-metrika';
import DynamicMarquee from 'vue-dynamic-marquee';

// import vuetify from "@/plugins/vuetify";
// import 'vuetify/dist/vuetify.min.css';
// import './assets/css/materialdesignicons.min.css';
import Vuelidate from 'vuelidate';
import Notifications from 'vue-notification';
import moment from 'moment';
// import VueGtag from "vue-gtag";
import VueHead from 'vue-head'

import './registerServiceWorker';

import VueResumable from 'vue-resource';
import Echo from "laravel-echo";

import Push from 'push.js';

import VueDeviceDetector from 'vue-device-detector';
export const bus = new Vue();

Push.Permission.request();


Vue.filter('moment', function(value, format= 'DD.MM.YYYY HH:mm', lang = 'en') {
    if (value) {
        return moment.utc(String(value)).lang(lang).format(format);
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment.utc(String(value)).format('H:mm');
    }
});
Vue.filter('formatDate', function(value) {
    if (value) {
        return moment.utc(String(value)).format('hh:mm:ss');
    }
});
Vue.filter('formatFullDate', function(value) {
    if (value) {
        return moment.utc(String(value)).format('DD.MM.YYYY hh:mm');
    }
});
Vue.filter('formatYMD', function(value) {
    if (value) {
        return moment.utc(String(value)).format('DD.MM.YYYY');
    } else {
        return moment.utc().format('DD.MM.YYYY');
    }
});

Vue.use(VueResumable);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(CKEditor);
Vue.use(VueHead);
Vue.component('VueAdsPagination', VueAdsPagination);
Vue.component('VueAdsPageButton', VueAdsPageButton);


Vue.component('marquee-text', MarqueeText);


Vue.component('v-icon', Icon);
Vue.component('Tabs', VueTabsWithActiveLine);

require('./env');
require('./locale');
require('./global_function');

Vue.use(VueResource);

Vue.config.productionTip = false;

Vue.http.options.root= Vue.prototype.$path;
// Vue.http.options.root= (process.env.NODE_ENV === 'production') ? 'https://eivon.bitoxbit.com/' : 'http://localhost:3000/';
//
Vue.http.interceptors.push(request=> {
    request.headers.set('X-CSRF-TOKEN', Vue.prototype.$token);
});

Vue.use(AsyncComputed);
Vue.component('VclFacebook', VclFacebook);
Vue.component('VclInstagram', VclInstagram);
Vue.component('VueContentLoading', VueContentLoading);
Vue.component('VclTable', VclTable);

// window.Pusher = require('./assets/js/pusher');
// window.Pusher = require('pusher-js');
// window.Pusher = require('./assets/js/pusher-js/dist/web/pusher');

let token = document.head.querySelector('meta[name="csrf-token"]');
window.io = require('socket.io-client');

Vue.prototype.$echo= new Echo({
    broadcaster: 'socket.io',
    key: '0356d8c72565a7000acb241fe52f17cb',
    cluster: 'mt1',
    wsHost: window.location.host,
    host: 'https://'+window.location.host+':8443',
    wsPort: 8443,
    wssPort: 8443,
    disableStats: true,
    // authEndpoint: '/broadcast',
    // encrypted: true,
    transports: ['websocket', 'polling', 'flashsocket'],
    authEndpoint: "broadcasting/auth",
    auth: {
        headers: {
            Authorization: 'Bearer '+ token,
            'X-CSRF-TOKEN': 'too_long_csrf_token_hardcoded',
            Authoriz: 'Bearer',
        },
    }
});

mapInit(Highcharts);

Highcharts.maps["myMapName"] = mapData;

Vue.use(HighchartsVue);

Vue.use(VueDeviceDetector);
// Vue.use(VueGtag, {
//     // config: {id: "UA-192849595-1"},
//     config: {id: "G-HP8L4VVYDC"},
//     globalObjectName: 'mindplays'
// }, Routes);
//
// Vue.use(VueYandexMetrika, {
//     id: '87397177',
//     router: Routes,
//     env: process.env.NODE_ENV,
//     options: {
//         clickmap: true,
//         trackLinks:true,
//         accurateTrackBounce:true,
//         webvisor: true,
//     },
//     // other options
// });

new Vue({
    created() {
        AOS.init();
    },
    router: Routes,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');

Vue.component('BaseButton', BaseButton);
Vue.component('VueStar', VueStar);
Vue.component('dynamic-marquee', DynamicMarquee);

