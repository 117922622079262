<template>
  <div class="block-result">
        <v-list>
          <v-list-item>
            <div style="position: relative">
              <div class="block-img">
                <router-link :to="{name: 'profile', params: {id: result.public_id, lang: $store.getters.getLang}}">
                  <div class="wrap-img">
                    <img v-if="result.photo && typeof result.photo == 'object'" :src="result.photo.original">
                    <img v-else-if="result.photo" :src="result.photo">
                    <span v-else class="photo" :style="{'background': result.bg_color}">{{result.name.substr(0,1)}}</span>
                  </div>
                </router-link>
                <div v-if="!result.online" class="online"></div>
              </div>
            </div>
            <v-list-item-content>
              <router-link :to="{name: 'profile', params: {id: result.public_id, lang: $store.getters.getLang}}">
              <v-list-item-title>
                {{result.name+" "+((result.last_name) ? result.last_name : '')}}
              </v-list-item-title>
              </router-link>
              <v-list-item-subtitle>
                <img width="14" class="mr-2" :src="result.location_img"/>
                {{$getLocation(result.location)}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      <div class="float-right text-right">
          <v-btn :loading="submitDisabled" text icon class="btn-link" v-ripple="false" v-on:click="$emit('update:management_event', {command: 'add', friend_id: result.id}), submitDisabled= true" >
            <svg width="26" height="26" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.99997 5.93933V12.0446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.0556 8.99203H5.94446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9047 0.666626H5.0952C2.37298 0.666626 0.666626 2.59336 0.666626 5.32092V12.679C0.666626 15.4066 2.36504 17.3333 5.0952 17.3333H12.9047C15.6349 17.3333 17.3333 15.4066 17.3333 12.679V5.32092C17.3333 2.59336 15.6349 0.666626 12.9047 0.666626Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </v-btn>
      </div>
  </div>
</template>

<script>
    export default {
        name: 'ManagementFriendResult',
        data () {
            return {
                submitDisabled: false
            }
        },
        props: {
            result: {
                type: Object
            },
            management_event: {
                type: Object
            }
        },
        methods: {

        },
        computed: {

        },
        components: {

        },

    }
</script>

<style lang="scss" scoped>

  .dark {
    .block-result {
      .v-list-item {
        &__title {
          color: #fff;
        }

        &__subtitle {
          color: #96AFD1;
        }
      }
    }
  }

  .block-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    a {
      text-decoration: none;
      color: #002B50;
      transition-duration: .2s;

      &:hover {
        text-decoration: underline;
        color: #26a9f4;
        transition-duration: .2s;
      }
    }

    .v-list {
      padding: 0;
    }
    .v-list-item {
      padding: 0;

      &__content {
        padding: 0;
      }

      &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #6886AF;
      }
    }
    .block-img {
      position: relative;
      width: 40px;
      height: 40px;
      margin-right: 10px;

      a {
        text-decoration: none;
      }

      .online {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 3px;
        bottom: 1px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        background-color: #00C569;
      }

      .wrap-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .photo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          color: #FFFFFF;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
        }
      }
    }

    .btn-link {
        svg {
          color: #26a9f4;
          transition-duration: .3s;
          border-radius: 7px;
        }

      &:hover {
        svg {
          border-radius: 7px;
          color: #fff;
          background-color: #26a9f4;
          transition-duration: .3s;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .block-result {
      .v-list-item {
        padding: 0!important;

        &__title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 160px;
        }
      }
    }
  }
</style>
