<template>
    <v-dialog v-model="dialog" max-width="610px">
        <div class="wrap-modal-2fa">
            <FormGoogle2fa v-bind:setting.sync="setting" v-bind:dialog.sync="dialog" @updateEnable="updateEnable"></FormGoogle2fa>
        </div>
    </v-dialog>
</template>

<script>
    import FormGoogle2fa from "./FormGoogle2fa";

    export default {
        name:'ModalSecurity2fa',
        data() {
            return {
                dialog: true,
            }
        },
        props: {
            setting: {
                type: Object
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            }
        },

        methods: {
            updateEnable(enable) {
                let setting= this.setting;
                setting.google_two_step= enable;

                this.$emit('update:setting', setting);
            },
            cancelModal() {
                this.$emit("update:modal_security_2fa", false);
            }
        },
        components: {
            FormGoogle2fa,

        },
    }
</script>

<style lang="scss" scoped>
    .wrap-modal-2fa {
        background: #FFFFFF;
    }
</style>