<template>
  <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:result', false)">
    <div class="modal-confirm">
<!--      <img src="/dist/images/frontend/delete-game-icon.svg" alt />-->
<!--      <p>Are you sure you want to start this quest?</p>-->
      <div
          class="image"
          :style="{background: 'url('+(item.icon)+') no-repeat', backgroundSize: 'cover'}"
      ></div>
      <span class="modal-confirm__name">{{__(item.name)}}</span>

      <div class="block-cost">
        <span class="block-cost__title">{{__('Cost of participation:')}}</span>
        <div v-if="item.wallet == 1" class="block-cost__desc">
          <img src="/dist/images/frontend/money.svg" alt />
          <span style="color: #FDAE02;">{{item.price}}</span>
        </div>
        <div v-else class="block-cost__desc">
          <img src="/dist/images/frontend/light-coin.svg" alt />
          <span>{{item.price}}</span>
        </div>
      </div>

      <div class="block-statistic">
        <span class="block-statistic__title">{{__('Completion reward:')}}</span>
        <div class="block-statistic__desc">
          <img src="/dist/images/frontend/fire.svg" alt />
          <span style="color: #F6A401;">+{{item.rating}}</span>
        </div>
        <div v-if="item.real" class="block-statistic__desc">
          <img src="/dist/images/frontend/money.svg" alt />
          <span  style="color: #FDAE02;">+{{item.real}}</span>
        </div>
        <div v-else class="block-statistic__desc">
          <img src="/dist/images/frontend/light-coin.svg" alt />
          <span class="block-lite" style="color: #6886AF;">+{{item.lite}}</span>
        </div>
      </div>
      <div class="wrap-btn">
        <div style="width: 48%">
          <BaseButton
              class_btn="btn-liteGrey"

              @click="cancelModal"
          >
            <span>{{__('Cancel')}}</span>
          </BaseButton>
        </div>

        <div style="width: 48%">
          <BaseButton
              v-on:click="$emit('buyQuest')"
              :loading="submitDisabled"
          >
            <span>{{__('Start quest')}}</span>
          </BaseButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmActionDefault",
  data() {
    return {
      modal: true,
    };
  },
  props: {
    open: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    result: {
      type: Boolean,
    },
    item: {
      type: Object
    },
      submitDisabled: {
        type: Boolean
      }
  },
  watch: {
    modal() {
      this.cancelModal();
    },
  },
  computed: {},

  methods: {
    cancelModal() {
      this.$emit("update:open_confirm", false);
    },
  },
  components: {},
};
</script>

<style lang='scss' scoped>

.dark {
    .modal-confirm {
        background: #171827;

        .image {
            border: 1px solid #2b2b2b;
            background-color: #181818;
            border-radius: 4px;
        }

        &__name {
            color: #fff;
        }

        .block-statistic {
            &__title {
                color: #fff;
            }

            &__desc {
                .block-lite {
                    color: #96AFD1!important;
                }
            }
        }

        .block-cost {
            &__title {
                color: #01df72;
            }

            &__desc {
                span {
                    color: #96AFD1;
                }
            }
        }
    }
}

.model_wrapper.v-dialog {
  overflow: unset;
  position: relative;
}

.modal-confirm {
  text-align: center;
  padding: 30px 25px 30px;
  box-shadow: 0 14px 34px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;

  p {
    max-width: 280px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #002B50;
    margin: 0 auto 32px;
  }

  .image {
    min-height: 60px;
    width: 60px;
    margin: 0 auto 12px;
    position: relative;
  }

  &__name {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #002B50;
    margin-bottom: 18px;
  }

  .block-cost {
    display: flex;
    align-items: center;

    &__title {
      font-size: 14px;
      line-height: 18px;
      color: #01df72;
    }

    &__desc {
      display: flex;
      align-items: center;
      margin-left: 12px;

      img {
        height: 12px;
        margin: 1px 4px 0 0;
      }
      span {
        font-size: 14px;
        color: #6886af;
      }
    }
  }

  .block-statistic {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &__title {
      font-size: 14px;
      line-height: 18px;
      color: #002B50;
    }
    &__desc {
      margin-left: 12px;
      img {
        height: 12px;
        margin: -1px 4px 0 0;
      }
      span {
        font-size: 14px;
      }
    }
  }

  .wrap-btn {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
    .modal-confirm {
        padding: 20px 15px;
        .image {
            min-width: 40px;
            width: 40px;
        }
        &__name {
            font-size: 14px;
            line-height: 18px;
        }

        .block-statistic {
            &__title {
                font-size: 12px;
                line-height: 16px;
            }

            &__desc {
                margin-top: -1px;
                span {
                    font-size: 12px;
                }

                img {
                    height: 11px;
                    margin: 0 4px 0 0;
                }
            }
        }
    }
}
</style>