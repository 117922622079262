<template>
  <div class="wrapper-moderation">
    <div class="wrapper-moderation__head">
      <div class="d-flex block-status">
        <span class="head-icon">
          <img src="/dist/images/frontend/moderation-icon.svg" alt />
        </span>

        <div v-if="developers.dev_mode== 1 || developers.dev_mode== 3" class="wrap-block-moderation">
          <div class="wrap-block-moderation__status">
            <p>{{__('Game status:')}}</p>
            <span class="red--text">{{__('Disabled')}}</span>
          </div>
          <BaseButton
            class_btn="btn-gold-round"
            width="180"
            v-on:click="submitGameMode('moderation')"
            :loading="submitDisabled"
          >
            <span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.9998 12.1733C8.32626 12.1733 8.59091 11.9087 8.59091 11.5822C8.59091 11.2558 8.32626 10.9911 7.9998 10.9911C7.67334 10.9911 7.40869 11.2558 7.40869 11.5822C7.40869 11.9087 7.67334 12.1733 7.9998 12.1733Z" fill="currentColor"/>
                <path d="M8.00011 10.0489C7.88223 10.0489 7.76919 10.0021 7.68584 9.91871C7.60249 9.83536 7.55566 9.72231 7.55566 9.60444V4.2711C7.55566 4.15323 7.60249 4.04018 7.68584 3.95684C7.76919 3.87349 7.88223 3.82666 8.00011 3.82666C8.11798 3.82666 8.23103 3.87349 8.31438 3.95684C8.39773 4.04018 8.44455 4.15323 8.44455 4.2711V9.60444C8.44455 9.72231 8.39773 9.83536 8.31438 9.91871C8.23103 10.0021 8.11798 10.0489 8.00011 10.0489Z" fill="currentColor"/>
                <path d="M6.69401 0.747109C6.81754 0.52229 6.9992 0.334792 7.22001 0.204226C7.44081 0.07366 7.69264 0.00482257 7.94916 0.00491342C8.20568 0.00500427 8.45746 0.07402 8.67818 0.204742C8.89889 0.335465 9.08042 0.523091 9.20379 0.747997L15.3771 12.108C15.4955 12.326 15.5552 12.5709 15.5504 12.8189C15.5456 13.0669 15.4764 13.3094 15.3496 13.5226C15.2228 13.7358 15.0428 13.9124 14.8272 14.035C14.6116 14.1577 14.3678 14.2222 14.1198 14.2222H1.78734C1.53651 14.2278 1.28861 14.1674 1.0685 14.047C0.84839 13.9266 0.663801 13.7504 0.533244 13.5362C0.402687 13.3219 0.330749 13.0771 0.324643 12.8263C0.318537 12.5755 0.378478 12.3275 0.498456 12.1071L6.69401 0.747109ZM1.27979 12.5324C1.234 12.6166 1.21133 12.7114 1.21411 12.8072C1.21688 12.903 1.245 12.9964 1.29558 13.0778C1.34617 13.1592 1.41743 13.2257 1.50209 13.2706C1.58676 13.3155 1.68181 13.3371 1.77757 13.3333H14.1198C14.2138 13.3333 14.3061 13.3088 14.3878 13.2623C14.4695 13.2158 14.5377 13.1488 14.5857 13.068C14.6337 12.9872 14.6598 12.8953 14.6616 12.8013C14.6634 12.7074 14.6407 12.6146 14.5958 12.532L8.42379 1.17422C8.37701 1.08923 8.30828 1.01835 8.22477 0.968971C8.14127 0.91959 8.04604 0.893518 7.94903 0.893472C7.85202 0.893427 7.75677 0.91941 7.67322 0.968713C7.58967 1.01802 7.52087 1.08883 7.47401 1.17378L1.27934 12.5324H1.27979Z" fill="currentColor"/>
              </svg>
              {{__('Need moderation')}}
            </span>
          </BaseButton>
        </div>
        <div v-else-if="developers.dev_mode== 0" class="wrap-block-moderation">
          <div class="wrap-block-moderation__status">
            <p>{{__('Game status:')}}</p>
            <span class="success--text">{{__('Enabled and visible')}}</span>
          </div>
<!--          <v-btn-->
<!--                  text-->
<!--                  v-on:click="submitGameOff('moderation')"-->
<!--                  :loading="submitDisabled"-->
<!--                  v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                  class="btn-secondary"-->
<!--          >Switch off</v-btn>-->
          <BaseButton
            v-on:click="open_modal= true"
            :loading="submitDisabled"
            class_btn="redBtn"
            width="180"
          >
            <span>{{__('Switch off')}}</span>
          </BaseButton>
          <ModalModerationOff
            v-if="open_modal"
            v-bind:open_modal.sync="open_modal"
            @submitGameOff="submitGameOff('moderation')"
          >
          </ModalModerationOff>
        </div>
        <div v-else class="wrap-block-moderation">
          <div class="wrap-block-moderation__status">
            <p>{{__('Game status:')}}</p>
            <span class="blue--text">{{__('For moderation')}}</span>
          </div>
          <BaseButton
            class_btn="btn-liteGrey"
            v-on:click="submitGameOff('moderation')"
            :loading="submitDisabled"
            width="180"
          >
            <span>{{__('Cancel moderation')}}</span>
          </BaseButton>
        </div>
      </div>

      <p>
        {{__('To place the application in the catalog, use payments and place ads, the application must be checked by the administration and comply with')}}
        <a href="https://mindplays.com/withdrawal_policy_and_refund_policy" target="_blank">{{__('the Rules')}}</a> !
      </p>
<!--        <v-btn-->
<!--                v-if="developers.dev_mode== 1 || developers.dev_mode== 3"-->
<!--                text-->
<!--                class="white&#45;&#45;text btn-cab"-->
<!--                v-on:click="submitGameMode('moderation')"-->
<!--                :loading="submitDisabled"-->
<!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--        >Submit the game to moderation</v-btn>-->

<!--        <v-btn v-else-if="developers.dev_mode== 0" text disabled v-ripple="false" class="btn-published">Published!</v-btn>-->

<!--        <v-btn v-else disabled text v-ripple="false" class="btn-status">-->
<!--          Submitted for moderation-->
<!--        </v-btn>-->
    </div>
    <h3 v-if="developers.moderation && developers.moderation.length">{{__('Application history')}}</h3>
    <div>
      <ModerationItemList
        v-for="(item, key) in developers.moderation"
        :key="key"
        v-bind:item="item"
      ></ModerationItemList>
    </div>
  </div>
</template>

<script>
import ModalModerationOff from "./ModalModerationOff"
import ModerationItemList from "./ModerationItemList";
export default {
  name: "GameInfoModeration",
  data() {
    return {
      submitDisabled: false,
      open_modal: false,
    };
  },
  props: {
    developers: {
      type: Object,
    },
  },
  methods: {
    // submitGameMode: function () {
    //   this.submitDisabled = true;
    //   this.$http.get("ajax/developers/" + this.$route.params.id + "/moderation")
    //     .then((response) => {
    //       return response.json();
    //     })
    //     .then(() => {
    //       let developers = this.developers;
    //       developers.dev_mode = 2;
    //       this.submitDisabled = false;
    //     })
    //     .catch((err) => {
    //       this.submitDisabled = false;
    //       this.httpHandler(err);
    //     });
    // },
    submitGameMode: function () {
      this.submitDisabled = true;
      this.$http.get("ajax/developers/" + this.$route.params.id + "/moderation")
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                let developers = this.developers;
                developers.dev_mode = 2;
                this.submitDisabled = false;
                const newItem = {
                    game_id: 1,
                    text: null,
                    type: 1,
                    created_at: new Date()
                }
                this.developers.moderation.unshift(newItem)
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            this.httpHandler(err);
          });
    },
    submitGameOff: function () {
      this.submitDisabled = true;
      this.$http.get("ajax/developers/" + this.$route.params.id + "/moderation")
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                let developers = this.developers;
                developers.dev_mode = 1;
                this.submitDisabled = false;
                const newItem = {
                    game_id: 1,
                    text: null,
                    type: 2,
                    created_at: new Date()
                }
                this.developers.moderation.unshift(newItem)
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            this.httpHandler(err);
          });
    },
  },
  components: {
    ModerationItemList,
    ModalModerationOff
  },
};
</script>

<style lang="scss" scoped>

  .dark {
    .wrapper-moderation {
      &__head {

        .head-icon {
          img {
            filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
          }
        }
        p {
          color: #96AFD1;
        }
        .wrap-block-moderation {
          &__status {
            border-bottom: 1px solid rgba(255, 255, 255, 0.07);
            p {
              color: #96AFD1;
            }
          }
        }
      }

      h3 {
        color: #fff;
      }
    }
  }


.wrapper-moderation {
  &__head {
    margin-bottom: 22px;

    .head-icon {
      margin: 0 28px 0 0;
      display: flex;
      align-items: flex-end;

      img {
        padding-bottom: 5px;
      }
    }

    .wrap-block-moderation {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 3px;
      width: 100%;

      &__status {
        padding-bottom: 4px;
        border-bottom: 1px solid #DAE6F1;
        width: 100%;
        margin-right: 30px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #6886AF;
          margin: 0 0 1px 0;
        }

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #FF4B55;
        }
      }

      .btn-secondary.btn-on {
        color: #F6A401!important;
        border: none;
        background-color: rgba(246, 164, 1, 0.1);
        border-radius: 6px;
        transition-duration: .3s;

        svg {
          margin: 3px 10px 0 0;
        }

        &:hover {
          color: #f68e0a !important;
          background-color: rgba(246, 164, 1, 0.2);
          transition-duration: .3s;
        }
      }

      .status-btn {
        color: #F6A401;
        text-transform: inherit;
        background: transparent;
        border-radius: 6px;
        outline: none;
        cursor: default;

        svg {
          margin: 3px 10px 0 0;
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #6886AF;
      margin: 2px 210px 0 46px;

      a {
        color: #26a9f4;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .btn-cab {
      margin: 0;
    }
    .btn-status {
      display: flex;
      justify-content: flex-end;
      color: #26A9F4;
      background-color: transparent;
      outline: none;
      text-transform: inherit;

      svg {
        margin: 3px 10px 0 0;
      }

      &.v-btn--disabled {
        color: #26A9F4!important;
      }
    }

    .btn-published {
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
      outline: none;
      text-transform: inherit;

      &.v-btn--disabled {
        color: #00C443!important;
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 150px;
      padding: 0 12px;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 7px;
  }

  .success {
    width: 100%;
    text-transform:inherit;
    outline:none;

    &.v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 64px;
      padding: 0 16px;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper-moderation {
    &__head {
      .head-icon {
        margin-right: 20px;
      }

      p {
        margin-left: 38px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper-moderation {

    h3 {
      font-size: 16px;
      line-height: 22px;
    }
    &__head {

      .wrap-block-moderation {
        flex-direction: column;
        align-items: flex-start;

        &__status {
          margin: 15px 0 0;
        }
      }

      .head-icon {
        display: none;
      }

      p {
        margin-left: 0;
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
}
</style>
