<template>
    <div class="wrap-info-settings">
        <div class="block-id">
            <span class="icon">
                <img src="/dist/images/frontend/developer-id-icon.svg" alt />
            </span>
            <div class="block-id__text">
                <p>{{__('Game ID')}}</p>
                <span>{{developers.id}}</span>
            </div>
        </div>
        <div class="wrap-input">
            <span class="icon">
                <img src="/dist/images/frontend/developer-iframe-icon.svg" alt />
            </span>
            <div class="input-big">
                <InputLabelTextDefault
                    :label='__("iFrame link on the game")'
                    color="main"
                    v-model="link"
                    v-bind:errors="formValidate.link"
                    @input="formValidate.link = null"
                    @change="updateValidate('link')"
                ></InputLabelTextDefault>
            </div>

            <div class="input-small">
                <InputLabelTextDefault
                    type="number"
                    :label='__("iFrame height")'
                    color="main"
                    width="100%"
                    v-model="height"
                    v-bind:errors="formValidate.height"
                    @input="formValidate.height = null"
                    @change="updateValidate('height')"
                ></InputLabelTextDefault>
            </div>

        </div>



        <div class="wrap-block-key">
            <span class="icon">
                <img src="/dist/images/frontend/password-developer-icon.svg" alt />
            </span>
            <InputLabelTextDefault
                    :label='__("API Secret Key")'
                    color="main"
                    :class_input="(status_secret) ? 'success--text' : 'blue--text'"
                    readonly
                    v-model="secret"
                    v-bind:errors="formValidate.secret"
                    :copy="true"
            ></InputLabelTextDefault>

            <v-btn
                    class="refresh-btn"
                    :loading="submitDisableded"
                    v-on:click="updateSecret(name, 'name')"
                    text
                    icon
                    v-ripple="false"
            >
                <img src="/dist/images/frontend/update-icon.svg" alt />
            </v-btn>
        </div>

        <div class="wrap-category">
        <span class="information-icon information-icon--name icon">
            <img src="/dist/images/frontend/information-category.svg" alt />
        </span>

            <div style="width: 100%">
                <p>{{__('Category')}}</p>
                <DropdownDefault
                        style="width: 100%"
                        color="select_color"
                        v-bind:value.sync="category"
                        :placeholder='__("Select a category")'
                        v-bind:title_key="'name'"
                        v-bind:dropdown="categories"
                        @change="updateValidate('category', category)"
                />
            </div>
        </div>

        <div v-if="developers.type == 2" class="wrap-block-players">
            <span class="icon">
               <img src="/dist/images/frontend/players-developer-icon.svg" alt />
            </span>
            <InputLabelTextDefault
                    type="number"
                    :label='__("Maximum players")'
                    color="main"
                    v-model="max_players"
                    v-bind:errors="formValidate.max_players"
                    @input="formValidate.max_players = null"
                    @change="updateValidate('max_players')"
            ></InputLabelTextDefault>
        </div>

        <div v-if="developers.type == 2" class="block-create">
          <span class="icon">
              <img src="/dist/images/frontend/person-icon.svg" alt />
          </span>

            <div>
                <p class="block-create__title">{{__('Who can create matches?')}}</p>

                <div class="wrap-checkbox d-flex">
                    <v-checkbox :error="((formValidate['type_user']) ? true : false)" v-model="type_user" disabled value="2" color="blue" :label="__('Admin')"></v-checkbox>
                    <v-checkbox :error="((formValidate['type_user']) ? true : false)" v-model="type_user" disabled value="1" color="blue" :label="__('User')"></v-checkbox>
                </div>
            </div>
        </div>

        <div class="wrap-row">
            <div class="d-flex">
        <span class="information-icon icon">
         <img src="/dist/images/frontend/information-agreement-icon.svg" alt />
        </span>
                <InputLabelTextDefault
                    :label='__("User agreement")'
                    class_input="developers_input-information"
                    color="developers_input"
                    v-model="agreement"
                    v-bind:errors="formValidate.agreement"
                    @input="formValidate.agreement = null"
                    @change="updateValidate('agreement')"
                >
                    <template v-slot:before_value>
                <span style="position: relative; top: 29px;">
                    https://docs.google.com/document/
                </span>
                    </template>
                </InputLabelTextDefault>
            </div>

            <span :class="((formValidate.agreement) ? 'hide' : '')"  class="desc">
                {{__('Displayed at installation! By default, the standard')}}
                <a href="https://mindplays.com/terms" target="_blank">{{__('user agreement')}}</a> {{__('is used.')}}
            </span>
        </div>

        <div class="wrap-row">
            <div class="d-flex">
        <span class="information-icon icon">
         <img src="/dist/images/frontend/information-policy-icon.svg" alt />
        </span>
                <InputLabelTextDefault
                    :label='__("Confidentiality policy")'
                    class_input="developers_input-information"
                    color="developers_input"
                    v-model="policy"
                    v-bind:errors="formValidate.policy"
                    @input="formValidate.policy = null"
                    @change="updateValidate('policy')"
                >
                    <template v-slot:before_value>
                <span style="position: relative; top: 29px;">
                    https://docs.google.com/document/
                </span>
                    </template>
                </InputLabelTextDefault>
            </div>

            <span class="desc" :class="((formValidate.policy) ? 'hide' : '')">
                {{__('Displayed at installation! By default, the standard')}}
                <a href="https://mindplays.com/privacy" target="_blank">{{__('Confidentiality policy')}}</a> {{__('is used.')}}
            </span>
        </div>

        <div class="position-mob">
            <div class="img">
                <img src="/dist/images/frontend/information-mob-icon1.svg" alt />
            </div>
            <div class="position-body">
                <p class="text">{{__('Screen position in game')}}</p>
                <div class="mob">
                    <v-radio-group v-model="orientation">
                      <v-radio :value="1" color="blue">
                        <template v-slot:label>
                          <div>{{__('Vertically')}}</div>
                        </template>
                      </v-radio>
                        <v-radio :value="2" color="blue">
                            <template v-slot:label>
                                <div>{{__('Horizontally')}}</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>

        </div>

<!--        <div  class="wrap-btn" v-if="!validateSubmit && !validateAttributes">-->

          <div  class="wrap-btn">
            <div>
<!--              <v-btn-->
<!--                text-->
<!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                :loading="submitDisabled"-->
<!--                class="btn-cab white&#45;&#45;text"-->
<!--                v-on:click="onSubmit"-->
<!--              >{{__('Save changes')}}</v-btn>-->
<!--            </div>-->
<!--            <span v-if="submitSuccess">-->
<!--              <i class="fas fa-check"></i> Saved!-->
<!--            </span>-->
              <BaseButton

                  :loading="submitDisabled"
                  v-on:click="onSubmit"
              >
                <span>{{__('Save changes')}}</span>
              </BaseButton>
              <!-- <v-btn v-if="submitDisabled" default disabled>{{__('loading')}}...</v-btn> -->

              <!--      <v-btn v-else-if="validateSubmit" color="default" disabled>{{__('No validate')}}</v-btn>-->
            </div>

            <p v-if="submitSuccess">
              <i class="fas fa-check"></i> {{__('Saved!')}}
            </p>
        </div>

        <!--      <v-btn v-else text class="btn-secondary btn-disabled" color="default" disabled>{{__('No validate')}}</v-btn>-->
    </div>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    // import ItemListPlatforms from "./ItemListPlatforms";
    // import _ from 'lodash';
    import {url, integer, required, minValue, maxValue, minLength, maxLength} from "vuelidate/lib/validators";
    import DropdownDefault from "../../modules/DropdownDefault";

    // optional style for arrows & dots

    export default {
        name: "GameInfoSettings",
        data() {
            let description = this.developers.description;
            return {
                link: (this.developers.setting.length > 0) ? this.developers.setting[0].link : null,
                height: (this.developers.setting.length > 0) ? this.developers.setting[0].height : 500,
                name: this.developers.name,
                secret: this.developers.secret,
                type_user: (this.developers.type_user == 3) ? ['1', '2'] : [String(this.developers.type_user)],
                max_players: (this.developers.setting.length > 0) ? this.developers.setting[0].max_players : 3,
                status_secret: null,
                pathDevelopers: "ajax/developers/" + this.$route.params.id,
                category: this.developers.category_id,
                agreement: description ? description.agreement : null,
                policy: description ? description.policy : null,
                orientation: (this.developers.orientation) ? this.developers.orientation : 1,

                formValidate: {
                    link: null,
                    height: null,
                    type_user: null,
                    max_players: null,
                    category: null,
                    agreement: null,
                    policy: null,
                    orientation: null,
                },

                settingsValidate: {},
                validate: this.validateMessage,
                setting: this.developers.setting,
                submitDisabled: false,
                submitDisableded: false,
                submitSuccess: false,
                validateSubmit: (this.developers.setting.length) ? false : true,
                validateSettings: false,

            };
        },

        watch: {
            agreement: function () {
                if (this.agreement == '') {
                    this.agreement = null
                }
            },
            policy: function () {
                if (this.policy == '') {
                    this.policy = null
                }
            },
        },
        props: {
            developers: {
                type: Object,
            },
            categories: {
                type: Array,
            },
        },
        computed: {
            game_platforms() {
                return this.$store.getters.platforms;
            },

            // statusSubmit: function () {
            //     let changed= false;
            //
            //     if (this.developers.type == 2 && this.developers.setting.length > 0 &&
            //         (this.link != this.developers.setting[0].link ||
            //             this.height != (this.developers.setting[0].height || 500) ||
            //             ((this.type_user.length > 1) ? '3' : this.type_user[0]) != this.developers.type_user)
            //     ) {
            //         changed= true;
            //     } else if (this.developers.type == 2 && this.developers.setting.length == 0 && (this.link || this.height != 500) ||
            //         ((this.type_user.length > 1) ? '3' : this.type_user[0]) != this.developers.type_user) {
            //         changed= true;
            //     } else if (this.developers.type == 1 && this.developers.setting.length > 0 &&
            //         (this.link != this.developers.setting[0].link ||
            //             this.height != (this.developers.setting[0].height || 500))
            //     ) {
            //         changed= true;
            //     } else if (this.developers.type == 1 && this.developers.setting.length == 0 && (this.link || this.height != 500))
            //     {
            //         changed= true;
            //     }
            //
            //     return changed;
            // }

            // statusSubmit: function () {
            //     let changed= false;
            //
            //     if (this.developers.type == 2) {
            //         if (this.developers.setting.length > 0 &&
            //             (this.link != this.developers.setting[0].link ||
            //             this.category != this.developers.category_id ||
            //             this.height != (this.developers.setting[0].height || 500) ||
            //             ((this.type_user.length > 1) ? '3' : this.type_user[0]) != this.developers.type_user ||
            //             this.max_players != this.developers.setting[0].max_players)
            //         ) {
            //             changed= true;
            //         } else if (this.developers.setting.length == 0 && (this.link || this.height != 500) ||
            //             ((this.type_user.length > 1) ? '3' : this.type_user[0]) != this.developers.type_user ||
            //             this.category != this.developers.category_id ||
            //             this.max_players != 3) {
            //             changed= true;
            //         }
            //     } else if (this.developers.type == 1) {
            //
            //         if (this.developers.setting.length > 0 &&
            //             (this.link != this.developers.setting[0].link ||
            //                 this.category != this.developers.category_id ||
            //                 this.height != (this.developers.setting[0].height || 500))
            //         ) {
            //             changed= true;
            //         } else if (this.developers.setting.length == 0 && (this.link ||
            //             this.height != 500 ||
            //             this.category != this.developers.category_id))
            //         {
            //             changed= true;
            //         }
            //     }
            //     if(
            //         this.link != this.developers.setting[0].link ||
            //         this.orientation != this.developers.orientation ||
            //         (this.description && (this.policy != this.description.policy || this.agreement != this.description.agreement))
            //
            //     ) {
            //         changed= true;
            //     }
            //
            //     return changed;
            // }
        },
        asyncComputed: {
            async_categories() {
                let platforms = this.game_platforms;

                if (platforms) {
                    this.platforms = platforms;
                } else {
                    return null;
                }
            },
        },
        validations() {
            return {
                max_players: {
                    required: ((this.developers.type == 2) ? required : true),
                    minValue: minValue(3),
                    maxValue: maxValue(100),
                },
                type_user: {
                    required: ((this.developers.type == 2) ? required : true),
                },
                link: {
                    url,
                    required,
                },
                height: {
                    required,
                    integer,
                    minValue: minValue(100),
                    maxValue: maxValue(2000),
                },
                category: {
                    required,
                },
                orientation: {
                    required,
                },
                agreement: {
                    minLength: minLength(3),
                    maxLength: maxLength(100),

                },
                policy: {
                    minLength: minLength(3),
                    maxLength: maxLength(100),
                },
            }
        },

        methods: {
            updateSecret: function () {
                this.submitDisableded = true;
                let response = {
                    secret: 1,
                };
                this.$http.post(this.pathDevelopers + "/update", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            this.status_secret = true;
                            this.submitDisableded = false;
                            setTimeout(() => {
                                this.status_secret = false;
                            }, 2000);
                            this.secret = response.data.secret;
                        }
                    })
                    .catch((err) => {
                        this.submitDisableded = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },

            onSubmit: function () {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        link: [],
                        height: [],
                        type_user: [],
                        max_players: [],
                        category: [],
                        agreement: [],
                        policy: [],
                        orientation: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;

                    let type_user= null;

                    if(this.developers.type == 2) {
                        type_user= this.type_user;
                        if (type_user.length > 1) {
                            type_user = "3";
                        } else {
                            type_user = type_user[0];
                        }
                    }

                    const response = {
                        iframe_link: this.link,
                        iframe_height: this.height,
                        type_user: type_user,
                        max_players: this.max_players,
                        category: this.category,
                        agreement: this.agreement,
                        policy: this.policy,
                        orientation: this.orientation,
                    };
                    this.$http.post("ajax/developers/" + this.$route.params.id + "/setting", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.submitSuccess = true;
                                //було
                                // let developers = this.$cleanObject(this.developers); ??????this.$cleanObject
                                // developers['setting'] = {
                                //     link: this.link,
                                //     height: this.height,
                                //     type_user: this.type_user,
                                // };

                                //стало
                                let developers = this.developers;
                                developers.type_user = response.type_user;
                                // developers.max_players = response.max_players;
                                developers["category_id"] = this.category;
                                developers['setting'] = [
                                    {
                                        link: this.link,
                                        height: this.height,
                                        max_players: this.max_players,
                                        // type_user: this.type_user,
                                    }
                                ];
                                developers['description'] = {
                                    agreement: this.agreement,
                                    policy: this.policy,
                                }
                                this.$emit("update:developers", developers);
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
                setTimeout(()=> {
                    this.submitSuccess= null;
                }, 2000);
            },
        },
        components: {
            // ItemListPlatforms,
            InputLabelTextDefault,
            DropdownDefault
        },
    };
</script>

<style lang="scss" scoped>

    /*.block-gender {*/
    /*    display: flex;*/

    /*    &__male {*/
    /*        margin-right: 45px;*/
    /*        display: flex;*/
    /*        align-items: center;*/
    /*    }*/

    /*    &__female {*/
    /*        display: flex;*/
    /*        align-items: center;*/
    /*    }*/

    /*    &__input {*/
    /*        width: 18px;*/
    /*        height: 18px;*/
    /*        border: 1.2px solid rgba(0, 43, 80, 0.3);*/
    /*        box-sizing: border-box;*/
    /*    }*/


    /*    &__label {*/
    /*        font-weight: 400;*/
    /*        font-size: 15px;*/
    /*        line-height: 16px;*/
    /*        letter-spacing: 0.4px;*/
    /*        color: #002B50;*/
    /*        margin-left: 10px;*/
    /*    }*/
    /*}*/


    .dark {
        .wrap-info-settings {
            .block-id {
                &__text {
                    p {
                        color: #96AFD1;
                    }

                    span {
                        color: #fff;
                    }
                }
            }

            .wrap-block-key {
                span {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }
            }

            .wrap-category {
                p {
                    color: #96AFD1;
                }
            }

            .wrap-row {
                .desc {
                    color: #96AFD1;
                }
            }
        }

        .block-id,
        .wrap-input,
        .wrap-row,
        .wrap-block-key,
        .wrap-category,
        .wrap-block-players,
        .block-create {
            img {
                filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
            }
        }
    }

    .wrap-info-settings {
        .block-id {
            display: flex;
            align-items: flex-end;
            margin-bottom: 18px;


            span {
                margin: 10px 28px 0 0;
                min-width: 20px;
                display: flex;
                justify-content: center;
            }

            &__text {
                p {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #6886AF;
                    margin-bottom: 0;
                }

                span {
                    display: flex;
                    justify-content: flex-start;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #002B50;
                    margin: 5px 0 0 0;
                }
            }


        }

        .wrap-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 26px;

            img {
                height: 20px;
            }

            span {
                margin: 10px 28px 0 0;
                min-width: 20px;
                display: flex;
                justify-content: center;
            }

            .input-big {
                width: 67%;
                margin: 0 20px 0 0;
                /*height: 40px;*/
            }

            .input-small {
                width: 34%;
                margin: 0 5px;

            }
        }

        .wrap-block-key {
            display: flex;
            align-items: flex-start;
            margin-right: 45px;
            margin-bottom: 25px;
            position: relative;

            span {
                margin: 17px 28px 0 0;

                img {
                    height: 20px;
                }
            }
        }

        .wrap-category {
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            max-width: 400px;
            width: 100%;

            img {
                height: 20px;
            }

            p {
                position: relative;
                z-index: 1;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: -8px;
            }
        }
        .information-icon {
            margin: 16px 28px 0 0;
            min-width: 20px;
            display: flex;
            justify-content: center;
        }

        .wrap-block-players {
            max-width: 230px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-right: 45px;
            margin-bottom: 25px;
            position: relative;
            span {
                margin: 17px 28px 0 0;

                img {
                    margin-left: -2px;
                    height: 18px;
                }
            }
        }

        .wrap-row {
            margin-bottom: 6px;

            img {
                margin-top: 15px;
                height: 20px;
            }

            .desc {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-left: 48px;

                a {
                    color: #26a9f4;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .hide {
                display: block;
                margin-top: 12px;
            }
        }

        .v-btn.refresh-btn {
            outline: none;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .block-create {
            display: flex;
            align-items: flex-start;
            margin-top: 6px;

            &__title {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            span {
                margin: 0 28px 0 0;

                img {
                    height: 20px;
                }
            }

            .v-input {
                margin-right: 50px;
            }
        }

        .v-btn {
            margin: 0;

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 64px;
                width: 124px;
                padding: 0;
            }
        }
        .wrap-btn {
            position: relative;
            display: flex;
            justify-content: center;
            margin: 40px 0 20px;
            min-height: 40px;

            p {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
                border-color: transparent;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #00C443;

                i {
                    font-size: 12px;
                    margin: 0 6px 0 20px;
                }
            }
        }
        .btn-disabled {
            display: block;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 768px) {
        .wrap-info-settings {

            .block-id,
            .wrap-input,
            .wrap-row,
            .wrap-block-key,
            .wrap-category {
                .icon {
                    margin-right: 20px;
                }
            }

            .wrap-row {
                .desc {
                    font-size: 11px;
                    line-height: 14px;
                    margin-top: 4px;
                    display: block;
                    margin-left: 48px;
                }
            }

            .wrap-input {
                .input-big {
                    width: 60%;
                }
                .input-small {
                    width: 40%;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .wrap-info-settings {
            .block-id,
            .wrap-input,
            .wrap-row,
            .wrap-block-key,
            .wrap-category {
                .icon {
                    display: none;
                }
            }

            .wrap-row {
                .desc {
                    margin-left: 0;
                }
            }

            .block-id {
            }

            .wrap-input {
                flex-direction: column;
                align-items: flex-start;

                .input-big {
                    width: 100%;
                    margin: 0 0 25px;
                }

                .input-small {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }


</style>
