export default {
    state: {
        platforms: false,
        categories: false,
        modal_invite: null,
        invite_game: [],
        invite_room: null,
    },
    getters: {
        platforms(state){
            return state.platforms;
        },
        categories(state){
            return state.categories;
        }
    },
    actions: {
        asyncPlatforms(context, payload) {
            context.commit('updatePlatforms', payload.data);
        },
        asyncCategories(context, payload) {
            context.commit('updateCategories', payload.data);
        },
        asyncInviteGame(context, payload) {
            context.commit('updateInviteGame', payload);
        },
        asyncPushInviteGame(context, payload) {
            context.commit('pushInviteGame', payload);
        },
        asyncInviteRoom(context, payload) {
            context.commit('pushInviteRoom', payload);
        },
        asyncModalInvite(context, payload) {
            context.commit('pushModalInvite', payload);
        },
    },
    mutations: {
        updatePlatforms(state, data) {
            this.state.games.platforms= data;
        },
        updateCategories(state, data) {
            this.state.games.categories= data;
        },
        updateInviteGame(state, data) {
            this.state.games.invite_game= data;
        },
        pushInviteGame(state, data) {
            this.state.games.invite_game.push(data);
        },
        pushInviteRoom(state, data) {
            this.state.games.invite_room= data;
        },
        pushModalInvite(state, data) {
            this.state.games.modal_invite= data;
        }
    }
}
