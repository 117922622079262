<template>
    <div class="wrap-all-quest">
        <div v-if="(my_quest && my_quest.length) || (actual_quest && actual_quest.length)">
            <div v-if="my_quest && my_quest.length && auth">
                <ItemQuestsNew v-for="(item, key) in my_quest" :item="item.quest" :position="key+1" :key="key">
                    <div slot="right" class="progress-line">
                        <div class="active-status" v-if="item.status == 1">
                            <v-tooltip
                                    v-model="show[key]"
                                    bottom
                                    color="transparent"
                                    nudge-top="10"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <progress v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>
                                </template>
                                <p class="block-name ma-0">
                                    {{__('Done')}}
                                    <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>
                                    <span v-else>{{Number(item.progress).toFixed(0)}}</span>
                                    {{__('from')}}

                                    <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>
                                    <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>

                                    <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>
                                    <span v-else></span>
                                </p>
                            </v-tooltip>

<!--                            <span class="active-status__num">{{ ((Number(item.progress) * 100) / Number(item.progress_end)).toFixed(2)}}%</span>-->
                        </div>
                        <div class="completed" v-else-if="item.status == 2">
<!--                            {{__('Completed')}}-->
                            <v-tooltip
                                    v-model="show[key]"
                                    bottom
                                    color="transparent"
                                    nudge-top="10"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <progress v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>
                                </template>
                                <p class="block-name ma-0">
                                    {{__('Done')}}
                                    <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>
                                    <span v-else>{{Number(item.progress).toFixed(0)}}</span>
                                    {{__('from')}}

                                    <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>
                                    <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>

                                    <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>
                                    <span v-else></span>
                                </p>
                            </v-tooltip>
                        </div>
                        <div class="failed" v-else-if="item.status == 3">
<!--                            {{__('Failed')}}-->
                            <v-tooltip
                                    v-model="show[key]"
                                    bottom
                                    color="transparent"
                                    nudge-top="10"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <progress v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>
                                </template>
                                <p class="block-name ma-0">
                                    {{__('Done')}}
                                    <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>
                                    <span v-else>{{Number(item.progress).toFixed(0)}}</span>
                                    {{__('from')}}

                                    <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>
                                    <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>

                                    <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>
                                    <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>
                                    <span v-else></span>
                                </p>
                            </v-tooltip>
                        </div>
                    </div>
                    <div slot="stat">
                        <p v-if="item.status == 0" class="performed">{{__('Available to pass')}}</p>
                        <p v-else-if="item.status == 2" class="done">{{__('Done')}}!</p>
                        <p v-else-if="item.status == 3" class="failed">{{__('Failed')}}!</p>
                        <p v-else class="available">{{__('In progress')}}</p>
                    </div>
                </ItemQuestsNew>
<!--                <QuestsItemNew v-for="(item, key) in my_quest" :item="item.quest" :position="key+1" :key="key">-->
<!--                    <div slot="right">-->
<!--                        <div class="active-status" v-if="item.status == 1">-->
<!--                            <v-tooltip-->
<!--                                    v-model="show[key]"-->
<!--                                    bottom-->
<!--                                    color="transparent"-->
<!--                                    nudge-top="10"-->
<!--                            >-->
<!--                                <template v-slot:activator="{ on, attrs }">-->
<!--                                    <progress  v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end">-->

<!--                                    </progress>-->
<!--                                </template>-->
<!--                                <p class="block-name ma-0">-->
<!--                                    {{__('Done')}}-->
<!--                                    <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>-->
<!--                                    <span v-else>{{Number(item.progress).toFixed(0)}}</span>-->
<!--                                    {{__('from')}}-->

<!--                                    <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>-->
<!--                                    <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>-->

<!--                                    <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>-->
<!--                                    <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>-->
<!--                                    <span v-else></span>-->
<!--                                </p>-->
<!--                            </v-tooltip>-->

<!--                            <span class="active-status__num">{{ ((Number(item.progress) * 100) / Number(item.progress_end)).toFixed(2)}}%</span>-->
<!--                        </div>-->
<!--                        <div class="completed-status" v-else-if="item.status == 2">{{__('Completed')}}</div>-->
<!--                        <div class="failed-status" v-else-if="item.status == 3">{{__('Failed')}}</div>-->
<!--                    </div>-->
<!--                </QuestsItemNew>-->
            </div>
            <div v-if="actual_quest && actual_quest.length">
<!--                          <QuestsItem :actual_quest.sync="actual_quest" v-for="(item, key) in actual_quest" :item="item" :position="key+1" :key="key"/>-->
                <ItemQuestsNew :actual_quest.sync="actual_quest" v-for="(item, key) in actual_quest" :item="item" :position="key+1" :key="key">
                    <div slot="stat">
                        <p class="available">{{__('Available to pass')}}</p>
                    </div>
                </ItemQuestsNew>
<!--                <QuestsItemNew :actual_quest.sync="actual_quest" v-for="(item, key) in actual_quest" :item="item" :position="key+1" :key="key">-->
<!--                </QuestsItemNew>-->
            </div>
        </div>

        <div v-else-if="actual_quest===null">
            {{__('loading')}}
        </div>
        <div v-else class="block-empty">
            <img class="img img--1" src="/dist/images/frontend/icon-quests.svg" alt />
            <p>{{__('You dont have quests')}} :(</p>
        </div>


    </div>
</template>

<script>
    import ItemQuestsNew from  "./ItemQuestsNew";
  // import QuestsItem from "./QuestsItem"
  // import QuestsItemNew from "./QuestsItemNew";
  // import Scrollbar from "smooth-scrollbar";

  export default {
    name: 'AllQuests',
    data() {
        return {
          actual_quest: this.quests.actual,
          my_quest: this.quests.my_quest,
          // next_page_url: null,
          // loadingPage: false,
          show: {},
        }
    },

    props: {
        progress: {
            type: Number
        },
        data: {
            type: Boolean
        },
        info: {
            type: Object
        },
        quests: {
            type: Object
        }
    },
      computed:{
          auth() {
              return this.$store.state.auth.user;
          },
      },

      methods: {

        ConvertTime(seconds)  {
            let days     = Math.floor(seconds / (24*60*60));
            seconds -= days    * (24*60*60);
            let hours    = Math.floor(seconds / (60*60));
            seconds -= hours   * (60*60);
            let minutes  = Math.floor(seconds / (60));
            seconds -= minutes * (60);
            return ((0<days)?(days+"day "):"") + ((0<hours)?(hours+"h "):"") + ((0 < minutes) ? (minutes + "m ") : "")+ ((0 < seconds) ? (seconds + "s") : "0s");
        },
        ConvertTimeEnd(seconds)  {
            let days     = Math.floor(seconds / (24*60*60));
            seconds -= days    * (24*60*60);
            let hours    = Math.floor(seconds / (60*60));
            seconds -= hours   * (60*60);
            let minutes  = Math.floor(seconds / (60));
            seconds -= minutes * (60);
            return ((0<days)?(days):"") + ((0<hours)?(hours):"") + ((0 < minutes) ? (minutes) : "")+ ((0 < seconds) ? (seconds) : "");
        }
      // scrollAllQuest() {
      //   let quest_list = this.$refs.quest_list;
      //   const progress = quest_list.scrollTop / (quest_list.scrollHeight - quest_list.clientHeight);
      //   if (!this.loadingPage && this.next_page_url) {
      //     let bottomOfWindow = 0.7 <= progress;
      //     if (bottomOfWindow) {
      //       this.loadingPage = true;
      //       this.$http.get(this.next_page_url)
      //         .then((response) => {
      //           return response.json();
      //         })
      //         .then((response) => {
      //           this.$checkAuth(response);
      //           response = response.data;
      //           let actual_quest = this.actual_quest;
      //           this.actual_quest = actual_quest.concat(response.data);
      //           this.next_page_url = response.actual.next_page_url;
      //           this.loadingPage = false;
      //         })
      //         .catch((err) => {
      //           this.httpHandler(err);
      //         });
      //     }
      //   }
      // },

        // scrollQuest: function () {
        //     const progress =
        //         document.documentElement.scrollTop /
        //         (document.documentElement.scrollHeight -
        //             document.documentElement.clientHeight);
        //     if (!this.loadingPage && this.next_page_url) {
        //         let bottomOfWindow = 0.7 <= progress;
        //         if (bottomOfWindow) {
        //             this.loadingPage = true;
        //             this.$http.get(this.next_page_url)
        //                 .then((response) => {
        //                     return response.json();
        //                 })
        //                 .then((response) => {
        //                     this.$checkAuth(response);
        //                     let actual_quest = this.actual_quest;
        //                     this.actual_quest = actual_quest.concat(response.data);
        //                     this.next_page_url = response.actual.next_page_url;
        //                     this.loadingPage = false;
        //                 })
        //                 .catch((err) => {
        //                     this.httpHandler(err);
        //                 });
        //         }
        //     }
        // },
    },

    // created() {
    //     let num = 0;
    //     let timeout = setInterval(() => {
    //         num += 1;
    //         this.$emit('update:progress', num);
    //         if(num > 50) {
    //             clearInterval(timeout)
    //         }
    //     },15);
    //   this.$http.get("ajax/quest")
    //       .then((response) => {
    //         return response.json();
    //       })
    //       .then((response) => {
    //           if(this.$checkAuth(response)) {
    //               this.actual_quest = response.data.actual;
    //               this.my_quest = response.data.my_quest;
    //               this.next_page_url = response.data.next_page_url;
    //               this.$emit('update:info', response.data.info);
    //               this.$emit('update:data', true);
    //               clearInterval(timeout);
    //               this.$emit('update:progress', 100);
    //               setTimeout(() => {
    //                   this.$emit('update:progress', 0);
    //               }, 500);
    //           }
    //       })
    //       .catch((err) => {
    //         this.httpHandler(err);
    //       });
    //
    //     // this.$addListener(window,'scroll', () => {
    //     //     this.scrollQuest();
    //     // });
    // },

    components: {
      // QuestsItem,
      // QuestsItemNew,
        ItemQuestsNew
    }
  }
</script>

<style lang="scss" scoped>

.dark .wrap-all-quest {
    .failed-status,
    .completed-status {

    }

}

.wrap-all-quest {

    &::-webkit-scrollbar-track
    {
        margin-top: 5px;
    }

    &::-webkit-scrollbar
    {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb
    {
        background: rgba(150, 175, 209, 0.3);
        border-radius: 10px;
    }

    .active-status {
        min-width: 110px;
        /*background-color: #F0F6FE;*/
        height: 40px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 19px;
        color: #96AFD1;
        position: relative;

        &__num {
            width: 110px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            color: #002b50;
            font-size: 15px;
            line-height: 19px;
            pointer-events: none;
            /*text-shadow: -0   -1px 0   #26A9F4,
            0   -1px 0   #26A9F4,
            -0    1px 0   #26A9F4,
            0    1px 0   #26A9F4,
            -1px -0   0   #26A9F4,
            1px -0   0   #26A9F4,
            -1px  0   0   #26A9F4,
            1px  0   0   #26A9F4,
            -1px -1px 0   #26A9F4,
            1px -1px 0   #26A9F4,
            -1px  1px 0   #26A9F4,
            1px  1px 0   #26A9F4,
            -1px -1px 0   #26A9F4,
            1px -1px 0   #26A9F4,
            -1px  1px 0   #26A9F4,
            1px  1px 0   #26A9F4;*/
        }
    }

    .completed-status {
        min-width: 110px;
        background-color: #F0F6FE;
        height: 40px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 19px;
        color: #26A9F4;
    }

    .failed-status {
        min-width: 110px;
        background-color: #F0F6FE;
        height: 40px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 19px;
        color: #F04C44;
    }



    .block-progress-line {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        span {
            font-size: 14px;
            line-height: 19px;
            color: #607EA7;
            margin-right: 20px;
        }

        .block-progress-quest {
            margin-left: 12px;

            span {
                font-size: 14px;
                line-height: 19px;
                color: #26A9F4;
                margin: 0;
            }
        }
    }
}

.block-name {
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    z-index: 1;
    font-size: 13px;
    line-height: 18px;
    color: #002B50;
    padding: 4px 8px;
    background: rgb(255, 255, 255);
    border: 1px solid #ECF4FF;
    border-radius: 6px;
    max-width: 110px;
    overflow: hidden;
    /*white-space: nowrap;*/
}

    @media screen and (max-width: 450px){
        .wrap-all-quest {

            .active-status {
                height: 36px;
                font-size: 12px;
                line-height: 16px;

                &__num {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            .completed-status {
                height: 36px;
                font-size: 12px;
                line-height: 16px;
            }

            .failed-status {
                height: 36px;
                font-size: 12px;
                line-height: 16px;
            }
        }

    }
</style>
