<template>
    <div class="page-auth">
        <div class="content-auth">
            <img src="/dist/images/frontend/LogoMP.svg" class="page-auth__logo" alt="mindpalys">

            <h1 class="page-auth__title" style="margin-bottom: 20px">{{__('Password reset')}}</h1>

            <p class="page-auth__subtitle" style="margin-bottom: 20px">{{__('To reset your password, enter the email address you use to move to the MindPlays cabinet')}}</p>

            <div class="block-input">
                <InputLabelTextDefault
                        color="auth"
                        :label="__('E-mail address')"
                        v-model="email"
                        v-bind:errors="formValidate.email"
                        @change="updateValidate('email')"
                ></InputLabelTextDefault>
            </div>

            <div class="block-control">


                <BaseButton
                    style="width: 100%"
                    class_btn="btn-guest"
                    v-on:click="openCaptcha"
                    :loading="submitDisabled"
                >
                    <span>
                        <img src="/dist/images/frontend/sign.svg" alt />
                        {{__('Reset Password')}}
                    </span>
                </BaseButton>
            </div>

            <div class="block-bottom">
                <router-link
                    class="block-bottom__link"
                    :to="{name: 'login', params: {lang: $store.getters.getLang}}"
                >
                    {{__('Log in to game area')}}
                </router-link>

            </div>

        </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>
    import { required, email } from "vuelidate/lib/validators";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import BaseButton from "../../modules/BaseButton";
    import Captcha from "../../modules/Captcha";

    export default {
        name: "AuthForgot",
        data() {
            return {
                captcha: false,
                submitDisabled: false,
                email: null,
                formValidate: {
                    email: null,
                },
                rules: this.validateMessage,
                dateUpdate: null,
            };
        },
        props: {
            modal: {
                type: Object,
            },
        },
        validations: {
            email: {
                required,
                email,
            },
        },
        methods: {
            openCaptcha() {
                let valid= this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },
            onSubmit: function (session, arrayData) {
                let valid = this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        email: this.email,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http
                        .post("ajax/password/email", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.captcha= false;
                                this.$router.push({name: 'login'});
                                // this.$store.dispatch("asyncModalAuth", false);
                                this.$notify({
                                    group: "message",
                                    title: "The letter was successfully sent to the mail",
                                });
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            this.captcha= false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            BaseButton,
            Captcha
        },
    };
</script>

<style lang="scss" scoped>

</style>