<template>
    <div class="game-solo-bit" :class="(matches.wallet == 1) ? 'game-solo-bit-usdt' : 'game-solo-bit-mind'">
        <ul :class="(matches.wallet == 1) ? 'bit-usdt' : 'bit-mind'">
            <li v-if="auth" v-on:click="sendInvitation()">
                <div class="l">
                    <div class="bit" v-if="matches.wallet == 2">
                        <img src="/dist/images/frontend/light-coin.svg" alt="">
                        <p>{{matches.amount}} MIND</p>
                    </div>
                    <div class="bit" v-else>
                        <img src="/dist/images/frontend/money.svg" alt="">
                        <p>{{matches.amount}} USDT</p>
                    </div>
                </div>
                <div class="play">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                        <g clip-path="url(#clip0_1890_20379)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9661 10.4415C12.993 12.3111 7.62308 15.4747 4.87589 16.4904C4.43742 16.6521 3.31959 16.9947 3.07871 16.9994C2.56716 17.0137 2.07456 16.7616 1.83638 16.3572C1.74435 16.2002 1.4737 15.175 1.38708 14.7183C1.12996 13.3292 0.997335 11.1717 1.00004 9.00241C0.997335 6.72605 1.14078 4.46633 1.42768 3.09148C1.50347 2.72041 1.72541 1.86647 1.79037 1.72851C1.91216 1.47162 2.1341 1.26943 2.40206 1.14336C2.60776 1.04584 2.84323 0.993505 3.07871 1.00065C3.31959 1.0054 4.29938 1.30749 4.635 1.42404C7.28205 2.32793 12.882 5.60571 14.939 7.53241C15.1231 7.70605 15.6292 8.17227 15.7131 8.26741C15.9053 8.48149 16 8.74076 16 9.00241C16 9.24504 15.9161 9.49241 15.7429 9.69936C15.6536 9.8064 15.1366 10.2821 14.9661 10.4415Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1890_20379">
                                <rect width="17" height="18" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p>{{__("Play now")}}</p>
                </div>
<!--                <div class="name">-->
<!--                    <p>LITE</p>-->
<!--                </div>-->
            </li>
            <li v-else v-on:click="$store.commit('updateModalAuth', 'login')">
                <div class="l">
                    <div class="bit" v-if="matches.wallet == 2">
                        <img src="/dist/images/frontend/light-coin.svg" alt="">
                        <p>{{matches.amount}} MIND</p>
                    </div>
                    <div class="bit" v-else>
                        <img src="/dist/images/frontend/money.svg" alt="">
                        <p>{{matches.amount}} USDT</p>
                    </div>
                </div>
                <div class="play">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                        <g clip-path="url(#clip0_1890_20379)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9661 10.4415C12.993 12.3111 7.62308 15.4747 4.87589 16.4904C4.43742 16.6521 3.31959 16.9947 3.07871 16.9994C2.56716 17.0137 2.07456 16.7616 1.83638 16.3572C1.74435 16.2002 1.4737 15.175 1.38708 14.7183C1.12996 13.3292 0.997335 11.1717 1.00004 9.00241C0.997335 6.72605 1.14078 4.46633 1.42768 3.09148C1.50347 2.72041 1.72541 1.86647 1.79037 1.72851C1.91216 1.47162 2.1341 1.26943 2.40206 1.14336C2.60776 1.04584 2.84323 0.993505 3.07871 1.00065C3.31959 1.0054 4.29938 1.30749 4.635 1.42404C7.28205 2.32793 12.882 5.60571 14.939 7.53241C15.1231 7.70605 15.6292 8.17227 15.7131 8.26741C15.9053 8.48149 16 8.74076 16 9.00241C16 9.24504 15.9161 9.49241 15.7429 9.69936C15.6536 9.8064 15.1366 10.2821 14.9661 10.4415Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1890_20379">
                                <rect width="17" height="18" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <p>{{__("Play now")}}</p>
                </div>
                <!--                <div class="name">-->
                <!--                    <p>LITE</p>-->
                <!--                </div>-->
            </li>
        </ul>

    </div>
</template>

<script>

    export default {
        data () {
            return {
            }
        },
        props: {
            game: {
                type: Object,
            },
            matches: {
                type: Object,
            },
            profile: {
                type: Object
            },
            item_game: {
                type: String,
            },
            class_list: {
                type: String,
            },
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            sendInvitation() {
                let matches = this.matches;

                this.submitDisabled = true;

                const formData = {
                    match_id: matches.id,
                    game_id: this.game.id,
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/games/play_single', formData)
                    .then(response=> {
                        return response.json()
                    })
                    .then((response)=> {
                        if(this.$checkAuth(response)) {
                            this.submitDisabled = false;
                            response = response.data;

                            this.$router.push({
                                name: "frame",
                                params: {
                                    id: response.game_url,
                                    room_id: response.room_id,
                                    battle_id: response.battle_id,
                                    lang: this.$store.getters.getLang
                                },
                            });
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                        this.submitDisabled = false;
                    });
            },
        },
        components: {

        }
    };
</script>