<template>
  <div>
    <LayoutNavAuth>
        <h1>{{__('Friends')}}</h1>

        <div slot="right" class="wrap-tabs" >
            <tabs
            :tabs="side_tabs[(currentUser) ? 'user': 'auth']"
            :currentTab="currentTab"
            :wrapper-class="'buttons-tabs'"
            :tab-class="'buttons-tabs__item'"
            :tab-active-class="'buttons-tabs__item_active'"
            :line-class="'buttons-tabs__active-line'"
            @onClick="sideChange"
            >
                <template v-slot:requests="item">
                    {{__(item.title)}} <span v-if="friends_req_count">{{friends_req_count}}</span>
                </template>
            </tabs>
        </div>

        <div slot="right" class="wrap-select">
            <DropdownDefault
            class_modal="modal_game"
            color="select_game"
            v-bind:dropdown="side_tabs[(currentUser) ? 'user': 'auth']"
            v-bind:value.sync="side_tabs.value"
            @updateValue="sideChange"
            />
        </div>

        <div class="loading"  :style="{width: progress + '%'}"></div>
    </LayoutNavAuth>
    <div class="block-peoples dashboard-content">
      <div class="wrapper-people">
          <div class="block-body" :style="($route.name == 'peoples') ? {marginTop: '0px'} : ''">
            <div class="wrap-header">
                <h1 class="block-peoples-head__title" v-if="user_info">Friends <router-link :to="{name: 'profile', params: {id: user_info.public_id}}">{{user_info.name+" "+user_info.last_name}}</router-link></h1>
                <h1 v-else-if="currentTab != 'peoples'">
                    {{__('List of your friends')}}
                    <span v-if="currentTab == 'friends' && users_list && users_list.count_total">({{users_list.count_total}})</span>
                </h1>
                <h1 v-else>{{__('Peoples')}}</h1>

                <div class="block-right">
                    <span>{{__('Find friends and rivals')}}</span>

                    <v-btn
                        tag="a"
                        text
                        v-ripple="false"
                        class="btn-link"
                        :href="(lang == 'ru') ? 'http://t.me/mindplayschat_ru' : 'http://t.me/mindplayschat'"
                        target="_blank"
                    >
                        <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.597745 8.39728L4.99949 10.591C5.16974 10.675 5.37074 10.669 5.53349 10.5738L9.45149 8.29603L7.07999 10.3975C6.95924 10.5048 6.89024 10.6578 6.89024 10.819V15.9378C6.89024 16.4778 7.57949 16.705 7.90274 16.276L9.80549 13.7463L14.511 16.426C14.841 16.6158 15.264 16.4253 15.3405 16.048L18.2752 1.42303C18.363 0.983527 17.9257 0.625027 17.5192 0.788527L0.644245 7.36978C0.191246 7.54678 0.161996 8.18053 0.597745 8.39728ZM16.9702 2.21053L14.391 15.0633L9.93224 12.5238C9.68924 12.385 9.37649 12.4465 9.20399 12.6745L8.01524 14.2548V11.0718L14.4285 5.38978C14.9362 4.94053 14.3527 4.14178 13.773 4.48228L5.23049 9.44878L2.2365 7.95703L16.9702 2.21053Z" fill="currentColor"/>
                        </svg>
                    </v-btn>

                    <v-btn
                        text
                        v-ripple="false"
                        class="btn-link"
                        :href="(lang == 'ru') ? 'https://www.facebook.com/mindplaysesports/' : 'https://www.facebook.com/mindplays.platform/'"
                        target="_blank"
                    >
                        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.48194 17.965H3.5994C3.11792 17.965 2.72626 17.5733 2.72626 17.0918V10.5901H1.04453C0.563049 10.5901 0.171387 10.1983 0.171387 9.71698V6.93099C0.171387 6.44951 0.563049 6.05785 1.04453 6.05785H2.72626V4.66272C2.72626 3.2794 3.16063 2.10249 3.98227 1.25943C4.80762 0.412521 5.96105 -0.0350342 7.31786 -0.0350342L9.51622 -0.0314636C9.99687 -0.0306396 10.3878 0.361023 10.3878 0.841675V3.42841C10.3878 3.90988 9.99632 4.30154 9.51499 4.30154L8.03485 4.30209C7.58345 4.30209 7.46851 4.39259 7.44393 4.42033C7.40341 4.46634 7.35521 4.59639 7.35521 4.95551V6.05771H9.40375C9.55797 6.05771 9.70738 6.09575 9.83579 6.16744C10.1128 6.32221 10.285 6.61485 10.285 6.93112L10.2839 9.71712C10.2839 10.1983 9.89223 10.59 9.41075 10.59H7.35521V17.0918C7.35521 17.5733 6.96341 17.965 6.48194 17.965ZM3.78149 16.9097H6.29984V10.1177C6.29984 9.79622 6.56145 9.53475 6.8828 9.53475H9.22865L9.22962 7.11308H6.88266C6.56131 7.11308 6.29984 6.85161 6.29984 6.53012V4.95551C6.29984 4.54324 6.34172 4.0744 6.65291 3.72202C7.02892 3.29602 7.62149 3.24686 8.03458 3.24686L9.33261 3.24631V1.0235L7.31703 1.0202C5.13652 1.0202 3.78149 2.41602 3.78149 4.66272V6.53012C3.78149 6.85147 3.52002 7.11308 3.19867 7.11308H1.22662V9.53475H3.19867C3.52002 9.53475 3.78149 9.79622 3.78149 10.1177V16.9097ZM9.51416 1.02377H9.5143H9.51416Z" fill="currentColor"/>
                        </svg>
                    </v-btn>

                    <v-btn
                        tag="a"
                        text
                        v-ripple="false"
                        class="btn-link"
                        :href="(lang == 'ru') ? 'https://www.instagram.com/mindplays/' : 'https://www.instagram.com/mindplays.esports/'"
                        target="_blank"
                    >
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                                <path d="M13.3868 -6.10352e-05H5.13171C2.44501 -6.10352e-05 0.259277 2.18567 0.259277 4.87238V13.1276C0.259277 15.8142 2.44501 17.9999 5.13171 17.9999H13.387C16.0735 17.9999 18.2593 15.8142 18.2593 13.1276V4.87238C18.2593 2.18567 16.0735 -6.10352e-05 13.3868 -6.10352e-05V-6.10352e-05ZM17.204 13.1276C17.204 15.2323 15.4917 16.9447 13.3868 16.9447H5.13171C3.02687 16.9447 1.31451 15.2323 1.31451 13.1276V4.87238C1.31451 2.76753 3.02687 1.05518 5.13171 1.05518H13.387C15.4917 1.05518 17.204 2.76753 17.204 4.87238V13.1276Z" fill="currentColor"/>
                                <path d="M9.25914 4.07819C6.54524 4.07819 4.3374 6.28603 4.3374 8.99992C4.3374 11.7138 6.54524 13.9217 9.25914 13.9217C11.973 13.9217 14.1809 11.7138 14.1809 8.99992C14.1809 6.28603 11.973 4.07819 9.25914 4.07819ZM9.25914 12.8664C7.12724 12.8664 5.39264 11.132 5.39264 8.99992C5.39264 6.86803 7.12724 5.13342 9.25914 5.13342C11.3912 5.13342 13.1256 6.86803 13.1256 8.99992C13.1256 11.132 11.3912 12.8664 9.25914 12.8664Z" fill="currentColor"/>
                                <path d="M14.2986 2.33026C13.4966 2.33026 12.8442 2.98271 12.8442 3.78458C12.8442 4.58658 13.4966 5.23903 14.2986 5.23903C15.1006 5.23903 15.753 4.58658 15.753 3.78458C15.753 2.98257 15.1006 2.33026 14.2986 2.33026ZM14.2986 4.18366C14.0786 4.18366 13.8995 4.00458 13.8995 3.78458C13.8995 3.56444 14.0786 3.3855 14.2986 3.3855C14.5187 3.3855 14.6978 3.56444 14.6978 3.78458C14.6978 4.00458 14.5187 4.18366 14.2986 4.18366Z" fill="currentColor"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="18" height="18" fill="white" transform="translate(0.259277)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </v-btn>

                </div>
            </div>
          <div class="block-peoples-head">
              <div class="block-switch">
                  <p>{{__('Show online only')}}</p>
                <v-switch
                    v-model="online"
                    color="darkblue"
                    hide-details
                    v-ripple="false"
                    @change="onSubmit()"
                ></v-switch>
              </div>

              <div class="wrap-btn">
                  <div class="wrap-btn-sorting">
                      <v-btn text v-ripple="false" class="btn-search" @click="peoples_sorting = !peoples_sorting" :class="(peoples_sorting || filter.length) ? 'btn-search--active': ''">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27778 3.63981C6.27778 5.0963 5.0963 6.2787 3.63889 6.2787C2.18148 6.2787 1 5.0963 1 3.63981C1 2.18241 2.18148 1 3.63889 1C5.0963 1 6.27778 2.18241 6.27778 3.63981Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M9.93269 3.63984H16.2781" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0006 12.8666C11.0006 11.4091 12.182 10.2277 13.6394 10.2277C15.0969 10.2277 16.2783 11.4091 16.2783 12.8666C16.2783 14.324 15.0969 15.5054 13.6394 15.5054C12.182 15.5054 11.0006 14.324 11.0006 12.8666Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M7.34574 12.8666H1.00037" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </v-btn>
                      <div v-if="filter.length" class="block-count">{{filter.length}}</div>
                  </div>

                  <div class="wrap-btn-search">
                      <v-btn text v-ripple="false" class="btn-search" @click="changeClass()">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </v-btn>
                  </div>


                  <div class="search" ref="block_search">
                      <div class="search__icon">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </div>
                      <div class="search__input">
                          <SearchDefault
                                  v-model="search"
                                  style="width: 100%"
                                  :placeholder="__('Search ...')"
                                  class_search="games_search"
                                  v-on:keyup.enter="onSubmit()"
                                  @searching="onSubmit()"
                          ></SearchDefault>
                      </div>
                      <div class="cancel-icon" @click="cancelSearch()">
                          <svg v-if="show" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.59548 4.00097L7.87669 0.719756C8.04111 0.555319 8.04111 0.288726 7.87669 0.124304C7.71225 -0.0401327 7.44564 -0.0401327 7.28122 0.124304L3.99999 3.40552L0.71878 0.124304C0.554343 -0.0401327 0.287749 -0.0401327 0.123328 0.124304C-0.0410937 0.288742 -0.0411093 0.555335 0.123328 0.719756L3.40454 4.00097L0.123328 7.28218C-0.0411093 7.44662 -0.0411093 7.71321 0.123328 7.87763C0.287765 8.04207 0.554358 8.04206 0.71878 7.87763L3.99999 4.59642L7.28119 7.87763C7.44563 8.04207 7.71223 8.04206 7.87666 7.87763C8.04108 7.7132 8.04108 7.4466 7.87666 7.28218L4.59548 4.00097Z" fill="currentColor"/>
                          </svg>
                      </div>
                  </div>
              </div>
          </div>

          <div class="block-thead">
              <span>{{__('Information')}}</span>
              <span>{{__('Rating')}}</span>
              <span>{{__('Country')}}</span>
              <span>{{__('You VS User')}}</span>
          </div>

          <div class="wrapper-body" :class="($route.name == 'peoples') ? 'wrapper-body-people' : ''" :style="((active_search) ? {marginTop: '40px'} : '')">
            <div class="block-peoples-body" :class="($route.name == 'peoples') ? 'block-peoples-body--people' : 'block-peoples-body'">
              <Friends v-bind:progress.sync="progress" v-bind:update.sync="update" v-bind:property="property" v-bind:currentTab="currentTab" @updatePeoples="updatePeoples" v-bind:users_list.sync="users_list" v-bind:loading_page.sync="loading_page" :online.sync="online" :search.sync="search" v-bind:sorting="sorting">
                <template>
                  <div class="scroll">
                    <div v-if="$isEmpty(users_list)">
                      <div v-if="users_list.users">
                        <FriendsList v-bind:users_list="users_list.users" v-bind:property.sync="property" v-bind:currentTab="currentTab"></FriendsList>
                      </div>
                      <div v-else>
                        <div>
                          <div v-if="users_list.friend_requests && users_list.friend_requests.total > 0 || (users_list.friend_requests.total == 0 && users_list.sent_requests.total == 0)">
                            <FriendsList @showMoreRequest="showMoreRequest('friend')" v-bind:users_list="users_list.friend_requests" v-bind:property="property" :submitDisabled.sync="submitDisabled" operation="friend" v-bind:currentTab="currentTab">
                                <p>{{__('Friend requests')}}</p>
                            </FriendsList>
                          </div>
                        </div>
                        <div>
                            <div v-if="users_list.sent_requests && users_list.sent_requests.total > 0">
                              <FriendsList @showMoreRequest="showMoreRequest('sent')" v-bind:users_list="users_list.sent_requests" v-bind:property="property" :submitDisabled.sync="submitDisabled" operation="sent" v-bind:currentTab="currentTab">
                                 <p>{{__('Sent requests')}}</p>
                              </FriendsList>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                        <div class="skeleton-frends">
                            <SkeletonListAvatarLine :count="5"></SkeletonListAvatarLine>
                        </div>
                    </div>
                  </div>
                  <div v-if="loading_page">
                      {{__('loading_page')}}
                  </div>
                </template>
              </Friends>
            </div>
          </div>
        </div>
<!--          <div v-on:click:outside="active = false" class="block-sorting" ref="block_sorting" :class="($route.name == 'peoples') ? 'block-sorting-people' : ''" v-if="$isEmpty(users_list)">-->
              <PeoplesSorting v-bind:filter.sync="filter"  v-bind:peoples_sorting.sync="peoples_sorting" :active.sync="active" v-bind:sorting_user.sync="sorting" v-bind:property.sync="property" @updatePeoples="updatePeoples" v-if="!update && peoples_sorting"/>
<!--          </div>-->
      </div>
    </div>
  </div>
</template>

<script>
    import SearchDefault from "../modules/SearchDefault";
    import Friends from "./Peoples/Friends";
    import PeoplesSorting from "./Peoples/PeoplesSorting";
    import FriendsList from "./Peoples/FriendsList";
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    import DropdownDefault from "../modules/DropdownDefault";
    import SkeletonListAvatarLine from "../modules/SkeletonListAvatarLine";

    export default {
        name: 'Peoples',
        data () {
          return {
              progress: 0,
              active: false,
              online: false,
              sorting: {
                  name: (this.$route.params.search) ? this.$route.params.search : null,
                  location: null,
                  age_from: null,
                  age_to: null,
                  gender: null,
              },
              side_tabs: {
                auth: [
                  {
                    'title': 'All friends',
                    'value': 'friends'
                  },
                  {
                    'title': 'Requests',
                    'value': 'requests'
                  },
                  {
                    'title': 'Advanced Search',
                    'value': 'peoples'
                  }

                ],
                user: [
                  {
                    'title': 'All players',
                    'value': 'friends'
                  },
                  {
                    'title': 'Mutual friends',
                    'value': 'mutual'
                  },
                  {
                    'title': 'Advanced Search',
                    'value': 'peoples'
                  }
                ],
              },
              users_list: null,
              loading_page: false,
              next_page_url: false,
              next_page_url_friend: false,
              next_page_url_sent: false,
              submitDisabled: false,
              user_info: null,
              count_friends_req_count: 0,
              update: false,
              search: null,
              show: false,
              peoples_sorting: false,
              filter: [],
              active_search: false,
          }
        },
        created: function () {
            this.$addListener(window,'scroll', () => {
                this.scrollPeoples();
            });
            this.online = this.$route.query.online || false;
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            if(to.name== 'friends' || to.name== 'peoples') {
                this.sorting= {
                    name: (to.params.search) ? to.params.search : null,
                    location: null,
                    age_from: null,
                    age_to: null,
                    gender: null,
                };
                this.users_list= null;
                this.property= null;
                this.loading_page= false;
                this.next_page_url= false;
                this.next_page_url_friend = false;
                this.next_page_url_sent = false;
                this.submitDisabled =  false,
                this.user_info= null;
                this.count_friends_req_count= 0;
                this.update= true;
            }
            return next();
        },
        watch: {
            friends_req(val) {
                let user= val.user;
                let users_list= this.$cleanObject(this.users_list);
                if(users_list && users_list.friend_requests) {

                     if(users_list.friend_requests.data.length) {
                         let index = this.$filterIndexObject(users_list.friend_requests.data, user.id);
                         if (index >= 0) {
                             users_list.friend_requests.data[index] = user;
                         } else {
                             users_list.friend_requests.total += 1;
                             users_list.friend_requests.data.unshift(user);
                         }
                     } else {
                         users_list.friend_requests.total += 1;
                         users_list.friend_requests.data.push(user);
                     }
                     this.users_list= users_list;
                 }
            },

            active() {
                if (this.active) {
                    this.$refs.block_sorting.classList.add('active');
                } else {
                    this.$refs.block_sorting.classList.remove('active');
                }
            },

            search(val) {
                if (val) {
                    this.show = true;
                } else {
                    this.show = false;
                }
            },
        },
        computed: {
            friends_req() {
                return this.$store.state.auth.friends_req;
            },
            friends_req_count() {
                return this.$store.state.auth.friends_req_count;
            },
            auth() {
                return this.$store.state.auth.user
            },
            currentTab: function () {
                return (this.$route.params.current) ? this.$route.params.current : 'friends'
            },
            currentUser: function () {
                return (this.$route.params.user_id) ? this.$route.params.user_id : null
            },
            operation: function () {
                return (this.$route.params.operation) ? this.$route.params.operation : null
            },
            property() {
                let props;
                let user_id= this.currentUser;

                switch (this.currentTab) {
                    case 'friends':
                        props= {
                            tab: 1,
                            user_public_id: user_id
                        };
                        break;
                    // case 'online':
                    //     props= {
                    //         tab: 1,
                    //         user_public_id: user_id
                    //     };
                    //     break;
                    case 'requests':
                        props= {
                            tab: 2,
                            operation: this.operation
                        };
                        break;
                    case 'peoples':
                        props= {
                            tab: null,
                            operation: this.operation
                        };
                        break;
                    case 'mutual':
                        props= {
                            tab: 3,
                            user_public_id: user_id
                        };
                        break;
                }

                return props;
            },
            lang() {
                return this.$store.getters.getLanguage;
            }
        },
        methods: {
            showMoreRequest(val) {
                let next_page_url = (val == 'friend') ? this.next_page_url_friend : this.next_page_url_sent;
                if(next_page_url) {
                    this.submitDisabled = true;
                    let response= Object.assign(this.property,  this.sorting);
                    response.operation = val;
                    this.$http.post((val == 'friend') ? this.next_page_url_friend : this.next_page_url_sent, response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                let users_list = this.$cleanObject(this.users_list);
                                let type;

                                if (response.data.friend_requests) {
                                    type = 'friend_requests';
                                } else if (response.data.sent_requests) {
                                    type = 'sent_requests';
                                }
                                users_list[type].data = users_list[type].data.concat(response.data[type].data);
                                (type == 'friend_requests') ? this.next_page_url_friend : this.next_page_url_sent = response.data[type].next_page_url;
                                this.users_list = users_list;
                                this.submitDisabled = false;
                            }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                        });
                }
            },

            sideChange(newTab) {
                if(newTab != this.currentTab) {
                    if(this.currentUser) {
                        this.$router.push({name: 'friends_user_current', params: {'user_id': this.currentUser,'current': newTab, lang: this.$store.getters.getLang}});
                    } else {
                        this.$router.push({name: 'friends_current', params: {'current': newTab, lang: this.$store.getters.getLang}});
                        // if(newTab== 'friends') {
                        //     this.$router.push({name: 'friends', params: {lang: this.$store.getters.getLang}});
                        // } else {
                        //     this.$router.push({name: 'friends_current', params: {'current': newTab, lang: this.$store.getters.getLang}});
                        // }
                    }
                    this.online = false;
                    this.search = null;
                    this.sorting.location = null;
                    this.sorting.gender = null;
                    this.sorting.age_from = null;
                    this.sorting.age_to = null;
                    this.filter = [];
                    this.$addListener(window,'scroll', () => {
                        this.scrollPeoples();
                    });
                }
            },
            scrollPeoples() {
                if(this.currentTab!= 'requests' || this.currentTab== 'requests' && this.operation) {
                    const progress = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
                    if(!this.loading_page && this.next_page_url) {
                        let bottomOfWindow = 0.7 <= progress;
                        if (bottomOfWindow) {
                            this.loading_page = true;
                            let response= Object.assign(this.property,  this.sorting);
                            this.$http.post(this.next_page_url, response)
                                .then((response) => {
                                    return response.json();
                                })
                                .then((response) => {
                                    if(this.$checkAuth(response)) {
                                        let users_list = this.$cleanObject(this.users_list);
                                        let type;

                                        if (response.data.users) {
                                            type = 'users';
                                        } else if (response.data.friend_requests) {
                                            type = 'friend_requests';
                                        } else if (response.data.sent_requests) {
                                            type = 'sent_requests';
                                        }
                                        users_list[type].data = users_list[type].data.concat(response.data[type].data);
                                        this.next_page_url = response.data[type].next_page_url;
                                        this.users_list = users_list;
                                        this.loading_page = false;
                                    }
                                })
                                .catch((err) => {
                                    this.httpHandler(err);
                                });
                        }
                    }
                }
            },
            updatePeoples: function (response) {
                if(response) {
                    this.users_list = response;
                    if (response.users) {
                        this.next_page_url = response.users.next_page_url;
                    }
                    if (response.friend_requests) {
                        this.next_page_url_friend = response.friend_requests.next_page_url;
                    }
                    if (response.sent_requests) {
                        this.next_page_url_sent = response.sent_requests.next_page_url;
                    }

                    if(response.user_info) {
                        this.user_info= response.user_info
                    }
                    // this.$store.dispatch("asyncFriendsReqCount", response.count_friends_req_count);

                    if (response.count_friends_req) {
                        this.$store.dispatch("asyncFriendsReqCount", response.count_friends_req);
                    }
                }
            },
            changeClass() {
              this.$refs.block_search.classList.toggle('active');
              this.active_search = !this.active_search;
            },

            onSubmit() {
                let response = Object.assign(this.property,  this.sorting);
                if (this.online) {
                    this.online = 1
                } else {
                    this.online = 0
                }
                response.online = this.online;
                response.search = this.search;
                this.users_list = null;
                this.$http.post("ajax/"+((this.currentTab == 'peoples') ? 'peoples' : 'friends'), response)
                    .then((response) => {
                        return response.json();

                    })
                    .then((response)=>{
                        if(this.$checkAuth(response)) {
                            //
                            // response = {
                            //     "data": {
                            //         "count_friends_req": 0,
                            //         "count_online": 1,
                            //         "count_total": 5,
                            //         "users": {
                            //             "current_page": 1,
                            //             "data": [
                            //                 {
                            //                     "id": 1,
                            //                     "public_id": "v.volkonsky",
                            //                     "name": "Vladyslav",
                            //                     "last_name": "Volkonsky",
                            //                     "photo": null,
                            //                     "bg_color": "#f84138",
                            //                     "location": "United Kingdom",
                            //                     "location_img": "gb.png",
                            //                     "online": "2021/01/27 13:13:10",
                            //                     "rating_points": "751"
                            //                 },
                            //                 {
                            //                     "id": 8,
                            //                     "public_id": "1799696562",
                            //                     "name": "Elena",
                            //                     "last_name": "Cherniak",
                            //                     "photo": "a-1605784297wHxVIO2Q7e.png",
                            //                     "bg_color": "#29a3a3",
                            //                     "location": "Ukraine",
                            //                     "location_img": "ua.png",
                            //                     "online": "2021/01/19 20:18:52",
                            //                     "rating_points": "44"
                            //                 }
                            //             ],
                            //             "first_page_url": "https://mindplays.com/ajax/friends?page=1",
                            //             "from": 1,
                            //             "last_page": 1,
                            //             "last_page_url": "https://mindplays.com/ajax/friends?page=1",
                            //             "next_page_url": null,
                            //             "path": "https://mindplays.com/ajax/friends",
                            //             "per_page": 24,
                            //             "prev_page_url": null,
                            //             "to": 4,
                            //             "total": 4
                            //         }
                            //     },
                            //     "status": 200
                            // }
                            //
                            this.updatePeoples(response.data);
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            },

            cancelSearch() {
                this.search = null;
                this.onSubmit();
                this.$refs.block_search.classList.toggle('active');
                this.active_search = false;
            }
        },
        components: {
          SkeletonListAvatarLine,
          FriendsList,
          Friends,
          PeoplesSorting,
          LayoutNavAuth,
          SearchDefault,
          DropdownDefault
        },

    }
</script>

<style lang="scss" scoped>

    .dark .block-peoples {
        .block-body {
            h1 {
                color: #fff;
            }
        }

        .block-peoples-head {
            background: #171827;

            .block-switch {
                p {
                    color: #fff;
                }
            }

            .wrap-btn {
                .v-btn.btn-search,
                .v-btn.btn-sorting {
                    background: #171827;
                    border: 1px solid #171827;
                    color: #96AFD1;

                    &:before {
                        /*background-color: #181818!important;*/
                        /*color: #26a9f4!important;*/
                    }

                    &:hover {
                        background-color: #0B0C1A!important;
                        /*color: #26a9f4!important;*/
                    }
                }

                .search {
                    border-left: 1px solid rgba(255, 255, 255, 0.1);
                    background: #171827;
                    &__icon {
                        svg {
                            color: #96AFD1;
                        }
                    }

                    .cancel-icon {
                        svg {
                            color: #96AFD1;
                        }
                    }
                }
            }
        }

        .block-peoples-body {
            background: #171827;

            p {
                color: #fff;
            }
        }

        .block-thead {
             background: #10111b;

            span {
                color: #fff;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .dark .block-peoples {
            .block-peoples-head {
                border-bottom: 2px solid #2B2B2B;
                .wrap-btn {
                    .search {
                        border-bottom: 1px solid #2B2B2B;
                    }
                }
            }
        }
    }

  .block-peoples {

    .block-body {
      width: 100%;
      border-radius: 8px;
        margin-top: 20px;

        h1 {
            font-size: 18px;
            line-height: 24px;
            color: #000000;

            span {
                color: #96AFD1;
            }
        }

        .wrap-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .block-right {
                display: flex;
                align-items: center;

                span {
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.01em;
                    color: #86A1C6;
                }

                .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
                    padding: 0 0 0 16px;
                    min-width: auto;
                }
            }
        }
    }

    .wrapper-people {
      display: flex;
      height: 100%;
    }

    .block-peoples-head {
      padding: 0 20px;
      height: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      background-color: #FFF;
      border-radius: 4px 4px 0 0;

        .block-switch {
            display: flex;
            align-items: center;

            p {
                font-size: 14px;
                line-height: 19px;
                color: #002B50;
                margin: 0 9px 0 0;
                white-space: nowrap;
            }
        }

        .wrap-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            .wrap-btn-search {
                display: none;
            }

            .search {
                display: flex;
                align-items: center;
                padding-left: 20px;
                margin-left: 20px;
                border-left: 1px solid #E7F5FF;

                &__icon {
                    svg  {
                        margin-right: 0;
                    }
                }

                .cancel-icon {
                    cursor: pointer;
                    width: 10px;

                    svg {
                        transition: .3s;
                        margin-right: 0;
                        color: #607EA7;
                    }

                    &:hover {
                        svg {
                            transform: scale(1.15);
                            transition: .3s;
                            color: #26A9F4;
                        }
                    }
                }
            }

            .v-btn.btn-search,
            .v-btn.btn-sorting {
                background: #fff;
                border-radius: 8px;
                transition: .3s;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 40px;
                    padding: 0;
                }

                &:hover {
                    background-color: #E7F5FF;
                    transition: .3s;
                }

                &.v-btn:before {
                    background-color: #E7F5FF;
                    transition: .3s;
                }

            }

            .v-btn.btn-search--active {
                background-color: #E7F5FF;
            }

            .wrap-btn-sorting {
                position: relative;
                .block-count {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background: #26A9F4;
                    border: 2px solid #FFFFFF;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    font-size: 12px;
                    line-height: 16px;
                    position: absolute;
                    top: -7px;
                    right: -7px;
                }
            }
        }

      &--people {
        padding: 0;
        height: 0;
        border: none;
      }

      .block-title {
        display: flex;
        position: relative;
        z-index: 11;

        img {
          margin-right: 25px;
        }
      }

      &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #002B50;
        margin-bottom: 2px;

        a {
          cursor: pointer;
          color: #26A9F4;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
      .block-information {
        display: flex;
        align-items: center;

        span {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #6886AF;
          margin-right: 10px;
          white-space: nowrap;
        }

      }
    }

    .block-thead {
        background-color: #ECF2FE;
        padding: 0 20px;
        display: flex;
        align-items: center;
        height: 36px;

        span {
            font-size: 13px;
            line-height: 18px;
            color: #6886AF;

            &:nth-child(1) {
                width: 27%;
            }

            &:nth-child(2) {
                width: 13%;
            }

            &:nth-child(3) {
                width: 17%;
            }

            &:nth-child(4) {
                width: 13%;
                white-space: nowrap;
            }
        }
    }

    .wrapper-body {
      display: flex;
      border-radius: 0 0 4px 4px;
    }

    .wrapper-body-people {
    }

    .block-peoples-body {
      min-height: calc(100vh - 310px);
      background-color: #fff;
      width: 100%;
      border-radius: 0 0 4px 4px;

      &::-webkit-scrollbar-track
      {
        margin-top: 5px;
      }

      &::-webkit-scrollbar
      {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb
      {
        background: rgba(150, 175, 209, 0.3);
        border-radius: 10px;
      }

      &--people {
      }

      .block-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 0 10px;
        height: 0;
        border-bottom: 1px solid #ECF4FF;
        transform: translateY(-45px);
        transition: .3s;
        opacity: 0;

        svg {
          cursor: pointer;
          color: #6886AF;
          transition: .3s;

          &:hover {
            color: #E94F4E;
            transition: .3s;
          }
        }
      }

      .block-search.active {
        transform: translateY(0);
        height: 50px;
        transition: .3s;
        opacity: 1;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #002B50;
        padding: 10px 0 8px 10px;
        margin: 0;
      }
    }
  }

  .block-sorting {
    flex-shrink: 0;
    width: 320px;
    background: #fff;
    border-radius: 8px;
    padding: 0;
    height: 100%;
    margin-right: 20px;
  }

  .block-sorting-people {
  }

  @media screen and (max-width: 950px){
      .block-peoples {
          .block-thead {
              span {
                  &:nth-child(1) {
                      width: 35%;
                  }

              }
          }
      }
  }

  @media screen and (max-width: 768px) {
      .block-peoples {
          .block-thead {
              padding: 0 10px;

              span {

                  &:nth-child(1) {
                      width: 37%;
                      min-width: 240px;
                  }

                  &:nth-child(2) {
                      display: none;
                  }

                  &:nth-child(3) {
                      display: none;
                  }

              }
          }
      }
  }

  @media screen and (max-width: 600px){
      .block-peoples {

          .block-body {
              .wrap-header {
                  flex-direction: column;
                  align-items: flex-start;
                  margin-bottom: 14px;

                  .block-right {
                      span {
                          font-size: 14px;
                          line-height: 20px;
                      }

                      .v-btn.btn-link {

                          svg {
                              height: 16px;
                          }

                      }

                      .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
                          padding-left: 14px;
                      }


                  }
              }
          }

          .block-thead {
              display: none;
          }
          .block-peoples-head {
              border-bottom: 1px solid #f0f6fe;

              .wrap-btn {
                  .search {
                      position: absolute;
                      left: 0;
                      bottom: -41px;
                      width: 100%;
                      background-color: #fff;
                      display: flex;
                      align-items: center;
                      padding: 0 20px 4px 10px;
                      height: 40px;
                      transform: translateY(-40px);
                      transition: .3s;
                      opacity: 0;
                      margin: 0;
                      border: none;
                      border-bottom: 1px solid #ECF4FF;
                      z-index: -1;

                      &__input {
                          width: 100%;
                      }
                  }

                  .search.active {
                      transform: translateY(0);
                      height: 40px;
                      transition: .3s;
                      opacity: 1;
                      z-index: 10;
                  }

                  .wrap-btn-search {
                      margin-left: 10px;
                      display: block;
                  }
              }
          }
          .block-peoples-body {
              min-height: calc(100vh - 290px);
          }
      }
  }

  @media screen and (max-width: 450px){
    .block-peoples {
      .block-peoples-head {
        padding-right: 10px;

      }

      .block-peoples-body {
        p {
          font-size: 12px;
          padding: 10px 0 0 10px;
        }
      }
    }
  }

</style>
