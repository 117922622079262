<template>
  <div :class="className">
    <span v-if="loadingPage">{{__('loading')}}</span>
    <slot v-bind="items"></slot>
  </div>
</template>

<script>
    export default {
        data () {
            return {
                games_history: null,
                loadingPage: false
            }
        },
        props: {
            items: {
                type: Object
            },
            scroll: {
                type: Boolean,
                default: true
            },
            next_page_url: {
                type: String
            },
            params: {
                type: Object,
                default: null
            },
            className: {
                type: String,
                default: 'wrap-item'
            },
            type: {
                type: String,
                default: 'get'
            },
            active: {
              type: Boolean,
            },

        },
        mounted() {

        },
        created: function () {
            this.$addListener(window,'scroll', ()=> {
                this.scrollPage();
              if (document.documentElement.scrollTop > 60) {
                this.$emit('update:active', true);
              } else {
                this.$emit('update:active', false);
              }
            }, false);
        },
        methods: {
            scrollPage: function(){

                if(this.scroll) {
                    let next_page_url= this.next_page_url;

                    if(!next_page_url) {
                        next_page_url= this.items.next_page_url;
                    }
                    const progress= document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
                    if(!this.loadingPage && next_page_url) {
                        let bottomOfWindow = (0.7 <= progress);
                        if (bottomOfWindow) {
                            this.loadingPage= true;
                            let params= this.params;

                            if(this.type== 'post') {
                                next_page_url= this.items.path;
                                params.page= this.items.current_page+1;
                            }

                            this.$http[this.type](next_page_url, params)
                                .then(response => {
                                    return response.json()
                                })
                                .then(response => {
                                    if(this.$checkAuth(response)) {
                                        response = response.data;
                                        if (response.data) {
                                            let items = this.items;
                                            response.data = items.data.concat(response.data);
                                            this.$emit('update:items', response);
                                        } else {
                                            this.$emit('scrollPagination', response);
                                        }
                                        this.loadingPage = false;
                                    }
                                })
                                .catch((err) => {
                                    this.httpHandler(err);
                                });
                        }
                    }
                }
            },
        },
        components: {

        }
    };
</script>