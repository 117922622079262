<template>
  <div class="block-reset">
    <div>
<!--      <img src="/dist/images/frontend/LogoHome.svg" class="logo img-responsive " alt="">-->
      <h1 class="text-darker text-center mb-2">{{__('Password reset')}}</h1>
      <p
         class="text-secondary " style="margin-bottom: 20px"
      >{{__('To reset your password, enter the email address you use to move to the MindPlays cabinet')}}</p>
    </div>
    <div>
      <div>
        <InputLabelTextDefault
          color="auth"
          :label="__('E-mail address')"
          v-model="email"
          v-bind:errors="formValidate.email"
          @change="updateValidate('email')"
        ></InputLabelTextDefault>
      </div>
      <div class="mt-4">
<!--        <v-btn v-if="submitDisabled" block default disabled>{{__('loading...')}}</v-btn>-->
<!--        <v-btn-->
<!--          v-else-->
<!--          class="btn-main btn-main-center btn-register white&#45;&#45;text"-->
<!--          v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--          block-->
<!--          text-->
<!--          v-on:click="onSubmit"-->
<!--        >-->
<!--          <img src="/dist/images/frontend/sign.svg" alt />-->
<!--          {{__('Reset Password')}}-->
<!--        </v-btn>-->
        <div >
          <BaseButton
                  class="new-btn"
            class_btn="btn-guest"
            v-on:click="openCaptcha"
            :loading="submitDisabled"
          >
          <span>
<!--            <img src="/dist/images/frontend/sign.svg" alt />-->
            {{__('Reset Password')}}
          </span>
          </BaseButton>
        </div>

        <a
          v-on:click="$store.commit('updateModalAuth', 'login')"
        >{{__('Log in to game area')}}</a>
      </div>
    </div>
  </div>
</template>


<script>
import InputLabelTextDefault from "./InputLabelTextDefault";
import { required, email } from "vuelidate/lib/validators";
import BaseButton from "./BaseButton";

export default {
  name: "ModalAuthFormForgot",
  data() {
    return {
      submitDisabled: false,
      email: null,
      formValidate: {
        email: null,
      },
      rules: this.validateMessage,
      dateUpdate: null,
    };
  },
  props: {
    modal: {
      type: Object,
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
      openCaptcha() {
          let valid= this.$v;
          if (valid.$invalid) {
              let massages = {
                  email: [],
              };
              this.formValidate = this.$formValidate(massages, valid);
          } else {
              this.$emit('update:captcha', this.onSubmit);
          }
      },
    updateValidate: function (type) {
      this.formValidate = this.$changeValidate(
        this.formValidate,
        this.$v[type],
        type
      );
    },
    onSubmit: function (session, arrayData) {
      let valid = this.$v;
      if (valid.$invalid) {
        let massages = {
          email: [],
        };
        this.formValidate = this.$formValidate(massages, valid);
      } else {
        this.submitDisabled = true;
        const response = {
          email: this.email,
          challenge: session,
        };
        response[session]= arrayData;

        this.$http
          .post("ajax/password/email", response)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                this.submitDisabled = false;
                this.$store.dispatch("asyncModalAuth", false);
                this.$notify({
                    group: "message",
                    title: "The letter was successfully sent to the mail",
                });
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            let response = this.httpHandler(err);
            if (response.error) {
              this.formValidate = response.error.message;
            }
          });
      }
    },
  },
  components: {
    InputLabelTextDefault,
    BaseButton
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: normal;
  src: url("~@/assets/fonts/Muli/Muliregular.woff") format("woff"),
    url("~@/assets/fonts/Muli/Muliregular.woff2") format("woff2");
}
.block-reset {
  &__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #002b50;
    margin-bottom: 20px;
  }

  &__desc {
    font-weight: 400;;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #96afd1;
    margin-bottom: 22px;
  }
  a {
    display: block;
    /*font-family: "Muli";*/
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #26A9F4!important;
    margin: 16px 0 70px;
    padding-bottom: 3px;

    &:hover {
      text-decoration: underline!important;

    }
  }
}

.btn-register {
  outline: none;
  min-height: 40px;
  background: #26a9f4;
  text-transform: inherit;
  border-radius: 8px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

  img {
    margin: 1px 8px 0 0;
  }

  &:hover {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
  }
}
</style>