<template>
    <div class="wrapper-modal-settings">
      <div class="personal-information">
        <div class="personal-information__head block-box">
          <div class="block-box__icon">
            <img src="/dist/images/frontend/name.svg" alt="">
          </div>
          <div class="personal-information__wrap-name">
            <div class="personal-information__head-name">
              <v-text-field
                      color="light-blue"
                      :label='__("First Name")'
                      hide-details="auto"
                      v-model="form.name"
                      @change="updateValidate('name')"
                      @input="formValidate.name = null"
              ></v-text-field>
              <div>
                <p v-for="(value, key) in formValidate.name" :key="key">{{value}}</p>
              </div>
            </div>
            <div class="personal-information__head-lastName">
              <v-text-field
                      color="light-blue"
                      :label='__("Last Name")'
                      hide-details="auto"
                      v-model="form.last_name"
                      v-bind:errors="formValidate.last_name"
                      @change="updateValidate('last_name')"
                      @input="formValidate.last_name = null"
              ></v-text-field>
              <div>
                <p v-for="(value, key) in formValidate.last_name" :key="key">{{value}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="personal-information__birthday block-box">
          <div class="block-box__icon">
            <img src="/dist/images/frontend/birthday-icon-setting.svg" alt />
          </div>
          <div class="block-birthday">
            <v-menu
                v-if="inputDate == null || inputDate == ''"
                v-model="date"
                :close-on-content-click="false"
                max-width="290"
            >
              <template v-slot:activator="{ on }">
                <span
                    class="calendar"
                    prepend-icon="mdi-calendar"
                    :value="computedDate"
                    clearable
                    readonly
                    v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
              </template>
              <v-date-picker
                  ref="picker"
                  color="blue lighten-1"
                  :min="minYear"
                  :max="maxYear"
                  v-model="form.birthday"
                  @change="changeCalendar"
              ></v-date-picker>
            </v-menu>

            <v-menu
                v-else
                v-model="date_menu"
                :close-on-content-click="false"
                max-width="290"
            >
              <template v-slot:activator="{ on }">
                  <span
                      class="calendar"
                      prepend-icon="mdi-calendar"
                      :value="computedDate"
                      clearable
                      readonly
                      v-on="on"
                  >
                      <img src="/dist/images/frontend/Calendar.svg" alt />
                  </span>
              </template>
              <v-date-picker
                  color="blue lighten-1"
                  :min="minYear"
                  :max="maxYear"
                  v-model="form.birthday"
                  @change="changeCalendar"
              ></v-date-picker>
            </v-menu>

            <v-text-field
                color="light-blue"
                :label='__("Birthday")'
                v-mask="'##.##.####'"
                v-model="inputDate"
                clearable
                @input="changeInput"
                @change="updateDate"
                ref="birthday"
            ></v-text-field>
            <!--                        placeholder="DD.MM.YYYY"-->
            <div class="error-text" v-if="errorDate && this.inputDate && !this.majority">{{__('No 18 years old')}}</div>
            <div class="error-text" v-else-if="errorDate && this.inputDate && this.majority">{{__('Date does not exist')}}</div>
          </div>
        </div>
        <div class="personal-information__gender block-box">
          <div class="block-box__icon">
            <img src="/dist/images/frontend/gender-icon-setting.svg" alt />
          </div>

          <p>{{__('Gender')}}</p>
          <div class="block-gender">
            <v-radio-group v-model="form.gender" :mandatory="true" row>
              <v-radio  class="block-gender__male" color="blue" value="1" :label="__('Male')"></v-radio>
              <v-radio  class="block-gender__female" color="blue" value="2" :label="__('Female')"></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div class="personal-information__location block-box">
          <div class="block-box__icon">
            <img src="/dist/images/frontend/location-icon-setting.svg" alt />
          </div>
          <div class="personal-information__wrap-location">
            <div class="location-country">
              <DropdownCountry
                      :label='__("Country")'
                      v-bind:value.sync="form.country"
                      @change="updateValidate('country')"
                      v-bind:errors="formValidate.country"
                      @updateCountry="updateCountry"
              ></DropdownCountry>
            </div>
            <div class="location-city">
              <DropdownCities
                      :label='__("City")'
                      v-bind:value.sync="form.city"
                      v-bind:country="form.country"
                      @change="updateValidate('city')"
                      v-bind:errors="formValidate.city"
              ></DropdownCities>
            </div>
          </div>
        </div>

        <div class="personal-information__phone block-box">
          <div class="block-box__icon block-img--phone">
            <img src="/dist/images/frontend/phone-icon.svg" alt />
          </div>
          <div class="block-phone">
            <VuePhoneNumberInput
                v-model="phone"
                v-bind:error="errorNumber"
                v-bind:default-country-code="form.phone_country"
                @update="updateNumber"
            ></VuePhoneNumberInput>
          </div>

        </div>
        <div class="personal-information__pageAddress block-box">
          <div class="block-box__icon block-img--address">
            <img src="/dist/images/frontend/address-icon.svg" alt />
          </div>
          <InputLabelTextDefault
              :label='__("Page Address")'
              class_input="settings_input-address"
              color="setting_input"
              v-model="form.page"
              v-bind:errors="formValidate.page"
              v-bind:placeholder="auth.id"
              @change="updateValidate('page')"
          >
            <template v-slot:before_value>
              <span style="position: relative; top: 28px;">
                https://{{$APP_DOMEN}}/
              </span>
            </template>
          </InputLabelTextDefault>
        </div>
        <div class="personal-information__social block-box">
          <div class="block-box__icon block-img--social">
            <img src="/dist/images/frontend/social-icon.svg" alt />
          </div>
          <SettingsSocial @add-social="addSocial" @remove-social="removeSocial" v-bind:socials.sync="socials"></SettingsSocial>
        </div>
      </div>

      <div class="footer-btn">
        <div v-if="statusSubmit">
          <BaseButton

              v-on:click="onSubmit"
              :loading="submitDisabled"
          >
            <span>{{__('Save changes')}}</span>
          </BaseButton>
        </div>
        <span class="setting-check" v-if="submitSuccess">
            <i class="fas fa-check"></i> {{__('Saved!')}}
        </span>
      </div>
    </div>
</template>

<script>
import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
import moment from "moment";
import {maxLength, minLength, required, numeric} from "vuelidate/lib/validators";
import {mask} from 'vue-the-mask';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import DropdownCountry from "../../modules/DropdownCountry";
import DropdownCities from "../../modules/DropdownCities";
import SettingsSocial from "./SettingsSocial";


    export default {
        name: 'SettingsBasic',
      data() {
        return {
          mystring: "crt/r2002_2",
          form: {
            name: this.setting.name,
            last_name: this.setting.last_name,
            country: (this.setting.location) ? this.setting.location : this.$countries[0].value,
            city: this.setting.city,
            page: this.setting.page,
            gender: this.setting.gender,
            birthday: this.setting.birthday,
            phone_number: this.setting.phone_number,
            phone_country: this.setting.phone_country,
            phone_code: this.setting.phone_code,
          },
          submitSuccess: false,
          submitDisabled: false,
          submit_Disabled: false,
          response_upload: null,
          date_menu: false,
          date: false,
          calling_code: null,
          formValidate: {
            name: null,
            last_name: null,
            country: null,
            city: null,
            page: null,
            gender: null,
            birthday: null,

          },
          validNumber: null,
          errorNumber: null,
          avatar: null,
          socials: this.$cleanObject(this.setting.social),
          phone: String(this.setting.phone_number),
          phone_number: null,
          inputDate: (this.setting.birthday) ? moment(this.setting.birthday).format('DD.MM.YYYY') : null,
          errorDate: false,
          majority: false,
          dialog: true

        };
      },
      props: {
        setting: {
          type: Object
        },
        profile: {
          type: Object
        },
        settings_personal_modal: {
          type: Boolean,
        },

      },
      computed: {
        statusSubmit: function () {
          let changed= false;
          if(this.form.name != this.setting.name ||
            this.form.last_name != this.setting.last_name ||
            this.form.country != (this.setting.location || this.$countries[0].value) ||
            this.form.city != this.setting.city ||
            this.form.page != this.setting.page ||
            this.form.gender != this.setting.gender ||
            this.form.birthday != this.setting.birthday ||
            this.inputDate != moment(this.setting.birthday).format('DD.MM.YYYY') ||
            (this.phone_number && this.phone_number.nationalNumber != String(this.setting.phone_number)) ||
            // this.numberPhone != this.setting.phone_number ||
            this.form.phone_country != this.setting.phone_country ||
            this.form.phone_code != this.setting.phone_code
          )
          {
            changed= true;
          }

          return changed;
        },
        auth() {
          return this.$store.state.auth.user;
        },
        computedDate() {
          return this.form.birthday ? moment(this.form.birthday).format('DD.MM.YYYY') : ''
        },

        minYear: function () {
          return (new Date().getFullYear() - 100).toString();

        },
        maxYear: function () {
          let a = new Date().getTime() - 567648000000;
          return moment(a).format('YYYY-MM-DD');

        },
        numberPhone: function () {
          return this.phone ? this.phone.replace(/[^\d;]/g, '') : this.phone
        }
      },
      validations: {
        form: {
          name: {
            required,
            maxLength: maxLength(50),
            minLength: minLength(2),
          },
          last_name: {
            // required,
            maxLength: maxLength(50),
            minLength: minLength(2),
          },
          country: {
            required,
          },
          city: {

          },
          page: {
            maxLength: maxLength(20),
            minLength: minLength(3),
          },
          gender: {
            numeric,
          },
          birthday: {

          },
        }
      },
      created() {
      },
      watch: {
        response_upload: function (payload) {
          let setting= this.setting,
              auth= this.auth;

          setting.photo= auth.photo= payload.logo;

          this.$emit('update:setting', setting);
          this.$store.commit('updateUserInfo', auth);
        },
        amount() {
          this.formValidate.amount = [];
        },
        date (val) {
          val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        dialog() {
          this.cancelModal();
        },
      },
      methods: {
        updateCountry(val) {
          this.form.country= val;
          this.form.city= null;
        },
        updateValidate (type) {
          this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
        },
        onSubmit() {
          let valid = this.$v.form;

          if (valid.$invalid) {
            let massages = {
              name: [],
              last_name:[],
              country: [],
              city: [],
              email: [],
              page: [],
              gender: [],
              birthday: [],
            };
            this.formValidate = this.$formValidate(massages, valid);
          } else if(this.phone_number.nationalNumber && !this.phone_number.isValid) {
            this.errorNumber= true;
          }
          else if (!this.validationDate() && this.inputDate) {
            this.errorDate= true;
          }
          else {
            this.submitDisabled = true;
            const response = this.$cleanObject(this.form);

            if(this.phone_number.nationalNumber) {
              response.phone_number= this.phone_number.nationalNumber;
              response.phone_country= this.phone_number.countryCode;
              response.phone_code= this.phone_number.countryCallingCode;
            }

            this.$http.post("ajax/setting/personal", response)
                .then((response) => {
                  return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        this.submitDisabled = false;

                        this.submitSuccess = true;
                        let auth = this.$cleanObject(this.auth);
                        // let setting= this.$cleanObject(this.setting);
                        let setting = this.setting;
                        setting.name = this.form.name;
                        setting.last_name = this.form.last_name;
                        setting.birthday = this.form.birthday;
                        setting.gender = this.form.gender;
                        // setting.country.location= response.country.location;
                        setting.location = this.form.country;
                        setting.city = this.form.city;
                        setting.phone_number = this.phone_number.nationalNumber;
                        setting.phone_country = this.phone_number.countryCode;
                        setting.phone_code = this.phone_number.countryCallingCode;
                        setting.page = this.form.page;

                        auth.name = this.form.name;
                        auth.last_name = this.form.last_name;
                        // auth.country.location= response.country.location;
                        // auth.city= response.city;
                        // auth.gender= response.gender;
                        auth.page = this.form.page;
                        auth.location = this.form.country;

                        this.$store.dispatch("asyncUpdateAuth", auth);
                        // this.$emit('change-setting', response, this.socials);
                        this.$emit('update:setting', setting);
                        this.dialog = false;
                    }
                })
                .catch((err) => {
                  this.submitDisabled = false;
                  let response = this.httpHandler(err);
                  if (response.error) {
                    this.formValidate = response.error.message;
                  }
                });
            setTimeout(()=> {
              this.submitSuccess= null;
            }, 2000);
          }
        },
        updateNumber: function (event) {
          this.errorNumber= false;
          this.phone_number= event;
        },
        deletePhoto() {
          this.submit_Disabled = true;
          this.$http.get("ajax/setting/remove_photo")
              .then((response) => {
                return response.json();
              })
              .then((response)=> {
                if(this.$checkAuth(response)) {
                    this.$store.dispatch('asyncDeletePhoto', null);
                    let setting = this.setting;
                    setting['photo'] = null;
                    this.$emit("update:setting", setting);
                    this.submit_Disabled = false;
                }
              })
              .catch((err) => {
                this.httpHandler(err);
                this.submit_Disabled = false;
              });
        },
        updateDate: function (value) {
          this.errorDate = false;
          if (this.validationDate()) {
            let dateParts = value.split(".");
            let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            this.form.birthday = moment(new Date(dateObject)).format('YYYY-MM-DD');
          } else if (this.inputDate == '') {
            this.form.birthday = moment(new Date()).format('YYYY-MM-DD');
            this.errorDate = false;
          } else {

            this.errorDate = true
          }
        },

        changeCalendar: function () {
          this.date_menu = false;
          this.date = false;
          this.inputDate = this.computedDate;
        },
        validationDate: function () {
          let date = this.inputDate;
          let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
          // pattern.test(date);
          if (date) {
            let year = (+date.split('.')[2])
            let minData = (new Date().getFullYear() - 100);
            let maxDate = (new Date().getFullYear() - 18);
            let check =  (year >= minData && year <= maxDate) ? true : false;

            if (check) {
              this.majority = true
            } else {
              this.majority = false
            }

            return (pattern.test(date)) && check;
          }
        },
        changeInput: function (value) {
          if (value) {
            this.errorDate = false;
          }
        },
        cancelModal() {
          this.$emit("update:settings_personal_modal", false);
        },
        addSocial(social) {
          let socials= this.socials;
          socials[social.name] = social.value;
          this.socials= socials;
          this.$emit('add-social', social);
        },
        removeSocial(social) {
          this.socials[social] = null;
          this.$emit('remove-social', social);
        }
      },
      components: {
        DropdownCountry,
        DropdownCities,
        InputLabelTextDefault,
        // DropdownDefault,
        // UploadAvatar,
        // SettingsSocialItem,
        VuePhoneNumberInput,
        // DatePicker
        // TheMask
        SettingsSocial
      },
      directives: {mask}
    };
</script>

<style lang="scss" scoped>
.footer-btn{
  text-align: center;
  height: 40px;
}

.setting-check{
  border-color: transparent;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  line-height: 40px;
  color: #00C443;
  height: 40px;
}
.setting-check i{
  font-size: 12px;
  margin: 0 6px 0 20px;
}

.location-country{
  margin-top: 10px;
}
.location-city{
  margin-top: 10px;
}
.block-box{
  margin-bottom: 30px;
}

.block-box__icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  min-width: 46px;
  height: 46px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-right: 21px;
}

.wrapper-modal-settings {
  border-radius: 0 0 8px 0;
  /*padding: 40px;*/
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px 8px 0 0;
    padding: 20px 0 20px 25px;
    border-bottom: 1px solid #F0F6FE;
    position: relative;

    h3 {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #002B50;
      margin-bottom: 2px;
    }

    /*p {*/
    /*    font-weight: 400;*/
    /*    font-size: 14px;*/
    /*    line-height: 19px;*/
    /*    color: #6886AF;*/
    /*    margin-bottom: 0;*/
    /*}*/

    /*.icon {*/
    /*    position: absolute;*/
    /*    top: 2px;*/
    /*    right: 2px;*/
    /*    outline: none;*/

    /*    img {*/
    /*        height: 13px;*/
    /*    }*/

    /*    &:hover {*/
    /*        img {*/
    /*            transform: scale(1.1);*/
    /*        }*/
    /*    }*/
    /*}*/
  }

}

.personal-information {


  &__head {
    display: flex;
    align-items: center;

    /*.v-btn {*/
    /*    margin-left: 14px;*/

    /*    img {*/
    /*        margin-right: 12px;*/
    /*    }*/
    /*}*/

    /*.btn-upload {*/
    /*    margin-left: 30px;*/
    /*}*/

    .wrap-img {
      margin-right: 20px;
      position: relative;

      .avatar {
        border-radius: 50%;
        width: 56px;
        height: 56px;
        cursor: pointer;
      }

      .photo {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 26px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
      }

      .change-photo {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          transition-duration: .3s;
        }
      }

      &:hover {
        .change-photo {
          img {
            transform: scale(1.1);
            transition-duration: .3s;
          }
        }
      }
    }
  }

  &__wrap-name {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__head-name {
    margin-top: 10px;
    margin-right: 21px;
    max-width: 230px;
    width: 100%;

    p {
      position: absolute;
      color: #FF4B55;
      font-size: 12px;
    }
  }

  &__head-lastName {
    margin-top: 10px;
    max-width: 230px;
    width: 100%;

    p {
      position: absolute;
      color: #FF4B55;
      font-size: 12px;
    }
  }

  .block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    margin-right: 20px;

    &--phone {

    }

    &--address {

    }

    &--social {

    }
  }

  &__birthday {
    display: flex;
    align-items: center;


    .block-birthday {
      max-width: 230px;
      width: 100%;
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;

      .calendar {
        color: #96AFD1;
        position: absolute;
        z-index: 100;
        top: 5px;
        right: 0px;
        cursor: pointer;

        &:hover {
          transform: scale(1.1);
          color: #26A9F4;
        }
      }
      .error-text {
        position: absolute;
        bottom: -18px;
        left: 0;
        font-size: 12px;
        color: #FF4B55;
      }
    }
  }

  &__gender {
    display: flex;
    align-items: center;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      margin: 0 30px 0 0;
    }

    .block-gender {
      display: flex;

      &__male {
        margin-right: 45px;
        display: flex;
        align-items: center;
      }

      &__female {
        display: flex;
        align-items: center;
      }

      &__input {
        width: 18px;
        height: 18px;
        border: 1.2px solid rgba(0, 43, 80, 0.3);
        box-sizing: border-box;
      }


      &__label {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #002B50;
        margin-left: 10px;
      }
    }
  }

  &__wrap-location {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__location {
    display: flex;
    align-items: center;

    .location-country {
      max-width: 230px;
      width: 100%;
      margin-right: 21px;
    }

    .location-city {
      max-width: 230px;
      width: 100%;
    }
  }

  &__phone {
    display: flex;
    align-items: center;

    .block-phone {
      margin-left: -14px;
      max-width: 280px;
      width: 100%;
      position: relative;

      img {
        position: absolute;
        top: 23px;
        left: 90px;
        z-index: 1;
      }
    }
  }

  &__pageAddress {
    display: flex;
    align-items: center;
  }

  &__social {
    display: flex;
    align-items: flex-start;
  }
}
.footer-btn {
  display: flex;
  justify-content: center;
  margin: 0 0px 0 0;


  .v-btn.save-btn {
    border-radius: 6px;
    min-width: 120px;


    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: 120px;
      padding: 0 10px;
      height: 40px;
      margin-left: 15px;
    }
  }
}


@media screen and (max-width: 650px) {
  .block-img--social {
    display: none;
  }

  .block-box__icon {
    width: 36px;
    min-width: 36px;
    height: 36px;
    margin-right: 12px;

    img {
      width: 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .personal-information {
    &__pageAddress {
      span {
        font-size: 12px;
        top: 31px!important;
      }
    }
  }
}


  @media screen and (max-width: 500px) {

    .personal-information {

      &__head {
        align-items: flex-start;
      }
      &__wrap-name {
        flex-direction: column;
        align-items: flex-start;
      }

      &__head-lastName {
        max-width: 100%;
      }

      &__head-name {
        margin-bottom: 15px;
        margin-right: 0;
        max-width: 100%;
      }

      &__location {
        align-items: flex-start;

        .location-country {
          margin-bottom: 15px;
        }
      }

      &__wrap-location {
        flex-direction: column;
        align-items: flex-start;
      }

      &__gender {
        p {
          margin-right: 20px;
        }
      }
    }
  }

@media screen and (max-width: 350px){
  .block-box__icon {
    display: none;
  }
}


  //dark
  .dark .block-box__icon {
    background: rgba(38, 169, 244, 0.1);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05) !important;
  }

  .dark .personal-information {
    &__gender {
      p {
        color: #96AFD1;
      }
    }
  }

</style>