export default {
    state: {
        chat_public: null,
        count_online: 0
    },
    getters: {
        getChatPublic(state){
            return state.chat_public;
        },

        getCountOnline(state){
            return state.count_online;
        },
    },
    actions: {
        asyncChatPublic(context, payload) {
            context.commit('updateChatPublic', payload);
        },

        asyncCountOnline(context, payload) {
            context.commit('updateCountOnline', payload);
        },
    },
    mutations: {
        updateChatPublic(state, data) {
            this.state.general_chat.chat_public= data;
        },
        updateCountOnline(state, data) {
            this.state.general_chat.count_online= data;
        },
    }
}
