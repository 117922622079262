<template>
  <div class="wrap-partners-item">
    <div v-if="open" class="line"></div>
    <div  class="line-long"></div>
<!--    <div v-if="item.count_referral > 0 && !open" class="submit" @click="getReferrals2lvl()">-->
<!--      <span>+</span>-->
<!--    </div>-->
<!--    <div v-else-if="item.count_referral > 0 && open" class="submit" @click="open = false">-->
<!--      <span>–</span>-->
<!--    </div>-->
    <v-card v-ripple="false">
      <div class="partners-item">
        <div class="partners-person-wrap">
          <v-list color="transparent" class="pa-0 partners-person">
            <v-list-item class="pa-0">
              <div class="block-img" :class="(item.user.pro) ? 'user-pro-img' : ''">
                <div class="arrow"></div>
                <router-link :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}">
                  <div class="wrap-img">
                    <img v-if="item.user.photo && typeof item.user.photo == 'object'" width="40" :src="item.user.photo[40]">
                    <img v-else-if="item.user.photo" width="40" :src="item.user.photo">
                    <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
                  </div>
                  <div v-if="now_date < date" class="online"></div>
                </router-link>
              </div>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :class="(item.user.pro) ? 'user-pro' : ''" :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}">
                    {{item.user.name +' '+((item.user.last_name) ? item.user.last_name : '')}}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <img class="ml-0 mr-1" height="12px" src="/dist/images/frontend/fire.svg" alt />
                  <span style="color: #F6A401;">{{item.user.rating_points}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="data">{{item.created_at}}</div>
        <div class="success--text block-profit">
          <div class="block-profit-wrap">
            <div class="block-profit-wrap__real">
              <img src="/dist/images/frontend/money.svg" alt />
              <span>{{primNum(item.amount_real, 4)}}</span>
            </div>

            <div class="block-profit-wrap__lite">
              <img src="/dist/images/frontend/light-coin.svg" alt />
              <span>{{Number(item.amount_lite).toFixed(4)}}</span>
            </div>
          </div>
        </div>
        <div class="country-wrap">
          <img v-if="item.user.location_img" width="14" class="flag" :src="item.user.location_img">
          <span v-if="item.user.location">{{$getLocation(item.user.location)}}</span>
        </div>
      </div>

      <v-expand-transition>
        <div class="wrap-referrals2lvl" v-show="open" @scroll="scrollReferrals2lvl" ref="referrals2lvl_list">
          <div v-if="referrals2lvl">
            <PartnersItem2lvl  v-for="(item, key) in referrals2lvl.data" :item="item" :key="key"></PartnersItem2lvl>
          </div>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
    import moment from "moment";
    import PartnersItem2lvl from  "./PartnersItem2lvl";

    export default {
        name: 'PartnersTableItem',
        data () {
            return {
              now_date: moment.utc(new Date()).format('X'),
              date: (this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
              open: false,
              referrals2lvl: null,
              next_page_url: null,
              loadingPage: false,
              response: null,
            }
        },
        props: {
            item: {
                type: Object
            },
            position: {
                type: Number
            }
        },
        computed: {

        },
      methods: {
          getReferrals2lvl() {
            this.open = true;

            const response = {
              ref_id: this.item.ref_id,
            };
            this.response = response;

            this.$http.post("ajax/affiliate/referrals", response)
                .then((response) => {
                  return response.json();
                })
                .then((response) => {
                  this.$checkAuth(response);
                  // response = {
                  //   "data": {
                  //     "referrals": {
                  //       "current_page": 1,
                  //       "data": [
                  //         {
                  //           "ref_id": 2051979895,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 0.12121221212121212,
                  //           "amount_lite": 15.12121212212,
                  //           "user": {
                  //             "id": 2051979895,
                  //             "name": "Dark",
                  //             "last_name": "Raven",
                  //             "public_id": "2051979895",
                  //             "photo": {
                  //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //             },
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 627820218,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 13,
                  //           "amount_lite": 15,
                  //           "user": {
                  //             "id": 627820218,
                  //             "name": "Vladyslav",
                  //             "last_name": "Volkonsky",
                  //             "public_id": "627820218",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 2051979895,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 0.12121221212121212,
                  //           "amount_lite": 15.12121212212,
                  //           "user": {
                  //             "id": 2051979895,
                  //             "name": "Dark",
                  //             "last_name": "Raven",
                  //             "public_id": "2051979895",
                  //             "photo": {
                  //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //             },
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 627820218,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 13,
                  //           "amount_lite": 15,
                  //           "user": {
                  //             "id": 627820218,
                  //             "name": "Vladyslav",
                  //             "last_name": "Volkonsky",
                  //             "public_id": "627820218",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         }
                  //       ],
                  //       "first_page_url": "https://mindplay.loc/ajax/affiliate/referrals?page=1",
                  //       "from": 1,
                  //       "last_page": 1,
                  //       "last_page_url": "https://mindplay.loc/ajax/affiliate/referrals?page=1",
                  //       "next_page_url": null,
                  //       "path": "https://mindplay.loc/ajax/affiliate/referrals",
                  //       "per_page": 3,
                  //       "prev_page_url": null,
                  //       "to": 3,
                  //       "total": 3
                  //     }
                  //   },
                  //   "status": 200
                  // }

                  this.referrals2lvl = response.data.referrals;
                  this.next_page_url = response.data.referrals.next_page_url;
                  this.loadingPage = false;
                })
                .catch((err) => {
                  this.httpHandler(err);
                });
          },
          scrollReferrals2lvl: function () {
            let referrals2lvl_list = this.$refs.referrals2lvl_list;
            const progress = referrals2lvl_list.scrollTop / (referrals2lvl_list.scrollHeight - referrals2lvl_list.clientHeight);
            if (!this.loadingPage && this.next_page_url) {
              let bottomOfWindow = 0.7 <= progress;
              if (bottomOfWindow) {
                this.loadingPage = true;
                this.$http.post(this.next_page_url)
                    .then((response) => {
                      return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            response = response.data;
                            let referrals2lvl = this.referrals2lvl.data;
                            this.referrals2lvl = referrals2lvl.concat(response.data);
                            this.next_page_url = response.next_page_url;
                            this.loadingPage = false;
                        }
                    })
                    .catch((err) => {
                      this.httpHandler(err);
                    });
              }
            }
          },
        },
        components: {
          PartnersItem2lvl,
          // ScrollPagination
        },

    }
</script>

<style lang="scss">

.dark .wrap-partners-item {
  .theme--light.v-card {
    background: #171827;
  }

  .line-long {
    border-left: 1px dashed #373737;
  }

  &:last-child {
    &:after {
      border-left: 1px solid #202020;
    }
  }
}

.dark .partners-item {
  border-bottom: 1px solid #2B2B2B;
}

.dark .partners-person {

  .v-list-item {
    &__title {
      a {
        color: #fff;
      }
    }
  }
  .block-img {
    .arrow {
      background-image: url("/dist/images/frontend/affiliate-arrow-dark.svg");
    }

    &:after {
      background: #202020;
    }

    &:before {
      border-bottom: 1px dashed #373737;
      border-left: 1px dashed #373737;
    }
  }
}

.dark .block-profit {
  .block-profit-wrap {
    &__lite {
      span {
        color: #96AFD1;
      }
    }
  }
}

.dark .data {
  color: #fff;
}

.dark .country-wrap {
  span {
    color: #96AFD1;
  }
}

.wrap-partners-item {
  position: relative;

  &:last-child {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 18px;
      bottom: 0;
      display: block;
      width: 2px;
      height: calc(100% - 32px);
      border-left: 1px solid #fff;
      z-index: 11;
    }

  }

  .line {
    position: absolute;
    top: 53px;
    left: 59px;
    width: 2px;
    height: 12px;
    z-index: 10;
    border-left: 1px dashed #DAE6F1;
  }
  .line-long {
    position: absolute;
    top: 32px;
    left: 18px;
    width: 2px;
    height: 32px;
    z-index: 10;
    border-left: 1px dashed #DAE6F1;
  }

  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 23px;
    left: 11px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    z-index: 12;
    background-color: #6886AF;
    transition-duration: .3s;

    &:hover {
      transition-duration: .3s;
      //transform: scale(1.1);
      span {
        color: #26a9f4;
        transition-duration: .3s;
      }
    }
    span {
      display: block;
      height: 16px;
      line-height: 14px;
      //margin-top: -1px;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .v-sheet.v-card {
    border-radius: 8px;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
    border: none;
  }

  .v-card--link:before {
    background: transparent;
  }

  .wrap-referrals2lvl {
    max-height: 195px;
    height: 100%;
    overflow-y: auto;
    position: relative;
  }
}

  .partners-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ECF4FF;
    margin-left: 40px;
  }


  .partners-person-wrap {
    width: 35%;
    //padding-left: 20px;
  }

  .partners-person {

    .block-img {
      position: relative;
      z-index: 10;
      width: 40px;
      height: 40px;
      margin: 12px 12px 12px 0;

      .arrow {
        position: absolute;
        top: 13px;
        left: -9px;
        width: 9px;
        height: 12px;
        background-image: url("/dist/images/frontend/affiliate-arrow.svg");
        background-position: center;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 20px;
        left: -22px;
        display: block;
        width: 20px;
        height: 66px;
        border-bottom: 1px dashed #DAE6F1;
        border-left: 1px dashed #DAE6F1;
        border-radius: 0 0 0 5px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -13px;
        display: block;
        width: 50px;
        height: 2px;
        background-color: #fff;
      }


      a {
        text-decoration: none;
      }

      .online {
        border-radius: 50%;
        width: 8px;
        height: 8px;
        position: absolute;
        right: 3px;
        bottom: 1px;
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        background-color: #00C569;
      }
    }

    .wrap-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }

    .v-list-item {

      &__title {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-transform: capitalize;
        color: #002B50;


        a {
          color: #002B50;
          text-decoration: none;


          &:hover {
            color: #26A9F4;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .data {
    width: 20%;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #002B50;
  }

  .block-profit {
    width: 20%;
    font-size: 14px;
    padding: 9px 0;
    margin: 0;

    .block-profit-wrap {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &__real {
        min-width: 35px;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #FDAE02;
        }
      }

      &__lite {
        margin-left: 0;
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
        }
      }

      img {
        height: 12px;
        margin-right: 6px;
        margin-top: -2px;
      }
    }


  }

  .result-wrap {
    padding-top: 14px;
  }

  .country-wrap {
    width: 25%;
    display: flex;
    align-items: center;
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: #002B50;
    }

    .flag {
      border-radius: 2px;
      margin: 1px 6px 0 0;
      height: 10px;
    }
  }

  @media screen and (max-width: 650px) {
    .data {
      font-size: 12px;
      line-height: 16px;
    }
    .country-wrap {
      span {
        font-size: 12px;
        line-height: 16px;
      }

      .flag {
        height: 9px;
        margin: 0 6px 0 0;
      }
    }
    .block-profit {
      .block-profit-wrap {
        &__real {
          span {
            font-size: 12px;
            line-height: 16px;
          }
        }
        &__lite {
          span {
            font-size: 12px;
            line-height: 16px;
          }
        }
        img {
          margin-right: 4px;
        }
      }
    }
    .partners-person {
      .block-img {
        width: 36px;
        height: 36px;
        margin: 12px 8px 12px 0;

        .arrow {
          position: absolute;
          top: 13px;
          left: -9px;
          width: 9px;
          height: 12px;
          background-image: url("/dist/images/frontend/affiliate-arrow.svg");
          background-position: center;
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 16px;
          left: -22px;
          display: block;
          width: 20px;
          height: 66px;
          border-bottom: 1px dashed #DAE6F1;
          border-left: 1px dashed #DAE6F1;
          border-radius: 0 0 0 5px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -13px;
          display: block;
          width: 40px;
          height: 1px;
          background-color: #fff;
        }
      }

      .wrap-img {
        width: 36px;
        height: 36px;

        img {
          width: 36px;
          height: 36px;
        }

        .photo {
          width: 36px;
          height: 36px;
          font-size: 16px;
        }
      }

      .v-list-item {
        &__title {
          font-size: 12px;
          line-height: 16px;
        }
        &__subtitle {
          img {
            height: 10px;
            margin-bottom: 1px;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 450px){
    .country-wrap {
      display: none;
    }
    .partners-person-wrap {
      width: 47%;
      padding-right: 5px;
    }
    .data {
      width: 28%;
    }
    .block-profit {
      width: 25%;
    }
  }

</style>