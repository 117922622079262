<template>
  <div class="main-search">
    <div :class="(color) ? 'input_'+color : ''" class="input-group d-flex">
      <slot name="left"></slot>
      <input
        :placeholder="placeholder"
        class=" input"
        :disabled="disabled"
        :class="class_search"
        :readonly="readonly"
        ref="input"
        v-bind:value="value"
        v-bind="$attrs"
        v-on="inputListeners"
        style="width: 100%"
      />
      <slot name="right"></slot>
    </div>
    <div>
      <p class="red--text mt-2" v-for="(value, key) in errors" :key="key">{{value}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDefault",
  data() {
    return {
      input_type: this.type,
    };
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    color: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
    },
    errors: {
      type: Array,
    },
    class_search: {
      type: String,
    },
  },
  mounted() {
      let  timer= null;
      let friends_search= document.getElementsByClassName(this.class_search)[0];
      if(friends_search) {
          this.$addListener(friends_search, 'keyup', ()=> {
              clearTimeout(timer);
              timer = setTimeout(()=> {
                  this.$emit('searching');
              }, 600);
          });
      }
  },
    computed: {
        inputListeners: function () {
            var vm = this;
            // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
            return Object.assign(
                {},
                // Мы добавляем все слушатели из родителя
                this.$listeners,
                // Затем мы можем добавить собственные слушатели или
                // перезаписать поведение некоторых существующих.
                {
                    // Это обеспечит, что будет работать v-model на компоненте
                    input: function (event) {
                        vm.$emit("input", event.target.value);
                    },
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>

  .dark {
    .input-group.input_main {
      background: transparent;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }
  }

.main-search {
  width: 100%;
  .input-group {
    border: none;
  }
}

.input-group.input_main {
  background-color: #fff;
  border-bottom: 1px solid #DAE6F1;
  border-radius: 0;
  padding: 0;

  .input {
    padding: 14px 0 0 0;
    font-size: 15px;

    &::placeholder {
      color: #96afd1;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
    }
  }



  .label {
    background: transparent;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #6886AF;
    position: absolute;
    pointer-events: none;
    top: 16px;
    left: 0;
    transition-duration: .3s;
  }

  &:focus-within {
    border-bottom: 1px solid #26A9F4;

    .label {
      background: transparent;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #26A9F4;
      position: absolute;
      top: 0;
      left: 0;
      transition-duration: .3s;
    }
  }

  .btn-password {
    outline: none;
    top: 14px;
    right: 0;

    i {
      color: #26A9F4;
    }

    &.v-btn:before {
      background-color: transparent;
    }
  }

  .label.label_top {
    background: transparent;
    font-weight: 400;
    font-size: 13px!important;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #6886AF;
    position: absolute;
    top: 0!important;
    left: 0;
    transition-duration: .3s;
  }

  .v-btn.btn-copy {
    top: 7px;
  }
}

.input {
  padding: 5px 0 0;
  height: 40px;
  min-width: 250px;
  border-radius: 8px 0 0 8px;
  background-color: transparent;
  font-size: 15px;
  color: #002b50;
  outline: none;
}

.search-btn {
  outline: none;
  background: transparent;
  border-radius: 0 8px 8px 0;
  // padding: 3px 10px 0;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 20px;
    padding: 0 10px;
  }

  img {
    width: 14px;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.v-btn:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  background-color: transparent;
}

.input-group {
  font-size: 15px;
  background: none;
}
.input-group .label {
  background: #d7dcf0;
  padding: 5px 0px 3px;
  max-width: 100px;
  width: 100%;
  text-align: center;
}
.input_success {
  color: green;
}
.input_primary {
  color: #26a9f4;
}
.input-group .input {
  padding: 5px 10px 3px;
  width: 100%;
  border: none;
}

.search-btn.search-user {
  border-radius: 50%;
}

.search-user {
  background-color: #fff;
  min-width: 80px;
  max-width: 100px;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 41px;
    padding: 0;
  }

  img {
    width: 16px;
    margin-bottom: 3px;
  }
}

  .input-group .friends_search {
    background-color: transparent;
    font-size: 14px;
    line-height: 19px;
    /*color: #94ADCF;*/
  }

  .search-btn.friends_search {
    display: none;
  }

  .input-group .operations_search {
    background: #FFFFFF;
    border-radius: 20px;
    font-size: 14px;
    border: 1px solid #ECF4FF;
    /*border-left: none;*/
    padding: 0 20px;

    &::placeholder {
      font-size: 12px;
      line-height: 16px;
      color: #94B6D7;
    }
  }

  .input-group .user_search_modal {
    padding: 0;
    font-size: 12px!important;

    &::placeholder {
      font-size: 12px!important;
      line-height: 16px;
      color: #96AFD1;
    }
  }

  .dark .input-group .games_search {
    color: #fff;
    border: none;
  }

  .input-group .games_search {
    background-color: transparent;
    padding: 0px 10px 0;

    &::placeholder {
      font-size: 12px!important;
      line-height: 16px;
      color: #96AFD1;
    }
  }

@media screen and (max-width: 1124px) {
  .input-group .operations_search {
    padding: 0 10px;
  }
}

  @media screen and(max-width: 600px){
    .input {
      min-width: 200px;
    }

    .input.search_management {
      &::placeholder {
        font-size: 12px!important;
        line-height: 16px;
      }
    }
  }
</style>