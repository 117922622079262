<template>
    <v-dialog v-model="dialog" max-width="440px">
        <div class="reviews-game">
            <div class="reviews-game__body">
                {{__('Your comment has been added to the moderation queue.')}}
                {{__('It will appear if the moderator approves of it.')}}

                <div class="wrap-btn">
                    <BaseButton
                        v-on:click="cancelModal()"
                    >
                        <span>{{__('Ok')}}</span>
                    </BaseButton>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
    export default {
        name:"ModalInfoReviews",
        data(){
            return {
                dialog: true,
            }
        },
        props: {
            info_reviews_modal: {
                type: Boolean,
            },
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:info_reviews_modal", false);
            },
        },
        components: {
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .reviews-game {
            &__body {
                background: #171827;
                color: #fff;
            }
        }
    }

    .reviews-game {

        &__body {
            padding: 20px 24px 20px;
            border-radius: 8px;
            background-color: #fff;
            text-align: center;

            .wrap-btn {
                margin: 20px auto 0;
                width: 30%;
            }
        }
    }
</style>