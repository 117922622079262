<template>
  <div v-if="dialog">
    <!--<v-row justify="center">-->
      <v-dialog
        v-model="dialog" persistent :max-width="width"
        v-on:click:outside="$emit('update:modal_cancel', false)"
      >
        <div class="white portfolio-window" :class="class_name" style="position: relative; border-radius: 5px">
          <span class="close-video" v-on:click="$emit('update:modal_cancel', false)"></span>
          <slot></slot>
        </div>
      </v-dialog>
    <!--</v-row>-->
  </div>
</template>

<script>
    export default {
        name: 'ModalVideo',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
            modal_cancel: {
                type: [Boolean, Object, Array, String]
            },
          class_name: {
              type: String,
          },
          width: {
            type: [Number, String],
            default: 400
          },
        },
        computed: {

        },
        watch: {

        },
        methods: {

        },
        components: {

        },
    };
</script>

<style scoped>
  .close-video{
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    z-index: 1;
    opacity: 0.7;
    cursor: pointer;
    right: -20px;
    background-size: 20px;
    background-image: url(/dist/images/frontend/close.svg);
    transition: 300ms;
  }
  .close-video:hover{
    opacity: 1;
    transition: 300ms;
  }
</style>

