<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {

            }
        },
        name: 'HomeApp',
        computed: {

        },
        components: {

        }
    }


</script>

<style scoped>

</style>