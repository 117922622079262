<template>
  <router-link :to="{name: 'blog', params: {'page': item.url, lang: $store.getters.getLang}}" class="block-news">
    <router-link :to="{name: 'blog', params: {'page': item.url, lang: $store.getters.getLang}}" class="block-img" :style="{'backgroundImage': 'url('+ item.img +')'}"></router-link>
    <div class="block-right">
      <router-link class="title-news" :to="{name: 'blog', params: {'page': item.url, lang: $store.getters.getLang}}">
        {{item.title}}
      </router-link>
<!--      <router-link :to="{name: 'blogs_category', params: {'type': 'category', 'name': item.category, lang: $store.getters.getLang}}" class="block-category">{{item.category}}</router-link>-->
      <span class="block-category">{{item.category}}</span>
    </div>
  </router-link>
</template>

<script>
import moment from "moment";

export default {
  name:'ItemLastNews',
  data () {
    return {
      date: (this.item.created_at) ? moment(this.item.created_at).format('ll') : null,
    }
  },
  props: {
    item: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark .block-news .block-right{
    background-color: #171827;
  }
  .dark .block-news .block-right .title-news{
    color: #ffffff;
  }
.block-news {
  margin-bottom: 10px;
  display: flex;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);

  &:hover {
    .block-right .title-news {
        text-decoration: underline;
        color: #0F9AFF;
    }
  }

  .block-img {
    display: block;
    width: 130px;
    height: 95px;
    border-radius: 4px 0 0 4px;
    background-size: cover;
    background-position: center;
    position: relative;
    flex-shrink: 0;
  }

  .block-right {
    width: 100%;
    background-color: #fff;
    height: 95px;
    border-radius: 0 4px 4px 0;
    padding: 8px 10px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;

    .title-news {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: #002B50;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      /*&:hover {*/
      /*  text-decoration: underline;*/
      /*  color: #0F9AFF;*/
      /*}*/
    }

    .block-category {
      position: absolute;
      right: 10px;
      bottom: 10px;
      height: 22px;
      width: 64px;
      background: #026EED;
      border-radius: 2px;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      color: #FFFFFF;
      cursor: default;

      &:after {
        content: '';
        position: absolute;
        left: -22px;
        top: 0;
        border: 11px solid transparent;
        border-right: 12px solid #026EED;
        border-radius: 2px;
      }

      a {
        color: #fff;
      }
    }
  }
}
</style>