<template>
  <div class="nav-wrap-top">
    <div class="nav-wrap" v-if="auth && this.$route.name !== 'frame'">
      <NavUserMobile v-bind:sidebar_nav="sidebar_nav"></NavUserMobile>
      <div
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          class="nav"
      >
        <router-link :to="{name: 'games',params: {lang: $store.getters.getLang}}" class="logo">
          <img src="/dist/images/frontend/LogoMP_D.svg" alt="mindplays" />
        </router-link>

        <div class="wrap-btn">
          <div class="item-btn" v-for="(item, key) in sidebar_nav" :key="key">
            <v-btn
              class="btn-link-nav"
              text
              v-ripple="false"
              :to="{name: item.url, params: {lang: $store.getters.getLang, id: ((item.url== 'home' && auth) ? auth.public_id : null)}}"
              :exact="((item.exact) ? item.exact : false)"
            >
              <div class="img-sidebar">
                <img class="icon-sidebar" :src="'/dist/images/frontend/'+item.icon" />
              </div>
              {{__(item.name)}}
              <div class="count-friends" v-if="friends_req_count && item.url== 'friends'">
                <p>{{friends_req_count}}</p>
              </div>
              <div class="count-friends" v-if="auth.tournament && item.url== 'tournaments'">
                  <p>{{auth.tournament}}</p>
              </div>
            </v-btn>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="right-block-nav">
<!--            <div class="block-notice" @click="pushMessenger()">-->
<!--&lt;!&ndash;                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;                    <path d="M17.6309 16.3482C15.5056 15.3884 15.4247 11.2684 15.4231 11.1645V8.40047C15.4231 5.74027 13.7976 3.45278 11.4876 2.4788C11.4826 1.11111 10.3687 0 9.00002 0C7.63129 0 6.5174 1.11118 6.5124 2.47873C4.20253 3.45272 2.57694 5.74021 2.57694 8.40041V11.1644C2.57544 11.2684 2.49452 15.3883 0.369061 16.3481C0.126937 16.4575 -0.00613562 16.7211 0.0498449 16.9808C0.10576 17.2406 0.335466 17.4261 0.601186 17.4261H5.98943C6.09989 18.0338 6.38721 18.5991 6.82665 19.0569C7.41031 19.665 8.18218 19.9999 8.99992 19.9999C9.81773 19.9999 10.5896 19.665 11.1732 19.0569C11.6127 18.599 11.9 18.0337 12.0104 17.4261H17.3987C17.6644 17.4261 17.8941 17.2406 17.95 16.9808C18.0061 16.7211 17.8731 16.4575 17.6309 16.3482ZM14.8312 14.3745C15.0546 15.0519 15.371 15.7322 15.8196 16.2982H2.18037C2.62893 15.7322 2.94531 15.052 3.16874 14.3745H14.8312ZM9.00002 1.12794C9.61914 1.12794 10.1426 1.54396 10.3064 2.11102C9.88443 2.0235 9.44751 1.97736 9.00002 1.97736C8.55254 1.97736 8.11561 2.02347 7.69365 2.11102C7.85747 1.54399 8.38094 1.12794 9.00002 1.12794ZM3.70488 11.1679V8.40038C3.70488 5.48066 6.08025 3.1053 9.00002 3.1053C11.9198 3.1053 14.2952 5.48066 14.2952 8.40038V11.1725C14.2959 11.2573 14.3091 12.1489 14.5338 13.2465H3.46609C3.69093 12.1479 3.7042 11.2547 3.70488 11.1679ZM9.00002 18.8721C8.1392 18.8721 7.38793 18.2514 7.14678 17.4261H10.8532C10.6121 18.2514 9.86091 18.8721 9.00002 18.8721Z" fill="white"/>&ndash;&gt;-->
<!--&lt;!&ndash;                    <path d="M9.00002 3.89661C6.57355 3.89661 4.59946 5.87063 4.59946 8.29708C4.59946 8.60855 4.85197 8.86107 5.16345 8.86107C5.47492 8.86107 5.72743 8.60855 5.72743 8.29708C5.72743 6.4926 7.19551 5.02455 9.00005 5.02455C9.31152 5.02455 9.56404 4.77203 9.56404 4.46056C9.56401 4.14906 9.31149 3.89661 9.00002 3.89661Z" fill="white"/>&ndash;&gt;-->
<!--&lt;!&ndash;                </svg>&ndash;&gt;-->

<!--                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path d="M10.6667 12.6667H20M10.6667 18H17.3333M20.9271 25.6424L25.4703 27.1568C26.5127 27.5043 27.5043 26.5125 27.1568 25.4703L25.6424 20.9271M20.4 25.4667C20.4 25.4667 18.7796 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5925 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5925 4 26.6667 9.07411 26.6667 15.3333C26.6667 18.6667 25.4667 20.4 25.4667 20.4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->

<!--                <div v-if="message_count > 0" class="block-notice__count">{{message_count}}</div>-->
<!--            </div>-->

          <LayoutNavBalanceNew></LayoutNavBalanceNew>
            <v-tooltip
                bottom
                color="transparent"
                nudge-top="5"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div
                        v-on="on"
                        v-bind="attrs"
                        class="block-connect"
                    >
                        <svg width="26" height="40" viewBox="0 0 26 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.63279 20.7098C9.19196 20.1101 9.86807 19.6314 10.6194 19.3031C11.3707 18.9749 12.1819 18.8044 13.0019 18.8015C13.8217 18.7986 14.6335 18.9638 15.3871 19.2868C16.1406 19.6098 16.8203 20.0837 17.3836 20.6794M6 17.0535C6.89468 16.0941 7.97644 15.3283 9.17857 14.803C10.3807 14.2778 11.6775 14.0046 12.9893 14.0001C14.3011 13.9955 15.5994 14.2599 16.8052 14.7766C18.0109 15.2935 19.0985 16.0518 20 17.005M13.0227 26.0036C12.3598 26.0036 11.8224 25.4662 11.8224 24.8032C11.8224 24.1403 12.3598 23.6029 13.0227 23.6029C13.6857 23.6029 14.2231 24.1403 14.2231 24.8032C14.2231 25.4662 13.6857 26.0036 13.0227 26.0036Z" :stroke="(connectionStable == 1) ? '#01DF72' : (connectionStable == 2) ? '#FDAE02' : '#FF4B55'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <circle cx="13" cy="25" r="1.5" :fill="(connectionStable == 1) ? '#01DF72' : (connectionStable == 2) ? '#FDAE02' : '#FF4B55'"/>
                        </svg>
                    </div>
                </template>
                <div class="block-tooltip-info ma-0">
                    <p>{{__('Internet status indicator')}}</p>

                    <p><span style="color: #01DF72">{{__('Green')}}</span> - {{__('stable')}}</p>
                    <p><span style="color: #FDAE02">{{__('Orange')}}</span> - {{__('not stable')}}</p>
                    <p><span style="color: #FF4B55">{{__('Red')}}</span> - {{__('absent')}}</p>
                </div>
            </v-tooltip>

          <div class="block-ready">
              <v-tooltip bottom v-if="submitDisabled && !ready_play">
                  <template v-slot:activator="{ on, attrs }">
                      <div
                          v-on="on"
                          v-bind="attrs"
                      >
                          <BaseButton
                              class_btn="btn-guest btn-nav"
                              width="100"
                              :disabled="submitDisabled"
                          >
                          <span>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="white"/>
                                <path d="M7.83875 6.31876C7.94098 6.26612 8.05575 6.2427 8.17043 6.25108C8.28511 6.25946 8.39526 6.29932 8.48875 6.36626L12.8638 9.49126C12.9448 9.54908 13.0108 9.62541 13.0564 9.7139C13.1019 9.80239 13.1257 9.90048 13.1257 10C13.1257 10.0995 13.1019 10.1976 13.0564 10.2861C13.0108 10.3746 12.9448 10.4509 12.8638 10.5088L8.48875 13.6338C8.39529 13.7007 8.2852 13.7405 8.17058 13.7489C8.05595 13.7572 7.94124 13.7339 7.83905 13.6813C7.73685 13.6287 7.65114 13.5489 7.59132 13.4508C7.53151 13.3527 7.49991 13.2399 7.5 13.125V6.87501C7.49988 6.76012 7.53143 6.64741 7.5912 6.54928C7.65096 6.45115 7.73661 6.37139 7.83875 6.31876Z" fill="white"/>
                              </svg>

                              <p>{{__('Start')}}</p>
                          </span>
                          </BaseButton>
                      </div>
                  </template>
                  <span>{{__('Updating every 5 seconds')}}</span>
              </v-tooltip>

              <BaseButton
                  v-else-if="!ready_play && !submitDisabled"
                  class_btn="btn-guest btn-nav"
                  width="100"
                  v-on:click="readyPlay"
              >
              <span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="white"/>
                    <path d="M7.83875 6.31876C7.94098 6.26612 8.05575 6.2427 8.17043 6.25108C8.28511 6.25946 8.39526 6.29932 8.48875 6.36626L12.8638 9.49126C12.9448 9.54908 13.0108 9.62541 13.0564 9.7139C13.1019 9.80239 13.1257 9.90048 13.1257 10C13.1257 10.0995 13.1019 10.1976 13.0564 10.2861C13.0108 10.3746 12.9448 10.4509 12.8638 10.5088L8.48875 13.6338C8.39529 13.7007 8.2852 13.7405 8.17058 13.7489C8.05595 13.7572 7.94124 13.7339 7.83905 13.6813C7.73685 13.6287 7.65114 13.5489 7.59132 13.4508C7.53151 13.3527 7.49991 13.2399 7.5 13.125V6.87501C7.49988 6.76012 7.53143 6.64741 7.5912 6.54928C7.65096 6.45115 7.73661 6.37139 7.83875 6.31876Z" fill="white"/>
                  </svg>

                  <p>{{__('Start')}}</p>
              </span>
              </BaseButton>

              <v-tooltip bottom v-else-if="ready_play && submitDisabled">
                  <template v-slot:activator="{ on, attrs }">
                      <div
                              v-on="on"
                              v-bind="attrs"
                      >
                          <BaseButton
                                  class_btn="dark-btn"
                                  width="100"
                                  :disabled="submitDisabled"
                          >
                          <span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.6361 5.90909H7.27246V14.0909H8.6361V5.90909Z" fill="#96AFD1"/>
                                    <path d="M12.7269 5.90909H11.3633V14.0909H12.7269V5.90909Z" fill="#96AFD1"/>
                                    <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="#96AFD1"/>
                                </svg>

                                <p>{{__('Pause')}}</p>
                            </span>
                          </BaseButton>
                      </div>
                  </template>
                  <span>{{__('Updating every 5 seconds')}}</span>
              </v-tooltip>

              <BaseButton
                  v-else-if="!submitDisabled && ready_play"
                  class_btn="dark-btn"
                  width="100"
                  v-on:click="readyPlay"
              >
                <span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.6361 5.90909H7.27246V14.0909H8.6361V5.90909Z" fill="#96AFD1"/>
                        <path d="M12.7269 5.90909H11.3633V14.0909H12.7269V5.90909Z" fill="#96AFD1"/>
                        <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="#96AFD1"/>
                    </svg>

                    <p>{{__('Pause')}}</p>
                </span>
              </BaseButton>
          </div>

            <div class="block-ready-mob">
                <v-tooltip bottom v-if="submitDisabled && !ready_play">
                    <template v-slot:activator="{ on, attrs }">
                        <div
                                v-on="on"
                                v-bind="attrs"
                        >
                            <BaseButton
                                    class_btn="btn-guest btn-nav"
                                    width="40"
                                    :disabled="submitDisabled"
                            >
                          <span>
                              <svg class="ma-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="white"/>
                                <path d="M7.83875 6.31876C7.94098 6.26612 8.05575 6.2427 8.17043 6.25108C8.28511 6.25946 8.39526 6.29932 8.48875 6.36626L12.8638 9.49126C12.9448 9.54908 13.0108 9.62541 13.0564 9.7139C13.1019 9.80239 13.1257 9.90048 13.1257 10C13.1257 10.0995 13.1019 10.1976 13.0564 10.2861C13.0108 10.3746 12.9448 10.4509 12.8638 10.5088L8.48875 13.6338C8.39529 13.7007 8.2852 13.7405 8.17058 13.7489C8.05595 13.7572 7.94124 13.7339 7.83905 13.6813C7.73685 13.6287 7.65114 13.5489 7.59132 13.4508C7.53151 13.3527 7.49991 13.2399 7.5 13.125V6.87501C7.49988 6.76012 7.53143 6.64741 7.5912 6.54928C7.65096 6.45115 7.73661 6.37139 7.83875 6.31876Z" fill="white"/>
                              </svg>
                          </span>
                            </BaseButton>
                        </div>
                    </template>
                    <span>{{__('Updating every 5 seconds')}}</span>
                </v-tooltip>

                <BaseButton
                        v-else-if="!ready_play && !submitDisabled"
                        class_btn="btn-guest btn-nav"
                        width="40"
                        v-on:click="readyPlay"
                >
              <span>
                  <svg class="ma-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="white"/>
                    <path d="M7.83875 6.31876C7.94098 6.26612 8.05575 6.2427 8.17043 6.25108C8.28511 6.25946 8.39526 6.29932 8.48875 6.36626L12.8638 9.49126C12.9448 9.54908 13.0108 9.62541 13.0564 9.7139C13.1019 9.80239 13.1257 9.90048 13.1257 10C13.1257 10.0995 13.1019 10.1976 13.0564 10.2861C13.0108 10.3746 12.9448 10.4509 12.8638 10.5088L8.48875 13.6338C8.39529 13.7007 8.2852 13.7405 8.17058 13.7489C8.05595 13.7572 7.94124 13.7339 7.83905 13.6813C7.73685 13.6287 7.65114 13.5489 7.59132 13.4508C7.53151 13.3527 7.49991 13.2399 7.5 13.125V6.87501C7.49988 6.76012 7.53143 6.64741 7.5912 6.54928C7.65096 6.45115 7.73661 6.37139 7.83875 6.31876Z" fill="white"/>
                  </svg>
              </span>
                </BaseButton>

                <v-tooltip bottom v-else-if="ready_play && submitDisabled">
                    <template v-slot:activator="{ on, attrs }">
                        <div
                                v-on="on"
                                v-bind="attrs"
                        >
                            <BaseButton
                                    class_btn="dark-btn"
                                    width="40"
                                    :disabled="submitDisabled"
                            >
                          <span>
                                <svg class="ma-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.6361 5.90909H7.27246V14.0909H8.6361V5.90909Z" fill="#96AFD1"/>
                                    <path d="M12.7269 5.90909H11.3633V14.0909H12.7269V5.90909Z" fill="#96AFD1"/>
                                    <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="#96AFD1"/>
                                </svg>
                            </span>
                            </BaseButton>
                        </div>
                    </template>
                    <span>{{__('Updating every 5 seconds')}}</span>
                </v-tooltip>

                <BaseButton
                        v-else-if="!submitDisabled && ready_play"
                        class_btn="dark-btn"
                        width="40"
                        v-on:click="readyPlay"
                >
                <span>
                    <svg class="ma-0" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.6361 5.90909H7.27246V14.0909H8.6361V5.90909Z" fill="#96AFD1"/>
                        <path d="M12.7269 5.90909H11.3633V14.0909H12.7269V5.90909Z" fill="#96AFD1"/>
                        <path d="M9.99956 1.8182C11.6178 1.8182 13.1996 2.29805 14.5451 3.19708C15.8906 4.09611 16.9393 5.37394 17.5586 6.86897C18.1778 8.364 18.3399 10.0091 18.0242 11.5962C17.7085 13.1833 16.9292 14.6412 15.785 15.7854C14.6407 16.9297 13.1829 17.7089 11.5958 18.0246C10.0086 18.3403 8.36355 18.1783 6.86851 17.559C5.37348 16.9398 4.09566 15.8911 3.19663 14.5456C2.2976 13.2001 1.81774 11.6182 1.81774 10C1.81774 7.83006 2.67975 5.74898 4.21414 4.21459C5.74853 2.68021 7.82961 1.8182 9.99956 1.8182ZM9.99956 0.454559C8.11165 0.454559 6.26613 1.01439 4.69639 2.06326C3.12665 3.11213 1.90318 4.60292 1.18071 6.34713C0.458237 8.09133 0.269206 10.0106 0.637519 11.8622C1.00583 13.7139 1.91495 15.4147 3.2499 16.7497C4.58486 18.0846 6.2857 18.9937 8.13733 19.3621C9.98897 19.7304 11.9082 19.5413 13.6524 18.8189C15.3967 18.0964 16.8874 16.8729 17.9363 15.3032C18.9852 13.7334 19.545 11.8879 19.545 10C19.545 7.4684 18.5393 5.04048 16.7492 3.25036C14.9591 1.46024 12.5312 0.454559 9.99956 0.454559Z" fill="#96AFD1"/>
                    </svg>
                </span>
                </BaseButton>
            </div>

<!--          <v-btn  text class="btn-link ml-4 btn-theme" v-ripple="false" @click="changeTheme">-->
<!--            <svg  v-if="theme == 'dark'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#F3F7FE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-moon"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>-->

<!--            <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M7.99911 13.3556C7.69758 13.3556 7.45312 13.6 7.45312 13.9015V15.4539C7.45312 15.7554 7.69758 15.9999 7.99911 15.9999C8.30072 15.9999 8.5451 15.7554 8.5451 15.4539V13.9015C8.5451 13.5999 8.30065 13.3556 7.99911 13.3556Z" fill="#F3F7FE"/>-->
<!--              <path d="M7.99911 2.64468C8.30072 2.64468 8.5451 2.40022 8.5451 2.09869V0.546199C8.5451 0.24467 8.30072 0.000213623 7.99911 0.000213623C7.69758 0.000213623 7.45312 0.24467 7.45312 0.546199V2.09869C7.45312 2.40022 7.69758 2.64468 7.99911 2.64468Z" fill="#F3F7FE"/>-->
<!--              <path d="M3.44098 11.7867L2.34289 12.8842C2.12959 13.0973 2.12952 13.4431 2.34267 13.6563C2.44932 13.7629 2.58909 13.8163 2.72886 13.8163C2.86856 13.8163 3.00819 13.763 3.11484 13.6565L4.21293 12.559C4.42622 12.3458 4.4263 12.0001 4.21314 11.7869C3.99985 11.5736 3.6542 11.5736 3.44098 11.7867Z" fill="#F3F7FE"/>-->
<!--              <path d="M12.1724 4.37274C12.3121 4.37274 12.4518 4.31945 12.5585 4.21288L13.6564 3.11538C13.8696 2.90215 13.8697 2.55651 13.6565 2.34321C13.4433 2.12991 13.0977 2.12984 12.8844 2.34313L11.7864 3.44064C11.5732 3.65379 11.5731 3.99951 11.7863 4.21273C11.8929 4.31945 12.0326 4.37274 12.1724 4.37274Z" fill="#F3F7FE"/>-->
<!--              <path d="M2.64432 8.00012C2.64432 7.69859 2.39986 7.45413 2.09833 7.45413H0.545986C0.244456 7.45413 0 7.69859 0 8.00012C0 8.30165 0.244456 8.5461 0.545986 8.5461H2.09833C2.39986 8.5461 2.64432 8.30165 2.64432 8.00012Z" fill="#F3F7FE"/>-->
<!--              <path d="M15.454 7.45413H13.901C13.5994 7.45413 13.355 7.69859 13.355 8.00012C13.355 8.30165 13.5994 8.5461 13.901 8.5461H15.454C15.7556 8.5461 16 8.30165 16 8.00012C16 7.69866 15.7556 7.45413 15.454 7.45413Z" fill="#F3F7FE"/>-->
<!--              <path d="M3.44102 4.21292C3.54759 4.31949 3.68737 4.37278 3.82707 4.37278C3.96677 4.37278 4.10654 4.31949 4.21312 4.21292C4.42634 3.99969 4.42634 3.65397 4.21312 3.44075L3.11561 2.34324C2.90238 2.13001 2.55667 2.13001 2.34351 2.34324C2.13029 2.55646 2.13029 2.90218 2.34351 3.11534L3.44102 4.21292Z" fill="#F3F7FE"/>-->
<!--              <path d="M12.5591 11.7868C12.3459 11.5736 12.0002 11.5735 11.7869 11.7867C11.5736 11.9999 11.5736 12.3457 11.7869 12.5588L12.8842 13.6563C12.9909 13.763 13.1306 13.8163 13.2703 13.8163C13.41 13.8163 13.5498 13.763 13.6564 13.6564C13.8697 13.4432 13.8697 13.0975 13.6565 12.8843L12.5591 11.7868Z" fill="#F3F7FE"/>-->
<!--              <path d="M7.99969 3.7504C5.65654 3.7504 3.75024 5.65683 3.75024 8.00013C3.75024 10.3434 5.65654 12.2497 7.99969 12.2497C10.3431 12.2497 12.2495 10.3434 12.2495 8.00013C12.2495 5.65683 10.343 3.7504 7.99969 3.7504ZM7.99969 11.1577C6.25865 11.1577 4.84222 9.74124 4.84222 8.00013C4.84222 6.25895 6.25865 4.84237 7.99969 4.84237C9.74095 4.84237 11.1575 6.25895 11.1575 8.00013C11.1575 9.74124 9.74095 11.1577 7.99969 11.1577Z" fill="#F3F7FE"/>-->
<!--            </svg>-->
<!--          </v-btn>-->


<!--          <div class="block-lang" style="margin-bottom: 0">-->
<!--            <DropdownLanguages/>-->
<!--          </div>-->

          <LayoutNavProfile></LayoutNavProfile>
        </div>

      </div>
    </div>


    <div v-else-if="!auth" class="nav-wrap-quest">
      <div  class="nav nav--guest nav-quest">
        <div class="content-nav-quest">
          <div class="d-flex align-center left-menu">
            <NavMobile @ListenScroll="ListenScroll"></NavMobile>
            <router-link :to="{name: 'home', params: {lang: $store.getters.getLang}}" class="logo">
              <img src="/dist/images/frontend/LogoMP.svg" alt="mindplays" />
            </router-link>
            <div  class="st-nav-section main-menu st-nav-primary nav-item">
              <v-btn
                     text
                     class="btn-link"
                     v-for="(item, key) in nav"
                     :key="key"
                     :to="{name: item.url, params: {lang: $store.getters.getLang}}"
                     :exact="((item.exact) ? item.exact : false)"
                     v-ripple="false"
                     @click="ListenScroll"
              >
                <!--              <img :src="'/dist/images/frontend/'+item.icon" />-->
                <div class="btn-content">
                  <span>{{__(item.name)}}</span>
                </div>
              </v-btn>
            </div>
          </div>
          <div class="main-dev">
            <BaseButton
                    v-on:click="$store.commit('updateModalAuth', 'login')"
                    class_btn="btn-video"
            >

                <span>
                    <img src="/dist/images/frontend/btn-sign-in.svg" alt="">
                    {{__('Sign In')}}
                </span>
            </BaseButton>
<!--            <v-btn class="border-btn-blue px-4 mr-5" text v-ripple="false" v-on:click="$store.commit('updateModalAuth', 'login')">{{__('Sign In')}}</v-btn>-->
            <BaseButton
                class_btn="btn-guest"
                v-on:click="$store.commit('updateModalAuth', 'register')"
            >
              <span>
                <img src="/dist/images/frontend/btn-sign-up.svg" alt="">
                {{__('Get Started')}}
              </span>
            </BaseButton>

            <div class="block-lang-guest">
              <DropdownLanguages @ListenScroll="ListenScroll"/>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-if="auth" class="block-animation">
    </div>
  </div>


</template>

<script>
import LayoutNavBalanceNew from "./LayoutNavBalanceNew";
import LayoutNavProfile from "./LayoutNavProfile";
import DropdownLanguages from "./DropdownLanguages";
import NavMobile from "./NavMobile";
import NavUserMobile from "./NavUserMobile";
import BaseButton from "@/components/modules/BaseButton";

export default {
  name: "LayoutNav",
  data: function () {
    return {
        modal_cancel: false,
      menu: false,
      show: false,
      search: null,
      count: 1,
      dark: false,
      nav: [
        {
          name: "Home",
          subtitle: "about us",
          icon: "icon-home.svg",
          url: "home",
          exact: true,
        },
        // {
        //   name: "Airdrop",
        //   subtitle: "",
        //   url: "airdrop",
        // },
        // {
        //   name: "About",
        //   icon: "icon-about.svg",
        //   url: "about",
        // },
        {
          name: "Games",
          subtitle: "only online",
          icon: "icon-about.svg",
          url: "games",
        },
        {
          name: "Championship",
          subtitle: "only online",
          icon: "nav-icon-tournaments-new.svg",
          url: "tournaments",
        },
        // {
        //   name: "Quests",
        //   subtitle: "only online",
        //   icon: "icon-about.svg",
        //   url: "quests",
        // },
        {
          name: "Affiliate",
          subtitle: "money flow",
          icon: "icon-affiliate.svg",
          url: "main_affiliate",
        },
        {
          name: "Blog",
          subtitle: "helpful",
          icon: "icon-blog.svg",
          url: "blogs",
        },
        // {
        //   name: "To Developers",
        //   subtitle: "api documentation",
        //   icon: "icon-developers.svg",
        //   url: "api",
        // },
        {
          name: "FAQ",
          subtitle: "ask to us",
          icon: "icon-contacts.svg",
          url: "faq",
        },
        {
          name: "Contacts",
          subtitle: "write to us",
          icon: "icon-contacts.svg",
          url: "contact",
        },
      ],
      ready_play: null,
      submitDisabled: false,
    };
  },
    props: {
        sidebar_nav: {
            type: Array,
        },
        scroll: {
          type: Boolean
        }
    },

    methods: {

    pushMessenger() {
        this.$messOpen(false);
        this.$router.push({name: 'chat', params:{lang: this.$store.getters.getLang}});
    },
    changeTheme() {
        let template = (this.theme == 'dark') ? 'white' : 'dark';
        this.$store.commit("updateTheme", template);
        localStorage.template = template;

    },
    ListenScroll() {
      this.$addListener(window,'scroll', () => {
        if (document.documentElement.scrollTop > 20) {
          this.$emit("update:scroll", true);
          // this.scroll = true;
        } else {
          this.$emit("update:scroll", false);
          // this.scroll = false;
        }
      });
    },
      readyPlay() {
          this.submitDisabled = true;
          if(this.ready_play != 2){
              // const ready = this.ready_play;
              // this.ready_play = 2;
              this.$http.get('ajax/user_info/ready_play')
                  .then(response=> {
                      return response.json()
                  })
                  .then((response)=> {
                      if(this.$checkAuth(response)) {
                          this.ready_play = response.data.ready_play;
                          this.$store.commit('updateReadyPlay', this.ready_play);
                      }
                  }).catch(err => {
                  this.httpHandler(err);
              });
          }

          setTimeout(()=> {
              this.submitDisabled= false;
          }, 5000);
      }
  },

  asyncComputed: {
    async_user() {
        let auth= this.auth;

        if(auth) {
            this.ready_play = auth.ready_play;
        }
        return true;
    }
  },

  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
    message_count() {
      return this.$store.getters.message_count;
    },
    friends_req_count() {
        return this.$store.state.auth.friends_req_count;
    },
    theme() {
      return this.$store.getters.getTheme;
    },
    connectionStable() {
      return this.$store.state.preference.connectionStable;
    },
  },
  components: {
    NavMobile,
    NavUserMobile,
    LayoutNavBalanceNew,
    LayoutNavProfile,
    BaseButton,
    DropdownLanguages,
  },
};
</script>


<style lang="scss" scoped>

    .block-tooltip-info {
        display: block;
        text-overflow: ellipsis;
        z-index: 1;
        font-size: 13px;
        line-height: 18px;
        color: #002B50;
        padding: 4px 8px;
        background: rgb(255, 255, 255);
        border: 1px solid #ECF4FF;
        border-radius: 6px;
        max-width: 300px;
        overflow: hidden;

        p {
            color: #6886AF;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            margin: 0;
        }
    }

  .block-animation {
      position: absolute;
      z-index: 100;
      top: 76px;
      left: 0;
      right: 0;
      height: 45px;
      pointer-events: none;
  }

  .nav-wrap {
    /*position: fixed;*/
    //margin-left: -16px;
    /*top: 0;*/
    /*right: 50%;*/
    /*padding-left: 217.5px;*/
    /*left:50%;*/
    /*<!--transform: translate(-50%);-->*/
    /*max-width: 1702px;*/
    width: 100%;
    /*z-index: 150;*/
    /*-webkit-backdrop-filter: blur(40px);*/
    /*-moz-backdrop-filter: blur(40px);*/
    /*-ms-backdrop-filter: blur(40px);*/
    /*-o-backdrop-filter: blur(40px);*/
    /*backdrop-filter: blur(40px);*/

    .logo {
      margin-right: 9px;
      /*img {*/
      /*  height: 38px;*/
      /*}*/
    }

    .logo-text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .logo-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
      }

      .logo-subtitle {
        font-size: 10.5px;
        line-height: 12px;
        color: #00AEFF;
        white-space: nowrap;
      }
    }

    .wrap-btn {
      margin-left: 28px;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
        &:after {
            content: '';
            display: block;
            width: 1px;
            height: 60px;
            position: absolute;
            left: -1px;
            top: 0;
            background:linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50.88%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .block-lang {
      margin: 0;
      /*  margin: 30px 10px 30px 25px!important;*/
    }
  }
  /*https://codepen.io/jonitrythall/pen/GJQBOp*/

  .nav-wrap-quest {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100%;
    z-index: 150;
    height: 90px;

    .v-btn.btn-link {

      .btn-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      span {
        font-size: 14px;
        display: block;
        color: #fff;
        letter-spacing: 0;
      }

      p {
        color: #96AFD1;
        font-size: 10px;
        margin: 0;
      }

      &:hover {

        span {
          color: #ffffff;
        }
        p {
          color: #00A5FF;
        }
      }

        &:after {
            content: '';
            display: block;
            opacity: 0;
            width: 16px;
            height: 11px;
            position: absolute;
            background-image: url(../../../public/images/frontend/top-menu-icon-new.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: -24px;
            left: calc(50% - 8px);
            transition: 200ms;
        }
    }

      .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
          padding: 0 14px;
      }

    .v-btn--active.btn-link{

      span {
        color: #ffffff;
      }
      p {
        color: #00A5FF!important;
      }
    }
  }
  .v-btn--active.btn-link:after{
      opacity: 1;
  }
  .nav-wrap-quest.active {
    background: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(40px);
    -moz-backdrop-filter: blur(40px);
    -ms-backdrop-filter: blur(40px);
    -o-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
  }



  .v-btn__content {
    flex-direction: column!important;
  }

.dashboard_menu {
  list-style: none;
}
.content-nav-quest {
  margin: 0 auto;
  /*max-width: 1440px;*/
  /*padding: 0 0 0 16px;*/
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.content-nav-quest .logo{
margin-right: 50px;
margin-top: -10px;
}


.nav {
  height: 60px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 0 0 20px;
  /*margin-left: 108px;*/
  /*-webkit-backdrop-filter: blur(40px);*/
  /*-moz-backdrop-filter: blur(40px);*/
  /*-ms-backdrop-filter: blur(40px);*/
  /*-o-backdrop-filter: blur(40px);*/
  /*backdrop-filter: blur(40px);*/
  /*box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);*/
  /*background: rgba(255,255,255,0.75);*/
  /*background-color: #182541;*/
  background-color: #283550;

  .count-friends {
    flex-shrink: 0;
    background: #026EED;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    margin-top: 1px;

      p {
          text-align: center;
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          margin: 0 1px 0 0;
          letter-spacing: normal;
      }
  }

  &--guest {
    background: none;
    padding: 0 20px 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .logo {
      margin-right: 50px;
      margin-top: 0.5px;
    }

    .block-lang-guest {
        background: #1F1F1F;
      border-radius: 8px;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      padding-right: 0px;
    }
  }

  .wrap-chat {
      position: relative;
      margin: 3px 0 0 0;
      padding: 0 25px 0 28px;
      //border-right: 2px solid #ECF4FF;
  }

  .v-btn.btn-icon {
    color: #94ADCF;
    transition-duration: .3s;



      svg {

      }

    &:hover {
      color: #4EBEFF;
        transition-duration: .3s;
    }
  }

  .right-block-nav {
    display: flex;
    align-items: center;
    /*background-color: #111D38;*/
    background-color: #212c46;
    padding: 0 20px 0 60px;
    height: 60px;

    .block-connect {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
    }

    .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
      padding: 0 10px 0 16px!important;
    }

      .block-ready {
          display: flex;
      }

      .block-ready-mob {
          display: none;
      }
  }
}

.dark .nav {
    background: #0B0C1A;

    .right-block-nav {
        background: #0B0C1A;
    }
}

.dark .nav-quest {
    background: #000000;
}


  .modal-info {
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    z-index: 1;
    font-size: 13px;
    line-height: 18px;
    color: #002B50;
    padding: 4px 8px;
    background: rgb(255, 255, 255);
    border: 1px solid #ECF4FF;
    border-radius: 6px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
  }

.v-btn.btn-nav {
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #002B50;
    margin: 0 2px 0 0;
    border: none;
    padding: 0 20px;
    /*background-color: #26a9f4;*/
    border-radius: 6px;
    box-shadow: none;
    text-transform: inherit;
    outline: none;
    display: flex;
    align-items: center;
    transition-duration: .3s;

    img {
      display: block;
      margin:0  10px 0 0;
      width: 14px;
      height: 14px;
      /*width: 100%;*/
      /*margin-right: 1px;*/
      filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg)
      brightness(15%) contrast(100%);
    }

    &:hover {
      box-shadow: none;
      background-color: #E7EFFF;
      transition-duration: .3s;
    }

  .v-btn__content {
    color: #002B50;
    transition-duration: .3s;
  }
}
.v-btn--active {
  background-color: #F0F6FE;
  transition-duration: .3s;
}
.v-btn:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity .2s cubic-bezier(.4,0,.6,1);
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
  background-color: #F0F6FE;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 40px;
  min-width: 100px;
  padding: 0 20px;
}


  .v-btn.btn-link-nav.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 14px;
    min-width: auto;
    height: 60px;
  }

  .dark .v-btn.btn-link-nav {
      background-position: center bottom;
      /*background-size: 100%;*/
      /*background-repeat: no-repeat;*/

      &:hover {
          background-color: transparent;
          background-image: url("/dist/images/frontend/bg-nav-item-1.png");
          background-position: center bottom;
          background-size: 100%;
          background-repeat: no-repeat;
      }
  }

  .v-btn.btn-link-nav {
      letter-spacing: 0;
      border-radius: 0;
      position: relative;
      margin-left: -1px;

      &:hover {
          /*background-color: #183762;*/
          color: #fff!important;
      }

      &:after {
          content: '';
          display: block;
          width: 1px;
          height: 60px;
          position: absolute;
          z-index: +1;
          right: 0;
          top: 0;
          background:linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50.88%, rgba(255, 255, 255, 0) 100%);
      }
  }

  .v-btn.btn-link-nav.v-btn--active {
      /*background: #183762;*/
  }

  .dark .v-btn.btn-link-nav.v-btn--active {
      background-color: transparent;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image: url("/dist/images/frontend/bg-nav-item-1.png");
      /*background-position: center;*/
      /*background-size: cover;*/
  }



.count {
  position: absolute;
  top: -9px;
  right: -8px;
  background: #4ebeff;
  border-radius: 6px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-weight: 400;
}

  .wrap-user {
    display: flex;

    .block-balance {
      display: flex;
      flex-direction: column;
      cursor: pointer;



      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #002B50;
      }

      &__money {
        display: flex;
        align-items: center;


        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 0;
          color: #6886AF;
        }

        img {
          width: 14px;
          margin-right: 4px;
        }
      }
    }

    .block-user {
      display: flex;
      cursor: pointer;

      &__name {
        display: flex;
        flex-direction: column;
        padding-left: 12px;
        border-left: 2px solid #ECF4FF;
        margin-left: 22px;

        h2 {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-align: right;
          letter-spacing: 0.01em;
          color: #002B50;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          letter-spacing: 0.01em;
          color: #96AFD1;
        }
      }

      &__img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-left: 10px;

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }
  }

  .block-notice {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    /*border: 1px solid #363636;*/
    background: #212232;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    flex-shrink: 0;
      transition: 200ms;

    &__count {
      position: absolute;
      top: -6px;
      right: -6px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      width: 16px;
      height: 16px;
      background-color: #01DF72;
      border-radius: 50%;

    }
  }
    .block-notice:hover{
        background-color: #2f303f;
        transition: 200ms;
    }
  .modal-profile {
    padding: 10px 10px 10px;
    width: 300px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.15);
    font-size: 14px;

    .block-verifycation {
      background: #F3F7FE;
      border-radius: 4px;
      padding: 10px;

      &__title {
        font-size: 14px;
        line-height: 19px;
        color: #002B50;
        margin-bottom: 4px;
      }

      &__subtitle {
        font-size: 13px;
        line-height: 18px;
        color: #607EA7;
        margin: 0;
      }
    }

    .block-empty {
      text-align: center;
      padding: 10px 0;
      color: #96AFD1;
    }
  }

  .v-list-item {
    margin: 16px 0 16px 15px;
    min-height: fit-content;

    &__content {
      padding: 0 0 0 30px;
      display: block;
    }
  }

    .v-menu__content {
    position: absolute;
    display: inline-block;
    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    will-change: transform;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
    margin-top: -18px;
    border-radius: 4px!important;
  }

  .v-menu__content--fixed {
    position: fixed;
    margin-right: 0;
    margin-top: -12px;
  }

  .v-list-item--link:before {
    background-color: #f0f6fe;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .v-list-item--active {
    background-color: transparent;
    color: #002B50!important;
  }
/*<!--@media screen and (max-width: 1920px) {-->*/
/*<!--  .nav-wrap {-->*/
/*<!--    max-width: 100%;-->*/
/*<!--    margin-left: -218px;-->*/
/*<!--    padding-left: 218px;-->*/
/*<!--  }-->*/
/*<!--}-->*/

/*<!--  @media screen and (max-width: 1440px) {-->*/
/*<!--    .wrapper-input {-->*/
/*<!--      margin-right: 40px;-->*/
/*<!--    }-->*/
/*<!--  }-->*/

/*<!--  @media screen and (max-width: 1220px) {-->*/

/*<!--    .v-btn.btn-nav {-->*/
/*<!--      padding: 0 15px;-->*/

/*<!--      img {-->*/
/*<!--        margin-right: 5px;-->*/
/*<!--      }-->*/
/*<!--      &.v-btn:not(.v-btn&#45;&#45;round).v-size&#45;&#45;default {-->*/
/*<!--        height: 40px;-->*/
/*<!--        min-width: 80px;-->*/
/*<!--        padding: 0 15px;-->*/
/*<!--      }-->*/
/*<!--    }-->*/
/*<!--  }-->*/

  @media screen and (max-width: 1400px) {
      .nav {

          .right-block-nav {
              padding: 0 20px 0 0;
          }
      }
  }

  @media screen and (max-width: 1300px) {
    .nav-wrap {
      .wrap-btn {
        display: none;
      }

      .logo {
        margin-left: 40px;
      }
    }
  }

  @media screen and (max-width: 750px) {
      .nav-wrap {
          .logo {
              img {
                  width: 90px;
              }
          }
      }
  }

  /*@media screen and (max-width: 1024px) {
    .nav-wrap {
      max-width: 100%;
      margin-left: -195px;
      padding-left: 195px;
    }


    .nav {
      //height: 60px;
      //padding: 0 11px 0 20px;
    }

    .wrapper-input {
      margin-right: 30px;
    }

    a.wrap-chat {
      padding-right: 20px;
      margin-right: 12px;
      img {
        height: 16px;
      }
      .count {
        height: 12px;
        width: 12px;
        border-radius: 3px;
        font-size: 10px;
        right: 11px;
        line-height: 12px;
        top: -5px;

      }
    }

    .search-img {
      height: 16px;
    }

    .search-img.active-img {
      height: 16px;
    }

    .wrap-input {
      height: 30px;
      line-height: 27px;
    }

    .wrap-input.active {
      height: 30px;

    }

    .input {
      font-size: 12px;
      height: 30px;
      top: 0;
    }

    .input.input.active {
      height: 30px;
      top: 0;
    }
  }*/

  /*@media screen and (max-width: 950px) {
    .nav-wrap {
      max-width: 100%;
      width: 100%;
      margin-left: -45px;
      padding-left: 0;
    }
    .nav {
      padding: 0 11px 0 45px;
      !*<!--margin-right: -20px;-->*!
    }

  }*/

  @media screen and (max-width: 700px) {

      .block-animation {
          display: none;
      }

    .nav-wrap {
      .logo {
        display: none;
      }

      .logo-text {
        display: none;
      }
    }

    .nav {
      padding: 0 0 0 60px;

      &--guest {
        padding: 0 20px 0 30px;
      }

      .right-block-nav {
        width: 100%;
        justify-content: flex-end;


        .block-lang {
          display: none;
        }
        .btn-theme {
            margin-right: -15px;
        }

          .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
              padding: 0 16px!important;
              margin-left: 0!important;
          }
      }
    }
    .wrap-input.active {
      position: fixed;
      top: 75px;
      left: 40px;
      right: 100px;
      border-radius: 0;
      background-color: #FFFFFF;
    }

    .wrap-user {
      .block-user {
        &__name {
          margin-left: 15px;

          h2 {
            font-size: 12px;
            line-height: 18px;
          }

          span {
            font-size: 10px;
            line-height: 12px;
          }
        }

        &__img {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-left: 5px;

          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }
      }

      .block-balance {

        span {
          font-size: 10px;
        }

        &__money {

          img {
            height: 12px;
          }

          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px){
    .nav-wrap {
      /*position: fixed;*/
      //margin-left: -16px;
      /*top: 0;*/
      /*right: 50%;*/
      /*padding-left: 217.5px;*/
      /*left:50%;*/
      /*<!--transform: translate(-50%);-->*/
      /*max-width: 1702px;*/
      width: 100%;
      /*z-index: 150;*/
      /*-webkit-backdrop-filter: blur(40px);*/
      /*-moz-backdrop-filter: blur(40px);*/
      /*-ms-backdrop-filter: blur(40px);*/
      /*-o-backdrop-filter: blur(40px);*/
      /*backdrop-filter: blur(40px);*/
    }
  }

  @media screen and (max-width: 599px) {
    .wrap-input.active {
      left: 0;
    }
    .nav-wrap {
      max-width: 100%;
      width: 100%;
      padding-left: 0;
    }
    .nav {

      /*<!--margin-right: -20px;-->*/
    }

  }

  @media screen and (max-width: 625px) {

    .nav {

      &--guest {

      }
    }
    /*.v-btn.btn-nav {*/
    /*  padding: 0 8px;*/

    /*  img {*/
    /*    margin-right: 0px;*/
    /*    height: 12px;*/
    /*  }*/

    /*  span {*/
    /*    font-size: 0;*/
    /*    transition-duration: .1s;*/
    /*  }*/

    /*  &:hover {*/

    /*    img {*/
    /*      margin-right: 3px;*/
    /*    }*/
    /*    span {*/
    /*      font-size: 10px;*/
    /*      margin-left: 0;*/
    /*    }*/
    /*  }*/
    /*  &.v-btn:not(.v-btn--round).v-size--default {*/
    /*    height: 35px;*/
    /*    min-width: 35px;*/
    /*    padding: 0 8px;*/
    /*  }*/
    /*}*/
  }

  @media screen and (max-width: 600px) {
    .nav {

      &--guest {
        .logo {
          display: none;
        }
      }

      .right-block-nav {
          .block-ready {
              display: none;
          }

          .block-ready-mob {
              display: flex;
          }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .wrap-user {
      .block-user {
        margin-top: 2px;

        &__name {
          margin-left: 10px;
          padding-left: 8px;

          h2 {
            font-size: 10px;
            line-height: 16px;
          }

          span {
            font-size: 8px;
            line-height: 12px;
          }
        }

        &__img {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          margin-left: 5px;

          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
          }
        }
      }

      .block-balance {

        span {
          font-size: 8px;
        }

        &__money {

          img {
            height: 10px;
          }

          p {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
    .v-btn.btn-nav {
      padding: 0 8px;

      img {
        margin-right: 0px;
        height: 10px;
      }

      span {
        font-size: 0;
        transition-duration: .1s;
      }

      &:hover {

        img {
          margin-right: 3px;
        }
        span {
          font-size: 8px;
          margin-left: 0;
        }
      }
      &.v-btn:not(.v-btn--round).v-size--default {
        height: 30px;
        min-width: 30px;
        padding: 0 8px;
      }
    }
  }

  @media screen and (max-width: 500px) {

      .block-notice {
          margin-right: 8px;
          svg {
              width: 28px;
              height: 28px;
          }
      }

    .nav {
      &--guest {

      }
    }
    .wrap-user {
      .block-balance {
        display: none;
      }

      .block-user {
        margin-top: 2px;

        &__name {
          margin-left: 5px;
          padding-left: 0;
          border: none;

          h2 {
            font-size: 9px;
            line-height: 16px;
          }

          span {
            font-size: 7px;
            line-height: 8px;
          }
        }

        &__img {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          margin-left: 3px;

          img {
            width: 26px;
            height: 26px;
            border-radius: 50%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 475px) {

    .nav-wrap {
      max-width: 100%;
      width: 100%;
      padding-left: 0;
    }
    .nav {
      /*<!--margin-right: -20px;-->*/
    }

    .search-img {
      height: 14px;
    }

    .search-img.active-img {
      height: 14px;
    }

    a.wrap-chat {
      padding-right: 13px;
      margin-right: 5px;
      img {
        height: 14px;
      }
      .count {
        height: 10px;
        width: 10px;
        border-radius: 2px;
        font-size: 8px;
        right: 6px;
        line-height: 10px;
        top: -3px;

      }
    }
  }

  @media screen and (max-width: 425px) {
    .nav {
        .baseButton.btn-guest {
            height: 40px!important;
            padding: 0 8px!important;
        }
      &--guest {
        .logo {
         //position: relative;
         // transform: translateX(-75px);
          display: none;
        }
      }

        .right-block-nav {
            padding: 0 10px;
        }
    }

    .nav .right-block-nav .block-connect {
        padding: 0 4px;
    }

    .v-btn.btn-nav {
      padding: 0 5px;
      margin-right: 2px;

      img {
        margin-right: 0px;
        height: 10px;
      }

      span {
        font-size: 0;
        transition-duration: .1s;
      }

      &:hover {

        img {
          margin-right: 1px;
        }
        span {
          font-size: 8px;
          margin-left: 0;
        }
      }

      &.v-btn:not(.v-btn--round).v-size--default {
        height: 25px;
        min-width: 25px;
        padding: 0 5px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    .nav {
      &--guest {
        .logo {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 350px) {
    .nav {

      .right-block-nav {
        padding: 0 12px 0 0;
      }
    }
  }
</style>
