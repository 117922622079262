<template>
    <div class="reg-auth page-auth">
        <div class="content-auth">
            <img src="/dist/images/frontend/LogoMP.svg" class="page-auth__logo" alt="mindpalys">

            <h1 class="page-auth__title">{{__('Login')}}</h1>

            <div class="block-social">
                <p class="block-social__title">{{__('Or sign in with')}}</p>

                <div class="list-social">
                    <a class="btn btn-rounded btn-outline-secondary brand-discord mr-2" :href="'https://discord.com/oauth2/authorize?client_id=960886664694235189&redirect_uri=https://mindplays.com/login/discord'+(($route.name== 'game') ? '/'+$route.params.id : '')+'&response_type=code&scope=identify%20email'">
                        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1774_7075)">
                                <path d="M16.9419 3.79664C15.6303 3.18295 14.2427 2.74689 12.8158 2.5C12.638 2.82133 12.4304 3.25352 12.2872 3.59734C10.7487 3.36602 9.22445 3.36602 7.71429 3.59734C7.57117 3.25359 7.35883 2.82133 7.17945 2.5C5.75116 2.747 4.36239 3.18417 3.05015 3.79984C0.438901 7.74586 -0.26899 11.5938 0.0849165 15.3872C1.81687 16.6805 3.49531 17.4663 5.14547 17.9804C5.55557 17.4164 5.91809 16.8193 6.22929 16.1953C5.63682 15.9698 5.06564 15.6918 4.52257 15.3647C4.66549 15.2588 4.80504 15.1484 4.94101 15.0337C8.2318 16.5729 11.8074 16.5729 15.059 15.0337C15.1956 15.1476 15.3351 15.258 15.4773 15.3647C14.9334 15.6926 14.3612 15.9712 13.7675 16.197C14.0805 16.8235 14.4423 17.4212 14.8513 17.982C16.503 17.4679 18.1831 16.6822 19.915 15.3872C20.3303 10.9897 19.2056 7.17703 16.9419 3.79656V3.79664ZM6.67765 13.0543C5.68976 13.0543 4.87961 12.132 4.87961 11.009C4.87961 9.88594 5.6725 8.96211 6.67765 8.96211C7.68289 8.96211 8.49297 9.8843 8.4757 11.009C8.47726 12.132 7.68289 13.0543 6.67765 13.0543ZM13.3223 13.0543C12.3344 13.0543 11.5243 12.132 11.5243 11.009C11.5243 9.88594 12.3171 8.96211 13.3223 8.96211C14.3275 8.96211 15.1376 9.8843 15.1203 11.009C15.1203 12.132 14.3275 13.0543 13.3223 13.0543Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1774_7075">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>

                    <a class="btn btn-rounded btn-outline-secondary brand-facebook mr-2"  :href="'/login/facebook'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                        <i class="fab fa-facebook-f"></i>
                    </a>

                    <a class="btn btn-rounded btn-outline-secondary brand-google mr-2"  :href="'/login/google'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                        <i class="fab fa-google"></i>
                    </a>

                    <a class="btn btn-rounded btn-outline-secondary brand-twitter "  :href="'/login/vkontakte'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                        <i class="fab fa-vk"></i>
                    </a>
                </div>
            </div>

            <div class="block-input">
                <InputLabelTextDefault
                        color="auth"
                        :label="__('E-mail address')"
                        v-model="email"
                        v-bind:errors="formValidate.email"
                        type="email"
                        name="email"
                        @change="updateValidate('email')"
                ></InputLabelTextDefault>
            </div>

            <div class="block-input">
                <InputLabelTextDefault
                        color="auth"
                        :label="__('Password')"
                        switchType
                        type="password"
                        v-model="password"
                        v-bind:errors="formValidate.password"
                        @change="updateValidate('password')"
                ></InputLabelTextDefault>
            </div>

            <div class="block-control">
                <router-link
                    class="link"
                    :to="{name: 'forgot', params: {lang: $store.getters.getLang}}"
                >
                    {{__('Forgot Password?')}}
                </router-link>

                <BaseButton
                    width="180"
                    class_btn="btn-guest"
                    block
                    text
                    v-on:click="openCaptcha"
                    :loading="submitDisabled"
                >
                    <span>
                        <img src="/dist/images/frontend/sign.svg" alt />
                        {{__('Sign In')}}
                    </span>
                </BaseButton>
            </div>

            <div class="block-bottom">
                <p class="block-bottom__text">{{__('Dont have an account yet?')}}</p>

                <router-link
                        class="block-bottom__link"
                        :to="{name: 'register', params: {lang: $store.getters.getLang}}"
                >
                    {{__('Create it here')}}
                </router-link>
            </div>

        </div>
        <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
</template>


<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import BaseButton from "../../modules/BaseButton";
    import Captcha from "../../modules/Captcha";

    import {
        required,
        email,
        maxLength,
        minLength,
    } from "vuelidate/lib/validators";

    export default {
        name: "AuthLogin",
        data() {
            return {
                captcha: false,
                email: null,
                password: null,
                submitDisabled: false,
                formValidate: {
                    email: null,
                    password: null,
                },
                rules: this.validateMessage,
            };
        },
        props: {

        },
        validations: {
            email: {
                required,
                email,
            },
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(6),
            },
        },
        methods: {
            openCaptcha() {
                let valid= this.$v;
                if (valid.$invalid) {
                    let massages = {
                        email: [],
                        password: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.captcha= true;
                }
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },
            onSubmit: function (session, arrayData) {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        email: [],
                        password: [],
                    };

                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    const response = {
                        email: this.email,
                        password: this.password,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http
                        .post("ajax/login", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                if (response.data.security) {
                                    this.$store.dispatch('asyncAuth', response.data);

                                    this.$router.push({
                                        name: 'security',
                                        lang:  this.$store.getters.getLang,
                                    });
                                } else {
                                    this.$router.push({
                                        name: "games",
                                        lang:  this.$store.getters.getLang,
                                    });
                                    if (response.data.gtag_login) {
                                        if (this.$gtag) {
                                            this.$gtag.event('login', { method: response.data.gtag_login });
                                        }
                                        if (this.$metrika) {
                                            this.$metrika.reachGoal('LoginDone');
                                        }
                                    }
                                    location.reload();
                                }
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            this.captcha= false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            BaseButton,
            Captcha
        },
    };
</script>

<style lang="scss" scoped>

</style>