<template>
    <div class="page-product">
        <LayoutNavAuth>
            <!--<h4>Quests</h4>-->
            <div class="wrap-tabs pa-0">
                <tabs
                        :tabs="side_tabs"
                        :currentTab="currentTab"
                        :wrapper-class="'default-tabs'"
                        :tab-class="'default-tabs__item'"
                        :tab-active-class="'default-tabs__item_active'"
                        :line-class="'default-tabs__active-line'"
                        @onClick="sideChange"
                />
            </div>
            <div class="wrap-select">
                <DropdownDefault
                        class_modal="modal_game"
                        color="select_game"
                        v-bind:dropdown="side_tabs"
                        v-bind:value.sync="side_tabs.value"
                        @updateValue="sideChange"
                />
            </div>

            <div class="loading"  :style="{width: progress + '%'}"></div>
        </LayoutNavAuth>
        <div class="dashboard-content">
            <div class="page-product__head">
                <v-btn
                        text
                        v-ripple="false"
                        class="btn-back"
                >
                    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.90929 7.81789C4.03006 7.69737 4.03025 7.5018 3.90974 7.38101L0.707886 4.1725C0.587442 4.05204 0.587457 3.85608 0.708118 3.73541L3.90974 0.527127C4.03026 0.406358 4.03006 0.210786 3.90929 0.0902487C3.78852 -0.0302577 3.59294 -0.0300569 3.47243 0.0906966L0.271024 3.29876C0.0903256 3.47949 0 3.71681 0 3.95416C0 4.19137 0.0902946 4.42863 0.270807 4.60913L3.47243 7.81744C3.59294 7.93819 3.78852 7.9384 3.90929 7.81789Z" fill="currentColor"/>
                    </svg>
                </v-btn>
                <span>{{__('Sunglasses Polarized Mindplays')}}</span>
            </div>

            <div class="page-product__body">
                <div class="product-information">
                    <div class="product-information__head">
                        <div class="block-img-product">
                            <div class="block-img">
                                <div class="slider-main">
                                    <VueSlickCarousel
                                            ref="c1"
                                            :asNavFor="$refs.c2"
                                            :focusOnSelect="true"
                                            :arrows="false"
                                    >
                                        <div class="slide-item">
                                            <img src="/dist/images/frontend/store/img_product-1.png" alt="">
                                        </div>
                                        <div class="slide-item">
                                            <img src="/dist/images/frontend/store/store-top-1.png" alt="">
                                        </div>
                                        <div class="slide-item">
                                            <img src="/dist/images/frontend/store/store-top-2.png" alt="">
                                        </div>
                                        <div class="slide-item">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>
                                        <div class="slide-item">
                                            <img src="/dist/images/frontend/store/store-top-1.png" alt="">
                                        </div>
                                        <div class="slide-item">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>

                                    </VueSlickCarousel>
                                </div>

                                <div class="block-colors">
                                    <span class="block-colors__item black-color"></span>
                                    <span class="block-colors__item white-color"></span>
                                    <span class="block-colors__item blue-color"></span>
                                </div>
                            </div>
                            <div class="block-slider">
                                <div class="slider-vertical">
                                    <VueSlickCarousel
                                            ref="c2"
                                            :asNavFor="$refs.c1"
                                            :slidesToShow="5"
                                            :slidesToScroll="1"
                                            :focusOnSelect="true"
                                            :centerMode="true"
                                            :centerPadding="'2px'"
                                    >
                                        <div class="slider-vertical-item">
                                            <img src="/dist/images/frontend/store/img_product-1.png" alt="">
                                        </div>
                                        <div class="slider-vertical-item">
                                            <img src="/dist/images/frontend/store/store-top-1.png" alt="">
                                        </div>
                                        <div class="slider-vertical-item">
                                            <img src="/dist/images/frontend/store/store-top-2.png" alt="">
                                        </div>
                                        <div class="slider-vertical-item">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>
                                        <div class="slider-vertical-item">
                                            <img src="/dist/images/frontend/store/store-top-1.png" alt="">
                                        </div>
                                        <div class="slider-vertical-item">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>
                                    </VueSlickCarousel>
                                </div>

                            </div>
                        </div>

                        <div class="block-info">
                            <span class="block-status">{{__('In stock')}}</span>
                            <h1 class="block-info__title">{{__('Sunglasses Polarized Mindplays')}}</h1>
                            <span class="block-info__subtitle">{{__('Polarized black sunglasses for men with MINDPLAYS ESPORTS brand logo print')}}</span>
                            <div class="block-rate">
                                <div class="rating">
                                    <v-rating
                                            half-increments
                                            readonly
                                            v-model="rating"
                                            background-color="white darken-2"
                                            color="#C4C4C4"
                                            dense
                                            hover
                                            size="24"
                                    ></v-rating>
                                    <span>{{__('Rate this product')}}</span>
                                </div>
                                <p>{{__('4.5/5.0 (21 reviews)')}}</p>
                            </div>
                            <div class="block-price">
                                <span>120 K MIND</span>
                                = 12.4 USDT
                                <div class="block-sale">-20% PRO</div>
                            </div>

                            <div class="btn-add-card">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.78469 8.20909L9.78469 1.60332C9.78467 1.27229 9.51632 1.00393 9.1853 1.00393C8.85425 1.00393 8.58588 1.2723 8.5859 1.60333L8.58588 8.20912L1.98012 8.20912C1.64907 8.20912 1.38072 8.47747 1.38073 8.8085C1.38075 9.13953 1.64909 9.4079 1.98012 9.40789L8.58588 9.40789L8.58588 16.0137C8.58588 16.3447 8.85424 16.6131 9.18527 16.613C9.51632 16.613 9.78465 16.3447 9.78465 16.0137L9.78465 9.40789L16.3904 9.4079C16.7215 9.4079 16.9898 9.13952 16.9898 8.8085C16.9898 8.47747 16.7214 8.20912 16.3904 8.20912L9.78469 8.20909Z" fill="white" stroke="white" stroke-linejoin="round"/>
                                </svg>
                                <span>{{__('Add to Cart')}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="product-information__description">
                        <span class="description-title">{{__('Description:')}}</span>
                        <div class="description-info">
                            <p>High quality men sunglasses with polarized lense protects you from intense UV rays exposure and makes a fresh stylish addition to your outfit, with a brand logo of MINDPLAYS ESPORTS</p>
                            <br>
                            <p>Available in 3 colors:</p>
                            <ul>
                                <li>Black</li>
                                <li>White</li>
                                <li>Blue</li>
                            </ul>
                            <span>Dimensions: 143x130x80 mm</span>
                            <br>
                            <p>Weight: 150 g</p>
                            <p>Made in China</p>
                        </div>
                    </div>
                </div>

                <div class="block-products">

                    <p class="block-products__title">{{__('See more products')}}</p>

                    <div class="list-products">
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutNavAuth from "../../modules/LayoutNavAuth";
    import DropdownDefault from "../../modules/DropdownDefault";
    import ItemProduct from "./ItemProduct";
    import VueSlickCarousel from "vue-slick-carousel";
    import "vue-slick-carousel/dist/vue-slick-carousel.css";
    import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

    export default {
        data() {
            return {
                side_tabs: [
                    {
                        'title': 'Product page',
                        'value': 'page'
                    },
                    {
                        'title': 'My orders',
                        'value': 'orders'
                    }
                ],
                loading_page: false,
                progress: 0,
                rating: 4.5,
            }
        },
        computed: {
            currentTab: function () {
                return (this.$route.params.current) ? this.$route.params.current : 'page'
            },
        },
        methods: {
            sideChange(newTab) {
                if (newTab != this.currentTab) {
                    this.$router.push({name: 'quests_current', params: {'current': newTab, lang: this.$store.getters.getLang}});
                    // this.data = false;
                    this.getQuests(newTab);
                }
            },
        },
        components: {
            LayoutNavAuth,
            DropdownDefault,
            ItemProduct,
            VueSlickCarousel
        }
    }
</script>

<style lang="scss" scoped>
    .page-product {
        color: #fff;

        &__head {
            margin: 20px 0;
            display: flex;
            align-items: center;

            .btn-back {
                color: #fff;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #171827;
                border: 1px solid #252525;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-right: 10px;

                &:hover {
                    color: #26A9F4;
                    background-color: #262626;
                }

            }

            .v-btn:before {
                background-color: #262626;
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 24px;
                min-width: 24px;
                padding: 0;
            }

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #FFFFFF;
                cursor: default;
                margin: 0;
                display: block;
            }
        }

        &__body {

            .product-information {
                margin-bottom: 30px;
                padding: 20px;
                background: #171827;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                display: flex;
                flex-direction: column;

                &__head {
                    padding-bottom: 20px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.62);
                    display: flex;
                    justify-content: space-between;

                    .block-img-product {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        margin-right: 60px;

                        .block-img {
                            height: 100%;
                            width: calc(100% - 60px);
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .slider-main {
                                height: 100%;
                                /*display: flex;*/
                                /*align-items: center;*/
                                /*justify-content: center;*/

                                .slick-slider {
                                    top: 50%;
                                    left: 0;
                                    transform: translate(0, -50%);
                                }

                                .slide-item {
                                    height: 100%;


                                    img {
                                        display: block;
                                        /*display: flex;*/
                                        /*align-items: center;*/
                                        /*justify-content: center;*/
                                        max-width: 320px;
                                        margin: 0 auto;
                                    }
                                }
                            }

                            .block-colors {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-bottom: 26px;
                                cursor: pointer;

                                &__item {
                                    width: 18px;
                                    height: 18px;
                                    border: 2px solid #C4C4C4;
                                    background-color: #171827;
                                    margin: 0 3px;
                                    border-radius: 50%;
                                }

                                .white-color {
                                    background-color: #fff;
                                }
                                .blue-color {
                                    background-color: #026EED;
                                }
                            }
                        }

                        .block-slider {
                            width: 60px;
                            height: 100%;
                            flex-shrink: 0;
                            position: relative;


                            .slider-vertical {
                                height: 60px;
                                width: 400px;
                                padding: 20px;
                                /*transform: rotate(90deg);*/
                                transform: translate(-150px, 160px) rotate(90deg) ;
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: flex!important;
                                flex-direction: column!important;

                                .slider-vertical-item {
                                    transform: rotate(-90deg);
                                    width: 64px!important;
                                    height: 64px;
                                    background: rgba(255, 255, 255, 0.05);
                                    border: 1px solid rgba(255, 255, 255, 0.05);
                                    border-radius: 8px;
                                    display: flex!important;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        width: 48px;
                                    }
                                }
                            }
                        }
                    }
                    .block-info {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 50%;
                        position: relative;
                        padding-top: 45px;

                        .block-status {
                            position: absolute;
                            right: 20px;
                            top: 15px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #01DF72;
                        }

                        &__title {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 32px;
                            color: #FFFFFF;
                            margin-bottom: 8px;
                        }
                        &__subtitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #96AFD1;
                            display: block;
                            margin-bottom: 34px;
                        }

                        .block-rate {
                            margin-bottom: 50px;
                            cursor: default;

                            .rating {
                                display: flex;
                                align-items: center;

                                .v-application--is-ltr .v-rating .v-icon {
                                    background: rgba(255, 255, 255, 0.05);
                                    border: 1px solid rgba(255, 255, 255, 0.05);
                                }

                                span {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    color: #96AFD1;
                                    display: block;
                                    margin-left: 13px;
                                }
                            }

                            p {
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 20px;
                                color: #96AFD1;
                                margin: 5px 0 0;
                            }
                        }

                        .block-price {
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 32px;
                            color: #96AFD1;
                            margin-bottom: 20px;
                            position: relative;
                            span {
                                font-weight: 400;
                                font-size: 48px;
                                line-height: 65px;
                                color: #00AEFF;
                            }

                            .block-sale {
                                background: #141414;
                                border: 1px solid rgba(255, 255, 255, 0.05);
                                box-sizing: border-box;
                                border-radius: 4px;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                color: #96AFD1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 22px;
                                padding: 0 5px;
                                width: fit-content;
                                position: absolute;
                                right: -90px;
                                top: 18px;
                            }
                        }

                        .btn-add-card {
                            border-radius: 8px;
                            background: #00AEFF;
                            height: 40px;
                            min-width: 200px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            position: relative;

                            svg {
                                position: absolute;
                                left: 12px;
                            }

                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 14px;
                                text-align: center;
                                color: #fff;
                            }
                        }
                    }
                }

                &__description {
                    padding: 25px 20px;

                    .description-title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #FFFFFF;
                        display: block;
                        margin-bottom: 8px;
                    }

                    .description-info {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #96AFD1;
                        width: 60%;

                        span {
                            display: block;
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }

            .block-products {
                &__title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                    margin-bottom: 30px;
                }

                .list-products {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;

                    .wrap-item-product {
                        padding: 0 15px;
                        width: 25%;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
</style>