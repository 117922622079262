<template>
    <v-card v-ripple="false">
        <div class="wrap-transaction-item">
            <ul>
                <li>{{item.id}}</li>
                <li>
                    <span v-if="item.opp_type==1" class="block-dep">
                      {{__('Deposit')}}
                    </span>
                    <span v-else-if="item.opp_type==2" class="block-withdraw">
                      {{__('Withdraw')}}
                    </span>
                    <span v-else-if="item.opp_type==3" style="color: #6886af">
                      {{__('Game battle')}}
                    </span>
                    <span v-else-if="item.opp_type==4" style="color: #6886af">
                      {{__('Referral')}}
                    </span>
                    <span v-else-if="item.opp_type==5" style="color: #6886af">
                      {{__('Achievements')}}
                    </span>
                    <span v-else-if="item.opp_type==6" style="color: #6886af">
                      {{__('Game commission')}}
                    </span>
                    <span v-else-if="item.opp_type==7 && item.income ==1" style="color: #6886af">
                      {{__('Quest')}}
                    </span>
                    <span v-else-if="item.opp_type==7 && item.income ==2" style="color: #6886af">
                      {{__('By Quest')}}
                    </span>
                    <span v-else-if="item.opp_type==8" style="color: #6886af">
                      {{__('Transfer')}}
                    </span>
                    <span v-else-if="item.opp_type==9" style="color: #6886af">
                      {{__('Transfer of partner funds')}}
                    </span>
                    <span v-else-if="item.opp_type==10 || item.opp_type == 11" style="color: #6886af">
                      {{__('Bonus')}}
                    </span>
                    <span v-else-if="item.opp_type == 12" style="color: #6886af">
                      {{__('Buy pro account')}}
                    </span>
                    <span v-else-if="item.opp_type == 14" style="color: #6886af">
                      {{__('Other')}}
                    </span>
                    <span v-else-if="item.opp_type == 15" style="color: #6886af">
                      {{__('Tournament')}}
                    </span>
                    <span v-else-if="item.opp_type == 16" style="color: #6886af">
                      {{__('Bonus')}}
                    </span>
                </li>

                <li>
                    <div v-if="(item.opp_type == 1 || item.opp_type == 2) && item.payment_id" :class="sortPayments.name_class" class="block-payment">
<!--                        <img :src="sortPayments.img">-->
                    </div>

                    <div v-if="((item.opp_type == 1 || item.opp_type == 2) && !item.payment_id) || item.opp_type == 10 || item.opp_type == 11 || item.opp_type == 12" class="block-game">
                        <img src="/dist/images/frontend/LogoMP-icon.svg">
                    </div>

                    <div v-if="item.opp_type == 3 || item.opp_type == 15" class="block-game">
                        <img v-if="typeof item.game.logo == 'object'" :src="item.game.logo[100]">
                        <img v-else :src="item.game.logo">
                    </div>

                    <div v-if="item.opp_type == 4" class="block-game">
                        <span>{{item.lvl}} {{__('LVL')}}</span>
                    </div>

                    <div v-if="item.opp_type == 5" class="block-game">
                        <img :src="item.achievement.icon">
                    </div>

                    <div v-if="item.opp_type == 6" class="block-game">
                        <!--<img class="pic" src="/dist/images/frontend/money.svg" alt />-->
                        <img v-if="typeof item.game.logo == 'object'" :src="item.game.logo[100]">
                        <img v-else :src="item.game.logo">
                    </div>

                    <div v-if="item.opp_type == 7" class="block-game">
                        <img :src="item.quest.icon">
                    </div>
                    <div v-if="item.opp_type == 8" class="block-transfer">
                        <div class="wrap-img" :class="(item.enemy.pro) ? 'user-pro-img' : ''">
                            <div class="block-img">
                                <img v-if="item.enemy.photo && typeof item.enemy.photo == 'object'" :src="item.enemy.photo[40]" />
                                <img v-else-if="item.enemy.photo" :src="item.enemy.photo" />
                                <span v-else class="photo" :style="{'background': item.enemy.bg_color}">{{item.enemy.name.substr(0,1)}}</span>
                            </div>
                            <div class="online"></div>
                        </div>
                    </div>
                    <div v-if="item.opp_type == 9" class="block-partner">
                        <img src="/dist/images/frontend/crown.png">
                    </div>
                    <div v-if="item.opp_type == 14" class="block-game">
                        <img src="/dist/images/frontend/LogoMP-icon.svg">
                    </div>
                </li>
                <li>
                     <span class="block-real" v-if="item.wallet== 1">
                        <img class="pic" src="/dist/images/frontend/money.svg" alt />
                        <span v-if="item.income == 2">-</span>
                        <span v-else-if="item.income == 1">+</span>
                         {{primNum(item.amount, 3)}}
                    </span>
                    <span class="block-lite" v-else>
                      <img class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                      <span v-if="item.income==2">-</span>
                      <span v-else-if="item.income==1">+</span>
                        {{primNum(item.amount, 3)}}
                    </span>
                </li>
                <li>
                    <span v-if="(item.income == 1 && item.opp_type == 8) || item.opp_type == 9" class="success--text">{{__('REQUEST')}}</span>
                    <span v-else-if="item.income == 2 && item.opp_type == 8" class="error--text">{{__('SEND')}}</span>

                    <a v-else-if="(item.opp_type == 1 || item.opp_type == 2) && item.payment_id" :href="$paymentLink[sortPayments.currency]+item.tx" target="_blank">{{item.tx}}</a>

                    <a v-else-if="(item.opp_type == 1 || item.opp_type == 2) && !item.payment_id" href="#">{{item.tx}}</a>

                    <v-btn class="btn-link-blue" v-else-if="(item.opp_type == 3 && item.frozen == 0) || item.opp_type == 4" text v-ripple="false" @click="getInfo">{{__('Read more')}}</v-btn>

                    <span v-else-if="item.opp_type == 3 && item.frozen == 1">{{__('Frozen')}}</span>

                    <div v-else-if="item.opp_type == 6 ">{{__('Players:')}} {{item.tx}}</div>

                    <div v-else-if="item.opp_type == 11 ">{{item.tx}}</div>

                    <div v-else-if="item.opp_type == 10">{{__('for each game played')}}</div>

                    <div v-else-if="item.opp_type == 5" class="block-game">
                        <v-tooltip
                                v-model="show"
                                bottom
                                color="transparent"
                                nudge-top="5"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div class="wrap-chat">
                                    <a v-on="on" v-bind="attrs">{{__(item.achievement.name)}}</a>
                                </div>
                            </template>
                            <p class="description ma-0">
                                {{__(item.achievement.description)}}
                            </p>
                        </v-tooltip>
                    </div>

                    <div v-else-if="item.opp_type == 7" class="block-game">
                        <!--          <img :src="item.quest.icon">-->

                        <v-tooltip
                                v-model="show"
                                bottom
                                color="transparent"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div class="wrap-chat">
                                    <a v-on="on" v-bind="attrs">{{__(item.quest.name)}}</a>
                                </div>
                            </template>
                            <p class="description ma-0">
                                {{__(item.quest.description)}}
                            </p>
                        </v-tooltip>
                    </div>

                    <div v-else-if="item.opp_type == 14 ">{{item.tx}}</div>
                    <div v-else-if="item.opp_type == 15 ">{{__('ID:')}} {{item.tournament_id}}</div>
                    <div v-else-if="item.opp_type == 16 ">{{__('Payment per action')}}</div>
                </li>
                <li>{{item.created_at}}</li>
                <v-btn
                        @click="show_mobile = !show_mobile"
                        class="btn-link"
                        icon
                        v-ripple="false"
                >
                    <svg v-if="!show_mobile" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8334 1.08331L7.00008 6.91665L1.16675 1.08331" stroke="#96AFD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.16658 6.91669L6.99992 1.08335L12.8333 6.91669" stroke="#26A9F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </v-btn>
            </ul>
            <ModalInfoReferral
                    :info.sync="info"
                    v-if="transaction_info_modal && item.opp_type == 4"
                    v-bind:transaction_info_modal.sync="transaction_info_modal"
            ></ModalInfoReferral>

            <ModalInfoBattleGame
                    :info.sync="info"
                    v-if="transaction_info_battle && item.opp_type == 3"
                    v-bind:transaction_info_battle.sync="transaction_info_battle"
            >
            </ModalInfoBattleGame>

        </div>
        <v-expand-transition >
            <div v-show="show_mobile">
                <v-card-text>
                    <span>
                        <p>{{__('Operation ID')}}:</p>
                        {{item.id}}
                    </span>
                    <span>
                        <p>{{__('Date/UTC')}}:</p>
                        {{item.created_at}}
                    </span>
                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
import ModalInfoBattleGame from "@/components/pages/TransactionHistory/ModalInfoBattleGame";
import ModalInfoReferral from "@/components/pages/TransactionHistory/ModalInfoReferral";

export default {
  name: 'TransactionItem',
  data() {
    return {
      show_mobile: false,
      show: false,
      transaction_info_modal: false,
      transaction_info_battle: false,
      submitDisabled: false,
      info: null,
    }
  },
  methods: {
    getInfo() {
      this.transaction_info_modal = true;
      this.transaction_info_battle = true;
      this.submitDisabled = true;

      const response = {
        id: this.item.id,
      };

      this.$http.post("ajax/transaction/info", response)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                // response = {
                //     "data": {
                //         "current_page": 1,
                //         "data": [
                //             {
                //                 "user_id": 205899639,
                //                 "wallet": 2,
                //                 "commis": "0.016400000000",
                //                 "user": {
                //                     "id": 205899639,
                //                     "public_id": "205899639",
                //                     "email": "aleksey.skorobogatko@mail.ru",
                //                     "name": "Алексей Скоробогатько",
                //                     "last_name": null,
                //                     "photo": "/assets/users/a-1643810446Gh0KzsJHp5.png",
                //                     "bg_color": "#31cc41",
                //                     "location": "UA",
                //                     "location_img": "/dist/images/flags/ua.png",
                //                     "city": null,
                //                     "online": "2022/02/03 11:03:35",
                //                     "rating_points": 184,
                //                     "vip": 0,
                //                     "plays": 1,
                //                     "lang": "en",
                //                     "verified": 0
                //                 }
                //             },
                //             {
                //                 "user_id": 1457047959,
                //                 "wallet": 2,
                //                 "commis": "0.006400000000",
                //                 "user": {
                //                     "id": 1457047959,
                //                     "public_id": "1457047959",
                //                     "email": "dimasprocrypto@gmail.com",
                //                     "name": "Дмитрий",
                //                     "last_name": null,
                //                     "photo": null,
                //                     "bg_color": "#40fc6e",
                //                     "location": "UA",
                //                     "location_img": "/dist/images/flags/ua.png",
                //                     "city": null,
                //                     "online": "2022/02/02 22:48:25",
                //                     "rating_points": 156,
                //                     "vip": 0,
                //                     "plays": 0,
                //                     "lang": "en",
                //                     "verified": 0
                //                 }
                //             },
                //             {
                //                 "user_id": 1558357398,
                //                 "wallet": 2,
                //                 "commis": "0.006000000000",
                //                 "user": {
                //                     "id": 1558357398,
                //                     "public_id": "1558357398",
                //                     "email": "Daisor14@gmail.com",
                //                     "name": "Daisor",
                //                     "last_name": null,
                //                     "photo": null,
                //                     "bg_color": "#f84138",
                //                     "location": "UA",
                //                     "location_img": "/dist/images/flags/ua.png",
                //                     "city": null,
                //                     "online": "2022/02/03 08:35:19",
                //                     "rating_points": 69,
                //                     "vip": 0,
                //                     "plays": 0,
                //                     "lang": "en",
                //                     "verified": 1
                //                 }
                //             }
                //         ],
                //         "first_page_url": "https://mindplays.com/ajax/transaction/info?page=1",
                //         "from": 1,
                //         "last_page": 1,
                //         "last_page_url": "https://mindplays.com/ajax/transaction/info?page=1",
                //         "next_page_url": null,
                //         "path": "https://mindplays.com/ajax/transaction/info",
                //         "per_page": 3,
                //         "prev_page_url": null,
                //         "to": 3,
                //         "total": 3
                //     },
                //     "auth": true,
                //     "status": 200
                // }

                // if (this.item.opp_type == 3) {
                //   response = {
                //     "data": [
                //       {
                //         "user": {
                //           "id": 532267078,
                //           "public_id": "532267078",
                //           "name": "GameLogic2",
                //           "last_name": null,
                //           "photo": null,
                //           "bg_color": "#29a3a3",
                //           "location": "Ukraine",
                //           "location_img": "/dist/images/flags/ua.png",
                //           "online": "2021/03/31 06:29:29",
                //           "rating_points": 19,
                //           "vip": 0
                //         },
                //         "operation_type": 2,
                //         "amount": 5,
                //         "comment": "Lose 5"
                //       }
                //     ],
                //     "status": 200
                //   }
                // } else {
                //   response = {
                //     "data": {
                //       "current_page": 1,
                //       "data": [
                //         {
                //           "user_id": 1,
                //           "commis": "0.020000000000",
                //           "user": {
                //             "id": 1,
                //             "public_id": "volkonsky",
                //             "name": "Vladyslav",
                //             "last_name": "Volkonsky",
                //             "photo": "/assets/users/a-1601498151BVVxvEEhDv.png",
                //             "bg_color": "#f84138",
                //             "location": "UA",
                //             "location_img": "/dist/images/flags/ua.png",
                //             "online": "2021/03/31 06:34:44",
                //             "rating_points": 78089,
                //             "vip": 0
                //           }
                //         },
                //         {
                //           "user_id": 3,
                //           "commis": "0.004000000000",
                //           "user": {
                //             "id": 3,
                //             "public_id": "1838849874",
                //             "name": "zxc",
                //             "last_name": "zxc",
                //             "photo": "/assets/users/a-16167708047STVtTdszV.png",
                //             "bg_color": "#FCDD01",
                //             "location": "United Kingdom",
                //             "location_img": "/dist/images/flags/de.png",
                //             "online": "2021/03/26 14:04:07",
                //             "rating_points": 12,
                //             "vip": 0
                //           }
                //         },
                //         {
                //           "user_id": 532267078,
                //           "commis": "0.024000000000",
                //           "user": {
                //             "id": 532267078,
                //             "public_id": "532267078",
                //             "name": "GameLogic2",
                //             "last_name": null,
                //             "photo": null,
                //             "bg_color": "#29a3a3",
                //             "location": "Ukraine",
                //             "location_img": "/dist/images/flags/ua.png",
                //             "online": "2021/03/31 06:29:29",
                //             "rating_points": 19,
                //             "vip": 0
                //           }
                //         },
                //         {
                //           "user_id": 1,
                //           "commis": "0.020000000000",
                //           "user": {
                //             "id": 1,
                //             "public_id": "volkonsky",
                //             "name": "Vladyslav",
                //             "last_name": "Volkonsky",
                //             "photo": "/assets/users/a-1601498151BVVxvEEhDv.png",
                //             "bg_color": "#f84138",
                //             "location": "UA",
                //             "location_img": "/dist/images/flags/ua.png",
                //             "online": "2021/03/31 06:34:44",
                //             "rating_points": 78089,
                //             "vip": 0
                //           }
                //         },
                //         {
                //           "user_id": 3,
                //           "commis": "0.004000000000",
                //           "user": {
                //             "id": 3,
                //             "public_id": "1838849874",
                //             "name": "zxc",
                //             "last_name": "zxc",
                //             "photo": "/assets/users/a-16167708047STVtTdszV.png",
                //             "bg_color": "#FCDD01",
                //             "location": "United Kingdom",
                //             "location_img": "/dist/images/flags/de.png",
                //             "online": "2021/03/26 14:04:07",
                //             "rating_points": 12,
                //             "vip": 0
                //           }
                //         },
                //         {
                //           "user_id": 1,
                //           "commis": "0.020000000000",
                //           "user": {
                //             "id": 1,
                //             "public_id": "volkonsky",
                //             "name": "Vladyslav",
                //             "last_name": "Volkonsky",
                //             "photo": "/assets/users/a-1601498151BVVxvEEhDv.png",
                //             "bg_color": "#f84138",
                //             "location": "UA",
                //             "location_img": "/dist/images/flags/ua.png",
                //             "online": "2021/03/31 06:34:44",
                //             "rating_points": 78089,
                //             "vip": 0
                //           }
                //         },
                //         {
                //           "user_id": 3,
                //           "commis": "0.004000000000",
                //           "user": {
                //             "id": 3,
                //             "public_id": "1838849874",
                //             "name": "zxc",
                //             "last_name": "zxc",
                //             "photo": "/assets/users/a-16167708047STVtTdszV.png",
                //             "bg_color": "#FCDD01",
                //             "location": "United Kingdom",
                //             "location_img": "/dist/images/flags/de.png",
                //             "online": "2021/03/26 14:04:07",
                //             "rating_points": 12,
                //             "vip": 0
                //           }
                //         },
                //       ],
                //       "first_page_url": "https://mindplay.loc/ajax/transaction/info?page=1",
                //       "from": 1,
                //       "last_page": 1,
                //       "last_page_url": "https://mindplay.loc/ajax/transaction/info?page=1",
                //       "next_page_url": null,
                //       "path": "https://mindplay.loc/ajax/transaction/info",
                //       "per_page": 3,
                //       "prev_page_url": null,
                //       "to": 3,
                //       "total": 3
                //     },
                //     "status": 200
                //   }
                // }
                this.info = response.data;
                this.submitDisabled = false;
            }
          })
          .catch((err) => {
            this.httpHandler(err);
          });
    }
  },
  props: {
    item: {
      type: Object,
    },
    sortPayments: {
      type: Object,
    }
  },
  components: {
    ModalInfoReferral,
    ModalInfoBattleGame,
  }
}
</script>

<style lang="scss" scoped>

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
    }

    .v-sheet.v-card {
        border-radius: 0;
        border-bottom: 1px solid #ecf4ff;
    }

    .v-card__text {
        padding: 0;
    }

    .dark {
        .v-card__text {
            span {
                color: #fff;

                p {
                    color: #96AFD1;
                }
            }
        }
    }

    .v-card__text {
        padding: 0 15px 10px;
        span {
            display: flex;
            align-items: center;

            &:first-child {
                display: none;
            }

            p {
                font-size: 14px;
                line-height: 19px;
                color: #6886AF;
                margin: 0 5px 0 0;
            }

            a {
                color: #26A9F4;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

.dark {
    .wrap-transaction-item {
        ul {
            li {
                color: #fff;

                .block-lite {
                    color: #96AFD1;
                }
            }
        }
    }
}

.wrap-transaction-item {
  ul {
    display: flex;
    /*border-bottom: 1px solid #ecf4ff;*/
    padding: 0 26px;
    margin: 0;
    align-items: center;

  .v-btn.btn-link {
      display: none;
      height: 24px;
  }


    li {
      display: flex;
      align-items: center;
      width: 15%;
      overflow: hidden;
      text-overflow: ellipsis;
      list-style-type: none;
      height: 55px;
      font-size: 14px;
      color: #002B50;

      &:nth-child(3) {
        padding: 0;
      }

      &:nth-child(5) {
        width: 25%;
        overflow: hidden;
         text-overflow: ellipsis;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
      }

      &:nth-child(6) {
        justify-content: flex-end;
        text-align: end;
      }


      .block-withdraw {
        color: #FF4B55;
      }

      .block-dep {
        color: #00C443;
      }

      .block-real {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #96AFD1;

          img {
              width: 20px;
          }
      }

      .block-lite {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #6886AF;

          img {
              width: 20px;
          }
      }

      .pic {
        margin: 0 5px 0 0;
      }

      a {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4ebeff;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
        .block-partner {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
            }
        }

        .block-payment {
            height: 30px;
            min-width: 30px;
            margin-right: 16px;
            border-radius: 50%;
            background-size: 14px;
            background-repeat: no-repeat;
            background-position: center;
        }

        .block-payment.btc{
            background-color: #FF7A00;
            background-image: url(/dist/images/frontend/wallets/white-btc.svg);
        }
        .img.bsc-usd_bep20 {
            background-color: #f3ba2f;
            background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
        }
        .block-payment.bnb{
            background-color: #f3ba2f;
            background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
        }

        .block-payment.ada{
            background-color: #2A76D0;
            background-image: url(/dist/images/frontend/wallets/white-ada.svg);
        }
        .block-payment.dot{
            background-color: #666;
            background-image: url(/dist/images/frontend/wallets/white-dot.svg);
        }
        //
        //.img.usdt {
        //  background-color: #4FD984;
        //  background-image: url(../../../public/images/wallets/white-usdt.svg);
        //}
        .block-payment.ltc {
            background-color: #BBBBBB;
            background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
        }
        .block-payment.doge {
            background-color: #D4BE00;
            background-image: url(/dist/images/frontend/wallets/white-doge.svg);
        }
        .block-payment.usdt_trc20 {
            background-color: #C3332D;
            background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
        }
        .block-payment.eth {
            background-color: #4A84FF;
            background-image: url(/dist/images/frontend/wallets/white-eth.svg);
        }
        .block-payment.xrp {
            background-position: 10px center;
            background-color: #2DA9E8;
            background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
        }
        .block-payment.bch {
            background-color: #8DC351;
            background-image: url(/dist/images/frontend/wallets/white-bch.svg);
        }
        .block-payment.trx {
            background-color: #C3332D;
            background-image: url(/dist/images/frontend/wallets/white-trx.svg);
        }
        .block-payment.link {
            background-color: #2A5ADA;
            background-image: url(/dist/images/frontend/wallets/white-link.svg);
        }
        .block-payment.payeer {
            background-color: #2A93FF;
            background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
        }
        .block-payment.pm {
            background-color: #FF0000;
            background-image: url(/dist/images/frontend/wallets/white-pm.svg);
        }
        .block-payment.etc {
            background-color: #1B8600;
            background-image: url(/dist/images/frontend/wallets/white-etc.svg);
        }
        .block-payment.adv {
            background-color: #0f2d38;
            background-image: url(/dist/images/frontend/wallets/advcash.svg);
            background-size: 20px;
            background-position: 11px center;
        }
        .block-payment.dai{
            background-image: url(/dist/images/frontend/wallets/white-dai.svg);
            background-color: #F9AC13;
        }
        .block-payment.dash{
            background-image: url(/dist/images/frontend/wallets/dash.png);
            /*background-color: #F9AC13;*/
            background-size: 100%;
        }

        .block-payment.mc_uah,
        .block-payment.mc_usd,
        .block-payment.mc_rub {
            background-image: url(/dist/images/frontend/wallets/mc_visa.svg);
            background-color: #333333;
            background-size: 24px;
        }

        .block-payment.visa_uah,
        .block-payment.visa_usd,
        .block-payment.visa_rub {
            background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
            background-color: #00579F;
            background-size: 26px;
        }

      .block-game {
        display: flex;
        align-items: center;

        span {
          display: block;
          max-width: 300px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        img {
          position: relative;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          //margin-top: 3px;
          float: left;
        }
      }

    .block-transfer {
        .wrap-img {
            position: relative;
        }

        .block-img {
            width: 24px;
            height: 24px;
            border-radius: 50%;

            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }

            .photo {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
    }
  }
}
.description {
  font-size: 13px;
  line-height: 18px;
  color: #002B50;
  max-width: 300px;
  padding: 10px;
  background: rgb(255, 255, 255);
  border: 1px solid #ECF4FF;
  border-radius: 6px;
}

@media screen and (max-width: 1400px) {
    .wrap-transaction-item {
        ul {
            li {
                width: 15%;

                &:nth-child(3) {
                    width: 10%;
                }
                &:nth-child(4) {
                    width: 20%;
                }
                &:nth-child(5) {
                    width: 25%;
                }
            }
        }
    }
}

@media screen and (max-width: 950px){
    .wrap-transaction-item {
        ul {
            padding: 0 15px;

            li {
                padding-right: 5px;

                &:nth-child(6) {
                    padding-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .wrap-transaction-item {
        ul {
            padding: 0 3px 0 15px;
            li {
                width: 20%;
                &:nth-child(3) {
                    width: 15%;
                }
                &:nth-child(4) {
                    width: 25%;
                }
                &:nth-child(5) {
                    width: 25%;
                }
                &:nth-child(6) {
                    display: none;
                }
            }

            .v-btn.btn-link {
                display: block;
                height: 24px;
            }
        }
    }
}

    @media screen and (max-width: 650px) {

        .v-card__text {
            span {
                font-size: 12px;
                line-height: 17px;

                p {
                    font-size: 12px;
                    line-height: 17px;
                }

            }
        }
        .wrap-transaction-item {
            ul {
                li {
                    font-size: 12px;

                    .block-real {
                        font-size: 12px;
                        line-height: 16px;

                        img {
                            height: 14px;
                        }
                    }

                    .block-lite {
                        font-size: 12px;
                        line-height: 16px;

                        img {
                            height: 14px;
                        }
                    }
                }

                .v-btn.btn-link-blue {
                    font-size: 12px;
                }
            }
        }
    }

    @media screen and (max-width: 500px){

        .description {
            font-size: 11px;
            line-height: 16px;
        }

        .v-card__text {
            span {
                &:first-child {
                    display: flex;
                }
            }
        }

        .wrap-transaction-item {
            ul {
                li {
                    width: 25%;
                    &:first-child {
                        display: none;
                    }
                    &:nth-child(3) {
                        width: 20%;
                    }
                    &:nth-child(4) {
                        width: 25%;
                    }
                }

                .v-btn.btn-link {
                    display: block;
                    height: 24px;
                }
            }
        }
    }
</style>