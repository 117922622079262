<template>
  <div class="wrap-friends-buttons">
      <BaseButton class_btn="btn-liteGrey" class="ml-3" v-on:click="$emit('sendSubscribe', 2)" v-if="item.subscribe== 0"  :loading="submitDisabled">
          <span>{{__('Cancel request')}}</span>
      </BaseButton>

      <BaseButton class_btn="btn-liteGrey"  v-on:click="$emit('sendSubscribe', 3)" v-else-if="item.subscribe== 1" :loading="submitDisabled">
          <span>{{__('Unfriend')}}</span>
      </BaseButton>

    <BaseButton  v-on:click="$emit('sendSubscribe', 0)" v-else-if="item.subscribe== 2" :loading="submitDisabled">
      <span>{{__('Add friend')}}</span>
    </BaseButton>

    <BaseButton  v-on:click="$emit('sendSubscribe', 1)" v-else-if="item.subscribe== 3" :loading="submitDisabled">
      <span>{{__('Confirm request')}}</span>
    </BaseButton>
  </div>
</template>


<script>
    export default {
        name: 'FriendsButtons',
        data () {
            return {
            }
        },
        props: {
            item: {
                type: Object
            },
            submitDisabled: {
                type: Boolean
            }
        },
        computed: {

        },
        methods: {

        },
        components: {

        },

    }
</script>

<style lang="scss" scoped>

    .wrap-friends-buttons {
        .btn-cab {
            margin-right: 10px;
        }
    }

</style>