<template>
    <div>
        <v-dialog v-model="dialog" max-width="350px">
            <div class="balance-sorting">
                <div class="balance-sorting-head">
                <div class="balance-sorting-head__icon">
                <img src="/dist/images/frontend/filter-icon.svg" alt />
                </div>
                <h5>{{__('Filters')}}</h5>
                </div>
                <div class="balance-sorting-body">
                    <div class="betting status" v-if="currentTab == 'deposit' || currentTab == 'withdraw'">
                        <div class="betting__head">
                            <div class="map__icon">
                                <img src="/dist/images/frontend/payment-icon.svg" alt />
                            </div>
                            <p>{{__('Payment')}}</p>
                        </div>
                        <div class="payment__input">
                            <v-autocomplete
                                    dense
                                    filled
                                    solo
                                    v-model="sorting.payment_item"
                                    :items="payments"
                                    :filter="customFilter"
                                    item-color="white"
                                    :label="__('Payment system')"
                            >
                                <template v-slot:selection="data">
                                    <img class="payment-item__icon mr-3" width="30" v-html="data.item.img" :src="data.item.img">
                                    {{ data.item.name }}
                                    <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <div class="wrap-item-select">
                                            <img class="payment-item__icon" v-html="data.item.img" :src="data.item.img">
                                            <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                                        </div>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>

                    <div class="betting" v-if="currentTab == 'deposit' || currentTab == 'withdraw'">
                        <div class=" status betting__head">
                            <div class="map__icon">
                                <img src="/dist/images/frontend/status-icon.svg" alt />
                            </div>
                            <p>{{__('Status')}}</p>
                        </div>
                        <div class="status__input">
                            <DropdownDefault
                                    style="width: 100%"
                                    color="select_color"
                                    v-bind:value.sync="sorting.status"
                                    v-bind:dropdown="sortType"
                                    @change="updateValidate('type')"
                                    v-bind:errors="formValidate.type"
                            ></DropdownDefault>
                        </div>
                    </div>
                    <div class="betting" v-if="currentTab == 'transfer'">
                        <div class="betting__head">
                            <div class="map__icon">
                                <img src="/dist/images/frontend/map-icon.svg" alt />
                            </div>
                            <p>{{__('Amount')}}</p>
                        </div>
                        <v-radio-group row class="wrap-radio">
                            <v-checkbox v-model="sorting.income" color="blue" value="1" :label="__('Income')"></v-checkbox>
                            <v-checkbox v-model="sorting.income" color="blue" value="2" :label="__('Expens')"></v-checkbox>
                        </v-radio-group>
                    </div>
                    <!--        <div class="gender">-->
                    <!--            <div class="gender__head">-->
                    <!--                <p>Profit/Withdraw</p>-->
                    <!--            </div>-->
                    <!--            <div class="wrap-checkbox d-flex">-->
                    <!--                <v-radio color="blue" value="1" :label="__('+')"></v-radio>-->
                    <!--                <v-radio color="blue" value="2" :label="__('-')"></v-radio>-->
                    <!--            </div>-->
                    <!--        </div>-->
                    <div class="betting" v-if="currentTab == 'deposit'">
                        <div class="betting__head">
                            <div class="map__icon">
                                <img src="/dist/images/frontend/coin-icon.svg" alt />
                            </div>
                            <p>{{__('Coin')}}</p>
                        </div>
                        <v-radio-group row class="wrap-radio">
                            <v-checkbox v-model="sorting.wallet" color="blue" value="1" :label="__('Gold')"></v-checkbox>
                            <v-checkbox v-model="sorting.wallet" color="blue" value="2" :label="__('Silver')"></v-checkbox>
                        </v-radio-group>
                    </div>
                    <div class="betting date">
                        <div class="betting__head">
                            <div class="map__icon">
                                <img src="/dist/images/frontend/date-icon.svg" alt />
                            </div>
                            <p>{{__('Date')}}</p>
                        </div>
                        <div class="wrap-date">
                            <div class="block-birthday">
                                <!--            <InputLabelTextDefault-->
                                <!--                label="Date"-->
                                <!--                color="main"-->
                                <!--                class="mr-8"-->
                                <!--                type="number"-->
                                <!--            ></InputLabelTextDefault>-->
                                <v-menu
                                        v-if="inputDateFrom == null || inputDateFrom == ''"
                                        v-model="date"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            ref="picker"
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="sorting.date_from"
                                            @change="changeCalendar"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                        v-else
                                        v-model="date_menu"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="sorting.date_from"
                                            @change="changeCalendar"
                                    ></v-date-picker>
                                </v-menu>

                                <v-text-field
                                        color="light-blue"
                                        :label="__('From')"
                                        v-mask="'##.##.####'"
                                        v-model="inputDateFrom"
                                        clearable
                                        @input="changeInput"
                                        @change="updateDate"
                                        ref="birthday"
                                ></v-text-field>
                                <div class="information">{{__('(DD.MM.YYYY)')}}</div>
                            </div>



                            <div class="block-birthday">
                                <v-menu
                                        v-if="inputDateTo == null || inputDateTo == ''"
                                        v-model="dateTo"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDateTo"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            ref="picker"
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="sorting.date_to"
                                            @change="changeCalendarTo"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                        v-else
                                        v-model="date_menuTo"
                                        :close-on-content-click="false"
                                        max-width="290"
                                >
                                    <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDateTo"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                                    </template>
                                    <v-date-picker
                                            color="blue lighten-1"
                                            :min="minYear"
                                            :max="maxYear"
                                            v-model="sorting.date_to"
                                            @change="changeCalendarTo"
                                    ></v-date-picker>
                                </v-menu>

                                <v-text-field
                                        color="light-blue"
                                        :label="__('To')"
                                        v-mask="'##.##.####'"
                                        v-model="inputDateTo"
                                        clearable
                                        @input="changeInput"
                                        @change="updateDateTo"
                                        ref="birthday"
                                ></v-text-field>
                                <div class="information">{{__('(DD.MM.YYYY)')}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-btn">
                        <div style="width: 48%">
                            <BaseButton
                                    v-on:click="cancelFilters()"
                                    :loading="submitDisableded"

                                    class_btn="btn-liteGrey"
                            >
                                <span>{{__('Clear')}}</span>
                            </BaseButton>
                        </div>

                        <div style="width: 48%">
                            <BaseButton
                                    v-on:click="onSubmit"
                                    :loading="submitDisabled"

                            >
                                <span>{{__('Apply')}}</span>
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    // import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownDefault from "../../modules/DropdownDefault";
    import {numeric} from "vuelidate/lib/validators";
    import moment from "moment";
    import {mask} from 'vue-the-mask';

    export default {
        name: "TransactionSorting",
        data() {
            return {
                sorting: {
                    income: this.sorting_balance.income,
                    wallet: this.sorting_balance.wallet,
                    date_from: this.sorting_balance.date_from,
                    date_to: this.sorting_balance.date_to,
                    payment_item: this.sorting_balance.payment_item,
                    payment: this.sorting_balance.payment,
                    status: this.sorting_balance.status,
                },
                dialog: true,

                submitDisabled: false,
                submitDisableded: false,

                formValidate: {
                    status: null,
                    income: null,
                    wallet: null,
                    date_from: null,
                    date_to: null,
                    payment: null,
                },

                date: false,
                dateTo: false,
                inputDateFrom: this.sorting_balance.date_from ? moment(this.sorting_balance.date_from).format('DD.MM.YYYY') : '',
                inputDateTo: this.sorting_balance.date_to ? moment(this.sorting_balance.date_to).format('DD.MM.YYYY') : '',
                date_menu: false,
                date_menuTo: false,

                sortType: [
                    {
                        title: this.__("All statuses"),
                        value: null,
                    },
                    {
                        title: this.__("Success"),
                        value: 1,
                    },
                    {
                        title: this.__("Wait"),
                        value: 2,
                    },
                    {
                        title: this.__("Cancel"),
                        value: 3,
                    },
                    {
                        title: this.__("Error"),
                        value: 4,
                    },
                ],
            };
        },

        watch: {
            dialog() {
                this.$emit("update:sorting_modal", false);
            },
        },

        validations: {
            sorting: {
                type: {
                    numeric
                },
            },
        },

        props: {
            // search: {
            //   type: String,
            // },
            color: {
                type: String,
            },

            // sorting: {
            //     type: Boolean
            // },
            search: {
                type: String
            },
            sorting_modal: {
                type: Boolean
            },
            payments: {
                type: Array
            },
            sorting_balance: {
                type: Object
            },
            filter: {
                type: Array,
            }
        },
        computed: {
            minYear: function () {
                return (new Date().getFullYear() - 100).toString();

            },
            maxYear: function () {
                // let a = new Date().getTime() - 567648000000;
                let a = new Date().getTime();
                return moment(a).format('YYYY-MM-DD');
                // return (new Date().getFullYear() - 18).toString();
            },
            computedDate() {
                return this.sorting.date_from ? moment(this.sorting.date_from).format('DD.MM.YYYY') : ''
            },
            computedDateTo() {
                return this.sorting.date_to ? moment(this.sorting.date_to).format('DD.MM.YYYY') : ''
            },
            currentTab() {
                return (this.$route.params.current) ? this.$route.params.current : this.side_tabs[0].value
            },
        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.sorting[type],
                    type
                );
            },
            onSubmit: function () {
                let valid = this.$v.sorting;

                if (valid.$invalid) {
                    let massages = {
                        status: [],
                        income: [],
                        wallet: [],
                        date_from: [],
                        date_to: [],
                        payment: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {

                    // let type = this.type;
                    //
                    // if (this.type == 0) {
                    //     type = null;
                    // } else {
                    //     type = this.type
                    // }

                    // let income = this.income;
                    //
                    // if (income.length > 1) {
                    //     income = null;
                    // } else {
                    //     income = Number(income[0]);
                    // }
                    //
                    // let wallet = this.wallet;
                    //
                    // if (wallet.length > 1) {
                    //     wallet = null;
                    // } else {
                    //     wallet = Number(wallet[0]);
                    // }
                    //
                    //
                    // this.$emit('update:histories', null);
                    this.$emit('update:sorting_balance', this.sorting);
                    let sorting = this.sorting;
                    let count = [];
                    Object.keys(this.sorting).forEach((key) => {
                        if(this.sorting[key]) {
                            count.push(this.sorting[key]);
                        }
                    });
                    this.$emit('update:filter', count);
                    let response = sorting;
                    response.payment = (this.sorting.payment_item) ? this.sorting.payment_item.id : null;
                    response.search = this.search ? this.search : null;
                    let type;
                    if(this.currentTab=='deposit') {
                        type = 1;
                    } else if (this.currentTab=='withdraw') {
                        type = 2;
                    } else if (this.currentTab=='transfer') {
                        type = 8;
                    } else {
                        type = null;
                    }
                    response.type = type;
                    this.submitDisabled = true;
                    // this.$emit('updateParams', response);
                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction/history", response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                // response= {
                                //     "data": {
                                //         "current_page": 1,
                                //         "data": [
                                //             {
                                //                 "id": 576,
                                //                 "user_id": 2,
                                //                 "frozen": 0,
                                //                 "wallet": 1,
                                //                 "amount": "0.00000000",
                                //                 "amount_unit": "100.00000000",
                                //                 "rate_amount": "0.00000000",
                                //                 "income": 1,
                                //                 "commission": "0.00000000",
                                //                 "opp_type": 1,
                                //                 "game_played_id": null,
                                //                 "achiev_id": null,
                                //                 "quest_id": null,
                                //                 "game_id": null,
                                //                 "payment_id": 3,
                                //                 "tx": "0x57a887fbd46cbe66e9c4404c4bf99ee117aa3ea5",
                                //                 "status": 3,
                                //                 "confirm": 0,
                                //                 "created_at": "2021-03-18 08:38:56"
                                //             }
                                //         ],
                                //         "first_page_url": "https://mindplay.loc/ajax/deposit/paginate/deposit?page=1",
                                //         "from": 1,
                                //         "last_page": 1,
                                //         "last_page_url": "https://mindplay.loc/ajax/deposit/paginate/deposit?page=1",
                                //         "next_page_url": null,
                                //         "path": "https://mindplay.loc/ajax/deposit/paginate/deposit",
                                //         "per_page": 24,
                                //         "prev_page_url": null,
                                //         "to": 6,
                                //         "total": 6
                                //
                                //     },
                                //     "status": 200
                                // };
                                this.submitDisabled = false;
                                this.$emit('updateTransactions', response.data);
                                this.$emit("update:sorting_modal", false);

                                // this.$emit('update:sorting', false);
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },

            cancelFilters: function () {
                this.$emit('update:histories', null);

                let response = {
                    status: null,
                    income: null,
                    wallet: null,
                    date_from: null,
                    date_to: null,
                    payment: null,
                    payment_item: null,
                };
                response.search = this.search ? this.search : null;
                let type;
                if(this.currentTab=='deposit') {
                    type = 1;
                } else if (this.currentTab=='withdraw') {
                    type = 2;
                } else if (this.currentTab=='transfer') {
                    type = 8;
                } else {
                    type = null;
                }
                response.type = type;
                this.$emit('update:sorting_balance', response);
                this.submitDisableded = true;
                // this.$emit('updateParams', response);
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction/history", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        // this.search = null;
                        // this.type = null;
                        // this.income = [];
                        // this.wallet = [];
                        // this.date_from = null;
                        // this.date_to = null;
                        // this.inputDateFrom = null;
                        // this.inputDateTo = null;
                        this.submitDisableded = false;
                        this.$emit('updateTransactions', response.data);
                        // this.$emit('update:sorting', false);
                        // this.$emit('update:search', null);
                        this.$emit("update:sorting_modal", false);
                        this.$emit('update:filter', []);
                    })
                    .catch((err) => {
                        this.submitDisableded = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
            },
            changeCalendar: function () {
                this.date_menu = false;
                this.date = false;
                this.inputDateFrom = this.computedDate;
            },
            changeCalendarTo: function () {
                this.date_menuTo = false;
                this.dateTo = false;
                this.inputDateTo = this.computedDateTo;
            },
            changeInput: function (value) {
                if (value) {
                    this.errorDate = false;
                }
            },
            updateDate: function (value) {
                this.errorDate = false;
                if (this.validationDate()) {
                    let dateParts = value.split(".");
                    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                    this.sorting.date_from = moment(new Date(dateObject)).format('YYYY-MM-DD');
                } else if (this.inputDateFrom == '') {
                    this.sorting.date_from = moment(new Date()).format('YYYY-MM-DD');
                    this.errorDate = false;
                } else {

                    this.errorDate = true
                }
            },
            updateDateTo: function (value) {
                this.errorDate = false;
                if (this.validationDate()) {
                    let dateParts = value.split(".");
                    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                    this.sorting.date_to = moment(new Date(dateObject)).format('YYYY-MM-DD');
                } else if (this.inputDateTo == '') {
                    this.sorting.date_to = moment(new Date()).format('YYYY-MM-DD');
                    this.errorDate = false;
                } else {

                    this.errorDate = true
                }
            },
            validationDate: function () {
                let date = this.inputDateFrom;
                let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
                // pattern.test(date);
                if (date) {
                    let year = (+date.split('.')[2])
                    let minData = (new Date().getFullYear() - 100);
                    let maxDate = (new Date().getFullYear() - 18);
                    let check =  (year >= minData && year <= maxDate) ? true : false;

                    if (check) {
                        this.majority = true
                    } else {
                        this.majority = false
                    }

                    return (pattern.test(date)) && check;
                }
            },
            validationDateTo: function () {
                let date = this.inputDateTo;
                let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
                // pattern.test(date);
                if (date) {
                    let year = (+date.split('.')[2])
                    let minData = (new Date().getFullYear() - 100);
                    let maxDate = (new Date().getFullYear() - 18);
                    let check =  (year >= minData && year <= maxDate) ? true : false;

                    if (check) {
                        this.majority = true
                    } else {
                        this.majority = false
                    }

                    return (pattern.test(date)) && check;
                }
            },
            customFilter (item, queryText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();
                return textOne.indexOf(searchText) > -1
            },
        },
        components: {
            // InputLabelTextDefault,
            DropdownDefault
        },
        directives: {mask}
    };
</script>


<style lang="scss">

    .dark {
        .balance-sorting {
            background: #171827;

            .balance-sorting-head {
                background: #171827;
                border-bottom: 1px solid #2B2B2B;

                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                h5 {
                    color: #fff;
                }
            }

            .balance-sorting-body {
                background: #171827;
            }

            .map {
                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }
            }

            .betting {

                p {
                    color: #96AFD1;
                }

                &__head {

                }
            }
        }

        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
            background: #181818;
            border-color: transparent;
            color: #fff;
            position: relative;

            .v-icon.v-icon {
                font-size: 0;
            }

            &:hover {
                border: 1px solid #26a9f4;
            }
        }
    }

    .balance-sorting {
        /*position: fixed;*/
        /*top: 87px;*/
        /*right: 0;*/
        max-width: 350px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;


        .balance-sorting-head {
            padding: 20px;
            display: flex;
            align-items: center;
            //box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
            border-radius: 8px 8px 0px 0px;
            border-bottom: 2px solid #ECF4FF;
            background: #fff;

            &__icon {
                margin-right: 17px;
                display: flex;
                align-items: center;
            }

            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }

        .search {
            display: flex;
            align-items: center;
            margin-bottom: 0px;
            padding: 10px 0 15px;
            box-shadow: 0px 2px 0px #F2F3FB;

            &__input {
                width: 100%;
            }

            &__icon {
                margin: 17px 14px 0 20px;
            }
        }

        .balance-sorting-body {
            padding: 20px 20px 20px;
            background-color: #fff;
            border-radius: 0 0 8px 8px;
        }

        .map {
            display: flex;
            align-items: center;
            margin:0 0 26px 0;
            position: relative;

            &__icon {
                margin: 0 18px 2px 0;
            }

            &__input {
                width: 100%;
                position: relative;

                p {
                    position: absolute;
                    top: -7px;
                    left: 0;
                    z-index: 10;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #6886AF;
                }
            }
        }

        /*.region {*/
        /*  margin-bottom: 20px;*/
        /*  p {*/
        /*    font-weight: 400;*/
        /*    font-size: 14px;*/
        /*    line-height: 19px;*/
        /*    margin-bottom: 10px;*/
        /*    color: #002b50;*/
        /*  }*/
        /*}*/
        .region {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            position: relative;

            &__icon {
                margin: 5px 16px 0 0;
            }

            &__input {
                width: 100%;
            }
            .v-input .v-label {
                font-size: 16px;
            }
        }

        .age {
            margin-bottom: 20px;

            &__icon {
                margin: 5px 16px 0 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            &__inputs {
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                margin-left: 36px;
            }
        }

        .gender {
            margin-bottom: 0;

            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
            }

            &__icon {
                margin: 7px 16px 0 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            .wrap-checkbox {
                margin-left: 36px;

                .v-input {
                    margin-right: 50px;
                }
            }
        }

        .status.betting__head {
            margin-bottom: -8px;
        }

        .status {
            .v-input {
                margin-right: 0 !important;
            }
        }

        .status__input {
            margin-bottom: 20px;
            margin-left: 36px;
        }
        .payment__input {
            margin-left: 35px;
        }

        .betting {
            margin-bottom: 5px;

            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 6px;
            }

            &__icon {
                margin: 0px 16px 0 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }
            .wrap-radio {
                margin-left: 35px;
            }

            .v-input {
                margin-right: 25px;
            }
        }

        .v-label {
            font-size: 14px;
        }

        .v-input--selection-controls {
            margin-top: 0;
            padding-top: 0;
        }

        .v-input--radio-group.v-input--radio-group--row .v-radio {
            margin-right: 0;
            margin-bottom: 10px;

            &:last-child {
                margin-right: 4px;
            }
        }

        .wrap-date {
            display: flex;
            justify-content: space-between;
            margin-left: 35px;
            margin-top: -4px;

            .v-text-field .v-label--active {
                transform: translateY(-15px) scale(.75)
            }

        }
        .block-birthday {
            max-width: 180px;
            width: 100%;
            z-index: 1;
            position: relative;
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 30px;
            }

            .calendar {
                color: #96AFD1;
                position: absolute;
                z-index: 100;
                top: 5px;
                right: 0;
                cursor: pointer;

                &:hover {
                    transform: scale(1.1);
                    color: #26A9F4;
                }
            }
            .information {
                position: absolute;
                bottom: -18px;
                left: 0;
                font-size: 11px;
                color: #6886af;
            }
        }

        .wrap-btn {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .v-btn {
                width: 48%;
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 100px;
                    width: 48%;
                    padding: 0;

                }
            }

            .btn-secondary {
                margin-right: 15px;
            }

        }
    }

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
        background: #FFFFFF;
        /*height: 64px;*/
        border: 1px solid #EBEBEB;
        box-sizing: border-box;
        border-radius: 8px;
        box-shadow: none!important;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #002B50;
        position: relative;

        .v-icon.v-icon {
            font-size: 0;
        }
    }
    .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
        background: transparent;
    }

    .chevron-icon {
        position: absolute;
        right: 0;
        top: 16px;
        transition-duration: .3s;
    }

    .v-select--is-menu-active {
        .chevron-icon {
            transform: rotate(180deg);
            transition-duration: .3s;
        }
    }

    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
        margin-bottom: 10px;
    }

    .theme--light.v-messages {
        display: none;
    }

</style>