<template>
    <v-dialog v-model="dialog" max-width="320px">

        <div class="wrap-sorting-game">
            <div class="wrap-sorting-head">
                <div class="wrap-sorting-head__icon">
                    <img src="/dist/images/frontend/filter-icon.svg" alt />
                </div>
                <h5>{{__('Filters')}}</h5>
            </div>

            <div class="wrap-sorting-game__body">
                <div class="region">
                    <div class="region__icon">
                        <img src="/dist/images/frontend/location-icon.svg" alt />
                    </div>
                    <div class="region__input">
                        <DropdownCountry
                                :label="__('Country')"
                                v-bind:value.sync="sorting.country"
                                @change="updateValidate('country')"
                                v-bind:errors="formValidate.country"
                                @updateCountry="updateCountry"
                        ></DropdownCountry>
                    </div>
                </div>

                <div class="male">
                    <div class="male__head">
                        <div class="male__icon">
                            <img src="/dist/images/frontend/coin-icon.svg" alt />
                        </div>
                        <p>{{__('Coin')}}</p>
                    </div>
                    <div class="wrap-checkbox d-flex">
<!--                        <v-checkbox v-model="sorting.wallet" color="blue" value="1" :label="__('Gold')"></v-checkbox>-->
                        <v-checkbox v-model="sorting.wallet" color="blue" value="2" :label="__('Silver')"></v-checkbox>
                    </div>
                </div>

                <div class="age">
                    <div class="d-flex align-center">
                        <div class="age__icon">
                            <img src="/dist/images/frontend/amount-icon.svg" alt />
                        </div>
                        <p>{{__('Amount')}}</p>
                    </div>

                    <div class="age__inputs">
                        <InputLabelTextDefault
                                :label="__('From')"
                                color="main"
                                class="mr-8"
                                v-model="sorting.amount_from"
                                type="number"
                                v-bind:errors="formValidate.amount_from"
                        ></InputLabelTextDefault>
                        <InputLabelTextDefault
                                :label="__('To')"
                                color="main"
                                v-model="sorting.amount_to"
                                type="number"
                                v-bind:errors="formValidate.amount_to"
                        ></InputLabelTextDefault>
                    </div>
                </div>

                <div class="age">
                    <div class="d-flex align-center">
                        <div class="age__icon">
                            <img src="/dist/images/frontend/rating-icon.svg" alt />
                        </div>
                        <p>{{__('Rating')}}</p>
                    </div>

                    <div class="age__inputs">
                        <InputLabelTextDefault
                                :label="__('From')"
                                color="main"
                                class="mr-8"
                                v-model="sorting.rating_from"
                                type="number"
                                v-bind:errors="formValidate.rating_from"
                        ></InputLabelTextDefault>
                        <InputLabelTextDefault
                                :label="__('To')"
                                color="main"
                                v-model="sorting.rating_to"
                                type="number"
                                v-bind:errors="formValidate.rating_to"
                        ></InputLabelTextDefault>
                    </div>
                </div>

                <div v-if="game.attributes && game.attributes.length" class="block-attributes">
                    <div class="d-flex align-center">
                        <div class="age__icon">
                            <img width="16px" style="margin-left: 2px;" src="/dist/images/frontend/attributes-icon.svg" alt />
                        </div>
                        <p>{{__('Attributes')}}</p>
                    </div>

                    <ItemSelectAttributes v-for="(item, key) in game.attributes" @updateAttribute="updateAttribute" :sorting="sorting" :empty="empty" v-bind:item="item" :key="key"></ItemSelectAttributes>
                </div>

                <div class="wrap-btn">
                    <!--        <v-btn-->
                    <!--            v-ripple="{ class:'secondary&#45;&#45;text'}"-->
                    <!--            text-->
                    <!--            class="btn-secondary"-->
                    <!--        >Cancel</v-btn>-->
                    <div style="width: 48%">
                        <BaseButton
                                :loading="submitDisabled"
                                class_btn="btn-liteGrey"
                                v-on:click="cancelFilters"
                        >
                            <span>{{__('Clear')}}</span>
                        </BaseButton>
                    </div>

                    <div style="width: 48%">
                        <!--        <v-btn-->
                        <!--                text-->
                        <!--                class="btn-cab white&#45;&#45;text"-->
                        <!--                v-on:click="onSubmit"-->
                        <!--                :loading="submitDisabled"-->
                        <!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
                        <!--        >Apply</v-btn>-->

                        <BaseButton
                                v-on:click="onSubmit"
                                :loading="submitDisabled"

                        >
                            <span>{{__('Apply')}}</span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>

</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownCountry from "../../modules/DropdownCountry";
    // import DropdownDefault from "../../modules/DropdownDefault";
    import { numeric } from "vuelidate/lib/validators";
    import ItemSelectAttributes from "../Developers/ItemSelectAttributes";

    export default {
        name: "FriendsRequests",
        data() {
            return {
                empty: true,
                dialog: true,
                scroll: false,
                submitDisabled: false,
                sortFriendList: [
                    {
                        title: this.__("By popularity"),
                        value: 1,
                    },
                    {
                        title: this.__("Date added"),
                        value: 2,
                    },
                    {
                        title: this.__("By rating"),
                        value: 3,
                    },
                ],
                sorting: {
                    amount_from: this.sorting_list.amount_from,
                    amount_to: this.sorting_list.amount_to,
                    wallet: this.sorting_list.wallet,
                    rating_from: this.sorting_list.rating_from,
                    rating_to: this.sorting_list.rating_to,
                    country: this.sorting_list.country,
                    attr: this.sorting_list.attr
                },
                formValidate: {
                    amount_from: null,
                    amount_to: null,
                    wallet: null,
                    rating_from: null,
                    rating_to: null,
                    country: null,
                },
                rules: this.validateMessage,
            };
        },
        validations: {
            sorting: {
                name: {

                },
                // location: {
                //   maxLength: maxLength(50),
                //   minLength: minLength(3),
                // },
                age_from: {
                    numeric,
                },
                age_to: {
                    numeric,
                },
                gender: {
                    numeric,
                },
            },
        },
        props: {
            sorting_list: {
                type: Object,
            },
            game: {
                type: Object
            },
            game_list_sorting: {
                type: Boolean,
            },
            filter: {
                type: Array,
            }
        },
        computed: {
            currentTab: function () {
                return this.$route.params.current
                    ? this.$route.params.current
                    : "friends";
            },
        },

        watch: {
            dialog() {
                this.$emit("update:game_list_sorting", false);
            },
        },

        created() {
            this.$addListener(window,'scroll', () => {
                if (document.documentElement.scrollTop > 60) {
                    this.scroll = true;
                } else {
                    this.scroll = false;
                }
            });
        },
        methods: {
            updateAttribute(option) {
                let attributes= this.$cleanObject(this.sorting.attr);

                let index= this.$filterIndexObject(attributes, option.key, 'key');

                if(index >= 0) {
                    this.sorting.attr[index]= option;
                } else {
                    this.sorting.attr.push(option);
                }
            },

            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.sorting[type],
                    type
                );
            },
            /*onSubmit() {
                let valid = this.$v.sorting;

                if (valid.$invalid) {
                    let massages = {
                        amount_from: [],
                        amount_to: [],
                        wallet: [],
                        rating_from: [],
                        rating_to: [],
                        country: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    let sorting= this.sorting;
                    let count = [];
                    Object.keys(this.sorting).forEach((key) => {
                        if(this.sorting[key]) {
                            count.push(this.sorting[key]);
                        }
                    });
                    this.$emit('update:filter', count);
                    this.submitDisabled = true;
                    // this.$emit('update:sorting_user', sorting);
                    let response = sorting;

                    let wallet = this.sorting.wallet;

                    if (wallet.length > 1) {
                        wallet = null;
                    } else {
                        wallet = Number(wallet[0]);
                    }

                    response.wallet = wallet;
                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/games/match/list",response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                // this.$emit('updatePeoples', response.data);
                                // this.$emit("update:game_list_sorting", false);
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },*/

            onSubmit() {
                let attr = [];
                // const item = this.sorting.attr.findIndex(function(i) {
                //    return i.option == null;
                // });
                for (let item of this.sorting.attr) {
                    if (item.option) {
                        attr.push(item);
                    }
                }

                this.sorting.attr = attr;

                let count = [];
                Object.keys(this.sorting).forEach((key) => {
                    if(this.sorting[key] && this.sorting[key].length > 0) {
                        count.push(this.sorting[key]);
                    }
                });
                this.$emit('update:filter', count);
                this.$emit('update:sorting_list', this.sorting);
                this.$emit('sortingList');
                this.$emit("update:game_list_sorting", false);
            },

            cancelFilters() {
                this.sorting.amount_from = null;
                this.sorting.amount_to = null;
                this.sorting.wallet = null;
                this.sorting.rating_from = null;
                this.sorting.rating_to = null;
                this.sorting.country = null;
                this.sorting.attr = [];

                this.$emit('update:filter', []);
                this.$emit('update:sorting_list', this.sorting);
                this.$emit('sortingList');
                this.$emit("update:game_list_sorting", false);
            },

            updateCountry(val) {
                this.sorting.country = val;
            },
        },
        components: {
            InputLabelTextDefault,
            DropdownCountry,
            // DropdownDefault,
            ItemSelectAttributes
        },
    };
</script>


<style lang="scss">

    .dark {

        .wrap-sorting-game {

            .wrap-sorting-head {
                background: #171827;
                border-bottom: 1px solid #2B2B2B;

                h5 {
                    color: #fff;
                }

                img {
                    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                }
            }

            &__body {
                background: #171827;

                .block-attributes {
                    p {
                        color: #fff;
                    }
                }
            }

            .region {
                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }
            }

            .male {

                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                p {
                    color: #fff;
                }

                .wrap-checkbox {
                    .v-label {
                        color: #96AFD1!important;
                    }
                }
            }

            .age {
                &__icon {
                    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                }

                p {
                    color: #fff;
                }
            }
        }
    }

    .wrap-sorting-game {
        /*position: fixed;*/
        /*top: 82px;*/
        /*right: auto;*/
        max-width: 320px;
        width: 100%;

        &--fixed {

        }

        .wrap-sorting-head {
            padding: 21px 20px 20px;
            display: flex;
            align-items: center;
            border-radius: 8px 8px 0 0;
            /*border-bottom: 2px solid #ECF4FF;*/
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
            background: #fff;
            position: relative;

            &__icon {
                margin-right: 17px;
                display: flex;
                align-items: center;
            }

            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }

        .search {
            display: flex;
            align-items: center;
            margin-bottom: 26px;
            padding: 10px 0 15px;
            box-shadow: 0px 2px 0px #F2F3FB;

            &__input {
                width: 100%;
            }

            &__icon {
                margin: 17px 14px 0 20px;
            }
        }

        &__body {
            border-radius: 0 0 8px 8px;
            padding: 20px 20px 22px;
            background: #fff;

            .block-attributes {
                margin-bottom: 30px;

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #6886AF;
                    margin-bottom: 0;
                }
            }
        }

        .region {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            position: relative;

            &__icon {
                margin: 0 16px 2px 0;
            }

            &__input {
                width: 100%;
            }
            .v-input .v-label {
                font-size: 16px;
            }
        }

        .age {
            margin-bottom: 20px;

            &__icon {
                margin: 0 16px 2px 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            &__inputs {
                margin-top: 0;
                display: flex;
                justify-content: space-between;
                margin-left: 36px;
            }
        }

        .male {
            margin-bottom: 12px;

            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 0;
            }

            &__icon {
                margin: 0 16px 3px 0;
            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            .wrap-checkbox {
                margin-left: 36px;

                .v-input {
                    margin-right: 50px;
                }

                .v-label {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #6886AF!important;
                }
            }
        }
        .btn-cab {
            margin: 0 auto;
            display: block;

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 100px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
            }
        }

        .wrap-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .v-btn {
                width: 48%;
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 100px;
                    width: 48%;
                    padding: 0;

                }
            }

            .btn-secondary {
                margin-right: 15px;
            }

        }
    }

    @media screen and (max-width: 1350px) {
        .wrap-sorting-peoples {
            max-width: 280px;

            &--fixed {

            }
        }
    }

    @media screen and (max-width: 1200px) {
        .wrap-sorting-peoples {
            &--fixed {
                position: relative;
                top: 0;
            }
        }
    }

    @media screen and (max-width: 920px) {

        .wrap-sorting {
            .sort,
            .region,
            .age {
                margin-bottom: 10px;

                p {
                    margin-bottom: 5px;
                }
            }

            .male {
                p {
                    margin-bottom: 5px;
                }
            }
        }
    }

    @media screen and (max-width: 525px) {

        .wrap-sorting {
            .sort,
            .region,
            .age {
                margin-bottom: 6px;

                /*p {*/
                /*    margin-bottom: 1px;*/
                /*    font-size: 10px;*/
                /*}*/
            }
            .male {
                /*p {*/
                /*    margin-bottom: 1px;*/
                /*    font-size: 10px;*/
                /*}*/
            }

            .btn-cab {
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    min-width: 64px;
                    width: 100px;
                    padding: 0;
                    margin: 0 auto;
                    font-size: 10px;
                }
            }
            v-input--radio-group.v-input--radio-group--row .v-radio {
                margin-right: 0;
                margin-bottom: 0px;
            }
            .v-label {
                font-size: 12px;
            }
        }
    }
</style>