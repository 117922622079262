<template>
    <v-dialog v-model="dialog" max-width="400px">
        <div v-if="!successModal" class="wrap-modal-security">
            <div class="btn-close">
                <v-btn text icon class="icon" @click="dialog = false">
                    <img src="/dist/images/frontend/close.svg" alt="/" />
                </v-btn>
            </div>
            <div>
                <h4 v-if="auth.google_two_step == 1" class="title">2FA</h4>
                <h4 v-else class="title">{{__('Email')}}</h4>

                <p v-if="auth.google_two_step == 1">{{__('Additional security from intruders is enabled on your account. Enter below the one-time code from the application')}}</p>
                <p v-else>{{__('We have sent you an email with a confirmation code. Enter below one-time code with email')}}</p>
            </div>
            <div>
                <div>
                    <InputLabelTextDefault
                            color="main"
                            :label="__((auth.google_two_step == 1) ? 'OTP Code' :'Email Code')"
                            v-model="authenticator"
                            v-bind:errors="formValidate.authenticator"
                            @change="updateValidate('authenticator')">
                    </InputLabelTextDefault>
                </div>
                <div class="mt-6">

                        <BaseButton
                                :loading="submitDisabled"
                                @click="addWallet"
                        >
                            <span style="color: #fff">
                                <img src="/dist/images/frontend/sign.svg" alt />
                                {{__('Confirm')}}
                            </span>

                        </BaseButton>

                    <span v-if="auth.google_two_step != 1 && dateUpdate=== false" class="mt-3 d-block">{{__('loading...')}}</span>
                    <a v-else-if="auth.google_two_step != 1 && !dateUpdate" class="mt-3 d-block" @click="sendCode">{{__('Resend code')}}</a>
                    <p v-else-if="dateUpdate" class="mt-3 mb-0 d-block text-center">{{__('Next resend: '+dateUpdate)}}</p>
                </div>
            </div>
        </div>
        <div v-else class="wrap-modal-security">
            <div class="block-status">
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.9998 0.148193C9.65573 0.148193 7.3643 0.843292 5.41527 2.14559C3.46624 3.44789 1.94716 5.2989 1.05012 7.46454C0.153084 9.63018 -0.0816218 12.0132 0.375685 14.3122C0.832991 16.6113 1.96177 18.7231 3.61928 20.3806C5.27679 22.0381 7.38859 23.1669 9.68763 23.6242C11.9867 24.0815 14.3697 23.8468 16.5353 22.9497C18.701 22.0527 20.552 20.5336 21.8543 18.5846C23.1566 16.6356 23.8517 14.3441 23.8517 12C23.8517 8.85674 22.603 5.84218 20.3803 3.61952C18.1577 1.39687 15.1431 0.148193 11.9998 0.148193ZM11.9998 22.3704C9.94874 22.3704 7.94374 21.7622 6.23834 20.6227C4.53294 19.4832 3.20374 17.8636 2.41883 15.9686C1.63392 14.0737 1.42856 11.9885 1.8287 9.97689C2.22884 7.96523 3.21652 6.11741 4.66685 4.66709C6.11717 3.21676 7.96499 2.22908 9.97665 1.82894C11.9883 1.4288 14.0734 1.63416 15.9684 2.41907C17.8633 3.20398 19.4829 4.53318 20.6225 6.23858C21.762 7.94398 22.3702 9.94898 22.3702 12C22.3702 14.7504 21.2776 17.3882 19.3328 19.333C17.3879 21.2778 14.7502 22.3704 11.9998 22.3704Z" fill="#24E07A"/>
                    <path d="M19.4072 7.62959C19.2685 7.49162 19.0807 7.41418 18.885 7.41418C18.6893 7.41418 18.5016 7.49162 18.3628 7.62959L10.1406 15.8148L5.69612 11.3703C5.56057 11.224 5.37242 11.1375 5.17308 11.1298C4.97373 11.1222 4.77952 11.194 4.63316 11.3296C4.4868 11.4651 4.40028 11.6533 4.39264 11.8526C4.385 12.052 4.45686 12.2462 4.59242 12.3926L10.1406 17.9259L19.4072 8.68144C19.4767 8.61258 19.5318 8.53065 19.5694 8.44038C19.607 8.35012 19.6263 8.2533 19.6263 8.15551C19.6263 8.05773 19.607 7.96091 19.5694 7.87064C19.5318 7.78038 19.4767 7.69845 19.4072 7.62959Z" fill="#24E07A"/>
                </svg>
            </div>
            <p class="status-text">{{__('Wallet added successfully')}}</p>
        </div>
    </v-dialog>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {integer, maxLength, minLength, required} from "vuelidate/lib/validators";
    export default {
        data() {
            return {
                submitDisabled:false,
                dateUpdate: null,
                dialog: true,
                authenticator: null,
                formValidate: {
                    authenticator: null,
                },
                successModal: false,
            }
        },
        validations: {
            authenticator: {
                required,
                maxLength: maxLength(8),
                minLength: minLength(4),
                integer
            },
        },
        props: {
            modal_security: {
                type: [Boolean, Object]
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
            authenticator(val) {
                if(!val || val.length == 0) {
                    this.formValidate.authenticator = null;
                }
            }
        },
        methods: {
            cancelModal() {
                this.$emit("update:modal_security", false);
            },
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            sendCode: function() {
                this.dateUpdate = false;
                this.$http.get('ajax/setting/send_code')
                    .then(response=> {
                        return response.json()
                    })
                    .then(response=> {
                        this.$checkAuth(response);
                    }).catch(err => {
                    let response= this.httpHandler(err);
                    if(response.error) {
                        // this.formValidate = response.error.message;
                        this.dateUpdate= response.error.message.time;
                        let refreshIntervalId = setInterval(()=> {
                            if(this.dateUpdate > 0) {
                                this.dateUpdate= this.dateUpdate-1;
                            } else {
                                clearInterval(refreshIntervalId);
                            }
                        }, 1000);
                    }
                });
            },
            addWallet() {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        authenticator:[],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    let response = this.modal_security;
                    response.code = this.authenticator;

                    this.$http.post('ajax/setting/wallet', response)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.$emit("add-wallet");
                                this.authenticator = null;
                                this.successModal = true;
                                setTimeout(() => {
                                    this.$emit("update:modal_security", false);
                                    this.successModal = false;
                                }, 1200)
                            }
                        }).catch(err => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }

            }
        },
        components: {
            InputLabelTextDefault
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-modal-security {
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 15px 20px;
        position: relative;

        .block-status {
            background-color: rgba(36, 224, 122, 0.1);
            border-radius: 50%;
            width: 90px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 16px;
        }

        .status-text {
            color: #24E07A;
            font-size: 16px;
        }

        .btn-close {
            position: absolute;
            top: 5px;
            right: -8px;
        }

        h4 {
            text-align: center;
            font-weight: 400;;
            font-size: 18px;
            line-height: 24px;
            color: #002B50;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        p,
        span {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #96AFD1;
            width: 100%;
        }

        a {
            color: #26A9F4!important;
            text-align: center;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }

        .btn-cab {
            margin: 0 auto;
            display: block;
            width: 100%;

            img {
                margin: 1px 8px 0 0;
            }

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 100px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
            }
        }
    }
</style>