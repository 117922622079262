<template>
    <div style="display: flex; flex-shrink: 0;">
        <div style="width: 100%" v-if="game.type == 1 || game.type == 3">
            <div class="wrap-create-btn" v-if="auth && game.list_auth && game.list_auth.length < 5">
                <BaseButton
                        v-on:click="openModal"
                        class_btn="btn-green"
                >
                    <span>{{__('Create match')}}</span>
                </BaseButton>
            </div>


            <v-tooltip bottom v-else-if="auth">
                <template v-slot:activator="{ on, attrs }">
                    <BaseButton
                            class_btn="btn-liteGrey-game"
                            v-on="on"
                            v-bind="attrs"
                    >
                        <span>{{__('Create match')}}</span>
                    </BaseButton>

                </template>
                <span>{{__('Cant create more than 5 applications')}}</span>
            </v-tooltip>
            <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                    <BaseButton
                            class_btn="btn-green"
                            v-on="on"
                            v-bind="attrs"
                            v-on:click="pushRegister"
                    >
                        <span>{{__('Create match')}}</span>
                    </BaseButton>
                </template>
                <span>{{__('You are not logged in')}}</span>
            </v-tooltip>
        </div>

        <div style="width: 100%" v-else-if="game.type == 2 || game.type == 4">
            <div v-if="(game.type_user == 2 || game.type_user == 3) && game.developer.id == auth.id">
<!--                <div class="wrap-create-btn" v-if="auth && game.list_auth">-->
                <div class="wrap-create-btn" v-if="auth">
                    <BaseButton
                            v-on:click="openModal"
                            class_btn="btn-green"
                    >
                        <span>{{__('Create match')}}</span>
                    </BaseButton>
                </div>

                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <BaseButton
                                class_btn="btn-green"
                                v-on="on"
                                v-bind="attrs"
                                v-on:click="pushRegister"
                        >
                            <span>{{__('Create match')}}</span>
                        </BaseButton>
                    </template>
                    <span>{{__('You are not logged in')}}</span>
                </v-tooltip>
            </div>
            <div v-else-if="game.type_user == 1 || game.type_user == 3">
<!--                <div class="wrap-create-btn" v-if="auth && game.list_auth && game.list_auth.length < 5">-->
                <div class="wrap-create-btn" v-if="auth">
                    <BaseButton
                            class_btn="btn-green"
                            v-on:click="openModal"
                    >
                        <span>{{__('Create match')}}</span>
                    </BaseButton>
                </div>
<!--                <v-tooltip bottom v-else-if="auth">-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <BaseButton-->
<!--                                class_btn="btn-liteGrey-game"-->

<!--                                v-on="on"-->
<!--                                v-bind="attrs"-->
<!--                        >-->
<!--                            <span>{{__('Create match')}}</span>-->
<!--                        </BaseButton>-->
<!--                    </template>-->
<!--                    <span>{{__('Cant create more than 5 applications')}}</span>-->
<!--                </v-tooltip>-->
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <BaseButton
                                class_btn="btn-green"
                                v-on="on"
                                v-bind="attrs"
                                v-on:click="pushRegister"
                        >
                            <span>{{__('Create match')}}</span>
                        </BaseButton>
                    </template>
                    <span>{{__('You are not logged in')}}</span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            game: {
                type: Object,
            },
            list: {
                type: [Object, Array],
            },
            currentTab: {
                type: String
            },
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            openModal() {
                this.$emit('update:create_match_modal', this.game);
                if (this.$gtag) {
                    this.$gtag.event('CreateGame', { 'GameID': this.game.id });
                }
                if (this.$metrika) {
                    this.$metrika.reachGoal('CreateGame');
                }
            },
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');
                if (this.$gtag) {
                    this.$gtag.event('CreateGame', { 'GameID': this.game.id });
                }
                if (this.$metrika) {
                    this.$metrika.reachGoal('CreateGame');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-create-btn {
        position: relative;
        overflow: hidden;
        height: fit-content;
        width: 100%;

        &:before {
            content: '';
            position: absolute;
            z-index: +1;
            left: -10%;
            top: -50px;
            bottom: -50px;
            background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, .8)), to(rgba(255, 255, 255, 0)));
            background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0));
            background: -o-linear-gradient(left, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0));
            background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0));
            width: 30px;
            -webkit-transform: rotate(30deg);
            -ms-transform: rotate(30deg);
            transform: rotate(30deg);
            -webkit-animation: moving 13s infinite;
            animation: moving 13s infinite;
        }
        @keyframes moving {
            100%,
            7% {
                left: 110%
            }
        }
    }
</style>