<template>
  <div>
    <div v-if="tournaments && tournaments.length" class="wrap-user-tournaments">
      <ItemParticipant
        v-for="(item, key) in tournaments"
        :tournament="item.tournament"
        :item.sync="item"
        :position="key+1"
        :key="key"
      >
      </ItemParticipant>
    </div>

    <div v-else class="wrap-user-tournaments block-empty">
      <img src="/dist/images/frontend/icon-championship.svg" alt />

      <p>{{__('No active tournaments')}}</p>

      <div class="wrap-btn">
        <BaseButton :to="{name: 'tournaments', params: {lang: $store.getters.getLang}}">
          <span>{{__('See tournaments')}}</span>
        </BaseButton>
      </div>
    </div>
  </div>

</template>



<script>
  import ItemParticipant from "../Tournaments/ItemParticipant";
export default {
  name: 'ProfileInfoTournaments',
  data() {
    return {

    }
  },
  props: {
    tournaments: {
      type: Array
    }
  },
  components: {
    ItemParticipant
  }
}
</script>

<style lang="scss" scoped>
  .wrap-user-tournaments {
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    margin-bottom: 20px;
    max-height: 275px;
    overflow: auto;
    padding: 20px;

    img {
      height: 20px;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      text-align: center;
      color: #002b50;
      text-transform: capitalize;
    }
  }

  .dark .wrap-user-tournaments {
    background: #171827;

    p {
      color: #fff;
    }
  }

  .wrap-user-tournaments.block-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #96AFD1;
    border-radius: 0 0 4px 4px;
    height: 260px;
    padding: 0;

    svg,
    img {
      margin-bottom: 10px;
      height: 24px;
    }

    p {
      margin-bottom: 20px;
    }

    .wrap-btn {
      width: 130px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 600px){
    .wrap-user-tournaments {
      padding: 10px;
    }
  }
</style>