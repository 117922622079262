<template>
    <div style="padding-bottom: 30px">

        <GameAttributes v-bind:attributes.sync="attributes" @updateAttributes="updateAttributes"></GameAttributes>
        <div  class="wrap-btn">
<!--            <div v-if="JSON.stringify(attributes) != JSON.stringify(developers.attributes)">-->
            <div v-if="!validateAttributes && JSON.stringify(attributes) != JSON.stringify(developers.attributes)">
              <BaseButton
                    :loading="submitDisabled"
                    v-on:click="onSubmit"
              >
                  <span>{{__('Save changes')}}</span>
              </BaseButton>
            </div>

            <p v-else-if="submitSuccess">
                  <i class="fas fa-check"></i> {{__('Saved!')}}
            </p>

            <div v-else-if="validateAttributes && JSON.stringify(attributes) != (JSON.stringify(developers.attributes) && JSON.stringify(arr))">
                <v-btn text class="border-btn btn-disabled" color="default" disabled>{{__('No validate')}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import GameAttributes from "./GameAttributes";
import _ from 'lodash';

export default {
    name: "GameInfoAttributes",
    data() {
        return {

            pathDevelopers: "ajax/developers/" + this.$route.params.id,
            settingsValidate: {},
            setting: this.developers.setting,
            attributes: this.developers.attributes,
            submitDisabled: false,
            submitSuccess: false,
            validateSubmit: (this.developers.setting.length) ? false : true,
            validateAttributes: false,
            validateSettings: false,
            arr: null
        };
    },
    props: {
        developers: {
            type: Object,
        },
    },

    computed: {

    },

    created () {
      this.arr = [
          {
              "name": null,
              "key": null,
              "options": [
                  {
                      // "img": null,
                      "name": null,
                      "key": null,
                      // "description": null,
                      "invalid": true
                  },
                  {
                      // "img": null,
                      "name": null,
                      "key": null,
                      // "description": null,
                      "invalid": true
                  }
              ]
          }
      ]
    },

    methods: {
        updateAttributes: function(attributes) {
            this.submitSuccess= false;
            this.attributes= attributes;

            let invalid= attributes.filter(item => {
                if(_.filter(attributes, {'key': item.key}).length > 1 || item.invalid) {
                    return true;
                } else {
                    let valid_optrion= item.options.filter(option=> {
                        return (_.filter(item.options, {'key': option.key}).length > 1 || option.invalid);
                    });

                    return (valid_optrion.length) ? true : false;
                }
            });

            this.validateAttributes= (invalid.length) ? true : false;
        },

        onSubmit: function () {
            this.submitDisabled = true;
            let error = false;
            let response = {};
            let formValidate = this.formValidate;
            // let settings = this.setting;

            if (error) {
                this.submitDisabled = false;
                this.formValidate = formValidate;
                return false;
            }
            response.attributes= this.attributes;

            this.$http.post (
                "ajax/developers/" + this.$route.params.id + "/attributes", response)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        this.submitDisabled = false;
                        this.submitSuccess = true;
                        let developers = this.developers;
                        developers["attributes"] = this.attributes;
                        this.$emit("update:developers", developers);
                    }
                })
                .catch((err) => {
                    this.submitDisabled = false;
                    let response = this.httpHandler(err);
                    if (response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            setTimeout(()=> {
                this.submitSuccess= null;
            }, 2000);
        },
    },
    components: {
        GameAttributes,
    },
};
</script>

<style lang="scss" scoped>

    .dark {
        .theme--light.v-btn.v-btn--disabled {
            color: rgba(255, 255, 255, 0.3)!important;
        }
    }

    .wrap-block-key {
        display: flex;
        align-items: flex-start;
        margin-right: 45px;
        margin-bottom: 25px;
        position: relative;

        span {
            margin: 0 28px 0 0;

            img {
                height: 20px;
            }
        }
    }

    .v-btn.refresh-btn {
        outline: none;
        position: absolute;
        right: -50px;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    .block-create {
        display: flex;
        align-items: flex-start;
        margin-top: 6px;

        &__title {
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: 0;
        }

        span {
            margin: 0 28px 0 0;

            img {
                height: 20px;
            }
        }

        .v-input {
            margin-right: 50px;
        }
    }

    .v-btn {
        margin: 0;

        &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 64px;
            width: 124px;
            padding: 0;
        }
    }
    .wrap-btn {
        position: relative;
        display: flex;
        justify-content: center;
        margin: 40px 0 0;
        min-height: 40px;

        p {
            position: absolute;
            left: 45%;
            top: 10px;
            border-color: transparent;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #00C443;
            margin: 0;

            i {
                font-size: 12px;
                margin: 0 6px 0 20px;
            }
        }
    }
    .btn-disabled {
        display: block;
        margin: 0 auto;
    }
</style>
