<template>
    <div>
        <v-dialog v-model="dialog" max-width="500px">
            <div class="rivals-info">
                <div class="rivals-info__head">
                    <div class="d-flex align-center">
                        <p>{{__('All Rivals')}}</p>
                    </div>
                    <v-btn text icon class="icon" @click="dialog = false">
                        <img src="/dist/images/frontend/close.svg" alt="/" />
                    </v-btn>
                </div>
                <div class="rivals-info__body" v-if="rivals">
                    <RivalsMultiplayerItem v-for="(item, key) in rivals" :item="item" :key="key" ></RivalsMultiplayerItem>
                </div>
                <div v-else class="transaction-info__body">{{__('loading')}}</div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import RivalsMultiplayerItem from "./RivalsMultiplayerItem";
    export default {
        data () {
            return {
                dialog: true
            }
        },
        props: {
            modal_rivals: {
                type: Boolean,
            },
            rivals: {
                type: Array
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:modal_rivals", false);
            },
        },
        components: {
            RivalsMultiplayerItem
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .rivals-info {
            &__head {
                background: #171827;
                border-bottom: 2px solid #2B2B2B;

                p {
                    color: #fff;
                }
            }

            &__body {
                background: #171827;
            }
        }
    }
    .rivals-info {
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #F0F6FE;
            border-radius: 8px 8px 0px 0px;
            padding: 20px 24px;
            position: relative;


            svg {
                margin-right: 12px;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #002b50;
                margin-bottom: 0;
            }

            .icon {
                position: absolute;
                top: 8px;
                right: -6px;
                outline: none;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__body {
            max-height: 335px;
            padding: 10px 0 0;
            background-color: #ffffff;
            border-radius: 0 0 8px 8px;
            overflow-y: auto;


            p {
                text-align: left;
                font-size: 14px;
                color: #002B50;
                margin-bottom: 0;
            }

            img {
                max-width: 500px;
            }
        }
    }
</style>