<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:window_result', null)">
        <div class="modal-result-tournament">
            <v-btn text icon class="icon ma-0" @click="cancelModal">
                <img src="/dist/images/frontend/close.svg" alt="/" />
            </v-btn>

            <div class="modal-result-tournament__body">
                <div v-if="typeof window_result.game_logo == 'object'" class="img" :style="{'backgroundImage': 'url(' +window_result.game_logo[300] + ')'}"></div>
                <div v-else class="img" :style="{'backgroundImage': 'url(' +window_result.game_logo + ')'}"></div>

                <div class="block-title">
                    <p>{{__('Tournament result')}}</p>
                </div>

                <div class="block-cost">
                    <span class="block-cost__title">{{__('Your winnings amounted to')}}</span>
                    <div  class="block-cost__desc">
                        <img v-if="window_result.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />

                        <span>{{primNum(window_result.sum_win, 2)}}</span>
                    </div>

                </div>
            </div>
            <div class="wrap-btn">
                <BaseButton
                    class_btn="btn-guest"
                    :to="item.solo ? {name: 'tournament_solo', params: {'id': window_result.tournament_id, lang: $store.getters.getLang}} : {name: 'tournament', params: {'id': window_result.tournament_id, lang: $store.getters.getLang}}"
                    :loading="submitDisabled"
                >
                    <span>{{__('View more details')}}</span>
                </BaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "ModalResultTournament",
        data() {
            return {
                modal: true,
            };
        },
        props: {
            window_result: {
                type: [Boolean, String, Object]
            },

        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {
            lang() {
                return this.$store.getters.getLanguage;
            },
        },

        methods: {
            cancelModal() {
                this.$emit("update:window_result", null);
            },
        },
        components: {},
    };
</script>

<style lang='scss' scoped>

    .modal-result-tournament {
        text-align: center;
        padding: 40px 60px 45px;
        background: #171827;
        box-shadow: 0 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;

        .icon {
            position: absolute;
            top: 4px;
            right: 4px;
            outline: none;

            img {
                height: 12px;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            .img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                background-color: #181818;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                flex-shrink: 0;
                margin-bottom: 20px;
            }

            .block-title {
                border-radius: 8px;
                background: #181818;
                height: 36px;
                margin-bottom: 14px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    color: #01DF72;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: uppercase;
                    margin: 0;
                }
            }

            .block-cost {
                display: flex;
                align-items: center;
                justify-content: center;

                &__title {
                    color: #FFF;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                    letter-spacing: 0.014px;
                    margin: 0 10px 0 0;
                }

                &__desc {
                    display: flex;
                    align-items: center;
                    img {
                        height: 18px;
                        margin: 0 6px 0 0;
                    }

                    span {
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 14px;
                    }
                }
            }
        }

        .wrap-btn {
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 500px) {
        .modal-result-tournament {
            padding: 35px 20px 25px;
        }
    }
</style>