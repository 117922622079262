<template>
    <div class="history-solo">
        <div class="name" v-if="matches.opponent_info !== null && typeof matches.opponent_info === 'object'">
            <span class="mob">{{__("Opponent")}}</span>
            <div class="name-block">
                <div class="block-img" >
                    <router-link :to="{name: 'profile', params: {id: matches.opponent_info.public_id, lang: $store.getters.getLang}}" >
                        <div class="wrap-img">
                            <img v-if="matches.opponent_info.photo && typeof matches.opponent_info.photo == 'object'" :src="matches.opponent_info.photo[40]"/>
                            <img v-else-if="matches.opponent_info.photo" :src="matches.opponent_info.photo"/>
                            <span v-else class="photo" :style="{'background': matches.opponent_info.bg_color}">{{matches.opponent_info.name.substr(0,1)}}</span>
                        </div>
                        <!--                <div class="online"></div>-->
                    </router-link>
                </div>
                <router-link :to="{ name: 'profile', params: { id: matches.opponent_info.public_id, lang: $store.getters.getLang } }">
                    <p :class="{ 'user-pro': matches.opponent_info && matches.opponent_info.pro }">{{ matches.opponent_info ? matches.opponent_info.name : 'Не определен' }}</p>
                </router-link>
            </div>
        </div>
        <div class="name name-none" v-else>
            <span class="mob">{{__("Opponent")}}</span>
            <div class="name-block">
                <div class="wrap-img">
                    <img src="/dist/images/frontend/target-svg.svg" alt="">
                </div>
                <p>{{__("Indefined")}}</p>
            </div>
        </div>
        <div class="date">
            <span class="mob">{{__("Date")}}</span>
            <p>{{matches.created_at}}</p>
        </div>
        <div class="my-score">
            <span class="mob">{{__("My result")}}</span>
            <p>{{matches.score}}</p>
        </div>
        <div class="opponent-score">
            <span class="mob">{{__("The result of the opponent")}}</span>
            <p>{{ matches.score_opponent !== null ? matches.score_opponent : ' - ' }}</p>
        </div>
        <div class="bit">
            <span class="mob">{{__("Match bet")}}</span>
            <p v-if="matches.wallet == 2">{{formatNumber(primNum(matches.amount, 2))}} MIND</p>
            <p v-else-if="matches.wallet == 1" >{{formatNumber(primNum(matches.amount, 2))}} USDT</p>
        </div>
        <div class="status">
            <span class="mob">{{__("Status")}}</span>
            <p class="status-1" v-if="matches.income == 1">{{__("Win")}}</p>
            <p class="status-2" v-else-if="matches.income == 2">{{__("Loose")}}</p>
            <p class="status-3" v-else-if="matches.income == 3">{{__("Draw")}}</p>
            <p class="status-4" v-else>{{__("Pending")}}</p>
        </div>

    </div>
</template>

<script>

    // import GameAttributesItem from '../pages/Games/GameAttributesItem';
    // import ModalDefault from "../modules/ModalDefault";
    // import moment from "moment";
    // import {required, decimal, minValue, maxValue, maxLength, minLength} from "vuelidate/lib/validators";
    // import InputLabelTextDefault from '../modules/InputLabelTextDefault';
    // import _ from 'lodash';
    // import ModalBunMatch from "../pages/Games/ModalBunMatch";

    export default {
        name: 'GameUserSoloListItem',
        data: function() {
            return {

            }
        },


        props: {
            matches: {
                type: Object,
            },
            game: {
                type: Object
            },
            user: {
                type: Object
            },
            profile: {
                type: Object
            },
            item_game: {
                type: String,
            },
            class_list: {
              type: String,
              default: '',
            },
        },

        watch: {

        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            },
            lang() {

                return this.$store.state.language.lang;
            },
            game_array() {
                if (this.$filterArray(this.game.game_translations ? this.game.game_translations : this.game.game_translations_all, this.lang, 'lang')) {
                    return this.$filterArray(this.game.game_translations ? this.game.game_translations : this.game.game_translations_all, this.lang, 'lang');
                } else {
                    return this.$filterArray(this.game.game_translations ? this.game.game_translations : this.game.game_translations_all, 'en', 'lang');
                }
            },

        },
        methods: {

        },
        mounted() {

        },

        beforeDestroy() {

        },

        components: {
            // GameAttributesItem,
            // ModalDefault,
            // InputLabelTextDefault,
            // ModalBunMatch
        },
    };
</script>

<style lang="scss" scoped>

    .dark .theme--light.v-card {
        background: #171827!important;
    }

    .dark .theme--light.v-sheet {
        border-color: #171827!important;
    }

    .dark .item_game {
        background: #171827!important;
    }

    .dark .user-list {
        background: transparent;

        .v-list-item {
            &__title {
                color: #fff;
                a,
                span {
                    color: #fff;
                }

                span {
                    cursor: default;
                }
            }
        }
    }

    .dark .v-sheet.v-card {
        border-radius: 0;
        border-bottom: 1px solid #2B2B2B!important;
    }

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
    }

    .v-sheet.v-card {
        border-radius: 0;
        border-bottom: 1px solid #ECF4FF;
    }

    .v-card__text {
        padding: 0;
    }

.dark {
    .modal-invite {
        border-radius: 4px;
        background: #171827;
        &__head {
            background-color: #171827;

            .v-list-item {
                &__title {
                    color: #fff;
                }

                &__subtitle {
                    color: #96AFD1;
                }
            }
        }

        &__body {
            .wrap-price {
                &__title {
                    color: #96AFD1;
                }
            }

            .block-params {
                &__head {
                    color: #fff;
                    background: #171827;
                }

                &__body {
                    .item-params {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.modal-invite {
    border-radius: 4px;
    &__head {
        /*display: flex;*/
        /*justify-content: space-between;*/
        /*align-items: center;*/
        background-color: #fff;
        border-radius: 8px 8px 0px 0px;
        padding: 10px 10px 0;
        position: relative;

        .wrap-slide {
            margin:0 2px 0;
            position: relative;

            &:after {
                content: '';
                position: absolute;
                left: -12px;
                top: 15px;
                width: 20px;
                height: 2px;
                background-color: #E0ECFD;
            }

            &:before {
                content: '';
                position: absolute;
                right: -12px;
                top: 15px;
                width: 20px;
                height: 2px;
                background-color: #E0ECFD;
            }


        }



            .v-list-item {
                display: flex;
                align-items: center;

                .wrap-img {
                    border-radius: 8px;
                    width: 40px;
                    height: 40px;
                    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

                    img {
                        border-radius: 8px;
                        width: 40px;
                        height: 40px;
                    }
                }

                &__content {
                    padding: 0;
                    margin-left: 8px;
                }

                &__title {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #000000;
                    margin-bottom: 1px;
                }

                &__subtitle {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    color: #002B50;

                    span {
                        color: #002B50;
                    }
                }
            }

    }

    &__body {
        padding: 10px 20px 20px;

        .wrapper-btn {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .v-btn.real-btn {
            height: 24px;
            background: #FFFFFF;
            border: 1px solid #FDAE02;
            box-shadow: 0 2px 5px rgba(51, 97, 255, 0.03);
            border-radius: 30px;
            padding: 0 5px;
            margin-right: 10px;
            font-size: 13px;
            line-height: 18px;
            transition: .3s;

            &:before {
                background-color: #fff;
            }

            &:hover {
                transform: scale(1.1);
                transition: .3s;
            }

            img {
                margin-right: 2px;
            }
        }

        .v-btn.lite-btn {
            height: 24px;
            background: #FFFFFF;
            border: 1px solid #96AFD1;
            box-shadow: 0 2px 5px rgba(51, 97, 255, 0.03);
            border-radius: 30px;
            padding: 0 5px;
            margin-right: 10px;
            font-size: 13px;
            line-height: 18px;
            transition: .3s;

            &:before {
                background-color: #fff;
            }

            &:hover {
                transform: scale(1.1);
                transition: .3s;
            }
            img {
                margin-right: 2px;
            }
        }

        .wrap-price {

            &__title {
                font-size: 14px;
                line-height: 12px;
                display: flex;
                align-items: center;
                color: #6886AF;
                opacity: 0.87;
                margin-bottom: 10px;
            }
        }
        .currency {
            display: flex;
            align-items: center;

            img {
                margin-bottom: 2px;
            }
        }

        .block-params {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            cursor: default;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 9px;
                width: 100%;
                height: 1px;
                background: linear-gradient(90deg, rgba(0, 174, 255, 0) 0%, rgba(0, 174, 255, 0.6) 36.16%, rgba(0, 174, 255, 0.6) 67.61%, rgba(0, 174, 255, 0) 100.62%);
            }


            &__head {
                background: #fff;
                padding: 1px 6px 1px;
                border: 1px solid #00AEFF;
                box-sizing: border-box;
                border-radius: 4px;
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
                color: #002B50;
                text-transform: uppercase;
                margin-bottom: 10px;
                position: relative;
                z-index: 10;
            }

            &__body {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;

                .item-params {
                    padding: 6px 12px;
                    display: flex;
                    align-items: center;
                    background: rgba(0, 174, 255, 0.1);
                    border-radius: 5px;
                    margin: 4px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    color: #002B50;

                    span {
                        margin-right: 6px;
                        text-transform: capitalize;
                    }

                    p {
                        margin: 0;
                        color: #00AEFF;
                    }
                }
            }
        }

        .wrap-btn {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;

            div {
                width: 50%;
            }
        }
    }
}

.user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:5px 12px 5px 5px;
  /*border-bottom: 2px solid #ECF4FF;*/
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 38.42%, rgba(255, 255, 255, 0.5) 99.34%);
  border-radius: 8px;
  margin-bottom: 10px;

  .block-played {
      display: none;
  }

  .block-sleeped {
      display: none;
  }

  .button-sleepped {
    display: none;
  }

  .button-played {
    display: none;
  }

  .v-list-item {

    &__content {
      display: block;
    }

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-transform: capitalize;
      color: #002B50;
      margin-bottom:3px;
      max-width: 170px;
      min-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

        a {
            text-decoration: none;
            color: #002B50;

            &:hover {
                text-decoration: underline;
                color: #4EBEFF;
            }
        }
    }

    &__subtitle {
      font-size: 12px;
      line-height: 16px;
      max-width: 170px;
      min-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;

        .block-raiting {
            margin-right: 10px;
            img {
                margin-bottom: 3px;
            }
        }

        .block-info-game {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #6886AF;

            &__players {
                margin-right: 7px;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 4px;
                }

                span {
                    color: #26A9F4;
                    font-weight: bold;
                }
            }

            p {
                margin: 0;

                span {
                    color: #26A9F4;
                    margin-left: 1px;
                }
            }

            .circle {
                display: block;
                min-width: 5px;
                min-height: 5px;
                border-radius: 50%;
                background-color: #CBDAE9;
                margin-right: 7px;
            }

            &__created {
                margin: 0;
            }
            a {
                color: #26A9F4;
                text-decoration: none;
                transition-duration: .3s;

                &:hover {
                    text-decoration: underline;
                    transition-duration: .3s;
                }
            }
        }
    }
  }
}

.dark {
    .games-card {
        .online {
            border: 2px solid #202020;
        }
    }
}

.games-card {
  /*border-bottom: 1px solid #ECF4FF;*/

    .online {
        border: 2px solid #fff;
        border-radius: 50%;
        background-color: #00C569;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 0;
        bottom: 2px;
    }
}

.games-card.sleep {
    position: relative;

    .online {
        background: #96AFD1;
    }
}

.games-card.played {
    position: relative;

    .online {
        background: #FDAE02;
    }
}

.list-profile {
  /*border-bottom: 2px solid #ECF4FF;*/
  /*margin-left: 0;*/
  //margin-right: 7px;
    margin-bottom: 0;

  .user-list {
    padding: 10px 10px 10px 10px;
    margin-bottom: 0;
  }
}

.dark {
    .games-card.sleep {
        .user-list.item_game {
            .button-sleepped {
                background: #171827;
                border: 1px solid #4C4C4C;

                p {
                    color: #4C4C4C;
                }

                svg {
                    color: #4D4D4D;
                }
            }
        }
    }
}

.games-card.sleep {
    .user-list.item_game {
        &:hover {
            .block-sleeped {
                opacity: 1;
                transition-duration: .3s;
            }
        }

        .button-sleepped {
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 40px;
            background: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 18px;
            height: 42px;
            width: 172px;
            cursor: default;
            border: 1px solid rgba(150, 175, 209, 0.7);

            svg {
                margin-right: 12px;
                color: rgba(150, 175, 209, 0.7);
            }

            p {
                margin: 0;
                font-size: 14px;
                line-height: 19px;
                color: rgba(150, 175, 209, 0.7);
            }
        }
    }
}

.dark {
    .games-card.played {
        .user-list.item_game {
            .button-played {
                background: #171827;
                border: 1px solid rgba(253, 174, 2, 0.5);

                p {
                    color: rgba(253, 174, 2, 0.5);
                }

                svg {
                    color: rgba(253, 174, 2, 0.5);
                }
            }
        }
    }
}

.games-card.played {
    .user-list.item_game {
        &:hover {
            .block-played {
                opacity: 1;
                transition-duration: .3s;
            }
        }

        .button-played {
            z-index: 20;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 40px;
            background: #fff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 18px;
            height: 42px;
            width: 172px;
            cursor: default;
            border: 1px solid rgba(253, 174, 2, 0.5);

            svg {
                margin-right: 12px;
                color: rgba(253, 174, 2, 0.5);
            }

            p {
                margin: 0;
                font-size: 14px;
                line-height: 19px;
                color: rgba(253, 174, 2, 0.5);
            }
        }
    }
}

.dark {
    .item_game {
        .block-sleeped,
        .block-played {
            background: #fff;
        }
    }
}

.item_game {
    border-radius: 8px;
    background-color: #fff;
    //border-bottom: 1px solid #ECF4FF;
    margin-bottom: 0;
    padding: 6px 20px 6px 20px;
    position: relative;

    .block-sleeped {
        display: flex;
        background: #171827;
        backdrop-filter: blur(5px);
        border-radius: 0 20px 20px 0;
        padding: 2px 14px 2px 30px;
        font-size: 12px;
        line-height: 16px;
        color: #96AFD1;
        position: absolute;
        bottom: 10px;
        left: 30px;
        z-index: 10;
        opacity: 0;
        transition-duration: .3s;
    }

    .block-played {
        display: flex;
        background: #171827;
        backdrop-filter: blur(5px);
        border-radius: 0 20px 20px 0;
        padding: 2px 14px 2px 30px;
        font-size: 12px;
        line-height: 16px;
        text-transform: capitalize;
        color: #FDAE02;
        white-space: nowrap;
        position: absolute;
        bottom: 10px;
        left: 30px;
        z-index: 10;
        opacity: 0;
    }
}

.block-img {
  margin-right: 10px;
  position: relative;
  z-index: 11;

    a {
        text-decoration: none;
    }

    .wrap-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

    .photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
    }
}

.block-img-game {
    margin-right: 10px;
    position: relative;

    .wrap-img-game {
        width: 54px;
        height: 54px;
        border-radius: 8px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

        img {
            width: 54px;
            height: 54px;
            border-radius: 8px;
        }
    }
}

.rating {
    span {
        margin-right: 6px;
        margin-top: 3px;
        /*font-weight: bold;*/
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: #96afd1;
    }

    .v-rating {
        margin-top: 2px;

        .v-rating--dense .v-icon {
            padding: 0;
        }
    }

    a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #26a9f4;
        text-decoration: none;
        margin-top: 7px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.wrapper-attributes {
    margin: -4px 0 0 45px;
    width: 100%;
    /*min-height: 40px;*/
    height: 100%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;

    /*.attributes-item {
        position: relative;
        display: flex;
        align-items: center;
        color: #6886AF;
        width: 33%;
        min-width: 60px;


        &__name {
            cursor: pointer;
            transition-duration: .3s;

            &:hover {
                color: #002B50;
                transition-duration: .3s;

                & ~.block-info-option {
                    opacity: 1;
                    display: flex;
                    transition-duration: .3s;
                }
            }
        }



        !*&:nth-child(3),*!
        !*&:nth-child(6){*!
        !*    margin-right: 0;*!
        !*}*!

        p {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            max-width: 150px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        i {
            font-size: 7px;
            display: flex;
            align-items: center;
            margin: 1px 6px 0 0;
        }

        .block-info-option {
            position: absolute;
            bottom: -85px;
            left: -50px;
            opacity: 0;
            display: none;
            transition-duration: .3s;
            width: 380px;
            !*width: 100%;*!
            z-index: 10;
            padding: 10px;
            background-color: #FFFFFF;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            border-radius: 8px;


            img {
                width: 60px;
                height: 60px;
                border-radius: 6px;
                margin-right: 9px;
            }

            h5 {
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #002B50;
                margin-bottom: -2px;
            }

            span {
                max-width: 200px;
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #6886AF;
            }
        }
    }*/
}

.dark {
    .wrapper-attributes-bottom {
        background: #171827;
        border-bottom: 1px solid #363636;
    }
}

.wrapper-attributes-bottom {
    display: none;
    align-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0 -1px;
    background-color: #F3F7FE;
    border-bottom: 1px solid #ECF4FF;

}

.success-inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #00C443;
    box-sizing: border-box;
    border-radius: 8px;
    text-transform: inherit;
    color: #00C443;
    transition-duration: .3s;

    .pic {
        height:12px;
        margin: 0 1px 0 4px;
    }

    &:hover {
       border: 2px solid #00AD3B;
    }
}

.default-inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #6886AF;
    box-sizing: border-box;
    border-radius: 8px;
    text-transform: inherit;
    color: #6886AF;
    transition-duration: .3s;

    .pic {
        height:12px;
        margin: 0 1px 0 4px;
    }

     &:hover {
        border: 2px solid #96AFD1;
    }
}

.v-btn.not-create {

    &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
        min-width: 140px;
        // width: 124px;
        padding: 10px;
        border-radius: 20px;
        margin: 0;
    }

    p {
        margin: 0 0 0 8px;
    }
}

.block-right {
    display: flex;
    align-items: center;
    white-space: nowrap;

    .wrap-btn {
        display: flex;
        align-items: center;
        width: 170px;
        justify-content: flex-end;

        .v-btn.btn-link {
            /*margin-left: 10px;*/
            display: none;
            position: absolute;
            z-index: 10;
            bottom: -5px;
            left: calc(50% - 18px);
            height: 24px;
        }
    }
}
    .dark .block-money {
        &__lite {
            color: #96AFD1;
        }
    }
.block-money {
    display: flex;
    align-items: center;

    &__lite {
        color: #6886af;
    }

    .wrap-icon {
        width: 58px;
        display: flex;
        justify-content: center;

        img {
            height: 18px;
        }
    }

    span {
        display: flex;
        width: 160px;
        justify-content: center;
        align-items: center;

        .line {
            width: fit-content;
            margin: 0 5px;
        }

        p {
            margin: 0;
            width: 70px;
            text-align: center;
        }
    }


}

    /*.lite-btn {*/
        /*background-color: #96AFD1;*/
        /*transition-duration: 0.3s;*/
        /*outline: none;*/

        /*&:hover {*/
            /*background-color: #819BBF;*/
        /*}*/
    /*}*/

    /*.real-btn {*/
        /*background-color: #4EBEFF;*/
        /*transition-duration: .3s;*/
        /*outline: none;*/

        /*&:hover {*/
            /*background-color: #26A9F4;*/
        /*}*/
    /*}*/


@media screen and (max-width: 1600px) {
  .wrapper-attributes {
    margin: 0;

  }
}


@media screen and (max-width: 1250px) {
    .v-btn.not-create {

        &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 65px;
            padding: 10px;
            border-radius: 20px;
            margin: 0;
        }

        p {
            display: none;
        }
    }

    .user-list {

        .v-list-item {

            &__avatar {
                /*min-width: 45px;*/
            }

            &__title {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-transform: capitalize;
                color: #002B50;
                max-width: 120px;
                min-width: 120px;
                margin-right: 10px;
            }

            &__subtitle {
                font-size: 12px;
                line-height: 16px;
                max-width: 120px;
                min-width: 120px;
            }
        }
    }

    /*.block-img {*/

    /*    .wrap-img {*/
    /*        width: 45px;*/
    /*        height: 45px;*/
    /*        border-radius: 50%;*/

    /*        img {*/
    /*            width: 45px;*/
    /*            height: 45px;*/
    /*            border-radius: 50%;*/
    /*        }*/
    /*    }*/

    /*    .photo {*/
    /*        width: 45px;*/
    /*        height: 45px;*/
    /*        border-radius: 50%;*/
    /*    }*/
    /*}*/
}

@media screen and (max-width: 1200px) {

    .block-money {

        .wrap-icon {
            width: 40px;
        }
    }

    .block-right {
        .wrap-btn {
            width: 150px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .item_game {
        padding: 6px 10px 6px 10px;
    }

    .wrapper-attributes {
        display: none;
    }
    .wrapper-attributes-bottom {
        display: flex;
        padding: 6px 12px;
    }

    .block-right {
        .wrap-btn {
            width: fit-content;
            margin-left: 10px;

            .v-btn.btn-link {
                display: block;
            }
        }
    }

    .games-card.sleep {
        .user-list.item_game {
            .button-sleepped {
                right: 9px;
            }
        }
    }

    .games-card.played {
        .user-list.item_game {
            .button-played {
                right: 9px;
            }
        }
    }
}


/*@media screen and (max-width: 800px) {*/

  /*.online {*/
    /*right: 4px;*/
    /*bottom: 0px;*/
  /*}*/


  /*.user-list {*/

      /*.v-list-item {*/

          /*&__avatar {*/
              /*min-width: 45px;*/
          /*}*/

          /*&__title {*/
              /*font-weight: 400;*/
              /*font-size: 14px;*/
              /*line-height: 19px;*/
              /*text-transform: capitalize;*/
              /*color: #002B50;*/
              /*margin-bottom:3px;*/
          /*}*/

          /*&__subtitle {*/
              /*font-size: 12px;*/
              /*line-height: 16px;*/
          /*}*/
      /*}*/
  /*}*/

  /*.block-img {*/

      /*.wrap-img {*/
          /*width: 45px;*/
          /*height: 45px;*/
          /*border-radius: 50%;*/

          /*img {*/
              /*width: 45px;*/
              /*height: 45px;*/
              /*border-radius: 50%;*/
          /*}*/
      /*}*/

      /*.photo {*/
        /*width: 45px;*/
        /*height: 45px;*/
        /*border-radius: 50%;*/
      /*}*/
  /*}*/


  /*.block-money {*/
      /*span {*/
        /*font-size: 14px;*/
      /*}*/
      /*img {*/
          /*height: 12px;*/
      /*}*/
      /*.v-btn {*/
          /*font-size: 12px;*/
          /*margin-left: 30px;*/
      /*}*/
  /*}*/

  /*.v-btn:not(.v-btn--round).v-size--default {*/
      /*height: 36px;*/
      /*min-width: 120px;*/
      /*// width: 124px;*/
      /*padding: 10px;*/
  /*}*/
/*}*/

@media screen and (max-width: 768px) {
    .user-list {

        .v-list-item {

            &__avatar {
                min-width: 45px;
            }

            &__title {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-transform: capitalize;
                color: #002B50;
                max-width: 170px;
                min-width: 170px;
                margin-right: 10px;
            }

            &__subtitle {
                font-size: 12px;
                line-height: 16px;
                max-width: 170px;
                min-width: 170px;
            }
        }
    }
}

/*@media screen and (max-width: 525px) {*/

    /*.user-list {*/
        /*padding: 5px 10px;*/

        /*.block-money {*/
            /*span {*/
                /*font-size: 10px;*/
            /*}*/

            /*img {*/
                /*height: 8px;*/
            /*}*/

            /*.v-btn {*/
                /*font-size: 8px;*/
                /*margin-left: 15px;*/
                /*border-radius: 6px;*/
            /*}*/
        /*}*/
        /*.v-btn:not(.v-btn--round).v-size--default {*/
            /*height: 30px;*/
            /*min-width: 90px;*/
            /*// width: 124px;*/
            /*padding: 10px;*/
        /*}*/

        /*.v-list-item {*/

            /*&__avatar {*/
                /*min-width: 45px;*/
            /*}*/

            /*&__title {*/
                /*font-weight: 400;*/
                /*font-size: 10px;*/
                /*line-height: 19px;*/
                /*text-transform: capitalize;*/
                /*color: #002B50;*/
                /*margin-bottom:0px;*/
            /*}*/

            /*&__subtitle {*/
                /*font-size: 8px;*/
                /*line-height: 16px;*/

                /*img {*/
                    /*height: 6px;*/
                    /*max-width: 10px;*/
                /*}*/
            /*}*/
        /*}*/

        /*.block-img {*/
            /*margin-right: 5px;*/
            /*.wrap-img {*/
                /*width: 38px;*/
                /*height: 38px;*/
                /*border-radius: 50%;*/

                /*img {*/
                    /*width: 38px;*/
                    /*height: 38px;*/
                    /*border-radius: 50%;*/
                /*}*/
            /*}*/
        /*}*/
    /*}*/
/*}*/

@media screen and (max-width: 650px) {

    .block-right .wrap-btn {
        width: 65px;
        .baseButton {
            min-width: 65px!important;
            width: 65px!important;
            span {
                svg,
                img {
                    margin-right: 0;
                }
                p {
                    display: none;
                }
            }
        }
    }
    .block-money {
        span {
            flex-direction: column;
            width: 64px;
            align-items: flex-start;

            p {
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                width: 64px;
            }

            .line {
                display: none;
            }
        }
    }

    .block-img-game {
        margin-right: 10px;
        position: relative;

        .wrap-img-game {
            width: 40px;
            height: 40px;
            border-radius: 4px;
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

            img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
            }
        }
    }

    .user-list {

        .v-list-item {

            &__avatar {
                /*min-width: 45px;*/
            }

            &__title {
                font-weight: 400;
                font-size: 13px;
                line-height: 17px;
                text-transform: capitalize;
                color: #002B50;
                max-width: 150px;
                min-width: 150px;
                margin-right: 10px;
            }

            &__subtitle {
                font-size: 11px;
                line-height: 14px;
                max-width: 150px;
                min-width: 150px;

                .block-info-game {
                    font-size: 11px;
                    line-height: 14px;
                }
            }
        }
    }

    .games-card.sleep {
        .user-list.item_game {
            .button-sleepped {
                width: 66px;

                p {
                    display: none;
                }

                svg {
                    margin-right: 0;
                }
            }
        }
    }

    .games-card.played {
        .user-list.item_game {
            .button-played {
                width: 66px;

                p {
                    display: none;
                }

                svg {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .user-list {
        padding: 10px;

        .v-list-item {

            &__title {
                max-width: 110px;
                min-width: 110px;
                margin-right: 5px;
            }

            &__subtitle {
                max-width: 110px;
                min-width: 110px;

                .block-info-game {

                    &__players {
                        margin-right: 3px;
                    }
                    .circle {
                        margin-right: 3px;
                    }
                }
            }
        }

        .block-money {

            .wrap-icon {
                width: 30px;
                img {
                    height: 16px;
                }
            }
            span {
                flex-direction: column;
                width: 58px;

                .line {
                    display: none;
                }

                p {
                    width: 58px;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: left;
                }
            }
        }
    }

    .item_game {
        .block-sleeped,
        .block-played {
            bottom: 15px;
        }
    }

    .games-card.sleep {
        .user-list.item_game {
            .button-sleepped {
                /*top: 11px;*/
            }
        }
    }

    .games-card.played {
        .user-list.item_game {
            .button-played {
                /*top: 11px;*/
            }
        }
    }

    .modal-invite {
        &__body {
            .block-params {
                margin: 14px 0;

                &__body {
                    .item-params {
                        padding: 4px 10px;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

</style>