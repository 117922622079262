<template>
    <v-dialog v-model="dialog" max-width="400px">
        <div class="modal-edit-match-tournament">
            <div class="modal-edit-match-tournament__head">
                <div class="block-game">
                    <img v-if="typeof tournament.game.logo == 'object'" :src="tournament.game.logo[100]" />
                    <img v-else :src="tournament.game.logo" />

                    <div class="block-text">
                        <p>{{__('Edit match')}}</p>
                        <span>{{gameTranslation(tournament.game.game_translations).name}}</span>
                    </div>
                </div>

                <div class="block-icon">
                    <img src="/dist/images/frontend/nav-icon-tournaments-new.svg" alt="">
                </div>
            </div>
        </div>

        <div class="modal-edit-match-tournament__body">

            <div class="wrapper-currency">
                <p class="wrapper-currency__title">{{__('Change currency')}}</p>

                <div class="block-currency">
                    <img v-if="item.match.wallet == 1" width="20px" src="/dist/images/frontend/money.svg" alt="">
                    <img v-else width="20px" src="/dist/images/frontend/light-coin.svg" alt />

                    <p>{{__((item.match.wallet == 1) ? 'USDT Coin' : 'MIND Coin')}}</p>
                </div>
            </div>

            <div class="wrapper-price">
                <p class="wrapper-price__title">{{__("Enter amount")}}</p>

                <div class="wrapper-price__amount">
                    <div class="dis">
                        <InputLabelTextDefault
                                :label="__('From')"
                                color="border-norm"
                                v-model="item.match.amount_from"
                                disabled
                        >
                            <template v-slot:right>
                                <div class="currency">
                                <span v-if="item.match.wallet == 1">
                                  <img width="20px" src="/dist/images/frontend/money.svg" alt="">
                                </span>

                                    <span v-else-if="item.match.wallet == 2">
                                   <img width="20px" src="/dist/images/frontend/light-coin.svg" alt />
                                </span>
                                </div>
                            </template>
                        </InputLabelTextDefault>
                    </div>

                    <div class="wrap-img">
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.617403 11.93L7.80642 6.19537C7.92511 6.10052 7.92511 5.89997 7.80642 5.80512L0.616904 0.0698846C0.530918 0.00161989 0.40275 -0.0185975 0.29118 0.0182181C0.267967 0.0258308 0.249997 0.0343171 0.236768 0.0414306C0.155649 0.08511 0.10498 0.169349 0.10498 0.261575V11.7389C0.10498 11.8308 0.155524 11.9154 0.236768 11.9588C0.250371 11.9663 0.268342 11.9747 0.289932 11.9819C0.402625 12.0187 0.531168 11.9986 0.617403 11.93Z" fill="#E0ECFD"/>
                        </svg>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.3826 11.93L0.193578 6.19537C0.0748944 6.10052 0.0748944 5.89997 0.193578 5.80512L7.3831 0.0698846C7.46908 0.00161989 7.59725 -0.0185975 7.70882 0.0182181C7.73203 0.0258308 7.75 0.0343171 7.76323 0.0414306C7.84435 0.08511 7.89502 0.169349 7.89502 0.261575V11.7389C7.89502 11.8308 7.84448 11.9154 7.76323 11.9588C7.74963 11.9663 7.73166 11.9747 7.71007 11.9819C7.59738 12.0187 7.46883 11.9986 7.3826 11.93Z" fill="#E0ECFD"/>
                        </svg>
                    </div>

                    <InputLabelTextDefault
                        :label="__('To')"
                        color="border-norm"
                        type="number"
                        v-model="form.amount_to"
                        v-bind:errors="formValidate.amount_to"
                        @input="updateValidate('amount_to')"
                        isFocus
                    >
                        <template v-slot:right>
                            <div class="currency">
                                    <span v-if="item.match.wallet == 1">
                                      <img width="20px" src="/dist/images/frontend/money.svg" alt="">
                                    </span>

                                <span v-else-if="item.match.wallet == 2">
                                       <img width="20px" src="/dist/images/frontend/light-coin.svg" alt />
                                    </span>
                            </div>
                        </template>
                    </InputLabelTextDefault>
                </div>
            </div>

            <div class="wrap-btn">
                <div style="width: 48%">
                    <BaseButton
                        v-ripple="false"
                        class_btn="btn-liteGrey-quest"
                        @click="dialog= false"
                    >
                        <span>{{__('Cancel')}}</span>
                    </BaseButton>
                </div>

                <div style="width: 48%">
                    <BaseButton
                            class_btn="btn-guest"
                            @click="onSubmit"
                            :loading="submitDisabledEdit"
                    >
                        <span>{{__('Edit match')}}</span>
                    </BaseButton>
                </div>

            </div>
        </div>
    </v-dialog>
</template>

<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {
        required,
        decimal,
        minValue,
        maxValue
    } from "vuelidate/lib/validators";


    export default {
        name: "ModalEditMatch",
        data() {
            return {
                dialog: true,
                form: {
                    amount_to: this.item.match.amount_to,
                },
                formValidate: {
                    amount_to: null,
                },

            };
        },
        props: {
            edit_match: {
                type: Boolean
            },
            tournament: {
                type: Object
            },
            item: {
                type: Object
            },
            submitDisabledEdit: {
                type: Boolean
            }
        },
        validations() {
            return {
                form: {
                    amount_to: {
                        decimal,
                        required: required,
                        minValue: minValue(Number(this.item.match.amount_from)),
                        maxValue: ((this.item.match.wallet == 1) ? maxValue(this.primNum(this.auth['balance_real'].available, 2)) : maxValue(this.primNum(this.auth['balance_lite'].available, 2))),
                    },

                }
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.state.language.lang;
            },
        },

        watch: {
            dialog() {
                this.cancelModal();
            },
            amount() {
                this.formValidate.amount = [];
            },

        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.form[type],
                    type
                );
            },
            gameTranslation(arr) {
                if (this.$filterArray(arr, this.lang, 'lang')) {
                    return this.$filterArray(arr, this.lang, 'lang');
                } else {
                    return this.$filterArray(arr, 'en', 'lang');
                }
            },
            cancelModal() {
                this.$emit("update:edit_match", false);
            },
            onSubmit() {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        amount_to: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.$emit('update:submitDisabledEdit', true);
                    const response = {
                        amount_to: this.form.amount_to,
                        match_id: this.item.match.id,
                        tournament_id: this.tournament.id
                    };

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/tournaments/match/edit', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response)=> {
                            if(this.$checkAuth(response)) {
                                this.$emit('update:submitDisabledEdit', false);
                                this.$emit("update:edit_match", false);

                                response = response.data;

                                this.$emit('change_users', response);
                            }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                            this.$emit('update:submitDisabledEdit', false);
                            this.$emit("update:edit_match", false);
                        });
                }
            }
        },
        created() {

        },
        mounted() {},

        components: {
            InputLabelTextDefault,
        },
    };
</script>

<style lang="scss">
    .modal-edit-match-tournament {
        border-radius: 8px;
        background: #171827;

        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            background-color: #171827;
            border-radius: 8px 8px 0 0;
            border-bottom: 1px solid #2b2b2b;
            position: relative;

            .block-game {
                display: flex;
                align-items: center;

                img {
                    border-radius: 4px;
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                }

                .block-text {

                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 1px;
                        color: #fff;
                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 14px;
                        color: #96afd1;
                    }
                }
            }

            .block-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #000;

                img {
                    height: 20px;
                }
            }
        }

        &__body {
            padding: 15px;
            background: #171827;
            border-radius: 0 0 8px 8px;

            .wrapper-currency {
                margin-bottom: 15px;

                &__title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #96afd1;
                    margin-bottom: 10px;
                }

                .block-currency {
                    height: 40px;
                    padding: 0 10px;
                    border: 1px solid hsla(0,0%,100%,.15);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;


                    img {
                        margin-right: 8px;
                    }

                    p {
                        color: #fff;
                        margin: 0;
                        font-size: 16px;
                    }
                }
            }
            .wrapper-price {
                margin-bottom: 30px;

                &__title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    color: #6886AF;
                    opacity: 0.87;
                    margin: 0 0 32px;
                }

                &__amount {
                    margin-top: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .wrap-img {
                        display: flex;
                        align-items: center;
                        margin: 0 20px;
                    }

                    .dis {
                        display: flex;
                        width: 100%;
                        .input-group.input_border-norm {
                            background-color: transparent;
                            border: 1px solid rgba(255, 255, 255, 0.15);
                        }
                    }
                }
                .currency {
                    margin-top: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: 25%;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #6886AF;
                    cursor: default;
                }
            }

            .wrap-btn {
                display: flex;
                justify-content: space-between;
            }
        }
    }

</style>
