<template>
    <div class="wrap-insruction">
        <v-dialog v-model="dialog" max-width="350px">
            <div class="insruction-app">
                <div class="insruction-app__body">
                    <div class="block-title">
                        {{__('app installation')}}
                    </div>
                    <div class="block-img">
                        <img src="/dist/images/frontend/instruction_apple.svg"/>
                    </div>

                    <div class="block-btn">
                        <BaseButton
                                @click="dialog = false"
                        >
                            <span>{{__('Done')}}</span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>

</template>
<script>
    import BaseButton from "./BaseButton";
    export default {
        name:"ModalRulesGame",
        data(){
            return {
                dialog: true,
            }
        },
        props: {
            instruction_apple: {
                type: Boolean,
            },
        },

        computed: {

        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:instruction_apple", false);
            },
        },
        components: {
            BaseButton
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .insruction-app {

            &__body {
                background: #171827;
                color: #fff;
            }
        }
    }

    .insruction-app {

        &__body {
            padding: 0 30px 20px;
            background-color: #ffffff;
            border-radius: 8px;
            max-height: 500px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: center;


            .block-title {
                background: #181818;
                border-radius: 0 0 5px 5px;
                text-transform: uppercase;
                color: #01DF72;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                padding: 6px 10px;
                margin-bottom: 20px;
            }

            .block-img {
                margin-bottom: 20px;
            }
        }
    }
</style>