<template>
  <div class="block-game" :class="(scroll) ? 'block-game--fixed': ''">
    <div class="block-game-head">
      <UploadAvatar
        v-bind:response.sync="response_upload"
        :url="'/'+this.pathDevelopers+'/update/avatar'"
        style="background-size: cover"
        :width="500"
        :height="500"
      >
<!--        <div-->
<!--          class="image"-->
<!--          :style="{background: 'url(/assets/games/'+((developers.logo) ? developers.logo : 'default-avatar.svg')+') no-repeat', backgroundSize: 'cover'}"-->
<!--        ></div>-->
        <div
          class="image"
        >
          <img v-if="developers.logo && typeof developers.logo == 'object'" width="180" :src="this.developers.logo[300]"/>
          <img v-else-if="developers.logo" width="180" :src="this.developers.logo"/>
          <img v-else width="180" src="/dist/logo_600.png" alt />
        </div>
      </UploadAvatar>

<!--      <div class="block-game-head__name">-->
<!--        <h3>{{developers.name}}</h3>-->
<!--      </div>-->
      <div class="block-game-info">
          <div class="block-game-head__status">
              <span>{{__('Status:')}}</span>
              <p v-if="developers.dev_mode== 0" class="success--text">{{__('Enabled and visible')}}</p>
              <p v-else-if="developers.dev_mode== 1 || developers.dev_mode== 3"  class="red--text">{{__('Disabled')}}</p>
              <p v-else class="blue--text">{{__('For moderation')}}</p>
          </div>


          <div class="block-game-head__type">
              <span>{{__('Type:')}}</span>
              <p v-if="developers.type == 1">{{__('Battle 1 vs 1')}}</p>
              <p v-else-if="developers.type == 2">{{__('Multiplayer')}}</p>
          </div>

          <div class="block-game-head__id">
              <span>ID:</span>
              <p>{{developers.id}}</p>
          </div>

          <div class="block-game-head__statistics" v-if="developers.chart && developers.chart.length">
              <!--        <span>Количество сыгранных игр по дням</span>-->
              <v-sparkline
                      height="90"
                      :fill="fill"
                      :gradient="gradients"
                      :line-width="width"
                      :padding="padding"
                      :smooth="radius || false"
                      :value="value"
                      auto-draw
              ></v-sparkline>
              <!--<span>Number of games played by day</span>-->
              <span>Number of games per month</span>
          </div>
      </div>

      <BaseButton
            width="250"
            style="margin-top: 30px"
            class_btn="redBtn"
            v-on:click="open_delete= true"
      >
        <span>{{__('Delete Game')}}</span>
      </BaseButton>
    </div>

      <ModalConfirmActionDefault
              v-if="open_delete"
              v-bind:developers="developers"
              v-bind:open="open_delete"
              text="You definitely want to delete?"
              v-bind:result.sync="result_delete"
              v-on:update:result="deleteApp()"
      ></ModalConfirmActionDefault>
<!--      <div style="width: 100%">-->
<!--        <div class="wrap-block-update__name">-->
<!--          <InputLabelTextDefault-->
<!--            label="Application Name"-->
<!--            color="main"-->
<!--            v-model="name"-->
<!--            v-bind:errors="formValidate.name"-->
<!--            @change="updateValidate('name')"-->
<!--          ></InputLabelTextDefault>-->

<!--          <v-btn-->
<!--            class="btn-cab save-btn white&#45;&#45;text"-->
<!--            text-->
            <!--v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--            v-if="name!= name_input"-->
<!--            v-on:click="updateValue(name, 'name')"-->
<!--          >save</v-btn>-->
<!--        </div>-->
<!--        <v-row>-->
<!--          <v-col cols="5">-->
<!--            <DropdownDefault-->
<!--              color="select_color"-->
<!--              v-bind:value.sync="category"-->
<!--              placeholder="Select a category"-->
<!--              v-bind:title_key="'name'"-->
<!--              v-bind:dropdown="categories"-->
<!--              @change="updateValidate('category', category)"-->
<!--            />-->
<!--          </v-col>-->
<!--          -->
<!--          <v-col cols="7">-->
<!--            <div v-if="developers.dev_mode== 1 || developers.dev_mode== 3" class="wrap-block-moderation">-->
<!--              <div class="wrap-block-moderation__status">-->
<!--                <p>Status:</p>-->
<!--                <span class="red&#45;&#45;text">Disabled</span>-->
<!--              </div>-->
<!--              <v-btn-->
<!--                text-->
<!--                class="btn-secondary btn-on"-->
<!--                v-on:click="submitGameMode('moderation')"-->
<!--                :loading="submitDisabledLoad"-->
<!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--              >-->
<!--                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M7.9998 12.1733C8.32626 12.1733 8.59091 11.9087 8.59091 11.5822C8.59091 11.2558 8.32626 10.9911 7.9998 10.9911C7.67334 10.9911 7.40869 11.2558 7.40869 11.5822C7.40869 11.9087 7.67334 12.1733 7.9998 12.1733Z" fill="currentColor"/>-->
<!--                  <path d="M8.00011 10.0489C7.88223 10.0489 7.76919 10.0021 7.68584 9.91871C7.60249 9.83536 7.55566 9.72231 7.55566 9.60444V4.2711C7.55566 4.15323 7.60249 4.04018 7.68584 3.95684C7.76919 3.87349 7.88223 3.82666 8.00011 3.82666C8.11798 3.82666 8.23103 3.87349 8.31438 3.95684C8.39773 4.04018 8.44455 4.15323 8.44455 4.2711V9.60444C8.44455 9.72231 8.39773 9.83536 8.31438 9.91871C8.23103 10.0021 8.11798 10.0489 8.00011 10.0489Z" fill="currentColor"/>-->
<!--                  <path d="M6.69401 0.747109C6.81754 0.52229 6.9992 0.334792 7.22001 0.204226C7.44081 0.07366 7.69264 0.00482257 7.94916 0.00491342C8.20568 0.00500427 8.45746 0.07402 8.67818 0.204742C8.89889 0.335465 9.08042 0.523091 9.20379 0.747997L15.3771 12.108C15.4955 12.326 15.5552 12.5709 15.5504 12.8189C15.5456 13.0669 15.4764 13.3094 15.3496 13.5226C15.2228 13.7358 15.0428 13.9124 14.8272 14.035C14.6116 14.1577 14.3678 14.2222 14.1198 14.2222H1.78734C1.53651 14.2278 1.28861 14.1674 1.0685 14.047C0.84839 13.9266 0.663801 13.7504 0.533244 13.5362C0.402687 13.3219 0.330749 13.0771 0.324643 12.8263C0.318537 12.5755 0.378478 12.3275 0.498456 12.1071L6.69401 0.747109ZM1.27979 12.5324C1.234 12.6166 1.21133 12.7114 1.21411 12.8072C1.21688 12.903 1.245 12.9964 1.29558 13.0778C1.34617 13.1592 1.41743 13.2257 1.50209 13.2706C1.58676 13.3155 1.68181 13.3371 1.77757 13.3333H14.1198C14.2138 13.3333 14.3061 13.3088 14.3878 13.2623C14.4695 13.2158 14.5377 13.1488 14.5857 13.068C14.6337 12.9872 14.6598 12.8953 14.6616 12.8013C14.6634 12.7074 14.6407 12.6146 14.5958 12.532L8.42379 1.17422C8.37701 1.08923 8.30828 1.01835 8.22477 0.968971C8.14127 0.91959 8.04604 0.893518 7.94903 0.893472C7.85202 0.893427 7.75677 0.91941 7.67322 0.968713C7.58967 1.01802 7.52087 1.08883 7.47401 1.17378L1.27934 12.5324H1.27979Z" fill="currentColor"/>-->
<!--                </svg>-->

<!--                Need moderation-->
<!--              </v-btn>-->
<!--            </div>-->
<!--            <div v-else-if="developers.dev_mode== 0" class="wrap-block-moderation">-->
<!--              <div class="wrap-block-moderation__status">-->
<!--                <p>Status:</p>-->
<!--                <span class="success&#45;&#45;text">Enabled and visible</span>-->
<!--              </div>-->
<!--              <v-btn-->
<!--                 text-->
<!--                 v-on:click="submitGameOff('moderation')"-->
<!--                 :loading="submitDisabledLoad"-->
<!--                 v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                 class="btn-secondary"-->
<!--              >Switch off</v-btn>-->
<!--            </div>-->
<!--            <div v-else class="wrap-block-moderation">-->
<!--              <div class="wrap-block-moderation__status">-->
<!--                <p>Status:</p>-->
<!--                <span class="blue&#45;&#45;text">For moderation</span>-->
<!--              </div>-->
<!--              <v-btn-->
<!--                  text-->
<!--                  v-on:click="submitGameOff('moderation')"-->
<!--                  :loading="submitDisabledLoad"-->
<!--                  v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                  class="btn-secondary">-->
<!--                Cancel moderation-->
<!--              </v-btn>-->
<!--            </div>-->
<!--          </v-col>-->
<!--        </v-row>-->

<!--        <div class="wrap-block-key">-->
<!--          <InputLabelTextDefault-->
<!--            label="API Secret Key"-->
<!--            color="main"-->
<!--            :class_input="(status_secret) ? 'success&#45;&#45;text' : 'blue&#45;&#45;text'"-->
<!--            readonly-->
<!--            v-model="secret"-->
<!--            v-bind:errors="formValidate.secret"-->
<!--            :copy="true"-->
<!--          ></InputLabelTextDefault>-->

<!--          <v-btn-->
<!--            class="refresh-btn"-->
<!--            :loading="submitDisabled"-->
<!--            v-on:click="updateSecret(name, 'name')"-->
<!--            text-->
<!--            icon-->
<!--            v-ripple="false"-->
<!--          >-->
<!--            <img src="/dist/images/frontend/update-icon.svg" alt />-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->
  </div>
</template>

<script>
// import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
// import DropdownDefault from "../../modules/DropdownDefault";
import ModalConfirmActionDefault from "../../modules/ModalConfirmActionDefault";
import UploadAvatar from "../../modules/UploadAvatar";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "GameInfoUpdate",
  data() {
    return {
      result_delete: null,
      open_delete: false,
      gradients: [
              ['rgba(38, 169, 244, 0.5)'],
              ['rgba(38, 169, 244, 0)']
      ],
      fill: true,
      padding: 8,
      radius: 10,
      value: this.developers.chart.map(val => {
          return Number(val)
      }),
      width: 2,


      // name: this.developers.name,
      // name_input: this.developers.name,
      category: this.developers.category_id,
      secret: this.developers.secret,
      submitDisabled: false,
      submitDisabledLoad: false,
      active: this.developers.active,
      status_secret: null,
      status_update: {
        name: null,
        category: null,
        active: null,
      },
      selects_active: [
        {
          value: 0,
          name: "Игра отключена для всех",
          color: "error",
        },
        {
          value: 1,
          name: "Enabled and visible to everyone",
          color: "success",
        },
      ],
      formValidate: {
        name: null,
        category: null,
        active: null,
      },
      pathDevelopers: "ajax/developers/" + this.$route.params.id,
      rules: this.validateMessage,
      response_upload: null,
    };
  },
  props: {
    developers: {
      type: Object,
    },
    categories: {
      type: Array,
    },
      scroll: {
          type: Boolean
      }
  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(3),
    },
    category: {
      required,
    },
    active: {
      required,
    },
  },
  watch: {
    response_upload: function (payload) {
      let developers = this.developers;
      developers.logo = payload.logo;
      this.$emit("update:developers", developers);
    },
  },

  // created() {
  //   this.$addListener(window,'scroll', () => {
  //       if (document.documentElement.scrollTop > 60) {
  //           this.scroll = true;
  //       } else {
  //           this.scroll = false;
  //       }
  //   });
  // },

  methods: {
      deleteApp() {
          this.open_delete = false;
          if (this.result_delete) {
              this.$http.get("ajax/developers/" + this.$route.params.id + "/delete")
                  .then((response) => {
                      return response.json();
                  })
                  .then((response) => {
                      if(this.$checkAuth(response)) {
                          this.$router.push({name: "developers", params: {lang: this.$store.getters.getLang}});
                      }
                  })
                  .catch((err) => {
                      this.httpHandler(err);
                  });
          }
      },

    submitGameMode: function () {
      this.submitDisabledLoad = true;
      this.$http.get("ajax/developers/" + this.$route.params.id + "/moderation")
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if(this.$checkAuth(response)) {
              let developers = this.developers;
              developers.dev_mode = 2;
              this.submitDisabledLoad = false;
          }
        })
        .catch((err) => {
          this.submitDisabledLoad = false;
          this.httpHandler(err);
        });
    },
      submitGameOff: function () {
        this.submitDisabledLoad = true;
        this.$http.get("ajax/developers/" + this.$route.params.id + "/moderation")
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                let developers = this.developers;
                developers.dev_mode = 1;
                this.submitDisabledLoad = false;
            }
          })
          .catch((err) => {
            this.submitDisabledLoad = false;
            this.httpHandler(err);
          });
      },
    updateSecret: function () {
      this.submitDisabled = true;
      let response = {
        secret: 1,
      };
      this.$http.post(this.pathDevelopers + "/update", response)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if(this.$checkAuth(response)) {
              this.status_secret = true;
              this.submitDisabled = false;
              setTimeout(() => {
                  this.status_secret = false;
              }, 2000);
              this.secret = response.data.secret;
          }
        })
        .catch((err) => {
          this.submitDisabled = false;
          let response = this.httpHandler(err);
          if (response.error) {
            this.formValidate = response.error.message;
          }
        });
    },
    updateValue: function (value, name) {
      let valid = this.$v;

      if (valid.$invalid) {
        let massages = {};
        massages[name] = [];
        this.formValidate = this.$formValidate(massages, valid);
      }
      if (!this.formValidate[name]) {
        let response = {};
        switch (name) {
          case "name":
            this.name_input = this.name;
            break;
        }
        response[name] = value;
        this.ajaxUpdate(response, name);
      }
    },
    ajaxUpdate: function (response, name) {
      this.$http.post(this.pathDevelopers + "/update", response)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if(this.$checkAuth(response)) {
              this.status_update[name] = true;
              let developers = this.developers;
              developers[name] = response[name];
              this.$emit("update:developers", developers);

              setTimeout(() => {
                  this.status_update[name] = null;
              }, 2000);
          }
        })
        .catch((err) => {
          let response = this.httpHandler(err);
          if (response.error) {
            this.formValidate = response.error.message;
          }
        });
    },
    updateValidate: function (type, value = null) {
      this.formValidate = this.$changeValidate(
        this.formValidate,
        this.$v[type],
        type
      );

      if (value !== null) {
        this.updateValue(value, type);
      }
    },
  },
  components: {
    // InputLabelTextDefault,
    // DropdownDefault,
    UploadAvatar,
    ModalConfirmActionDefault
  },
};
</script>

<style lang="scss" scoped>

.dark {
    .block-game {
        background: #171827;

        .block-game-head {
            &__status {
                color: #fff;
            }

            &__type {
                color: #fff;
            }

            &__id {
                color: #fff;
            }
        }
    }
}

  .block-game {

      /*&--fixed {*/
          /*position: fixed;*/
          /*top: 88px;*/
          /*right: auto;*/
          /*max-width: 320px;*/
          /*width: 100%;*/
          /*margin-top: 0;*/
      /*}*/

    /*margin-top: 80px;*/
    /*position: fixed;*/
    /*right: auto;*/
    /*  top: 148px;*/
    border-radius: 4px;
    /*background: #F9F9F9;*/
    background: #fff;
    /*border-radius: 8px;*/
    width: 320px;
      /*margin-left: 20px;*/
    /*height: 100%;*/

    .block-game-head {
      text-align: center;
      /*background-image: url("/dist/images/frontend/bg-head-settings.png");*/
      /*border-radius: 8px 0 0 0;*/
      padding: 30px 20px 20px;
      background-size: contain;

      .image {
        background: #e7efff;
        width: 180px;
        height: 180px;
        margin: 0 auto 17px;
        border-radius: 8px;
        cursor: pointer;
        background-size: cover;
        position: relative;

          img {
              border-radius: 8px;
          }

          &:after {
              position: absolute;
              top: 0;
              left: 0;
              content: '';
              display: block;
              opacity: 0;
              width: 180px;
              height: 180px;
              border-radius: 8px;
              background-color: rgba(0, 43, 80, 0.6);
              background-image: url("/dist/images/frontend/change-photo-game.svg");
              background-size: 40px;
              background-position: center;
              transition-duration: .4s;
          }

          &:hover {
              &:after {
                  opacity: 1;
                  transition-duration: .4s;
              }
          }
      }

      /*&__name {*/
      /*  margin-bottom: 7px;*/

      /*  h3 {*/
      /*    font-weight: 400;*/
      /*    font-size: 18px;*/
      /*    line-height: 24px;*/
      /*    text-align: center;*/
      /*    color: #002B50;*/
      /*  }*/
      /*}*/

      &__id   {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #002B50;
        margin-bottom: 6px;

          p {
              color: #26A9F4;
              margin: 0 0 0 4px;
          }
      }

      &__type {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 9px;
        color: #002B50;

        /*span {*/
        /*  color: #26a9f4;*/
        /*}*/

        p {
          color: #26A9F4;
          margin: 0 0 0 4px;
        }
      }

        &__status {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 9px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #002B50;

            p {
                margin: 0 0 0 4px;
            }
        }

      &__statistics {
          /*margin-bottom: 30px;*/

        span {
          text-align: center;
          display: block;
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: -0.0015em;
          color: #6886AF;
        }
      }
    }
  }




.image {
  background: #e7efff;
  min-width: 180px;
  min-height: 180px;
  max-height: 180px;
  margin-right: 25px;
  border-radius: 8px;
  cursor: pointer;
}

.name-application {
  margin: 0 0 4px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #002b50;
}

.v-btn.save-btn {
  margin: 0 0 0 15px;
  padding: 0 10px;
  text-transform: capitalize;
  width: 85px;
  border-radius: 6px;

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 50px;
    padding: 0 10px;
  }
}

.api-desc {
  margin: 2px 0 4px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #002b50;
}
.btn-cab {
  margin-right: 0;
  max-width: 135px;
  width:100%;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 64px;
    max-width: 135px;
    padding: 0 16px;
  }
}

.wrap-block-key {
  margin-right: 45px;
  margin-top: 12px;
  position: relative;
}

.v-btn.refresh-btn {
  outline: none;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.expect {
  background:#F0F6FE;
}

.success {
  max-width: 100px;
  width: 100%;
  text-transform:inherit;
  outline:none;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 100px;
    padding: 0 16px;
  }
}

.btn-off {
  max-width: 100px;
  width: 100%;
  text-transform:inherit;
  outline:none;
  background: #F0F6FE;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 100px;
    padding: 0 16px;
  }


  &:hover {
    background-color: #E7EFFF;
  }
}

.purp {
  max-width: 100px;
  width: 100%;
  text-transform:inherit;
  outline:none;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 100px;
    padding: 0 16px;
  }

}

.v-btn:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  background-color: transparent;
}

  .wrap-block-moderation {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;

    &__status {
      display: flex;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6886AF;
        margin: 0 7px 0 0;
      }

      span {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #FF4B55;
      }
    }

    .btn-secondary.btn-on {
      color: #F6A401!important;
      border: none;
      background-color: rgba(246, 164, 1, 0.1);
      border-radius: 6px;
      transition-duration: .3s;

      svg {
        margin: 3px 10px 0 0;
      }

      &:hover {
        color: #f68e0a !important;
        background-color: rgba(246, 164, 1, 0.2);
        transition-duration: .3s;
      }
    }

    .status-btn {
      color: #F6A401;
      text-transform: inherit;
      background: transparent;
      border-radius: 6px;
      outline: none;
      cursor: default;

      svg {
        margin: 3px 10px 0 0;
      }
    }
  }

    @media screen and (max-width: 1024px){
        .block-game {
            width: 300px;
        }
    }

    @media screen and (max-width: 950px) {
        .block-game {
            width: 100%;

            .block-game-head {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
</style>
