<template>
  <div class="nft-header">
    <div class="dashboard-content">
      <div class="block-header" >
          <div v-if="auth" class="auth">
              <img v-if="auth.photo && typeof auth.photo == 'object'" :src="auth.photo[40]" />
              <img v-else-if="auth.photo" :src="auth.photo" />
              <span v-else class="photo" :style="{'background': auth.bg_color}">{{auth.name.substr(0,1)}}</span>
              <div>
                <p class="name">{{auth.name}}</p>

                <div v-if="metamask">
                  <div v-if="address">
                    <p class="wallet">
                      {{address}}
                    </p>

                    <div v-if="auth.web3_address">
                      <p v-if="auth.web3_address.toLowerCase()== address.toLowerCase()">Подключино</p>
                      <div v-else>
                        <p>Подключен другой адрес: <span>{{auth.web3_address.toLowerCase()}}</span></p>
                        <BaseButton :loading="loading" v-on:click="approveAddress"><span>Connect</span></BaseButton>
                      </div>
                    </div>
                    <div v-else>
                      <p>Не подключено</p>
                      <BaseButton :loading="loading" v-on:click="approveAddress"><span>Connect</span></BaseButton>
                    </div>

                  </div>
                  <div v-else-if="metamask['error']">
                    <p class="name">{{metamask['message']}}</p>
                    <div class="header-btn" v-if="metamask['error']== 'network'">
                      <!--            <v-btn class="btn-liteGrey" v-on:click="modal_cancel= false"><span>Cancel</span></v-btn>-->
                      <BaseButton v-on:click="switchNetwork" :loading="switch_network"><span>Switch network</span></BaseButton>
                    </div>
                    <div class="header-btn" v-else-if="metamask['error']== 'account'">
                      <!--            <v-btn class="btn-liteGrey" v-on:click="modal_cancel= false"><span>Cancel</span></v-btn>-->
                      <BaseButton v-on:click="connectAccount" :loading="switch_network"><span>Connect account</span></BaseButton>
                    </div>
                    <div v-else-if="switch_network">
                      <p class="name">Ожидание подтверждения MetaMask</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="name">no have metamask 1</p>
                </div>
              </div>
          </div>
          <div v-else-if="!auth">
              <p class="name">Войдите в аккаунт Mindplays</p>
              <div class="header-btn">
                  <BaseButton v-on:click="$store.commit('updateModalAuth', 'login')"><span>Логин</span></BaseButton>
              </div>
          </div>

      </div>
      <div class="header-tab">
        <router-link exact :to="{name: 'nft_list'}">NFT marketplace</router-link>
        <router-link v-if="auth" exact :to="{name: 'nft_list', params: {type: 'favorite'}}">My Favorite NFT</router-link>
        <a v-else v-on:click="$store.commit('updateModalAuth', 'login')">My Favorite NFT</a>
      </div>
      <div class="meta" v-if="address">
<!--        <img src="/dist/images/frontend/meta-mask.svg" alt="">-->
        <a v-if="!auth" v-on:click="$store.commit('updateModalAuth', 'login')">My NFT</a>
        <router-link v-else exact :to="{name: 'my_nft'}">My NFT</router-link>
      </div>
    </div>
  </div>
</template>


<script>


import {MarketPlaceMixin} from "../../../mixins/MarketPlaceMixin";

export default {
  name: "NftHeader",
  data() {
    return {
        address: null,
        metamask: null,
        loading: false
    };
  },

  watch: {

  },
  created() {
      this.address= (window.ethereum && window.ethereum.selectedAddress) ? window.ethereum.selectedAddress : null;
      if(window.ethereum){
          // this.accounts = window.ethereum.request({
          //     method: 'eth_requestAccounts',
          // });
          window.ethereum.on('accountsChanged', () => {
              // console.log('accountsChanged');
              window.location.reload();
              // this.switch_network= false;
              // this.details();
              return true;
          });
          window.ethereum.on('chainChanged', () => {
              // console.log('chainChanged');
              window.location.reload();
              // this.switch_network= false;
              // this.details();
              return true;
          });
      }

      this.details();
  },
  mixins: [MarketPlaceMixin],
  computed: {
      auth() {
          return this.$store.state.auth.user
      },
  },
  methods: {
  },
  components: {

  },
};
</script>