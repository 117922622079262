<template>
  <div class="wrap-basic-settings">
    <div class="block-box">
      <div class="block-box__head">
        <div class="block-box__icon">
          <img src="/dist/images/frontend/password-icon.svg" alt />
        </div>

        <div class="block-box__head-content">
          <h2>{{__('Password and profile security')}}</h2>
          <p>{{__('Protect your profile: change your login password in a timely manner')}}</p>
        </div>
      </div>

      <div class="block-box__body">
        <div class="block-btn">
          <FormChangePassword></FormChangePassword>
        </div>
      </div>
    </div>

    <div class="block-box">
      <div class="block-box__head">
        <div class="block-box__icon">
          <img src="/dist/images/frontend/QR-icon.svg" alt />
        </div>

        <div class="block-box__head-content">
          <h2 v-if="setting.google_two_step == 1">{{__('Disable 2FA authentication')}}</h2>
          <h2 v-else>{{__('Enable 2FA authentication')}}</h2>
          <p>{{__('You can set up two-factor authentication')}}</p>
        </div>
      </div>

      <div class="block-box__body">
        <FormGoogle2fa v-bind:setting.sync="setting" @updateEnable="updateEnable"></FormGoogle2fa>
      </div>
    </div>

    <div class="block-box">
      <div class="block-box__head">
        <div class="block-box__icon">
          <img src="/dist/images/frontend/email.svg" alt />
        </div>

        <div class="block-box__head-content">
          <h2 v-if="setting.email_two_step == 1">{{__('Disable Email verification')}}</h2>
          <h2 v-else>{{__('Enable Email verification')}}</h2>
          <p>{{__('You can set up email verification')}}</p>
        </div>
      </div>

      <div class="block-box__body">
        <FormEmailVerification v-bind:setting.sync="setting"></FormEmailVerification>
      </div>
    </div>

  </div>
</template>

<script>
import FormChangePassword from "./FormChangePassword";
import FormGoogle2fa from "./FormGoogle2fa";
import FormEmailVerification from "./FormEmailVerification";
    export default {

      name: 'SettingsPrivacy',
      data: function () {
        return {
          twoStep: Number(this.setting.google_two_step),
          items: ['English', 'Русский', 'Укріїнська'],
          e1: "English"
        }
      },
      props: {
        setting: {
          type: Object
        }
      },
      methods: {
          updateEnable(enable) {
              let setting= this.setting;
              setting.google_two_step= enable;

              this.$emit('update:setting', setting);
          },
      },
      components: {
        FormChangePassword,
        FormGoogle2fa,
        FormEmailVerification
      }
    }
</script>
<style lang="scss" scoped>


.wrap-basic-settings {
  /*padding: 40px 40px 0;*/
}

.block-box {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 20px;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-right: 21px;
  }

  &__head-content {

    h2 {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #002B50;
      margin-bottom: 2px;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #6886AF;
      margin-bottom: 0;
    }
  }

  &__body {
    margin-left: 67px;


    .block-btn {
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      border-bottom: 1px solid #ECF4FF;


      &--language {
        border-bottom: none;
        margin: -16px 0 -10px;
        padding: 0;

        .v-select__selections {
          max-width: 100px;
        }
      }
    }

    .block-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: #F0F6FE;
      border-radius: 8px;
      margin-right: 14px;

      &:last-child {
        margin-top: -10px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #6886AF;
      margin-bottom: 0;
    }

    .block-switch {

      &--notification {
        padding-bottom: 17px;
        border-bottom: 1px solid #ECF4FF;
        margin-bottom: 15px;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    .block-language {
      display: flex;
      align-items: center;
      max-width: 150px;
      margin-left: 27px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #002B50;

      img {
        margin-left: -35px;
        margin-top: 2px;
        transition-duration: .3s;
      }

      .v-select--is-menu-active ~img {
        transform: rotate(180deg);
        transition-duration: .3s;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .block-box {
    margin-bottom: 20px;


    &__icon {
      display: none;
    }

    &__body {
      margin-left: 0;
    }
    &__head-content {
      h2 {
        font-size: 16px;
        line-height: 22px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}

.dark .block-box {
  &__icon {
    background-color: rgba(38, 169, 244, 0.1);
  }

  &__head-content {
    h2 {
      color: #fff;
    }
    p {
      color: #96AFD1;
    }
  }

  &__body {
    .block-btn {
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    }
  }
}


</style>