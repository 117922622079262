<template>
  <div class="input-country">
    <v-autocomplete
      color="light-blue"
      dense
      :label="label"
      :placeholder="placeholder"
      :items="$countries"
      item-value="key"
      :filter="customFilter"
      @change="countryChange"
      @focusout="countryFocus"
      v-model="country"
    >
      <template v-slot:selection="data">
        <img class="mr-1 flag" width="14" :src="'/dist/images/flags/'+data.item.key.toLowerCase()+'.png'"/>
        {{data.item.value}}
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              <img class="mr-1 flag" width="14" :src="'/dist/images/flags/'+data.item.key.toLowerCase()+'.png'"/>
              {{data.item.value}}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <div>
      <p class="desc-error" v-for="(value, key) in errors" :key="key">{{value}}</p>
    </div>
    <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
  </div>
</template>

<script>


export default {
  name: "DropdownCountry",
  data() {
    return {
        country: this.value
      // dropdown_font: ['English', 'Russian'],
    };
  },
  props: {
      value: {
          type: String,
      },
      errors: {
          type: Array
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
  },
  computed: {

  },
  watch: {
      country(val) {
          this.$emit('updateCountry', val);
      },
  },
  methods: {
    customFilter (item, queryText) {
        const textOne = item.value.toLowerCase();
        const searchText = queryText.toLowerCase();

        return textOne.indexOf(searchText) > -1
    },
    countryChange(change) {
        if(change) {
            this.country_select= change;
        }
    },
    countryFocus() {
        this.country= this.country_select;
    },
  },
};
</script>

<style lang="scss">

  .input-country .v-select--is-menu-active ~ .chevron-icon {
    transform: rotate(180deg);
    transition-duration: .3s;
  }

  .input-country {
    position: relative;

    .chevron-icon {
      position: absolute;
      right: 4px;
      top: 13px;
      transition-duration: .3s;
    }
  }
</style>