<template>
  <div class="table-logs">
    <table width="100%" height="100%">
      <thead class="text-left">
        <tr>
          <th v-for="(item, key) in headers" :key="key">{{__(item)}}</th>
        </tr>
      </thead>
      <tbody v-if="body">
        <slot v-for="(item) in body" v-bind="item"></slot>
      </tbody>
      <slot v-else name="result"></slot>
    </table>
  </div>
</template>

<script>
    export default {
        name: 'Table',
        data () {
            return {

            }
        },
        props: {
            headers: {
                type: Array,
            },
            body: {
                type: [Array, Object],
            },
        },
        created: function () {

        },
        methods:{

        },
        computed: {

        },
        components: {

        }
    }
</script>

<style lang="scss">

  .dark .table-logs {
    table {
      background-color: transparent;
    }

    thead {
      background-color: transparent;
    }

    thead th {
      color: #96AFD1;
    }

    tr {
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);;
    }

    td {
      color: #fff;
    }
  }

  .table-logs {

    table {
      background-color: #fff;
      height: 100%;
      border-radius: 0 0 6px 0;

    }

    thead {
      background-color: #fff;
    }

    thead th {
      padding: 18px 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #6886AF;
      border: none;

      &:first-child {
        padding-left: 20px;
      }
    }

    tbody {
      border-bottom: none;
    }

    tr {
      border-bottom: 1px solid #ECF4FF;
      //cursor: pointer;
    }

    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #002B50;
      padding: 10px 0;
      border-top: none;

      &:last-child {
        max-width: 50px;
      }

      &:first-child {
        padding-left: 20px;
      }
    }
    /*td:first-child {*/
    /*  padding-left: 20px;*/
    /*}*/
  }

  @media screen and (max-width: 1024px){
    .table-logs {
      thead  th {
        &:first-child {
          padding-left: 0;
        }
      }

      td {
        &:first-child{
          padding-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 600px){
    .table-logs {
      thead  th {
        padding: 0 10px 10px 0;
        font-size: 11px;
        &:nth-child(2) {
          display: none;
        }

        &:last-child {
          text-align: right;
        }
      }

      td {
        padding-right: 10px;
        font-size: 12px;

        &:nth-child(2) {
          display: none;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

</style>
