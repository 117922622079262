<template>
    <div class="wrapper-wallets">

        <div v-if="wallets && wallets.length === 0" class="block-empty-wallets">
            <img class="block-empty-wallets__img" src="/dist/images/frontend/card-img.svg" alt="">
            <span class="block-empty-wallets__text">{{__('You do not have withdraw methods yet')}}</span>
            <BaseButton @click="pushForm">
                <span>{{__('Add payment method')}}</span>
            </BaseButton>
        </div>

        <div v-else class="block-wallets">
            <Payment v-for="(item, key) in wallets" :key="key" v-bind:payments="payments" v-bind:payment_id="key">
                <template v-slot="payment">
                    <div v-if="payment.cryptocurrency == '1'">
                        <WalletsItem @remove-wallet="removeWallet" :item="item" :payment="payment"></WalletsItem>
                    </div>
                    <div v-else v-for="(i, k) in item" :key="k">
                        <WalletsItem @remove-wallet="removeWallet" :item="i" :payment="payment"></WalletsItem>
                    </div>
                </template>
            </Payment>
        </div>

    </div>
</template>

<script>
import WalletsItem from "./WalletsItem";
import Payment from "../../modules/Payment";
    export default {
        data() {
            return {

            }
        },
        methods: {
            pushForm() {
                this.$router.push({
                    name: "settings_current",
                    params: {current: 'add_wallet', lang: this.$store.getters.getLang },
                });
            },
            removeWallet(wallet, payment) {
                this.$emit("remove-wallet", wallet, payment);
            }
        },
        props: {
            payments: {
                type: Array
            },
            wallets: {
                type: [Object, Array]
            }
        },

        created() {

        },
        components: {
            WalletsItem,
            Payment
        }
    }
</script>

<style lang="scss" scoped>
.wrapper-wallets {
    .block-empty-wallets {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
        &__img {
            margin-bottom: 30px;
        }
        &__text {
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 26px;
        }
    }

    .block-wallets {
        margin: -20px;
    }
}
    
.dark .wrapper-wallets {
    .block-empty-wallets {
        &__text {
            color: #fff;
        }
    }
}
</style>