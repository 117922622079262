<template>
  <div class="d-flex">
    <button
        :class="class_btn"
        :style="{'minWidth': width + 'px', 'height': height + 'px', 'fontSize': font + 'px'}"
        class="baseButton-dis"
        v-if="loading"
        disabled
    >
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </button>
    <a
        :class="class_btn"
        :style="{'minWidth': width + 'px', 'height': height + 'px', 'fontSize': font + 'px'}"
        v-else-if="disabled"
        class="baseButton disabled"
        disabled
    >
      <slot/>
    </a>
    <a
      :class="class_btn"
      :style="{'minWidth': width + 'px', 'height': height + 'px', 'fontSize': font + 'px'}"
      v-on="inputListeners"
      :href="href"
      :target="target"
      v-else-if="href"
      class="baseButton"
      @mousemove="hoverStyle($event)"
    >
      <slot/>
    </a>
    <router-link
      :class="class_btn"
      :style="{'minWidth': width + 'px', 'height': height + 'px', 'fontSize': font + 'px'}"
      :to="to"
      v-else-if="to"
      class="baseButton"
      @mousemove="hoverStyle($event)"
    >
      <slot/>
    </router-link>
    <button
        :class="class_btn"
        :style="{'minWidth': width + 'px', 'height': height + 'px', 'fontSize': font + 'px'}"
        v-on="inputListeners"
        v-else
        class="baseButton"
        @mousemove="hoverStyle($event)"
    >
      <slot/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  data() {
    return {

    }
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: Object,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
    },
    height: {
      type: [Number, String],
    },
    font: {
      type: [Number, String],
    },
    class_btn: {
      type: String,
    },
  },

  computed: {
    inputListeners: function () {
      // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
      return Object.assign(
          {},
          // Мы добавляем все слушатели из родителя
          this.$listeners,
          // Затем мы можем добавить собственные слушатели или
          // перезаписать поведение некоторых существующих.
      );
    },
  },

  // mounted() {
  //   this.$refs.qwerty.onmousemove = function (e) {
  //     let x = e.pageX - e.target.getBoundingClientRect().left;
  //     let y = e.pageY - e.target.getBoundingClientRect().top;
  //     e.target.style.setProperty('--x', x + 'px');
  //     e.target.style.setProperty('--y', y + 'px');
  //   }
  // },

  methods: {
    hoverStyle(e) {
      let x = e.pageX - e.target.getBoundingClientRect().left;
      // let y = e.pageY - e.target.getBoundingClientRect().top;
      e.target.style.setProperty('--x', x + 'px');
      // e.target.style.setProperty('--y', y + 'px');
    },
  }

}
</script>

<style lang="scss">
  .baseButton.btn-video {
    border: 1px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    span {
      color: #ffffff!important;
    }
    &:before {
      background: radial-gradient(circle closest-side, #181818, transparent);
    }
  }
  .baseButton.btn-dev {
    border: 1px solid #26A9F4;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    span {
      color: #26A9F4!important;
    }
    &:before {
      background: radial-gradient(circle closest-side, #181818, transparent);
    }
  }

  .baseButton.disabled {
    opacity: 0.7;
    &:before {
      background: none;
    }
  }

.baseButton.btn-guest {
  border-radius: 8px;
  padding: 0 10px;

  span {
    margin-bottom: 1px;
  }
}
.baseButton-dis.btn-guest {
  border-radius: 8px;
}

.baseButton-dis {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #0F9AFF;
  padding: 0 16px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  //border-radius: 6px;
  border-radius: 25px;
  height: 40px;
}



.baseButton.redBtn {
  background-color: #FF4B55;
  color: #fff;

  &:before {
    background: radial-gradient(circle closest-side, #E94F4E, transparent);
  }
}

.baseButton.btn-guest-grey {
  background: linear-gradient(87.63deg, #1C2530 0.88%, #2C3A4E 99.45%);
  border-radius: 5px;
  color: #fff;

  &:before {
    background: radial-gradient(circle closest-side, #6886af, transparent);
  }
}

.baseButton.baseButton-game {
  background-color: #FDAE02;
  color: #fff;

  &:before {
    background: radial-gradient(circle closest-side, #FD8C02, transparent);
  }
}

.baseButton.blue-btn {
  background-color: #DDF9FF;
  color: #000!important;

  &:before {
    background: radial-gradient(circle closest-side, #BCF4FF, transparent);
  }
}

.baseButton.btn-silver {
  background: #183762;
  box-shadow: 0px 2px 5px rgba(51, 97, 255, 0.03);
  border-radius: 4px;
  color: #DBE9FB!important;

  span {
    img {
      width: 20px;
    }
  }

  &:before {
    background: radial-gradient(circle closest-side, #1b4379, transparent);
  }
}

.baseButton.btn-gold-round {
  background: rgba(253, 174, 2, 0.1);
  box-shadow: 0px 2px 5px rgba(51, 97, 255, 0.03);
  border-radius: 25px;
  color: #FDAE02!important;

  span {
    img {
      width: 20px;
    }
  }

  &:before {
    background: radial-gradient(circle closest-side, rgba(253, 174, 2, 0.3), transparent);
  }
}

.baseButton.btn-gold {
  background: rgba(253, 174, 2, 0.1);
  box-shadow: 0px 2px 5px rgba(51, 97, 255, 0.03);
  border-radius: 4px;
  color: #FDAE02!important;

  span {
    img {
      width: 20px;
    }
  }

  &:before {
    background: radial-gradient(circle closest-side, rgba(253, 174, 2, 0.3), transparent);
  }
}

.baseButton {
  text-align: center;
  width: 100%;
  display: inline-block;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #026EED;
  padding: 0 16px;
  border: none;
  color: white!important;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  //border-radius: 6px;
  border-radius: 25px;
  height: 40px;

  &:after{
    position: absolute;
    content: '';
    width: 0%; height: 100%;
    background: rgba(246, 250, 254, 0.2);
    top: 0; left: 50%;
    //z-index: -1;
    transition: all 0ms ease;
  }

  &:active {
    color: white;
    box-shadow:none;

    &:after {
      position: absolute;
      content: '';
      width: 100%; height: 100%;
      background: rgba(246, 250, 254, 0.2);
      top: 0; left: 0%;
      //z-index: -1;
      transition: all 200ms ease;
    }
  }
}

.baseButton.small-btn {
  height: 36px;
  border-radius: 8px;
}

.baseButton.grey-btn {
  background: #96afd1;
}
.baseButton.grey-btn::before {
  background: radial-gradient(circle closest-side, #6886af, transparent);
}

.baseButton-dis.grey-btn {
  background: #96afd1;
}

.baseButton.grey-btn-game {
  background: #96afd1;
}
.baseButton.grey-btn-game::before {
  background: radial-gradient(circle closest-side, #6886af, transparent);
}

.baseButton-dis.grey-btn-game {
  background: #96afd1;
}

.dark .baseButton.white-btn {
  background-color: #191919;

  span {
    color: #96AFD1!important;
  }

  &:before {
    background: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.07), transparent);
  }
}

.baseButton.dark-btn {
  background: #222338;
  border: 1px solid #212232;
  border-radius: 8px;
  padding: 0 10px;

  span {
    color: #96AFD1;
  }

  &:before {
    background: radial-gradient(circle closest-side, #181818, transparent);
  }
}

.baseButton.white-btn {
  background-color: #fff;

  span {
    color: #6886AF!important;
    font-size: 14px!important;
    line-height: 16px;
  }

  &:before {
    background: radial-gradient(circle closest-side, #f7f7f7, transparent);
  }
}

.baseButton.white-btn-blue {
  background-color: #fff;

  span {
    color: #26A9F4!important;
    font-size: 14px!important;
  }

  &:before {
    background: radial-gradient(circle closest-side, #f7f7f7, transparent);
  }

  &--blue {
    span {
      color: #26A9F4!important;
    }
  }
}

.baseButton.white-btn-blue-guest {
  background-color: #fff;
  border-radius: 4px;

  span {
    color: #26A9F4!important;
    font-size: 14px!important;
  }

  &:before {
    background: radial-gradient(circle closest-side, #f7f7f7, transparent);
  }

  &--blue {
    span {
      color: #26A9F4!important;
    }
  }
}

.baseButton.white_btn {
  background-color: #EBF2FA;

  span {
    color: #000000!important;
  }

  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}

.baseButton.white_btn-round {
  background-color: #EBF2FA;
  border-radius: 50%;
  padding: 0;

  span {
    color: #000000!important;

    svg,
    img {
      margin: 0;
    }
  }

  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}

.baseButton.darkGrey-btn {
  background: rgba(255, 255, 255, 0.2);

  &:before {
    background: radial-gradient(circle closest-side, rgba(255, 255, 255, 0.1), transparent);
  }
}

.baseButton.btn-liteGrey-quest {
  background-color: #E3EDFE;
  border-radius: 8px;
  span {
    color: #6886AF!important;
  }
  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}

.baseButton-dis.btn-liteGrey-quest {
  background: #ECF4FF;
  border-radius: 4px;
}

.baseButton.btn-liteGrey {
  background-color: #E3EDFE;
  span {
    color: #131313!important;
  }
  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}

.baseButton-dis.btn-liteGrey {
  background: #ECF4FF;
}

.baseButton.square-grey-btn {
  background-color: #E0ECFD;
  border-radius: 4px;
  span {
    color: #6886AF!important;
    font-weight: 500;
    font-size: 12px!important;
    line-height: 16px;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin: 0 2px 0 4px;
    }

    img {
      height: 12px;
      margin-bottom: 2px;
    }
  }
  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}

.baseButton.btn-liteGrey-game {
  background-color: #E3EDFE;
  border-radius: 4px;
  span {
    color: #131313!important;
  }
  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}



.baseButton-dis.btn-liteGrey-game {
  background: #ECF4FF;
  border-radius: 4px;
}

.baseButton.btn-liteGrey-game-round {
  background-color: #E3EDFE;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  span {
    svg,
    img {
      margin: 0;
      width: 10px;
    }
  }
  &:before {
    background: radial-gradient(circle closest-side, #e1eaff, transparent);
  }
}

.baseButton-dis.btn-liteGrey-game-round {
  background: #ECF4FF;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.baseButton.btn-green {
  background-color: #00BA3F;
  border-radius: 8px;

  &:before {
    background: radial-gradient(circle closest-side, #00C443, transparent);
    border-radius: 25px;
  }
}

.baseButton-dis.btn-green {
  background: #00D84A;
  border-radius: 4px;
}

.baseButton.btn-base-green {
  background-color: #00D84A;
  border-radius: 25px;

  &:before {
    background: radial-gradient(circle closest-side, #00C443, transparent);
    border-radius: 25px;
  }
}

.baseButton-dis.btn-base-green {
  background: #00D84A;
  border-radius: 25px;
}

.baseButton.btn-green-round {
  background-color: #00D84A;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  span {
    svg,
    img {
      margin: 0;
      width: 12px;
    }
  }

  &:before {
    background: radial-gradient(circle closest-side, #00C443, transparent);
    border-radius: 25px;
  }
}

.baseButton-dis.btn-green-round {
  background: #00D84A;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.baseButton.btn-red {
  background-color: #FF4B55;

  &:before {
    background: radial-gradient(circle closest-side, #E94F4E, transparent);
    border-radius: 25px;
  }
}
.baseButton-dis.btn-red {
  background: #FF4B55;
}

.baseButton.btn-red-quest {
  background-color: #FF4B55;
  border-radius: 4px;

  &:before {
    background: radial-gradient(circle closest-side, #E94F4E, transparent);
    border-radius: 4px;
  }
}
.baseButton-dis.btn-red-quest {
  background: #FF4B55;
  border-radius: 4px;
}

.baseButton img {
  position: relative;
  pointer-events: none;
}
.baseButton span {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
  font-size: 14px!important;
  line-height: 16px;
  height: 100%;

  p {
    margin: 0;
  }

  img,
  svg {
    margin-right: 8px;
    margin-bottom: 0;
  }
}
.baseButton::before {
  --size: 0;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #008DF2, transparent);
  -webkit-background: radial-gradient(circle closest-side, #008DF2, transparent);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: width .2s ease, height .2s ease;
}


.baseButton:hover::before {
  --size: 200px;
}

//LOADER
.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 1250px) {
  /*.baseButton.grey-btn-game,*/
  /*.baseButton.baseButton-game {*/
    /*min-width: 65px!important;*/
    /*span {*/
      /*p {*/
        /*display: none;*/
      /*}*/

      /*img,*/
      /*svg {*/
        /*margin: 0!important;*/
      /*}*/
    /*}*/
  /*}*/
}

@media screen and (max-width: 650px){
  .baseButton.grey-btn-game {
    min-width: 64px!important;
    span {
      p {
        display: none;
      }

      img,
      svg {
        margin-right: 0;
      }
    }
  }

  .baseButton-dis.grey-btn-game {
    min-width: 64px!important;
  }

  .baseButton.baseButton-game {
    min-width: 64px!important;
    span {
      p {
        display: none;
      }

      img,
      svg {
        margin-right: 0;
      }
    }
  }
  .baseButton-dis.baseButton-game {
    min-width: 64px!important;
  }
}

  @media screen and (max-width: 450px) {
    .baseButton {
      padding: 0 12px;
      height: 36px;

      span {
        font-size: 12px!important;
        line-height: 14px;
      }
    }
  }

  @media screen and (max-width: 350px) {
    .baseButton.btn-gold,
    .baseButton.btn-silver {
      padding: 0 10px;
    }
  }

</style>