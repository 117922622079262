
<template>
  <div class="block-login">
    <div class="wrap-head">
<!--      <p>-->
<!--        Continue with social network-->
<!--      </p>-->
<!--      <div class="d-flex justify-space-between wrap-social-btn">-->
<!--        <v-btn-->
<!--                text-->
<!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                class="social-btn"-->
<!--                v-for="(social, key) in socialAuth"-->
<!--                :key="key"-->
<!--                tag="a"-->
<!--                :href="social.url"-->
<!--        >-->
<!--          <img :src="'/dist/images/frontend/'+social.icon" />-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      <img src="/dist/images/frontend/LogoHome.svg" class="logo img-responsive " alt="mindpalys">-->
        <div class="wrap-title d-flex flex-column align-center">
            <h1 class="text-darker ma-0">{{__('Login')}}</h1>
            <p class="text-reg">{{__('fast entry')}}</p>
            <nav class="nav">
                <a class="btn btn-rounded btn-outline-secondary brand-google"  :href="'/login/google'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                    <i class="fab fa-google"></i>
                    <span>Google</span>
                </a>
                <a class="btn btn-rounded btn-outline-secondary brand-facebook "  :href="'/login/facebook'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                    <i class="fab fa-facebook-f"></i>
                    <span>Facebook</span>
                </a>
                <a class="btn btn-rounded btn-outline-secondary brand-discord " :href="'https://discord.com/oauth2/authorize?client_id=960886664694235189&redirect_uri=https://mindplays.com/login/discord'+(($route.name== 'game') ?  '/'+$route.params.id : '')+'&response_type=code&scope=identify%20email'">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1774_7075)">
                            <path d="M16.9419 3.79664C15.6303 3.18295 14.2427 2.74689 12.8158 2.5C12.638 2.82133 12.4304 3.25352 12.2872 3.59734C10.7487 3.36602 9.22445 3.36602 7.71429 3.59734C7.57117 3.25359 7.35883 2.82133 7.17945 2.5C5.75116 2.747 4.36239 3.18417 3.05015 3.79984C0.438901 7.74586 -0.26899 11.5938 0.0849165 15.3872C1.81687 16.6805 3.49531 17.4663 5.14547 17.9804C5.55557 17.4164 5.91809 16.8193 6.22929 16.1953C5.63682 15.9698 5.06564 15.6918 4.52257 15.3647C4.66549 15.2588 4.80504 15.1484 4.94101 15.0337C8.2318 16.5729 11.8074 16.5729 15.059 15.0337C15.1956 15.1476 15.3351 15.258 15.4773 15.3647C14.9334 15.6926 14.3612 15.9712 13.7675 16.197C14.0805 16.8235 14.4423 17.4212 14.8513 17.982C16.503 17.4679 18.1831 16.6822 19.915 15.3872C20.3303 10.9897 19.2056 7.17703 16.9419 3.79656V3.79664ZM6.67765 13.0543C5.68976 13.0543 4.87961 12.132 4.87961 11.009C4.87961 9.88594 5.6725 8.96211 6.67765 8.96211C7.68289 8.96211 8.49297 9.8843 8.4757 11.009C8.47726 12.132 7.68289 13.0543 6.67765 13.0543ZM13.3223 13.0543C12.3344 13.0543 11.5243 12.132 11.5243 11.009C11.5243 9.88594 12.3171 8.96211 13.3223 8.96211C14.3275 8.96211 15.1376 9.8843 15.1203 11.009C15.1203 12.132 14.3275 13.0543 13.3223 13.0543Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1774_7075">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Discord</span>
                </a>
                <a class="btn btn-rounded btn-outline-secondary brand-twitter"  :href="'/login/vkontakte'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                    <i class="fab fa-vk"></i>
                    <span>Vkontakte</span>
                </a>
            </nav>
        </div>
        <div class="social-mob-login">
            <p class="small bold mob-text">{{__('Or sign in with')}}</p>
            <div class="d-flex justify-content-center wrap-social-btn">
<!--                <nav class="nav mb-4">-->
<!--                    <a class="btn btn-rounded btn-outline-secondary brand-facebook mr-2" href="#">-->
<!--                        <i class="fab fa-facebook-f"></i>-->
<!--                    </a>-->
<!--                    <a class="btn btn-rounded btn-outline-secondary brand-twitter mr-2" href="#">-->
<!--                        <i class="fab fa-twitter"></i>-->
<!--                    </a>-->
<!--                    <a class="btn btn-rounded btn-outline-secondary brand-linkedin" href="#">-->
<!--                        <i class="fab fa-linkedin-in"></i>-->
<!--                    </a>-->
<!--                </nav>-->
                <nav class="nav ">
                    <a class="btn btn-rounded btn-outline-secondary brand-google"  :href="'/login/google'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                        <i class="fab fa-google"></i>
                        <span>Google</span>
                    </a>
                    <a class="btn btn-rounded btn-outline-secondary brand-facebook "  :href="'/login/facebook'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                        <i class="fab fa-facebook-f"></i>
                        <span>Facebook</span>
                    </a>
                    <a class="btn btn-rounded btn-outline-secondary brand-discord " :href="'https://discord.com/oauth2/authorize?client_id=960886664694235189&redirect_uri=https://mindplays.com/login/discord'+(($route.name== 'game') ?  '/'+$route.params.id : '')+'&response_type=code&scope=identify%20email'">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1774_7075)">
                                <path d="M16.9419 3.79664C15.6303 3.18295 14.2427 2.74689 12.8158 2.5C12.638 2.82133 12.4304 3.25352 12.2872 3.59734C10.7487 3.36602 9.22445 3.36602 7.71429 3.59734C7.57117 3.25359 7.35883 2.82133 7.17945 2.5C5.75116 2.747 4.36239 3.18417 3.05015 3.79984C0.438901 7.74586 -0.26899 11.5938 0.0849165 15.3872C1.81687 16.6805 3.49531 17.4663 5.14547 17.9804C5.55557 17.4164 5.91809 16.8193 6.22929 16.1953C5.63682 15.9698 5.06564 15.6918 4.52257 15.3647C4.66549 15.2588 4.80504 15.1484 4.94101 15.0337C8.2318 16.5729 11.8074 16.5729 15.059 15.0337C15.1956 15.1476 15.3351 15.258 15.4773 15.3647C14.9334 15.6926 14.3612 15.9712 13.7675 16.197C14.0805 16.8235 14.4423 17.4212 14.8513 17.982C16.503 17.4679 18.1831 16.6822 19.915 15.3872C20.3303 10.9897 19.2056 7.17703 16.9419 3.79656V3.79664ZM6.67765 13.0543C5.68976 13.0543 4.87961 12.132 4.87961 11.009C4.87961 9.88594 5.6725 8.96211 6.67765 8.96211C7.68289 8.96211 8.49297 9.8843 8.4757 11.009C8.47726 12.132 7.68289 13.0543 6.67765 13.0543ZM13.3223 13.0543C12.3344 13.0543 11.5243 12.132 11.5243 11.009C11.5243 9.88594 12.3171 8.96211 13.3223 8.96211C14.3275 8.96211 15.1376 9.8843 15.1203 11.009C15.1203 12.132 14.3275 13.0543 13.3223 13.0543Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1774_7075">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>Discord</span>
                    </a>
                    <a class="btn btn-rounded btn-outline-secondary brand-twitter"  :href="'/login/vkontakte'+(($route.name== 'game') ? '?game_url='+$route.params.id : '')">
                        <i class="fab fa-vk"></i>
                        <span>Vkontakte</span>
                    </a>
                </nav>
            </div>
        </div>
      <p class="text-secondary mt-0 mob-text-none mb-6">{{__('Dont have an account yet?')}}
        <a
           class="text-primary bold"
           v-on:click="$store.commit('updateModalAuth', 'register')"
        >{{__('Create it here')}}</a>
      </p>
    </div>
    <div>
      <div>
        <InputLabelTextDefault
          color="auth"
          :label="__('E-mail address')"
          v-model="email"
          v-bind:errors="formValidate.email"
          type="email"
          name="email"
          @change="updateValidate('email')"
        ></InputLabelTextDefault>
      </div>
      <div class="wrap-input">
        <InputLabelTextDefault
          color="auth"
          :label="__('Password')"
          switchType
          type="password"
          v-model="password"
          v-bind:errors="formValidate.password"
          @change="updateValidate('password')"
        ></InputLabelTextDefault>
      </div>
      <div class="btn-login">
        <a
          class="d-block forgot"
          v-on:click="$store.commit('updateModalAuth', 'forgot')"
        >{{__('Forgot Password?')}}</a>
<!--        <v-btn-->
<!--          class="btn-main white&#45;&#45;text"-->
<!--          v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--          block-->
<!--          text-->
<!--          v-on:click="onSubmit"-->
<!--          :loading="submitDisabled"-->
<!--        >-->
<!--          <img src="/dist/images/frontend/sign.svg" alt />-->
<!--          Sign In-->
<!--        </v-btn>-->
        <BaseButton
           class="new-btn"
          class_btn="btn-guest"

          block
          text
          v-on:click="openCaptcha"
          :loading="submitDisabled"
        >
          <span>
<!--            <img src="/dist/images/frontend/sign.svg" alt />-->
            {{__('Sign In')}}
          </span>
        </BaseButton>
      </div>
      <div class="social-mob-login">
          <p class="text-secondary mt-0 mt-4">{{__('Dont have an account yet?')}}
              <a
                  class="text-primary bold"
                  v-on:click="$store.commit('updateModalAuth', 'register')"
              >{{__('Create it here')}}</a>
          </p>
      </div>
    </div>
  </div>
</template>


<script>
import InputLabelTextDefault from "./InputLabelTextDefault";
import BaseButton from "./BaseButton";
import {
  required,
  email,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "ModalAuthFormLogin",
  data() {
    return {
      email: null,
      password: null,
      submitDisabled: false,
      formValidate: {
        email: null,
        password: null,
      },
      rules: this.validateMessage,
    };
  },
  props: {
    socialAuth: {
      type: Array,
    },
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      maxLength: maxLength(50),
      minLength: minLength(6),
    },
  },
  methods: {
    openCaptcha() {
        let valid= this.$v;
        if (valid.$invalid) {
            let massages = {
                email: [],
                password: [],
            };

            this.formValidate = this.$formValidate(massages, valid);
        } else {
            this.$emit('update:captcha', this.onSubmit);
        }
    },
    updateValidate: function (type) {
      // let app= this.formValidate;
      //
      // let validate= this.$v[type];
      // if(validate.$invalid) {
      //     app[type]= [];
      //     for(var prop in validate.$params) {
      //         if(!validate[prop]) {
      //             let msg= this.__(this.rules[prop], validate.$params[prop]);
      //             app[type].push(msg);
      //         }
      //     }
      // } else {
      //     app[type]= [];
      // }
      this.formValidate = this.$changeValidate(
        this.formValidate,
        this.$v[type],
        type
      );
    },
    onSubmit: function (session, arrayData) {
      let valid = this.$v;

      if (valid.$invalid) {
        let massages = {
          email: [],
          password: [],
        };

        this.formValidate = this.$formValidate(massages, valid);
      } else {
        this.submitDisabled = true;
        const response = {
          email: this.email,
          password: this.password,
          challenge: session,
        };
        response[session]= arrayData;

        this.$http
          .post("ajax/login", response)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                this.submitDisabled = false;
                if (response.data.security) {
                    this.$store.dispatch("asyncModalAuth", response.data);
                } else {
                    if(this.$route.name== 'home') {
                        this.$router.push({
                            name: "games",
                            lang:  this.$store.getters.getLang,
                        });
                    }
                    if (response.data.gtag_login) {
                        if (this.$gtag) {
                            this.$gtag.event('login', { method: response.data.gtag_login });
                        }
                        if (this.$metrika) {
                            this.$metrika.reachGoal('LoginDone');
                        }
                    }
                    location.reload();


                    // this.$store.dispatch("asyncUserInfo", response);
                    // this.$store.dispatch("asyncModalAuth", false);
                    //
                    // if(response.data.friends) {
                    //     // this.$store.dispatch("asyncFriends", response.data.friends);
                    //     this.$store.dispatch("asyncFriendsReqCount", response.data.friends);
                    // }
                }
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            let response = this.httpHandler(err);
            if (response.error) {
              this.formValidate = response.error.message;
            }
          });
      }
    },
  },
  components: {
    InputLabelTextDefault,
    BaseButton
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: normal;
  src: url("~@/assets/fonts/Muli/Muliregular.woff") format("woff"),
    url("~@/assets/fonts/Muli/Muliregular.woff2") format("woff2");
}

.wrap-head {
  p {
    font-weight: 400;;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    max-width: 170px;
    margin: 0 auto 20px;
  }

    .nav {
        margin: 20px 0 0 0;
    }

    .wrap-title {
        margin-bottom: 20px;
    }
}

.wrap-input {
  margin-top: 16px;
}

.wrap-social-btn {
  margin: 0 auto 20px;
  max-width: 204px;
}

.social-btn {
  width: 64px;
  height: 26px;
  border: 1px solid #e4e7f3;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0;

  img {
    height: 14px;
    margin: 0;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}

.v-btn:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  background-color: transparent;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 26px;
  min-width: 64px;
  padding: 0;
}

.btn-register {
  outline: none;
  min-height: 40px;
  background: #26a9f4;
  text-transform: inherit;
  border-radius: 8px;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

  img {
    margin: 1px 8px 0 0;
  }

  &:hover {
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.15);
  }
}



  .forgot {
    margin-top: 16px;
    text-decoration: none;
    color: #26a9f4!important;

    &:hover {
      text-decoration: underline!important;
    }
  }


.forgot {
  font-size: 14px;
  /*font-family: "Muli";*/
}

  @media screen and (max-width: 768px) {
    .wrap-social-btn {
      margin-bottom: 15px;
    }
    .wrap-input {
      margin-top: 10px;
    }
  }

@media screen and (max-width: 600px){
    .wrap-head {
        .wrap-title {
            justify-content: center;
            /*flex-direction: column;*/

            .nav {
                display: none;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 550px) {

  .desc {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .btn-register {
    outline: none;
    min-height: 30px;
    background: #26a9f4;
    text-transform: inherit;
    font-size: 12px;
    border-radius: 8px;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

    img {
      height: 16px;
    }

    &.v-btn.v-size--default, .v-btn.v-size--large {
      font-size: 12px;
    }
  }

  .wrap-input {
    margin-top: 8px;
  }

  .wrap-head {
    p {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 5px;
    }
  }




  .social-btn {
    width: 44px;
    height: 20px;
    border: 1px solid #e4e7f3;
    box-sizing: border-box;
    border-radius: 4px;
    img {
      height: 10px;
      margin: 0;
    }
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 20px;
    min-width: 44px;
  }
}

  .forgot {
    font-size: 14px;
    margin: 12px 0 0;
  }

  @media screen and (max-width: 425px) {

    .wrap-head {
      p {
        max-width: 150px;
        font-size: 10px;
        line-height: 14px;
        margin-bottom: 5px;
      }
    }
    .wrap-input {
      margin-top: 5px;
    }

  }

@media screen and (max-width: 400px) {

  .btn-register {
    img {
      height: 14px;
    }
  }
}
</style>