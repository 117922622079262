<template>
    <v-dialog v-model="modal" max-width="400" >
        <!--    <div class="head-modal">-->
        <!--      <h4>Game deletion confirmation</h4>-->
        <!--      <v-btn text icon class="icon" v-on:click="$emit('update:result', false)">-->
        <!--        <img src="/dist/images/frontend/close.svg" alt="/" />-->
        <!--      </v-btn>-->
        <!--    </div>-->

        <div class="modal-confirm">
            <img src="/dist/images/frontend/delete-game-icon.svg" alt />
            <p>Are you sure you want to switch off this game?</p>

            <div class="wrap-btn">
                <div style="width: 48%">
                    <BaseButton
                            class_btn="btn-liteGrey"
                            v-on:click="modal = false"
                    >
                        <span>Cancel</span>
                    </BaseButton>
                </div>

                <div style="width: 48%">
                    <BaseButton
                            class_btn="redBtn"
                            v-on:click="gameOff"
                    >
                        <span>Switch off</span>
                    </BaseButton>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
    export default {
        name: "ModalModerationOff",
        data() {
            return {
                modal: true,
            };
        },
        props: {

        },
        watch: {
            modal() {
                this.cancelModal();
            }
        },
        computed: {},
        methods: {
            cancelModal() {
                this.$emit("update:open_modal", false);
            },
            gameOff () {
                this.$emit('submitGameOff')
            }
        },
        components: {},
    };
</script>

<style lang='scss' scoped>

    .dark {
        .modal-confirm {
            background: #171827;

            p {
                color: #fff;
            }
        }
    }

    .model_wrapper.v-dialog {
        overflow: unset;
        position: relative;
    }

    .modal-confirm {
        text-align: center;
        padding: 50px 25px 30px;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: #fff;

        img {
            margin-bottom: 8px;
        }

        p {
            max-width: 280px;
            width: 100%;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #002B50;
            margin: 0 auto 32px;
        }

        .wrap-btn {
            display: flex;
            justify-content: space-between;
        }
    }
</style>