    export default {
    state: {
        modal_users_list: null,
        users_list: null,
        user: null,
        message: null,
        active_id: null,
        message_count: null,
    },
    getters: {
        message_count(state){
            return state.message_count;
        },
        users_list(state){
            return state.users_list;
        },
        modal_users_list(state){
            return state.modal_users_list;
        },
        user(state){
            return state.user;
        },
        message(state){
            return state.message;
        },
        active_id(state){
            return state.active_id;
        },
        invite_notice(state){
            return state.invite_notice;
        },
    },
    actions: {
        asyncMessagesCount(context, payload) {
            context.commit('updateMessagesCount', payload);
        },
        asyncUsersList(context, payload) {
            context.commit('updateUsersList', payload);
        },
        asyncModalUsersList(context, payload) {
            context.commit('updateModalUsersList', payload);
        },
        asyncUser(context, payload) {
            context.commit('updateUser', payload);
        },
        asyncMessage(context, payload) {
            context.commit('asyncMessage', payload);
        },
        asyncActiveId(context, payload) {
            context.commit('pushActiveId', payload);
        },
    },
    mutations: {
        updateMessagesCount(state, data) {
            this.state.chat.message_count= data;
        },
        updateUsersList(state, data) {
            this.state.chat.users_list= data;
        },
        updateModalUsersList(state, data) {
            this.state.chat.modal_users_list= data;
        },
        updateUser(state, data) {
            this.state.chat.user= data;
        },
        asyncMessage(state, data) {
            this.state.chat.message= data;
        },
        pushActiveId(state, data) {
            this.state.chat.active_id.push(data);
        },
    }
}
