<template>
  <div class="container content_ivancha">
    <div class="form-row">
      <div class="col-12 cont_ivancha" v-show="session">
        <div class="bg-gaus"></div>
        <div class="slidercaptcha card" style="max-width: 362px;">
          <div class="card-body">
            <div class="logo-img">
              <img class="logo" src="/dist/images/frontend/LogoMP.svg" alt="">
            </div>
            <div id="captcha"></div>
          </div>
        </div>
      </div>
      <div class="col-12 cont_ivancha" v-if="!session">
        <div class="bg-gaus"></div>
        <div class="slidercaptcha card" style="max-width: 362px;">
          <div class="card-body">
            <div class="logo-img">
              <img class="logo" src="/dist/images/frontend/LogoMP.svg" alt="">
              <div class="loading-capcha">
                <div class="body">
                    <Loader></Loader>
                </div>
                <div class="bottom">
<!--                  <div class="btn"></div>-->
                  <span>{{__('Loading captcha...')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Loader from "./Loader";

export default {
  name: 'Captcha',
  data() {
    return {
        arrayData: null,
        session: null,
    }
  },

  methods: {

      sliderCaptcha: function () {
          'use strict';

          let $vue= this;

          var SliderCaptcha = function (element, options) {
              this.$element = $(element);
              this.options = $.extend({}, SliderCaptcha.DEFAULTS, options);
              this.$element.css({'position': 'relative', 'width': this.options.width + 'px', 'margin': '0 auto'});
              this.init();
          };

          SliderCaptcha.VERSION = '1.0';
          SliderCaptcha.Author = 'argo@163.com';
          SliderCaptcha.DEFAULTS = {
              width: 280,     // canvas宽度
              height: 155,    // canvas高度
              PI: Math.PI,
              sliderL: 42,    // 滑块边长
              sliderR: 9,     // 滑块半径
              offset: 5,      // 容错偏差
              loadingText: this.__("Loading captcha..."),
              failedText: this.__('try again'),
              barText: this.__('Swipe right to solve the puzzle'),
              repeatIcon: 'fa fa-repeat',
              maxLoadCount: 3,
              localImages: function () {
                  return '/pic' + Math.round(Math.random() * 4) + '.jpg';
              },
              verify: function (arr, url) {
                  var ret = false;
                  $.ajax({
                      url: url,
                      data: JSON.stringify(arr),
                      async: false,
                      cache: false,
                      type: 'POST',
                      contentType: 'application/json',
                      dataType: 'json',
                      success: function (result) {
                          ret = result;
                      }
                  });
                  return ret;
              },
              remoteUrl: null
          };

          function Plugin(option) {
              return this.each(function () {
                  var $this = $(this);
                  var data = $this.data('lgb.SliderCaptcha');
                  var options = typeof option === 'object' && option;

                  if (data && !/reset/.test(option)) return;
                  if (!data) $this.data('lgb.SliderCaptcha', data = new SliderCaptcha(this, options));
                  if (typeof option === 'string') data[option]();
              });
          }

          $.fn.sliderCaptcha = Plugin;
          $.fn.sliderCaptcha.Constructor = SliderCaptcha;

          var _proto = SliderCaptcha.prototype;
          _proto.init = function () {
              this.initDOM();
              this.initImg();
              this.bindEvents();
          };

          _proto.initDOM = function () {
              var createElement = function (tagName, className) {
                  var elment = document.createElement(tagName);
                  elment.className = className;
                  return elment;
              };

              var createCanvas = function (width, height) {
                  var canvas = document.createElement('canvas');
                  canvas.width = width;
                  canvas.height = height;
                  return canvas;
              };

              var canvas = createCanvas(this.options.width - 2, this.options.height); // 画布
              var block = canvas.cloneNode(true); // 滑块
              var sliderContainer = createElement('div', 'sliderContainer');
              var refreshIcon = createElement('i', 'refreshIcon ' + this.options.repeatIcon);
              var sliderMask = createElement('div', 'sliderMask');
              var sliderbg = createElement('div', 'sliderbg');
              var slider = createElement('div', 'slider');
              var sliderIcon = createElement('i', 'fa fa-arrow-right sliderIcon');
              var text = createElement('span', 'sliderText');

              block.className = 'captcha_block';
              text.innerHTML = this.options.barText;

              var el = this.$element;
              el.append($(canvas));
              el.append($(refreshIcon));
              el.append($(block));
              slider.appendChild(sliderIcon);
              sliderMask.appendChild(slider);
              sliderContainer.appendChild(sliderbg);
              sliderContainer.appendChild(sliderMask);
              sliderContainer.appendChild(text);
              el.append($(sliderContainer));

              var _canvas = {
                  canvas: canvas,
                  block: block,
                  sliderContainer: $(sliderContainer),
                  refreshIcon: refreshIcon,
                  slider: slider,
                  sliderMask: sliderMask,
                  sliderIcon: sliderIcon,
                  text: $(text),
                  canvasCtx: canvas.getContext('2d'),
                  blockCtx: block.getContext('2d')
              };

              if ($.isFunction(Object.assign)) {
                  Object.assign(this, _canvas);
              } else {
                  $.extend(this, _canvas);
              }
          };

          _proto.initImg = function () {
              var that = this;
              var isIE = window.navigator.userAgent.indexOf('Trident') > -1;
              var L = this.options.sliderL + this.options.sliderR * 2 + 3; // 滑块实际边长
              var drawImg = function (ctx, operation) {
                  var l = that.options.sliderL;
                  var r = that.options.sliderR;
                  var PI = that.options.PI;
                  var x = that.x;
                  var y = that.y;
                  ctx.beginPath();
                  ctx.moveTo(x, y);
                  ctx.arc(x + l / 2, y - r + 2, r, 0.72 * PI, 2.26 * PI);
                  ctx.lineTo(x + l, y);
                  ctx.arc(x + l + r - 2, y + l / 2, r, 1.21 * PI, 2.78 * PI);
                  ctx.lineTo(x + l, y + l);
                  ctx.lineTo(x, y + l);
                  ctx.arc(x + r - 2, y + l / 2, r + 0.4, 2.76 * PI, 1.24 * PI, true);
                  ctx.lineTo(x, y);
                  ctx.lineWidth = 2;
                  ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
                  ctx.strokeStyle = 'rgba(255, 255, 255, 0.7)';
                  ctx.stroke();
                  ctx[operation]();
                  ctx.globalCompositeOperation = isIE ? 'xor' : 'destination-over';
              };

              var getRandomNumberByRange = function (start, end) {
                  var cord = Math.round(Math.random() * (end - start) + start);
                  return cord;
              };
              var img = new Image();
              img.crossOrigin = "Anonymous";
              var loadCount = 0;
              img.onload = function () {
                  // 随机创建滑块的位置
                  that.x = getRandomNumberByRange(L + 10, that.options.width - (L + 10));
                  that.y = getRandomNumberByRange(10 + that.options.sliderR * 2, that.options.height - (L + 10));
                  drawImg(that.canvasCtx, 'fill');
                  drawImg(that.blockCtx, 'clip');

                  that.canvasCtx.drawImage(img, 0, 0, that.options.width - 2, that.options.height);
                  that.blockCtx.drawImage(img, 0, 0, that.options.width - 2, that.options.height);
                  var y = that.y - that.options.sliderR * 2 - 1;
                  var ImageData = that.blockCtx.getImageData(that.x - 3, y, L, L);
                  that.block.width = L;
                  that.blockCtx.putImageData(ImageData, 0, y + 1);
                  that.text.text(that.text.attr('data-text'));
              };
              img.onerror = function () {
                  loadCount++;
                  if (window.location.protocol === 'file:') {
                      loadCount = that.options.maxLoadCount;
                      console.error("can't load pic resource file from File protocal. Please try http or https");
                  }
                  if (loadCount >= that.options.maxLoadCount) {
                      that.text.text(this.__('Failed to load')).addClass('text-danger');
                      return;
                  }
                  img.src = that.options.localImages();
              };
              img.setSrc = function () {
                  var src = '';
                  loadCount = 0;
                  that.text.removeClass('text-danger');
                  if ($.isFunction(that.options.setSrc)) src = that.options.setSrc();
                  if (!src || src === '') src = '/captcha/pic/' + Math.round(Math.random() * 20) + '.jpg';
                  // if (!src || src === '') src = 'https://picsum.photos/280/155/?image=2';
                  if (isIE) { // IE浏览器无法通过img.crossOrigin跨域，使用ajax获取图片blob然后转为dataURL显示
                      var xhr = new XMLHttpRequest();
                      xhr.onloadend = function (e) {
                          var file = new FileReader(); // FileReader仅支持IE10+
                          file.readAsDataURL(e.target.response);
                          file.onloadend = function (e) {
                              img.src = e.target.result;
                          };
                      };
                      xhr.open('GET', src);
                      xhr.responseType = 'blob';
                      xhr.send();
                  } else img.src = src;
              };
              img.setSrc();
              this.text.attr('data-text', this.options.barText);
              this.text.text(this.options.loadingText);
              this.img = img;
          };

          _proto.clean = function () {
              this.canvasCtx.clearRect(0, 0, this.options.width, this.options.height);
              this.blockCtx.clearRect(0, 0, this.options.width, this.options.height);
              this.block.width = this.options.width;
          };

          _proto.bindEvents = function () {
              var that = this;
              this.$element.on('selectstart', function () {
                  return false;
              });

              $(this.refreshIcon).on('click', function () {
                  that.text.text(that.options.barText);
                  that.reset();
                  if ($.isFunction(that.options.onRefresh)) that.options.onRefresh.call(that.$element);
              });

              var originX, originY, trail = [],
                  isMouseDown = false;

              var handleDragStart = function (e) {
                  if (that.text.hasClass('text-danger')) return;
                  originX = e.clientX || e.touches[0].clientX;
                  originY = e.clientY || e.touches[0].clientY;
                  isMouseDown = true;
              };

              var handleDragMove = function (e) {
                  // e.addEventListener('handleEvent', { passive: false });
                  // e.preventDefault();
                  if (!isMouseDown) return false;
                  var eventX = e.clientX || e.touches[0].clientX;
                  var eventY = e.clientY || e.touches[0].clientY;
                  var moveX = eventX - originX;
                  var moveY = eventY - originY;
                  if (moveX < 0 || moveX + 40 > that.options.width) return false;
                  that.slider.style.left = (moveX - 1) + 'px';
                  var blockLeft = (that.options.width - 40 - 20) / (that.options.width - 40) * moveX;
                  that.block.style.left = blockLeft + 'px';

                  that.sliderContainer.addClass('sliderContainer_active');
                  that.sliderMask.style.width = (moveX + 4) + 'px';
                  trail.push(moveY);
              };

              var handleDragEnd = (e)=> {
                  if (!isMouseDown) return false;
                  isMouseDown = false;
                  var eventX = e.clientX || e.changedTouches[0].clientX;
                  if (eventX === originX) return false;
                  that.sliderContainer.removeClass('sliderContainer_active');
                  that.trail = trail;
                  var data = that.verify();
                  if (data.spliced && data.verified) {
                      that.sliderContainer.addClass('sliderContainer_success');
                      if ($.isFunction(that.options.onSuccess)) {
                          $vue.arrayData = JSON.stringify(that.trail);
                          $vue.session = that.options.session;
                          that.options.onSuccess.call(that.$element);
                      }
                  } else {
                      that.sliderContainer.addClass('sliderContainer_fail');
                      if ($.isFunction(that.options.onFail)) that.options.onFail.call(that.$element);
                      setTimeout(function () {
                          that.text.text(that.options.failedText);
                          that.reset();
                      }, 1000);
                  }
              };

              this.slider.addEventListener('mousedown', handleDragStart);
              this.slider.addEventListener('touchstart', handleDragStart);
              document.addEventListener('mousemove', handleDragMove);
              document.addEventListener('touchmove', handleDragMove);
              document.addEventListener('mouseup', handleDragEnd);
              document.addEventListener('touchend', handleDragEnd);

              document.addEventListener('mousedown', function () {
                  return false;
              });
              document.addEventListener('touchstart', function () {
                  return false;
              });
              document.addEventListener('swipe', function () {
                  return false;
              });
          };

          _proto.verify = function () {
              var arr = this.trail; // 拖动时y轴的移动距离
              var left = parseInt(this.block.style.left);
              var verified = false;
              if (this.options.remoteUrl !== null) {
                  verified = this.options.verify(arr, this.options.remoteUrl);
              } else {
                  var sum = function (x, y) {
                      return x + y;
                  };
                  var square = function (x) {
                      return x * x;
                  };
                  var average = arr.reduce(sum) / arr.length;
                  var deviations = arr.map(function (x) {
                      return x - average;
                  });
                  var stddev = Math.sqrt(deviations.map(square).reduce(sum) / arr.length);
                  verified = stddev !== 0;
              }
              return {
                  spliced: Math.abs(left - this.x) < this.options.offset,
                  verified: verified
              };
          };

          _proto.reset = function () {
              this.sliderContainer.removeClass('sliderContainer_fail sliderContainer_success');
              this.slider.style.left = 0;
              this.block.style.left = 0;
              this.sliderMask.style.width = 0;
              this.clean();
              this.text.attr('data-text', this.text.text());
              this.text.text(this.options.loadingText);
              this.img.setSrc();
          };

          $('#captcha').sliderCaptcha({
              repeatIcon: 'fa fa-sync',
              session: $vue.session,
              onSuccess: () => {
                  this.$emit('onSubmit', String($vue.session), $vue.arrayData);
              }
          });
      },
  },
  mounted () {
      this.$http.post('ajax/session')
          .then(response => {
              return response.json()
          })
          .then((response) => {
              if(process.env.NODE_ENV === 'production') {
                  this.session= response.data;
              } else {
                  response='433443';
                  // response = null;
                  this.session= response;
              }
              this.sliderCaptcha();
          }).catch((err) => {
          this.httpHandler(err);
      });

  },
  components: {
    Loader
  }


}
</script>

<style>



  .content_ivancha{
    max-width: 100%;
  }
  .content_ivancha .captcha_block {
    position: absolute;
    padding: 0px;
    background-color: transparent!important;
    height: auto!important;
    left: 0;
    top: 0;
    width: auto!important;
    display: block!important;
  }
  .content_ivancha .sliderContainer {
    position: relative;
    text-align: center;
    line-height: 40px;
    margin-top: 15px;
    /*background: #909193;*/
    color: #45494c;
    border-radius: 4px;
  }
  .content_ivancha .sliderbg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #26a9f4;
  }
  .content_ivancha .sliderContainer_active .slider {
    top: -1px;
    border: 1px solid #008df2;
  }
  .content_ivancha .sliderContainer_active .sliderMask {
    border-width: 1px 0 1px 1px;
  }
  .content_ivancha .sliderContainer_success .slider {
    top: -1px;
    border: 1px solid #008df2;
    /*background-color: #fd3a39 !important;*/

  }
  .content_ivancha .sliderContainer_success .sliderMask {
    /*border: 1px solid #909193;*/
    border-width: 1px 0 1px 1px;
    /*background-color: #909193;*/
  }
  .content_ivancha .sliderContainer_success .sliderIcon:before {
    content: "";
  }
  .content_ivancha .sliderContainer_fail .slider {
    top: -1px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    /*background-image: url(../../../public/images/frontend/close-red.svg);*/
    border: 1px solid red;
    background-color: #f57a7a!important;
  }
  .content_ivancha .sliderContainer_fail .sliderMask {
    border: 1px solid #f57a7a;
    background-color: #fce1e1;
    border-width: 1px 0 1px 1px;
  }
  .content_ivancha .sliderContainer_fail .sliderIcon:before {
    content: "";
  }
  .content_ivancha .sliderContainer_active .sliderText, .sliderContainer_success .sliderText, .sliderContainer_fail .sliderText {
    display: none;
  }
  .content_ivancha .sliderMask {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    border: 0 solid #26a9f4;
    background: linear-gradient(213.2deg, #002B50 0%, #0087FB 100%);
    border-radius: 4px;
  }
  .content_ivancha .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #26a9f4;
    background: #ffffff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background .2s linear;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content_ivancha .sliderContainer .slider .fa-arrow-right{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transform: scale(1);
    transition: 100ms;
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../public/images/frontend/captcha-arrow.png);
  }
  .content_ivancha .sliderContainer_success .slider .fa-arrow-right{
    background-image: url(../../../public/images/frontend/mp-icon.png);
    background-size: 26px;
    transition: 100ms;
    background-position: center;
  }
  .content_ivancha .sliderContainer_success .slider:hover .fa-arrow-right{
    /*background-size: 16px!important;*/
    transition: 100ms;
  }
  .content_ivancha .sliderContainer_fail .slider .fa-arrow-right{
    /*background-image: url(../../../public/images/frontend/close-red.svg);*/
    background-size: 16px!important;
    background-repeat: no-repeat;
    transition: 100ms;
  }
  .content_ivancha .sliderContainer_fail .slider:hover .fa-arrow-right{
    background-size: 14px!important;
  }
  .content_ivancha .logo-img{
    text-align: center;
  }
  .content_ivancha .logo{
    margin-bottom: 10px;
  }
  .content_ivancha .sliderContainer .slider:hover .fa-arrow-right{
    background-size: 28px;
    transition: 100ms;
    background-position: center!important;
  }
  .content_ivancha .slider:hover {
    /*background: #008df2;*/
  }
  .content_ivancha .slider:hover .sliderIcon {
    /*background-position: 0 -13px;*/
  }
  .content_ivancha .slider i:after{
    left: 0;
    content: '';
    display: inline-block;
    height: 200px;
    width: 40px;
    position: absolute;
    bottom: 0;
  }
  .content_ivancha .slider i:before{
    display: none
  }
  .content_ivancha .sliderText {
    position: relative;
    color: #ffffff;
  }
  .content_ivancha .sliderIcon {
  }
  .content_ivancha .refreshIcon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    margin: 6px;
    color: rgba(0,0,0,.25);
    font-size: 1rem;
    z-index: 5;
    transition: color .3s linear;
  }
  .content_ivancha .refreshIcon:hover {
    color: #6c757d;
  }
  .cont_ivancha{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content_ivancha .slidercaptcha {
    margin: 0 auto;
    width: 314px;
    height: 290px;
    border-radius: 4px;
  }
  .content_ivancha .card-body{
    background: linear-gradient(213.2deg, #002B50 0%, #0087FB 100%);
    border-radius: 4px;

  }
  .content_ivancha .slidercaptcha .card-body {
    padding: 1rem;

  }
  .content_ivancha .slidercaptcha canvas:first-child {
    border-radius: 4px;
    border: 1px solid #26a9f4;
  }
  .content_ivancha .slidercaptcha.card .card-header {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.03);
  }
  .container.content_ivancha{
    padding: 0;
    height: 100vh;
    width: 100%;
    z-index: 111111;
    max-width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .content_ivancha .form-row{
    padding: 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    align-content: center;
  }
  .content_ivancha .bg-gaus{
    height: 100vh;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    -moz-backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    -ms-backdrop-filter: blur(5px);
    -o-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    top: 0;
    z-index: 1;
  }
  .content_ivancha .slidercaptcha{
    position: relative;
    font-size: 12px;
    z-index: 2;
  }
  .content_ivancha .slidercaptcha .card{
    max-width: 362px;
  }

  .loading-capcha {

  }
  .loading-capcha .body {
    border: 1px solid #26a9f4;
    border-radius: 4px;
    height: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-capcha .bottom {
    border: 1px solid #26a9f4;
    border-radius: 4px;
    height: 40px;
    margin-top: 15px;
    display: flex;
  }

  .loading-capcha .bottom .btn {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .1s;
    transition: .1s;
    background-size: 26px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-image: url(../../../public/images/frontend/captcha-arrow.png);
  }

  .loading-capcha .bottom span {
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }


</style>
