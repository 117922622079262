import Vue from 'vue';
import VueRouter from "vue-router";
import guest from "./middleware/guest";
import auth from "./middleware/auth";
import middlewarePipeline from "./middlewarePipeline";
import store from '../store';

import Home from '../components/pages/Home';
// import About from '../components/pages/AboutNew';
import AffiliateProgram from '../components/pages/AffiliateProgram'
import Blog from '../components/pages/Blog'
import BlogPage from '../components/pages/BlogPage'
import Contacts from '../components/pages/Contacts'
import Api from '../components/pages/ToDevelopers';
// import Profile from '../components/pages/Profile';
import ProfileNew from '../components/pages/ProfileNew';
// import Games from '../components/pages/Games/Games';
// import GamesNew from '../components/pages/Games/GamesNew';
import GamePage from '../components/pages/Games/GamePageLastNew';
import GameFrame from "../components/pages/Games/GameFrame";
import Developers from '../components/pages/Developers';
import Affiliate from '../components/pages/Affiliate';
import Championship from '../components/pages/Championship';
import Quests from '../components/pages/Quests';
import Peoples from '../components/pages/Peoples';
import Settings from '../components/pages/Settings';
import NotFound from '../components/pages/NotFound';
import Privacy from '../components/pages/Privacy';
import Terms from '../components/pages/Terms';
import Balances from "../components/pages/Balances";
import TransactionHistory from "../components/pages/TransactionHistory";
import Messenger from "../components/pages/Messenger";
import Vacancies from "../components/pages/Vacancies";
import WithdrawalPolicyRefundPolicy from "../components/pages/WithdrawalPolicyRefundPolicy";
import CookiePolicy from "../components/pages/CookiePolicy";
import FAQ from "../components/pages/FAQ";
// import Airdrop from "../components/pages/Airdrop";
import EmailVerification from "../components/pages/EmailVerification";
import BuyPremium from "../components/pages/BuyPremium";
import Store from "../components/pages/Store";
import PageProductItem from "../components/pages/Store/PageProductItem";
import Cart from "../components/pages/Store/Cart";
import PageMyOrders from "../components/pages/Store/PageMyOrders";
import QuestPage from "../components/pages/Quests/QuestPage";
import ApiPage from "../components/pages/ApiPage";

import Nft from "../components/pages/Nft";
import NftList from "../components/pages/Nft/NftList";
import NftPage from "../components/pages/Nft/NftPage";
import NftMy from "../components/pages/Nft/NftMy";
import _ from "lodash";


import AuthLogin from "../components/pages/HomeApp/AuthLogin";
import AuthSignup from "../components/pages/HomeApp/AuthSignup";
import AuthForgot from "../components/pages/HomeApp/AuthForgot";
import AuthSecurity from "../components/pages/HomeApp/AuthSecurity";
import HomeApp from "../components/HomeApp";


// import Games from './components/pages/Games/Games';
// import GamePage from './components/pages/Games/GamePage';
// import Peoples from './components/pages/Peoples';
// import Clans from './components/pages/Clans';
// import Champ from './components/pages/Champ';
// import Leaderboard  from './components/pages/Leaderboard';
// import Quests  from './components/pages/Quests';
// import ThunderShop  from './components/pages/ThunderShop';
// import Developers  from './components/pages/Developers';
// import Affiliate  from './components/pages/Affiliate';
// import Settings from './components/pages/Settings';
// import UserProfile from './components/pages/UserProfile';
// import Portfolio from './components/pages/Portfolio';
// import Messages from './components/pages/Messages';
import GamesHome from "../components/pages/GamesHome";
import NftNew from "../components/pages/NftNew";
import Tournaments from "../components/pages/Tournaments";
import PageTournament from "../components/pages/Tournaments/PageTournament";
import PageTournamentSolo from "../components/pages/Tournaments/PageTournamentSolo";

Vue.use(VueRouter);

const routes = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        // {
        //     path: '/:lang?/login',
        //     name: 'login',
        //     component: Home,
        //     meta: {
        //         middleware: [
        //             guest
        //         ]
        //     },
        // },
        {
            path: '/:lang?/login',
            component: HomeApp,
            children: [
                {
                    path: '/:lang?/registration',
                    name: 'register',
                    component: AuthSignup,
                    meta: {
                        title: 'Registration',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/:lang?/login',
                    name: 'login',
                    component: AuthLogin,
                    meta: {
                        title: 'Login',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/:lang?/forgot',
                    name: 'forgot',
                    component: AuthForgot,
                    meta: {
                        title: 'Forgot',
                        middleware: [
                            guest
                        ]
                    },
                },
                {
                    path: '/:lang?/security',
                    name: 'security',
                    component: AuthSecurity,
                    meta: {
                        title: 'Authentication security',
                    },
                },
            ]
        },
        {
            path: '/:lang?/email_verification',
            name: 'email_verification',
            component: EmailVerification,
        },
        {
            path: '/:lang?/terms',
            name: 'terms',
            component: Terms,
        },
        {
            path: '/:lang?/ajax/*',
            name: 'ajax',
            component: Home
        },
        {
            path: '/:lang?/password/reset/:token',
            name: 'password_reset',
            component: Home
        },
        // {
        //     path: '/:lang?/about',
        //     name: 'about',
        //     component: About,
        // },

        {
            path: '/:lang?/affiliate',
            name: 'main_affiliate',
            component: AffiliateProgram,
            meta: {title: 'Affiliate Program'}
        },
        {
            path: '/:lang?/transaction_history',
            name: 'transaction_history',
            component: TransactionHistory,
            meta: {
                title: 'Transaction History',
                middleware: [
                    auth
                ]
            }
        },
        {
            path: '/:lang?/api',
            name: 'api',
            component: ApiPage,
            meta: {title: 'To Developers'}
        },
        {
            path: '/:lang?/api_mindplays',
            name: 'apiPage',
            component: Api,
            meta: {title: 'Api mindplays'}
        },
        {
            path: '/:lang?/blogs',
            // path: '/:lang?/blogs/:category?',
            name: 'blogs',
            component: Blog,
            meta: {title: 'Blog'}
        },
        {
            path: '/:lang?/blogs/:type(category|author)/:name',
            // path: '/:lang?/blogs/:category?',
            name: 'blogs_category',
            component: Blog,
            meta: {title: 'Blog'}
        },
        {
            path: '/:lang?/blog/:page',
            name: 'blog',
            component: BlogPage,
        },

        {
            path: '/:lang?/contact',
            name: 'contact',
            component: Contacts,
            meta: {title: 'Contact Us'}
        },
        // {
        //     path: '/:lang?/games',
        //     name: 'games',
        //     component: Games,
        //     meta: {title: 'Games'}
        // },

        {
            path: '/:lang?/quests',
            name: 'quests',
            component: Quests,
            meta: {
                title: 'Quests',

            },
            children: [{
                path: '/:lang?/quests/:current',
                name: 'quests_current',
                meta: {

                }
            },
            ]
        },
        {
            path: '/:lang?/quest/:id',
            name: 'quest',
            component: QuestPage,
            meta: {
                title: 'Quests',
            },
        },
        {
            path: '/:lang?/tournaments',
            name: 'tournaments',
            component: Tournaments,
            meta: {
                title: 'Tournaments',

            },
            children: [{
                path: '/:lang?/tournaments/:current',
                name: 'tournaments_current',
                meta: {

                }
            },
            ]
        },
        {
            path: '/:lang?/tournament/:id',
            name: 'tournament',
            component: PageTournament,
            meta: {
                title: 'Tournament',

            },
        },
        {
            path: '/:lang?/tournament_solo/:id',
            name: 'tournament_solo',
            component: PageTournamentSolo,
            meta: {
                title: 'Tournament',

            },
        },
        {
            path: '/:lang?/nft',
            component: Nft,
            children: [
                {
                    path: 'my_nft',
                    name: 'my_nft',
                    component: NftMy,
                    meta: {
                        title: 'Transaction History',
                        middleware: [
                            auth
                        ]
                    }
                },
                {
                    path: ':type(favorite)?',
                    name: 'nft_list',
                    component: NftList,
                },
                {
                    path: ':id',
                    name: 'nft_page',
                    component: NftPage,
                },
            ]
        },

        {
            path: '/:lang?/games',
            name: 'games',
            component: GamesHome,
            meta: {title: 'Games'}
        },
        {
            path: '/:lang?/NftNew',
            name: 'NftNew',
            component: NftNew,
            meta: {title: 'NftNew'}
        },
        {
            path: '/:lang?/GamesHome',
            name: 'GamesHome',
            component: GamesHome,
            meta: {title: 'GamesHome'}
        },
        {
            path: '/:lang?/games/:id',
            name: 'game',
            component: GamePage,
            children: [{
                path: '/:lang?/games/:id/:current',
                name: 'game_choosing_opponent',
                meta: 'tab'
            }]
        },
        {
            path: '/:lang?/auth/:page',
            name: 'auth',
            component: Home,
            meta: {
                middleware: [
                    guest
                ]
            },
            // children: [{
            //     path: '/:lang?/:current',
            //     name: 'profile_current',
            //     // meta: {
            //     //     middleware: [
            //     //         auth
            //     //     ],
            //     //
            //     // },
            //     meta: 'tab'
            //
            // }]
        },

        {
            path: '/:lang?/store',
            name: 'store',
            component: Store,
            meta: {
                middleware: [
                    auth
                ],
                title: 'Store'
            },
        },
        {
            path: '/:lang?/store/:id',
            name: 'page_product',
            component: PageProductItem,
            meta: {
                middleware: [
                    auth
                ],
                title: 'Page product'
            },
        },

        {
            path: '/:lang?/cart',
            name: 'cart',
            component: Cart,
            meta: {
                middleware: [
                    auth
                ],
                title: 'Cart'
            },
        },

        {
            path: '/:lang?/my_orders',
            name: 'my_orders',
            component: PageMyOrders,
            meta: {
                middleware: [
                    auth
                ],
                title: 'My orders'
            },
        },

        {
            path: '/:lang?/developers',
            name: 'developers',
            component: Developers,
            meta: {
                middleware: [
                    auth
                ],
                title: 'To Developers'
            },
            children: [{
                path: '/:lang?/developers/create',
                name: 'dev_create',
                meta: {
                    middleware: [
                        auth
                    ]
                },
            }, {
                path: '/:lang?/developers/:id',
                name: 'dev_info',
                meta: {
                    middleware: [
                        auth
                    ]
                },
                children: [{
                    path: '/:lang?/developers/:id/:current',
                    name: 'dev_info_current',
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                }]
            }]
        },
        {
            path: '/:lang?/affiliates',
            name: 'affiliate',
            component: Affiliate,
            meta: {
                title: 'Affiliate Program',
                middleware: [
                    auth
                ]
            },
            children: [{
                path: '/:lang?/affiliates/:current',
                name: 'affiliate_current',
                meta: {
                    middleware: [
                        auth
                    ]
                },
            }]
        },
        {
            path: '/:lang?/peoples/:search?',
            name: 'peoples',
            component: Peoples,
            meta: {
                middleware: [
                    auth
                ],
                title: 'Peoples'
            },
            children: [{
                path: '/:lang?/friends',
                name: 'friends',
                meta: {
                    middleware: [
                        auth
                    ],
                    title: 'Friends'
                },
                children: [{
                    path: '/:lang?/friends/:current',
                    name: 'friends_current',
                    meta: {
                        middleware: [
                            auth
                        ]
                    }
                },{
                    path: '/:lang?/friends/user/:user_id',
                    name: 'friends_user',
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                    children: [{
                        path: '/:lang?/friends/user/:user_id/:current',
                        name: 'friends_user_current',
                        meta: {
                            middleware: [
                                auth
                            ]
                        },
                    }]
                }]
            }]
        },
        {
            path: '/:lang?/balances',
            name: 'balances',
            component: Balances,
            children: [{
                path: '/:lang?/balances/:current',
                name: 'balances_current',
                meta: {
                    middleware: [
                        auth
                    ]
                }
            }]
        },
        {
            path: '/:lang?/chat/:id?',
            name: 'chat',
            component: Messenger,
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/:lang?/champ',
            name: 'champ',
            component: Championship,
            meta: {
                title: 'Championship',
                meta: {
                    middleware: [
                        auth
                    ]
                }
            },
            // children: [{
            //     path: '/:lang?/championship/:current',
            //     name: 'championship_current',
            //     meta: {
            //         middleware: [
            //             auth
            //         ]
            //     },
            // }]
        },
        {
            path: '/:lang?/games/:id/frame/:room_id/:battle_id',
            name: 'frame',
            component: GameFrame,
            meta: {
                middleware: [
                    auth
                ]
            },
        },
        {
            path: '/:lang?/settings',
            name: 'settings',
            component: Settings,
            meta: {
                middleware: [
                    auth
                ],
                title: 'Settings & Security'
            },
            children: [{
                path: '/:lang?/settings/:current',
                name: 'settings_current',
                meta: {
                    middleware: [
                        auth
                    ]
                },
            }]
        },
        {
            path: '/:lang?/clans',
            name: 'clans',
            // component: Clans
        },
        {
            path: '/:lang?/leaderboard',
            name: 'leaderboard',
            // component: Leaderboard
        },

        {
            path: '/:lang?/thunder-shop',
            name: 'thunder-shop',
            // component: ThunderShop
        },
        // {
        //     path: '/:lang?/developers',
        //     name: 'developers',
        //     // component: Developers
        // },
        {
            path: '/:lang?/portfolio',
            name: 'portfolio',
            // component: Portfolio
        },
        {
            path: '/:lang?/messages',
            name: 'messages',
            // component: Messages
        },

        {
            path: '/:lang?/vacancies',
            name: 'vacancies',
            component: Vacancies,
        },
        {
            path: '/:lang?/withdrawal_policy_and_refund_policy',
            name: 'withdrawal_policy_and_refund_policy',
            component: WithdrawalPolicyRefundPolicy,
        },
        {
            path: '/:lang?/cookie_policy',
            name: 'cookie_policy',
            component: CookiePolicy,
        },
        {
            path: '/:lang?/faq',
            name: 'faq',
            component: FAQ,
        },
        // {
        //     path: '/:lang?/airdrop',
        //     name: 'airdrop',
        //     component: Airdrop,
        // },
        {
            path: '/:lang?/u/:id',
            component: Home,
        },
        {
            path: '/:lang?/privacy',
            name: 'privacy',
            component: Privacy,
        },
        {
            path: '/:lang?/buy_premium',
            name: 'buy_premium',
            component: BuyPremium,
        },
        {
            path: ((process.env.NODE_ENV === 'production') ? '/:lang?/:id?' : '/:lang?/dist/:id?'),
            name: 'home',
            component: Home
        },
        {
            path: '/:lang?/:id',
            name: 'profile',
            component: ProfileNew,
        },
        {
            name: 'not_found',
            path: '/:lang?/not_found',
            component: NotFound,
        },
        // {
        //     path: '/:lang?/:id',
        //     name: 'profile',
        //     component: ProfileNew,
        //     // redirect: {name: 'profile.games'},
        //     children: [
        //         {
        //             path: ((process.env.NODE_ENV === 'production') ? '/:lang?' : '/:lang?/dist/'),
        //             name: 'home',
        //             component: Home
        //         },
        //     ]
        //     //     {
        //     //         name: 'profile.games',
        //     //         path: '/:lang?/games',
        //     //         component: ProfileGames,
        //     //     },
        //     //     {
        //     //         name: 'profile.achievements',
        //     //         path: '/:lang?/achievements',
        //     //         component: ProfileAchievements
        //     //     },
        //     //     {
        //     //         name: 'profile.statistics',
        //     //         path: '/:lang?/statistics',
        //     //         component: ProfileStatistics
        //     //     },
        //     //     {
        //     //         name: 'profile.communities',
        //     //         path: '/:lang?/communities',
        //     //         component: ProfileCommunities
        //     //     },
        //     //     {
        //     //         name: 'profile.quests',
        //     //         path: '/:lang?/quests',
        //     //         component: ProfileQuests
        //     //     },
        //     // ]
        // },
        // {
        //     path: ((process.env.NODE_ENV === 'production') ? '/:lang?' : '/:lang?/dist/'),
        //     name: 'home',
        //     component: Home
        // },
        {
            path: '/:lang?/*',
            component: NotFound
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition || to.meta=='tab') {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});



routes.beforeEach((to, from, next) => {
    // let lang= ((to.params.lang) ? to.params.lang : 'ru');
    // //|| to.params.lang== 'ru'
    // if(store.getters.getLangs.map(function (e) { return String(e['lang']); }).indexOf(String(lang)) < 0) {
    //     lang= 'ru';
    //     let params = to.params;
    //     params['lang'] = null;
    //     if(to.name != 'profile' || to.name != 'profile' && to.params.id) {
    //         return routes.push({ name: to.name, params: params });
    //     }
    // }
    let path= to.path,
        reqex= new RegExp('^/'+store.getters.getLanguage);

    path= path.replace(reqex, '');

    if(!path) {
        path= '/';
    }
    // if(to.path.search(reqex) < 0) {
    // }

    if(to.params.lang== 'ru' || to.params.lang && store.getters.getLanguage != to.params.lang && ((to.name != 'profile' && to.name != 'home') || to.params.id)) {
        let params = to.params;
        params['lang'] = null;

        return routes.push({ name: to.name, params: params });
    }

    if(to.name !='home' || to.name =='home' && !window.userAuth) {
        let seo_list= store.getters.getSeo[store.getters.getLanguage];
        let seo= _.find(seo_list, {'uri': path});

        let title = "MINDPLAYS";
        if(seo) {
            document.title = seo.title + ' | ' + title;
        }
    }

    // store.commit("updateLanguage", lang);
    //
    //
    // if (to.meta.title) {
    //     document.title = title + ' | ' + to.meta.title;
    // } else {
    //     document.title = title;
    // }



    if (!to.meta.middleware) {
        return next();
    }
    const middleware = to.meta.middleware;

    const context = {
        to,
        from,
        next,
        store
    };


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    });
});

export default routes;
