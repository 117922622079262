<template>
  <div :class="(this.auth) ? '' : 'all-main-content'">
    <div class="home-games-new all-content blog-pages-new">
      <div class="home-games-left">
        <LayoutNavAuth>
          <h1>{{__('News & Articles')}}</h1>
          <div class="wrap-btn">
            <div class="wrap-btn-sort">
              <v-btn text v-ripple="false" class="btn-search" @click="modal_sort = !modal_sort" :class="(modal_sort || filter.length) ? 'btn-search--active': ''">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27778 3.63981C6.27778 5.0963 5.0963 6.2787 3.63889 6.2787C2.18148 6.2787 1 5.0963 1 3.63981C1 2.18241 2.18148 1 3.63889 1C5.0963 1 6.27778 2.18241 6.27778 3.63981Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.93269 3.63984H16.2781" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0006 12.8666C11.0006 11.4091 12.182 10.2277 13.6394 10.2277C15.0969 10.2277 16.2783 11.4091 16.2783 12.8666C16.2783 14.324 15.0969 15.5054 13.6394 15.5054C12.182 15.5054 11.0006 14.324 11.0006 12.8666Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.34574 12.8666H1.00037" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </v-btn>
              <div v-if="filter.length" class="block-count">{{filter.length}}</div>
            </div>

            <div class="wrap-btn-search">
              <v-btn text v-ripple="false" class="btn-search" @click="changeClass">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </v-btn>
            </div>
          </div>
          <div class="search" slot="right" ref="block_search">
            <div class="search__icon">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.6857 14.0743L16.6191 17" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="search__input">
              <SearchDefault
                      style="width: 100%"
                      v-model="search"
                      :placeholder="__('Search ...')"
                      class_search="games_search"
                      v-on:keyup.enter="searchNews()"
                      @searching="searchNews()"
              ></SearchDefault>
            </div>
            <div class="cancel-icon" @click="cancelSearch()">
              <svg v-if="show" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.59548 4.00097L7.87669 0.719756C8.04111 0.555319 8.04111 0.288726 7.87669 0.124304C7.71225 -0.0401327 7.44564 -0.0401327 7.28122 0.124304L3.99999 3.40552L0.71878 0.124304C0.554343 -0.0401327 0.287749 -0.0401327 0.123328 0.124304C-0.0410937 0.288742 -0.0411093 0.555335 0.123328 0.719756L3.40454 4.00097L0.123328 7.28218C-0.0411093 7.44662 -0.0411093 7.71321 0.123328 7.87763C0.287765 8.04207 0.554358 8.04206 0.71878 7.87763L3.99999 4.59642L7.28119 7.87763C7.44563 8.04207 7.71223 8.04206 7.87666 7.87763C8.04108 7.7132 8.04108 7.4466 7.87666 7.28218L4.59548 4.00097Z" fill="currentColor"/>
              </svg>
            </div>
          </div>
          <div slot="line" class="loading"  :style="{width: progress + '%'}"></div>
        </LayoutNavAuth>

        <div class="wrapper-blog" :class="(this.auth) ? 'dashboard-content' : 'all-content'">
          <div class="mainer" >
            <!--        <a class="tw" href="https://twitter.com/share?url={{ route('news.page',["news" => $news->url] )}}&text={{$news->title}}" onclick="window.open(this.href, this.title, 'toolbar=0, status=0, width=548, height=325'); return false" target="_parent">-->
            <!--        </a>-->
            <!--        <a class="fb"  href="http://www.facebook.com/sharer.php?s=100&p[url]={{route('news.page',["news" => $news->url] )}}&p[title]={{$news->title}}&p[summary]={{$news->description}}" onclick="window.open(this.href, this.title, 'toolbar=0, status=0, width=548, height=325'); return false" title="Поделиться ссылкой на Фейсбук" target="_parent">-->
            <!--        </a>-->
            <!--        <div class="head">-->
            <!--          <h1 class="head__title">{{__('Blog')}}</h1>-->
            <!--          <span class="head__text">{{__('description blog')}}</span>-->
            <!--        </div>-->
            <div class="body" v-if="news && news.length" ref="news_list">
              <div class="blog-wrapper" v-if="news.length">
                <div v-for="(item, key) in news" :key="key" style="width: 100%">
                  <BlogItemFirst v-if="key == 0" v-bind:item="item" :sorting.sync="sorting" :filter.sync="filter"></BlogItemFirst>
                  <!--              <div v-if="key == 0" class="block-create-new">-->
                  <!--                <BaseButton class_btn="white-btn-blue" width="100" :to="{name: 'chat', params: {id: 'support', lang: $store.getters.getLang}}">-->
                  <!--                  <span>{{__('Create Articles')}}</span>-->
                  <!--                </BaseButton>-->

                  <!--                <p> {{__('If you have something to tell us, we will indicate your name as the author and reward with a coin')}} </p>-->

                  <!--                <svg width="62" height="60" viewBox="0 0 62 60" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                  <!--                  <g clip-path="url(#clip0_2621_2287)">-->
                  <!--                    <rect y="-14" width="74" height="74" rx="37" fill="white"/>-->
                  <!--                    <path d="M25.5909 34.2974C26.1913 35.3388 27.7831 34.9128 27.7831 33.7107V7.71062C27.7831 5.92602 29.2277 4.47931 31.0097 4.47931H49.5963C50.1864 4.47931 50.4287 3.75097 49.9374 3.42377C46.2223 0.94967 41.7625 -0.491943 36.9665 -0.491943C27.5765 -0.491943 19.4753 5.03406 15.7285 13.0159C15.012 14.5421 15.2224 16.3124 16.0645 17.773L25.5909 34.2974Z" fill="#00AEFF"/>-->
                  <!--                    <path d="M31.3437 7.45195C30.582 7.45195 30.3117 8.46197 30.9715 8.84342L54.8442 22.6466C56.3875 23.5389 56.9163 25.5152 56.0253 27.0607L46.7319 43.1808C46.4369 43.6926 46.9456 44.2668 47.4742 44.0043C51.4712 42.0193 54.9478 38.8721 57.3458 34.7126C62.212 26.2717 61.2524 16.0634 55.7165 8.75725C55.0696 7.90347 54.0377 7.4519 52.9675 7.4519L31.3437 7.45195Z" fill="#01DF72"/>-->
                  <!--                    <path d="M53.229 25.8223C53.6099 25.1616 52.8716 24.4222 52.2119 24.8036L28.3391 38.6068C26.7958 39.4991 24.8225 38.9696 23.9315 37.4241L14.6382 21.304C14.3431 20.7922 13.5922 20.9463 13.5549 21.536C13.2729 25.9951 14.2562 30.5839 16.6542 34.7434C21.5204 43.1843 30.8279 47.4562 39.9138 46.308C40.9756 46.1738 41.882 45.5047 42.4171 44.5765L53.229 25.8223Z" fill="#026EED"/>-->
                  <!--                    <path fill-rule="evenodd" clip-rule="evenodd" d="M37 60C57.4345 60 74 43.4345 74 23C74 2.56546 57.4345 -14 37 -14C16.5655 -14 0 2.56546 0 23C0 43.4345 16.5655 60 37 60ZM37 51.7777C52.8935 51.7777 65.7777 38.8935 65.7777 23C65.7777 7.10642 52.8935 -5.77783 37 -5.77783C21.1064 -5.77783 8.22217 7.10642 8.22217 23C8.22217 38.8935 21.1064 51.7777 37 51.7777Z" fill="url(#paint0_angular_2621_2287)"/>-->
                  <!--                    <path d="M39.1536 51.7776H34.8467V59.9998C36.2893 59.9998 37.7247 59.9843 39.1536 59.9567V51.7776Z" fill="white" fill-opacity="0.5"/>-->
                  <!--                    <path d="M55.8031 44.8279L61.6509 50.5901C62.1651 50.1294 62.6697 49.6548 63.1616 49.1628C63.6967 48.6278 64.2111 48.0777 64.709 47.5163L58.8692 41.762L55.8031 44.8279Z" fill="white" fill-opacity="0.5"/>-->
                  <!--                    <path d="M8.22222 20.8464H0.04307C0.0155129 21.5608 0 22.2785 0 22.9999C0 23.7213 0.0155129 24.4389 0.04307 25.1533H8.22222V20.8464Z" fill="white" fill-opacity="0.5"/>-->
                  <!--                    <path d="M58.7629 4.30418L64.6515 -1.58456C64.1723 -2.12222 63.6778 -2.64961 63.1642 -3.16327C62.6506 -3.67679 62.1232 -4.17123 61.5854 -4.65063L55.6968 1.23797L58.7629 4.30418Z" fill="white" fill-opacity="0.5"/>-->
                  <!--                    <path d="M15.2698 41.6643L9.34998 47.5842C9.82924 48.1218 10.3237 48.6492 10.8373 49.1629C11.3509 49.6764 11.8783 50.1709 12.4161 50.6503L18.3359 44.7304L15.2698 41.6643Z" fill="white" fill-opacity="0.5"/>-->
                  <!--                    <path d="M18.2379 1.22608L12.3272 -4.57178C11.8201 -4.11708 11.3227 -3.64836 10.8373 -3.16303C10.2956 -2.62132 9.77516 -2.06429 9.27161 -1.49571L15.1721 4.29203L18.2379 1.22608Z" fill="white" fill-opacity="0.5"/>-->
                  <!--                  </g>-->
                  <!--                  <defs>-->
                  <!--                    <radialGradient id="paint0_angular_2621_2287" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(37 23) rotate(128.904) scale(43.0176)">-->
                  <!--                      <stop stop-color="#00AEFF"/>-->
                  <!--                      <stop offset="0.232766" stop-color="#01DF72"/>-->
                  <!--                      <stop offset="0.399436" stop-color="#026EED"/>-->
                  <!--                      <stop offset="0.639026" stop-color="#00AEFF"/>-->
                  <!--                    </radialGradient>-->
                  <!--                    <clipPath id="clip0_2621_2287">-->
                  <!--                      <rect width="62" height="60" rx="4" fill="white"/>-->
                  <!--                    </clipPath>-->
                  <!--                  </defs>-->
                  <!--                </svg>-->
                  <!--              </div>-->
                </div>
              </div>

              <div class="blog-wrapper-item" v-if="news.length">
                <div v-for="(item, key) in news" :key="key" class="wrap-item">
                  <BlogItem v-bind:item="item" :sorting.sync="sorting" :filter.sync="filter"></BlogItem>
                </div>
              </div>
              <div v-else>{{__('No result')}}</div>
            </div>
            <div class="body" v-else>
              <!--          {{__('loading')}}-->
              <div class="skleton-blog">
                <div class="top">
                  <div class="left">
                    <SkeletonImage></SkeletonImage>
                  </div>
                  <div class="right">
                    <SkeletonText :count="5"></SkeletonText>
                  </div>
                </div>
                <div class="block-sklet">
                  <SkeletonButton class="img"></SkeletonButton>
                  <SkeletonText class="text"></SkeletonText>
                </div>
                <div class="body">
                  <div>
                    <SkeletonImage></SkeletonImage>
                    <SkeletonText :count="6" class="text"></SkeletonText>
                  </div>
                  <div>
                    <SkeletonImage></SkeletonImage>
                    <SkeletonText :count="6" class="text"></SkeletonText>
                  </div>
                  <div>
                    <SkeletonImage></SkeletonImage>
                    <SkeletonText :count="6" class="text"></SkeletonText>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="wrap-sort" v-if="categories">
            <SortingBlog @updateBlogs="updateBlogs" @updateFilter="updateFilter" :filter.sync="filter" :sorting.sync="sorting" :search.sync="search" v-bind:modal_sort.sync="modal_sort" v-bind:categories="categories" v-if="modal_sort"></SortingBlog>
          </div>
        </div>
      </div>
      <div class="home-games-right">
         <GeneralChatNew></GeneralChatNew>
      </div>
    </div>


    <div v-if="!auth" class="block-footer">
      <Footer></Footer>
    </div>


  </div>
</template>


<script>
  import GeneralChatNew from "../modules/GeneralChatNew";
import BlogItemFirst from "./Blog/BlogItemFirst";
import BlogItem from "./Blog/BlogItem";
import SortingBlog from "./Blog/SortingBlog"
import LayoutNavAuth from "../modules/LayoutNavAuth";
import SearchDefault from "../modules/SearchDefault.vue";
import Footer from "../modules/Footer";
import SkeletonImage from "../modules/SkeletonImage";
import SkeletonText from "../modules/SkeletonText";
import SkeletonButton from "../modules/SkeletonButton";

export default {
  // name: "Blog",
  data() {
    return {
      show: false,
      search: null,
      news: [],
      categories: null,
      next_page_url: false,
      loadingPage: false,
      response: null,
      progress: 0,
      modal_sort: false,
      filter: [],
      sorting: {
        category_id: [],
      }
    };
  },

  watch: {
    search(val) {
      if(val) {
        this.show = true;
      } else {
        this.show = false;
      }
    }
  },

  created() {
    let response = {};

    if(this.$route.name== 'blogs_category') {
        response[this.$route.params.type]= this.$route.params.name;
    }

    this.response = response;
    this.getNews(response);

    this.$addListener(window,'scroll',  () => {
      this.scrollNews();
    });
  },

  beforeRouteUpdate (to, from, next) {
      // обрабатываем изменение параметров маршрута...
      // не забываем вызвать next()
      let response = {};

      if(to.name== 'blogs_category') {
          response[to.params.type]= to.params.name;
      }

      this.response = response;

      this.getNews(response);
      return next();
  },
  computed: {
    languages() {
      return this.$store.state.language.lang;
    },
    auth() {
      return this.$store.state.auth.user;
    },
    category() {
      return this.$route.params.name;
    },
    author() {
      return this.$route.params.name;
    },
  },


  methods: {



    changeClass() {
      this.$refs.block_search.classList.toggle('active');
    },

    getNews(response, url="ajax/blog") {
      let timeout = setInterval(() => {
        this.progress += 1;
        if(this.progress > 50) {
          clearInterval(timeout)
        }
      },15);
      response.category_id = this.sorting.category_id.length ? this.sorting.category_id : null;
      response.search = this.search;
      response.lang = this.languages;

      this.$http
        .post(url, response)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if(this.$checkAuth(response)) {
              // response = {
              //     "data": {
              //         "category": [
              //             {
              //                 "id": 1,
              //                 "category": "Category"
              //             },
              //             {
              //                 "id": 2,
              //                 "category": "test2"
              //             },
              //             {
              //                 "id": 5,
              //                 "category": "test5"
              //             },
              //             {
              //                 "id": 6,
              //                 "category": "tes5"
              //             },
              //             {
              //                 "id": 7,
              //                 "category": "qwe4"
              //             }
              //         ],
              //         "blog": {
              //             "current_page": 1,
              //             "data": [
              //                 {
              //                     "url": "populyarnyie_wer",
              //                     "category_id": 6,
              //                     "title": "populyarnyie igryi onlayn: chervyaki zmeyki tanchiki strelyalki i mnogo drugih igr!",
              //                     "img": "/assets/games/3.jpg",
              //                     "description": "populyarnyie igryi onlayn: chervyaki zmeyki tanchiki strelyalki i mnogo drugih igr! igrayte na MindPlays — s druzyami ili drugimi igrokami besplatno i na dengi! bezgranichnyie vozmojnosti!",
              //                     "created_at": "2020-09-11 18:38:31",
              //                     "category": "OVERVIEWS"
              //                 },
              //                 {
              //                     "url": "",
              //                     "category_id": 1,
              //                     "title": "Онлайн-игры с живыми игроками набирают популярность",
              //                     "img": "/assets/games/3.jpg",
              //                     "description": "populyarnyie igryi onlayn: chervyaki zmeyki tanchiki strelyalki i mnogo drugih igr! igrayte na MindPlays — s druzyami ili drugimi igrokami besplatno i na dengi! bezgranichnyie vozmojnosti!",
              //                     "created_at": "2021-03-26 10:24:21",
              //                     "category": "OVERVIEWS"
              //                 },
              //                 {
              //                     "url": "dfsadfs",
              //                     "category_id": 2,
              //                     "title": "Онлайн-игры с живыми игроками набирают популярность",
              //                     "img": "/assets/games/3.jpg",
              //                     "description": "На сегодняшний день многие люди становятся поклонниками игорного заработка. Сложно поверить в то, что от игр можно получать не только удовольствие, но и реальный доход. Сегодня онлайн-игры на деньги являются безопасным и весьма выгодным развлечением, главное – выбрать надежный интернет-ресурс с хорошей репутацией =) Хоть в основном",
              //                     "created_at": "2021-03-26 11:13:50",
              //                     "category": "test2"
              //                 },
              //               {
              //                 "url": "",
              //                 "category_id": 1,
              //                 "title": "dsasda3",
              //                 "img": "/assets/games/3.jpg",
              //                 "description": "dsadsadsasda adssda",
              //                 "created_at": "2021-03-26 10:24:21",
              //                 "category": "test"
              //               },
              //               {
              //                 "url": "dfsadfs",
              //                 "category_id": 2,
              //                 "title": "dfsadfs4",
              //                 "img": "/assets/games/3.jpg",
              //                 "description": "dafadfadfadf",
              //                 "created_at": "2021-03-26 11:13:50",
              //                 "category": "test2"
              //               }
              //             ],
              //             "first_page_url": "https://mindplay.loc/ajax/blog?page=1",
              //             "from": 1,
              //             "last_page": 1,
              //             "last_page_url": "https://mindplay.loc/ajax/blog?page=1",
              //             "next_page_url": null,
              //             "path": "https://mindplay.loc/ajax/blog",
              //             "per_page": 12,
              //             "prev_page_url": null,
              //             "to": 3,
              //             "total": 3
              //         }
              //     },
              //     "status": 200
              // };

              this.categories = response.data.category;
              // let news = this.news;
              // this.news = news.concat(response.data.blog.data);
              this.news = response.data.blog.data;
              this.next_page_url = response.data.blog.next_page_url;
              this.loadingPage = false;
              clearInterval(timeout);
              this.progress = 100;
              setTimeout(() => {
                this.progress = 0;
              }, 500);
          }
        })
        .catch((err) => {
          this.httpHandler(err);
          clearInterval(timeout);
          this.loadingPage = false;
      });
    },
    searchNews() {
      let response = {
        category_id: this.sorting.category_id.length ? this.sorting.category_id : null,
        search: this.search,
        lang: this.languages
      }
      this.getNews(response);
    },

    cancelSearch() {
      this.search = null;
      let response = {
        category_id: this.sorting.category_id.length ? this.sorting.category_id : null,
        search: this.search,
        lang: this.languages,
      }
      this.getNews(response);
      this.$refs.block_search.classList.toggle('active');
    },
    scrollNews: function () {
      let timeout = setInterval(() => {
        this.progress += 1;
        if(this.progress > 50) {
          clearInterval(timeout)
        }
      },15);
      const progress =
        document.documentElement.scrollTop /
        (document.documentElement.scrollHeight -
          document.documentElement.clientHeight);
      if (!this.loadingPage && this.next_page_url) {
        let bottomOfWindow = 0.7 <= progress;
        if (bottomOfWindow) {
          this.loadingPage = true;
          // this.getNews(this.response, this.next_page_url);
          this.$http
                  .post(this.next_page_url, this.response)
                  .then((response) => {
                    return response.json();
                  })
                  .then((response) => {
                    if(this.$checkAuth(response)) {
                      this.categories = response.data.category;
                      let news = this.news;
                      this.news = news.concat(response.data.blog.data);
                      this.next_page_url = response.data.blog.next_page_url;
                      this.loadingPage = false;
                      clearInterval(timeout);
                      this.progress = 100;
                      setTimeout(() => {
                        this.progress = 0;
                      }, 500);
                    }
                  })
                  .catch((err) => {
                    this.httpHandler(err);
                    clearInterval(timeout);
                    this.loadingPage = false;
                  });
        }
      }
    },
    // scrollNews: function () {
    //   let news_list = this.$refs.news_list;
    //   const progress = news_list.scrollTop / (news_list.scrollHeight - news_list.clientHeight);
    //   if (!this.loadingPage && this.next_page_url) {
    //     let bottomOfWindow = 0.7 <= progress;
    //     if (bottomOfWindow) {
    //       this.loadingPage = true;
    //       this.$http.post(this.next_page_url)
    //         .then((response) => {
    //           return response.json();
    //         })
    //         .then((response) => {
    //           response = response.data;
    //           let news = this.news;
    //           this.news = news.concat(response.data);
    //           this.next_page_url = response.next_page_url;
    //           this.loadingPage = false;
    //         })
    //         .catch((err) => {
    //           this.httpHandler(err);
    //         });
    //     }
    //   }
    // },

    updateBlogs(response) {
      if(response) {
        this.news = response.blog.data;
        this.next_page_url = response.blog.next_page_url;
      }
    },
    updateFilter() {
      this.filter = [];
      this.$router.push({name: 'blogs', params: {lang: this.$store.getters.getLang}});
    }
  },
  components: {
    GeneralChatNew,
    SkeletonButton,
    SkeletonText,
    SkeletonImage,
    BlogItemFirst,
    BlogItem,
    SortingBlog,
    LayoutNavAuth,
    SearchDefault,
    Footer
  },
};
</script>



<style lang="scss" scoped>
  .all-main-content .all-content{
    margin-right: 0;
    margin: 0 auto;
  }
.wrapper-blog {
  display: flex;
  padding: 20px 0 0;

}
.mainer {
  width: 100%;
  box-sizing: border-box;
}

.dark {
  .head {
    &__title {
      color: #fff;
    }

    &__text {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.head {
  padding-top: 22px;

  &__title {
    font-weight: 400;
    font-size: 36px;
    color: #002B50;
    text-align: center;
    margin-bottom: 15px;
  }

  &__text {
    display: block;
    max-width: 570px;
    margin: 0 auto 50px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #96afd1;
  }
}

.body {

  .blog-wrapper {
    display: flex;
    flex-wrap: wrap;

    .block-create-new {
      background: linear-gradient(92.11deg, #00AEFF 0%, #026EED 100%);
      border-radius: 4px;
      height: 60px;
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 10px;

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        color: #FFFFFF;
        margin: 0 60px 0 10px;
      }

      svg {
        position: absolute;
        right: 0;
        top: 0;
      }

    }
  }


  .blog-wrapper-item {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;



    .wrap-item {
      padding: 20px;
      border-radius: 20px;
      background: #0b0c1a;
      width: 49%;
      margin: 0 2% 20px 0;

      &:nth-child(2n + 1) {
        margin-right: 0;
      }

      &:first-child {
        display: none;
      }
    }
  }
}

  .block-footer {
    margin-top: 20px;
  }

@media screen and (max-width: 1200px) {
  .wrapper-blog {
    padding: 20px 20px 0;
  }
}

@media screen and (max-width: 1100px){
  .body {
    .blog-wrapper-item {
      .wrap-item {
        margin: 0 8px 10px 0;
      }
    }
  }
}

  @media screen and (max-width: 900px){
    .body {
      .blog-wrapper-item {
        .wrap-item {
          width: 32.5%;
          margin: 0 8px 10px 0;
        }
      }
    }
  }

  @media screen and(max-width: 768px){

    .wrapper-blog {
      padding: 20px 15px 0;
    }
    .body {
      .blog-wrapper-item {
        .wrap-item {
          width: 49%;
          margin: 0 10px 10px 0;

          &:nth-child(3n + 4) {
            margin-right: 10px;
          }

          &:nth-child(odd) {
            margin-right: 0;
          }

          &:first-child {
            display: none;
          }
        }
      }
    }
  }

@media screen and (max-width: 650px) {

  h4 {
    display: none;
  }
  .body {
    .blog-wrapper-item {
      .wrap-item {
        &:first-child {
          display: block;
        }

        &:nth-child(3n + 4) {
          margin-right: 10px;
        }

        &:nth-child(odd) {
          margin-right: 10px;
        }

        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
}

  @media screen and (max-width: 550px) {
    .body {

      .blog-wrapper {
        .block-create-new {
          height: auto;
          min-height: 60px;
          p {
            font-size: 12px;
          }
        }
      }
      .blog-wrapper-item {
        .wrap-item {
          width: 100%;
          margin: 0 0 20px 0;
          &:first-child {
            display: block;
          }

          &:nth-child(3n + 4) {
            margin-right: 0;
          }

          &:nth-child(odd) {
            margin-right: 0;
          }

          &:nth-child(even) {
            margin-right: 0;
          }
        }
      }
    }
  }

   @media screen and (max-width: 500px){
     .head {
       padding-top: 20px;

       &__title {
         font-size: 30px;
       }

       &__text {
         font-size: 14px;
         margin-bottom: 30px;
       }
     }
   }

</style>