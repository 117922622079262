<template>
  <div class="wrap-info-settings dev-nft" v-if="nft_list" >
    <div>
      <BaseButton class="btn-guest" v-on:click="openCreate"><span>+ Создать NFT</span></BaseButton>
    </div>

    <GameNftList v-bind:nft_list.sync="nft_list" v-bind:next_page_url.sync="next_page_url" ></GameNftList>
    <ModalDefault v-if="modal_cancel" :width="800">

      <div class="wrapper-modal-developer nft-windows">
        <div class="wrapper-modal-developer__head ">
          <h2>Create NFT</h2>
        </div>
        <div class="wrapper-modal-developer__body nft-text-white">
          <div v-if="metamask">

            <div v-if="metamask['error']">
              <p>{{metamask['message']}}</p>
              <div class="btn-nft-window"  v-if="metamask['error']== 'network'">
                <BaseButton class_btn="baseButton grey-btn-game" v-on:click="modal_cancel= false"><span>Cancel</span></BaseButton>
                <BaseButton v-on:click="switchNetwork" :loading="switch_network"><span>Switch network</span></BaseButton>
              </div>
              <div class="btn-nft-window"  v-else-if="metamask['error']== 'account'">
                <BaseButton class_btn="baseButton grey-btn-game" v-on:click="modal_cancel= false"><span>Cancel</span></BaseButton>
                <BaseButton v-on:click="connectAccount" :loading="switch_network"><span>Connect account</span></BaseButton>
              </div>
            </div>
            <div v-else-if="modal_cancel['status']">
              <p>{{modal_cancel['title']}}</p>
              <div v-html="modal_cancel['message']"></div>
              <BaseButton class_btn="baseButton grey-btn-game" v-if="modal_cancel['success']" v-on:click="modal_cancel= false"><span>Close</span></BaseButton>
            </div>
            <div v-else-if="waiting">
              Ожидание подтверждения MetaMask
            </div>
            <GameCreateNFT v-else @createMintingRecord="createMintingRecord" v-bind:nft_list.sync="nft_list" @updateNftList="updateNftList" v-bind:modal_cancel.sync="modal_cancel" v-bind:next_page_url.sync="next_page_url"></GameCreateNFT>
          </div>
          <div v-else class="nft-text-white">
            no have metamask
            <BaseButton
                    :loading="submitDisabled"
                    v-on:click="modal_cancel= null"
            >
              <span>{{__('Close')}}</span>
            </BaseButton>
          </div>
        </div>
      </div>
    </ModalDefault>
  </div>
    <div v-else>
      loading
    </div>
</template>

<script>
  import GameNftList from './GameNftList';
  import GameCreateNFT from './GameCreateNFT';
  import ModalDefault from "../../modules/ModalDefault";
  import {MarketPlaceMixin} from "../../../mixins/MarketPlaceMixin";
  import _ from 'lodash';
  const { ethers } = require('ethers');

    export default {
        name: "GameInfoNFT",
        data() {
            return {
                waiting: null,
                next_page_url: null,
                nft_list: null,
                modal_cancel: null
            };
        },
        watch: {

        },
        mixins: [MarketPlaceMixin],
        props: {
            developers: {
                type: Object,
            },
            categories: {
                type: Array,
            },
        },
        computed: {
        },
        asyncComputed: {

        },
        created() {
            if(window.ethereum){
                // this.accounts = window.ethereum.request({
                //     method: 'eth_requestAccounts',
                // });
                window.ethereum.on('accountsChanged', () => {
                    // console.log('accountsChanged');
                    window.location.reload();
                    // this.switch_network= false;
                    // this.details();
                    return true;
                });
                window.ethereum.on('chainChanged', () => {
                    // console.log('chainChanged');
                    window.location.reload();
                    // this.switch_network= false;
                    // this.details();
                    return true;
                });
            }

            this.$http.get("ajax/developers/" + this.$route.params.id + "/nft")
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        this.updateNftList(response.data);
                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });

        },
        validations() {

        },
        methods: {
            async createMintingRecord(tokenUri, price, response) {
                this.waiting= true;

                let nft_list= this.$cleanObject(this.nft_list);
                console.log('nft_list23');
                console.log(nft_list);
                
                nft_list.unshift(response.nft);

                console.log('nft_list');
                console.log(nft_list);
                this.nft_list= nft_list;

                let tx= await this.marketPlace.createMintingRecord(
                    tokenUri,
                    ethers.utils.parseUnits(price.toString())
                )
                    .catch((err) => {
                        console.log('err createMintingRecord');
                        console.log(err);
                        this.updateMinting(tokenUri, 2);
                    });

                if(tx) {
                    this.txMessage(tx.hash);
                    let txReady= await tx.wait(3);

                    if (txReady.status !== 1) {
                        this.$notify({
                            group: "message",
                            title: "Transaction failed",
                        });

                        this.updateMinting(tokenUri, 1);
                        return false;
                    }

                    this.updateMinting(tokenUri, 1);
                }
                return tx;
            },
            updateMinting(tokenUri, status) {
                this.modal_cancel= null;
                this.waiting= null;

                let nft_list= this.$cleanObject(this.nft_list);
                let index= _.findIndex(nft_list, {'token_url': tokenUri});
                console.log('index');
                console.log(nft_list);
                console.log(tokenUri);
                console.log(index);
                if(index >= 0) {
                    nft_list[index]['status']= status;
                }


                this.nft_list= nft_list;

                // let data= {
                //     tokenUri: tokenUri,
                //     status: status
                // };
                //
                //
                // this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/developers/" + this.$route.params.id + "/nft/update", data)
                //     .then((response) => {
                //         return response.json();
                //     })
                //     .then((response) => {
                //         if(this.$checkAuth(response)) {
                //             this.$emit('updateNftList', response.data);
                //             this.$emit('update:modal_cancel', null);
                //         }
                //     })
                //     .catch((err) => {
                //         this.submitDisabled = false;
                //         let response = this.httpHandler(err);
                //         if (response.error) {
                //             this.formValidate = response.error.message;
                //         }
                //     });
                //
                //
                // console.log('testewqqw ewqe qweqwettestewqqw ewqe qweqwet');
                // console.log(tokenUri);
            },
            updateNftList(response) {
                this.nft_list = response.data;
                this.next_page_url = response.next_page_url;
            },
            openCreate() {
                if(!this.marketPlace) {
                    console.log('marketPlacemarketPlace');
                    this.details();
                }
                this.modal_cancel= true;
            }
        },
        components: {
            GameCreateNFT,
            ModalDefault,
            GameNftList
        },
    };
</script>

<style lang="scss" scoped>
  .wrapper-modal-developer {
    max-height: 100%;
    height: calc(100vh - 100px);
    overflow: scroll;
  }

  .dark {
    .wrapper-modal-developer {
      background: #171827;

      &__head {
        border-bottom: 1px solid #2B2B2B;

        h2 {
          color: #fff;
        }
      }

      &__body {
        .wrapper-inputs {
          .wrap-input {
            .check-text {
              color: #fff;
            }
          }
        }
        .block-type {
          &__title {
            color: #96AFD1;
          }
        }
      }
    }
  }

  .wrapper-modal-developer {
    background: #FFFFFF;
    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &__head {
      padding: 20px 25px;
      border-bottom: 1px solid #F0F6FE;

      h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
      }
    }

    &__body {
      padding: 25px 25px 27px;
      .block-name {
        display: flex;
        align-items: flex-end;

        span {
          margin: 0 38px 0 10px;
        }
      }

      .block-category {
        display: flex;
        align-items: flex-end;
        margin-bottom: 28px;

        span {
          margin: 0 35px 0 10px;
          padding-left: 3px;
        }
      }

      .block-type {
        display: flex;
        align-items: flex-start;

        &__title {
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #6886AF;
          margin-bottom: 0;
        }

        span {
          margin: 0 35px 0 10px;
          padding-left: 4px;

          img {
            height: 20px;
          }
        }
        .v-input--radio-group.v-input--radio-group--row .v-radio {
          margin-right: 38px;
        }
      }

      .block-create {
        display: flex;
        align-items: flex-start;
        margin-top: 6px;

        &__title {
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #6886AF;
          margin-bottom: 0;
        }

        span {
          margin: 0 37px 0 11px;
          padding-left: 4px;

          img {
            height: 20px;
          }
        }
        .v-input--radio-group.v-input--radio-group--row .v-radio {
          margin-right: 38px;
        }
      }

      .wrapper-inputs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-top: -16px;

        .wrap-input {
          display: flex;
          align-items: center;
          margin-left: 12px;

          .label-text {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #002b50;
            margin-left: 26px;
            margin-right: 6px;
          }

          .check-text {
            margin-top: 30px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #002b50;
            margin-right: 6px;
            margin-left: 25px;

            a {
              color: #26a9f4;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .wrap-btn {
          display: flex;
          .btn-cab {
            margin: 0 0 0 15px;
            height: 40px;
          }

          .btn-secondary {
            min-width: 105px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width:1024px) {
    .wrapper-modal-developer {
      &__body {
        padding: 20px;

        .wrapper-inputs {
          flex-direction: column;

          .wrap-input {
            width: 100%;

            .label-text {
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #002b50;
              margin-left: 4px;
              margin-right: 6px;
            }
            .check-text {
              margin-top: 28px;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #002b50;
              margin-left: -3px;
              margin-right: 6px;

              a {
                color: #26a9f4;
                text-decoration: none;
                margin-left: 0;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .wrap-btn {
            width: 100%;
            justify-content: center;
            margin-top: 15px;
          }
        }

        .block-name,
        .block-category,
        .block-type {
          span {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px){
    .wrapper-modal-developer {

      &__head {
        padding: 15px;

        h2 {
          font-size: 18px;
          line-height: 25px;
        }
      }

      &__body {
        padding: 15px;
        .block-category {
          margin-bottom: 20px;
          margin-top: -10px;
        }

        .block-type {
          .v-input--radio-group.v-input--radio-group--row .v-radio {
            margin-bottom: 6px;
          }
          .v-input--selection-controls {
            margin-top: 10px;
            padding-top: 0;
          }
        }

        .wrapper-inputs {
          .wrap-input {
            margin-top: -15px;
          }
        }
      }
    }
  }
</style>