<template>
  <div class="messenger">
    <LayoutNavAuth class_nav="nav-messenger">
<!--      <h1>{{__('Messenger')}}</h1>-->
      <div slot="line" class="loading"  :style="{width: progress + '%'}"></div>
    </LayoutNavAuth>

    <vue-headful
        :title="pageTitle"
        description=""
    />
    <div class="wrap-messenger dashboard-content" :class="(updateMess) ? 'open' : ''">
      <div class="left-mess">
        <div class="left-chat">
          <MessengerUsersList @openMessages="openMessages" v-bind:user="user" v-bind:active_id="active_id"></MessengerUsersList>
        </div>
        <div class="right-chat">
          <div v-if="messages == false" class="loding-mess">
            <div class="skeleton-mess-right">
              <div class="skeleton-mess-right__top">
                <SkeletonListAvatarLine></SkeletonListAvatarLine>
              </div>
              <div class="skeleton-mess-right__body">
                <div class="body-left">
                  <SkeletonText :count="2"></SkeletonText>
                </div>
                <div class="body-left">
                  <SkeletonText :count="2"></SkeletonText>
                </div>
                <div class="body-left">
                  <SkeletonText :count="3"></SkeletonText>
                </div>

                <div class="body-center">
                  <SkeletonText></SkeletonText>
                </div>

                <div class="body-right">
                  <SkeletonText :count="3"></SkeletonText>
                </div>
                <div class="body-right">
                  <SkeletonText :count="2"></SkeletonText>
                </div>

                <div class="skeleton-mess-right__bottom"></div>
              </div>
            </div>
          </div>
          <MessengerMessages v-bind:messages.sync="messages" v-bind:messages_id.sync="messages_id" v-else-if="messages" v-bind:user="user" @actionOptions="actionOptions" v-bind:active_id="active_id"></MessengerMessages>
        </div>
      </div>
      <ModalDefault class_name="del-window" class="" v-if="modal_deleted" v-bind:modal_cancel.sync="modal_deleted">
        <div class="text-center" v-if="modal_deleted== 'messages'">
          <div><h2>{{__('Clear the history')}}</h2></div>
          <img src="/dist/images/frontend/delete-game-icon.svg" alt="">
          <div class="text-center text-window">
            <p>{{__('Are you sure you want to delete all messages ?!')}}</p>
            <div class="btn-window">
              <v-btn class="btn-info btn-del" v-on:click="requestDeleted(1)">{{__('Clear')}}</v-btn>
              <v-btn class="btn-info-ok btn-secondary" v-on:click="modal_deleted= false">{{__('Cancel')}}</v-btn>
            </div>
          </div>
        </div>
        <div v-else>
          <div><h2>{{__('Delete chat')}}</h2></div>
          <div class="text-center text-window">
            <p>{{__('Are you sure you want to delete the chat')}}</p>
            <div class="btn-window">
              <v-btn class="btn-info-ok" v-on:click="requestDeleted(2)">{{__('Delete')}}</v-btn>
              <v-btn class="btn-info-ok" v-on:click="modal_deleted= false">{{__('Cancel')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
  </div>
</template>

<script>

    import MessengerUsersList from './Messenger/MessengerUsersList';
    import MessengerMessages from './Messenger/MessengerMessages';
    import ModalDefault from '../modules/ModalDefault';
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    import vueHeadful from 'vue-headful';
    import SkeletonText from "../modules/SkeletonText";
    import SkeletonListAvatarLine from "../modules/SkeletonListAvatarLine";

    export default {
        name: 'Messages',
        data () {
            return {
                messages_id: [],
                messages: false,
                active_id: null,
                modal_deleted: false,
                user: null,
                progress: 0,
            }
        },
        asyncComputed: {

        },
        watch: {
            message(messages) {
                let user_id= this.user.to;
                messages= this.$cleanObject(messages);
                let mes_sound = true;

                if(messages== 'deleted') {
                    this.messages.data= [];
                    this.messages.total= 0;
                } else if(messages instanceof Array) {
                    for (let i= 0; i < messages.length; i++) {
                        let message= messages[i];
                        if(message.user_id== user_id || message.to== user_id) {
                            this.updateMessages(message);
                            if (mes_sound && message.to== user_id) {
                              try {
                                new Audio('/assets/media/MSG_MP.mp3').play();
                              } catch ($e) {
                                console.error($e);
                              }
                              mes_sound = false;
                            }
                        }
                    }
                } else {
                    if(messages.user_id== user_id || messages.to== user_id) {
                        this.updateMessages(messages);
                        if (messages.to== user_id) {
                          try {
                            new Audio('/assets/media/MSG_MP.mp3').play();
                          } catch ($e) {
                            console.error($e);
                          }
                        }
                    }
                }
            }
        },
        created() {
            this.$store.dispatch("asyncUsersList", null);

            let response= {
                id: ((this.public_id) ? this.public_id : null)
            };

          let timeout = setInterval(() => {
            this.progress += 1;
            if(this.progress > 50) {
              clearInterval(timeout)
            }
          },15);

            this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/chat", response)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                      //   response= {
                      //     "data": {
                      //         "users_list": {
                      //             "current_page": 1,
                      //             "data": [
                      //                 {
                      //                     "id": 666953196,
                      //                     "user_id": "235569904",
                      //                     "count": 0,
                      //                     "message": "weq",
                      //                     "to": "1110510318",
                      //                     "updated_at": "2021-03-15 07:50:13",
                      //                     "name": "testeeee",
                      //                     "last_name": null,
                      //                     "public_id": "1110510318",
                      //                     "photo": {
                      //                       "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                      //                       "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                      //                     },
                      //                     "bg_color": "#FCDD01",
                      //                     "online": "2021-03-11 17:57:24"
                      //                 },
                      //                 {
                      //                     "id": 963464519,
                      //                     "user_id": "235569904",
                      //                     "count": 1,
                      //                     "message": "This is a chat with MindPlays support\nIf you have any questions - write to us, we will try to answer you as quickly as possible. We hope you will not have any difficulties using our platform.\nWe are always open to your wishes!",
                      //                     "to": "notice",
                      //                     "updated_at": "2021-03-15 07:46:24",
                      //                     "name": "qwe",
                      //                     "last_name": null,
                      //                     "public_id": "235569904",
                      //                     "photo": null,
                      //                     "bg_color": "#3d9970",
                      //                     "online": "2021-03-15 09:58:48"
                      //                 },
                      //                 {
                      //                     "id": 1871474334,
                      //                     "user_id": "235569904",
                      //                     "count": 1,
                      //                     "message": "Welcome to the MindPlays platform!\nYou have received the “Welcome” award +1 Rating and + 250 $ LC\nImprove your gaming skills and make money with MindPlays!",
                      //                     "to": "support",
                      //                     "updated_at": "2021-03-15 07:46:24",
                      //                     "name": "qwe",
                      //                     "last_name": null,
                      //                     "public_id": "235569904",
                      //                     "photo": null,
                      //                     "bg_color": "#3d9970",
                      //                     "online": "2021-03-15 09:58:48"
                      //                 }
                      //             ],
                      //             "first_page_url": "https://mindplay.loc/ajax/chat?page=1",
                      //             "from": 1,
                      //             "last_page": 1,
                      //             "last_page_url": "https://mindplay.loc/ajax/chat?page=1",
                      //             "next_page_url": null,
                      //             "path": "https://mindplay.loc/ajax/chat",
                      //             "per_page": 24,
                      //             "prev_page_url": null,
                      //             "to": 3,
                      //             "total": 3
                      //         },
                      //         "messages": {
                      //             "current_page": 1,
                      //             "data": [
                      //                 {
                      //                     "id": "933595455",
                      //                     "user_id": "235569904",
                      //                     "to": "1110510318",
                      //                     "read": "0",
                      //                     "message": "weq",
                      //                     "resend_id": null,
                      //                     "message_id": null,
                      //                     "message_at": null,
                      //                     "created_at": "2021-03-15 07:50:13",
                      //                     "opp_type": "2",
                      //                     "resend": null,
                      //                     "user": null
                      //                 },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "1",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },
                      //               {
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               },{
                      //                 "id": "933595455",
                      //                 "user_id": "235569904",
                      //                 "to": "1110510318",
                      //                 "read": "0",
                      //                 "message": "weq",
                      //                 "resend_id": null,
                      //                 "message_id": null,
                      //                 "message_at": null,
                      //                 "created_at": "2021-03-15 07:50:13",
                      //                 "opp_type": "2",
                      //                 "resend": null,
                      //                 "user": null
                      //               }
                      //             ],
                      //             "first_page_url": "https://mindplay.loc/ajax/chat/search/message?page=1",
                      //             "from": 1,
                      //             "last_page": 1,
                      //             "last_page_url": "https://mindplay.loc/ajax/chat/search/message?page=1",
                      //             "next_page_url": null,
                      //             "path": "https://mindplay.loc/ajax/chat/search/message",
                      //             "per_page": 50,
                      //             "prev_page_url": null,
                      //             "to": 1,
                      //             "total": 1
                      //         },
                      //         "user": {
                      //             "id": 666953196,
                      //             "user_id": "235569904",
                      //             "count": 0,
                      //             "message": "weq",
                      //             "to": "1110510318",
                      //             "updated_at": "2021-03-15 07:50:13",
                      //             "name": "testeeee",
                      //             "last_name": null,
                      //             "public_id": "1110510318",
                      //             "photo": {
                      //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                      //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                      //             },
                      //             "bg_color": "#FCDD01",
                      //             "online": "2021-03-11 17:57:24"
                      //         }
                      //     },
                      //     "status": 200
                      // };

                        response = response.data;
                        let users_list = response.users_list,
                            user = response.user;
                        if (user) {
                            this.active_id = user.public_id;
                        }
                        this.user = user;


                        users_list.exclude_chats = [];
                        users_list.exclude_users = [];

                        for (let i = 0; i < users_list.data.length; i++) {
                            let list = users_list.data[i];
                            if (list.id) {
                                users_list.exclude_chats.push(list.id);
                            } else {
                                users_list.exclude_users.push(list.to);
                            }
                        }

                        this.$store.dispatch("asyncUsersList", users_list);

                        response.messages.data = response.messages.data.reverse();
                        this.messages = response.messages;
                        let messages_id = [];

                        for (let i = 0; i < response.messages.data.length; i++) {
                            let message = response.messages.data[i];
                            messages_id.push(message.id);
                        }
                        this.messages_id = messages_id;

                      clearInterval(timeout);
                      this.progress = 100;
                      setTimeout(() => {
                        this.progress = 0;
                      }, 500);
                    }
                })
                .catch((err) => {
                    this.submitDisabled = false;
                    let response = this.httpHandler(err);
                    if (response.error) {
                        this.formValidate = response.error.message;
                    }
                });
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            if(to.params.lang != to.params.lang) {
                document.title= this.pageTitle;
            }
            return next();
        },
        computed: {
            updateMess() {
              return this.$store.state.preference.updateMess
            },
            auth() {
                return this.$store.state.auth.user;
            },
            public_id: function () {
                return this.$route.params.id ? this.$route.params.id : null;
            },
            users_list() {
                return this.$store.getters.users_list;
            },
            message() {
                return this.$store.getters.message;
            },
            pageTitle() {
                let title = "MINDPLAYS";

                if(this.user) {
                    return title+' | '+this.__('Chat with')+' '+ (this.user.name +' '+((this.user.last_name) ? this.user.last_name : ''));
                } else {
                    return title
                }
            },
        },
        methods: {
            updateMessages(message) {
                let messages= this.$cleanObject(this.messages);

                if(message.deleted != "0" && message.deleted != null) {
                    let indexMessage= this.$filterIndexObject(messages.data, message.id);
                    if((message.deleted== "1" || (message.user_id== this.auth.id && message.deleted == "2") || (message.to== this.auth.id && message.deleted== "3")) && indexMessage >= 0) {
                        if(indexMessage >= 0) {
                            messages.data[indexMessage]['deleted'] = true;
                            this.messages = messages;
                        }
                    }
                } else {
                    message.deleted= null;
                    if(message.update) {
                        let indexMessage= this.$filterIndexObject(messages.data, message.id);

                        if(indexMessage >= 0) {
                            messages.data[indexMessage]= message;
                        }
                    } else {
                        this.messages_id.push(message.id);
                        messages.data.push(message);
                        messages.total += 1;
                    }
                    this.messages= messages;
                }
            },
            updateUsersList() {

            },
            requestDeleted(type) {
                let request= {
                    'user_id': this.user.to,
                    'type': type
                };
                this.modal_deleted= false;

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/chat/messages/deleted/", request)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    this.$checkAuth(response);
                    this.modal_deleted= false;
                })
                .catch((err) => {
                    this.submitDisabled = false;
                    let response = this.httpHandler(err);
                    if (response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            },
            actionOptions(option) {
                switch (option.value) {
                    case 'deleted_message':
                        this.deleteMessages(option.message);
                        break;
                    case 'deleted_history':
                        this.modal_deleted= 'messages';
                        break;
                    case 'deleted_chat':
                        this.modal_deleted= 'chat';
                        break;
                }
            },
            openMessages(item) {
                let public_id= ((['notice', 'support']).includes(item.to)) ? item.to : item.public_id;

                this.$router.push({ name: 'chat', params: {id: public_id, lang: this.$store.getters.getLang}});

                this.messages= false;
                this.messages_id= [];
                this.active_id= public_id;

                let response= {
                    user_id: item.to,
                    messages_id: this.messages_id,
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/chat/messages/open/", response)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        response = response.data;
                        this.user = item;
                        response.messages.data = response.messages.data.reverse();

                        let messages_id = [];
                        for (let i = 0; i < response.messages.data.length; i++) {
                            let message = response.messages.data[i];
                            messages_id.push(message.id);
                        }
                        this.messages_id = messages_id;
                        this.messages = response.messages;
                    }
                })
                .catch((err) => {
                    this.submitDisabled = false;
                    let response = this.httpHandler(err);
                    if (response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            }
        },
        components: {
            MessengerUsersList,
            MessengerMessages,
            ModalDefault,
            vueHeadful,
            LayoutNavAuth,
            SkeletonText,
            SkeletonListAvatarLine
        },

    }
</script>
