<template>
  <div v-if="dialog">
    <!--<v-row justify="center">-->
      <v-dialog
        v-model="dialog" persistent :max-width="width"
        v-on:click:outside="$emit('update:modal_cancel', false)"
      >
        <div class="modal-click-window" :class="class_name" style="position: relative; border-radius: 8px">
          <span class="close"></span>
          <slot></slot>
          <div class="top-window">
            <p>{{__('FREE COINS')}}</p>
          </div>
          <div class="img-block">
            <img src="/dist/images/frontend/img-coin-icon.svg" alt="">
          </div>
          <div v-if="updateBonus == 1">
            <div class="text-block">
              <p class="text-g">{{__('You received coins +3 MIND')}}</p>
              <p class="text">{{__('You received additional coins.')}}</p>
              <p class="text">{{__('You can also always purchase MIND')}}</p>
            </div>
          </div>
          <div v-if="updateBonus == 0">
            <div class="text-block">
              <p class="text">{{__('You can receive free coins for viewing ads no more than once every 10 minutes')}}</p>
            </div>
          </div>
          <div class="block-btn">
            <router-link @click.native="onBuyClick" class="baseButton btn-green" :to="{name: 'balances_current', params: {'current': 'deposit', lang: $store.getters.getLang}}"><span>{{__('Buy')}}</span></router-link>
            <a @click="reloadPage" class="baseButton"><span>Ok</span></a>
          </div>
        </div>
      </v-dialog>
    <!--</v-row>-->
  </div>
</template>

<script>
    export default {
        name: 'ModalClickWindow',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
          updateBonus: {
            type: [String, Number, Object, Array, Boolean], // Определение типа prop
            default: null // Значение по умолчанию, если prop не будет передан
          },
            modal_cancel: {
                type: [Boolean, Object, Array, String]
            },
          class_name: {
              type: String,
          },
          width: {
            type: [Number, String],
            default: 400
          },
        },
        computed: {

        },
        watch: {

        },
        methods: {
          reloadPage() {
            // Перезагрузить текущую страницу
            window.location.reload();
          },
          onBuyClick() {
            window.location.reload();
          }
        },
        components: {

        },
    };
</script>

<style scoped>
  .dark .v-dialog__content{
    z-index: 400!important;
  }
</style>

