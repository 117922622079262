<template>
    <v-card v-ripple="false">
        <ul class="wrap-item-transaction">
            <li @click="show = !show" v-if="transaction.opp_type == 8 && !payment">{{transaction.id}}</li>
            <li @click="show = !show" v-else-if="transaction.opp_type == 1 && !payment">{{__('Gold')}}</li>
            <li @click="show = !show" v-else-if="transaction.opp_type == 2 && !payment">{{__('Silver')}}</li>
            <li @click="show = !show" v-else-if="payment && payment.name">{{payment.name}}</li>

            <li @click="show = !show">
              <span class="block-real" v-if="transaction.wallet== 1">
                  <img  class="pic" src="/dist/images/frontend/money.svg" alt />
                  {{primNum((transaction.amount > 0) ? transaction.amount : transaction.rate_amount, 2)}} {{__('RC')}}
              </span>
              <span class="block-lite" v-else>
                  <img  class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                  {{primNum((transaction.amount > 0) ? transaction.amount : transaction.rate_amount, 2)}} {{__('LC')}}
              </span>
            </li>
            <li @click="show = !show" style="text-transform: uppercase" v-if="payment">
                {{primNum(transaction.amount_unit, payment.sprintf)}} {{payment.currency}}
            </li>
            <li @click="show = !show" v-else-if="!payment && !transaction.payment_id">
                <span v-if="transaction.income == 1" class="success--text">{{__('REQUEST')}}</span>
                <span v-else class="error--text">{{__('SEND')}}</span>
            </li>

            <li v-else>
                {{primNum(transaction.amount_unit, 2)}} {{__((transaction.opp_type == 1) ? 'Gold' : 'Silver')}}
            </li>

            <li>
                <span v-if="transaction.status== 1" class="block-success">
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="3" cy="3" r="3" fill="#00C443"/>
                    </svg>
                    {{__('Success')}}
                </span>
                <div v-else-if="transaction.status== 2 || transaction.status== 7" class="d-flex align-center">
                    <span class="block-wait">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="3" cy="3" r="3" fill="#F6A401"/>
                        </svg>
                        {{__('Wait')}}
                    </span>

                    <v-btn text class="btn-del" v-on:click="$emit('cancelTransactions', transaction)">
                        <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1504 6.00391L6.00304 12.1513" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12.1533 12.1533L6 6" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9046 0.666016H5.09508C2.37286 0.666016 0.666504 2.59275 0.666504 5.32031V12.6784C0.666504 15.406 2.36492 17.3327 5.09508 17.3327H12.9046C15.6348 17.3327 17.3332 15.406 17.3332 12.6784V5.32031C17.3332 2.59275 15.6348 0.666016 12.9046 0.666016Z" stroke="#6886AF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </v-btn>
                </div>

                <span v-else-if="transaction.status== 3 || transaction.status== 6" class="block-cancel">
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3" cy="3" r="3" fill="#FF4B55"/>
                    </svg>
                    {{__('Canceled')}}
                </span>
                <span v-else-if="transaction.status== 5" class="block-success">
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="3" cy="3" r="3" fill="#00C443"/>
                    </svg>
                    {{__('Confirm')}}
                </span>
              <span v-else class="block-cancel">
                    <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3" cy="3" r="3" fill="#FF4B55"/>
                    </svg>
                    {{__('Error')}}
                </span>
            </li>
            <li @click="show = !show">{{transaction.created_at}}</li>
            <v-btn
                    class="btn-link"
                    icon
                    v-ripple="false"
                    @click="show = !show"
            >
                <svg v-if="!show" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8334 1.08331L7.00008 6.91665L1.16675 1.08331" stroke="#96AFD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.16658 6.91669L6.99992 1.08335L12.8333 6.91669" stroke="#26A9F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </v-btn>
        </ul>

<!--        <v-expand-transition v-if="payment">-->
        <v-expand-transition>
            <div v-show="show">
                <v-card-text>
                    <div v-if="transaction.opp_type != 8">
                        <span>
                            <p>{{__('Operation ID:')}}</p>
                            {{transaction.id}}
                        </span>

                        <span v-if="payment && payment.merchant">
                            <p>Wallet address:</p>
                             <a :href="$paymentLink[payment.currency]+transaction.tx" target="_blank">{{transaction.tx}}</a>
                        </span>

                        <span>
                            <p>{{__('Date and time UTC:')}}</p>
                            {{transaction.created_at}}
                        </span>

                        <span v-if="payment">
                             <p>{{__('Amount:')}}</p>
                            {{primNum(transaction.amount_unit, payment.sprintf)}} {{payment.currency}}
                        </span>
                    </div>
                    <div v-else>
                        <span>
                            <p>{{__('Transfer:')}}</p>
                            <v-list v-if="transaction.enemy" color="transparent" class="block-transfer">
                                <v-list-item>
                                    <div class="block-img" :class="(transaction.enemy.pro) ? 'user-pro-img' : ''">
                                        <router-link :to="{name: 'profile', params: {id: transaction.enemy.public_id, lang: $store.getters.getLang}}" class="mb-0 mr-2">
                                            <div class="wrap-img">
                                                <!--              <img :src="'/assets/users/'+((item.photo) ? item.photo : 'a-default.svg')">-->

                                                <!--              <img v-if="item.photo" width="43" :src="'/assets/users/'+item.photo">-->
                                                <img v-if="transaction.enemy.photo" width="43" :src="transaction.enemy.photo">
                                                <span v-else class="photo" :style="{'background': transaction.enemy.bg_color}">{{transaction.enemy.name.substr(0,1)}}</span>

                                            </div>
                                        </router-link>
                                    </div>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <router-link :class="(transaction.enemy.pro) ? 'user-pro' : ''" :to="{name: 'profile', params: {id: transaction.enemy.public_id, lang: $store.getters.getLang}}">
                                                {{transaction.enemy.name+" "+((transaction.enemy.last_name) ? transaction.enemy.last_name : '')}}
                                            </router-link>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <img class="flag" :src="transaction.enemy.location_img">
                                            <p style="color: #96AFD1;">{{$getLocation(transaction.enemy.location)}}</p>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </span>

                        <span></span>

                        <span>
                            <p>{{__('Date and time UTC:')}}</p>
                            {{transaction.created_at}}
                        </span>

                        <span>
                            <p>{{__('Opperation')}}:</p>
                            <span v-if="transaction.income == 1" class="success--text">{{__('REQUEST')}}</span>
                            <span v-else class="error--text">{{__('SEND')}}</span>
                        </span>
                    </div>

                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    // import Switcher from "../../modules/Switcher";
    export default {
        name: 'TransactionItem',
        data() {
            return {
                show: false,
            }
        },
        props: {
            payment: {
                type: Object
            },
            transaction: {
                type: Object
            },
            currentTab: {
                type: String
            }

        },
        methods: {

        },
        components: {
            // Switcher
        },

    }
</script>

<style lang="scss" scoped>

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
        border-bottom: 1px solid #DAE6F1;
        margin: 0 20px;

        &:last-child {
            border-bottom: none;
        }
    }

    .dark {
        .v-card__text {
            span {
                color: #fff;
                p {
                    color: #96AFD1;
                }

                .block-transfer {
                    .v-list-item {
                        &__title {
                            a {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .v-card__text {
        span {
            display: flex;
            align-items: center;

            &:last-child {
                display: none;
            }

            &:nth-child(3) {
                display: none;
            }


            p {
                font-size: 14px;
                line-height: 19px;
                color: #6886AF;
                margin: 0 5px 0 0;
            }

            a {
                color: #26A9F4;

                &:hover {
                    text-decoration: underline;
                }
            }
            .block-transfer {
                padding: 0;

                .v-list-item {
                    padding: 0;
                    min-height: 40px;
                    display: flex;
                    align-items: center;

                    .block-img {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        margin-right: 8px;

                        a {
                            text-decoration: none;
                        }

                        .online {
                            border-radius: 50%;
                            width: 11px;
                            height: 11px;
                            position: absolute;
                            right: 3px;
                            bottom: 1px;
                            border: 2px solid #FFFFFF;
                            box-sizing: border-box;
                            background-color: #00C569;
                        }
                    }

                    .wrap-img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;

                        img {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                        }

                        .photo {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            display: flex;
                            color: #FFFFFF;
                            justify-content: center;
                            align-items: center;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }

                    &__content {
                        padding: 0;

                        p {
                            margin-bottom: 0;
                            font-weight: 400;
                            font-size: 12px;
                            max-width: 750px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }

                    &__title {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        text-transform: capitalize;
                        color: #002B50;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 130px;
                        display: block;
                        margin-bottom: 0;

                        a {
                            color: #002B50;
                            text-decoration: none;

                            &:hover {
                                color: #26A9F4;
                                text-decoration: underline;
                            }
                        }
                        span {
                            font-size: 12px;
                            margin-top: 2px;

                        }

                        img {
                            max-height: 11px;
                            margin: 1px 4px 0 10px;
                        }
                    }

                    &__subtitle {
                        display: flex;
                        align-items: center;

                        p {
                            font-size: 10px;
                            margin: 0;
                        }

                        img {
                            max-height: 10px;
                        }

                        .flag {
                            border-radius: 1px;
                            margin-right: 3px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }

    .dark {
        .wrap-item-transaction {
            li {
                color: #fff;
            }
        }
    }

    .wrap-item-transaction {
        display: flex;
        padding: 6px 0 8px;
        margin: 0;
        cursor: pointer;

        li {
            display: flex;
            align-items: center;
            list-style-type: none;
            width: 20%;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #002B50;

            &:nth-child(5) {
                text-align: right;
                justify-content: flex-end;
                margin-right: 12px;
            }
        }

        .block-real,
        .block-lite {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 19px;
            text-align: right;
            letter-spacing: 0.01em;

            img {
                margin-right: 6px;
                width: 20px;
            }
        }
        
        .block-lite {
            color: #607EA7;
        }

        .block-real {
            color: #FDAE02;
        }

        .v-btn.btn-link {
            &:hover {
                transform: scale(1.1);
            }
        }

        .v-btn.btn-del {
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none;
            border-radius: 5px;
            transition: .3s;
            border: none;


            &.v-btn:not(.v-btn--round).v-size--default {
                height: 18px;
                min-width: 18px;
                padding: 0;
            }
            svg {
                transition-duration: .3s;
                border-radius: 5px;
                color: #6886AF;
            }

            &:hover {
                svg {
                    background-color: #6886AF;
                    color: #fff;
                    border-radius: 5px;
                    transition-duration: .3s;
                }
            }
        }

        .block-success {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: #CDFFCD;
            border-radius: 10px;
            color: #00C443;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            svg {
                margin-right: 5px;
            }
        }

        .block-wait {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: rgba(246, 164, 1, 0.1);
            border-radius: 10px;
            color: #F6A401;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            height: 22px;
            margin-right: 10px;

            svg {
                margin-right: 5px;
            }
        }

        .block-cancel {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: rgba(255, 75, 85, 0.1);
            border-radius: 10px;
            color: #FF4B55;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            svg {
                margin-right: 5px;
            }
        }
    }



@media screen and (max-width: 950px) {

    .v-sheet.v-card:not(.v-sheet--outlined) {
        margin: 0 15px;
    }

    .v-card__text {
        span {
            &:nth-child(3) {
                display: flex;
            }
        }
    }
    .wrap-item-transaction {
        li {
            width: 25%;
            &:nth-child(5) {
                display: none;
            }

            &:nth-child(4) {
                text-align: right;
                justify-content: flex-end;
                margin-right: 5px;
            }
        }
    }
}

    @media screen and (max-width: 600px){
        .v-card__text {
            padding: 16px 0 16px 16px;

            span {
                font-size: 12px;
                line-height: 18px;
                &:last-child {
                    display: flex;
                }

                p {
                    font-size: 12px;
                    line-height: 18px;
                    white-space: nowrap;
                }
                a {
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        .wrap-item-transaction {
            li {
                font-size: 12px;
                width: 30%;

                &:nth-child(2) {
                    width: 20%;
                }
                &:nth-child(3) {
                    display: none;
                }

                &:nth-child(4) {
                    text-align: right;
                    justify-content: flex-end;
                    width: 40%;
                }
            }

            .block-real {
                font-size: 12px;
                line-height: 14px;
                text-align: left;

                img {
                    width: 14px;
                    margin-right: 4px;
                }
            }
            .block-lite {
                font-size: 12px;
                line-height: 14px;
                text-align: left;

                img {
                    width: 14px;
                    margin-right: 4px;
                }
            }
        }
    }

    @media screen and (max-width: 425px){
        .wrap-item-transaction {
            .block-wait,
            .block-success,
            .block-cancel {
                padding: 2px 10px 2px 8px;
                font-size: 11px;
                line-height: 16px;

                svg {
                    margin-right: 3px;
                }
            }

            .block-wait {
                margin-right: 6px;
            }
        }
    }
</style>