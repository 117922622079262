<template>
  <div class="block-slide" :class="scroll ? 'active' : ''">
    <VueSlickCarousel
      ref="carousel"
      v-if="slides && slides.length"
      :items="items"
      :dots="dots"
      :nav="nav"
      :autoplay="false"
      @update="update"
      class="slider"
      :class="class_slider"
    >
   
      <div  v-for="slide in slides" :key="slide.sort" class="text-center slide">
<!--        <img v-if="slide.type==1" :height="height" :src="'/assets/games/'+slide.file" />-->
        <img v-if="slide.type==1" :height="height" :src="slide.file" />
<!--        <video v-else :height="height" controls :src="'/assets/games/'+slide.file"></video>-->
        <video v-else :height="height" controls :src="slide.file"></video>
      </div>
    </VueSlickCarousel>


    

    <div v-else-if="slides !== null" class="block-bg-game">
      <img class="img" src="/dist/images/frontend/logo-game-page.svg" alt />
    </div>
    <div v-else>{{__('loading')}}</div>
  </div>
</template>


<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  data() {
    return {
    };
  },
  props: {
    slides: {
      type: Array,
    },
    height: {
      type: [Number, String],
      default: "146px",
    },
    nav: {
      type: Boolean,
      default: true,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Number,
      default: 1,
    },
    indexSlide: {
      type: Number,
    },
    class_slider: {
      type: String,
    },
    scroll: {
      type: Boolean,
    }
  },
  methods: {
    update: function (data) {
      console.log(data);
    },
  },

  watch: {
    indexSlide(key) {
      this.$refs.carousel.goTo(key)
    },
  },
  components: { VueSlickCarousel },
};
</script>

<style lang="scss">

.block-slide {
 border-radius: 8px;
 /*max-width: 1195px;*/
 /*height: 330px;*/
 /*height: 100%;*/
}

//SCROLL
.block-slide.active {
  .slick-list {
    max-height: 80px;
    transition: .5s;
  }

  .slick-next:before,
  .slick-prev:before {
    opacity: 0;
    transition: .2s;
  }
}


.slider {
  position: relative;
  /*border-radius: 8px;*/
  /*max-width: 1195px;*/
  //margin-top: 6px;
}

.slide {
  outline: none;
  display: inline-block;
  z-index: 10;
  position: relative;
  background-color: #fff;

  img {
    object-fit: cover;
    overflow: hidden;
    object-position: center;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 100%;
  }
}

.slick-arrow {
  z-index: 1;
}

.slick-prev {
    position: absolute;
    left: -75px;
    top: calc(50% - 15px);
    z-index: 10;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    opacity: 1;
    z-index: 100;

    &:before {
        content: '';
        background-image: url("/dist/images/frontend/chevronNext.svg");
        transform: rotate(180deg);
        display: block;
        width: 10px;
        height: 12px;
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 9px);
    }

    &:hover {
        width: 30px;
        height: 30px;
        background: #fff;

        &:before {
            background-image: url("/dist/images/frontend/chevronNextBlue.svg");
        }
    }
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    opacity: 0;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: #fff;
}

.slick-next {
    position: absolute;
    right: -75px;
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    z-index: 10;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    opacity: 1;

    &:before {
        content: '';
        background-image: url("/dist/images/frontend/chevronNext.svg");
        display: block;
        width: 10px;
        height: 12px;
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 2px);
    }

    &:hover {
        width: 30px;
        height: 30px;
        background: #fff;

        &:before {
            background-image: url("/dist/images/frontend/chevronNextBlue.svg");
        }
    }
}

/*.slick-prev:before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  //background-color: rgba(242, 244, 246, 0.85);
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: black;
  position: absolute;
  !*left: 60px;*!
  top: -50px;
  opacity: .5;
  background-image: url("/dist/images/frontend/next-btn-slide.svg");
  background-position: 15px center;
  transition-duration: .3s;
}

.slick-next {
  width: 0;
  font-size: 0;
  right: 0;
  top:  0;
}

.slick-prev {
  width: 0;
  font-size: 0;
  left: 0;
  top: 0;
}

.slick-next:before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  //background-color: rgba(242, 244, 246, 0.85);
  background-color: #fff;
  color: black;
  position: absolute;
  !*right: 60px;*!
  top: -50px;
  opacity: .5;
  background-image: url("/dist/images/frontend/next-btn-slide.svg");
  // border-radius: 50%;
  // background-color: #fff;
  transform: rotate(180deg);
  background-position: 15px center;
  transition-duration: .3s;
}*/

.v-dialog {
    box-shadow: none!important;
}

.slick-track {
    display: flex !important;
    align-items: center !important;
}


.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 -2px 0 0;
  padding: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  /*max-width: 1195px;*/
  max-height: fit-content;
  transition: .5s;
  //border-radius: 8px 0 0 0;
    box-shadow: none;
}

.block-bg-game {
  //display: block;
  background: #FFFFFF;
  //border-radius: 8px 0 0 0;
  /*height: 400px;*/
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


//Developers
.developer-slider {
  img {
    object-fit: cover;
    overflow: hidden;
    object-position: center;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    height: 200px;
    border-radius: 8px;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    /*max-width: 1195px;*/
    max-height: 200px;
    border-radius: 8px;
  }

  .slick-next {
    width: 0px;
    font-size: 0px;
    right: 30px;
  }

  .slick-prev {
    width: 0px;
    font-size: 0px;
    left: 10px;
  }
}

//Games
.games-slider {

  .slide {
    img {
      height: 256px;
    }
  }
  .slick-list {
    max-height: 256px;
    border-radius: 8px;
  }

  .slick-next:before,
  .slick-prev:before {
    display: none;
  }
}


//@media screen and (max-width: 768px) {
//
//  .block-slide {
//    height: 220px;
//  }
//  .slide {
//    img {
//      height: 220px;
//    }
//  }
//}

 @media screen and (max-width: 550px) {

   .block-slide {
     height: 180px;
   }
   .slide {
     img {
       height: 180px;
     }
   }

   .slick-prev:before {
     top: -6px;
     left: -20px;
   }

   .slick-next:before {
     top: -6px;
     right: -38px;
   }
 }

 @media screen and (max-width: 475px) {

   .block-slide {
     height: 140px;
   }
   .slide {
     img {
       height: 140px;
     }
   }
 }
</style>