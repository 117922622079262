<template>
  <div v-if="isDialogVisible" class="window-click">
    <div persistent :max-width="width" v-on:click:outside="cancelModal">
      <div class="white portfolio-window" :class="class_name" style="position: relative; border-radius: 5px">
        <span class="close"></span>
        <slot></slot>
        <div class="timer-window" v-if="!timerFinished">
          <div>
            <p>{{__('The reward will be available through:')}}
              <span>{{ waitingTime }}</span>
            </p>
          </div>
        </div>
        <zxc></zxc>
      </div>
      <ModalClickWindow v-if="timerFinished && modal_cancel" v-bind:modal_cancel.sync="modal_cancel" :update-bonus="updateBonus"></ModalClickWindow>
    </div>
  </div>
</template>

<script>
  import ModalClickWindow from '../modules/ModalClickWindow';
  import zxc from '../modules/zxc';

  export default {
    name: 'ModalClick',
    data() {
      return {
        updateBonus: null,
        modal_cancel: false,
        waitingTime: '-',
        isDialogVisible: true,
        submitDisabled: false,
        remainingTime: 20, // Изначально 20 секунд
        timerInterval: null,
        timerFinished: false // Объявляем переменную timerFinished в опции data
      };
    },
    props: {
      class_name: {
        type: String,
      },
      width: {
        type: [Number, String],
        default: 400
      },
    },
    methods: {
      startTimer() {
        this.remainingTime = 20; // Устанавливаем время таймера (в секундах)
        this.timerInterval = setInterval(() => {
          this.remainingTime--; // Уменьшаем время каждую секунду
          if (this.remainingTime <= 0) {
            clearInterval(this.timerInterval); // Останавливаем таймер
            this.waitingTime = 'Time is over'; // Помечаем, что время истекло
            this.timerFinished = true; // Устанавливаем флаг, что таймер завершен
            this.openModalWindow(); // Вызываем метод для открытия окна ModalClickWindow
            this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/advert_bonus')
                    .then((response) => {
                      return response.json();
                    })
                    .then((response) => {
                      if(this.$checkAuth(response)) {
                        this.updateBonus= response.data.advertising;
                      }
                    })
                    .catch((err) => {
                      this.httpHandler(err);
                    });
          } else {
            this.waitingTime = this.remainingTime;
          }
        }, 1000);
      },



      cancelModal() {
        clearInterval(this.timerInterval); // Останавливаем таймер при закрытии модального окна
        this.$emit('update:modal_cancel', false);
      },
      openModalWindow() {
        this.modal_cancel = true; // Устанавливаем флаг для отображения окна ModalClickWindow
      }
    },
    created() {
      this.startTimer(); // Запускаем таймер при создании компонента
    },
    components: {
      zxc,
      ModalClickWindow
    },
  };
</script>

<style>
  /* Добавьте стили, если необходимо */
</style>
