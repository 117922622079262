<template>
  <div class="wrapper-banners">
    <div v-if="banners">
      <BannersItem v-for="(item, key) in banners" :key="key" :item.sync="item"></BannersItem>
    </div>
    <div v-else class="block-empty">
      <img class="img img--1" src="/dist/images/frontend/not-referrals.svg" alt />
      <p>{{__('You dont have banners :(')}}</p>
    </div>
  </div>
</template>

<script>
  import BannersItem from "./BannersItem";
    export default {
        name: 'Banners',
        data () {
            return {
              banners: this.affiliate.banners,
            }
        },

        props: {
          affiliate: {
            type: Object
          }
        },
        computed: {

        },
        methods: {

        },
        components: {
          BannersItem,
        },
    }
</script>

<style lang="scss" scoped>
  .dark .wrapper-banners {
    background: #171827;
  }

  .wrapper-banners {
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 4px;
    background-color: #fff;
    min-height: calc(100vh - 410px);
  }
</style>
