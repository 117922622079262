<template>
  <div>
    <div v-if="currentTab == 'top'" >
      <div v-if="game && game.top.length" >
        <GameUserTopListItem
                v-for="(matches, key) in game.top"
                item_game="item_game"
                :key="key"
                v-bind:index="key"
                v-bind:matches="matches"
                v-bind:game.sync="game"
                v-bind:user="matches.user"
        ></GameUserTopListItem>
      </div>

      <div v-else class="none-100">
        <p>{{__('the top 100 has not yet been determined')}}</p>
      </div>
    </div>

    <div v-else>
      <LayoutNavAuth>
        <p>{{__("My played matches")}}</p>
        <div slot="right" class="wrap-tabs">
          <nav class="tabs buttons-tabs">
            <a
              v-for="(item, key) in list_tabs_history"
              :key="key"
              class="item-tab buttons-tabs__item"
              :class="item.id == active_tab_history.id ? 'buttons-tabs__item_active' : ''"
              @click="changeTab(item)"
            >
              {{__(item.title)}}
            </a>
          </nav>
        </div>
      </LayoutNavAuth>
      <div>
        <div v-if="list.match.data.length">
          <div class="history-solo history-solo-list">
            <div class="name">
              <p>{{__("Opponent")}}</p>
            </div>
            <div class="date">
              <p>{{__("Date")}}</p>
            </div>
            <div class="my-score">
              <p>{{__("My result")}}</p>
            </div>
            <div class="opponent-score">
              <p>{{__("The result of the opponent")}}</p>
            </div>
            <div class="bit">
              <p>{{__("Match bet")}}</p>
            </div>
            <div class="status">
              <p>{{__("Status")}}</p>
            </div>
          </div>
          <GameUserSoloListItem
                  v-for="(matches, key) in list.match.data"
                  item_game="item_game"
                  :key="key"
                  v-bind:matches="matches"
                  v-bind:game.sync="game"
                  v-bind:user="matches.user"
          ></GameUserSoloListItem>
        </div>

        <div v-else class="none-100 block-empty-match">
          <p>{{__("No matches played")}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import GameUserSoloListItem from "./GameUserSoloListItem"
    import LayoutNavAuth from "./LayoutNavAuth";
    import GameUserTopListItem from "./GameUserTopListItem"
    // import ScrollPagination from "./ScrollPagination"
    // import SkeletonListAvatarLine from "./SkeletonListAvatarLine";

    export default {
        name: 'GameSoloList',
        data: function() {
            return {


              id: this.game.id,
            }
        },
        props: {
            list: {
                type: [Object, Array],
            },
            game: {
                type: Object
            },
            user: {
                type: Object
            },
            profile: {
                type: Object
            },
            currentTab: {
                type: String
            },
            list_tabs_history: {
                type: Array
            },
            active_tab_history: {
                type: Object
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            },
            lang() {
              return this.$store.state.language.lang;
            },


        },
        methods: {
          changeTab(tab) {
            this.$emit('update:active_tab_history', tab);
            this.$emit('changeList', tab.id);
          }
        },
        mounted() {

        },
        components: {
          LayoutNavAuth,
          GameUserSoloListItem,
            GameUserTopListItem,
            // ScrollPagination,
            // SkeletonListAvatarLine
        },
    };
</script>

<style lang="scss" scoped>

  .dark {
    .list-socials {
      p {
        color: #fff;
      }

      .v-list-item {

        &:hover {
          background: #2B2B2B;
        }

        &__title {
          color: #96AFD1;
        }

        svg {
          color: #96AFD1;
        }
      }
    }
  }

  .list-socials {
    width: 230px;

    p {
      margin: 4px 0 12px 14px;
    }

    .v-list-item {

      &:hover {
        background-color: #F1F2F8;

        .v-list-item__title {
          color: #26a9f4;
        }
      }

      &__title {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #6886AF;

        svg {
          margin-right: 12px;

        }
      }
    }
  }

.online {
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #00C569;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  bottom: 2px;
}

.user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:5px 12px 5px 5px;
  /*border-bottom: 2px solid #ECF4FF;*/
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 38.42%, rgba(255, 255, 255, 0.5) 99.34%);
  border-radius: 8px;
  margin-bottom: 10px;

  .v-list-item {

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-transform: capitalize;
      color: #002B50;
      margin-bottom:3px;

        a {
            text-decoration: none;
            color: #002B50;

            &:hover {
                text-decoration: underline;
                color: #4EBEFF;
            }
        }
    }

    &__subtitle {
      font-size: 12px;
      line-height: 16px;
    }
  }
} 

.block-img {
  margin-right: 10px;
  position: relative;

    a {
        text-decoration: none;
    }

    .wrap-img {
        width: 54px;
        height: 54px;
        border-radius: 50%;

        img {
            width: 54px;
            height: 54px;
            border-radius: 50%;
        }
    }

    .photo {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
    }
}

.block-img-game {
    margin-right: 10px;
    position: relative;

    .wrap-img-game {
        width: 54px;
        height: 54px;
        border-radius: 8px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

        img {
            width: 54px;
            height: 54px;
            border-radius: 8px;
        }
    }
}

.rating {
    span {
        margin-right: 6px;
        margin-top: 3px;
        /*font-weight: bold;*/
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: #96afd1;
    }

    .v-rating {
        margin-top: 2px;

        .v-rating--dense .v-icon {
            padding: 0;
        }
    }

    a {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #26a9f4;
        text-decoration: none;
        margin-top: 7px;

        &:hover {
            text-decoration: underline;
        }
    }
}

.success-inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #00C443;
    box-sizing: border-box;
    border-radius: 8px;
    text-transform: inherit;
    color: #00C443;
    transition-duration: .3s;

    .pic {
        height:12px;
        margin: 0 1px 0 4px;
    }

    &:hover {
       border: 2px solid #00AD3B;
    }
}

.default-inner {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: 1px solid #6886AF;
    box-sizing: border-box;
    border-radius: 8px;
    text-transform: inherit;
    color: #6886AF;
    transition-duration: .3s;

    .pic {
        height:12px;
        margin: 0 1px 0 4px;
    }

     &:hover {
        border: 2px solid #96AFD1;
    }
}

.v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 140px;
      // width: 124px;
      padding: 10px;
    }

    .block-money {
        display: flex;
        align-items: center;

        img {
            margin-right: 3px;
        }

        .v-btn {
            border-radius: 8px;
            text-transform: inherit;
            outline: none;
            margin-left: 40px;

        }
    }

    .lite-btn {
        background-color: #96AFD1;
        transition-duration: 0.3s;
        outline: none;

        &:hover {
            background-color: #819BBF;
        }
    }

    .real-btn {
        background-color: #4EBEFF;
        transition-duration: .3s;
        outline: none;

        &:hover {
            background-color: #26A9F4;
        }
    }

    .dark .block-empty-search {
      span {
        color: #fff;
      }
    }

  .block-empty-search {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 20px;
    }

    span {
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: #000000;
      text-align: center;
      margin-bottom: 30px;
    }

    a {
      font-size: 14px;
      line-height: 19px;
      color: #26A9F4;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .dark .block-empty-match {
    span {
      color: #96AFD1;
    }

    p {
      color: #fff;
    }
  }

    .block-empty-match {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        margin-bottom: 25px;
      }

      span {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #6886AF;
      }

      p {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 18px;
        max-width: 320px;
      }

      a {
        font-size: 14px;
        line-height: 19px;
        color: #26A9F4;

        &:hover {
          text-decoration: underline;
          color: #26A9F4;
        }
      }
    }


@media screen and (max-width: 1200px) {


    .user-list {

        .v-list-item {

            &__avatar {
                min-width: 45px;
            }

            &__title {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-transform: capitalize;
                color: #002B50;
                margin-bottom:3px;
            }

            &__subtitle {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .block-img {
        .wrap-img {
            width: 45px;
            height: 45px;
            border-radius: 50%;

            img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }
        }
    }


    .block-money {
        span {
            font-size: 14px;
        }
        img {
            height: 12px;
        }
        .v-btn {
            font-size: 12px;
            margin-left: 30px;
        }
    }

    .v-btn:not(.v-btn--round).v-size--default {
        height: 36px;
        min-width: 120px;
        // width: 124px;
        padding: 10px;
    }

}

@media screen and (max-width: 525px) {

    .user-list {
        padding: 5px 10px;

        .block-money {
            span {
                font-size: 10px;
            }

            img {
                height: 8px;
            }

            .v-btn {
                font-size: 8px;
                margin-left: 15px;
                border-radius: 6px;
            }
        }
        .v-btn:not(.v-btn--round).v-size--default {
            height: 30px;
            min-width: 90px;
            // width: 124px;
            padding: 10px;
        }

        .v-list-item {

            &__avatar {
                min-width: 45px;
            }

            &__title {
                font-weight: 400;
                font-size: 10px;
                line-height: 19px;
                text-transform: capitalize;
                color: #002B50;
                margin-bottom:0px;
            }

            &__subtitle {
                font-size: 8px;
                line-height: 16px;

                img {
                    height: 6px;
                    max-width: 10px;
                }
            }
        }

        .block-img {
            margin-right: 5px;
            .wrap-img {
                width: 38px;
                height: 38px;
                border-radius: 50%;

                img {
                    width: 38px;
                    height: 38px;
                    border-radius: 50%;
                }
            }
        }
    }
}

</style>