<template>
    <v-dialog v-model="modal" max-width="400" >
        <div class="modal-confirm">
            <img src="/dist/images/frontend/delete-game-icon.svg" alt />
            <p>{{__('Are you sure you want to remove')}}
<!--                <router-link :to="{name: 'profile', params: {id: item.public_id}}">-->
<!--                    {{item.name+" "+((item.last_name) ? item.last_name : '')}}-->
<!--                </router-link>-->
                <span class="user-name" v-if="item">
                    {{item.name+" "+((item.last_name) ? item.last_name : '')}}
                </span>
                <span v-else-if="profile">{{profile.user.name+" "+((profile.user.last_name) ? profile.user.last_name : '')}}</span>
                {{__('from your friends?')}}</p>
            <div class="wrap-btn">
<!--                <v-btn-->

<!--                        text-->
<!--                        class="btn-secondary"-->
<!--                        v-on:click="modal = false"-->

<!--                >{{__('Cancel')}}</v-btn>-->
<!--                <v-btn-->

<!--                        text-->
<!--                        class=" btn-delete white&#45;&#45;text"-->
<!--                        v-on:click="unfriend"-->
<!--                >{{__('Unfriend')}}</v-btn>-->
                <div style="width: 48%">
                    <BaseButton
                        class_btn="btn-liteGrey"
                        v-on:click="modal = false"
                    >
                        <span>{{__('Cancel')}}</span>
                    </BaseButton>
                </div>

                <div style="width: 48%">
                    <BaseButton
                        class_btn="redBtn"
                        v-on:click="unfriend"
                    >
                        <span>{{__('Unfriend')}}</span>
                    </BaseButton>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
    export default {
        name: "ModalConfirmUnfriend",
        data() {
            return {
                modal: true,
            };
        },
        props: {
            item: {
                type: Object
            },
            profile: {
                type: Object
            },
        },
        watch: {
            modal() {
                this.cancelModal();
            }
        },
        computed: {},
        methods: {
            cancelModal() {
                this.$emit("update:open_modal", false);
            },
            unfriend () {
                this.$emit('submitUnfriend');
                this.cancelModal();
            }
        },
        components: {},
    };
</script>

<style lang='scss' scoped>

    .dark {
        .modal-confirm {
            background: #171827;

            p {
                color: #fff;
            }
        }
    }
    .model_wrapper.v-dialog {
        overflow: unset;
        position: relative;
    }

    .modal-confirm {
        text-align: center;
        padding: 50px 25px 30px;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background-color: #fff;

        img {
            margin-bottom: 8px;
        }

        p {
            max-width: 280px;
            width: 100%;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #002B50;
            margin: 0 auto 32px;
        }

        .user-name {
            color: #26A9F4;
        }

        .wrap-btn {
            display: flex;
            justify-content: space-between;

            .btn-delete {
                background: #FF4B55;
                border-radius: 6px;
                text-transform: inherit;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                box-shadow: none;
                padding: 0;
                outline: none;
                transition-duration: .3s;

                &:hover {
                    background-color: #e94f4e;
                    transition-duration: .3s;
                }
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 165px;
                padding: 0;
            }
        }
    }
</style>