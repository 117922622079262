<template>
    <div class="new-nav-btn-dep">
        <router-link
                :to="{name: 'balances_current', params: {'current': 'deposit', lang: $store.getters.getLang}}"
                class="wrapper-nav-balance"
        >

            <div class="block-wallet">
                <img src="/dist/images/frontend/light-coin.svg" alt />

                <div class="wallet-info">
                    <span style="color: #026EED">{{__('MIND')}}</span>

                    <p>{{primNum(auth['balance_lite'].available, 2)}}</p>
                </div>
            </div>

            <div class="block-wallet" v-if="auth && auth.balance_real.available > 0">
                <img src="/dist/images/frontend/money.svg" alt="">

                <div class="wallet-info">
                    <span style="color: #F2A600">{{__('USDT')}}</span>

                    <p>{{primNum(auth['balance_real'].available, 2)}}</p>
                </div>
            </div>

            <BaseButton height="30" width="30" class_btn="btn-guest" :to="{name: 'balances_current', params: {'current': 'deposit', lang: $store.getters.getLang}}">
            <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7H13M7 1V13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            </BaseButton>

        </router-link>
        <a class="block-click" @click="openModal">Free</a>
    </div>


</template>

<script>
    import {bus} from "../../main";

    export default {
        data() {
            return {
                show: false,
                show1: false,
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        methods: {
            openModal() {
                bus.$emit('open-modal');
            },
        }

    }
</script>

<style lang="scss">
    .new-nav-btn-dep .block-click{
        margin-left: 10px;
        width: 40px;
        height: 30px;
        padding: 0px 8px;
        border-radius: 6px;
        color: #ffffff!important;
        background-color: #00C443;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-size: 12px;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-family: UniNeue;
        font-weight: 700;
    }
    .new-nav-btn-dep .block-click:hover{
        background-color: #009f3e;
    }

    .new-nav-btn-dep{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        border-radius: 8px;
        background-color: #222338;
        height: 40px;
        box-shadow: (0px 2px 5px rgba(51, 97, 255, 0.03));

    }

    .wrapper-nav-balance {
        display: flex;
        align-items: center;
        justify-content: center;

        .block-wallet {
            display: flex;
            align-items: center;
            margin-right: 10px;

            img {
                height: 24px;
                margin-right: 6px;
            }

            .wallet-info {
                display: flex;
                flex-direction: column;

                span {
                    color: #026EED;
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 13px;
                }

                p {
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;
                    margin: 0;
                    white-space: nowrap;
                }
            }
        }

        .baseButton.btn-guest {
            border-radius: 6px!important;
            height: 30px!important;
            width: 30px!important;
            padding: 0!important;

            span {
                svg {
                    margin: 0;
                }
            }
        }
    }

    @media screen and (max-width: 500px){
        .wrapper-nav-balance {
            padding: 0 2px 0 8px;

            .block-wallet {
                margin-right: 6px;

                img {
                    height: 22px;
                    margin-right: 4px;
                }

                .wallet-info {

                    span {
                        font-size: 10px;
                        line-height: 12px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }

            .baseButton.btn-guest {
                display: none;
            }
        }
    }
</style>