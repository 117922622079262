<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:inaccessible_quest', false)">
        <div class="modal-inaccessible_quest">

            <v-btn text icon class="icon ma-0" @click="cancelModal">
                <img src="/dist/images/frontend/close.svg" alt="/" />
            </v-btn>

            <div class="modal-inaccessible_quest__body">
                <img src="/dist/images/frontend/inaccessible-quest.svg" alt />

                <p>{{__('This feature is only available to verified users!')}}</p>
            </div>
            <div class="wrap-btn">
                <BaseButton
                    @click="onSubmit"
                >
                    <span>{{__('Pass verification')}}</span>
                </BaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                modal: true,
            };
        },
        props: {
            inaccessible_quest: {
                type: Boolean,
            }

        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {},

        methods: {
            cancelModal() {
                this.$emit("update:inaccessible_quest", false);
            },
            onSubmit() {
                this.$router.push({name: 'settings_current', params: {current: 'verification', lang: this.$store.getters.getLang}});
                this.$emit("update:inaccessible_quest", false);
            }
        },
        components: {},
    };
</script>

<style lang='scss' scoped>
.modal-inaccessible_quest {
    text-align: center;
    padding: 45px 25px 30px;
    background: #171827;
    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;

    .icon {
        position: absolute;
        top: 4px;
        right: 4px;
        outline: none;

        img {
            height: 12px;
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        img {
            margin-bottom: 25px;
        }

        p {
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
        }
    }

    .wrap-btn {
        display: flex;
        justify-content: center;
    }
}

    @media screen and (max-width: 500px) {
        .modal-inaccessible_quest {
            padding: 35px 20px 25px;

            &__body {
                margin-bottom: 25px;
                img {
                    height: 130px;
                    margin-bottom: 20px;
                }
            }
        }
    }

</style>