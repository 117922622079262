<template>
    <div class="home-games-new all-content">
        <div class="home-games-left">
            <LayoutNavAuth>
                <div v-if="tournament" class="block-breadcrumbs">
                    <router-link :to="{name: 'tournaments', params: {lang: $store.getters.getLang}}">

                        <div class="back-img">
                            <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.90929 7.81789C4.03006 7.69737 4.03025 7.5018 3.90974 7.38101L0.707886 4.1725C0.587442 4.05204 0.587457 3.85608 0.708118 3.73541L3.90974 0.527127C4.03026 0.406358 4.03006 0.210786 3.90929 0.0902487C3.78852 -0.0302577 3.59294 -0.0300569 3.47243 0.0906966L0.271024 3.29876C0.0903256 3.47949 0 3.71681 0 3.95416C0 4.19137 0.0902946 4.42863 0.270807 4.60913L3.47243 7.81744C3.59294 7.93819 3.78852 7.9384 3.90929 7.81789Z" fill="currentColor"></path>
                            </svg>
                        </div>

                        <span>{{__('All tournaments')}}</span>
                    </router-link>

                    <p>{{__('Game tournament')}} {{gameTranslation(tournament.game.game_translations).name}}</p>
                </div>

                <div class="loading"  :style="{width: progress + '%'}"></div>
            </LayoutNavAuth>
            <div class="tournament-page tournament-page-new tournament-page-solo" v-if="tournament">

                <!--            <div v-if="auth" class="loading"  :style="{width: progress + '%'}"></div>-->
                <div class="content dashboard-content">
                    <div class="left-page">
                        <div class="header-tournament">
                            <div class="my-block">
                                <div class="progress-quest">
                                    <div class="bb">
                                        <div v-if="typeof tournament.game.logo == 'object'" class="img" :style="{'backgroundImage': 'url(' +tournament.game.logo[300] + ')'}"></div>
                                        <div v-else class="img" :style="{'backgroundImage': 'url(' +tournament.game.logo + ')'}"></div>

                                        <div class="head">
                                            <div class="head__info">
                                                <p class="title-quest">{{gameTranslation(tournament.game.game_translations).name}}</p>

                                                <div class="block-attr" v-if="tournament.attribute && tournament.attribute.length">
                                                    <p v-for="(item, key) in tournament.attribute" :key="key">{{item.name}}: <span>{{item.value}}</span></p>
                                                </div>
                                            </div>
                                            <div class="head__stat">
                                                <p class="text">RTP: 70% </p>
                                                <div>
                                                    <p style="color: #E4E4E4" class="text" v-if="now_date > date_finish">
                                                        {{__('Completed_tournament')}}
                                                    </p>
                                                    <p style="color: #01DF72" class="text"  v-else-if="tournament.participate">
                                                        {{__('Purchased')}}
                                                    </p>
                                                    <p style="color: #E4E4E4" class="text"  v-else-if="now_date < date_start && tournament.users_count >= tournament.count_users" >
                                                        {{__('Participants identified')}}
                                                    </p>
                                                    <p style="color: #FDAE02" class="text"  v-else-if="now_date > date_start && now_date < date_finish">
                                                        {{__("The tournament is ongoing")}}
                                                    </p>
                                                </div>
                                                <!--                                            <div class="stat-item">-->
                                                <!--                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                                                <!--                                                    <path d="M0.0122337 0.944785H2.96553V0H11.6564V0.93047H14.6241C14.6241 0.93047 15.005 5.9238 11.5151 7.43432C11.305 8.22856 10.8735 8.9476 10.2703 9.50858C9.6672 10.0696 8.91697 10.4497 8.10609 10.6051V12.4826H10.15V14H4.55632V12.4826H6.57144V10.6154C5.75069 10.4692 4.98893 10.0931 4.37564 9.53128C3.76235 8.96948 3.32299 8.24528 3.10918 7.44376C-0.368049 5.92638 0.0122337 0.944785 0.0122337 0.944785ZM11.6564 5.62033C13.0299 4.57849 13.1188 2.47648 13.1188 2.47648H11.6564V5.62033ZM5.64621 7.01431L7.32423 6.1371L9.00224 7.01431L8.68155 5.15624L10.0392 3.83926L8.16309 3.56814L7.32423 1.87898L6.48536 3.56957L4.60929 3.8407L5.9669 5.15767L5.64621 7.01431ZM2.96553 5.62376V2.4908H1.51724C1.51724 2.4908 1.60561 4.57849 2.96553 5.62376Z" fill="#F6A401"/>-->
                                                <!--                                                </svg>-->
                                                <!--                                                <span>≈70%</span>-->
                                                <!--                                            </div>-->

                                                <div class="stat-item">
                                                    <img src="/dist/images/frontend/users.svg" alt="">
                                                    <span>{{tournament.users_count}}/{{tournament.count_users}}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="block-date">
                                            <p>
                                                <img src="/dist/images/frontend/start.svg" alt="">
                                                {{__('Start date:')}} {{tournament.date_start | moment('DD.MM')}}
                                            </p>

                                            <p>
                                                <img src="/dist/images/frontend/finish.svg" alt="">
                                                {{__('Expiration date:')}} {{tournament.date_finish | moment('DD.MM')}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="rr">
                                        <div class="time">
                                            <div v-if="isTournamentUpcoming(tournament.date_start)">
                                                <span>{{__("It will start in")}}</span>
                                                <p>{{ countdownToStart }}</p>
                                            </div>
                                            <div v-else-if="isTournamentActive(tournament.date_start, tournament.date_finish)">
                                                <span>{{__("It will end in")}}</span>
                                                <p>{{ countdownToFinish }}</p>
                                            </div>
                                            <p v-else class="end">{{__("The tournament has concluded")}}</p>
                                        </div>

                                        <div class="right-block">
                                            <div v-if="!auth" class="block-btn">
                                                <div class="block-price">
                                                    <p class="block-price__title">{{__('Сost')}}</p>

                                                    <div class="block-price__body">
                                                        <p>{{primNum(tournament.price, 2)}}</p>
                                                        <span v-if="tournament.wallet == 1">USDT</span>
                                                        <span v-else>MIND</span>
                                                        <!--                                                <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />-->
                                                        <!--                                                <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />-->
                                                    </div>
                                                </div>
                                                <BaseButton
                                                        class_btn="btn-green"
                                                        v-on:click="pushRegister"
                                                >
                                                <span>
                                                    {{__('Participate')}}
                                                </span>
                                                </BaseButton>
                                            </div>

                                            <div v-else-if="now_date > date_finish" class="block-btn">
                                                <div class="block-price">
                                                    <p class="block-price__title">{{__('Сost')}}</p>

                                                    <div class="block-price__body">
                                                        <p>{{primNum(tournament.price, 2)}}</p>
                                                        <span v-if="tournament.wallet == 1">USDT</span>
                                                        <span v-else>MIND</span>
                                                        <!--                                                <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />-->
                                                        <!--                                                <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />-->
                                                    </div>
                                                </div>

                                                <div class="completed">
                                                    <p>{{__('Completed_tournament')}}</p>
                                                </div>
                                            </div>

                                            <div v-else-if="tournament.participate" class="block-btn">
                                                <div class="block-price">
                                                    <p class="block-price__title">{{__('Сost')}}</p>

                                                    <div class="block-price__body">
                                                        <p>{{primNum(tournament.price, 2)}}</p>
                                                        <span v-if="tournament.wallet == 1">USDT</span>
                                                        <span v-else>MIND</span>
                                                    </div>
                                                </div>

                                                <div class="purchased">
                                                    <p>{{__('Purchased')}}</p>
                                                </div>
                                            </div>

                                            <div v-else-if="now_date < date_start && tournament.users_count >= tournament.count_users" class="block-btn">
                                                <div class="block-price">
                                                    <p class="block-price__title">{{__('Сost')}}</p>

                                                    <div class="block-price__body">
                                                        <p>{{primNum(tournament.price, 2)}}</p>
                                                        <span v-if="tournament.wallet == 1">USDT</span>
                                                        <span v-else>MIND</span>
                                                    </div>
                                                </div>

                                                <div class="available">
                                                    <p>{{__('Participants identified')}}</p>
                                                </div>
                                            </div>

                                            <div v-else-if="now_date > date_start && now_date < date_finish" class="block-btn">
                                                <div class="block-price">
                                                    <p class="block-price__title">{{__('Сost')}}</p>

                                                    <div class="block-price__body">
                                                        <p>{{primNum(tournament.price, 2)}}</p>
                                                        <span v-if="tournament.wallet == 1">USDT</span>
                                                        <span v-else>MIND</span>
                                                    </div>
                                                </div>

                                                <div class="active">
                                                    <p>{{__('Active')}}</p>
                                                </div>
                                            </div>

                                            <div v-else class="block-btn">
                                                <div class="block-price">
                                                    <p class="block-price__title">{{__('Сost')}}</p>

                                                    <div class="block-price__body">
                                                        <p>{{primNum(tournament.price, 2)}}</p>
                                                        <span v-if="tournament.wallet == 1">USDT</span>
                                                        <span v-else>MIND</span>
                                                    </div>
                                                </div>

                                                <BaseButton
                                                        style="width: 100%"
                                                        class_btn="btn-green"
                                                        v-on:click="onSubmit"
                                                        :loading="submitDisabled"
                                                >
                                <span>
                                    {{__('Participate')}}
                                </span>
                                                </BaseButton>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="block-progress">
                                    <!--                                        <p>{{tournament.date_start | moment('DD.MM')}}</p>-->

                                    <div v-if="now_date < date_start" class="progress-users">
                                        <progress  style="width: 100%" :value="tournament.users_count" :max="tournament.count_users"></progress>
                                    </div>

                                    <div v-else-if="calcPrecentDaysPassed() >= calcPrecentPlayedMatches()" class="progress-line">
                                        <progress  style="width: 100%" :value="countDays(tournament.date_start, new Date())" :max="countDays(tournament.date_start, tournament.date_finish)"></progress>
                                    </div>

                                    <div v-else class="progress-line">
                                        <progress  style="width: 100%" :value="Number(tournament.played_matches)" :max="((tournament.users_count - 1) * tournament.count_match) * tournament.users_count"></progress>
                                    </div>

                                    <!--                                        <p>{{tournament.date_finish | moment('DD.MM')}}</p>-->
                                </div>
                                <div class="block-btns-new">
                                    <div class="block-btns">
                                        <p
                                                v-for="(item, key) in list_tabs"
                                                :key="key"
                                                class="btn-item"
                                                :class="(item.id == active_tab.id) ? 'active' : ''"
                                                @click="active_tab = item"

                                        >{{__(item.name)}}</p>
                                    </div>

                                    <div class="btn-new">
                                        <div v-if="!auth">
                                            <div class="no-active" v-on:click="pushRegister">
                                                <p><img src="/dist/images/frontend/create-btn.svg" alt="/" /> {{__('Play')}} 0/{{tournament.count_match}}</p>
                                                <p class="price">
                                                    <img src="/dist/images/frontend/light-coin.svg" alt="">
                                                    <span>{{tournament.rate}} mind</span>
                                                </p>
                                            </div>
                                        </div>

                                        <BaseButton
                                                v-on:click="sendInvitation()"
                                                v-else-if="auth && now_date > date_start && now_date < date_finish && tournament.participate && tournament.auth_count_game < tournament.count_match"
                                        >
                                            <span>

                                                <p><img src="/dist/images/frontend/create-btn.svg" alt="/" /> {{__('Play')}} {{tournament.auth_count_game}}/{{tournament.count_match}}</p>
                                                <p class="price">
                                                    <img src="/dist/images/frontend/light-coin.svg" alt="">
                                                    <span>{{tournament.rate}} mind</span>
                                                </p>
                                            </span>
                                            <div :style="{width: ((tournament.auth_count_game * 100) / tournament.count_match) + '%'}" class="match-progress"></div>
                                        </BaseButton>

                                        <div v-else>
                                            <div class="no-active">
                                                <p><img src="/dist/images/frontend/create-btn.svg" alt="/" /> {{__('Play')}} {{ tournament.auth_count_game ? tournament.auth_count_game : 0 }}/{{tournament.count_match}}</p>
                                                <p class="price">
                                                    <img src="/dist/images/frontend/light-coin.svg" alt="">
                                                    <span>{{tournament.rate}} mind</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div v-if="active_tab.id == 1" class="block-participants">
                            <div class="header-participants">
                                <p class="place">{{__('Place/Prize')}}</p>
                                <p class="player">{{__('Player')}}</p>
                                <p class="stat">Сыграно игр</p>
                                <p class="result">{{__('Result')}}</p>
                            </div>

                            <div class="list-participants">
                                <div v-if="users && users.length">
                                    <ItemParticipantSolo
                                            v-for="(item, key) in users"
                                            :tournament="tournament"
                                            :item.sync="item"
                                            :position="key+1"
                                            :key="key"
                                            @data_invite="dataInvite($event)"
                                            @change_users="changeUsers($event)"
                                    >
                                    </ItemParticipantSolo>
                                </div>
                                <div v-else class="list-participants-empty">
                                    <p>{{__('Be the first!')}}</p>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="active_tab.id == 3" class="block-rules">
                            <div v-if="gameTranslation(tournament.translate).text" class="rules-item" v-html="gameTranslation(tournament.translate).text"></div>
                            <div class="rules-item" v-html="gameTranslation(rules).text"></div>
                        </div>
                    </div>
                    <div class="right-page">
                        <div class="top">
                            <div>
                                <div class="block-attr" v-if="tournament.attribute && tournament.attribute.length">
                                    <p v-for="(item, key) in tournament.attribute" :key="key">{{item.name}}: <span>{{item.value}}</span></p>
                                </div>

                                <div v-if="typeof tournament.game.logo == 'object'" class="img" :style="{'backgroundImage': 'url(' +tournament.game.logo[300] + ')'}"></div>
                                <div v-else class="img" :style="{'backgroundImage': 'url(' +tournament.game.logo + ')'}"></div>

                                <p class="title-quest">{{gameTranslation(tournament.game.game_translations).name}}</p>
                            </div>
                            <ul>
                                <li>
                                    <div class="block-reward__desc">
                                        <img src="/dist/images/frontend/users.svg" alt="">
                                        <span>{{tournament.users_count}}/{{tournament.count_users}}</span>
                                    </div>
                                </li>
                                <li>
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.0122337 0.944785H2.96553V0H11.6564V0.93047H14.6241C14.6241 0.93047 15.005 5.9238 11.5151 7.43432C11.305 8.22856 10.8735 8.9476 10.2703 9.50858C9.6672 10.0696 8.91697 10.4497 8.10609 10.6051V12.4826H10.15V14H4.55632V12.4826H6.57144V10.6154C5.75069 10.4692 4.98893 10.0931 4.37564 9.53128C3.76235 8.96948 3.32299 8.24528 3.10918 7.44376C-0.368049 5.92638 0.0122337 0.944785 0.0122337 0.944785ZM11.6564 5.62033C13.0299 4.57849 13.1188 2.47648 13.1188 2.47648H11.6564V5.62033ZM5.64621 7.01431L7.32423 6.1371L9.00224 7.01431L8.68155 5.15624L10.0392 3.83926L8.16309 3.56814L7.32423 1.87898L6.48536 3.56957L4.60929 3.8407L5.9669 5.15767L5.64621 7.01431ZM2.96553 5.62376V2.4908H1.51724C1.51724 2.4908 1.60561 4.57849 2.96553 5.62376Z" fill="#F6A401"/>
                                    </svg>
                                    <span>≈70%</span>
                                </li>
                            </ul>
                        </div>

                        <div v-if="!auth" class="block-btn">
                            <div class="block-price">
                                <p class="block-price__title">{{__('Сost')}}</p>

                                <div class="block-price__body">
                                    <p>{{primNum(tournament.price, 2)}}</p>
                                    <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                                    <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </div>
                            <BaseButton
                                    class_btn="btn-green"
                                    v-on:click="pushRegister"
                            >
                            <span>
                                {{__('Participate')}}
                            </span>
                            </BaseButton>
                        </div>

                        <div v-else-if="now_date > date_finish" class="block-btn">
                            <div class="block-price">
                                <p class="block-price__title">{{__('Сost')}}</p>

                                <div class="block-price__body">
                                    <p>{{primNum(tournament.price, 2)}}</p>
                                    <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                                    <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </div>

                            <div class="completed">
                                <p>{{__('Completed_tournament')}}</p>
                            </div>
                        </div>

                        <div v-else-if="tournament.participate" class="block-btn">
                            <div class="block-price">
                                <p class="block-price__title">{{__('Сost')}}</p>

                                <div class="block-price__body">
                                    <p>{{primNum(tournament.price, 2)}}</p>
                                    <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                                    <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </div>

                            <div class="purchased">
                                <p>{{__('Purchased')}}</p>
                            </div>
                        </div>

                        <div v-else-if="now_date < date_start && tournament.users_count >= tournament.count_users" class="block-btn">
                            <div class="block-price">
                                <p class="block-price__title">{{__('Сost')}}</p>

                                <div class="block-price__body">
                                    <p>{{primNum(tournament.price, 2)}}</p>
                                    <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                                    <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </div>

                            <div class="available">
                                <p>{{__('Participants identified')}}</p>
                            </div>
                        </div>


                        <div v-else-if="now_date > date_start && now_date < date_finish" class="block-btn">
                            <div class="block-price">
                                <p class="block-price__title">{{__('Сost')}}</p>

                                <div class="block-price__body">
                                    <p>{{primNum(tournament.price, 2)}}</p>
                                    <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                                    <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </div>

                            <div class="active">
                                <p>{{__('Active')}}</p>
                            </div>
                        </div>



                        <div v-else class="block-btn">
                            <div class="block-price">
                                <p class="block-price__title">{{__('Сost')}}</p>

                                <div class="block-price__body">
                                    <p>{{primNum(tournament.price, 2)}}</p>
                                    <img height="20" v-if="tournament.wallet == 1" src="/dist/images/frontend/money.svg" alt />
                                    <img height="20" v-else src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </div>

                            <BaseButton
                                    style="width: 100%"
                                    class_btn="btn-green"
                                    v-on:click="onSubmit"
                                    :loading="submitDisabled"
                            >
                            <span>
                                {{__('Participate')}}
                            </span>
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>

            </div>
        </div>
        <div class="home-games-right">
            <GeneralChatNew></GeneralChatNew>
        </div>


        <ModalConfirmTournament
            v-if="open_confirm"
            v-bind:open_confirm.sync="open_confirm"
            @buyTournament="buyTournament(tournament.id)"
            v-bind:tournament.sync="tournament"
            :submitDisabled.sync = "submitDisabled"
        ></ModalConfirmTournament>

        <ModalInaccessibleTournament
            v-if="inaccessible_tournament"
            :inaccessible_tournament.sync="inaccessible_tournament"
            :tournament="tournament"
            :info="info"
        >
        </ModalInaccessibleTournament>

        <ModalBunParticipate
            v-if="modal_bun"
            v-bind:modal_bun.sync="modal_bun"
        ></ModalBunParticipate>

        <ModalInviteGame v-if="invite" v-bind:data_invite.sync="data_invite" v-bind:game="tournament.game" v-bind:user="invite.user" v-bind:invite="invite" />
    </div>
</template>

<script>
    import GeneralChatNew from "../../modules/GeneralChatNew";
    import moment from "moment";
    import ModalConfirmTournament from "./ModalConfirmTournament";
    import ModalInaccessibleTournament from "./ModalInaccessibleTournament";
    import BaseButton from "../../modules/BaseButton";
    import ItemParticipantSolo from "./ItemParticipantSolo";
    import ModalInviteGame from "../../modules/ModalInviteGame";
    import LayoutNavAuth from "../../modules/LayoutNavAuth";
    import ModalBunParticipate from "./ModalBunParticipate";

    export default {
        name: 'PageTournamentSolo',
        data() {
            return {
                countdownToStart: "",
                countdownToFinish: "",
                show: false,
                submitDisabled: false,
                open_confirm: false,
                inaccessible_tournament: false,
                tournament: null,
                users: null,
                info: null,
                rules: null,
                table: null,
                progress: 0,
                now_date: moment.utc().format('X'),
                date_start: null,
                date_finish: null,
                interval: null,
                list_tabs: [
                    {
                        id: 1,
                        name: 'List of participants'
                    },
                    {
                        id: 3,
                        name: 'Tournament rules'
                    }
                ],
                active_tab: {
                    id: 1,
                    name: 'List of participants'
                },

                data_invite: null,
                modal_bun: false
            }
        },
        computed: {

            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.getters.getLanguage;
            },
            invite() {
                return this.$store.state.games.modal_invite;
            },
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.createdComponent(to.params.id);
            return next();
        },
        created() {
            this.createdComponent(this.$route.params.id);
            if (this.auth) {
                this.$echo.join("tournament." + this.$route.params.id)
                .listen("TournamentEvent", (response) => {
                    if (response.data.method == "update_user") {
                        this.createdComponent(this.tournament.id);
                    }
                });
            }
        },
        beforeDestroy() {
            this.$echo.leave("tournament." + this.tournament.id);
            clearInterval(this.interval);
        },

        mounted() {
            this.updateCountdowns();
            this.interval = setInterval(this.updateCountdowns, 1000);
        },
        methods: {
            sendInvitation() {

                this.submitDisabled = true;

                const formData = {
                    match_id: this.tournament.game.match_id,
                    game_id: this.tournament.game.id,
                    tournament_id: this.tournament.id,
                };

                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/games/play_single', formData)
                    .then(response=> {
                        return response.json()
                    })
                    .then((response)=> {
                        if(this.$checkAuth(response)) {
                            this.submitDisabled = false;
                            response = response.data;

                            this.$router.push({
                                name: "frame",
                                params: {
                                    id: response.game_url,
                                    room_id: response.room_id,
                                    battle_id: response.battle_id,
                                    lang: this.$store.getters.getLang
                                },
                            });
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                        this.submitDisabled = false;
                    });
            },
            isTournamentUpcoming(date_start) {
                const now = moment.utc(); // текущее время в UTC
                const startDate = moment.utc(date_start); // начало турнира в UTC
                return now.isBefore(startDate); // сравнение в UTC
            },

            isTournamentActive(date_start, date_finish) {
                const now = moment.utc(); // текущее время в UTC
                const startDate = moment.utc(date_start); // начало турнира в UTC
                const endDate = moment.utc(date_finish); // конец турнира в UTC
                return now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate); // сравнение в UTC
            },
            updateCountdowns() {
                if (!this.date_start || !this.date_finish) {
                    return;
                }

                const now = moment.utc(); // текущее время в UTC
                const startDate = moment.utc(this.tournament.date_start); // начало турнира в UTC
                const endDate = moment.utc(this.tournament.date_finish); // конец турнира в UTC

                if (this.isTournamentUpcoming(this.tournament.date_start)) {
                    const timeDiff = startDate.diff(now); // разница во времени в UTC
                    this.countdownToStart = this.formatCountdown(timeDiff);
                } else if (this.isTournamentActive(this.tournament.date_start, this.tournament.date_finish)) {
                    const timeDiff = endDate.diff(now); // разница во времени в UTC
                    this.countdownToFinish = this.formatCountdown(timeDiff);
                }
            },
            formatCountdown(time) {
                const hours = Math.floor(time / (1000 * 60 * 60));
                const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((time % (1000 * 60)) / 1000);

                const formattedHours = hours.toString().padStart(2, '0');
                const formattedMinutes = minutes.toString().padStart(2, '0');
                const formattedSeconds = seconds.toString().padStart(2, '0');

                return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            },

            calcPrecentPlayedMatches() {
                if (this.tournament.users_count > 1) {
                    let total_match = ((this.tournament.users_count - 1) * this.tournament.count_match) * this.tournament.users_count;
                    return this.primNum((Number(this.tournament.played_matches) * 100) / total_match, 2)
                } else {
                    return 0
                }

            },
            calcPrecentDaysPassed() {
                return this.countDays(this.tournament.date_start, new Date()) * 100 / this.countDays(this.tournament.date_start, this.tournament.date_finish);
            },
            changeUsers(res) {
                this.users = res.users;
            },
            dataInvite(res) {
                this.data_invite = res;
            },
            gameTranslation(arr) {
                if (this.$filterArray(arr, this.lang, 'lang')) {
                    return this.$filterArray(arr, this.lang, 'lang');
                } else {
                    return this.$filterArray(arr, 'en', 'lang');
                }
            },
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');
            },

            buyTournament(val) {
                this.submitDisabled = true;
                const response = {
                    id: val,
                };
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/tournaments/participate", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            this.submitDisabled = false;
                            this.open_confirm = false;
                            this.users = response.data.users;
                            this.tournament.participate = response.data.participate;
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        this.httpHandler(err);
                        // let response = this.httpHandler(err);
                        // if (response.status == '422') {
                        //     this.open_confirm = false;
                        //     this.inaccessible_quest = true;
                        // }
                    });
            },
            onSubmit() {
                if (this.info.ban) {
                    this.modal_bun = true;
                } else {
                    if ((this.tournament.verification && !this.info.verification) || (this.tournament.pro && !this.info.pro) || (+this.tournament.count_game > +this.info.count_game) || (+this.tournament.count_opponent > +this.info.count_opponent) || (Number(this.primNum(this.tournament.min_balance, 2)) > Number(this.primNum(this.info.balance, 2)))) {
                        this.inaccessible_tournament = true;
                    } else {
                        this.open_confirm = true;
                    }
                }
            },

            date(date) {
                return moment.utc(date).format('X')
            },

            createdComponent(id) {
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);
                this.$http.get("ajax/tournaments/" + id)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        this.tournament = response.data.tournament;
                        this.users = response.data.users;
                        this.info = response.data.info;
                        this.rules = response.data.rules;
                        this.table = response.data.table;
                        this.date_finish =  moment.utc(response.data.tournament.date_finish).format('X');
                        this.date_start =  moment.utc(response.data.tournament.date_start).format('X');
                        // this.isTournamentUpcoming(response.data.tournament.date_start);
                        // this.isTournamentActive(response.data.tournament.date_start, response.data.tournament.date_finish);
                        // clearInterval(timeout);
                        // console.log(moment.utc().format('x'));
                        // console.log(moment.utc());
                        this.progress = 100;
                        setTimeout(() => {
                            this.progress = 0;
                        }, 500);
                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });
            },

            countDays(dateStart, dateFinish) {
                return moment(dateFinish).diff(moment(dateStart), 'days')
            }
        },


        props: {

        },
        components: {
            GeneralChatNew,
            ModalConfirmTournament,
            ModalInaccessibleTournament,
            BaseButton,
            ItemParticipantSolo,
            ModalInviteGame,
            LayoutNavAuth,
            ModalBunParticipate,
        }
    }
</script>

<style lang="scss" scoped>

</style>