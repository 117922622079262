<template>
    <div class="user-list">
      <v-list color="transparent" class="pa-0">
        <v-list-item class="pa-0">
          <div class="block-img" :class="(item.user.pro) ? 'user-pro-img' : ''">
            <router-link :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}" >
              <div class="wrap-img">
                <img v-if="item.user.photo && typeof item.user.photo == 'object'" :src="item.user.photo[40]"/>
                <img v-else-if="item.user.photo" :src="item.user.photo"/>
                <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
              </div>
              <div v-if="now_date < date" class="online"></div>
            </router-link>
          </div>
          <v-list-item-content class="person-info pa-0">
            <v-list-item-title>
              <router-link :class="(item.user.pro) ? 'user-pro' : ''" :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}" >
                {{item.user.name+" "+((item.user.last_name) ? item.user.last_name : '')}}
              </router-link>
            </v-list-item-title>
            <v-list-item-subtitle>
              <img class="ml-0 mr-1" height="12px" src="/dist/images/frontend/fire.svg" alt />
              <span style="color: #F6A401" class="mr-2">{{item.user.rating_points}}</span>
              <span style="color: #96AFD1">
                    <img width="14" :src="item.user.location_img" />
                    {{$getLocation(item.user.location)}}
                  </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="block-game-rating" v-if="item.commis">
<!--        <img height="16px" class="mr-2" src="/dist/images/frontend/money.svg" alt />-->
<!--        <span>{{Number(item.commis).toFixed(4)}}</span>-->
        <span class="block-real" v-if="item.wallet == 1">
            <img height="16px" class="mr-2" src="/dist/images/frontend/money.svg" alt />
            {{Number(Math.floor(item.commis * 1000) / 1000)}} {{__('RC')}}
        </span>
        <span class="block-lite" v-else>
          <img height="16px" class="mr-2" src="/dist/images/frontend/light-coin.svg" alt />
          {{Number(Math.floor(item.commis * 1000) / 1000)}} {{__('LC')}}
        </span>
      </div>
    </div>
</template>

<script>

import moment from "moment";

export default {

  data() {
    return {
      submitDisabled: false,
      now_date: moment.utc(new Date()).format('X'),
      date: (this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
    }
  },
  props: {
    item: {
      type: Object,
    },
    info: {
      type: Object,
    }
  },
  mounted() {

  },
  components: {

  },
};
</script>

<style lang="scss" scoped>

.online {
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: #00C569;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 5px;
  bottom: 2px;
}

.user-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:5px 12px 5px 5px;
  /*border-bottom: 2px solid #ECF4FF;*/
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 38.42%, rgba(255, 255, 255, 0.5) 99.34%);
  border-radius: 8px;
  margin-bottom: 5px;

  .v-list-item {

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-transform: capitalize;
      color: #002B50;
      margin-bottom:3px;

      a {
        text-decoration: none;
        color: #002B50;

        &:hover {
          text-decoration: underline;
          color: #4EBEFF;
        }
      }
    }

    &__subtitle {
      font-size: 12px;
      line-height: 16px;

      img{
        margin-top: -2px;
      }
    }
  }
}

.item_game {
  border-radius: 0;
  background-color: #fff;
  border-bottom: 1px solid #ECF4FF;
  margin-bottom: 0;
  padding: 12px 20px 7px;
}

.block-img {
  margin-right: 10px;
  position: relative;

  a {
    text-decoration: none;
  }

  .wrap-img {
    width: 46px;
    height: 46px;
    border-radius: 50%;

    img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
    }
  }

  .photo {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
  }
}

.block-img-game {
  margin-right: 10px;
  position: relative;

  .wrap-img-game {
    width: 54px;
    height: 54px;
    border-radius: 8px;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);

    img {
      width: 54px;
      height: 54px;
      border-radius: 8px;
    }
  }
}

.rating {
  span {
    margin-right: 6px;
    margin-top: 3px;
    /*font-weight: bold;*/
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #96afd1;
  }

  .v-rating {
    margin-top: 2px;

    .v-rating--dense .v-icon {
      padding: 0;
    }
  }

  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #26a9f4;
    text-decoration: none;
    margin-top: 7px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.success-inner {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #00C443;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: inherit;
  color: #00C443;
  transition-duration: .3s;

  .pic {
    height:12px;
    margin: 0 1px 0 4px;
  }

  &:hover {
    border: 2px solid #00AD3B;
  }
}

.default-inner {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  border: 1px solid #6886AF;
  box-sizing: border-box;
  border-radius: 8px;
  text-transform: inherit;
  color: #6886AF;
  transition-duration: .3s;

  .pic {
    height:12px;
    margin: 0 1px 0 4px;
  }

  &:hover {
    border: 2px solid #96AFD1;
  }
}
.block-game-rating {
  display: flex;
  align-items: center;

  .block-real {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #00C443;

  }

  .block-lite {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #6886AF;
  }

  span {
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #00C443;
  }
}
</style>