<template>
    <div class="wrap-item-banners">
        <span class="text-saved" v-if="submitSuccess">
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.49373 0.758435C8.83831 0.413855 9.39698 0.413855 9.74156 0.758435C10.0861 1.10302 10.0861 1.66169 9.74156 2.00627L4.44745 7.30039C4.10287 7.64497 3.54419 7.64497 3.19961 7.30039L0.258435 4.35921C-0.0861451 4.01463 -0.0861451 3.45596 0.258435 3.11138C0.603015 2.7668 1.16169 2.7668 1.50627 3.11138L3.82353 5.42863L8.49373 0.758435Z" fill="#00C443"/>
            </svg>
             {{__('Copied')}}
        </span>
        <div class="wrap-item-banners__head">
            <h3>{{__('Banner')}} {{item.width}}×{{item.height}}</h3>
            <BaseButton
            v-on:click="banner_preview_modal = true"
            height="30"
            >
                <span>{{__('Preview')}}</span>
            </BaseButton>
        </div>

        <div class="wrap-item-banners__body">
            <input readonly type="text" :value="item.href" ref="text">
<!--            <pre ref="text">{{item.href}}</pre>-->

            <div class="wrap-btn">
                <v-btn
                    v-on:click="copyText"
                    class="btn-link"
                    v-ripple="false"
                    text
                >{{__('Copy html code')}}</v-btn>
            </div>
        </div>
        <ModalBannerPreview
                v-if="banner_preview_modal"
                v-bind:banner_preview_modal.sync="banner_preview_modal"
                v-bind:item.sync="item"
        ></ModalBannerPreview>
    </div>
</template>

<script>
    import ModalBannerPreview from "./ModalBannerPreview";

    export default {
        name: 'BannersItem',
        data() {
            return {
                banner_preview_modal: false,
                submitSuccess: false,
            }
        },
        props: {
            item: {
                type: Object
            }
        },
        methods: {
            copyText: function () {
                // /* Get the text field */
                let copyText = this.$refs.text;
                /* Select the text field */
                copyText.select();

                document.execCommand("copy");

                this.submitSuccess=true;

                setTimeout(()=> {
                    this.submitSuccess= null;
                }, 2000);
            },
        },
        components: {
            ModalBannerPreview
        }
    }
</script>

<style lang="scss" scoped>

    .dark .wrap-item-banners {
        &__head {
            h3 {
                color: #fff;
            }
        }

        &__body {
            background: #181818;
            color: #fff;

            .wrap-btn {
                background: rgba(38, 169, 244, 0.15);

                .v-btn.btn-link {
                    color: #26A9F4!important;
                }
            }
        }
    }

    .wrap-item-banners {
        position: relative;
        margin-bottom: 20px;

        .text-saved {
            position: absolute;
            right: 4px;
            top: 12px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #00C443;

            svg {
                margin-right: 4px;
            }
        }

        &__head {
            display: flex;
            margin-bottom: 10px;

            h3 {
                font-weight: 500;
                font-size: 28px;
                margin-right: 10px;
            }
        }

        &__body {
            overflow: hidden;
            background-color: #F0F6FE;
            border-radius: 6px;
            width: 100%;
            padding:40px 20px 20px;
            position: relative;

            input {
                width: 100%;

                &:focus-visible {
                    outline: none;
                }
            }

            pre {
                margin: 0;
            }

            .wrap-btn {
                position: absolute;
                top: 0;
                right: 0;
                background-color: #D1DEEF;
                height: 40px;
                padding: 0 20px;
                border-radius: 0 8px 0 8px;
            }
        }
    }
</style>