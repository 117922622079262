<template>
    <div class="block-product">
        <div class="block-sale">-30%</div>
        <img src="/dist/images/frontend/store/img_product-1.png" alt="">
        <p class="block-product__name">{{__('USВ FLASH Mindplays 1 TB')}}</p>
        <div class="block-price">
            <p class="price-mind">120k  MIND</p>
            <span class="price-usdt">12.4 USDT</span>
        </div>
        <div class="btn-add-card">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.78469 8.20909L9.78469 1.60332C9.78467 1.27229 9.51632 1.00393 9.1853 1.00393C8.85425 1.00393 8.58588 1.2723 8.5859 1.60333L8.58588 8.20912L1.98012 8.20912C1.64907 8.20912 1.38072 8.47747 1.38073 8.8085C1.38075 9.13953 1.64909 9.4079 1.98012 9.40789L8.58588 9.40789L8.58588 16.0137C8.58588 16.3447 8.85424 16.6131 9.18527 16.613C9.51632 16.613 9.78465 16.3447 9.78465 16.0137L9.78465 9.40789L16.3904 9.4079C16.7215 9.4079 16.9898 9.13952 16.9898 8.8085C16.9898 8.47747 16.7214 8.20912 16.3904 8.20912L9.78469 8.20909Z" fill="white" stroke="white" stroke-linejoin="round"/>
            </svg>
            <span>{{__('Add to Cart')}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .block-product {
        cursor: default;
        background: #171827;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 10px 15px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        min-height: 350px;

        .block-sale {
            position: absolute;
            left: 0;
            top: 20px;
            background: #00AEFF;
            border: 1px solid #00AEFF;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
        }

        img {
            margin-bottom: 40px;
        }

        &__name {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 5px;
        }

        .block-price {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;

            .price-mind {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: #00AEFF;
                margin: 0;
            }
            .price-usdt {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #96AFD1;
                margin: 0;
            }
        }

        .btn-add-card {
            border-radius: 8px;
            background: #000000;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            svg {
                position: absolute;
                left: 12px;
            }

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                text-align: center;
                color: #00AEFF;
            }
        }
    }
</style>