<template>
    <v-dialog v-model="dialog" max-width="610px">
        <div class="wrapper-modal-developer">
            <div class="wrapper-modal-developer__head">
                <h2>{{__('Create new game')}}</h2>
            </div>

            <div class="wrapper-modal-developer__body">
              <UploadAvatarResponse
                      @crop-success="cropSuccess"
                      width="500"
                      height="500"
              >
                <template v-slot="{handleClick}">
                  <div class="block-img">
                    <img
                            @click="handleClick"
                            ref="previewCanvas"
                            class="wrap-upload__img"
                            :width="180"
                            :height="180"
                            src="/assets/games/default-avatar.svg"
                    />
                  </div>
                  <div class="wrap-upload__desc">
                      <span class="text-req">* {{__('Required')}}</span>
                    <div>
                      <h4>{{__('Add preview for your game')}}</h4>
                      <p>{{__('The image must be of high quality, clear, 180 by 180 pixels')}}</p>
                    </div>
                    <div class="d-flex align-center">
<!--                      <v-btn v-ripple="{ class:'secondary&#45;&#45;text'}" text class="btn-cab white&#45;&#45;text" @click="handleClick">Upload image</v-btn>-->
                      <BaseButton

                          @click="handleClick"
                      >
                        <span>{{__('Upload image')}}</span>
                      </BaseButton>
                      <span>{{__('or drag and drop here')}}</span>
                    </div>
                  </div>
                </template>
              </UploadAvatarResponse>

                <div class="block-name">
                    <span>
                        <img src="/dist/images/frontend/application_name-icon.svg" alt />
                    </span>

                    <InputLabelTextDefault
                            :label="__('Application Name')"
                            color="main"
                            v-model="name"
                            v-bind:errors="formValidate.name"
                            @change="updateValidate('name')"
                    ></InputLabelTextDefault>
                </div>
                <div class="block-category">
                    <span>
                        <img src="/dist/images/frontend/application_category-icon.svg" alt />
                    </span>

                    <DropdownDefault
                            style="width: 100%"
                            class="mt-5"
                            color="select_color"
                            v-bind:value.sync="category"
                            :placeholder= "__('Select a category')"
                            v-bind:title_key="'name'"
                            v-bind:dropdown="categories"
                            v-if="categories"
                            @change="updateValidate('category')"
                            v-bind:errors="formValidate.category"
                    />
                </div>

                <div class="block-type">
                    <span>
                        <img src="/dist/images/frontend/developer-iframe-icon.svg" alt />
                    </span>

                    <div>
                        <p class="block-type__title">{{__('Which game will be?')}}</p>

                        <v-radio-group v-model="type" :mandatory="true" row class="wrap-radio">
                            <v-radio color="blue" value="1" :label="__('Simple')"></v-radio>
                            <v-radio color="blue" value="2" :label="__('Multiplayer')"></v-radio>
                        </v-radio-group>
                    </div>
                </div>

                <div v-if="type == 2" class="block-create">
                    <span>
                        <img src="/dist/images/frontend/person-icon.svg" alt />
                    </span>

                    <div>
                        <p class="block-create__title">{{__('Who can create matches?')}}</p>

                        <div class="wrap-checkbox d-flex">
                            <v-checkbox :error="((formValidate['type_user']) ? true : false)" v-model="type_user" disabled value="2" color="blue" :label="__('Admin')"></v-checkbox>
                            <v-checkbox :error="((formValidate['type_user']) ? true : false)" v-model="type_user" disabled value="1" color="blue" :label="__('User')"></v-checkbox>
                        </div>
                    </div>
                </div>
                <div class="wrapper-inputs">
                    <div class="wrap-input">
                        <v-checkbox
                                v-model="terms"
                                color="info"
                                value="info"
                                :error="((!terms && formValidate['terms']) ? true : false)"
                        >
<!--                            <template v-slot:label>-->
<!--                                <div class="label-text">-->
<!--                                    {{__('I have read the')}}-->
<!--                                </div>-->
<!--                            </template>-->
                        </v-checkbox>
                        <div class="check-text">
                            {{__('I have read the')}}
                            <router-link :to="{name: 'privacy', params: {lang: $store.getters.getLang}}">{{__('user agreement')}}</router-link>
                            {{__('and')}}
                            <router-link :to="{name: 'terms', params: {lang: $store.getters.getLang}}">{{__('Terms of use')}}</router-link>
                        </div>
                    </div>

                    <div class="wrap-btn">
<!--                        <v-btn -->
<!--                            class="btn-secondary" -->
<!--                            v-ripple="{ class:'secondary&#45;&#45;text'}" -->
<!--                            text -->
<!--                            @click="dialog = false">-->
<!--                          Cancel-->
<!--                        </v-btn>-->
                      <BaseButton
                          width="115"
                          class_btn="btn-liteGrey mr-3"
                          v-ripple="false"

                          @click="dialog = false"
                      >
                        <span>{{__('Cancel')}}</span>
                      </BaseButton>

<!--                        <v-btn-->
<!--                                class="btn-cab white&#45;&#45;text"-->
<!--                                :loading="submitDisabled"-->
<!--                                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                                text-->
<!--                                v-on:click="onSubmit"-->
<!--                        >Save changes</v-btn>-->
                      <BaseButton
                          :loading="submitDisabled"

                          v-on:click="onSubmit"
                      >
                        <span>{{__('Save changes')}}</span>
                      </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownDefault from "../../modules/DropdownDefault";
    import { maxLength, minLength, required } from "vuelidate/lib/validators";
    import UploadAvatarResponse from "../../modules/UploadAvatarResponse";
    export default {
        name: 'ModalCreateGame',
        data() {
            return {
                dialog: true,
                name: "",
                category: null,
                type: "1",
                type_user: ['2'],
                terms: false,
                submitDisabled: false,
                categories: this.game_categories,
                formValidate: {
                    name: null,
                    category: null,
                    type: null,
                    type_user: null,
                    terms: null,
                },
                rules: this.validateMessage,
                img: null,
                mime: null,
                createImgUrl: null,
                imgFormat: null,
                field: null
            };
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
            game_categories() {
                return this.$renameKeys(this.$store.getters.categories, {
                    id: "value",
                    category: "name",
                });
            },
            page: function () {
                return this.$route.name;
            },
        },
        validations: {
            name: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(3),
            },
            category: {
                required,
                minValue: 1,
            },
            type: {
                required,
            },
            type_user: {
                required,
            },
            terms: {
                required,
            },
        },
        asyncComputed: {
            async_categories() {
                let categories = this.game_categories;

                if (categories) {
                    this.categories = categories;
                } else {
                    return null;
                }
            },
        },
        methods: {
            cropSuccess(img,imgFormat, field) {
                this.img= img;
                this.imgFormat= imgFormat;
                this.field= field;
            },
            updateValidate: function (type) {
                // let app= this.formValidate;
                //
                // let validate= this.$v[type];
                // if(validate.$invalid) {
                //     app[type]= [];
                //     for(var prop in validate.$params) {
                //         if(!validate[prop]) {
                //             let msg= this.__(this.rules[prop], validate.$params[prop]);
                //             app[type].push(msg);
                //         }
                //     }
                // } else {
                //     app[type]= [];
                // }
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v[type],
                    type
                );
            },

            onSubmit: function () {
                let valid = this.$v;

                if (!this.terms) {
                    this.formValidate["terms"] = true;
                } else if (valid.$invalid) {
                    let massages = {
                        name: [],
                        category: [],
                        type: [],
                        type_user: [],
                        terms: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else if (this.category == 0) {
                    this.formValidate["category"] = [
                        this.__(this.validateMessage["required"]),
                    ];
                } else {

                    let {
                        name,
                        category,
                        type,
                        type_user,
                        img,
                        field,
                        imgFormat
                    } = this;

                 if (type_user.length > 1) {
                        type_user = "3";
                 } else {
                     type_user = type_user[0];
                 }

                    this.submitDisabled = true;
                    let fmData = new FormData();
                    if(img) {
                        fmData.append(field, img, field + '.' + imgFormat);
                    }

                    fmData.append('name', name);
                    fmData.append('category', category);
                    fmData.append('type', type);
                    if (type == "2") {
                        fmData.append('type_user', type_user);
                    }


                    this.$http.post("ajax/developers/create", fmData)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.$emit('update:developer_create_game', false);
                                this.$router.push({
                                    name: "dev_info",
                                    params: {id: response.data.id, lang: this.$store.getters.getLang},
                                });
                                this.dialog = false;
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
            cancelModal() {
                this.$emit("update:developer_create_game", false);
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            }
        },
        components: {
            UploadAvatarResponse,
            InputLabelTextDefault,
            DropdownDefault,
        },
    }
</script>

<style lang="scss" scoped>

.dark {
    .wrapper-modal-developer {
        background: #171827;

        &__head {
            border-bottom: 1px solid #2B2B2B;

            h2 {
                color: #fff;
            }
        }

        &__body {
            .wrapper-inputs {
                .wrap-input {
                    .check-text {
                        color: #fff;
                    }
                }
            }
            .block-type {
                &__title {
                    color: #96AFD1;
                }
            }
        }
    }
}

  .wrapper-modal-developer {
    background: #FFFFFF;
    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &__head {
      padding: 20px 25px;
      border-bottom: 1px solid #F0F6FE;

      h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
      }
    }

    &__body {
      padding: 25px 25px 27px;
      .block-name {
        display: flex;
        align-items: flex-end;

        span {
          margin: 0 38px 0 10px;
        }
      }

      .block-category {
        display: flex;
        align-items: flex-end;
        margin-bottom: 28px;

        span {
          margin: 0 35px 0 10px;
          padding-left: 3px;
        }
      }

      .block-type {
        display: flex;
        align-items: flex-start;

        &__title {
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: 0;
        }

        span {
            margin: 0 35px 0 10px;
            padding-left: 4px;

            img {
                height: 20px;
            }
        }
        .v-input--radio-group.v-input--radio-group--row .v-radio {
          margin-right: 38px;
        }
      }

        .block-create {
            display: flex;
            align-items: flex-start;
            margin-top: 6px;

            &__title {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            span {
                margin: 0 37px 0 11px;
                padding-left: 4px;

                img {
                    height: 20px;
                }
            }
            .v-input--radio-group.v-input--radio-group--row .v-radio {
                margin-right: 38px;
            }
        }

      .wrapper-inputs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-top: -16px;

        .wrap-input {
          display: flex;
          align-items: center;
          margin-left: 12px;

          .label-text {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #002b50;
            margin-left: 26px;
            margin-right: 6px;
          }

          .check-text {
            margin-top: 30px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #002b50;
            margin-right: 6px;
            margin-left: 25px;

            a {
              color: #26a9f4;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .wrap-btn {
          display: flex;
          .btn-cab {
            margin: 0 0 0 15px;
            height: 40px;
          }

          .btn-secondary {
            min-width: 105px;
            margin-right: 15px;
          }
        }
      }
    }
  }

@media screen and (max-width:1024px) {
    .wrapper-modal-developer {
        &__body {
            padding: 20px;

            .wrapper-inputs {
                flex-direction: column;

                .wrap-input {
                    width: 100%;

                    .label-text {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #002b50;
                        margin-left: 4px;
                        margin-right: 6px;
                    }
                    .check-text {
                        margin-top: 28px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #002b50;
                        margin-left: -3px;
                        margin-right: 6px;

                        a {
                            color: #26a9f4;
                            text-decoration: none;
                            margin-left: 0;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .wrap-btn {
                    width: 100%;
                    justify-content: center;
                    margin-top: 15px;
                }
            }

            .block-name,
            .block-category,
            .block-type {
                span {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 500px){
    .wrapper-modal-developer {

        &__head {
            padding: 15px;

            h2 {
                font-size: 18px;
                line-height: 25px;
            }
        }

        &__body {
            padding: 15px;
            .block-category {
                margin-bottom: 20px;
                margin-top: -10px;
            }

            .block-type {
                .v-input--radio-group.v-input--radio-group--row .v-radio {
                    margin-bottom: 6px;
                }
                .v-input--selection-controls {
                    margin-top: 10px;
                    padding-top: 0;
                }
            }

            .wrapper-inputs {
                .wrap-input {
                    margin-top: -15px;
                }
            }
        }
    }
}
</style>