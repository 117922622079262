<template>
  <div  class="wrapper-notification" v-on:click="closeNotice">
    <router-link :to="{name: 'profile', params: {id: notice.user.public_id, lang: $store.getters.getLang}}" v-if="notice.notice== 'friends'">
      <v-list color="transparent" class="pa-0 block-person">
        <v-list-item class="pa-0">
          <div class="image">
            <div class="wrap-img">
              <!--                <img :src="'/assets/users/'+((notice.photo) ? notice.photo : 'a-default.svg')" />-->

              <img v-if="notice.user.photo" :src="notice.user.photo" />
              <span v-else class="photo" :style="{'background': notice.user.bg_color}">{{notice.user.name.substr(0,1)}}</span>

            </div>
            <div class="online"></div>
          </div>
          <v-list-item-content class="pa-0">
            <div>
              <v-list-item-title>{{notice.user.name}}</v-list-item-title>
              <v-list-item-subtitle v-if="notice.type== 1">
                {{__('Friend invite')}}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                {{__('Confirmed friend request')}}
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </router-link>
    <div class="wrapper-notification__item" v-else-if="notice.notice == 'achievement'">
      <v-list color="transparent" class="pa-0 block-info-item">
        <v-list-item class="pa-0">
          <div class="image">
            <img :src="notice.icon" />
          </div>
          <v-list-item-content class="pa-0">
            <div>
              <v-list-item-title>{{notice.name}}</v-list-item-title>
<!--              <v-list-item-subtitle>-->
<!--                <p>Rating: {{notice.rating}}</p>-->
<!--                <p>Amount:-->
<!--                  <span v-if="notice.real">{{notice.real}} real</span>-->
<!--                  <span v-else>{{notice.lite}} lite</span>-->
<!--                </p>-->
<!--              </v-list-item-subtitle>-->
              <v-list-item-subtitle class="block-statistic">
                <span class="block-statistic__title">{{__('Reward:')}}</span>
                <div class="block-statistic__desc">
                  <img src="/dist/images/frontend/fire.svg" alt />
                  <span style="color: #F6A401;">+{{notice.rating}}</span>
                </div>
                <div v-if="notice.real" class="block-statistic__desc">
                  <img src="/dist/images/frontend/money.svg" alt />
                  <span  style="color: #00C443;">+{{notice.real}}</span>
                </div>
                <div v-else class="block-statistic__desc">
                  <img src="/dist/images/frontend/light-coin.svg" alt />
                  <span style="color: #6886AF;">+{{notice.lite}}</span>
                </div>
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <router-link class="wrapper-notification__item" :to="{name: 'quests', params: {lang: $store.getters.getLang}}" v-else-if="notice.notice== 'quests'">
      <v-list color="transparent" class="pa-0 block-info-item">
        <v-list-item class="pa-0">
          <div class="image">
            <img :src="notice.icon" />
          </div>
          <v-list-item-content class="pa-0">
            <div>
              <v-list-item-title>{{notice.name}}</v-list-item-title>
<!--              <v-list-item-subtitle>-->
<!--                <p>Rating: {{notice.rating}}</p>-->
<!--                <p>Amount:-->
<!--                  <span v-if="notice.real">{{notice.real}} real</span>-->
<!--                  <span v-else>{{notice.lite}} lite</span>-->
<!--                </p>-->
<!--              </v-list-item-subtitle>-->
              <v-list-item-subtitle class="block-statistic">
                <span class="block-statistic__title">{{__('Reward:')}}</span>
                <div class="block-statistic__desc">
                  <img src="/dist/images/frontend/fire.svg" alt />
                  <span style="color: #F6A401;">+{{notice.rating}}</span>
                </div>
                <div v-if="notice.real" class="block-statistic__desc">
                  <img src="/dist/images/frontend/money.svg" alt />
                  <span  style="color: #00C443;">+{{notice.real}}</span>
                </div>
                <div v-else class="block-statistic__desc">
                  <img src="/dist/images/frontend/light-coin.svg" alt />
                  <span style="color: #6886AF;">+{{notice.lite}}</span>
                </div>
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </router-link>
    <router-link class="wrapper-notification__item"  :to="{name: 'blog', props: {'page': notice.url}, params: {lang: $store.getters.getLang}}" v-else-if="notice.notice== 'news'">
      <v-list color="transparent" class="pa-0 block-info-item">
        <v-list-item class="pa-0">
          <div class="image">
            <img :src="notice.img" />
          </div>
          <v-list-item-content class="pa-0">
            <div>
              <v-list-item-title>{{notice.title}}</v-list-item-title>
              <v-list-item-subtitle>
                {{__('Category:')}} {{notice.category}}
              </v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </router-link>
    <v-btn
        text
        class="btn-link"
        v-ripple="false"
    >
      <img width="12" src="/dist/images/frontend/close.svg" alt="/" />
    </v-btn>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "NotificationsInviteGame",
  data: function () {
    return {
      // currentQ : 0,
      submitDisabled: false,
      date_start: this.notice.timestamp+10,
      lineTime: 0
    };
  },
  props: {
    notice: {
      type: Object,
    },
    activeClass: {
      type: String,
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
    notice_notice() {
        return this.$store.state.auth.notice;
    },
  },
  watch: {},
  methods: {
      closeNotice() {
          let notice_notice= this.$cleanObject(this.notice_notice);

          let index = this.$filterIndexObject(
              notice_notice,
              this.notice.timestamp,
              "timestamp"
          );

          if (index >= 0) {
              notice_notice.splice(index, 1);
              this.$store.dispatch("asyncNotice", notice_notice);
              clearInterval(this.interval);
          }
      }
  },
  mounted() {
    let timestamp= this.date_start;

    this.interval = setInterval(() => {
      let momentUtc= (moment.utc().format('X'));

      if (timestamp >= momentUtc) {
          let timer = timestamp- momentUtc;
          this.lineTime= this.primNum(((this.$noticeTimer- timer)/(this.$noticeTimer/100)), 2);
      } else {
          clearInterval(this.interval);
          this.closeNotice();
      }
    }, 1000);
  },
  components: {},
};
</script>

<style lang="scss" scoped>
  .wrapper-notification {
    background: #FFFFFF;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px 20px 10px 10px;
    width: 330px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      width: 100%;
    }

    .block-person {
      .image {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        margin-right: 10px;
        position: relative;

        .online {
          border-radius: 50%;
          width: 8px;
          height: 8px;
          position: absolute;
          right: 3px;
          bottom: 0;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          background-color: #00C569;
        }

        .wrap-img {
          width: 46px;
          height: 46px;
          border-radius: 50%;

          img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
          }
        }
        .photo {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          display: flex;
          color: #FFFFFF;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          font-size: 18px;
          font-weight: bold;
          text-transform: uppercase;
          text-decoration: none;
        }
      }

      .v-list-item {
        &__title {
          font-weight: bold;
          font-size: 14px;
          line-height: 19px;
          color: #002B50;
          display: flex;
          align-items: center;
          max-width: 205px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__subtitle {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
          max-width: 205px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__item {
      .block-info-item {
        display: flex;

        .image {
          width: 54px;
          height: 54px;
          border-radius: 6px;
          margin-right: 10px;
          position: relative;
          img {
            width: 54px;
            height: 54px;
            border-radius: 6px;
          }
        }
        .v-list-item {
          &__title {
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: #002B50;
            align-items: center;
            margin-bottom: 4px;
            max-width: 205px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__subtitle {
            max-width: 205px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            color: #6886AF;
            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              margin-right: 10px;
            }

            .block-statistic {

              &__title {
                font-size: 14px;
                line-height: 18px;
                color: #6886AF;
              }
              &__desc {
                img {
                  height: 12px;
                  margin-right: 2px;
                  margin-top: -2px;
                }
              }
            }
          }
        }
      }
    }

    .v-btn.btn-link {
      transition: .3s;
      &:hover {
        transform: scale(1.1);
        transition: .3s;
      }
    }
    .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
      padding: 0;
    }
  }
</style>