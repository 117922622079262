<template>
  <div class="wrap-modal-security">
      <div>
        <h4 v-if="modal.security['2fa']" class="title">2FA</h4>
        <h4 v-else-if="modal.security['email']" class="title">{{__('Email')}}</h4>
        <h4 v-else-if="modal.security['email_verification']" class="title">{{__('Email verification')}}</h4>

        <p v-if="modal.security['2fa']">{{__('Additional security from intruders is enabled on your account. Enter below the one-time code from the application')}}</p>
        <p v-else-if="modal.security['email']">{{__('Additional security from intruders is enabled on your account. Enter below one-time code with email')}}</p>
        <p v-else-if="modal.security['email_verification']">{{__('You have not passed verification. Enter below one-time code with email')}}</p>
      </div>
      <div>
          <div>
            <InputLabelTextDefault
                    color="auth"
                    :label="modal.security['2fa'] ? 'OTP Code' : 'Email Code'"
                    v-model="authenticator"
                    v-bind:errors="formValidate.authenticator"
                    @change="updateValidate('authenticator')">

            </InputLabelTextDefault>
          </div>
          <div class="mt-4">
            <v-btn
                    style="width: 100%"
                    class="btn-cab btn-main btn-main-center white--text"
                    :loading="submitDisabled"
                    text
                    v-on:click="onSubmit">
<!--                <img src="/dist/images/frontend/sign.svg" alt />-->
                {{__('Confirm')}}
            </v-btn>
                  <span v-if="modal.security.email && dateUpdate=== false" class="mt-3 d-block">loading...</span>
                  <a v-else-if="modal.security.email && !dateUpdate" class="mt-3 d-block" @click="sendCode">{{__(((send) ? 'Resend' : 'Send')+'  code')}}</a>
                  <p v-else-if="dateUpdate" class="mt-3 mb-0 d-block text-center">{{__('Next resend: '+dateUpdate)}}</p>
          </div>
      </div>
  </div>
</template>


<script>
    import InputLabelTextDefault from "./InputLabelTextDefault";
    import {required, maxLength, minLength, integer} from "vuelidate/lib/validators";

    export default {
        name: 'ModalAuthFormSecurity',
        data() {
            return {
                submitDisabled: false,
                authenticator: null,
                send: (this.modal.security['email'] && !this.modal.security['2fa'] && !this.modal.security['email_verification']) ? true : false,
                formValidate: {
                    authenticator: null,
                },
                rules: this.validateMessage,
                dateUpdate: null,
                captcha: false,
            };
        },
        props: {
            modal: {
                type: Object
            }
        },
        validations: {
            authenticator: {
                required,
                maxLength: maxLength(8),
                minLength: minLength(4),
                integer
            },
        },
        methods: {
            // openCaptcha() {
            //     let valid= this.$v;
            //     if(valid.$invalid) {
            //         let massages= {
            //             'authenticator': [],
            //         };
            //         this.formValidate= this.$formValidate(massages, valid);
            //     } else {
            //         this.$emit('update:captcha', this.onSubmit);
            //     }
            // },
            sendCode: function() {
                this.dateUpdate= false;
                this.$http.get('ajax/resend_code')
                .then(response=> {
                    return response.json()
                })
                .then(response=> {
                    this.$checkAuth(response);
                    this.dateUpdate= response.data.dateUpdate;
                    let refreshIntervalId = setInterval(()=> {
                        if(this.dateUpdate > 0) {
                            this.dateUpdate= this.dateUpdate-1;
                        } else {
                            clearInterval(refreshIntervalId);
                        }
                    }, 1000);
                    this.send= true;
                }).catch(err => {
                    let response= this.httpHandler(err);
                    if(response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            },
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function (session, arrayData) {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'authenticator': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;
                    const response ={
                        authenticator: this.authenticator,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http.post('ajax/authenticator', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then(()=> {
                            if(this.$route.name== 'home') {
                                this.$router.push({
                                    name: "games",
                                    lang:  this.$store.getters.getLang,
                                });
                            }

                            location.reload();
                            // this.$checkAuth(response);
                            // this.submitDisabled= false;
                            // this.$store.dispatch('asyncUserInfo', response);
                            // this.$store.commit('updateModalAuth', false);
                            //
                            // if(response.data.friends) {
                            //     // this.$store.dispatch("asyncFriends", response.data.friends);
                            //     this.$store.dispatch("asyncFriendsReqCount", response.data.friends);
                            // }
                        }).catch(err => {
                            this.submitDisabled= false;
                            let response= this.httpHandler(err);
                            if(response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-modal-security {

        h4 {
            text-align: center;
            font-weight: 400;;
            font-size: 18px;
            line-height: 24px;
            color: #002B50;
            margin-bottom: 20px;
        }

        p,
        span {
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #96AFD1;
            width: 100%;
        }

        a {
            color: #26A9F4!important;

            &:hover {
                text-decoration: underline;
            }
        }

        .btn-cab {
            margin: 0 auto;
            display: block;
            width: 100%;

            img {
                margin: 1px 8px 0 0;
            }

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 100px;
                width: 100%;
                padding: 0;
                margin: 0 auto;
            }
        }

    }
</style>