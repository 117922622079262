<template>
  <div class="wrap-modal-create">
  <v-dialog v-model="dialog" max-width="400px">
    <div class="create-modal-head" :class="(create_match_modal.type == 1) ? 'create-modal-head--simple' : ''">

      <!--<p>{{(this.edit_bid) ? 'Edit match' : 'Create match'}}</p>-->
        <v-list-item class="pa-0">
          <div class="wrap-img">
            <img v-if="typeof create_match_modal.logo == 'object'" :src="create_match_modal.logo[100]" />
            <img v-else :src="create_match_modal.logo" />
          </div>
          <v-list-item-content>
            <div>
              <v-list-item-title>{{__((this.edit_bid) ? 'Edit match' : 'Create match')}}</v-list-item-title>
              <v-list-item-subtitle>{{game_array.name}}</v-list-item-subtitle>
            </div>
          </v-list-item-content>
        </v-list-item>
      <v-btn v-if="this.edit_bid && !auth.pro" text icon class="icon ma-0" @click="dialog= false">
        <img src="/dist/images/frontend/close.svg" alt="/" />
      </v-btn>
      <div class="btn-password" v-if="auth.pro" @click="unlock = !unlock">
        <svg v-if="unlock" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.75953 3.59424C7.35339 3.00038 8.15885 2.66675 8.9987 2.66675C9.83855 2.66675 10.644 3.00038 11.2379 3.59424C11.8648 4.22114 12 4.80854 12 5C12 5.55228 12.4477 6 13 6C13.5523 6 14 5.55228 14 5C14 4.19146 13.588 3.11594 12.6521 2.18003C11.6831 1.21109 10.369 0.666748 8.9987 0.666748C7.62841 0.666748 6.31425 1.21109 5.34531 2.18003C4.37637 3.14897 3.83203 4.46313 3.83203 5.83341V8.16669H3.16667C1.69391 8.16669 0.5 9.36059 0.5 10.8334V16.6667C0.5 18.1394 1.69391 19.3334 3.16667 19.3334H14.8333C16.3061 19.3334 17.5 18.1394 17.5 16.6667V10.8334C17.5 9.36059 16.3061 8.16669 14.8333 8.16669H5.83203V5.83341C5.83203 4.99356 6.16566 4.18811 6.75953 3.59424ZM4.82087 10.1667C4.82458 10.1667 4.82831 10.1667 4.83203 10.1667C4.83576 10.1667 4.83948 10.1667 4.8432 10.1667H14.8333C15.2015 10.1667 15.5 10.4652 15.5 10.8334V16.6667C15.5 17.0349 15.2015 17.3334 14.8333 17.3334H3.16667C2.79848 17.3334 2.5 17.0349 2.5 16.6667V10.8334C2.5 10.4652 2.79848 10.1667 3.16667 10.1667H4.82087Z" fill="url(#paint0_linear_1767_7057)"/>
          <defs>
            <linearGradient id="paint0_linear_1767_7057" x1="1" y1="1" x2="18" y2="19" gradientUnits="userSpaceOnUse">
              <stop stop-color="#01DF72"/>
              <stop offset="1" stop-color="#00AEFF"/>
            </linearGradient>
          </defs>
        </svg>

        <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75953 3.59424C8.35339 3.00038 9.15885 2.66675 9.9987 2.66675C10.8386 2.66675 11.644 3.00038 12.2379 3.59424C12.8317 4.18811 13.1654 4.99356 13.1654 5.83341V8.16669H6.83203V5.83341C6.83203 4.99356 7.16566 4.18811 7.75953 3.59424ZM5.82087 10.1667C5.82458 10.1667 5.82831 10.1667 5.83203 10.1667C5.83576 10.1667 5.83948 10.1667 5.8432 10.1667H14.1542C14.1579 10.1667 14.1616 10.1667 14.1654 10.1667C14.1691 10.1667 14.1728 10.1667 14.1765 10.1667H15.8333C16.2015 10.1667 16.5 10.4652 16.5 10.8334V16.6667C16.5 17.0349 16.2015 17.3334 15.8333 17.3334H4.16667C3.79848 17.3334 3.5 17.0349 3.5 16.6667V10.8334C3.5 10.4652 3.79848 10.1667 4.16667 10.1667H5.82087ZM4.83203 8.16669V5.83341C4.83203 4.46313 5.37637 3.14897 6.34531 2.18003C7.31425 1.21109 8.62841 0.666748 9.9987 0.666748C11.369 0.666748 12.6831 1.21109 13.6521 2.18003C14.621 3.14897 15.1654 4.46313 15.1654 5.83341V8.16669H15.8333C17.3061 8.16669 18.5 9.36059 18.5 10.8334V16.6667C18.5 18.1394 17.3061 19.3334 15.8333 19.3334H4.16667C2.69391 19.3334 1.5 18.1394 1.5 16.6667V10.8334C1.5 9.36059 2.69391 8.16669 4.16667 8.16669H4.83203Z" fill="url(#paint0_linear_1767_5729)"/>
          <defs>
            <linearGradient id="paint0_linear_1767_5729" x1="2" y1="1" x2="19" y2="19" gradientUnits="userSpaceOnUse">
              <stop stop-color="#01DF72"/>
              <stop offset="1" stop-color="#00AEFF"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div class="wrap-slide" v-if="create_match_modal.type == 1">
        <v-range-slider
            :track-color="trackColor1"
            v-model="range"
            :max="(this.form.wallet == 1) ? parseFloat(this.auth['balance_real'].available).toFixed(2) : parseFloat(this.auth['balance_lite'].available).toFixed(2)"
            :min="min"
            hide-details
            class="align-center"
            step="0.1"
        >
        </v-range-slider>
      </div>
    </div>
    <div class="create-modal-body" :class="(create_match_modal.type == 1) ? 'create-modal-body--simple' : ''">

      <div v-if="create_match_modal.type == 2 || create_match_modal.type == 4" class="block-name">
        <p>{{__('Room name')}}</p>
        <InputLabelTextDefault
                type="text"
                color="border-norm"
                v-model="form.name"
                v-bind:errors="formValidate.name"
                @change="updateValidate('name')"
        ></InputLabelTextDefault>
      </div>
      <div class="block-currency">
        <p class="block-currency__title">{{__('Change currency')}}</p>
        <!--<v-radio-group v-model="form.wallet" :mandatory="true" row class="wrap-radio">-->
          <!--<v-radio color="blue" value="1" :label="__('Real Money')"></v-radio>-->
          <!--<v-radio color="blue" value="2" :label="__('Lite Money')"></v-radio>-->
        <!--</v-radio-group>-->

        <v-select
                disabled
                dense
                filled
                solo
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="form.wallet"
                :items="coins"
                item-color="white"

        >
          <template v-slot:selection="data">
            <img width="20px" class="payment-item__icon mr-3" v-html="data.item.img" :src="data.item.img">
            {{ data.item.name }}
<!--            <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />-->
          </template>
          <template v-slot:item="data">
            <template>
              <div class="wrap-item-select">
                <img width="20px" class="payment-item-icon mr-3" v-html="data.item.img" :src="data.item.img">
                <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
              </div>
            </template>
          </template>
        </v-select>
      </div>
      <div class="wrap-price">
        <p class="wrap-price__title" :style="(create_match_modal.type == 1) ? {marginBottom: '32px'} : ''">{{__("Enter amount")}}</p>
        <div class="wrap-price__sum" v-if="create_match_modal.type == 2 || create_match_modal.type == 4">
          <InputLabelTextDefault
            autocomplete="off"
            posLabel="right"
            type="number"
            min="1"
            color="border-norm"
            placeholder="0"
            v-model="form.amount"
            v-bind:errors="formValidate.amount"
            @change="updateValidate('amount')"
          >
            <template v-slot:right>
              <div class="currency">
                <span v-if="form.wallet==1">
                  <img width="20px" src="/dist/images/frontend/money.svg" alt="">
                </span>
                <span v-else-if="form.wallet==2">
                   <img width="20px" src="/dist/images/frontend/light-coin.svg" alt />
                </span>
              </div>
            </template>
          </InputLabelTextDefault>
        </div>


        <div class="wrap-price__amount" v-else>
          <InputLabelTextDefault
            :label="__('From')"
            color="border-norm"
            v-model="form.amount_from"
            v-bind:errors="formValidate.amount_from"
            @input="updateValidate('amount_from')"
            @change="updateMin"
            readonly
            isFocus
          >
              <!--@change="$set(range, 0, $event)"-->

              <template v-slot:right>
              <div class="currency">
                <span v-if="form.wallet==1">
                  <img width="20px" src="/dist/images/frontend/money.svg" alt="">
                </span>
                <span v-else-if="form.wallet==2">
                   <img width="20px" src="/dist/images/frontend/light-coin.svg" alt />
                </span>
              </div>
            </template>
          </InputLabelTextDefault>

          <div class="wrap-img">
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.617403 11.93L7.80642 6.19537C7.92511 6.10052 7.92511 5.89997 7.80642 5.80512L0.616904 0.0698846C0.530918 0.00161989 0.40275 -0.0185975 0.29118 0.0182181C0.267967 0.0258308 0.249997 0.0343171 0.236768 0.0414306C0.155649 0.08511 0.10498 0.169349 0.10498 0.261575V11.7389C0.10498 11.8308 0.155524 11.9154 0.236768 11.9588C0.250371 11.9663 0.268342 11.9747 0.289932 11.9819C0.402625 12.0187 0.531168 11.9986 0.617403 11.93Z" fill="#E0ECFD"/>
            </svg>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.3826 11.93L0.193578 6.19537C0.0748944 6.10052 0.0748944 5.89997 0.193578 5.80512L7.3831 0.0698846C7.46908 0.00161989 7.59725 -0.0185975 7.70882 0.0182181C7.73203 0.0258308 7.75 0.0343171 7.76323 0.0414306C7.84435 0.08511 7.89502 0.169349 7.89502 0.261575V11.7389C7.89502 11.8308 7.84448 11.9154 7.76323 11.9588C7.74963 11.9663 7.73166 11.9747 7.71007 11.9819C7.59738 12.0187 7.46883 11.9986 7.3826 11.93Z" fill="#E0ECFD"/>
            </svg>
          </div>

          <InputLabelTextDefault
            :label="__('To')"
            color="border-norm"
            type="number"
            v-model="form.amount_to"
            v-bind:errors="formValidate.amount_to"
            @input="updateValidate('amount_to')"
            @change="updateMax"
            readonly
            isFocus
          >
              <!--@change="$set(range, 1, $event)"-->

              <template v-slot:right>
              <div class="currency">
                <span v-if="form.wallet==1">
                  <img width="20px" src="/dist/images/frontend/money.svg" alt="">
                </span>
                <span v-else-if="form.wallet==2">
                   <img width="20px" src="/dist/images/frontend/light-coin.svg" alt />
                </span>
              </div>
            </template>
          </InputLabelTextDefault>
        </div>

        <div v-if="create_match_modal.type == 2 || create_match_modal.type == 4" class="block-players">
          <p>{{__('Maximum players:')}}</p>
          <v-slider
                  v-model="form.max_players"
                  :thumb-color="color"
                  :thumb-size="24"
                  :track-color="trackColor"
                  :track-fill-color="color"
                  thumb-label="always"
                  :max="max_players"
                  min="2"
          ></v-slider>
        </div>
      </div>


<!--      <div class="block-password" v-if="auth.pro == 0">-->
<!--        <div class="block-password__text">-->
<!--          <span data-text="PRO">PRO</span>-->
<!--          <p>Create Password</p>-->
<!--        </div>-->
<!--        <div class="block-password__input">-->
<!--          <PasswordSeparate-->
<!--            v-bind:count="6"-->
<!--            v-bind:input_password.sync="form.password"-->
<!--            :error_password.sync="error_password"-->
<!--          ></PasswordSeparate>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="block-create_password" v-if="auth.pro == 0">-->
      <div class="block-create_password" :class="(!unlock) ? 'active' : ''">
        <p>{{__('Game password:')}}</p>
<!--      <div class="block-create_password" v-if="create_match_modal.type_user == 1 || create_match_modal.type_user == 3">-->
        <InputLabelTextDefault
          v-model="form.password"
          :errors="formValidate.password"
          @input="updateValidate('password')"
          switchType
          color="border-norm"
          type="password"
          autocomplete="new-password"
        >
        </InputLabelTextDefault>
      </div>

      <div v-if="create_match_modal.attributes && create_match_modal.attributes.length" class="block-attributes">
        <span>{{__('Adjust game options')}}</span>

        <ItemSelectAttributes v-for="(item, key) in create_match_modal.attributes" v-bind:form.sync="form" v-bind:item="item" @updateAttribute="updateAttribute" :key="key"></ItemSelectAttributes>
<!--        <div class="list-attributes" v-for="(item, key) in create_match_modal.attributes" :key="key">-->
<!--          <div class="item-attributes">-->
<!--            <div class="block-info">-->
<!--              <p>{{item.name}}</p>-->
<!--          <img src="/dist/images/frontend/information.svg" alt />-->
<!--            </div>-->
<!--            <div class="block-select">-->
<!--              <DropdownDefault-->
<!--                      style="width: 100%"-->
<!--                      color="select_color"-->
<!--                      v-bind:dropdown="item.options"-->
<!--                      value_key="key"-->
<!--                      title_key="name"-->
<!--                      v-bind:value.sync="item.options.name"-->
<!--                      type="array"-->
<!--              >-->
<!--                <template v-slot="test">-->
<!--                    {{test.active_value.name}}-->
<!--                </template>-->
<!--              </DropdownDefault>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

      </div>
      <div class="wrap-btn">
<!--        <v-btn-->
<!--          v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--          text-->
<!--          class="btn-secondary"-->
<!--          @click="dialog= false"-->
<!--        >Cancel</v-btn>-->

        <BaseButton
                v-if="this.edit_bid"
                width="121"
                v-ripple="false"
                class_btn="btn-red-quest mr-3"
                @click="cancelBid()"
                :loading="submitDisableded"
        >
          <span>{{__('Delete match')}}</span>
        </BaseButton>

        <BaseButton
            v-else
            width="121"
            v-ripple="false"
            class_btn="btn-liteGrey-quest mr-3"
            @click="dialog= false"
        >
          <span>{{__('Cancel')}}</span>
        </BaseButton>

<!--        <v-btn-->
<!--          class="btn-cab white&#45;&#45;text"-->
<!--          v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--          text-->
<!--          @click="onSubmit"-->
<!--          :loading="submitDisabled"-->
<!--        >{{(this.edit_bid) ? 'Edit match' : 'Create match'}}</v-btn>-->

        <BaseButton
            width="121"
            class_btn="btn-guest"
            @click="onSubmit"
            :loading="submitDisabled"
        >
          <span>{{__((this.edit_bid) ? 'Edit match' : 'Create match')}}</span>
        </BaseButton>
      </div>
    </div>
    <!--    <v-card class="pa-4">-->
    <!--      <span class="dialog_invite">Приглашение в игру</span>-->
    <!--      <v-card-text style="height: 360px;">-->

    <!--        <span>{{invite.amount}}</span>-->
    <!--        <span>{{invite.wallet}}</span>-->
<!--            <span v-for="(attributes, key) in invite.attributes" :key="key">{{attributes}}</span>-->
    <!--        <span>{{invite.user.name}} {{invite.user.last_name}}</span>-->
    <!--        <span>{{invite.user.location_img}} {{invite.user.location}} </span>-->
    <!--        <span>{{invite.user.photo}}</span>-->
    <!--        <span>{{invite.user.id}}</span>-->
    <!--      </v-card-text>-->
    <!--      <v-card-actions>-->
    <!--        <v-btn color="green darken-1" text @click="dialog= false">Cancel</v-btn>-->
    <!--      </v-card-actions>-->
    <!--    </v-card>-->
  </v-dialog>

    <div v-if="filterError" class="modal-error">
      {{__('You already have a created match with the same parameters.')}}
      <v-btn
              text
              class="btn-link"
              v-ripple="false"
              @click="filter = false"
      >
        <img width="12" src="/dist/images/frontend/close.svg" alt="/" />
      </v-btn>
    </div>

  </div>
</template>

<script>
import InputLabelTextDefault from "./InputLabelTextDefault";
// import Switcher from "./Switcher";
// import PasswordSeparate from "./PasswordSeparate";
import {
  maxLength,
  minLength,
  required,
  decimal,
  minValue
} from "vuelidate/lib/validators";
// import DropdownDefault from "./DropdownDefault";
import ItemSelectAttributes from  '../pages/Developers/ItemSelectAttributes.vue';


export default {
  name: "ModalCreateGame",
  data() {
    // let max_players = 2;
    let max_players = (this.create_match_modal.setting && this.create_match_modal.setting[0].max_players) ?  Number(this.create_match_modal.setting[0].max_players) : null;

    return {
      trackColor1: '#E0ECFD',
      min: null,
      range: [],
      max_players: max_players,
      // min_amount: null,
      // max_amount: null,
      color: '#4EBEFF',
      trackColor: '#DAE6F1',
      show: false,
      swipeDirection: 'None',
      dialog: true,
      timer: this.$inviteTimer,
      create_password: false,
      filterError: false,
      form: {
        max_players: 2,
        name: this.edit_bid ? this.edit_bid.name : this.$store.state.auth.user.name,
        amount: this.edit_bid ? this.edit_bid.amount : null,
        amount_from: null,
        amount_to: null,
        password: this.edit_bid ? this.edit_bid.password : null,
        attributes: this.edit_bid ? ((this.edit_bid.attributes) ? this.edit_bid.attributes : JSON.parse(this.edit_bid.attributes)) : [],
        // wallet: this.edit_bid ? Number(this.edit_bid.wallet) : 1,
        wallet: null,
        match_id: this.edit_bid ? ((this.edit_bid.match_id) ? this.edit_bid.match_id : this.edit_bid.id) : null,
      },
      formValidate: ((this.create_match_modal.type == 2 || this.create_match_modal.type == 4) ? {
        name: null,
        amount: null,
        password: null,
      } : {
        password: null,
        amount_to: null,
        amount_from: null
      }),
      error_password: false,
      submitDisabled: false,
      submitDisableded: false,
      coins: [
        {
          name: "MIND Coin",
          img: "/dist/images/frontend/light-coin.svg",
          value: 2
        },
          {
              name: "USDT Coin",
              img: "/dist/images/frontend/money.svg",
              value: 1
          },

      ],
      unlock: true,
    };
  },
  props: {
    create_match_modal: {
      type: [Object, Boolean],
    },
    edit_bid: {
      type: Object,
    },
    list_auth: {
      type: Array,
    },
    invite: {
      type: Object,
    },
  },
  validations() {
    return {
      form: ((this.create_match_modal.type == 2 || this.create_match_modal.type == 4) ? {
        name: {
          required: required,
          maxLength: maxLength(100),
          minLength: minLength(3),
        },
        password: {
          // required: ((!this.unlock) ? required : true),
          minLength: minLength(4),
          maxLength: maxLength(20),
        },
        amount: {
          decimal,
          required: required,
          minValue: ((this.form.wallet == 1) ? minValue(0.1) : minValue(1)),
        },
        wallet: {
          required,
        },
      } : {
          password: {
              // required: ((!this.unlock) ? required : true),
              minLength: minLength(4),
              maxLength: maxLength(20),
          },
          amount_to: {
              decimal,
              required: required,
              minValue: ((this.form.wallet == 1) ? minValue(0.1) : minValue(1))
          },
          amount_from: {
              decimal,
              required: required,
              minValue: ((this.form.wallet == 1) ? minValue(0.1) : minValue(1))
          },
          wallet: {
              required,
          },
      })
    }
  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },

    min_amount() {
      if (this.form.wallet == 1) {
          // return Number((parseFloat(this.auth['balance_real'].available).toFixed(2) * 10) / 100).toFixed(1);
        return Number(this.auth['balance_real'].available).toFixed(2) > 0  ? Number(0.1).toFixed(1) : this.primNum(this.auth['balance_real'].available, 1);

      } else {
          // return Number((parseFloat(this.auth['balance_lite'].available).toFixed(2) * 10) / 100).toFixed(1);
          return Number(1).toFixed(1);
      }
    },

    max_amount() {
        if (this.form.wallet == 1) {
            // return Number((parseFloat(this.auth['balance_real'].available).toFixed(2) * 90) / 100).toFixed(1);
          return Number(this.auth['balance_real'].available).toFixed(2) > 10 ? Number(10).toFixed(1) : this.primNum(this.auth['balance_real'].available, 1);
        } else {
            // return Number((parseFloat(this.auth['balance_lite'].available).toFixed(2) * 90) / 100).toFixed(1);
            return Number(this.auth['balance_lite'].available).toFixed(2) > 1000 ? Number(1000).toFixed(1) : this.primNum(this.auth['balance_lite'].available, 1);
        }
    },

    wallet_start() {
      return 2;
      // return (Number((parseFloat(this.auth['balance_real'].available))) > 0) ?  1 : 2;
    },

    lang() {
      return this.$store.state.language.lang;
    },

    game_array() {
      if (this.$filterArray((this.create_match_modal.game_translations ? this.create_match_modal.game_translations : this.create_match_modal.game_translations_all), this.lang, 'lang')) {
        return this.$filterArray((this.create_match_modal.game_translations ? this.create_match_modal.game_translations : this.create_match_modal.game_translations_all), this.lang, 'lang');
      } else {
        return this.$filterArray((this.create_match_modal.game_translations ? this.create_match_modal.game_translations : this.create_match_modal.game_translations_all), 'en', 'lang');
      }
    },
  },

  asyncComputed: {
      async_user() {
          let auth = this.auth;
          if(auth && !this.form.wallet) {
              this.form.amount_from = this.edit_bid ? Number(this.edit_bid.amount_from) : this.min_amount;
              this.form.amount_to = this.edit_bid ? Number(this.edit_bid.amount_to) : this.max_amount;
              this.form.wallet = this.edit_bid ? Number(this.edit_bid.wallet) : this.wallet_start;
          }

          if(this.form.wallet == 1) {
            this.min = 0.1;
          } else {
            this.min = 1;
          }
          return true;
      },

      async_password() {
        let auth = this.auth;
        if(auth && this.form.password) {
          this.unlock = false;
        }
        return true
      }
  },
  watch: {
    dialog() {
      this.cancelModal();
    },
    amount() {
      this.formValidate.amount = [];
    },

    range(val) {

      this.form.amount_from = val[0];
      this.form.amount_to = val[1];
      this.updateValidate('amount_from');
      this.updateValidate('amount_to');
    },

    filterError(val) {
        if(val) {
            setTimeout(() => {
                this.filterError = false;
            }, 5000)
        }
    },

    'form.wallet'() {
      this.form.amount_from = this.edit_bid ? Number(this.edit_bid.amount_from) : this.min_amount;
      this.form.amount_to = this.edit_bid ? Number(this.edit_bid.amount_to) : this.max_amount;
    },

    // 'form.amount_from'(val) {
    //   if (this.form.wallet == 1 && val < 0.1) {
    //     this.form.amount_from = '0.1'
    //   } else if (this.form.wallet == 2 && val < 1) {
    //     this.form.amount_from = '1'
    //   }
    // }

  },
  methods: {
      updateAttribute(option) {
          let attributes= this.$cleanObject(this.form.attributes);

          let index= this.$filterIndexObject(attributes, option.key, 'key');

          if(index >= 0) {
              this.form.attributes[index]= option;
          } else {
              this.form.attributes.push(option);
          }
      },

      rangeData() {
        if (this.edit_bid) {
          this.$set(this.range, 0, this.form.amount_from);
          this.$set(this.range, 1, this.form.amount_to);
        } else {
          this.$set(this.range, 0, this.min_amount);
          this.$set(this.range, 1, this.max_amount);
        }
      },

      updateMin() {
          this.$set(this.range, 0, this.form.amount_from);
      },

      updateMax() {
          this.$set(this.range, 1, this.form.amount_to);
      },

      updateRangeFrom(val) {
          this.range[0] = Number(val);
      },

      updateRangeTo(val) {
          this.range[1] = Number(val);
      },
    // updateAttributes(key, value) {

      // this.form.attributes.push({
      //     key: key,
      //     option: value
      // });
      // this.form.attributes.push(attributes);
      // let newAttributes = {};
      // newAttributes.attribute = key;
      // newAttributes.option = value;

    // },
    swipe (direction) {
      this.swipeDirection = direction
    },
    updateValidate: function (type) {
      this.formValidate = this.$changeValidate(
        this.formValidate,
        this.$v.form[type],
        type
      );
    },
    cancelModal() {
      this.$emit("updateModal");
    },
    // onSubmit: function () {
    //   let valid = this.$v.form,
    //       filter= false,
    //       form= this.form;
    //
    //
    //   for (let i = 0; i < this.list_auth.length; i++) {
    //     let list_auth= this.list_auth[i];
    //     if(String(list_auth.wallet)== form.wallet && String(list_auth.id)!= form.match_id && this.primNum(list_auth.amount, 2)== this.primNum(form.amount, 2)) {
    //         filter= true;
    //         break;
    //     }
    //   }
    //
    //   if (filter) {
    //     this.formValidate.amount = ["Вы уже создали заявку с такой суммой"];
    //   } else if (valid.$invalid) {
    //     let massages = {
    //       name: [],
    //       amount: [],
    //       password: [],
    //       wallet: [],
    //     };
    //     this.formValidate = this.$formValidate(massages, valid);
    //     this.error_password = valid.password.$invalid;
    //   } else {
    //     this.submitDisabled = true;
    //     const response = this.form;
    //
    //     this.$http.post(
    //         "ajax/games/" + this.create_match_modal.id + "/create_match",
    //         response
    //       )
    //       .then((response) => {
    //         return response.json();
    //       })
    //       .then(() => {
    //         // response={
    //         //     "data": {
    //         //         "amount": "12",
    //         //         "attributes": {},
    //         //         "game_id": "1",
    //         //         "id": 12345701,
    //         //         "status": 1,
    //         //         "user_id": 1,
    //         //         "wallet": 1,
    //         //         "user": {
    //         //             "id": 1,
    //         //             "name": "Happy",
    //         //             "last_name": "Proger",
    //         //             "location": "United Kingdom",
    //         //             "location_img": "de.png",
    //         //             "photo": "1.jpg",
    //         //             "public_id": "happy",
    //         //             "rating_points": 4114
    //         //         },
    //         //         "match_id": "1"
    //         //     },
    //         //     "status": 200
    //         // };
    //
    //         this.submitDisabled = false;
    //         this.dialog = false;
    //
    //         // let game= this.$cleanObject(this.game);
    //         // let userBidList= game.list_auth;
    //         // if(this.edit_bid) {
    //         //     let index = this.$filterIndexObject(userBidList, this.edit_bid.match_id, 'id');
    //         //     userBidList[index]= response.data;
    //         // } else {
    //         //     userBidList.push(response.data);
    //         // }
    //         //
    //         // game.list_auth= userBidList;
    //         // this.$emit('update:game', game);
    //       })
    //       .catch((err) => {
    //         this.submitDisabled = false;
    //         let response = this.httpHandler(err);
    //         if (response && response.error) {
    //           this.formValidate = response.error.message;
    //         }
    //       });
    //   }
    // },
    onSubmit: function () {
      let valid = this.$v.form;
              // filter= false,
              // form= this.form;

      // if (this.create_match_modal.type == 2) {
      //   for (let i = 0; i < this.list_auth.length; i++) {
      //     let list_auth= this.$cleanObject(this.list_auth[i]);
      //     if(String(list_auth.wallet) == form.wallet && String(list_auth.id)!= form.match_id && JSON.stringify(form.attributes) == JSON.stringify(list_auth.attributes)) {
      //       filter = true;
      //       break;
      //     }invalid
      //   }
      // }

      // if (filter) {
      //   // this.formValidate.amount = ["You have already created a request for this amount"];
      //     this.filterError = true;
      // } else if (valid.$invalid) {
       if (valid.$invalid) {
        let massages;

        if(this.create_match_modal.type == 1) {
            massages= {
                amount_to: [],
                amount_from: [],
                password: [],
                wallet: [],
            }
        } else {
            massages= {
                name: [],
                amount: [],
                password: [],
                wallet: [],
            }
        }

        this.formValidate = this.$formValidate(massages, valid);
      } else {
        this.submitDisabled = true;
        let response;


         if(this.create_match_modal.type == 1) {
             response= {
                 amount_to: this.form.amount_to,
                 amount_from: this.form.amount_from,
                 wallet: this.form.wallet,
             }
         } else {
             response= {
                 name: this.form.name,
                 amount: this.form.amount,
                 wallet: this.form.wallet,
                 max_players: this.form.max_players,
             }
         }

         if(!this.unlock) {
             response.password= this.form.password;
         }

         response.attributes= this.form.attributes;
         response.match_id= this.form.match_id;

        this.$http.post("ajax/games/" + this.create_match_modal.id + "/create_match", response)
          .then((response) => {
            return response.json();
          })

        .then((response) => {
            if(this.$checkAuth(response)) {
                // response= {data: {success: true, game_id: "736792", room_id: 836182203, battle_id: 1611317292}, status: 200};
                // response={
                //     "data": {
                //         "amount": "12",
                //         "attributes": {},
                //         "game_id": "1",
                //         "id": 12345701,
                //         "status": 1,
                //         "user_id": 1,
                //         "wallet": 1,
                //         "user": {
                //             "id": 1,
                //             "name": "Happy",
                //             "last_name": "Proger",
                //             "location": "United Kingdom",
                //             "location_img": "de.png",
                //             "photo": "1.jpg",
                //             "public_id": "happy",
                //             "rating_points": 4114
                //         },
                //         "match_id": "1"
                //     },
                //     "status": 200
                // };
                response = response.data;
                if (!this.edit_bid) {
                  if (this.$gtag) {
                    this.$gtag.event('CreateGameDone', { 'GameID': this.create_match_modal.id });
                  }
                  if (this.$metrika) {
                    this.$metrika.reachGoal('CreateGameDone');
                  }
                }
                if ((this.create_match_modal.type == 2 || this.create_match_modal.type == 4) && (this.create_match_modal.developer.id !== this.auth.id || this.create_match_modal.type_user == 1)) {
                    // let invite_game = this.invite;
                    this.submitDisabled = true;

                    this.$router.push({
                        name: "frame",
                        params: {
                            id: this.create_match_modal.url,
                            room_id: response.room_id,
                            battle_id: response.battle_id,
                            lang: this.$store.getters.getLang,
                        },
                    });
                    this.$store.dispatch("asyncInviteGame", []);

                } else {
                    this.submitDisabled = false;
                    this.dialog = false;

                    this.$emit('updateListAuth', response, this.edit_bid);
                }
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            let response = this.httpHandler(err);
            if (response && response.error) {
              this.formValidate = response.error.message;
            }
          });
        }
      },

    cancelBid() {
      let matches = this.edit_bid;
      this.submitDisableded= true;

      const formData = {
        match_id: matches.id,
        game_id: this.create_match_modal.id,
        operation: "remove",
      };
      this.$http.post("ajax/games/" + this.create_match_modal.id + "/remove_match", formData)
              .then((response) => {
                return response.json();
              })
              .then((response) => {
                if(this.$checkAuth(response)) {
                    this.submitDisabled = false;
                    this.dialog = false;
                    this.$emit('removeListGame', matches.id);
                }
              })
              .catch((err) => {
                this.httpHandler(err);
                this.submitDisableded= false;
              });
    },
  },
  created() {
      if(this.create_match_modal.type == 1) {
          this.rangeData();
      }
  },
  mounted() {},
  components: {
    InputLabelTextDefault,
    ItemSelectAttributes,
    // DropdownDefault,
    // PasswordSeparate,
    // Switcher
  },
};
</script>

<style lang="scss" scoped>

  .dark .theme--light.v-list {
    background: #10111b;
    border: 1px solid #2B2B2B;
    box-sizing: border-box;
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
  }

  .dark .v-list {

  }

  .dark {


    .create-modal-head {
      background: #171827;

      .v-list-item {
        &__title {
          color: #fff;
        }

        &__subtitle {
          color: #96AFD1;
        }
      }

      .btn-password {
        background: #000;
      }
    }

    .create-modal-body {
      background: #171827;

      .block-currency,
      .wrap-price {
        &__title {
          color: #96AFD1;
        }
      }

      .block-attributes {
        span {
          color: #FFFFFF;
        }
      }
    }
  }

.create-modal-head {
  /*display: flex;*/
  /*justify-content: space-between;*/
  /*align-items: center;*/
  background-color: #fff;
  border-bottom: 1px solid #F0F6FE;
  border-radius: 4px 4px 0px 0px;
  padding: 10px 10px 10px;
  position: relative;

  &--simple {
    padding: 10px 10px 0;
    border: none;
  }

  .btn-password {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F0F6FE;
    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;

    svg {
      transition-duration: .3s;
    }

    &:hover {
      svg {
        transform: scale(1.1);
        transition-duration: .3s;
      }
    }
  }

    .v-list-item {
      display: flex;
      align-items: center;

      .wrap-img {
        border-radius: 4px;
        width: 40px;
        height: 40px;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);


        img {
          border-radius: 4px;
          width: 40px;
          height: 40px;
        }
      }

      &__content {
        padding: 0;
        margin-left: 8px;
      }

      &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 1px;
      }

      &__subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #002B50;

        span {
          color: #002B50;
        }
      }
    }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #002b50;
    margin-bottom: 0;
  }

  .icon {
    position: absolute;
    top: 6px;
    right: 6px;
    outline: none;

    img {
      height: 12px;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .wrap-slide {
    margin: 0 -18px 0;
  }
}

.create-modal-body {
  padding: 25px 24px 27px;
  border-radius: 0 0 4px 4px;
  background: #fff;

  &--simple {
    padding: 6px 24px 27px;
  }


  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #96afd1;
    margin-bottom: 10px;
  }

  .block-name {
    margin-bottom: 14px;
  }

  .block-currency {
    margin-bottom: 8px;

    /*&__title {*/
    /*  font-weight: 400;*/
    /*  font-size: 15px;*/
    /*  line-height: 16px;*/
    /*  letter-spacing: 0.4px;*/
    /*  color: #6886AF;*/
    /*  margin-bottom: 16px;*/
    /*}*/

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 10px;
    }

    .v-input--radio-group.v-input--radio-group--row .v-radio {
      margin-right: 38px;
    }
  }

  .wrap-price {

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: #6886AF;
      opacity: 0.87;
      margin: 0 0 16px;
    }

    &__amount {
      margin-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrap-img {
        display: flex;
        align-items: center;
        margin: 0 20px;
      }
    }

    /*&__view {*/
    /*  position: relative;*/
    /*  display: flex;*/
    /*  align-items: center;*/
    /*  background: #f0f6fe;*/
    /*  border-radius: 8px;*/
    /*  width: 220px;*/
    /*  height: 40px;*/

    /*  .block-price-view {*/
    /*    display: flex;*/
    /*    width: 100%;*/
    /*    align-items: center;*/


    /*    .price-text {*/
    /*      margin-right: 6px;*/
    /*      display: flex;*/
    /*      width: 100%;*/
    /*      justify-content: center;*/

    /*      &__wrap {*/
    /*        display: flex;*/
    /*        align-items: center;*/


    /*        span {*/
    /*          min-width: 82px;*/
    /*          font-size: 16px;*/
    /*        }*/
    /*      }*/

    /*      .pic {*/
    /*        height: 18px;*/
    /*        margin-right: 9px;*/
    /*      }*/
    /*    }*/
    /*    &.active + .price-btn {*/
    /*      left: calc(100% - 40px);*/
    /*    }*/
    /*  }*/
    /*  .price-btn {*/
    /*    position: absolute;*/
    /*    left: 0;*/
    /*    top: 0;*/
    /*    width: 40px;*/
    /*    height: 40px;*/
    /*    background-color: #d8e5f7;*/
    /*    border-radius: 8px;*/
    /*    color: #ffffff;*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    font-size: 24px;*/
    /*    transition: 0.7s;*/
    /*    pointer-events: none;*/
    /*  }*/
    /*}*/

    .currency {
      margin-top: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 25%;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #6886AF;
      cursor: default;
    }
  }

  /*.block-password {*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  justify-content: space-between;*/
  /*  background: #f0f6fe;*/
  /*  border-radius: 8px;*/
  /*  margin-top: 20px;*/
  /*  padding: 10px 20px 10px 10px;*/

  /*  &__text {*/
  /*    position: relative;*/
  /*    p {*/
  /*      margin-top: 22px;*/
  /*      font-weight: 400;*/
  /*      font-size: 14px;*/
  /*      line-height: 20px;*/
  /*      display: flex;*/
  /*      align-items: center;*/
  /*      color: #000000;*/
  /*      margin-bottom: 0;*/
  /*    }*/

  /*    span {*/
  /*      -webkit-text-stroke: 0.02em #ffffff;*/
  /*      position: absolute;*/
  /*      top: 2px;*/
  /*      left: 83px;*/
  /*      font-weight: 400;*/
  /*      font-size: 10px;*/
  /*      line-height: 20px;*/
  /*      display: flex;*/
  /*      align-items: center;*/
  /*      color: #26a9f4;*/
  /*      !*border: 2px solid #FFFFFF;*!*/
  /*      text-transform: uppercase;*/
  /*      !*width: 25px;*!*/
  /*      !*height: 20px;*!*/
  /*    }*/
  /*  }*/
  /*}*/

  .block-create_password.active {
    height: 70px;
    transition-duration: .3s;
    margin-top: 10px;
    overflow: visible;
  }

  .block-create_password {
    height: 0;
    transition-duration: .3s;
    overflow: hidden;

    &__text {
      display: flex;
      align-items: center;
      margin-bottom: -6px;
      p {
        margin: 0 7px 0 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #002B50;
      }

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 7px;
        width: 22px;
        height: 12px;
        background: #00C443;
        border-radius: 3px;
        font-weight: bold;
        font-size: 8px;
        line-height: 11px;
        color: #fff;
      }
    }

    .v-btn.btn-password {
      top: 6px;
    }
  }

  .block-players {
    margin-top: 18px;
    margin-bottom: -25px;

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      margin-bottom: 30px;
    }
  }

  .block-attributes {
    margin-top: 18px;

    span {
      display: block;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      margin-bottom: 6px;
    }


  }
}
.wrap-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  .btn-cab {
    margin-left: 15px;
  }

  .btn-secondary {
    margin-right: 15px;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 105px;
    padding: 0;
  }
}

.wrap-item-select {
  margin-left: -5px;
}

.modal-error {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  padding: 10px 35px 10px 10px;
  background-color: #fff;
  position: fixed;
  width: 330px;
  height: 65px;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #FF4B55;

  .v-btn.btn-link {
    position: absolute;
    top: 0;
    right: -2px;
  }
}

  @media screen and (max-width: 1024px) {

    /*.v-dialog {*/
    /*  max-width: 450px!important; //styles.scss*/
    /*}*/

    .create-modal-head {
      padding: 15px;

      p {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .create-modal-body {
      padding: 15px;

      .image {
        border-radius: 4px;
        width: 50px;
        height: 50px;
      }
      .wrap-img {
        border-radius: 4px;
        width: 50px;
        height: 50px;

        img {
          border-radius: 4px;
          width: 50px;
          height: 50px;
        }
      }

      .v-list-item {
        display: flex;
        align-items: center;


        &__content {
          padding: 0;
        }

        &__title {
          font-size: 16px;
          line-height: 26px;

        }
      }
      .theme--light.v-list-item .v-list-item__subtitle {
        font-size: 12px;
        line-height: 22px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        margin-top: -5px;
      }

      .wrap-price {

        &__view {
          width: 200px;
          height: 35px;

          .block-price-view {

            .price-text {

              &__wrap {
                display: flex;
                align-items: center;


                span {
                  min-width: 82px;
                  font-size: 14px;
                }
              }

              .pic {
                height: 16px;
                margin-right: 5px;
              }
            }
            &.active + .price-btn {
              left: calc(100% - 35px);
            }
          }
          .price-btn {
            width: 35px;
            height: 35px;
          }
        }

        &__sum {
          width: 200px;
          height: 35px;
          position: relative;

          .currency {
            width: 60px;
            height: 35px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .block-password {
        margin-top: 20px;
        padding: 10px 20px 10px 10px;

        &__text {
          position: relative;
          p {
            margin-top: 22px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-bottom: 0;
          }

        }
      }
    }
    .wrap-btn {

      margin-top: 15px;

      .v-btn:not(.v-btn--round).v-size--default {
        height: 35px;
        min-width: 200px;
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    /*.v-dialog {*/
    /*  max-width: 400px!important; //styles.scss*/
    /*}*/

    .create-modal-head {
      padding: 10px;

      p {
        font-size: 16px;
        line-height: 22px;
      }

      .icon {
        top: 2px;
        right: 2px;
      }
    }

    .create-modal-body {
      padding: 15px;
      border-radius: 0 0 4px 4px;

      .image {
        border-radius: 4px;
        width: 46px;
        height: 46px;
      }
      .wrap-img {
        border-radius: 4px;
        width: 46px;
        height: 46px;

        img {
          border-radius: 4px;
          width: 46px;
          height: 46px;
        }
      }

      .v-list-item {
        display: flex;
        align-items: center;



        &__content {
          padding: 0;
          margin-left: -5px;
        }

        &__title {
          font-size: 16px;
          line-height: 26px;

        }
      }
      .theme--light.v-list-item .v-list-item__subtitle {
        font-size: 12px;
        line-height: 22px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        margin-top: -10px;
      }

      .wrap-price {

        &__view {
          width: 170px;
          height: 35px;

          .block-price-view {

            .price-text {

              &__wrap {
                display: flex;
                align-items: center;
                margin-left: 15px;


                span {
                  min-width: 82px;
                  font-size: 14px;

                }
              }

              .pic {
                height: 14px;
                margin-right: 5px;
              }
            }
            &.active + .price-btn {
              left: calc(100% - 35px);
            }
          }
          .price-btn {
            width: 35px;
            height: 35px;
          }
        }

        &__sum {
          width: 170px;
          height: 35px;
          position: relative;

          .currency {
            width: 60px;
            height: 35px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .block-password {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f0f6fe;
        border-radius: 4px;
        margin-top: 20px;
        padding: 10px 10px 10px 10px;

        &__text {
          position: relative;
          p {
            margin-top: 18px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #000000;
            margin-bottom: 0;
          }

          span {
            -webkit-text-stroke: 0.02em #ffffff;
            position: absolute;
            top: 2px;
            left: 70px;
            font-weight: 400;
            font-size: 10px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #26a9f4;
            /*border: 2px solid #FFFFFF;*/
            text-transform: uppercase;
            /*width: 25px;*/
            /*height: 20px;*/
          }
        }
      }
    }
    .wrap-btn {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;

      .v-btn:not(.v-btn--round).v-size--default {
        height: 35px;
        min-width: 170px;
        padding: 0;
      }

      .btn-cab {
        margin-right: 0;
      }

      .v-btn.cancel {
        background: #e5f0ff;
        border-radius: 6px;
        text-transform: inherit;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0em;
        box-shadow: none;
        padding: 0;
      }

      .cancel {
        outline: none;
        &:hover {
          background-color: #cddff7;
        }
      }
    }
  }

@media screen and (max-width: 500px) {
  /*.v-dialog {*/
  /*  max-width: 300px!important; //styles.scss*/
  /*}*/

  .create-modal-head {
    padding: 10px;

    p {
      font-size: 16px;
      line-height: 22px;
    }

    .icon {
      top: 0px;
      right: 2px;
    }
  }

  .create-modal-body {
    padding: 15px;
    border-radius: 0 0 4px 4px;

    .image {
      border-radius: 6px;
      width: 40px;
      height: 40px;
    }
    .wrap-img {
      border-radius: 6px;
      width: 40px;
      height: 40px;

      img {
        border-radius: 6px;
        width: 40px;
        height: 40px;
      }
    }

    .v-list-item {

      &__content {
        padding: 0;
        margin-left: -5px;
      }

      &__title {
        font-size: 14px;
        line-height: 20px;

      }
    }
    .theme--light.v-list-item .v-list-item__subtitle {
      font-size: 12px;
      line-height: 20px;
    }

    p {
      font-size: 12px;
      line-height: 20px;
      margin-top: -15px;
      margin-bottom: 6px;
    }

    .wrap-price {

      &__view {
        width: 130px;
        height: 30px;
        border-radius: 6px;

        .block-price-view {

          .price-text {

            &__wrap {
              display: flex;
              align-items: center;
              margin-left: 32px;


              span {
                min-width: 82px;
                font-size: 10px;

              }
            }

            .pic {
              height: 12px;
              margin-right: 3px;
            }
          }
          &.active + .price-btn {
            left: calc(100% - 25px);
          }
        }
        .price-btn {
          width: 28px;
          height: 30px;
          border-radius: 6px;
          font-size: 20px;
        }
      }

      &__sum {
        width: 130px;
        height: 30px;
        position: relative;

        .currency {
          width: 40px;
          height: 30px;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    .block-password {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f0f6fe;
      border-radius: 8px;
      margin-top: 20px;
      padding: 10px 10px 10px 10px;

      &__text {
        position: relative;
        p {
          margin-top: 14px;
          font-weight: 400;
          font-size: 10px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #000000;
          margin-bottom: 0;
        }

        span {
          -webkit-text-stroke: 0.02em #ffffff;
          position: absolute;
          top: 2px;
          left: 58px;
          font-weight: 400;
          font-size: 8px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #26a9f4;
          /*border: 2px solid #FFFFFF;*/
          text-transform: uppercase;
          /*width: 25px;*/
          /*height: 20px;*/
        }
      }
    }
  }
  .wrap-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    .v-btn:not(.v-btn--round).v-size--default {
      height: 30px;
      min-width: 130px;
      padding: 0;
    }

    .btn-cab {
      margin-right: 0;
    }

    .v-btn {
      font-size: 12px;
    }

    .v-btn.cancel {
      background: #e5f0ff;
      border-radius: 6px;
      text-transform: inherit;
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0em;
      box-shadow: none;
      padding: 0;
    }

    .cancel {
      outline: none;
      &:hover {
        background-color: #cddff7;
      }
    }
  }
}
</style>
