<template>
    <div class="block-info block-info-left">
        <div class="block-info__head-new" :class="((user.top) ? 'user-top' : '')">
            <div class="top-block">
                <div v-if="my_profile" class="block-img" :class="(user.pro) ? 'user-pro-img' : ''">
                    <UploadAvatar v-bind:response.sync="response_upload" :url="'/ajax/users/image/upload'" v-bind:post_params="{'type': 1}" >
                        <img v-if="user.photo && typeof user.photo == 'object'" :src="user.photo.original">
                        <img v-else-if="user.photo" :src="user.photo">
                        <span v-else class="photo" :style="{'background': user.bg_color}">{{user.name.substr(0,1)}}</span>
                    </UploadAvatar>
<!--                    <p class="location" v-if="user.location"><img class="mr-1 flag" width="14" :src="user.location_img"/></p>-->
                </div>
                <div v-else class="block-img block-img--friend" :class="(user.pro) ? 'user-pro-img' : ''">
                    <img v-if="user.photo && typeof user.photo == 'object'" :src="user.photo.original">
                    <img v-else-if="user.photo" :src="user.photo">
                    <span v-else class="photo" :style="{'background': user.bg_color}">{{user.name.substr(0,1)}}</span>

<!--                    <p class="location" v-if="user.location"><img class="mr-1 flag" width="14" :src="user.location_img"/></p>-->
                </div>
                <div class="top-right">
                    <div class="name">
                        <h2 v-if="profile.top" :class="(user.pro) ? 'user-pro' : ''"><span class="user-name">{{user.name}}</span> {{((user.last_name) ? user.last_name : '')}}</h2>
                        <h3 v-else :class="(user.pro) ? 'user-pro' : ''"><span class="user-name">{{user.name}}</span> {{((user.last_name) ? user.last_name : '')}}</h3>
                    </div>
                    <div class="status-block">
                        <p class="status" v-if="my_profile">
                          <span class="success--text">
                                <span class="dot">•</span>
                                {{__('Online')}}
                          </span>
                        </p>
                        <p class="status" v-else>
                    <span class="warning--text" v-if="profile.ready_play == 1">
                        <span class="dot">•</span>
                        {{__('Standby mode')}}
                    </span>

                            <span class="blue--text" v-else-if="profile.ready_play == 2">
                        <span class="dot">•</span>
                        {{__('Now playing')}}
                    </span>

                            <span class="status__offline grey--text" v-else-if="now_date > date">
                        <span class="dot">•</span>
                        {{__('Online more than')}} {{$minAgo(timestamp_ago)}} {{__('ago')}}
                    </span>

                            <span class="success--text" v-else>
                        <span class="dot">•</span>
                        {{__('Online')}}
                    </span>
                        </p>
                    </div>
                    <div class="stat-block">
                        <p class="location" v-if="user.location"><img class="mr-1 flag" width="14" :src="user.location_img"/></p>
                        <div class="rating">
                            <div class="block-info__person-rating">
                                <img class="ml-0" height="14px" src="/dist/images/frontend/fire.svg" alt />
                                {{user.rating_points}}
                            </div>
                            <div class="block-info__person-statistic">
                                <img class="pic" src="/dist/images/frontend/rating.svg" alt />
                                {{profile.history_percent}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <div class="block-info__head-wrap">-->

<!--                <div class="block-info__person-wrap">-->
<!--                    <div class="block-info__person-name">-->
<!--                        <div class="d-flex align-center">-->
<!--                            <div class="block-info__person-rating">-->
<!--                                <img class="ml-0" height="14px" src="/dist/images/frontend/fire.svg" alt />-->
<!--                                {{user.rating_points}}-->
<!--                            </div>-->
<!--                            <div class="block-info__person-statistic">-->
<!--                                <img class="pic" src="/dist/images/frontend/rating.svg" alt />-->
<!--                                 {{profile.history_percent}}%-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
        </div>
        <div class="block-info__body">

            <div class="block-user_pick">
                    <div v-if="my_profile" class="block-link">
                        <div class="wrap-btn">
<!--                            <v-btn :to="{name: 'settings'}" class="btn-profile" text v-ripple="{ class:'secondary&#45;&#45;text'}">-->
<!--                                <img src="/dist/images/frontend/icon-edit.svg" alt />-->
<!--                                <span>Edit profile</span>-->
<!--                            </v-btn>-->
                            <BaseButton :width="126" :to="{name: 'settings', params: {lang: $store.getters.getLang}}">
                              <span>
                                <img src="/dist/images/frontend/icon-edit.svg" alt />
                                {{__('Edit profile')}}
                              </span>
                            </BaseButton>

<!--                            <div class="switch">-->
<!--                                <span>-->
<!--                                    Standby mode-->
<!--                                </span>-->
<!--                                <div class="switch-text">-->
<!--                                    <input type="checkbox" id="switch" :disabled="loadingReadyPlay" v-model="ready_play"  @change="waitingStatus()"/>-->
<!--                                    <label for="switch">Toggle</label>-->
<!--                                    <p v-if="ready_play" class="warning&#45;&#45;text">On</p>-->
<!--                                    <p v-else>Off</p>-->
<!--                                </div>-->
<!--                                <v-tooltip-->
<!--                                        v-model="show"-->
<!--                                        top-->
<!--                                        color="transparent"-->
<!--                                        nudge-top="-16"-->
<!--                                >-->
<!--                                    <template v-slot:activator="{ on, attrs }">-->
<!--                                        <v-btn-->
<!--                                                icon-->
<!--                                                v-bind="attrs"-->
<!--                                                v-on="on"-->
<!--                                        >-->
<!--                                            <img src="/dist/images/frontend/information.svg" alt />-->
<!--                                        </v-btn>-->
<!--                                    </template>-->
<!--                                    <p class="block-desc">-->
<!--                                        When this mode is turned on,-->
<!--                                        you will receive an invitation notification-->
<!--                                        into the game even when you are offline-->
<!--                                    </p>-->
<!--                                </v-tooltip>-->
<!--                            </div>-->
                        </div>
                    </div>
                    <div v-else class="block-link-friend">
                        <div class="wrap-btn" v-if="!auth">
                            <BaseButton v-on:click="pushRegister">
                            <span>
                              {{__('Add to friends')}}
                            </span>
                            </BaseButton>
                            <BaseButton
                                    class="ml-3"
                                    class_btn="btn-liteGrey"
                                    v-on:click="pushRegister"
                            >
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M8.00003 9.50003H15M8.00003 13.5H13M15.6953 19.2318L19.1027 20.3676C19.8845 20.6282 20.6282 19.8844 20.3676 19.1027L19.2318 15.6953M15.3 19.1C15.3 19.1 14.0847 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5C20 14 19.1 15.3 19.1 15.3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                              </span>
                            </BaseButton>
                        </div>
                        <div class=" wrap-btn"  v-else>
<!--                        <v-btn :loading="submitDisabled" class="btn-cab btn-add white&#45;&#45;text" @click="submitSubscribe(0)" v-ripple="{ class:'secondary&#45;&#45;text'}" text v-if="profile.subscribe == 2">-->
<!--                            <img class="ml-0 mr-2" height="16px" src="/dist/images/frontend/add_person.svg" alt />-->
<!--                            <span>Add to friends</span>-->
<!--                        </v-btn>-->
                          <BaseButton :loading="submitDisabled" @click="submitSubscribe(0)"  text v-if="profile.subscribe == 2">
                            <span>
<!--                              <img height="16px" src="/dist/images/frontend/add_person.svg" alt />-->
                              {{__('Add to friends')}}
                            </span>
                          </BaseButton>
<!--                          <v-btn :loading="submitDisabled"  class="btn-delete" @click="submitSubscribe(2)" text   v-else-if="profile.subscribe == 0">-->
<!--                              <img class="ml-0" height="16px" src="/dist/images/frontend/del_person.svg" alt />-->
<!--                              <span>Cancel request</span>-->
<!--                          </v-btn>-->
                          <BaseButton
                              :loading="submitDisabled"
                              class_btn="btn-liteGrey"
                              @click="submitSubscribe(2)"
                              v-else-if="profile.subscribe == 0"
                          >
                            <span>
<!--                              <img class="ml-0" height="16px" src="/dist/images/frontend/del_person.svg" alt />-->
                              {{__('Cancel request')}}
                            </span>
                          </BaseButton>
<!--                        <v-btn :loading="submitDisabled" class="btn-delete" @click="submitSubscribe(3)" text v-ripple="{ class:'secondary&#45;&#45;text'}"  v-else-if="profile.subscribe == 1">-->
<!--                            <img class="ml-0" height="16px" src="/dist/images/frontend/del_person.svg" alt />-->
<!--                            <span>Remove from friends</span>-->
<!--                        </v-btn>-->
<!--                        <v-btn :loading="submitDisabled" class="btn-delete" v-on:click="open_modal= true" text   v-else-if="profile.subscribe == 1">-->
<!--                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                              <path d="M10.0511 7.02486C9.78206 6.89697 9.50756 6.7868 9.22824 6.69452C10.1329 6.01552 10.7191 4.93432 10.7191 3.71875C10.7191 1.66824 9.05089 0 7.00036 0C4.94982 0 3.28161 1.66824 3.28161 3.71875C3.28161 4.93566 3.86917 6.01792 4.77548 6.69678C3.9451 6.97017 3.16594 7.39556 2.48399 7.95648C1.2335 8.98507 0.364055 10.4198 0.0358479 11.9963C-0.0670193 12.4903 0.056219 12.9977 0.373899 13.3883C0.69002 13.7771 1.15877 14 1.65993 14H8.39489C8.69693 14 8.94176 13.7552 8.94176 13.4531C8.94176 13.1511 8.69693 12.9062 8.39489 12.9062H1.65993C1.42647 12.9062 1.28562 12.7759 1.22246 12.6983C1.11338 12.5642 1.07116 12.3896 1.10663 12.2192C1.67415 9.4932 4.07865 7.50187 6.85538 7.43471C6.90348 7.43657 6.95179 7.4375 7.00036 7.4375C7.04938 7.4375 7.09819 7.43654 7.14675 7.43466C7.99769 7.45462 8.81609 7.64881 9.58155 8.0127C9.85436 8.14231 10.1806 8.02635 10.3102 7.75354C10.4399 7.48076 10.3239 7.15452 10.0511 7.02486ZM7.13374 6.34039C7.08936 6.33959 7.0449 6.33918 7.00036 6.33918C6.95622 6.33918 6.91209 6.33962 6.86801 6.34044C5.48182 6.27129 4.37536 5.12181 4.37536 3.71875C4.37536 2.27131 5.55292 1.09375 7.00036 1.09375C8.4478 1.09375 9.62536 2.27131 9.62536 3.71875C9.62536 5.12146 8.51944 6.27071 7.13374 6.34039Z" fill="currentColor"/>-->
<!--                              <path d="M11.9492 9.85547H13.4531C13.7552 9.85547 14 10.1003 14 10.4023C14 10.7044 13.7552 10.9492 13.4531 10.9492H11.9492H10.8555H9.35156C9.04952 10.9492 8.80469 10.7044 8.80469 10.4023C8.80469 10.1003 9.04952 9.85547 9.35156 9.85547H10.8555H11.9492Z" fill="currentColor"/>-->
<!--                            </svg>-->
<!--                            <span>Remove from friends</span>-->
<!--                        </v-btn>-->
                          <BaseButton
                            :loading="submitDisabled"
                            class_btn="btn-liteGrey"
                            v-on:click="open_modal= true"
                            v-else-if="profile.subscribe == 1">
                            <span>
<!--                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                              <path d="M10.0511 7.02486C9.78206 6.89697 9.50756 6.7868 9.22824 6.69452C10.1329 6.01552 10.7191 4.93432 10.7191 3.71875C10.7191 1.66824 9.05089 0 7.00036 0C4.94982 0 3.28161 1.66824 3.28161 3.71875C3.28161 4.93566 3.86917 6.01792 4.77548 6.69678C3.9451 6.97017 3.16594 7.39556 2.48399 7.95648C1.2335 8.98507 0.364055 10.4198 0.0358479 11.9963C-0.0670193 12.4903 0.056219 12.9977 0.373899 13.3883C0.69002 13.7771 1.15877 14 1.65993 14H8.39489C8.69693 14 8.94176 13.7552 8.94176 13.4531C8.94176 13.1511 8.69693 12.9062 8.39489 12.9062H1.65993C1.42647 12.9062 1.28562 12.7759 1.22246 12.6983C1.11338 12.5642 1.07116 12.3896 1.10663 12.2192C1.67415 9.4932 4.07865 7.50187 6.85538 7.43471C6.90348 7.43657 6.95179 7.4375 7.00036 7.4375C7.04938 7.4375 7.09819 7.43654 7.14675 7.43466C7.99769 7.45462 8.81609 7.64881 9.58155 8.0127C9.85436 8.14231 10.1806 8.02635 10.3102 7.75354C10.4399 7.48076 10.3239 7.15452 10.0511 7.02486ZM7.13374 6.34039C7.08936 6.33959 7.0449 6.33918 7.00036 6.33918C6.95622 6.33918 6.91209 6.33962 6.86801 6.34044C5.48182 6.27129 4.37536 5.12181 4.37536 3.71875C4.37536 2.27131 5.55292 1.09375 7.00036 1.09375C8.4478 1.09375 9.62536 2.27131 9.62536 3.71875C9.62536 5.12146 8.51944 6.27071 7.13374 6.34039Z" fill="currentColor"/>-->
<!--                              <path d="M11.9492 9.85547H13.4531C13.7552 9.85547 14 10.1003 14 10.4023C14 10.7044 13.7552 10.9492 13.4531 10.9492H11.9492H10.8555H9.35156C9.04952 10.9492 8.80469 10.7044 8.80469 10.4023C8.80469 10.1003 9.04952 9.85547 9.35156 9.85547H10.8555H11.9492Z" fill="currentColor"/>-->
<!--                            </svg>-->
                              {{__('Remove from friends')}}
                            </span>
                          </BaseButton>
<!--                        <v-btn :loading="submitDisabled" class="btn-delete" @click="submitSubscribe(1)" text   v-else-if="profile.subscribe == 3">-->
<!--&lt;!&ndash;                            <img class="ml-0" height="16px" src="/dist/images/frontend/del_person.svg" alt />&ndash;&gt;-->
<!--                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M10.0511 7.02486C9.78206 6.89697 9.50756 6.7868 9.22824 6.69452C10.1329 6.01552 10.7191 4.93432 10.7191 3.71875C10.7191 1.66824 9.05089 0 7.00036 0C4.94982 0 3.28161 1.66824 3.28161 3.71875C3.28161 4.93566 3.86917 6.01792 4.77548 6.69678C3.9451 6.97017 3.16594 7.39556 2.48399 7.95648C1.2335 8.98507 0.364055 10.4198 0.0358479 11.9963C-0.0670193 12.4903 0.056219 12.9977 0.373899 13.3883C0.69002 13.7771 1.15877 14 1.65993 14H8.39489C8.69693 14 8.94176 13.7552 8.94176 13.4531C8.94176 13.1511 8.69693 12.9062 8.39489 12.9062H1.65993C1.42647 12.9062 1.28562 12.7759 1.22246 12.6983C1.11338 12.5642 1.07116 12.3896 1.10663 12.2192C1.67415 9.4932 4.07865 7.50187 6.85538 7.43471C6.90348 7.43657 6.95179 7.4375 7.00036 7.4375C7.04938 7.4375 7.09819 7.43654 7.14675 7.43466C7.99769 7.45462 8.81609 7.64881 9.58155 8.0127C9.85436 8.14231 10.1806 8.02635 10.3102 7.75354C10.4399 7.48076 10.3239 7.15452 10.0511 7.02486ZM7.13374 6.34039C7.08936 6.33959 7.0449 6.33918 7.00036 6.33918C6.95622 6.33918 6.91209 6.33962 6.86801 6.34044C5.48182 6.27129 4.37536 5.12181 4.37536 3.71875C4.37536 2.27131 5.55292 1.09375 7.00036 1.09375C8.4478 1.09375 9.62536 2.27131 9.62536 3.71875C9.62536 5.12146 8.51944 6.27071 7.13374 6.34039Z" fill="currentColor"/>-->
<!--                                <path d="M13.4531 10.8555H11.9492V9.35156C11.9492 9.04952 11.7044 8.80469 11.4023 8.80469C11.1003 8.80469 10.8555 9.04952 10.8555 9.35156V10.8555H9.35156C9.04952 10.8555 8.80469 11.1003 8.80469 11.4023C8.80469 11.7044 9.04952 11.9492 9.35156 11.9492H10.8555V13.4531C10.8555 13.7552 11.1003 14 11.4023 14C11.7044 14 11.9492 13.7552 11.9492 13.4531V11.9492H13.4531C13.7552 11.9492 14 11.7044 14 11.4023C14 11.1003 13.7552 10.8555 13.4531 10.8555Z" fill="currentColor"/>-->
<!--                              </svg>-->
<!--                            <span>Сonfirm request</span>-->
<!--                        </v-btn>-->
                          <BaseButton
                              :loading="submitDisabled"
                              class_btn="btn-liteGrey"
                              @click="submitSubscribe(1)"
                              v-else-if="profile.subscribe == 3">
                            <span>
<!--                              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M10.0511 7.02486C9.78206 6.89697 9.50756 6.7868 9.22824 6.69452C10.1329 6.01552 10.7191 4.93432 10.7191 3.71875C10.7191 1.66824 9.05089 0 7.00036 0C4.94982 0 3.28161 1.66824 3.28161 3.71875C3.28161 4.93566 3.86917 6.01792 4.77548 6.69678C3.9451 6.97017 3.16594 7.39556 2.48399 7.95648C1.2335 8.98507 0.364055 10.4198 0.0358479 11.9963C-0.0670193 12.4903 0.056219 12.9977 0.373899 13.3883C0.69002 13.7771 1.15877 14 1.65993 14H8.39489C8.69693 14 8.94176 13.7552 8.94176 13.4531C8.94176 13.1511 8.69693 12.9062 8.39489 12.9062H1.65993C1.42647 12.9062 1.28562 12.7759 1.22246 12.6983C1.11338 12.5642 1.07116 12.3896 1.10663 12.2192C1.67415 9.4932 4.07865 7.50187 6.85538 7.43471C6.90348 7.43657 6.95179 7.4375 7.00036 7.4375C7.04938 7.4375 7.09819 7.43654 7.14675 7.43466C7.99769 7.45462 8.81609 7.64881 9.58155 8.0127C9.85436 8.14231 10.1806 8.02635 10.3102 7.75354C10.4399 7.48076 10.3239 7.15452 10.0511 7.02486ZM7.13374 6.34039C7.08936 6.33959 7.0449 6.33918 7.00036 6.33918C6.95622 6.33918 6.91209 6.33962 6.86801 6.34044C5.48182 6.27129 4.37536 5.12181 4.37536 3.71875C4.37536 2.27131 5.55292 1.09375 7.00036 1.09375C8.4478 1.09375 9.62536 2.27131 9.62536 3.71875C9.62536 5.12146 8.51944 6.27071 7.13374 6.34039Z" fill="currentColor"/>-->
<!--                                <path d="M13.4531 10.8555H11.9492V9.35156C11.9492 9.04952 11.7044 8.80469 11.4023 8.80469C11.1003 8.80469 10.8555 9.04952 10.8555 9.35156V10.8555H9.35156C9.04952 10.8555 8.80469 11.1003 8.80469 11.4023C8.80469 11.7044 9.04952 11.9492 9.35156 11.9492H10.8555V13.4531C10.8555 13.7552 11.1003 14 11.4023 14C11.7044 14 11.9492 13.7552 11.9492 13.4531V11.9492H13.4531C13.7552 11.9492 14 11.7044 14 11.4023C14 11.1003 13.7552 10.8555 13.4531 10.8555Z" fill="currentColor"/>-->
<!--                              </svg>-->
                              {{__('Сonfirm request')}}
                            </span>
                          </BaseButton>
<!--                        <v-btn class="btn-send"  v-bind:to="{name: 'chat', params: {id: user.public_id}}">-->
<!--                            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M15.75 12.75H8.25C7.836 12.75 7.5 13.086 7.5 13.5C7.5 13.914 7.836 14.25 8.25 14.25H15.75C16.164 14.25 16.5 13.914 16.5 13.5C16.5 13.086 16.164 12.75 15.75 12.75ZM17.25 8.25H6.75C6.336 8.25 6 8.586 6 9C6 9.414 6.336 9.75 6.75 9.75H17.25C17.664 9.75 18 9.414 18 9C18 8.586 17.664 8.25 17.25 8.25ZM12 0C5.37302 0 0 4.70098 0 10.5C0 13.8143 1.75875 16.7647 4.5 18.6893V24L9.75675 20.8102C10.4843 20.9303 11.2327 21 12 21C18.6278 21 24 16.299 24 10.5C24 4.70098 18.6278 0 12 0ZM12 19.5C11.124 19.5 10.278 19.398 9.46498 19.2247L5.93396 21.3472L5.98122 17.8687C3.27451 16.2405 1.5 13.5495 1.5 10.5C1.5 5.52977 6.20098 1.5 12 1.5C17.799 1.5 22.5 5.52977 22.5 10.5C22.5 15.4702 17.799 19.5 12 19.5Z" fill="currentColor"/>-->
<!--                            </svg>-->
<!--                            <span>Send a message</span>-->
<!--                        </v-btn>-->
                        <BaseButton
                            class="ml-3"
                            class_btn="btn-liteGrey"
                            @click="sendMess"
                        >
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M8.00003 9.50003H15M8.00003 13.5H13M15.6953 19.2318L19.1027 20.3676C19.8845 20.6282 20.6282 19.8844 20.3676 19.1027L19.2318 15.6953M15.3 19.1C15.3 19.1 14.0847 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5C20 14 19.1 15.3 19.1 15.3" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
<!--                            Send a message-->
                          </span>
                        </BaseButton>
                        </div>

                    </div>
<!--                    <div class="block-social" v-if="my_profile">-->
<!--                        <v-btn :class="(user.data[item.name]) ? 'soc-btn-active' : 'soc-btn'"   text   v-for="(item, key) in $socials" target="_blank" :key="key" tag="a" :href="user.data[item.name]">-->
<!--                            <span class="btn-social" :class="item.name"><img :src="'/dist/images/frontend/'+ item.name +'.svg'" alt=""></span>-->
<!--                        </v-btn>-->
<!--                    </div>-->
<!--                    <div class="block-social-friend" v-else-if="getSocials">-->
<!--                        <v-btn  class="soc-btn"  text   v-for="(social, key) in  getSocials" target="_blank" :key="key" tag="a" :href="social.link">-->
<!--                            <span class="btn-social" :class="social.social"><img :src="'/dist/images/frontend/'+social.social+'.svg'" alt=""></span>-->
<!--                        </v-btn>-->
<!--                    </div>-->
            </div>
        </div>
        <ModalConfirmUnfriend
                v-bind:profile.sync="profile"
                v-if="open_modal"
                v-bind:open_modal.sync="open_modal"
                @submitUnfriend="submitSubscribe(3)">
        </ModalConfirmUnfriend>
    </div>
</template>

<script>
    import UploadAvatar from '../../modules/UploadAvatar';
    import {decimal, required} from "vuelidate/lib/validators";
    import moment from 'moment';
    import ModalConfirmUnfriend from "../../modules/ModalConfirmUnfriend"

    export default {
        name: 'ProfileHeaderInfo',
        data: function() {
          return {
              now_date: moment.utc(new Date()).format('X'),
              date: (this.profile.online) ? moment.utc(this.profile.online).format('X') : null,
              open_modal: false,
              loadingReadyPlay: false,
              ready_play: 0,
              show: false,
              submitDisabled: false,
              strTime: "min",
              marqueePaused: true,
              changeStatus: false,
              status: this.profile.user.data.status,
              user: this.profile.user,
              inputStatusReadonly: false,
              responseStatusUpdate: null,
              response_upload: null,
              form: {
                  amount: this.edit_bid ? this.edit_bid.amount : 10,
                  wallet: this.edit_bid ? this.edit_bid.wallet : 1,
              },
              formValidate: {
                  amount: null,
              },
          }
        },
        validations: {
            form: {
                amount: {
                    decimal,
                    required,
                },
            },
        },
        props: {
          profile: {
              type: Object
          },
          my_profile: {
              type: Boolean
          },
          class_input: {
              type: String
          },
        },
        computed: {
            auth() {
              return this.$store.state.auth.user
            },
            getSocials() {
                let socials= this.$socials;
                let userSocials= [];

                for(let x=0; x<socials.length; x++) {
                    let social= socials[x].name;
                    if(this.user.data[social]) {
                        userSocials.push({
                            'social': social,
                            'link': this.user.data[social]
                        });
                    }
                }
                return userSocials;
            },
            timestamp_ago() {
                return moment.utc(this.profile.online).format('X');
            },
            page: function () {
                return this.$route.name;
            },
            updateMess() {
                return this.$store.state.preference.updateMess
            },

        },
        watch: {
            response_upload: function (payload) {
                let profile= this.profile,
                    auth= this.auth;

                profile.user.photo= this.user.photo= auth.photo= payload.logo;

                this.$emit('update:profile', profile);
                this.$store.commit('updateUserInfo', auth);
            },
            amount() {
                this.formValidate.amount = [];
            },
        },
        mounted() {
            if(this.$refs.marquee) {
                if(this.$refs.marqueeContent.offsetWidth < this.$refs.marquee.offsetWidth) {
                    this.marqueePaused= false;
                }
            }
        },
        methods: {
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');
            },
            sendMess() {
                this.$messOpen(true);
                this.$router.push({name: 'chat', params: {id: this.user.public_id, lang: this.$store.getters.getLang}});
            },
            focusStatus: function () {
                this.changeStatus= true;
                this.$nextTick(() => {
                    this.$refs.inputStatus.focus();
                });
            },
            updateStatus: function () {
                this.inputStatusReadonly= true;
                let response= {
                    'text': this.status
                };
                this.responseStatusUpdate= 'loading';

                this.$http.post('ajax/user_info/status', response)
                    .then(response=> {
                        return response.json()
                    })
                    .then((response)=> {
                        if(this.$checkAuth(response)) {
                            this.responseStatusUpdate = 'success';
                            this.responseStatus();
                        }
                    }).catch(err => {
                        this.responseStatusUpdate= 'error';
                        this.responseStatus();
                        this.httpHandler(err);
                    });

            },
            responseStatus: function () {
                this.changeStatus= false;
                this.marqueePaused= true;
                this.inputStatusReadonly= false;


                this.$nextTick(() => {
                    if(this.$refs.marquee) {
                        if(this.$refs.marqueeContent.offsetWidth < this.$refs.marquee.offsetWidth+100) {
                            this.marqueePaused= false;
                        }
                    }
                });

                setTimeout(()=> {
                    this.responseStatusUpdate= null;
                }, 3000);
            },
            submitSubscribe: function (type) {
                this.submitDisabled = true;
                let response= {
                    public_id: this.profile.user.public_id,
                    type: type,
                }
                this.$http.post("ajax/friends/subscribe", response)
                .then((response) => {
                     return response.json();
                })
                .then((response)=> {
                    if(this.$checkAuth(response)) {
                        // response= {
                        //     "data": {
                        //         "friends": 2
                        //     },
                        //     "status": 200
                        // }
                        let profile = this.profile;
                        profile['subscribe'] = response.data.friends;
                        this.$emit("update:profile", profile);
                        this.submitDisabled = false
                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                    this.submitDisabled = false
                });
            },
            waitingStatus: function () {
                // let response= {
                //     type: (this.ready_play) ? 1 : 0,
                // }
                this.loadingReadyPlay= true;
                this.$http.post("ajax/user_info/ready_play")
                    .then((response) => {
                        return response.json();
                    })
                    .then((response)=> {
                        if(this.$checkAuth(response)) {
                            this.$store.dispatch('asyncReadyPlay', (this.ready_play) ? 1 : 0);
                            this.loadingReadyPlay = false;
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                        this.loadingReadyPlay= true;
                    });
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.form[type],
                    type
                );
            },
            nameWithLang ({ name, language }) {
                return `${name} — [${language}]`
            },
        },
        asyncComputed: {
            async_auth() {
                let auth = this.auth;

                if (auth) {
                    this.ready_play = (auth.ready_play == 1) ? 1 : 0;
                }
                return true;
            }
        },
        components: {
            UploadAvatar,
            ModalConfirmUnfriend,
        },
    }
</script>


<style lang="scss" scoped>

  .user-top .user-name {
    display: inline-block;
  }
  .user-top .user-name:first-letter {
      color: #FA8C00;
  }

  .success--text .status {
    background: #4caf50;
  }
  .red--text .status {
    background: red;
  }

  .dark {
      .block-info {
          background: transparent;

          &__head {
              background: none;
          }

          &__head-wrap {
              .status {
                  background: rgba(38, 169, 244, 0.15);
              }
          }

          &__person-wrap {
              h2,
              h3 {
                  color: #fff;
              }
          }
      }
  }

    .block-info {
      /*background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.9) 38.42%, rgba(255, 255, 255, 0.5) 99.34%);*/
      background-color: #fff;
      border-radius: 4px;
      z-index: 10;

      &__head {
          border-radius: 6px 0 0 0;
          height: 53px;
          z-index: 99;
          //border-radius: 6px 6px 0px 0px;
          padding: 0 20px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: linear-gradient(90.1deg, #FFFFFF 0.12%, rgba(255, 255, 255, 0) 99.96%);
          -webkit-backdrop-filter: blur(3px);
          -moz-backdrop-filter: blur(3px);
          -ms-backdrop-filter: blur(3px);
          -o-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
      }

      &__head-wrap {
        height: 100%;
        width: 100%;
        //margin-bottom: 2px;
        display:flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 118px;
        flex-wrap: wrap;
        //border-bottom: 1px solid #F1F2F9;

        .status {
            background-color: #FFFFFF;
            padding: 4px 6px 4px 21px;
            border-radius: 6px;
            /*margin-bottom: 8px;*/
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            letter-spacing: 0.01em;
            margin-bottom: 0;
            position: relative;


            .dot {
                height: 10px;
                font-size: 30px;
                position: absolute;
                left: 4px;
                top: 3px;
            }
        }
      }

        &__person-wrap {

          h2 {
              font-weight: 400;
              font-size: 22px;
              line-height: 30px;
              color: #002B50;
              &:first-letter {
                  color: #FA8C00;
              }
          }
            h3 {
                font-weight: 400;
                font-size: 22px;
                line-height: 30px;
                color: #002B50;
            }
          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /*text-align: right;*/
            letter-spacing: 0.01em;
            color: #96AFD1;
            margin-bottom:5px;

              .flag {
                  border-radius: 1px;
              }
          }
        }

        &__person-rating {
            margin-top: 5px;
            font-weight: 400;
            font-size: 14px;
            color: #F6A401;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 17px;


            img {
                margin-right: 3px;
                margin-bottom: 3px;
            }
        }

        &__person-statistic {
            margin-top: 5px;
            font-weight: 400;
            font-size: 14px;
            color: #26A9F4;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 13px;

            .pic {
                margin-right: 4px;
                width: 18px;
                margin-bottom: 2px;
            }
        }

        &__body {
          display:flex;
          align-items: center;
          //border-radius: 0px 0px 8px 8px ;
          padding: 0 10px 0 0;
          //border-radius: 0 0 0 6px;
          //background-color: #fff;


          .block-img {
              width: 80px;
              height: 80px;
            border-radius: 4px;
            //box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
            cursor: pointer;
            position: relative;

              img {
                  border-radius: 50%;
                  width: 80px;
                  height: 80px;
                  background-color: red;
              }

              &--friend {
                  cursor: default;
              }

              .location {
                  position: absolute;
                  bottom: 1px;
                  right: 3px;


                  img {
                      border-radius: 2px;
                      border: 1px solid #FFFFFF;
                      width: 18px;
                      height: fit-content;
                  }
              }


            .img-upload {
            width: 108px;
            height: 108px;
            border-radius: 4px;


                img {
                   border-radius: 4px;
                   width: 108px;
                   height: 108px;
                }
            }

            &__avatar {
                border-radius: 4px;
                width: 108px;
                height: 108px;
                cursor: default;
            }

            p {
              font-size: 20px;
              line-height: 24px;
              color: #F6A401;
              margin-bottom: 0;
              margin-top: 11px;
            }

              .photo {
                  width: 108px;
                  height: 108px;
                  border-radius: 4px;
                  display: flex;
                  color: #FFFFFF;
                  justify-content: center;
                  align-items: center;
                  text-decoration: none;
                  font-size: 50px;
                  font-weight: bold;
                  text-transform: uppercase;
              }
          }

            .wrap-btn {
                /*margin-top: 4px;*/
                display: flex;
                align-items: center;
            }

          .block-user_pick {
              /*width: calc(73% - 122px);*/
              width: 100%;
              margin-left: 10px;



              .user_pick-link {
                  margin-left: 15px;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  color: #002B50;
                  width: 100%;
                  max-width: 350px;
                  min-width: 340px;


                  a {
                      font-size: 14px;
                      line-height: 17px;
                      color: #46BCFF;
                      text-decoration: none;

                      &:hover {
                          text-decoration: underline;
                      }
                  }
              }

              .block-link-friend {
                  display: flex;
                  align-items: center;
              }

              .block-social-friend {
                  display: flex;
                  align-items: center;
                  margin-left: 20px;
                  justify-content: flex-end;
                  margin-top: 4px;

                  .soc-btn {
                      /*background: #F0F6FE;*/
                      background: transparent;
                      border-radius: 6px;
                      width: 40px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      opacity: 1;
                      transition-duration: .3s;

                      span {
                          display: flex;
                          align-items: center;
                      }

                      &:hover {
                          transition-duration: .3s;
                          background-color: #E7EFFF;
                      }

                      &.v-btn:not(.v-btn--round).v-size--default {
                          height: 40px;
                          min-width: 40px;
                          padding: 0;
                          margin: 0 5px;
                      }
                  }
              }

              .block-social {
                  display: flex;
                  align-items: center;
                  margin-left: 20px;
                  justify-content: flex-end;
                  margin-top: 4px;


                .soc-btn-active {
                    display: flex;
                    align-items: center;
                    /*background: #F0F6FE;*/
                    background: transparent;
                    border-radius: 6px;
                    width:40px;
                    height: 40px;
                    opacity: 1;
                    transition-duration: .3s;

                    span {
                        display: flex;
                        align-items: center;
                    }
                    &:hover {
                        transition-duration: .3s;
                        background-color: #E7EFFF;
                    }

                    &.v-btn:not(.v-btn--round).v-size--default {
                        height: 40px;
                        min-width: 40px;
                        padding: 0;
                        margin: 0 5px;
                    }
                }
                .soc-btn {
                    display: flex;
                    align-items: center;
                    /*background: #F0F6FE;*/
                    background: transparent;
                    border-radius: 6px;
                    width:40px;
                    height: 40px;
                    opacity: 0.2;
                    transition-duration: .3s;

                    span {
                        display: flex;
                        align-items: center;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &.v-btn:not(.v-btn--round).v-size--default {
                        height: 40px;
                        min-width: 40px;
                        padding: 0;
                        margin:0 5px;
                    }
                }
              }
          }   
        }

    .v-btn.btn-send {
        display: flex;
        align-items: center;
        background: #F0F6FE;
        border-radius: 6px;
        text-transform: inherit;
        font-weight: 400;
        font-size: 14px;
        /*line-height: 40px;*/
        letter-spacing: 0em;
        box-shadow: none;
        padding: 0;
        min-width: 206px;
        outline: none;
        color: #6886AF;
        margin-right: 10px;
        transition-duration: .3s;
        margin-left: 10px;

        svg {
            margin:2px 8px 0 0;
        }

        &:hover {
          transition-duration: .3s;
          background-color: #E7EFFF;
          color: #26a9f4;
        }


        &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 100px;
            padding: 0 15px;
        }
    }
    .v-btn.btn-profile {
        background: #4EBEFF;
        border-radius: 6px;
        text-transform: inherit;
        color: #fff;
        outline: none;
        transition-duration: .3s;

        &:first-child {
            margin-right: 10px;
        }

        img {
            height: 14px;
            width: 14px;
            margin-right: 8px;
        }

        &:hover {
            transition-duration: .3s;
            background-color: #26A9F4;
        }

        &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 150px;
            padding: 0 15px;
        }
    }

     .btn-add {
         border-radius: 6px;
         margin-right: 10px;
     }

    .btn-delete {
      background: #F0F6FE;
      text-transform: inherit;
      color: #6886AF;
      outline: none;
      border-radius: 6px;
      transition-duration: .3s;

        svg {
            margin-right: 8px;
        }

      &:hover {
        transition-duration: .3s;
        background-color: #E7EFFF;
        color: #26a9f4;
      }
        &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 100px;
            padding: 0 15px;
        }

    }


  .btn-complaint {
      margin-top: 10px;
      display: block;
      background: #F0F6FE;
      border-radius: 6px;
      height: 20px;
      max-width: 180px;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #6886AF;
      text-transform: inherit;
      letter-spacing: normal;
      outline: none;

      &:hover {
          background: #E7EFFF;
      }

      &.v-btn:not(.v-btn--round).v-size--default {
          height: 20px;
          max-width: 180px;
          padding: 0;
      }
  }

}
    .wrap-select {
        margin-right: 6px;
    }

  input[type=checkbox]{
      height: 0;
      width: 0;
      visibility: hidden;
  }

  label {
      cursor: pointer;
      text-indent: -9999px;
      width: 50px;
      height: 26px;
      background: #F0F6FE;
      display: block;
      border-radius: 20px;
      position: relative;
  }

  label:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 22px;
      height: 22px;
      background: #fff;
      border-radius: 34px;
      transition: 0.3s;
  }

  input:checked + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
      background: #F6A401;
  }

  label:active:after {
      width: 28px;
  }

  .switch {
      display: flex;
      align-items: center;
      margin-left: 18px;
      position: relative;
      span {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #002B50;
          margin-right: 12px;

      }

      img {
          cursor: pointer;
      }

      .v-btn {
          margin-left: 2px;
          outline: none;
          &.v-btn:before {
              background-color: transparent;
          }

          &.v-btn--icon.v-size--default {
              height: 20px;
              width: 20px;
          }
      }
  }
  .block-desc {
    display: block;
    z-index: 1;
    font-size: 13px;
    line-height: 18px;
    color: #002B50;
    padding: 10px;
    background: rgb(255, 255, 255);
    border: 1px solid #ECF4FF;
    border-radius: 6px;
    max-width: 280px;
  }

  .switch-text {
      display: flex;
      align-items: center;
      p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
          margin-left: 10px;
          margin-bottom: 0;
      }
  }

  @media screen and (max-width: 1264px) {
    .block-info {

      .block-user_pick {
        width: calc(100% - 122px);
        margin-left: 10px;
      }
    }
  }



    @media screen and (max-width: 1200px) {

        .block-info {

            .v-btn.btn-profile {
                font-size: 12px;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 36px;
                }
            }
            .block-user_pick {
                .block-social-friend {
                    .soc-btn {
                        width: 36px;
                        height: 36px;
                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 36px;
                            min-width: 36px;
                            margin: 0 3px;
                        }
                    }
                }

                .block-social {
                    .soc-btn-active {
                        width: 36px;
                        height: 36px;
                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 36px;
                            min-width: 36px;
                            margin: 0 3px;
                        }
                    }
                    .soc-btn {
                        width: 36px;
                        height: 36px;
                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 36px;
                            min-width: 36px;
                            margin: 0 3px;
                        }
                    }
                }
            }

            .v-btn.btn-send {
                font-size: 12px;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 36px;
                }
            }

            .btn-add {
                font-size: 12px;

                img {
                    height: 14px;
                }

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 36px;
                    padding: 0 15px;
                }
            }

            .btn-del {
                font-size: 12px;

                img {
                    height: 14px;
                }

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 36px;
                }
            }
        }
    }

  @media screen and (max-width: 1024px) {
        .block-info {
            .v-btn.btn-profile {
                &:first-child {
                    width: 40px;
                    margin-right: 10px;

                    span {
                        display: none;
                    }

                    img {
                        margin: 0;
                    }
                }
                &.v-btn:not(.v-btn--round).v-size--default {
                    min-width: 40px;
                    padding: 0 10px;
                }
            }

            .v-btn.btn-send {
                span {
                    display: none;
                }

                img {
                    margin: 0;
                }
                &.v-btn:not(.v-btn--round).v-size--default {
                    min-width: 40px;
                    padding: 0 10px;
                }
            }
        }
  }



  @media screen and (max-width: 815px) {
    .block-info {
        .block-user_pick {
            .block-social-friend {
                margin-top: 0;
                align-items: start;
                .soc-btn {
                    width: 36px;
                    height: 36px;
                    border-radius: 6px;

                    img {
                        height: 16px;
                    }
                    &.v-btn:not(.v-btn--round).v-size--default {
                        height: 36px;
                        min-width: 36px;
                        margin: 0 2px;
                    }
                }
            }
        }
    }
  }
    /*@media screen and (max-width: 740px) {
        .block-info {
            .block-user_pick {
                .block-social-friend {
                    .soc-btn {
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;

                        img {
                            height: 8px;
                        }
                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 30px;
                            min-width: 30px;
                            margin: 0 2px;
                        }
                    }
                }

                .block-social {
                    .soc-btn-active {
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                        img {
                            height: 14px;
                        }
                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 30px;
                            min-width: 30px;
                            margin: 0 2px;
                        }
                    }
                    .soc-btn {
                        width: 30px;
                        height: 30px;
                        border-radius: 4px;
                        img {
                            height: 14px;
                        }
                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 30px;
                            min-width: 30px;
                            margin: 0 2px;
                        }
                    }
                }
            }

            &__head-wrap {
                margin-left: 116px;
                .status {
                    font-size: 10px;
                }
            }

            .v-btn.btn-profile {
                font-size: 10px;

                &:first-child {
                    width: 30px;
                }

                &:last-child {
                    img {
                        margin-right: 4px;
                    }
                }

                img {
                    height: 12px;
                }

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    min-width: 35px;

                }
            }

            &__head {
                height: 55px;
            }

            &__body {
                .block-img {
                    margin-top: -53px;
                    width: 100px;
                    height: 100px;
                    border-radius: 6px;
                    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
                    cursor: pointer;


                    .img-upload {
                        width: 100px;
                        height: 100px;
                        border-radius: 6px;


                        img {
                            border-radius: 6px;
                            width: 100px;
                            height: 100px;
                        }
                    }
                }
            }
            &__person-rating {
                font-size: 14px;
                margin-left: 14px;

                img {
                    height: 14px;
                    margin-right: 4px;
                }
            }

            &__person-wrap {

                h2 {
                    font-size: 18px;
                    line-height: 28px;
                }
            }

            .v-btn.btn-send {

                img {
                    height: 14px;
                }

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    min-width: 35px;
                    padding: 0 10px;
                }
            }

            .btn-add {
                font-size: 10px;

                img {
                    height: 10px;
                    margin-right: 5px;
                }

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    padding: 0 10px;
                }
            }

            .btn-del {
                font-size: 10px;

                img {
                    height: 10px;
                    margin-right: 5px;
                }

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    padding: 0 10px;
                }
            }
        }
    }*/

    @media screen and (max-width: 560px) {
        .block-info {
            .v-btn.btn-profile {
                border-radius: 4px;
                &:first-child {
                    margin-right: 4px;
                }
                &:last-child {
                    width: 40px;
                    margin-right: 10px;

                    span {
                        display: none;
                    }

                    img {
                        margin: 0;
                    }
                }


                &.v-btn:not(.v-btn--round).v-size--default {
                    min-width: 40px;
                    padding: 0 10px;
                }
            }
        }
    }

    @media screen and (max-width: 525px) {
        .block-info {

            &__head-wrap {
                margin-left: 100px;
                .status {
                    font-size: 8px;
                    /*margin-bottom: 5px;*/
                    padding-left: 18px;
                    padding-right: 6px;

                    .dot {
                        font-size: 30px;
                    }
                }
            }

            &__person-wrap {
                h2 {
                    font-size: 16px;
                    line-height: 26px;
                }
            }

            &__head {
                height: 46px;
            }

            &__body {
                .block-img {
                    margin-top: -44px;
                    width: 90px;
                    height: 90px;
                    border-radius: 6px;
                    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
                    cursor: pointer;


                    .img-upload {
                        width: 90px;
                        height: 90px;
                        border-radius: 6px;


                        img {
                            border-radius: 6px;
                            width: 90px;
                            height: 90px;
                        }
                    }
                }
            }

            .block-user_pick {
                margin-left: 10px;
            }

            &__person-rating {
                font-size: 12px;

                img {
                    height: 11px;
                    margin-right: 3px;
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .block-info {

            &__head-wrap {
                margin-left: 90px;

                .status {
                    border-radius: 4px;
                    padding: 2px 6px 2px 15px;
                    font-size: 6px;

                    .dot {
                        top: 1px;
                        font-size: 25px;
                    }
                }
            }
            .block-user_pick {
                .block-social-friend {
                    align-items: start;

                    .soc-btn {
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;

                        img {
                            height: 8px;
                        }

                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 20px;
                            min-width: 20px;
                            margin: 0 2px;
                        }
                    }
                }

                .block-social {
                    align-items: start;

                    .soc-btn-active {
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;

                        img {
                            height: 10px;
                        }

                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 20px;
                            min-width: 20px;
                            margin: 0 2px;
                        }
                    }

                    .soc-btn {
                        width: 20px;
                        height: 20px;
                        border-radius: 4px;

                        img {
                            height: 10px;
                        }

                        &.v-btn:not(.v-btn--round).v-size--default {
                            height: 20px;
                            min-width: 20px;
                            margin: 0 2px;
                        }
                    }
                }
            }

            &__head {
                height: 42px;
            }
            &__body {
                .block-img {
                    margin-top: -40px;
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
                    cursor: pointer;


                    .img-upload {
                        width: 80px;
                        height: 80px;
                        border-radius: 6px;


                        img {
                            border-radius: 6px;
                            width: 80px;
                            height: 80px;
                        }
                    }
                }
            }

            .v-btn.btn-profile {
                border-radius: 4px;
                &:first-child {
                    margin-right: 4px;
                }
                &:last-child {
                    width: 35px;
                    margin-right: 0;
                }


                &.v-btn:not(.v-btn--round).v-size--default {
                    min-width: 35px;
                    padding: 0 5px;
                }
            }

            &__person-wrap {

                h2 {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            &__person-rating {
                font-size: 10px;
                margin-left: 10px;

                img {
                    height: 9px;
                    margin-right: 3px;
                }
            }
        }
    }

    @media screen and (max-width: 425px) {
        .block-info {

        }
    }
</style>

