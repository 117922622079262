<template>
    <div class="partners-page">

        <div class="header">
            <div class="content">
                <div class="top">
                    <h1 class="name-page">{{__('For partners')}} </h1>
                    <h2>{{__('Take full advantage of the affiliate program')}}</h2>
                    <p class="text-top">{{__('Get rewarded for promoting and helping to develop the platform')}} </p>
                </div>

                <div class="btn-header">
                    <BaseButton class_btn="btn-video" class="btn-link-nav" tag="a" :href="(lang == 'ru') ? 'https://mindplays.com/Whitepaper_ru.pdf' : 'https://mindplays.com/Whitepaper.pdf'" target="_blank" text v-ripple="false">
                        <span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.0675 13.4074C17.5103 14.7253 16.6387 15.8865 15.5289 16.7897C14.4192 17.6929 13.1051 18.3105 11.7015 18.5884C10.298 18.8664 8.8477 18.7963 7.47747 18.3843C6.10724 17.9724 4.8588 17.231 3.8413 16.225C2.82378 15.2191 2.0682 13.9792 1.64058 12.6138C1.21296 11.2483 1.12633 9.79892 1.38827 8.39227C1.65021 6.98563 2.25274 5.66457 3.14318 4.5446C4.03363 3.4246 5.18488 2.5398 6.49628 1.96753" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.7595 9.99995C18.7595 8.84964 18.5329 7.7106 18.0927 6.64785C17.6525 5.58509 17.0073 4.61946 16.1939 3.80606C15.3805 2.99267 14.4149 2.34745 13.3521 1.90725C12.2893 1.46705 11.1503 1.24048 10 1.24048V9.99995H18.7595Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            {{__('White Paper')}}
                        </span>
                    </BaseButton>
                    <BaseButton
                        class_btn="btn-guest"
                        v-on:click="$store.commit('updateModalAuth', 'register')"
                    >
                          <span>
                            <img src="/dist/images/frontend/btn-sign-up.svg" alt="">
                            {{__('Get Started')}}
                          </span>
                    </BaseButton>
                    <BaseButton class_btn="btn-video" class="btn-link-nav" tag="a" target="_blank" v-on:click="dialog= true"  text v-ripple="false">
                        <span>
                             <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_86_2237)">
                                <path d="M12.0029 16.208L12.0029 16.808L12.0028 16.208H12.0026H12.0017L11.9978 16.208L11.982 16.208L11.9196 16.2078C11.8647 16.2077 11.7838 16.2075 11.68 16.207C11.4724 16.206 11.1736 16.204 10.809 16.2001C10.0797 16.1922 9.08858 16.1763 8.0402 16.1447C6.99084 16.1131 5.88902 16.0658 4.93681 15.9953C3.96738 15.9235 3.20461 15.8309 2.80243 15.7212L2.8013 15.7209C2.39375 15.6106 2.02221 15.3954 1.72367 15.0969C1.42637 14.7996 1.2118 14.4299 1.10111 14.0243C0.336881 10.9725 0.538478 6.04921 1.09154 2.77791C1.20384 2.38066 1.41608 2.01863 1.70819 1.7265C2.00673 1.42793 2.37828 1.21278 2.78584 1.10248L2.78696 1.10217C3.18963 0.992351 3.95285 0.898783 4.92226 0.825554C5.87459 0.753616 6.97651 0.704414 8.02592 0.670873C9.07436 0.637364 10.0655 0.619636 10.7949 0.61029C11.1595 0.605618 11.4584 0.603044 11.6659 0.601639C11.7697 0.600936 11.8506 0.600526 11.9055 0.600291L11.9679 0.600057L11.9837 0.600011L11.9876 0.600001L11.9876 0.6H11.9878L11.9888 0.6L11.9927 0.600002L12.0085 0.600015L12.0709 0.600123C12.1258 0.600245 12.2067 0.600492 12.3105 0.600985C12.518 0.601972 12.8168 0.603948 13.1814 0.607901C13.9107 0.615811 14.9018 0.631625 15.9502 0.66322C16.9995 0.694845 18.1013 0.74214 19.0535 0.812648C20.0229 0.884431 20.7857 0.977109 21.1878 1.0868L21.189 1.0871C21.5965 1.1974 21.9681 1.41254 22.2666 1.71109L22.6909 1.28684L22.2666 1.71109C22.5635 2.00802 22.7779 2.37714 22.8887 2.78207C23.6498 5.85189 23.4812 10.7759 22.8993 14.0431C22.7872 14.4413 22.5746 14.8042 22.2819 15.0969C21.9834 15.3954 21.6118 15.6106 21.2043 15.7209L21.2031 15.7212C20.801 15.8309 20.0382 15.9235 19.0688 15.9953C18.1166 16.0658 17.0148 16.1131 15.9655 16.1447C14.9171 16.1763 13.926 16.1922 13.1967 16.2001C12.8321 16.204 12.5333 16.206 12.3257 16.207C12.222 16.2075 12.141 16.2077 12.0862 16.2078L12.0238 16.208L12.008 16.208L12.0041 16.208H12.0031H12.0029Z" stroke="white" stroke-width="1.2"/>
                                <path d="M9.60193 12.0056L15.8202 8.40393L9.60193 4.80225V12.0056Z" fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_86_2237">
                                    <rect width="24" height="16.875" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        {{__('Play Video')}}
                        </span>
                    </BaseButton>
                </div>
                <div class="stat" >
                    <p class="name">{{__('Some statistics')}}</p>
                    <ul v-if="partners">
                        <li>
                            <p>{{partners.played}}</p>
                            <span>{{__('Games played')}}</span>
                        </li>
                        <li>
                            <p>$ {{partners.win}}</p>
                            <span>{{__('Earned by users')}}</span>
                        </li>
                        <li>
                            <p>{{partners.players}}</p>
                            <span>{{__('Active players')}}</span>
                        </li>
                        <li>
                            <p>{{partners.developers}}</p>
                            <span>{{__('Developers text')}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="reward" >
            <div class="content">
                <div class="top">
                    <h2>{{__('Reward Options')}}</h2>
                    <p class="text-top">{{__('Available methods of lifetime rewards')}}</p>
                </div>
                <ul>
                    <li>
                        <img src="/dist/images/frontend/reward-1.svg" alt="">
                        <p>{{__('Invite a friend')}}</p>
                        <span class="text-m">{{__('Get commission for every game from your referrals wins')}}</span>
                        <div class="btn-link">
                            <BaseButton  class_btn="btn-video" class="btn-link-nav" v-on:click="$store.commit('updateModalAuth', 'register')" text v-ripple="false">
                                <span>{{__('To invite')}}</span>
                            </BaseButton>
                        </div>
                    </li>
                    <li>
                        <img src="/dist/images/frontend/reward-2.svg" alt="">
                        <p>{{__('Write about us in the press')}}</p>
                        <span class="text-m">{{__('For every mention on the forums or in the press, we will generously reward')}}</span>
                        <div class="btn-link">
                            <BaseButton class_btn="btn-video" class="btn-link-nav" href="#calc" text v-ripple="false">
                                <span>{{__('Calculator')}}</span>
                            </BaseButton>
                        </div>
                    </li>
                    <li>
                        <img src="/dist/images/frontend/reward-3.svg" alt="">
                        <p>{{__('Become an Ambassador')}}</p>
                        <span class="text-m">{{__('Lead a social network and get coins on a regular basis')}}</span>
                        <div class="btn-link">
                            <BaseButton class_btn="btn-video" class="btn-link-nav" href="#calc" text v-ripple="false">
                                <span>{{__('Calculate income')}}</span>
                            </BaseButton>
                        </div>
                    </li>
                    <li>
                        <img src="/dist/images/frontend/reward-4.svg" alt="">
                        <p>{{__('Develop a game')}}</p>
                        <span class="text-m">{{__('get passive income from every game you play')}}</span>
                        <div class="btn-link">
                            <BaseButton class_btn="btn-video" href="https://mindplays.com/api" tag="a" target="_blank" class="btn-link-nav" text v-ripple="false">
                                <span>{{__('Developers')}}</span>
                            </BaseButton>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="calk-page">
            <div class="content">
                <div class="block-calc" id="calc">
                    <div class="block-left">
                        <h3 class="block-left__title">{{__('Additional rewards of MIND tokens')}}</h3>
                        <div class="grid-wrapper">
                            <div class="block-item">
                                <p>{{__('Упоминание в соц-сетях:')}}</p>
                                <span>{{__('от 1 MIND')}}</span>
                            </div>

                            <div class="block-item">
                                <p>{{__('Упоминанание в видео:')}}</p>
                                <span>{{__('от 3 MIND')}}</span>
                            </div>

                            <div class="block-item">
                                <p>{{__("Обзор на YouTube:")}}</p>
                                <span>{{__('от 50 MIND')}}</span>
                            </div>
                        </div>

                        <div class="block-help">
                            <div class="block-help__icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.9259 16.9669C16.7781 16.871 16.5984 16.8376 16.426 16.8738C16.2536 16.91 16.1026 17.013 16.0059 17.1602C15.0659 18.6002 13.3392 20.3669 11.7992 20.9602C10.2592 21.5535 9.05254 21.3402 8.57254 20.9602C8.36588 20.7802 7.82588 20.2935 8.28588 18.6335C8.59255 17.5269 10.4992 12.3135 10.9525 11.0469L9.49921 11.2335C9.03921 12.4735 7.30588 17.1269 6.99255 18.2869C6.53921 19.9469 6.76588 21.1535 7.71921 21.9935C8.38532 22.4609 9.18608 22.6974 9.99921 22.6669C10.7882 22.6555 11.5682 22.4972 12.2992 22.2002C14.1592 21.4735 16.1125 19.4535 17.1392 17.8869C17.1858 17.8125 17.2172 17.7297 17.2316 17.6431C17.2461 17.5566 17.2434 17.4681 17.2235 17.3826C17.2037 17.2971 17.1672 17.2164 17.1161 17.1451C17.0651 17.0737 17.0004 17.0132 16.9259 16.9669Z" fill="#26A9F4"/>
                                    <path d="M12.8672 7.3335C13.4605 7.3335 14.0406 7.15755 14.5339 6.82791C15.0272 6.49826 15.4118 6.02973 15.6388 5.48155C15.8659 4.93337 15.9253 4.33017 15.8095 3.74823C15.6938 3.16628 15.4081 2.63173 14.9885 2.21218C14.569 1.79262 14.0344 1.5069 13.4525 1.39114C12.8705 1.27539 12.2673 1.3348 11.7191 1.56186C11.171 1.78892 10.7024 2.17344 10.3728 2.66679C10.0431 3.16013 9.86719 3.74015 9.86719 4.3335C9.86719 5.12915 10.1833 5.89221 10.7459 6.45482C11.3085 7.01743 12.0715 7.3335 12.8672 7.3335ZM12.8672 2.66683C13.1968 2.66683 13.5191 2.76458 13.7931 2.94771C14.0672 3.13085 14.2808 3.39115 14.407 3.69569C14.5331 4.00024 14.5661 4.33535 14.5018 4.65865C14.4375 4.98195 14.2788 5.27892 14.0457 5.51201C13.8126 5.7451 13.5156 5.90383 13.1923 5.96814C12.869 6.03245 12.5339 5.99944 12.2294 5.8733C11.9248 5.74715 11.6645 5.53353 11.4814 5.25945C11.2983 4.98537 11.2005 4.66313 11.2005 4.3335C11.2005 3.89147 11.3761 3.46755 11.6887 3.15499C12.0012 2.84243 12.4252 2.66683 12.8672 2.66683Z" fill="#26A9F4"/>
                                    <path d="M7.87337 9.99984C7.91337 9.99984 12.0534 9.45317 13.0267 9.33317C13.46 9.26651 13.7867 9.33317 13.8934 9.43317C14 9.53317 14.0334 9.96651 13.8467 10.5532C13.44 11.7932 11.3867 17.8865 10.7867 19.6932C11.08 19.633 11.3677 19.5483 11.6467 19.4398C11.9136 19.3295 12.1702 19.1956 12.4134 19.0398C13.2334 16.6665 14.7667 12.0665 15.1134 11.0132C15.46 9.95984 15.3734 9.15984 14.9134 8.61317C14.651 8.34711 14.3225 8.15564 13.9616 8.0584C13.6008 7.96117 13.2206 7.96166 12.86 8.05984C11.9067 8.15984 7.88004 8.68651 7.70671 8.72651C7.5299 8.74949 7.36946 8.84178 7.26069 8.98305C7.15191 9.12433 7.10372 9.30303 7.12671 9.47984C7.14969 9.65665 7.24197 9.81709 7.38325 9.92586C7.52453 10.0346 7.70323 10.0828 7.88004 10.0598L7.87337 9.99984Z" fill="#26A9F4"/>
                                </svg>
                            </div>

                            <p class="block-help__txt">{{__('Для получения вознаграждений пишите нам на')}} <a href="mailto:support@mindplays.com">support@mindplays.com</a> {{__('с указанием ссылки на источник')}}</p>
                        </div>
                    </div>

                    <div class="block-calculator">
                        <div class="block-calculator__left">
                            <div class="top-text">
                                <p>{{__('Make a count')}}</p>
                                <img src="/dist/images/frontend/calc-img.svg" alt="">
                            </div>
                            <div class="block-select">
                                <v-select
                                        dark
                                        dense
                                        filled
                                        solo
                                        :itemValue="null"
                                        v-model="social"
                                        :items="calculator"
                                        :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: false, dark: true}"
                                        @change="updateOption(social)"
                                >
                                    <template v-slot:selection="data">
                                        <div class="img">
                                            <img :src="'/dist/images/frontend/' + data.item.name + '-icon.svg'" alt="/" />
                                        </div>
                                        {{__(data.item.name)}}
                                        <svg class="chevron-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.27574 5C9.54301 5 9.67686 5.32314 9.48787 5.51213L6.21213 8.78787C6.09497 8.90503 5.90503 8.90503 5.78787 8.78787L2.51213 5.51213C2.32314 5.32314 2.45699 5 2.72426 5H9.27574Z" fill="currentColor"/>
                                        </svg>
                                    </template>
                                    <template v-slot:item="data">
                                        <template>
                                            <div class="wrap-modal-select d-flex align-center">
                                                <div class="img">
                                                    <img :src="'/dist/images/frontend/' + data.item.name + '-icon.svg'" alt="/" />
                                                </div>
                                                <v-list-item-title class='menu-item'>{{__(data.item.name)}}</v-list-item-title>
                                            </div>
                                        </template>
                                    </template>
                                </v-select>
                            </div>

                            <div class="block-select">
                                <v-select
                                        dense
                                        filled
                                        solo
                                        :itemValue="null"
                                        v-model="opt"
                                        :items="social.option"
                                        :menu-props="{ bottom: true, offsetY: true, closeOnClick: true, closeOnContentClick: false, dark:true}"
                                        @change="calculateReward()"
                                >
                                    <template v-slot:selection="data">
                                        {{__(data.item.name)}}
                                        <svg class="chevron-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.27574 5C9.54301 5 9.67686 5.32314 9.48787 5.51213L6.21213 8.78787C6.09497 8.90503 5.90503 8.90503 5.78787 8.78787L2.51213 5.51213C2.32314 5.32314 2.45699 5 2.72426 5H9.27574Z" fill="currentColor"/>
                                        </svg>
                                    </template>
                                    <template v-slot:item="data">
                                        <template>
                                            <div class="wrap-modal-select d-flex align-center">
                                                <v-list-item-title class='menu-item'>{{__(data.item.name)}}</v-list-item-title>
                                            </div>
                                        </template>
                                    </template>
                                </v-select>
                            </div>

                            <div class="block-input">
                                <v-text-field
                                        type="number"
                                        :placeholder="__('Введите количество подписчиков')"
                                        hide-details="auto"
                                        v-model="subscribers"
                                        @input="inputSubscribers()"
                                ></v-text-field>
                                <!--                        <div class="block-error">-->
                                <!--                            <p v-for="(value, key) in formValidate.subscribers" :key="key">{{value}}</p>-->
                                <!--                        </div>-->
                            </div>

<!--                            <BaseButton-->
<!--                                    class_btn="btn-guest"-->
<!--                                    href="mailto:marketing@mindplays.com"-->
<!--                            >-->
<!--                                <span>{{__('Получить вознаграждение')}}</span>-->
<!--                            </BaseButton>-->

                        </div>

                        <div class="block-calculator__right">
                            <svg class="block-calculator__right-icon" width="78" height="78" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_240_7211)">
                                    <rect width="56" height="56" rx="28" fill="white"/>
                                    <path d="M19.3678 36.5487C19.8221 37.3369 21.0267 37.0145 21.0267 36.1048V16.429C21.0267 15.0785 22.1199 13.9837 23.4685 13.9837H37.534C37.9806 13.9837 38.1639 13.4325 37.7921 13.1849C34.9807 11.3126 31.6057 10.2217 27.9763 10.2217C20.8704 10.2217 14.7397 14.4035 11.9043 20.4438C11.3621 21.5988 11.5213 22.9385 12.1586 24.0438L19.3678 36.5487Z" fill="#00AEFF"/>
                                    <path d="M23.7187 16.2334C23.1422 16.2334 22.9377 16.9978 23.437 17.2864L41.5029 27.7321C42.6707 28.4074 43.0709 29.9029 42.3966 31.0725L35.3638 43.2715C35.1406 43.6588 35.5255 44.0933 35.9256 43.8947C38.9503 42.3925 41.5812 40.0109 43.3959 36.8631C47.0785 30.4754 46.3523 22.7502 42.163 17.2212C41.6734 16.5751 40.8925 16.2334 40.0827 16.2334L23.7187 16.2334Z" fill="#01DF72"/>
                                    <path d="M40.2818 30.135C40.5701 29.635 40.0114 29.0755 39.5121 29.3641L21.4462 39.8098C20.2783 40.485 18.785 40.0843 18.1107 38.9147L11.078 26.7157C10.8547 26.3284 10.2864 26.445 10.2582 26.8913C10.0448 30.2658 10.7889 33.7384 12.6036 36.8861C16.2861 43.2738 23.3296 46.5066 30.2055 45.6377C31.009 45.5362 31.695 45.0298 32.0999 44.3274L40.2818 30.135Z" fill="#026EED"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM28.0004 49.7782C40.028 49.7782 49.7782 40.028 49.7782 28.0004C49.7782 15.9729 40.028 6.22266 28.0004 6.22266C15.9729 6.22266 6.22266 15.9729 6.22266 28.0004C6.22266 40.028 15.9729 49.7782 28.0004 49.7782Z" fill="url(#paint0_angular_240_7211)"/>
                                    <path d="M29.6304 49.7773H26.3711V55.9996C27.4628 55.9996 28.5491 55.9878 29.6304 55.967V49.7773Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M29.6304 0.0325935C28.5491 0.0117395 27.4628 0 26.3711 0V6.22222H29.6304V0.0325935Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M42.2285 44.5184L46.6538 48.879C47.043 48.5304 47.4248 48.1712 47.7971 47.7989C48.202 47.394 48.5913 46.9777 48.9681 46.5529L44.5488 42.1982L42.2285 44.5184Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M49.6309 29.6294H55.9679C55.9892 29.0888 56.0012 28.5457 56.0012 27.9997C56.0012 27.4538 55.9892 26.9107 55.9679 26.3701H49.6309V29.6294Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M6.22222 26.3701H0.0325935C0.0117395 26.9107 0 27.4538 0 27.9997C0 28.5457 0.0117395 29.0888 0.0325935 29.6294H6.22222V26.3701Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M44.4707 13.8518L48.927 9.39547C48.5643 8.98859 48.1901 8.58949 47.8014 8.20077C47.4128 7.81216 47.0137 7.43799 46.6067 7.0752L42.1504 11.5314L44.4707 13.8518Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M11.5561 42.125L7.07617 46.6049C7.43886 47.0118 7.81303 47.4109 8.20175 47.7996C8.59036 48.1882 8.98947 48.5624 9.39645 48.9252L13.8763 44.4453L11.5561 42.125Z" fill="white" fill-opacity="0.5"/>
                                    <path d="M13.7989 11.5223L9.32598 7.13477C8.94229 7.47886 8.56582 7.83357 8.19854 8.20085C7.7886 8.61079 7.39474 9.03232 7.01367 9.4626L11.4789 13.8425L13.7989 11.5223Z" fill="white" fill-opacity="0.5"/>
                                </g>
                                <defs>
                                    <radialGradient id="paint0_angular_240_7211" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(28 28) rotate(128.904) scale(32.5538)">
                                        <stop stop-color="#00AEFF"/>
                                        <stop offset="0.232766" stop-color="#01DF72"/>
                                        <stop offset="0.399436" stop-color="#026EED"/>
                                        <stop offset="0.639026" stop-color="#00AEFF"/>
                                    </radialGradient>
                                    <clipPath id="clip0_240_7211">
                                        <rect width="56" height="56" rx="28" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="block-calculator__right-title">{{__('Ваше вознаграждение:')}}</p>
                            <p class="block-calculator__right-txt">
                                {{reward}}
                                <span>MIND</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-page">
            <div class="content">
                <div class="left-text">
                    <p>{{__('Online games at MINDPLAYS')}} {{__('play with profit and real rivals')}}. {{__('Can the most popular, famous and uncomplicated games, such as tic-tac-toe or Sea Battle, bring real income? With the MINDPLAYS platform, you can play online games with real opponents and earn money. The selection of available competitions and our audience are constantly growing, and you will surely find a worthy opponent.')}}</p>
                    <p>{{__('Playing online games for real money with real people, with amateurs or professional players, is much more interesting and exciting than with a robot. You have a certain experience (everyone played checkers or dots) and passion, and so does your opponent. To meet in an online game with a live opponent is always more honest than with a computer algorithm.')}}</p>
                </div>
                <div class="right-text">
                    <p>{{__('In order to equalize the chances in the game to win and eliminate mistakes in making money online, each player on the platform is provided with:')}}</p>
                    <ul>
                        <li>{{__("fixing each player's move (logging)")}};</li>
                        <li>{{__('constant and prompt support from our specialists')}};</li>
                        <li>{{__('tracking statistics and achievements of each player, receiving rewards for victories')}}.</li>
                    </ul>
                    <p>{{__('The platform has an official token - MIND Token. You get it when registering, verifying your profile, and then in mini-games in which you fight with live players. Tokens can be used in cryptocurrency trading.')}}</p>
                </div>
            </div>
        </div>

        <div class="rules">
            <div class="content">
                <div class="rules-block">
                    <div class="top">
                        <h2>{{__('Affiliate Rewards Rules')}}</h2>
                        <p>{{__('All received coins are immediately available for purchase / sale on exchanges. After registration, you will receive on your balance')}} <span>{{__('3 MIND tokens')}}</span>, {{__('and also during verification is charged')}} <span>{{__('+ 8 MIND tokens')}}</span>. </p>
                    </div>
                    <div class="body">
                        <div class="img">
                            <img src="/dist/images/frontend/img-rules.svg" alt="">
                        </div>
                        <p>{{__('We have the right to refuse to receive a bonus without explaining the reasons. As a rule, all bonuses are credited within 48 hours after receiving an application by mail.')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <ModalVideo width="800px" height="500" v-if="dialog" v-bind:modal_cancel.sync="dialog">
            <div class="window-video" v-if="(lang == 'ru')">
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/AN2PS5gGwBo?controls=0&autoplay=1&rel=0;"  allow="rel=0; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div v-else>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/JdoflzMPyg4?controls=0&autoplay=1&rel=0;"  frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </ModalVideo>

        <div class="block-footer">
            <Footer></Footer>
        </div>

    </div>
</template>

<script>
    import ModalVideo from "../modules/ModalVideo";
    import BaseButton from "@/components/modules/BaseButton";
    import Footer from "../modules/Footer";
    export default {
        data() {
            let calculator= [
                {
                    value: 500,
                    name: "Youtube",

                    option: [
                        {
                            value: 200,
                            name: 'Обзор'
                        },
                        {
                            value: 100,
                            name: 'Упоминание в ролике'
                        }
                    ]
                },
                {
                    value: 100,
                    name: 'Instagram',

                    option: [
                        {
                            value: 10,
                            name: 'История'
                        },
                        {
                            value: 20,
                            name: 'Публикация'
                        },
                        {
                            value: 25,
                            name: 'Reels'
                        },
                    ]
                },
                {
                    value: 500,
                    name: 'Twitter',

                    option: [
                        {
                            value: 100,
                            name: 'Публикация'
                        }
                    ]
                },
                {
                    value: 100,
                    name: 'Facebook',

                    option: [
                        {
                            value: 10,
                            name: 'История'
                        },
                        {
                            value: 10,
                            name: 'Публикация'
                        }
                    ]
                },
                {
                    value: 100,
                    name: 'Vkontakte',

                    option: [
                        {
                            value: 10,
                            name: 'История'
                        },
                        {
                            value: 20,
                            name: 'Публикация'
                        },
                        {
                            value: 15,
                            name: 'Клипы'
                        },
                    ]
                },
                {
                    value: 500,
                    name: 'TikTok',

                    option: [
                        {
                            value: 25,
                            name: 'TikTok'
                        }
                    ]
                },
                {
                    value: 100,
                    name: 'WebSite-Blog',

                    option: [
                        {
                            value: 100,
                            name: 'Публикация'
                        }
                    ]
                },
            ];

            return {
                partners: null,
                dialog: false,
                calculator: calculator,
                social: calculator[0],
                opt: calculator[0].option[0],
                subscribers: null,
                reward: 0,
                progressLine: 0,
            }
        },

        computed: {
            auth() {
                return this.$store.state.auth.user
            },
            lang() {
                return this.$store.getters.getLanguage;
            }
        },
        methods: {


            updateOption(val) {
                this.opt = val.option[0];
                this.calculateReward();
            },
            calculateReward() {

                if (!this.subscribers || this.subscribers <= Number(this.social.value)) {
                    this.reward = Number(this.opt.value);
                } else {
                    this.reward = Math.floor(Number(this.subscribers) / Number(this.social.value)) * Number(this.opt.value);
                }
            },
            inputSubscribers() {
                setTimeout(()=> {
                    this.calculateReward();
                },600)
            }
        },

        created() {

            this.calculateReward();
            this.$http
                .get("ajax/partners")
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                        response = response.data;
                        this.partners = response;
                })
                .catch((err) => {
                    this.httpHandler(err);
                });
        },

        destroyed() {
            clearInterval(this.timeinterval);
        },
        components: {
            Footer,
            BaseButton,
            ModalVideo
        }
    }
</script>

<style lang="scss" scoped>

</style>