import Vue from "vue";
// import response from "vue-resource/src/http/response";

export default {
    state: {
        user: false,
        notice: [],
        friends_req: null,
        friends_req_count: null,
        active_session: true,
    },
    getters: {
        authUser(state){
            return state.user;
        }
    },
    actions: {
        asyncUserInfo(context, payload) {
            let response;

            if(payload && payload.data) {
                response= payload.data;
            } else {
                response= null;
            }
            context.commit('updateUserInfo', response);
        },
        asyncReadyPlay(context, payload) {
            context.commit('updateReadyPlay', payload);
        },
        asyncDeletePhoto(context, payload) {
            context.commit('updatePhoto', payload);
        },
        asyncUpdateAuth(context, payload) {
            context.commit('updateAuth', payload);
        },
        asyncUpdateUserBalance(context, payload) {
            context.commit('updateUserBalance', payload);
        },
        asyncNotice(context, payload) {
            context.commit('updateNotice', payload);
        },
        asyncPushNotice(context, payload) {
            context.commit('pushNotice', payload);
        },
        asyncFriendsReq(context, payload) {
            context.commit('updateFriendsReq', payload);
        },
        asyncFriendsReqCount(context, payload) {
            context.commit('updateFriendsReqCount', payload);
        },
        asyncActiveSession(context, payload) {
            context.commit('updateActiveSession', payload);
        },
    },
    mutations: {
        updateUserInfo(state, data) {
            this.state.auth.user= data;
            if(data && process.env.NODE_ENV === "production") {
                Vue.prototype.$connectWebsocket();
            }
        },
        updateAuth(state, data) {
            this.state.auth.user= data;
        },
        updateReadyPlay(state, data) {
            this.state.auth.user.ready_play = data;
        },
        updateBalanceType(state, data) {
            this.state.auth.user.balance_type= data;
        },
        updatePhoto(state, data) {
            this.state.auth.user.photo = data;
        },
        updateUserBalance(state, data) {
            this.state.auth.user.balance_real = data.balance_real;
            this.state.auth.user.balance_lite = data.balance_lite;
            if (data.rating) {
                this.state.auth.user.rating_points = data.rating;
            }
        },
        updateNotice(state, data) {
            this.state.auth.notice= data;
        },
        updateFriendsReq(state, data) {
            this.state.auth.friends_req= data;
        },
        updateFriendsReqCount(state, data) {
            this.state.auth.friends_req_count= data;
        },
        pushNotice(state, data) {
            this.state.auth.notice.push(data);
        },
        updateActiveSession(state, data) {
            this.state.auth.active_session= data;
        },
    }
}
