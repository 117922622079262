<template>
    <div class="block-tournament-bracket">
        <div class="block-tournament-bracket__header">
            <div class="item-header">
                <p>{{__('Place')}}</p>
            </div>

            <div class="item-header item-header--user">
                <p>{{__('User Name')}}</p>
            </div>

            <div
                v-for="(item, key) in Object.keys(table)"
                :key="key"
                class="item-header"
            >
                <p>{{item}}</p>
            </div>
        </div>

        <ItemTournamentBracket
            v-for="(item, key) in table"
            :key="key"
            :item="item"
        />
    </div>
</template>

<script>
    import ItemTournamentBracket from "./ItemTournamentBracket";

    export default {
        name: 'TournamentBracket',
        data() {
            return {

            }

        },
        watch: {

        },
        props: {
            table: {
                type: Object
            }
        },
        computed:{
            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.getters.getLanguage;
            }
        },
        methods: {

        },
        components: {
            ItemTournamentBracket
        }
    }
</script>

<style lang="scss" scoped>

.block-tournament-bracket {
    border-radius: 8px;
    background: #131420;
    overflow-x: auto;

    &__header {
        display: flex;
        background: #131420;
        position: relative;
        z-index: 10;

        .item-header {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            min-width: 70px;
            height: 44px;
            border-right: 1px solid #282A40;
            border-bottom: 1px solid #282A40;


            &--user {
                min-width: 220px;
                width: 220px;
                justify-content: flex-start;
                padding: 0 12px;
            }
            p {
                color: #667085;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                margin: 0;
            }
        }
    }
}
</style>