<template>
    <v-dialog v-model="dialog" max-width="400px">
        <div class="wrapper-modal-password">
            <div class="wrapper-modal-password__head">
                <div>
                    <h3>{{__('Change password')}}</h3>
                </div>
            </div>
            <div>
                <FormChangePassword v-bind:dialog.sync="dialog"></FormChangePassword>
            </div>

        </div>
    </v-dialog>
</template>



<script>
    import FormChangePassword from "./FormChangePassword";
    export default {
        name:'ModalChangePassword',
        data() {
            return {
                dialog: true,
            }
        },

        watch: {
            dialog() {
                this.cancelModal();
            }
        },
        methods: {
            cancelModal() {
                this.$emit("update:change_password_modal", false);
            }
        },
        props: {
            setting: {
                type: Object
            }
        },
        components: {
            FormChangePassword,
        }
    }
</script>

<style lang="scss" scoped>

    .wrapper-modal-password {
        background: #FFFFFF;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;

        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px 8px 0 0;
            padding: 20px 0 20px 25px;
            border-bottom: 1px solid #F0F6FE;
            position: relative;

            h3 {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #002B50;
                margin-bottom: 2px;
            }
        }
    }
</style>


