<template>
    <div class="attributes-item">
        <div class="d-flex attributes-item__name">
<!--            <i class="fas fa-circle"></i>-->
            <span>{{attribute.attrName}}</span>
            <p class="my-0">{{attribute.name}}</p>
        </div>

<!--        <div class="block-info-option">-->
<!--&lt;!&ndash;            <img class="avatar" :src="'/assets/games/options/'+((this.attribute.option.img) ? this.attribute.option.img : 'default.svg')"/>&ndash;&gt;-->
<!--            <img class="avatar" :src="attribute.option.img"/>-->
<!--            <div>-->
<!--                <h5>{{this.attribute.attributes.name}}</h5>-->
<!--                <span>{{this.attribute.option.description}}</span>-->
<!--            </div>-->

<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: 'GameAttributesItem',
    data() {
        return {

        }
    },
    props: {
        attribute: {
            type: [Object, Array]
        }
    }
}
</script>

<style lang="scss" scoped>

    .dark .attributes-item {
        color: #fff;
    }
    .attributes-item {
        padding: 4px 10px;
        display: flex;
        align-items: center;
        background: rgba(0, 174, 255, 0.1);
        border-radius: 5px;
        margin: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #002B50;
        width: fit-content;
        /*min-width: 120px;*/


        &__name {
            cursor: default;
            transition-duration: .3s;

            &:hover {
                /*color: #002B50;*/
                transition-duration: .3s;

                & ~.block-info-option {
                    opacity: 1;
                    visibility: visible;
                    display: flex;
                    transition-duration: .5s;
                }
            }
        }

        span {
            margin-right: 6px;
            text-transform: capitalize;
            white-space: nowrap;
        }

        p {
            color: #00AEFF;
            font-weight: 400;
            /*max-width: 150px;*/
            /*width: 100%;*/
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        i {
            font-size: 7px;
            display: flex;
            align-items: center;
            margin: 1px 6px 0 0;
        }

        .block-info-option {
          position: absolute;
          top: 22px;
          left: -80px;
          //position: fixed;
          //transform: translateX(-50%);
          opacity: 0;
          visibility: hidden;
          //display: flex;
          transition-duration: .3s;
          width: 380px;
          max-height: 170px;
          overflow: auto;
          //width: 100%;
          z-index: 10;
          padding: 10px;
          background-color: #FFFFFF;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          border-radius: 8px;

            img {
                width: 60px;
                height: 60px;
                border-radius: 6px;
                margin-right: 9px;
            }

            h5 {
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                color: #002B50;
                margin-bottom: -2px;
            }

            span {
                max-width: 200px;
                width: 100%;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #6886AF;
            }
        }
    }

    @media screen and (max-width: 450px) {
        .attributes-item {
            p {
                font-size: 12px;
            }
        }
    }
</style>