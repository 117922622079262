<template>
  <div v-if="dialog">
    <!--<v-row justify="center">-->
      <v-dialog
        v-model="dialog" persistent :max-width="width"
        v-on:click:outside="$emit('update:modal_cancel', false)"
      >
        <div class="white portfolio-window" :class="class_name" style="position: relative; border-radius: 5px">
          <span class="close"></span>
          <slot></slot>
        </div>
      </v-dialog>
    <!--</v-row>-->
  </div>
</template>

<script>
    export default {
        name: 'ModalDefault',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
            modal_cancel: {
                type: [Boolean, Object, Array, String]
            },
          class_name: {
              type: String,
          },
          width: {
            type: [Number, String],
            default: 400
          },
        },
        computed: {

        },
        watch: {

        },
        methods: {

        },
        components: {

        },
    };
</script>

<style>

</style>

