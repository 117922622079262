<template>
    <div class="wrap-session">
        <img src="/dist/images/frontend/session.png" alt />
        <p>{{__('You have another active session')}}</p>
        <v-btn text  class="btn-cab white--text" onclick="location.reload()">{{__('Restore')}}</v-btn>
    </div>
</template>

<script>
    export default {
        name: 'DisconnectedSession',
        data: function () {
            return {

            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        created: function () {
            this.$echo.disconnect();
        },
    }
</script>

<style lang="scss" scoped>
.wrap-session {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 52px 60px 42px;
  margin: auto;
  background-color: #fff;
  max-width: 400px;
  border-radius: 5px;

  img {
    margin-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #002B50;
    margin-bottom: 24px;
  }
}
</style>