<template>
    <div :class="(!auth) ? 'dark' : ''">
        <div class="page-faq">
            <div class="wrapper-faq">
                <div class="wrapper-faq__left">
                    <h1>{{__('Frequently Asked Questions')}}</h1>
                    <span>{{__('Below are the answers to the most frequently asked questions. If you did not find the answer to your question,')}}
                    <a @click="(auth) ? $router.push({name: 'chat', params: {id: 'support'}}) : $store.commit('updateModalAuth', 'register')">{{__('Write to us!')}}</a>
                </span>
                    <div class="wrap-questions">
                        <Question :key="i" v-for="(item,i) in questions" :item="item" :faqParams="faqParams" />
                    </div>

                    <div class="wrap-questions-desktop">
                        <div class="wrap-questions-desktop__left">
                            <Question :key="i" v-for="(item,i) in questions.slice(0, Math.round(questions.length / 2))" :item="item" :faqParams="faqParams" />
                        </div>

                        <div class="wrap-questions-desktop__right">
                            <Question :key="i" v-for="(item,i) in questions.slice(Math.round(questions.length / 2))" :item="item" :faqParams="faqParams" />
                        </div>
                    </div>
                </div>

                <!--            <div class="wrapper-faq__img">-->
                <!--                <div class="block-img"></div>-->
                <!--&lt;!&ndash;                <img  src="/dist/images/frontend/faq-img.png" alt />&ndash;&gt;-->
                <!--            </div>-->
            </div>
            <div class="block-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
    import Footer from "../modules/Footer";
    import Question from "./Contacts/Question";
    export default {
        data () {
            return {
                payments: false,
                scroll: false,
                faqParams: {
                    payments: null
                },
                questions: [
                    {
                        question: 'How much does it cost to open an account?',
                        answer: 'Opening an account on our platform is absolutely free. No hidden costs or service charges.'
                    },
                    {
                        question: 'How many accounts can I create?',
                        answer: 'You can create an unlimited number of accounts. However, creating new accounts to receive fake affiliate commissions is prohibited. If we learn of this type of activity on your behalf, your accounts will be blocked and your funds frozen.'
                    },
                    {
                        question: 'How do opponents compete on MindPlays?',
                        answer: 'There are two coins on the platform, SilverCoin and GoldCoin, you can compete in games for any of these coins.'
                    },
                    {
                        question: 'What is SilverCoin?',
                        answer: 'SilverCoin is actually a free coin that you can compete with, learn and improve your skills with. You get 250 coins upon registration and for each match you play you get one coin, provided that you play the game with an opponent for the first time in a day. The more unique rivals - the more coins!'
                    },
                    {
                        question: 'What is GoldCoin?',
                        answer: 'SilverCoin is a coin whose value is equal to the dollar 1GOLD = 1USD. You can compete and earn GOLD, you can both buy and withdraw GOLD to your card or any other payment system available on the platform.'
                    },
                    {
                        question: 'How do I make my first deposit?',
                        answer: 'After registration, you can always log into your personal secure account using your username and password with which you first registered and make your first deposit. You will need to click the "Deposit" button in the account menu, where you can choose a payment method and follow the simple instructions on the page to complete the deposit process. All transactions are safe, fast and convenient.'
                    },
                    {
                        question: 'Which payment system can I use to make a deposit?',
                        answer: ':payments'
                    },
                    {
                        question: 'Can I fund my account in one currency and withdraw funds in another?',
                        answer: 'Yes, you can replenish your account in any way convenient for you and make a withdrawal to any of the available payment systems.'
                    },
                    {
                        question: 'What is the minimum and maximum deposit amount?',
                        answer: 'The amounts are different for each payment system. You can always check the exact minimum and maximum amount in your personal account.'
                    },
                    {
                        question: "How long does it take for funds to be credited after they have been sent to the company's accounts?",
                        answer: 'In most cases, funds are credited to your personal account almost instantly. To make a deposit via cryptocurrency, it will take from 3 to 11 confirmations, depending on the blockchain network.'
                    },
                    {
                        question: "How quickly are withdrawal requests processed?",
                        answer: 'All withdrawals are processed within 24 hours.'
                    },
                    {
                        question: "What is the minimum withdrawal amount?",
                        answer: 'The minimum withdrawal amount for each payment system is different. In general, you can make a withdrawal from $ 1. You can always check the exact amount in your personal account.'
                    },
                    {
                        question: "I still have questions, what should I do?",
                        answer: 'Contact technical support to resolve any issues you are experiencing.'
                    },
                ]
            }
        },
        created() {
            this.$addListener(window,'scroll', () => {
                if (document.documentElement.scrollTop > 60) {
                    this.scroll = true;
                } else {
                    this.scroll = false;
                }
            });

            this.$http
                .get("ajax/payments_info")
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        let payments = response.data.reverse();

                        this.payments = payments;
                        let list= '';

                        for (let i = 0; i < payments.length; i++) {
                            let payment= payments[i];

                            list+= payment.name+' ('+payment.currency.toUpperCase()+'); '
                        }

                        this.faqParams['payments']= list
                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });

        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            },
        },
        components: {
            Question,
            Footer
        }
    }
</script>

<style lang="scss" scoped>
    .page-faq {
        min-height: calc(100vh - 82px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .wrapper-faq {
        color: #002B50;
        max-width: 1185px;
        width: 100%;
        margin: 0 auto 50px;
        padding: 40px 0 0;
        display: flex;
        justify-content: space-between;

        &__left {
            width: 100%;

            h1 {
                font-size: 36px;
                line-height: 49px;
                color: #fff;
                margin-bottom: 15px;
                text-align: center;
            }
            span {
                color: rgba(255, 255, 255, 0.8);
                display: block;
                max-width: 570px;
                margin: 0 auto 50px;
                text-align: center;

                a {
                    color: #0F9AFF;

                    &:hover {
                        text-decoration: underline;
                        color: #0F9AFF;
                    }
                }
            }

            .wrap-questions {
                display: none;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            .wrap-questions-desktop {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                &__left {
                    width: 48%;
                    flex-shrink: 0;
                }

                &__right {
                    width: 48%;
                    flex-shrink: 0;
                }
            }
        }

        &__img {
            width: 42%;
            margin-top: 12px;
            /*max-height: 512px;*/
            /*height: 100%;*/
            /*position: fixed;*/
            /*top: 132px;*/
            /*left: 58%;*/

            .block-img {
                width: 100%;
                height: 100%;
                background-image: url("/dist/images/frontend/faq-img.png");
                background-size: contain;
                background-repeat: no-repeat;
            }

        }
    }

    @media screen and (max-width: 1240px) {
        .wrapper-faq {
            padding: 40px 20px 0;
        }
    }
    @media screen and (max-width: 950px) {
        .wrapper-faq {
            &__left {
                width: 100%;
                h1 {
                    font-size: 36px;
                    line-height: 49px;
                    text-align: center;
                }

                span {
                    font-size: 14px;
                    margin-bottom: 30px;
                    text-align: center;
                    padding: 0 25px;
                }
            }

            &__img {
                width: 45%;
            }
        }
    }
    
    @media screen  and (max-width: 768px){
        .wrapper-faq {
            &__img {
                display: none;
            }

            &__left {
                width: 100%;

                h1 {
                    font-size: 36px;
                    line-height: 49px;
                    text-align: center;
                }

                span {
                    font-size: 16px;
                    margin-bottom: 30px;
                }

                .wrap-questions {
                    display: flex;
                }

                .wrap-questions-desktop {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 650px){
        .wrapper-faq {
            &__left {
                span {
                    padding: 0;
                }
            }
        }
    }

    @media screen and (max-width: 500px){
        .wrapper-faq {
            &__left {
                h1 {
                    font-size: 30px;
                    line-height: 40px;
                }

                span {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>