import Vue from "vue";
import Vuetify from "vuetify";
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {

                white: '#ffffff',
                success: '#01DF72',
                primary: '#3f51b5',
                secondary: '#CDDFF7',
                accent: '#8c9eff',
                error: '#FF4B55',
                grey: '#6886AF',
                darkGrey: '#96AFD1',
                purp: '#F0F6FE',
                blue: '#26A9F4',
                darkblue: '#4EBEFF',
                warning: '#FDAE02'
            },
        },
    },
};

export default new Vuetify(opts);

