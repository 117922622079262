<template>
<!--  <div style="position: fixed; right: 10px; top: 25px; z-index: 1000">-->
  <div v-if="invite_room" class="wrap-invite">
      <InviteRoom></InviteRoom>
    </div>
    <div v-else-if="invite_game.length"  class="wrap-invite">
      <NotificationsInviteGameItem v-for="(invite, key) in invite_game" :key="invite.inviter_id" v-bind:activeClass="(currentQ == invite.inviter_id || (!currentQ && key== 0)) ? 'active' : ''" v-bind:invite_game.sync="invite_game" v-bind:invite="invite" @updateActiveClass="currentQ = $event"></NotificationsInviteGameItem>
      <!--    <NotificationsInviteGameItem v-for="(invite, key) in invite_game" :key="key" v-bind:invite_game.sync="invite_game" v-bind:invite="invite"></NotificationsInviteGameItem>-->
  </div>

<!--  <v-expansion-panels v-model="panel" v-if="invite_game.length" class="wrap-invite">-->
<!--    <NotificationsInviteGameItem v-for="(invite, key) in invite_game" :key="key" v-bind:invite_game.sync="invite_game" v-bind:invite="invite"></NotificationsInviteGameItem>-->
<!--  </v-expansion-panels>-->
</template>

<script>
    import NotificationsInviteGameItem from './NotificationsInviteGameItem';
    import InviteRoom from './InviteRoom';

    export default {
        name: 'NotificationsInviteGame',
        data: function() {
            return {
                submitDisabled: false,
                panel: [0],
                currentQ: 0,
            }
        },
        methods: {
          // updateActiveClass(index) {
          //   this.currentQ = index;
          // }
        },
        computed: {
            invite_game() {
              return this.$store.state.games.invite_game;
            },
            invite_room() {
              return this.$store.state.games.invite_room;
            },
        },
        components: {
            NotificationsInviteGameItem,
            InviteRoom
        },
    };
</script>

<style lang="scss" scoped>
.wrap-invite {
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgba(33, 33, 33, 0.5);
}
</style>

