<template>
  <div class="input-city" v-if="cities">
    <div>
      <v-autocomplete
        color="light-blue"
        dense
        :placeholder="placeholder"
        :label="label"
        :items="cities"
        :filter="customFilter"
        @change="cityChange"
        v-model="city"
      ></v-autocomplete>
      <div>
        <p class="desc-error" v-for="(value, key) in errors" :key="key">{{value}}</p>
      </div>
      <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
    </div>
  </div>
</template>

<script>

export default {
  name: "DropdownCities",
  data() {
    return {
        city: this.value
      // dropdown_font: ['English', 'Russian'],
    };
  },
  props: {
      value: {
          type: String,
      },
      errors: {
          type: Array
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      country: {
          type: String
      }
  },
  watch: {
      city(val) {
          this.$emit('update:value', val);
      },
  },
  created() {

  },
  computed: {
      cities() {
          let cities= this.$cities;
          return cities[this.country];
      }
  },
  mounted() {
  },
  methods: {
    customFilter (item, queryText) {
        const textOne = item.toLowerCase();
        const searchText = queryText.toLowerCase();

        return textOne.indexOf(searchText) > -1
    },
    cityChange(change) {
        if(change) {
            this.city_select= change;
        }
    },
  },
};
</script>

<style lang="scss">
  .input-city .v-select--is-menu-active ~ .chevron-icon {
    transform: rotate(180deg);
    transition-duration: .3s;
  }

  .input-city {
    position: relative;

    .chevron-icon {
      position: absolute;
      right: 4px;
      top: 13px;
      transition-duration: .3s;
    }
  }
</style>