<template>
    <div class="item-achievement">
        <div class="item-achievement__icon" :style="{'backgroundImage': 'url('+item.achievement.icon + ')'}"></div>
        <div class="item-achievement__info">
            <h5>{{__(item.achievement.name)}}</h5>
            <div class="block-prize">
                <div class="warning--text">
                    <img src="/dist/images/frontend/fire.svg" alt />
                    <span>+{{item.rating}}</span>
                </div>
                <div v-if="item.real > 0" class="warning--text">
                    <img  src="/dist/images/frontend/money.svg" alt />
                    <span>+{{item.real}}</span>
                </div>

                <div v-if="item.lite > 0" class="lite-coin">
                    <img  src="/dist/images/frontend/light-coin.svg" alt />
                    <span>+{{item.lite}}</span>
                </div>
            </div>
            <p>{{__(item.achievement.description)}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            item: {
                type: Object,
            }
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .item-achievement {
            border-bottom: 1px solid #2B2B2B;

            &:last-child {
                margin-bottom: 0;
                border: none;
            }

            &__icon {
                background-color: #181818;
                border: 1px solid #2B2B2B;
            }

            &__info {
                h5 {
                    color: #fff;
                }

                p {
                    color: #96AFD1;
                }

                .block-prize {
                    .lite-coin {
                        color: #96AFD1;
                    }
                }
            }
        }
    }

    .item-achievement {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid #ECF4FF;
        padding: 0 20px 10px;

        &:last-child {
            margin-bottom: 0;
            border: none;
        }

        &__icon {
            width: 60px;
            height: 60px;
            background-color: #FAFBFF;
            border: 1px solid #E7EFFF;
            box-sizing: border-box;
            border-radius: 10px;
            margin-right: 12px;
            background-position: center;
            background-size: cover;
            position: relative;
            flex-shrink: 0;
        }

        &__info {
            h5 {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #002B50;
            }

            .block-prize {
                width: 100%;
                display: flex;
                align-items: center;

                img {
                    height: 12px;
                    margin: 0 3px 0 0;
                }

                span {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.1px;
                    margin-right: 10px;
                }

                .lite-coin {
                    color: #6886af;
                }
            }

            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.04em;
                color: #96AFD1;
                margin-bottom: 0;
            }
        }
    }
</style>