<template>
  <div class="wrap-user-quest">
    <div v-if="quest && quest.length">
      <!--          <QuestsItem :actual_quest.sync="actual_quest" v-for="(item, key) in actual_quest" :item="item" :position="key+1" :key="key"/>-->
        <ItemQuestsNew v-for="(item, key) in quest" :item="item.quest" :position="key+1" :key="key">
            <div slot="right" class="progress-line">
                <div class="active-status" v-if="item.status == 1">
                    <v-tooltip
                            v-model="show[key]"
                            bottom
                            color="transparent"
                            nudge-top="10"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <progress v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>
                        </template>
                        <p class="block-name ma-0">
                            {{__('Done')}}
                            <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>
                            <span v-else>{{Number(item.progress).toFixed(0)}}</span>
                            {{__('from')}}

                            <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>
                            <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>

                            <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>
                            <span v-else></span>
                        </p>
                    </v-tooltip>

                    <!--                            <span class="active-status__num">{{ ((Number(item.progress) * 100) / Number(item.progress_end)).toFixed(2)}}%</span>-->
                </div>
                <div class="completed" v-else-if="item.status == 2">
                    <!--                            {{__('Completed')}}-->
                    <v-tooltip
                            v-model="show[key]"
                            bottom
                            color="transparent"
                            nudge-top="10"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <progress v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>
                        </template>
                        <p class="block-name ma-0">
                            {{__('Done')}}
                            <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>
                            <span v-else>{{Number(item.progress).toFixed(0)}}</span>
                            {{__('from')}}

                            <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>
                            <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>

                            <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>
                            <span v-else></span>
                        </p>
                    </v-tooltip>
                </div>
                <div class="failed" v-else-if="item.status == 3">
                    <!--                            {{__('Failed')}}-->
                    <v-tooltip
                            v-model="show[key]"
                            bottom
                            color="transparent"
                            nudge-top="10"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <progress v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>
                        </template>
                        <p class="block-name ma-0">
                            {{__('Done')}}
                            <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>
                            <span v-else>{{Number(item.progress).toFixed(0)}}</span>
                            {{__('from')}}

                            <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>
                            <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>

                            <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>
                            <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>
                            <span v-else></span>
                        </p>
                    </v-tooltip>
                </div>
            </div>
            <div slot="stat">
                <p v-if="item.status == 0" class="performed">{{__('Available to pass')}}</p>
                <p v-else-if="item.status == 2" class="done">{{__('Done')}}!</p>
                <p v-else-if="item.status == 3" class="failed">{{__('Failed')}}!</p>
                <p v-else class="available">{{__('In progress')}}</p>
            </div>
        </ItemQuestsNew>

<!--        <QuestsItemNew v-for="(item, key) in quest" :item="item.quest" :position="key+1" :key="key">-->
<!--        <div slot="right">-->
<!--&lt;!&ndash;          <div class="active-status" v-if="item.status == 1">In progress</div>&ndash;&gt;-->
<!--          <div class="active-status" v-if="item.status == 1">-->
<!--            <v-tooltip-->
<!--                    v-model="show[key]"-->
<!--                    bottom-->
<!--                    color="transparent"-->
<!--                    nudge-top="10"-->
<!--            >-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <progress  v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>-->
<!--              </template>-->
<!--              <p class="block-name ma-0">-->
<!--                  {{__('Done')}}-->
<!--                <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>-->
<!--                <span v-else>{{Number(item.progress).toFixed(0)}}</span>-->
<!--                  {{__('from')}}-->
<!--                <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>-->
<!--                <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>-->
<!--                <span v-if="item.quest.progress_end_time == 1"> {{__('seconds')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 2"> {{__('minutes')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 3"> {{__('hours')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 4"> {{__('days')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 5"> {{__('weeks')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 6"> {{__('months')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 7"> {{__('year')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 8"> {{__('users')}}</span>-->
<!--                <span v-else-if="item.quest.progress_end_time == 9"> {{__('games')}}</span>-->
<!--                <span v-else></span>-->
<!--              </p>-->
<!--            </v-tooltip>-->

<!--            <span class="active-status__num">{{ ((Number(item.progress) * 100) / Number(item.progress_end)).toFixed(2)}}%</span>-->
<!--          </div>-->
<!--          <div class="completed-status" v-else-if="item.status == 2">{{__('Completed')}}</div>-->
<!--          <div class="failed-status" v-else-if="item.status == 3">{{__('Failed')}}</div>-->
<!--        </div>-->

<!--&lt;!&ndash;        <div slot="line" class="block-progress-line">&ndash;&gt;-->
<!--&lt;!&ndash;          <span>Progress</span>&ndash;&gt;-->
<!--&lt;!&ndash;          <v-tooltip&ndash;&gt;-->
<!--&lt;!&ndash;                  v-model="show[key]"&ndash;&gt;-->
<!--&lt;!&ndash;                  bottom&ndash;&gt;-->
<!--&lt;!&ndash;                  color="transparent"&ndash;&gt;-->
<!--&lt;!&ndash;                  nudge-top="55"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
<!--&lt;!&ndash;              <progress  v-on="on" v-bind="attrs" style="width: 100%" :value="item.progress" :max="item.progress_end"></progress>&ndash;&gt;-->
<!--&lt;!&ndash;            </template>&ndash;&gt;-->
<!--&lt;!&ndash;            <p class="block-name ma-0">&ndash;&gt;-->
<!--&lt;!&ndash;              Выполнено&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-if="item.quest.progress_time == 1">{{ConvertTime(item.progress)}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else>{{Number(item.progress).toFixed(0)}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              из&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-if="item.quest.progress_end_time == 3">{{ConvertTimeEnd(item.progress_end)}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else>{{Number(item.progress_end).toFixed(0)}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-if="item.quest.progress_end_time == 1"> секунд</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 2"> минут</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 3"> часов</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 4"> дней</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 5"> недель</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 6"> месяцев</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 7"> год</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 8"> пользователей</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else-if="item.quest.progress_end_time == 9"> игр</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <span v-else></span>&ndash;&gt;-->
<!--&lt;!&ndash;            </p>&ndash;&gt;-->
<!--&lt;!&ndash;          </v-tooltip>&ndash;&gt;-->

<!--&lt;!&ndash;          &lt;!&ndash;<progress  :value="item.progress" :max="item.progress_end"></progress>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          <div class="block-progress-quest">&ndash;&gt;-->
<!--&lt;!&ndash;            <span>{{ ((Number(item.progress) * 100) / Number(item.progress_end)).toFixed(2)}}%</span>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--      </QuestsItemNew>-->
    </div>

    <div v-else-if="quest===null">
        {{__('loading')}}
    </div>

    <div v-else class="block-empty">
      <img class="img img--1" src="/dist/images/frontend/icon-quests.svg" alt />
      <p>{{__('You dont have quests :(')}}</p>
      <div class="wrap-btn">
        <BaseButton :to="{name: 'quests', params: {lang: $store.getters.getLang}}">
          <span>{{__('Buy Quest')}}</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
  import ItemQuestsNew from "../Quests/ItemQuestsNew";
  // import QuestsItemNew from "../Quests/QuestsItemNew";
export default {
  name: 'ProfileQuest',
  data() {
    return {
      quest: this.profile.user_quest,
      show: {}
    }
  },
  methods: {
    ConvertTime(seconds)  {
      // let minutes = Math.floor(seconds / (60));
      // seconds -= minutes * (60);
      // if (0 < seconds && seconds < 10) {
      //     seconds = "0" + seconds;
      // }
      // return ((0 < minutes) ? (minutes + ":") : "0:") + ((0 < seconds) ? (seconds) : "00");

      let days     = Math.floor(seconds / (24*60*60));
      seconds -= days    * (24*60*60);
      let hours    = Math.floor(seconds / (60*60));
      seconds -= hours   * (60*60);
      let minutes  = Math.floor(seconds / (60));
      seconds -= minutes * (60);
      return ((0<days)?(days+"day "):"") + ((0<hours)?(hours+"h "):"") + ((0 < minutes) ? (minutes + "m ") : "")+ ((0 < seconds) ? (seconds + "s") : "0s");
    },
    ConvertTimeEnd(seconds)  {
      let days     = Math.floor(seconds / (24*60*60));
      seconds -= days    * (24*60*60);
      let hours    = Math.floor(seconds / (60*60));
      seconds -= hours   * (60*60);
      let minutes  = Math.floor(seconds / (60));
      seconds -= minutes * (60);
      return ((0<days)?(days):"") + ((0<hours)?(hours):"") + ((0 < minutes) ? (minutes) : "")+ ((0 < seconds) ? (seconds) : "");
    }
  },
  props: {
    profile: {
      type: Object,
    }
  },
  components: {
    // QuestsItemNew,
      ItemQuestsNew
  }
}
</script>

<style lang="scss" scoped>

    .dark .wrap-user-quest {
        background: #000303;

        .block-empty {
            background: #171827;
        }
    }

  .wrap-user-quest {
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    margin-bottom: 20px;
    max-height: 275px;
    overflow: auto;
    padding: 10px 3px 0 0;

    &::-webkit-scrollbar-track
    {
      /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
      /*background-color: #F5F5F5;*/
      margin-top: 5px;
    }

    &::-webkit-scrollbar
    {
      width: 4px;
      /*background-color: #F5F5F5;*/
    }

    &::-webkit-scrollbar-thumb
    {
      background: rgba(150, 175, 209, 0.3);
      border-radius: 10px;
    }

    .active-status {
      min-width: 110px;
      /*background-color: #F0F6FE;*/
      height: 40px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 19px;
      color: #96AFD1;
      position: relative;

      &__num {
        position: absolute;
        color: #002b50;
        font-size: 15px;
        line-height: 19px;
        pointer-events: none;
       /*   text-shadow: -0   -1px 0   #26A9F4,
          0   -1px 0   #26A9F4,
          -0    1px 0   #26A9F4,
          0    1px 0   #26A9F4,
          -1px -0   0   #26A9F4,
          1px -0   0   #26A9F4,
          -1px  0   0   #26A9F4,
          1px  0   0   #26A9F4,
          -1px -1px 0   #26A9F4,
          1px -1px 0   #26A9F4,
          -1px  1px 0   #26A9F4,
          1px  1px 0   #26A9F4,
          -1px -1px 0   #26A9F4,
          1px -1px 0   #26A9F4,
          -1px  1px 0   #26A9F4,
          1px  1px 0   #26A9F4;*/
      }
    }

    .completed-status {
      min-width: 110px;
      background-color: #F0F6FE;
      height: 40px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 19px;
      color: #26A9F4;
    }

    .failed-status {
      min-width: 110px;
      background-color: #F0F6FE;
      height: 40px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 19px;
      color: #F04C44;
    }



    .block-progress-line {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      span {
        font-size: 14px;
        line-height: 19px;
        color: #607EA7;
        margin-right: 20px;
      }

      .block-progress-quest {
        margin-left: 12px;

        span {
          font-size: 14px;
          line-height: 19px;
          color: #26A9F4;
          margin: 0;
        }
      }
    }

      .block-empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #FFFFFF;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #96AFD1;
          border-radius: 0 0 4px 4px;
          height: 260px;
          padding: 0;

          svg,
          img {
              margin-bottom: 10px;
              height: 24px;
          }

          p {
              margin-bottom: 20px;
          }

          .wrap-btn {
              width: 130px;
              margin: 0 auto;
          }
      }
  }

  .block-name {
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    z-index: 1;
    font-size: 13px;
    line-height: 18px;
    color: #002B50;
    padding: 4px 8px;
    background: rgb(255, 255, 255);
    border: 1px solid #ECF4FF;
    border-radius: 6px;
    max-width: 110px;
    overflow: hidden;
    /*white-space: nowrap;*/
  }
</style>