import { render, staticRenderFns } from "./SettingsVerification.vue?vue&type=template&id=3f57048c&scoped=true&"
import script from "./SettingsVerification.vue?vue&type=script&lang=js&"
export * from "./SettingsVerification.vue?vue&type=script&lang=js&"
import style0 from "./SettingsVerification.vue?vue&type=style&index=0&id=3f57048c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f57048c",
  null
  
)

export default component.exports