<template>
  <div  class="my-nft">
    <div v-if="accounts && auth.web3_address.toLowerCase()== accounts.toLowerCase()">
      <div v-if="nft_list" class="wrap-developers-page">
        <ScrollPagination v-bind:items.sync="nft_list" type="post" v-bind:params="query" v-slot="list" className="nft-card" v-if="nft_list.total">
          <div class="nft-itam" v-for="(item, key) in list.data" :key="key">
            <NftListItem  v-bind:item="item" v-if="[3,5].includes(item.status)"/>
          </div>
        </ScrollPagination>
        <div v-else class="wrap-notgames">
          <img src="/dist/images/frontend/no-games.svg" alt />
          <div>
            <p>{{__('No have nft!')}}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{__('loading...')}}</p>
      </div>
    </div>
    <div v-else-if="auth.web3_address">
      <p>Подключен другой адрес: <span>{{auth.web3_address.toLowerCase()}}</span></p>
      <BaseButton :loading="loading" v-on:click="approveAddress"><span>Connect</span></BaseButton>
    </div>
    <div v-else>
      <p>Не подключено</p>
      <BaseButton :loading="loading" v-on:click="approveAddress"><span>Connect</span></BaseButton>
    </div>
  </div>
</template>


<script>


import ScrollPagination from '../../modules/ScrollPagination';
import NftListItem from './NftListItem';
import {MarketPlaceMixin} from "../../../mixins/MarketPlaceMixin";

export default {
  name: "NftMy",
  data() {
    return {
        games: null,
        collections: null,
        nft_list: null,
        current_game: null,
        query: {
            my_nft: true
        },
        accounts: (window.ethereum && window.ethereum.selectedAddress) ? window.ethereum.selectedAddress : ''
    }
  },

  watch: {

  },
  created() {
      this.details();

      this.createdComponent();
  },
  mixins: [MarketPlaceMixin],
  computed: {
      auth() {
          return this.$store.state.auth.user
      },
  },
  methods: {

      async approveAddress() {
          this.loading= true;
          const tx = await this.paymentToken.approve(this.marketPlace.address, 1);

          await tx.wait();
          const txReady = await tx.wait(4);

          if (txReady.status !== 1) {
              this.$notify({
                  group: "message",
                  title: "Transaction failed",
              });
              this.loading= false;
              return false;
          }

          this.loading= false;
          let query= {
              'address': tx.from,
              'hash': tx.hash,
          };

          this.$http.post('ajax/nft/approve', query)
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  if(this.$checkAuth(response)) {
                      let auth= this.$cleanObject(this.auth);

                      auth.web3_address= tx.from;

                      this.$store.dispatch("asyncUserInfo", {
                          "data": auth
                      });

                      console.log('this.$route.name');
                      console.log(this.$route.name);
                      if(this.$route.name== 'my_nft') {
                          this.createdComponent();
                      }
                  }
              }).catch(err => {
              this.httpHandler(err);
          });
          console.log('this.accounts');
          console.log(tx);
          console.log(tx.hash);
      },
      createdComponent() {
          this.nft_list= null;

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/nft', this.$cleanObject(this.query))
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  if(this.$checkAuth(response)) {
                      response= response.data;

                      let page= response.page;
                      this.nft_list= page;
                      let games= response.games;
                      this.games= this.$cleanObject(games);
                      this.collections= games;
                      games.unshift({
                          "id": null,
                          "logo": "/dist/images/frontend/all-filter.svg",
                          "name": "All",
                      });

                      this.current_game= games[0];
                  }
              }).catch(err => {
              this.httpHandler(err);
          });
      },
      customFilter (item, queryText) {
          const textOne = item.name.toLowerCase();
          const searchText = queryText.toLowerCase();
          return textOne.indexOf(searchText) > -1
      },
      filterChange(change) {
          if(change) {
              this.current_game= change;
          }

          let query= {
              game_id: this.current_game.id,
              my_nft: true
          };
          this.query= query;
          this.updateNftList();
      },
      updateNftList() {
          this.nft_list= null;

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/get/nft', this.$cleanObject(this.query))
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  if(this.$checkAuth(response)) {
                      let page= response.data;

                      this.nft_list= page;
                  }
              }).catch(err => {
              this.httpHandler(err);
          });
      }
  },
  components: {
      NftListItem,
      ScrollPagination
  },
};
</script>


<style lang="scss" scoped>
  .wrapper-modal-developer {
    /*max-height: 100%;*/
    /*height: calc(100vh - 100px);*/
    /*overflow: scroll;*/
  }

  .dark {
    .wrapper-modal-developer {
      background: #171827;

      &__head {
        border-bottom: 1px solid #2B2B2B;

        h2 {
          color: #fff;
        }
      }

      &__body {
        .wrapper-inputs {
          .wrap-input {
            .check-text {
              color: #fff;
            }
          }
        }
        .block-type {
          &__title {
            color: #96AFD1;
          }
        }
      }
    }
  }

  .wrapper-modal-developer {
    background: #FFFFFF;
    box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &__head {
      padding: 20px 25px;
      border-bottom: 1px solid #F0F6FE;

      h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
      }
    }

    &__body {
      padding: 25px 25px 27px;
      .block-name {
        display: flex;
        align-items: flex-end;

        span {
          margin: 0 38px 0 10px;
        }
      }

      .block-category {
        display: flex;
        align-items: flex-end;
        margin-bottom: 28px;

        span {
          margin: 0 35px 0 10px;
          padding-left: 3px;
        }
      }

      .block-type {
        display: flex;
        align-items: flex-start;

        &__title {
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #6886AF;
          margin-bottom: 0;
        }

        span {
          margin: 0 35px 0 10px;
          padding-left: 4px;

          img {
            height: 20px;
          }
        }
        .v-input--radio-group.v-input--radio-group--row .v-radio {
          margin-right: 38px;
        }
      }

      .block-create {
        display: flex;
        align-items: flex-start;
        margin-top: 6px;

        &__title {
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #6886AF;
          margin-bottom: 0;
        }

        span {
          margin: 0 37px 0 11px;
          padding-left: 4px;

          img {
            height: 20px;
          }
        }
        .v-input--radio-group.v-input--radio-group--row .v-radio {
          margin-right: 38px;
        }
      }

      .wrapper-inputs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-top: -16px;

        .wrap-input {
          display: flex;
          align-items: center;
          margin-left: 12px;

          .label-text {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #002b50;
            margin-left: 26px;
            margin-right: 6px;
          }

          .check-text {
            margin-top: 30px;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #002b50;
            margin-right: 6px;
            margin-left: 25px;

            a {
              color: #26a9f4;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .wrap-btn {
          display: flex;
          .btn-cab {
            margin: 0 0 0 15px;
            height: 40px;
          }

          .btn-secondary {
            min-width: 105px;
            margin-right: 15px;
          }
        }
      }
    }
  }

  @media screen and (max-width:1024px) {
    .wrapper-modal-developer {
      &__body {
        padding: 20px;

        .wrapper-inputs {
          flex-direction: column;

          .wrap-input {
            width: 100%;

            .label-text {
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #002b50;
              margin-left: 4px;
              margin-right: 6px;
            }
            .check-text {
              margin-top: 28px;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #002b50;
              margin-left: -3px;
              margin-right: 6px;

              a {
                color: #26a9f4;
                text-decoration: none;
                margin-left: 0;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
          .wrap-btn {
            width: 100%;
            justify-content: center;
            margin-top: 15px;
          }
        }

        .block-name,
        .block-category,
        .block-type {
          span {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px){
    .wrapper-modal-developer {

      &__head {
        padding: 15px;

        h2 {
          font-size: 18px;
          line-height: 25px;
        }
      }

      &__body {
        padding: 15px;
        .block-category {
          margin-bottom: 20px;
          margin-top: -10px;
        }

        .block-type {
          .v-input--radio-group.v-input--radio-group--row .v-radio {
            margin-bottom: 6px;
          }
          .v-input--selection-controls {
            margin-top: 10px;
            padding-top: 0;
          }
        }

        .wrapper-inputs {
          .wrap-input {
            margin-top: -15px;
          }
        }
      }
    }
  }


</style>