<template>
  <div>
    <div class="d-flex justify-space-between list" v-on:click="(active_id== item.to || (!(['notice', 'support']).includes(item.to) && item.public_id== active_id)) ? $emit('updateType', 'messenger_messenger') : openMessages(item)">
      <v-list color="transparent" v-if="(['notice', 'support']).includes(item.to)" :class="item.to">
        <v-list-item class="px-0">
          <div class="wrap-img">
            <div class="block-img">
              <!--                <img :src="'/assets/users/'+((auth.photo) ? auth.photo : 'a-default.svg')" />-->
              <img :src="'/dist/images/frontend/chat_'+item.to+'.svg'"/>
            </div>
            <div class="online" v-if="item.to== 'support'"></div>
          </div>
          <v-list-item-content>
            <v-list-item-title>{{(item.to== 'support') ? 'Support center' : 'System notice'}}</v-list-item-title>
            <v-list-item-subtitle v-if="item.message">{{decodeEmoji(item.message)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list color="transparent" v-else>
        <v-list-item class="px-0">
          <div class="wrap-img" :class="(item.pro) ? 'user-pro-img' : ''">
            <div class="block-img">
              <!--                <img :src="'/assets/users/'+((auth.photo) ? auth.photo : 'a-default.svg')" />-->
              <img v-if="item.photo && typeof item.photo == 'object'" :src="item.photo[40]" />
              <img v-else-if="item.photo" :src="item.photo" />
              <span v-else class="photo" :style="{'background': item.bg_color}">{{item.name.substr(0,1)}}</span>
            </div>
            <div class="online" v-if="now_date < date"></div>
          </div>
          <v-list-item-content>
            <v-list-item-title :class="(item.pro) ? 'user-pro' : ''">{{item.name +' '+((item.last_name) ? item.last_name : '')}}</v-list-item-title>
            <v-list-item-subtitle v-if="item.message">{{decodeEmoji(item.message)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="list-left">
        <p v-if="item.updated_at">{{$timeAgo(item.updated_at)}}</p>
        <span class="" v-if="item.count > 0">{{item.count}}</span>
      </div>
    </div>
  </div>
</template>

<script>
    import {emojis} from "vue-chat-emoji";
    import moment from "moment";

    export default {
        name: 'MessengerUsersListItem',
        data () {
            return {
                search: null,
                now_date: moment.utc(new Date()).format('X'),
                date: (this.item.online) ? moment.utc(this.item.online).format('X') : null,
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            }
        },
        props: {
            item: {
                type: Object
            },
            active_id: {
                type: Number
            },
            modal_type: {
                type: String
            }
        },
        methods: {
            openMessages: function(item) {
                this.$emit('openMessages', item);
            },
            decodeEmoji(mess) {
                return emojis.decodeEmoji(mess);
            }
            // searchUser: function() {
            //     let search= this.search;
            //
            //     const response ={
            //         search: search
            //     };
            //     this.$http.post("ajax/messenger/user", response)
            //         .then(response=> {
            //             return response.json()
            //         })
            //         .then(()=> {
            //
            //         }).catch(err => {
            //         this.submitDisabled= false;
            //         let response= this.httpHandler(err);
            //         if(response.error) {
            //             this.formValidate = response.error.message;
            //         }
            //     });
            // },
        },
        components: {


        },

    }
</script>
