<template>
    <div class="quest-list">
        <router-link :to="{name: 'quest', params: {'id': item.id, lang: $store.getters.getLang}}">
            <div class="list">
                <div class="top-list">
                    <div class="left">
                        <div class="img" :style="{'backgroundImage': 'url(' +item.icon + ')'}">
                            <!--                    <img :src="item.icon">-->
                        </div>
                    </div>
                    <div class="right">
                            <div class="name">
                                <p class="title-name">{{__(item.name)}}</p>
                                <div>
                                    <p v-if="item.pro" class="pro">{{__('Pro')}}</p>
                                    <p v-else-if="item.discord" class="discord">{{__('Discord')}}</p>
                                    <p v-else-if="item.type == 1" class="all">{{__('One-time')}}</p>
                                    <p v-else-if="item.type == 3" class="all">{{__('Daily')}}</p>
                                    <p v-else class="all">{{__('Monthly')}}</p>
                                    <img src="/dist/images/frontend/users.svg" alt="">
                                    <span>{{item.quest_user_count}}</span>
                                </div>
                            </div>
<!--                            <div class="description">-->
<!--                                <p>{{__(item.description)}}</p>-->
<!--                            </div>-->
                            <div class="progress-quest">
                                <p>0</p>
                                <slot name="right">
                                    <div v-if="!item.user" class="block-info">
                                        <progress style="width: 100%" :value="item.progress" ></progress>
                                    </div>
                                </slot>
                                <p>100</p>
                            </div>
                    </div>
                </div>
                <div class="body-list">
                    <ul>
                        <li>
                           <p>{{__('Price')}}:</p>
                            <div v-if="item.wallet == 1" class="block-cost__desc">
                                <span style="color: #26A9F4;">{{item.price}}</span>
                                <img src="/dist/images/frontend/money.svg" alt />
                            </div>
                            <div v-else class="block-cost__desc">
                                <span style="color: #26A9F4;">{{item.price}}</span>
                                <img src="/dist/images/frontend/light-coin.svg" alt />
                            </div>
                        </li>
                        <li>
                            <p>{{__('Reward')}}:</p>
                            <div v-if="item.real" class="block-reward__desc">
                                <span  style="color: #01DF72;">+{{item.real}}</span>
                                <img src="/dist/images/frontend/money.svg" alt />
                            </div>
                            <div v-else class="block-reward__desc">
                                <span style="color: #01DF72;">+{{item.lite}}</span>
                                <img src="/dist/images/frontend/light-coin.svg" alt />
                            </div>
                        </li>
                        <li>
                            <p>{{__('Rating')}}:</p>
                            <span>+{{item.rating}}</span>
                            <img src="/dist/images/frontend/fire.svg" alt />
                        </li>
                    </ul>
                    <div>
                        <slot name="stat">

                        </slot>
                    </div>

                </div>

            </div>
        </router-link>
<!--       <div class="list">-->
<!--           <div class="top-list">-->
<!--              <div class="left">-->
<!--                  <div class="img"></div>-->
<!--              </div>-->
<!--              <div class="right">-->
<!--                <div class="name">-->
<!--                    <p class="title-name">10 часов игры в Марте месяце</p>-->
<!--                    <div>-->
<!--                        <p class="all">Ежемесячный</p>-->
<!--&lt;!&ndash;                        <p class="pro">PRO</p>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p class="discord">Discord</p>&ndash;&gt;-->
<!--                        <img src="/dist/images/frontend/users.svg" alt="">-->
<!--                        <span>234</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="description">-->
<!--                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et usmod tempor Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et usmod tempor</p>-->
<!--                </div>-->
<!--                <div class="progress-quest">-->
<!--                    <p>0</p>-->
<!--                    <div class="progress-line">-->
<!--                        <v-progress-linear-->
<!--                                color="light-blue"-->
<!--                                height="10"-->
<!--                                value="10"-->
<!--                                striped-->
<!--                        ></v-progress-linear>-->
<!--                    </div>-->
<!--                    <p>100</p>-->
<!--                </div>-->
<!--              </div>-->
<!--           </div>-->
<!--           <div class="body-list">-->
<!--              <ul>-->
<!--                  <li>-->
<!--                     <p>Стоимость:</p>-->
<!--                     <span>1000</span>-->
<!--                     <img src="/dist/images/frontend/light-coin.svg" alt="">-->
<!--                  </li>-->
<!--                  <li>-->
<!--                      <p>Награда:</p>-->
<!--                      <span>1000</span>-->
<!--                      <img src="/dist/images/frontend/light-coin.svg" alt="">-->
<!--                  </li>-->
<!--                  <li>-->
<!--                      <p>Рейтинг:</p>-->
<!--                      <span>100</span>-->
<!--                      <img src="/dist/images/frontend/fire.svg" alt="">-->
<!--                  </li>-->
<!--              </ul>-->
<!--              <div>-->
<!--                  <router-link to="QuestPage">Доступно для прохождения</router-link>-->
<!--              </div> -->
<!--           </div>-->
<!--       </div>-->

    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'ItemQuestsNew',
        data() {
            return {
                submitDisabled: false,
                open_confirm: false,
                date_start: (this.item.start_date) ? moment(this.item.start_date).format('ll') : null,
                date_finish: (this.item.end_date) ? moment(this.item.end_date).format('ll') : null,
                inaccessible_quest: false,
                pro_quest: false,
                discord_quest: false
            }
        },
        computed: {
            lang() {
                return this.$store.state.language.lang;
            },
            auth() {
                return this.$store.state.auth.user;
            },
        },

        methods: {
            ConvertTime(seconds)  {
                let days     = Math.floor(seconds / (24*60*60));
                seconds -= days    * (24*60*60);
                let hours    = Math.floor(seconds / (60*60));
                seconds -= hours   * (60*60);
                let minutes  = Math.floor(seconds / (60));
                seconds -= minutes * (60);
                return ((0<days)?(days+"day "):"") + ((0<hours)?(hours+"h "):"") + ((0 < minutes) ? (minutes + "m ") : "")+ ((0 < seconds) ? (seconds + "s") : "0s");
            },
            ConvertTimeEnd(seconds)  {
                let days     = Math.floor(seconds / (24*60*60));
                seconds -= days    * (24*60*60);
                let hours    = Math.floor(seconds / (60*60));
                seconds -= hours   * (60*60);
                let minutes  = Math.floor(seconds / (60));
                seconds -= minutes * (60);
                return ((0<days)?(days):"") + ((0<hours)?(hours):"") + ((0 < minutes) ? (minutes) : "")+ ((0 < seconds) ? (seconds) : "");
            },
            buyQuest(val) {
                this.submitDisabled = true;
                let id = val;
                const response = {
                    id: id,
                };
                this.$http.post("ajax/quest/buy", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then(() => {
                        let actual_quest= this.$cleanObject(this.actual_quest);

                        this.submitDisabled = false;
                        let index = this.$filterIndexObject(actual_quest, id);
                        actual_quest[index].user= {
                            "progress": "0",
                            "progress_end": actual_quest[index].progress_end
                        }
                        this.$emit('update:actual_quest', actual_quest);
                        this.open_confirm = false;
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.status == '422') {
                            this.open_confirm = false;
                            this.inaccessible_quest = true;
                        }
                    });
            },
            onSubmit() {
                if (this.auth.verified) {
                    if (this.item.pro && !this.auth.pro) {
                        this.pro_quest = true;
                    } else if (this.item.discord && Number(this.item.discord) >= Number(this.auth.discord_lvl)) {
                        this.discord_quest = true;
                    } else {
                        this.open_confirm = true;
                    }
                } else {
                    this.inaccessible_quest = true;
                }
            }
        },
        props: {
            item: {
                type: Object
            },

            actual_quest: {
                type: Array,
            },
        },
        components: {

        }
    }
</script>

<style lang="scss" scoped>
    .block-name {
        display: block;
        text-align: center;
        text-overflow: ellipsis;
        z-index: 1;
        font-size: 13px;
        line-height: 18px;
        color: #002B50;
        padding: 4px 8px;
        background: rgb(255, 255, 255);
        border: 1px solid #ECF4FF;
        border-radius: 6px;
        max-width: 110px;
        overflow: hidden;
        /*white-space: nowrap;*/
    }

</style>