<template>
    <div class="wrap-transfer">
        <div class="wrap-transfer__body">
            <div class="wrapper-payment-select">
                <div class="wrapper-payment-select__item">
                    <span>{{__('Choose a payment system')}}</span>
                    <v-select
                        dense
                        filled
                        solo
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="wallet"
                        :items="coins"
                        v-on:change="changeWallet(wallet)"
                        :filter="customFilter"
                        item-color="white"
                    >
                        <template v-slot:selection="data">
                            <img height="40" class="payment-item__icon mr-4" v-html="data.item.img" :src="data.item.img">
                            {{ data.item.name }}
                            <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
                        </template>
                        <template v-slot:item="data">
                            <template>
                                <div class="wrap-item-select">
                                    <img class="payment-item__icon" v-html="data.item.img" :src="data.item.img">
                                    <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                                </div>
                            </template>
                        </template>
                    </v-select>
<!--                    <span style="margin-top: -8px">{{__('Available for transfer')}}</span>-->
<!--                    <div class="block-person">-->
<!--                        <v-list-item class="px-0">-->
<!--                                <div class="wrap-img" :class="(auth.pro) ? 'user-pro-img' : ''">-->
<!--                                    <div class="block-img">-->
<!--                                        <img v-if="auth.photo && typeof auth.photo == 'object'" :src="auth.photo[40]" />-->
<!--                                        <img v-else-if="auth.photo" :src="auth.photo" />-->
<!--                                        <span v-else class="photo" :style="{'background': auth.bg_color}">{{auth.name.substr(0,1)}}</span>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    <v-list-item-title :class="(auth.pro) ? 'user-pro' : ''">{{auth.name +' '+((auth.last_name) ? auth.last_name : '')}}</v-list-item-title>-->
<!--                                    <v-list-item-subtitle>-->
<!--                                        <img src="/dist/images/frontend/money.svg" alt />-->
<!--                                        <span>{{parseFloat(auth['balance_real'].available).toFixed(2)}}</span>-->
<!--                                        <p>{{__('GOLD')}}</p>-->
<!--                                    </v-list-item-subtitle>-->
<!--                                </div>-->
<!--                        </v-list-item>-->
<!--                    </div>-->
                </div>

                <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.71 1.29L15.71 5.29C15.8488 5.43063 15.9428 5.6092 15.9801 5.80319C16.0175 5.99718 15.9966 6.19789 15.92 6.38C15.845 6.56262 15.7176 6.71895 15.5539 6.82928C15.3901 6.93961 15.1974 6.99902 15 7H1C0.734784 7 0.48043 6.89465 0.292894 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73479 0.105357 5.48043 0.292894 5.2929C0.48043 5.10536 0.734784 5 1 5H12.59L10.29 2.71C10.1017 2.5217 9.99591 2.2663 9.99591 2C9.99591 1.7337 10.1017 1.47831 10.29 1.29C10.4783 1.1017 10.7337 0.995911 11 0.995911C11.1319 0.995911 11.2624 1.02188 11.3842 1.07234C11.5061 1.1228 11.6168 1.19676 11.71 1.29Z" fill="#96AFD1"/>
                    <path d="M11.7113 10.7061L15.7118 6.70883C15.8506 6.56831 15.9446 6.38986 15.9819 6.196C16.0193 6.00214 15.9984 5.80156 15.9218 5.61958C15.8468 5.43708 15.7194 5.28086 15.5556 5.1706C15.3919 5.06035 15.1992 5.00099 15.0017 5H1.00011C0.734867 5 0.480485 5.10529 0.292927 5.29269C0.105369 5.4801 0 5.73428 0 5.99932C0 6.26435 0.105369 6.51853 0.292927 6.70594C0.480485 6.89335 0.734867 6.99864 1.00011 6.99864H12.5914L10.2912 9.28708C10.1028 9.47525 9.99704 9.73047 9.99704 9.99659C9.99704 10.2627 10.1028 10.5179 10.2912 10.7061C10.4795 10.8943 10.7349 11 11.0012 11C11.1331 11 11.2637 10.974 11.3855 10.9236C11.5074 10.8732 11.6181 10.7993 11.7113 10.7061Z" fill="#96AFD1"/>
                </svg>

                <div class="wrapper-payment-select__item">
                    <span>{{__('Transfer recipient')}}</span>
                    <v-autocomplete
                        v-if="wallet == 1 || (wallet == 2 && auth.id == 158912500)"
                        dense
                        filled
                        solo
                        v-model="search"
                        :items="search_result"
                        :filter="customFilter"
                        item-color="white"
                        :label="__('Find friend ...')"
                        @update:search-input="SearchUsers"
                    >
                        <template v-slot:selection="data">
                            <!--<img class="payment-item__icon mr-4" v-html="data.item.photo" :src="data.item.photo">-->
                            <div class="block-img" :class="(data.item.pro) ? 'user-pro-img' : ''">
                                <div class="wrap-img">
                                    <img v-if="data.item.photo && typeof data.item.photo == 'object'" width="40" :src="data.item.photo[40]">
                                    <img v-else-if="data.item.photo" width="40" :src="data.item.photo">
                                    <span v-else class="photo" :style="{'background': data.item.bg_color}">{{data.item.name.substr(0,1)}}</span>
                                </div>
                            </div>
                            <div class="chosen-name" :class="(data.item.pro) ? 'user-pro' : ''">{{data.item.name+" "+((data.item.last_name) ? data.item.last_name : '')}}</div>
                            <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
                        </template>
                        <template v-slot:item="data">
                            <template>
                                <div class="select-transfer">
                                    <!--<img class="payment-item__icon" style="margin: 0 10px 0 0" v-html="data.item.img" :src="data.item.img">-->
                                    <div class="block-img" :class="(data.item.pro) ? 'user-pro-img' : ''">
                                        <div class="wrap-img">
                                            <img v-if="data.item.photo" width="43" :src="data.item.photo">
                                            <span v-else class="photo" :style="{'background': data.item.bg_color}">{{data.item.name.substr(0,1)}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <!--<v-list-item-title v-html="data.item.name" class='menu-item'>-->
                                        <v-list-item-title class='menu-item' :class="(data.item.pro) ? 'user-pro' : ''">
                                            {{data.item.name+" "+((data.item.last_name) ? data.item.last_name : '')}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class='menu-item'>
                                            <img style="margin: 0 4px 3px 0" height="10px" :src="data.item.location_img">
                                            <span style="color: #96AFD1;">{{$getLocation(data.item.location)}}</span>
                                        </v-list-item-subtitle>

                                    </div>
                                </div>
                            </template>
                        </template>
                    </v-autocomplete>

                    <div v-else class="block-person">
                        <v-list-item class="px-0" v-if="search_result && search_result.length">
                                <div class="wrap-img" :class="(search_result[0].pro) ? 'user-pro-img' : ''">
                                    <div class="block-img">
                                        <img v-if="search_result[0].photo && typeof search_result[0].photo == 'object'" :src="search_result[0].photo[40]" />
                                        <img v-else-if="search_result[0].photo" :src="search_result[0].photo" />
                                        <span v-else class="photo" :style="{'background': search_result[0].bg_color}">{{search_result[0].name.substr(0,1)}}</span>
                                    </div>
                                </div>
                                <div>
                                    <v-list-item-title :class="(search_result[0].pro) ? 'user-pro' : ''">{{search_result[0].name +' '+((search_result[0].last_name) ? search_result[0].last_name : '')}}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <img style="margin: 0 4px 0 0; width: 18px; border-radius: 2px" :src="search_result[0].location_img">
                                        <span style="color: #96AFD1;">{{$getLocation(search_result[0].location)}}</span>
                                    </v-list-item-subtitle>
                                </div>
                        </v-list-item>
                    </div>
<!--                        <div class="block-transfer" v-if="search_result && search_result.length">-->
<!--                            <div class="block-img" :class="(search_result[0].pro) ? 'user-pro-img' : ''">-->
<!--                                <div class="wrap-img">-->
<!--                                    <img v-if="search_result[0].photo" width="43" :src="search_result[0].photo">-->
<!--                                    <span v-else class="photo" :style="{'background': search_result[0].bg_color}">{{search_result[0].name.substr(0,1)}}</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="block-info">-->
<!--                                <p :class="(search_result[0].pro) ? 'user-pro' : ''">-->
<!--                                    {{search_result[0].name+" "+((search_result[0].last_name) ? search_result[0].last_name : '')}}-->
<!--                                </p>-->
<!--                                <div class='block-info__subtitle'>-->
<!--                                    <img style="margin: 0 4px 3px 0" height="10px" :src="search_result[0].location_img">-->
<!--                                    <span style="color: #96AFD1;">{{$getLocation(search_result[0].location)}}</span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->


                    <span class="error-text" v-if="formValidate.transfer_id && !transfer_id">{{__('Required')}}</span>
                </div>
            </div>

            <div class="wrapper-inputs">
                <div class="wrapper-inputs__item">
                    <div class="d-flex justify-content-between">
                        <p class="text text--label">{{__('Withdrawal amount')}}</p>
                        <p class="text text--label">{{__((wallet== 1) ? 'Min 1 GOLD' : 'Min 1 MIND')}}</p>
                    </div>
                    <div class="wrap-input">
                        <InputLabelTextDefault
                                style="width: 100%"
                                color="balance"
                                v-model="calc_give"
                                @input="calcSum($event, 'give')"
                                @change="updateValidate('calc_give')"
                                v-bind:errors="formValidate.calc_give"
                                type="number"
                                :placeholder="__('0')"
                        >
                            <template v-slot:right>
                                <div class="currency">
                                    <span><span>{{__((wallet== 1) ? 'GOLD' : 'MIND')}}</span></span>
                                </div>
                            </template>
                        </InputLabelTextDefault>
                        <!--<p class="text" style="font-size: 13px; text-align: right">Max {{user_balance.balance_real.available}}</p>-->
                    </div>
                </div>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.71 9.29L17.71 5.29C17.6168 5.19676 17.5061 5.1228 17.3843 5.07234C17.2624 5.02188 17.1319 4.99591 17 4.99591C16.7337 4.99591 16.4783 5.1017 16.29 5.29C16.1017 5.47831 15.9959 5.7337 15.9959 6C15.9959 6.2663 16.1017 6.5217 16.29 6.71L18.59 9H7.00002C6.7348 9 6.48045 9.10536 6.29291 9.2929C6.10537 9.48043 6.00002 9.73479 6.00002 10C6.00002 10.2652 6.10537 10.5196 6.29291 10.7071C6.48045 10.8946 6.7348 11 7.00002 11H21C21.1974 10.999 21.3902 10.9396 21.5539 10.8293C21.7176 10.7189 21.845 10.5626 21.92 10.38C21.9966 10.1979 22.0175 9.99718 21.9801 9.80319C21.9428 9.6092 21.8488 9.43063 21.71 9.29ZM17 13H3.00002C2.80259 13.001 2.60988 13.0604 2.44616 13.1707C2.28244 13.2811 2.15504 13.4374 2.08002 13.62C2.00344 13.8021 1.98252 14.0028 2.01989 14.1968C2.05726 14.3908 2.15126 14.5694 2.29002 14.71L6.29002 18.71C6.38298 18.8037 6.49358 18.8781 6.61544 18.9289C6.7373 18.9797 6.86801 19.0058 7.00002 19.0058C7.13203 19.0058 7.26274 18.9797 7.38459 18.9289C7.50645 18.8781 7.61705 18.8037 7.71002 18.71C7.80375 18.617 7.87814 18.5064 7.92891 18.3846C7.97968 18.2627 8.00582 18.132 8.00582 18C8.00582 17.868 7.97968 17.7373 7.92891 17.6154C7.87814 17.4936 7.80375 17.383 7.71002 17.29L5.41002 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8947 14.5196 18 14.2652 18 14C18 13.7348 17.8947 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13Z" fill="#96AFD1"/>
                </svg>

                <div class="wrapper-inputs__item">
                    <p class="text text--label">{{__('You receive')}}</p>
                    <InputLabelTextDefault
                            color="balance"
                            v-model="calc_get"
                            v-bind:errors="formValidate.calc_get"
                            @input="calcSum($event, 'get')"
                            @change="updateValidate('calc_get')"
                            type="number"
                            :placeholder="__('0')"
                    >
                        <template v-slot:right>
                            <div class="currency">
                                <span>{{__((wallet== 1) ? 'GOLD' : 'MIND')}}</span>
                            </div>
                        </template>
                    </InputLabelTextDefault>
                </div>
            </div>
            <div class="block-bottom">
                <BaseButton
                        width="175"
                        :loading="submitDisabled"
                        v-on:click="sendTransfer"
                >
                    <span>{{__('Send')}}</span>
                </BaseButton>

                <div class="block-bottom__info">
                    <p style="color: #00C443">{{__('Will be charged')}} {{calc_give}} {{__((wallet== 1) ? 'GOLD' : 'MIND')}}</p>
                    <p>{{__('Payment system commission 0%')}}</p>
                </div>
            </div>
            <ModalDefault v-if="modal_cancel" v-bind:modal_cancel.sync="modal_cancel" :width="400">
                <div class="wrap-modal">
                    <div class="wrap-modal__head">
                        {{__('Transfer Confirmation')}}
                    </div>
                    <div class="wrap-modal__body">
                        <h5>{{__('Are you sure you want to make a translation?')}}</h5>
                                <div class="block-row">
                                    <span>{{__('Recipient')}}</span>
                                    <div class="block-img">
                                        <img v-if="search.photo" :src="search.photo" />
                                        <span v-else class="photo" :style="{'background': search.bg_color}">{{search.name.substr(0,1)}}</span>
                                    </div>
                                    <div>
                                        <router-link :to="{name: 'profile', params: {id: search.public_id, lang: $store.getters.getLang}}">{{search.name+" "+((search.last_name) ? search.last_name : '')}}</router-link>
                                        <span class="block-id">ID {{search.id}}</span>
                                    </div>
                                </div>

<!--                                <p class="block-row"><span>{{__('Translation request:')}}</span>-->
<!--                                    #{{transfer_id}}-->
<!--                                </p>-->

                                <p class="block-row" :style="wallet == 1 ? {'color': '#FDAE02'} : {'color': '#DBE9FB'}"><span>{{__('Amount:')}}</span>
                                    <img v-if="wallet == 1" class="icon-money" src="/dist/images/frontend/money.svg" alt />
                                    <img v-else class="icon-money" src="/dist/images/frontend/light-coin.svg" alt />
                                    {{primNum(calc_give)}}
                                </p>
                        <div class="wrap-btn">
                            <BaseButton
                                width="100"
                                class="mr-5"
                                v-on:click="onSubmit"
                                :loading="submitDisabled"
                            >
                                <span>{{__('Yes')}}</span>
                            </BaseButton>

                            <v-btn
                                    text
                                    class="btn-cancel"
                                    v-on:click="modal_cancel = false"
                                    v-ripple="{ class:'secondary--text'}"
                            >
                                <span>{{__('No')}}</span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </ModalDefault>
        </div>
    </div>
</template>

<script>

    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {decimal, required, minValue} from "vuelidate/lib/validators";
    import ModalDefault from "../../modules/ModalDefault";
    export default {
        name: 'Withdraws',
        data () {
            return {
                payment: this.payments[0],
                commission: '2.9',
                formValidate: {
                    calc_give: null,
                    calc_get: null,
                    transfer_id: null,
                },
                calc_give: null,
                calc_get: null,
                transfer_id: null,
                amount_currency: null,
                submitDisabled: false,
                users: null,
                search: null,
                search_result: [],
                modal_cancel: false,
                coins: [
                    {
                        name: "USDT Coin",
                        img: "/dist/images/frontend/money.svg",
                        value: 1
                    },
                    // {
                    //     name: "MIND Coin",
                    //     img: "/dist/images/frontend/light-coin.svg",
                    //     value: 2
                    // }
                ],
                wallet: 1,
            }
        },
        validations() {
            return {
                calc_give: {
                    required,
                    decimal,
                    minValue: minValue(1)
                },
                calc_get: {
                    required,
                    decimal,
                    minValue: minValue(1)
                },
                transfer_id: {
                    required,
                }
            }
        },
        props: {
            transfers: {
                type: Object
            },
            payments: {
                type: Array
            },
            url: {
                type: String
            }

        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            }
        },
        asyncComputed: {

        },
        watch:{
            payment: function () {
                this.calcSum(this.calc_give, 'give');
            },

            search: function (val) {
                if (val) {
                    this.transfer_id = val.id
                } else {
                    this.transfer_id = null
                }
            },
        },
        methods: {
            updateValidate(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },

            changeWallet(val) {
                if (val == 2 && this.auth.id != 158912500) {

                    this.search_result = [];

                    this.SearchUsers('158912500');
                } else {
                    this.search = null;
                }
            },

            sendTransfer () {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        calc_get:[],
                        calc_give:[],
                        transfer_id:[],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.modal_cancel = true;
                }
            },
            onSubmit () {
                // let valid = this.$v;
                //
                // if (valid.$invalid) {
                //     let massages = {
                //         calc_get:[],
                //         calc_give:[],
                //         transfer_id:[],
                //     };
                //     this.formValidate = this.$formValidate(massages, valid);
                // } else {
                    this.submitDisabled = true;
                    let response= {
                        "amount": this.calc_give,
                        "wallet": this.wallet,
                        "transfer_id": this.transfer_id,
                    };

                    if(this.auth.google_two_step == 1) {
                        this.$emit('update:url', "ajax/transfer/create");
                        this.$emit('update:modal_confirmation_2fa', response);
                        this.submitDisabled = false;
                        this.modal_cancel = false;
                    } else {
                        this.$http.post("ajax/transfer/create", response)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if(this.$checkAuth(response)) {
                                    // response= {
                                    //     "data": {
                                    //         "status": "success",
                                    //             "info": {
                                    //             "current_page": 1,
                                    //                 "data": [
                                    //                     {
                                    //                         "id": 2064,
                                    //                         "user_id": 2,
                                    //                         "frozen": 0,
                                    //                         "wallet": 2,
                                    //                         "amount": "2.00000000",
                                    //                         "amount_unit": "2.00000000",
                                    //                         "rate_amount": "2.00000000",
                                    //                         "income": 2,
                                    //                         "transfer_user_id": 4,
                                    //                         "commission": "0.00000000",
                                    //                         "lvl": null,
                                    //                         "opp_type": 8,
                                    //                         "game_played_id": null,
                                    //                         "achiev_id": null,
                                    //                         "quest_id": null,
                                    //                         "game_id": null,
                                    //                         "payment_id": null,
                                    //                         "tx": null,
                                    //                         "status": 1,
                                    //                         "confirm": 0,
                                    //                         "created_at": "2021-07-05 14:12:17",
                                    //                         "transfer": {
                                    //                             "id": 4,
                                    //                             "public_id": "253950408222",
                                    //                             "email": "user@gmail.com",
                                    //                             "name": "First221",
                                    //                             "last_name": "1Last",
                                    //                             "photo": "/assets/users/a-1612773345SD6hwEaGhB.png",
                                    //                             "bg_color": "#80dbff",
                                    //                             "location": "KH",
                                    //                             "location_img": "/dist/images/flags/kh.png",
                                    //                             "city": "Svay Chek",
                                    //                             "online": "2021/04/16 10:08:36",
                                    //                             "rating_points": 1050,
                                    //                             "vip": 0,
                                    //                             "plays": 0,
                                    //                             "lang": "ru"
                                    //                         }
                                    //                     },
                                    //                     {
                                    //                         "id": 2062,
                                    //                         "user_id": 2,
                                    //                         "frozen": 0,
                                    //                         "wallet": 1,
                                    //                         "amount": "1.00000000",
                                    //                         "amount_unit": "1.00000000",
                                    //                         "rate_amount": "1.00000000",
                                    //                         "income": 2,
                                    //                         "transfer_user_id": 1,
                                    //                         "commission": "0.00000000",
                                    //                         "lvl": null,
                                    //                         "opp_type": 8,
                                    //                         "game_played_id": null,
                                    //                         "achiev_id": null,
                                    //                         "quest_id": null,
                                    //                         "game_id": null,
                                    //                         "payment_id": null,
                                    //                         "tx": null,
                                    //                         "status": 1,
                                    //                         "confirm": 0,
                                    //                         "created_at": "2021-07-05 12:15:24",
                                    //                         "transfer": {
                                    //                             "id": 1,
                                    //                             "public_id": "volkonsky",
                                    //                             "email": "vladblackdog@gmail.com",
                                    //                             "name": "Vladyslav",
                                    //                             "last_name": "Volkonsky",
                                    //                             "photo": "/assets/users/a-1601498151BVVxvEEhDv.png",
                                    //                             "bg_color": "#f84138",
                                    //                             "location": "UA",
                                    //                             "location_img": "/dist/images/flags/ua.png",
                                    //                             "city": "Cherniyiv",
                                    //                             "online": "2021/04/16 06:45:03",
                                    //                             "rating_points": 78225,
                                    //                             "vip": 0,
                                    //                             "plays": 0,
                                    //                             "lang": "ru"
                                    //                         }
                                    //                     }
                                    //                 ],
                                    //                 "first_page_url": "https://mindplay.loc/ajax/transfer/paginate?page=1",
                                    //                 "from": 1,
                                    //                 "last_page": 1,
                                    //                 "last_page_url": "https://mindplay.loc/ajax/transfer/paginate?page=1",
                                    //                 "next_page_url": null,
                                    //                 "path": "https://mindplay.loc/ajax/transfer/paginate",
                                    //                 "per_page": 24,
                                    //                 "prev_page_url": null,
                                    //                 "to": 2,
                                    //                 "total": 2
                                    //         }
                                    //     },
                                    //     "auth": true,
                                    //     "status": 200
                                    // },
                                    this.modal_cancel = false;
                                    this.submitDisabled = false;
                                    this.successForm = true;
                                    // this.$emit('updateTransfer', response.data.info.data);
                                    this.$emit('updateTransfer');
                                    this.calc_give = null;
                                    this.calc_get = null;
                                    this.$emit('update:url', "ajax/transfer/create")
                                }
                            })
                            .catch((err) => {
                                this.submitDisabled = false;
                                let response = this.httpHandler(err);
                                if (response.error) {
                                    this.formValidate = response.error.message;
                                }
                            });
                    }
                // }
            },
            customFilter (item, queryText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1
            },
            calcSum(val, type= 'give') {
                if(type== 'give') {
                    this.calc_get = this.calc_give;
                } else {
                    this.calc_give = this.calc_get;
                }
            },

            SearchUsers(val) {
                let search = val;
                const response = {
                    search: (search) ? search : null,
                };
                this.$http.post("ajax/transfer/search", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            //
                            // response = {
                            //     "data": {
                            //         "users": {
                            //             "current_page": 1,
                            //             "data": [
                            //                 {
                            //                     "id": 123,
                            //                     "public_id": "volkonsky",
                            //                     "ref_id": null,
                            //                     "name": "Vladyslav",
                            //                     "last_name": "Volkonsky",
                            //                     "email": "vladblackdog@gmail.com",
                            //                     "photo": null,
                            //                     "bg_color": "#f84138",
                            //                     "location": "UA",
                            //                     "city": "Cherniyiv",
                            //                     "location_img": "/dist/images/flags/ua.png",
                            //                     "ready_play": 1,
                            //                     "rating_points": 78225,
                            //                     "email_two_step": 0,
                            //                     "email_secret": null,
                            //                     "verified": 0,
                            //                     "google_two_step": 0,
                            //                     "google2fa_secret": "WZ3X57ZYTRJD47WH",
                            //                     "email_verified_at": null,
                            //                     "fake": 0,
                            //                     "block": null,
                            //                     "online": "2021-04-16 06:45:03",
                            //                     "plays": 0,
                            //                     "session_id": "bYXYbPlIg9QEOu3bPSQufwQuvwIrztJFgpqqkB4f",
                            //                     "from_app": 0,
                            //                     "vip": 0,
                            //                     "password": "$2y$10$C/8gLymuH8cPDz92mpCC6OJrXZejxECeB5XTpUOyHvfUb5rmH80Vi",
                            //                     "remember_token": null,
                            //                     "lang": "ru",
                            //                     "created_at": "2020-04-11 18:39:00",
                            //                     "updated_at": "2021-04-16 06:45:03"
                            //                 },
                            //                 {
                            //                     "id": 2,
                            //                     "public_id": "happy",
                            //                     "ref_id": null,
                            //                     "name": "Happy",
                            //                     "last_name": "Proger",
                            //                     "email": "happyproger1@gmail.com",
                            //                     "photo": "/assets/users/a-1609759324m90pBdwJSb.png",
                            //                     "bg_color": "#f9851a",
                            //                     "location": "DZ",
                            //                     "city": "Zeribet el Oued",
                            //                     "location_img": "/dist/images/flags/dz.png",
                            //                     "ready_play": 1,
                            //                     "rating_points": 1079,
                            //                     "email_two_step": 0,
                            //                     "email_secret": null,
                            //                     "verified": 0,
                            //                     "google_two_step": 0,
                            //                     "google2fa_secret": "7YGDEDWKU36QFOWO",
                            //                     "email_verified_at": null,
                            //                     "fake": 1,
                            //                     "block": null,
                            //                     "online": "2021-07-05 14:13:51",
                            //                     "plays": 0,
                            //                     "session_id": "b2tHl3vi6YjYoOlqrrFA61e9zM8ChFE6o14dxXEl",
                            //                     "from_app": 0,
                            //                     "vip": 0,
                            //                     "password": "$2y$10$jaNU1W5U6MB4kEpyuVRqdum82O1vpK774DS7WyVbXAROJpczbb0wW",
                            //                     "remember_token": null,
                            //                     "lang": "ru",
                            //                     "created_at": "2020-04-11 18:16:31",
                            //                     "updated_at": "2021-07-05 12:13:51"
                            //                 },
                            //                 {
                            //                     "id": 3,
                            //                     "public_id": "1838849874",
                            //                     "ref_id": null,
                            //                     "name": "zxcqwqwwwqwqwqwqwqwqwqwqwqwqwqwqwqww",
                            //                     "last_name": "zxcqwqwqwqwqwqwqwqwqwqwqwqwqwqqw",
                            //                     "email": "vitaliykurach86@gmail.com",
                            //                     "photo": "/assets/users/a-16167708047STVtTdszV.png",
                            //                     "bg_color": "#FCDD01",
                            //                     "location": "United Kingdom",
                            //                     "city": null,
                            //                     "location_img": "/dist/images/flags/de.png",
                            //                     "ready_play": 0,
                            //                     "rating_points": 40,
                            //                     "email_two_step": 0,
                            //                     "email_secret": null,
                            //                     "verified": 0,
                            //                     "google_two_step": 0,
                            //                     "google2fa_secret": "U5STU7ZBT37XLKAL",
                            //                     "email_verified_at": null,
                            //                     "fake": 0,
                            //                     "block": null,
                            //                     "online": "2021-04-13 12:57:12",
                            //                     "plays": 0,
                            //                     "session_id": "uqhXtljZFHCgyP5Sqe0qqIehKGZ6BLMfk0IoFm4j",
                            //                     "from_app": 0,
                            //                     "vip": 0,
                            //                     "password": "$2y$10$dv6jutSCDfVXjoWa3CXgfuwjUDLd9ZU.LPwd7LIgVuKO6t/UDUgDe",
                            //                     "remember_token": null,
                            //                     "lang": "en",
                            //                     "created_at": "2020-04-22 07:57:32",
                            //                     "updated_at": "2021-04-13 10:57:12"
                            //                 }
                            //             ],
                            //             "first_page_url": "https://mindplay.loc/ajax/transfer/search?page=1",
                            //             "from": 1,
                            //             "last_page": 28,
                            //             "last_page_url": "https://mindplay.loc/ajax/transfer/search?page=28",
                            //             "next_page_url": "https://mindplay.loc/ajax/transfer/search?page=2",
                            //             "path": "https://mindplay.loc/ajax/transfer/search",
                            //             "per_page": 3,
                            //             "prev_page_url": null,
                            //             "to": 3,
                            //             "total": 84
                            //         }
                            //     },
                            //     "auth": true,
                            //     "status": 200
                            // }
                            //
                            this.search_result = response.data.users.data;

                            if (this.wallet == 2 && this.auth.id != 158912500) {
                                this.search = response.data.users.data[0];
                                this.transfer_id = response.data.users.data[0].id;
                            }
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
            }
        },
        components: {
            InputLabelTextDefault,
            ModalDefault
        },

    }
</script>

<style lang="scss">

    .dark {
        .wrap-transfer {
            background: #171827;
            &__body {
                background: #171827;

                .wrapper-payment-select {
                    span {
                        color: #96AFD1;
                    }

                    .block-person {
                        background: #181818;
                        border-color: transparent;

                        .v-list-item {
                            &__title {
                                color: #fff;
                            }
                            &__subtitle {
                                color: #96AFD1;

                                p {
                                    color: #96AFD1;
                                }
                            }
                        }
                    }
                }

                .block-bottom {
                    p {
                        color: #96AFD1;
                    }
                }
            }
            .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
                background: #181818;
                border-color: transparent;

                &:hover {
                    border-color: #0F9AFF;
                    background: #181818!important;
                }
            }

            .text {
                &--label {
                    color: #96AFD1;
                }
            }

            .currency {
                color: #96AFD1;
            }
        }
    }

    .wrap-transfer {
        background-color: #FFFFFF;
        border-radius: 4px;
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;

        &__head {
            display: flex;
            align-items: center;
            padding: 20px;
            border-bottom: 2px solid #ecf4ff;

            svg {
                margin-right: 18px;
            }


            p {
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
                margin: 0!important;
            }
        }

        &__body {
            padding: 20px;

            .wrapper-payment-select {
                display: flex;
                align-items: center;
                margin-bottom: 27px;

                &__item {
                    width: 50%;
                    position: relative;
                }

                svg {
                    margin: 28px 20px 0;
                    flex-shrink: 0;
                }

                span {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: #6886AF;
                    margin-bottom: 12px;
                    display: block;
                }

                .block-person {
                    display: flex;
                    align-items: center;
                    background: #FFFFFF;
                    padding: 0 19px 0 17px;
                    border: 1px solid #EBEBEB;
                    box-sizing: border-box;
                    border-radius: 4px;
                    box-shadow: none;
                    height: 66px;

                    .v-list-item {
                        .wrap-img {
                            position: relative;
                            margin-right: 10px;
                        }

                        .block-img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;


                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                            }

                            .photo {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                display: flex;
                                color: #FFFFFF;
                                justify-content: center;
                                align-items: center;
                                text-decoration: none;
                                font-size: 18px;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }

                        &__title {
                            max-width: 150px;
                            width: 100%;
                            font-size: 15px;
                            line-height: 16px;
                            letter-spacing: 0.4px;
                            color: #002B50;
                            font-weight: 400;
                            text-overflow: ellipsis;
                            margin-bottom: 4px;
                        }

                        &__subtitle {
                           display: flex;
                           align-items: center;

                            img {
                                margin-right: 6px;
                                width: 20px;
                            }

                            span {
                                font-size: 15px;
                                line-height: 17px;
                                letter-spacing: 0.4px;
                                color: #FDAE02;
                                margin: 0 10px 0 0;
                                display: block;
                            }

                            p {
                                font-size: 15px;
                                line-height: 17px;
                                letter-spacing: 0.4px;
                                color: #6886AF;
                                margin: 0;
                            }

                        }

                        &__action {
                            -ms-flex-item-align: center;
                            align-self: center;
                            padding: 0;

                        }

                        &__avatar {
                            box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
                        }
                    }
                }

                .error-text {
                    font-size: 10px;
                    position: absolute;
                    bottom: -22px;
                    left: 0;
                    color: #FF4B55;
                }
            }

            .wrapper-inputs {
                display: flex;
                align-items: center;
                margin-bottom: 30px;

                &__item {
                    width: 50%;
                }

                svg {
                    margin: 35px 26px 0;
                }
            }

            .wrapper-block-info {
                display: flex;
                align-items: center;
                margin-bottom: 26px;
                margin-top: 30px;

                .block-info {
                    margin-right: 30px;

                    &:last-child {
                        margin-right: 0;
                    }

                    p {
                        font-size: 14px;
                        line-height: 19px;
                        color: #6886AF;
                        margin-bottom: 2px;
                    }

                    span {
                        font-size: 20px;
                        line-height: 27px;
                        color: #26A9F4;
                    }
                }
            }

            .block-bottom {
                display: flex;
                align-items: center;
                margin-top: 40px;

                &__info {
                    margin-left: 20px;
                }

                p {
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0.001em;
                    color: #6886AF;
                    margin: 0;

                    a {
                        color: #26A9F4;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .text {
            font-weight: 400;;
            font-size: 15px;
            line-height: 20px;
            color: #002B50;
            margin:0 0 10px;

            &--label {
                /*margin-top: 16px;*/
                margin-bottom: -5px;
                position: relative;
                color: #6886AF;
                z-index: +1;
            }
        }
        .v-text-field.v-text-field--enclosed .v-text-field__details {
            margin: 0;
        }
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
            background: #FFFFFF;
            height: 66px;
            padding: 12px 19px 12px 17px;
            border: 1px solid #EBEBEB;
            box-sizing: border-box;
            border-radius: 8px;
            box-shadow: none;
            font-weight: 400;;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #002B50;
            position: relative;

            .v-icon.v-icon {
                font-size: 0;
            }
        }
        .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
            background: transparent;
        }

        .chevron-icon {
            position: absolute;
            right: 0;
            top: 16px;
            transition-duration: .3s;
        }

        .v-select--is-menu-active {
            .chevron-icon {
                transform: rotate(180deg);
                transition-duration: .3s;
            }
        }

        .wrap-input {
            //margin-top: 14px;
        }

        .wrap-price__view {
            position: relative;
            display: flex;
            align-items: center;
            background: #f0f6fe;
            border-radius: 8px;
            min-width: 180px;
            height: 40px;
            margin-left: 10px;
            margin-bottom: 20px;

            .block-price-view {
                display: flex;
                width: 100%;
                align-items: center;


                .price-text {
                    margin-left: 12px;
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    &__wrap {
                        display: flex;
                        align-items: center;


                        span {
                            min-width: 80px;
                            font-size: 14px;
                        }
                    }

                    .pic {
                        margin-top: 1px;
                        height: 14px;
                        margin-right: 6px;
                    }
                }
                &.active + .price-btn {
                    left: calc(100% - 40px);
                }
            }
            .price-btn {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                height: 40px;
                background-color: #d8e5f7;
                border-radius: 8px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                transition: 0.7s;
                pointer-events: none;
            }
        }

        .currency {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 25%;
            font-weight: 400;;
            font-size: 14px;
            line-height: 18px;
            color: #6886AF;
            cursor: default;
        }

        .text-charged {
            margin: 21px 0 0;
            font-weight: 400;;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            color: #6886AF;
            display: flex;
            justify-content: center;

            img {
                margin: 0 3px 0 7px;
                height: 14px;
            }

            span {
                color: #00C443;
                font-weight: bold;
                display: flex;
                align-items: center;
            }
        }
        .text-commission {
            font-weight: 400;;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            color: #6886AF;
            margin: 0 0 21px;
        }

        .block-img {
            position: relative;
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        .wrap-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .photo {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        .chosen-name {
            max-width: 120px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    @media screen and (max-width: 600px){
        .wrap-transfer {
            &__body {
                padding: 15px;
                .wrapper-payment-select {
                    flex-direction: column;
                    margin-bottom: 0px;

                    svg {
                        display: none;
                    }

                    &__item {
                        width: 100%;
                        margin-bottom: 10px;

                        span {
                            margin-top: 0!important;
                        }
                    }
                }

                .wrapper-inputs {
                    flex-direction: column;
                    margin-bottom: 10px;

                    svg {
                        display: none;
                    }

                    &__item {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }
                .wrapper-inputs-tag {
                    display: flex;
                    flex-direction: column;
                    &__item {
                        &:first-child {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 15px;
                        }

                        &:last-child {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 500px){
        .wrap-transfer {
            .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
                padding: 3px 12px 0 10px;
                height: 50px;

                .v-icon.v-icon {
                    font-size: 0;
                }

                .payment-item__icon {
                    height: 30px;
                    width: 30px;
                    margin-right: 8px!important;
                }
            }
            &__body {
                .block-bottom {
                    flex-direction: column;
                    margin-top: 20px;

                    &__info {
                        margin: 10px 0 0;

                        p {
                            text-align: center;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
                .wrapper-payment-select {
                    span {
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .block-person {
                        padding: 0 12px 0 10px;
                        height: 50px;

                        .v-list-item {
                            .wrap-img {
                                width: 30px;
                                height: 30px;
                                position: relative;
                                margin-right: 8px;
                            }

                            .block-img {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;


                                img {
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 50%;
                                }

                                .photo {
                                    width: 30px;
                                    height: 30px;
                                    font-size: 16px;
                                }
                            }

                            &__title {
                                max-width: 135px;
                                width: 100%;
                                font-size: 13px;
                                line-height: 14px;
                                margin-bottom: 4px;
                            }

                            &__subtitle {
                                display: flex;
                                align-items: center;

                                img {
                                    margin-right: 4px;
                                    height: 12px;
                                }

                                span {
                                    font-size: 13px;
                                    line-height: 14px;
                                    letter-spacing: 0.4px;
                                    color: #FDAE02;
                                    margin: 0 6px 0 0;
                                    display: block;
                                }

                                p {
                                    font-size: 13px;
                                    line-height: 14px;
                                    letter-spacing: 0.4px;
                                    color: #6886AF;
                                    margin: 0;
                                }

                            }

                            &__action {
                                -ms-flex-item-align: center;
                                align-self: center;
                                padding: 0;

                            }

                            &__avatar {
                                box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
                            }
                        }
                    }
                }
            }
            .text {
                font-size: 14px;
                line-height: 18px;
            }

            .block-img {
                position: relative;
                width: 30px;
                height: 30px;
                margin-right: 8px;
            }

            .wrap-img {
                width: 30px;
                height: 30px;
                border-radius: 50%;

                img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }

                .photo {
                    width: 30px;
                    height: 30px;
                    font-size: 16px;
                }
            }

            .v-select__selections {
                font-size: 14px;
            }
        }
    }

</style>