<template>
  <div class="nft-dev-list">
    <div v-if="nft_list" class="wrap-developers-page">
      <div v-if="nft_list.length">
        <GameNftListItem v-for="(item, key) in nft_list" v-bind:item="item" :key="key"/>
      </div>
      <div v-else class="wrap-notgames">
        <img src="/dist/images/frontend/no-games.svg" alt />
        <div>
          <p>{{__('No have nft!')}}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{__('loading...')}}</p>
    </div>
  </div>
</template>

<script>
  import GameNftListItem from './GameNftListItem';

    export default {
        name: "GameNftList",
        data() {
            return {
                loadingPage: false,
            };
        },

        props: {
            nft_list: {
                type: Array
            },
            next_page_url: {
                type: String
            }
        },
        computed: {},
        asyncComputed: {},
        created: function () {
            this.$removeAllListeners(window,'scroll');

            this.$addListener(window,'scroll', () => {
                this.scrollNft();
            });
        },
        methods: {
            scrollNft: function () {
                const progress =
                    document.documentElement.scrollTop /
                    (document.documentElement.scrollHeight -
                        document.documentElement.clientHeight);
                if (!this.loadingPage && this.next_page_url) {
                    let bottomOfWindow = 0.7 <= progress;
                    if (bottomOfWindow) {
                        this.loadingPage = true;
                        this.$http.get(this.next_page_url)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if(this.$checkAuth(response)) {
                                    response= response.data;
                                    let nft_list = this.$cleanObject(this.nft_list);
                                    nft_list = nft_list.concat(response.data);

                                    this.$emit('update:nft_list', nft_list);
                                    this.$emit('update:next_page_url', response.next_page_url);
                                    this.loadingPage = false;
                                }
                            })
                            .catch((err) => {
                                this.httpHandler(err);
                            });
                    }
                }
            },
        },
        components: {
            GameNftListItem
            // ScrollingContent
        },
    };
</script>