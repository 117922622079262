<template>
  <div>
      <LayoutNavAuth>
          <div class="wrap-tabs">
              <tabs
                  :tabs="side_tabs"
                  :currentTab="currentTab"
                  :wrapper-class="'default-tabs'"
                  :tab-class="'default-tabs__item'"
                  :tab-active-class="'default-tabs__item_active'"
                  :line-class="'default-tabs__active-line'"
                  @onClick="sideChange"
              />
          </div>
          <div class="wrap-select">
              <DropdownDefault
                      class_modal="modal_game"
                      color="select_game"
                      v-bind:dropdown="side_tabs"
                      v-bind:value.sync="side_tabs.value"
                      @updateValue="sideChange"
              >
              </DropdownDefault>
          </div>
          <div slot="line" class="loading"  :style="{width: progress + '%'}"></div>
      </LayoutNavAuth>
    <div class="block-developers-info dashboard-content" v-if="developers">
<!--      <h2 class="subject">{{developers.name}}</h2>-->
      <div class="wrap-info">

<!--        <GameInfoUpdate v-bind:categories="categories" v-bind:developers.sync="developers"></GameInfoUpdate>-->

        <div class="block-edit">
          <div class="block-edit__head" v-if="currentTab == 'informations'">
<!--            <h2 >{{developers.name}}</h2>-->
<!--            <EditGameName v-bind:developers.sync="developers"></EditGameName>-->

            <div class="wrapper-tabs">
                <tabs
                    :tabs="languages"
                    :currentTab="infoTab"
                    value="lang"
                    title="full_lang"
                    :wrapper-class="'default-tabs'"
                    :tab-class="'default-tabs__item'"
                    :tab-active-class="'default-tabs__item_active'"
                    :line-class="'default-tabs__active-line'"
                    @onClick="langChange"
                />
            </div>

          </div>
<!--            v-bind:developers.sync="developers[infoTab]"-->
          <div class="block-info-tabs" :style="(currentTab != 'informations') ? {borderRadius: '4px'} : ''">
              <div v-if="currentTab=='informations'">
                  <GameInfoInformationsNew
                          v-if="form"
                          v-bind:developers.sync="developers"
                          v-bind:form.sync="form"
                          :infoTab.sync="infoTab"
                          @updateForm="updateForm"
                  ></GameInfoInformationsNew>

                  <div class="wrap-info-btn" v-if="JSON.stringify(form) != JSON.stringify(game_translations_all)">
                      <BaseButton
                        v-if="!validate"
                        :loading="submitDisabled"
                        v-on:click="onSubmit"
                      >
                          <span>{{__('Save changes')}}</span>
                      </BaseButton>
                      <BaseButton
                        v-else
                      >
                          <span>{{__('No validate')}}</span>
                      </BaseButton>

                  </div>
              </div>

            <GameInfoSettings
              v-else-if="currentTab=='settings'"
              v-bind:developers.sync="developers"
              v-bind:categories="categories"
            ></GameInfoSettings>

<!--              <GameInfoSettingsOld-->
<!--                      v-else-if="currentTab=='settings1'"-->
<!--                      v-bind:developers.sync="developers"-->
<!--              ></GameInfoSettingsOld>-->

            <GameInfoAttributes
              v-else-if="currentTab=='attributes'"
              v-bind:developers.sync="developers"
            ></GameInfoAttributes>
            <GameInfoManagement
              v-else-if="currentTab=='management'"
              v-bind:developers.sync="developers"
            ></GameInfoManagement>
            <GameInfoNFT
              v-else-if="currentTab=='nft'"
              v-bind:developers.sync="developers"
            ></GameInfoNFT>
            <GameInfoModeration
              v-else-if="currentTab=='moderation'"
              v-bind:developers.sync="developers"
            ></GameInfoModeration>
            <GameInfoDeleted
              v-bind:developers="developers"
              v-else-if="currentTab=='deleted'"
              v-bind:open_delete.sync="open_delete"
            ></GameInfoDeleted>
            <div v-else>{{pageNotFound()}}</div>
          </div>
        </div>

        <div class="block-card" v-if="developers">
            <GameInfoUpdate v-bind:scroll.sync="scroll" v-bind:open_delete.sync="open_delete" v-bind:categories="categories" v-bind:developers.sync="developers"></GameInfoUpdate>
        </div>

          <ModalConfirmActionDefault
          v-if="open_delete"
          v-bind:open="open_delete"
          text="Вы точно хотите удалить?"
          v-bind:result.sync="result_delete"
          v-on:update:result="deleteApp()"
        ></ModalConfirmActionDefault>
      </div>
    </div>
    <div class="dashboard-content" v-else>loading</div>
  </div>
</template>

<script>
import GameInfoUpdate from "./GameInfoUpdate";
// import EditGameName from "./EditGameName";
import GameInfoInformationsNew from "./GameInfoInformationsNew";
import GameInfoManagement from "./GameInfoManagement";
import GameInfoModeration from "./GameInfoModeration";
import GameInfoSettings from "./GameInfoSettings";
import GameInfoNFT from "./GameInfoNFT";
// import GameInfoSettingsOld from "./GameInfoSettingsOld";
import GameInfoAttributes from "./GameInfoAttributes"
import GameInfoDeleted from "./GameInfoDeleted";
import ModalConfirmActionDefault from "../../modules/ModalConfirmActionDefault";
import LayoutNavAuth from "../../modules/LayoutNavAuth";
import DropdownDefault from "../../modules/DropdownDefault";

import _ from 'lodash';

export default {
  name: "GameInfo",
  data() {
    return {
      progress: 0,
      scroll: false,
      game_translations_all: null,
      developers: null,
      categories: null,
      result_delete: null,
      infoTab: 'en',
      open_delete: false,
      info_tabs: [],
      form: null,
      invalid: true,
      validate: false,
      side_tabs: [
        {
          title: "Informations",
          value: "informations",
          // 'exact': true
        },
        {
          title: "Settings",
          value: "settings",
        },
        // {
        //   title: "Settings1",
        //   value: "settings1",
        // },
        {
          title: "Attributes",
          value: "attributes",
        },
        {
          title: "Nft",
          value: "nft",
        },
        {
          title: "Management",
          value: "management",
        },
        {
          title: "Moderation",
          value: "moderation",
        },
        // {
        //   title: "Delete Game",
        //   value: "deleted",
        // },
      ],
      submitDisabled: false,
    };
  },
  computed: {
    currentTab: function () {
      return this.$route.params.current ? this.$route.params.current : this.side_tabs[0].value;
    },
    game_categories() {
      return this.$renameKeys(this.$store.getters.categories, {
        id: "value",
        category: "name",
      });
    },
    languages() {
      return this.$store.state.language.list;
    },

    lang_array() {
      return this.$filterArray(this.developers.name, this.infoTab, 'lang');
    },
  },
  asyncComputed: {
    async_categories() {
      let categories = this.game_categories;

      if (categories) {
        this.categories = categories;
      } else {
        return null;
      }
    },
},
  beforeCreate: function () {
      let timeout = setInterval(() => {
          this.progress += 1;
          if(this.progress > 50) {
              clearInterval(timeout)
          }
      },15);
    this.$http.get("ajax/developers/" + this.$route.params.id)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if(this.$checkAuth(response)) {
            this.developers = response.data;

            let game_translations_all= {};
            for (let i = 0; i < this.languages.length; i++) {
                let lang= this.languages[i];

                game_translations_all[lang.lang]= {
                    "description": null,
                    "lang": lang.lang,
                    "name": null,
                    "rules": null
                }
            }

            Object.assign(game_translations_all, _.keyBy(this.developers.game_translations_all, 'lang'));

            this.game_translations_all= this.form= game_translations_all;
            clearInterval(timeout);
            this.progress = 100;
            setTimeout(() => {
                this.progress = 0;
            }, 500);
        }
      })
      .catch((err) => {
        this.httpHandler(err);
      });
  },
  methods: {
      updateForm(form) {
          this.form = form;

          this.submitSuccess= false;
          let invalid= false;

          _.forEach(form, function (item) {
              if(item.invalid) {
                  invalid= true;
                  return true;
              }
          });
          this.validate= invalid;
      },
      onSubmit: function () {
          this.submitDisabled = true;


          let response= {
              descriptions: {},
              name: {},
              rules: {},
          };

          for(let key in this.form) {
              response['descriptions'][key]= this.form[key].description;
              response['name'][key]= this.form[key].name;
              response['rules'][key]= this.form[key].rules;
          }

          console.log('response');
          console.log(JSON.stringify(response));

          this.$http.post("ajax/developers/" + this.$route.params.id + "/information", response)
              .then((response) => {
                  return response.json();
              })
              .then((response) => {
                  if(this.$checkAuth(response)) {
                      this.submitDisabled = false;
                      this.submitSuccess = true;
                      this.game_translations_all= this.$cleanObject(this.form);
                      this.validate= false;
                      // let developers = this.$cleanObject(this.developers);
                      // developers["name"] = this.name;
                      // developers["category_id"] = this.category;
                      // developers["descriptions"]= this.descriptions,
                      //     developers['description'] = {
                      //         rules: this.rules,
                              // agreement: this.agreement,
                              // policy: this.policy,
                          // }
                      // this.$emit("update:developers", developers);
                  }
              })
              .catch((err) => {
                  this.submitDisabled = false;
                  let response = this.httpHandler(err);
                  if (response.error) {
                      this.formValidate = response.error.message;
                  }
              });
      },

    sideChange(newTab) {
      this.$router.push({
        name: "dev_info_current",
        params: { id: this.$route.params.id, current: newTab, lang: this.$store.getters.getLang },
      });
      this.scrollHeight();
    },
  langChange(newTab) {
        this.infoTab= newTab;
    },
    pageNotFound() {
      this.$router.push({ name: "not_found", params: {lang: this.$store.getters.getLang}});
    },
    deleteApp() {
      this.open_delete = false;
      if (this.result_delete) {
        this.$http
          .get("ajax/developers/" + this.$route.params.id + "/delete")
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
              this.$router.push({name: "developers", params: {lang: this.$store.getters.getLang}});
            }
          })
          .catch((err) => {
            this.httpHandler(err);
          });
      }
    },
      scrollHeight() {
          this.$addListener(window,'scroll', () => {
              if (document.documentElement.scrollTop > 60) {
                  this.scroll = true;
              } else {
                  this.scroll = false;
              }
          });
      },
  },
    created() {
      this.$addListener(window,'scroll', () => {
          if (document.documentElement.scrollTop > 60) {
              this.scroll = true;
          } else {
              this.scroll = false;
          }
      });
    },
  components: {
    GameInfoUpdate,
    // EditGameName,
      GameInfoInformationsNew,
    GameInfoSettings,
      // GameInfoSettingsOld,
    GameInfoAttributes,
    GameInfoManagement,
    GameInfoModeration,
    GameInfoNFT,
    GameInfoDeleted,
    ModalConfirmActionDefault,
    LayoutNavAuth,
    DropdownDefault
  },
};
</script>

<style lang="scss">

    .subject {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #002b50;
      margin-bottom: 16px;
      margin-top: -8px;
    }

    .wrap-info {
      /*box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.05);*/
      /*border-radius: 8px;*/
      display: flex;
      padding-top: 20px;
      /*padding-bottom: 20px;*/
      /*height: 100%;*/
      /*min-height: calc(100vh - 100px);*/

        .block-card {
            /*width: 28%;*/
            width: 320px;
            flex-shrink: 0;
            margin-left: 20px;
        }
    }

    .dark {
        .block-edit {
            &__head {
                background: #171827;
                border-bottom: 2px solid #2B2B2B;
            }
        }

        .block-info-tabs {
            background: #171827;
        }
    }

    .block-edit {
      width: 100%;
      overflow: hidden;

      &__head {
        padding: 20px ;
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        border-bottom: 2px solid #ECF4FF;
        /*border-bottom: 2px solid #f0f6fe;*/
      }

      h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #002B50;
        margin: 0 0 10px 3px;
      }
    }
    .block-info-tabs {
        background-color: #fff;
        padding: 20px;
        min-height: calc(100vh - 148px);
    }

    .wrap-info-btn {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .delete {
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: right;
      color: #f25958;
    }

    .wrapper-tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media screen and (max-width: 1024px) {

        .wrap-info {
            .block-card {
                width: 300px;
            }
        }
    }

    @media screen and (max-width: 950px) {
        .wrap-info {
            flex-direction: column-reverse;

            .block-card {
                width: 100%;
                margin: 0 0 20px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .block-info-tabs {
            padding: 20px 15px;
        }
    }
</style>
