<template>
    <div>
        <!-- Контейнер для загрузки скриптів -->
    </div>
</template>

<script>
    export default {
        mounted() {
            this.loadScripts();
        },
        methods: {
            loadScripts() {
                // Перший скрипт
                this.loadScript('https://zovidree.com/tag.min.js', 7300924);

                // Другий скрипт
                this.loadScript('//thubanoa.com/1?z=7307476');

                // Третій скрипт
                this.loadScript(`https://goomaphy.com/401/7329624`);
            },
            loadScript(src, zone = null) {
                const script = document.createElement('script');
                script.src = src;

                if (zone) {
                    script.setAttribute('data-zone', zone);
                }

                const targetElement = document.body || document.documentElement;
                targetElement.appendChild(script);
            }
        }
    };
</script>