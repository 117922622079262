<template>
  <div  class="block-friends">
    <v-list color="transparent">
      <v-list-item>
        <div class="block-img" :class="(item.pro) ? 'user-pro-img' : ''">
          <router-link :to="{name: 'profile', params: {id: item.public_id, lang: $store.getters.getLang}}" class="mb-0 mr-2">
            <div class="wrap-img">
              <img v-if="item.photo && typeof item.photo == 'object'" width="43" :src="item.photo[40]">
              <img v-else-if="item.photo" width="43" :src="item.photo">
              <span v-else class="photo" :style="{'background': item.bg_color}">{{item.name.substr(0,1)}}</span>
            </div>
            <div v-if="now_date < date" class="online"></div>
          </router-link>
        </div>
        <v-list-item-content>
          <v-list-item-title>
            <router-link :class="(item.pro) ? 'user-pro' : ''" :to="{name: 'profile', params: {id: item.public_id, lang: $store.getters.getLang}}">
              {{item.name+" "+((item.last_name) ? item.last_name : '')}}
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle>
              ID: {{item.id}}
          </v-list-item-subtitle>
          <div class="block-subtitle">
              <div class="block-rating">
                  <img  src="/dist/images/frontend/fire.svg" alt />
                  <span>{{item.rating_points}}</span>
              </div>

              <div class="block-country">
                  <img class="flag" :src="item.location_img">
                  <span style="color: #96AFD1;">{{$getLocation(item.location)}}</span>
              </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div class="block-rating">
        <img  src="/dist/images/frontend/fire.svg" alt />
        <span>{{item.rating_points}}</span>
    </div>

    <div class="block-country">
        <img class="flag" :src="item.location_img">
        <span style="color: #96AFD1;">{{$getLocation(item.location)}}</span>
    </div>

    <div class="block-butl">
        {{item.count_win + ' VS ' + item.count_lose}}
    </div>

    <div v-if="auth.id != item.id" class="block-buttons">
      <div class="d-flex" v-if="currentTab=='friends' || currentTab=='online' || currentTab=='mutual' || currentTab=='peoples'">
        <FriendsButtons v-if="item.subscribe !== undefined" v-bind:item="item" v-bind:submitDisabled="submitDisabled" @sendSubscribe="sendSubscribe"/>

          <BaseButton v-else class_btn="btn-liteGrey" v-on:click="open_modal= true" :loading="submitDisabled">
            <span>{{__('Unfriend')}}</span>
          </BaseButton>


<!--        <v-btn @click="sendMess(item)" :to="{name: 'chat', params: {id: item.public_id, lang: $store.getters.getLang}}" v-on:click="$messOpen(!updateMess)" text class="chat-btn" v-ripple="false">-->
        <v-btn @click="sendMess(item)" text class="chat-btn" v-ripple="false">
          <img class="img" src="/dist/images/frontend/chat-personal.svg" alt />
          <img class="hov" src="/dist/images/frontend/chat-personal-blue.svg" alt />
        </v-btn>
      </div>
      <div v-else-if="currentTab== 'search'" class="d-flex">
        <FriendsButtons v-bind:item="item" v-bind:submitDisabled="submitDisabled" @sendSubscribe="sendSubscribe"/>
      </div>
      <div v-else-if="operation== 'friend'">
        <FriendsButtons v-if="item.subscribe !== undefined" v-bind:item="item" v-bind:submitDisabled="submitDisabled" @sendSubscribe="sendSubscribe"/>
        <div class="d-flex" v-else>
            <div class="computer-btns">
                <BaseButton  v-on:click="sendSubscribe(1)" :loading="submitDisabled">
                    <span>{{__('Confirm request')}}</span>
                </BaseButton>


                <BaseButton class_btn="btn-liteGrey" class="ml-3"  v-on:click="sendSubscribe(2)" :loading="submitDisabled">
                    <span>{{__('Cancel request')}}</span>
                </BaseButton>
            </div>

            <div class="mobile-btns">
                <BaseButton width="40"  v-on:click="sendSubscribe(1)" :loading="submitDisabled">
                    <span>
                        <img class="mr-0" height="16px" src="/dist/images/frontend/add_person.svg" alt />
                    </span>
                </BaseButton>


                <BaseButton width="40" class_btn="btn-liteGrey" class="ml-3"  v-on:click="sendSubscribe(2)" :loading="submitDisabled">
                    <span>
                        <img class="mr-0" height="16px" src="/dist/images/frontend/del_person.svg" alt />
                    </span>
                </BaseButton>
            </div>

        </div>
      </div>
      <div v-else-if="operation== 'sent'">
        <FriendsButtons v-if="item.subscribe !== undefined" v-bind:item="item" v-bind:submitDisabled="submitDisabled" @sendSubscribe="sendSubscribe"/>

          <BaseButton v-else class_btn="btn-liteGrey" class="ml-3"  v-on:click="sendSubscribe(2)" :loading="submitDisabled">
              <span>{{__('Cancel request')}}</span>
          </BaseButton>
      </div>
    </div>
      <ModalConfirmUnfriend
              v-bind:item.sync="item"
              v-if="open_modal"
              v-bind:open_modal.sync="open_modal"
              @submitUnfriend="sendSubscribe(2)">
      </ModalConfirmUnfriend>
  </div>
</template>

<script>
    import ModalConfirmUnfriend from "../../modules/ModalConfirmUnfriend"
    import FriendsButtons from "./FriendsButtons";
    import moment from "moment";

    export default {
        name: 'FriendsItem',
        data () {
            return {
                submitDisabled: false,
                open_modal: false,
                now_date: moment.utc(new Date()).format('X'),
                date: (this.item.online) ? moment.utc(this.item.online).format('X') : null,
            }
        },
        props: {
            item: {
                type: Object
            },
            currentTab: {
                type: String
            },
            operation: {
                type: String
            },
        },
        computed: {

            auth() {
                return this.$store.state.auth.user;
            },
            updateMess() {
                return this.$store.state.preference.updateMess
            },
        },
        methods: {
            sendMess(item) {
                this.$messOpen(true);
                this.$router.push({name: 'chat', params: {id: item.public_id, lang: this.$store.getters.getLang}});
            },
            sendSubscribe: function (type) {
                let response= {
                    type: type,
                    public_id: this.item.public_id
                };
                this.submitDisabled= true;
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/friends/subscribe", response)
                .then((response) => {
                    return response.json();
                })
                .then((response)=> {
                    if(this.$checkAuth(response)) {
                        let item = this.item;
                        response = response.data;

                        item.subscribe = response.friends;
                        this.submitDisabled = false;

                        this.$store.dispatch("asyncFriendsReqCount", response.friend_req);
                    }
                })
                .catch((err) => {
                    this.submitDisabled= false;
                    this.httpHandler(err);
                });
            }
        },
        components: {
            FriendsButtons,
            ModalConfirmUnfriend
        },

    }
</script>

<style lang="scss" scoped>

    .dark .block-friends {
        border-bottom: 1px solid #2B2B2B;

        .v-list-item {
            &__title {
                a {
                    color: #fff;
                }
            }

            &__subtitle {
                color: #96AFD1!important;
            }
        }

        .block-butl {
            color: #fff;
        }
    }

  .block-friends {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #ECF4FF;

      &:last-child {
          border: none;
      }

    .v-list {
      padding: 0;
      width: 27%;
    }

    .v-list-item {
      padding: 0;
      min-height: 40px;

      .block-img {
        position: relative;
        width: 40px;
        height: 40px;
        margin-right: 10px;

        a {
          text-decoration: none;
        }

        .online {
          border-radius: 50%;
          width: 11px;
          height: 11px;
          position: absolute;
          right: 3px;
          bottom: 1px;
          border: 2px solid #FFFFFF;
          box-sizing: border-box;
          background-color: #00C569;
        }
      }

      .wrap-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        .photo {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          color: #FFFFFF;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &__content {
        padding: 0;

        p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 12px;
          max-width: 750px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      &__title {
        display: block;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
        color: #002B50;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
          color: #002B50;
          text-decoration: none;

          &:hover {
            color: #26A9F4;
            text-decoration: underline;
          }
        }
        span {
          font-size: 12px;
          margin-top: 2px;

        }

        img {
          max-height: 11px;
          margin: 1px 4px 0 10px;
        }
      }

      &__subtitle {
          font-size: 14px;
          line-height: 19px;
          color: #6886AF !important;
          max-width: 170px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

        span {
          font-size: 12px;
        }

        img {
          max-height: 12px;
        }

        .flag {
          border-radius: 1px;
          margin-right: 3px;
          width: 14px;
        }
      }

        .block-subtitle {
            display: none;
        }
    }

    .block-rating {
        width: 13%;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: #F6A401;

        img {
            height: 12px;
            margin-right: 4px;
            margin-bottom: 2px;
        }
    }

    .block-country {
        width: 17%;
        display: flex;
        align-items: center;

        span {
            font-size: 12px;
        }

        .flag {
            border-radius: 1px;
            margin-right: 4px;
            width: 14px;
        }
    }

    .block-butl {
        width: 13%;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #000000;
        white-space: nowrap;
    }

    .block-buttons {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .btn-cab {
        margin-right: 10px;
    }

    .btn-del {
      margin-left: 10px;
    }

    .chat-btn {
      width: 40px;
      height: 40px;
      margin-right: 0;
      border-radius: 4px;
      outline: none;
      margin-left: 10px;

      .hov {
        display: none;
      }

      &:hover {
        background-color: transparent;

        .img {
          display: none;
        }

        .hov {
          display: block;
        }
      }

      &.v-btn:before {
        background-color: transparent;
      }

      &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
        min-width: 40px;
        padding: 0;
      }
    }

    .mobile-btns {
        display: none;
    }
    .computer-btns {
        display: flex;
    }
  }

  @media screen and (max-width: 950px) {
      .block-friends {
          .v-list {
              width: 35%;
          }

          .block-rating {

          }

          .block-country {

          }

          .block-butl {

          }

          .block-buttons {
            width: 22%;
          }
      }
  }

  @media screen and (max-width: 768px) {
      .block-friends {
          padding: 10px;

          .v-list {
              width: 37%;
              min-width: 240px;
              padding-right: 4px;
          }

          .v-list-item {
              &__subtitle {
                  display: none;
              }

              .block-subtitle {
                  display: flex;
                  max-width: 170px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;

                  .block-rating {
                      display: flex;
                      align-items: center;
                      font-size: 12px;
                      line-height: 16px;
                      letter-spacing: 0.1px;
                      color: #F6A401;
                      margin-right: 10px;
                      width: fit-content;

                      img {
                          height: 12px;
                          margin-right: 4px;
                          margin-bottom: 2px;
                      }
                  }

                  .block-country {
                      display: flex;
                      align-items: center;
                      width: fit-content;

                      span {
                          font-size: 12px;
                      }

                      .flag {
                          border-radius: 1px;
                          margin-right: 4px;
                          width: 14px;
                      }
                  }
              }
          }

          .block-rating {
            display: none;
          }

          .block-country {
            display: none;
          }

          .block-buttons {
              width: 50%;
              justify-content: flex-end;
          }
      }
  }

  @media screen and (max-width: 600px){
      .block-friends {
          .v-list {
              width: 35%;
          }

          .block-butl {
              display: none;
          }

          .block-buttons {
              width: 65%;
          }

      }
  }

  @media screen and (max-width: 450px) {
      .block-friends {

          .v-list {
              width: 37%;
              min-width: 180px;
          }

          .v-list-item {
              &__title {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: block;
                  font-size: 13px;
              }

              .block-subtitle {
                  .block-rating {
                      font-size: 11px;
                  }
                  .block-country {
                      span {
                          font-size: 11px;
                      }
                  }
              }
          }

          .chat-btn {
              width: 36px;
              height: 36px;
              margin-right: 0;
              border-radius: 4px;
              outline: none;
              margin-left: 5px;

              img {
                  height: 20px;
              }

              &.v-btn:not(.v-btn--round).v-size--default {
                  height: 36px;
                  min-width: 36px;
                  padding: 0;
              }
          }
          .mobile-btns {
              display: flex;
              flex-shrink: 0;
          }
          .computer-btns {
              display: none;
          }
      }
  }
</style>
