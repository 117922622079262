<template>
    <div class="wrap-block-rating">

        <a v-on:click="showReviews" style="white-space: nowrap">
            {{__('All reviews')}}
        </a>

        <div class="rating">
            <v-rating
                half-increments
                :readonly="!game.available"
                v-model="rating"
                background-color="white darken-2"
                color="#FFBA49"
                dense
                hover
                size="16"
                v-on:input="changeRating()"
                full-icon="star"
                empty-icon="star_outline"
                half-icon="star_half"
            ></v-rating>

            <!--<v-rating-->
                    <!--half-increments-->
                    <!--readonly-->
                    <!--v-model="rating"-->
                    <!--background-color="white darken-2"-->
                    <!--color="#FFBA49"-->
                    <!--dense-->
                    <!--hover-->
                    <!--size="20"-->
            <!--&gt;</v-rating>-->
            <span>{{Number(game.rating).toFixed(2)}}</span>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                rating: this.game.rating,
                submitDisabled: false,
            };
        },
        props: {
            game: {
                type: Object,
            },
            iframe: {
                type: Object,
            },
            profile: {
                type: Object
            },
            scroll: {
                type: Boolean,
            }
            // favoriteChanged: {
            //   type: Boolean,
            // }
        },
        methods: {
            changedFavorite() {
                this.$emit("favoriteChanged");
            },
            changeRating () {
                this.$emit('update:reviews_game_modal', true);
                this.$emit('newRating', this.rating);
            },
            showReviews() {
                this.$emit('update:all_reviews_modal', true);
                this.$emit('ShowReviews');
            }
        },
        beforeCreate: function () {},
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        components: {},
    };
</script>

<style lang="scss" scoped>

    .wrap-block-rating {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        .rating {
            display: flex;
            align-items: center;

            span {
                font-size: 13px;
                line-height: 18px;
                color: #002B50;
                margin-top: 6px;
                margin-left: 7px;
                padding-bottom: 3px;
            }
        }

        a {
            display: inline-block;
            margin-left: 10px;
            margin-top: 6px;
            font-size: 12px;
            line-height: 16px;
            color: #00AEFF;
            
            &:hover {
                text-decoration: underline;
                color: #26A9F4;
            }
        }
    }

    .dark .wrap-block-rating {
        a {
            color: #00AEFF;
        }

        .rating {
            span {
                color: #96AFD1;
            }
        }
    }

    @media screen and (max-width: 950px){
        .wrap-block-rating {
            flex-direction: row-reverse;
            justify-content: flex-end;

            .rating {
                margin-right: 12px;

                span {
                    margin-left: 4px;
                }
            }
        }
    }

    @media screen and (max-width: 425px){
        .wrap-block-rating {
            flex-direction: column-reverse;
            align-items: flex-start;

            .rating {
                margin: -4px 0 -2px;
            }

            a {
                margin-top: 0;
            }
        }
    }
</style>