<template>
  <div class="wrap-logs">
        <Table v-bind:headers="headers_program" v-bind:body="logs.data" class="table" v-if="logs">
          <template v-slot="log">
            <SettingsLogsItem :log="log"></SettingsLogsItem>
          </template>
          <template v-slot:result>
              {{__('no-result')}}
          </template>
        </Table>

  </div>
</template>

<script>
    import Table from '../../modules/Table';
    import SettingsLogsItem from "./SettingsLogsItem";

    export default {

        name: 'SettingsLogs',
        data () {
            return {
                headers_program: [
                    'Date and time login', 'OS', 'Browser', 'IP Address', 'Location'
                ],
                logs: this.setting.logs,
                loadingPage: false,
                next_page_url: this.setting.logs.next_page_url
            }
        },
        props: {
            setting: {
                type: Object,
            },
            scroll: {
                type: Boolean,
            }
        },

        created() {
            this.$addListener(window,'scroll', () => {
                if (document.documentElement.scrollTop > 60) {
                    this.$emit("update:scroll", true);
                } else {
                    this.$emit("update:scroll", false);
                }

                this.scrollLogs();
            });
        },
        computed: {

        },
        asyncComputed: {

        },
        methods: {
            updateEnable(enable) {
                let setting= this.setting;
                setting.google_two_step= enable;

                this.$emit('update:setting', setting);
            },

            scrollLogs: function () {
                const progress =
                    document.documentElement.scrollTop /
                    (document.documentElement.scrollHeight -
                        document.documentElement.clientHeight);
                if (!this.loadingPage && this.next_page_url) {
                    let bottomOfWindow = 0.7 <= progress;
                    if (bottomOfWindow) {
                        this.loadingPage = true;
                        this.$http.get(this.next_page_url)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                response = response.data;
                                let logs = this.logs.data;
                                this.logs.data = logs.concat(response.data);
                                this.next_page_url = response.next_page_url;
                                this.loadingPage = false;
                            }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                        });
                    }
                }
            },

        },
        components: {
            Table,
            SettingsLogsItem
        },

    }
</script>

<style lang="scss" scoped>

  .wrap-logs {
   padding-top: 2px;
  }

</style>
