<template>
  <div class="nft-list">
    <div v-if="games" >
      <h2>Game NFT collections</h2>
      <VueSlickCarousel
              v-bind="settings1"
              class="game-list"
      >
        <div v-for="(game, key) in games" :key="key" class="card-game" v-on:click="filterChange(game)">
          <div class="top-card">
            <div class="bg" :style="{'backgroundImage': 'url(' +((typeof game.logo == 'object') ? game.logo[100] : game.logo)+ ')'}" ></div>
            <div class="bg-g"></div>
            <div class="top-text">
              <a class="name">{{game.name}}</a>
            </div>
            <div class="block-img">
              <div class="wrap-img">
                <img v-if="typeof game.logo == 'object'" :src="game.logo[100]">
                <img v-else :src="game.logo">
              </div>
            </div>
          </div>
          <div class="body-card">
            <p>Total NFT: {{(game.total_nft) ? game.total_nft : 0}}</p>
            <p>For sale: {{(game.sale_nft) ? game.sale_nft : 0}}</p>
          </div>
        </div>
      </VueSlickCarousel>
<!--      <div class="game-list">-->
<!--      </div>-->
      <div class="filter-block">
        <div class="filter">
          <v-autocomplete
                  class="filter-change"
                  dense
                  filled
                  solo
                  v-model="current_game"
                  :filter="customFilter"
                  @change="filterChange"
                  :items="collections"
                  item-color="white"
                  title="name"
                  :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:selection="data">
              <div class="wrap-img">
                <img v-if="typeof data.item.logo == 'object'" :src="data.item.logo[100]">
                <img v-else :src="data.item.logo">
              </div>
                <p class="change">Выбери или начни вводить название игры</p>

                <p class="name">{{ data.item.name }}</p>
            </template>
            <template v-slot:item="data">
              <div class="wrap-item-select filter-change-select">
                <div class="wrap-img">
                  <img v-if="typeof data.item.logo == 'object'" :src="data.item.logo[100]">
                  <img v-else :src="data.item.logo">
                </div>
                <div>
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </div>
            </template>
          </v-autocomplete>
        </div>
        <div class="sort">
          <v-select
                  dense
                  filled
                  solo
                  v-model="sort"
                  :filter="customFilter"
                  @change="filterChange(null)"
                  :items="sort_filter"
                  item-color="white"
                  title="name"
                  :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:selection="data">
              <div>
                <p class="change">Сортировать по:</p>
                <p class="name">{{ data.item.name }}</p>
              </div>
            </template>
            <template v-slot:item="data">
              <div class="wrap-item-select">
                <div>
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </div>
            </template>
          </v-select>
        </div>
        <div class="reload">
          <v-btn v-on:click="updateNftList()">
            <img src="/dist/images/frontend/reload.svg" alt="">
          </v-btn>
        </div>
        <div class="sort-bar">
          <v-range-slider
                  v-model="range"
                  :max="max"
                  :min="min"
                  hide-details
                  class="range-slider"
                  v-on:change="filterChange(null)"
          >
            <template v-slot:prepend>
              <v-text-field
                      :value="range[0]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      @change="$set(range, 0, $event), filterChange(null)"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                      :value="range[1]"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                      @change="$set(range, 1, $event), filterChange(null)"
              ></v-text-field>
            </template>
          </v-range-slider>
          <p class="p-text">MIND</p>
<!--          <v-slider-->
<!--              v-model="price_filter"-->
<!--              :thumb-color="color"-->
<!--              :thumb-size="24"-->
<!--              :track-color="trackColor"-->
<!--              :track-fill-color="color"-->
<!--              thumb-label="always"-->
<!--              :max="max_price"-->
<!--              min="1"-->
<!--              v-on:change="updateNftList()"-->
<!--          ></v-slider>-->
<!--          <p class="p-text">1 MIND<span>10000 MIND</span></p>-->
        </div>
      </div>
    </div>

    <div v-if="nft_list" class="wrap-developers-page">
      <ScrollPagination v-bind:items.sync="nft_list" type="post" v-bind:params="query" v-slot="list" className="nft-card" v-if="nft_list.total">
        <div class="nft-itam" v-for="(item, key) in list.data" :key="key">
          <NftListItem  v-bind:item="item" v-if="[3,5].includes(item.status)"/>
        </div>
      </ScrollPagination>
      <div v-else class="wrap-notgames">
        <img src="/dist/images/frontend/no-games.svg" alt />
        <div>
          <p>{{__('No have nft!')}}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{__('loading...')}}</p>
    </div>
  </div>
</template>


<script>

import NftListItem from './NftListItem';
import ScrollPagination from '../../modules/ScrollPagination';
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "NftList",
  data() {

    let sort_filter= [
        {
            "name": "Самые новые",
            "value": 0
        },
        {
            "name": "Cамые старые",
            "value": 1
        },
        {
            "name": "От дорогих к дешевым",
            "value": 2
        },
        {
            "name": "От дешевых к дорогим",
            "value": 3
        }
    ];
    let max_price= 10000;

    return {
      settings1: {
        "arrows":true,
        "dots": false,
        "infinite": true,
        "autoplay": true,
        "speed": 2000,
        "autoplaySpeed": 2000,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 650,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 1,
              "initialSlide": 0
            }
          },
          {
            "breakpoint": 550,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "initialSlide": 0
            }
          },

        ]
      },
      min: 0,
      max: 0,
      range: [0],
      color: '#4EBEFF',
      trackColor: '#DAE6F1',
      nft_list: null,
      query: null,
      games: null,
      collections: null,
      current_game: null,
      sort_filter: sort_filter,
      sort: sort_filter[0].value,
      max_price: max_price,
      price_filter: max_price,
    };
  },

  watch: {

  },
  beforeRouteUpdate (to, from, next) {
      this.createdComponent(to.params.type);
      return next();
  },
  created() {
      this.createdComponent(this.$route.params.type);
  },
  computed: {

  },
  methods: {
      createdComponent(name) {
          let params= {
              'favorite': (name=='favorite') ? true : false,
              'sort': this.sort,
          };

          this.nft_list= null;

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/nft', params)
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  if(this.$checkAuth(response)) {
                      response= response.data;

                      let page= response.page;

                      this.nft_list= page;

                      let games= response.games;
                      this.games= this.$cleanObject(games);
                      this.collections= games;
                      games.unshift({
                          "id": null,
                          "logo": "/dist/images/frontend/all-filter.svg",
                          "name": "All",
                      });

                      this.max= response.max_range;
                      this.range[1]= this.max;
                      this.current_game= games[0];
                  }
              }).catch(err => {
              this.httpHandler(err);
          });
      },
      customFilter (item, queryText) {
          const textOne = item.name.toLowerCase();
          const searchText = queryText.toLowerCase();
          return textOne.indexOf(searchText) > -1
      },
      filterChange(change) {
            if(change) {
                this.current_game= change;
            }

            let query= {
              game_id: this.current_game.id,
              sort: this.sort,
              min_range: this.range[0],
              max_range: this.range[1],
              favorite: (this.$route.params.type=='favorite') ? true : false
            };
            this.query= query;
            this.updateNftList();
      },
      updateNftList() {
          this.nft_list= null;

          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/get/nft', this.$cleanObject(this.query))
              .then(response => {
                  return response.json()
              })
              .then(response => {
                  if(this.$checkAuth(response)) {
                      let page= response.data;


                      this.nft_list= page;
                  }
              }).catch(err => {
              this.httpHandler(err);
          });
      }

  },
  components: {
    ScrollPagination,
    VueSlickCarousel,
      NftListItem
  },
};
</script>