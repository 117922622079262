<template>
  <div class="wrapper-iframe">
    <div class="wrapper-loader">
      <Loader></Loader>
    </div>
    <div class="wrap-iframe" style="height:100%">
      <iframe class="iframe" ref="game_iframe" id="game_iframe" scrolling="no" style="overflow:hidden; touch-action: none" :src="iframe.url_game"></iframe>
    </div>
  </div>
</template>

<script>
  import Loader from "../../modules/Loader.vue"

    export default {
        name: 'GameIframe',
        data () {
            return {

            }
        },
        props: {
            iframe: {
                type: Object
            },
            focus: {
              type: Boolean
            }
        },
        watch: {
          focus(val) {
            if (val) {
              this.$refs.game_iframe.contentWindow.focus();
            }
          }
        },
        computed: {

        },
        mounted() {
          // this.$nextTick(() => {
          //   this.$refs.game_iframe.contentWindow.focus();
          // });
          this.$refs.game_iframe.addEventListener("load", () => {
            this.$refs.game_iframe.contentWindow.focus();
          });
        },
      // created: function () {
        //     this.$leaveWebsocket();
        // },
        // beforeDestroy() {
        //     this.$connectWebsocket();
        // },
        beforeCreate: function () {
            this.$store.commit('updateNextPage', false);

            window.onbeforeunload = function() {
                return  "Вы же ничего не сохранили!";
            };
        },
      components: {
        Loader
      }
    }
</script>
<style>

  .wrapper-iframe {
    /*height: calc(100vh - 148px);*/
    height: calc(100vh - 44px);
    background-color: #000;
    position: relative;
  }
  .wrap-iframe {
    /*height: 100%;*/
    width: 100%;
    /*border-radius: 8px 8px 0px 0px;*/
    z-index: 10;
    position: absolute;
  }

  .iframe {
    width: 100%;
    /*height: 300px;*/
    height: 100%;
    /*border-radius: 8px 8px 0px 0px;*/
  }

  .wrapper-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 44px);
    position: absolute;
  }

  /*@media screen and (max-width: 1200px) {*/
  /*  .wrapper-iframe {*/
  /*    height: calc(100vh - 162px);*/
  /*  }*/
  /*}*/

</style>