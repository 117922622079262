<template>
  <div class="block-change-password">
    <div>
      <form>
        <div class="block-change-password__item">
          <div class="block-icon block-icon--red">
<!--              <img src="/dist/images/frontend/password-modal.svg" alt />-->
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.30722 9.23084L9.38269 1.26834C10.4217 0.243886 12.1069 0.243886 13.1459 1.26834L14.2245 2.33188C15.2635 3.35633 15.2635 5.01796 14.2245 6.04241L6.11717 14.0363C5.65359 14.4934 5.02506 14.7499 4.36901 14.7499H1.04878C0.740346 14.7499 0.492487 14.4994 0.500174 14.1953L0.583693 10.892C0.600298 10.2676 0.859392 9.67241 1.30722 9.23084ZM13.4484 3.09711L12.3698 2.03358C11.7594 1.43175 10.7691 1.43175 10.1588 2.03358L9.53186 2.65173L12.8218 5.89498L13.4484 5.27718C14.0588 4.67535 14.0588 3.69894 13.4484 3.09711ZM2.08331 9.99608L8.75577 3.41696L12.0457 6.66022L5.34108 13.2711L5.2519 13.3516C5.00559 13.5551 4.69352 13.6677 4.36901 13.6677L1.61121 13.6673L1.68089 10.9197C1.69012 10.573 1.83438 10.2415 2.08331 9.99608ZM15.5 14.2089C15.5 13.9101 15.2543 13.6678 14.9512 13.6678H9.22583L9.15136 13.6727C8.8835 13.7086 8.67705 13.935 8.67705 14.2089C8.67705 14.5077 8.92274 14.75 9.22583 14.75H14.9512L15.0257 14.7451C15.2935 14.7092 15.5 14.4828 15.5 14.2089Z" fill="#FF4B55"/>
            </svg>
          </div>
<!--          <p>Change password</p>-->
          <InputLabelTextDefault switchType color="main" :label='__("Old password")' type="password" @input="formValidate.current_password = null" v-model="form.current_password" v-bind:errors="formValidate.current_password" @change="updateValidate('current_password')"></InputLabelTextDefault>
        </div>
        <div class="block-change-password__item">
            <div class="block-icon">
<!--                <img src="/dist/images/frontend/password-modal.svg" alt />-->
<!--              <img src="/dist/images/frontend/edit-settings-blue.svg" alt />-->
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.30722 9.23084L9.38269 1.26834C10.4217 0.243886 12.1069 0.243886 13.1459 1.26834L14.2245 2.33188C15.2635 3.35633 15.2635 5.01796 14.2245 6.04241L6.11717 14.0363C5.65359 14.4934 5.02506 14.7499 4.36901 14.7499H1.04878C0.740346 14.7499 0.492487 14.4994 0.500174 14.1953L0.583693 10.892C0.600298 10.2676 0.859392 9.67241 1.30722 9.23084ZM13.4484 3.09711L12.3698 2.03358C11.7594 1.43175 10.7691 1.43175 10.1588 2.03358L9.53186 2.65173L12.8218 5.89498L13.4484 5.27718C14.0588 4.67535 14.0588 3.69894 13.4484 3.09711ZM2.08331 9.99608L8.75577 3.41696L12.0457 6.66022L5.34108 13.2711L5.2519 13.3516C5.00559 13.5551 4.69352 13.6677 4.36901 13.6677L1.61121 13.6673L1.68089 10.9197C1.69012 10.573 1.83438 10.2415 2.08331 9.99608ZM15.5 14.2089C15.5 13.9101 15.2543 13.6678 14.9512 13.6678H9.22583L9.15136 13.6727C8.8835 13.7086 8.67705 13.935 8.67705 14.2089C8.67705 14.5077 8.92274 14.75 9.22583 14.75H14.9512L15.0257 14.7451C15.2935 14.7092 15.5 14.4828 15.5 14.2089Z" fill="#00C443"/>
              </svg>
            </div>
<!--          <p>New password</p>-->
          <InputLabelTextDefault color="main" :label='__("New password")' type="password" @input="formValidate.password = null" v-model="form.password" v-bind:errors="formValidate.password" @change="updateValidate('password')"></InputLabelTextDefault>
        </div>
        <div class="block-change-password__item">
            <div class="block-icon">
<!--                <img src="/dist/images/frontend/password-modal.svg" alt />-->
<!--              <img src="/dist/images/frontend/edit-settings-blue.svg" alt />-->
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.30722 9.23084L9.38269 1.26834C10.4217 0.243886 12.1069 0.243886 13.1459 1.26834L14.2245 2.33188C15.2635 3.35633 15.2635 5.01796 14.2245 6.04241L6.11717 14.0363C5.65359 14.4934 5.02506 14.7499 4.36901 14.7499H1.04878C0.740346 14.7499 0.492487 14.4994 0.500174 14.1953L0.583693 10.892C0.600298 10.2676 0.859392 9.67241 1.30722 9.23084ZM13.4484 3.09711L12.3698 2.03358C11.7594 1.43175 10.7691 1.43175 10.1588 2.03358L9.53186 2.65173L12.8218 5.89498L13.4484 5.27718C14.0588 4.67535 14.0588 3.69894 13.4484 3.09711ZM2.08331 9.99608L8.75577 3.41696L12.0457 6.66022L5.34108 13.2711L5.2519 13.3516C5.00559 13.5551 4.69352 13.6677 4.36901 13.6677L1.61121 13.6673L1.68089 10.9197C1.69012 10.573 1.83438 10.2415 2.08331 9.99608ZM15.5 14.2089C15.5 13.9101 15.2543 13.6678 14.9512 13.6678H9.22583L9.15136 13.6727C8.8835 13.7086 8.67705 13.935 8.67705 14.2089C8.67705 14.5077 8.92274 14.75 9.22583 14.75H14.9512L15.0257 14.7451C15.2935 14.7092 15.5 14.4828 15.5 14.2089Z" fill="#00C443"/>
              </svg>
            </div>
<!--          <p>Confirm password</p>-->
          <InputLabelTextDefault color="main" :label='__("Confirm password")' type="password" @input="formValidate.password_confirmation = null" v-model="form.password_confirmation" v-bind:errors="formValidate.password_confirmation" @change="updateValidate('password_confirmation')"></InputLabelTextDefault>
        </div>
      </form>
      <div class="block-btn">
<!--        <v-btn class="btn-secondary" v-ripple="{ class:'secondary&#45;&#45;text'}" text v-on:click="$emit('update:dialog', false)">Cancel</v-btn>-->
<!--        <v-btn class="btn-cab white&#45;&#45;text" text v-ripple="{ class:'secondary&#45;&#45;text'}" v-on:click="onSubmit" :loading="submitDisabled">-->
<!--          Save changes-->
<!--        </v-btn>-->
<!--        <BaseButton-->
<!--            class="mr-3"-->
<!--            width="125"-->
<!--            class_btn="btn-liteGrey"-->
<!--            v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--            v-on:click="$emit('update:dialog', false)"-->
<!--        >-->
<!--          <span>Cancel</span>-->
<!--        </BaseButton>-->

        <BaseButton
            width="125"

            v-on:click="onSubmit"
            :loading="submitDisabled">
          <span>{{__('Save changes')}}</span>
        </BaseButton>
      </div>
    </div>


<!--    <ModalDefault class_name="info-window" v-if="modal_success" v-bind:modal_cancel.sync="modal_success">-->
<!--      <div class="text-center" >-->
<!--        <div><h2>{{__('Success')}}</h2></div>-->
<!--        <div class="text-center text-window">-->
<!--          <p>Password changed successfully</p>-->
<!--          <div class="btn-window">-->
<!--            <v-btn class="btn-info-ok" v-on:click="modal_success= false">{{__('Ok')}}</v-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </ModalDefault>-->
  </div>
</template>

<script>
    // import ModalDefault from '../../modules/ModalDefault';
    import InputLabelTextDefault from '../../modules/InputLabelTextDefault';
    import {required, sameAs, minLength} from "vuelidate/lib/validators";

    export default {

        name: 'FormChangePassword',
        data () {
            return {
                form: {
                    password: null,
                    password_confirmation: null,
                    current_password: null,
                },
                formValidate: {
                    password: null,
                    password_confirmation: null,
                    current_password: null,
                },
                submitDisabled: false,
                modal_success: false
            }
        },
        validations: {
            form: {
                current_password: {
                    required
                },
                password: {
                    required
                },
                password_confirmation: {
                    required,
                    minLength: minLength(6),
                    sameAsPassword: sameAs(function() {
                        if(this.form.password_confirmation && this.form.password_confirmation.length) {
                            return (this.form.password_confirmation== this.form.password) ? this.form.password_confirmation : false;
                        } else {
                            return this.form.password_confirmation;
                        }
                    }),
                },
            }
        },
        computed: {

        },
        created() {

        },
      asyncComputed: {

        },
        methods: {
            updateValidate: function(type, validate= false) {
                if(validate) {
                    let formValidate= this.formValidate;
                    formValidate[type]= [];

                    if(!this.$v.form[type][validate]) {
                        let msg= this.__(this.$validateMessage[validate]);
                        formValidate[type].push(msg);

                    }

                    this.formValidate= formValidate;
                } else {
                    this.formValidate= this.$changeValidate(this.formValidate, this.$v.form[type], type);
                }
            },
            onSubmit: function () {
                let valid= this.$v.form;

                if(valid.$invalid) {
                    let massages= {
                        'password': [],
                        'current_password': [],
                        'password_confirmation': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;

                    this.$http.post('ajax/setting/password', this.form)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response)=> {
                            if(this.$checkAuth(response)) {
                                this.form = {
                                    password: null,
                                    password_confirmation: null,
                                    current_password: null,
                                };
                                this.submitDisabled = false;
                                this.modal_success = true;
                                this.$emit('update:dialog', false);
                                this.$notify({
                                    group: 'message',
                                    title: 'Password changed successfully',
                                    type: 'success',
                                    position: 'left top',
                                    duration: 5000,
                                    speed: 500
                                });
                            }
                        }).catch(err => {
                        this.submitDisabled= false;
                        let response= this.httpHandler(err);
                        if(response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            // ModalDefault
        },

    }
</script>

<style lang="scss">

  .block-change-password {
    //padding: 26px;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      .block-icon {
        //margin-top: 8px;
        //display: flex;
        //align-items: center;
        //justify-content: center;
        //margin-right: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        height: 28px;
        background: rgba(0, 196, 67, 0.4);
        border-radius: 6px;
        margin-right: 12px;
        margin-top: 12px;

        &--red {
          background: rgba(255, 75, 85, 0.4);
        }
      }
    }

    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #002B50;
    }

    .block-btn {
      display: flex;
      //justify-content: flex-end;
      margin-top: 25px;
      min-width: 250px;

        .btn-secondary {
            min-width: 105px;

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 105px;
            }
        }

      .v-btn.btn-cab {
        border-radius: 6px;
        display: block;

        &.v-btn:not(.v-btn--round).v-size--default {
          height: 40px;
          min-width: 100px;
          padding: 0 17px;
          margin:0 0 0 15px;
        }
      }
    }

  }


</style>
