<template>
<v-dialog v-model="dialog" max-width="320px">
    <div class="wrap-sorting-peoples">
          <div class="wrap-sorting-head">
              <div class="wrap-sorting-head__icon">
                  <img src="/dist/images/frontend/filter-icon.svg" alt />
              </div>
              <h5>{{__('Filters')}}</h5>
          </div>

          <div class="wrap-sorting-peoples__body">
              <div class="region">
                  <div class="region__icon">
                      <img src="/dist/images/frontend/location-icon.svg" alt />
                  </div>
                  <div class="region__input">
                      <DropdownCountry
                              :label='__("Country")'
                              v-bind:value.sync="sorting.location"
                              @change="updateValidate('location')"
                              v-bind:errors="formValidate.location"
                              @updateCountry="updateCountry"
                      ></DropdownCountry>
                  </div>
              </div>

              <div class="age">
                  <div class="d-flex align-center">
                      <div class="age__icon">
                          <img src="/dist/images/frontend/birthday-icon.svg" alt />
                      </div>
                      <p>{{__('Age')}}</p>
                  </div>

                  <div class="age__inputs">
                      <InputLabelTextDefault
                              :label='__("From")'
                              color="main"
                              class="mr-8"
                              v-model="sorting.age_from"
                              type="number"
                              v-bind:errors="formValidate.age_from"
                              @change="updateValidate('age_from')"
                      ></InputLabelTextDefault>
                      <InputLabelTextDefault
                              :label='__("To")'
                              color="main"
                              v-model="sorting.age_to"
                              type="number"
                              v-bind:errors="formValidate.age_to"
                              @change="updateValidate('age_to')"
                      ></InputLabelTextDefault>
                  </div>
              </div>

              <div class="male">
                  <div class="male__head">
                      <div class="male__icon">
                          <img src="/dist/images/frontend/gender-icon.svg" alt />
                      </div>
                      <p>{{__('Gender')}}</p>
                  </div>
                  <div class="wrap-checkbox d-flex">
                      <v-checkbox v-model="sorting.gender" color="blue" value="1" :label="__('Male')"></v-checkbox>
                      <v-checkbox v-model="sorting.gender" color="blue" value="2" :label="__('Female')"></v-checkbox>
                  </div>
              </div>
              <div class="wrap-btn">

                  <div style="width: 48%">
                      <BaseButton
                              :loading="submitDisabled"
                              class_btn="btn-liteGrey"
                              v-on:click="cancelFilters"
                      >
                          <span>{{__('Clear')}}</span>
                      </BaseButton>
                  </div>

                  <div style="width: 48%">
                      <BaseButton
                              v-on:click="onSubmit"
                              :loading="submitDisabled"
                      >
                          <span>{{__('Apply')}}</span>
                      </BaseButton>
                  </div>
              </div>
          </div>
    </div>
</v-dialog>

</template>

<script>
  import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
  import DropdownCountry from "../../modules/DropdownCountry";
  import { numeric } from "vuelidate/lib/validators";

  export default {
    name: "FriendsRequests",
    data() {
      return {
        dialog: true,
        scroll: false,
        submitDisabled: false,
        sortFriendList: [
          {
            title: this.__("By popularity"),
            value: 1,
          },
          {
            title: this.__("Date added"),
            value: 2,
          },
          {
            title: this.__("By rating"),
            value: 3,
          },
        ],
        sorting: {
          name: this.sorting_user.name,
          location: this.sorting_user.location,
          gender: this.sorting_user.gender,
          age_from: this.sorting_user.age_from,
          age_to: this.sorting_user.age_to,
          sort_friend: this.sorting_user.sort_friend,
        },
        formValidate: {
          name: null,
          location: null,
          gender: null,
          age_from: null,
          age_to: null,
        },
        rules: this.validateMessage,
      };
    },
    validations: {
      sorting: {
        name: {

        },
        age_from: {
          numeric,
        },
        age_to: {
          numeric,
        },
        gender: {
          numeric,
        },
      },
    },
    props: {
      sorting_user: {
        type: Object,
      },
      color: {
        type: String,
      },
        property: {
        type: Object,
      },
        active: {
          type: Boolean,
        },
        peoples_sorting: {
          type: Boolean,
        },
        filter: {
          type: Array,
        }
    },
    computed: {
      currentTab: function () {
        return this.$route.params.current
                ? this.$route.params.current
                : "friends";
      },
    },

      watch: {
          dialog() {
              this.$emit("update:peoples_sorting", false);
          },
      },

      created() {
          this.$addListener(window,'scroll', () => {
              if (document.documentElement.scrollTop > 60) {
                  this.scroll = true;
              } else {
                  this.scroll = false;
              }
          });
      },
      methods: {
      updateValidate: function (type) {
        this.formValidate = this.$changeValidate(
                this.formValidate,
                this.$v.sorting[type],
                type
        );
      },
      onSubmit() {
          let valid = this.$v.sorting;

        if (valid.$invalid) {
          let massages = {
            country: [],
            city: [],
            location: [],
            age_from: [],
            age_to: [],
            sort_friend: [],
          };
          this.formValidate = this.$formValidate(massages, valid);
        } else {
          let sorting= this.sorting;
          let count = [];
          Object.keys(this.sorting).forEach((key) => {
              if(this.sorting[key]) {
                  count.push(this.sorting[key]);
              }
          });
          this.$emit('update:filter', count);
          this.submitDisabled = true;
          this.$emit('update:sorting_user', sorting);
          this.$emit("update:active", false);
          let response= Object.assign(this.property, sorting);
          this.$emit('update:users_list', null);
          this.$emit('update:loading_page', false);
          // this.$http.post("ajax/" + ((this.$route.name == 'peoples') ? 'peoples' : 'friends'), response)
          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/" + ((this.currentTab == 'peoples') ? 'peoples' : 'friends'), response)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            if(this.$checkAuth(response)) {
                // response = {
                //     "data": {
                //         "count_friends_req": 0,
                //         "count_online": 1,
                //         "count_total": 5,
                //         "users": {
                //             "current_page": 1,
                //             "data": [
                //                 {
                //                     "id": 2,
                //                     "public_id": "happy",
                //                     "name": "Happy",
                //                     "last_name": "Proger",
                //                     "photo": "a-1609759324m90pBdwJSb.png",
                //                     "bg_color": "#f9851a",
                //                     "location": "AX",
                //                     "location_img": "de.png",
                //                     "online": "2021/01/27 13:13:10",
                //                     "rating_points": "802",
                //                     "subscribe": 2
                //                 }
                //             ],
                //             "first_page_url": "https://mindplays.com/ajax/friends?page=1",
                //             "from": 1,
                //             "last_page": 1,
                //             "last_page_url": "https://mindplays.com/ajax/friends?page=1",
                //             "next_page_url": null,
                //             "path": "https://mindplays.com/ajax/friends",
                //             "per_page": 24,
                //             "prev_page_url": null,
                //             "to": 4,
                //             "total": 4
                //         }
                //     },
                //     "status": 200
                // }
                this.submitDisabled = false;
                this.$emit('updatePeoples', response.data);
                this.$emit("update:peoples_sorting", false);
            }
          })
          .catch((err) => {
            this.submitDisabled = false;
            let response = this.httpHandler(err);
            if (response.error) {
              this.formValidate = response.error.message;
            }
          });
        }
      },

      cancelFilters() {

          this.$emit("update:active", false);
        this.$emit('update:users_list', null);

          this.sorting.name = null;
          this.sorting.location = null;
          this.sorting.gender = null;
          this.sorting.age_from = null;
          this.sorting.age_to = null;

          this.onSubmit();
      },

      updateCountry(val) {
        this.sorting.location = val;
      },
    },
    components: {
      InputLabelTextDefault,
      DropdownCountry,
      // DropdownDefault,
    },
  };
</script>


<style lang="scss">

.dark {
    .wrap-sorting-peoples {
        .wrap-sorting-head {
            background: #171827;
            border-bottom: 1px solid #2B2B2B;

            &__icon {
                img {
                    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                }
            }

            h5 {
                color: #fff;
            }
        }

        &__body {
            background: #171827;
        }

        .region__icon {
            img {
                filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
            }
        }

        .age,
        .male {
            &__icon {
                img {
                    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                }
            }

            p {
                color: #fff;
            }

            .wrap-checkbox {

                .v-label {
                    color: #96AFD1!important;
                }
            }
        }
    }
}

  .wrap-sorting-peoples {
      /*position: fixed;*/
      /*top: 82px;*/
      /*right: auto;*/
      max-width: 320px;
      width: 100%;

    &--fixed {

    }

    .wrap-sorting-head {
      padding: 21px 20px 20px;
      display: flex;
      align-items: center;
      border-radius: 4px 4px 0 0;
      /*border-bottom: 2px solid #ECF4FF;*/
      /*box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);*/
      background: #fff;
      position: relative;
      border-bottom: 2px solid #ECF4FF;

      &__icon {
        margin-right: 17px;
        display: flex;
        align-items: center;
      }

      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #002B50;
      }
    }

    .search {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      padding: 10px 0 15px;
      box-shadow: 0px 2px 0px #F2F3FB;

      &__input {
        width: 100%;
      }

      &__icon {
        margin: 17px 14px 0 20px;
      }
    }

    &__body {
      border-radius: 0 0 4px 4px;
      padding: 20px 20px 22px;
      background: #fff;
    }

    .region {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;

      &__icon {
        margin: 5px 16px 0 0;
      }

      &__input {
        width: 100%;
      }
      .v-input .v-label {
        font-size: 16px;
      }
    }

    .age {
      margin-bottom: 20px;

      &__icon {
        margin: 5px 16px 0 0;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #6886AF;
        margin-bottom: 0;
      }

      &__inputs {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        margin-left: 36px;
      }
    }

    .male {
      margin-bottom: 12px;

      &__head {
        display: flex;
        align-items: center;
      }

      &__icon {
        margin: 7px 16px 0 0;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #6886AF;
        margin-bottom: 0;
      }

      .wrap-checkbox {
        margin-left: 36px;

        .v-input {
          margin-right: 30px;
        }

        .v-label {
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.4px;
          color: #6886AF!important;
        }
      }
    }
    .btn-cab {
      margin: 0 auto;
      display: block;

      &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
        min-width: 100px;
        width: 100%;
        padding: 0;
        margin: 0 auto;
      }
    }

    .wrap-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .v-btn {
        width: 48%;
        margin: 0 auto;
        display: block;

        &.v-btn:not(.v-btn--round).v-size--default {
          height: 40px;
          min-width: 100px;
          width: 48%;
          padding: 0;

        }
      }

      .btn-secondary {
        margin-right: 15px;
      }

    }
  }


  @media screen and (max-width: 920px) {

    .wrap-sorting {
      .sort,
      .region,
      .age {
        margin-bottom: 10px;

        p {
          margin-bottom: 5px;
        }
      }

      .male {
        p {
          margin-bottom: 5px;
        }
      }
    }
  }

  @media screen and (max-width: 525px) {

    .wrap-sorting {
      .sort,
      .region,
      .age {
        margin-bottom: 6px;

        /*p {*/
        /*  margin-bottom: 1px;*/
        /*  font-size: 10px;*/
        /*}*/
      }
      .male {
        /*p {*/
        /*  margin-bottom: 1px;*/
        /*  font-size: 10px;*/
        /*}*/
      }

      .btn-cab {
        margin: 0 auto;
        display: block;

        &.v-btn:not(.v-btn--round).v-size--default {
          height: 30px;
          min-width: 64px;
          width: 100px;
          padding: 0;
          margin: 0 auto;
          font-size: 10px;
        }
      }
      v-input--radio-group.v-input--radio-group--row .v-radio {
        margin-right: 0;
        margin-bottom: 0px;
      }
      .v-label {
        font-size: 12px;
      }
    }
  }
</style>