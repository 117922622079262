<template>
  <div class="wrap-google-form">
    <div v-if="setting">
<!--      <div class="wrap-googleForm__head">-->
<!--        <h2 v-if="setting.google_two_step == 1">Disable 2FA authentication</h2>-->
<!--        <h2 v-else>Enable 2FA authentication</h2>-->
<!--      </div>-->
      <div class="wrap-google-form__body">
        <div v-if="setting.google_two_step == 1">
          <div class="block-step">
<!--            <div class="block-icon">-->
<!--              <img src="/dist/images/frontend/warning-icon.svg" alt />-->
<!--            </div>-->
            <div>
              <p class="block-step__title error--text">{{__('Warning')}}</p>
              <p class="block-step__desc">{{__('By removing two-factor authentication, your account is at risk of hacker attacks')}}</p>
            </div>
          </div>
          <div class="block-step">
<!--            <div class="block-icon">-->
<!--              <img src="/dist/images/frontend/password-icon.svg" alt />-->
<!--            </div>-->
            <div>
              <p class="block-step__title">{{__('Disable 2FA authentication')}}</p>
              <p class="block-step__desc">{{__('Enter the 2FA code displayed in the app to confirm and enable 2FA for your account')}}</p>
            </div>
          </div>
          <div class="block-authentication">
            <div style="min-width: 300px">
              <InputLabelTextDefault color="main" :label='__("6-digit 2 FA number")' v-model="code" v-bind:errors="formValidate.code" @change="updateValidate('code')"></InputLabelTextDefault>
            </div>
              <!--            <v-btn class="btn-secondary" v-ripple="{ class:'secondary&#45;&#45;text'}" text v-on:click="$emit('update:dialog', false)">Cancel</v-btn>-->
<!--            <v-btn v-on:click="onSubmit" text v-ripple="{ class:'secondary&#45;&#45;text'}" :loading="submitDisabled" class="btn-cab white&#45;&#45;text error-btn">-->
<!--              {{__('Disable 2FA')}}-->
<!--            </v-btn>-->
            <div class="block-authentication__buttons">
<!--                <BaseButton-->
<!--                        width="125"-->
<!--                        class_btn="btn-liteGrey mr-3"-->

<!--                        v-on:click="$emit('update:dialog', false)"-->
<!--                >-->
<!--                    <span>{{__('Cancel')}}</span>-->
<!--                </BaseButton>-->
                <BaseButton
                        width="125"
                        class_btn="btn-red"
                        v-on:click="onSubmit"

                        :loading="submitDisabled"
                >
                    <span>{{__('Disable 2FA')}}</span>
                </BaseButton>
            </div>

          </div>
        </div>

        <div v-else>
          <div class="block-step">
<!--            <div class="block-icon">-->
<!--              <img src="/dist/images/frontend/installed-icon.svg" alt />-->
<!--            </div>-->

            <div>
              <p class="block-step__title">{{__('Step 1')}}</p>
              <p class="block-step__desc">{{__('Install a two-factor authentication program')}}</p>
            </div>

          </div>
          <div class="block-step">
<!--            <div class="block-icon">-->
<!--              <img src="/dist/images/frontend/QR-icon.svg" alt />-->
<!--            </div>-->

            <div>
              <p class="block-step__title">{{__('Step 2')}}</p>
              <p class="block-step__desc">{{__('Open up your Google authenticator 2FA mobile app in your phone and scan the following QR-barcode')}}</p>
            </div>
          </div>

          <div class="security-qr">
            <VueQrcode :value="'otpauth://totp/'+$APP_NAME+':'+setting.email+'?secret='+setting.google2fa_secret+'&issuer='+$APP_NAME+'&algorithm=SHA1&digits=6&period=30'" width="170"/>
            <!--              <VueQrcode :value="setting.google2fa_url" :options="{ width: 175, margin: 5 }"></VueQrcode>-->
          </div>
          <div class="block-gr">
              <p>{{__('If your mobile app does not read QR-code, enter these code:')}}</p>
              <InputLabelTextDefault color="read_only" readonly copy :value="setting.google2fa_secret"></InputLabelTextDefault>
          </div>
          <div class="block-step">
<!--            <div class="block-icon">-->
<!--              <img src="/dist/images/frontend/password-icon.svg" alt />-->
<!--            </div>-->
            <div>
              <p class="block-step__title">{{__('Step 3')}}</p>
              <p class="block-step__desc">{{__('Enter the 2FA code displayed in the app to confirm and enable 2FA for your account')}}</p>
            </div>
          </div>
          <div class="block-authentication">
            <div class="block-authentication__wrap-input">
              <InputLabelTextDefault color="main" :label='__("6-digit 2 FA number")' v-model="code" v-bind:errors="formValidate.code" @change="updateValidate('code')"></InputLabelTextDefault>
            </div>
<!--            <v-btn class="btn-secondary" v-ripple="{ class:'secondary&#45;&#45;text'}" text v-on:click="$emit('update:dialog', false)">Cancel</v-btn>-->
<!--            <v-btn v-on:click="onSubmit" text v-ripple="{ class:'secondary&#45;&#45;text'}" :loading="submitDisabled" class="btn-cab  white&#45;&#45;text">-->
<!--              {{__('Enable 2FA')}}-->
<!--            </v-btn>-->

              <div class="block-authentication__buttons">
<!--                  <BaseButton-->
<!--                          width="125"-->
<!--                          class_btn="btn-liteGrey mr-3"-->

<!--                          v-on:click="$emit('update:dialog', false)"-->
<!--                  >-->
<!--                      <span>{{__('Cancel')}}</span>-->
<!--                  </BaseButton>-->
                  <BaseButton
                          width="125"
                          v-on:click="onSubmit"

                          :loading="submitDisabled"
                  >
                      <span>{{__('Enable 2FA')}}</span>
                  </BaseButton>
              </div>
          </div>
        </div>
      </div>


    </div>
    <div v-else>
        {{__('loading')}}
    </div>
  </div>
</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import InputLabelTextDefault from '../../modules/InputLabelTextDefault';
    import {required, numeric, maxLength, minLength} from "vuelidate/lib/validators";
    export default {

        name: 'FormGoogle2fa',
        data () {
            return {
                submitDisabled: false,
                code: null,
                formValidate: {
                    code: null
                },
            }
        },
        props: {
            setting: {
                type: Object
            }
        },
        computed: {

        },
        validations() {
            return {
                code: {
                    required,
                    numeric,
                    maxLength: maxLength(6),
                    minLength: minLength(6),
                },
            }
        },
        created() {

        },
        asyncComputed: {

        },
        methods: {
            updateValidate: function(type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function () {
                let valid= this.$v;
                if(valid.$invalid) {
                    let massages= {
                        'code': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;
                    let response= {
                        'code': this.code
                    };

                    this.$http.post('ajax/setting/enable_two_fa', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response)=> {
                            if(this.$checkAuth(response)) {
                                // response= {
                                //     'data': {
                                //         'enable': 1
                                //     }
                                // };
                                this.$emit('update:dialog', false);
                                this.submitDisabled = false;
                                this.$emit('updateEnable', response.data.enable);

                                this.code = null;
                            }
                        }).catch(err => {
                            this.submitDisabled= false;
                            let response= this.httpHandler(err);
                            if(response.error) {
                                this.formValidate = response.error.message;

                            }
                        });
                }
            }
        },
        components: {
            InputLabelTextDefault,
            VueQrcode
        },

    }
</script>

<style lang="scss">

  .wrap-google-form {

    &__head {
      padding: 20px 0 20px 25px;
      border: 1px solid #F0F6FE;
      h2 {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #002B50;
      }
    }

    &__body {
      //padding: 24px 28px 29px 25px;

      .block-step {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        .block-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 46px;
          height: 46px;
          background: #FFFFFF;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          margin-right: 21px;
        }

        &__title {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #26A9F4;
          margin-bottom: 1px;
        }

        &__desc {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
          margin-bottom: 0;
        }
      }

      .security-qr {
        //margin: -12px 0 17px 67px;
        margin: 16px 0 17px 0;
        filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1))
      }

      .block-gr {
        //margin-left: 67px;
        margin-left: 0;
        /*margin-bottom: 3px;*/

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
          margin-bottom: 0;
        }
      }

      .block-authentication {
        width: 50%;
        display: flex;
        //margin-left: 67px;
        /*height: 40px;*/

          &__buttons {
              display: flex;
              align-items: center;
          }

          &__wrap-input  {
              min-width: 300px;
          }

        .v-btn.btn-secondary {
          &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 105px;
            padding: 0;
            margin: 0 0 0 17px;
          }
        }

        .v-btn.error-btn {
          background-color: #FF4B55;
          duration: .3s;
          outline: none;

          &:hover {
            background-color: #E94F4E;
            duration: .3s;
          }
        }

        .v-btn.btn-cab {
          border-radius: 6px;

          &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 118px;
            padding: 0;
            margin: 0 0 0 15px;
          }
        }
      }
    }
  }

    @media screen and (max-width: 1050px){
        .wrap-google-form {
            &__body {
                .block-authentication {
                    &__wrap-input {
                        min-width: 200px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px){
        .wrap-google-form {

            &__body {
                .block-authentication {
                    flex-wrap: wrap!important;
                    &__wrap-input {
                        min-width: 300px;
                        width: 100%;
                        flex-shrink: 0;
                    }

                    &__buttons {
                        display: flex;
                        margin-top: 20px;
                    }
                }

                .block-step {
                    margin-bottom: 8px!important;
                    &__title {
                        font-size: 14px!important;
                        line-height: 18px!important;
                    }

                    &__desc {
                        font-size: 12px!important;
                    }
                }
            }
        }
    }

    .dark .wrap-google-form {
        &__body {
            .block-step {
                &__desc {
                    color: #96AFD1;
                }
            }
        }
    }
</style>
