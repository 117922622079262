<template>
  <div class="wrap-withdraws">

    <div class="wrap-withdraws__body">
      <div class="wrapper-payment-select">
        <div class="wrapper-payment-select__item">
<!--          <span style="margin-top: -8px;">{{__('Choose a payment system')}}</span>-->
<!--          <div class="block-gold">-->
<!--            <img src="/dist/images/frontend/money.svg" alt />-->
<!--            <div class="block-gold-info">-->
<!--              <span class="block-gold-info__title">{{__('Gold Coin')}}</span>-->
<!--              <span class="block-gold-info__subtitle">{{parseFloat(auth['balance_real'].available).toFixed(2)}}</span>-->
<!--            </div>-->
<!--          </div>-->
          <span>{{__('Choose a payment system')}}</span>
          <v-select
            v-if="user_balance.balance_real.available > 0"
            dense
            filled
            solo
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="form.wallet"
            :items="coins"
            v-on:change="changeWallet(true)"
            :filter="customFilter"
            item-color="white"
          >
            <template v-slot:selection="data">
              <img height="40" class="payment-item__icon mr-4" v-html="data.item.img" :src="data.item.img">
              {{ data.item.name }}
              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
            </template>
            <template v-slot:item="data">
              <template >
                <div class="wrap-item-select">
                  <img class="payment-item__icon" v-html="data.item.img" :src="data.item.img">
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </template>
            </template>
          </v-select>
          <v-select
                  disabled
                  v-else
                  dense
                  filled
                  solo
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="form.wallet"
                  :items="coins1"
                  v-on:change="changeWallet(true)"
                  :filter="customFilter"
                  item-color="white"
          >
            <template v-slot:selection="data">
              <img height="40" class="payment-item__icon mr-4" v-html="data.item.img" :src="data.item.img">
              {{ data.item.name }}
<!--              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />-->
            </template>
            <template v-slot:item="data">
              <template >
                <div class="wrap-item-select">
                  <img class="payment-item__icon" v-html="data.item.img" :src="data.item.img">
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </template>
            </template>
          </v-select>
        </div>

        <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.71 1.29L15.71 5.29C15.8488 5.43063 15.9428 5.6092 15.9801 5.80319C16.0175 5.99718 15.9966 6.19789 15.92 6.38C15.845 6.56262 15.7176 6.71895 15.5539 6.82928C15.3901 6.93961 15.1974 6.99902 15 7H1C0.734784 7 0.48043 6.89465 0.292894 6.70711C0.105357 6.51957 0 6.26522 0 6C0 5.73479 0.105357 5.48043 0.292894 5.2929C0.48043 5.10536 0.734784 5 1 5H12.59L10.29 2.71C10.1017 2.5217 9.99591 2.2663 9.99591 2C9.99591 1.7337 10.1017 1.47831 10.29 1.29C10.4783 1.1017 10.7337 0.995911 11 0.995911C11.1319 0.995911 11.2624 1.02188 11.3842 1.07234C11.5061 1.1228 11.6168 1.19676 11.71 1.29Z" fill="#96AFD1"/>
          <path d="M11.7113 10.7061L15.7118 6.70883C15.8506 6.56831 15.9446 6.38986 15.9819 6.196C16.0193 6.00214 15.9984 5.80156 15.9218 5.61958C15.8468 5.43708 15.7194 5.28086 15.5556 5.1706C15.3919 5.06035 15.1992 5.00099 15.0017 5H1.00011C0.734867 5 0.480485 5.10529 0.292927 5.29269C0.105369 5.4801 0 5.73428 0 5.99932C0 6.26435 0.105369 6.51853 0.292927 6.70594C0.480485 6.89335 0.734867 6.99864 1.00011 6.99864H12.5914L10.2912 9.28708C10.1028 9.47525 9.99704 9.73047 9.99704 9.99659C9.99704 10.2627 10.1028 10.5179 10.2912 10.7061C10.4795 10.8943 10.7349 11 11.0012 11C11.1331 11 11.2637 10.974 11.3855 10.9236C11.5074 10.8732 11.6181 10.7993 11.7113 10.7061Z" fill="#96AFD1"/>
        </svg>
        <div class="wrapper-payment-select__item">
          <span>{{__('What coin do you want to receive?')}}</span>

<!--          <v-select-->
<!--                  dense-->
<!--                  filled-->
<!--                  solo-->
<!--                  :menu-props="{ bottom: true, offsetY: true }"-->
<!--                  v-model="payment"-->
<!--                  :items="payments_mind"-->
<!--                  v-on:change="changeWallet"-->
<!--                  :filter="customFilter"-->
<!--                  item-color="white"-->
<!--          >-->
<!--            <template v-slot:selection="data">-->
<!--              <div class="img" :class="data.item.name_class"></div>-->
<!--              {{ data.item.name }}-->
<!--              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />-->
<!--            </template>-->
<!--            <template v-slot:item="data">-->
<!--              <template>-->
<!--                <div class="wrap-item-select">-->
<!--                  <div class="img" :class="data.item.name_class"></div>-->
<!--                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>-->
<!--                </div>-->
<!--              </template>-->
<!--            </template>-->
<!--          </v-select>-->

          <v-select
            dense
            filled
            solo
            v-model="payment"
            :items="payments"
            :filter="customFilter"
            item-color="white"
            :menu-props="{ bottom: true, offsetY: true }"
            @change="dataPayment()"
          >
            <template v-slot:selection="data">
              <div class="img" :class="data.item.name_class"></div>
              {{ data.item.name }}
              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
            </template>
            <template v-slot:item="data">
              <template >
                <div class="wrap-item-select">
                  <div class="img" :class="data.item.name_class"></div>
                  <v-list-item-title v-html="data.item.name" class='menu-item'></v-list-item-title>
                </div>
              </template>
            </template>
          </v-select>
        </div>
      </div>
      <div class="wrapper-inputs">
        <div class="wrapper-inputs__item">
          <div class="d-flex justify-content-between">
            <p class="text text--label">{{__('Withdrawal amount')}}</p>
            <p class="text text--label">{{__('Min')}} {{primNum(((form.wallet== 1) ? payment.min_withdraw*payment.course_usd : payment.min_withdraw* (payment.course_mind)), 2)}} {{(form.wallet== 1) ? 'USDT' : 'MIND'}}</p>
          </div>
          <div class="wrap-input">
            <InputLabelTextDefault
                    style="width: 100%"
                    color="balance"
                    v-model="calc_give"
                    @input="calcSum($event, 'give')"
                    v-bind:errors="formValidate.calc_give"
                    type="number"
                    :placeholder="__('0')"

            >
              <template v-slot:right>
                <div class="currency">
                  <span>{{__((form.wallet== 1) ? 'GOLD' : 'MIND')}}</span>
                </div>
              </template>
            </InputLabelTextDefault>
          </div>
        </div>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.71 9.29L17.71 5.29C17.6168 5.19676 17.5061 5.1228 17.3843 5.07234C17.2624 5.02188 17.1319 4.99591 17 4.99591C16.7337 4.99591 16.4783 5.1017 16.29 5.29C16.1017 5.47831 15.9959 5.7337 15.9959 6C15.9959 6.2663 16.1017 6.5217 16.29 6.71L18.59 9H7.00002C6.7348 9 6.48045 9.10536 6.29291 9.2929C6.10537 9.48043 6.00002 9.73479 6.00002 10C6.00002 10.2652 6.10537 10.5196 6.29291 10.7071C6.48045 10.8946 6.7348 11 7.00002 11H21C21.1974 10.999 21.3902 10.9396 21.5539 10.8293C21.7176 10.7189 21.845 10.5626 21.92 10.38C21.9966 10.1979 22.0175 9.99718 21.9801 9.80319C21.9428 9.6092 21.8488 9.43063 21.71 9.29ZM17 13H3.00002C2.80259 13.001 2.60988 13.0604 2.44616 13.1707C2.28244 13.2811 2.15504 13.4374 2.08002 13.62C2.00344 13.8021 1.98252 14.0028 2.01989 14.1968C2.05726 14.3908 2.15126 14.5694 2.29002 14.71L6.29002 18.71C6.38298 18.8037 6.49358 18.8781 6.61544 18.9289C6.7373 18.9797 6.86801 19.0058 7.00002 19.0058C7.13203 19.0058 7.26274 18.9797 7.38459 18.9289C7.50645 18.8781 7.61705 18.8037 7.71002 18.71C7.80375 18.617 7.87814 18.5064 7.92891 18.3846C7.97968 18.2627 8.00582 18.132 8.00582 18C8.00582 17.868 7.97968 17.7373 7.92891 17.6154C7.87814 17.4936 7.80375 17.383 7.71002 17.29L5.41002 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8947 14.5196 18 14.2652 18 14C18 13.7348 17.8947 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13Z" fill="#96AFD1"/>
        </svg>

        <div class="wrapper-inputs__item">
          <div class="d-flex justify-content-between">
            <p class="text text--label">{{__('You receive')}}</p>
<!--            <p class="text text&#45;&#45;label">{{__('Min :min GOLD', {min: payment.min_withdraw})}}</p>-->
          </div>
          <InputLabelTextDefault
                  color="balance"
                  v-model="calc_get"
                  v-bind:errors="formValidate.calc_get"
                  disabled
                  type="number"
                  :placeholder="__('0')"
          >
            <template v-slot:right>
              <div class="currency" v-if="form.wallet== 2 && form.payment_mind==1">
                USDT
              </div>
              <div class="currency" v-else>
                {{payment.currency.toUpperCase()}}
              </div>
            </template>
          </InputLabelTextDefault>
        </div>
      </div>
      <div v-if="(payment.id === 0 && form.wallet == 1) || (form.wallet == 2 && form.payment_mind == 1)">

      </div>
      <div v-else-if="payment.cryptocurrency == '1'">
        <div>
          <div v-if="payment.currency== 'xrp'" class="d-flex wrapper-inputs-tag">
            <div class="wrapper-inputs-tag__item">
              <p class="text text--label">{{__('Receiving address')}}</p>
              <InputLabelTextDefault
                      color="balance"
                      v-model="address"
                      v-bind:errors="formValidate.address"
                      @change="updateValidate('address')"
                      readonly
              ></InputLabelTextDefault>
            </div>
            <div class="wrapper-inputs-tag__item">
              <p class="text text--label">{{__('Tag')}}</p>
              <InputLabelTextDefault
                      color="balance"
                      v-model="tag"
                      v-bind:errors="formValidate.tag"
                      @change="updateValidate('tag')"
              ></InputLabelTextDefault>
            </div>
          </div>
          <div v-else>
            <p class="text text--label">{{__('Receiving address')}}</p>
            <InputLabelTextDefault
                    color="balance"
                    v-model="address"
                    v-bind:errors="formValidate.address"
                    @change="updateValidate('address')"
            ></InputLabelTextDefault>
          </div>
        </div>
<!--        <div v-else class="non-wallet">-->
<!--          <div class="non-wallet__txt">-->
<!--            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <g clip-path="url(#clip0)">-->
<!--                <path d="M17.7279 14.6681L10.3265 1.92834C9.9862 1.3541 9.49703 1.0138 8.98659 1.0138C8.45488 1.0138 7.96571 1.3541 7.64668 1.92834L0.287829 14.6681C-0.0524651 15.2423 -0.0950018 15.8378 0.181487 16.2845C0.436707 16.7311 0.968416 16.9863 1.649 16.9863H16.3667C17.026 16.9863 17.5577 16.7311 17.8342 16.2845C18.0894 15.8378 18.0469 15.2423 17.7279 14.6681ZM17.026 15.8378C16.9197 16.008 16.6857 16.0931 16.3454 16.0931H1.62774C1.28744 16.0931 1.03222 15.9867 0.947148 15.8378C0.840806 15.6677 0.883343 15.4125 1.05349 15.1147L8.41234 2.37498C8.58249 2.07722 8.79517 1.90707 8.98659 1.90707C9.178 1.90707 9.39069 2.07722 9.56083 2.37498L16.9197 15.1147C17.0898 15.4125 17.1324 15.6677 17.026 15.8378Z" fill="#FF4B55"/>-->
<!--                <path d="M8.98667 5.20363C8.73145 5.20363 8.54004 5.39504 8.54004 5.65026V11.5629C8.54004 11.8181 8.73145 12.0095 8.98667 12.0095C9.24189 12.0095 9.43331 11.8181 9.43331 11.5629V5.65026C9.43331 5.39504 9.24189 5.20363 8.98667 5.20363Z" fill="#FF4B55"/>-->
<!--                <path d="M8.98663 14.583C9.42124 14.583 9.77355 14.2306 9.77355 13.796C9.77355 13.3614 9.42124 13.0091 8.98663 13.0091C8.55202 13.0091 8.19971 13.3614 8.19971 13.796C8.19971 14.2306 8.55202 14.583 8.98663 14.583Z" fill="#FF4B55"/>-->
<!--              </g>-->
<!--              <defs>-->
<!--                <clipPath id="clip0">-->
<!--                  <rect width="18" height="18" fill="white"/>-->
<!--                </clipPath>-->
<!--              </defs>-->
<!--            </svg>-->

<!--            <p>{{__('Attention!')}}</p>-->
<!--          </div>-->
<!--          <div v-if="payment.currency == 'Silver'" class="non-wallet__info">-->
<!--            <span>{{__('Coins will be converted to Silver Coin.')}}</span>-->
<!--          </div>-->
<!--          <div v-else class="non-wallet__info">-->
<!--            <span>{{__('You have not saved data for this payment system.')}} </span>-->
<!--            <span>{{__('Enter data in')}} </span>-->
<!--            <router-link :to="{name: 'settings', params: {'current': 'add_wallet', lang: $store.getters.getLang}}">{{__('Settings')}}</router-link>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div v-else>
        <div v-if="cardItems.length" class="wrapper-payment-select__item">
          <span class="text text--label">{{__('Choose a card')}}</span>
          <v-select
            dense
            filled
            solo
            v-model="cardItems[0]"
            :items="cardItems"
            :filter="customFilter"
            item-color="white"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:selection="data">
              <div class="img" :class="payment.name_class"></div>
              {{ data.item.card }}
              <img class="chevron-icon" src="/dist/images/frontend/chevron-down.svg" alt />
            </template>
            <template v-slot:item="data">
              <template>
                <div class="wrap-item-select">
                  <div class="img" :class="payment.name_class"></div>
                  <v-list-item-title v-html="data.item.card" class='menu-item'></v-list-item-title>
                </div>
              </template>
            </template>
          </v-select>
        </div>

        <div v-else class="non-wallet">
          <div class="non-wallet__txt">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
                <path d="M17.7279 14.6681L10.3265 1.92834C9.9862 1.3541 9.49703 1.0138 8.98659 1.0138C8.45488 1.0138 7.96571 1.3541 7.64668 1.92834L0.287829 14.6681C-0.0524651 15.2423 -0.0950018 15.8378 0.181487 16.2845C0.436707 16.7311 0.968416 16.9863 1.649 16.9863H16.3667C17.026 16.9863 17.5577 16.7311 17.8342 16.2845C18.0894 15.8378 18.0469 15.2423 17.7279 14.6681ZM17.026 15.8378C16.9197 16.008 16.6857 16.0931 16.3454 16.0931H1.62774C1.28744 16.0931 1.03222 15.9867 0.947148 15.8378C0.840806 15.6677 0.883343 15.4125 1.05349 15.1147L8.41234 2.37498C8.58249 2.07722 8.79517 1.90707 8.98659 1.90707C9.178 1.90707 9.39069 2.07722 9.56083 2.37498L16.9197 15.1147C17.0898 15.4125 17.1324 15.6677 17.026 15.8378Z" fill="#FF4B55"/>
                <path d="M8.98667 5.20363C8.73145 5.20363 8.54004 5.39504 8.54004 5.65026V11.5629C8.54004 11.8181 8.73145 12.0095 8.98667 12.0095C9.24189 12.0095 9.43331 11.8181 9.43331 11.5629V5.65026C9.43331 5.39504 9.24189 5.20363 8.98667 5.20363Z" fill="#FF4B55"/>
                <path d="M8.98663 14.583C9.42124 14.583 9.77355 14.2306 9.77355 13.796C9.77355 13.3614 9.42124 13.0091 8.98663 13.0091C8.55202 13.0091 8.19971 13.3614 8.19971 13.796C8.19971 14.2306 8.55202 14.583 8.98663 14.583Z" fill="#FF4B55"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <p>{{__('Attention!')}}</p>
          </div>
          <div class="non-wallet__info">
            <span>{{__('You have not saved data for this payment system.')}} </span>
            <span>{{__('Enter data in')}} </span>
            <router-link :to="{name: 'settings', params: {'current': 'add_wallet', lang: $store.getters.getLang}}">{{__('Settings')}}</router-link>
          </div>
        </div>
      </div>
      <div class="block-bottom">
        <BaseButton
          v-on:click="(auth.verified == 1) ? onModal() : inaccessible_withdraw = true"
          width="175"
          :loading="submitDisabled"
          v-if="(form.wallet == 1 && (payment.id === 0 || address && tag || payment.currency!= 'xrp' && address)) || (form.wallet == 2 && (form.payment_mind == 1 || form.payment_mind == 2 && address))"
        >
          <span>{{__('Withdraw funds')}}</span>
        </BaseButton>
        <BaseButton
          width="175"
          disabled
          class_btn="btn-liteGrey"
          v-else
        >
          <span>{{__('No validate')}}</span>
        </BaseButton>
        <div class="block-bottom__info">
<!--          <p style="color: #00C443">{{__('Will be charged')}} {{primNum(Number(calc_get)+commission, payment.sprintf)}} {{payment.currency.toUpperCase()}}</p>-->
          <p>
            {{__('Payment system commission')}}
            <span v-if="payment.withdraw_pay_commission== 'percent'">{{payment.withdraw_commission}}%</span>
            <span v-else>{{commission}} {{payment.currency.toUpperCase()}}</span>
          </p>
        </div>
      </div>
    </div>
    <ModalDefault  :width="610" v-if="modalWithdraw">
      <div class="wrap-modal">
        <div class="wrap-modal__head">
          {{__('Withdraw funds')}}
        </div>
        <div class="wrap-modal__body">
          <Payment v-bind:payments="payments">
            <template>
              <h5>{{__('Are you sure you want to transfer funds?')}}</h5>
              <p class="block-row"><span>{{__('Date of creation:')}}</span> {{new Date() | formatFullDate}}</p>
              <p class="block-row"><span>{{__('Will be sent:')}}</span> {{calc_give}} {{__('Gold Coin')}}</p>
              <p class="block-row"><span>{{__('Will be withdrawn:')}}</span> {{calc_get}} {{payment.name}}</p>
              <p class="block-row"><span>{{__('To the address:')}}</span> {{address}}</p>
              <div class="wrap-btn d-flex justify-content-end" style="margin: 20px auto 0">
                <v-btn
                        text
                        class="btn-cancel mr-5"
                        v-on:click="modalWithdraw = false"
                        v-ripple="{ class:'secondary--text'}"
                >
                  <span>{{__('No')}}</span>
                </v-btn>

                <BaseButton
                        width="100"
                        v-on:click="onSubmit"
                        :loading="submitDisabled"
                >
                  <span>{{__('Yes')}}</span>
                </BaseButton>
              </div>
            </template>
          </Payment>
        </div>
      </div>
    </ModalDefault>
    <ModalInaccessibleWithdraw
            v-if="inaccessible_withdraw"
            v-bind:inaccessible_withdraw.sync="inaccessible_withdraw"
    ></ModalInaccessibleWithdraw>

  </div>
</template>

<script>

    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {decimal, required, minValue, maxValue} from "vuelidate/lib/validators";
    import ModalDefault from "../../modules/ModalDefault";
    import Payment from "../../modules/Payment";
    import ModalInaccessibleWithdraw from "./ModalInaccessibleWithdraw";
    import _ from 'lodash';

    export default {
        name: 'Withdraws',
        data () {
            return {
                payment: this.payments[0],
                formValidate: {
                    tag: null,
                    address: null,
                    calc_give: null,
                    calc_get: null,
                },
                tag: null,
                address: null,
                calc_give: null,
                calc_get: null,
                amount_currency: null,
                submitDisabled: false,
                modalWithdraw: false,
                cardItems: [],
                inaccessible_withdraw: false,
                coins: [
                  {
                    name: "MIND Coin",
                    img: "/dist/images/frontend/light-coin.svg",
                    value: 2
                  },
                  {
                    name: "USDT Coin",
                    img: "/dist/images/frontend/money.svg",
                    value: 1
                  }
                ],
                  coins1: [
                  {
                    name: "MIND Coin",
                    img: "/dist/images/frontend/light-coin.svg",
                    value: 2
                  },
                ],
                form: {
                  wallet: 2,
                  amount: 0,
                  amount_coin: 0,
                  payment_mind: 2,
                },

                payments_mind: [
                  // {
                  //   name: "USDT Coin (сhange)",
                  //   img: "/dist/images/frontend/money.svg",
                  //   value: 1
                  // },
                  _.find(this.payments, {currency: 'mind'})
                ]
            }
        },
        validations() {
            return {
                address: {
                    required: ((this.payment.id!== 0)? required : false),
                },
                tag: {
                    required: ((this.payment.currency== 'xrp')? required : false)
                },
                calc_give: {
                    required,
                    decimal,
                    minValue: minValue(this.primNum(((this.form.wallet== 1) ? this.payment.min_withdraw*this.payment.course_usd : this.payment.min_withdraw*(this.payment.course_mind)), 2)),
                    maxValue: maxValue(this.primNum(((this.form.wallet== 1) ? this.user_balance.balance_real.available : this.user_balance.balance_lite.available), 2))
                },
                calc_get: {
                    required,
                    decimal,
                },
                silver: {
                    Object
                }
            }
        },
        props: {
            silver: {
                type: Object
            },
            user_balance: {
                type: Object
            },
            payments: {
                type: Array
            },
            url: {
                type: String
            },
            wallets: {
                type: [Object, Array]
            }
        },
        computed: {

          auth() {
            return this.$store.state.auth.user;
          },
          commission() {
              let commission= 0;

              if(this.payment.withdraw_pay_commission== 'percent') {
                  commission= Number(this.calc_give)/100*Number(this.payment.withdraw_commission);
              } else {
                  commission= this.payment.withdraw_commission;
              }

              return Number(Number(commission));
          },
          // dataPayment() {
          //   // let key = this.payment.id;
          //   // let item;
          //   let wallets = this.wallets;
          //   let keys = Object.keys(wallets);
          //   return keys;
          //   // keys.forEach((k) => {
          //   //   // if(key == k) {
          //   //   //   item = this.wallets[k];
          //   //   // }
          //   //   item = this.wallets[k]
          //   // });
          //   // return item;
          // }
        },
        asyncComputed: {

        },
        watch:{
          calc_give(val) {
            if(!val) {
              this.formValidate.calc_get = null;
            }
          }
        },

        created() {
            let min_withdraw= (this.form.wallet== 1) ? this.payment.min_withdraw*this.payment.course_usd : this.payment.min_withdraw*(this.payment.course_mind);

            this.calc_give= this.primNum(min_withdraw, 2);
            this.calcSum(this.calc_give, 'give');
        },
        methods: {
          showItem(item) {
            return item.value === 2 || (item.value === 1 && this.user_balance.balance_real.available > 0);
          },
            changeWallet(wallet= false) {
                // if(this.form.wallet== 1) {
                //     if(wallet) {
                //         this.payment= this.payments[0];
                //     }
                // } else {
                //     // this.calc_give= this.payment.min_withdraw;
                //     // this.calcSum(this.calc_give, 'give');
                //     //
                // }

                if(wallet) {
                    this.payment= this.payments[0];
                }

                let min_withdraw= (this.form.wallet== 1) ? this.payment.min_withdraw*this.payment.course_usd : this.payment.min_withdraw*(this.payment.course_mind);


                this.calc_give= this.primNum(min_withdraw, 2);
                this.calcSum(this.calc_give, 'give');
            },
            dataPayment() {
              let key = this.payment.id;
              let item = this.payment.cryptocurrency == '1' ? {} : [];
              for(let k in this.wallets) {
                if (key == k) {
                  item = this.wallets[k]
                }
              }
              if (this.payment.cryptocurrency == '1') {
                this.address = item.address ? item.address : null;
                this.tag = item.tag ? item.tag : null
              } else {
                this.cardItems = item;
              }
              this.changeWallet();
            },
            updateValidate(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onModal() {
              let valid = this.$v;
              if (valid.$invalid) {
                let massages = {
                  calc_get:[],
                  calc_give:[],
                  address:[],
                  tag:[],
                };
                this.formValidate = this.$formValidate(massages, valid);
              } else {
                this.modalWithdraw = true;
              }
            },
            onSubmit () {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        calc_get:[],
                        calc_give:[],
                        address:[],
                        tag:[],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    let payment_id = null;
                    if (this.form.wallet == 2 && this.form.payment_mind == 1) {
                      payment_id = 0
                    }  else {
                      payment_id = this.payment.id
                    }
                    let response= {
                        "payment_id": payment_id,
                        "amount_coin": this.calc_get,
                        "amount": this.calc_give,
                        "address": this.address,
                        "tag": this.tag,
                        "mind": (this.form.wallet == 2) ? 1 : 0,
                    };
                    this.modalWithdraw = false;

                    if(this.auth.google_two_step == 1) {
                      this.$emit('update:url', "ajax/withdraw/create");
                      this.$emit('update:modal_confirmation_2fa', response);
                      this.modalWithdraw = false;
                      this.submitDisabled = false;

                    } else {
                        this.$http.post("ajax/withdraw/create", response)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                              // response= {
                              //   "data": {
                              //     "status": "success",
                              //     "info": {
                              //       "user_balance": {
                              //         "balance_real": {
                              //           "available": "112.53",
                              //           "frozen": 0.2,
                              //           "total": "112.74"
                              //         },
                              //         "balance_lite": {
                              //           "available": "133.37",
                              //           "frozen": 32.87,
                              //           "total": "166.25"
                              //         }
                              //       }
                              //     },
                              //     "with": {
                              //       "wallet": 1,
                              //       "amount": "10",
                              //       "commission": 0,
                              //       "opp_type": 2,
                              //       "tx": "adsdsasad",
                              //       "payment_id": 1,
                              //       "status": 2,
                              //       "created_at": "2021-09-22T10:57:38.000000Z"
                              //     }
                              //   },
                              //   "auth": true,
                              //   "status": 200
                              // };

                                if(this.$checkAuth(response)) {
                                //

                                    this.submitDisabled = false;
                                    this.successForm = true;
                                    this.$emit('updateWithdraws', response.data.info, response.data.with);
                                    this.$emit('update:url', "ajax/withdraw/create");
                                    this.tag = null;
                                    this.address = null;
                                    this.calc_give = null;
                                    this.calc_get = null;
                                }
                            })
                            .catch((err) => {
                                this.submitDisabled = false;
                                let response = this.httpHandler(err);
                                if (response.error) {
                                    this.formValidate = response.error.message;
                                }
                            });
                    }
                }
            },
            customFilter (item, queryText) {
                const textOne = item.name.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1
            },
            calcSum(val, type= 'give') {
                let payment= this.payment;

                if(type== 'give') {
                    // let val_give= val;
                    let val_give, calc_get;

                    val_give= Number(val);

                    if(payment.withdraw_pay_commission== 'percent') {
                        val_give= val_give- this.commission;
                    }

                    if(this.form.wallet== 2) {
                      if(this.form.payment_mind== 1) {
                          // calc_get= val_give/payment.course_mind;
                          calc_get= val_give*this.silver.course_lite;
                          calc_get= this.primNum(calc_get, 4);
                      } else {
                          calc_get= val_give/payment.course_mind;
                          calc_get= this.primNum(calc_get, 6);
                      }
                    } else {
                        calc_get= (val_give/payment.course_usd)*1;

                        calc_get= this.primNum(calc_get, payment.sprintf);
                    }

                    if(payment.withdraw_pay_commission== 'rate') {
                        calc_get= calc_get- this.commission;
                    }
                    // if(payment.id=== 0) {
                    //     // console.log('calc_getcalc_get');
                    //     // console.log(calc_get);
                    //     // console.log(this.silver.course_lite);
                    //     // calc_get= calc_get/this.silver.course_lite;
                    //     // console.log('calc_get');
                    //     // console.log(calc_get);
                    //     calc_get-= (calc_get/100*this.silver.percent_lite);
                    // }



                    this.calc_get= calc_get;
                    // this.calc_get= this.primNum(val/payment.course_usd, payment.sprintf);
                } else {
                    this.calc_give= this.primNum(val*payment.course_usd, 2);
                }
                // if (this.calc_give) {
                //   this.updateValidate('calc_give');
                //   this.updateValidate('calc_get');
                // }
            },
        },
        components: {
            InputLabelTextDefault,
            ModalDefault,
            Payment,
            ModalInaccessibleWithdraw
        },

    }
</script>

<style lang="scss">

  .dark {
    .wrap-withdraws {
      background: #171827;
      &__body {
        background: #171827;

        .wrapper-payment-select {
          span {
            color: #96AFD1;
          }

          .block-gold {
            background: #181818;
            border-color: transparent;

            .block-gold-info {
              &__title {
                color: #fff;
              }
              &__subtitle {
                color: #96AFD1;
              }
            }
          }
        }

        .block-bottom {
          p {
            color: #96AFD1;
          }
        }
      }
      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
        background: #181818;
        border-color: transparent;

        &:hover {
          border-color: #0F9AFF;
          background: #181818!important;
        }
      }

      .text {
        &--label {
          color: #96AFD1;
        }
      }

      .currency {
        color: #96AFD1;
      }
    }
  }

  .wrap-withdraws {
    background-color: #FFFFFF;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    &__head {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid #ecf4ff;

      svg {
        margin-right: 18px;
      }


      p {
        font-size: 16px;
        line-height: 22px;
        color: #002B50;
        margin: 0!important;
      }
    }

    &__body {
      padding: 20px;

      .wrapper-payment-select {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &__item {
          width: 50%;
        }

        svg {
          margin: 20px 20px 0;
          flex-shrink: 0;
        }

        span {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #6886AF;
          margin-bottom: 12px;
          display: block;
        }

        .block-gold {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          padding: 12px 19px 12px 17px;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          border-radius: 4px;
          box-shadow: none;

          img {
            height: 40px;
            margin-right: 20px;
          }

          .block-gold-info {

            &__title {
              font-size: 15px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #002B50;
              margin-bottom: 3px;
            }

            &__subtitle {
              font-size: 15px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #86A1C6;
              margin: 0;
            }
          }
        }
      }

      .wrapper-inputs {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &__item {
          width: 50%;
        }

        svg {
          margin: 35px 26px 0;
        }
      }

      .non-wallet {
        display: flex;

        &__txt {
          display: flex;
          align-items: center;
          margin-right: 12px;

          svg {
            margin-right: 9px;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #FF4B55;
            margin: 0;
          }
        }

        &__info {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #6886AF;
          margin: 0;

          a {
            color: #0F9AFF;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .wrapper-inputs-tag {
        display: flex;
        &__item {
          &:first-child {
            width: 55%;
            margin-right: 30px;
          }

          &:last-child {
            width: 45%;
          }
        }
      }

      .wrapper-block-info {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        margin-top: 30px;

        .block-info {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }

          p {
            font-size: 14px;
            line-height: 19px;
            color: #6886AF;
            margin-bottom: 2px;
          }

          span {
            font-size: 20px;
            line-height: 27px;
            color: #26A9F4;
          }
        }
      }

      .block-bottom {
        display: flex;
        align-items: center;
        margin-top: 40px;

        &__info {
          margin-left: 20px;
        }

        p {
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.001em;
          color: #6886AF;
          margin: 0;

          a {
            color: #26A9F4;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .text {
      font-weight: 400;;
      font-size: 15px;
      line-height: 20px;
      color: #002B50;
      margin:0 0 10px;

      &--label {
        /*margin-top: 16px;*/
        margin-bottom: -5px;
        position: relative;
        color: #6886AF;
        z-index: +1;
      }
    }
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
      background: #FFFFFF;
      /*height: 64px;*/
      padding: 12px 19px 12px 17px;
      border: 1px solid #EBEBEB;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: none;
      font-weight: 400;;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #002B50;
      position: relative;

      .v-icon.v-icon {
        font-size: 0;
      }
    }
    .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
      background: transparent;
    }

    .chevron-icon {
      position: absolute;
      right: 0;
      top: 16px;
      transition-duration: .3s;
    }

    .v-select--is-menu-active {
      .chevron-icon {
        transform: rotate(180deg);
        transition-duration: .3s;
      }
    }

    .wrap-input {
      //margin-top: 14px;
    }

    .wrap-price__view {
      position: relative;
      display: flex;
      align-items: center;
      background: #f0f6fe;
      border-radius: 8px;
      min-width: 180px;
      height: 40px;
      margin-left: 10px;
      margin-bottom: 20px;

      .block-price-view {
        display: flex;
        width: 100%;
        align-items: center;


        .price-text {
          margin-left: 12px;
          display: flex;
          width: 100%;
          justify-content: center;

          &__wrap {
            display: flex;
            align-items: center;


            span {
              min-width: 80px;
              font-size: 14px;
            }
          }

          .pic {
            margin-top: 1px;
            height: 14px;
            margin-right: 6px;
          }
        }
        &.active + .price-btn {
          left: calc(100% - 40px);
        }
      }
      .price-btn {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background-color: #d8e5f7;
        border-radius: 8px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        transition: 0.7s;
        pointer-events: none;
      }
    }

    .currency {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 25%;
      font-weight: 400;;
      font-size: 14px;
      line-height: 18px;
      color: #6886AF;
      cursor: default;
    }

    .text-charged {
      margin: 21px 0 0;
      font-weight: 400;;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #6886AF;
      display: flex;
      justify-content: center;

      img {
        margin: 0 3px 0 7px;
        height: 14px;
      }

      span {
        color: #00C443;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
    }
    .text-commission {
      font-weight: 400;;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #6886AF;
      margin: 0 0 21px;

      /*span {*/
      /*  font-weight: bold;*/
      /*}*/
    }
    .img {
      height: 40px;
      min-width: 40px;
      margin-right: 16px;
      border-radius: 50%;
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .img.silver {
      background-image: url(/dist/images/frontend/light-coin.svg);
      background-size: 40px;
    }

    .img.mind {
      background-image: url(/dist/images/frontend/mind_bep.svg);
      background-size: 32px;
    }

    .img.btc{
      background-color: #FF7A00;
      background-image: url(/dist/images/frontend/wallets/white-btc.svg);
    }
    .img.bsc-usd_bep20 {
      background-color: #f3ba2f;
      background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
    }
    .img.bnb{
      background-color: #f3ba2f;
      background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
    }

    .img.ada{
      background-color: #2A76D0;
      background-image: url(/dist/images/frontend/wallets/white-ada.svg);
    }
    .img.dot{
      background-color: #666;
      background-image: url(/dist/images/frontend/wallets/white-dot.svg);
    }
    //
    //.img.usdt {
    //  background-color: #4FD984;
    //  background-image: url(../../../public/images/wallets/white-usdt.svg);
    //}
    .img.ltc {
      background-color: #BBBBBB;
      background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
    }
    .img.doge {
      background-color: #D4BE00;
      background-image: url(/dist/images/frontend/wallets/white-doge.svg);
    }
    .img.usdt_trc20 {
      background-color: #C3332D;
      background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
    }
    .img.eth {
      background-color: #4A84FF;
      background-image: url(/dist/images/frontend/wallets/white-eth.svg);
    }
    .img.xrp {
      background-position: 10px center;
      background-color: #2DA9E8;
      background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
    }
    .img.bch {
      background-color: #8DC351;
      background-image: url(/dist/images/frontend/wallets/white-bch.svg);
    }
    .img.trx {
      background-color: #C3332D;
      background-image: url(/dist/images/frontend/wallets/white-trx.svg);
    }
    .img.link {
      background-color: #2A5ADA;
      background-image: url(/dist/images/frontend/wallets/white-link.svg);
    }
    .img.payeer {
      background-color: #2A93FF;
      background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
    }
    .img.pm {
      background-color: #FF0000;
      background-image: url(/dist/images/frontend/wallets/white-pm.svg);
    }
    .img.etc {
      background-color: #1B8600;
      background-image: url(/dist/images/frontend/wallets/white-etc.svg);
    }
    .img.adv {
      background-color: #0f2d38;
      background-image: url(/dist/images/frontend/wallets/advcash.svg);
      background-size: 20px;
      background-position: 11px center;
    }
    .img.dai{
      background-image: url(/dist/images/frontend/wallets/white-dai.svg);
      background-color: #F9AC13;
    }
    .img.dash{
      background-image: url(/dist/images/frontend/wallets/dash.png);
      /*background-color: #F9AC13;*/
      background-size: 100%;
    }

    .img.mc_uah,
    .img.mc_usd,
    .img.mc_rub {
      background-image: url(/dist/images/frontend/wallets/mc_visa.svg);
      background-color: #333333;
      background-size: 30px;
    }

    .img.visa_uah,
    .img.visa_usd,
    .img.visa_rub {
      background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
      background-color: #00579F;
      background-size: 26px;
    }
  }
  @media screen and (max-width: 600px){
    .wrap-withdraws {
      &__body {
        padding: 15px;
        .wrapper-payment-select {
          flex-direction: column;
          margin-bottom: 0px;

          svg {
            display: none;
          }

          &__item {
            width: 100%;
            margin-bottom: 10px;

            span {
              margin-top: 0!important;
            }
          }
        }

        .wrapper-inputs {
          flex-direction: column;
          margin-bottom: 10px;

          svg {
            display: none;
          }

          &__item {
            width: 100%;
            margin-bottom: 10px;
          }
        }
        .wrapper-inputs-tag {
          display: flex;
          flex-direction: column;
          &__item {
            &:first-child {
              width: 100%;
              margin-right: 0;
              margin-bottom: 15px;
            }

            &:last-child {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px){
    .wrap-withdraws {
      .img {
        height: 30px;
        min-width: 30px;
        margin-right: 8px;
        border-radius: 50%;
        background-size: 14px;
        background-repeat: no-repeat;
        background-position: center;
      }
      .img.xrp {
        background-position: 7px center;
      }

      .img.adv {
        background-size: 16px;
        background-position: 8px center;
      }
      .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
        padding: 8px 12px 8px 10px;

        .v-icon.v-icon {
          font-size: 0;
        }

        .payment-item__icon {
          height: 30px;
          width: 30px;
          margin-right: 8px!important;
        }
      }
      &__body {
        .block-bottom {
          flex-direction: column;
          margin-top: 20px;

          &__info {
            margin: 10px 0 0;

            p {
              text-align: center;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        .wrapper-payment-select {
          span {
            font-size: 14px;
            line-height: 18px;
          }

          .block-gold {
            padding: 8px 12px 8px 10px;

            img {
              height: 30px;
              margin-right: 8px;
            }

            .block-gold-info {
              &__title {
                font-size: 13px;
                line-height: 14px;
              }
              &__subtitle {
                font-size: 13px;
                line-height: 14px;
              }
            }
          }
        }
      }
      .text {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
</style>
