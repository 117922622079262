<template>
  <div>
    <div>
      <div>
<!--        <router-link :to="{name: 'nft_page',params: {lang: $store.getters.getLang, id: item.record_id}}" class="adv">-->
<!--          -->
<!--        </router-link>-->
          <div class="img">
            <router-link v-if="auth" :to="{name: 'nft_page',params: {lang: $store.getters.getLang, id: item.record_id}}" class="adv">
              <img :src="item.data.image"/>
            </router-link>
              <a v-else v-on:click="$store.commit('updateModalAuth', 'login')" class="adv">
                  <img :src="item.data.image"/>
              </a>
          </div>
          <div class="timer" v-if="item.status != 5 && item.type=='auction'">
            <p>{{finalTime}}</p>
          </div>
          <div v-if="auth" v-on:click="changedFavorite()" class="favorites">
              <svg  width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="favorite">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7195 2.4805C17.772 1.53258 16.4867 1 15.1464 1C13.8062 1 12.5209 1.53258 11.5734 2.4805L10.5998 3.45413L9.62612 2.4805C7.65278 0.507155 4.45335 0.507155 2.48001 2.4805C0.506664 4.45384 0.506664 7.65327 2.48001 9.62661L3.45364 10.6002L10.5998 17.7464L17.7459 10.6002L18.7195 9.62661C19.6674 8.67913 20.2 7.39381 20.2 6.05356C20.2 4.7133 19.6674 3.42798 18.7195 2.4805Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" fill="#26A9F4"/>
              </svg>
            <svg  width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7195 2.4805C17.772 1.53258 16.4867 1 15.1464 1C13.8062 1 12.5209 1.53258 11.5734 2.4805L10.5998 3.45413L9.62612 2.4805C7.65278 0.507155 4.45335 0.507155 2.48001 2.4805C0.506664 4.45384 0.506664 7.65327 2.48001 9.62661L3.45364 10.6002L10.5998 17.7464L17.7459 10.6002L18.7195 9.62661C19.6674 8.67913 20.2 7.39381 20.2 6.05356C20.2 4.7133 19.6674 3.42798 18.7195 2.4805Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div  v-else v-on:click="$store.commit('updateModalAuth', 'login')"  class="favorites">
              <svg  width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="favorite">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7195 2.4805C17.772 1.53258 16.4867 1 15.1464 1C13.8062 1 12.5209 1.53258 11.5734 2.4805L10.5998 3.45413L9.62612 2.4805C7.65278 0.507155 4.45335 0.507155 2.48001 2.4805C0.506664 4.45384 0.506664 7.65327 2.48001 9.62661L3.45364 10.6002L10.5998 17.7464L17.7459 10.6002L18.7195 9.62661C19.6674 8.67913 20.2 7.39381 20.2 6.05356C20.2 4.7133 19.6674 3.42798 18.7195 2.4805Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" fill="#26A9F4"/>
              </svg>
              <svg  width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7195 2.4805C17.772 1.53258 16.4867 1 15.1464 1C13.8062 1 12.5209 1.53258 11.5734 2.4805L10.5998 3.45413L9.62612 2.4805C7.65278 0.507155 4.45335 0.507155 2.48001 2.4805C0.506664 4.45384 0.506664 7.65327 2.48001 9.62661L3.45364 10.6002L10.5998 17.7464L17.7459 10.6002L18.7195 9.62661C19.6674 8.67913 20.2 7.39381 20.2 6.05356C20.2 4.7133 19.6674 3.42798 18.7195 2.4805Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <div class="name">
            <router-link v-if="auth" :to="{name: 'nft_page',params: {lang: $store.getters.getLang, id: item.record_id}}" class="adv">
              <p>{{item.data.name}}</p>
            </router-link>
              <a v-else v-on:click="$store.commit('updateModalAuth', 'login')" class="adv">
                  <p>{{item.data.name}}</p>
              </a>
          </div>
          <div class="price">
            <p class="mind" v-if="item.status== 3">{{item.price}} MIND</p>
            <p v-else>Не в продаже</p>

            <router-link tag="p" class="name-game" :to="{name: 'game', 'params': {id: item.game.url, lang: $store.getters.getLang}}">{{item.game.name}}</router-link>
          </div>
          <div class="price-bid">
            <p class="auction" v-if="item.type=='auction'">
              <img src="/dist/images/frontend/Line-bid.svg" alt="">
              Bid <span>{{item.last_bid}} MIND</span>
<!--              <span v-if="item.last_bid > 0">New bid??</span>-->
            </p>
<!--            <p v-if="item.status== 3">{{item.price}} MIND</p>-->
            <p v-else>Не в продаже</p>
            <p class="last-bid" v-if="item.last_bid > 0">
              New bid
              <span>🔥</span>
            </p>
          </div>

      </div>
<!--      <div>-->
<!--        <p v-if="[0,2].includes(item.status)">No minting</p>-->
<!--        <p v-else-if="item.status== 3">В продаже</p>-->
<!--        <p v-else-if="item.status== 4">Отменен</p>-->
<!--        <p v-else-if="item.status== 5">Продан</p>-->
<!--        <p v-else>Ожидает подтверждения</p>-->
<!--      </div>-->
<!--      <div class="block-img">-->
<!--     <div class="block-img">-->
<!--              <router-link :to="{name: 'game', params: {id: item.game.url, lang: $store.getters.getLang}}"  class="mb-0">-->
<!--                <div class="wrap-img">-->
<!--                  <img v-if="typeof item.game.logo == 'object'" :src="item.game.logo[100]">-->
<!--                  <img v-else :src="item.game.logo">-->
<!--                </div>-->
<!--              </router-link>-->
<!--            </div>-->
<!--            <router-link :to="{name: 'nft_page',params: {lang: $store.getters.getLang, id: item.record_id}}" class="adv">{{(item.status== 3) ? 'Купить' : 'Подробнее'}}</router-link>   <router-link :to="{name: 'game', params: {id: item.game.url, lang: $store.getters.getLang}}"  class="mb-0">-->
<!--          <div class="wrap-img">-->
<!--            <img v-if="typeof item.game.logo == 'object'" :src="item.game.logo[100]">-->
<!--            <img v-else :src="item.game.logo">-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
<!--      <router-link :to="{name: 'nft_page',params: {lang: $store.getters.getLang, id: item.record_id}}" class="adv">{{(item.status== 3) ? 'Купить' : 'Подробнее'}}</router-link>-->
    </div>
  </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "NftListItem",
        data() {
            return {
                status: 0,
                timeInterval: null,
                favorite: this.item.favorite,
                finalTime: null
            };
        },

        props: {
            item: {
                type: Object
            }
        },
        computed: {
            timeLot() {
               let end_time= moment.utc(this.item.end_time).format('x');
               let time= moment.utc().format('x');

               return end_time- time;
            },
            auth() {
                return this.$store.state.auth.user
            },
        },
        asyncComputed: {},
        created: function () {
            if(this.item.status != 5 && this.item.type=='auction') {
                this.updateTime();

                this.timeInterval= setInterval(()=> {
                    this.updateTime();
                }, 60000);
            }
        },
        methods: {
            changedFavorite() {

                this.$http.get("ajax/nft/" + this.item.record_id + "/favorite")
                    .then((response) => {
                        return response.json();
                    })
                    // .then(() => {
                    //   this.game.favorite = !this.game.favorite;
                    // })
                    .then((response)=>{
                        if(this.$checkAuth(response)) {
                            this.favorite = response.data.favorite;
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            },
            updateTime() {

                let x = moment.utc(this.item.start_time).format('x') - moment.utc().format('x');
                if(x < 0) {
                    x= moment.utc(this.item.end_time).format('x') - moment.utc().format('x')
                    if(x < 0) {
                        clearInterval(this.timeInterval);

                        this.finalTime = 'End time';
                        return false;
                    }
                }

                let seconds = Math.floor((x / 1000) % 60);
                let minutes = Math.floor((x / 1000 / 60) % 60);
                let hours = Math.floor((x / (1000 * 60 * 60)) % 24);
                let days = Math.floor(x / (1000 * 60 * 60 * 24));

                let t = {
                    'total': x,
                    'days': days,
                    'hours': hours,
                    'minutes': minutes,
                    'seconds': seconds
                };

                if (t.days < 10) {
                    t.days = '0' + t.days;
                }
                if (t.hours < 10) {
                    t.hours = '0' + t.hours;
                }
                if (t.minutes < 10) {
                    t.minutes = '0' + t.minutes;
                }
                if (t.seconds < 10) {
                    t.seconds = '0' + t.seconds;
                }

                let formatTime= '';

                if(t.days) {
                    formatTime += t.days+'d ';
                }
                if(t.hours) {
                    formatTime += t.hours+'h ';
                }
                if(t.minutes) {
                    formatTime += t.minutes+'m ';
                }
                this.finalTime= formatTime;
            }
        },
        components: {
            // ScrollingContent
        },
    };
</script>