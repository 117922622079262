<template>
    <v-card v-ripple="false" :class="(show) ? 'active' : ''">
        <div class="item-orders" @click="show = !show" >
            <div class="block-info">
                <p class="block-info__title">№ 707 732 511 от 24.06.21</p>

                <div class="block-info__status">
                    <span v-if="status== 1" class="block-success">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3" cy="3" r="3" fill="#01DF72"/>
                        </svg>
                        {{__('Доставлено')}}
                    </span>

                    <span v-else-if="status== 2" class="block-wait">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="3" cy="3" r="3" fill="#F6A401"/>
                        </svg>
                        {{__('Собирается')}}
                    </span>

                    <span v-else-if="status== 3" class="block-onway">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="3" cy="3" r="3" fill="#00AEFF"/>
                        </svg>
                        {{__('В пути')}}
                    </span>

                    <span v-else-if="status== 4" class="block-cancel">
                        <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="3" cy="3" r="3" fill="#FF4B55"/>
                        </svg>
                        {{__('Canceled')}}
                    </span>
                </div>
            </div>

            <div class="block-count">
                <span class="block-count__title">{{__('Товаров')}}</span>
                <p class="block-count__subtitle">4</p>
            </div>

            <div class="block-price">
                <span class="block-price__title">{{__('Сумма')}}</span>
                <p class="block-price__subtitle">$17,349.00</p>
            </div>
            
            <div class="block-products">
                <div class="block-products__item">
                    <img src="/dist/images/frontend/store/flesh.png" alt="">
                </div>

                <div class="block-products__item">
                    <img src="/dist/images/frontend/store/flesh.png" alt="">
                </div>
            </div>

            <div class="block-chevron">
                <v-btn
                        class="btn-link"
                        icon
                        v-ripple="false"
                >
                    <svg v-if="!show" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8334 1.08331L7.00008 6.91665L1.16675 1.08331" stroke="#96AFD1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <svg v-else width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.16658 6.91669L6.99992 1.08335L12.8333 6.91669" stroke="#26A9F4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                </v-btn>
            </div>
        </div>

        <v-expand-transition>
            <div v-show="show">
                <v-card-text>
                    <div class="block-detail">
                        <div class="block-detail__left">
                            <p class="left-title">{{__('Информация о заказе')}}</p>
                            <div class="left-body">
                                 <span>ТТН РОЗ009944830</span>
                                 <span>Курьер Meest</span>
                                 <span>Украина, г. Киев, ул. Б. Хмельницкого, 567, корпус 2, офис 45</span>
                                 <span>Александров Александр</span>
                                 <span> +38 (888) 567-6970</span>
                                 <span>email@email.com</span>
                            </div>
                        </div>

                        <div class="block-detail__right">
                            <p class="right-title">{{__('Заказанные товары')}}</p>
                            <div class="list-products">
                                <div class="item-product">
                                    <div class="block-product">
                                        <div class="block-img">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>
                                        <div class="block-info">
                                            <p>Флешка MINDPLAYS Kingston</p>
                                            <span>DataTraveler Exodia 32GB</span>
                                        </div>
                                    </div>

                                    <div class="block-price">
                                        <span class="block-price__title">{{__('Цена')}}</span>
                                        <p class="block-price__subtitle">$4,349.00</p>
                                    </div>

                                    <div class="block-count">
                                        <span class="block-count__title">{{__('Товаров')}}</span>
                                        <p class="block-count__subtitle">4</p>
                                    </div>

                                    <div class="block-price">
                                        <span class="block-price__title">{{__('Сумма')}}</span>
                                        <p class="block-price__subtitle">$17,349.00</p>
                                    </div>
                                </div>

                                <div class="item-product">
                                    <div class="block-product">
                                        <div class="block-img">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>
                                        <div class="block-info">
                                            <p>Флешка MINDPLAYS Kingston</p>
                                            <span>DataTraveler Exodia 32GB</span>
                                        </div>
                                    </div>

                                    <div class="block-price">
                                        <span class="block-price__title">{{__('Цена')}}</span>
                                        <p class="block-price__subtitle">$4,349.00</p>
                                    </div>

                                    <div class="block-count">
                                        <span class="block-count__title">{{__('Товаров')}}</span>
                                        <p class="block-count__subtitle">4</p>
                                    </div>

                                    <div class="block-price">
                                        <span class="block-price__title">{{__('Сумма')}}</span>
                                        <p class="block-price__subtitle">$17,349.00</p>
                                    </div>
                                </div>

                                <div class="item-product">
                                    <div class="block-product">
                                        <div class="block-img">
                                            <img src="/dist/images/frontend/store/flesh.png" alt="">
                                        </div>
                                        <div class="block-info">
                                            <p>Флешка MINDPLAYS Kingston</p>
                                            <span>DataTraveler Exodia 32GB</span>
                                        </div>
                                    </div>

                                    <div class="block-price">
                                        <span class="block-price__title">{{__('Цена')}}</span>
                                        <p class="block-price__subtitle">$4,349.00</p>
                                    </div>

                                    <div class="block-count">
                                        <span class="block-count__title">{{__('Товаров')}}</span>
                                        <p class="block-count__subtitle">4</p>
                                    </div>

                                    <div class="block-price">
                                        <span class="block-price__title">{{__('Сумма')}}</span>
                                        <p class="block-price__subtitle">$17,349.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                status: 1
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
        border: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin: 0 30px;
        padding: 0;
        background: transparent;
        border-radius: 0;
    }

    .v-sheet.v-card:not(.v-sheet--outlined).active {
        border-bottom: none;
        background-color: #181818;
        margin: 0;
        padding: 0 30px;

        .item-orders {
            background-color: #181818;
        }
    }

    .v-card__text {
        padding: 8px 0 0;
    }

    .item-orders {
        background: #171827;
        /*border-bottom: 1px solid rgba(255, 255, 255, 0.1);*/
        padding: 12px 0;
        display: flex;

        .block-info {
            width: 20%;

            &__title {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #FFFFFF;
                margin-bottom: 5px;
            }

            &__status {
                .block-success {
                    display: flex;
                    align-items: center;
                    padding: 0 10px 0 8px;
                    background: rgba(1, 223, 114, 0.18);
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: #01DF72;
                    height: 20px;
                    width: fit-content;

                    svg {
                        margin-right: 5px;
                    }
                }

                .block-wait {
                    display: flex;
                    align-items: center;
                    padding: 0 10px 0 8px;
                    background: rgba(246, 164, 1, 0.18);
                    border-radius: 10px;
                    color: #F6A401;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    height: 20px;
                    margin-right: 10px;
                    width: fit-content;

                    svg {
                        margin-right: 5px;
                    }
                }

                .block-onway {
                    display: flex;
                    align-items: center;
                    padding: 0 10px 0 8px;
                    background: rgba(0, 174, 255, 0.18);
                    border-radius: 10px;
                    color: #00AEFF;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    height: 20px;
                    margin-right: 10px;
                    width: fit-content;

                    svg {
                        margin-right: 5px;
                    }
                }

                .block-cancel {
                    display: flex;
                    align-items: center;
                    padding: 0 10px 0 8px;
                    background: rgba(255, 75, 85, 0.18);
                    border-radius: 10px;
                    color: #FF4B55;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    width: fit-content;
                    height: 20px;

                    svg {
                        margin-right: 5px;
                    }
                }
            }
        }

        .block-count,
        .block-price {
            width: 10%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__title {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: #00AEFF;
                margin-bottom: 2px;
                display: block;
            }

            &__subtitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #FFFFFF;
                margin: 0;
            }
        }

        .block-products {
            width: 55%;
            display: flex;
            justify-content: flex-end;

            &__item {
                margin-left: 10px;
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(255, 255, 255, 0.05);
                border-radius: 8px;

                img {
                    height: 34px;
                }
            }
        }

        .block-chevron {
            width: 5%;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .v-btn.btn-link {
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    .block-detail {
        display: flex;
        justify-content: space-between;

        &__left {
            width: 35%;

            .left-title {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #96AFD1;
                margin-bottom: 12px;
            }

            .left-body {
                display: flex;
                flex-direction: column;

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                    display: block;
                    margin-bottom: 12px;
                }
            }
        }

        &__right {
            width: 65%;
            margin-left: 30px;

            .right-title {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #96AFD1;
                margin-bottom: 12px;
            }

            .list-products {

                .item-product {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 8px 0;

                    .block-product {
                        width: 55%;
                        display: flex;
                        align-items: center;

                        .block-img {
                            background: rgba(255, 255, 255, 0.05);
                            border: 1px solid rgba(255, 255, 255, 0.05);
                            border-radius: 8px;
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 14px;
                        }

                        .block-info {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19px;
                            color: #FFFFFF;
                            p {
                                margin: 0;
                            }
                        }
                    }

                    .block-count,
                    .block-price {
                        width: 15%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &__title {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #00AEFF;
                            margin-bottom: 2px;
                            display: block;
                        }

                        &__subtitle {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            text-align: center;
                            color: #FFFFFF;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
</style>