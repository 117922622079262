<template>
    <div class="wrap-item-awards" :class="'wrap-item-awards--'+index">
        <div class="wrap-item-awards__head">
            {{__('you got achieve!')}}
        </div>

        <div class="wrap-item-awards__icon" :style="{'backgroundImage': 'url('+item.achievement.icon + ')'}"></div>

                <div class="wrap-item-awards__prize">
                    <div class="warning--text">
                        <img src="/dist/images/frontend/fire.svg" alt />
                        <span>+{{item.rating}}</span>
                    </div>
                    <div v-if="item.real > 0" class="warning--text">
                        <img src="/dist/images/frontend/money.svg" alt />
                        <span>+{{item.real}}</span>
                    </div>

                    <div v-if="item.lite > 0" class="lite-coin">
                        <img src="/dist/images/frontend/light-coin.svg" alt />
                        <span>+{{item.lite}}</span>
                    </div>
                </div>

        <span class="wrap-item-awards__name">{{__(item.achievement.name)}}</span>

        <p class="wrap-item-awards__desc">{{__(item.achievement.description)}}</p>

        <BaseButton
            width="100"
            v-on:click="$emit('removeNotification', index)"
        >
            <span>{{__('Get reward')}}</span>
        </BaseButton>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            item: {
                type: Object,
            },
            index: {
                type: Number
            }
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .wrap-item-awards {
            background: #171827;

            &__head {
                background-color: #181818;
            }

            &__icon {
                background-color: #181818;
            }

            &__desc {
                color: #fff;
            }
        }
    }

    .wrap-item-awards {
        background: #fff;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border: 1px solid #2B2B2B;
        border-radius: 5px;
        padding: 0 40px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        position: absolute;
        bottom: 22%;
        left: 50%;
        transform: translate(-50%, 0) scale(1);
        z-index: 10;
        transition-duration: .3s;

        &--0 {
            transform: translate(-50%, 0) scale(1);
            z-index: 10;
            transition-duration: .3s;
        }


        &--1 {
            z-index: 9;
            transform: translate(-50%, -30px) scale(0.95);
            transition-duration: 2s;

            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }
        &--2 {
            z-index: 8;
            transform: translate(-50% ,-60px) scale(0.90);
            transition-duration: 2s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }
        &--3 {
            z-index: 7;
            transform: translate(-50%, -90px) scale(0.85);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }
        &--4 {
            z-index: 6;
            transform: translate(-50%, -120px) scale(0.80);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }

        &--5 {
            z-index: 5;
            transform: translate(-50%, -150px) scale(0.75);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }

        &--6 {
            z-index: 4;
            transform: translate(-50%, -180px) scale(0.70);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }
        &--7 {
            z-index: 3;
            transform: translate(-50%, -210px) scale(0.65);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }

        &--8 {
            z-index: 2;
            transform: translate(-50%, -240px) scale(0.60);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }

        &--9 {
            z-index: 1;
            transform: translate(-50%, -270px) scale(0.55);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }

        &--10 {
            z-index: 0;
            transform: translate(-50%, -300px) scale(0.50);
            transition-duration: .5s;
            .wrap-item-awards__head {
                opacity: 0.3;
            }
        }

        &__head {
            padding: 5px 10px 7px;
            background: #F3F7FE;
            border-radius: 0px 0px 8px 8px;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            text-transform: uppercase;
            color: #01DF72;
            margin-bottom: 30px;
            opacity: 1;
        }

        &__icon {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: #F3F6FE;
            margin-bottom: 16px;
            background-position: center;
            background-size: cover;
            box-sizing: border-box;
            flex-shrink: 0;
        }

        &__name {
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #00AEFF;
            margin-bottom: 20px;
        }

        &__desc {
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.001em;
            color: #002b50;
            margin-bottom: 30px;
            height: 60px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        &__prize {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            background: rgba(38, 169, 244, 0.15);
            border-radius: 20px;
            padding: 1px 20px 3px;

            img {
                height: 12px;
                margin: 0 3px 0 0;
            }

            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.1px;
                margin-right: 10px;
            }

            .lite-coin {
                color: #6886af;

                span {
                    margin-right: 0;
                }
            }
        }
    }

    @media screen and (max-height: 750px) {
        .wrap-item-awards {
            bottom: 15%;
        }
    }

    @media screen and (max-width: 750px) {
        .wrap-item-awards {
            bottom: 18%;
            width: 350px;
            padding: 0 20px 30px;

            &__head {
                margin-bottom: 25px;
            }

            &__icon {
                width: 120px;
                height: 120px;
            }

            &__name {
                margin-bottom: 16px;
            }

            &__desc {
                font-size: 12px;
                line-height: 17px;
                padding: 0 10px;
                height: 53px;
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 450px) {
        .wrap-item-awards {
            bottom: 18%;
            width: 300px;
            padding: 0 20px 20px;

            &__head {
                margin-bottom: 20px;
            }

            &__icon {
                width: 100px;
                height: 100px;
            }

            &__name {
                margin-bottom: 14px;
            }

            &__desc {
                padding: 0;
            }
        }
    }
</style>