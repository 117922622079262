<template>
    <div class="wrapper-tournament">
        <div class="tournament-head">
            <div class="block-head">
                <div class="block-left">
                    <h5>{{__('Tournament')}}</h5>
                    <p>01.2021 - 02.2021</p>
                    <span>{{item.desc}}</span>
                </div>

                <div class="block-img">
                    <img :src="item.logo" alt="">
                </div>
            </div>

            <div class="block-bottom">
                <div class="block-money">
                    <div class="wrap-icon">
                        <img v-if="(item.wallet== 1)" class="pic" src="/dist/images/frontend/money.svg" alt/>
                        <img v-else class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                    </div>

                    <span v-if="(item.wallet== 1)" class="warning--text">
                        <p>{{primNum(item.price, 2)}}</p>

                    </span>
                    <span v-else style="color: #6886AF">
                      <p>{{primNum(item.price, 2)}}</p>
                    </span>
                </div>

                <BaseButton>
                    <span>
                        {{__('Participate')}}
                    </span>
                </BaseButton>
            </div>
        </div>

        <div class="tournament-body">
            <div class="tournament-item" v-for="(i, key) in item.list" :key="key">
                <div class="wrap-img">
                    <img v-if="i.img" :src="i.img">
                    <span v-else class="photo" :style="{'background': i.bg_color}">{{i.name.substr(0,1)}}</span>
                </div>
                <div class="block-info">
                    <div>
                        <router-link :to="{name: 'profile', params: {id: i.public, lang: $store.getters.getLang}}" class="mb-0">{{i.name + ' ' + ((i.last_name) ? i.last_name : '')}}</router-link>
                        <img width="16" class="ml-2" :src="i.location_img">
                    </div>
                    <span v-if="i.rating_points" class="warning--text block-rating">
                        <img class="pic" src="/dist/images/frontend/fire.svg" alt />
                        +{{i.rating_points}}
                     </span>
                </div>
            </div>
        </div>

        <div class="tournament-bottom">
            <span>{{__('Participants')}} {{item.participants}}</span>
            <a href="#">{{__('View more')}}</a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            item: {
                type: Object,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dark {
        .wrapper-tournament {
            background-color: #171827;

            .tournament-head {
                border-bottom: 2px solid #2B2B2B;
                .block-head {
                    .block-left {
                        h5 {
                            color: #fff;
                        }

                        p {
                            color: #fff;
                        }

                        span {
                            color: #96AFD1;
                        }
                    }
                }
            }

            .tournament-body {
                .tournament-item {
                    border-bottom: 1px solid #2b2b2b;

                    .block-info {
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .wrapper-tournament {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
        margin-bottom: 10px;
        .tournament-head {
            padding: 20px;
            border-bottom: 2px solid #ECF4FF;

            .block-head {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;

                .block-left {
                    h5 {
                        font-size: 18px;
                        line-height: 24px;
                        color: #002B50;
                        display: block;
                    }

                    p {
                        font-size: 18px;
                        line-height: 24px;
                        color: #002B50;
                        margin-bottom: 6px;
                    }

                    span {
                        display: block;
                        font-size: 13px;
                        line-height: 16px;
                        color: #607EA7;
                    }
                }

                .block-img {
                    img {
                        width: 80px;
                        height: 80px;
                        filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.05));
                        border-radius: 4px;
                    }
                }
            }

            .block-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .block-money {
                    display: flex;
                    align-items: center;

                    img {
                        height: 24px;
                        margin-right: 4px;
                    }

                    p {
                        font-size: 24px;
                        line-height: 32px;
                        margin: 0;
                    }
                }
            }
        }

        .tournament-body {
            .tournament-item {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                border-bottom: 1px solid #ECF4FF;

                .wrap-img {
                    position: relative;
                    margin-right: 8px;

                    img {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        /*  width: 40px;*/
                        /*  height: 40px;*/
                        /*  border-radius: 8px;*/
                    }

                    .photo {
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        /*  width: 40px;*/
                        /*  height: 40px;*/
                        /*  border-radius: 50%;*/
                        display: flex;
                        color: #FFFFFF;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        font-size: 12px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }

                .block-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    a {
                        font-size: 14px;
                        line-height: 19px;
                        color: #002B50;
                    }

                    .block-rating {
                        font-size: 13px;
                        line-height: 16px;
                        display: flex;
                        align-items: center;

                        img {
                            width: 10px;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }

        .tournament-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px 12px;

            span {
                font-size: 12px;
                line-height: 16px;
                color: #96AFD1;
            }

            a {
                font-size: 12px;
                line-height: 16px;
                color: #26A9F4;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
</style>