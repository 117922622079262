<template>
  <div>
    <div class="list">
      <div class="img">
        <router-link v-if="[3,5].includes(item.status)" :to="{name: 'nft_page', params: {id: item.record_id, lang: $store.getters.getLang}}">
          <img :src="item.img" width="50"/>
        </router-link>
        <img v-else :src="item.img" width="50"/>
      </div>
      <div class="text">
        <p class="name">{{item.data.name}}</p>
        <p class="description">{{item.data.description}}</p>
      </div>
      <div class="price">
        <p class="start">Start price</p>
        <p class="mind">100.00 mind</p>
      </div>
      <div class="verification">
        <p class="" v-if="[0,2].includes(item.status)">No minting</p>
        <p class="sale" v-else-if="item.status== 3">В продаже</p>
        <p class="cancel" v-else-if="item.status== 4">Отменен</p>
        <p class="buy" v-else-if="item.status== 5">Куплено</p>
        <p class="wait" v-else>Ожидает подтверждения</p>
      </div>
      <div class="btn-list">
        <v-btn v-on:click="modal_cancel= item">JSON</v-btn>
        <p class="redBtn baseButton"><span>карзина</span></p>
      </div>
    </div>
    <ModalDefault v-if="modal_cancel" v-bind:modal_cancel.sync="modal_cancel" :width="500">
      <div class="modal-invite modal-invite-nft">
        <div class="modal-invite__head">
          NFT Info
        </div>
        <div class="modal-invite__body">
              <pre>nft_list
                {{modal_cancel.data}}
              </pre>
        </div>
      </div>
    </ModalDefault>
  </div>
</template>

<script>
    import ModalDefault from "../../modules/ModalDefault";

    export default {
        name: "GameNftListItem",
        data() {
            return {
                modal_cancel: false,
            };
        },

        props: {
            item: {
                type: Object
            },
            next_page_url: {
                type: String
            }
        },
        computed: {},
        asyncComputed: {},
        created: function () {

        },
        methods: {

        },
        components: {
            ModalDefault

            // ScrollingContent
        },
    };
</script>