<template>
    <div :class="(!auth) ? 'dark' : ''">
        <div v-if="privacy">
            <div class="block-privacy">
                <h1>{{privacy.title}}</h1>
                <div v-html="privacy.text"></div>
            </div>
            <div class="block-footer">
                <Footer></Footer>
            </div>
        </div>
        <div class="block-privacy" v-else>{{__('loading...')}}</div>
    </div>

</template>

<script>
    import Footer from "../modules/Footer";
    export default {
        name: 'Privacy',
        data: function () {
            return {
                privacy: null,
            }
        },

        computed: {
            languages() {
                return this.$store.state.language.lang;
            },
            auth() {
                return this.$store.state.auth.user
            }
        },

        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.createdComponent(to.name);
            return next();
        },

        created() {
            this.createdComponent(this.$route.name);
        },

        methods: {
            createdComponent(page) {
                this.$http.get('ajax/pages/' + page + '/' + this.languages)
                    .then(response => {
                        return response.json()
                    })
                    .then(response => {
                        if(this.$checkAuth(response)) {
                            this.privacy = response.data;
                        }
                    }).catch(err => {
                    this.httpHandler(err);
                });
            }
        },

        components: {
            Footer
        }
    }
</script>

<style lang="scss" scoped>
    .block-privacy {
        color: #fff;
        padding: 40px 20px 0;
        max-width: 1185px;
        margin: 0 auto 40px;
        h1 {
            margin-bottom: 20px;
            text-align: center;
            font-size: 36px;
            line-height: 50px;
            color: #fff;

        }

        h4 {
            margin-bottom: 20px;
            font-size: 20px;
            line-height: 18px;

            color: #fff;
        }

        p {
            margin-bottom: 20px;
            font-size: 15px;
            line-height: 18px;
            color: #fff;
        }
    }
</style>