<template>
  <div class="wrap-partners-item2lvl">
      <div class="partners-item2lvl">
        <div class="border-item2lvl"></div>
        <div class="partners-person-wrap2lvl">
          <v-list color="transparent" class="pa-0 partners-person">
            <v-list-item class="pa-0">
              <div class="block-img2lvl" :class="(item.user.pro) ? 'user-pro-img' : ''">
                <div class="arrow"></div>
                <router-link :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}">
                  <div class="wrap-img">
                    <img v-if="item.user.photo && typeof item.user.photo == 'object'" width="40" :src="item.user.photo[40]">
                    <img v-else-if="item.user.photo" width="40" :src="item.user.photo">
                    <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
                  </div>
                  <div v-if="now_date < date" class="online"></div>
                </router-link>
              </div>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :class="(item.user.pro) ? 'user-pro' : ''" :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}">
                    {{item.user.name +' '+((item.user.last_name) ? item.user.last_name : '')}}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <img class="ml-0 mr-1" height="12px" src="/dist/images/frontend/fire.svg" alt />
                  <span style="color: #F6A401;">{{item.user.rating_points}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div class="data">{{item.created_at}}</div>
        <div class="success--text block-profit">
          <div class="block-profit-wrap">
            <div class="block-profit-wrap__real">
              <img src="/dist/images/frontend/money.svg" alt />
              <span>{{primNum(item.amount_real, 4)}}</span>
            </div>

            <div class="block-profit-wrap__lite">
              <img src="/dist/images/frontend/light-coin.svg" alt />
              <span>{{primNum(item.amount_lite, 4)}}</span>
            </div>
          </div>
        </div>
        <div class="country-wrap">
          <img v-if="item.user.location_img" width="14" class="flag" :src="item.user.location_img">
          <span v-if="item.user.location">{{$getLocation(item.user.location)}}</span>
        </div>
      </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: 'PartnersItem2lvl',
  data () {
    return {
      now_date: moment.utc(new Date()).format('X'),
      date: (this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
    }
  },
  props: {
    item: {
      type: Object
    },
  },
  computed: {

  },
  methods: {

  },
  components: {

  },

}
</script>

<style lang="scss">

.wrap-partners-item2lvl {

  .v-sheet.v-card {
    border-radius: 8px;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
  }
}

.partners-item2lvl {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ECF4FF;
  margin-left: 40px;

  .border-item2lvl {
    position: absolute;
    left: -22px;
    bottom: 35px;
    width: 2px;
    height: 63px;
    border-left: 1px dashed #DAE6F1;
  }
}


.partners-person-wrap2lvl {
  width: 35%;
  padding-left: 45px;
}

.partners-person {

  .block-img2lvl {
    position: relative;
    width: 40px;
    height: 40px;
    margin: 12px 12px 12px 0;

    .arrow {
      position: absolute;
      top: 13px;
      left: -9px;
      width: 9px;
      height: 12px;
      background-image: url("/dist/images/frontend/affiliate-arrow.svg");
      background-position: center;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 20px;
      left: -26px;
      display: block;
      width: 25px;
      height: 66px;
      border-bottom: 1px dashed #DAE6F1;
      border-left: 1px dashed #DAE6F1;
      border-radius: 0 0 0 5px;
    }

    &:after {
      content: '';
      position: absolute;
      left: -45px;
      bottom: -13px;
      display: block;
      width: 50px;
      height: 2px;
      background-color: #fff;
    }


    a {
      text-decoration: none;
    }

    .online {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 3px;
      bottom: 1px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      background-color: #00C569;
    }
  }

  .wrap-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .v-list-item {

    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-transform: capitalize;
      color: #002B50;


      a {
        color: #002B50;
        text-decoration: none;


        &:hover {
          color: #26A9F4;
          text-decoration: underline;
        }
      }
    }
  }


}
.data {
  width: 20%;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #002B50;
}

.block-profit {
  width: 20%;
  font-size: 14px;
  padding: 9px 0;
  margin: 0;

  .block-profit-wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &__real {
      min-width: 35px;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #00C443;
      }
    }

    &__lite {
      margin-left: 0;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #6886AF;
      }
    }

    img {
      height: 12px;
      margin-right: 6px;
      margin-top: -2px;
    }
  }


}

.result-wrap {
  padding-top: 14px;
}

.country-wrap {
  width: 25%;
  display: flex;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #002B50;
  }

  .flag {
    border-radius: 2px;
      margin: 1px 6px 0 0;
    height: 10px;
  }
}

@media screen and (max-width: 650px) {
    .data {
        font-size: 12px;
        line-height: 16px;
    }
    .country-wrap {
        span {
            font-size: 12px;
            line-height: 16px;
        }

        .flag {
            height: 9px;
            margin: 0 6px 0 0;
        }
    }
    .block-profit {
        .block-profit-wrap {
            &__real {
                span {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            &__lite {
                span {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            img {
                margin-right: 4px;
            }
        }
    }
    .partners-person {
        .block-img2lvl {
            width: 36px;
            height: 36px;
            margin: 12px 8px 12px 0;

            .arrow {
                position: absolute;
                top: 10spx;
                left: -9px;
                width: 9px;
                height: 12px;
                background-image: url("/dist/images/frontend/affiliate-arrow.svg");
                background-position: center;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 16px;
                left: -26px;
                display: block;
                width: 20px;
                height: 66px;
                border-bottom: 1px dashed #DAE6F1;
                border-left: 1px dashed #DAE6F1;
                border-radius: 0 0 0 5px;
            }

            &:after {

            }
        }

        .wrap-img {
            width: 36px;
            height: 36px;

            img {
                width: 36px;
                height: 36px;
            }

            .photo {
                width: 36px;
                height: 36px;
                font-size: 16px;
            }
        }

        .v-list-item {
            &__title {
                font-size: 12px;
                line-height: 16px;
            }
            &__subtitle {
                img {
                    height: 10px;
                    margin-bottom: 1px;
                }
                span {
                    font-size: 12px;
                }
            }
        }
    }

    @media screen and (max-width: 450px){
        .country-wrap {
            display: none;
        }
        .partners-person-wrap2lvl {
            width: 47%;
            padding-right: 5px;
        }
        .data {
            width: 28%;
        }
        .block-profit {
            width: 25%;
        }
    }
}

</style>