<template>
  <div>
    <div class="block-options">
<!--      <div class="block-img">-->
<!--        <UploadAvatar-->
<!--          v-bind:response.sync="response_upload"-->
<!--          :url="'/ajax/developers/'+game_id+'/update/avatar'"-->
<!--          :post_params="{type: 'attribute'}"-->
<!--          style="background-size: cover"-->
<!--        >-->
<!--          <img width="90" :src="((img) ? img : '/assets/games/options/default.svg')"/>-->
<!--        </UploadAvatar>-->
<!--      </div>-->
      <div class="wrap-option-input">
        <div class="d-flex justify-space-between">
          <div style="width: 55%; margin-right: 20px;">
            <InputLabelTextDefault
              class_input="input_attributes"
              color="main"
              v-model="name"
              v-bind:errors="formValidate.name"
              @input="formValidate.name = null"
              @change="updateValidate('name')"
              :label='__("Option name")'
              v-on:input="updateOptions(name,'name')"
            ></InputLabelTextDefault>
          </div>
          <div class="d-flex align-center" style="width: 45%;">
            <InputLabelTextDefault
              class_input="input_attributes"
              color="main"
              v-model="key"
              v-bind:errors="formValidate.key"
              @input="formValidate.key = null"
              @change="updateValidate('key')"
              :label='__("Key")'
              v-on:input="updateOptions(key,'key')"
            ></InputLabelTextDefault>
            <v-btn  v-on:click="deleteOption" :disabled="options.length <= 2" icon text>
              <img :class="(options.length <= 2) ? 'disable' : ''" height="16px" src="/dist/images/frontend/icon-del-red.svg" alt />
            </v-btn>
          </div>
        </div>
<!--        <div style="margin: 12px 0 2px;">-->
<!--          <InputLabelTextDefault-->
<!--            class_input="input_attributes"-->
<!--            color="main"-->
<!--            v-model="description"-->
<!--            v-bind:errors="formValidate.description"-->
<!--            @input="formValidate.description = null"-->
<!--            @change="updateValidate('description')"-->
<!--            label="Description of the option"-->
<!--            v-on:input="updateOptions(description,'description')"-->
<!--          ></InputLabelTextDefault>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
    import InputLabelTextDefault from '../../modules/InputLabelTextDefault';
    // import UploadAvatar from '../../modules/UploadAvatar';
    import {required, minLength, maxLength} from "vuelidate/lib/validators";

    export default {
        name: 'GameAttributesOptions',
        data: function() {
            return {
                response_upload: null,
                name: this.option.name,
                key: this.option.key,
                // img: this.option.img,
                // description: this.option.description,
                formValidate: {
                    // img: null,
                    name: null,
                    key: null,
                    // description: null,
                },
            }
        },
        props: {
            option: {
                type: Object,
            },
            options: {
                type: Array,
            },
            index: {
                type: Number,
            }
        },
        validations() {
            return {
                name: {
                    required,
                    minLength: minLength(3),
                    maxLength: maxLength(100)
                },
                // img: {
                //     // required,
                //     minLength: minLength(3),
                // },
                key: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(100)
                },
                // description: {
                //     required,
                //     minLength: minLength(3),
                //     maxLength: maxLength(250)
                // }
            }
        },
        watch: {
            response_upload: function (payload) {
                this.img= payload.logo;
                this.updateOptions(payload.logo, 'img');
            },
            option: function (val) {
                // this.img= val.img;
                this.name= val.name;
                this.key= val.key;
                // this.description= val.description;
            },
        },
        computed: {
            game_id: function () {
                return this.$route.params.id;
            },
        },
        asyncComputed: {

        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            updateOptions(value, type) {
                let response= {
                    type,
                    index: this.index,
                    value
                };
                this.$emit('updateOption', response, this.$v.$invalid)
            },
            deleteOption() {
                this.$emit('deleteOption', this.index);
            }
        },
        components: {
            InputLabelTextDefault,
            // UploadAvatar,
        },
    }
</script>

<style lang="scss" scoped>

  .dark {
    .block-options {
      background: #181818;
    }
  }

  .block-options {
    background-color: #F8FAFD;
    border-radius: 8px;
    margin: 15px 0 0 45px;
    padding: 20px;
    display: flex;
  }

  .block-img {
    padding: 0;
    height: 114px;
    width: 114px;
    margin-right: 20px;

    img {
      height: 114px;
      width: 114px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .wrap-option-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .v-btn {
    outline: none;
    &:hover {
      transform: scale(1.1);
    }
  }

  .v-btn:before {
    background-color: transparent;
  }

  .disable {
    opacity: 0.4;
  }

</style>
