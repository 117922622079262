<template>
    <div class="wrap-social-item">
        <div  class="social-network">
            <DropdownDefault
                color="settings"
                placeholder = " "
                value_key="name"
                title_key="name"
                v-bind:value.sync="name"
                v-bind:dropdown="selectArr"
            >
                <template v-slot:default="item">
<!--                    {{item.active_value.icon}}-->
                    <div class="d-flex align-center">
                        <img  :src="item.active_value.icon" />
                        <img class="chevron-icon" :class="((item.value_menu) ? 'active' : '')" src="/dist/images/frontend/chevron-down.svg" alt />
                    </div>
                </template>

                <template v-slot:list="items">
                    <v-list-item
                            class="d-flex justify-center"

                            v-for="(list, key) in items.dropdown"
                            :key="key"
                            v-on:click="name= list[items.value_key]"
                    >
<!--                        {{list.icon}}-->
                        <img  :src="list.icon" />
                    </v-list-item>
                </template>
            </DropdownDefault>
<!--            <img class="chevron-icon" :class="((item.value_menu) ? 'active' : '')" src="/dist/images/frontend/chevron-down.svg" alt />-->
        </div>
        <div class="social-link">
<!--            <v-text-field-->
<!--                    v-on:keyup.enter="onSubmit"-->
<!--                    color="light-blue"-->
<!--                    :placeholder='__("Enter social link")'-->
<!--                    hide-details="auto"-->
<!--                    v-model="link"-->
<!--            ></v-text-field>-->
            <InputLabelTextDefault
                    class_input="settings_input-address"
                    color="setting_input"
                    v-model="link"
                    v-on:input="parseLink"
                    v-on:keyup.enter="onSubmit"
                    ref="input"
            >
                <template v-slot:before_value>
                <div class="desc-soc">
                    <span style="position: relative; top: 28px;" v-if="name == 'facebook'">
                      https://www.facebook.com
                    </span>
                    <span style="position: relative; top: 28px;" v-if="name == 'twitter'">
                      https://twitter.com
                    </span>
                    <span style="position: relative; top: 28px;" v-if="name == 'twitch'">
                      https://www.twitch.tv
                    </span>
                    <span style="position: relative; top: 28px;" v-if="name == 'youtube'">
                      https://www.youtube.com
                    </span>
                    <span style="position: relative; top: 28px;" v-if="name == 'linkedin'">
                      https://www.linkedin.com
                    </span>
                    <span style="position: relative; top: 28px;" v-if="name == 'instagram'">
                      https://www.instagram.com
                    </span>
                </div>

                <div class="mob-soc">
                    <span v-if="name == 'facebook'">
                      ...facebook.com
                    </span>
                        <span v-if="name == 'twitter'">
                      ...twitter.com
                    </span>
                        <span v-if="name == 'twitch'">
                      ...twitch.tv
                    </span>
                        <span v-if="name == 'youtube'">
                      ...youtube.com
                    </span>
                        <span v-if="name == 'linkedin'">
                      ...linkedin.com
                    </span>
                        <span v-if="name == 'instagram'">
                      ...instagram.com
                    </span>
                    </div>
                </template>
            </InputLabelTextDefault>
        </div>
        <v-btn
                :loading="submitDisabled"
                class="btn-add"
                text

                v-on:click="onSubmit"
        >
<!--            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M8.99997 5.93933V12.0446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path d="M12.0556 8.99203H5.94446" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9047 0.666626H5.0952C2.37298 0.666626 0.666626 2.59336 0.666626 5.32092V12.679C0.666626 15.4066 2.36504 17.3333 5.0952 17.3333H12.9047C15.6349 17.3333 17.3333 15.4066 17.3333 12.679V5.32092C17.3333 2.59336 15.6349 0.666626 12.9047 0.666626Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
            <svg width="19" height="19" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.612 1.29183H5.38783C2.87033 1.29183 1.29199 3.07433 1.29199 5.59683V12.4035C1.29199 14.926 2.86283 16.7085 5.38783 16.7085H12.6112C15.137 16.7085 16.7087 14.926 16.7087 12.4035V5.59683C16.7087 3.07433 15.137 1.29183 12.612 1.29183Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.0332 9.00016L8.01154 10.9777L11.9665 7.02266" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

        </v-btn>
        <v-btn
                class="btn-del"
                text

                v-on:click="closeForm"
        >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.1504 6.00391L6.00304 12.1513" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.1533 12.1533L6 6" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9046 0.666016H5.09508C2.37286 0.666016 0.666504 2.59275 0.666504 5.32031V12.6784C0.666504 15.406 2.36492 17.3327 5.09508 17.3327H12.9046C15.6348 17.3327 17.3332 15.406 17.3332 12.6784V5.32031C17.3332 2.59275 15.6348 0.666016 12.9046 0.666016Z" stroke="#6886AF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </v-btn>


    </div>
</template>

<script>
    import DropdownDefault from "../../modules/DropdownDefault";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: 'FormSocialItem',
        data() {
            return {
                name:this.selectArr[0].name,
                link: "",
                submitDisabled: false,
            }
        },
        props: {
            open: {
                type: Boolean
            },
            value: {
                type: String
            },
            index: {
                type: String
            },
            selectArr: {
                type: Array
            }
        },
        validations() {
            return {
                link: {
                    required,
                },
            }
        },

        methods: {
            closeForm() {
                this.$emit("update:open", false)
            },
            // onSubmit() {
            //     if (this.link.trim()) {
            //         let newSocial = {
            //             name: this.name,
            //             value: this.link
            //         };
            //
            //         // newSocial[this.name] = this.link;
            //         this.$emit('add-social', newSocial);
            //         this.$emit("update:open", false)
            //     }
            // }
            onSubmit() {
                let valid = this.$v;
                if (valid.$invalid) {
                    let massages = {
                        'link': [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled = true;
                    let domen;

                    if (this.name == 'facebook') {
                       domen ='https://www.facebook.com/';
                    } else if (this.name == 'twitter') {
                        domen = 'https://twitter.com';
                    } else if (this.name == 'twitch') {
                        domen = 'https://www.twitch.tv';
                    } else if (this.name == 'youtube') {
                        domen = 'https://www.youtube.com';
                    } else if (this.name == 'linkedin') {
                        domen = 'https://www.linkedin.com';
                    } else if (this.name == 'instagram') {
                        domen = 'https://www.instagram.com';
                    }

                    let response = {};
                    response[this.name] = domen + this.link;



                    this.$http.post('ajax/setting/social', response)
                        .then(response => {
                            return response.json()
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;

                                if (this.link.trim()) {
                                    let newSocial = {
                                        name: this.name,
                                        value: domen + this.link
                                    };
                                    this.$emit('add-social', newSocial);
                                    this.$emit("update:open", false)
                                }
                            }
                        }).catch(err => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }
            },
            parseLink(val) {
                try {
                    this.link = new URL(val).pathname;
                } catch (error) {
                    if (error) {
                        if (val && val[0] == '/') {
                            this.link = val;

                        } else if (val && val[0] !== '/') {
                            this.link = '/' + val
                        }
                    }
                }

            }
        },
        components: {
            DropdownDefault,
            InputLabelTextDefault
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-social-item {
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 5px;
        margin-top: -8px;

        .social-network {
            max-width: 50px;
            width: 100%;
            margin-right: 20px;
            margin-bottom: -4px;
            display: flex;
            position: relative;

            .chevron-icon {
                position: absolute;
                right: 5px;
                top: 18px;
                transition-duration: .3s;

                &.active {
                    transform: rotate(180deg);
                    transition-duration: .3s;
                }
            }
        }

        .social-link {
            width: 100%;

            .mob-soc {
                display: none;

                span {
                    cursor: pointer;
                }
            }

            .desc-soc {
                span {
                    cursor: pointer;
                }
            }
        }

        .v-btn.btn-del {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 12px;
            outline: none;
            border-radius: 5px;
            transition: .3s;
            border: none;


            &.v-btn:not(.v-btn--round).v-size--default {
                height: 18px;
                min-width: 18px;
                padding: 0;
            }
            svg {
                transition-duration: .3s;
                border-radius: 5px;
                color: #6886AF;
            }

            &:hover {
                svg {
                    background-color: #6886AF;
                    color: #fff;
                    border-radius: 5px;
                    transition-duration: .3s;
                }
            }
        }

        .btn-add {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            color: #26A9F4;
            margin-left: 12px;
            outline: none;
            border-radius: 5px;
            transition: .3s;

            &.v-btn:not(.v-btn--round).v-size--default {
                min-width: 18px;
                padding: 0;
                height: 18px;
            }

            svg {
                transition-duration: .3s;
                border-radius: 5px;
            }

            &:hover {
                svg {
                    background-color: #26a9f4;
                    color: #fff;
                    border-radius: 5px;
                    transition-duration: .3s;
                }
            }
        }
    }


    .dark .wrap-social-item .v-btn.btn-del {
        svg {
            background-color: #191919;
        }

        &:hover {
            svg {
                background-color: #6886AF;
                color: #fff;
                border-radius: 5px;
                transition-duration: .3s;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .wrap-social-item {
            .social-network {
                margin-right: 10px;
            }

            .social-link {
                span {
                    font-size: 12px;
                    top: 31px!important;
                    position: relative;
                }

                .mob-soc {
                    display: block;
                }

                .desc-soc {
                    display: none;
                }
            }
        }
    }

</style>