<template>
    <div class="wrapper-all-tournament">
        <div class="wrap-tournament-item" v-for="(item, key) in tournaments" :key="key">
            <ItemTournament  :item="item" ></ItemTournament>
        </div>
    </div>
</template>

<script>
    import ItemTournament from "./ItemTournament";
    export default {
        data() {
            return {
                tournaments: [
                    {
                        "desc": 'Принимай участие в турнире сражайся за приз в размере',
                        "wallet": 1,
                        "price": 100,
                        "participants": 374,
                        "logo": "/assets/games/game2048.png",
                        "list": [
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "7823",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                        ]

                    },
                    {
                        "desc": 'Принимай участие в турнире сражайся за приз в размере',
                        "wallet": 1,
                        "price": 100,
                        "participants": 374,
                        "logo": "/assets/games/game2048.png",
                        "list": [
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "7823",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                        ]

                    },
                    {
                        "desc": 'Принимай участие в турнире сражайся за приз в размере',
                        "wallet": 1,
                        "price": 100,
                        "participants": 374,
                        "logo": "/assets/games/game2048.png",
                        "list": [
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "7823",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                        ]

                    },
                    {
                        "desc": 'Принимай участие в турнире сражайся за приз в размере',
                        "wallet": 1,
                        "price": 100,
                        "participants": 374,
                        "logo": "/assets/games/game2048.png",
                        "list": [
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "7823",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                            {
                                "id": 1,
                                "public_id": "volkonsky",
                                "name": "Vladyslav",
                                "last_name": "Volkonsky",
                                "photo": null,
                                "bg_color": "#f84138",
                                "location": "UA",
                                "location_img": "/dist/images/flags/ua.png",
                                "online": "2021/04/20 07:21:44",
                                "rating_points": "782",
                                "vip": "0",
                                "plays": "0",
                                "lang": "ru"
                            },
                        ]

                    }
                ],
            }
        },
        components: {
            ItemTournament
        }
    }
</script>

<style lang="scss" scoped>
    .wrapper-all-tournament {
        display: flex;
        /*align-items: center;*/
        /*width: 100%;*/
        padding-top: 20px;
        flex-wrap: wrap;

        .wrap-tournament-item {
            width: 31%;
            margin-right: 20px;
            margin-bottom: 10px;
        }
    }
</style>