<template>
    <div v-if="cookie" class="wrap-cookies">
<!--        <v-btn text icon class="icon" @click="hideCookie()" v-ripple="false">-->
<!--            <img src="/dist/images/frontend/close.svg" alt="/" />-->
<!--        </v-btn>-->
        <div class="wrap-cookies__left">
            <div class="wrap-cookies__icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9803 8.5468C17.5123 8.69458 17.0197 8.7931 16.5271 8.7931C14.2118 8.76847 12.3399 6.89655 12.3153 4.58128C12.3153 4.13793 12.3892 3.69458 12.537 3.27586C11.9951 2.68473 11.6995 1.92118 11.6995 1.13301C11.6995 0.812808 11.7488 0.492611 11.8473 0.172414C11.2315 0.0738918 10.6158 0 10 0C4.48276 0 0 4.48276 0 10C0 15.5172 4.48276 20 10 20C15.5172 20 20 15.5172 20 10C20 9.77833 20 9.55665 19.9754 9.33498C19.2611 9.26108 18.5468 8.99015 17.9803 8.5468ZM4.58128 7.31527C6.30542 7.31527 6.30542 10.0246 4.58128 10.0246C2.85714 10.0246 2.61084 7.31527 4.58128 7.31527ZM6.05912 15.7635C4.08867 15.7635 4.08867 12.8079 6.05912 12.8079C8.02956 12.8079 8.02956 15.7635 6.05912 15.7635ZM9.01478 1.33005C10.7389 1.33005 10.7389 4.28571 9.01478 4.28571C7.29064 4.28571 7.04434 1.33005 9.01478 1.33005ZM10.2463 8.84237C11.7241 8.84237 11.7241 10.8128 10.2463 10.8128C8.76848 10.8128 9.01478 8.84237 10.2463 8.84237ZM11.9704 16.9458C10.4926 16.9458 10.4926 14.9754 11.9704 14.9754C13.4483 14.9754 13.202 16.9458 11.9704 16.9458ZM16.6503 13.1034C15.4187 13.1034 15.4187 11.133 16.6503 11.133C17.8818 11.133 17.8818 13.1034 16.6503 13.1034Z" fill="#26A9F4"/>
                </svg>
            </div>
            <p>{{__('We use cookies to ensure the functionality of our website, improve its performance and provide you with personalized services / information. We also exchange data about your actions on the site with our advertising partners. If you do not want to accept additional cookies, please close this notice. In addition, you can clarify your preferences in the "Cookie Settings" section. You can find more detailed information in our')}}
                <router-link :to="{name: 'cookie_policy', params: {lang: $store.getters.getLang}}">{{__('Cookie Policy')}}</router-link>
            </p>
        </div>

        <div class="wrap-cookies__btn">
            <BaseButton
                    class_btn="btn-liteGrey-quest"
                    @click="hideCookie()"
                    class="mr-3"
            >
                <span>{{__('Decline')}}</span>
            </BaseButton>

            <BaseButton
                    class_btn="btn-guest"
                    @click="hideCookie()"
            >
                <span>{{__('Allow')}}</span>
            </BaseButton>
        </div>
    </div>
</template>

<script>
    import BaseButton from "./BaseButton";
    export default {
        data() {
            return {
                cookie: true,
            }
        },


        methods: {
            hideCookie() {
                this.cookie = false;
                localStorage.cookie = false;
            }
        },
        mounted() {
            if (localStorage.cookie) {
                if (localStorage.cookie == 'false') {
                    this.cookie = false;
                } else {
                    this.cookie = true;
                }
            }
        },
        components: {
            BaseButton
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-cookies {
        max-width: 1185px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        position: fixed;
        z-index: 1000;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);

        &__left {
            display: flex;
            align-items: center;
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            width: 36px;
            border-radius: 4px;
            margin-right: 20px;
            flex-shrink: 0;
            background: rgba(76, 206, 234, 0.1);
        }

        p {
            font-size: 12px;
            line-height: 16px;
            color: #002b50;
            margin: 0 20px 0 0;

            a {
                color: #26A9F4;
                transition: .3s;

                &:hover {
                    text-decoration: underline;
                    color: #26A9F4;
                    transition: .3s;
                }
            }
        }

        &__btn {
            display: flex;
            align-items: center;
        }

        .icon {
            position: absolute;
            top: 5px;
            right: 10px;
            margin: 0;
            transition-duration: 0.3s;

            &:hover {
                transform: scale(1.1);
                transition-duration: .3s;
            }

            img {
                width: 9px;
            }

        }

        .v-btn:before {
            background-color: transparent;
        }

        .v-btn--icon.v-size--default {
            height: 25px;
            width: 25px;
        }
    }

    @media screen and (max-width: 1200px) {
        .wrap-cookies {
            left: 20px;
            right: 20px;
            transform: translateX(0);
            width: auto;
        }
    }

    @media screen and (max-width: 950px) {
        .wrap-cookies {
            flex-direction: column;

            &__left {
                margin-bottom: 10px;
            }

            &__btn {
                width: 100%;
                justify-content: flex-end;
            }
        }
    }

    @media screen and (max-width: 500px) {
        .wrap-cookies {
            &__icon {
                display: none;
            }
            p {
                text-align: center;
            }
            &__btn {
                justify-content: center;
            }
        }
    }
</style>