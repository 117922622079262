<template>
  <v-dialog
    v-model="modal"
    v-on:click:outside="$store.commit('updateModalAuth', false)"
    content-class="reg-auth model_wrapper"
  >
<!--    <div class="close"-->
<!--         v-on:click="$store.commit('updateModalAuth', false)"-->
<!--    >-->
<!--    </div>-->
    <div class="block">
      <div>
<!--        <v-row class="d-flex">-->
          <div class="main-sign-up">
<!--              <img src="/dist/images/frontend/logo-2.svg" alt />-->
             <h2>{{__('Welcome to')}} <span>MindPlays</span></h2>
              <p class="white--text lead">{{__('Login to your account')}}</p>
<!--              <v-btn-->
<!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--                class="btn"-->
<!--                tag="a"-->
<!--                height="30px"-->
<!--                v-on:click="$store.commit('updateModalAuth', 'register')"-->
<!--              >Sign Up</v-btn>-->
<!--            <hr class="mt-md-6 w-25">-->
<!--            <p class="small bold text-contrast">{{__('Or sign in with')}}</p>-->
            <div class="d-flex justify-space-between wrap-social-btn">
              <nav class="nav mb-4">
                <a class="btn btn-rounded btn-outline-secondary brand-facebook" href="/login/facebook">
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a class="btn btn-rounded btn-outline-secondary brand-twitter" href="/login/google">
                  <i class="fab fa-google"></i>
                </a>
<!--                <a class="btn btn-rounded btn-outline-secondary brand-twitter mr-2" href="/login/vkontakte">-->
<!--                  <i class="fab fa-google"></i>-->
<!--                </a>-->
              </nav>
            </div>
          </div>
          <div class="main-login">
              <img src="/dist/images/frontend/logo-2.svg" alt />
              <p class="white--text ">{{__('You have an account?')}}</p>
              <v-btn

                class="btn"
                tag="a"
                height="30px"
                v-on:click="$store.commit('updateModalAuth', 'login')"
              >{{__('Login')}}</v-btn>
          </div>
<!--        </v-row>-->
      </div>
      <!--        :style="{transform: 'translateY(-50%) '+((modal_position== 'left') ? 'translateX(-350px)' : '')}"-->
        <!--      <div-->
        <!--        class="white text-center d-flex flex-wrap align-center form-register"-->
        <!--        :class="(modal_position== 'left') ? 'position-left' : 'position-right'"-->
        <!--      >-->
        <div class="white text-center d-flex flex-wrap align-center form-register">
            <div class="close"
                 v-on:click="$store.commit('updateModalAuth', false)"
            >
            </div>
        <div class="all-reg" style="width: 100%">
          <ModalAuthFormRegister v-bind:captcha.sync="captcha" v-if="window=='register'" v-bind:socialAuth="socialAuth"></ModalAuthFormRegister>
          <ModalAuthFormSecurity v-else-if="window=='security'" v-bind:modal="modal"></ModalAuthFormSecurity>
          <ModalAuthFormPassReset v-bind:captcha.sync="captcha" v-else-if="window=='password_reset'" v-bind:modal="modal"></ModalAuthFormPassReset>
          <ModalAuthFormForgot v-bind:captcha.sync="captcha" v-else-if="window=='forgot'"></ModalAuthFormForgot>
          <ModalAuthFormLogin v-bind:captcha.sync="captcha" v-else v-bind:socialAuth="socialAuth"></ModalAuthFormLogin>
        </div>
      </div>
      <Captcha v-if="captcha" @onSubmit="onSubmit"></Captcha>
    </div>
  </v-dialog>
</template>

<script>
import ModalAuthFormLogin from "./ModalAuthFormLogin";
import ModalAuthFormRegister from "./ModalAuthFormRegister";
import ModalAuthFormSecurity from "./ModalAuthFormSecurity";
import ModalAuthFormForgot from "./ModalAuthFormForgot";
import ModalAuthFormPassReset from "./ModalAuthFormPassReset";

import Captcha from "./Captcha";

export default {
  name: "ModalAuth",
  data() {
    return {
      captcha: false,
      socialAuth: [
        {
          icon: "google.svg",
          url: "/ajax/login/google",
        },
        {
          icon: "facebook-act.svg",
          url: "/ajax/login/facebook",
        },
        {
          icon: "vk.svg",
          url: "/ajax/login/vkontakte",
        },
      ],
      modal_position: "left",
      window: null,
    };
  },
  props: {},
  computed: {
    modal() {
      return this.$store.state.preference.modal_auth;
    },
  },
  asyncComputed: {
    async_modal() {
      let modal = this.modal;

      if (modal) {
        if (modal instanceof Object) {
          if (modal.security) {
            this.window = "security";
            this.modal_position = "right";
          } else if (modal.password_reset) {
            this.window = "password_reset";
            this.modal_position = "right";
          }
        } else {
          this.window = modal;
          switch (modal) {
            case "register":
              this.modal_position = "right";
              break;
            default:
              this.modal_position = "right";
          }
        }
      }
      return true;
    },
  },
  methods: {

      onSubmit(session, arrayData) {
          this.captcha(session, arrayData);
          this.captcha= false;
      }
  },
  components: {
    ModalAuthFormLogin,
    ModalAuthFormRegister,
    ModalAuthFormSecurity,
    ModalAuthFormForgot,
    ModalAuthFormPassReset,
    Captcha,
  },
};
</script>

<style lang="scss">
.model_wrapper.v-dialog {
  overflow: unset;
  position: relative;
  border-radius: 8px;
  width: 100%;
}

.form-register {
  /*<!--transform: translateY(-50%);-->*/
  /*width: 320px;*/
  /*position: absolute;*/
  /*right: 16px;*/
  /*top: 50%;*/
  padding: 30px 26px;
  transition: 0.6s;
  font-size: 14px;
  background: #ffffff;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
}

.position-left {
  transform:translateY(-50%) translateX(-350px);
}

.block {
  background: #002b50;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  /*padding: 60px 15px 70px 20px;*/
  font-size: 14px;

  p {
    margin-bottom: 20px;
  }

  img {
    margin-bottom: 8px;
  }

  .btn {
    width: 100px;
    height: 30px;
    text-transform: inherit;
  }
}

@media screen and (max-width: 1024px) {

}
@media screen and (max-width: 991px){
    .reg-auth .block{
        width: 50%;
    }
    .reg-auth .form-register{
        width: 50%;
    }
    .reg-auth .main-sign-up h2{
        font-size: 52px!important;
    }
}
  @media screen and (max-width: 768px) {

      /*.reg-auth .block{*/
      /*    width: 0;*/
      /*}*/

    /*.model_wrapper.v-dialog {*/
    /*  max-width: 670px!important;*/
    /*}*/

    /*.form-register {*/
    /*  width: 280px;*/
    /*  padding: 15px;*/
    /*}*/


  }

@media screen and (max-width: 700px) {

  .model_wrapper.v-dialog {
  }
  .form-register {
  }

  .block {
  }
}

@media screen and (max-width: 670px) {

}

@media screen and (max-width: 645px) {

}

@media screen and (max-width: 625px) {

}

@media screen and (max-width: 605px) {

}
@media screen and (max-width: 585px) {

}
@media screen and (max-width: 565px) {

}

@media screen and (max-width: 550px) {


  /*.block {*/

  /*  .btn {*/
  /*    font-size: 12px;*/

  /*    &.v-btn.v-size--default, .v-btn.v-size--large {*/
  /*      font-size: 12px;*/
  /*    }*/
  /*  }*/

  /*  img {*/
  /*    margin-bottom: 0;*/
  /*  }*/
  /*  p {*/
  /*    font-size: 12px;*/
  /*  }*/
  /*}*/
}

@media screen and (max-width: 525px) {

  .block {
  }
  .form-register {
  }
}

@media screen and (max-width: 500px) {
  .position-left {
  }
}

@media screen and (max-width: 475px) {
  .position-left {
  }
}

@media screen and (max-width: 450px) {
  .position-right {
  }
  .position-left {
  }
}

@media screen and (max-width: 430px) {
  .position-right {
  }

  .position-left {
  }
}

@media screen and (max-width: 425px) {

  .block {
  }

  .form-register {
  }
  .position-left {
  }
}

  @media screen and (max-width: 400px) {
    .position-left {
    }
    .form-register {
    }

    /*.block {*/

    /*  p {*/
    /*    margin-bottom: 10px;*/
    /*    font-size: 10px;*/
    /*  }*/

    /*  img {*/
    /*    margin-bottom: 0px;*/
    /*  }*/

    /*  .btn {*/
    /*    width: 80px;*/
    /*    max-height: 25px;*/
    /*    text-transform: inherit;*/
    /*  }*/
    /*}*/
  }

@media screen and (max-width: 375px) {
  .position-left {
    transform:translateY(-50%) translateX(-130px);
  }
}

</style>
