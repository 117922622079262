<template>
    <div class="wrap-banner-preview">
        <v-dialog v-model="dialog" width="fit-content">
            <div class="banner-preview">
                <div class="banner-preview__head">
                    <div class="d-flex align-center">
                        <p>{{__('Preview banner')}}</p>
                    </div>
                    <v-btn text icon class="icon" @click="dialog = false">
                        <img src="/dist/images/frontend/close.svg" alt="/" />
                    </v-btn>
                </div>
                <div class="banner-preview__body" v-html="item.img">

                </div>
            </div>
        </v-dialog>
    </div>

</template>
<script>
    export default {
        name:"ModalBannerPreview",
        data(){
            return {
                dialog: true,
            }
        },
        props: {
            banner_preview_modal: {
                type: Boolean,
            },
            item: {
                type: Object,
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:banner_preview_modal", false);
            },
        }
    }
</script>

<style lang="scss" scoped>

    .banner-preview {
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #F0F6FE;
            border-radius: 8px 8px 0px 0px;
            padding: 20px 24px;
            position: relative;


            svg {
                margin-right: 12px;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #002b50;
                margin-bottom: 0;
            }

            .icon {
                position: absolute;
                top: 10px;
                right: -4px;
                outline: none;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__body {
            display: flex;
            justify-content: center;
            padding: 20px 15px 20px;
            background-color: #ffffff;
            border-radius: 0 0 8px 8px;
            max-height: 400px;
            max-width: 1000px;
            height: 100%;
            overflow: auto;
            min-width: 300px;
            
            img {
                width: 100%;
            }

        }
    }
</style>