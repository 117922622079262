<template>
    <div class="wrap-social-item">
<!--        <div v-if="edit" class="social-network">-->
<!--            <DropdownDefault-->
<!--                    color="settings"-->
<!--                    placeholder = " "-->
<!--                    value_key="name"-->
<!--                    title_key="name"-->
<!--                    v-bind:value.sync="social.name"-->
<!--                    v-bind:dropdown="$socials"-->
<!--            />-->
<!--        </div>-->
        <div class="social-img">
            <img :src="social.icon" alt />
        </div>
        <div class="social-link">
            <v-text-field
                    disabled
                    color="light-blue"
                    :placeholder='__("Enter social link")'
                    hide-details="auto"
                    v-model="value"
            ></v-text-field>
        </div>
        <v-btn
                class="btn-delete"
                :loading="submitDisabled"
                icon
                text
                @click="removeSocial"
        >
            <img src="/dist/images/frontend/icon-del.svg" alt="/" />
            <img src="/dist/images/frontend/icon-del-blue.svg" alt="/" />
        </v-btn>


<!--        <v-btn-->
<!--                -->
<!--                class="btn-add"-->
<!--                text-->
<!--                v-ripple="{ class:'secondary&#45;&#45;text'}"-->
<!--        >+-->
<!--        </v-btn>-->
    </div>

</template>

<script>
    // import DropdownDefault from "../../modules/DropdownDefault";
    export default {
        name: 'SocialItem',
        data() {
            return {
                social:this.$cleanObject(this.$filterArray(this.$socials, this.index, 'name')),
                submitDisabled:false,
            }
        },
        props: {
            social_name: {
                type: Object
            },
            value: {
                type: String
            },
            index: {
                type: String
            },
        },

        methods: {
            // removeSocial() {
            //     this.$emit("remove-social", this.index)
            // }
            removeSocial() {
                this.submitDisabled = true;

                let response = {};
                response[this.social.name] = null;


                this.$http.post('ajax/setting/social', response)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            this.submitDisabled = false;
                            this.$emit("remove-social", this.index);
                        }
                    }).catch(err => {
                    this.submitDisabled = false;
                    let response = this.httpHandler(err);
                    if (response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            }
        },
        components: {
            // DropdownDefault
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-social-item {
        display: flex;
        align-items: center;
        width: 100%;

        .social-img {
            /*min-width: 20px;*/
            max-width: 21px;
            width: 100%;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .social-link {
            width: 100%;

        }

        .btn-delete {
            outline: none;
            transition: .3s;
            width: 20px;

            img {
                &:last-child {
                    display: none;
                }
            }

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 20px;
                padding: 0 5px;
            }

            &.v-btn:before {
                background-color: transparent;
            }

            &:hover {
                background-color: transparent;
                color: #26A9F4 !important;
                transition: .3s;

                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }
    }
</style>