let langLocation= window.location.pathname.split('/');
let lang= 'ru',
    languages= JSON.parse(window.languages);

if(langLocation[1] && (languages.map(function (e) { return String(e['lang']); }).indexOf(langLocation[1]) >= 0)) {
    lang= langLocation[1];
}

export default {
    state: {
        lang: lang,
        langLink: (lang== 'ru') ? null : lang,
        list: languages
    },
    getters: {
        getLanguage(state){
            return state.lang;
        },
        getLang(state){
            return state.langLink;
        },
        getLangs(state){
            return state.list;
        }
    },
    actions: {
        asyncLanguage(context, payload) {
            context.commit('updateLanguage', payload);
        },
        asyncLanguages(context, payload) {
            context.commit('updateLanguages', payload.data);
        },
    },
    mutations: {
        updateLanguage(state, language) {
            this.state.language.lang = language;
            this.state.language.langLink = ((language== 'ru') ? null : language);
            // routes.params.lang= ((language== 'ru') ? '' : language);
        },
        updateLanguages(state, data) {
            this.state.language.list = data;
        }
    }
}
