<template>
    <div>
        <v-dialog v-model="dialog" max-width="610px">
            <div class="wrap-googleForm">
                <!--        <div v-if="setting">-->
                <div class="wrap-googleForm__head">

                    <h2>{{__('Withdrawal confirmation')}}</h2>
                </div>

                <div class="wrap-googleForm__body">
                    <div class="block-step">
                        <div class="block-icon">
                            <img src="/dist/images/frontend/password-icon.svg" alt />
                        </div>
                        <div>
                            <p class="block-step__title">{{__('2FA Authentication')}}</p>
                            <p class="block-step__desc">{{__('Enter the 2FA code displayed in the app to confirm withdraw funds')}}</p>
                        </div>
                    </div>
                    <div class="block-authentication">
                        <InputLabelTextDefault color="main" type="number" :label="__('6-digit 2 FA number')" v-model="code" v-bind:errors="formValidate.code" @change="updateValidate('code')"></InputLabelTextDefault>
                        <div class="block-buttons">
                            <BaseButton class_btn="btn-liteGrey" class="mr-3" :width="100" v-on:click="dialog = false"><span>{{__('Cancel')}}</span></BaseButton>
                            <BaseButton v-on:click="onSubmit" width="100" :loading="submitDisabled">
                                <span>{{__('Confirm')}}</span>
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    // import VueQrcode from '@chenfengyuan/vue-qrcode';
    import InputLabelTextDefault from '../../modules/InputLabelTextDefault';
    import {required, numeric, maxLength, minLength} from "vuelidate/lib/validators";
    import BaseButton from "../../modules/BaseButton";
    export default {

        name: 'Modal2FAConfirmation',
        data () {
            return {
                dialog: true,
                submitDisabled: false,
                code: null,
                formValidate: {
                    code: null
                },
            }
        },
        props: {
            response: {
                type: Object
            },
            url: {
                type: String
            },
        },
        computed: {

        },
        watch: {
            dialog() {
                this.cancelModal();
            }
        },
        validations() {
            return {
                code: {
                    required,
                    numeric,
                    maxLength: maxLength(6),
                    minLength: minLength(6),
                },
            }
        },
        created() {

        },
        asyncComputed: {

        },
        methods: {
            updateValidate: function(type) {
                this.formValidate= this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit() {
                let valid = this.$v;

                if (valid.$invalid) {
                    let massages = {
                        code: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    this.dialog= false;

                    let response= this.response;
                    response.code= this.code;

                    this.$http.post(this.url, response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                // response= {
                                //     "data": {
                                //         "status": "success",
                                //         "with":  {
                                //             "id": 1027,
                                //             "user_id": 2,
                                //             "frozen": 0,
                                //             "wallet": 1,
                                //             "amount": "0.00000000",
                                //             "amount_unit": "0.00093802",
                                //             "rate_amount": "0.00001000",
                                //             "income": 2,
                                //             "commission": "0.60000000",
                                //             "opp_type": 2,
                                //             "game_played_id": null,
                                //             "game_id": null,
                                //             "payment_id": 1,
                                //             "tx": "hsfdhkjskhjsdfkjhsdfajkl",
                                //             "status": 3,
                                //             "confirm": 0,
                                //             "created_at": "2021-02-23 20:27:42"
                                //         },
                                //         "info": {
                                //             "user_balance": {
                                //                 "balance_real": {
                                //                     "available": "47.42",
                                //                     "frozen": 84,
                                //                     "total": "131.42"
                                //                 },
                                //                 "balance_lite": {
                                //                     "available": "913.73",
                                //                     "frozen": 229.01,
                                //                     "total": "1142.74"
                                //                 }
                                //             },
                                //             "withdraw_transaction": {
                                //                 "current_page": 1,
                                //                 "data": [
                                //                     {
                                //                         "id": 1027,
                                //                         "user_id": 2,
                                //                         "frozen": 0,
                                //                         "wallet": 1,
                                //                         "amount": "0.00000000",
                                //                         "amount_unit": "0.00093802",
                                //                         "rate_amount": "0.00001000",
                                //                         "income": 2,
                                //                         "commission": "0.60000000",
                                //                         "opp_type": 2,
                                //                         "game_played_id": null,
                                //                         "game_id": null,
                                //                         "payment_id": 1,
                                //                         "tx": "hsfdhkjskhjsdfkjhsdfajkl",
                                //                         "status": 3,
                                //                         "confirm": 0,
                                //                         "created_at": "2021-02-23 20:27:42"
                                //                     },
                                //                     {
                                //                         "id": 1028,
                                //                         "user_id": 2,
                                //                         "frozen": 0,
                                //                         "wallet": 1,
                                //                         "amount": "50.00000000",
                                //                         "amount_unit": "0.00093802",
                                //                         "rate_amount": "0.00001000",
                                //                         "income": 2,
                                //                         "commission": "0.60000000",
                                //                         "opp_type": 2,
                                //                         "game_played_id": null,
                                //                         "game_id": null,
                                //                         "payment_id": 1,
                                //                         "tx": "hsfdhkjskhjsdfkjhsdfajkl",
                                //                         "status": 2,
                                //                         "confirm": 0,
                                //                         "created_at": "2021-02-23 20:34:44"
                                //                     },
                                //                     {
                                //                         "id": 1029,
                                //                         "user_id": 2,
                                //                         "frozen": 0,
                                //                         "wallet": 1,
                                //                         "amount": "50.00000000",
                                //                         "amount_unit": "0.00093802",
                                //                         "rate_amount": "0.00001000",
                                //                         "income": 2,
                                //                         "commission": "0.60000000",
                                //                         "opp_type": 2,
                                //                         "game_played_id": null,
                                //                         "game_id": null,
                                //                         "payment_id": 1,
                                //                         "tx": "hsfdhkjskhjsdfkjhsdfajkl",
                                //                         "status": 2,
                                //                         "confirm": 0,
                                //                         "created_at": "2021-02-23 20:35:20"
                                //                     }
                                //                 ],
                                //                 "first_page_url": "https://mindplay.loc/ajax/withdraw/paginate/withdraw?page=1",
                                //                 "from": 1,
                                //                 "last_page": 1,
                                //                 "last_page_url": "https://mindplay.loc/ajax/withdraw/paginate/withdraw?page=1",
                                //                 "next_page_url": null,
                                //                 "path": "https://mindplay.loc/ajax/withdraw/paginate/withdraw",
                                //                 "per_page": 24,
                                //                 "prev_page_url": null,
                                //                 "to": 3,
                                //                 "total": 3
                                //             }
                                //         }
                                //     },
                                //     "status": 200
                                // };

                                this.submitDisabled = false;
                                this.$emit('updateWithdraws', response.data.info, response.data.with);
                                this.$emit('updateTransfer', response.data.info.data);
                                // this.$emit('update:response', false);
                            }
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });
                }
            },
            cancelModal() {
                this.$emit("update:response", false);
            }
        },
        components: {
            InputLabelTextDefault,
            BaseButton
            // VueQrcode
        },

    }
</script>
<style lang="scss">

    .dark {
        .wrap-googleForm {
            background: #171827;

            &__head {
                border-bottom: 1px solid #2b2b2b;
                h2 {
                    color: #fff;
                }
            }

            &__body {
                .block-step {

                    .block-icon {
                        background: rgba(38,169,244,.1);
                    }
                    &__desc {
                        color: #96AFD1;
                    }
                }
            }
        }
    }

    .wrap-googleForm {
        background-color: #FFFFFF;
        border-radius: 4px;


        &__head {
            padding: 20px 0 20px 25px;
            border-bottom: 1px solid #F0F6FE;
            h2 {
                font-weight: 400;;
                font-size: 20px;
                line-height: 27px;
                color: #002B50;
            }
        }

        &__body {
            padding: 30px 25px;

            .block-step {
                display: flex;
                align-items: flex-start;
                margin-bottom: 10px;

                .block-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 46px;
                    height: 46px;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
                    border-radius: 8px;
                    margin-right: 21px;
                }

                &__title {
                    font-weight: 400;;
                    font-size: 16px;
                    line-height: 22px;
                    color: #26A9F4;
                    margin-bottom: 1px;
                }

                &__desc {
                    font-weight: 400;;
                    font-size: 14px;
                    line-height: 19px;
                    color: #6886AF;
                    margin-bottom: 0;
                }
            }

            .security-qr {
                //margin: -12px 0 17px 67px;
                margin: 16px 0 17px 0;
                filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1))
            }

            .block-gr {
                //margin-left: 67px;
                /*margin-bottom: 3px;*/

                p {
                    font-weight: 400;;
                    font-size: 14px;
                    line-height: 19px;
                    color: #6886AF;
                    margin-bottom: 0;
                }
            }

            .block-authentication {
                display: flex;
                //margin-left: 67px;
                /*height: 40px;*/
                .block-buttons {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        .wrap-googleForm {
            &__body {
                padding:15px 20px 20px;

                .block-step {
                    .block-icon {
                        display: none;
                    }
                }
                .block-authentication {
                    flex-direction: column;

                    .block-buttons {
                        margin-top: 20px;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
</style>