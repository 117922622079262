<template>
    <div class="nav-auth" :class="class_nav" v-if="auth">
        <div
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            class="nav-auth__wrap"
        >
            <div class="left-block">
                <slot></slot>
            </div>
            <div class="right-block">
                <!--<LayoutNavBalanceNew></LayoutNavBalanceNew>-->
                <!--<LayoutNavProfile></LayoutNavProfile>-->
                <slot name="right"></slot>
            </div>

            <!--<div class="border-line"></div>-->
        </div>



        <slot name="line"></slot>


    </div>
</template>

<script>

// import LayoutNavBalanceNew from "./LayoutNavBalanceNew";
// import LayoutNavProfile from "./LayoutNavProfile";
    export default {
        name: 'LayoutNavAuth',
        data() {
            return {

            }
        },

        props: {
            class_nav: {
                type: String,
            }
        },

        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
        },
        components: {
            // LayoutNavBalanceNew,
            // LayoutNavProfile,
        }
    }
</script>

<style lang="scss">

    .dark .nav-auth {
        background: #171827;
        -webkit-backdrop-filter: blur(40px);
        -moz-backdrop-filter: blur(40px);
        -ms-backdrop-filter: blur(40px);
        -o-backdrop-filter: blur(40px);
        backdrop-filter: blur(40px);

        &__wrap {
            .left-block {

                .wrap-btn {
                    border-right: 1px solid rgba(255, 255, 255, 0.1);
                }

                .page-name {
                    h1 {
                        color: #fff;
                    }
                }

                h1 {
                    color: #fff;
                }

                .v-btn.btn-search {
                    color: #96AFD1;
                    background: rgba(255, 255, 255, 0.05);
                    border: 1px solid rgba(255, 255, 255, 0.05);

                    &:hover {
                        background-color: #181818!important;
                        color: #26a9f4!important;
                        border: 1px solid rgba(255, 255, 255, 0.05);
                    }

                    &:before {
                        background-color: #181818!important;
                        color: #26a9f4!important;
                        border: 1px solid rgba(255, 255, 255, 0.05);
                    }
                }
            }

            .search {
                &__icon {
                    svg {
                        color: #96AFD1;
                    }
                }
            }

            .right-block {
                .search {
                    background: #171827;
                }
            }
        }
    }
    .nav-auth {
        /*position: fixed;*/
        /*top: 0;*/
        /*overflow: hidden;*/
        /*right: 0;*/
        /*left: 0;*/
        /*margin-left: 260px;*/
        /*z-index: 150;*/
        -webkit-backdrop-filter: blur(40px);
        -moz-backdrop-filter: blur(40px);
        -ms-backdrop-filter: blur(40px);
        -o-backdrop-filter: blur(40px);
        backdrop-filter: blur(40px);
        background: rgba(255, 255, 255, 0.75);
        height: 60px;
        width: 100%;
        position: relative;
        z-index: 1;

        &__wrap {
            max-width: 1200px;
            /*padding-left: 260px;*/
            width: 100%;
            margin: 0 auto;
            height: 60px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            /*position: relative;*/
            /*padding: 0 20px;*/


            .left-block {
                display: flex;
                align-items: center;
                /*padding: 0 0 0 20px;*/
                width: 100%;

                a {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #26A9F4;
                    text-decoration: none;

                    svg {
                        margin-right: 14px;
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }

                h1 {
                    font-size: 16px;
                    line-height: 22px;
                    color: #000000;
                    white-space: nowrap;
                    flex-shrink: 0;
                }

                .page-name {
                    display: flex;
                    align-items: center;

                    h1 {
                        font-size: 16px;
                        line-height: 22px;
                        color: #000000;
                        white-space: nowrap;
                    }

                    svg,
                    img {
                        margin-right: 10px;
                        margin-bottom: 2px;
                    }
                }

                .block-breadcrumbs {
                    display: flex;
                    align-items: center;
                    height: 30px;

                    a {
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        color: #ffffff;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        padding-right: 12px;
                        margin-right: 12px;
                        position: relative;
                        height: 100%;
                        transition-duration: 0s;

                        .back-img {
                            width: 20px;
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            /*border: 1px solid #96AFD1;*/
                            border-radius: 50%;
                            margin-right: 10px;
                            background-color: #000303;

                            svg {
                                margin: 0;
                            }
                        }

                        span {
                            white-space: nowrap;
                        }


                        &:hover {
                            color: #fff;
                            text-decoration: none;
                            transition-duration: 0s;

                            .back-img {
                               background-color:#0B0C1A;
                                /*border: 1px solid #fff;*/
                            }
                        }
                    }



                    p {
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;
                        margin: 0;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }



                .block-search {
                    background-color: #fff;
                    position: absolute;
                    left: 0;
                    bottom: -59px;
                    width: 100%;
                    /*height: 56px;*/
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px 0 20px;
                    height: 0;
                    border-bottom: 1px solid #ECF4FF;
                    transform: translateY(-56px);
                    transition: .3s;
                    opacity: 0;
                }

                .search.active {
                    transform: translateY(0);
                    height: 56px;
                    //position: relative;
                    //z-index: 1;
                    transition: .3s;
                    opacity: 1;
                    bottom: -55px;
                    z-index: 10;


                }

                .search {
                    /*width: 100%;*/
                    /*display: flex;*/
                    /*align-items: center;*/
                    /*padding-left: 20px;*/
                    /*margin-left: 40px;*/
                    /*border-left: 1px solid #EEF0F9;*/

                    background-color: #fff;
                    position: absolute;
                    left: 0;
                    bottom: -30px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px 0 280px;
                    height: 0;
                    border-bottom: 1px solid #ECF4FF;
                    border-top: 1px solid #ECF4FF;
                    transform: translateY(-56px);
                    transition: .3s;
                    opacity: 0;
                    z-index: -1;

                    &__icon {

                    }

                    &__input {
                        width: 100%;
                        background-color: transparent;
                        margin-top: 7px;
                    }

                    svg {
                        cursor: pointer;
                        color: #6886AF;
                        transition: .3s;

                        &:hover {
                            color: #E94F4E;
                            transition: .3s;
                        }
                    }
                }

                .wrap-img {
                    border-radius: 3px;
                    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
                    width: 48px;
                    height: 48px;
                    margin-right: 10px;
                    margin-left: 2px;

                    img {
                        border-radius: 3px;
                        width: 48px;
                        height: 48px;
                    }
                }

                .info-game {
                    &__title {
                        font-size: 15px;
                        line-height: 16px;
                        color: #002B50;
                        margin-bottom: 4px;
                    }

                    &__subtitle {
                        .block-info-game {
                            display: flex;
                            align-items: center;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 18px;
                            color: #6886AF;

                            &__players {
                                margin-right: 7px;
                                display: flex;
                                align-items: center;
                                svg {
                                    margin-right: 4px;
                                }

                                span {
                                    color: #26A9F4;
                                    font-weight: bold;
                                }
                            }

                            p {
                                margin: 0;

                                span {
                                    color: #26A9F4;
                                    margin-left: 1px;
                                }
                            }

                            .circle {
                                display: block;
                                min-width: 5px;
                                min-height: 5px;
                                border-radius: 50%;
                                background-color: #CBDAE9;
                                margin-right: 7px;
                            }

                            &__created {
                                margin: 0;
                            }

                            a {
                                color: #26A9F4;
                                text-decoration: none;
                                transition-duration: .3s;

                                &:hover {
                                    text-decoration: underline;
                                    transition-duration: .3s;
                                }
                            }
                        }
                    }
                }

                .wrap-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    border-right: 1px solid #F0F6FE;
                    padding-right: 20px;
                    width: 100%;
                }

                .wrap-btn-sort {
                    position: relative;
                    .block-count {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        background: #26A9F4;
                        border: 2px solid #FFFFFF;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        font-size: 12px;
                        line-height: 16px;
                        position: absolute;
                        top: -7px;
                        right: -7px;
                    }
                }

                .wrap-btn-search {
                    display: none;
                }

                .v-btn.btn-search,
                .v-btn.btn-sorting {
                    background: transparent;
                    border-radius: 8px;
                    transition: .3s;

                    &.v-btn:not(.v-btn--round).v-size--default {
                        height: 40px;
                        min-width: 40px;
                        padding: 0;
                    }

                    &:hover {
                        background-color: #E7F5FF;
                        transition: .3s;
                    }

                    &.v-btn:before {
                        background-color: #E7F5FF;
                        transition: .3s;
                    }

                }
                .v-btn.btn-search--active {
                    background-color: #E7F5FF;
                }

                .wrap-tabs {
                    margin-bottom: -24px;
                }

                .wrap-select {
                    display: none;
                }
            }

            .right-block {
                display: flex;
                align-items: center;
                padding: 0 0 0 20px;
                flex-shrink: 0;

                .wrap-select {
                    display: none;
                }

                .wrap-tabs {

                }
            }



            /*.border-line {
                position: fixed;
                top: -60px;
                left: 0;
                height: 2px;
                width: 100%;
                !*background-color: #E0ECFD;*!
                background-color: red;
            }*/

            .search {
                display: flex;
                align-items: center;

                &__icon {
                    /*margin-bottom: 3px;*/

                    svg {
                        color: #002b50;
                    }
                }

                .cancel-icon {
                    cursor: pointer;
                    width: 10px;

                    svg {
                        transition: .3s;
                        margin-right: 0;
                        color: #607EA7;
                    }

                    &:hover {
                        svg {
                            transform: scale(1.15);
                            transition: .3s;
                            color: #26A9F4;
                        }
                    }
                }
            }

            /*.loader__element {*/
                /*height: 2px;*/
                /*width: 100%;*/
                /*background: #E0ECFD;*/
                /*position: absolute;*/
                /*bottom: 0;*/
                /*left: 260px;*/

            /*}*/

            /*.loader__element:before {*/
                /*content: '';*/
                /*display: block;*/
                /*background: linear-gradient(90deg, #026EED 0%, #00AEFF 51.56%, #01DF72 100%);;*/
                /*height: 2px;*/
                /*width: 0;*/
                /*animation: getWidth 2s ease-in;*/
            /*}*/

            /*@keyframes getWidth {*/
                /*100% { width: 50%; }*/
            /*}*/
        }

        .loading {
            position: absolute;
            top: -60px;
            left: 0;
            height: 2px;
            background: linear-gradient(90deg, #026EED 0%, #00AEFF 51.56%, #01DF72 100%);
        }
    }

    .nav-auth.nav-messenger {
        height: 0;
    }

    @media screen and (max-width: 1240px){
        .nav-auth__wrap {
            padding: 0 20px;
            max-width: 1240px;
        }

        .block-verifycation {
            padding: 10px 20px 0;
        }
    }

    @media screen and (max-width: 1100px) {
        .nav-auth.settings_nav .nav-auth__wrap .left-block .wrap-tabs {
            display: none;

        }

        .nav-auth.settings_nav .wrap-select {
            display: block;
        }
    }

    @media screen and (max-width: 1024px) {
        .nav-auth {

            &__wrap {
                /*padding-left: 240px;*/

                .left-block {
                    .search {
                        /*padding-left: 260px;*/
                    }
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        .nav-auth {
            &__wrap {
                /*padding-left: 0;*/

                .left-block {
                    /*padding-left: 60px;*/
                }
            }
        }

    }

    @media screen and (max-width: 768px) {
        .nav-auth {
            &__wrap {
                padding: 0 15px;
                .left-block {
                    .wrap-img {
                        display: none;
                    }

                    .info-game {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        .nav-auth {
            &__wrap {
                justify-content: flex-start;
                .left-block {
                    /*width: fit-content;*/
                }
            }
        }
    }

    @media screen and (max-width: 600px) {

        .dark .nav-auth {
            &__wrap {
                .right-block {
                    .search {
                        border-bottom: 1px solid #2B2B2B;
                    }
                }
            }
        }
        .nav-auth {
            &__wrap {
                .left-block {

                    .wrap-btn {
                        border: none;
                        padding: 0;
                    }

                    .wrap-tabs {
                        display: none;
                    }
                    .wrap-select {
                        display: block;
                    }

                    .wrap-btn-search {
                        margin-left: 10px;
                        display: block;
                    }
                }

                .right-block {
                    padding: 0;
                    .search {
                        position: absolute;
                        left: 0;
                        bottom: -41px;
                        width: 100%;
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 4px 10px;
                        height: 40px;
                        transform: translateY(-40px);
                        transition: .3s;
                        opacity: 0;
                        margin: 0;
                        border: none;
                        border-bottom: 1px solid #ECF4FF;
                        z-index: -1;

                        &__input {
                            width: 100%;
                        }
                    }

                    .search.active {
                        transform: translateY(0);
                        height: 40px;
                        transition: .3s;
                        opacity: 1;
                        z-index: 10;
                    }

                    .wrap-select {
                        display: block;
                    }

                    .wrap-tabs {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 500px) {
        .nav-auth {
            &__wrap {

                .left-block {
                    h1 {
                        white-space: normal;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .block-breadcrumbs {
                        height: 28px;

                        a {
                            font-size: 12px;
                            line-height: 14px;
                            padding-right: 10px;
                            margin-right: 10px;

                            .back-img {
                                width: 18px;
                                height: 18px;
                                margin-right: 6px;
                            }
                        }

                        p {
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
                .right-block {
                    /*padding-left: 0;*/
                }
            }
        }
    }

    @media screen and (max-width: 450px) {
        .nav-auth {
            &__wrap {
                .left-block {

                    h4 {
                        display: none;
                    }
                   .search {
                       display: none;
                   }
                }
            }
        }

        .nav-auth.profile_nav .nav-auth__wrap .left-block {
            display: block;
        }
    }
</style>