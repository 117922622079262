<template>
  <div class="block-reset">
      <div>
        <img src="/dist/images/frontend/LogoHome.svg" class="logo img-responsive " alt="">
        <h1 class="text-darker text-center bold mb-2">
          {{__('Password reset')}}
        </h1>
        <p class="text-secondary ">{{__('Enter a new password')}}</p>
      </div>
      <div>
        <div class="mt-3">
          <InputLabelTextDefault color="auth" disabled :value="modal.password_reset.email" v-bind:errors="formValidate.email" type="email" name="email"></InputLabelTextDefault>
        </div>
        <div class="mt-3">
          <InputLabelTextDefault color="auth" switchType :placeholder="__('New password')" type="password" v-model="password" v-bind:errors="formValidate.password" @change="updateValidate('password')"></InputLabelTextDefault>
        </div>
        <div class="mt-3">
          <InputLabelTextDefault color="auth" switchType :placeholder="__('Confirmation Password')" type="password" v-model="password_confirmation" v-bind:errors="formValidate.password_confirmation" @change="updateValidate('password_confirmation')"></InputLabelTextDefault>
        </div>
<!--          <div class="mt-6">-->
<!--            <v-btn v-if="submitDisabled" block default disabled>{{__('loading...')}}</v-btn>-->
<!--            <v-btn class="btn-main" v-else  block success v-on:click="openCaptcha"><v-icon name="home"></v-icon> {{__('Send')}}</v-btn>-->
<!--          </div>-->
        <div style=" width: fit-content; margin: 20px auto 0">
          <BaseButton
                  width="120"
                  class_btn="btn-guest"
                  block
                  text
                  v-on:click="openCaptcha"
                  :loading="submitDisabled"
          >
          <span>
            {{__('Send')}}
          </span>
          </BaseButton>
        </div>

      </div>
  </div>
</template>


<script>
    import InputLabelTextDefault from "./InputLabelTextDefault";
    import {required, maxLength, minLength, sameAs} from "vuelidate/lib/validators";
    import BaseButton from "./BaseButton";

    export default {
        name: 'ModalAuthFormPassReset',
        data() {
            return {
                password: null,
                password_confirmation: null,
                submitDisabled: false,
                formValidate: {
                    password: null,
                    password_confirmation: null,
                },
                rules: this.validateMessage,
                dateUpdate: null
            };
        },
        props: {
            modal: {
                type: Object
            }
        },
        validations: {
            password: {
                required,
                maxLength: maxLength(50),
                minLength: minLength(3),
            },
            password_confirmation: {
                sameAsPassword: sameAs(function() {
                    if(this.password_confirmation.length) {
                        return (this.password_confirmation== this.password) ? this.password_confirmation : false;
                    } else {
                        return this.password_confirmation;
                    }
                }),
            },
        },
        methods: {
            openCaptcha() {
                let valid= this.$v;
                if(valid.$invalid) {
                    let massages= {
                        'authenticator': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.$emit('update:captcha', this.onSubmit);
                }
            },
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function (session, arrayData) {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'password': [],
                        'password_confirmation': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;

                    const response ={
                        email: this.modal.password_reset.email,
                        token: this.modal.password_reset.token,
                        password: this.password,
                        password_confirmation: this.password_confirmation,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http.post('ajax/password/update', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response)=> {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.$store.commit('updateModalAuth', 'login');
                                this.$notify({
                                    group: 'message',
                                    title: 'Password changed successfully'
                                });
                            }
                        }).catch(err => {
                            this.submitDisabled= false;
                            let response= this.httpHandler(err);
                            if(response.error) {
                                this.formValidate = response.error.message;

                            }
                        });
                }
            },
        },
        components: {
            InputLabelTextDefault,
            BaseButton
        }
    }
</script>