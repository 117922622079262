<template>
  <div :data-id="message.id">
    <p class="text-center date-mess" v-if="check_date">{{created_at}}</p>
    <MessengerMessagesListItem @editMessages="editMessages" v-bind:message="message" v-bind:user="user" v-bind:index="index" v-bind:messages_edit="options.messages_edit" v-bind:context_id="context_id" @openContextmenu="openContextmenu" @actionOptions="actionOptions"></MessengerMessagesListItem>
  </div>
</template>

<script>
    import MessengerMessagesListItem from "./MessengerMessagesListItem";
    import moment from "moment";

    export default {
        name: 'MessengerMessagesList',
        data () {
            return {
            }
        },
        props: {
            user: {
                type: Object
            },
            index: {
                type: Number
            },
            message: {
                type: Object
            },
            messages: {
                type: Object
            },
            date: {
                type: String
            },
            options: {
                type: Object
            },
            context_id: {
                type: Number
            },
        },
        computed: {
            check_date() {
                if(this.messages.data[this.index-1]) {
                    let prev_at= moment.utc(this.messages.data[this.index-1].created_at).format('DD.MM.YYYY');
                    let created_at= this.created_at;
                    return created_at!= prev_at;
                } else {
                    return true;
                }
            },
            created_at() {
                return  moment.utc(this.message.created_at).format('DD.MM.YYYY');
            }
        },
        asyncComputed: {

        },
        created() {
        },
        methods: {
            editMessages(id, val) {
                this.$emit('editMessages', id, val);
            },
            openContextmenu(index) {
                this.$emit('openContextmenu', index);
            },
            actionOptions(option) {
                this.$emit('actionOptions', option);
            }
        },
        components: {
            MessengerMessagesListItem
        },

    }
</script>
