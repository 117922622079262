<template>
  <div class="wrap-merchant">
    <Payment v-bind:payments="payments" v-bind:payment_id="transaction.payment_id" class="wrap-merchant__body">
      <template v-slot:default="payment">
        <div v-if="step == 1" class="d-flex justify-content-between">
        <span v-if="status== 1" class="block-success">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="3" cy="3" r="3" fill="#00C443"/>
            </svg>
            {{__('Success')}}
        </span>
          <span class="block-wait" v-else-if="status== 2 || status== 7">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#F6A401"/>
            </svg>
            {{__('Wait')}}
        </span>
          <span v-else-if="status== 3" class="block-cancel">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#FF4B55"/>
            </svg>
            {{__('Canceled')}}
        </span>
          <span v-else-if="status== 5" class="block-success">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="3" cy="3" r="3" fill="#00C443"/>
            </svg>
            {{__('Confirm')}}
        </span>
          <span v-else class="block-cancel">
            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#FF4B55"/>
            </svg>
            {{__('Error')}}
        </span>

          <span class="block-time">
          <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4913 1.75C5.66125 1.75 1.75 5.67 1.75 10.5C1.75 15.33 5.66125 19.25 10.4913 19.25C15.33 19.25 19.25 15.33 19.25 10.5C19.25 5.67 15.33 1.75 10.4913 1.75ZM10.5 17.5C6.6325 17.5 3.5 14.3675 3.5 10.5C3.5 6.6325 6.6325 3.5 10.5 3.5C14.3675 3.5 17.5 6.6325 17.5 10.5C17.5 14.3675 14.3675 17.5 10.5 17.5ZM10.3075 6.125H10.255C9.905 6.125 9.625 6.405 9.625 6.755V10.885C9.625 11.1912 9.7825 11.48 10.0538 11.6375L13.685 13.8162C13.9825 13.9913 14.3675 13.9038 14.5425 13.6062C14.5864 13.5354 14.6157 13.4564 14.6284 13.374C14.6412 13.2917 14.6373 13.2076 14.6169 13.1267C14.5965 13.0459 14.56 12.97 14.5096 12.9036C14.4593 12.8371 14.3961 12.7815 14.3238 12.74L10.9375 10.7275V6.755C10.9375 6.405 10.6575 6.125 10.3075 6.125Z" fill="#96AFD1"/>
          </svg>

          {{waitingTime}}
        </span>
        </div>

        <div v-if="step== 1">
          <div class="block-box">
            <div class="block-box__head">
              <div class="block-box__icon">
                <img src="/dist/images/frontend/QR-icon.svg" alt />
              </div>

              <div class="block-box__head-content">
                <h2 >{{__('Step 1')}}</h2>
                <div class="wrap-merchant__transfer">Transfer
                  <a v-on:click="$copyMessage(primNum(transaction.amount_unit, payment.sprintf))">{{primNum(transaction.amount_unit, payment.sprintf)}} {{payment.currency.toUpperCase()}}</a>
                  <!--              <span>({{primNum(((transaction.amount > 0) ? transaction.amount : transaction.amount_unit*payment.course_usd*((transaction.wallet== 1) ? 1 : 100)), 2)}} {{(transaction.wallet== 1) ? 'RC' : 'LC'}})</span>-->
                  <!--              {{__('to the address')}}-->
                </div>
              </div>
            </div>

            <div class="block-box__body">
              <div class="wrap-merchant__qrcode">
                <VueQrcode content-class="qr" :value="((payment.currency== 'btc') ? 'bitcoin:'+transaction.address+'?amount='+transaction.amount_unit : transaction.address)" :options="{  margin: 1 }"></VueQrcode>
              </div>

              <div class="wrap-merchant__address">
                <InputLabelTextDefault :label="payment.name + ' address'" color="main" readonly :value="transaction.address" copy/>
              </div>
              <div class="wrap-merchant__desc">
                <div class="block-desc-txt">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                      <path d="M17.7279 14.6681L10.3265 1.92834C9.9862 1.3541 9.49703 1.0138 8.98659 1.0138C8.45488 1.0138 7.96571 1.3541 7.64668 1.92834L0.287829 14.6681C-0.0524651 15.2423 -0.0950018 15.8378 0.181487 16.2845C0.436707 16.7311 0.968416 16.9863 1.649 16.9863H16.3667C17.026 16.9863 17.5577 16.7311 17.8342 16.2845C18.0894 15.8378 18.0469 15.2423 17.7279 14.6681ZM17.026 15.8378C16.9197 16.008 16.6857 16.0931 16.3454 16.0931H1.62774C1.28744 16.0931 1.03222 15.9867 0.947148 15.8378C0.840806 15.6677 0.883343 15.4125 1.05349 15.1147L8.41234 2.37498C8.58249 2.07722 8.79517 1.90707 8.98659 1.90707C9.178 1.90707 9.39069 2.07722 9.56083 2.37498L16.9197 15.1147C17.0898 15.4125 17.1324 15.6677 17.026 15.8378Z" fill="#FF4B55"/>
                      <path d="M8.98667 5.20363C8.73145 5.20363 8.54004 5.39504 8.54004 5.65026V11.5629C8.54004 11.8181 8.73145 12.0095 8.98667 12.0095C9.24189 12.0095 9.43331 11.8181 9.43331 11.5629V5.65026C9.43331 5.39504 9.24189 5.20363 8.98667 5.20363Z" fill="#FF4B55"/>
                      <path d="M8.98663 14.583C9.42124 14.583 9.77355 14.2306 9.77355 13.796C9.77355 13.3614 9.42124 13.0091 8.98663 13.0091C8.55202 13.0091 8.19971 13.3614 8.19971 13.796C8.19971 14.2306 8.55202 14.583 8.98663 14.583Z" fill="#FF4B55"/>
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="18" height="18" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>

                  <p>{{__('Attention!')}}</p>
                </div>
                <p class="block-desc-info">{{__('Transfer exactly the same amount as indicated in your invoice. Otherwise, your deposit will not be credited')}}</p>
              </div>
            </div>
          </div>

          <div class="block-box">
            <div class="block-box__head">
              <div class="block-box__icon">
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.6389 12.3957H16.5906C15.1042 12.3948 13.8993 11.1909 13.8984 9.70446C13.8984 8.21801 15.1042 7.01409 16.5906 7.01318H20.6389" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.0485 9.64294H16.7369" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.74766 1H15.3911C18.2892 1 20.6388 3.34951 20.6388 6.24766V13.4247C20.6388 16.3229 18.2892 18.6724 15.3911 18.6724H6.74766C3.84951 18.6724 1.5 16.3229 1.5 13.4247V6.24766C1.5 3.34951 3.84951 1 6.74766 1Z" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M6.03564 5.5382H11.4346" stroke="#26A9F4" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>

              <div class="block-box__head-content">
                <h2 >{{__('Step 2')}}</h2>
                <div class="wrap-merchant__transfer">
                  <p>
                    {{__('after making the transfer of funds, click on the button')}} <span>“{{__('I have paid')}}”</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="wrap-btn">
            <BaseButton
                    width="115"
                    class_btn="btn-liteGrey mr-3"
                    v-on:click="$emit('cancelTransactions', transaction)"
                    :loading="submitDisabled">
              <span>{{__('Cancel')}}</span>
            </BaseButton>
            <BaseButton
                    width="115"
                    v-on:click="paidPayment"
                    :loading="submitDisabled">
              <span>{{__('I have paid')}}</span>
            </BaseButton>
          </div>
        </div>
        <div v-else class="wrap-step-three">
          <div class="wrap-step-three__head">
            <div class="d-flex flex-column align-center" v-if="step == 2">
              <img src="/dist/images/frontend/waiting-payment.svg" alt />
              <p class="text">{{__('Your payment is waiting...')}}</p>
            </div>
            <div class="d-flex flex-column align-center" v-else>
              <img src="/dist/images/frontend/success-payment.svg" alt="">
              <p class="text">{{__('Funds have been successfully credited to your account')}}</p>
            </div>
          </div>

          <div class="wrap-step-three__body">
            <h5>{{__('Check the correctness of the data and confirm the withdrawal request')}}</h5>
            <div class="block-row"><span>{{__('Deposit request:')}}</span> #{{transaction.id}}</div>
            <div class="block-row"><span>{{__('Date of creation:')}}</span> {{transaction.date | formatFullDate}}</div>
            <div class="block-row"><span>{{__('Amount:')}}</span>
              <p v-if="step== 2">{{primNum(transaction.amount_unit, payment.sprintf)}} {{payment.currency.toUpperCase()}}</p>
              <p v-else>{{primNum(deposit_info.amount, payment.sprintf)}} {{__((transaction.wallet== 1) ? 'Gold coin' : 'Silver coin')}}</p>
            </div>
            <div class="block-row"><span>{{__('Address:')}}</span> <p class="text-address">{{transaction.address}}</p></div>
          </div>

<!--          <div class="wrap-merchant__address">-->
<!--            <InputLabelTextDefault :label="payment.name + ' address'" color="main" readonly :value="transaction.address" copy/>-->
<!--          </div>-->
          <div class="wrap-btn">
            <BaseButton v-on:click="onCancel">
              <span>{{__('Back to wallet')}}</span>
            </BaseButton>
          </div>
        </div>
      </template>
    </Payment>
  </div>
</template>

<script>
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    // import Switcher from "../../modules/Switcher";
    import Payment from "../../modules/Payment";
    import moment from 'moment';


    export default {
        name: 'Merchant',
        data () {
            return {
                waitingTime: '-',
                status:  this.transaction.status,
                submitDisabled: false,
                step: this.transaction.step,
                deposit_info: 0,
            }
        },
        props: {
            payments: {
                type: Array
            },
            transaction: {
                type: Object
            },
            launchpad: {
                type: Boolean
            }
        },
        computed: {

        },
        watch:{
            step(val) {
                if(val== 2) {
                    this.startInterval();
                } else {
                    clearInterval(this.intervalCheck);
                }
            }
        },
        asyncComputed: {

        },
        created() {
            this.startInterval();

            let timer= this.timer();
            if(timer.timestamp > 0) {
                this.waitingTime = timer.time;
                let interval = setInterval(() => {
                    let timer = this.timer();
                    if(this.waitingTime=== null) {
                        clearInterval(interval);
                    } else if (timer.timestamp > 0) {
                        this.waitingTime = timer.time;
                    } else {
                        this.waitingTime = 'Time is over';
                        clearInterval(interval);
                    }
                }, 1000);
            } else {
                this.waitingTime = 'Time is over';
            }
        },
        methods: {
            startInterval() {
                this.intervalCheck= setInterval( ()=> {
                    this.checkPayment();
                }, 60000);
            },
            onCancel() {
                this.$emit('update:transaction', null);
            },
            onSubmit() {
            },
            paidPayment() {
                this.submitDisabled = true;

                this.$http.get('ajax/deposit/paid/' + this.transaction.id)
                    .then(response => {
                        return response.json()
                    })
                    .then(() => {
                        console.log('adssaddsa');
                        this.submitDisabled= false;
                        this.step= 2;
                    }).catch(err => {
                    this.submitDisabled= false;
                    this.httpHandler(err);
                });
            },
            checkPayment() {
                this.submitDisabled= true;

                this.$http.get('ajax/deposit/check/'+this.transaction.id)
                    .then(response=> {
                        return response.json()
                    })
                    .then((response)=> {
                        response= response.data;

                        this.submitDisabled= false;
                        let message= response.message;
                        // if(message== 'wait') {
                        //     Vue.notify({
                        //         group: 'message',
                        //         title: this.__('We not see your transaction'),
                        //         type: 'warn'
                        //     });
                        // } else if(message== 'success') {
                        //     Vue.notify({
                        //         group: 'message',
                        //         title: this.__('Transaction success'),
                        //         type: 'success'
                        //     });
                        //     this.$emit('update:deposit', null);
                        // } else if(message== 'confirmation') {
                        //     Vue.notify({
                        //         group: 'message',
                        //         title: this.__('We see your transaction, after confirming the network, the funds will be transferred to your account'),
                        //         type: 'warn'
                        //     });
                        // } else {
                        //     Vue.notify({
                        //         group: 'message',
                        //         title: this.__('Transaction error'),
                        //         type: 'error'
                        //     });
                        // }
                        //
                        // if(response.data.history) {
                        //     this.$emit('updateDeposit', response.data);
                        // }
                        this.status= message;
                        if(message== 1) {
                            this.step = 3;
                            this.deposit_info= response.deposit_info;
                            this.waitingTime= null;

                            this.$emit('statusMerchant', response);
                        }
                        //
                        // if(response.user_info) {
                        //     let portfolio = response.user_info;
                        //     // if(portfolio.lang) {
                        //     //     this.$store.dispatch("asyncLanguage", portfolio.lang);
                        //     // }
                        //     this.$store.dispatch('asyncPortfolio', portfolio);
                        //     this.$store.dispatch('asyncPayments', portfolio.payment);
                        // }

                    }).catch(err => {
                    this.submitDisabled= false;
                    this.httpHandler(err);
                });
            },
            timer() {
                let time = moment.utc().format('X');
                let timeCreated = moment.utc(this.transaction.time).format('X');
                let timestamp = ((timeCreated - time)).toFixed(0);
                return {
                    time: this.$toTime(timestamp),
                    timestamp: timestamp
                }
            },
        },

        components: {
            InputLabelTextDefault,
            VueQrcode,
            Payment,
            // Switcher
        },

    }
</script>

<style lang="scss">

  .dark {
    .wrap-merchant {
      &__body {
        background: #171827;

        .block-box {
          &__icon {
            background: rgba(38, 169, 244, 0.15);
          }

          &__head-content {
            p {
              color: #96AFD1;
            }
          }
        }

        .block-time {
          color: #fff;
        }
      }

      &__transfer {
        color: #96AFD1;

        span {
          color: #fff!important;
        }

        a {
          color: #fff!important;
        }
      }

      &__desc {
        .block-desc-info {
          color: #96AFD1;
        }
      }
    }
  }

  .wrap-merchant {
    background-color: #FFFFFF;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    &__head {
      display: flex;
      align-items: center;
      padding: 20px;
      border-bottom: 2px solid #ecf4ff;

      svg {
        margin-right: 18px;
      }


      p {
        font-size: 16px;
        line-height: 22px;
        color: #002B50;
        margin: 0!important;
      }
    }

    &__body {
      padding: 20px;

      .block-success {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 3px 15px 3px 10px;
        background: #CDFFCD;
        border-radius: 10px;
        color: #00C443;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px;

        svg {
          margin-right: 5px;
        }
      }

      .block-wait {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 3px 15px 3px 10px;
        background: rgba(246, 164, 1, 0.1);
        border-radius: 10px;
        color: #F6A401;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        height: 22px;
        margin-bottom: 20px;

        svg {
          margin-right: 5px;
        }
      }

      .block-cancel {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 3px 15px 3px 10px;
        background: rgba(255, 75, 85, 0.1);
        border-radius: 10px;
        color: #FF4B55;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 20px;

        svg {
          margin-right: 5px;
        }
      }

      .block-time {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: fit-content;

        svg {
          margin-right: 4px;
        }
      }


      .block-box {
        margin-bottom: 25px;

        &__head {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
        }

        &__icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 46px;
          height: 46px;
          background: #FFFFFF;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          margin-right: 21px;
        }

        &__head-content {

          h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #26A9F4;
            margin-bottom: 2px;
          }

          p {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #6886AF;
            margin-bottom: 0;
          }
        }

        &__body {
          margin-left: 67px;
        }
      }
    }

    &__qrcode {
      margin-bottom: 20px;
      filter: drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.1));
    }

    &__transfer {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #6886AF;
      display: flex;
      /*align-items: center;*/

      span {
        color: #002B50!important;
        margin: 0 0 0 4px;
        font-weight: 600;
      }
      a {
        display: block;
        color: #002B50!important;
        margin: 0 0 0 4px;
        font-weight: 600;
      }

      span {
        margin-right: 5px;
      }
    }

    &__address {
      margin-bottom: 20px;
      width: 85%;
    }

    &__desc {
      display: flex;

      .block-desc-txt {
        display: flex;
        align-items: center;
        margin-right: 12px;

        svg {
          margin-right: 9px;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #FF4B55;
          margin: 0;
        }
      }

      .block-desc-info {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #6886AF;
        margin: 0;
      }
    }

    .wrap-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .dark {
    .wrap-step-three {
      &__head {
        border-bottom: 1px solid rgba(240, 246, 254, 0.1);
        p {
          color: #fff;
        }
      }

      &__body {
        h5 {
          color: #fff;
        }

        .block-row {
          color: #fff;
          span {
            color: #96AFD1;
          }

          p {
            color: #fff;
          }
        }
      }
    }
  }

  .wrap-step-three {
    padding: 20px 0 5px;

    &__head {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #F0F6FE;

      img {
        margin-bottom: 20px;
      }

      p {
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #002B50;
        margin: 0;
      }
    }

    &__body {
      margin-bottom: 25px;
      h5 {
        font-size: 16px;
        line-height: 20px;
        color: #002B50;
        margin-bottom: 20px;
      }

      .block-row {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
        cursor: default;
        color: #002b50;

        span {
          display: block;
          width: 28%;
          font-size: 14px;
          line-height: 20px;
          color: #6886AF;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          color: #002B50;
          margin: 0;
        }

        .text-address {
          color: #26A9F4;
        }
      }
    }
  }

@media screen and (max-width: 600px){
  .wrap-merchant {
    &__body {
      padding: 15px;

      .block-box {
        &__icon {
          display: none;
        }
        &__body {
          margin-left: 0;
        }
      }
    }
  }

  .wrap-step-three {
    &__body {
      .block-row {
        span {
          width: 40%;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .wrap-merchant {
    &__body {
      .block-success,
      .block-wait,
      .block-cancel {
        margin-bottom: 12px;
      }
      .block-box {
        margin-bottom: 15px;

        &__head {
          margin-bottom: 12px;
        }
        &__head-content {
          h2 {
            font-size: 15px;
            line-height: 20px;
            margin: 0 0 2px;
          }
        }
      }
    }
    &__transfer {
      font-size: 12px;
      line-height: 16px;


      span {
        white-space: nowrap;
      }
    }

    &__qrcode {
      margin-bottom: 8px;
    }

    &__address {
      width: 100%;
      margin-bottom: 15px;
    }

    &__desc {
      .block-desc-txt {
        p {
          font-size: 12px;
          line-height: 16px;
        }

        svg {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      .block-desc-info {
        font-size: 12px;
      }
    }
  }

  .wrap-step-three {

    &__head {

      img {
        height: 60px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    &__body {

      h5 {
        font-size: 14px;
        line-height: 18px;
      }

      .block-row {
        font-size: 12px;
        line-height: 18px;
        span {
          width: 35%;
          font-size: 12px;
          line-height: 18px;
        }

        p {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

</style>
