<template>
    <div>
        <div class="overlay" ref="overlay">
            <div class="bg" v-on:click="navToggle"></div>
            <nav class="overlayMenu">
                <div class="logo" v-on:click="navToggle">
                    <router-link :to="{name: 'home', params: {lang: $store.getters.getLang}}" class="logo-img">
                        <img src="/dist/images/frontend/LogoMP.svg" alt="mindplays.com" />
                    </router-link>
<!--                    <router-link :to="{name: 'home', params: {lang: $store.getters.getLang}}" class="logo-text">-->
<!--                        <span class="logo-name">MINDPLAYS</span>-->
<!--                        <span class="logo-subtitle">Gaming platform</span>-->
<!--                    </router-link>-->
                </div>

                <div  class="st-nav-section st-nav-primary nav-item">
<!--                    <v-btn-->
<!--                        v-for="(item, key) in sidebar_nav"-->
<!--                        :key="key"-->
<!--                        v-on:click="navToggle"-->
<!--                        class="btn-link"-->
<!--                        text-->
<!--                        v-ripple="false"-->
<!--                        :to="{name: item.url, params: {lang: $store.getters.getLang}}"-->
<!--                        :exact="((item.exact) ? item.exact : false)"-->
<!--                    >-->
                    <v-btn
                        v-for="(item, key) in sidebar_nav"
                        :key="key"
                        v-on:click="navToggle"
                        class="btn-link"
                        text
                        v-ripple="false"
                        :to="{name: item.url, params: {lang: $store.getters.getLang, id: ((item.url== 'home' && auth) ? auth.public_id : null)}}"
                        :exact="((item.exact) ? item.exact : false)"
                    >
                        <div class="img-sidebar">
                            <img class="icon-sidebar" :src="'/dist/images/frontend/'+item.icon" />
                        </div>
                        {{__(item.name)}}
                        <div class="count-friends" v-if="friends_req_count && item.url== 'friends'">
                            <p>{{friends_req_count}}</p>
                        </div>
                        <div class="count-friends" v-if="auth.tournament && item.url== 'tournaments'">
                            <p>{{auth.tournament}}</p>
                        </div>
                    </v-btn>
                </div>
                <div class="block-lang">
                    <DropdownLanguagesMobile/>
                </div>

<!--                <img class="img-bottom" src="/dist/images/frontend/mob_menu-bottom.png" alt="/" />-->
            </nav>
        </div>
        <div class="navBurger" role="navigation" ref="navToggle" v-on:click="navToggle"></div>
    </div>
</template>

<script>
    import DropdownLanguagesMobile from "./DropdownLanguagesMobile";
    export default {
        name: 'NavMobile',
        data: function() {
            return {

            }
        },
        props: {
            sidebar_nav: {
                type: Array,
            },
        },
        methods: {
            navToggle: function () {
                this.$refs.navToggle.classList.toggle('active');
                this.$refs.overlay.classList.toggle('open');
            }

        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
            message_count() {
                return this.$store.getters.message_count;
            },
            friends_req_count() {
                return this.$store.state.auth.friends_req_count;
            },
        },
        components: {
            DropdownLanguagesMobile
        }
    }
</script>
<style scoped lang="scss">

    .dark {
        .overlayMenu {
            background-color: #000;

            .v-btn.btn-link {
                color: #fff!important;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                position: relative;

                &:after {
                    content: '';
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 16px;
                    background-image: url("/dist/images/frontend/mob-menu-shadow.svg");
                    background-position: center;
                    background-size: cover;
                }

                &:hover {
                    background: #171827;
                    /*box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.1);*/
                    border-radius: 0;

                    &:after {
                        display: block;
                    }
                }
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 48px;
            }

        }
    }
    .bg{
         position: absolute;
         width: 100%;
         height: 100vh;
         opacity: 0.46;
         background-color: rgb(33, 33, 33);
         top: 0;
         left: 0;
     }
    .navBurger {
        display: none;
        position: absolute;
        left: 20px;
        top: 18px;
        cursor: pointer;
        background: #fff;
        box-sizing: border-box;
        background-clip: content-box;
        width: 20px;
        padding-left: 0px;
        padding-right: 0px;
        height: 15px;
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
        z-index: 302;
    }
    .navBurger:before, .navBurger:after {
        content: "";
        position: absolute;
        background: #fff;
        height: 2px;
        width: 20px;
        left: 0px;
        will-change: rotate;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .navBurger:before {
        top: -6px;
    }
    .navBurger:after {
        bottom: -6px;
    }
    .navBurger.active {
        margin-top: 4px;
        left: 20px;
        position: fixed;
        background: transparent;
        background-clip: content-box;
    }
    .navBurger.active:before {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 0px;
        background: #fff;
        width: 100%;
    }
    .navBurger.active:after {
        bottom: 0px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #fff;
        width: 100%;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        opacity: 0;
        transform-origin: left;
        transform: scale(0);
        visibility: hidden;
        transition: all 0.1s ease-in-out;
        z-index: 111;
        width: 100%;
        height: 100vh;
    }
    .overlay.open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
    /*.overlay .logo{*/
    /*    top: 12px;*/
    /*}*/
    .overlayMenu{
        /*background: #F1F7FF;*/
        background: #283550;
        padding-top: 11px;
        width: 270px;
        height: 100vh;
        min-height: 100%;
        position: relative;

        .logo {
            display: flex;
            align-items: center;
            margin: 0 0 20px 60px;
        }

        .logo-img {
            margin-right: 9px;
            /*img {*/
            /*    height: 38px;*/
            /*}*/
        }

        .logo-text {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .logo-name {
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;
                color: #FFFFFF;
            }

            .logo-subtitle {
                font-size: 10.5px;
                line-height: 12px;
                color: #00AEFF;
                white-space: nowrap;
            }
        }

        .count-friends {
            flex-shrink: 0;
            background: #026EED;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 6px;
            margin-top: 1px;

            p {
                text-align: center;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                margin: 0 1px 0 0;
                letter-spacing: normal;
            }
        }

        .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
            padding: 0 16px 0 20px;
        }

        .img-bottom {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .overlayMenu .nav-item{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
        /*margin-left: 44px;*/
        /*padding-left: 6px;*/

        img {
            width: 20px;
            margin-right: 18px;
        }
    }

    .overlayMenu .block-lang {
        /*margin-left: 22px;*/
    }





    @media only screen and (max-width : 1300px){
        .navBurger{
            display: block;
            margin-top: 5px;
        }
    }

    @media screen and (max-width: 425px) {

        .navBurger,
        .navBurger.active {
            left: 10px;
        }


        .overlay {
            z-index: 300;
        }
        .overlayMenu {
            /*width: 100%;*/

            /*.v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {*/
            /*    padding: 0 16px 0 60px;*/
            /*}*/

            /*.logo {*/
            /*    margin: 0 0 20px 60px;*/
            /*}*/
        }
    }

</style>
