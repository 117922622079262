<template>
    <div>
        <div
            class="overlay"
            ref="overlay"
        >
            <div class="bg" v-on:click="navToggle">
            </div>
            <nav class="overlayMenu">
                <router-link :to="{name: 'home', params: {lang: $store.getters.getLang}}" class="logo">
                    <img src="/dist/images/frontend/LogoMP.svg" alt="mindplays.com" />
                </router-link>
                <div  class="st-nav-section st-nav-primary nav-item">
                    <v-btn
                           text
                           v-on:click="navToggle"
                           class="btn-link"
                           v-for="(item, key) in nav"
                           :key="key"
                           :to="{name: item.url, params: {lang: $store.getters.getLang}}"
                           :exact="((item.exact) ? item.exact : false)"
                           @click="ListenScroll"
                    >
                        <span>{{__(item.name)}}</span>
                    </v-btn>
                    <img class="img-bottom" src="/dist/images/frontend/mob_menu-bottom.png" alt="/" />
                </div>
            </nav>
        </div>
        <div class="navBurger" role="navigation" ref="navToggle" v-on:click="navToggle"></div>
    </div>
</template>

<script>
    export default {
        name: 'NavMobile',
        data: function() {
            return {
                nav: [
                    {
                        name: "Home",
                        subtitle: "about us",
                        icon: "icon-home.svg",
                        url: "home",
                        exact: true,
                    },
                    // {
                    //     name: "Airdrop",
                    //     subtitle: "",
                    //     url: "airdrop",
                    // },
                    // {
                    //   name: "About",
                    //   icon: "icon-about.svg",
                    //   url: "about",
                    // },
                    {
                        name: "Games",
                        subtitle: "only online",
                        icon: "icon-about.svg",
                        url: "games",
                    },
                    {
                        name: "Championship",
                        subtitle: "only online",
                        icon: "nav-icon-tournaments-new.svg",
                        url: "tournaments",
                    },
                    {
                        name: "Affiliate",
                        subtitle: "money flow",
                        icon: "icon-affiliate.svg",
                        url: "main_affiliate",
                    },
                    {
                        name: "Blog",
                        subtitle: "helpful",
                        icon: "icon-blog.svg",
                        url: "blogs",
                    },
                    // {
                    //   name: "To Developers",
                    //   subtitle: "api documentation",
                    //   icon: "icon-developers.svg",
                    //   url: "api",
                    // },
                    {
                        name: "FAQ",
                        subtitle: "ask to us",
                        icon: "icon-contacts.svg",
                        url: "faq",
                    },
                    {
                        name: "Contacts",
                        subtitle: "write to us",
                        icon: "icon-contacts.svg",
                        url: "contact",
                    },
                ],
            }
        },
        props: {
            sidebar_nav: {
            },
            nav_sup: {
            }
        },
        methods: {
            navToggle: function () {
                this.$refs.navToggle.classList.toggle('active');
                this.$refs.overlay.classList.toggle('open');
            },

            ListenScroll() {
                this.$emit('ListenScroll');
            }

        },
        computed: {

        },
    }
</script>
<style scoped lang="scss">
    .dark {
        .overlayMenu {
            background-color: #000;

            .v-btn.btn-link {
                color: #fff!important;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                position: relative;
                z-index: +1;

                &:after {
                    content: '';
                    display: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 16px;
                    background-image: url("/dist/images/frontend/mob-menu-shadow.svg");
                    background-position: center;
                    background-size: cover;
                }

                &:hover {
                    background: #202020;
                    /*box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4), inset 0px 1px 1px rgba(255, 255, 255, 0.1);*/
                    border-radius: 0;

                    &:after {
                        display: block;
                    }
                }
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 44px;
            }
            .img-bottom {
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }

    .bg{
       position: absolute;
        width: 100%;
        height: 100vh;
        opacity: 0.46;
        background-color: rgb(33, 33, 33);
        top: 0;
        left: 0;
    }
    .navBurger {
        display: none;
        position: absolute;
        left: 22px;
        top: 22px;
        cursor: pointer;
        background: #fff;
        box-sizing: border-box;
        background-clip: content-box;
        width: 20px;
        padding-left: 0px;
        padding-right: 0px;
        height: 15px;
        border-top: 6px solid transparent;
        border-bottom: 7px solid transparent;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        /*-webkit-transition: all 0.25s ease-in-out;*/
        /*transition: all 0.25s ease-in-out;*/
        z-index: 111;
    }
    .navBurger:before, .navBurger:after {
        content: "";
        position: absolute;
        background: #fff;
        height: 2px;
        width: 20px;
        left: 0px;
        will-change: rotate;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .navBurger:before {
        top: -6px;
    }
    .navBurger:after {
        bottom: -6px;
    }
    .navBurger.active {
        margin-top: 20px;
        left: 22px;
        position: fixed;
        background: transparent;
        background-clip: content-box;
    }
    .navBurger.active:before {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        top: 0px;
        background: #fff;
        width: 100%;
    }
    .navBurger.active:after {
        bottom: 0px;
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: #fff;
        width: 100%;
    }
    .overlay {
        position: fixed;
        top: 0px;
        left: 0;
        opacity: 0;
        transform-origin: left;
        transform: scale(0);
        visibility: hidden;
        transition: all 0.1s ease-in-out;
        z-index: 111;
        width: 100%;
        height: 100vh;
    }
    .overlay.open {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
    .overlay .logo{
        top: -6px;
        margin: 0 0 20px 18px;
        position: relative;
    }
    .overlayMenu{
        background-color: #000;
        padding-top: 35px;
        width: 270px;
        height: 100vh;
        position: relative;

        .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
            padding: 0 16px 0 22px!important;
        }
    }
    .overlayMenu .nav-item{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
        margin-top: 10px;
    }

    .dark {
        .navBurger.active:before,
        .navBurger.active:after {
            background: #fff;
        }
    }





    @media only screen and (max-width : 1150px){
        .navBurger{
          display: block;
          margin-top: 3px;
        }
    }

    /*@media only screen and (max-width : 500px){*/
    /*    .overlayMenu {*/
    /*        width: 100%;*/
    /*    }*/
    /*}*/

    @media screen and (max-width: 425px) {

        .overlayMenu {
            /*width: 100%;*/

            .nav-item {
                margin-top: 0;
            }
        }

        .overlay .logo {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            margin-left: 65px;
            margin-bottom: 15px;
        }
    }

</style>
