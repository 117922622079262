import Vue from 'vue';
import Vuex from 'vuex';

import language from './modules/language';
import auth from './modules/auth';
import games from './modules/games';
import preference from './modules/preference';
import chat from './modules/chat';
import general_chat from "./modules/general_chat";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        language,
        auth,
        games,
        preference,
        chat,
        general_chat,
    },
    strict: debug,
});
