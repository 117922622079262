<template>
    <div class="page-auth">
        <div v-if="modal" class="content-auth">
            <img src="/dist/images/frontend/LogoMP.svg" class="page-auth__logo" alt="mindpalys">

            <h1 class="page-auth__title" style="margin-bottom: 20px">{{__('Password reset')}}</h1>

            <p class="page-auth__subtitle " style="margin-bottom: 20px">{{__('To reset your password, enter the email address you use to move to the MindPlays cabinet')}}</p>

            <h1 v-if="modal.security['2fa']" class="page-auth__title" style="margin-bottom: 20px">2FA</h1>
            <h1 v-else-if="modal.security['email']" class="page-auth__title" style="margin-bottom: 20px">{{__('Email')}}</h1>
            <h1 v-else-if="modal.security['email_verification']" class="page-auth__title" style="margin-bottom: 20px">{{__('Email verification')}}</h1>

            <p v-if="modal.security['2fa']" class="page-auth__subtitle">{{__('Additional security from intruders is enabled on your account. Enter below the one-time code from the application')}}</p>
            <p v-else-if="modal.security['email']" class="page-auth__subtitle">{{__('Additional security from intruders is enabled on your account. Enter below one-time code with email')}}</p>
            <p v-else-if="modal.security['email_verification']" class="page-auth__subtitle">{{__('You have not passed verification. Enter below one-time code with email')}}</p>

            <div class="block-input">
                <InputLabelTextDefault
                    color="auth"
                    :label="modal.security['2fa'] ? 'OTP Code' : 'Email Code'"
                    v-model="authenticator"
                    v-bind:errors="formValidate.authenticator"
                    @change="updateValidate('authenticator')">
                </InputLabelTextDefault>
            </div>

            <div class="block-control">
                <BaseButton
                    style="width: 100%"
                    class_btn="btn-guest"
                    v-on:click="onSubmit"
                    :loading="submitDisabled"
                >

                    <span>
                        <img src="/dist/images/frontend/sign.svg" alt />
                        {{__('Confirm')}}
                    </span>
                </BaseButton>
            </div>

            <div class="block-bottom">
                <p v-if="modal.security.email && dateUpdate === false" class="block-bottom__text">loading...</p>
                <a v-else-if="modal.security.email && !dateUpdate" class="block-bottom__link" @click="sendCode">{{__(((send) ? 'Resend' : 'Send')+'  code')}}</a>
                <p v-else-if="dateUpdate" class="block-bottom__text">{{__('Next resend: '+dateUpdate)}}</p>
            </div>
        </div>
    </div>
</template>


<script>
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, maxLength, minLength, integer} from "vuelidate/lib/validators";

    export default {
        name: 'AuthSecurity',
        data() {
            return {
                submitDisabled: false,
                authenticator: null,
                send: false,
                formValidate: {
                    authenticator: null,
                },
                rules: this.validateMessage,
                dateUpdate: null,
                captcha: false,
            };
        },
        props: {

        },
        validations: {
            authenticator: {
                required,
                maxLength: maxLength(8),
                minLength: minLength(4),
                integer
            },
        },
        computed: {
            modal() {
                return this.$store.state.preference.auth;
            },
        },
        created() {
            if (this.modal) {
                this.send = (this.modal.security['email'] && !this.modal.security['2fa'] && !this.modal.security['email_verification']) ? true : false;
            }
        },
        methods: {
            sendCode: function() {
                this.dateUpdate= false;
                this.$http.get('ajax/resend_code')
                    .then(response=> {
                        return response.json()
                    })
                    .then(response=> {
                        this.$checkAuth(response);
                        this.dateUpdate= response.data.dateUpdate;
                        let refreshIntervalId = setInterval(()=> {
                            if(this.dateUpdate > 0) {
                                this.dateUpdate= this.dateUpdate-1;
                            } else {
                                clearInterval(refreshIntervalId);
                            }
                        }, 1000);
                        this.send= true;
                    }).catch(err => {
                    let response= this.httpHandler(err);
                    if(response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            },
            updateValidate: function(type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v[type], type);
            },
            onSubmit: function (session, arrayData) {
                let valid= this.$v;

                if(valid.$invalid) {
                    let massages= {
                        'authenticator': [],
                    };
                    this.formValidate= this.$formValidate(massages, valid);
                } else {
                    this.submitDisabled= true;
                    const response ={
                        authenticator: this.authenticator,
                        challenge: session,
                    };
                    response[session]= arrayData;

                    this.$http.post('ajax/authenticator', response)
                        .then(response=> {
                            return response.json()
                        })
                        .then(()=> {
                            this.$router.push({
                                name: "games",
                                lang:  this.$store.getters.getLang,
                            });
                            location.reload();
                        }).catch(err => {
                        this.submitDisabled= false;
                        let response= this.httpHandler(err);
                        if(response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }
            },
        },
        components: {
            InputLabelTextDefault,
        }
    }
</script>

<style lang="scss" scoped>

</style>