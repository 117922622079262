<template>
  <div class="reviews-item" :class="class_review">
    <v-list color="transparent" class="pa-0">
      <v-list-item class="pa-0">
        <div class="block-img">
          <router-link :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}" >
            <div class="wrap-img">
<!--          <img :src="'/assets/users/'+((matches.user.photo) ? matches.user.photo : 'a-default.svg')"/>-->

              <img v-if="item.user.photo && typeof item.user.photo == 'object'" :src="item.user.photo[40]"/>
              <img v-else-if="item.user.photo" :src="item.user.photo"/>
              <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
            </div>
            <div v-if="now_date < date" class="online"></div>
          </router-link>
        </div>
        <v-list-item-content class="person-info pa-0">
          <v-list-item-title>
            <router-link :to="{name: 'profile', params: {id: item.user.public_id, lang: $store.getters.getLang}}" >
              {{item.user.name+" "+((item.user.last_name) ? item.user.last_name : '')}}
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle>
<!--            <img height="12px" src="/dist/images/frontend/fire.svg" alt />-->
<!--            <span style="color: #F6A401" class="mr-2">{{item.user.rating_points}}</span>-->
<!--            <span style="color: #96AFD1">-->
<!--              <img width="14" :src="item.user.location_img" />-->
<!--              {{$getLocation(item.user.location)}}-->
<!--            </span>-->
              <div class="d-flex rating align-center">
                  <v-rating
                          half-increments
                          v-model="item.rate"
                          background-color="white darken-1"
                          color="#FFBA49"
                          dense
                          readonly
                          size="15"
                          class="mr-2"
                          full-icon="star"
                          empty-icon="star_outline"
                          half-icon="star_half"
                  ></v-rating>
                  <span>{{item.rate}}</span>
              </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

     <div class="reviews-item__data">{{item.created_at | moment('DD.MM.YYYY')}}</div>

    </v-list>

    <div class="text-reviews" v-if="item.text">
      {{item.text}}
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: 'ReviewsItem',
  data() {
    return {
      now_date: moment.utc(new Date()).format('X'),
      date: (this.item.user && this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
    }
  },
  props: {
    item: {
      type: Object,
    },
      class_review: {
          type: String,
      },
  }
}
</script>

<style lang="scss" scoped>

.dark {
    .reviews-item {
        .v-list-item {
            &__title {
                a {
                    color: #fff;
                }
            }

            &__subtitle {
                .rating {
                    span {
                        color: #96AFD1;
                    }
                }
            }
        }

        &__data {
            color: #96AFD1;
        }

        .text-reviews {
            color: rgba(255, 255, 255, 0.8);
        }
    }
}

.reviews-item {
  padding: 10px 20px;
  /*margin-bottom: 5px;*/

  &__data {
    font-size: 12px;
    line-height: 25px;
    color: #6886AF;
  }


  .v-list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .v-list-item {

    .block-img {
      margin-right: 10px;
      position: relative;

      a {
        text-decoration: none;
      }

      .wrap-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      .photo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &__content {
      display: block;
    }

    &__title {
      font-weight: bold;
      font-size: 13px;
      line-height: 25px;
      color: #6B7A99;
      margin-bottom: -3px;
      max-width: 170px;
      min-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        text-decoration: none;
        color: #002B50;

        &:hover {
          text-decoration: underline;
          color: #4EBEFF;
        }
      }
    }

    &__subtitle {
      font-size: 13px;
      line-height: 16px;
      max-width: 170px;
      min-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .rating {
          margin-top: -4px;
          display: flex;
          align-items: center;

          span {
              margin-top: 2px;
              font-weight: 800;
              font-size: 13px;
              line-height: 25px;
              color: #6B7A99;
          }
      }

    }
  }
  .text-reviews {
      margin-top: 2px;
      font-size: 13px;
      line-height: 25px;
      color: #7D8FB3;
  }
}

//ON GAME PAGE

.review-on-page {
    background: #000000;
    backdrop-filter: blur(20px);
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;

    .reviews-item__data {
        display: none;
    }


    .v-list {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .v-list-item {

        .block-img {
            margin-right: 10px;
            position: relative;

            a {
                text-decoration: none;
            }

            .wrap-img {
                width: 40px;
                height: 40px;
                border-radius: 50%;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }

            .photo {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        &__content {
            display: block;
        }

        &__title {
            font-size: 12px;
            line-height: 16px;
            color: #FFFFFF;
            margin-bottom: -3px;
            max-width: 170px;
            min-width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 3px;

            a {
                text-decoration: none;
                color: #FFFFFF;

                &:hover {
                    text-decoration: underline;
                    color: #4EBEFF;
                }
            }
        }

        &__subtitle {
            font-size: 13px;
            line-height: 16px;
            max-width: 170px;
            min-width: 170px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .rating {
                display: flex;
                align-items: center;

                span {
                    display: none;
                }

                .v-icon.v-icon.v-icon--link {
                    color: #fff!important;
                }
            }

        }
    }

    .text-reviews {
        margin-top: 2px;
        font-size: 12px;
        line-height: 16px;
        color: #fff;
    }
}

</style>