<template>
  <div>
    <v-menu offset-y v-if="auth" v-model="search_list" :close-on-content-click="false">
      <template v-slot:activator="{}">
        <SearchDefault
          color="main"
          class_search="search_management"
          v-model="search"
          :placeholder='__("Enter username to add to management")'
          v-on:keyup.enter="searchFriend()"
          @searching="searchFriend()"
        >
          <template v-slot:right>
            <v-btn tag="a" v-on:click="searchFriend()" text v-ripple="false" class="search-btn">
              <img src="/dist/images/frontend/search.svg" alt />
            </v-btn>
          </template>
        </SearchDefault>
      </template>
      <v-list v-if="search">
        <v-list-item>
          <div v-if="search_result===false" class="block-loader">
<!--            <img class="loader" src="/dist/images/frontend/loader.svg" alt />-->
            <v-skeleton-loader
                    type="avatar"
                    width="48px"
            ></v-skeleton-loader>
            <div>
              <v-skeleton-loader
                      type="list-item"
                      width="150"
              ></v-skeleton-loader>

              <v-skeleton-loader
                      type="list-item"
                      width="100"
              ></v-skeleton-loader>
            </div>
          </div>
          <div v-else-if="search_result && search_result.length > 0" style="width: 100%">
            <ManagementFriendResult
                    v-for="(result, key) in search_result"
                    :key="key"
                    v-bind:result="result"
                    v-bind:management_event.sync="management_event"
                    v-on:update:management_event="managementEvent(management_event, key)"
            ></ManagementFriendResult>
          </div>
          <div v-else>
            <p class="text-result">{{__('No results were found for your search.')}}</p>
            <p class="text-result">{{__('Refine your request')}}</p>
          </div>
<!--          <div v-else>No result</div>-->
        </v-list-item>
      </v-list>
    </v-menu>
    <ManagementItemList class="mt-6" v-bind:management="auth" role="admin"></ManagementItemList>
    <ManagementItemList
      v-for="(management, key) in developers.management"
      :key="key"
      v-bind:management="management.user"
      v-bind:management_event.sync="management_event"
      v-on:update:management_event="managementEvent(management_event, key)"
    ></ManagementItemList>
  </div>
</template>

<script>
import SearchDefault from "../../modules/SearchDefault";
import ManagementFriendResult from "./ManagementFriendResult";
import ManagementItemList from "./ManagementItemList";

export default {
  name: "GameInfoManagement",
  data() {
    return {
      search: null,
      management_event: null,
      search_result: false,
      search_list: false,
    };
  },
  props: {
    developers: {
      type: Object,
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    search() {
      this.search_result= false;
    }
  },
  methods: {
    searchFriend: function () {
      this.search_list = true;
      this.search_result= false;
      let search = this.search;

      if (search.length > 0) {
        const response = {
          search: search,
        };
        this.$http.post(
            "ajax/developers/" + this.$route.params.id + "/management/search",
            response
          )
          .then((response) => {
            return response.json();
          })
          .then((response) => {
              if(this.$checkAuth(response)) {
                  // response= {
                  //     "data": [],
                  //     "status": 200
                  // };
                  // response = {
                  //     "data": [
                  //         {
                  //             "id": 2,
                  //             "name": "Vladyslav",
                  //             "last_name": "Volkonsky",
                  //             "public_id": "v.volkonsky",
                  //             "photo": "/dist/images/frontend/passport-img.png",
                  //             "location": "United Kingdom",
                  //             "location_img": "ua.png"
                  //         },
                  //         {
                  //             "id": 5,
                  //             "name": "Crazy",
                  //             "last_name": "dev",
                  //             "public_id": "297441997",
                  //             "bg_color": "#26A9F4",
                  //             "photo": null,
                  //             "location": null,
                  //             "location_img": "de.png"
                  //         },
                  //         {
                  //             "id": 7,
                  //             "name": "new name",
                  //             "last_name": "new last_name",
                  //             "public_id": "1171981472",
                  //             "photo": "man_avatar.svg",
                  //             "location": null,
                  //             "location_img": ".png"
                  //         }
                  //     ],
                  //     "status": 200
                  // };
                  this.search_result = response.data;
              }
          })
          .catch((err) => {
            this.submitDisabled = false;
            let response = this.httpHandler(err);
            if (response.error) {
              this.formValidate = response.error.message;
            }
          });
      }
    },
    managementEvent: function (event, key) {
      const response = {
        command: event.command,
        friend_id: event.friend_id,
      };
      this.$http.post(
          "ajax/developers/" + this.$route.params.id + "/management/command",
          response
        )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if(this.$checkAuth(response)) {
          // response= {
          //     "data": {
          //         "user": {
          //             "id": 2,
          //             "name": "Vladyslav",
          //             "last_name": "Volkonsky",
          //             "public_id": "v.volkonsky",
          //             "photo": "/dist/images/frontend/passport-img.png",
          //             "location": "United Kingdom",
          //             "location_img": "de.png"
          //         }
          //     },
          //     "status": 200
          // };
            response= response.data;
            switch (event.command) {
              case "remove": {
                let developers = this.$cleanObject(this.developers);
                let management = developers.management;
                management.splice(key, 1);
                developers.management = management;
                this.$emit('update:developers', developers);
                break;
              }
              case "add": {
                this.search_list = false;
                let developers = this.$cleanObject(this.developers);
                developers.management.push(response);
                this.$emit('update:developers', developers);
                break;
              }
            }
          }
        })
        .catch((err) => {
          this.submitDisabled = false;
          let response = this.httpHandler(err);
          if (response.error) {
            this.formValidate = response.error.message;
          }
        });
    },
  },
  components: {
    SearchDefault,
    ManagementFriendResult,
    ManagementItemList,
  },
};
</script>

<style lang="scss" scoped>

.dark {
   .search-btn {
       img {
           filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
       }
   }

    .text-result {
        color: #96AFD1;
    }
}

  .search-btn {
    margin-right: 0;
  }
  .search-btn.v-btn:not(.v-btn--round).v-size--default[data-v-1c6aea71] {
    height: 40px;
    min-width: 20px;
    padding: 0 6px;
  }

  .block-loader {
    display: flex;
  }

  .v-skeleton-loader__text {
    height: 14px;
  }

  .v-skeleton-loader__list-item {
    height: 20px;
  }

    .text-result {
        margin-bottom: 6px;
        color: #6886AF;
        font-size: 14px;
    }

    @media screen and (max-width: 600px) {
        .v-btn.search-btn {

            img {
                height: 14px;
                margin-bottom: -8px;
            }
        }
    }
</style>
