<template>
  <div class="wrapper-invite">
      <div class="modal-invite" :class="invite.tournament_id ? 'tournament-invite' : ''">
        <div :class="this.activeClass" class="invite-modal-head dd">

            <v-list color="transparent" class="pa-0 block-game">
                <v-list-item class="pa-0">
                    <div class="img">
                        <div class="wrap-img">
                            <img v-if="typeof invite.game_logo == 'object'" :src="invite.game_logo[100]"/>
                            <img v-else :src="invite.game_logo"/>
                        </div>
                    </div>
                    <v-list-item-content class="mr-2 pa-0">
                        <div>
                            <v-list-item-title>{{game_array.name}}</v-list-item-title>
                            <v-list-item-subtitle>{{__('You are invited to the game!')}}</v-list-item-subtitle>
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list color="transparent" class="pa-0 block-person">
                <v-list-item class="pa-0">

                    <v-list-item-content class="pa-0">
                        <div>
                            <v-list-item-title :class="(invite.pro) ? 'user-pro' : ''">{{invite.name}}</v-list-item-title>

                            <v-list-item-subtitle style="margin-top: -2px" v-if="invite.pro">
                              <span style="color: #F6A401; font-size: 14px" class="mr-2">
                                <img class="ml-0 icon" src="/dist/images/frontend/fire.svg" alt />
                                <span class="mt-1">*****</span>
                              </span>
                                <span v-if="invite.location" style="color: #96AFD1;">
                                <img width="14" :src="invite.location_img" />
                              </span>
                            </v-list-item-subtitle>

                            <v-list-item-subtitle v-else>
                              <span style="color: #F6A401" class="mr-2">
                                <img class="ml-0 icon" src="/dist/images/frontend/fire.svg" alt />
                                {{invite.rating_points}}
                              </span>
                              <span v-if="invite.location" style="color: #96AFD1;">
                                <img width="14" :src="invite.location_img" />
                              </span>
                            </v-list-item-subtitle>
                        </div>
                    </v-list-item-content>

                    <div class="image" :class="(invite.pro) ? 'user-pro-img' : ''">
                        <div class="wrap-img">
                            <img v-if="invite.photo && typeof invite.photo == 'object'" :src="invite.photo[100]" />
                            <img v-else-if="invite.photo" :src="invite.photo" />
                            <span v-else class="photo" :style="{'background': invite.bg_color}">{{invite.name.substr(0,1)}}</span>
                        </div>
                        <div class="online"></div>
                    </div>
                </v-list-item>
            </v-list>
        </div>
        <div @click="updateClass" class="invite-modal-body dt">
          <div class="wrap-line-time">
            <div class="line-time" :style="{transform: 'translateX(-'+lineTime+'%)'}"></div>
          </div>

          <div class="wrap-modal-body">
          <div class="block-bet">
              <div class="block-bet__real" v-if="invite.wallet == 1">
                  <img class="pic" src="/dist/images/frontend/money.svg" alt />
                  <span class="warning--text">
                    {{primNum(invite.amount, 2)}}
                    <p>{{__('Match bet')}}</p>
                  </span>
              </div>
              <div class="block-bet__lite" v-else>
                  <img class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                  <span style="color: #6886AF">
                    {{primNum(invite.amount, 2)}}
                    <p>{{__('Match bet')}}</p>
                  </span>
              </div>
          </div>
          </div>
          <div class="block-params" v-if="invite.attributes && invite.attributes.length">
            <div class="block-params__head">
                {{__('game options')}}
            </div>

            <div class="block-params__body">
                <div v-for="(item, key) in invite.attributes" :key="key" class="item-params">
                    <span v-if="item.attr_name">{{item.attr_name}}</span>
                    <p>{{item.name}}</p>
                </div>
            </div>
          </div>

          <div class="block-buttons">
              <div class="block-aside" v-if="invite.tournament_id">
                  <div class="block-aside__img">
                      <img src="/dist/images/frontend/nav-icon-tournaments-new.svg" alt="">
                  </div>
                  <p>Турнирный матч</p>
              </div>

              <div v-else-if="auth.pro" class="left" style="width: 100%">
                  <v-btn
                      text
                      v-ripple="false"
                      class="ban-btn"
                      @click="sendBun"
                      :loading="submitBun"
                  >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 0.703125V12L21.4413 5.79637C19.7188 2.95312 16.3438 0.703125 12 0.703125Z" fill="#FD6717"/>
                          <path d="M12 10.1983L11.5 12L12 13.8017C12.9951 13.8017 13.8017 12.9951 13.8017 12C13.8017 11.0049 12.9951 10.1983 12 10.1983V10.1983Z" fill="#86A1C6"/>
                          <path d="M10.1983 12C10.1983 12.9951 11.0049 13.8017 12 13.8017V10.1983C11.0049 10.1983 10.1983 11.0049 10.1983 12V12Z" fill="#86A1C6"/>
                          <path d="M20.4853 3.51473C18.2188 1.24823 15.2053 0 12 0L11.7656 0.734391L12 1.40625C14.8297 1.40625 17.49 2.50819 19.4909 4.50909C21.3251 6.34331 22.4032 8.73187 22.5702 11.2969H20V12.7031H22.5707C22.4189 15.0351 21.5131 17.2208 19.9715 18.9771L18.154 17.1597L17.1597 18.154L18.9771 19.9715C17.0452 21.6671 14.594 22.5938 12 22.5938V24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51473V3.51473Z" fill="#86A1C6"/>
                          <path d="M3.51469 3.51473C1.24823 5.78123 0 8.79469 0 12V12.7041L3.99998 12.7031V11.2969H1.42978C1.58161 8.96489 2.48695 6.7792 4.02853 5.02289L5.84597 6.84033L6.84033 5.84597L5.02289 4.02853C6.95475 2.33288 9.40598 1.40625 12 1.40625V0C8.79469 0 5.78119 1.24823 3.51469 3.51473V3.51473Z" fill="#86A1C6"/>
                          <path d="M3.22186 23.9714H1.81561V17.1562H0.375046V15.75H3.22186V23.9714Z" fill="#FD6717"/>
                          <path d="M7.22213 24C5.78597 24 4.61761 22.8316 4.61761 21.3954V18.3546C4.61761 16.9184 5.78602 15.75 7.22213 15.75C8.65833 15.75 9.82669 16.9184 9.82669 18.3546V21.3954C9.82669 22.8316 8.65833 24 7.22213 24ZM7.22213 17.1562C6.56138 17.1562 6.02386 17.6938 6.02386 18.3546V21.3954C6.02386 22.0562 6.56143 22.5938 7.22213 22.5938C7.88293 22.5938 8.42044 22.0562 8.42044 21.3954V18.3546C8.42044 17.6938 7.88293 17.1562 7.22213 17.1562Z" fill="#FD6717"/>
                          <path d="M12.7031 15.7344H12L11.8125 17.1719L12 18.6094H12.7031V15.7344Z" fill="#FD6717"/>
                          <path d="M11.2969 15.7344H12V18.6094H11.2969V15.7344Z" fill="#FD6717"/>
                      </svg>
                      <span>
                        {{__('Ban for 10 min')}}
                      </span>
                  </v-btn>
              </div>
              <div v-else></div>
              <div class="left-mob">
                  <v-btn
                      v-if="auth.pro && !invite.tournament_id"
                      text
                      v-ripple="false"
                      class="btn-ban-mob  mr-3"
                      @click="sendBun"
                      :loading="submitBun"
                  >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 0.703125V12L21.4413 5.79637C19.7188 2.95312 16.3438 0.703125 12 0.703125Z" fill="#FD6717"/>
                          <path d="M12 10.1983L11.5 12L12 13.8017C12.9951 13.8017 13.8017 12.9951 13.8017 12C13.8017 11.0049 12.9951 10.1983 12 10.1983V10.1983Z" fill="#86A1C6"/>
                          <path d="M10.1983 12C10.1983 12.9951 11.0049 13.8017 12 13.8017V10.1983C11.0049 10.1983 10.1983 11.0049 10.1983 12V12Z" fill="#86A1C6"/>
                          <path d="M20.4853 3.51473C18.2188 1.24823 15.2053 0 12 0L11.7656 0.734391L12 1.40625C14.8297 1.40625 17.49 2.50819 19.4909 4.50909C21.3251 6.34331 22.4032 8.73187 22.5702 11.2969H20V12.7031H22.5707C22.4189 15.0351 21.5131 17.2208 19.9715 18.9771L18.154 17.1597L17.1597 18.154L18.9771 19.9715C17.0452 21.6671 14.594 22.5938 12 22.5938V24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51473V3.51473Z" fill="#86A1C6"/>
                          <path d="M3.51469 3.51473C1.24823 5.78123 0 8.79469 0 12V12.7041L3.99998 12.7031V11.2969H1.42978C1.58161 8.96489 2.48695 6.7792 4.02853 5.02289L5.84597 6.84033L6.84033 5.84597L5.02289 4.02853C6.95475 2.33288 9.40598 1.40625 12 1.40625V0C8.79469 0 5.78119 1.24823 3.51469 3.51473V3.51473Z" fill="#86A1C6"/>
                          <path d="M3.22186 23.9714H1.81561V17.1562H0.375046V15.75H3.22186V23.9714Z" fill="#FD6717"/>
                          <path d="M7.22213 24C5.78597 24 4.61761 22.8316 4.61761 21.3954V18.3546C4.61761 16.9184 5.78602 15.75 7.22213 15.75C8.65833 15.75 9.82669 16.9184 9.82669 18.3546V21.3954C9.82669 22.8316 8.65833 24 7.22213 24ZM7.22213 17.1562C6.56138 17.1562 6.02386 17.6938 6.02386 18.3546V21.3954C6.02386 22.0562 6.56143 22.5938 7.22213 22.5938C7.88293 22.5938 8.42044 22.0562 8.42044 21.3954V18.3546C8.42044 17.6938 7.88293 17.1562 7.22213 17.1562Z" fill="#FD6717"/>
                          <path d="M12.7031 15.7344H12L11.8125 17.1719L12 18.6094H12.7031V15.7344Z" fill="#FD6717"/>
                          <path d="M11.2969 15.7344H12V18.6094H11.2969V15.7344Z" fill="#FD6717"/>
                      </svg>
                  </v-btn>

                  <BaseButton
                      :width="75"
                      :height="40"
                      class_btn="btn-liteGrey-quest"
                      v-on:click="cancelInvite('closed', 1)"
                      :loading="submitDisabled"
                  >
                      <span>
                        {{__('Cancel')}}
                      </span>
                  </BaseButton>
              </div>
              <div class="right">
                  <BaseButton
                      :width="100"
                      :height="40"
                      class_btn="btn-liteGrey-quest"
                      v-on:click="cancelInvite('closed', 1)"
                      :loading="submitDisabled"
                  >
                      <span>
<!--                        <img src="/dist/images/frontend/close.svg" alt="/" />-->
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.78408 6.00003L11.3896 1.39447C11.4806 1.28819 11.5282 1.15149 11.5228 1.01167C11.5174 0.871845 11.4594 0.739213 11.3605 0.640271C11.2616 0.54133 11.1289 0.483367 10.9891 0.477966C10.8493 0.472566 10.7126 0.520125 10.6063 0.61114L6.00074 5.2167L1.39519 0.605584C1.29058 0.500971 1.14869 0.4422 1.00074 0.4422C0.852799 0.4422 0.710913 0.500971 0.6063 0.605584C0.501687 0.710197 0.442916 0.852083 0.442916 1.00003C0.442916 1.14797 0.501687 1.28986 0.6063 1.39447L5.21741 6.00003L0.6063 10.6056C0.548144 10.6554 0.50091 10.7167 0.467564 10.7856C0.434218 10.8545 0.415479 10.9296 0.412523 11.0061C0.409568 11.0826 0.42246 11.1589 0.45039 11.2302C0.478321 11.3015 0.520686 11.3662 0.574828 11.4204C0.628969 11.4745 0.693718 11.5169 0.76501 11.5448C0.836301 11.5728 0.912597 11.5856 0.989108 11.5827C1.06562 11.5797 1.14069 11.561 1.20962 11.5277C1.27854 11.4943 1.33983 11.4471 1.38963 11.3889L6.00074 6.78336L10.6063 11.3889C10.7126 11.4799 10.8493 11.5275 10.9891 11.5221C11.1289 11.5167 11.2616 11.4587 11.3605 11.3598C11.4594 11.2608 11.5174 11.1282 11.5228 10.9884C11.5282 10.8486 11.4806 10.7119 11.3896 10.6056L6.78408 6.00003Z" fill="currentColor"/>
                          </svg>

                        {{__('Cancel')}}
                      </span>
                  </BaseButton>

                  <BaseButton
                      class="ml-3"
                      :width="100"
                      :height="40"
                      v-on:click="playGame()"
                      :loading="submitDisableded"
                      class_btn="btn-guest btn-nav"
                  >
                      <span>
                        <svg class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                         <path d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0ZM7.17733 4.852L11.514 7.42667C11.6135 7.4857 11.6959 7.56959 11.7531 7.67012C11.8104 7.77064 11.8405 7.88432 11.8405 8C11.8405 8.11568 11.8104 8.22936 11.7531 8.32988C11.6959 8.43041 11.6135 8.5143 11.514 8.57333L7.17733 11.148C7.02549 11.2382 6.85248 11.2866 6.67588 11.2882C6.49929 11.2898 6.32541 11.2446 6.17193 11.1573C6.01846 11.0699 5.89086 10.9434 5.80211 10.7908C5.71336 10.6381 5.66663 10.4646 5.66667 10.288V5.712C5.66663 5.5354 5.71336 5.36193 5.80211 5.20925C5.89086 5.05656 6.01846 4.93011 6.17193 4.84273C6.32541 4.75536 6.49929 4.71019 6.67588 4.71182C6.85248 4.71345 7.02549 4.76181 7.17733 4.852Z" fill="white"/>
                        </svg>

                        {{__('Play match')}}
                      </span>
                  </BaseButton>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import BaseButton from "./BaseButton";

export default {
  name: "NotificationsInviteGame",
  data: function () {
    return {
      // currentQ : 0,
      submitDisabled: false,
      submitDisableded: false,
      submitBun: false,
      date_start: this.invite.timestamp + ((this.invite.time_end) ? this.invite.time_end : this.$inviteTimer),
      interval: null,
      lineTime: 0
    };
  },
  props: {
    invite: {
      type: Object,
    },
    activeClass: {
      type: String,
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
    invite_game() {
      return this.$store.state.games.invite_game;
    },
    lang() {
      return this.$store.state.language.lang;
    },

    game_array() {
        if (this.$filterArray(this.invite.game_translations, this.lang, 'lang')) {
            return this.$filterArray(this.invite.game_translations, this.lang, 'lang');
        } else {
            return this.$filterArray(this.invite.game_translations, 'en', 'lang');

        }
    },
  },
  watch: {},
  methods: {
    cancelInvite(time_off='closed', invited_cancel = 0) {
      let invite_game = this.invite_game;
      let invite = this.invite;
      this.submitDisabled = true;

      const formData = {
        game_id: invite.game_id,
        user_id: invite.inviter_id,
        time_off: time_off,
      };

      if (this.invite.tournament_id) {
          formData.tournament_id = this.invite.tournament_id;
          formData.invited_cancel = invited_cancel;
      }

      this.$http.post("ajax/games/cancel_game", formData)
      .then((response) => {
          return response.json();
      })
      .then((response) => {
          if(this.$checkAuth(response)) {
              let index = this.$filterIndexObject(invite_game, invite.inviter_id, "inviter_id");

              if (index >= 0) {
                  invite_game.splice(index, 1);
                  this.$store.dispatch("asyncInviteGame", invite_game);
                  this.$emit('updateActiveClass', 0);
              }
              this.submitDisabled = false;
              clearInterval(this.interval);
          }
      })
      .catch((err) => {
          this.httpHandler(err);
          this.submitDisabled = false;
      });
    },
    playGame() {
      let invite_game = this.invite;
      this.submitDisableded = true;

      const formData = {
        game_id: invite_game.game_id,
        match_id: invite_game.match_id,
        inviter_id: invite_game.inviter_id,
        amount: invite_game.amount,
      };
      this.$http
        .post("ajax/games/play_go", formData)
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if(this.$checkAuth(response)) {
            if (response.data.success === true && response.data.room_id) {
              this.$router.push({
                name: "frame",
                params: {
                  id: invite_game.game_url,
                  room_id: response.data.room_id,
                  battle_id: response.data.battle_id,
                  lang: this.$store.getters.getLang,
                },
              });
              this.$store.dispatch("asyncInviteGame", []);
              clearInterval(this.interval);
              this.submitDisableded = false;
            }
          }
        })
        .catch((err) => {
            this.httpHandler(err);
        });
    },
    updateClass() {
      this.$emit('updateActiveClass', this.invite.inviter_id);
    },
    sendBun() {
        this.submitBun = true;
        let invite_game = this.invite_game;
        let invite = this.invite;
        const response = {
            game_id: invite.game_id,
            user_id: invite.inviter_id,
        };

        this.$http.post("ajax/games/ban_user", response)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if(this.$checkAuth(response)) {
                    let index = this.$filterIndexObject(invite_game, invite.inviter_id, "inviter_id");

                    if (index >= 0) {
                        invite_game.splice(index, 1);
                        this.$store.dispatch("asyncInviteGame", invite_game);
                        this.$emit('updateActiveClass', 0);
                    }
                    this.submitBun = false;
                    clearInterval(this.interval);
                }
            })
            .catch((err) => {
                this.httpHandler(err);
                this.submitBun = false;
            });
    }
  },

  beforeDestroy() {
    // this.cancelInvite();
    clearInterval(this.interval);
  },

  mounted() {
    let timestamp= this.date_start;
    this.interval = setInterval(() => {
      let momentUtc= (moment.utc().format('X'));
      if (timestamp >= momentUtc) {
          let timer = timestamp - momentUtc;
          this.lineTime= this.primNum(((this.$inviteTimer - timer)/(this.$inviteTimer/100)), 2);
      } else {
        clearInterval(this.interval);
        this.cancelInvite('timer', 0);
      }
    }, 1000);
  },
  components: {
      BaseButton
  },
};
</script>

<style lang="scss" scoped>


.modal-invite .dt {
  cursor: pointer;
}

/*.modal-invite .dd{*/
/*  opacity:0;*/
/*  padding:0;*/
/*  height:0;*/
/*  transition-duration:0.3s;*/
/*}*/

/*.modal-invite .dd.active {*/
/*  opacity:1;*/
/*  padding: 30px 20px;*/
/*}*/


//.wrapper-invite {
//  position: fixed;
//  top: 50%;
//  left: 50%;
//  transform: translate(-50%, -50%);
//  width: 100%;
//  height: 100%;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  z-index: 1000;
//  //align-items: center;
//  //justify-content: center;
//  background-color: rgba(33, 33, 33, 0.5);
//}

.modal-invite.tournament-invite {
    position: relative;
    overflow: visible;

    &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        border-radius: 4px;
        background: linear-gradient(90deg, #00AEFF 0%, #01DF72 100%);
        pointer-events: none
    }
}
.modal-invite {
  width: 500px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
}
.invite-modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 3px solid rgba(112, 124, 151, 0.1);
  border-radius: 4px 4px 0px 0px;
  padding: 12px 20px;
  position: relative;

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #002b50;
  }

  .wrap-btn {
    display: flex;
    justify-content: space-between;

    .v-btn:not(.v-btn--round).v-size--default {
      height: 30px;
      min-width: 74px;
      padding: 0;
    }

    .btn-cab {
      margin-right: 0;
    }

    .v-btn.cancel {
      margin-right: 10px;
      background: #e5f0ff;
      border-radius: 6px;
      text-transform: inherit;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0em;
      box-shadow: none;
      padding: 0;
    }

    .cancel {
      outline: none;
      &:hover {
        background-color: #cddff7;
      }
    }
  }
}

.invite-modal-body {
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  padding: 0 20px 15px 20px;
  margin-top: -3px;
  overflow: hidden;
}
.wrap-modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.block-game {
  .img {
    margin-right: 10px;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 40px;
    height: 40px;

    .wrap-img {
      border-radius: 8px;
      width: 40px;
      height: 40px;

      img {
        border-radius: 8px;
        width: 40px;
        height: 40px;
      }
    }
  }


  .v-list-item {
    display: flex;
    align-items: center;

    &__title {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #002b50;
      margin-bottom: 1px;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #00C443!important;
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
  }
}

.block-bet {
    &__real {
        display: flex;
        align-items: center;
        /*background: rgba(253, 174, 2, 0.1);*/
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /*padding: 10px 18px;*/
        border-radius: 5px;

        img {
            height: 38px;
            margin: 0 10px 0 0;
        }

        p {
            margin: 0;
            font-size: 14px;
            line-height: 19px;
            color: #86A1C6;
        }

        span {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__lite {
        display: flex;
        align-items: center;
        /*background: #F3F7FE;*/
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        /*padding: 10px 18px;*/
        border-radius: 5px;

        img {
            height: 38px;
            margin: 0 10px 0 0;
        }

        p {
            margin: 0;
            font-size: 14px;
            line-height: 19px;
            color: #86A1C6;
        }

        span {
            font-size: 16px;
            line-height: 22px;
        }
    }
}

.block-person {
  .image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 10px;
    position: relative;

    .online {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      position: absolute;
      right: 3px;
      bottom: 0;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      background-color: #00C569;
    }

    .wrap-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
    .photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .v-list-item {
    &__title {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      align-items: center;
      color: #002b50;
      margin-bottom: 2px;
      display: block;
      max-width: 145px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: right;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.01em;
      display: flex;
      align-items: center;
      justify-content: flex-end;

        span {
            display: flex;
            align-items: center;
        }

      .icon {
        height: 11px;
        margin-right: 4px;
      }
    }

    &__content {
      margin-top: 3px;
    }
  }
}

.block-params {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    cursor: default;
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(112, 124, 151, 0.1);

    &:after {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        top: 9px;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(0, 174, 255, 0) 0%, rgba(0, 174, 255, 0.6) 36.16%, rgba(0, 174, 255, 0.6) 67.61%, rgba(0, 174, 255, 0) 100.62%);
    }


    &__head {
        background: #fff;
        padding: 1px 6px 1px;
        border: 1px solid #00AEFF;
        box-sizing: border-box;
        border-radius: 4px;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        color: #002B50;
        text-transform: uppercase;
        margin-bottom: 10px;
        position: relative;
        z-index: 10;
        display: none;
    }

    &__body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .item-params {
            padding: 6px 12px;
            display: flex;
            align-items: center;
            background: rgba(0, 174, 255, 0.1);
            border-radius: 5px;
            margin: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            display: flex;
            align-items: center;
            color: #002B50;

            span {
                margin-right: 6px;
                text-transform: capitalize;
            }

            p {
                margin: 0;
                color: #00AEFF;
            }
        }
    }
}

.block-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .block-aside {
        display: flex;
        align-items: center;

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 6px;

            img {
                height: 20px;
            }
        }

        p {
            color: #026EED;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin: 0;
        }
    }

    .right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    .left-mob {
        display: none;
    }
}

.wrap-line-time {
  overflow: hidden;
  margin: 0 -20px 15px;
}

.line-time {
  height: 3px;
  background: #00AEFF;
  //margin: 0 -14px;
  transition: 0.5s;
  //animation: loading  60s linear;
}
//@keyframes loading {
//  to {
//    transform: translateX(-100%);
//  }
//}



.v-application .info {
  background-color: #46bcff !important;
  border-color: #46bcff !important;
}

  @media screen and (max-width: 768px) {
    .modal-invite {
      width: 440px;
    }

    .invite-modal-head {
      padding: 12px 15px;
    }

    .invite-modal-body {
        padding: 0 15px 15px;
    }

    .wrap-line-time {
        margin: 0 -15px 15px;
    }

    .block-game {
      .v-list-item {

        &__title {
          font-size: 14px;
          line-height: 19px;
          max-width: 160px;
          text-overflow: ellipsis;
        }

          &__subtitle {
              font-size: 12px;
              line-height: 16px;
              max-width: 160px;
              text-overflow: ellipsis;
          }
      }
    }

    /*.block-bet__lite,*/
    /*.block-bet__real {*/
    /*    font-size: 12px;*/
    /*    line-height: 16px;*/
    /*    padding: 6px 12px;*/

    /*    img {*/
    /*        height: 16px;*/
    /*    }*/
    /*}*/

      .block-person {
          .v-list-item {
              &__title {
                  font-size: 12px;
                  line-height: 16px;
                  max-width: 130px;
              }

              &__subtitle {
                  font-size: 10px;
                  line-height: 14px;

                  .icon {
                      height: 10px;
                      margin-right: 3px;
                  }
              }
          }

          .image {
              margin-left: 8px;
          }
      }
  }

  @media screen and (max-width: 450px) {
      .modal-invite {
          width: 320px;
          border-radius: 4px;
      }

      .invite-modal-body {
          padding: 0 10px 10px;
          border-radius: 0 0 4px 4px;
      }

      .invite-modal-head {
          padding: 10px;
          border-radius: 4px 4px 0 0;
      }

      .wrap-btn {
          svg {
              display: none;
          }
      }

      .block-game {
          .v-list-item {
              min-height: fit-content!important;

              &__title {
                  font-size: 12px;
                  line-height: 16px;
                  max-width: 100px;
              }

              &__subtitle {
                  font-size: 9px;
                  line-height: 14px;
              }
          }

          .img {
              width: 32px;
              height: 32px;
              border-radius: 4px;
              margin-right: 6px;

              .wrap-img {
                  width: 32px;
                  height: 32px;
                  border-radius: 4px;

                  img {
                      width: 32px;
                      height: 32px;
                      border-radius: 4px;
                  }
              }
          }
      }

      /*.block-bet__lite,*/
      /*.block-bet__real {*/
      /*    flex-direction: column;*/
      /*    align-items: center;*/
      /*    padding: 6px 10px;*/
      /*    border-radius: 4px;*/
      /*    margin-right: 12px;*/

      /*    p {*/
      /*        font-size: 12px;*/
      /*        line-height: 16px;*/
      /*        width: 100%;*/
      /*        text-align: center;*/
      /*        margin-bottom: 4px;*/
      /*    }*/

      /*    img {*/
      /*        margin: 2px 4px 0 0;*/
      /*    }*/

      /*    span {*/
      /*        display: flex;*/
      /*        align-items: center;*/
      /*    }*/
      /*}*/

      .block-person {
          .v-list-item {
              min-height: fit-content;
              &__title {
                  max-width: 105px;
              }
          }

          .image {
              width: 32px;
              height: 32px;
              margin-left: 6px;

              .wrap-img {
                  width: 32px;
                  height: 32px;

                  img {
                      width: 32px;
                      height: 32px;
                  }
              }
          }
      }

      .block-params {
          margin: 12px 0 15px;
          padding-bottom: 11px;

          &__body {
              .item-params {
                  padding: 4px 10px;
                  font-size: 12px;
                  line-height: 16px;
              }
          }
      }

      .block-buttons {

          .block-aside {
              display: flex;
              align-items: center;

              &__img {
                  width: 40px;
                  height: 40px;
                  border-radius: 8px;
                  background-color: transparent;
                  margin-right: 10px;

                  img {
                  }
              }

              p {
                  display: none;
              }
          }

          .right {
              width: calc(50% + 6px);

              div {
                  &:nth-child(1) {
                      display: none!important;
                  }

                  &:nth-child(2) {
                      width: 100%;
                  }
              }

          }
          .left {
              display: none;
          }
          .left-mob {
              display: flex;
              width: 100%;

              div {
                  width: 100%;
              }
          }
      }
  }
</style>
