<template>
    <div class="wrapper-tournaments home-games-new all-content">
        <div class="home-games-left">
            <LayoutNavAuth>
                <h1 class="img">
                    <img src="/dist/images/frontend/nav-icon-tournaments-new.svg" class="icon-sidebar">
                    {{__('Championship')}}
                </h1>

                <div slot="right" class="wrap-tabs pa-0">
                    <tabs
                            :tabs="side_tabs"
                            :currentTab="currentTab"
                            :wrapper-class="'buttons-tabs'"
                            :tab-class="'buttons-tabs__item'"
                            :tab-active-class="'buttons-tabs__item_active'"
                            :line-class="'buttons-tabs__active-line'"
                            @onClick="sideChange"
                    >
                        <template v-slot:upcoming="item">
                            {{__(item.title)}}
                            <span v-if="info && info.upcoming_count">{{info.upcoming_count}}</span>
                        </template>
                    </tabs>
                </div>
                <div slot="right" class="wrap-select">
                    <DropdownDefault
                            class_modal="modal_game"
                            color="select_game"
                            v-bind:dropdown="side_tabs"
                            v-bind:value.sync="side_tabs.value"
                            @updateValue="sideChange"
                    />
                </div>

                <div class="loading"  :style="{width: progress + '%'}"></div>
            </LayoutNavAuth>

            <div class="wrap-tournaments dashboard-content" v-if="tournaments">
                <!--            <div v-if="currentTab == 'actual' && tournaments.upcoming && tournaments.upcoming.length" class="block-upcoming">-->
                <!--                <h1>-->
                <!--                    {{__('Registration for tournaments is open')}}-->

                <!--                    <router-link :to="({name: 'tournaments_current', params: {'current': 'upcoming', lang: this.$store.getters.getLang}})">{{__('See all')}}</router-link>-->
                <!--                </h1>-->

                <!--                <VueSlickCarousel-->
                <!--                        v-bind="settings"-->
                <!--                >-->
                <!--                    <div class="wrap-item-upcoming" v-for="(item, key) in tournaments.upcoming"  :key="key">-->
                <!--                        <ItemUpcomingTournaments-->
                <!--                            :item="item"-->
                <!--                        ></ItemUpcomingTournaments>-->
                <!--                    </div>-->
                <!--&lt;!&ndash;                    <router-link&ndash;&gt;-->
                <!--&lt;!&ndash;                        v-for="(item, key) in tournaments.upcoming"&ndash;&gt;-->
                <!--&lt;!&ndash;                        :key="key"&ndash;&gt;-->
                <!--&lt;!&ndash;                        class="item-upcoming"&ndash;&gt;-->
                <!--&lt;!&ndash;                        :to="{name: 'tournament', params: {'id': item.id, lang: $store.getters.getLang}}"&ndash;&gt;-->
                <!--&lt;!&ndash;                    >&ndash;&gt;-->
                <!--&lt;!&ndash;                        <div v-if="typeof item.game.logo == 'object'" class="item-upcoming__img" :style="{'backgroundImage': 'url(' +item.game.logo['original'] + ')'}">&ndash;&gt;-->
                <!--&lt;!&ndash;                            <p class="block-date">{{item.date_start | moment('ll', lang)}}</p>&ndash;&gt;-->
                <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                        <div v-else class="item-upcoming__img" :style="{'backgroundImage': 'url(' +item.game.logo + ')'}">&ndash;&gt;-->
                <!--&lt;!&ndash;                            <p class="block-date">{{item.date_start | moment('ll', lang)}}</p>&ndash;&gt;-->
                <!--&lt;!&ndash;                        </div>&ndash;&gt;-->

                <!--&lt;!&ndash;                        <div class="item-upcoming__name">&ndash;&gt;-->
                <!--&lt;!&ndash;                            <p>{{gameTranslation(item.game.game_translations).name}}</p>&ndash;&gt;-->
                <!--&lt;!&ndash;                        </div>&ndash;&gt;-->

                <!--&lt;!&ndash;                        <div class="item-upcoming__info">&ndash;&gt;-->
                <!--&lt;!&ndash;                            <div class="block-price">&ndash;&gt;-->
                <!--&lt;!&ndash;                                <p>{{__('Price')}}:</p>&ndash;&gt;-->
                <!--&lt;!&ndash;                                <div v-if="item.wallet == 1" class="block-price__desc">&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <img src="/dist/images/frontend/money.svg" alt />&ndash;&gt;-->
                <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                                <div v-else class="block-price__desc">&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
                <!--&lt;!&ndash;                                    <img src="/dist/images/frontend/light-coin.svg" alt />&ndash;&gt;-->
                <!--&lt;!&ndash;                                </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                            </div>&ndash;&gt;-->

                <!--&lt;!&ndash;                            <div class="block-participants">&ndash;&gt;-->
                <!--&lt;!&ndash;                                <img src="/dist/images/frontend/users.svg" alt="">&ndash;&gt;-->
                <!--&lt;!&ndash;                                <span>{{item.users_count}}/{{item.count_users}}</span>&ndash;&gt;-->
                <!--&lt;!&ndash;                            </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--&lt;!&ndash;                    </router-link>&ndash;&gt;-->

                <!--                </VueSlickCarousel>-->
                <!--            </div>-->

                <!--            <div :style="(currentTab == 'upcoming') ? {maxWidth: '100%'} : ''" class="head-tournaments">-->
                <!--                <h1 v-if="currentTab=='actual'">{{__('Actual tournaments')}}</h1>-->
                <!--                <h1 v-else-if="currentTab=='upcoming'">{{__('Upcoming tournaments')}}</h1>-->
                <!--                <h1 v-else>{{__('My tournaments')}}</h1>-->

                <!--&lt;!&ndash;                <div v-if="auth" class="block-switch">&ndash;&gt;-->
                <!--&lt;!&ndash;                    <p :style="!my_tournaments ? {'color': '#01DF72'} : {'color': '#fff'}">{{__('All')}}</p>&ndash;&gt;-->
                <!--&lt;!&ndash;                    <v-switch&ndash;&gt;-->
                <!--&lt;!&ndash;                        v-model="my_tournaments"&ndash;&gt;-->
                <!--&lt;!&ndash;                        color="darkblue"&ndash;&gt;-->
                <!--&lt;!&ndash;                        hide-details&ndash;&gt;-->
                <!--&lt;!&ndash;                        v-ripple="false"&ndash;&gt;-->
                <!--&lt;!&ndash;                        @change="getTournaments(currentTab)"&ndash;&gt;-->
                <!--&lt;!&ndash;                    ></v-switch>&ndash;&gt;-->
                <!--&lt;!&ndash;                    <p :style="my_tournaments ? {'color': '#00AEFF'} : {'color': '#fff'}">{{__('My')}}</p>&ndash;&gt;-->
                <!--&lt;!&ndash;                </div>&ndash;&gt;-->
                <!--            </div>-->

                <div class="d-flex wrapper-tournaments">

                    <div v-if="currentTab == 'actual'" class="block-tournaments">
                        <div v-if="tournaments.upcoming && tournaments.upcoming.length" >
                            <h1>{{__('Registration for tournaments is open')}}</h1>
                            <div class="block-tournaments__body block-tournaments__body--upcoming">
                                <div
                                        v-for="(item, key) in tournaments.upcoming"
                                        :key="key"
                                        class="wrapper-item-upcoming"
                                >
                                    <ItemUpcomingTournaments
                                            :item="item"
                                    />
                                </div>
                            </div>
                        </div>

                        <div v-else class="block-empty">
                            <img src="/dist/images/frontend/icon-championship.svg" alt />
                            <p>{{__('There are no active tournaments, please wait until they start')}}</p>
                        </div>

                        <div v-if="tournaments.actual && tournaments.actual.length" >
                            <h1 >{{__('Actual tournaments')}}</h1>
                            <div class="block-tournaments__body">
                                <ItemTournaments
                                        v-for="(item, key) in tournaments.actual"
                                        :item="item"
                                        :key="key"
                                        :currentTab="currentTab"
                                >
                                </ItemTournaments>
                            </div>
                        </div>
                        <div v-else class="block-empty">
                            <!--                        <img src="/dist/images/frontend/icon-championship.svg" alt />-->
                            <!--                        <p>{{__('There are no active tournaments, please wait until they start')}}</p>-->
                        </div>
                    </div>

                    <div v-if="currentTab == 'upcoming'" class="block-tournaments block-tournaments--upcoming">
                        <h1>{{__('Upcoming tournaments')}}</h1>
                        <div v-if="tournaments.upcoming && tournaments.upcoming.length" class="block-tournaments__body block-tournaments__body--upcoming">
                            <div
                                    v-for="(item, key) in tournaments.upcoming"
                                    :key="key"
                                    class="wrapper-item-upcoming"
                            >
                                <ItemUpcomingTournaments
                                        :item="item"
                                />
                            </div>
                        </div>

                        <div v-else class="block-empty">
                            <img src="/dist/images/frontend/icon-championship.svg" alt />
                            <p>{{__('There are no active tournaments, please wait until they start')}}</p>
                        </div>
                    </div>

                    <div v-if="currentTab == 'archive'" class="block-tournaments">

                        <div v-if="archive_tournaments && archive_tournaments.length" class="block-tournaments__body">
                            <ItemTournaments
                                    v-for="(item, key) in archive_tournaments"
                                    :item="item"
                                    :key="key"
                                    :currentTab="currentTab"
                            >
                            </ItemTournaments>
                        </div>

                        <div v-else class="block-empty">
                            <img src="/dist/images/frontend/icon-championship.svg" alt />
                            <p>{{__('There are no active tournaments, please wait until they start')}}</p>
                        </div>
                    </div>

                    <div v-if="currentTab != 'upcoming'" class="sidebar-tournaments">

                        <div class="my-tournament" >
                            <h1 class="my-tournament-text" v-if="tournaments.my_tournaments">Турниры в которых я учавствую</h1>
                            <div v-for="(Item, key) in tournaments.my_tournaments" :key="key" class="my-block">
                                <router-link class="item-upcoming-tournament" :to="{name: 'tournament', params: {'id': Item.tournament.id, lang: $store.getters.getLang}}">

                                    <div class="img-block">
                                        <div v-if="typeof Item.tournament.game.logo == 'object'" class="img" :style="{'backgroundImage': 'url(' +Item.tournament.game.logo[300] + ')'}"></div>
                                        <div v-else class="img" :style="{'backgroundImage': 'url(' +Item.tournament.game.logo + ')'}"></div>
                                    </div>

                                    <div class="right-block">
                                        <div class="name">
                                            <p class="block-name">{{gameTranslation(Item.tournament.game.game_translations).name}}</p>
                                            <div class="users">
                                                <img src="/dist/images/frontend/users.svg" alt="">
                                                <span>{{Item.tournament.users_count}}/{{Item.tournament.count_users}}</span>
                                            </div>
                                        </div>

                                        <div class="start">
                                            <p style="color: #ffffff;" v-if="isTournamentUpcoming(Item.tournament)">
                                                {{__("Upcoming_new")}}
                                            </p>
                                            <p class="start-span" v-else-if="isTournamentActive(Item.tournament)">
                                                <span class="blink-1"></span>
                                                {{__("The tournament is ongoing")}}
                                            </p>
                                            <p style="color: #ffffff;"  v-else>
                                                {{__("Completed_tournament")}}
                                            </p>

                                        </div>

                                        <div class="time">
                                            <div v-if="isTournamentUpcoming(Item.tournament)">
                                                <span>{{__("It will start in")}}</span>
                                                <p>{{ countdownToStart(Item.tournament.date_start) }}</p>
                                            </div>
                                            <div class="time-start" v-else-if="isTournamentActive(Item.tournament)">
                                                <span>{{__("It will end in")}}</span>
                                                <p>{{ countdownToFinish(Item.tournament.date_finish) }}</p>
                                            </div>
                                            <p v-else class="end">{{__("Completed_tournament")}}</p>
                                        </div>
                                        <!--                                    <div class="href">-->
                                        <!--                                        <p>-->
                                        <!--                                            {{__("View more details")}}-->
                                        <!--                                            <svg width="6" height="24" viewBox="0 0 6 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                                        <!--                                                <path d="M1.5 15.5L4.5 12.5L1.5 9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
                                        <!--                                            </svg>-->
                                        <!--                                        </p>-->
                                        <!--                                    </div>-->
                                    </div>

                                </router-link>
                            </div>
                        </div>
                        <div class="top-block top-block-new" v-if="info">
                            <h5>{{__('Tournament statistics')}}</h5>
                            <p>{{__("It's not the size of the dog in the fight, it's the size of the fight in the dog. - Mark Twain")}}</p>
                            <div class="wrapper-block-info">
                                <div class="block-info">
                                    <div class="block-info__icon" style="background: #3994FF">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_201_5789)">
                                                <path d="M9.66665 14C9.52393 14.005 9.38371 13.9616 9.26874 13.8769C9.15378 13.7922 9.07081 13.6711 9.03332 13.5333L6.96665 7.3L5.29999 11.5667C5.25804 11.6927 5.17754 11.8023 5.06987 11.8801C4.9622 11.9579 4.8328 11.9998 4.69999 12C4.57087 12.0033 4.44337 11.9707 4.33159 11.906C4.21981 11.8413 4.12812 11.7469 4.06665 11.6333L2.86665 9.2L1.83332 10.4333C1.71767 10.5648 1.55495 10.6454 1.38031 10.6579C1.20568 10.6704 1.03315 10.6137 0.899987 10.5C0.768546 10.3843 0.687875 10.2216 0.675402 10.047C0.662928 9.87236 0.719652 9.69983 0.833321 9.56667L2.49999 7.56667C2.56698 7.483 2.65411 7.41771 2.75322 7.3769C2.85233 7.33609 2.96017 7.3211 3.06665 7.33333C3.1785 7.34701 3.28539 7.38749 3.37824 7.45133C3.47109 7.51516 3.54716 7.60047 3.59999 7.7L4.59999 9.7L6.36665 5.1C6.41389 4.96766 6.50173 4.85362 6.61764 4.77418C6.73355 4.69474 6.8716 4.65396 7.01207 4.65766C7.15254 4.66135 7.28825 4.70934 7.39982 4.79477C7.51139 4.88019 7.59311 4.99869 7.63332 5.13333L9.59999 11L12.0333 1.83333C12.0631 1.69492 12.1383 1.57047 12.2471 1.47984C12.3558 1.3892 12.4918 1.33763 12.6333 1.33333C12.7804 1.3266 12.9255 1.36873 13.0461 1.45315C13.1667 1.53757 13.256 1.65953 13.3 1.8L15.3 7.8C15.3363 7.96283 15.3102 8.13333 15.2269 8.27786C15.1436 8.42239 15.0091 8.53043 14.85 8.58067C14.6909 8.6309 14.5188 8.61967 14.3676 8.5492C14.2164 8.47872 14.0971 8.35413 14.0333 8.2L12.7333 4.33333L10.3 13.5C10.2702 13.6384 10.195 13.7629 10.0862 13.8535C9.97748 13.9441 9.84149 13.9957 9.69999 14H9.66665Z" fill="#026EED"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_201_5789">
                                                    <rect width="16" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div class="block-info__text">
                                        <span>{{__('Active_stat_tournaments')}} </span>
                                        <p style="color: #ffffff">{{info.active}}</p>
                                    </div>
                                </div>

                                <div class="block-info">
                                    <div class="block-info__icon" style="background: #3994FF">
                                        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.8365 0.000370618L13.1982 0.157381C12.8713 0.171132 12.7177 0.539141 12.949 0.753985L13.9757 1.70737L8.78387 6.52826L5.97275 3.91794C5.63224 3.60175 5.08016 3.60175 4.73965 3.91794L0.293315 8.04668C-0.0978115 8.40987 -0.0977636 8.99874 0.293411 9.36184C0.68449 9.72489 1.31851 9.72485 1.70954 9.36175L5.3562 5.97557L8.16775 8.58629C8.50826 8.90248 9.06038 8.90248 9.40089 8.58629L15.3922 3.02261L16.4188 3.97595C16.6502 4.19079 17.0465 4.04811 17.0619 3.74457L17.2304 0.366124C17.2405 0.160697 17.0578 -0.00900314 16.8365 0.000370618V0.000370618Z" fill="#026EED"/>
                                        </svg>
                                    </div>
                                    <div class="block-info__text">
                                        <span>{{__('Completed')}} </span>
                                        <p style="color: #ffffff">{{info.finish}}</p>
                                    </div>
                                </div>

                                <div class="block-info">
                                    <div class="block-info__icon" style="background: #3994FF">

                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.2002 13.9201V12.6401C11.2002 11.9611 10.9305 11.31 10.4504 10.8299C9.9703 10.3498 9.31916 10.0801 8.6402 10.0801H3.5202C2.84125 10.0801 2.1901 10.3498 1.71001 10.8299C1.22992 11.31 0.960205 11.9611 0.960205 12.6401V13.9201" stroke="#026EED" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.08026 7.52C7.49411 7.52 8.64026 6.37385 8.64026 4.96C8.64026 3.54615 7.49411 2.4 6.08026 2.4C4.66641 2.4 3.52026 3.54615 3.52026 4.96C3.52026 6.37385 4.66641 7.52 6.08026 7.52Z" stroke="#026EED" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15.0404 13.9201V12.6401C15.0399 12.0729 14.8511 11.5219 14.5036 11.0736C14.1561 10.6253 13.6696 10.3051 13.1204 10.1633" stroke="#026EED" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.5602 2.4832C11.1108 2.6242 11.5989 2.94445 11.9475 3.39348C12.296 3.84251 12.4852 4.39477 12.4852 4.9632C12.4852 5.53163 12.296 6.08389 11.9475 6.53292C11.5989 6.98195 11.1108 7.30221 10.5602 7.4432" stroke="#026EED" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div class="block-info__text">
                                        <span>{{__('Players')}} </span>
                                        <p style="color: #ffffff">{{info.users}}</p>
                                    </div>
                                </div>

                                <div class="block-info" >
                                    <div class="block-info__icon" style="background: #3994FF">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.01223 1.94479H3.96553V1H12.6564V1.93047H15.6241C15.6241 1.93047 16.005 6.9238 12.5151 8.43432C12.305 9.22856 11.8735 9.9476 11.2703 10.5086C10.6672 11.0696 9.91697 11.4497 9.10609 11.6051V13.4826H11.15V15H5.55632V13.4826H7.57144V11.6154C6.75069 11.4692 5.98893 11.0931 5.37564 10.5313C4.76235 9.96948 4.32299 9.24528 4.10918 8.44376C0.631951 6.92638 1.01223 1.94479 1.01223 1.94479ZM12.6564 6.62033C14.0299 5.57849 14.1188 3.47648 14.1188 3.47648H12.6564V6.62033ZM6.64621 8.01431L8.32423 7.1371L10.0022 8.01431L9.68155 6.15624L11.0392 4.83926L9.16309 4.56814L8.32423 2.87898L7.48536 4.56957L5.60929 4.8407L6.9669 6.15767L6.64621 8.01431ZM3.96553 6.62376V3.4908H2.51724C2.51724 3.4908 2.60561 5.57849 3.96553 6.62376Z" fill="#026EED"/>
                                        </svg>
                                    </div>
                                    <div class="block-info__text">
                                        <span>{{__('Winners')}}</span>
                                        <p style="color: #ffffff">{{info.winners}}</p>
                                    </div>
                                </div>
                            </div>

                            <h5>{{__('Awards received')}}</h5>
                            <div class="wrapper-block-info">
                                <div class="block-info">
                                    <div class="block-info__icon" style="background: #3994FF">
                                        <img src="/dist/images/frontend/light-coin.svg" alt />
                                    </div>
                                    <div class="block-info__text">
                                        <span>{{__('SILVER COIN')}} </span>
                                        <p   style="color: #ffffff">
                                            <!--                                        {{(info.win_mind != 0) ? '+' : ''}}-->
                                            {{info.win_mind}}
                                        </p>
                                    </div>
                                </div>

                                <div class="block-info" >
                                    <div class="block-info__icon" style="background: #3994FF">
                                        <img src="/dist/images/frontend/money.svg" alt />
                                    </div>
                                    <div class="block-info__text">
                                        <span>{{__('GOLD COIN')}}</span>
                                        <p style="color: #ffffff">
                                            <!--                                        {{(info.win_usdt != 0) ? '+' : ''}}-->
                                            {{info.win_usdt}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div v-else>
                <div class="dashboard-content skleton-quest">
                    <div class="left">
                        <SkeletonListAvatarLine :count="5"></SkeletonListAvatarLine>
                    </div>
                    <div class="right">
                        <div class="right">
                            <div class="top">
                                <SkeletonText class="text-top" :count="2"></SkeletonText>
                                <SkeletonListAvatarLine class="text" :count="4"></SkeletonListAvatarLine>
                            </div>
                            <div class="body">
                                <SkeletonText :count="2" class="text"></SkeletonText>
                                <SkeletonButton class="sklet-bottom"></SkeletonButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-games-right">
            <GeneralChatNew></GeneralChatNew>
        </div>

        <ModalResultTournament
            v-if="window_result"
            v-bind:window_result.sync="window_result"
        ></ModalResultTournament>
    </div>
</template>

<script>
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    import ItemTournaments from "./Tournaments/ItemTournaments";
    import ItemUpcomingTournaments from "./Tournaments/ItemUpcomingTournaments";
    import SkeletonText from "../modules/SkeletonText";
    import SkeletonListAvatarLine from "../modules/SkeletonListAvatarLine";
    import DropdownDefault from "../modules/DropdownDefault";
    import SkeletonButton from "../modules/SkeletonButton";
    // import VueSlickCarousel from "vue-slick-carousel";
    import "vue-slick-carousel/dist/vue-slick-carousel.css";
    import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
    import ModalResultTournament from "./Tournaments/ModalResultTournament";
    import moment from "moment";
    import GeneralChatNew from "../modules/GeneralChatNew";

    export default {
        name: 'Tournaments',
        data() {
            return {
                side_tabs: [
                    {
                        'title': 'Actual',
                        'id': 1,
                        'value': 'actual'
                    },
                    {
                        'title': 'Upcoming',
                        'id': 2,
                        'value': 'upcoming'
                    },
                    {
                        'title': 'Archive',
                        'id': 3,
                        'value': 'archive'
                    }
                ],
                progress: 0,
                info: null,
                tournaments: null,
                archive_tournaments: null,
                next_page_url: null,
                settings: {
                    "infinite": true,
                    "arrows": true,
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "autoplay": true,
                    "speed": 2000,
                    "autoplaySpeed": 5000,
                    "responsive": [
                        {
                            "breakpoint": 950,
                            "settings": {
                                "slidesToShow": 1,
                                "slidesToScroll": 1,
                                "initialSlide": 0
                            }
                        },
                    ]
                },
                my_tournaments: false,
                window_result: null,
                interval: null,
                now_date: moment.utc().format('X'),
                currentMoment: moment.utc(),
            }
        },

        watch: {
            currentTab(val) {
                this.my_tournaments = false;
                if (val == 'archive') {
                    this.$addListener(window,'scroll', () => {
                        this.scrollTournaments();
                    });
                } else {
                    this.$removeAllListeners(window,'scroll');
                }
            }
        },
        computed: {
            currentTab: function () {
                return (this.$route.params.current) ? this.$route.params.current : 'actual'
            },
            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.state.language.lang;
            },
        },

        created() {
            this.getTournaments(this.currentTab);

            if (this.currentTab == 'archive') {
                this.$addListener(window,'scroll', () => {
                    this.scrollTournaments();
                });
            }
        },
        mounted() {
            this.updateCountdowns();
            this.interval = setInterval(this.updateCountdowns, 1000);
        },
        methods: {
            isTournamentUpcoming(tournament) {
                const startDate = moment.utc(tournament.date_start);
                return this.currentMoment.isBefore(startDate);
            },
            isTournamentActive(tournament) {
                const startDate = moment.utc(tournament.date_start);
                const endDate = moment.utc(tournament.date_finish);
                return this.currentMoment.isSameOrAfter(startDate) && this.currentMoment.isSameOrBefore(endDate);
            },
            countdownToStart(dateStart) {
                const startDate = moment.utc(dateStart);
                const timeRemaining = startDate.diff(this.currentMoment);
                return this.formatTimeRemaining(timeRemaining);
            },
            countdownToFinish(dateFinish) {
                const endDate = moment.utc(dateFinish);
                const timeRemaining = endDate.diff(this.currentMoment);
                return this.formatTimeRemaining(timeRemaining);
            },
            formatTimeRemaining(timeRemaining) {
                const duration = moment.duration(timeRemaining);
                const days = Math.floor(duration.asDays()); // получаем количество дней
                const hours = duration.hours() + days * 24; // преобразуем дни в часы и добавляем к общим часам
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                const hours1 = hours.toString().padStart(2, '0');
                const minutes1 = minutes.toString().padStart(2, '0');
                const seconds1 = seconds.toString().padStart(2, '0');

                return `${hours1}:${minutes1}:${seconds1}`;
            },
            updateCountdowns() {
                this.currentMoment = moment.utc(); // Обновляем текущее время UTC
            },
            gameTranslation(arr) {
                if (this.$filterArray(arr, this.lang, 'lang')) {
                    return this.$filterArray(arr, this.lang, 'lang');
                } else {
                    return this.$filterArray(arr, 'en', 'lang');
                }
            },
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');

            },
            sideChange(newTab) {
                if (newTab != this.currentTab) {
                    this.$router.push({name: 'tournaments_current', params: {'current': newTab, lang: this.$store.getters.getLang}});
                    this.getTournaments(newTab);
                }
            },

            getTournaments(newTab) {
                let tab = null;
                if (newTab == 'actual') {
                    tab = 1
                } else if (newTab == 'upcoming') {
                    tab = 2
                } else if (newTab == 'archive') {
                    tab = 3
                } else {
                    tab = null
                }

                let response = {
                    type: tab,
                    my_tournaments: this.my_tournaments ? 1 : 0
                };
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/tournaments", response)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        response = response.data;
                        this.tournaments = response;
                        this.window_result = response.window;
                        if (this.currentTab == 'archive') {
                            this.archive_tournaments = response.archive.data;
                            this.next_page_url = response.archive.next_page_url;
                        }
                        this.info = response.info;
                        // if (window.innerWidth > 950) {
                        //     if (this.tournaments && this.tournaments.upcoming && this.tournaments.upcoming.length > 1) {
                        //         this.settings.slidesToShow = 1
                        //     } else {
                        //         this.settings.slidesToShow = 1
                        //     }
                        // }

                        clearInterval(timeout);
                        this.progress = 100;
                        setTimeout(() => {
                            this.progress = 0;
                        }, 500);
                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });
            },
        },
        scrollTournaments () {
            const progress =
                document.documentElement.scrollTop /
                (document.documentElement.scrollHeight -
                    document.documentElement.clientHeight);
            if (!this.loadingPage && this.next_page_url) {
                let bottomOfWindow = 0.7 <= progress;
                if (bottomOfWindow) {
                    this.loadingPage = true;
                    let response = {
                        type: 3
                    };
                    this.$http.post(this.next_page_url, response)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            if(this.$checkAuth(response)) {
                                response = response.data;
                                let archive_tournaments = this.archive_tournaments;
                                this.archive_tournaments = archive_tournaments.concat(response.archive.data);
                                this.next_page_url = response.archive.next_page_url;
                                this.loadingPage = false;
                            }
                        })
                        .catch((err) => {
                            this.httpHandler(err);
                        });
                }
            }
        },
        beforeDestroy() {
            this.$removeAllListeners(window,'scroll');
            clearInterval(this.interval);
        },
        components: {
            GeneralChatNew,
            SkeletonButton,
            SkeletonListAvatarLine,
            SkeletonText,
            ItemTournaments,
            LayoutNavAuth,
            DropdownDefault,
            // VueSlickCarousel,
            ItemUpcomingTournaments,
            ModalResultTournament
        }
    }
</script>

<style lang="scss" scoped>

    .dark .wrap-tournaments {
        h1 {
            color: #86A1C6;
        }
    }

    .wrapper-tournaments {

        .wrap-item-upcoming {
            padding: 0 10px;
        }
        .slick-slider {
            margin: 0 -10px;
        }

        .slick-arrow.slick-prev {
            left: 0!important;
        }

        /*.slick-next {*/
        /*    right: 0;*/
        /*}*/
    }

    .wrap-tournaments {
        display: block;
        padding: 20px;
        border-radius: 0px 0px 8px 8px;
        background: #171827;

        .wrapper-tournaments{
            flex-direction: column;
        }
        .block-upcoming {
            margin-bottom: 30px;

            h1 {
                margin-bottom: 20px;

                a {
                    margin-left: 12px;
                    color: #00AEFF;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        h1 {
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            margin: 0;
        }

        .head-tournaments {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            max-width: calc(100% - 340px);
            width: 100%;

            .block-switch {
                display: flex;
                align-items: center;

                p {
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    margin: 0 10px 0 0;

                    &:last-child {
                        margin: 0 0 0 4px;
                    }
                }
            }
        }
    }

    .dark .block-tournaments {
        &__body {
            background: transparent;
            margin-bottom: 10px;
        }
    }

    .block-tournaments {
        width: 100%;

        &--upcoming {
            width: 100%;
        }

        &__head {
            border-radius: 4px 4px 0px 0px;
            padding: 0 20px;
            height: 64px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            background-color: #fff;
            box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06);

            h5 {
                font-size: 18px;
                line-height: 24px;
                white-space: nowrap;
            }

            .block-title {
                display: flex;
                align-items: center;
                position: relative;
                z-index: 1;

                img {
                    margin-right: 30px;
                    height: 64px;
                }

                p {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    color: #002B50;
                    margin-bottom: 0;
                }

                span {
                    display: block;
                    font-size: 14px;
                    line-height: 18px;
                    color: #6886AF;
                    width: 78%;
                }
            }

            .v-btn.btn-search,
            .v-btn.btn-sorting {
                background: #F0F6FE;
                border-radius: 20px;
                transition: .3s;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 40px;
                    padding: 0;
                }

                &:hover {
                    background-color: #DFEAFC;
                    transition: .3s;
                }

                &.v-btn:before {
                    background-color: #DFEAFC;
                    transition: .3s;
                }

            }

            .wrap-btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                .search {
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    margin-left: 20px;
                    border-left: 1px solid #E7F5FF;

                    &__icon {
                        /*margin-bottom: 3px;*/
                        svg  {
                            margin-right: 0;
                        }
                    }

                    .cancel-icon {
                        cursor: pointer;
                        width: 10px;

                        svg {
                            transition: .3s;
                            margin-right: 0;
                            color: #607EA7;
                        }

                        &:hover {
                            svg {
                                transform: scale(1.15);
                                transition: .3s;
                                color: #26A9F4;
                            }
                        }
                    }
                }

                .v-btn.btn-search,
                .v-btn.btn-sorting {
                    background: #fff;
                    border-radius: 8px;
                    transition: .3s;

                    &.v-btn:not(.v-btn--round).v-size--default {
                        height: 40px;
                        min-width: 40px;
                        padding: 0;
                    }

                    &:hover {
                        background-color: #E7F5FF;
                        transition: .3s;
                    }

                    &.v-btn:before {
                        background-color: #E7F5FF;
                        transition: .3s;
                    }

                }
            }
        }

        &__body {
            border-radius: 4px;
            background-color: #fff;
            margin-bottom: 15px;

            &--upcoming {
                display: flex;
                flex-wrap: wrap;
            }

            .wrapper-item-upcoming {
                width: 100%;
                padding: 0 0px;
                margin-bottom: 10px;
            }
        }
    }

    .dark .sidebar-tournaments .top-block {
        border-radius: 8px;
        background: #026EED;

        h5 {
            color: #fff;
        }

        p {
            color: #ffffff;
        }

        .wrapper-block-info {
            .block-info {
                &__text {
                    span {
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .sidebar-tournaments {
        width: 320px;
        flex-shrink: 0;
        margin-left: 20px;

        .top-block {
            background: #FFFFFF;
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 20px 20px 0;
            position: relative;
            margin-bottom: 20px;

            .bg-gold {
                position: absolute;
                right: 0;
                top: 0;
                z-index: +1;

                svg {
                    border-radius: 0 4px 0 0;
                }
            }

            .bg-silver {
                position: absolute;
                right: 0;
                top: 27px;
            }

            h5 {
                font-size: 18px;
                line-height: 24px;
                color: #ffffff;
                margin-bottom: 10px;
                font-weight: 400;
            }

            p {
                font-size: 13px;
                line-height: 18px;
                color: #ffffff;
                font-weight: 500;
                margin-bottom: 20px;
            }

            .wrapper-block-info {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .block-info {
                    display: flex;
                    align-items: center;
                    width: 130px;
                    margin-bottom: 20px;

                    &__icon {
                        background: rgba(38, 169, 244, 0.15);
                        border-radius: 8px;
                        width: 42px;
                        height: 42px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 12px;
                        flex-shrink: 0;

                        img {
                            height: 20px;
                        }
                    }

                    &__text {
                        span {
                            font-size: 13px;
                            line-height: 18px;
                            letter-spacing: 0.01em;
                            color: #ffffff;
                        }

                        p {
                            font-size: 20px;
                            line-height: 27px;
                            margin: 0;
                            color: #ffffff;
                        }
                    }
                }
            }

            .wrap-btn {
                width: 154px;
                padding-bottom: 20px;
            }

        }
    }

    @media screen and (max-width: 1240px){
        .wrap-tournaments {
            padding: 20px 20px 0;
        }
    }

    @media screen and (max-width: 990px) {
        .wrapper-tournaments {
            /*flex-direction: column-reverse;*/
        }
        .wrap-tournaments {
            .head-tournaments {
                max-width: 100%;
            }
        }
        .block-tournaments {
            width: 100%;

        }
        .sidebar-tournaments {
            width: 100%;
            margin-left: 0;

            .top-block {
                padding: 10px 10px 0;

                &:nth-child(2) {
                    display: none;
                }
                h5 {
                }

                p {
                    font-size: 14px;
                }
                .wrapper-block-info {
                    justify-content: flex-start;

                    .block-info {
                        width: 25%;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 950px){
        .block-tournaments {
            &__body {
                &--upcoming {
                    margin: 0;
                }

                .wrapper-item-upcoming {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
    @media screen and (max-width: 768px){
        .wrap-tournaments {
            padding: 20px 15px 0;
        }
        .sidebar-tournaments {
            .top-block {
                margin-bottom: 15px;
            }
        }
    }

    @media screen and (max-width: 650px){
        .sidebar-tournaments {

            .top-block {
                .wrapper-block-info {
                    justify-content: flex-start;

                    .block-info {
                        width: 50%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 550px){
        .wrap-tournaments {
            .block-upcoming {
                margin-bottom: 20px;
            }
        }

        .block-tournaments {
            &__body {
                &--upcoming {
                }

                .wrapper-item-upcoming {
                    margin-bottom: 15px;
                }
            }
        }

        .sidebar-tournaments {
            .top-block {
                padding: 10px 12px 0;

                h5 {
                }

                p {
                    font-size: 12px;
                }
                .wrapper-block-info {
                    margin-right: 0;

                    .block-info {
                        margin-right: 0;
                        margin-bottom: 10px;

                        &__icon {
                            width: 36px;
                            height: 36px;
                            margin-right: 10px;
                        }

                        &__text {

                            span {
                                font-size: 12px;
                            }
                            p {
                                max-width: fit-content;
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                }

                .bg-gold {
                    svg {
                        width: 108px;
                        height: 80px;
                    }
                }

                .bg-silver {
                    top: 25px;
                    svg {
                        width: 50px;
                        height: 87px;
                    }
                }
            }
        }
    }
</style>