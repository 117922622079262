<template>
  <div class="wrapper-balance">
        <div class="wrap-table">
          <div class="wrap-history__body">
            <div class="header">
              <span>{{__('Operation ID')}}</span>
              <span>{{__('Type')}}</span>
              <span>{{__('PS')}}</span>
              <span>{{__('Amount')}}</span>
              <span>{{__('Details')}}</span>
              <span>{{__('Date/UTC')}}</span>
            </div>
            <div v-if="histories" class="body" ref="transaction_list">
              <TransactionItem v-for="(item, key) in this.histories.data" :item="item" :key="key" :sortPayments="sortPayments(item)"></TransactionItem>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
    import TransactionItem from "@/components/pages/TransactionHistory/TransctionItem";

    export default {
        name: 'TransactionList',
        data () {
            return {
              headers_transactions: [
                  'Operation ID', 'Type', 'Payment System', 'Amount', 'Details', 'Date/UTC'
              ],
              loadingPage: false
            }
        },

        props: {
          histories: {
            type: Object
          },
          payments: {
            type: Array
          },
          next_page_url: {
            type: String
          },
          form: {
              type: Object
          }
        },

        computed: {

        },
        asyncComputed: {

        },
        methods: {
          sortPayments(item) {
              if (item.payment_id) {
                  let payment_id = item.payment_id;
                  const payments = this.payments.find(function (payment) {
                      return payment.id == payment_id;
                  })
                  return payments;
              }
          },

          // scrollHistories: function () {
          //   let transaction_list = this.$refs.transaction_list;
          //   const progress = transaction_list.scrollTop / (transaction_list.scrollHeight - transaction_list.clientHeight);
          //   if (!this.loadingPage && this.next_page_url) {
          //     let bottomOfWindow = 0.7 <= progress;
          //     if (bottomOfWindow) {
          //       this.loadingPage = true;
          //       let response = this.form;
          //       this.$http.post(this.next_page_url, response)
          //           .then((response) => {
          //             return response.json();
          //           })
          //           .then((response) => {
          //             if(this.$checkAuth(response)) {
          //                 response = response.data;
          //                 let histories = this.histories.data;
          //                 this.histories.data = histories.concat(response.histories.data);
          //                 this.next_page_url = response.histories.next_page_url;
          //                 this.loadingPage = false;
          //             }
          //           })
          //           .catch((err) => {
          //             this.httpHandler(err);
          //           });
          //     }
          //   }
          // },
        },
        components: {
            // Table,
            TransactionItem
        },

    }
</script>

<style lang="scss">

  .dark {
    .wrapper-balance {

      .wrap-history {

        .wrap-table {
          background: #171827;
        }

        &__body {
          .header {
            background: #181818;

            span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .wrapper-balance {

    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #002B50;
      margin-top: -5px;
      margin-bottom: 16px;
    }

    .block-balance {
      padding: 0 0 0 12px;
    }

    .wrap-history {
      padding: 0 12px 0 16px;

      .wrap-table {
        background-color: #FFFFFF;
        border-radius: 8px;
        height: 100%;
      }

      &__head {
        background: #fff;
        border-radius: 8px 8px 0px 0px;
        border-bottom: 2px solid #f2f3fb;
        padding: 22px 26px 21px;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;

          .icon-deposit {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              background: rgba(0,196,67,.3);
              margin-right: 25px;
              border-radius: 4px;
          }

          .icon-withdraw {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 20px;
              height: 20px;
              background: rgba(255, 75, 85, 0.3);
              margin-right: 25px;
              border-radius: 4px;

              svg{
                  fill: red;
                  transform: rotate(180deg);
              }
          }
      }

      &__body {

        .header {
          background: #ECF2FE;
          display: flex;
          align-items: center;
          padding: 0 26px;
          height: 36px;


          span {
            width: 15%;
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #6886AF;

            &:nth-child(5) {
              width: 25%;
            }

            &:last-child {
              text-align: right;
            }
          }
        }

        .body {
          min-height: calc(100vh - 256px);
          border-radius: 0 0 4px 4px;

            &::-webkit-scrollbar-track {

            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(150, 175, 209, 0.3);
                border-radius: 10px;
            }
        }
      }

    }






    .table {
      margin-right: 0px;

      .pic {
        margin: 0 5px 0 0;
      }

      th {
        border-bottom: 1px solid #ecf4ff;
        padding: 18px 0px;
        padding-left: 26px;

          &:last-child {
            padding-right: 26px;
            padding-left: 0;
            text-align: right;
          }
      }

      tr {
        padding: 18px 26px;
        border-bottom: 1px solid #ecf4ff;
      }

      td {
        font-size: 14px;
        color: #002B50;
        padding: 22px 26px;

        &:last-child {
          text-align: right;
        }
      }

        td.details {
            width: 350px;
        }

      td.date {
        width: 165px;
      }

        .block-success {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: #CDFFCD;
            border-radius: 10px;
            color: #00C443;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        .block-expectation {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: rgba(246, 164, 1, 0.1);
            border-radius: 10px;
            color: #F6A401;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        .block-rejected {
            display: flex;
            align-items: center;
            padding: 3px 15px 3px 10px;
            background: rgba(255, 75, 85, 0.1);
            border-radius: 10px;
            color: #FF4B55;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        .block-real {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #00C443;
        }

        .block-lite {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #6886AF;
        }
    }
  }


@media screen and (max-width: 1400px) {
    .wrapper-balance {
        .wrap-history {
            &__body {
                .header {
                    span {
                        width: 15%;

                        &:nth-child(3) {
                            width: 10%;
                        }
                        &:nth-child(4) {
                            width: 20%;
                        }
                        &:nth-child(5) {
                            width: 25%;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 950px) {
    .wrapper-balance {
        .wrap-history {
            &__body {
                .header {
                    padding: 0 15px;

                    span {
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .wrapper-balance {
        .wrap-history {
            &__body {
                .header {
                    padding: 0 40px 0 15px;
                    span {
                        width: 20%;
                        &:nth-child(3) {
                            width: 15%;
                        }
                        &:nth-child(4) {
                            width: 25%;
                        }
                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .wrapper-balance {
        .wrap-history {
            &__body {
                .header {
                    padding: 0 3px 0 15px;
                    span {
                        width: 25%;
                        &:first-child {
                            display: none;
                        }
                        &:nth-child(2) {
                            flex-shrink: 0;
                        }
                        &:nth-child(3) {
                            width: 20%;
                        }
                        &:nth-child(4) {
                            width: 25%;
                        }

                    }
                }
            }
        }
    }
}


</style>
