<template>
    <div class="page-store">
        <LayoutNavAuth>
            <div class="wrap-tabs pa-0">
                <tabs
                        :tabs="side_tabs"
                        :currentTab="currentTab"
                        :wrapper-class="'default-tabs'"
                        :tab-class="'default-tabs__item'"
                        :tab-active-class="'default-tabs__item_active'"
                        :line-class="'default-tabs__active-line'"
                        @onClick="sideChange"
                />
            </div>
            <div class="wrap-select">
                <DropdownDefault
                        class_modal="modal_game"
                        color="select_game"
                        v-bind:dropdown="side_tabs"
                        v-bind:value.sync="side_tabs.value"
                        @updateValue="sideChange"
                />
            </div>

            <div class="loading"  :style="{width: progress + '%'}"></div>
        </LayoutNavAuth>

        <div class="dashboard-content">
            <div class="page-store__head">
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('All')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Caps and hats')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Shirts and hoodies')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Accessories')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Gaming')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Bags')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Sport')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('Other')}}</span>
                </v-btn>
                <v-btn
                        v-ripple="false"
                        text
                        class="dark-btn"
                >
                    <span>{{__('NFT')}}</span>
                </v-btn>
            </div>

            <div class="page-store__body">
                <div class="wrapper-block-top">
                    <div class="block-card">
                        <div class="block-card__info">
                            <div>
                                <p>{{__('YOUR NEW MERCH')}}</p>
                                <span>{{__('Upgrade your regular outfit to the new Mindplays branded top items')}}</span>
                            </div>
                            <a href="#">{{__('View more')}}</a>
                        </div>
                        <img src="/dist/images/frontend/store/store-top-1.png" alt="">
                    </div>

                    <div class="block-card">
                        <div class="block-card__info">
                            <div>
                                <p>{{__('accessories')}}</p>
                                <span>{{__('We brought the most in-demand high tech accessories that are making it')}}</span>
                            </div>
                            <a href="#">{{__('View more')}}</a>
                        </div>
                        <img src="/dist/images/frontend/store/store-top-2.png" alt="">
                    </div>

                    <div class="block-card">
                        <div class="block-card__info">
                            <div>
                                <p>{{__('PRO GAMER')}}</p>
                                <span>{{__('Explore the most latest hardware that will take your game to the new level')}}</span>
                            </div>
                            <a href="#">{{__('View more')}}</a>
                        </div>
                        <img src="/dist/images/frontend/store/store-top-3.png" alt="">
                    </div>
                </div>

                <div class="block-products">
                    <div class="block-products__head">
                        <div class="block-left">
                            <div class="block-select">
                                <DropdownDefault
                                        class_modal="modal_game"
                                        color="select_game"
                                        v-bind:dropdown="side_tabs"
                                        v-bind:value.sync="side_tabs.value"
                                        @updateValue="sideChange"
                                />
                            </div>

                            <v-btn
                                    v-ripple="false"
                                    text
                                    class="dark-btn-round"
                            >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="8.4819" cy="8.4819" rx="7.48191" ry="7.4819" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.6857 14.0743L16.619 17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </v-btn>
                        </div>

                        <div class="block-right">
                            <v-btn
                                    v-ripple="false"
                                    text
                                    class="dark-btn-round"
                            >
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.27778 3.63981C6.27778 5.0963 5.0963 6.2787 3.63889 6.2787C2.18148 6.2787 1 5.0963 1 3.63981C1 2.18241 2.18148 1 3.63889 1C5.0963 1 6.27778 2.18241 6.27778 3.63981Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.93262 3.63981H16.278" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0005 12.8666C11.0005 11.4092 12.182 10.2277 13.6394 10.2277C15.0968 10.2277 16.2783 11.4092 16.2783 12.8666C16.2783 14.324 15.0968 15.5055 13.6394 15.5055C12.182 15.5055 11.0005 14.324 11.0005 12.8666Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.34586 12.8666H1.00049" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </v-btn>
                        </div>
                    </div>

                    <div class="list-products">
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                        <div class="wrap-item-product">
                            <ItemProduct></ItemProduct>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    import ItemProduct from "./Store/ItemProduct";
    import DropdownDefault from "../modules/DropdownDefault";
    export default {
        data() {
            return {
                side_tabs: [
                    {
                        'title': 'Merch store',
                        'value': 'store'
                    },
                    {
                        'title': 'My orders',
                        'value': 'orders'
                    }
                ],
                loading_page: false,
                progress: 0,
            }
        },
        computed: {
            currentTab: function () {
                return (this.$route.params.current) ? this.$route.params.current : 'store'
            },
        },
        methods: {
            sideChange(newTab) {
                if (newTab != this.currentTab) {
                    this.$router.push({name: 'quests_current', params: {'current': newTab, lang: this.$store.getters.getLang}});
                    // this.data = false;
                    this.getQuests(newTab);
                }
            },
        },
        components: {
            LayoutNavAuth,
            ItemProduct,
            DropdownDefault
        }
    }
</script>

<style lang="scss" scoped>
    .page-store {
        &__head {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0 64px;

            .v-btn.dark-btn {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__body {

            .wrapper-block-top {
                display: flex;
                margin-bottom: 40px;
                .block-card {
                    width: 33%;
                    padding: 13px 0 25px 23px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    position: relative;
                    height: 215px;

                    img {
                        position: absolute;
                        right: 5px;
                        bottom: 15px;
                    }

                    &__info {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        max-width: 200px;
                        p {
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 27px;
                            letter-spacing: 0.055em;
                            text-transform: uppercase;
                            color: #FFFFFF;
                            margin: 0;
                        }

                        span {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            color: #FFFFFF;
                            display: block;
                        }

                        a {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19px;
                            color: #FFFFFF;

                            &:hover {
                                text-decoration: underline;
                            }

                        }
                    }

                    &:first-child {
                        background: linear-gradient(180deg, #2E68FF 0%, rgba(185, 154, 251, 0.62) 100%);
                    }
                    &:nth-child(2) {
                        background: linear-gradient(180deg, #006CEA 0%, #00AEFF 100%);
                        margin: 0 30px;
                    }
                    &:nth-child(3) {
                        background: linear-gradient(180deg, #202020 0%, #2F2F2F 100%);

                        .block-card__info {
                            max-width: 175px;
                        }
                    }
                }
            }

            .block-products {
                &__head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 40px;
                    width: 100%;

                    .block-left {
                        display: flex;
                        align-items: center;

                        .block-select {
                            margin-right: 10px;
                        }
                    }
                }
                .list-products {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -15px;

                    .wrap-item-product {
                        padding: 0 15px;
                        width: 25%;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
</style>