<template>
  <div class="nft-new">
    <div class="top-nft">
      <div class="dashboard-content">
        <div class="left">
          <h1>Create, Explore & Collect Digital NFTs</h1>
          <p class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo elementum cras integer proin interdum. Sed morbi massa enim aliquam.</p>
          <a href="#" class="baseButton"><span>Login on Mindplays</span></a>
        </div>
        <div class="right">
          <carousel-3d :autoplay="true" :autoplay-timeout="3000" :autoplayHoverPause="true" :border="0" :height="500">
            <slide :index="0">
              <div class="block-slide">
                <div class="top-block">
                  <div class="img-top">
                  </div>
                  <div class="img">
                  </div>
                  <div class="img">
                  </div>
                  <div class="img">
                    <p>+1,245</p>
                  </div>
                </div>
                <div class="top-text">
                  <p class="name">NEON Tanks 2023</p>
                  <div class="text-block">
                    <div class="game">
                      <div class="img"></div>
                      <p>BATTLE TANKS</p>
                    </div>
                    <p class="wall">0x54...70ed</p>
                  </div>
                </div>
                <div class="itm">
                  <div class="itm-block">
                    <div>
                      <p>Items</p>
                      <span>1,456</span>
                    </div>
                    <div>
                      <p>Estimated Value</p>
                      <span>
                        <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                        1-100
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <slide :index="1">
              <div class="block-slide">
                <div class="top-block">
                  <div class="img-top">
                  </div>
                  <div class="img">
                  </div>
                  <div class="img">
                  </div>
                  <div class="img">
                    <p>+1,245</p>
                  </div>
                </div>
                <div class="top-text">
                  <p class="name">NEON Tanks 2023</p>
                  <div class="text-block">
                    <div class="game">
                      <div class="img"></div>
                      <p>BATTLE TANKS</p>
                    </div>
                    <p class="wall">0x54...70ed</p>
                  </div>
                </div>
                <div class="itm">
                  <div class="itm-block">
                    <div>
                      <p>Items</p>
                      <span>1,456</span>
                    </div>
                    <div>
                      <p>Estimated Value</p>
                      <span>
                        <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                        1-100
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
            <slide :index="2">
              <div class="block-slide">
                <div class="top-block">
                  <div class="img-top">
                  </div>
                  <div class="img">
                  </div>
                  <div class="img">
                  </div>
                  <div class="img">
                    <p>+1,245</p>
                  </div>
                </div>
                <div class="top-text">
                  <p class="name">NEON Tanks 2023</p>
                  <div class="text-block">
                    <div class="game">
                      <div class="img"></div>
                      <p>BATTLE TANKS</p>
                    </div>
                    <p class="wall">0x54...70ed</p>
                  </div>
                </div>
                <div class="itm">
                  <div class="itm-block">
                    <div>
                      <p>Items</p>
                      <span>1,456</span>
                    </div>
                    <div>
                      <p>Estimated Value</p>
                      <span>
                        <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                        1-100
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </slide>
          </carousel-3d>
        </div>
      </div>
    </div>

    <div class="trading dashboard-content">
      <div class="top">
        <p class="text">Trending Collections</p>
        <div>
          <p class="sort">Last 24 hours</p>
          <a href="#">
            <span>View all</span>
            <img src="/dist/images/frontend/icon-right.svg" alt="">
          </a>
        </div>
      </div>
      <div class="content">
        <ul>
          <li>
            <div class="num">
              <p>1</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <img src="/dist/images/frontend/nft-plus.svg" alt="">
                <!--            <img src="/dist/images/frontend/nft-minus.svg" alt="">-->
                <p class="plus">+46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p>1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>2</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="minus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>3</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>4</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>5</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
        </ul>
        <ul>
          <li>
            <div class="num">
              <p>6</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <img src="/dist/images/frontend/nft-plus.svg" alt="">
                <!--            <img src="/dist/images/frontend/nft-minus.svg" alt="">-->
                <p class="plus">+46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>7</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>8</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>9</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
          <li>
            <div class="num">
              <p>10</p>
            </div>
            <div class="img"></div>
            <div class="text">
              <p class="name">Hamlet Contemplates collection Hamlet Contemplates collection lo</p>
              <div class="summ">
                <p>Floor:</p>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <span>1,000,459.89</span>
              </div>
            </div>
            <div class="percent">
              <div>
                <!--              <img src="/dist/images/frontend/nft-plus.svg" alt="">-->
                <img src="/dist/images/frontend/nft-minus.svg" alt="">
                <p class="plus">-46.92%</p>
              </div>
              <div>
                <img src="/dist/images/frontend/nft-img.svg" alt="">
                <p class="plus">1,000,459.89</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>

    <div class="latest dashboard-content">
      <div class="top">
        <h2>Latest collections</h2>
        <a href="#">
          <span>View all</span>
          <img src="/dist/images/frontend/icon-right.svg" alt="">
        </a>
      </div>
      <ul>
        <li>
          <div class="top-block">
            <div class="img-top">
            </div>
            <div class="img">
            </div>
            <div class="img">
            </div>
            <div class="img">
              <p>+1,245</p>
            </div>
          </div>
          <div class="top-text">
            <p class="name">NEON Tanks 2023</p>
            <div class="text-block">
              <div class="game">
                <div class="img"></div>
                <p>BATTLE TANKS</p>
              </div>
              <p class="wall">0x54...70ed</p>
            </div>
          </div>
          <div class="itm">
            <div class="itm-block">
              <div>
                <p>Items</p>
                <span>1,456</span>
              </div>
              <div>
                <p>Estimated Value</p>
                <span>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </span>
              </div>
            </div>
            <a href="#" class="baseButton">
              <span>
                 <img src="/dist/images/frontend/games-right.svg" alt="">
              </span>
            </a>
          </div>
        </li>
        <li>
          <div class="top-block">
            <div class="img-top">
            </div>
            <div class="img">
            </div>
            <div class="img">
            </div>
            <div class="img">
              <p>+1,245</p>
            </div>
          </div>
          <div class="top-text">
            <p class="name">NEON Tanks 2023</p>
            <div class="text-block">
              <div class="game">
                <div class="img"></div>
                <p>BATTLE TANKS</p>
              </div>
              <p class="wall">0x54...70ed</p>
            </div>
          </div>
          <div class="itm">
            <div class="itm-block">
              <div>
                <p>Items</p>
                <span>1,456</span>
              </div>
              <div>
                <p>Estimated Value</p>
                <span>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </span>
              </div>
            </div>
            <a href="#" class="baseButton">
              <span>
                 <img src="/dist/images/frontend/games-right.svg" alt="">
              </span>
            </a>
          </div>
        </li>
        <li>
          <div class="top-block">
            <div class="img-top">
            </div>
            <div class="img">
            </div>
            <div class="img">
            </div>
            <div class="img">
              <p>+1,245</p>
            </div>
          </div>
          <div class="top-text">
            <p class="name">NEON Tanks 2023</p>
            <div class="text-block">
              <div class="game">
                <div class="img"></div>
                <p>BATTLE TANKS</p>
              </div>
              <p class="wall">0x54...70ed</p>
            </div>
          </div>
          <div class="itm">
            <div class="itm-block">
              <div>
                <p>Items</p>
                <span>1,456</span>
              </div>
              <div>
                <p>Estimated Value</p>
                <span>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </span>
              </div>
            </div>
            <a href="#" class="baseButton">
              <span>
                 <img src="/dist/images/frontend/games-right.svg" alt="">
              </span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    
    <div class="games dashboard-content">
      <div class="top">
        <h2>Top games by NFTs</h2>
        <a href="#">
          <span>View all</span>
          <img src="/dist/images/frontend/icon-right.svg" alt="">
        </a>
      </div>
      <ul>
        <li>
          <div class="img"></div>
          <p class="name">NEON Tanks 2023</p>
          <p class="collections">Collections <span>1,456</span></p>
        </li>
        <li>
          <div class="img"></div>
          <p class="name">NEON Tanks 2023</p>
          <p class="collections">Collections <span>1,456</span></p>
        </li>
        <li>
          <div class="img"></div>
          <p class="name">NEON Tanks 2023</p>
          <p class="collections">Collections <span>1,456</span></p>
        </li>
        <li>
          <div class="img"></div>
          <p class="name">NEON Tanks 2023</p>
          <p class="collections">Collections <span>1,456</span></p>
        </li>
        <li>
          <div class="img"></div>
          <p class="name">NEON Tanks 2023</p>
          <p class="collections">Collections <span>1,456</span></p>
        </li>
        <li>
          <div class="img"></div>
          <p class="name">NEON Tanks 2023</p>
          <p class="collections">Collections <span>1,456</span></p>
        </li>
      </ul>
    </div>

    <div class="random dashboard-content">
      <div class="top">
        <h2>Random NFTs</h2>
        <a href="#">
          <span>View all</span>
          <img src="/dist/images/frontend/icon-right.svg" alt="">
        </a>
      </div>
      <ul>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
        <li>
          <div class="img"></div>
          <div class="content">
            <p class="name">NEON Tanks 2023</p>
            <div class="price">
              <p>
                <img src="/dist/images/frontend/light-coin.svg" alt="" class="pic">
                1-100
              </p>
              <a href="#">
                <span>View details</span>
                <img src="/dist/images/frontend/icon-details.svg" alt="">
              </a>
            </div>
            <div class="btn-block">
              <a href="#" class="add">Add to Cart</a>
              <a href="#" class="baseButton"><span>Buy now</span></a>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>


<script>
  import { Carousel3d, Slide } from 'vue-carousel-3d';
export default {
  name: "NftNew",
  data() {
    return {

    };
  },

  watch: {

  },
  created() {

  },
  computed: {

  },
  methods: {

  },
  components: {
    Carousel3d,
    Slide
  },
};
</script>