<template>
  <div class="wrap-item-history">
    <v-card @click="open = !open" v-ripple="false">
      <div class="block-item">
        <v-list class="block-game">
          <v-list-item class="text-left pa-0">
            <div class="block-img">
              <router-link :to="{name: 'game', params: {id: item.game.url, lang: $store.getters.getLang}}"  class="mb-0">
                <div class="wrap-img">
                  <img v-if="typeof item.game.logo == 'object'" :src="item.game.logo[100]">
                  <img v-else :src="item.game.logo">
                </div>
              </router-link>
            </div>
            <v-list-item-content class="pa-0">
              <router-link class="" :to="{name: 'game', params: {id: item.game.url, lang: $store.getters.getLang}}">{{game_array.name}}</router-link>
              <v-list-item-subtitle class="d-flex align-center">
<!--                <span class="platform" v-if="item.platform_id">-->
<!--                    <img src="/dist/images/frontend/brouser.svg" alt />-->
<!--                </span>-->

                <div class="score" v-if="item.type == 5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M7.23538 1.00149L7.23577 1.00229L8.79431 4.13428L8.7943 4.13429L8.79581 4.13728C8.98388 4.50894 9.34266 4.76782 9.75808 4.82604C9.75874 4.82613 9.7594 4.82622 9.76005 4.82631L13.2574 5.33569L13.2599 5.33604C13.3349 5.34658 13.4021 5.38606 13.4469 5.44497L13.4472 5.44528C13.4894 5.50062 13.5074 5.57068 13.4972 5.63928L13.497 5.64071C13.4884 5.69988 13.4605 5.75522 13.4175 5.79796C13.4171 5.79827 13.4168 5.79858 13.4165 5.79889L10.886 8.25187C10.5774 8.54036 10.4391 8.96464 10.5136 9.37755L10.5136 9.37776L11.137 12.8284C11.1583 12.9751 11.0629 13.112 10.9192 13.1433C10.8578 13.1516 10.7932 13.141 10.7342 13.1114L7.61407 11.4858L7.61409 11.4857L7.60832 11.4828C7.2349 11.2944 6.79394 11.2944 6.42051 11.4828L6.42049 11.4828L6.41476 11.4858L3.29209 13.1127L3.29208 13.1127L3.28857 13.1146C3.14633 13.1901 2.97154 13.1407 2.88971 13.0038C2.86414 12.9519 2.85461 12.8931 2.8627 12.8347L3.48583 9.3848C3.48584 9.38478 3.48584 9.38476 3.48584 9.38474C3.56047 8.97171 3.42082 8.54634 3.11362 8.25837L0.580323 5.80407L0.579702 5.80347C0.476813 5.70413 0.473532 5.54235 0.569923 5.43952L0.586023 5.42341L0.586036 5.42343L0.58906 5.42035C0.62983 5.37886 0.683104 5.35268 0.740936 5.34581L0.740957 5.34598L0.754066 5.34407L4.25281 4.8338C4.25316 4.83375 4.25351 4.8337 4.25386 4.83365C4.66677 4.77479 5.02614 4.51856 5.21553 4.14427L5.21563 4.14433L5.22038 4.13441L6.72087 0.999658C6.76859 0.906092 6.86589 0.847606 6.97233 0.850082L6.97233 0.850218H6.98396H7.04006C7.12349 0.867278 7.19619 0.922403 7.23538 1.00149Z" stroke="#01DF72"/>
                    </svg>
                    <span>{{item.score}}</span>
                </div>

                <div class="time">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0.9C3.1878 0.9 0.9 3.1878 0.9 6C0.9 8.8122 3.1878 11.1 6 11.1C8.8122 11.1 11.1 8.8122 11.1 6C11.1 3.1878 8.8122 0.9 6 0.9ZM6 12C2.6916 12 0 9.3084 0 6C0 2.6916 2.6916 0 6 0C9.3084 0 12 2.6916 12 6C12 9.3084 9.3084 12 6 12Z" fill="#96AFD1"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.05871 8.21545C7.98011 8.21545 7.90091 8.19505 7.82831 8.15245L5.56631 6.80305C5.43071 6.72145 5.34671 6.57445 5.34671 6.41605V3.50725C5.34671 3.25885 5.54831 3.05725 5.79671 3.05725C6.04571 3.05725 6.24671 3.25885 6.24671 3.50725V6.16045L8.28971 7.37845C8.50271 7.50625 8.57291 7.78225 8.44571 7.99585C8.36111 8.13685 8.21171 8.21545 8.05871 8.21545Z" fill="#96AFD1"/>
                  </svg>

                  <span>{{ConvertTime(item.second_game)}}</span>
                </div>
<!--                  <span class="platform">{{$platform(item.platform_id)}}</span>-->
<!--                  <div class="time">Play time-->
<!--                    <span>{{$timeFormatted(item.second_game)}}</span>-->
<!--                  </div>-->
<!--                  <v-btn icon text v-ripple="{ class:'secondary&#45;&#45;text'}" class="btn-play">-->
<!--                    <img src="/dist/images/frontend/play-grey.svg" alt />-->
<!--                  </v-btn>-->
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="right-block-item">
            <div class="block-vs" v-if="item.type == 5">
                {{__('SOLO')}}
                <div class="timestamp">{{$minAgo(start_timestamp)}} {{__('ago')}}</div>
            </div>
            <div class="block-vs" v-else>
                {{__('P2P')}}
                <div class="timestamp">{{$minAgo(start_timestamp)}} {{__('ago')}}</div>
            </div>

            <div class="block-many-user" v-if="item.users.length > 1">
                <div class="users">
                    <router-link class="wrap-img" v-for="(user, key) in item.users.slice(0,6)" :key="key" :to="{name: 'profile', params: {id: item.public, lang: $store.getters.getLang}}">
                        <!--                 <img :src="((user.img) ? user.img : '/assets/users/a-default.svg')">-->
                        <img v-if="user.img && typeof user.img == 'object'" :src="user.img[40]">
                        <img v-else-if="user.img" :src="user.img">
                        <div v-else class="photo" :style="{'background': user.bg_color}">{{user.name.substr(0,1)}}</div>
                    </router-link>
                </div>

                <span @click="modal_rivals = true" class="counter" v-if="item.users.length > 6">+{{Number(item.users.length) - 6}}</span>
            </div>

            <div class="block-one-user" v-else>
                <v-list class="pa-0">
                    <v-list-item>
                        <div style="position: relative">
                            <router-link :to="{name: 'profile', params: {id: item.users[0].public, lang: $store.getters.getLang}}" class="mb-0 img">
                                <div class="wrap-img" :class="(item.users[0].pro) ? 'user-pro-img' : ''">
                                    <!--                    <img :src="'/assets/users/'+((item.users[0].img) ? item.users[0].img : 'a-default.svg')">-->

                                    <img v-if="item.users[0].img && typeof item.users[0].img == 'object'" :src="item.users[0].img[40]">
                                    <img v-else-if="item.users[0].img" :src="item.users[0].img">
                                    <span v-else class="photo" :style="{'background': item.users[0].bg_color}">{{item.users[0].name.substr(0,1)}}</span>
                                    <div v-if="now_date < date" class="online"></div>
                                </div>
                            </router-link>
                        </div>
                        <v-list-item-content class="pa-0">
                            <v-list-item-title>
                                <router-link :to="{name: 'profile', params: {id: item.users[0].public, lang: $store.getters.getLang}}" :class="(item.users[0].pro) ? 'user-pro' : ''" class="mb-0">{{item.users[0].name}}</router-link>
                                <img width="14" class="mr-1" :src="item.users[0].location_img">
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="score" v-if="item.type == 5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M7.23538 1.00149L7.23577 1.00229L8.79431 4.13428L8.7943 4.13429L8.79581 4.13728C8.98388 4.50894 9.34266 4.76782 9.75808 4.82604C9.75874 4.82613 9.7594 4.82622 9.76005 4.82631L13.2574 5.33569L13.2599 5.33604C13.3349 5.34658 13.4021 5.38606 13.4469 5.44497L13.4472 5.44528C13.4894 5.50062 13.5074 5.57068 13.4972 5.63928L13.497 5.64071C13.4884 5.69988 13.4605 5.75522 13.4175 5.79796C13.4171 5.79827 13.4168 5.79858 13.4165 5.79889L10.886 8.25187C10.5774 8.54036 10.4391 8.96464 10.5136 9.37755L10.5136 9.37776L11.137 12.8284C11.1583 12.9751 11.0629 13.112 10.9192 13.1433C10.8578 13.1516 10.7932 13.141 10.7342 13.1114L7.61407 11.4858L7.61409 11.4857L7.60832 11.4828C7.2349 11.2944 6.79394 11.2944 6.42051 11.4828L6.42049 11.4828L6.41476 11.4858L3.29209 13.1127L3.29208 13.1127L3.28857 13.1146C3.14633 13.1901 2.97154 13.1407 2.88971 13.0038C2.86414 12.9519 2.85461 12.8931 2.8627 12.8347L3.48583 9.3848C3.48584 9.38478 3.48584 9.38476 3.48584 9.38474C3.56047 8.97171 3.42082 8.54634 3.11362 8.25837L0.580323 5.80407L0.579702 5.80347C0.476813 5.70413 0.473532 5.54235 0.569923 5.43952L0.586023 5.42341L0.586036 5.42343L0.58906 5.42035C0.62983 5.37886 0.683104 5.35268 0.740936 5.34581L0.740957 5.34598L0.754066 5.34407L4.25281 4.8338C4.25316 4.83375 4.25351 4.8337 4.25386 4.83365C4.66677 4.77479 5.02614 4.51856 5.21553 4.14427L5.21563 4.14433L5.22038 4.13441L6.72087 0.999658C6.76859 0.906092 6.86589 0.847606 6.97233 0.850082L6.97233 0.850218H6.98396H7.04006C7.12349 0.867278 7.19619 0.922403 7.23538 1.00149Z" stroke="#01DF72"/>
                                    </svg>
                                    <span>{{item.score_opponent}}</span>
                                </div>

<!--                                {{$getLocation(item.users[0].location)}}-->
                                <!--                  <span class="rating">-->
                                <!--                    <img class="pic" src="/dist/images/frontend/fire.svg" alt />-->
                                <!--                    +{{item.users[0].rating_points}}-->
                                <!--                  </span>-->
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>

            <div class="block-info">
                <p v-if="item.income == 1" class="blue--text mb-0">
                    {{__('WINNER!')}}
                    <span class="d-flex ma-0 wrapper-info">
                <span class="mr-2" :class="(item.wallet== 1) ? 'warning--text': 'grey--text'" v-if="item.amount">
                  <img v-if="item.wallet== 1" class="pic" src="/dist/images/frontend/money.svg" alt />
                  <img v-else class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                  +{{primNum(item.total_amount, 2)}}
                </span>
                <span v-if="item.rating_points" class="warning--text">
                  <img class="pic" src="/dist/images/frontend/fire.svg" alt />
                  +{{item.rating_points}}
                </span>
            </span>
                </p>

                <p v-else-if="item.income== 2" class="red--text mb-0">
                    {{__('LOOSE!')}}
                    <span class="d-flex ma-0 wrapper-info">
            <span class="mr-2" :class="(item.wallet== 1) ? 'warning--text': 'grey--text'" v-if="item.amount">
                  <img v-if="item.wallet== 1" class="pic" src="/dist/images/frontend/money.svg" alt />
                  <img v-else class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                  -{{primNum(item.total_amount, 2)}}
                </span>
            <span v-if="item.rating_points" class="warning--text">
                  <img class="pic" height="12px" src="/dist/images/frontend/fire.svg" alt />
                  +{{item.rating_points}}
                </span>
            </span>
                </p>

                <p v-else-if="item.income == 3" class="grey--text mb-0">
                    {{__('DRAW!')}}
                    <span class="d-flex ma-0 wrapper-info">
            <span class="mr-2" :class="(item.wallet== 1) ? 'warning--text': 'grey--text'" v-if="item.amount">
                  <img v-if="item.wallet== 1" class="pic" src="/dist/images/frontend/money.svg" alt />
                  <img v-else class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                  {{primNum(item.total_amount, 2)}}
                </span>
            <span v-if="item.rating_points" class="warning--text">
                  <img class="pic" src="/dist/images/frontend/fire.svg" alt />
                  +{{item.rating_points}}
                </span>
            </span>
                </p>

                <p v-else class="grey--text mb-0">
                    {{__('CANCELED')}}
                    <span class="d-flex ma-0 wrapper-info">
            <span class="mr-2" :class="(item.wallet== 1) ? 'warning--text': 'grey--text'" v-if="item.amount">
                  <img v-if="item.wallet== 1" class="pic" src="/dist/images/frontend/money.svg" alt />
                  <img v-else class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                  {{primNum(item.total_amount, 2)}}
                </span>
            <span v-if="item.rating_points" class="warning--text">
                  <img class="pic" src="/dist/images/frontend/fire.svg" alt />
                  +{{item.rating_points}}
                </span>
            </span>
                </p>
            </div>
        </div>
      </div>
    </v-card>
<!--    <VueSlideToggle :open="open" tag="section" :duration="500">-->
<!--      <div class="slide-block">-->
<!--&lt;!&ndash;        <v-btn class="btn-cab white&#45;&#45;text" text v-ripple="{ class:'secondary&#45;&#45;text'}">Game history log</v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;        <p class="mb-0">You can download the game log file and make sure the result is correct</p>&ndash;&gt;-->
<!--&lt;!&ndash;        <span class="platform">{{$platform(item.platform_id)}}</span>&ndash;&gt;-->
<!--        <span class="platform" v-if="item.platform_id">-->
<!--            <img src="/dist/images/frontend/brouser.svg" alt />-->
<!--        </span>-->
<!--        <div class="time">Play time-->
<!--          <span>{{$timeFormatted(item.second_game)}}</span>-->
<!--        </div>-->
<!--        <a class="btn-play">-->
<!--          <img src="/dist/images/frontend/play-grey.svg" alt />-->
<!--          <img class="img-hov" src="/dist/images/frontend/play-blue.svg" alt />-->
<!--          Watch the game recording-->
<!--        </a>-->
<!--        <a class="btn-download">-->
<!--          <img src="/dist/images/frontend/log.svg" alt />-->
<!--          <img class="img-hov" src="/dist/images/frontend/log-blue.svg" alt />-->
<!--          Download Log file-->
<!--        </a>-->
<!--      </div>-->
<!--    </VueSlideToggle>-->
      <ModalRivalsMultiplayer v-if="modal_rivals" :modal_rivals.sync="modal_rivals" :rivals="item.users"></ModalRivalsMultiplayer>
  </div>
</template>

<script>
    import moment from 'moment'
    import ModalRivalsMultiplayer from "../pages/Profile/ModalRivalsMultiplayer";
    // import GamesHistoryLogs from './GamesHistoryLogs'

    export default {
        data () {
            return {
                open: false,
                now_date: moment.utc(new Date()).format('X'),
                date: (this.item.users[0].online) ? moment.utc(this.item.users[0].online).format('X') : null,
                modal_rivals: false,
            }
        },
        props: {
            item: {
                type: Object
            },
            profile: {
                type: Object
            }
        },
        computed: {
            start_timestamp() {
                return moment.utc(this.item.start_timestamp).format('X');
            },

            lang() {
                return this.$store.state.language.lang;
            },

            game_array() {
                if (this.$filterArray(this.item.game.game_translations, this.lang, 'lang')) {
                    return this.$filterArray(this.item.game.game_translations, this.lang, 'lang');
                } else {
                    return this.$filterArray(this.item.game.game_translations, 'en', 'lang');
                }
            },
        },
        methods: {
          ConvertTime(seconds)  {
            let minutes = Math.floor(seconds / (60));
            seconds -= minutes * (60);
            if (0 < seconds && seconds < 10) {
              seconds = "0" + seconds;
            }
            return ((0 < minutes) ? (minutes + ":") : "0:") + ((0 < seconds) ? (seconds) : "00");
          }
        },
        components: {
            // VueSlideToggle,
            // GamesHistoryLogs
            ModalRivalsMultiplayer
        },
    };
</script>

<style lang="scss" scoped>

  @font-face {
    font-family: "Venera";
    font-style: normal;
    font-weight: 300;
    src: url("~@/assets/fonts/Venera/Venera.woff") format("woff"),
    url("~@/assets/fonts/Venera/Venera.woff2") format("woff2"),
    url("~@/assets/fonts/Venera/Venera.ttf") format("ttf");
  }

  .dark {
      .block-item {
          border-bottom: 1px solid #2B2B2B;
          .block-game {
              .v-list-item {
                  a {
                      color: #fff;
                  }
              }
          }

          .block-one-user {
              a {
                  color: #fff;
              }

              .v-list {
                  background: #171827;
              }
          }

          .block-many-user {
              .wrap-img {

                  img,
                  .photo {
                      border: 1px solid #fff;
                  }
              }
          }
      }
  }

  .dark .theme--light.v-card {
      background: #171827;
  }

  .dark .theme--light.v-sheet {
      border-color: transparent;
      background: transparent;
  }
  

  .v-sheet.v-card {
    /*border-radius: 8px;*/
    /*margin-bottom: 10px;*/
      border-radius: 0;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .wrap-item-history {
      width: 100%;
  }

  .block-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -1px;
    border-bottom: 1px solid #ECF4FF;
    border-radius: 0;



    .block-game {
      padding: 10px 0 10px 10px;
      width: 35%;
      flex-shrink: 0;

      .v-list-item {

        .block-img {
          margin-right: 10px;
        }

        .wrap-img {
          width: 54px;
          height: 54px;
          border-radius: 4px;
          background-color: #E7EFFF;

          img {
            width: 54px;
            height: 54px;
            border-radius: 4px;
          }
        }

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #002B50;
          text-decoration: none;
          margin-bottom: 8px;

          &:hover {
            text-decoration: underline;
            color: #26A9F4;
          }
        }

        p {
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.2px;
          text-transform: uppercase;

          span {
            margin-left: 10px;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.2px;

            .pic {
              height:12px;
              margin-right: 3px;
            }
          }
        }

        &__subtitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #96AFD1;
          white-space: normal;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
            .score{
                color: #01DF72;
                border-radius: 4px;
                background: #222338;
                padding: 2px 6px;
                font-size: 14px;
                margin-right: 10px;

                svg{
                    margin-top: -3px;
                    margin-right: 4px;
                }
             }
          .platform {
            margin-right: 5px;
            display: flex;
            align-items: center;
            margin-top: -2px;
          }
          .timestamp {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            //letter-spacing: 0.4px;
            color: #96AFD1;
            text-transform: uppercase;
            margin-right: 10px;
          }

          .time {
            display: flex;
            align-items: center;
              border-radius: 4px;
              padding: 2px 6px;
              background: #222338;

            svg {
              margin-right: 4px;
            }
          }
        }
      }
    }

    .block-info {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //background: #FAFBFF;
      padding:0 10px;
      width: 135px;
      height: 100%;

      p {
        font-family: "UniNeueBook";
        font-size: 18px;
        line-height: 20px;
        text-align: center;


        span {
          display: flex;
          align-items: center;
          margin-top: 8px;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.1px;
          color: #F6A401
        }
      }

      .pic {
        width: 11px;
        height: 12px;
        margin-right: 4px;
      }
    }

      .right-block-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
      }

    .block-vs {
        font-family: "Inter";
      display: flex;
        flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
        width: 140px;
      font-weight: 300;
      font-size: 24px;
      /*line-height: 29px;*/
      color: #96AFD1;
      float: right;
      min-width: 90px;

        p{
            margin-bottom: 0;
        }
        .timestamp{
            font-family: "Inter";
            /*font-family: "Roboto", sans-serif;*/
            color: #96AFD1!important;
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 0;
            /*color: #ffffff;*/
        }
    }

    .block-many-user {
      min-width: 250px;
      max-width: 250px;
      width: 100%;
      display: flex;
      align-items: center;
      //background: #FAFBFF;
      padding: 0 20px 0 10px;
      border-radius: 0 8px 8px 0;
      position: relative;

      .users {
          width: 110px;
          flex-shrink: 0;
          overflow: hidden;
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 10px;
      }

      .wrap-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: relative;
        left: 0;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #FFFFFF;

        }
        .photo {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            color: #FFFFFF;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            border: 1px solid #FFFFFF;
        }

        .online {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 1px;
            bottom: 2px;
            border: 1px solid #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background-color: #00c569;
        }

        &:nth-child(2) {
          left: -26px;
          z-index: 10;
        }
        &:nth-child(3) {
          left: -52px;
          z-index: 11;
        }
        &:nth-child(4) {
          left: -78px;
          z-index: 12;
        }
        &:nth-child(5) {
          left: -104px;
          z-index: 13;
        }
        &:nth-child(6) {
          left: -130px;
          z-index: 14;
        }
      }

      .counter {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.4px;
        color: #95AED0;
        cursor: pointer;

          &:hover {
              color: #26a9f4;
          }
      }


      .v-list-item__avatar {
        border-radius: 50%;
        border: 2px solid #FFFFFF;
      }
    }

    .block-one-user {
      min-width: 250px;
      max-width: 250px;
      //background: #FAFBFF;
      padding:0 20px 0 10px;
      border-radius:0 8px 8px 0;

      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: right;
        letter-spacing: 0.4px;
        color: #002B50;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: #26A9F4;
        }
      }

      .v-list {
        padding: 0;
        //background: #FAFBFF;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .v-list-item {
        padding: 0;
        /*padding: 21px 10px 21px 0;*/

        &__content {
          margin-left: 10px;

        }

        &__title {
          /*max-width: 125px;*/
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.1px;
          /*color: #FF9500;*/
          /*display: block;*/
          /*display: flex;*/
          /*align-items: center;*/
          /*justify-content: flex-end;*/
          margin-bottom: 0px;
          /*text-align: right;*/
          width: 140px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

            img{
                margin-top: -2px;
                margin-left: 6px;
            }
        }

        &__subtitle {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: #96AFD1;
          width: 170px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
            .score{
                display: inline-block;
                color: #01DF72;
                margin-top: 4px;
                border-radius: 4px;
                background: #222338;
                padding: 2px 6px;
                font-size: 14px;
                margin-right: 10px;

                svg{
                    margin-top: -3px;
                    margin-right: 4px;
                }
            }
          .rating {
            color: #F6A401;
            margin-left: 8px;
          }

          .pic {
            height: 12px;
            width: 11px;
            margin-right: 1px;
          }

        }
          a.img {
              text-decoration: none;

              &:hover {
                  text-decoration: none;
              }
          }

        .wrap-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          position: relative;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
            .photo {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none;
            }

            .online {
                border-radius: 50%;
                width: 10px;
                height: 10px;
                position: absolute;
                right: 1px;
                bottom: 2px;
                border: 1px solid #fff;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                background-color: #00c569;
            }
        }
      }
    }
  }

  .theme--light.v-list-item .v-list-item__action-text, .theme--light.v-list-item .v-list-item__subtitle {
    color: #96AFD1;
  }

  .slide-block {
    display: flex;
    align-items: center;
    background: #FCFCFE;
    border-radius: 0px 0px 8px 8px;
    padding: 14px 10px 7px;
      margin-top: -7px;

    .platform {
      display: flex;
      align-items: center;
      margin-right: 6px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #96AFD1;
    }

    .time {

      display: flex;
      align-items: flex-end;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #96AFD1;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #F0F2F8;

      span {
        font-size: 12px;
        line-height: 16px;
        color: #96AFD1;
        margin-left: 3px;
      }
    }

    .btn-play {
      display: flex;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: #96AFD1;
      text-transform: inherit;
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #F0F2F8;

      img {
        margin-right: 6px;
      }

        .img-hov {
            display: none;
        }

        &:hover {
            color: #4EBEFF;

            img {
                display: none;
            }

            .img-hov {
                display: block;
                margin-right: 6px;
            }
        }
    }
      .btn-download {
          display: flex;
          font-size: 12px;
          letter-spacing: 0.4px;
          color: #96AFD1;
          text-transform: inherit;
          /*padding-right: 20px;*/
          /*margin-right: 20px;*/
          /*border-right: 1px solid #F0F2F8;*/

          img {
              margin-right: 6px;
          }

          .img-hov {
              display: none;
          }

          &:hover {
              color: #4EBEFF;

              img {
                  display: none;
              }

              .img-hov {
                  display: block;
                  margin-right: 6px;
              }
          }
      }
  }

  .v-card--link:before {
    background: transparent;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
  }

  .v-btn.btn-cab {
    max-height: 40px;
  }

  @media screen and (max-width: 1400px) {
    .block-item {
      .block-game {
          width: 30%;
        .v-list-item {
            &__content {

                a {
                    margin-bottom: 3px;
                    width: 160px;
                    display: block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
      }

      .block-vs {
        min-width: 70px;
        padding: 10px;
        /*font-size: 20px;*/
      }

      .block-one-user,
      .block-many-user {
        min-width: 160px;
        max-width: 160px;
        padding: 0;

          a {
              width: fit-content;
          }

        .v-list-item {

          &__title {
              width: 100px;
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
          }

          &__subtitle {
            width: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {

    .wrap-item-history {
      width: 100%;
    }

    .block-item {
      flex-direction: column;

      .block-game {
        width: 100%;
        padding: 10px;
      }

      .right-block-item {
          flex-direction: row-reverse;
      }

      .block-many-user {
          justify-content: flex-end;
          margin-right: 10px;
        .wrap-img {

            &:nth-child(2) {
                left: -26px;
                z-index: 10;
            }
            &:nth-child(3) {
                left: -52px;
                z-index: 11;
            }
            &:nth-child(4) {
                left: -78px;
                z-index: 12;
            }
            &:nth-child(5) {
                left: -104px;
                z-index: 13;
            }
            &:nth-child(6) {
                left: -130px;
                z-index: 14;
            }
        }
      }

      .block-one-user {
        width: 100%;
        max-width: 100%;
        padding: 10px;

        .v-list {
          flex-direction: row-reverse;

            .v-list-item {
                flex-direction: row-reverse;

                &__content {
                    margin: 0 10px 0 0;
                }
                &__title {
                    justify-content: flex-end;
                    text-align: right;
                }

                &__subtitle {
                    justify-content: flex-end;
                    text-align: right;
                }
            }
        }
      }

      .block-vs {
        padding: 0;
        position: absolute;
        bottom: 15px;
        width: 130px;
        left: calc(50% - 65px);
      }

      .block-info {
        padding: 10px;
      }
    }
  }

  /*@media screen and (max-width: 1100px) {*/

  /*  .wrap-item-history {*/
  /*    width: 100%;*/

  /*  }*/
  /*}*/

  /*@media screen and (max-width: 950px) {*/

  /*  .wrap-item-history {*/
  /*    width: 49%;*/
  /*  }*/
  /*}*/

  /*@media screen and (max-width: 650px) {*/
  /*  .wrap-item-history {*/
  /*    .block-item {*/
  /*      flex-direction: column;*/

  /*      .block-game {*/
  /*        .v-list-item {*/
  /*          .wrap-img {*/
  /*            width: 48px;*/
  /*            height: 48px;*/
  /*            img {*/
  /*              width: 48px;*/
  /*              height: 48px;*/
  /*            }*/
  /*          }*/
  /*          &__content {*/
  /*            a {*/
  /*              font-size: 12px;*/
  /*            }*/
  /*          }*/

  /*          &__subtitle {*/
  /*            .timestamp,*/
  /*            .time {*/
  /*              font-size: 11px;*/
  /*            }*/
  /*          }*/
  /*        }*/
  /*      }*/

  /*      .block-one-user {*/
  /*        a {*/
  /*          font-size: 12px;*/
  /*        }*/

  /*        .v-list-item {*/

  /*          &__title {*/
  /*            margin-bottom: 0;*/
  /*          }*/
  /*          &__subtitle {*/
  /*            font-size: 11px;*/
  /*          }*/
  /*        }*/
  /*      }*/

  /*      .block-vs {*/
  /*        font-size: 20px;*/
  /*        line-height: 22px;*/
  /*      }*/

  /*      .block-info {*/
  /*        width: 100%;*/
  /*        border-top: 1px solid #EEF0F9;*/
  /*        padding: 10px;*/
  /*      }*/
  /*    }*/
  /*  }*/
  /*}*/

  @media screen and (max-width: 600px) {
        .block-item {
            .block-one-user {
                width: 100%;
                max-width: 100%;
                padding: 10px;

                .v-list {
                    flex-direction: row-reverse;

                    .v-list-item {
                        flex-direction: row-reverse;

                        &__content {
                            margin: 0 10px 0 0;
                            justify-content: flex-end;
                        }
                        &__title {
                            display: block;
                            text-align: right;
                            max-width: 120px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        &__subtitle {
                            justify-content: flex-end;
                            text-align: right;
                        }
                    }
                }
            }
        }
  }

  @media screen and (max-width: 450px) {
      .block-item {

            .block-game {
              .v-list-item {
                .wrap-img {
                  width: 48px;
                  height: 48px;
                  img {
                    width: 48px;
                    height: 48px;
                  }
                }
              }
            }

          .block-many-user {

              .counter {
                  font-size: 12px;
                  line-height: 16px;
              }

              .users {
                  width: 82px;
                  margin-right: 4px;
              }
              .wrap-img {
                  width: 32px;
                  height: 32px;
                  border-radius: 50%;
                  position: relative;

                  img {
                      width: 32px;
                      height: 32px;
                      border-radius: 50%;
                  }
                  .photo {
                      width: 32px;
                      height: 32px;

                  }

                  .online {
                      border-radius: 50%;
                      width: 8px;
                      height: 8px;
                      position: absolute;
                      right: 1px;
                      bottom: 2px;
                      border: 1px solid #fff;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                      background-color: #00c569;
                  }
                  &:nth-child(2) {
                      left: -22px;
                      z-index: 10;
                  }
                  &:nth-child(3) {
                      left: -44px;
                      z-index: 11;
                  }
                  &:nth-child(4) {
                      left: -66px;
                      z-index: 12;
                  }
                  &:nth-child(5) {
                      left: -88px;
                      z-index: 13;
                  }
                  &:nth-child(6) {
                      left: -110px;
                      z-index: 14;
                  }
              }
          }

          .block-one-user {
              width: 100%;
              max-width: 100%;
              padding: 10px;

              a {
                  font-size: 12px;
                  line-height: 18px;
              }

              .v-list {
                  flex-direction: row-reverse;

                  .v-list-item {
                      flex-direction: row-reverse;

                      &__content {
                          margin: 0 10px 0 0;
                          justify-content: flex-end;
                      }

                      &__title {
                          margin-bottom: 0;
                          max-width: 95px;
                      }

                      &__subtitle {
                          font-size: 11px;
                      }

                      .wrap-img {
                          width: 32px;
                          height: 32px;
                          border-radius: 50%;
                          position: relative;

                          img {
                              width: 32px;
                              height: 32px;
                              border-radius: 50%;
                          }
                          .photo {
                              width: 32px;
                              height: 32px;

                          }

                          .online {
                              border-radius: 50%;
                              width: 8px;
                              height: 8px;
                              position: absolute;
                              right: 1px;
                              bottom: 2px;
                              border: 1px solid #fff;
                              -webkit-box-sizing: border-box;
                              box-sizing: border-box;
                              background-color: #00c569;
                          }
                      }
                  }
              }
          }

          .block-info {
              p {
                  font-size: 16px;
              }
          }
      }
  }

</style>