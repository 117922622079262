<template>
    <router-link class="item-upcoming-tournament" :to="item.solo ? {name: 'tournament_solo', params: {'id': item.id, lang: $store.getters.getLang}} : {name: 'tournament', params: {'id': item.id, lang: $store.getters.getLang}}">
        <div class="top-upcoming-tournament">
            <div class="left-block">
                <div v-if="typeof item.game.logo == 'object'" class="img" :style="{'backgroundImage': 'url(' +item.game.logo[300] + ')'}"></div>
                <div v-else class="img" :style="{'backgroundImage': 'url(' +item.game.logo + ')'}"></div>

                <div class="info-tournament">
                    <div class="info-tournament__header">
                        <p class="block-name">{{gameTranslation(item.game.game_translations).name}}</p>

                        <div class="block-attr" v-if="item.attribute && item.attribute.length">
                            <p v-for="(item, key) in item.attribute" :key="key">{{item.name}}: <span>{{item.value}}</span></p>
                        </div>
                    </div>

                    <div class="rtp-block">
                        <div class="rtp">
                            <p>{{__("RTP: 70%")}}</p>
                        </div>

                        <div class="block-status" >
                            <p style="color: #01DF72;" v-if="item.users_count < item.count_users">{{__('Registration for the tournament is open')}}</p>
                            <p v-else :style="(item.users_count >= item.count_users) ? {color: '#fff'} : ''">{{__('Participants identified')}}</p>
                        </div>

                        <div class="block-users">
                            <img src="/dist/images/frontend/users.svg" alt="">
                            <span>{{item.users_count}}/{{item.count_users}}</span>
                        </div>
                    </div>

                    <div class="price-date">

                        <div class="block-price">
                            <p class="text">{{__("Cost of participation")}}</p>
                            <!--                        <img v-if="item.wallet == 1" src="/dist/images/frontend/money.svg" alt />-->
                            <!--                        <img v-else src="/dist/images/frontend/light-coin.svg" alt />-->
                            <div>
                                <span>{{item.price}}</span>
                                <p v-if="item.wallet == 1">USDT</p>
                                <p v-else>MIND</p>
                            </div>
                        </div>

                        <div class="block-date">
                            <p class="start">
                                <img src="/dist/images/frontend/start.svg" alt="">

                                {{__('Start date:')}} {{item.date_start | moment('DD.MM')}}
                            </p>
                            <p>
                                <img src="/dist/images/frontend/finish.svg" alt="">
                                {{__('Expiration date:')}} {{item.date_finish | moment('DD.MM')}}
                            </p>
                        </div>

                    </div>

                    <!--                <p class="block-description">{{gameTranslation(item.translate).title}}</p>-->



                    <!--                <div class="block-progress">-->
                    <!--                    <progress  style="width: 100%" :value="item.users_count" :max="item.count_users"></progress>-->
                    <!--                </div>-->
                </div>
            </div>

            <div class="right-block">
                <div class="time">
                    <div v-if="isTournamentUpcoming(item)">
                        <span>{{__("It will start in")}}</span>
                        <p>{{ countdownToStart(item.date_start) }}</p>
                    </div>
                    <div v-else-if="isTournamentActive(item)">
                        <span>{{__("It will end in")}}</span>
                        <p>{{ countdownToFinish(item.date_finish) }}</p>
                    </div>
                    <p v-else class="end">{{__("The tournament has concluded")}}</p>
                </div>
                <div class="href">
                    <a class="baseButton">
                        <span>
                            {{__("View more details")}}
                            <svg width="6" height="24" viewBox="0 0 6 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 15.5L4.5 12.5L1.5 9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>

                    </a>
                </div>
                <!--                <pre>{{item}}</pre>-->
<!--                <p>-->
<!--                    <img src="/dist/images/frontend/start.svg" alt="">-->
<!--                    {{__('Start date:')}} {{item.date_start | moment('DD.MM')}}-->
<!--                </p>-->
<!--                <p>-->
<!--                    <img src="/dist/images/frontend/finish.svg" alt="">-->
<!--                    {{__('Expiration date:')}} {{item.date_finish | moment('DD.MM')}}-->
<!--                </p>-->
<!--                <div>-->
<!--                    <p v-if="timeToStart(tournament.tournament) > 0">-->
<!--                        {{__('Time until start:')}} {{ formatCountdown(timeToStart(tournament.tournament)) }}-->
<!--                    </p>-->
<!--                    <p v-else-if="timeToFinish(tournament.tournament) > 0">-->
<!--                        {{__('Time remaining:')}} {{ formatCountdown(timeToFinish(tournament.tournament)) }}-->
<!--                    </p>-->
<!--                    <p v-else>-->
<!--                        {{__('Tournament is over')}}-->
<!--                    </p>-->
<!--                </div>-->
            </div>
        </div>

    </router-link>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'ItemUpcomingTournaments',
        data() {
            return {
                interval: null,
                now_date: moment.utc().format('X'),
                currentMoment: moment.utc(),
            }
        },
        computed: {
            lang() {
                return this.$store.state.language.lang;
            },
            auth() {
                return this.$store.state.auth.user;
            },
        },

        created() {
        },
        beforeDestroy() {
            clearInterval(this.interval)
        },
        mounted() {
            this.updateCountdowns();
            this.interval = setInterval(this.updateCountdowns, 1000);
        },

        methods: {
            isTournamentUpcoming(tournament) {
                const startDate = moment.utc(tournament.date_start);
                return this.currentMoment.isBefore(startDate);
            },
            isTournamentActive(tournament) {
                const startDate = moment.utc(tournament.date_start);
                const endDate = moment.utc(tournament.date_finish);
                return this.currentMoment.isSameOrAfter(startDate) && this.currentMoment.isSameOrBefore(endDate);
            },
            countdownToStart(dateStart) {
                const startDate = moment.utc(dateStart);
                const timeRemaining = startDate.diff(this.currentMoment);
                return this.formatTimeRemaining(timeRemaining);
            },
            countdownToFinish(dateFinish) {
                const endDate = moment.utc(dateFinish);
                const timeRemaining = endDate.diff(this.currentMoment);
                return this.formatTimeRemaining(timeRemaining);
            },
            formatTimeRemaining(timeRemaining) {
                const duration = moment.duration(timeRemaining);
                const days = Math.floor(duration.asDays()); // получаем количество дней
                const hours = duration.hours() + days * 24; // преобразуем дни в часы и добавляем к общим часам
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                const hours1 = hours.toString().padStart(2, '0');
                const minutes1 = minutes.toString().padStart(2, '0');
                const seconds1 = seconds.toString().padStart(2, '0');

                return `${hours1}:${minutes1}:${seconds1}`;
            },
            updateCountdowns() {
                this.currentMoment = moment.utc(); // Обновляем текущее время UTC
            },

            gameTranslation(arr) {
                if (this.$filterArray(arr, this.lang, 'lang')) {
                    return this.$filterArray(arr, this.lang, 'lang');
                } else {
                    return this.$filterArray(arr, 'en', 'lang');
                }
            },
            countDays(dateStart, dateFinish) {
                return moment(dateFinish).diff(moment(dateStart), 'days')
            }
        },
        props: {
            item: {
                type: Object
            }
        },
        components: {

        }
    }
</script>

<style lang="scss" scoped>

</style>