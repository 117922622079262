<template>
  <div class="block-item-game">
    <div class="d-flex">
      <router-link :to="{name: 'game', params: {'id': item.id, lang: $store.getters.getLang}}">
        <div
          v-if="item.logo"
          class="logo"
          :style="(typeof item.logo == 'object') ? {background: 'url('+(item.logo[100])+') no-repeat 50%', backgroundSize: 'cover'} : {background: 'url('+(item.logo)+') no-repeat 50%', backgroundSize: 'cover'}"
        ></div>

        <div v-else
             class="logo"
            :style="{background: 'url('+'/dist/logo_600.png'+') no-repeat 50%', backgroundSize: 'cover'}"
        >

        </div>
      </router-link>
      <div class="wrap-item">
        <div>
          <h2>
            <router-link :to="{name: 'game', params: {'id': item.id, lang: $store.getters.getLang}}" v-if="game_array">{{game_array.name}}</router-link>
          </h2>
          <div class="wrap">
            <div class="block-rating">
              <p class="rating">
                <v-rating
                    :value="item.rating"
                    background-color="#96AFD1"
                    color="yellow accent-4"
                    :readonly="true"
                    dense
                    size="16"
                ></v-rating>
                <span>({{item.rating}})</span>
              </p>

            </div>
            <span class="icon">
              <img class="img" src="/dist/images/frontend/apple.svg" alt />
              <img class="img" src="/dist/images/frontend/android.svg" alt />
              <img class="img" style="height: 12px" src="/dist/images/frontend/game_pad.svg" alt />
              <img class="img" src="/dist/images/frontend/Browser1.svg" alt />
            </span>
            <div class="block-type" v-if="item.type == 1">{{__('Type game:')}} <span>{{__('Battle 1 vs 1')}}</span></div>
            <div class="block-type" v-else>{{__('Type game:')}} <span>{{__('Multiplayer')}}</span></div>
          </div>
          <div class="wrapper">
            <span class="install">{{(item.played_count) ? item.played_count : 0}} {{__('Played')}}</span>

            <span
                v-if="item.dev_mode==0"
                class="green--text status"
            >{{__('Enabled and visible to everyone')}}</span>

            <span
                v-else-if="item.dev_mode==1"
                class="grey--text status"
            >{{__('Game created (game for Testing)')}}</span>

            <span
                v-else-if="item.dev_mode==2"
                class="grey--text status"
            >{{__('Moderation request (Testing game)')}}</span>

            <span
                v-else-if="item.dev_mode==3"
                class="error--text status"
            >{{__('Refusal of moderation (game for Testing)')}}</span>
          </div>
        </div>
        <div class="block-btn-mob">
          <BaseButton
                  :to="{name: 'dev_info', params: {id: item.id, lang: $store.getters.getLang}}"
          >
            <span>
              <img class="ma-0" src="/dist/images/frontend/icon-settings-white.svg" alt />
            </span>
          </BaseButton>
        </div>

        <div class="block-btn-comp">
          <BaseButton
                  width="136"

                  :to="{name: 'dev_info', params: {id: item.id, lang: $store.getters.getLang}}"
          >
            <span>
              {{__('Settings')}}
            </span>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "GamesItem",
  data() {
    return {}
  },
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    auth() {
      return this.$store.state.auth.user;
    },
    lang() {
      return this.$store.state.language.lang;
    },

    game_array() {
      if (this.$filterArray(this.item.game_translations, this.lang, 'lang')) {
        return this.$filterArray(this.item.game_translations, this.lang, 'lang');
      } else {
        return this.$filterArray(this.item.game_translations, 'en', 'lang');
      }
    },
  },
  asyncComputed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>

.v-application a {
  color: #002b50;
}

a {
  margin-top: 0;
  text-decoration: none;
  color: #002b50;

  &:hover {
    text-decoration: underline;
    color: #26a9f4;
  }
}

.dark {
  .block-item-game {
    background: #171827;

    a {
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    .wrap {
      .block-type {
        color: #96AFD1;

        span {
          color: #fff;
        }
      }
    }

    .wrapper {
      .install {
        color: #96AFD1;
      }
    }
  }
}

.block-item-game {
  position: relative;
  width: 100%;
  padding: 10px;
  max-height: 240px;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #fff;

  .wrap-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .block-btn-mob {
      display: none;
    }
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #002b50;
    margin-bottom: 2px;
  }

  .username {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.0015em;
    color: #6886AF;
    margin-bottom: 4px;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    position: relative;
    z-index: 10;

    .btn-cab {
      margin-right: 20px;
      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 64px;
      width: 136px;
      padding: 0 16px;
    }

    .status {
      font-weight: 400;
      font-size: 14px;
      // line-height: 20px;
      display: flex;
      white-space: nowrap;
      align-items: center;
    }

    .install {
      font-size: 13px;
      line-height: 20px;
      color: #6886AF;
      margin-right: 30px;
      white-space: nowrap;
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    flex-wrap: wrap;

    .block-rating {
      display: flex;

      .rating {
        display: flex;
        align-items: center;
        margin: 0 0 0 -4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 8px;
        color: #6886AF;

        span {
          margin-left: 4px;
          margin-top: 2px;
        }
      }
    }

    .block-type {
      margin: 0 5px 0 0;
      color: #6886AF;
      font-size: 14px;
      white-space: nowrap;
      span {
        color: #002B50;
        font-weight: 400;
      }
    }
  }

  .icon {
    margin: 0 20px 0 0;
    display: flex;
    align-items: center;
    img {
      margin-left: 10px;
      height: 16px;
    }
  }
}

.logo {
  min-width: 80px;
  min-height: 80px;
  background: #e7efff;
  border-radius: 6px;
  margin-right: 20px;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #0d4261;
  height: 56px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 600px) {
  .logo {
    margin-right: 12px;
  }
  .block-item-game {
    .wrap-item {
      .block-btn-mob {
        display: flex;
        position: absolute;
        right: 10px;
        top: 30px;
      }
      .block-btn-comp {
        display: none;
      }
    }
    .wrapper {

      .install {
        display: none;
      }

      .status {
        font-size: 12px;
      }
    }

    h2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #002b50;
      margin-bottom: 2px;
    }
    .icon {
      display: none;
    }
    .wrap {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .block-item-game .wrap .block-type {
    font-size: 12px;
  }
}


</style>
