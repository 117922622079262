<template>
  <div style="position:relative; width: 100%">
    <div :class="(color) ? 'input_'+color : ''" class="input-group d-flex">
      <slot name="left">
        <div class="label" :class="(value || value === 0) ? 'label_top' : ''" ref="label" v-if="posLabel=='left' && (icon || label)">
          <v-icon v-if="icon"  :name="icon"></v-icon>
          {{label}}
        </div>
      </slot>
      <div @click="$refs.input.focus()">
        <slot name="before_value"></slot>
      </div>
      <textarea
        v-if="type=='textarea'"
        :placeholder="placeholder"
        class="input-textarea"
        :disabled="disabled"
        :readonly="readonly"
        :type="input_type"
        ref="input"
        v-bind="$attrs"
        v-on="inputListeners"
        style="width: 100%"
        v-bind:value="value"
        :maxlength="maxlength"
        @focus="((isFocus) ? focusInput() : onFocus())"
        @blur="onBlur"
      ></textarea>
      <input
        v-else
        :label="label"
        :placeholder="placeholder"
        :class="class_input"
        class="input"
        :disabled="disabled"
        :readonly="readonly"
        :type="input_type"
        ref="input"
        :value="value"
        v-bind="$attrs"
        v-on="inputListeners"
        :maxlength="maxlength"
        :autocomplete="autocomplete"
        @click="((copy) ? copyText() : null)"
        :name="name"
        @focus="((isFocus) ? focusInput() : null)"
      />
      <v-btn
        class="btn-copy"
        text
        icon
        v-ripple="false"
        small
        v-if="copy"
        @click="copyText"
      >
        <img class="pic" src="/dist/images/frontend/copy-btn-icon.svg" alt />
        <img class="pic" src="/dist/images/frontend/copy-btn-icon-blue.svg" alt />
      </v-btn>

      <v-btn
          class="btn-copy ml-1"
          text
          icon
          v-ripple="false"
          small
          v-if="copyRef"
          @click="$emit('update:modal', true)"
      >
        <img class="pic" src="/dist/images/frontend/qr-btn-icon.svg" alt />
        <img class="pic" src="/dist/images/frontend/qr-btn-icon-blue.svg" alt />
      </v-btn>
        <!--<BaseButton-->
            <!--width="100"-->

            <!--v-if="copyRef"-->
            <!--@click="copyText"-->
        <!--&gt;-->
          <!--<span>Copy</span>-->
        <!--</BaseButton>-->

      <span class="text-copy" v-if="submitSuccess && copy">
        {{__('Copied!')}}
      </span>


      <slot name="right"></slot>
      <div class="label" v-if="posLabel=='right' && (icon || label)">
        <v-icon v-if="icon" class="mr-2" :name="icon"></v-icon>
        {{label}}
      </div>
    </div>
    <v-btn
        class="btn-password"
        v-ripple="false"
        text
        icon
        small
        v-if="switchType"
        @click="openPassword"
    >
      <i v-if="isActive" class="far fa-eye"></i>
      <i v-else class="far fa-eye-slash"></i>
    </v-btn>
    <div>
      <p class="desc-error" v-for="(value, key) in errors" :key="key">{{value}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputLabelTextDefault",
  data() {
    return {
      input_type: this.type,
      submitSuccess: false,
      isActive: false,
    };
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` объединяет объекты вместе, чтобы получить новый объект
      return Object.assign(
        {},
        // Мы добавляем все слушатели из родителя
        this.$listeners,
        // Затем мы можем добавить собственные слушатели или
        // перезаписать поведение некоторых существующих.
        {
          // Это обеспечит, что будет работать v-model на компоненте
          input: function (event) {
            vm.$emit("input", event.target.value);
          },
        }
      );
    },
  },
  watch: {
    focus: function (val) {
      if (val) {
        this.$emit("update:focus", false);
        this.$refs.input.focus();
      }
    },
    isFocus() {
      this.$refs.input.focus();
    },
    // value: function (val)  {
    //   if(val.length) {
    //     this.$refs.label.classList.add('label_top');
    //   }else {
    //     this.$refs.label.classList.remove('label_top');
    //   }
    // }
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    copy: {
      type: Boolean,
      default: false,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
    copyRef: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: [String, Number],
    },
    color: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    errors: {
      type: Array,
    },
    switchType: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    posLabel: {
      type: String,
      default: "left",
    },
    focus: {
      type: Boolean,
    },
    class_input: {
      type: String,
    },
    maxlength: {
      type: [Number, String],
      default: null
    },
    modal: {
      type: Boolean,
    },
    autocomplete: {
      type: String,
      default: "on",
    },
    name: {
      type: String,
    }
  },
  methods: {
    copyText: function () {
      // /* Get the text field */
      var copyText = this.$refs.input;
      /* Select the text field */
      copyText.select();

      /* Copy the text inside the text field */
      document.execCommand("copy");

      this.submitSuccess=true;

      setTimeout(()=> {
        this.submitSuccess= null;
      }, 2000);
    },
    openPassword: function () {
      this.input_type = this.input_type == "password" ? "text" : "password";
      this.isActive = !this.isActive;
    },

    focusInput () {
      if (this.$refs.input.hasAttribute('readonly')) {
        this.$refs.input.removeAttribute('readonly');
        // fix for mobile safari to show virtual keyboard
        this.$refs.input.blur();
        this.$refs.input.focus();
      }
      console.log('Focus Input');
      this.$emit('keyboard-opened', true);
    },

    onFocus() {
      console.log('OnFocus Input');
      this.$emit('keyboard-opened', true);
    },

    onBlur() {
      console.log('Blur Input')
      this.$emit('keyboard-opened', false);

    }
  },
};
</script>

<style lang="scss">

  .input {
    &::placeholder {
      font-size: 16px;
      line-height: 22px;
      color: #96AFD1;
    }
  }

.dark {
  .input-textarea {
    border: 1px solid #363636;
    color: #fff;
  }
}


.input-textarea {
  background: transparent;
  border: 1px solid #DAE6F1;
  border-radius: 8px;
  padding: 10px;
  height: 100px;
  outline: none;

  &::placeholder {
    font-size: 16px;
    line-height: 22px;
    color: #96AFD1;
  }
}

.dark .input-group.input_balance {
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  .input {
    color: #fff;
  }

  .label {
    color: #96AFD1;
  }

  .label.label_top {
    color: #96AFD1;
  }
}

  .input-group.input_balance {
    background-color: #fff;
    border-bottom: 1px solid #DAE6F1;
    border-radius: 0;
    padding: 0;
    position: relative;

    .input {
      width: 100%;
      font-family: "UniNeueBook";
      font-size: 24px;
      line-height: 18px;
      padding: 0;
      font-weight: normal;
      color: #002B50;

      &::placeholder {
        font-size: 24px;
        line-height: 18px;
        color: #96AFD1;
      }
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;
    }

    .label {
      background: transparent;
      font-weight: 400;;
      font-size: 15px;
      line-height: 16px;
      color: #6886AF;
      position: absolute;
      pointer-events: none;
      top: 16px;
      left: 0;
      transition-duration: .3s;
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;

      .label {
        background: transparent;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #26A9F4;
        position: absolute;
        top: -10px;
        left: 0;
        transition-duration: .3s;
      }
    }

    .label.label_top {
      background: transparent;
      font-weight: 400;;
      font-size: 13px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      position: absolute;
      top: -10px!important;
      left: 0;
      transition-duration: .3s;
    }
  }

  .input-group.input_store {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 0 12px;
    position: relative;
    height: 36px;
    display: flex;
    align-items: center;

    .input {
      width: 100%;
      padding: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #fff;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #96AFD1;
      }
    }

    &:focus-within {
      border: 1px solid #26A9F4;
    }

    .label {
      background: transparent;
      font-weight: 400;;
      font-size: 15px;
      line-height: 16px;
      color: #6886AF;
      position: absolute;
      pointer-events: none;
      top: 16px;
      left: 0;
      transition-duration: .3s;
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;

      .label {
        background: transparent;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #26A9F4;
        position: absolute;
        top: -22px;
        left: 0;
        transition-duration: .3s;
      }
    }

    .label.label_top {
      background: transparent;
      font-weight: 400;;
      font-size: 13px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      position: absolute;
      top: -22px!important;
      left: 0;
      transition-duration: .3s;
    }

    .v-btn.btn-password {
      top: 6px;
    }
  }

  .input-group.input_store-textarea {
    .input-textarea {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 5px;
      border: 1px solid transparent;
      padding: 10px;
      height: 100px;
      outline: none;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #96AFD1;
      }

      &:focus-within {
        border: 1px solid #26A9F4;
        border-radius: 5px;
      }
    }
  }

  .dark {
    .input-group.input_border-norm {
      background: #181818;
      border: 1px solid #181818;

      .input {
        color: #fff;
      }
    }
  }

  .input-group.input_border-norm {
    background-color: #fff;
    border: 1px solid #DAE6F1;
    border-radius: 8px;
    padding: 0 12px;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;

    .input {
      width: 100%;
      font-family: "UniNeueBook";
      font-size: 20px;
      line-height: 18px;
      padding: 0;
      font-weight: normal;
      color: #002B50;

      &::placeholder {
        font-size: 20px;
        line-height: 18px;
        color: #96AFD1;
      }
    }

    &:focus-within {
      border: 1px solid #26A9F4;
    }

    .label {
      background: transparent;
      font-weight: 400;;
      font-size: 15px;
      line-height: 16px;
      color: #6886AF;
      position: absolute;
      pointer-events: none;
      top: 16px;
      left: 0;
      transition-duration: .3s;
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;

      .label {
        background: transparent;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #26A9F4;
        position: absolute;
        top: -22px;
        left: 0;
        transition-duration: .3s;
      }
    }

    .label.label_top {
      background: transparent;
      font-weight: 400;;
      font-size: 13px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      position: absolute;
      top: -22px!important;
      left: 0;
      transition-duration: .3s;
    }

    .v-btn.btn-password {
      top: 6px;
    }
  }

  .dark {
    .input-group.input_border {
      background: #181818;
      border-radius: 8px;
      border: 1px solid #181818;

      .input {
        color: #fff;
      }
    }
  }

  .input-group.input_border {
    background-color: #fff;
    border: 1px solid #DAE6F1;
    border-radius: 8px;
    padding: 0 16px;
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;

    .input {
      width: 100%;
      font-family: "UniNeueBook";
      font-size: 24px;
      line-height: 18px;
      padding: 0;
      font-weight: normal;
      color: #002B50;

      &::placeholder {
        font-size: 24px;
        line-height: 18px;
        color: #96AFD1;
      }
    }

    &:focus-within {
      border: 1px solid #26A9F4;
    }

    .label {
      background: transparent;
      font-weight: 400;;
      font-size: 15px;
      line-height: 16px;
      color: #6886AF;
      position: absolute;
      pointer-events: none;
      top: 16px;
      left: 0;
      transition-duration: .3s;
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;

      .label {
        background: transparent;
        font-weight: 400;;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #26A9F4;
        position: absolute;
        top: -10px;
        left: 0;
        transition-duration: .3s;
      }
    }

    .label.label_top {
      background: transparent;
      font-weight: 400;;
      font-size: 13px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      position: absolute;
      top: -10px!important;
      left: 0;
      transition-duration: .3s;
    }
  }

  .dark {
    .input-group.input_guest .input-textarea {
      color: #002b50;
    }
  }

  .input-group.input_guest {
    background-color: #fff;
    border: 1px solid #96AFD1;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 15px;
    position: relative;

    .input {
      padding: 0;

      &::placeholder {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        opacity: 0.5;
      }
    }

    .input-textarea {
      background: transparent;
      border: none;
      border-radius: 4px;
      padding: 14px 0;
      outline: none;

      &::placeholder {
        font-size: 16px;
        line-height: 16px;
        color: #333333;
        opacity: 0.5;
      }
    }
  }

  .input-group.input_auth {
    background-color: transparent;
    border: 1px solid #DAE6F1;
    border-radius: 56px;
    padding: 0;
    position: relative;


    .input {
      padding: 0px 0 0 0;
      font-size: 15px;
    }

    .label {
      background: transparent;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #6886AF;
      position: absolute;
      pointer-events: none;
      top: 12px;
      left: 20px;
      transition-duration: .3s;
    }

    &:focus-within {
      border: 1px solid #26A9F4;

      .label {
        background: #ffffff;
        padding-left: 6px;
        padding-right: 6px;
        font-weight: 400;;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #26A9F4;
        position: absolute;
        top: -9px;
        left: 20px;
        transition-duration: .3s;
      }
    }

    .label.label_top {
      padding-left: 6px;
      padding-right: 6px;
      background: #ffffff;
      font-weight: 400;;
      font-size: 12px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #26A9F4;
      position: absolute;
      top: -9px!important;
      left: 20px;
      transition-duration: .3s;
    }

    .v-btn.btn-copy {
      top: 7px;
    }
  }

  .input-group.input_main {
    background-color: transparent;
    border-bottom: 1px solid #DAE6F1;
    border-radius: 0;
    padding: 0;
    position: relative;

    .input {
      padding: 14px 0 0 0;
      font-size: 15px;
    }

    .label {
      background: transparent;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: #6886AF;
      position: absolute;
      pointer-events: none;
      top: 16px;
      left: 0;
      transition-duration: .3s;
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;

      .label {
        background: transparent;
        font-weight: 400;;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #26A9F4;
        position: absolute;
        top: 0;
        left: 0;
        transition-duration: .3s;
      }
    }

    .label.label_top {
      background: transparent;
      font-weight: 400;;
      font-size: 13px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      position: absolute;
      top: 0!important;
      left: 0;
      transition-duration: .3s;
    }

    .v-btn.btn-copy {
      top: 7px;
    }
  }

  .dark .input-group.input_main_game {
    input {
      color: #fff;
    }
  }

  .input-group.input_main_game {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    position: relative;

    .input {
      padding: 14px 0 0 0;
      font-size: 14px;
      text-align: center;
    }

    .label {
      background: transparent;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #96AFD1;
      position: absolute;
      pointer-events: none;
      top: 12px;
      transition-duration: .2s;
      width: 100%;
      text-align: center;
    }

    &:focus-within {

      .label {
        background: transparent;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #96AFD1;
        position: absolute;
        top: 0;
        left: -10px;
        transition-duration: .2s;
      }
    }

    .label.label_top {
      background: transparent;
      font-weight: 400;;
      font-size: 12px!important;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF;
      position: absolute;
      top: 0!important;
      left: -10px;
      transition-duration: .3s;
    }

    .v-btn.btn-copy {
      top: 7px;
    }
  }

  .v-btn.btn-password {
    outline: none;
    display: block;
    position: absolute;
    top: 14px;
    right: 0;

    i {
      color: #26A9F4;
    }

    &.v-btn:before {
      background-color: transparent;
    }
  }

  .input-group.input_read_only {
    background-color: transparent;

    input {
      padding: 0 15px 0 0;
      width: auto;
      max-width: 100%;
    }
  }

  .input-group.input_setting_input {
    font-size: 15px;
    padding: 0;
    color: rgba(104, 134, 175, 0.6);
    border-bottom: 1px solid #DAE6F1;
    cursor: default;
    height: 56px;
    background-color: transparent;
    border-radius: 0;
    position: relative;

    &:after {
      display: block;
      content: "";
      background-color: #26A9F4;
      height: 2px;
      width: 0%;
      left: 50%;
      bottom: -1px;
      position: absolute;
      -webkit-transition: width .3s ease-in-out;
      -moz--transition: width .3s ease-in-out;
      transition: width .3s ease-in-out;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      transform: translateX(-50%);
    }

    &:focus-within:after {
      /*border-bottom: 2px solid #26A9F4;*/
      width: 100%;

    }
  }
  input.input.settings_input-address {
    margin-top: 16px;
    padding: 6px 0 0;
    color: #002B50;
    background-color: transparent;
    border-bottom: 1px solid #DAE6F1;
    border-radius: 0;
  }

  .dark {
    .input-group.input_developers_input {
      background: #171827;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      color: #fff;

      span {
        color: #96AFD1B1;
      }

      .label {
        color: #96AFD1;
      }
    }

    input.input.developers_input-information {
      background: #171827;
      border-bottom: 1px solid rgba(255, 255, 255, 0.07);
      color: #fff;
    }
  }

  @media screen and (max-width: 600px) {
    .input.input.settings_input-address {
      font-size: 12px;
      padding: 14px 0 0;
    }
  }

  .input-group.input_developers_input {
    position: relative;
    font-size: 15px;
    padding: 0;
    color: rgba(104, 134, 175, 0.6);
    border-bottom: 1px solid #DAE6F1;
    cursor: default;
    height: 56px;
    background-color: #fff;
    border-radius: 0;

    span {
      cursor: pointer;
    }

    &:focus-within {
      border-bottom: 1px solid #26A9F4;
    }
  }

  @media screen and (max-width: 600px) {
    .input.input.developers_input-information {
      font-size: 12px;
      line-height: 16px;
      padding: 11px 0 0;
    }
    .input-group.input_developers_input {
      span {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  input.input.developers_input-information {
    margin-top: 16px;
    padding: 6px 0 0;
    color: #002B50;
    background-color: #fff;
    border-bottom: 1px solid #DAE6F1;
    border-radius: 0;

    &:focus {
      border-bottom: 1px solid #26A9F4;
      border-radius: 0;
    }
  }

/*.input-group {*/
/*  font-size: 15px;*/
/*  background: #F0F6FE;*/
/*  !*border-bottom: 1px solid #DAE6F1;*!*/
/*  border-radius: 8px;*/
/*  width: 100%;*/
/*  position: relative;*/
/*}*/

/*.input-group .label {*/
/*  background: #E7EFFF;*/
/*  padding: 0;*/
/*  border-radius:  8px 0 0 8px ;*/
/*  min-width: 40px;*/
/*  font-weight: 400;;*/
/*  font-size: 16px;*/
/*  line-height: 20px;*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  color: #96AFD1;*/
/*}*/

.input-group .label {
  background: transparent;
  font-weight: 400;;
  font-size: 13px;
  line-height: 18px;
  color: #6886AF;
  position: absolute;
  top: 10px;
  left: 0;
}

.dark {
  .input-group .label {
    color: #96AFD1;
  }
}

/*.input_success {*/
/*  color: #00c443;*/
/*}*/
/*.input_primary {*/
/*  color: #26a9f4;*/
/*}*/
.input-group .input {
  padding: 14px 16px 0;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: transparent;
  outline: none;
}

.input-group textarea.input {
  /*height: 120px;*/
  padding: 10px;
  border-radius: 6px;
}

.btn-password {
  background-color: transparent;
  height: 40px;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 8px 8px 0;

  i {
    color: #002B50;
  }


  &:hover {
    i{
    transform: scale(1.1);
    }
  }
}

.v-btn:before {
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  background-color: transparent;
}

.v-btn:not(.v-btn--round).v-size--small {
  height: 40px;
  padding: 0;
}

.v-btn.btn-copy {
  outline: none;
  top: 5px;
  right: 0;
  color: #002B50 !important;


  img {
    &:last-child {
      display: none;
    }
  }

  &:hover {
    img {
      &:first-child{
        display: none;
      }

      &:last-child {
        display: block;
      }
    }
  }
  &.v-btn:before {
    background-color: transparent;
  }
}

.btn-copy-ref {
  outline: none;
  position: absolute;
  right: -75px;
  bottom: 0;
  border-radius: 0 8px 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #96AFD1;
  text-transform: inherit;
  padding: 0;


  img {
    margin-top: 1px;
    margin-right: 4px;
    height: 12px;
  }

  .pic-hover {
    display: none;
  }

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 64px;
    padding: 0 10px;
    margin-right: 5px;
  }

  &.theme--light.v-btn.v-btn--icon {
    color: #6886AF;
  }

  &:hover {
    color: #34B0F3;

    .pic {
      display: none;
    }

    .pic-hover {
      display: block;
    }


    .theme--light.v-btn.v-btn--icon {
      color: #34B0F3;
    }
  }

  .v-btn:before {
    border-radius: inherit;
    bottom: 0;
    color: #34B0F3;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    background-color: transparent;
  }
}

//Attribute option
/*.input-group .color_input {*/
/*  background-color: #fff;*/
/*  padding: 0 0 0 10px;*/
/*  font-size: 15px;*/
/*}*/

.input-group .search-sorting {
  background-color: #fff;
  padding: 0 10px 0 0;
  font-size: 15px;
}

/*.input-group .settings_input--const {*/
/*  cursor: no-drop;*/
/*}*/


/*.input-group .link_input {*/
/*  display: block;*/
/*  width: 100%;*/
/*  padding: 0 122px 0 10px;*/
/*  !*margin-right: 5px;*!*/
/*}*/

/*.input-group .link-input {*/
/*  background-color: #FFFFFF;*/
/*  width: 280px;*/
/*  color: #34B0F3;*/
/*}*/

/*.input-group.input_affiliate {*/
/*  background-color: transparent;*/


/*  .v-btn.btn-copy {*/
/*    top: 12px;*/
/*    right: 15px;*/
/*  }*/
/*}*/

.input-group.input_home {
  background-color: transparent;
  padding: 0 10px 0 0;
  position: relative;
  color: #fff;

  .input {
    font-size: 16px;
    line-height: 22px;
    padding: 12px 5px 12px 10px;
    margin-right: 10px;
    border-radius: 4px 0 0 4px;
  }
}

.input-group.input_affiliate {
  /*background: rgba(255, 255, 255, 0.6);*/
  /*border-radius: 20px;*/
  /*padding: 0 0 0 20px;*/
  /*position: relative;*/
  /*color: #002B50;*/
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);*/
  border-radius: 4px;
  padding: 0 10px 0 0;
  position: relative;
  color: #fff;
  background-color: #fff;

  .input {
    font-size: 15px;
    /*padding: 0;*/
    padding: 12px 30px 12px 10px;
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.3);*/
    margin-right: 10px;
    /*background-color: #4DB5FA;*/
    background-color: #004FAB;
    border-radius: 4px 0 0 4px;

  }

  .label {
    background: transparent;
    font-weight: 400;;
    font-size: 13px;
    line-height: 16px;
    color:  rgba(255, 255, 255, 0.75);
    position: absolute;
    pointer-events: none;
    top: 16px;
    left: 0;
    transition-duration: .3s;
  }

  &:focus-within {
    border: none;

    input {
      border-bottom: 1px solid #26A9F4;
    }

    .label {
      background: transparent;
      font-weight: 400;;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #26A9F4;
      position: absolute;
      top: 0;
      left: 0;
      transition-duration: .3s;
    }
  }

  .label.label_top {
    background: transparent;
    font-weight: 400;;
    font-size: 13px!important;
    line-height: 16px;
    letter-spacing: 0.4px;
    color:  rgba(255, 255, 255, 0.75);
    position: absolute;
    top: 0!important;
    left: 0;
    transition-duration: .3s;
  }
}
  .desc-error {
    position: absolute;
    /*top: 0;*/
    left: 0;
    font-weight: 400;;
    font-size: 10px;
    line-height: 14px;
    color: #F25958;
    margin-bottom: 0;
    z-index: +1;
  }

/*  .input-group .profile-input {*/
/*    padding: 0;*/
/*    color: #00C443;*/
/*  }*/

/*.input-group .profile-input-lite {*/
/*  padding: 0;*/
/*  color: #96AFD1;*/
/*}*/

.text-copy {
  font-weight: 400;;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  display: flex;
  align-items: center;
  /*background-color: #F0F6FE;*/
  /*height: 40px;*/
  /*border-radius: 0 8px 8px 0;*/
  position: absolute;
  right: -10px;
  top: -22px;
  padding-right: 10px;
  z-index: 10;


  i {
    font-size: 12px;
    margin: 3px 8px 0 0;
  }
}

/*.input-group .input_attributes {*/
/*  background-color: #F8FAFD;*/
/*  border-radius: 0;*/
/*  width: 100%;*/
/*}*/

  @media screen and (max-width: 1024px) {
    .input-group .create-input {
      height: 35px;
    }
  }

@media screen and (max-width: 768px) {
  .input-group .color_input {
    height: 34px;
  }



  .input-group .auth_input {
    height: 34px;
  }

  .btn-password {
    max-height: 34px;

    .v-btn:not(.v-btn--round).v-size--small {
      max-height: 34px;
      padding: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .input-group {

  }

  .input-group .auth_input {
    height: 30px;
    font-size: 10px;
  }

  .btn-password {
    max-height: 30px;

    i {
      font-size: 10px;
      height: 10px;
    }

    .v-btn:not(.v-btn--round).v-size--small {
      max-height: 30px;
      padding: 0;
    }
  }
}


@media screen and (max-width: 525px) {
  .input-group .color_input {
    height: 30px;
    font-size: 10px;
  }
}


@media screen and (max-width: 500px) {
  .input-group .create-input {
    height: 30px;
  }
}


//dark
  .dark .theme--light.v-label  {
    color: #96AFD1!important;
  }

  .dark .theme--light.v-input input,
  .dark .theme--light.v-select .v-select__selections {
    color: #fff!important;
  }

  .dark .input.input.settings_input-address {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
  }

  .dark .input-group.input_setting_input {
    color: rgba(150, 175, 209, 0.7);
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }

  .dark .input-group.input_main {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  }

  .dark .input-group.input_main .label {
    color: #96AFD1;
  }

  .dark .input-group.input_main .input {
    color: #fff;
  }

  .dark .input-group.input_read_only input {
    color: #fff;
  }
</style>