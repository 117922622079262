<template>
    <div>
        <div class="item-wallets">
            <div class="item-wallets__info">
                <div class="img" :class="payment.name_class"></div>
                <div class="block-info">
                    <p class="block-info__title">{{payment.name}}</p>
                    <div class="block-info__subtitle">
                        <span>{{address}}</span>
                        <span v-if="payment.cryptocurrency == '0'">{{item.card_month}}/{{item.card_year}}</span>
                    </div>
                </div>
            </div>

            <v-btn
                    class="btn-delete"
                    icon
                    text
                    v-on:click="modalDelete = true"
            >
                <img src="/dist/images/frontend/icon-del.svg" alt="/" />
                <img src="/dist/images/frontend/icon-del-blue.svg" alt="/" />
            </v-btn>
        </div>
        <ModalDefault :width="400" v-if="modalDelete" :modal_cancel.sync="modalDelete">
            <div class="wrap-modal">
                <div class="wrap-modal__head">
                    {{__('Delete payment method')}}
                </div>
                <div class="wrap-modal__body">
                    <p style="font-size: 15px">{{__('Are you sure you want to delete this payment method?')}}</p>
                    <div class="block-payment">
                        <div class="img" :class="payment.name_class"></div>
                        <div class="block-info">
                            <p class="block-info__title">{{payment.name}}</p>
                            <div class="block-info__subtitle">
                                <span>{{address}}</span>
                                <span v-if="payment.cryptocurrency == '0'">{{item.card_month}}/{{item.card_year}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-btn d-flex justify-content-end">
                        <v-btn
                                text
                                class="btn-cancel mr-4"
                                v-on:click="modalDelete = false"
                                v-ripple="false"
                        >
                            <span>{{__('Cancel')}}</span>
                        </v-btn>

                        <v-btn
                                class="btn-cancel"
                                text
                                v-ripple="false"
                                v-on:click="deleteWallet"
                                :loading="submitDisabled"
                        >
                            {{__('Confirm deletion')}}
                        </v-btn>
                    </div>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>
    import ModalDefault from "../../modules/ModalDefault";
    export default {
        data() {
            return {
                submitDisabled: false,
                modalDelete: false,
            }
        },
        props: {
            item: {
                type: Object
            },
            payment: {
                type: Object
            }
        },
        computed: {
            address() {
                return ((this.item.address) ? this.item.address.toString().slice(0,4) : this.item.card.toString().slice(0,4)) + '**********' + ((this.item.address) ? this.item.address.toString().slice(-4) : this.item.card.toString().slice(-4))
            }
        },
        methods: {
            deleteWallet() {
                this.submitDisabled = true;
                let response = {
                    wallet_id: this.item.id
                };

                this.$http.post('ajax/setting/wallet/delete', response)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            this.submitDisabled = false;
                            this.$emit("remove-wallet", this.item.id, this.payment);
                            this.modalDelete = false;
                        }
                    }).catch(err => {
                    this.submitDisabled = false;
                    let response = this.httpHandler(err);
                    if (response.error) {
                        this.formValidate = response.error.message;
                    }
                });
            }
        },
        components: {
            ModalDefault
        }
    }
</script>

<style scoped lang="scss">
    .item-wallets {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F0F6FE;
        height: 60px;
        padding: 0 25px;

        &__info {
            display: flex;
            align-items: center;

            .img {
                height: 36px;
                min-width: 36px;
                margin-right: 16px;
                border-radius: 50%;
                background-size: 16px;
                background-repeat: no-repeat;
                background-position: center;
            }

            .img.btc{
                background-color: #FF7A00;
                background-image: url(/dist/images/frontend/wallets/white-btc.svg);
            }
            .img.bsc-usd_bep20 {
                background-color: #f3ba2f;
                background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
            }
            .img.bnb{
                background-color: #f3ba2f;
                background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
            }

            .img.ada{
                background-color: #2A76D0;
                background-image: url(/dist/images/frontend/wallets/white-ada.svg);
            }
            .img.dot{
                background-color: #666;
                background-image: url(/dist/images/frontend/wallets/white-dot.svg);
            }
            //
            //.img.usdt {
            //  background-color: #4FD984;
            //  background-image: url(../../../public/images/wallets/white-usdt.svg);
            //}
            .img.ltc {
                background-color: #BBBBBB;
                background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
            }
            .img.doge {
                background-color: #D4BE00;
                background-image: url(/dist/images/frontend/wallets/white-doge.svg);
            }
            .img.usdt_trc20 {
                background-color: #C3332D;
                background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
            }
            .img.eth {
                background-color: #4A84FF;
                background-image: url(/dist/images/frontend/wallets/white-eth.svg);
            }
            .img.xrp {
                background-position: 9px center;
                background-color: #2DA9E8;
                background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
            }
            .img.bch {
                background-color: #8DC351;
                background-image: url(/dist/images/frontend/wallets/white-bch.svg);
            }
            .img.trx {
                background-color: #C3332D;
                background-image: url(/dist/images/frontend/wallets/white-trx.svg);
            }
            .img.link {
                background-color: #2A5ADA;
                background-image: url(/dist/images/frontend/wallets/white-link.svg);
            }
            .img.payeer {
                background-color: #2A93FF;
                background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
            }
            .img.pm {
                background-color: #FF0000;
                background-image: url(/dist/images/frontend/wallets/white-pm.svg);
            }
            .img.etc {
                background-color: #1B8600;
                background-image: url(/dist/images/frontend/wallets/white-etc.svg);
            }
            .img.adv {
                background-color: #0f2d38;
                background-image: url(/dist/images/frontend/wallets/advcash.svg);
                background-size: 20px;
                background-position: 11px center;
            }
            .img.dai{
                background-image: url(/dist/images/frontend/wallets/white-dai.svg);
                background-color: #F9AC13;
            }
            .img.dash{
                background-image: url(/dist/images/frontend/wallets/dash.png);
                /*background-color: #F9AC13;*/
                background-size: 100%;
            }

            .img.mc_uah,
            .img.mc_usd,
            .img.mc_rub {
                background-image: url(/dist/images/frontend/wallets/mc.svg);
                background-color: #333333;
                background-size: 24px;
            }

            .img.visa_uah,
            .img.visa_usd,
            .img.visa_rub {
                background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
                background-color: #00579F;
                background-size: 26px;
            }

            .block-info {
                &__title {
                    font-size: 14px;
                    line-height: 19px;
                    color: #002B50;
                    margin-bottom: 1px;
                }

                &__subtitle {
                    display: flex;

                    span {
                        font-size: 12px;
                        line-height: 16px;
                        color: #6886AF;

                        &:first-child {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

        .btn-delete {
            outline: none;
            transition: .3s;
            width: 20px;

            img {
                &:last-child {
                    display: none;
                }
            }

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 20px;
                padding: 0 5px;
            }

            &.v-btn:before {
                background-color: #fff;
            }

            &:hover {
                background-color: #fff;
                color: #26A9F4 !important;
                transition: .3s;

                img {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }
    }
    .block-payment {
        border: 1px solid #F0F6FE;
        border-radius: 4px;
        display: flex;
        padding: 10px;
        margin-bottom: 25px;

        .img {
            height: 36px;
            min-width: 36px;
            margin-right: 16px;
            border-radius: 50%;
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: center;
        }

        .img.btc{
            background-color: #FF7A00;
            background-image: url(/dist/images/frontend/wallets/white-btc.svg);
        }
        .img.bsc-usd_bep20 {
            background-color: #f3ba2f;
            background-image: url(/dist/images/frontend/wallets/white-usd_bep.svg);
        }
        .img.bnb{
            background-color: #f3ba2f;
            background-image: url(/dist/images/frontend/wallets/white-bnb.svg);
        }

        .img.ada{
            background-color: #2A76D0;
            background-image: url(/dist/images/frontend/wallets/white-ada.svg);
        }
        .img.dot{
            background-color: #666;
            background-image: url(/dist/images/frontend/wallets/white-dot.svg);
        }
        //
        //.img.usdt {
        //  background-color: #4FD984;
        //  background-image: url(../../../public/images/wallets/white-usdt.svg);
        //}
        .img.ltc {
            background-color: #BBBBBB;
            background-image: url(/dist/images/frontend/wallets/white-ltc.svg);
        }
        .img.doge {
            background-color: #D4BE00;
            background-image: url(/dist/images/frontend/wallets/white-doge.svg);
        }
        .img.usdt_trc20 {
            background-color: #C3332D;
            background-image: url(/dist/images/frontend/wallets/white-usd_trc.svg);
        }
        .img.eth {
            background-color: #4A84FF;
            background-image: url(/dist/images/frontend/wallets/white-eth.svg);
        }
        .img.xrp {
            background-position: 9px center;
            background-color: #2DA9E8;
            background-image: url(/dist/images/frontend/wallets/white-xrp.svg);
        }
        .img.bch {
            background-color: #8DC351;
            background-image: url(/dist/images/frontend/wallets/white-bch.svg);
        }
        .img.trx {
            background-color: #C3332D;
            background-image: url(/dist/images/frontend/wallets/white-trx.svg);
        }
        .img.link {
            background-color: #2A5ADA;
            background-image: url(/dist/images/frontend/wallets/white-link.svg);
        }
        .img.payeer {
            background-color: #2A93FF;
            background-image: url(/dist/images/frontend/wallets/white-payeer.svg);
        }
        .img.pm {
            background-color: #FF0000;
            background-image: url(/dist/images/frontend/wallets/white-pm.svg);
        }
        .img.etc {
            background-color: #1B8600;
            background-image: url(/dist/images/frontend/wallets/white-etc.svg);
        }
        .img.adv {
            background-color: #0f2d38;
            background-image: url(/dist/images/frontend/wallets/advcash.svg);
            background-size: 20px;
            background-position: 11px center;
        }
        .img.dai{
            background-image: url(/dist/images/frontend/wallets/white-dai.svg);
            background-color: #F9AC13;
        }
        .img.dash{
            background-image: url(/dist/images/frontend/wallets/dash.png);
            /*background-color: #F9AC13;*/
            background-size: 100%;
        }

        .img.mc_uah,
        .img.mc_usd,
        .img.mc_rub {
            background-image: url(/dist/images/frontend/wallets/mc.svg);
            background-color: #333333;
            background-size: 24px;
        }

        .img.visa_uah,
        .img.visa_usd,
        .img.visa_rub {
            background-image: url(/dist/images/frontend/wallets/visa_icon.svg);
            background-color: #00579F;
            background-size: 26px;
        }

        .block-info {
            &__title {
                font-size: 14px;
                line-height: 19px;
                color: #002B50;
                margin-bottom: 1px;
            }

            &__subtitle {
                display: flex;

                span {
                    font-size: 12px;
                    line-height: 16px;
                    color: #6886AF;

                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    .dark .item-wallets {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);

        &__info {
            .block-info {
                &__title {
                    color: #fff;
                }

                &__subtitle {
                    span {
                        color: #96AFD1;
                    }
                }
            }
        }
    }
</style>