<template>
  <div>
<!--      <Games></Games>-->
      <AboutNew></AboutNew>

  </div>
</template>

<script>
      // import Games from '../pages/Games/Games';
      import AboutNew from "./AboutNew";

      export default {
          name: 'HomeGuest',
          data: function() {
              return {
                  games: false
              }
          },
          props: {

          },
          computed: {

          },
          created() {
              window.addEventListener("DOMContentLoaded", () => {
                  if (window.matchMedia("(display-mode: standalone)").matches) {
                      // From browser to standalone
                      console.log('HomeGuest: From browser to standalone - is PWA');
                  } else {
                      // From standalone to browser
                      console.log('HomeGuest: From standalone to browser - isBrowser');
                  }
              });
          },
          methods: {

          },
          components: {
            // Games
            AboutNew
          },
      }
</script>
