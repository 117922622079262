<template>
<!--  <div style="position: fixed; right: 10px; top: 25px; z-index: 1000">-->
  <div v-if="notices.length" class="wrap-invite" style="position: fixed;left: 20px;bottom: 20px;z-index: 5000;">
<!--    {{invite_notice}}-->
    <NotificationsChatItem v-for="(notice) in notices" :key="notice.timestamp" v-bind:notice="notice"></NotificationsChatItem>
<!--    <NotificationsInviteGameItem v-for="(invite, key) in invite_game" :key="key" v-bind:invite_game.sync="invite_game" v-bind:invite="invite"></NotificationsInviteGameItem>-->
<!--    <pre>{{notices}}</pre>-->
  </div>
<!--  <v-expansion-panels v-model="panel" v-if="invite_game.length" class="wrap-invite">-->
<!--    <NotificationsInviteGameItem v-for="(invite, key) in invite_game" :key="key" v-bind:invite_game.sync="invite_game" v-bind:invite="invite"></NotificationsInviteGameItem>-->
<!--  </v-expansion-panels>-->
</template>

<script>
    import NotificationsChatItem from './NotificationsChatItem';

    export default {
        name: 'NotificationsInviteGame',
        data: function() {
            return {
                submitDisabled: false,
                panel: [0],
                currentQ: 0,
            }
        },
        methods: {
          // updateActiveClass(index) {
          //   this.currentQ = index;
          // }
        },
        computed: {
            notices() {
              return this.$store.state.auth.notice;
            },
        },
        components: {
            NotificationsChatItem
        },
    };
</script>

