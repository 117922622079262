<template>
  <div class="right" :class="(edit_message || resend_message) ? 'open' : ''">
    <div v-if="user">
      <div class="d-flex justify-space-between lighten-5 top">
        <div class="mess-back" v-on:click="$messOpen(!updateMess)">
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 7.27429L16.25 7.27429" stroke="" stroke-opacity="0.7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.2998 13.2987L1.2498 7.27469L7.2998 1.24969" stroke="" stroke-opacity="0.7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <v-list color="transparent" class="pa-0" v-if="(['notice', 'support']).includes(user.to)">
          <v-list-item class="px-0">
            <div class="wrap-img">
              <div class="block-img">
                <img :src="'/dist/images/frontend/chat_'+user.to+'.svg'"/>
              </div>
              <div class="online" v-if="user.to== 'support'"></div>
            </div>
            <v-list-item-content>
              <v-list-item-title>{{(user.to== 'support') ? 'Support center' : 'System notice'}}</v-list-item-title>
              <v-list-item-subtitle v-if="user.to== 'support'">Online</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list color="transparent" class="pa-0" v-else>
          <v-list-item class="px-0">
            <router-link :to="{name: 'profile', params: {id: user.public_id, lang: $store.getters.getLang}}" class="wrap-img" :class="(user.pro) ? 'user-pro-img' : ''">
              <div class="block-img">
                <img v-if="user.photo && typeof user.photo == 'object'" :src="user.photo[40]" />
                <img v-else-if="user.photo" :src="user.photo" />
                <span v-else class="photo" :style="{'background': user.bg_color}">{{user.name.substr(0,1)}}</span>
              </div>
              <div class="online"
                   v-if="now_date < date"
              ></div>
            </router-link>
            <v-list-item-content>
              <v-list-item-title >
                <router-link :class="(user.pro) ? 'user-pro' : ''" :to="{name: 'profile', params: {id: user.public_id, lang: $store.getters.getLang}}">{{user.name +' '+((user.last_name) ? user.last_name : '')}}</router-link>
              </v-list-item-title>
              <v-list-item-subtitle v-if="now_date > date">{{__('Online')}} {{$minAgo(timestamp_ago)}} {{__('ago')}}</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{__('Online')}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <SearchMessages v-bind:user="user" @scrollToMessage="scrollToMessage"></SearchMessages>
        <MessengerMessagesOption @actionOptions="actionOptions" v-bind:options="options"></MessengerMessagesOption>
      </div>
      <div ref="messages_list" class="body" style="overflow: auto" v-if="messages.data.length" @scroll="scrollMessages(this)">
        <MessengerMessagesList
          v-for="(message, key) in messages.data"
          :key="message.id"
          v-bind:index="key"
          v-bind:message="message"
          v-bind:messages="messages"
          v-bind:options="options"
          @editMessages="editMessages"
          v-bind:context_id="context_id"
          @openContextmenu="openContextmenu"
          @actionOptions="actionOptions"
          v-bind:user="user"
        ></MessengerMessagesList>
      </div>
      <div v-else class="no-result">
        {{__('No message here yet')}}
      </div>
      <div v-if="options.messages_edit" class="d-flex justify-space-between edit-block" v-on:click="((edit_list.length == 0) ? options.messages_edit= false : '')">
        <v-btn class="btn-del" v-on:click="modal_deleted= true" :disabled="(edit_list.length) ? false : true" :loading="submitDisabled">{{__('Delete')}}</v-btn>
        <p>{{__('Selected')}} {{edit_list.length}} {{__('messages')}}</p>
        <v-btn class="btn-cab" v-on:click="forwardMessages" :loading="submitDisabled" :disabled="(edit_list.length) ? false : true">{{__('Forward')}}</v-btn>
      </div>
      <div class="send-mess" v-else>
        <div v-if="edit_message" class="d-flex align-center resend-block">
          <v-btn class="btn-secondary" v-on:click="cancelEdit">{{__('cancel')}}</v-btn>
          <div class="resend_message">
            <p>{{__('Editing')}}</p>
            <p>{{decodeEmoji(edit_message.message)}}</p>
          </div>
        </div>
        <div v-else-if="resend_message" class="d-flex align-center resend-block">
          <v-btn class="btn-secondary" v-on:click="cancelResend">{{__('cancel')}}</v-btn>
          <div class="resend_message">
            <p>{{resend_message.user.name+" "+resend_message.user.last_name}}</p>
            <p>{{decodeEmoji(resend_message.message.message)}}</p>
          </div>
        </div>
        <InputLabelTextDefault
            ref="message"
            v-model="new_message"
            :placeholder='__("Type a message here")'
            type="textarea"
            v-on:keydown.enter.prevent.exact="keypressMessages"
            v-on:keydown.shift.enter.prevent.exact="newLine"
            maxlength="1000"
        >
          <template v-slot:left>
            <div
                class="smile-block"
                @mouseover="openEmoji = true"
                @mouseleave="openEmoji = false"
            >
              <div v-if="openEmoji">
                <VueChatEmoji class="emoj" :open="openEmoji"  @click="selectedEmoji"></VueChatEmoji>
              </div>
              <v-btn
                class="smile"

              >
                <img src="/dist/images/frontend/smile-icon.svg" alt="">
              </v-btn>
            </div>
          </template>
          <template v-slot:right>
            <v-btn
              class="smile-btn"
              v-on:click="sendMessage(edit_message)"
              :loading="submitDisabled"
            >
              <img src="/dist/images/frontend/navigation.svg" alt="">
<!--              {{(edit_message) ? 'edit' : 'send'}}-->

            </v-btn>
          </template>
        </InputLabelTextDefault>
      </div>
      <ModalDefault v-if="modal_deleted" v-bind:modal_cancel.sync="modal_deleted">
        <div class="text-center del-mess" >
          <div><h2>{{__('Are you sure you want to delete the selected message?')}}</h2></div>
          <div class="text-center text-window">
            <p>{{__('This action cannot be undone!')}}</p>
            <v-checkbox
              v-model="deleted"
            >
              <template v-slot:label>
                <div class="check-text">
                  {{__('Delete from me and from')}} {{user.name}}
                </div>
              </template>
            </v-checkbox>
            <div class="btn-window">
              <v-btn class="btn-info btn-del" v-on:click="deleteMessages(edit_list, deleted)">{{__('Delete')}}</v-btn>
              <v-btn class="btn-info-ok btn-secondary" v-on:click="modal_deleted= false">{{__('Cancel')}}</v-btn>
            </div>
          </div>
        </div>
      </ModalDefault>
      <ModalForward v-if="modal_forward" v-bind:edit_list="edit_list" v-bind:modal_cancel.sync="modal_forward" @openMessages="openMessages"></ModalForward>
    </div>
    <div v-else class="no-write">
      {{__('no one to write to')}}
    </div>
  </div>
</template>

<script>

    import moment from 'moment';
    import _ from 'lodash';
    import MessengerMessagesList from './MessengerMessagesList';
    import MessengerMessagesOption from './MessengerMessagesOption';
    import ModalForward from './ModalForward';
    import SearchMessages from './SearchMessages';
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {required, maxLength} from "vuelidate/lib/validators";
    import ModalDefault from '../../modules/ModalDefault';
    import { VueChatEmoji, emojis} from 'vue-chat-emoji';
    import "vue-chat-emoji/dist/vue-chat-emoji.min.css";

    export default {
        name: 'MessengerUsersList',
        data () {
            return {
                now_date: moment.utc(new Date()).format('X'),
                date: (this.user && this.user.online) ? moment.utc(this.user.online).format('X') : null,
                openEmoji: false,
                modal_deleted: false,
                submitDisabled: false,
                loadingPage: false,
                new_message: null,
                formValidate: {
                  new_message: null,
                },
                options: {
                  messages_edit: false,
                },
                edit_list: [],
                modal_forward: false,
                context_id: null,
                edit_message: null,
                resend_message: null,
                deleted: false,
                scroll_max: null,
                scroll_messages: [],
                timer: null,
                search: null
            }
        },
        watch: {
            message(message) {
                let messages= this.$cleanObject(this.messages);

                if(!this.messages.search) {
                    let indexMessage= this.$filterIndexObject(messages.data, message.id);
                    if(indexMessage >= 0 && !message.update) {
                        this.readMessage();
                        this.scrollBottom();
                    }
                }
            }
        },
        props: {
            messages: {
                type: Object
            },
            user: {
                type: Object
            },
            active_id: {
                type: Number
            },
            messages_id: {
                type: Array
            }
        },
        computed: {
            updateMess() {
              return this.$store.state.preference.updateMess
            },
            // time_ago() {
            //     let online= this.user.online;
            //
            //     if(online) {
            //         return 'Online '+ this.$minAgo(moment.utc(online).format('X'));
            //     } else {
            //         return 'Online';
            //     }
            // },
            timestamp_ago() {
              return moment.utc(this.user.online).format('X');
            },
            // group_date() {
            //     return _.groupBy(this.messages.data, item=> moment.utc(item.created_at).format('DD.MM.YYYY'));
            // },
            message() {
                return this.$store.getters.message;
            },
        },
        validations: {
            new_message: {
                required,
                maxLength: maxLength(1000)
            },
        },
        asyncComputed: {

        },
        mounted() {
            this.scrollBottom();
        },
        created() {
            this.readMessage();
        },
        methods: {
            selectedEmoji(args) {
                let endPos= this.$refs.message.$refs.input.selectionEnd,
                    startPos= this.$refs.message.$refs.input.selectionStart;
                let new_message= (this.new_message) ? this.new_message : '';
                this.new_message= new_message.substring(0, ((startPos) ? startPos : 0)) + args.emoji + new_message.substring(endPos, new_message.length);
            },
            cancelResend() {
                this.resend_message= null;
                this.new_message= null;
            },
            cancelEdit() {
                this.edit_message= null;
                this.new_message= null;
            },
            openMessages() {
                this.modal_forward= false;
                this.edit_list= [];
                this.options.messages_edit= false;
            },
            deleteMessages(edit_list, deleted) {
                this.submitDisabled = true;
                let request= {
                    'id': edit_list,
                    'user_id': this.user.to,
                    'deleted': deleted
                };
                this.modal_deleted= false;

                this.$http.post("ajax/chat/messages/deleted/", request)
                    .then((response) => {
                        return response.json();
                    })
                    .then(() => {
                        this.submitDisabled= false;
                        this.edit_list= [];
                        this.options.messages_edit= false;
                        this.deleted= false;
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
            },
            forwardMessages() {
                this.modal_forward= true;
            },
            editMessages(id, val) {
                let edit_list= this.edit_list;
                let index= this.$filterIndexArray(edit_list, id);

                if(val && index < 0) {
                    edit_list.push(id);
                } else if(!val && index >= 0) {
                    edit_list.splice(index, 1);
                }
                this.edit_list= edit_list;
                if(edit_list.length== 0) {
                    this.options.messages_edit= false;
                }
            },
            actionOptions(option) {
                option= this.$cleanObject(option);
                let edit_message;
                switch (option.value) {
                    case 'edit':
                        this.options.messages_edit= !this.options.messages_edit;
                        if(!this.options.messages_edit) {
                            this.edit_list= [];
                        }
                        break;
                    case 'edit_message':
                        edit_message= option.message.message;
                        option.message.message= edit_message;

                        this.edit_message= option.message;
                        this.new_message= this.decodeEmoji(edit_message);
                        break;
                    case 'resend_message':
                        this.resend_message= option;
                        this.new_message= null;
                        break;
                    case 'deleted_message':
                        this.deleteMessages(option.message, option.deleted);
                        break;
                    default:
                        this.$emit('actionOptions', option);
                        break;
                }
            },
            scrollBottom() {
                this.$nextTick(() => {
                    if(this.$refs.messages_list) {
                        this.$refs.messages_list.scrollTo(0, this.$refs.messages_list.scrollHeight);
                    }
                });
            },
            sendMessage(edit= false) {
                if(!this.submitDisabled) {
                    let valid = this.$v;
                    if (valid.$invalid) {
                        let massages = {
                            new_message: [],
                        };
                        this.formValidate = this.$formValidate(massages, valid);
                    } else {
                        this.submitDisabled = true;
                        let response = {
                            message: emojis.encodeEmoji(this.new_message),
                            user_id: this.user.to
                        };

                        let resend_message= this.resend_message;
                        if(edit) {
                            response.id= this.edit_message.id;
                        } else if(resend_message) {
                            response.message_id= this.resend_message.message.id;
                            response.resend_id= this.resend_message.message.user_id;
                        }

                        this.$http.post("ajax/chat/messages/send", response)
                            .then((response) => {
                                return response.json();
                            })
                            .then(() => {
                                // this.updateMessages(response.data);
                                this.submitDisabled= false;
                                this.new_message= null;
                                this.edit_message= null;
                                this.resend_message= null;
                            })
                            .catch((err) => {
                                this.submitDisabled = false;
                                this.httpHandler(err);
                            });
                    }
                }
            },
            // updateMessages(message) {
            //     this.$emit('updateMessages', message);
            // },
            scrollMessages() {
                this.readMessage();

                let messages_list= this.$refs.messages_list;
                let messages= this.$cleanObject(this.messages);
                const progress= messages_list.scrollTop / (messages_list.scrollHeight - messages_list.clientHeight);

                if(!this.loadingPage) {
                    if(messages.search) {
                        if (this.messages.prev_page && 0.4 >= progress) {
                            let created_at= messages.data[messages.data.length- 1].created_at;

                            this.loadingPage= true;
                            let response= {
                                user_id: this.user.to,
                                messages_id: this.messages_id,
                                created_at: created_at,
                                load: 'prev',
                            };

                            this.responseMessages(response);
                        } else if(this.messages.next_page && 0.6 <= progress) {
                            let created_at= messages.data[0].created_at;

                            this.loadingPage= true;
                            let response= {
                                user_id: this.user.to,
                                messages_id: this.messages_id,
                                created_at: created_at,
                                load: 'next',
                            };

                            this.responseMessages(response);
                        }
                    } else {
                        if(this.messages.next_page_url) {
                            if (0.4 >= progress) {
                                this.loadingPage= true;
                                let response= {
                                    user_id: this.user.to,
                                    messages_id: this.messages_id,
                                };


                                this.responseMessages(response);
                            }
                        }
                    }
                }
            },
            responseMessages(response, scroll_to= false) {
                this.$http.post("ajax/chat/messages/open", response)
                    .then(response => {
                        return response.json()
                    })
                    .then((response) => {
                        response= response.data;
                        let response_messages= response.messages,
                            messages_id;
                        if(scroll_to) {
                            messages_id= [];
                        } else {
                            let messages= this.$cleanObject(this.messages);
                            if(response.load) {
                                if(response.load== 'next') {
                                    response_messages.data= messages.data.concat(response_messages.data);
                                    response_messages.next_page= response_messages.next_page_url;
                                    response_messages.prev_page= messages.prev_page;
                                } else {
                                    if(response_messages.total > 0) {
                                        response_messages.data= response_messages.data.reverse().concat(messages.data);
                                    } else {
                                        response_messages.data= messages.data;
                                    }
                                    response_messages.prev_page= response_messages.next_page_url;
                                    response_messages.next_page= messages.next_page;
                                }
                                response_messages.search= true;
                            } else {
                                response_messages.data= response_messages.data.reverse().concat(messages.data);
                            }
                            messages_id= this.$cleanObject(this.messages_id);
                        }
                        this.$emit('update:messages', response_messages);

                        for(let i=0; i < response_messages.data.length; i++) {
                            let message= response_messages.data[i];
                            messages_id.push(message.id);
                        }

                        this.$emit('update:messages_id', messages_id);
                        this.loadingPage= false;

                        this.$nextTick(() => {
                            if (scroll_to) {
                                this.scrollToMessage(scroll_to);
                            }
                        });
                    })
                    .catch((err) => {
                        this.loadingPage= false;
                        this.httpHandler(err);
                    });
            },
            openContextmenu(index) {
                this.context_id= index;
            },
            decodeEmoji(mess) {
                return emojis.decodeEmoji(mess);
            },
            keypressMessages() {
                this.sendMessage(this.edit_message);
            },
            newLine(e) {
                let caret = e.target.selectionStart;
                e.target.setRangeText("\n", caret, caret, "end");
                this.new_message = e.target.value;
            },
            scrollToMessage(message) {
                let item= document.querySelector('[data-id="'+message.id+'"]');
                if(item) {
                    this.$refs.messages_list.scrollTo(0, item.offsetTop-this.$refs.messages_list.offsetTop-100);
                    item.classList.add('active-search');
                    setTimeout(function () {
                        item.classList.remove('active-search');
                    }, 2000);
                } else {
                    this.loadingPage= true;

                    let response= {
                        user_id: this.user.to,
                        created_at: message.created_at,
                    };

                    this.responseMessages(response, message);
                }
                // let messages_list= this.$refs.messages_list;
                // let items= messages_list.children;
            },
            readMessage() {
                let timer= this.timer;
                clearTimeout(timer);
                this.timer = setTimeout(()=> {
                    let messages_list= this.$refs.messages_list;
                        if(messages_list) {
                        let scroll= messages_list.scrollTop;
                        // if(this.scroll_max== null || this.scroll_max > scroll) {
                        this.scroll_max= scroll;

                        let items= messages_list.children,
                            messages_watch= [];

                        for (let i = items.length- 1; i >= 0; i--) {
                            let item= items[i];

                            if(scroll >= (item.offsetTop+40 - messages_list.offsetTop)) {
                                break;
                            }
                            if(item.dataset.id) {
                                messages_watch.push(item.dataset.id);
                            }
                        }

                        let messages_id= _.xor(messages_watch, this.scroll_messages),
                            messages= this.$cleanObject(this.messages.data),
                            messages_read= [];
                        for (let i = 0; i < messages_id.length; i++) {
                            let index= _.findIndex(messages, {"id": messages_id[i], "opp_type": "1", "read": "0"});
                            if(index >= 0) {
                                messages_read.push(messages[index].id);
                            }
                        }
                        if(messages_read.length) {
                            this.scroll_messages= _.concat(this.scroll_messages, messages_watch);

                            let response= {
                                user_id: this.user.to,
                                messages_id: messages_read,
                            };

                            this.$http.post("ajax/chat/messages/read", response)
                                .then(response => {
                                    return response.json()
                                })
                                .catch((err) => {
                                    this.loadingPage= false;
                                    this.httpHandler(err);
                                });
                        }

                    }
                    // }
                }, 1000);
            }
        },
        components: {
            ModalDefault,
            ModalForward,
            InputLabelTextDefault,
            MessengerMessagesList,
            MessengerMessagesOption,
            VueChatEmoji,
            SearchMessages,
        },

    }
</script>

<style>
  .resend_message {
    margin-left: 10px;
    padding-left: 10px;
  }
</style>