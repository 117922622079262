<template>
  <div class="page-promo" style="height: calc(100vh - 395px)">
    PromoMaterials.vue
  </div>
</template>

<script>
    export default {
        name: 'PromoMaterials',
        data () {
            return {

            }
        },
        computed: {

        },
        methods: {

        },
        components: {

        },

    }
</script>

<style lang="scss" scoped>
  .dark .page-promo {
    background: #171827;
    color: #fff;
  }

  .page-promo {
    background: #fff;
    width: 100%;
    border-radius: 4px;
  }
</style>
