<template>
    <v-skeleton-loader
        ref="skeleton"
        ::boilerplate="boilerplate"
        :type="type"
        :tile="tile"
    ></v-skeleton-loader>

    <!--        <div class="language">-->
    <!--            <p>Language</p>-->
    <!--            <ul v-if="user_info" class="">-->
    <!--                <li v-if="languages" class="nav-item text-nowrap mt-1">-->
    <!--                    <select class="form-control form-control-sm turnintodropdown"  v-model="active_languages">-->
    <!--                        <option id="1" v-for="(item, key) in languages" :value="item.lang" :key="key">{{item.full_lang}}</option>-->
    <!--                        <lable for="1"> zxc </lable>-->

    <!--                    </select>-->
    <!--                </li>-->
    <!--            </ul>-->
    <!--            <div v-else class="text-white-50">-->
    <!--                <v-icon width="24" height="24" name="spinner" spin/>-->
    <!--            </div>-->
    <!--        </div>-->
</template>

<script>
    export default {
        data: function() {
            return {
                boilerplate: false,
                tile: false,
                type: 'image@'+this.count,
                types: [],
            }
        },
        props: {
            count:{
                type: Number,
                default: 1
            }
        }

    }
</script>

<style>



</style>
