<template>
    <div class="modal-ready" :class="(hide_ready) ? 'hide' : ''">
        <div class="modal-ready__icon" @click="showModal">
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.7">
                    <path d="M17.9999 27.39C18.7345 27.39 19.3299 26.7945 19.3299 26.06C19.3299 25.3255 18.7345 24.73 17.9999 24.73C17.2654 24.73 16.6699 25.3255 16.6699 26.06C16.6699 26.7945 17.2654 27.39 17.9999 27.39Z" fill="#FF4B55"/>
                    <path d="M18 22.61C17.7348 22.61 17.4804 22.5046 17.2929 22.3171C17.1054 22.1296 17 21.8752 17 21.61V9.60999C17 9.34477 17.1054 9.09042 17.2929 8.90288C17.4804 8.71534 17.7348 8.60999 18 8.60999C18.2652 8.60999 18.5196 8.71534 18.7071 8.90288C18.8946 9.09042 19 9.34477 19 9.60999V21.61C19 21.8752 18.8946 22.1296 18.7071 22.3171C18.5196 22.5046 18.2652 22.61 18 22.61Z" fill="#FF4B55"/>
                    <path d="M15.0615 1.68099C15.3395 1.17514 15.7482 0.753275 16.245 0.459501C16.7418 0.165727 17.3084 0.0108431 17.8856 0.0110476C18.4628 0.011252 19.0293 0.166537 19.5259 0.460663C20.0225 0.754788 20.431 1.17695 20.7085 1.68299L34.5985 27.243C34.8649 27.7334 34.9993 28.2846 34.9884 28.8426C34.9776 29.4006 34.8219 29.9462 34.5366 30.4259C34.2513 30.9056 33.8463 31.3028 33.3612 31.5788C32.8761 31.8548 32.3276 31.9999 31.7695 32H4.02153C3.45715 32.0126 2.89938 31.8766 2.40413 31.6057C1.90888 31.3348 1.49355 30.9384 1.1998 30.4564C0.906045 29.9743 0.744184 29.4235 0.730446 28.8592C0.716708 28.2948 0.851575 27.7368 1.12153 27.241L15.0615 1.68099ZM2.87953 28.198C2.77649 28.3874 2.7255 28.6007 2.73175 28.8163C2.73799 29.0318 2.80124 29.2418 2.91507 29.425C3.02889 29.6081 3.18921 29.7578 3.37971 29.8588C3.57021 29.9598 3.78407 30.0085 3.99953 30H31.7695C31.981 29.9999 32.1888 29.9448 32.3726 29.8401C32.5563 29.7355 32.7097 29.5849 32.8177 29.403C32.9257 29.2212 32.9846 29.0145 32.9886 28.803C32.9926 28.5916 32.9416 28.3828 32.8405 28.197L18.9535 2.64199C18.8483 2.45076 18.6936 2.29128 18.5057 2.18018C18.3179 2.06907 18.1036 2.01041 17.8853 2.01031C17.667 2.0102 17.4527 2.06867 17.2647 2.1796C17.0767 2.29053 16.922 2.44986 16.8165 2.64099L2.87853 28.198H2.87953Z" fill="#FF4B55"/>
                </g>
            </svg>
        </div>

        <div class="modal-ready__text">
            {{__('We have noticed that your account is not active, and temporarily hid all the matches you created. If you want to continue - click on the "Start" button in the upper right corner')}}
        </div>

        <v-btn text v-ripple="false" class="btn-link" @click="hideModal">
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.78321 5L10.8332 0.950003C10.9242 0.843724 10.9718 0.707015 10.9664 0.567195C10.961 0.427375 10.903 0.294743 10.8041 0.195801C10.7051 0.0968597 10.5725 0.038897 10.4327 0.0334963C10.2929 0.0280957 10.1562 0.0756549 10.0499 0.16667L5.99988 4.21667L1.94988 0.161114C1.8436 0.0700992 1.70689 0.0225405 1.56707 0.0279412C1.42725 0.0333418 1.29462 0.091304 1.19568 0.190245C1.09673 0.289187 1.03877 0.42182 1.03337 0.56164C1.02797 0.70146 1.07553 0.838169 1.16654 0.944447L5.21654 5L1.16099 9.05C1.10283 9.09981 1.0556 9.1611 1.02225 9.23002C0.988905 9.29895 0.970166 9.37402 0.967211 9.45053C0.964256 9.52704 0.977148 9.60334 1.00508 9.67463C1.03301 9.74592 1.07537 9.81067 1.12952 9.86481C1.18366 9.91895 1.24841 9.96132 1.3197 9.98925C1.39099 10.0172 1.46728 10.0301 1.5438 10.0271C1.62031 10.0242 1.69538 10.0054 1.7643 9.97207C1.83323 9.93873 1.89452 9.89149 1.94432 9.83334L5.99988 5.78334L10.0499 9.83334C10.1562 9.92435 10.2929 9.97191 10.4327 9.96651C10.5725 9.96111 10.7051 9.90315 10.8041 9.80421C10.903 9.70526 10.961 9.57263 10.9664 9.43281C10.9718 9.29299 10.9242 9.15628 10.8332 9.05L6.78321 5Z" fill="#6886AF"/>
            </svg>
        </v-btn>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                hide_ready: false,
            }
        },
        methods: {
            hideModal() {
                this.hide_ready = true;
                localStorage.hide_ready = true;
            },

            showModal() {
                this.hide_ready = false;
                localStorage.hide_ready = false;
            }
        },
        mounted() {
            if (localStorage.hide_ready) {
                if (localStorage.hide_ready == 'false') {
                    this.hide_ready = false;
                } else {
                    this.hide_ready = true;
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

    .dark {
        .modal-ready {
            background: #fff;

            &__text {
                color: #002b50;
            }
        }

        .hide.modal-ready {
            &:hover {
                background: #F3F7FE;
            }
        }
    }



    .hide.modal-ready {
        max-width: 52px;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(0);
        right: 20px;
        left: auto;
        bottom: 20px;

        &:hover {
            background: #181818;
            transition-duration: .3s;

            .modal-ready__icon {
                transform: scale(1);
                transition-duration: .3s;
            }
        }

        .modal-ready__text {
            display: none;
        }

        .modal-ready__icon {
            margin: 0;
            transform: scale(0.9);
            cursor: pointer;
            transition-duration: .3s;
        }

        .v-btn.btn-link {
            display: none;
        }
    }

    .modal-ready {
        max-width: 670px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #171827;
        border: 1px solid rgba(253, 174, 2, 0.05);
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 12px 7px 12px 15px;
        position: fixed;
        z-index: 301;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);

        &__icon {
            margin-right: 18px;
        }

        &__text {
            font-size: 14px;
            line-height: 19px;
            color: #fff;
            margin-right: 5px;
        }

        .v-btn.btn-link {
            transition-duration: .3s;
            &:hover {
                transform: scale(1.1);
                transition-duration: .3s;
            }
        }

        .v-btn.btn-link.v-btn:not(.v-btn--round).v-size--default {
            padding: 8px;
        }

        .v-btn:not(.v-btn--round).v-size--default {
            height: fit-content;
        }
    }

    @media screen and (max-width: 720px) {
        .modal-ready {
            left: 20px;
            right: 20px;
            transform: translateX(0);
            width: auto;
        }
    }

    @media screen and (max-width: 550px) {
        .modal-ready {
            &__text {
                font-size: 12px;
                line-height: 17px;
            }
        }
    }
</style>