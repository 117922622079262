<template>
  <div class="d-flex justify-space-between" :class="((item.public_id== active_id) ? 'blue': '')" v-on:click="sendMessages(item)">
    <v-list color="transparent" class="pa-0">
      <v-list-item class="px-0">
        <div class="wrap-img">
          <div class="block-img">
            <img v-if="item.photo" :src="item.photo" />
            <span v-else class="photo" :style="{'background': item.bg_color}">{{item.name.substr(0,1)}}</span>
          </div>
          <div class="online" v-if="now_date < date"></div>
        </div>
        <v-list-item-content>
          <v-list-item-title>{{item.name +' '+((item.last_name) ? item.last_name : '')}}</v-list-item-title>
          <v-list-item-subtitle>{{$timeAgo(item.last_message_time)}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'ModalForwardListItem',
        data () {
            return {
                search: null,
                now_date: moment.utc(new Date()).format('X'),
                date: (this.item.online) ? moment.utc(this.item.online).format('X') : null,
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            }
        },
        props: {
            item: {
                type: Object
            },
            active_id: {
                type: Number
            }
        },
        methods: {
            sendMessages(item) {
                this.$emit('sendMessages', item);
            },
        },
        components: {


        },

    }
</script>
