<template>
  <div>
    <slot></slot>
    <div v-if="users_list && users_list.total > 0">
      <FriendsItem v-for="(item, key) in users_list.data" v-bind:item.sync="item" v-bind:position="key+1" v-bind:key="key" v-bind:currentTab="currentTab" v-bind:operation="operation"></FriendsItem>
<!--      <v-btn class="btn-all-list" text tag="a" :to="{name: 'peoples_current_operation', params: {current: currentTab, operation: operation}}" v-if="(property.tab== 2 && !property.operation) && users_list.last_page > 1">-->
      <v-btn class="btn-all-list" text tag="a" @click="showRequest" :loading="submitDisabled" v-if="(property.tab== 2 && !property.operation) && users_list.last_page > 1">
        {{__('More')}} {{users_list.total-users_list.per_page}}
        <img width="12px" src="/dist/images/frontend/chevron-down-grey.svg" alt />
      </v-btn>
    </div>
<!--    <div v-else-if="users_list.total== 0 && currentTab=='requests'"></div>-->

    <div v-else-if="users_list.total == 0 && property.tab == 2" class="block-empty-friends">
      <img class="img img--1" src="/dist/images/frontend/not-referrals.svg" alt />
      <p>{{__("You don't have any friend requests")}} :(</p>
    </div>

    <div v-else-if="users_list.total == 0" class="block-empty-friends">
      <img class="img img--1" src="/dist/images/frontend/not-referrals.svg" alt />
      <p>{{__('You dont have friends')}} :(</p>
    </div>
    <div v-else>
      {{__('loading')}}
    </div>

  </div>
</template>

<script>
    import FriendsItem from "./FriendsItem";

    export default {
        name: 'FriendsList',
        data () {
            return {
            }
        },
        props: {
            users_list: {
                type: Object
            },
            property: {
                type: Object
            },
            operation: {
                type: String
            },
            currentTab: {
                type: String
            },
            submitDisabled: {
              type: Boolean
            }
        },

        computed: {

        },
        methods: {
          showRequest() {
            this.$emit('showMoreRequest')
          }
        },
        components: {
            FriendsItem
        },

    }
</script>

<style scoped lang="scss">
  .v-btn.btn-all-list {
    background-color: #F0F6FE;
    text-transform: inherit;
    color: #94ADCF;
    outline: none;
    border-radius: 8px;
    margin: 20px auto -20px;
    display: flex;
    width: fit-content;
    justify-content: center;

    img {
      margin-top: 2px;
      margin-left: 6px;
    }


    &:hover {
      background-color: #E7EFFF;
      color: #002B50;

      img {
        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(30%) contrast(90%);
      }
    }
    &.v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 82px;
      padding: 0 12px;
    }
  }

  .dark {
    .block-empty-friends {
      p {
        color: #fff;
      }

      img {
        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
      }
    }
  }

  .block-empty-friends {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      height: 30px;
      margin-bottom: 5px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      margin: 7px 0 0;
      color: #002B50;
      text-align: center;
    }
  }
</style>