<template>
  <div :class="(!auth) ? 'dark' : ''">
    <div class="wrapper-notFound">
      <img src="/dist/images/frontend/notFound.svg" alt />
      <p>{{__('Oops, We can seem to find the page what you are looking for.')}}</p>
      <BaseButton class_btn="btn-guest" :to="{name: 'home', params: {lang: $store.getters.getLang}}">
        <span>{{__('Back to Home page')}}</span>
      </BaseButton>
    </div>
  </div>
</template>

<script>

    export default {
        name: 'NotFound',
        data () {
            return {

            }
        },
        computed: {
          auth() {
            return this.$store.state.auth.user
          }
        },
        asyncComputed: {

        },
        methods: {
        },
        components: {

        },

    }
</script>

<style lang="scss" scoped>

  .dark .wrapper-notFound {

    p {
      color: #fff;
    }
  }
  .wrapper-notFound {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      max-width: 580px;
      font-family: "UniNeueBook";
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #002B50;
      margin: 40px 0 30px;
    }
  }

  @media screen and (max-width: 950px) {
    .wrapper-notFound {
      img {
        width: 350px;
      }

      p {
        max-width: 480px;
        font-family: "UniNeueBook";
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #002B50;
        margin: 10px 0 20px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .wrapper-notFound {
      img {
        width: 300px;
      }

      p {
        max-width: 420px;
        font-family: "UniNeueBook";
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        color: #002B50;
        margin: 20px 0 20px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .wrapper-notFound {
      img {
        width: 280px;
      }

      p {
        max-width: 350px;
        font-family: "UniNeueBook";
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #002B50;
        margin: 10px 0 20px;
      }
    }
  }
</style>
