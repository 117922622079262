<template>
  <div>
    <v-dialog v-model="dialog" max-width="320px">
      <div class="wrap-sorting">
        <div class="wrap-sorting-head">
          <div class="wrap-sorting-head__icon">
            <img src="/dist/images/frontend/filter-icon.svg" alt />
          </div>
          <h5>{{__('Filters')}}</h5>
        </div>
        <div class="wrap-sorting-body">
          <div class="region betting">
            <div class="betting__head">
              <div class="map__icon">
                <img src="/dist/images/frontend/type-icon.svg" alt />
              </div>
              <p>{{__('Type')}}</p>
            </div>
            <div class="region__input">
              <DropdownDefault
                      style="width: 100%"
                      color="select_color"
                      v-bind:value.sync="formData.type"
                      v-bind:dropdown="sortType"
                      @change="updateValidate('type')"
                      v-bind:errors="formValidate.type"
              ></DropdownDefault>
            </div>
          </div>
          <div class="betting">
            <div class="betting__head">
              <div class="map__icon">
                <img src="/dist/images/frontend/amount-icon.svg" alt />
              </div>
              <p>{{__('Amount')}}</p>
            </div>
            <v-radio-group row class="wrap-radio">
              <v-checkbox v-model="formData.income" color="blue" value="1" :label="__('Income')"></v-checkbox>
              <v-checkbox v-model="formData.income" color="blue" value="2" :label="__('Expens')"></v-checkbox>
            </v-radio-group>
          </div>
          <div class="betting">
            <div class="betting__head">
              <div class="map__icon">
                <img src="/dist/images/frontend/coin-icon.svg" alt />
              </div>
              <p>{{__('Coin')}}</p>
            </div>
            <v-radio-group row class="wrap-radio">
              <v-checkbox v-model="formData.wallet" color="blue" value="1" :label="__('Gold')"></v-checkbox>
              <v-checkbox v-model="formData.wallet" color="blue" value="2" :label="__('Silver')"></v-checkbox>
            </v-radio-group>
          </div>
          <div class="betting date">
            <div class="betting__head">
              <div class="map__icon">
                <img src="/dist/images/frontend/date-icon.svg" alt />
              </div>
              <p>{{__('Date')}}</p>
            </div>
            <div class="wrap-date">
              <div class="block-birthday">
                <v-menu
                        v-if="inputDateFrom == null || inputDateFrom == ''"
                        v-model="date"
                        :close-on-content-click="false"
                        max-width="290"
                >
                  <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                  </template>
                  <v-date-picker
                          ref="picker"
                          color="blue lighten-1"
                          :min="minYear"
                          :max="maxYear"
                          v-model="formData.date_from"
                          @change="changeCalendar"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                        v-else
                        v-model="date_menu"
                        :close-on-content-click="false"
                        max-width="290"
                >
                  <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDate"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                  </template>
                  <v-date-picker
                          color="blue lighten-1"
                          :min="minYear"
                          :max="maxYear"
                          v-model="formData.date_from"
                          @change="changeCalendar"
                  ></v-date-picker>
                </v-menu>

                <v-text-field
                        color="light-blue"
                        label="From"

                        v-mask="'##.##.####'"
                        v-model="inputDateFrom"
                        clearable
                        @input="changeInput"
                        @change="updateDate"
                        ref="birthday"
                ></v-text-field>
                <div class="information">{{__('(DD.MM.YYYY)')}}</div>
              </div>



              <div class="block-birthday">
                <v-menu
                        v-if="inputDateTo == null || inputDateTo == ''"
                        v-model="dateTo"
                        :close-on-content-click="false"
                        max-width="290"
                >
                  <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDateTo"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                  </template>
                  <v-date-picker
                          ref="picker"
                          color="blue lighten-1"
                          :min="minYear"
                          :max="maxYear"
                          v-model="formData.date_to"
                          @change="changeCalendarTo"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                        v-else
                        v-model="date_menuTo"
                        :close-on-content-click="false"
                        max-width="290"
                >
                  <template v-slot:activator="{ on }">
                <span
                        class="calendar"
                        prepend-icon="mdi-calendar"
                        :value="computedDateTo"
                        clearable
                        readonly
                        v-on="on"
                >
                    <img src="/dist/images/frontend/Calendar.svg" alt />
                </span>
                  </template>
                  <v-date-picker
                          color="blue lighten-1"
                          :min="minYear"
                          :max="maxYear"
                          v-model="formData.date_to"
                          @change="changeCalendarTo"
                  ></v-date-picker>
                </v-menu>

                <v-text-field
                        color="light-blue"
                        label="To"
                        v-mask="'##.##.####'"
                        v-model="inputDateTo"
                        clearable
                        @input="changeInput"
                        @change="updateDateTo"
                        ref="birthday"
                ></v-text-field>
                <div class="information">{{__('(DD.MM.YYYY)')}}</div>
              </div>
            </div>
          </div>
          <div class="wrap-btn">
            <div style="width: 48%">
              <BaseButton
                      v-on:click="cancelFilters()"
                      :loading="submitDisableded"

                      class_btn="btn-liteGrey"
              >
                <span>{{__('Clear')}}</span>
              </BaseButton>
            </div>

            <div style="width: 48%">
              <BaseButton
                      v-on:click="onSubmit"
                      :loading="submitDisabled"

              >
                <span>{{__('Apply')}}</span>
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import DropdownDefault from "../../modules/DropdownDefault";
import {maxLength, minLength, numeric} from "vuelidate/lib/validators";
import moment from "moment";
import {mask} from 'vue-the-mask';

export default {
  name: "TransactionSorting",
  data() {
    let formData= this.$cleanObject(this.form);

    return {
      // search: null,
      formData: formData,
      submitDisabled: false,
      submitDisableded: false,
      dialog: true,

      formValidate: {
        search: null,
        type: null,
        income: null,
        wallet: null,
        date_from: null,
        date_to: null,
      },

      date: false,
      dateTo: false,
      inputDateFrom:(formData.date_from) ? moment(formData.date_from).format('DD.MM.YYYY') : null,
      inputDateTo:(formData.date_to) ? moment(formData.date_to).format('DD.MM.YYYY') : null,
      date_menu: false,
      date_menuTo: false,

      sortType: [
        {
          title: this.__("All type"),
          value: 0,
        },
        {
          title: this.__("Deposit"),
          value: 1,
        },
        {
          title: this.__("Withdraw"),
          value: 2,
        },
        {
          title: this.__("Game battle"),
          value: 3,
        },
        {
          title: this.__("Referral"),
          value: 4,
        },
        {
          title: this.__("Achievements"),
          value: 5,
        },
        {
          title: this.__("Game commission"),
          value: 6,
        },
        {
          title: this.__("Quest"),
          value: 7,
        },
        {
          title: this.__("Transfer"),
          value: 8,
        },
        {
          title: this.__("Transfer of partner funds"),
          value: 9,
        },
        {
          title: this.__("Bonus"),
          value: 10,
        },
      ],
    };
  },

  validations() {
      return {
          formData: {
              sorting: {
                  search: {
                      maxLength: maxLength(100),
                      minLength: minLength(1),
                  },
                  type: {
                      numeric
                  },
              },
          }
      }
  },

  props: {
    // search: {
    //   type: String,
    // },
    color: {
      type: String,
    },

    sorting: {
        type: Boolean
    },
    search: {
        type: String
    },
    modal_sorting: {
      type: Boolean,
    },
    form: {
      type: Object,
    },
    filter: {
      type: Array,
    }
  },

  watch: {
    dialog() {
      this.$emit("update:modal_sorting", false);
    },
  },

  computed: {
    minYear: function () {
      return (new Date().getFullYear() - 100).toString();

    },
    maxYear: function () {
      // let a = new Date().getTime() - 567648000000;
      let a = new Date().getTime();
      return moment(a).format('YYYY-MM-DD');
      // return (new Date().getFullYear() - 18).toString();
    },
    computedDate() {
      return this.formData.date_from ? moment(this.formData.date_from).format('DD.MM.YYYY') : ''
    },
    computedDateTo() {
      return this.formData.date_to ? moment(this.formData.date_to).format('DD.MM.YYYY') : ''
    },
  },
  methods: {
    updateValidate: function (type) {
      this.formValidate = this.$changeValidate(
          this.formValidate,
          this.$v.formData[type],
          type
      );
    },
    onSubmit: function () {
      let valid = this.$v.formData;

      if (valid.$invalid) {
        let massages = {
          search: [],
          type: [],
          income: [],
          wallet: [],
          date_from: [],
          date_to: [],
        };
        this.formValidate = this.$formValidate(massages, valid);
      } else {

          let formData= this.formData;

        if (formData.type == 0) {
            formData.type = null;
        }

        this.$emit('update:histories', null);

        let sorting = formData;

        let count = [];
        Object.keys(sorting).forEach((key) => {
          if(sorting[key]) {
            count.push(sorting[key]);
          }
        });
        this.$emit('update:filter', count);
        this.$emit('update:form', sorting);

        let response = sorting;
        response.search = this.search;

        this.submitDisabled = true;
        this.$emit('updateParams', response);
        console.log('responseresponse');
        console.log(JSON.stringify(response));
        console.log(count);
        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction", response)
            .then((response) => {
              return response.json();
            })
            .then((response) => {
              this.submitDisabled = false;
              this.$emit('updateTransactions', response.data);
              this.$emit('update:sorting', false);
              this.$emit('update:modal_sorting', false);
            })
            .catch((err) => {
              this.submitDisabled = false;
              let response = this.httpHandler(err);
              if (response.error) {
                this.formValidate = response.error.message;
              }
            });
      }
    },

    cancelFilters: function () {
      this.$emit('update:histories', null);

      let sorting = {
        type: null,
        // income: this.income.length ? this.income : null,
        income: null,
        // wallet: this.wallet.length ? this.wallet : null,
        wallet: null,
        date_from: null,
        date_to: null,
      };

      let count = [];
      Object.keys(sorting).forEach((key) => {
        if(sorting[key]) {
          count.push(sorting[key]);
        }
      });
      this.$emit('update:filter', count);
      this.$emit('update:form', sorting);

      let response = sorting;
      response.search = this.search;
      this.submitDisableded = true;
      this.$emit('updateParams', response);
      this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/transaction", response)
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            this.type = null;
            this.income = [];
            this.wallet = [];
            this.date_from = null;
            this.date_to = null;
            this.inputDateFrom = null;
            this.inputDateTo = null;
            this.submitDisableded = false;
            this.$emit('updateTransactions', response.data);
            this.$emit('update:sorting', false);
            this.$emit('update:modal_sorting', false);
            // this.$emit('update:search', null);
          })
          .catch((err) => {
            this.submitDisableded = false;
            let response = this.httpHandler(err);
            if (response.error) {
              this.formValidate = response.error.message;
            }
          });
    },
    changeCalendar: function () {
      this.date_menu = false;
      this.date = false;
      this.inputDateFrom = this.computedDate;
    },
    changeCalendarTo: function () {
      this.date_menuTo = false;
      this.dateTo = false;
      this.inputDateTo = this.computedDateTo;
    },
    changeInput: function (value) {
      if (value) {
        this.errorDate = false;
      }
    },
    updateDate: function (value) {
      this.errorDate = false;
      if (this.validationDate()) {
        let dateParts = value.split(".");
        let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        this.date_from = moment(new Date(dateObject)).format('YYYY-MM-DD');
      } else if (this.inputDateFrom == '') {
        this.date_from = moment(new Date()).format('YYYY-MM-DD');
        this.errorDate = false;
      } else {

        this.errorDate = true
      }
    },
    updateDateTo: function (value) {
      this.errorDate = false;
      if (this.validationDate()) {
        let dateParts = value.split(".");
        let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        this.date_to = moment(new Date(dateObject)).format('YYYY-MM-DD');
      } else if (this.inputDateTo == '') {
        this.date_to = moment(new Date()).format('YYYY-MM-DD');
        this.errorDate = false;
      } else {

        this.errorDate = true
      }
    },
    validationDate: function () {
      let date = this.inputDateFrom;
      let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
      // pattern.test(date);
      if (date) {
        let year = (+date.split('.')[2])
        let minData = (new Date().getFullYear() - 100);
        let maxDate = (new Date().getFullYear() - 18);
        let check =  (year >= minData && year <= maxDate) ? true : false;

        if (check) {
          this.majority = true
        } else {
          this.majority = false
        }

        return (pattern.test(date)) && check;
      }
    },
    validationDateTo: function () {
      let date = this.inputDateTo;
      let pattern = /^((0[1-9]|[12][0-9]|3[01])(\.)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\.)(02))|((0[1-9]|[12][0-9]|3[0])(\.)(0[469]|11))(\.)\d{4}$/;
      // pattern.test(date);
      if (date) {
        let year = (+date.split('.')[2])
        let minData = (new Date().getFullYear() - 100);
        let maxDate = (new Date().getFullYear() - 18);
        let check =  (year >= minData && year <= maxDate) ? true : false;

        if (check) {
          this.majority = true
        } else {
          this.majority = false
        }

        return (pattern.test(date)) && check;
      }
    },
  },
  components: {
    // InputLabelTextDefault,
    DropdownDefault
  },
  directives: {mask}
};
</script>


<style lang="scss">

.wrap-sorting {
    /*position: fixed;*/
    /*top: 87px;*/
    /*right: 0;*/
    max-width: 320px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;


  .wrap-sorting-head {
    padding: 20px;
    display: flex;
    align-items: center;
    //box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 0px 0px;
    border-bottom: 2px solid #ECF4FF;
    background: #fff;

    &__icon {
      margin-right: 17px;
      display: flex;
      align-items: center;
    }

    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #002B50;
    }
  }

  .search {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    padding: 10px 0 15px;
    box-shadow: 0px 2px 0px #F2F3FB;

    &__input {
      width: 100%;
    }

    &__icon {
      margin: 17px 14px 0 20px;
    }
  }

  .wrap-sorting-body {
      padding: 20px;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
  }

  .map {
      display: flex;
      align-items: center;
      margin:0 0 26px 0;
      position: relative;

      &__icon {
          margin: 0 18px 3px 0;
      }

      &__input {
          width: 100%;
          position: relative;

          p {
              position: absolute;
              top: -7px;
              left: 0;
              z-index: 10;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #6886AF;
          }
      }
  }

  /*.region {*/
  /*  margin-bottom: 20px;*/
  /*  p {*/
  /*    font-weight: 400;*/
  /*    font-size: 14px;*/
  /*    line-height: 19px;*/
  /*    margin-bottom: 10px;*/
  /*    color: #002b50;*/
  /*  }*/
  /*}*/
    .region {
        display: flex;
        position: relative;
        flex-direction: column;
        margin-bottom: 15px !important;

      .betting__head {
        margin-bottom: -10px;
      }

        &__icon {
            margin: 0 16px 0 0;
        }

        &__input {
            /*width: 100%;*/
            margin-left: 35px;
        }
        .v-input .v-label {
            font-size: 16px;
        }
    }

    .age {
        margin-bottom: 20px;

        &__icon {
            margin: 5px 16px 0 0;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: 0;
        }

        &__inputs {
            margin-top: 0;
            display: flex;
            justify-content: space-between;
            margin-left: 36px;
        }
    }

    .gender {
        margin-bottom: 0;

        &__head {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
        }

        &__icon {
            margin: 7px 16px 0 0;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: 0;
        }

        .wrap-checkbox {
            margin-left: 36px;

            .v-input {
                margin-right: 50px;
            }
        }
    }

    .date {
      .betting__head {
        margin-bottom: -10px;
      }
    }

    .betting {
        margin-bottom: 5px;

        &__head {
            display: flex;
            align-items: center;
            margin-bottom: 6px;
        }

        &__icon {
            margin: 7px 16px 0 0;
        }

        p {
            font-weight: 400;
            font-size: 15px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #6886AF;
            margin-bottom: 0;
        }
        .wrap-radio {
            margin-left: 35px;

            &.v-input--radio-group.v-input--radio-group--row .v-radio {
                margin-right: 40px;
            }

          .v-input {
            margin-right: 30px;
          }
        }
    }

  .v-label {
       font-size: 14px;
   }

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 4px;
    }
  }

  .wrap-date {
    display: flex;
    justify-content: space-between;
    margin-left: 35px;
    margin-top: 14px;

  }
  .block-birthday {
    max-width: 180px;
    width: 100%;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 30px;
    }

    .calendar {
      color: #96AFD1;
      position: absolute;
      z-index: 100;
      top: 5px;
      right: 0;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        color: #26A9F4;
      }
    }
    .information {
      position: absolute;
      bottom: -18px;
      left: 0;
      font-size: 11px;
      color: #6886af;
    }
  }

  .wrap-btn {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-btn {
      width: 48%;
      margin: 0 auto;
      display: block;

      &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
        min-width: 100px;
        width: 48%;
        padding: 0;

      }
    }

    .btn-secondary {
      margin-right: 15px;
    }

  }
}

@media screen and (max-width: 1350px) {
    .wrap-sorting {
        width: 280px;
    }
}

@media screen and (max-width: 920px) {

  .wrap-sorting {
    .sort,
    .region,
    .age {
      margin-bottom: 10px;

      p {
        margin-bottom: 5px;
      }
    }
    .male {
      p {
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 525px) {

  .wrap-sorting {
    .sort,
    .region,
    .age {
      margin-bottom: 6px;

      /*p {*/
      /*  margin-bottom: 1px;*/
      /*  font-size: 10px;*/
      /*}*/
    }
    .male {
      /*p {*/
      /*  margin-bottom: 1px;*/
      /*  font-size: 12px;*/
      /*}*/
    }

    .btn-cab {
      margin: 0 auto;
      display: block;

      &.v-btn:not(.v-btn--round).v-size--default {
        height: 30px;
        min-width: 64px;
        width: 100px;
        padding: 0;
        margin: 0 auto;
        font-size: 12px;
      }
    }

    .v-input--radio-group.v-input--radio-group--row .v-radio {
      margin-bottom: 0px;
    }
    .v-label {
      font-size: 12px;
    }
  }
}
</style>