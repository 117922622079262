<template>
  <div>
    <slot v-bind="users_list"></slot>
  </div>
</template>

<script>
    export default {
        name: 'Friends',
        data () {
            return {
            }
        },
        props: {
            users_list: {
              type: [Object, Boolean]
            },
            currentTab: {
              type: String
            },
            sorting: {
                type: Object
            },
            loading_page: {
                type: Boolean
            },
            update: {
                type: Boolean,
                default: false
            },
            property: {
                type: Object
            },
            progress: {
              type: Number
            },
            online: {
              type: [Boolean, Number]
            },
            search: {
              type: String
            }
        },
        watch: {
            currentTab: function () {
                this.sendHttp();
            },
            operation: function () {
                this.sendHttp();
            },
            update: function (val) {
                if(val) {
                    this.sendHttp();
                    this.$emit('update:update', false);
                }
            }
        },
        created() {
          if (this.friends_req_count  && this.$route.name=='friends') {
            this.$router.push({ name: 'friends_current', params:{'current': 'requests'}});
          } else {
            this.sendHttp();
          }
        },
        computed: {
            operation: function () {
                return (this.$route.params.operation) ? this.$route.params.operation : null
            },
            currentUser: function () {
                return (this.$route.params.user_id) ? this.$route.params.user_id : null
            },
            friends_req_count() {
              return this.$store.state.auth.friends_req_count;
            },
        },
        methods: {
            sendHttp() {
              let num = 0;
              let timeout = setInterval(() => {
                num += 1;
                this.$emit('update:progress', num);
                if(num > 50) {
                  clearInterval(timeout)
                }
              },15);
                let response= Object.assign(this.property,  this.sorting);
                let online;
                if(this.online) {
                  online = 1;
                } else {
                  online = 0;
                }
                response.online = online;
                response.search = this.search;
                // this.$emit('update:property', response);
                this.$emit('update:users_list', null);
                this.$emit('update:loading_page', false);
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/"+((this.currentTab == 'peoples') ? 'peoples' : 'friends'), response)
                .then((response) => {
                  return response.json();

                })
                .then((response)=>{
                    if(this.$checkAuth(response)) {
                        if(this.$route.name=='friends' && response.data.count_total== 0) {
                            this.$router.push({ name: 'friends_current', params:{'current': 'peoples'}});
                        }
                        //
                        // response = {
                        //   "data": {
                        //     "count_friends_req": 0,
                        //     "count_online": 1,
                        //     "count_total": 80,
                        //     "users": {
                        //       "current_page": 1,
                        //       "data": [
                        //         {
                        //           "id": 2,
                        //           "public_id": "happy",
                        //           "name": "Happy",
                        //           "last_name": "Proger",
                        //           "photo": {
                        //             "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                        //             "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                        //           },
                        //           "bg_color": "#f9851a",
                        //           "location": "AX",
                        //           "location_img": "de.png",
                        //           "online": "2021/01/27 13:13:10",
                        //           "rating_points": "802",
                        //           "subscribe": 2,
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 1,
                        //           "public_id": "v.volkonsky",
                        //           "name": "Vladyslav",
                        //           "last_name": "Volkonsky",
                        //           "photo": null,
                        //           "bg_color": "#f84138",
                        //           "location": "United Kingdom",
                        //           "location_img": "gb.png",
                        //           "online": "2021/01/27 13:13:10",
                        //           "rating_points": "751",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 1967725861,
                        //           "public_id": "1859465238",
                        //           "name": "Dark",
                        //           "last_name": "Raven",
                        //           "photo": null,
                        //           "bg_color": "#80dbff",
                        //           "location": "Ukraine",
                        //           "location_img": "gb.png",
                        //           "online": "2021/01/27 13:45:03",
                        //           "rating_points": "94",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 8,
                        //           "public_id": "1799696562",
                        //           "name": "Elena",
                        //           "last_name": "Cherniak",
                        //           "photo": "a-1605784297wHxVIO2Q7e.png",
                        //           "bg_color": "#29a3a3",
                        //           "location": "Ukraine",
                        //           "location_img": "ua.png",
                        //           "online": "2021/01/19 20:18:52",
                        //           "rating_points": "44",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 2,
                        //           "public_id": "happy",
                        //           "name": "Happy",
                        //           "last_name": "Proger",
                        //           "photo": "a-1609759324m90pBdwJSb.png",
                        //           "bg_color": "#f9851a",
                        //           "location": "AX",
                        //           "location_img": "de.png",
                        //           "online": "2021/01/27 13:13:10",
                        //           "rating_points": "802",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 1,
                        //           "public_id": "v.volkonsky",
                        //           "name": "22222222222222222",
                        //           "last_name": "222222222222222222",
                        //           "photo": null,
                        //           "bg_color": "#f84138",
                        //           "location": "United Kingdom",
                        //           "location_img": "gb.png",
                        //           "online": "2021/01/27 13:13:10",
                        //           "rating_points": "751",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 1967725861,
                        //           "public_id": "1859465238",
                        //           "name": "Dark",
                        //           "last_name": "Raven",
                        //           "photo": null,
                        //           "bg_color": "#80dbff",
                        //           "location": "Ukraine",
                        //           "location_img": "gb.png",
                        //           "online": "2021/01/27 13:45:03",
                        //           "rating_points": "94",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         },
                        //         {
                        //           "id": 8,
                        //           "public_id": "1799696562",
                        //           "name": "Elena",
                        //           "last_name": "Cherniak",
                        //           "photo": "a-1605784297wHxVIO2Q7e.png",
                        //           "bg_color": "#29a3a3",
                        //           "location": "Ukraine",
                        //           "location_img": "ua.png",
                        //           "online": "2021/01/19 20:18:52",
                        //           "rating_points": "44",
                        //           "count_lose": 59,
                        //           "count_win": 25
                        //         }
                        //       ],
                        //       "first_page_url": "https://mindplays.com/ajax/friends?page=1",
                        //       "from": 1,
                        //       "last_page": 1,
                        //       "last_page_url": "https://mindplays.com/ajax/friends?page=1",
                        //       "next_page_url": null,
                        //       "path": "https://mindplays.com/ajax/friends",
                        //       "per_page": 24,
                        //       "prev_page_url": null,
                        //       "to": 4,
                        //       "total": 1
                        //     }
                        //   },
                        //   "status": 200
                        // }

                      //REQUEST
                      //   response = {
                      //     "data": {
                      //       "count_friends_req": 117,
                      //       "friend_requests": {
                      //         "current_page": 1,
                      //         "data": [
                      //           {
                      //             "id": 1506974500,
                      //             "public_id": "1506974500",
                      //             "email": "aqeelkkot@gmail.com",
                      //             "name": "Aqeel",
                      //             "last_name": "Ahmed",
                      //             "photo": "/assets/users/a-1644085181HhIQySwTxs.png",
                      //             "bg_color": "#2375db",
                      //             "location": "PK",
                      //             "location_img": "/dist/images/flags/pk.png",
                      //             "city": "Kalur Kot",
                      //             "online": "2022/02/22 10:43:42",
                      //             "rating_points": 4121,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1917425719,
                      //             "public_id": "1917425719",
                      //             "email": "orjisaiah@gmail.com",
                      //             "name": "Isaiah",
                      //             "last_name": "Orji",
                      //             "photo": "/assets/users/a-164432344323twH3ba1k.png",
                      //             "bg_color": "#FCDD01",
                      //             "location": "SL",
                      //             "location_img": "/dist/images/flags/sl.png",
                      //             "city": null,
                      //             "online": "2022/02/21 06:57:53",
                      //             "rating_points": 337,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1419676034,
                      //             "public_id": "1419676034",
                      //             "email": "sergejsergej9766@gmail.com",
                      //             "name": "Sergey",
                      //             "last_name": "Aleshkin",
                      //             "photo": "/assets/users/a-1645312339ZFBPi0nGMa.png",
                      //             "bg_color": "#f84138",
                      //             "location": "RU",
                      //             "location_img": "/dist/images/flags/ru.png",
                      //             "city": null,
                      //             "online": "2022/02/22 08:46:05",
                      //             "rating_points": 332,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 449494711,
                      //             "public_id": "449494711",
                      //             "email": "evgeniyturbo2@gmail.com",
                      //             "name": "Black",
                      //             "last_name": "Dragon",
                      //             "photo": "/assets/users/a-1644683992iCSi9czraw.png",
                      //             "bg_color": "#FCDD01",
                      //             "location": "RU",
                      //             "location_img": "/dist/images/flags/ru.png",
                      //             "city": "Yakutsk",
                      //             "online": "2022/02/22 08:29:18",
                      //             "rating_points": 328,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 0,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1986514790,
                      //             "public_id": "1986514790",
                      //             "email": "santoshjeena989@gmail.com",
                      //             "name": "santosh",
                      //             "last_name": null,
                      //             "photo": null,
                      //             "bg_color": "#f9851a",
                      //             "location": "IN",
                      //             "location_img": "/dist/images/flags/in.png",
                      //             "city": null,
                      //             "online": "2022/02/20 18:39:31",
                      //             "rating_points": 321,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1347333776,
                      //             "public_id": "1347333776",
                      //             "email": "lemanhokok@gmail.com",
                      //             "name": "Lê Quang",
                      //             "last_name": "Mạnh",
                      //             "photo": "/assets/users/a-16441714904PH5A0i0Wg.png",
                      //             "bg_color": "#40fc6e",
                      //             "location": "VN",
                      //             "location_img": "/dist/images/flags/vn.png",
                      //             "city": "Thành Phố Nam Định",
                      //             "online": "2022/02/20 16:21:34",
                      //             "rating_points": 255,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1917905844,
                      //             "public_id": "1917905844",
                      //             "email": "sergejrozlac1@gmail.com",
                      //             "name": "Сергей",
                      //             "last_name": null,
                      //             "photo": null,
                      //             "bg_color": "#40fc6e",
                      //             "location": "UA",
                      //             "location_img": "/dist/images/flags/ua.png",
                      //             "city": null,
                      //             "online": "2022/02/20 17:31:57",
                      //             "rating_points": 232,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 962560606,
                      //             "public_id": "962560606",
                      //             "email": "mirasblak@gmail.com",
                      //             "name": "Miras Baiys Bi",
                      //             "last_name": null,
                      //             "photo": "/assets/users/a-1644083517m4n95gAusH.png",
                      //             "bg_color": "#f9851a",
                      //             "location": "KZ",
                      //             "location_img": "/dist/images/flags/kz.png",
                      //             "city": null,
                      //             "online": "2022/02/05 23:14:17",
                      //             "rating_points": 222,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 0,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1610233193,
                      //             "public_id": "1610233193",
                      //             "email": "vetcom78@gmail.com",
                      //             "name": "Виталий",
                      //             "last_name": null,
                      //             "photo": "/assets/users/a-1644394666ackU6iyD42.png",
                      //             "bg_color": "#f84138",
                      //             "location": "KZ",
                      //             "location_img": "/dist/images/flags/kz.png",
                      //             "city": null,
                      //             "online": "2022/02/20 05:31:47",
                      //             "rating_points": 205,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           }
                      //         ],
                      //         "first_page_url": "https://mindplays.com/ajax/friends?page=1",
                      //         "from": 1,
                      //         "last_page": 5,
                      //         "last_page_url": "https://mindplays.com/ajax/friends?page=5",
                      //         "next_page_url": "https://mindplays.com/ajax/friends?page=22",
                      //         "path": "https://mindplays.com/ajax/friends",
                      //         "per_page": 24,
                      //         "prev_page_url": null,
                      //         "to": 24,
                      //         "total": 117
                      //       },
                      //
                      //       "sent_requests": {
                      //         "current_page": 1,
                      //         "data": [
                      //           {
                      //             "id": 1506974500,
                      //             "public_id": "1506974500",
                      //             "email": "aqeelkkot@gmail.com",
                      //             "name": "Aqeel",
                      //             "last_name": "Ahmed",
                      //             "photo": "/assets/users/a-1644085181HhIQySwTxs.png",
                      //             "bg_color": "#2375db",
                      //             "location": "PK",
                      //             "location_img": "/dist/images/flags/pk.png",
                      //             "city": "Kalur Kot",
                      //             "online": "2022/02/22 10:43:42",
                      //             "rating_points": 4121,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1917425719,
                      //             "public_id": "1917425719",
                      //             "email": "orjisaiah@gmail.com",
                      //             "name": "Isaiah",
                      //             "last_name": "Orji",
                      //             "photo": "/assets/users/a-164432344323twH3ba1k.png",
                      //             "bg_color": "#FCDD01",
                      //             "location": "SL",
                      //             "location_img": "/dist/images/flags/sl.png",
                      //             "city": null,
                      //             "online": "2022/02/21 06:57:53",
                      //             "rating_points": 337,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1419676034,
                      //             "public_id": "1419676034",
                      //             "email": "sergejsergej9766@gmail.com",
                      //             "name": "Sergey",
                      //             "last_name": "Aleshkin",
                      //             "photo": "/assets/users/a-1645312339ZFBPi0nGMa.png",
                      //             "bg_color": "#f84138",
                      //             "location": "RU",
                      //             "location_img": "/dist/images/flags/ru.png",
                      //             "city": null,
                      //             "online": "2022/02/22 08:46:05",
                      //             "rating_points": 332,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 449494711,
                      //             "public_id": "449494711",
                      //             "email": "evgeniyturbo2@gmail.com",
                      //             "name": "Black",
                      //             "last_name": "Dragon",
                      //             "photo": "/assets/users/a-1644683992iCSi9czraw.png",
                      //             "bg_color": "#FCDD01",
                      //             "location": "RU",
                      //             "location_img": "/dist/images/flags/ru.png",
                      //             "city": "Yakutsk",
                      //             "online": "2022/02/22 08:29:18",
                      //             "rating_points": 328,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 0,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1986514790,
                      //             "public_id": "1986514790",
                      //             "email": "santoshjeena989@gmail.com",
                      //             "name": "santosh",
                      //             "last_name": null,
                      //             "photo": null,
                      //             "bg_color": "#f9851a",
                      //             "location": "IN",
                      //             "location_img": "/dist/images/flags/in.png",
                      //             "city": null,
                      //             "online": "2022/02/20 18:39:31",
                      //             "rating_points": 321,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1347333776,
                      //             "public_id": "1347333776",
                      //             "email": "lemanhokok@gmail.com",
                      //             "name": "Lê Quang",
                      //             "last_name": "Mạnh",
                      //             "photo": "/assets/users/a-16441714904PH5A0i0Wg.png",
                      //             "bg_color": "#40fc6e",
                      //             "location": "VN",
                      //             "location_img": "/dist/images/flags/vn.png",
                      //             "city": "Thành Phố Nam Định",
                      //             "online": "2022/02/20 16:21:34",
                      //             "rating_points": 255,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1917905844,
                      //             "public_id": "1917905844",
                      //             "email": "sergejrozlac1@gmail.com",
                      //             "name": "Сергей",
                      //             "last_name": null,
                      //             "photo": null,
                      //             "bg_color": "#40fc6e",
                      //             "location": "UA",
                      //             "location_img": "/dist/images/flags/ua.png",
                      //             "city": null,
                      //             "online": "2022/02/20 17:31:57",
                      //             "rating_points": 232,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 962560606,
                      //             "public_id": "962560606",
                      //             "email": "mirasblak@gmail.com",
                      //             "name": "Miras Baiys Bi",
                      //             "last_name": null,
                      //             "photo": "/assets/users/a-1644083517m4n95gAusH.png",
                      //             "bg_color": "#f9851a",
                      //             "location": "KZ",
                      //             "location_img": "/dist/images/flags/kz.png",
                      //             "city": null,
                      //             "online": "2022/02/05 23:14:17",
                      //             "rating_points": 222,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 0,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           },
                      //           {
                      //             "id": 1610233193,
                      //             "public_id": "1610233193",
                      //             "email": "vetcom78@gmail.com",
                      //             "name": "Виталий",
                      //             "last_name": null,
                      //             "photo": "/assets/users/a-1644394666ackU6iyD42.png",
                      //             "bg_color": "#f84138",
                      //             "location": "KZ",
                      //             "location_img": "/dist/images/flags/kz.png",
                      //             "city": null,
                      //             "online": "2022/02/20 05:31:47",
                      //             "rating_points": 205,
                      //             "vip": 0,
                      //             "plays": 0,
                      //             "lang": "en",
                      //             "verified": 1,
                      //             "count_win": 0,
                      //             "count_lose": 0
                      //           }
                      //         ],
                      //         "first_page_url": "https://mindplays.com/ajax/friends?page=1",
                      //         "from": 1,
                      //         "last_page": 5,
                      //         "last_page_url": "https://mindplays.com/ajax/friends?page=5",
                      //         "next_page_url": "https://mindplays.com/ajax/friends?page=21",
                      //         "path": "https://mindplays.com/ajax/friends",
                      //         "per_page": 24,
                      //         "prev_page_url": null,
                      //         "to": 24,
                      //         "total": 100
                      //       },
                      //       "count_total": 36,
                      //       "count_online": 2
                      //     },
                      //     "auth": true,
                      //     "status": 200
                      //   }
                        //

                        this.$emit('updatePeoples', response.data);
                        clearInterval(timeout);
                        this.$emit('update:progress', 100);
                        setTimeout(() => {
                          this.$emit('update:progress', 0);
                        }, 500);
                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });
            }
        },
        components: {

        },

    }
</script>

<style lang="scss" scoped>

    .block-empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 100px;

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin: 7px 0 0;
            color: #002B50;
            text-align: center;
        }
    }
</style>
