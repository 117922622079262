<template>
  <div class="select-input">
<!--    <span class="label">Gender</span>-->
    <div v-if="dropdown">
      <v-menu offset-y v-model="value_menu">
        <template v-slot:activator="{ on }">
            <v-list :class="color" class="select">
              <v-list-item-action v-on="on" class="ma-0">
                <!--                    <v-btn text>-->
                <slot v-bind:value="value" v-bind:active_value="active_value" v-bind:placeholder="placeholder" v-bind:value_menu="value_menu">
                  <v-list-item v-if="value || !value && !placeholder">
                    <img class="mr-2" :src="'/images/wallets/'+active_value.img" v-if="active_value.img" />
                    {{__(active_value[title_key])}}
                    <img class="chevron-icon" :class="((value_menu) ? 'active' : '')" src="/dist/images/frontend/chevron-down.svg" alt />
                  </v-list-item>

                  <v-list-item v-else class="text-right placeholder">
                    {{__(placeholder)}}
                    <img class="chevron-icon" :class="((value_menu) ? 'active' : '')" src="/dist/images/frontend/chevron-down.svg" alt />
                  </v-list-item>
                </slot>

                <!--                    </v-btn>-->
              </v-list-item-action>
            </v-list>
        </template>
        <v-list class="select-modal" :class="class_modal">
          <slot v-bind:dropdown="dropdown" v-bind:value_key="value_key" name="list">
            <v-list-item
              class="px-2"

              v-for="(list, key) in dropdown"
              :key="key"
              v-on:click="changeValue(((type== 'key') ? list[value_key] : list));"
              v-on:change="$emit('changeOption')"
            >

              <slot name="option" v-bind="list">
                <img class="mr-2" :src="'/images/wallets/'+list.img" v-if="list.img" />
                {{__(list[title_key])}}
              </slot>
            </v-list-item>
          </slot>
        </v-list>
      </v-menu>
      <div>
        <p class="desc-error" v-for="(value, key) in errors" :key="key">{{value}}</p>
      </div>
    </div>
    <div v-else>{{__('loading')}}</div>

  </div>
</template>

<script>
export default {
  name: "DropdownDefault",
  data() {
    return {
      value_menu: false,
      // dropdown_font: ['English', 'Russian'],
    };
  },
  props: {
    value: {
      type: [Number, String, Object],
    },
    value_key: {
      type: String,
      default: "value",
    },
    title_key: {
      type: String,
      default: "title",
    },
    dropdown: {
      type: Array,
    },
    errors: {
      type: Array,
    },
    placeholder: {
      type: String,
      default: null,
    },
    color: {
      type: String,
    },
    class_modal: {
      type:String,
    },
    type: {
      type: String,
      default: 'key',
    },
    default: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    changeValue(val) {
      this.$emit('update:value', val);
      this.$emit('updateValue', val);
    }
  },
  created() {
    if(!this.value) {
      let active= this.active_value;
      this.$emit('update:value', ((this.type== 'key') ? active[this.value_key] : active));
    }
  },
  computed: {
    active_value() {
        if(this.value instanceof Object) {
            return this.value;
        } else {
            let active = this.$filterArray(this.dropdown, this.value, this.value_key);
            return active ? active : ((this.default) ? this.dropdown[0] : null) ;
        }
    },
  },
};
</script>

<style lang="scss" scoped>

  .dark .select.theme--light.v-sheet.settings {
    background-color: transparent;
  }

  .select.theme--light.v-sheet.settings {
    background-color: #fff;
    width: 50px;
    border-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;

    .chevron-icon {
      top: 17px;
      right: 2px;
    }
  }
.select-input {
  position: relative;
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
}

.label {
  position: absolute;
  left: 16px;
  top: 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #69788D;
}

.v-list.settings {
  .v-list-item {
    font-size: 15px;
    padding: 0 16px;
  }
}

.select {
  padding: 19px 0 0;
  border-radius: 8px;
  background-color: transparent;

  &.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #002b50;
  }

  &.theme--light.v-sheet {
    border-radius: 6px;
    background-color: transparent;
    border-color: #fff;
    color: #002b50;
    height: 40px;
  }
  .v-list-item {
    width: 100%;
    min-height: 40px;
    padding: 0 10px;
    font-size: 14px;

    &__action {
      width: 100%;
    }

    .chevron-icon {
      position: absolute;
      right: 20px;
      top: 5px;
    }
  }
}

.dark .select_color {
  &.theme--light.v-sheet {
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    color: #fff;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #fff !important;
  }
}

.select_color {
  background: #fff;

  &.theme--light.v-sheet {
    border-radius: 0;
    background-color: transparent;
    color: #002b50;
    height: 40px;
    padding: 4px 0 0;
    border-bottom: 1px solid #DAE6F1;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #002b50!important;

    &.placeholder {
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #6886AF!important;
    }
  }

  .v-list-item {
    padding: 0;
    font-size: 15px;

    .chevron-icon {
      top: 18px;
      right: 4px;
      transition-duration: .3s;
    }

    .active {
      transform: rotate(180deg);
      transition-duration: .3s;
    }
  }
}

.select_nav {
   background: #fff;
   position: relative;

   &.theme--light.v-sheet {
     padding: 0 15px 3px 20px;
     color: #002B50;
     height: 40px;
     min-width: 110px;
     width: 100%;
     background: #FFFFFF;
     border: 1px solid #ECF4FF;
     border-right: none;
     border-radius: 20px 0 0 20px;

     &:after {
       content: '';
       position: absolute;
       top: 3px;
       right: 0;
       display: block;
       width: 2px;
       border-right: 1px solid #ECF4FF;
       height: 32px;
     }
   }

   .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
     color: #002b50!important;

     &.placeholder {
       font-weight: 400;
       font-size: 15px;
       line-height: 16px;
       letter-spacing: 0.4px;
       color: #6886AF!important;
     }
   }

   .v-list-item {
     padding: 0;
     font-size: 15px;

     .chevron-icon {
       top: 18px;
       right: 0px;
       transition-duration: .3s;
     }

     .active {
       transform: rotate(180deg);
       transition-duration: .3s;
     }
   }
 }

.modal_nav {
  .v-list-item {
    padding-left: 20px!important;
  }
}

  .dark .select_game {
    background: #171827;
    border: 1px solid #363636;

    &.theme--light.v-sheet {
      color: #26a9f4;
      height: 40px;
      border-radius: 20px;
      background-color: #171827;
      padding: 0 40px 3px 20px;
      border: 1px solid #363636;
    }
  }

  .select_game {
    background: #F0F6FE;
    width: fit-content;
    position: relative;

    &.theme--light.v-sheet {
      color: #26a9f4;
      height: 40px;
      border-radius: 20px;
      background-color: #F0F6FE;
      padding: 0 40px 3px 20px;
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: #26a9f4!important;
      text-align: center;
      line-height: 16px;


      &.placeholder {
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #6886AF!important;
      }
    }

    .v-list-item {
      padding: 0;
      font-size: 15px;

      .chevron-icon {
        top: 18px;
        right: -25px;
        transition-duration: .3s;
      }

      .active {
        transform: rotate(180deg);
        transition-duration: .3s;
      }
    }
  }

  .modal_game {
    .v-list-item {
      padding-left: 20px!important;
    }
  }

  .select_create-match {


    &.theme--light.v-sheet {
      padding: 0;
      color: #002B50;
      height: 40px;
      width: 100%;
      border: 1px solid #DAE6F1;
      filter: drop-shadow(0px 2px 5px rgba(51, 97, 255, 0.03));
      border-radius: 30px;
      background-color: #fff;
    }
  }


.v-list-item--link:hover {
  background-color: #F0F6FE;
}

.dark {
  .v-list-item--link:hover {
    background-color: #171827;
  }
}

.v-list-item--link:before {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: .3s cubic-bezier(.25,.8,.5,1);
    transition: .3s cubic-bezier(.25,.8,.5,1);
}

  .desc-error {
    position: absolute;
    /*top: 0;*/
    left: 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #F25958;
    margin-bottom: 0;
    z-index: +1;
  }

  .select_money {
    font-size: 14px;

    &.theme--light.v-sheet {
      border-radius: 6px;
      background-color: #fff;
      color: #00C443;
      height: 40px;
      border: none;
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: #00C443!important;
    }
    .v-list-item {
      width: 100%;
      min-height: 40px;
      padding: 0 10px 0 30px;
    }
  }

  .dark .select-modal {
    .theme--light.v-list-item {
      color: #96AFD1!important;

      &:hover {
        color: #fff!important;
      }
    }
  }

  .select-modal {
    font-size: 14px;

    &.v-list {
      padding: 0;
    }

    .v-list-item {
      min-height: 40px;
    }
  }

  @media screen and (max-width: 768px) {
    .select_color {

      &.theme--light.v-sheet {
        height: 36px;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

/*@media screen and (max-width: 525px) {*/
  /*.select_color {*/

    /*&.theme--light.v-sheet {*/
      /*height: 30px;*/
      /*font-size: 10px;*/
    /*}*/

    /*.chevron-icon {*/
      /*height: 5px;*/
    /*}*/
  /*}*/
  /*.select .v-list-item .chevron-icon {*/
    /*position: absolute;*/
    /*right: 10px;*/
    /*top: 12px;*/
  /*}*/

  /*.select .v-list-item {*/
    /*width: 100%;*/
    /*min-height: 30px;*/
    /*padding: 0 10px;*/
  /*}*/

  /*.select-modal {*/

    /*.v-list-item {*/
      /*min-height: 30px;*/
      /*padding: 0 10px;*/
      /*font-size: 12px;*/

    /*}*/
  /*}*/
/*}*/


</style>