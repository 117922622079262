<template>
  <div class="question-item">
    <v-expansion-panels v-model="panel" :flat="flat" multiple>
      <v-expansion-panel class="transparent" @click="isActive=!isActive">
        <v-expansion-panel-header>
          {{__(item.question)}}
          <template v-slot:actions>
            <img class="ml-5" v-if="isActive" src="/dist/images/frontend/btn_plus.svg" alt="/" />
            <img class="ml-5" v-else src="/dist/images/frontend/btn_minus.svg" alt="/" />
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>{{__(item.answer, faqParams)}}</v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<!--v-expansion-panel-header&#45;&#45;active-->

<script>
export default {
  data: () => ({
    panel: null,
    // readonly: false,
    flat: true,

    isActive: true,
  }),
  props: {
      item: {
          type: Object,
      },
      faqParams: {
          type: Object
      }
  }
};
</script>

<style lang="scss" scoped>

.question-item {
  width: 100%;
  flex-shrink: 0;
}

.v-expansion-panels {
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 43, 80, 0.4);
}

.v-expansion-panel-header {
  padding: 14px 0;
  outline: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  transition-duration: 0.3s;

  &:hover {
    color: #26a9f4;
  }
}

.v-expansion-panel-content {
  font-size: 15px;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: -24px;
}

  @media screen and (max-width: 950px) {
    .v-expansion-panel-header {
      padding: 12px 0;
      font-size: 15px;
      line-height: 20px;
    }
    .v-expansion-panel-content {
      font-size: 13px;
      line-height: 19px;
    }
  }

  @media screen and (max-width: 768px) {
    .question-item {
      width: 100%;
      flex-shrink: 0;
    }
  }
</style>