<template>
  <div>
    <v-dialog v-model="dialog" max-width="700px">
      <div class="transaction-info">
        <div class="transaction-info__head">
          <div class="d-flex align-center">
            <p>{{__('Referrals')}}</p>
          </div>
          <v-btn text icon class="icon" @click="dialog = false">
            <img src="/dist/images/frontend/close.svg" alt="/" />
          </v-btn>
        </div>
        <div class="transaction-info__body" v-if="info" ref="list_referrals" @scroll="scrollReferrals">
          <TransactionUserItem v-for="(item, key) in info.data" :item="item" :key="key"></TransactionUserItem>
        </div>
        <div v-else class="transaction-info__body">{{__('loading')}}</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import TransactionUserItem from "@/components/pages/TransactionHistory/TransactionUserItem";
export default {
  data() {
    return {
      dialog: true,
      next_page_url: (this.info) ? this.info.next_page_url : null,
      loadingPage: false,
    }
  },
  props: {
    transaction_info_modal: {
      type: Boolean,
    },
    info: {
      type: Object
    }
  },
  watch: {
    dialog() {
      this.cancelModal();
    },
  },
  methods: {
    cancelModal() {
      this.$emit("update:transaction_info_modal", false);
    },
    scrollReferrals() {
      let list_referrals = this.$refs.list_referrals;
      const progress = list_referrals.scrollTop / (list_referrals.scrollHeight - list_referrals.clientHeight);
      if (!this.loadingPage && this.next_page_url) {
        let bottomOfWindow = 0.7 <= progress;
        if (bottomOfWindow) {
          this.loadingPage = true;
          this.$http.get(this.next_page_url)
              .then((response) => {
                return response.json();
              })
              .then((response) => {
                if(this.$checkAuth(response)) {
                    response = response.data;
                    let actual_quest = this.actual_quest;
                    this.actual_quest = actual_quest.concat(response.data);
                    this.next_page_url = response.next_page_url;
                    this.loadingPage = false;
                }
              })
              .catch((err) => {
                this.httpHandler(err);
              });
        }
      }
    }
  },
  components: {
    TransactionUserItem
  }

}
</script>

<style lang="scss" scoped>
.transaction-info {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #F0F6FE;
    border-radius: 8px 8px 0px 0px;
    padding: 20px 24px;
    position: relative;


    svg {
      margin-right: 12px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #002b50;
      margin-bottom: 0;
    }

    .icon {
      position: absolute;
      top: 12px;
      right: 6px;
      outline: none;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__body {
    padding: 20px 15px 20px;
    background-color: #ffffff;
    border-radius: 0 0 8px 8px;
    max-height: 335px;
    overflow-y: auto;


    p {
      text-align: left;
      font-size: 14px;
      color: #002B50;
      margin-bottom: 0;
    }

    img {
      max-width: 500px;
    }
  }
}
</style>