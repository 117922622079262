<template>
  <v-app class="block-app" id="app" :class="((quest) ? 'main-content ' : '') + theme" >
    <div v-if="auth=== false">{{__('loading')}}</div>
    <div
        v-else-if="active_page && active_session"
        class="wrap-app"
        style="width: 100%; margin: 0 auto; position: relative"
    >
      <v-main>
        <LayoutNav v-if="this.$route.name !== 'api' && this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'forgot' && this.$route.name !== 'security'" v-bind:sidebar_nav="sidebar_nav" :scroll.sync="scroll"></LayoutNav>

        <div style="width: 100%; margin: 0 auto;" ref="wrapper">
            <router-view :style="((!auth && this.$route.name !== 'main_affiliate' && this.$route.name !== 'login' && this.$route.name !== 'register' && this.$route.name !== 'forgot' && this.$route.name !== 'security') ? {marginTop: '82px'} : '')" />
        </div>
        <ModalAuth v-if="modal_auth"></ModalAuth>
        <ModalNextPage v-if="modal_next"></ModalNextPage>
        <ModalCookie v-if="!auth"></ModalCookie>
      </v-main>

<!--      <MiniChat v-if="auth && this.$route.name !== 'frame' && this.$route.name !== 'chat'"></MiniChat>-->
        <GeneralChat :class="$route.name" v-if="auth && this.$route.name !== 'frame'"/>

    </div>
    <DisconnectedSession v-else/>
    <NotificationsInviteGame></NotificationsInviteGame>
    <NotificationsChat></NotificationsChat>
      <notifications group="message"
                     position="bottom right"
                     :duration="10000"
      >
          <template slot="body" slot-scope="props">
              <div :class="props.item.type" class="my-notification">
                  <div v-if="props.item.type == 'success'" class="icon-success">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.9998 0.148193C9.65573 0.148193 7.3643 0.843292 5.41527 2.14559C3.46624 3.44789 1.94716 5.2989 1.05012 7.46454C0.153084 9.63018 -0.0816218 12.0132 0.375685 14.3122C0.832991 16.6113 1.96177 18.7231 3.61928 20.3806C5.27679 22.0381 7.38859 23.1669 9.68763 23.6242C11.9867 24.0815 14.3697 23.8468 16.5353 22.9497C18.701 22.0527 20.552 20.5336 21.8543 18.5846C23.1566 16.6356 23.8517 14.3441 23.8517 12C23.8517 8.85674 22.603 5.84218 20.3803 3.61952C18.1577 1.39687 15.1431 0.148193 11.9998 0.148193ZM11.9998 22.3704C9.94874 22.3704 7.94374 21.7622 6.23834 20.6227C4.53294 19.4832 3.20374 17.8636 2.41883 15.9686C1.63392 14.0737 1.42856 11.9885 1.8287 9.97689C2.22884 7.96523 3.21652 6.11741 4.66685 4.66709C6.11717 3.21676 7.96499 2.22908 9.97665 1.82894C11.9883 1.4288 14.0734 1.63416 15.9684 2.41907C17.8633 3.20398 19.4829 4.53318 20.6225 6.23858C21.762 7.94398 22.3702 9.94898 22.3702 12C22.3702 14.7504 21.2776 17.3882 19.3328 19.333C17.3879 21.2778 14.7502 22.3704 11.9998 22.3704Z" fill="#24E07A"/>
                          <path d="M19.4072 7.62959C19.2685 7.49162 19.0807 7.41418 18.885 7.41418C18.6893 7.41418 18.5016 7.49162 18.3628 7.62959L10.1406 15.8148L5.69612 11.3703C5.56057 11.224 5.37242 11.1375 5.17308 11.1298C4.97373 11.1222 4.77952 11.194 4.63316 11.3296C4.4868 11.4651 4.40028 11.6533 4.39264 11.8526C4.385 12.052 4.45686 12.2462 4.59242 12.3926L10.1406 17.9259L19.4072 8.68144C19.4767 8.61258 19.5318 8.53065 19.5694 8.44038C19.607 8.35012 19.6263 8.2533 19.6263 8.15551C19.6263 8.05773 19.607 7.96091 19.5694 7.87064C19.5318 7.78038 19.4767 7.69845 19.4072 7.62959Z" fill="#24E07A"/>
                      </svg>
                  </div>
                  <div v-else-if="props.item.type == 'error'" class="icon-error">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.989 11.993L16.5235 8.45847C16.6426 8.31934 16.7049 8.14037 16.6978 7.95734C16.6908 7.7743 16.6149 7.60067 16.4854 7.47115C16.3558 7.34162 16.1822 7.26575 15.9992 7.25868C15.8161 7.25161 15.6372 7.31386 15.498 7.43301L11.9635 10.9312L8.40714 7.37483C8.26801 7.25568 8.08904 7.19342 7.90601 7.20049C7.72297 7.20756 7.54934 7.28344 7.41982 7.41296C7.29029 7.54249 7.21442 7.71612 7.20735 7.89915C7.20028 8.08219 7.26254 8.26116 7.38168 8.40028L10.9308 11.993L7.45441 15.4257C7.37828 15.4909 7.31644 15.5712 7.27279 15.6614C7.22914 15.7516 7.20461 15.8499 7.20074 15.9501C7.19687 16.0502 7.21375 16.1501 7.25031 16.2434C7.28687 16.3368 7.34233 16.4215 7.41321 16.4924C7.48409 16.5633 7.56885 16.6187 7.66217 16.6553C7.7555 16.6919 7.85538 16.7087 7.95554 16.7049C8.0557 16.701 8.15398 16.6765 8.24421 16.6328C8.33443 16.5892 8.41467 16.5273 8.47986 16.4512L11.949 12.9821L15.3962 16.4294C15.5354 16.5485 15.7143 16.6108 15.8974 16.6037C16.0804 16.5966 16.254 16.5208 16.3835 16.3912C16.5131 16.2617 16.5889 16.0881 16.596 15.905C16.6031 15.722 16.5408 15.543 16.4217 15.4039L12.989 11.993Z" fill="#E51717"/>
                          <path d="M11.9998 23.2C9.78466 23.2 7.61925 22.5432 5.77742 21.3125C3.93559 20.0818 2.50006 18.3326 1.65236 16.2861C0.804657 14.2396 0.58286 11.9876 1.01501 9.81504C1.44717 7.64245 2.51387 5.6468 4.08021 4.08046C5.64656 2.51411 7.64221 1.44741 9.8148 1.01526C11.9874 0.583104 14.2393 0.804902 16.2859 1.6526C18.3324 2.5003 20.0816 3.93584 21.3123 5.77767C22.5429 7.6195 23.1998 9.7849 23.1998 12.0001C23.1998 14.9705 22.0198 17.8192 19.9194 19.9196C17.819 22.0201 14.9702 23.2 11.9998 23.2ZM11.9998 2.20005C10.0616 2.20005 8.16682 2.77481 6.55522 3.85165C4.94362 4.92849 3.68753 6.45904 2.94579 8.24975C2.20405 10.0405 2.00998 12.0109 2.38811 13.9119C2.76625 15.8129 3.69961 17.5591 5.07016 18.9297C6.44072 20.3002 8.18691 21.2336 10.0879 21.6117C11.9889 21.9899 13.9594 21.7958 15.7501 21.0541C17.5408 20.3123 19.0714 19.0562 20.1482 17.4446C21.225 15.833 21.7998 13.9383 21.7998 12.0001C21.7998 9.40093 20.7673 6.90826 18.9295 5.07041C17.0916 3.23255 14.5989 2.20005 11.9998 2.20005Z" fill="#E51717"/>
                      </svg>
                  </div>
                  <div v-else-if="props.item.type == 'warning' || props.item.type == 'warn'" class="icon-warn">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.8422 19.7171C12.3459 19.7171 12.7542 19.3057 12.7542 18.7982C12.7542 18.2907 12.3459 17.8793 11.8422 17.8793C11.3385 17.8793 10.9302 18.2907 10.9302 18.7982C10.9302 19.3057 11.3385 19.7171 11.8422 19.7171Z" fill="#FFC703"/>
                          <path d="M11.842 16.4144C11.6601 16.4144 11.4857 16.3416 11.3571 16.2121C11.2285 16.0825 11.1562 15.9068 11.1562 15.7235V7.43261C11.1562 7.24937 11.2285 7.07363 11.3571 6.94406C11.4857 6.81449 11.6601 6.7417 11.842 6.7417C12.0238 6.7417 12.1982 6.81449 12.3268 6.94406C12.4554 7.07363 12.5277 7.24937 12.5277 7.43261V15.7235C12.5277 15.9068 12.4554 16.0825 12.3268 16.2121C12.1982 16.3416 12.0238 16.4144 11.842 16.4144Z" fill="#FFC703"/>
                          <path d="M9.82746 1.95454C10.2168 1.24157 10.9581 0.800049 11.7632 0.800049C12.5683 0.800049 13.3095 1.24157 13.6994 1.95546L23.224 19.6151C23.5957 20.3044 23.5797 21.1399 23.1818 21.8142C22.784 22.4885 22.0631 22.9019 21.2843 22.9021L2.25739 22.9019C1.46998 22.9197 0.732794 22.5135 0.322761 21.8359C-0.0872721 21.1584 -0.108063 20.3121 0.2686 19.6142L9.82746 1.95454ZM1.47301 20.2751C1.33045 20.5392 1.33833 20.8598 1.49368 21.1166C1.64904 21.3733 1.92834 21.5272 2.24203 21.5203H21.2841C21.5791 21.5202 21.8523 21.3636 22.003 21.1081C22.1537 20.8526 22.1598 20.536 22.019 20.2749L12.4961 2.61843C12.3491 2.34915 12.0682 2.18187 11.7632 2.18187C11.4585 2.18187 11.178 2.34875 11.0308 2.61746L1.47301 20.2751Z" fill="#FFC703"/>
                      </svg>
                  </div>
                  <div v-else class="icon-info">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.6144 7.16356C12.1767 7.16356 12.6326 6.70771 12.6326 6.14538C12.6326 5.58305 12.1767 5.1272 11.6144 5.1272C11.052 5.1272 10.5962 5.58305 10.5962 6.14538C10.5962 6.70771 11.052 7.16356 11.6144 7.16356Z" fill="#26A9F4"/>
                          <path d="M13.8184 16.7273H12.3639V8.80005H10.1821C9.9892 8.80005 9.80422 8.87667 9.66783 9.01306C9.53143 9.14945 9.45481 9.33444 9.45481 9.52732C9.45481 9.72021 9.53143 9.90519 9.66783 10.0416C9.80422 10.178 9.9892 10.2546 10.1821 10.2546H10.9094V16.7273H9.45481C9.26193 16.7273 9.07694 16.8039 8.94055 16.9403C8.80416 17.0767 8.72754 17.2617 8.72754 17.4546C8.72754 17.6475 8.80416 17.8325 8.94055 17.9689C9.07694 18.1052 9.26193 18.1819 9.45481 18.1819H13.8184C14.0113 18.1819 14.1963 18.1052 14.3327 17.9689C14.4691 17.8325 14.5457 17.6475 14.5457 17.4546C14.5457 17.2617 14.4691 17.0767 14.3327 16.9403C14.1963 16.8039 14.0113 16.7273 13.8184 16.7273Z" fill="#26A9F4"/>
                          <path d="M11.6364 23.2727C9.33491 23.2727 7.08514 22.5903 5.17155 21.3116C3.25796 20.033 1.7665 18.2157 0.88577 16.0894C0.00504181 13.9631 -0.225397 11.6235 0.223595 9.36622C0.672586 7.10899 1.78084 5.03559 3.40822 3.40822C5.03559 1.78084 7.10899 0.672586 9.36622 0.223595C11.6235 -0.225397 13.9631 0.00504181 16.0894 0.88577C18.2157 1.7665 20.033 3.25796 21.3116 5.17155C22.5903 7.08514 23.2727 9.33491 23.2727 11.6364C23.2727 14.7225 22.0468 17.6823 19.8645 19.8645C17.6823 22.0468 14.7225 23.2727 11.6364 23.2727ZM11.6364 1.45455C9.62259 1.45455 7.65404 2.0517 5.97965 3.1705C4.30526 4.28929 3.00023 5.87947 2.22959 7.73995C1.45896 9.60044 1.25732 11.6477 1.65019 13.6227C2.04306 15.5978 3.01278 17.412 4.43673 18.836C5.86069 20.2599 7.67491 21.2297 9.64999 21.6225C11.6251 22.0154 13.6723 21.8138 15.5328 21.0431C17.3933 20.2725 18.9834 18.9675 20.1022 17.2931C21.221 15.6187 21.8182 13.6501 21.8182 11.6364C21.8182 8.93598 20.7455 6.34619 18.836 4.43673C16.9265 2.52727 14.3368 1.45455 11.6364 1.45455Z" fill="#26A9F4"/>
                      </svg>
                  </div>
                  <a class="title">
                      {{__(props.item.title)}}
                  </a>
                  <div class="close-notice" @click="props.close">
                      <img src="/dist/images/frontend/close-notice.svg" alt />
                  </div>
                  <div v-html="props.item.text">
                  </div>
              </div>
          </template>
      </notifications>
      <NotificationPlayGame v-if="play_game"></NotificationPlayGame>
      <ModalReadyToPlay v-if="auth && ready_play === 0 && this.$route.name !== 'frame'"></ModalReadyToPlay>
<!--      <ModalReadyToPlay v-if="ready"></ModalReadyToPlay>-->
      <ModalClick v-if="modal_cancel" v-bind:modal_cancel.sync="modal_cancel"></ModalClick>
  </v-app>
</template>

<script>
    import { bus } from './main.js';
    import ModalClick from "./components/modules/ModalClick";
import LayoutNav from "./components/modules/LayoutNav";
import ModalAuth from "./components/modules/ModalAuth";
import ModalNextPage from "./components/modules/ModalNextPage";
import NotificationsInviteGame from "./components/modules/NotificationsInviteGame";
import NotificationsChat from "./components/modules/NotificationsChat";
import DisconnectedSession from "./components/pages/DisconnectedSession";
// import MiniChat from './components/modules/MiniChat';
import NotificationPlayGame from "./components/modules/NotificationPlayGame";
import ModalCookie from "./components/modules/ModalCookie";
import ModalReadyToPlay from "./components/modules/ModalReadyToPlay";
import moment from "moment";
import GeneralChat from "./components/modules/GeneralChat";

export default {
  name: "App",
  data: function () {
    return {
        modal_cancel: false,
      sidebar_nav: [
        {
          name: "Personal",
          icon: "nav-icon-personal-new.svg",
          url: "home",
          exact: true,
        },
        {
          name: "Games",
          icon: "nav-icon-games-new.svg",
          url: "games",
        },
          {
              name: "Championship",
              icon: "nav-icon-tournaments-new.svg",
              url: "tournaments",
          },
        {
          name: "Friends",
          icon: "nav-icon-peoples-new.svg",
          url: "friends",
        },

        // {
        //   name: "Quests",
        //   icon: "nav-icon-quests-new.svg",
        //   url: "quests",
        // },
        {
          name: "Blog",
          icon: "nav-icon-blog-new.svg",
          url: "blogs",
        },
      ],
      drawer: null,
      active_page: true,
      scroll: false,
      play_game: false,
      ready_play: null,

      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timeinterval: null,
      timerConnectionStable: null,
      is_connection_stable: true
    };
  },

    watch: {
        route_name(val) {
            if (val == 'frame') {
                this.is_connection_stable = false;
            } else {
                this.is_connection_stable = true;
            }
        }
    },

    asyncComputed: {
        async_user() {
            let auth= this.auth;

            if(auth) {
                this.ready_play= auth.ready_play;
            }
            return true;
        }
    },
  computed: {
    updateMess() {
      return this.$store.state.preference.updateMess
    },
    auth() {
      return this.$store.state.auth.user;
    },
    active_session() {
      return this.$store.state.auth.active_session;
    },
    modal_auth() {
      return this.$store.state.preference.modal_auth;
    },
    modal_next() {
      return this.$store.state.preference.modal_next;
    },
    next_page() {
      return this.$store.state.preference.next_page;
    },
    languages() {
      return this.$store.getters.getLangs;
    },
    invite_game() {
      return this.$store.state.games.invite_game;
    },
    theme() {
        return this.$store.getters.getTheme;
    },
    quest() {
        if (!this.auth || this.$route.name == 'main_affiliate' ||
            this.$route.name == 'contact' ||
            this.$route.name == 'vacancies' ||
            this.$route.name == 'withdrawal_policy_and_refund_policy' ||
            this.$route.name == 'cookie_policy' ||
            this.$route.name == 'faq' ||
            this.$route.name == 'privacy' ||
            this.$route.name == 'api'
        ) {
            return true
        } else {
            return false
        }
    },
      chat_public() {
          return this.$store.state.general_chat.chat_public;
      },

       route_name() {
        return this.$route.name
       }

  },

  methods: {

      initializeClock() {
          this.timeinterval = setInterval(this.updateClock, 1000);
      },

      updateClock() {
          let x = moment.utc('2022-02-28 23:59:00').format('x') - moment.utc().format('x');
          let seconds = Math.floor((x / 1000) % 60);
          let minutes = Math.floor((x / 1000 / 60) % 60);
          let hours = Math.floor((x / (1000 * 60 * 60)) % 24);
          let days = Math.floor(x / (1000 * 60 * 60 * 24));

          let t = {
              'total': x,
              'days': days,
              'hours': hours,
              'minutes': minutes,
              'seconds': seconds
          };


          this.days = t.days;
          this.hours = t.hours;
          this.minutes = t.minutes;
          this.seconds = t.seconds;

          // let startFinish = moment.utc('2022-08-01 00:00:00').format('x') - moment.utc('2022-02-02 00:00:00').format('x');
          // this.progressLine = Math.round((x * 100)/startFinish);

          if (t.total <= 0) {
              clearInterval(this.timeinterval);
              this.days = 0;
              this.hours = 0;
              this.minutes = 0;
              this.seconds = 0;
          }
      },
  },

  mounted() {
      bus.$on('open-modal', () => {
          this.modal_cancel = true;
      });
      if (window.userAuth && this.$route.name != 'frame') {
          this.timerConnectionStable = setInterval( () => {
              if (this.is_connection_stable) {
                  let startTime = Date.now();
                  // for example, less than 1 second
                  this.$http.get("ajax/connection_stable")
                  .then((response) => {
                      return response.json();
                  })
                  .then((response) => {
                      if(this.$checkAuth(response)) {
                          let latency = Date.now() - startTime;
                          if (latency < 500) {
                              this.$store.dispatch("asyncConnectionStable", 1);
                          } else {
                              this.$store.dispatch("asyncConnectionStable", 2);
                          }
                      }
                  })
                  .catch((err) => {
                      this.$store.dispatch("asyncConnectionStable", 3);
                      this.httpHandler(err);
                  });
              }
          }, 2000);
      }
      window.addEventListener("DOMContentLoaded", () => {
          if (window.matchMedia("(display-mode: standalone)").matches) {
              // From browser to standalone
              console.log('From browser to standalone - is PWA');

              this.$refs.wrapper.dispatchEvent(new Event('touchstart'));
              this.$refs.wrapper.dispatchEvent(new Event('touchend'));

              // if (window.userAuth) {
              //     this.$router.push({
              //         name: "games",
              //         lang:  this.$store.getters.getLang,
              //     });
              // } else {
              //     this.$router.push({
              //         name: "login",
              //         lang:  this.$store.getters.getLang,
              //     });
              // }

              if (!window.userAuth) {
                  this.$router.push({
                      name: "login",
                      lang:  this.$store.getters.getLang,
                  });
              }

          } else {
              // From standalone to browser
              console.log('From standalone to browser - isBrowser');

              if (this.$refs.wrapper) {
                  this.$refs.wrapper.click();
              }

              switch (this.$route.name) {
                  case "password_reset": {
                      // if(this.$route.name== 'password_reset') {
                      let response = {
                          password_reset: {
                              token: this.$route.params.token,
                              email: this.$route.query.email,
                          },
                      };

                      this.$store.commit("updateModalAuth", response);
                      history.pushState(
                          null,
                          null,
                          process.env.NODE_ENV === "production" ? "/" : "/dist/"
                      );
                      // }
                      break;
                  }

                  // case "login": {
                  //   if (!window.userAuth) {
                  //     this.$store.commit('updateModalAuth', 'login');
                  //   }
                  //   break;
                  // }
                  case "ajax": {
                      this.$http
                          .get(this.$route.fullPath)
                          .then((response) => {
                              return response.json();
                          })
                          .catch((err) => {
                              this.httpHandler(err);
                          });
                      history.pushState(
                          null,
                          null,
                          process.env.NODE_ENV === "production" ? "/" : "/dist/"
                      );
                      break;
                  }
                  case "auth": {
                      switch (this.$route.params.page) {
                          case "register":
                              this.$store.commit("updateModalAuth", "register");
                              break;
                          default:
                              this.$store.commit("updateModalAuth", "login");
                      }
                      history.pushState(
                          null,
                          null,
                          process.env.NODE_ENV === "production" ? "/" : "/dist/"
                      );
                      break;
                  }
              }
          }
      });


    // window.location.href= '/';
    // var urlParams = new URLSearchParams(window.location.search);
    // for(var pair of urlParams.entries()) {
    //     console.log(pair[0]+ ', '+ pair[1]);
    // }
    // var urlParams2 = new URLSearchParams(window.location.pathname);
    // for(var pair2 of urlParams2.entries()) {
    //     console.log(pair2[0]+ ', '+ pair2[1]);
    // }
    // window.Echo.join('play.' + this.auth.id)
    //     .listen('CancelGame',(data)=> {
    //         console.log('client-play-cancel');
    //         console.log(data);
    //         this.dialog=false;
    //     })


  // Notification
  //     this.$notify({
  //         group: 'message',
  //         title: 'Password changed successfully',
  //         type: 'success',
  //     });



      // new Howl({
      //     src: ['/assets/media/battle-city-sfx-12.mp3']
      // }).play();
  },
  created() {
      this.$addListener(window,'scroll', () => {
          if (document.documentElement.scrollTop > 10) {
            this.scroll = true;
        } else {
            this.scroll = false;
        }
    });
      //
      // this.$echo.join('notice')
      //     .listen('NewsEvent', (response)=> {
      //         response= response.data;
      //         this.$store.dispatch("asyncPushNotice", response.data);
      //         this.$store.dispatch("asyncFriendsReqCount", response.data.friends);
      //     });


      //
      // let response= {
      //     "data": {
      //         "session_id": "PdXtFzUQj1V8hThLHXde4GwBk975d1X4DGMi1FKA",
      //         "friends": 10,
      //         "data": {
      //             "timestamp": 2131331322222,
      //             "notice": "friends",
      //             "type": 1,
      //           "user": {
      //             "name": "Happy",
      //             "last_name": "Proger122",
      //             "public_id": "happy22",
      //             "location": "AO",
      //             "location_img": "de.png",
      //             "rating_points": 224,
      //             "photo": null,
      //             "bg_color": "#f84138",
      //           }
      //         }
      //     }
      // };
      // response= response.data;
      // this.$store.dispatch("a/syncPushNotice", response.data);
      // this.$store.dispatch("asyncFriends", response.friends);
      //


    //   let response= {
    //       "data": {
    //           "session_id": "PdXtFzUQj1V8hThLHXde4GwBk975d1X4DGMi1FKA",
    //           "data": {
    //               "timestamp": 2423443234234,
    //               "notice": "achievement",
    //               "name": "22Happy2",
    //               "icon": "/assets/games/game2048.png",
    //               "rating": "23",
    //               "real": "2",
    //               "lite": null,
    //           }
    //       }
    //   };
    // response= response.data;
    // this.$store.dispatch("asyncPushNotice", response.data);

  //     'session_id' => $user->session_id,
  //         "achievement" => [
  //         "name" => $achievement->name,
  //         "icon" => $achievement->icon,
  //         "rating" => $achievement->rating,
  //         "real" => $achievement->real,
  //         "lite" => $achievement->lite,
  // ]
  //
  //     let response= {"data":{"data":{"timestamp":16172791813,"notice":"news","category":"test","url":"populyarnyie_wer","title":"populyarnyie igryi onlayn: chervyaki zmeyki tanchiki strelyalki i mnogo drugih igr!","icon":"/assets/games/game2048.png"}}};
  //   response= response.data;
  //   this.$store.dispatch("asyncPushNotice", response.data);


    if (window.userAuth) {
        // setInterval(() => {
        //     let chat = this.$cleanObject(this.chat_public);
        //     let mess = {
        //         "id": 4,
        //         "user_id": 1599808862,
        //         "message": "test mess",
        //         "read": 0,
        //         "likes": 0,
        //         "deleted_at": null,
        //         "created_at": "2023-10-12 08:07:10",
        //         "updated_at": "2023-10-05 11:42:33",
        //         "user": {
        //             "id": 1599808862,
        //             "public_id": "happy",
        //             "name": "Happy",
        //             "last_name": "",
        //             "photo": "/assets/users/a-1693676758oAQ1KlmMmz.png",
        //             "bg_color": "#29a3a3",
        //             "online": "2023/10/05 14:02:18",
        //             "pro": "2023-10-07 14:49:02"
        //         }
        //     };
        //     chat.push(mess);
        //     this.$store.dispatch("asyncChatPublic", chat);
        // }, 3000);


        this.$http.get("ajax/user_info")
        .then((response) => {
          return response.json();
        })
        .then((response) => {
            if(this.$checkAuth(response)) {
                this.$store.dispatch("asyncUserInfo", response);
                if (response.data.message_count) {
                    this.$store.dispatch("asyncMessagesCount", response.data.message_count);
                }

                // if (response.data.lang && response.data.lang != this.$route.params.lang) {
                //     let params = this.$cleanObject(this.$route.params);
                //     let lang=  response.data.lang;
                //
                //     if(this.$route.name== 'profile' || this.$route.name== 'home') {
                //         if(this.languages.map(function (e) { return String(e['lang']); }).indexOf(String(lang)) < 0 || params.lang) {
                //             params['id'] = params.lang;
                //         }
                //     }
                //
                //     params['lang'] = (lang == 'ru') ? null : lang;
                //
                //     console.log("this.$route.params");
                //     console.log(this.$route.params);
                //     console.log(params);
                //
                //     if(params != this.$route.params) {
                //         this.$router.push({ name: this.$route.name, params: params });
                //     }
                //     this.languages_menu = false;
                //     this.$store.commit("updateLanguage", lang);
                // }

                if (response.data.friends) {
                    // this.$store.dispatch("asyncFriends", response.data.friends);
                    this.$store.dispatch("asyncFriendsReqCount", response.data.friends);
                }

                let invite= response.data.invite_game;
                if (invite) {
                    this.$store.dispatch("asyncPushInviteGame", invite);
                    this.$sendNotification(invite.game_name, {
                        body: ((invite.wallet== 1) ? 'Real Coin:' : 'Lite Coin')+" "+invite.amount,
                        dir: 'auto',
                        renotify: true,
                        silent: true,
                        tag: invite.match_id,
                        icon: '/assets/games/'+invite.game_logo
                    });
                }
                this.$connectNotification();


                // if (this.$metrika) {
                //     this.$metrika.hit(87397177, 'userParams', {
                //         auth_status: true,
                //         UserID: '@' + this.auth.id
                //     });
                // }

                if (this.$gtag) {
                    this.$gtag.set('user_properties', {
                        auth_status: 'true',
                        UserID: '@' + this.auth.id
                    })
                }
            }
        })
        .catch((err) => {
          this.httpHandler(err);
        });
    } else {
      this.$store.commit("updateUserInfo", null);

        // if (this.$metrika) {
        //     this.$metrika.hit(87397177, 'userParams', {
        //         auth_status: false
        //     });
        // }

        if (this.$gtag) {
            this.$gtag.set('user_properties', {
                auth_status: 'false',
            })
        }
    }

    let token_auth= localStorage.getItem('token_auth');
    let interval= setInterval(()=>{
        if(token_auth != localStorage.getItem('token_auth')) {
            this.active_page= false;
            clearInterval(interval);
        }
    }, 5000);

    /*this.$http
      .get("ajax/platform")
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if(this.$checkAuth(response)) {
            this.$store.dispatch("asyncPlatforms", response);
        }
      })
      .catch((err) => {
        this.httpHandler(err);
      });

    this.$http
      .get("ajax/category")
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if(this.$checkAuth(response)) {
            this.$store.dispatch("asyncCategories", response);
        }
      })
      .catch((err) => {
        this.httpHandler(err);
      });*/

    // this.$http
    //   .get("ajax/languages")
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((response) => {
    //     if(this.$checkAuth(response)) {
    //         this.$store.dispatch("asyncLanguages", response);
    //     }
    //   })
    //   .catch((err) => {
    //     this.httpHandler(err);
    //   });

    this.$router.beforeEach((to, from, next) => {
      this.$removeAllListeners(window,'scroll');
      this.$store.commit("updateSearch", false);

      if (this.next_page) {
          return next();
      } else {
        this.$store.commit("updateModalNext", next);
      }
    });

    this.initializeClock();
    this.updateClock();
  },

  destroyed() {
    clearInterval(this.timeinterval);
    clearInterval(this.timerConnectionStable);
  },

  //
  // beforeCreate() {
  //   this.$store.dispatch("asyncPushInviteGame", {
  //       tournament_id: 123455,
  //       amount: "2.00",
  //       game_id: "1",
  //       game_logo: {
  //           "40": "/assets/games/70_l-1630568030jHkbUJ9Zuj.webp",
  //           "70": "/assets/games/70_l-1630568030jHkbUJ9Zuj.webp",
  //           "100": "/assets/games/70_l-1630568030jHkbUJ9Zuj.webp",
  //           "200": "/assets/games/200_l-1630568030jHkbUJ9Zuj.webp",
  //           "original": "/assets/games/l-1630568030jHkbUJ9Zuj.webp"
  //         },
  //       game_name: "2048 Online - Real time edition",
  //       game_translations: [
  //           {
  //               "description": "Bomberman Description",
  //               "game_id": 632110,
  //               "lang": "en",
  //               "name": "Bomberman",
  //               "rules": "Bomberman Game control"
  //           },
  //           {
  //               "description": "Бомбермен описание",
  //               "game_id": 632110,
  //               "lang": "ru",
  //               "name": "Бомбермен",
  //               "rules": "Бомбермен &nbsp;Game control"
  //           }
  //       ],
  //       inviter_id: 1370646003,
  //       location: null,
  //       location_img: null,
  //       match_id: 12345752,
  //       name: "Dark Raven",
  //       photo: {
  //           "40": "/assets/games/5.jpeg",
  //           "100": "/assets/games/5.jpeg",
  //           "original": "/assets/games/5.jpeg"
  //         },
  //       pro: '1',
  //       rating_points: 0,
  //       user_id: 1205822411,
  //       wallet: 2,
  //       attributes: [
  //         {
  //             attr_name: "game speed",
  //             img: "/assets/games/options/attr-1618509166NGttSYZKaJ.png",
  //             name: "Легкий",
  //             key: "lite",
  //             description: "Для нубов безпальцых",
  //             invalid: false
  //         },
  //         {
  //             attr_name: "game speed",
  //             img: "/assets/games/options/attr-1617201681aCBrGbGTKf.png",
  //             name: "1 мин",
  //             key: "1",
  //             description: "1 мин",
  //             invalid: false
  //         },
  //       ]
  //
  //   });
  // },
  // methods: {
  //   connectWebsocket: function () {
  //     this.auth = this.$store.state.auth.user;
  //     this.$socket()
  //       .listen("InviteGame", (data) => {
  //         this.$store.dispatch("asyncPushInviteGame", data.data);
  //         new Audio('/assets/media/battle-city-sfx-12.mp3').play();
  //       })
  //       .listen("StartGame", (data) => {
  //         if (data.data.room_id) {
  //           this.$router.push({
  //             name: "frame",
  //             params: {
  //               id: data.data.game_id,
  //               room_id: data.data.room_id,
  //             },
  //           });
  //         }
  //       })
  //       .listen("CancelGame", (data) => {
  //         let invite_game = this.invite_game;
  //         let index = this.$filterIndexObject(
  //           invite_game,
  //           data.data.user_id,
  //           "user_id"
  //         );
  //         if (index >= 0) {
  //           invite_game.splice(index, 1);
  //           this.$store.dispatch("asyncInviteGame", invite_game);
  //         }
  //       });
  //   },
  // },
  //

  components: {
      ModalClick,
    DisconnectedSession,
    NotificationsInviteGame,
    NotificationsChat,
    // LayoutSidebar,
    LayoutNav,
    ModalAuth,
    ModalNextPage,
    // MiniChat,
    NotificationPlayGame,
    ModalCookie,
    ModalReadyToPlay,
    GeneralChat
  },
};
</script>


<style lang="scss">

.block-launchpad {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background-image: url("/dist/images/frontend/bg-head_launchpad.png");
    background-size: cover;
    z-index: 1150;

    &:hover {
        opacity: 0.9;

        span {
            color: #00AEFF;
            transition-duration: .3s;
        }

        img {
            transform: scale(1.1);
            transition-duration: .3s;
        }
    }

    img {
        height: 16px;
        margin-right: 8px;
        width: 16px;
        flex-shrink: 0;
        transition-duration: .3s;
    }

    span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-right: 32px;
        position: relative;
        transition-duration: .3s;

        &:after {
            content: '';
            display: block;
            width: 1px;
            height: 24px;
            position: absolute;
            z-index: +1;
            right: -16px;
            top: -2px;
            background:linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 50.88%, rgba(255, 255, 255, 0) 100%);
        }
    }

    a {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #00AEFF!important;

        svg {
            margin-left: 6px;
            margin-bottom: 2px;
        }

        &:hover {
            text-decoration: underline;
        }

    }

    .block-timer {
        display: flex;
        align-items: center;
        position: relative;
        margin-right: 120px;
        &__title {
            margin: 0 6px 0 0;
            color: #fff;
            font-size: 14px;
            line-height: 19px;
        }

        .wrap-timer {
            display: flex;
            align-items: center;
            position: absolute;
            left: 100%;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 4px;
            .num {
                margin: 0;
                font-size: 16px;
                line-height: 21px;
                color: #00AEFF;
                &:after {
                    display: none;
                }
            }

            .txt {
                margin: 0;
                font-size: 16px;
                line-height: 21px;
                color: #00AEFF;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .block-launchpad {
        .block-timer {
            &__title {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 450px){
    .block-launchpad {
        height: 40px;
        padding: 0 15px;

        img {
            height: 14px;
            margin-right: 6px;
            width: 14px;
            flex-shrink: 0;
        }

        span {
            font-size: 12px;
            line-height: 17px;
            margin-right: 24px;

            &:after {
                right: -12px;
                height: 20px;
                top: -1px;
            }
        }

        a {
            font-size: 12px;
            line-height: 17px;

            svg {
                height: 10px;
                width: 6px;
            }
        }

        .block-timer {
            margin-right: 110px;
            &__item {
                .num,
                .txt {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }

    }
}


  .qwerty {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    height: 96px;
    width: 100%;
  }

  .qwerty.active {
    background: rgba(255, 255, 255, 0.4);
  }

  .liner  {
    position: absolute;
    background: #FFFFFF;
    backdrop-filter: blur(40px);
    height: 60px;
    width: 100%;
    -webkit-backdrop-filter: blur(40px);
    -moz-backdrop-filter: blur(40px);
    -ms-backdrop-filter: blur(40px);
    -o-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
  }

.block-app {

    &:before {
    content: "";
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(135deg, #F5F6FC 0%, #F4F5FA 100%);
  }
}

.theme--light.v-application.main-content {
    background: #000;
}

.block-app.main-content {
  &:before {
    content: "";
    display: block;
    width: 100%;
    position: fixed;
    //height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("/dist/images/frontend/bg-guest.svg");
    background-position: center;
    background-size: cover;
  }
}

  .wrap-app {
    min-height: 100vh;
  }


//dark

.dark.block-app {

  &:before {
      content: "";
      display: block;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      /*background: #0D0D0D;*/
      background: #000303;
  }
}
</style>
