<template>
      <div class="wrap-info-settings">
        <div class="wrapper-upload">
          <UploadAvatarResponse
                  @crop-success="cropSuccess"
                  :width="640"
                  :height="640"
                  img-format="png"
          >
            <template v-slot="{handleClick}">
              <div class="block-img">
                <img
                        @click="handleClick"
                        ref="previewCanvas"
                        class="wrap-upload__img"
                        :width="500"
                        :height="500"
                        src="/assets/games/default-avatar.svg"
                />
              </div>
              <div class="wrap-upload__desc">
                <span class="text-req">* {{__('Required')}}</span>
                <div>
                  <h4>{{__('Add preview for your game')}}</h4>
                  <p>{{__('The image must be of high quality, clear, 180 by 180 pixels')}}</p>
                </div>
                <div class="d-flex align-center">
                  <!--                      <v-btn v-ripple="{ class:'secondary&#45;&#45;text'}" text class="btn-cab white&#45;&#45;text" @click="handleClick">Upload image</v-btn>-->
                  <BaseButton

                          @click="handleClick"
                  >
                    <span>{{__('Upload image')}}</span>
                  </BaseButton>
                  <span>{{__('or drag and drop here')}}</span>
                </div>
              </div>
            </template>
          </UploadAvatarResponse>

        </div>

        <div class="wrap-block-key">
            <span class="icon">
                <img src="/dist/images/frontend/password-developer-icon.svg" alt />
            </span>
          <InputLabelTextDefault
                  :label='__("Name")'
                  color="main"
                  v-model="form.name"
                  v-bind:errors="formValidate.name"
                  @change="updateValidate('name')"
          ></InputLabelTextDefault>

        </div>

        <div class="wrap-block-key">
            <span class="icon">
                <img src="/dist/images/frontend/password-developer-icon.svg" alt />
            </span>
          <InputLabelTextDefault
                  :label='__("Description")'
                  color="main"
                  type="textarea"
                  v-model="form.description"
                  v-bind:errors="formValidate.description"
                  @change="updateValidate('description')"
          ></InputLabelTextDefault>
        </div>
        <div class="wrap-block-key">
            <span class="icon">
                <img src="/dist/images/frontend/password-developer-icon.svg" alt />
            </span>
          <InputLabelTextDefault
                  :label='__("Price")'
                  color="main"
                  step="0"
                  v-model="form.price"
                  v-bind:errors="formValidate.price"
                  @change="updateValidate('price')"
          ></InputLabelTextDefault>
        </div>
        <div class="wrap-input">
            <span class="icon">
                <img src="/dist/images/frontend/developer-iframe-icon.svg" alt />
            </span>
          <div class="input-big">
            <InputLabelTextDefault
                    :label='__("Property")'
                    color="main"
                    v-model="form.property"
                    v-bind:errors="formValidate.property"
                    @change="updateValidate('property')"
            ></InputLabelTextDefault>
          </div>

          <div class="input-small">
            <InputLabelTextDefault
                    :label='__("Value")'
                    color="main"
                    width="100%"
                    v-model="form.value"
                    v-bind:errors="formValidate.value"
                    @change="updateValidate('value')"
            ></InputLabelTextDefault>
          </div>
        </div>
        <div class="wrap-info-btn" >
          <BaseButton
                  :loading="submitDisabled"
                  v-on:click="onSubmit"
          >
            <span>{{__('Save')}}</span>
          </BaseButton>

          <BaseButton
                  :loading="submitDisabled"
                  v-on:click="$emit('update:create_nft', null)"
          >
            <span>{{__('Close')}}</span>
          </BaseButton>

        </div>
      </div>
</template>

<script>
    import UploadAvatarResponse from "../../modules/UploadAvatarResponse";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    // import ItemListPlatforms from "./ItemListPlatforms";
    import {required, integer} from "vuelidate/lib/validators";

    // import DropdownDefault from "../../modules/DropdownDefault";

    // optional style for arrows & dots

    export default {
        name: "GameCreateNFT",
        data() {
            return {
                form: {
                    img: null,
                    name: null,
                    description: null,
                    property: null,
                    value: null,
                    price: null,
                    contract: this.$contract.marketPlace.address
                },
                formValidate: {
                    img: null,
                    name: null,
                    description: null,
                    property: null,
                    value: null,
                    price: null,
                },
                provider: null,
                accounts: null,
                submitDisabled: false,
                submitSuccess: false,

            };
        },
        watch: {

        },
        props: {
            developers: {
                type: Object,
            },
            categories: {
                type: Array,
            },
            nft_list: {
                type: Array,
            },
        },
        computed: {

        },
        asyncComputed: {

        },
        created() {

        },
        validations() {
            return {
                form: {
                    // img: {
                    //     required,
                    // },
                    name: {
                        required,
                    },
                    description: {
                        required,
                    },
                    property: {
                        required,
                    },
                    value: {
                        required,
                    },
                    price: {
                        required,
                        integer
                    },
                },
            }
        },
        methods: {
            cropSuccess(img,imgFormat, field, sourceImgUrl) {
                this.img= img;
                this.imgFormat= imgFormat;
                this.field= field;

                this.sourceImgUrl= sourceImgUrl;
            },
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.form[type],
                    type
                );
            },

            onSubmit: function () {
                let valid = this.$v.form;

                if (valid.$invalid) {
                    let massages = {
                        img: [],
                        name: [],
                        description: [],
                        property: [],
                        value: [],
                        price: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    // this.submitDisabled = true;

                    let {
                        img,
                        field,
                        imgFormat,
                        sourceImgUrl
                    } = this;

                    const request = this.$cleanObject(this.form);
                    request['owner']= window.ethereum.selectedAddress;


                    let fmData = new FormData();
                    if(img) {
                        fmData.append(field, img, field + '.' + imgFormat);
                    }

                    fmData.append('name', request['name']);
                    fmData.append('description', request['description']);
                    fmData.append('property', request['property']);
                    fmData.append('value', request['value']);
                    fmData.append('price', request['price']);
                    fmData.append('contract', request['contract']);
                    fmData.append('owner', request['owner']);
                    fmData.append('sourceImgUrl', sourceImgUrl);

                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/developers/" + this.$route.params.id + "/nft/create", fmData)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            response= response.data;


                            this.$emit('createMintingRecord', response.tokenUri, this.primNum(request.price, 0), response);

                            //
                            //
                            //
                            // console.log('nft_list');
                            // console.log(nft_list);

                            // this.$emit('update:nft_list', nft_list);
                            // this.$emit('update:create_nft', null);
                        })
                        .catch((err) => {
                            this.submitDisabled = false;
                            let response = this.httpHandler(err);
                            if (response.error) {
                                this.formValidate = response.error.message;
                            }
                        });


                }
                setTimeout(()=> {
                    this.submitSuccess= null;
                }, 2000);
            },
        },
        components: {
            UploadAvatarResponse,
            // ItemListPlatforms,
            InputLabelTextDefault,
            // DropdownDefault
        },
    };
</script>

<style lang="scss" scoped>
    .dark {
        .wrap-info-settings {
            .block-id {
                &__text {
                    p {
                        color: #96AFD1;
                    }

                    span {
                        color: #fff;
                    }
                }
            }

            .wrap-block-key {
                span {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }
            }

            .wrap-category {
                p {
                    color: #96AFD1;
                }
            }

            .wrap-row {
                .desc {
                    color: #96AFD1;
                }
            }
        }

        .block-id,
        .wrap-input,
        .wrap-row,
        .wrap-block-key,
        .wrap-category,
        .wrap-block-players,
        .block-create {
            img {
                filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
            }
        }
    }

    .wrap-info-settings {
        .block-id {
            display: flex;
            align-items: flex-end;
            margin-bottom: 18px;


            span {
                margin: 10px 28px 0 0;
                min-width: 20px;
                display: flex;
                justify-content: center;
            }

            &__text {
                p {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #6886AF;
                    margin-bottom: 0;
                }

                span {
                    display: flex;
                    justify-content: flex-start;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #002B50;
                    margin: 5px 0 0 0;
                }
            }


        }

        .wrap-input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 26px;

            img {
                height: 20px;
            }

            span {
                margin: 10px 28px 0 0;
                min-width: 20px;
                display: flex;
                justify-content: center;
            }

            .input-big {
                width: 67%;
                margin: 0 20px 0 0;
                /*height: 40px;*/
            }

            .input-small {
                width: 34%;
                margin: 0 5px;

            }
        }

        .wrap-block-key {
            display: flex;
            align-items: flex-start;
            margin-right: 45px;
            margin-bottom: 25px;
            position: relative;

            span {
                margin: 17px 28px 0 0;

                img {
                    height: 20px;
                }
            }
        }

        .wrap-category {
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            max-width: 400px;
            width: 100%;

            img {
                height: 20px;
            }

            p {
                position: relative;
                z-index: 1;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: -8px;
            }
        }
        .information-icon {
            margin: 16px 28px 0 0;
            min-width: 20px;
            display: flex;
            justify-content: center;
        }

        .wrap-block-players {
            max-width: 230px;
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-right: 45px;
            margin-bottom: 25px;
            position: relative;
            span {
                margin: 17px 28px 0 0;

                img {
                    margin-left: -2px;
                    height: 18px;
                }
            }
        }

        .wrap-row {
            margin-bottom: 6px;

            img {
                margin-top: 15px;
                height: 20px;
            }

            .desc {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-left: 48px;

                a {
                    color: #26a9f4;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .hide {
                display: block;
                margin-top: 12px;
            }
        }

        .v-btn.refresh-btn {
            outline: none;
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        .block-create {
            display: flex;
            align-items: flex-start;
            margin-top: 6px;

            &__title {
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #6886AF;
                margin-bottom: 0;
            }

            span {
                margin: 0 28px 0 0;

                img {
                    height: 20px;
                }
            }

            .v-input {
                margin-right: 50px;
            }
        }

        .v-btn {
            margin: 0;

            &.v-btn:not(.v-btn--round).v-size--default {
                height: 40px;
                min-width: 64px;
                width: 124px;
                padding: 0;
            }
        }
        .wrap-btn {
            position: relative;
            display: flex;
            justify-content: center;
            margin: 40px 0 20px;
            min-height: 40px;

            p {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
                border-color: transparent;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #00C443;

                i {
                    font-size: 12px;
                    margin: 0 6px 0 20px;
                }
            }
        }
        .btn-disabled {
            display: block;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 768px) {
        .wrap-info-settings {

            .block-id,
            .wrap-input,
            .wrap-row,
            .wrap-block-key,
            .wrap-category {
                .icon {
                    margin-right: 20px;
                }
            }

            .wrap-row {
                .desc {
                    font-size: 11px;
                    line-height: 14px;
                    margin-top: 4px;
                    display: block;
                    margin-left: 48px;
                }
            }

            .wrap-input {
                .input-big {
                    width: 60%;
                }
                .input-small {
                    width: 40%;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        .wrap-info-settings {
            .block-id,
            .wrap-input,
            .wrap-row,
            .wrap-block-key,
            .wrap-category {
                .icon {
                    display: none;
                }
            }

            .wrap-row {
                .desc {
                    margin-left: 0;
                }
            }

            .block-id {
            }

            .wrap-input {
                flex-direction: column;
                align-items: flex-start;

                .input-big {
                    width: 100%;
                    margin: 0 0 25px;
                }

                .input-small {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }


</style>
