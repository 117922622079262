<template>
    <div class="wallet-form">
        <div class="wallet-form__head">
            <div class="wrap-tabs">
                <tabs
                        :tabs="side_tabs"
                        :currentTab="currentTab"
                        :wrapper-class="'default-tabs'"
                        :tab-class="'default-tabs__item'"
                        :tab-active-class="'default-tabs__item_active'"
                        :line-class="'default-tabs__active-line'"
                        @onClick="sideChange"
                >
                </tabs>
            </div>

            <div class="wrap-select">
                <DropdownDefault
                        class_modal="modal_game"
                        color="select_game"
                        v-bind:dropdown="side_tabs"
                        v-bind:value.sync="side_tabs.value"
                        @updateValue="sideChange"
                >
                </DropdownDefault>
            </div>
        </div>
        <div class="wallet-form__body">
            <WalletFormCard v-if="currentTab == 'card'" @sendCode="sendCode" v-bind:modal_security.sync="modal_security"></WalletFormCard>
            <WalletFormCrypto @sendCode="sendCode" v-bind:modal_security.sync="modal_security" v-else :payments="selectArr"></WalletFormCrypto>
        </div>
        <ModalAddWalletSecurity @add-wallet="addWallet" v-if="modal_security" v-bind:modal_security.sync="modal_security"></ModalAddWalletSecurity>
    </div>
</template>

<script>
    import WalletFormCard from "./WalletFormCard";
    import WalletFormCrypto from "./WalletFormCrypto";
    import DropdownDefault from "../../modules/DropdownDefault";
    import ModalAddWalletSecurity from "./ModalAddWalletSecurity";
    import _ from "lodash";
    export default {
        data() {
            return {
                side_tabs: [
                    {
                        title: "Cryptocurrency wallet",
                        value: "crypto",
                    },
                    {
                        title:"Debit / Credit Card",
                        value: "card",
                        // 'exact': true
                    }

                ],
                currentTab: 'crypto',
                modal_security: false,
            }
        },
        props: {
            payments: {
                type: Array
            },
            wallets: {
                type: [Object, Array]
            }
        },
        computed: {
            cryptocurrencyWallets() {
                let cryptocurrency = this.payments.filter(item => item.cryptocurrency == '1');
                return cryptocurrency
            },

            filterSelect: function () {
               return Object.keys(this.wallets);
            },

            selectArr: function () {
                let arr = [];
                 this.cryptocurrencyWallets.filter(item => {
                     let filter = this.filterSelect;
                     let test= _.includes(filter, String(item.id));
                     if(!test) {
                         arr.push(item);
                     }
                });
                return arr
            }
        },
        methods: {
            sideChange(newTab) {
                if (newTab != this.currentTab) {
                    this.currentTab = newTab;
                }
            },
            sendCode: function() {
                this.$http.get('ajax/setting/send_code')
                    .then(response=> {
                        return response.json()
                    })
                    .then(response=> {
                        this.$checkAuth(response);
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            },

            addWallet() {
                this.$emit('addWallet')
            }
        },
        components: {
            WalletFormCard,
            WalletFormCrypto,
            DropdownDefault,
            ModalAddWalletSecurity
        }
    }
</script>

<style lang="scss" scoped>
    .wallet-form {
        margin: 0 -20px;
        &__head {
            border-bottom: 1px solid #F0F6FE;

            .wrap-select {
                display: none;
            }
        }
    }

    .dark .wallet-form {
        &__head {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    @media screen and (max-width: 600px){
        .wallet-form {
            &__head {
                display: flex;
                align-items: center;
                padding: 0 15px;
                margin-top: -20px;
                height: 64px;
                .wrap-select {
                    display: flex;
                }
                .wrap-tabs {
                    display: none;
                }
            }
        }
    }
</style>