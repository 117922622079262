<template>
  <div style="background:transparent;" class="mt-0">
    <v-menu offset-y left v-if="languages && active_language" v-model="languages_menu" open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-list class="pa-0 block-lang" color="transparent">
          <v-list-item-action v-on="on" v-bind="attrs" class="mr-0">
            <v-list-item class="text-right pa-0">
              <img :src="`/dist/images/flags/${active_language.lang}.png`" />
<!--              {{active_language.full_lang}}-->
<!--              <img-->
<!--                :class="((languages_menu) ? 'active' : '')"-->
<!--                class="chevron"-->
<!--                src="/dist/images/frontend/chevron-down.svg"-->
<!--                alt-->
<!--              />-->
            </v-list-item>
          </v-list-item-action>
        </v-list>
      </template>
      <div class="modal-langs">
        <span
          v-for="(language, key) in languages"
          :key="key"
          v-on:click="selectLanguages(language.lang)"
          :class="(language.lang == active_language.lang) ? 'd-none' : ''"
        >
          <img
            class="mr-2"
            :src="'/dist/images/flags/'+language.lang+'.png'"
          />
          {{language.full_lang}}
        </span>
      </div>
    </v-menu>
    <div v-else>{{__('loading')}}</div>
  </div>
  <!-- <v-app  id="inspire">-->
  <!--      <v-overflow-btn-->
  <!--              class="dropdown"-->
  <!--              :items="languagesDropdown"-->
  <!--              target="#dropdown-example"-->
  <!--              v-model="active_languages"-->
  <!--              label="English"-->
  <!--              input-value="en"-->
  <!--      >-->
  <!--      </v-overflow-btn>-->
  <!--    </v-app> -->
</template>

<script>
import store from "../../store";

export default {
  name: "DropdownLanguages",
  data() {
    return {
      languages_menu: false,
      active_languages: null,
      // dropdown_font: ["English", "Russian"],
    };
  },
  computed: {
    languages() {
      return this.$store.state.language.list;
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    lang() {
      return this.$store.getters.getLanguage;
    },
  },
  asyncComputed: {
    active_language() {
      let language = this.$store.state.language.lang,
        languages = this.languages;

      if (language && languages) {
        return this.getLanguage(language);
      } else {
        return null;
      }
    },
  },
  methods: {
    selectLanguages: function (event) {
        let response= {
            lang: event
        };

        if(event != this.lang) {
            let params = this.$cleanObject(this.$route.params);
            if(this.$route.name== 'profile' || this.$route.name== 'home') {
                if(this.languages.map(function (e) { return String(e['lang']); }).indexOf(String(event)) < 0 && params.lang && !params.id) {
                    params['id'] = params.lang;
                }
            }
            params['lang'] = (event == 'ru') ? null :event;

            store.commit("updateLanguage", event);
            this.$router.push({ name: this.$route.name, params: params });
            this.languages_menu = false;

            this.$http
                .post("ajax/set_locale", response)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    this.$checkAuth(response)
                    // if(this.$checkAuth(response)) {
                    // }
                })
                .catch((err) => {
                    this.httpHandler(err);
                })
        }
        this.$emit('ListenScroll');
    },
    getLanguage: function (event) {
      let languages = this.languages;

      let language = languages.filter((item) => {
        return item.lang == event;
      });
      return language.length ? language[0] : languages[0];
    },
  },
};
</script>

<style lang="scss" scoped>

.menuable__content__active {
  /*min-width: 150px;*/
  /*max-width: 150px;*/
  /*width: 100%;*/
}

.v-menu__content {
  /*min-width: 150px;*/
  /*max-width: 150px;*/
  /*width: 100%;*/
  /*left: 7px!important;*/
}

.theme--light {
  /*min-width: 150px;*/
  /*max-width: 150px;*/
}
.v-list-item__action{
  width: 50px;
  height: 50px;
  /*padding: 30px 10px 30px 25px!important;*/
}
.block-lang .v-list-item{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-lang {
  cursor: pointer;
  /*padding: 30px 10px 30px 25px!important;*/
  background-color: transparent;
  /*height: 25px;*/

  .v-list-item {
    /*box-shadow : 0px 2px 24px rgba(0, 0, 0, 0.05);*/
    background: transparent;
    border-radius: 8px;
    min-height: fit-content;
    /*width: 150px;*/
    font-size: 14px;

    &__content {
      padding: 0 0 0 30px;
      display: block;
    }
  }

  .chevron {
    width: 12px;
    margin-left: 42px;
    transition-duration: .3s;

    &.active {
      transform: rotate(180deg);
      transition-duration: .3s;
    }
  }

  img {
    width: 20px;
    border-radius: 1px;
    /*margin-right: 8px;*/
  }
}

.modal-langs {
  padding: 10px;
  max-width: 300px;
  width: fit-content;
  height: fit-content;
  /*min-height: 200px;*/
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {

    &:hover {
      color: #0F9AFF!important;
    }
  }

  .v-list-item--link:before {
    background-color: transparent;
  }

  span {
    cursor: pointer;
    box-shadow : none;
    background: #fff;
    /*border-radius: 8px;*/
    height: 40px;
    font-size: 14px;
    min-width: 100px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    /*margin-bottom: 10px;*/
    &:hover {
      background-color: #fff!important;
      color: #0F9AFF!important;
    }
  }

  img {
    width: 18px;
    border-radius: 1px
  } 
}

.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
  margin-top: -18px;
  border-radius: 4px;
}

.v-menu__content--fixed {
  position: fixed;
  margin-right: 0;
  border-radius: 4px;
}

/*@media screen and (max-width: 1024px) {
  .menuable__content__active {
    min-width: 180px;
    max-width: 180px;
    width: 100%;
  }

  .v-menu__content {
    min-width: 180px;
    max-width: 180px;
    width: 100%;
    left: 7px!important;
  }

  .theme--light {
    min-width: 180px;
    max-width: 180px;
  }

  .v-list-item{
    .chevron {
      margin-left: 68px;
    }
  }
}

@media screen and (max-width: 950px) {
  .block-lang {
    .v-list-item {
      img {
        margin-right: 15px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .block-lang {
    .v-list-item {
      img {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (max-width: 475px) {

  .block-lang {
    .v-list-item {
      min-height: 30px;
      font-size: 12px;

      img {
        width: 14px;
      }

      .chevron {
        width: 10px;
        margin-left: 44px;
      }
    }
  }

  .block-all-lang {
    .v-list-item {
      min-height: 30px;
      font-size: 12px;
    }
  }
  .menuable__content__active {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
  }

  .v-menu__content {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    left: 10px!important;
  }

  .theme--light {
    min-width: 150px;
    max-width: 150px;
  }

}*/

</style>