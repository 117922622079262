<template>
    <div class="wrap-controls-game">
        <v-dialog v-model="dialog" max-width="500px">
            <div class="controls-game">
                <div class="controls-game__head">
                    <div class="d-flex align-center">
                        <!--            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                        <!--              <path d="M16.425 7.26433C16.2643 7.10362 15.975 7.10362 15.8143 7.26433C15.6536 7.42505 15.6536 7.71434 15.8143 7.87505L16.5214 8.58219H13.5964C13.3714 8.58219 13.1786 8.77505 13.1786 9.00005C13.1786 9.22505 13.3714 9.41791 13.5964 9.41791H16.5214L15.8143 10.1251C15.6536 10.2858 15.6536 10.5751 15.8143 10.7358C15.9107 10.8322 16.0072 10.8643 16.1036 10.8643C16.2 10.8643 16.3286 10.8322 16.3929 10.7358L17.8072 9.32148C17.9679 9.16077 17.9679 8.87148 17.8072 8.71076L16.425 7.26433Z" fill="currentColor"/>-->
                        <!--              <path d="M10.125 15.8143L9.41785 16.5214V13.5964C9.41785 13.3714 9.225 13.1786 9 13.1786C8.775 13.1786 8.58214 13.3714 8.58214 13.5964V16.5214L7.875 15.8143C7.71428 15.6536 7.425 15.6536 7.26428 15.8143C7.16785 15.9107 7.13571 16.0072 7.13571 16.1036C7.13571 16.2 7.16785 16.3286 7.26428 16.3929L8.67857 17.8072C8.83928 17.9679 9.12857 17.9679 9.28928 17.8072L10.7036 16.3929C10.8643 16.2322 10.8643 15.9429 10.7036 15.7822C10.575 15.6536 10.2857 15.6536 10.125 15.8143Z" fill="currentColor"/>-->
                        <!--              <path d="M9.28929 0.128592C9.12857 -0.0321219 8.83929 -0.0321219 8.67857 0.128592L7.26429 1.54288C7.10357 1.70359 7.10357 1.99288 7.26429 2.15359C7.425 2.31431 7.71429 2.31431 7.875 2.15359L8.58214 1.44645V4.37145C8.58214 4.59645 8.775 4.78931 9 4.78931C9.225 4.78931 9.41786 4.59645 9.41786 4.37145V1.44645L10.125 2.15359C10.2857 2.31431 10.575 2.31431 10.7357 2.15359C10.8321 2.05716 10.8643 1.96074 10.8643 1.86431C10.8643 1.76788 10.8321 1.63931 10.7357 1.57502L9.28929 0.128592Z" fill="currentColor"/>-->
                        <!--              <path d="M4.40358 8.58229H1.47858L2.18572 7.87515C2.34643 7.71444 2.34643 7.42515 2.18572 7.26443C2.08929 7.16801 1.99286 7.13586 1.89643 7.13586C1.8 7.13586 1.67143 7.16801 1.60715 7.26443L0.192862 8.67872C0.0321481 8.83944 0.0321481 9.12872 0.192862 9.28944L1.60715 10.7037C1.76786 10.8644 2.05715 10.8644 2.21786 10.7037C2.37858 10.543 2.37858 10.2537 2.21786 10.093L1.51072 9.38586H4.43572C4.66072 9.38586 4.85358 9.19301 4.85358 8.96801C4.82143 8.77515 4.62858 8.58229 4.40358 8.58229Z" fill="currentColor"/>-->
                        <!--              <path d="M9.00002 10.3178C9.72785 10.3178 10.3179 9.72782 10.3179 8.99999C10.3179 8.27215 9.72785 7.68213 9.00002 7.68213C8.27218 7.68213 7.68216 8.27215 7.68216 8.99999C7.68216 9.72782 8.27218 10.3178 9.00002 10.3178Z" fill="currentColor"/>-->
                        <!--            </svg>-->
                        <p>{{__('Awards and achievements')}}</p>
                    </div>
                    <v-btn text icon class="icon" @click="dialog = false">
                        <img src="/dist/images/frontend/close.svg" alt="/" />
                    </v-btn>
                </div>
                <div class="controls-game__body">
                    <AchievementItem v-for="(item, key) in profile.achievements.data" :item="item" :key="key"></AchievementItem>
                </div>
            </div>
        </v-dialog>
    </div>

</template>
<script>
    import AchievementItem from "./AchievementItem";
    export default {
        data(){
            return {
                dialog: true,
            }
        },
        props: {
            achievements_modal: {
                type: Boolean,
            },
            profile: {
                type: Object,
            }
        },
        watch: {
            dialog() {
                this.cancelModal();
            },
        },
        methods: {
            cancelModal() {
                this.$emit("update:achievements_modal", false);
            },
        },
        components: {
            AchievementItem
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .controls-game {
            &__head {
                background: #171827;
                border-bottom: 1px solid #2B2B2B;

                p {
                    color: #fff;
                }
            }

            &__body {
                background: #171827;
            }
        }
    }

    .controls-game {
        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-bottom: 1px solid #F0F6FE;
            border-radius: 8px 8px 0px 0px;
            padding: 20px 24px;
            position: relative;


            svg {
                margin-right: 12px;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 27px;
                color: #002b50;
                margin-bottom: 0;
            }

            .icon {
                position: absolute;
                top: 7px;
                right: -7px;
                outline: none;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        &__body {
            padding: 20px 0 0;
            background-color: #ffffff;
            border-radius: 0 0 8px 8px;
            max-height: 400px;
            overflow-y: auto;

        }
    }
</style>