import Vue from "vue";
import _ from 'lodash';
import store from './store';

// let requireComponent;

let activeLang= [];

// if(process.env.NODE_ENV === 'production') {
//     // requireComponent = require.context(
//     //     // Относительный путь до каталога компонентов
//     //     '../../backend/resources/lang',
//     //     // Обрабатывать или нет подкаталоги
//     //     false,
//     //     // Регулярное выражение для определения файлов базовых компонентов
//     //     /[a-z]\w+\.(json)$/,
//     // );
//     //
//     // requireComponent.keys().forEach(fileName => {
//     //     // Получение конфигурации компонента
//     //     const componentConfig = requireComponent(fileName)
//     //     let langName= fileName.replace('./', '').replace('.json', '');
//     //
//     //     activeLang[langName]= componentConfig;
//     // });
// } else {
//     // requireComponent = require.context(
//     //     // Относительный путь до каталога компонентов
//     //     './assets/lang',
//     //     // Обрабатывать или нет подкаталоги
//     //     false,
//     //     // Регулярное выражение для определения файлов базовых компонентов
//     //     /[a-z]\w+\.(json)$/
//     // );
// }
activeLang= window.i18n;

Vue.prototype.__ = (string, args) => {
    let value = _.get(activeLang[store.state.language.lang], string);
    if(value) {
        _.eachRight(args, (paramVal, paramKey) => {
            value = _.replace(value, `:${paramKey}`, paramVal);
        });
    } else {
        // var stringSplit= string.split('.');
        // value= stringSplit[stringSplit.length-1];
        value = _.get(activeLang['ru'], string);
        if(value) {
            _.eachRight(args, (paramVal, paramKey) => {
                value = _.replace(value, `:${paramKey}`, paramVal);
            });
        } else {
            // var stringSplit= string.split('.');
            // value= stringSplit[stringSplit.length-1];
            value= string;
        }
    }
    return value;
};