<template>
  <v-dialog v-model="dialog" max-width="500px">
    <div class="reviews-game">
      <div class="reviews-game__head">
        <p>{{__('Leave review')}}</p>
      </div>
      <div class="reviews-game__body">
        <div class="d-flex rating">
          <div class="d-flex">
            <v-rating
                half-increments
                v-model="form.rate"
                background-color="grey darken-1"
                color="blue lighten-1"
                dense
                hover
                size="36"
                class="mr-2"
                full-icon="star"
                empty-icon="star_outline"
                half-icon="star_half"
            ></v-rating>
            <span>{{form.rate}}</span>
          </div>
        </div>

        <div class="block-input">
          <InputLabelTextDefault
              v-bind:errors="formValidate.text"
              @change="updateValidate('text')"
              v-model="form.text"
              type="textarea"
              placeholder="Write a review"
          >
          </InputLabelTextDefault>
        </div>

<!--        <v-btn :loading="submitDisabled" text  class="btn-cab white&#45;&#45;text" @click="onSubmit">Send</v-btn>-->
        <BaseButton
            :loading="submitDisabled"
            text
            class="btn-cab white--text"
            @click="onSubmit">
          <span>{{__('Send')}}</span>
        </BaseButton>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import InputLabelTextDefault from "@/components/modules/InputLabelTextDefault";
import {maxValue, maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
export default {
  name:"ModalReviewsGame",
  data(){
    return {
      dialog: true,
      submitDisabled: false,
      form: {
        rate: this.valRating,
        text: null,
      },
      formValidate: {
        rate: null,
        text: null,
      },
    }
  },
  validations() {
    return {
      form: {
        rate: {
          required,
          maxLValue: maxValue(5),
          minValue: minValue(0.5),
        },
        text: {
          maxLength: maxLength(500),
          minLength: minLength(5),
        },
      },
    }
  },
  props: {
    reviews_game_modal: {
      type: Boolean,
    },
    info_reviews_modal: {
      type: Boolean,
    },
    game: {
      type: Object,
    },
    valRating: {
      type: Number,
    }
  },
  watch: {
    dialog() {
      this.cancelModal();
    },
  },
  methods: {
    cancelModal() {
      this.$emit("update:reviews_game_modal", false);
    },
    updateValidate: function (type) {
      this.formValidate = this.$changeValidate(
          this.formValidate,
          this.$v.form[type],
          type
      );
    },
    onSubmit: function () {
      let valid= this.$v.form;
      if(valid.$invalid) {
        let massages= {
          'rate': [],
          'text': [],
        };
        this.formValidate= this.$formValidate(massages, valid);
      } else {
        this.submitDisabled= true;

        this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/games/' + this.game.id +'/comment', this.form)
            .then(response=> {
              return response.json()
            })
            .then((response)=> {
                if(this.$checkAuth(response)) {
                    // response= {
                    //   "data": {
                    //     "rate": 2.5
                    //   },
                    //   "status":200
                    // }
                    this.form = {
                        rate: null,
                        text: null,
                    };
                    this.submitDisabled = false;
                    this.$emit('update:reviews_game_modal', false);
                    this.$emit('update:info_reviews_modal', true);
                    this.game.rating = response.data.rate;
                }
            }).catch(err => {
          this.submitDisabled= false;
          let response= this.httpHandler(err);
          if(response.error) {
            this.formValidate = response.error.message;
          }
        });
      }
    }
  },
  components: {
    InputLabelTextDefault
  }
}
</script>

<style lang="scss" scoped>

  .dark {
    .reviews-game {
      &__head {
        background: #171827;
        border-bottom: 1px solid #2B2B2B;

        p {
          color: #fff;
        }
      }
      &__body {
        background: #171827;

        .rating {
          span {
            color: #fff;
          }
        }
      }
    }
  }

.reviews-game {

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #F0F6FE;
    border-radius: 8px 8px 0px 0px;
    padding: 20px 24px;
    position: relative;

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #002b50;
      margin-bottom: 0;
    }
  }

  &__body {
    padding: 16px 24px 27px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;

    .rating {
      justify-content: center;
      align-items: center;

      span {
        min-width: 14px;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: #96AFD1;
      }
    }

    p {
      text-align: left;
      font-size: 14px;
      color: #002B50;
      margin-bottom: 0;
    }
  }

  .block-input {
    margin: 12px 0 20px;
  }

  .btn-cab {
    width: 100%;
  }
}
</style>