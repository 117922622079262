<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:modal_bun', false)">
        <div class="modal-inaccessible_quest">

            <v-btn text icon class="icon ma-0" @click="cancelModal">
                <img src="/dist/images/frontend/close.svg" alt="/" />
            </v-btn>

            <div class="modal-inaccessible_quest__body">
                <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0.703125V12L21.4413 5.79637C19.7188 2.95312 16.3438 0.703125 12 0.703125Z" fill="#FD6717"/>
                    <path d="M12 10.1983L11.5 12L12 13.8017C12.9951 13.8017 13.8017 12.9951 13.8017 12C13.8017 11.0049 12.9951 10.1983 12 10.1983V10.1983Z" fill="#86A1C6"/>
                    <path d="M10.1983 12C10.1983 12.9951 11.0049 13.8017 12 13.8017V10.1983C11.0049 10.1983 10.1983 11.0049 10.1983 12V12Z" fill="#86A1C6"/>
                    <path d="M20.4853 3.51473C18.2188 1.24823 15.2053 0 12 0L11.7656 0.734391L12 1.40625C14.8297 1.40625 17.49 2.50819 19.4909 4.50909C21.3251 6.34331 22.4032 8.73187 22.5702 11.2969H20V12.7031H22.5707C22.4189 15.0351 21.5131 17.2208 19.9715 18.9771L18.154 17.1597L17.1597 18.154L18.9771 19.9715C17.0452 21.6671 14.594 22.5938 12 22.5938V24C15.2053 24 18.2188 22.7518 20.4853 20.4853C22.7518 18.2188 24 15.2053 24 12C24 8.79469 22.7518 5.78123 20.4853 3.51473V3.51473Z" fill="#86A1C6"/>
                    <path d="M3.51469 3.51473C1.24823 5.78123 0 8.79469 0 12V12.7041L3.99998 12.7031V11.2969H1.42978C1.58161 8.96489 2.48695 6.7792 4.02853 5.02289L5.84597 6.84033L6.84033 5.84597L5.02289 4.02853C6.95475 2.33288 9.40598 1.40625 12 1.40625V0C8.79469 0 5.78119 1.24823 3.51469 3.51473V3.51473Z" fill="#86A1C6"/>
                    <path d="M3.22186 23.9714H1.81561V17.1562H0.375046V15.75H3.22186V23.9714Z" fill="#FD6717"/>
                    <path d="M7.22213 24C5.78597 24 4.61761 22.8316 4.61761 21.3954V18.3546C4.61761 16.9184 5.78602 15.75 7.22213 15.75C8.65833 15.75 9.82669 16.9184 9.82669 18.3546V21.3954C9.82669 22.8316 8.65833 24 7.22213 24ZM7.22213 17.1562C6.56138 17.1562 6.02386 17.6938 6.02386 18.3546V21.3954C6.02386 22.0562 6.56143 22.5938 7.22213 22.5938C7.88293 22.5938 8.42044 22.0562 8.42044 21.3954V18.3546C8.42044 17.6938 7.88293 17.1562 7.22213 17.1562Z" fill="#FD6717"/>
                    <path d="M12.7031 15.7344H12L11.8125 17.1719L12 18.6094H12.7031V15.7344Z" fill="#FD6717"/>
                    <path d="M11.2969 15.7344H12V18.6094H11.2969V15.7344Z" fill="#FD6717"/>
                </svg>

                <p>{{__('This user has limited your invitations to 10 minutes')}}</p>
            </div>
            <div class="wrap-btn">
                <BaseButton
                        width="100"
                        @click="cancelModal"
                >
                    <span>{{__('Ок')}}</span>
                </BaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        data() {
            return {
                modal: true,
            };
        },
        props: {
            modal_bun: {
                type: Boolean,
            }

        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {},

        methods: {
            cancelModal() {
                this.$emit("update:modal_bun", false);
            },
        },
        components: {},
    };
</script>

<style lang='scss' scoped>
    .modal-inaccessible_quest {
        text-align: center;
        padding: 45px 25px 30px;
        background: #171827;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;

        .icon {
            position: absolute;
            top: 4px;
            right: 4px;
            outline: none;

            img {
                height: 12px;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            svg {
                margin-bottom: 25px;
            }

            p {
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                color: #FFFFFF;
                margin: 0;
            }
        }

        .wrap-btn {
            display: flex;
            justify-content: center;
        }
    }

    @media screen and (max-width: 500px) {
        .modal-inaccessible_quest {
            padding: 35px 20px 25px;

            &__body {
                margin-bottom: 25px;
                img {
                    height: 130px;
                    margin-bottom: 20px;
                }
            }
        }
    }

</style>