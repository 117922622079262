<template>
    <div class="block-loader">
        <img src="/dist/images/frontend/left.svg" alt="">
        <img src="/dist/images/frontend/top.svg" alt="">
        <img src="/dist/images/frontend/footer.svg" alt="">
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .block-loader {
        position: relative;
        width: 30px;
        height: 30px;

        img {
            &:nth-child(1) {
                position: absolute;
                top: -5px;
                left: 0;
                animation-direction: alternate;
                animation-duration: 1s;
                animation-name: left;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-delay: .3s;

            }

            &:nth-child(2) {
                position: absolute;
                top: 1px;
                left: 10px;
                animation-direction: alternate;
                animation-duration: 1s;
                animation-name: top;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-delay: .3s;
            }

            &:nth-child(3) {
                position: absolute;
                top: 13px;
                left: -1px;
                animation-direction: alternate;
                animation-duration: 1s;
                animation-name: bottom;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
                animation-delay: .3s;
            }
        }
    }

    @keyframes top {
        0% {
            margin-left: 0;
        }
        40% {
            margin-left: 0;
        }
        80% {
            margin-left: 7px;
        }
        100% {
            margin-left: 7px;
        }
    }

    @keyframes left {
        0% {
            margin-top: 0;
            margin-left: 0;
        }
        40% {
            margin-top: 0;
            margin-left: 0;
        }
        80% {
            margin-top: -7px;
            margin-left: -7px;
        }
        100% {
            margin-top: -7px;
            margin-left: -7px;
        }
    }

    @keyframes bottom {
        0% {
            margin-top: 0;
            margin-left: 0;
        }
        40% {
            margin-top: 0;
            margin-left: 0;
        }
        80% {
            margin-top: 7px;
            margin-left: -7px;
        }
        100% {
            margin-top: 7px;
            margin-left: -7px;
        }
    }
</style>