<template>
    <div class="item-tournament item-tournament-new">
        <router-link :to="item.solo ? {name: 'tournament_solo', params: {'id': item.id, lang: $store.getters.getLang}} : {name: 'tournament', params: {'id': item.id, lang: $store.getters.getLang}}">
<!--        <router-link :to="{name: 'tournament', params: {'id': item.id, lang: $store.getters.getLang}}">-->
            <div class="list">
                <div class="top-list">
                    <div class="left-block">
                        <div v-if="typeof item.game.logo == 'object'" class="img" :style="{'backgroundImage': 'url(' +item.game.logo[300] + ')'}"></div>
                        <div v-else class="img" :style="{'backgroundImage': 'url(' +item.game.logo + ')'}"></div>
                        <div class="info-tournament">

                            <div class="info-tournament__header">
                                <p class="block-name">{{gameTranslation(item.game.game_translations).name}}</p>
                                <div class="block-attr" v-if="item.attribute && item.attribute.length">
                                    <p v-for="(item, key) in item.attribute" :key="key" class="all">{{item.name}}: <span>{{item.value}}</span></p>
                                </div>
                            </div>

                            <div class="rtp-block">
                                <div class="rtp">
                                    <p>{{__("RTP: 70%")}}</p>
                                </div>
<!--                                <div class="block-status">-->
<!--                                    <p style="color: #01DF72" v-if="status == 1" class="available">{{__('Available to participate')}}</p>-->
<!--                                    <p style="color: #E4E4E4" v-else-if="status == 2" class="available">{{__('Participants identified')}}</p>-->
<!--                                    <p style="color: #FDAE02" v-else-if="status == 3" class="performed">{{__("The tournament is ongoing")}}</p>-->
<!--                                    <p style="color: #E4E4E4" v-else class="done">{{__('Completed_tournament')}}</p>-->
<!--                                    &lt;!&ndash;                        <p class="failed">{{__('Faild')}}!</p>&ndash;&gt;-->
<!--                                </div>-->
                                <div class="block-users">
                                    <img src="/dist/images/frontend/users.svg" alt="">
                                    <span>{{item.users_count}}/{{item.count_users}}</span>
                                </div>
                            </div>

                            <div class="price-date">
                                <div class="block-price">
                                    <p class="text">{{__("Cost of participation")}}</p>
                                    <div>
                                        <span>{{item.price}}</span>
                                        <p v-if="item.wallet == 1">USDT</p>
                                        <p v-else>MIND</p>
                                    </div>
                                </div>

                                <div v-if="currentTab != 'upcoming'" class="block-date">
                                    <p class="start">
                                        <img src="/dist/images/frontend/start.svg" alt="">
                                        {{__('Start date:')}} {{item.date_start | moment('DD.MM')}}
                                    </p>

                                    <p>
                                        <img src="/dist/images/frontend/finish.svg" alt="">
                                        {{__('Expiration date:')}} {{item.date_finish | moment('DD.MM')}}
                                    </p>
                                </div>

                                <div v-if="currentTab == 'upcoming'" class="block-date">
                                    <p class="start">{{__('Start:')}}<span>{{item.date_start | moment('DD.MM')}} 00:00 UTC</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-block">
                        <div class="time">
                            <div v-if="isTournamentUpcoming(item)">
                                <span>{{__("It will start in")}}</span>
                                <p>{{ countdownToStart(item.date_start) }}</p>
                            </div>
                            <div v-else-if="isTournamentActive(item)">
                                <span style="color: #01DF72" v-if="status == 1" class="available">{{__('Available to participate')}}</span>
                                <span style="color: #E4E4E4" v-else-if="status == 2" class="available">{{__('Participants identified')}}</span>
                                <span style="color: #01DF72" v-else-if="status == 3" class="performed"> <span class="blink-1"></span>{{__("The tournament is ongoing")}}</span>
                                <span style="color: #E4E4E4" v-else class="done">{{__('Completed_tournament')}}</span>
<!--                                <span>{{__("It will end in")}}</span>-->
                                <p style="color: #01DF72">{{ countdownToFinish(item.date_finish) }}</p>
                            </div>
                            <p v-else class="end">{{__("The tournament has concluded")}}</p>
                        </div>
                        <div class="href">
                            <a class="baseButton">
                                <span>
                                    {{__("View more details")}}
                                    <svg width="6" height="24" viewBox="0 0 6 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 15.5L4.5 12.5L1.5 9.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </a>
                        </div>


<!--                        <div class="description">-->
<!--                            <p>{{gameTranslation(item.translate).title}}</p>-->
<!--                        </div>-->

<!--                        <div v-if="currentTab != 'upcoming'" class="block-date">-->
<!--                            <p>{{__('Start date:')}} {{item.date_start | moment('DD.MM')}}</p>-->

<!--                            <p>{{__('Expiration date:')}} {{item.date_finish | moment('DD.MM')}}</p>-->
<!--                        </div>-->

<!--                        <div v-if="currentTab == 'upcoming'" class="block-release">-->
<!--                            <p>{{__('Start:')}}<span>{{item.date_start | moment('DD.MM')}} 00:00 UTC</span></p>-->
<!--                        </div>-->
<!--                        <div v-else class="progress-quest">-->
<!--&lt;!&ndash;                            <p style="color: #00AEFF">{{item.date_start| moment('DD.MM')}}</p>&ndash;&gt;-->
<!--                            <div v-if="now_date < date_start" class="progress-users">-->
<!--                                <progress  style="width: 100%" :value="item.users_count" :max="item.count_users"></progress>-->
<!--                            </div>-->

<!--                            <div v-else-if="calcPrecentDaysPassed() >= calcPrecentPlayedMatches()" class="block-info">-->
<!--                                <progress  style="width: 100%" :value="countDays(item.date_start, new Date())" :max="countDays(item.date_start, item.date_finish)"></progress>-->
<!--                            </div>-->

<!--                            <div v-else class="block-info">-->
<!--                                <progress  style="width: 100%" :value="Number(item.played_matches)" :max="((item.users_count - 1) * item.count_match) * item.users_count"></progress>-->
<!--                            </div>-->
<!--&lt;!&ndash;                            <p style="color: #01DF72">{{item.date_finish | moment('DD.MM')}}</p>&ndash;&gt;-->
<!--                        </div>-->
                    </div>
                </div>
<!--                <div :style="(!item.partipicate_count) ? {paddingLeft: '10px'} : ''" class="body-list">-->
<!--                    <ul class="block-desktop" v-if="item.partipicate_count">-->
<!--                        <div class="block-status">-->
<!--                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M3.5 6.75L5 8.25L8.5 4.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                                <path d="M6 11.5C8.7615 11.5 11 9.2615 11 6.5C11 3.7385 8.7615 1.5 6 1.5C3.2385 1.5 1 3.7385 1 6.5C1 9.2615 3.2385 11.5 6 11.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                            </svg>-->

<!--                            <span>{{__('participate')}}</span>-->
<!--                        </div>-->

<!--&lt;!&ndash;                        <li>&ndash;&gt;-->
<!--&lt;!&ndash;                            <p>{{__('Price')}}:</p>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-if="item.wallet == 1" class="block-cost__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img src="/dist/images/frontend/money.svg" alt />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-else class="block-cost__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img src="/dist/images/frontend/light-coin.svg" alt />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->

<!--&lt;!&ndash;                        <li class="block-mob">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p>{{__('Price')}}:</p>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-if="item.wallet == 1" class="block-cost__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img src="/dist/images/frontend/money.svg" alt />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-else class="block-cost__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img src="/dist/images/frontend/light-coin.svg" alt />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->

<!--&lt;!&ndash;                        <li v-if="item.partipicate_count" class="block-mob">&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="block-reward__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <g clip-path="url(#clip0_678_4160)">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <path d="M3.5 6.25L5 7.75L8.5 4.25" stroke="#01DF72" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <path d="M6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11Z" stroke="#01DF72" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </g>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <defs>&ndash;&gt;-->
<!--&lt;!&ndash;                                        <clipPath id="clip0_678_4160">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <rect width="12" height="12" fill="white"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </clipPath>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </defs>&ndash;&gt;-->
<!--&lt;!&ndash;                                </svg>&ndash;&gt;-->

<!--&lt;!&ndash;                                <span style="color: #01DF72;">принимаю участие</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->
<!--&lt;!&ndash;                        <li v-else class="block-mob">&ndash;&gt;-->
<!--&lt;!&ndash;                            <p>{{__('Winners:')}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="block-reward__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span  style="color: #01DF72;">≈70%</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->
<!--                    </ul>-->

<!--                    <ul v-else>-->
<!--&lt;!&ndash;                        <li>&ndash;&gt;-->
<!--&lt;!&ndash;                            <p>{{__('Price')}}:</p>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-if="item.wallet == 1" class="block-cost__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img src="/dist/images/frontend/money.svg" alt />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div v-else class="block-cost__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span style="color: #26A9F4;">{{item.price}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img src="/dist/images/frontend/light-coin.svg" alt />&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->

<!--&lt;!&ndash;                        <li>&ndash;&gt;-->
<!--&lt;!&ndash;                            <p>{{__('Winners:')}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                            <div class="block-reward__desc">&ndash;&gt;-->
<!--&lt;!&ndash;                                <span  style="color: #01DF72;">≈70%</span>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                        </li>&ndash;&gt;-->
<!--                    </ul>-->
<!--&lt;!&ndash;                    <div>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p v-if="status == 1" class="available">{{__('Available to participate')}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p v-else-if="status == 2" class="available">{{__('Participants identified')}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p v-else-if="status == 3" class="performed">{{__('Active')}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                        <p v-else class="done">{{__('Completed_tournament')}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                        <p class="failed">{{__('Faild')}}!</p>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--                </div>-->

            </div>
        </router-link>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        name: 'ItemTournaments',
        data() {
            return {
                submitDisabled: false,
                open_confirm: false,
                inaccessible_quest: false,
                pro_quest: false,
                discord_quest: false,
                status: null,
                // 1 - Available to participate
                // 2 - Participants identified
                // 3 - Active
                // 4 - Completed
                interval: null,
                now_date: moment.utc().format('X'),
                currentMoment: moment.utc(),
                // now_date: moment.utc().format('X'),
                // date_start: moment.utc(this.item.date_start).format('X'),
                // date_finish: moment.utc(this.item.date_finish).format('X')
            }
        },
        computed: {
            lang() {
                return this.$store.state.language.lang;
            },
            auth() {
                return this.$store.state.auth.user;
            },
        },

        created() {
            this.setStatus(this.item)
        },
        beforeDestroy() {
            clearInterval(this.interval)
        },
        mounted() {
            this.updateCountdowns();
            this.interval = setInterval(this.updateCountdowns, 1000);
        },

        methods: {
            isTournamentUpcoming(tournament) {
                const startDate = moment.utc(tournament.date_start);
                return this.currentMoment.isBefore(startDate);
            },
            isTournamentActive(tournament) {
                const startDate = moment.utc(tournament.date_start);
                const endDate = moment.utc(tournament.date_finish);
                return this.currentMoment.isSameOrAfter(startDate) && this.currentMoment.isSameOrBefore(endDate);
            },
            countdownToStart(dateStart) {
                const startDate = moment.utc(dateStart);
                const timeRemaining = startDate.diff(this.currentMoment);
                return this.formatTimeRemaining(timeRemaining);
            },
            countdownToFinish(dateFinish) {
                const endDate = moment.utc(dateFinish);
                const timeRemaining = endDate.diff(this.currentMoment);
                return this.formatTimeRemaining(timeRemaining);
            },
            formatTimeRemaining(timeRemaining) {
                const duration = moment.duration(timeRemaining);
                const days = Math.floor(duration.asDays()); // получаем количество дней
                const hours = duration.hours() + days * 24; // преобразуем дни в часы и добавляем к общим часам
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                const hours1 = hours.toString().padStart(2, '0');
                const minutes1 = minutes.toString().padStart(2, '0');
                const seconds1 = seconds.toString().padStart(2, '0');

                return `${hours1}:${minutes1}:${seconds1}`;
            },
            updateCountdowns() {
                this.currentMoment = moment.utc(); // Обновляем текущее время UTC
            },
            calcPrecentPlayedMatches() {
                if (this.item.users_count > 1) {
                    let total_match = ((this.item.users_count - 1) * this.item.count_match) * this.item.users_count;
                    return this.primNum((Number(this.item.played_matches) * 100) / total_match, 2)
                } else {
                    return 0
                }

            },
            calcPrecentDaysPassed() {
                return this.countDays(this.item.date_start, new Date()) * 100 / this.countDays(this.item.date_start, this.item.date_finish);
            },
            setStatus(item) {
                if (moment().utc().isBefore(moment(item.date_start).utc()) && Number(item.users_count) < Number(item.count_users)) {
                    this.status = 1;
                } else if (moment().utc().isBefore(moment(item.date_start).utc()) && Number(item.users_count) >= Number(item.count_users)) {
                    this.status = 2;
                } else if (moment().utc().isAfter(moment(item.date_start).utc()) && moment().utc().isBefore(moment(item.date_finish).utc())) {
                    this.status = 3;
                } else if (moment().utc().isAfter(moment(item.date_finish).utc())) {
                    this.status = 4;
                }
            },
            gameTranslation(arr) {
                if (this.$filterArray(arr, this.lang, 'lang')) {
                    return this.$filterArray(arr, this.lang, 'lang');
                } else {
                    return this.$filterArray(arr, 'en', 'lang');
                }
            },
            countDays(dateStart, dateFinish) {
                return moment(dateFinish).diff(moment(dateStart), 'days')
            }
        },
        props: {
            item: {
                type: Object
            },
            currentTab: {
                type: String
            }
        },
        components: {

        }
    }
</script>

<style lang="scss" scoped>

</style>