<template>
    <div class="wrapper-awards" >
        <NotificationAchievementsItem  v-for="(item, key) in achievements_new" @removeNotification="$emit('removeNotification', $event)" :item="item" v-bind:index="key" :key="key"></NotificationAchievementsItem>
    </div>
</template>

<script>

    import NotificationAchievementsItem from "./NotificationAchievementsItem";

    export default {
        data() {
            return {

            }
        },
        props: {
            achievements_new: {
                type: [Object, Array]
            }
        },
        components: {
            NotificationAchievementsItem
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .wrapper-awards {
            background: rgba(0, 0, 0, 0.7);
        }
    }

    .wrapper-awards {
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-height: 100vh;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 500;
        background: rgba(0, 0, 0, 0.85);
        overflow: hidden;
    }

</style>