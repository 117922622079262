<template>
    <div>
        <LayoutNavAuth>
            <div v-if="quest" class="block-breadcrumbs">
                <router-link :to="{name: 'quests', params: {lang: $store.getters.getLang}}">

                    <div class="back-img">
                        <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.90929 7.81789C4.03006 7.69737 4.03025 7.5018 3.90974 7.38101L0.707886 4.1725C0.587442 4.05204 0.587457 3.85608 0.708118 3.73541L3.90974 0.527127C4.03026 0.406358 4.03006 0.210786 3.90929 0.0902487C3.78852 -0.0302577 3.59294 -0.0300569 3.47243 0.0906966L0.271024 3.29876C0.0903256 3.47949 0 3.71681 0 3.95416C0 4.19137 0.0902946 4.42863 0.270807 4.60913L3.47243 7.81744C3.59294 7.93819 3.78852 7.9384 3.90929 7.81789Z" fill="currentColor"></path>
                        </svg>
                    </div>

                    <span>{{__('All quests')}}</span>
                </router-link>

                <p>{{__(quest.name)}}</p>
            </div>

            <div class="loading"  :style="{width: progress + '%'}"></div>
        </LayoutNavAuth>
        <div class="quest-page" v-if="quest">
<!--            <div class="top-tab nav-auth">-->
<!--                <div class="container">-->
<!--                    <a>{{__(quest.name)}}</a>-->
<!--&lt;!&ndash;                    <a href="#">Архив</a>&ndash;&gt;-->
<!--                    <div v-if="auth" class="loading"  :style="{width: progress + '%'}"></div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="back">-->
<!--                <div class="container">-->
<!--                    <div>-->
<!--                        <router-link :to="{name: 'quests', params: {lang: $store.getters.getLang}}" >-->
<!--                            <div class="img">-->
<!--                                <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.90929 7.81789C4.03006 7.69737 4.03025 7.5018 3.90974 7.38101L0.707886 4.1725C0.587442 4.05204 0.587457 3.85608 0.708118 3.73541L3.90974 0.527127C4.03026 0.406358 4.03006 0.210786 3.90929 0.0902487C3.78852 -0.0302577 3.59294 -0.0300569 3.47243 0.0906966L0.271024 3.29876C0.0903256 3.47949 0 3.71681 0 3.95416C0 4.19137 0.0902946 4.42863 0.270807 4.60913L3.47243 7.81744C3.59294 7.93819 3.78852 7.9384 3.90929 7.81789Z" fill="#ffffff" opacity="0.7"></path></svg>-->
<!--                            </div>-->
<!--                            <p>{{__('All quests')}}</p>-->
<!--                        </router-link>-->
<!--                        <p v-if="quest.pro" class="pro">{{__('Pro')}}</p>-->
<!--                        <p v-else-if="quest.discord" class="discord">{{__('Discord')}}</p>-->
<!--                        <p v-else class="all"></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <div class="content dashboard-content">
                <div class="left-page">
                    <div class="my-block">
                        <div class="progress-quest">
                            <p>0</p>
                            <div class="progress-line" v-if="quest.status== 3">
                                <v-tooltip
                                        v-model="show"
                                        bottom
                                        color="transparent"
                                        nudge-top="10"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <progress v-on="on" v-bind="attrs" style="width: 100%" :value="quest.progress" :max="quest.progress_end"></progress>
                                    </template>
                                    <p class="block-name ma-0">
                                        {{__('Done')}}
                                        <span v-if="quest.progress_time == 1">{{ConvertTime(quest.progress)}}</span>
                                        <span v-else>{{Number(quest.progress).toFixed(0)}}</span>
                                        {{__('from')}}

                                        <span v-if="quest.progress_end_time == 3">{{ConvertTimeEnd(quest.progress_end)}}</span>
                                        <span v-else>{{Number(quest.progress_end).toFixed(0)}}</span>

                                        <span v-if="quest.progress_end_time == 1"> {{__('seconds')}}</span>
                                        <span v-else-if="quest.progress_end_time == 2"> {{__('minutes')}}</span>
                                        <span v-else-if="quest.progress_end_time == 3"> {{__('hours')}}</span>
                                        <span v-else-if="quest.progress_end_time == 4"> {{__('days')}}</span>
                                        <span v-else-if="quest.progress_end_time == 5"> {{__('weeks')}}</span>
                                        <span v-else-if="quest.progress_end_time == 6"> {{__('months')}}</span>
                                        <span v-else-if="quest.progress_end_time == 7"> {{__('year')}}</span>
                                        <span v-else-if="quest.progress_end_time == 8"> {{__('users')}}</span>
                                        <span v-else-if="quest.progress_end_time == 9"> {{__('games')}}</span>
                                        <span v-else></span>
                                    </p>
                                </v-tooltip>
                            </div>
                            <div v-else-if="!auth" class="progress-line">
                                <progress  style="width: 100%" value="0" :max="quest.progress_end"></progress>
                            </div>
                            <div class="progress-line" v-else>
                                <v-tooltip
                                        v-model="show"
                                        bottom
                                        color="transparent"
                                        nudge-top="10"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <progress v-on="on" v-bind="attrs" style="width: 100%" :value="quest.progress" :max="quest.progress_end"></progress>
                                    </template>
                                    <p class="block-name ma-0">
                                        {{__('Done')}}
                                        <span v-if="quest.progress_time == 1">{{ConvertTime(quest.progress)}}</span>
                                        <span v-else>{{Number(quest.progress).toFixed(0)}}</span>
                                        {{__('from')}}

                                        <span v-if="quest.progress_end_time == 3">{{ConvertTimeEnd(quest.progress_end)}}</span>
                                        <span v-else>{{Number(quest.progress_end).toFixed(0)}}</span>

                                        <span v-if="quest.progress_end_time == 1"> {{__('seconds')}}</span>
                                        <span v-else-if="quest.progress_end_time == 2"> {{__('minutes')}}</span>
                                        <span v-else-if="quest.progress_end_time == 3"> {{__('hours')}}</span>
                                        <span v-else-if="quest.progress_end_time == 4"> {{__('days')}}</span>
                                        <span v-else-if="quest.progress_end_time == 5"> {{__('weeks')}}</span>
                                        <span v-else-if="quest.progress_end_time == 6"> {{__('months')}}</span>
                                        <span v-else-if="quest.progress_end_time == 7"> {{__('year')}}</span>
                                        <span v-else-if="quest.progress_end_time == 8"> {{__('users')}}</span>
                                        <span v-else-if="quest.progress_end_time == 9"> {{__('games')}}</span>
                                        <span v-else></span>
                                    </p>
                                </v-tooltip>
                            </div>

<!--                                <div class="progress-line" v-if="quest.status== 1">-->
<!--                                    <progress style="width: 100%" :value="quest.progress" :max="quest.progress_end"></progress>-->
<!--                                </div>-->
<!--                                <div class="progress-line" v-if="quest.status== 2">-->
<!--                                    <progress style="width: 100%" :value="quest.progress" :max="quest.progress_end"></progress>-->
<!--                                </div>-->
<!--                                <div class="progress-line failed" v-if="quest.status== 3">-->
<!--                                    <progress style="width: 100%" :value="quest.progress" :max="quest.progress_end"></progress>-->
<!--                                </div>-->
<!--                                <div class="progress-line" v-else>-->
<!--                                    <progress style="width: 100%" :value="quest.progress" :max="quest.progress_end"></progress>-->
<!--                                </div>-->
                            <p>100</p>
                        </div>
                        <div class="description">
                            <p>{{__(quest.description)}}</p>
                        </div>
                    </div>
                    <div class="users">
                        <div>
                            <p>{{__('List of participants')}}</p>
                            <img src="/dist/images/frontend/users.svg" alt="">
                            <span>{{quest.quest_user_count}}</span>
                        </div>
                    </div>
                    <ul class="all-list" >
                        <div v-if="users">
                            <ItemQuestUser v-for="(item, key) in users" :item.sync="item" :quest="quest" :position="key" :key="key"></ItemQuestUser>
                        </div>
                        <div v-else class="all-list-empty">
                            <p>{{__('This quest is not completed by more than one player, Be the first!')}}</p>
                        </div>
                    </ul>

                </div>
                <div class="right-page">
                    <div class="top">
                        <div>
                            <p v-if="quest.type == 1" class="name">{{__('One-time')}}</p>
                            <p v-else-if="quest.type == 3" class="name">{{__('Daily')}}</p>
                            <p v-else class="name">{{__('Monthly')}}</p>
                            <div class="img" :style="{'backgroundImage': 'url(' +quest.icon + ')'}"></div>
                            <p class="title-quest">{{__(quest.name)}}</p>
                        </div>
                        <ul>
                            <li>
                                <p>{{__('Reward')}}:</p>
                                <div v-if="quest.real" class="block-reward__desc">
                                    <span  style="color: #01DF72;">+{{quest.real}}</span>
                                    <img src="/dist/images/frontend/money.svg" alt />
                                </div>
                                <div v-else class="block-reward__desc">
                                    <span style="color: #01DF72;">+{{quest.lite}}</span>
                                    <img src="/dist/images/frontend/light-coin.svg" alt />
                                </div>
                            </li>
                            <li>
                                <p>{{__('Rating')}}:</p>
                                <span class="rating">{{quest.rating}}</span>
                                <img src="/dist/images/frontend/fire.svg" alt="">
                            </li>
                        </ul>
                    </div>
                    <div class="body">
                        <div>
                            <div v-if="quest.wallet == 1" class="block-reward__desc">
                                <span style="color: #FFFFFF;">{{quest.price}}</span>
                                <img src="/dist/images/frontend/money.svg" alt />
                            </div>
                            <div v-else class="block-reward__desc">
                                <span style="color: #FFFFFF;">{{quest.price}}</span>
                                <img src="/dist/images/frontend/light-coin.svg" alt />
                            </div>
                        </div>
                        <p v-if="quest.status== 1" class="performed">{{__('Active-quest')}}</p>
                        <p v-else-if="quest.status== 2" class="done">{{__('Passed')}}</p>
                        <p v-else-if="quest.status== 3" class="failed" >{{__('Failed')}}</p>
                        <BaseButton class_btn="btn-guest baseButton" v-on:click="pushRegister" v-else-if="!auth" ><span>{{__('Buy quest')}}</span></BaseButton>
                        <BaseButton class_btn="btn-guest baseButton" v-on:click="onSubmit" v-else ><span>{{__('Buy quest')}}</span></BaseButton>
<!--                            <p v-if="quest.status== 0" class="btn-site">{{__('Buy a quest')}}</p>-->
                    </div>
                </div>
            </div>
<!--            <pre>-->
<!--                 {{users}}-->
<!--            </pre>-->

        </div>
        <div v-else>

        </div>
        <ModalConfirmQuest
            v-if="open_confirm"
            v-bind:open_confirm.sync="open_confirm"
            @buyQuest="buyQuest(quest.id)"
            v-bind:item.sync="quest"
            :submitDisabled.sync = "submitDisabled"
        ></ModalConfirmQuest>

        <ModalInaccessibleQuest
            v-if="inaccessible_quest"
            v-bind:inaccessible_quest.sync="inaccessible_quest"
        >
        </ModalInaccessibleQuest>
        <ModalProQuest
            v-if="pro_quest"
            v-bind:pro_quest.sync="pro_quest"
        >
        </ModalProQuest>
        <ModalDiscordQuest
            v-if="discord_quest"
            v-bind:discord_quest.sync="discord_quest"
        >
        </ModalDiscordQuest>
    </div>
</template>

<script>
    import moment from "moment";
    import ModalConfirmQuest from "./ModalConfirmQuest";
    import ModalInaccessibleQuest from "./ModalInaccessibleQuest";
    import ModalProQuest from "./ModalProQuest";
    import ModalDiscordQuest from "./ModalDiscordQuest";
    import BaseButton from "../../modules/BaseButton";
    import ItemQuestUser from "./ItemQuestUser";
    import LayoutNavAuth from "../../modules/LayoutNavAuth";

    export default {
        name: 'questpage',
        data() {
            return {
                show: false,
                submitDisabled: false,
                open_confirm: false,
                inaccessible_quest: false,
                pro_quest: false,
                discord_quest: false,
                next_page_url: false,
                quest: null,
                users: null,
                loadingPage: false,
                progress: 0,
                now_date: moment.utc(new Date()).format('X'),
            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
            lang() {
                return this.$store.getters.getLanguage;
            }
        },
        beforeRouteUpdate (to, from, next) {
            // обрабатываем изменение параметров маршрута...
            // не забываем вызвать next()
            this.createdComponent(to.params.id);
            return next();
        },
        created() {
            this.createdComponent(this.$route.params.id);
            this.$addListener(window,'scroll',  () => {
                this.scrollUser();
            });
        },
        methods: {
            pushRegister() {
                this.$store.commit('updateModalAuth', 'register');

            },
            ConvertTime(seconds)  {
                let days     = Math.floor(seconds / (24*60*60));
                seconds -= days    * (24*60*60);
                let hours    = Math.floor(seconds / (60*60));
                seconds -= hours   * (60*60);
                let minutes  = Math.floor(seconds / (60));
                seconds -= minutes * (60);
                return ((0<days)?(days+"day "):"") + ((0<hours)?(hours+"h "):"") + ((0 < minutes) ? (minutes + "m ") : "")+ ((0 < seconds) ? (seconds + "s") : "0s");
            },
            ConvertTimeEnd(seconds)  {
                let days     = Math.floor(seconds / (24*60*60));
                seconds -= days    * (24*60*60);
                let hours    = Math.floor(seconds / (60*60));
                seconds -= hours   * (60*60);
                let minutes  = Math.floor(seconds / (60));
                seconds -= minutes * (60);
                return ((0<days)?(days):"") + ((0<hours)?(hours):"") + ((0 < minutes) ? (minutes) : "")+ ((0 < seconds) ? (seconds) : "");
            },

            buyQuest(val) {
                this.submitDisabled = true;
                let id = val;
                const response = {
                    id: id,
                };
                this.$http.post("ajax/quest/buy", response)
                    .then((response) => {
                        return response.json();
                    })
                    .then(() => {
                        // let actual_quest= this.$cleanObject(this.actual_quest);
                        //
                        this.submitDisabled = false;
                        // let index = this.$filterIndexObject(actual_quest, id);
                        // actual_quest[index].user= {
                        //     "progress": "0",
                        //     "progress_end": actual_quest[index].progress_end
                        // }
                        // this.$emit('update:actual_quest', actual_quest);
                        this.open_confirm = false;
                        this.quest.status = 1;
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        let response = this.httpHandler(err);
                        if (response.status == '422') {
                            this.open_confirm = false;
                            this.inaccessible_quest = true;
                        }
                    });
            },
            onSubmit() {
                if (this.auth.verified) {
                    if (this.quest.pro && !this.auth.pro) {
                        this.pro_quest = true;
                    } else if (this.quest.discord && Number(this.quest.discord) > Number(this.auth.discord_lvl)) {
                        this.discord_quest = true;
                    } else {
                        this.open_confirm = true;
                    }
                } else {
                    this.inaccessible_quest = true;
                }
            },

            date(date) {
                return moment.utc(date).format('X')
            },
            scrollUser: function () {
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);
                const progress =
                    document.documentElement.scrollTop /
                    (document.documentElement.scrollHeight -
                        document.documentElement.clientHeight);
                if (!this.loadingPage && this.next_page_url) {
                    let bottomOfWindow = 0.7 <= progress;
                    if (bottomOfWindow) {
                        this.loadingPage = true;
                        // this.getNews(this.response, this.next_page_url);
                        this.$http
                            .get(this.next_page_url)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                if(this.$checkAuth(response)) {
                                    let users = this.users;
                                    this.users = users.concat(response.data.users.data);
                                    this.next_page_url = response.data.users.next_page_url;
                                    this.loadingPage = false;
                                    clearInterval(timeout);
                                    this.progress = 100;
                                    setTimeout(() => {
                                        this.progress = 0;
                                    }, 500);
                                }
                            })
                            .catch((err) => {
                                this.httpHandler(err);
                                clearInterval(timeout);
                                this.loadingPage = false;
                            });
                    }
                }
            },
            createdComponent(id) {
                let timeout = setInterval(() => {
                    this.progress += 1;
                    if(this.progress > 50) {
                        clearInterval(timeout)
                    }
                },15);
                this.$http.get("ajax/quest/page/" + id)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            this.quest = response.data.quest;
                            this.users = response.data.users.data;
                            this.next_page_url = response.data.users.next_page_url;
                            clearInterval(timeout);
                            this.progress = 100;
                            setTimeout(() => {
                                this.progress = 0;
                            }, 500);
                        }
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
            }
        },
        props: {

        },
        components: {
            ModalConfirmQuest,
            ModalInaccessibleQuest,
            ModalProQuest,
            ModalDiscordQuest,
            BaseButton,
            ItemQuestUser,
            LayoutNavAuth
        }
    }
</script>

<style lang="scss" scoped>
    .block-name {
        display: block;
        text-align: center;
        text-overflow: ellipsis;
        z-index: 1;
        font-size: 13px;
        line-height: 18px;
        color: #002B50;
        padding: 4px 8px;
        background: rgb(255, 255, 255);
        border: 1px solid #ECF4FF;
        border-radius: 6px;
        max-width: 110px;
        overflow: hidden;
        /*white-space: nowrap;*/
    }

    .block-one-user {
        min-width: 250px;
        max-width: 250px;
        //background: #FAFBFF;
        padding:0 20px 0 10px;
        border-radius:0 8px 8px 0;

        a {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            letter-spacing: 0.4px;
            color: #002B50;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                color: #26A9F4;
            }
        }

        .v-list {
            padding: 0;
            //background: #FAFBFF;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .v-list-item {
            padding: 0;
            /*padding: 21px 10px 21px 0;*/

            &__content {
                margin-left: 10px;

            }

            &__title {
                /*max-width: 125px;*/
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.1px;
                /*color: #FF9500;*/
                /*display: block;*/
                /*display: flex;*/
                /*align-items: center;*/
                /*justify-content: flex-end;*/
                margin-bottom: 6px;
                /*text-align: right;*/
                width: 140px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

            }

            &__subtitle {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #96AFD1;
                width: 170px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                .rating {
                    color: #F6A401;
                    margin-left: 8px;
                }

                .pic {
                    height: 12px;
                    width: 11px;
                    margin-right: 1px;
                }

            }
            a.img {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }

            .wrap-img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                position: relative;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
                .photo {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    color: #FFFFFF;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                .online {
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 1px;
                    bottom: 2px;
                    border: 1px solid #fff;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    background-color: #00c569;
                }
            }
        }
    }

</style>