<template>
    <div class="wrap-social-icon">
        <a :href="social.url" target="_blank">
            <img :src="social.icon" alt="">
        </a>
    </div>
</template>

<script>
    // import DropdownDefault from "../../modules/DropdownDefault";
    export default {
        name: 'SocialIcon',
        data() {
            return {
                social:this.$cleanObject(this.$filterArray(this.$socials, this.index, 'name')),
            }
        },
        props: {
            social_name: {
                type: Object
            },
            value: {
                type: String
            },
            index: {
                type: String
            },
        },

        components: {
            // DropdownDefault
        }
    }
</script>

<style lang="scss" scoped>
    .wrap-social-icon{
        a {

            img {
                transition-duration: .3s;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                    transition-duration: .3s;
                }

            }
        }

    }

</style>