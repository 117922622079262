<template>
    <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:inaccessible_tournament', false)">
        <div class="modal-inaccessible_tournament">

            <v-btn text icon class="icon ma-0" @click="cancelModal">
                <img src="/dist/images/frontend/close.svg" alt="/" />
            </v-btn>

            <div class="modal-inaccessible_tournament__body">
                <div class="block-requirements">
                    <p class="block-requirements__title">{{__('Requirements for participation in the tournament:')}}</p>

                    <div class="list-requirements">
                        <div class="list-requirements__item">
                            <p>{{__('Verification')}}</p>

                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 9.375L7.5 11.625L12.75 6.375" :stroke="(!auth || !tournament.verification || (tournament.verification && info.verification)) ? '#01DF72' : '#FF4B55'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9 16.5C13.1422 16.5 16.5 13.1422 16.5 9C16.5 4.85775 13.1422 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1422 4.85775 16.5 9 16.5Z" :stroke="(!auth || !tournament.verification || (tournament.verification && info.verification)) ? '#01DF72' : '#FF4B55'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div v-if="tournament.pro" class="list-requirements__item">
                            <p>{{__('Pro account')}}</p>

                            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.25 9.375L7.5 11.625L12.75 6.375" :stroke="(!auth || !tournament.pro || (tournament.pro && info.pro)) ? '#01DF72' : '#FF4B55'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M9 16.5C13.1422 16.5 16.5 13.1422 16.5 9C16.5 4.85775 13.1422 1.5 9 1.5C4.85775 1.5 1.5 4.85775 1.5 9C1.5 13.1422 4.85775 16.5 9 16.5Z" :stroke="(!auth || !tournament.pro || (tournament.pro && info.pro)) ? '#01DF72' : '#FF4B55'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div class="list-requirements__item">
                            <p>{{__('Number of games in 10 days')}}</p>

                            <span :style="(!auth || (+info.count_game >= +tournament.count_game)) ? {'color': '#01DF72'} : {'color': '#FF4B55'}">{{info.count_game}}/{{tournament.count_game}}</span>
                        </div>

                        <div class="list-requirements__item">
                            <p>{{__('Number of opponents in 10 days')}}</p>

                            <span :style="(!auth || (+info.count_opponent >= +tournament.count_opponent)) ? {'color': '#01DF72'} : {'color': '#FF4B55'}">{{info.count_opponent}}/{{tournament.count_opponent}}</span>
                        </div>

                        <div class="list-requirements__item">
                            <p>{{__('Min balance')}} {{tournament.wallet == 1 ? 'USDT' : 'MIND'}}</p>

                            <span :style="(!auth || Number(this.primNum(info.balance, 2)) >= Number(this.primNum(tournament.min_balance, 2))) ? {'color': '#01DF72'} : {'color': '#FF4B55'}">{{primNum(tournament.min_balance, 2)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrap-btn">
                <BaseButton
                    @click="cancelModal"
                    class_btn="btn-guest"
                >
                    <span>{{__('Ok')}}</span>
                </BaseButton>
            </div>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        name: "ModalInaccessibleTournament",
        data() {
            return {
                modal: true,
            };
        },
        props: {
            inaccessible_tournament: {
                type: Boolean,
            },
            tournament: {
                type: Object
            },
            info: {
                type: Object
            }
        },
        watch: {
            modal() {
                this.cancelModal();
            },
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            }
        },

        methods: {
            cancelModal() {
                this.$emit("update:inaccessible_tournament", false);
            },
            onSubmit() {
                this.$emit("update:inaccessible_tournament", false);
            }
        },
        components: {},
    };
</script>

<style lang='scss' scoped>
    .modal-inaccessible_tournament {
        text-align: center;
        padding: 40px 25px;
        background: #171827;
        box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position: relative;

        .icon {
            position: absolute;
            top: 4px;
            right: 4px;
            outline: none;

            img {
                height: 12px;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            .block-requirements {
                width: 100%;

                &__title {
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 20px;
                }

                .list-requirements {

                    &__item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 6px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        p {
                            color: #FFF;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: normal;
                            margin: 0;
                        }

                        span {
                            color: #01DF72;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }
            }
        }

        .wrap-btn {
            width: 50%;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 500px) {
        .modal-inaccessible_tournament {
            padding: 30px 20px;

            &__body {
                margin-bottom: 20px;
            }
        }
    }

</style>