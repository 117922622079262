<template>
    <div v-if="show" ref="item_broadcast_message" class="item-broadcast-message">
        <div class="block-user">
            <div class="wrap-img" :class="(item.user.pro) ? 'user-pro-img' : ''">
                <img class="img" v-if="item.user.photo && typeof item.user.photo == 'object'" :src="item.user.photo[40]">
                <img class="img"  v-else-if="item.user.photo" :src="item.user.photo">
                <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
                <div v-if="now_date < date" class="online"></div>
            </div>

<!--            <div class="block-name" :class="(item.user.pro) ? 'user-pro' : ''">-->
<!--                <p>{{item.user.name+" "+((item.user.last_name) ? item.user.last_name : '')}}</p>-->
<!--                <span>:</span>-->
<!--            </div>-->
        </div>

        <div class="block-mess">
            <p v-html="highlightedText"></p>

            <span>{{item.created_at | moment('HH:mm')}}</span>
        </div>
    </div>
</template>

<script>

    import moment from "moment";
    import {emojis} from "vue-chat-emoji";

    export default {
        name: "ItemBroadcastMessage",
        data() {
            return {
                now_date: moment.utc().format('X'),
                date: (this.item.user && this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
                date_create: (this.item && this.item.created_at) ? moment.utc(this.item.created_at).format('X') : null,
                highlightedText: '',
                regex: /@(\w+)/g,
                interval: null,
                show: false,
            }
        },

        props: {
            item: {
                type: Object
            }
        },
        watch: {

        },

        created() {
            if ((this.now_date - this.date_create) > 5) {
                this.show = false;
            } else {
                this.show = true;
            }
        },
        mounted() {
            if ((this.now_date - this.date_create) < 5) {
                this.interval = setInterval(() => {
                    let date = moment.utc().format('X');
                    if ((date - this.date_create) > 5) {
                        clearInterval(this.interval);
                        this.$refs.item_broadcast_message.classList.add('hide');
                    }
                }, 1000);
            }
          this.highlightText();
        },
        beforeDestroy() {

        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            }
        },
        methods: {
            highlightText() {
                this.highlightedText = emojis.decodeEmoji(this.item.message).replace(
                    this.regex,
                    '<span style="color: #00AEFF">$&</span>'
                );
            },
        },
        components: {

        },
    };
</script>

<style lang="scss" scoped>

    .item-broadcast-message.hide {
        opacity: 0;
        transition-duration: .5s;
    }

    .item-broadcast-message {
        display: flex;
        align-items: center;
        height: 32px;
        margin-bottom: 6px;
        padding: 0 8px;
        border-radius: 12px;
        background: #E7EFFF;
        width: fit-content;
        transition-duration: .5s;

        &:last-child {
            margin-bottom: 4px;
        }


        .block-user {
            display: flex;
            align-items: center;

            .wrap-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                margin-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                .img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }

                .photo {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    color: #FFFFFF;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .online {
                    border-radius: 50%;
                    background-color: #00c569;
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    border: 1px solid #E7EFFF;
                }
            }
            .block-name {
                display: flex;
                align-items: center;
                width: fit-content;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-transform: capitalize;
                color: #00AEFF;
                margin-right: 4px;


                p {
                    margin: 0;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: default;
                }

                &:hover {
                    color: #00AEFF;
                }
            }

            .block-name.user-pro {
                background: linear-gradient(90.56deg, #01DF72 0.33%, #00AEFF 104.71%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;

                &:hover {
                    background: linear-gradient(90.56deg, #01DF72 0.33%, #00AEFF 104.71%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    -webkit-text-fill-decoration: underline;
                }
            }
        }

        .block-mess {
            display: flex;
            align-items: flex-end;

            p {
                color: #011627;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                margin: 0 4px 0 0;
                width: fit-content;
                word-break: break-all;
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            span {
                display: block;
                color: #96AFD1;
                font-size: 10px;
                font-weight: 500;
                line-height: 18px;
            }

        }
    }

    @media screen and (max-width: 500px){
        .item-broadcast-message {
            .block-mess {
                p {
                    max-width: 180px;
                }
            }
        }
    }
</style>