<template>
  <div>
    <slot v-bind="payment"></slot>
  </div>
</template>

<script>
    export default {
        name: 'Payment',

        data: function() {
            return {

            }
        },
        props: {
            payments: {
                type: Array
            },
            payment_id: {
                type: [
                    Number, String
                ]
            }
        },
        computed: {
            payment() {
                return this.$filterArray(this.payments, this.payment_id, 'id');
            }
        }
    }
</script>

<!--&lt;!&ndash; Add "scoped" attribute to limit CSS to this component only &ndash;&gt;-->
<style scoped>
</style>
