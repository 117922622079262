<template>
  <div class="block-moderation-item">

    <span class="icon">
         <img src="/dist/images/frontend/information-describe-icon.svg" alt />
      </span>

    <div class="wrap-moderation-item">
      <div class="wrap-moderation-item__info">
        <p v-if="item.type==0">{{__('Moderation was successful')}}</p>
        <p v-else-if="item.type==1">{{__('Moderation request')}}</p>
        <p v-else-if="item.type==2">{{__('Moderation rejected')}}</p>
        <p v-else>{{__('Moderation canceled')}}</p>
        <div class="d-flex right">
          <div class="wrap-status">
            <span v-if="item.type==0" class="block-published">
                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="3" cy="3" r="3" fill="#00C443"/>
                </svg>
                {{__('Published')}}
            </span>

            <span v-else-if="item.type==1" class="block-consideration">
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#26A9F4"/>
              </svg>
              {{__('Under consideration')}}
            </span>

            <span v-else  class="block-rejected">
              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="3" cy="3" r="3" fill="#FF4B55"/>
              </svg>
              {{__('Rejected')}}
            </span>
          </div>

          <span class="data">{{new Date(item.created_at) | formatFullDate}}</span>
        </div>
      </div>

      <p v-if="item.text" class="block-desc">{{item.text}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModerationItemList",
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {},
  computed: {},
  components: {},
};
</script>


<style lang="scss" scoped>

  .dark {
    .block-moderation-item {

      .icon {
        img {
          filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
        }
      }

      .wrap-moderation-item {
        border-bottom: 1px solid rgba(255, 255, 255, 0.07);

        &__info {
          p {
            color: #96AFD1;
          }

          .data {
            color: #96AFD1;
          }
        }
      }
    }
  }

  .block-moderation-item {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .icon {
      margin:21px 33px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .wrap-moderation-item {
      width: 100%;
      padding: 18px 0;
      border-bottom: 1px solid #DAE6F1;
      box-sizing: border-box;

      &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.01em;
          margin: 0;
        }

        .data {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.01em;
          margin-left: 25px;
          min-width: 115px;
          text-align: right;
        }

        .wrap-status {
          min-width: 150px;
          display: flex;
          justify-content: flex-end;
        }

        .block-published {
          display: flex;
          align-items: center;
          padding: 3px 15px 3px 10px;
          background: #CDFFCD;
          border-radius: 10px;
          color: #00C443;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          svg {
            margin-right: 5px;
          }
        }

        .block-consideration {
          display: flex;
          align-items: center;
          padding: 3px 15px 3px 10px;
          background: rgba(38, 169, 244, 0.1);
          border-radius: 10px;
          color: #26A9F4;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          svg {
            margin-right: 5px;
          }
        }

        .block-rejected {
          display: flex;
          align-items: center;
          padding: 3px 15px 3px 10px;
          background: rgba(255, 75, 85, 0.1);
          border-radius: 10px;
          color: #FF4B55;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

          svg {
            margin-right: 5px;
          }
        }
      }
      .block-desc {
        width: 90%;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #6886AF;
        margin: 12px 0 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .block-moderation-item {
      .icon {
        margin-right: 20px;
      }
    }
  }

  @media screen and (max-width: 600px) {

    .block-moderation-item {
      .icon {
        display: none;
      }

      .wrap-moderation-item {
        padding: 14px 0;

        &__info {
          p {
            display: none;
          }

          .wrap-status {
            justify-content: flex-start;
          }

          .right {
            width: 100%;
            justify-content: space-between;
          }

          .data {
            font-size: 12px;
            line-height: 17px;
          }
        }
      }
    }
  }

</style>