<template>
    <div>
        <v-dialog v-model="dialog" max-width="320px">
            <div class="wrap-sorting-affiliate">
                <div class="wrap-sorting-head">
                    <div class="wrap-sorting-head__icon">
                        <img src="/dist/images/frontend/filter-icon.svg" alt />
                    </div>
                    <h5>{{__('Filters')}}</h5>
                </div>

                <div class="wrap-sorting-affiliate__body">
                    <div class="region">
                        <div class="region__icon">
                            <img src="/dist/images/frontend/location-icon.svg" alt />
                        </div>
                        <div class="region__input">
                            <DropdownCountry
                                    :label='__("Country")'
                                    v-bind:value.sync="sorting.location"
                                    @change="updateValidate('location')"
                                    v-bind:errors="formValidate.location"
                                    @updateCountry="updateCountry"
                            ></DropdownCountry>
                        </div>
                    </div>

<!--                    <div class="level">-->
<!--                        <div class="level__head">-->
<!--                            <div class="level__icon">-->
<!--                                <img src="/dist/images/frontend/level-icon.svg" alt />-->
<!--                            </div>-->
<!--                            <p>{{__('Level')}}</p>-->
<!--                        </div>-->
<!--                        <v-radio-group row class="wrap-radio">-->
<!--                            <v-checkbox v-model="sorting.level" color="blue" value="1" :label="__('1 lvl')"></v-checkbox>-->
<!--                            <v-checkbox v-model="sorting.level" color="blue" value="2" :label="__('2 lvl')"></v-checkbox>-->
<!--                        </v-radio-group>-->
<!--                    </div>-->

                    <div class="level">
                        <div class="level__head">
                            <div class="level__icon">
                                <img src="/dist/images/frontend/players-developer-icon.svg" alt />
                            </div>
                            <p>{{__('Referrals')}}</p>
                        </div>
                        <v-radio-group row class="wrap-radio">
                            <v-checkbox v-model="sorting.active" color="blue" value="1" :label="__('Active')"></v-checkbox>
                            <v-checkbox v-model="sorting.active" color="blue" value="2" :label="__('Inactive')"></v-checkbox>
                        </v-radio-group>
                    </div>
                    <div class="wrap-btn">

                        <div style="width: 48%">
                            <BaseButton
                                    :loading="submitDisabled"
                                    class_btn="btn-liteGrey"
                                    v-on:click="cancelFilters"
                            >
                                <span>{{__('Clear')}}</span>
                            </BaseButton>
                        </div>

                        <div style="width: 48%">
                            <BaseButton
                                    :loading="submitDisabled"
                                    v-on:click="onSubmit"
                            >
                                <span>{{__('Apply')}}</span>
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    // import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import DropdownCountry from "../../modules/DropdownCountry";
    // import { maxLength, minLength, numeric } from "vuelidate/lib/validators";

    export default {
        data() {
            return {
                dialog: true,
                submitDisabled: false,
                sorting: {
                    location: this.sorting_referrals.location ? this.sorting_referrals.location : null,
                    // level: this.sorting_referrals.level ? this.sorting_referrals.level : null,
                    active: this.sorting_referrals.active ? this.sorting_referrals.active : null,
                },

                formValidate: {
                    location: null,
                    // level: null,
                    active: null,
                },
            };
        },
        validations: {
            sorting: {

            }
        },
        props: {
            color: {
                type: String,
            },
            modal_sorting: {
                type: Boolean,
            },
            sorting_referrals: {
                type: Object,
            },
            filter: {
                type: Array,
            },
            search: {
                type: String
            }
        },
        watch: {
            dialog() {
                this.$emit("update:modal_sorting", false);
            },
        },
        methods: {
            updateValidate: function (type) {
                this.formValidate = this.$changeValidate(
                    this.formValidate,
                    this.$v.sorting[type],
                    type
                );
            },
            updateCountry(val) {
                this.sorting.location = val;
            },
            cancelFilters: function () {

                let form = {
                    location: null,
                    // level: null,
                    active: null,
                };

                this.$emit('update:filter', []);
                // this.$emit('update:sorting_referrals', form);
                this.$emit('filterReferrals', form);
                this.$emit('update:modal_sorting', false);
            },
            onSubmit: function () {
                let valid = this.$v.sorting;

                if (valid.$invalid) {
                    let massages = {
                        location: [],
                        // level: [],
                        active: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);
                } else {
                    let count = [];
                    Object.keys(this.sorting).forEach((key) => {
                        if(this.sorting[key] && this.sorting[key].length) {
                            count.push(this.sorting[key]);
                        }
                    });
                    this.$emit('update:filter', count);
                    let sorting= this.sorting;
                    this.submitDisabled = true;
                    setTimeout(() =>{
                        this.submitDisabled = false;
                    },500);
                    // this.$emit('update:sorting_referrals', sorting);
                    this.$emit('filterReferrals', sorting);
                    this.$emit('update:modal_sorting', false);
                }
            },
        },
        components: {
            // InputLabelTextDefault,
            DropdownCountry,
            // DropdownDefault,
        },
    };
</script>


<style lang="scss">

    .dark {
        .wrap-sorting-affiliate {
            .wrap-sorting-head {
                background: #171827;
                border-bottom: 1px solid #2b2b2b;

                &__icon {
                    img {
                        filter: invert(0) sepia(100%) saturate(0) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                h5 {
                    color: #fff;
                }
            }

            &__body {
                background-color: #171827;

                .region__icon,
                .level__icon {
                    img {
                        filter: invert(0) sepia(100%) saturate(0) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                .level {
                    p {
                        color: #fff;
                    }
                }
            }
        }
    }

    .wrap-sorting-affiliate {
        width: 320px;

        .wrap-sorting-head {
            padding: 20px;
            display: flex;
            align-items: center;
            //box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
            border-radius: 4px 4px 0px 0px;
            border-bottom: 2px solid #ECF4FF;
            background: #fff;

            &__icon {
                margin-right: 17px;
                display: flex;
                align-items: center;
            }

            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }

        &__body {
            padding: 20px 20px 22px;
            background-color: #fff;
            border-radius: 0 0 4px 4px;

            .region {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                position: relative;

                &__icon {
                    margin: 0 16px 2px 0;
                }

                &__input {
                    width: 100%;
                }
                .v-input .v-label {
                    font-size: 16px;
                }
            }

            .level {
                margin-bottom: 5px;

                &__head {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;

                }

                &__icon {
                    margin: 0 16px 2px 0;
                }

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #6886AF;
                    margin-bottom: 0;
                }

                .wrap-radio {
                    margin-left: 35px;
                    margin-top: 0;
                    padding-top: 0;

                    &.v-input--radio-group.v-input--radio-group--row .v-radio {
                        margin-right: 20px;
                    }

                    .v-input {
                        margin: 5px 20px 5px 0;
                    }
                }
            }
            .wrap-btn {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .v-btn {
                    width: 48%;
                    margin: 0 auto;
                    display: block;

                    &.v-btn:not(.v-btn--round).v-size--default {
                        height: 40px;
                        min-width: 100px;
                        width: 48%;
                        padding: 0;

                    }
                }

                .btn-secondary {
                    margin-right: 15px;
                }

            }
        }
    }

    /*@media screen and (max-width: 1350px) {
        .wrap-sorting-affiliate {

            &--fixed {
                max-width: 280px;
            }
            width: 280px;
        }
    }

    @media screen and (max-width: 920px) {

        .wrap-sorting {
            .sort,
            .region,
            .age {
                margin-bottom: 10px;

                p {
                    margin-bottom: 5px;
                }
            }

            .male {
                p {
                    margin-bottom: 5px;
                }
            }
        }
    }

    @media screen and (max-width: 525px) {

        .wrap-sorting {
            .sort,
            .region,
            .age {
                margin-bottom: 6px;
            }
            .male {
            }

            .btn-cab {
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 30px;
                    min-width: 64px;
                    width: 100px;
                    padding: 0;
                    margin: 0 auto;
                    font-size: 10px;
                }
            }
            v-input--radio-group.v-input--radio-group--row .v-radio {
                margin-right: 0;
                margin-bottom: 0px;
            }
            .v-label {
                font-size: 12px;
                margin-left: -8px;
            }
        }
    }*/


</style>