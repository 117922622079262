<template>
    <div class="list-attributes">
        <div class="item-attributes">
            <div class="block-info">
                <p>{{item.name}}</p>
<!--                <img  src="/dist/images/frontend/information.svg" alt />-->
<!--                <div v-if="option">-->
<!--                    {{option.description}}-->
<!--                </div>-->
<!--                <v-tooltip-->
<!--                        v-model="show"-->
<!--                        top-->
<!--                >-->
<!--                    <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-btn-->
<!--                                class="btn-link"-->
<!--                                icon-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                        >-->
<!--                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M9.96113 7.3889C10.3907 7.3889 10.7389 7.04068 10.7389 6.61112C10.7389 6.18157 10.3907 5.83334 9.96113 5.83334C9.53157 5.83334 9.18335 6.18157 9.18335 6.61112C9.18335 7.04068 9.53157 7.3889 9.96113 7.3889Z" fill="currentColor"/>-->
<!--                                <path d="M11.6667 12.7778H10.5556V8.33334H8.88894C8.7416 8.33334 8.60029 8.39188 8.49611 8.49606C8.39192 8.60025 8.33339 8.74156 8.33339 8.8889C8.33339 9.03624 8.39192 9.17755 8.49611 9.28174C8.60029 9.38592 8.7416 9.44445 8.88894 9.44445H9.4445V12.7778H8.33339C8.18605 12.7778 8.04474 12.8363 7.94055 12.9405C7.83636 13.0447 7.77783 13.186 7.77783 13.3333C7.77783 13.4807 7.83636 13.622 7.94055 13.7262C8.04474 13.8304 8.18605 13.8889 8.33339 13.8889H11.6667C11.8141 13.8889 11.9554 13.8304 12.0596 13.7262C12.1637 13.622 12.2223 13.4807 12.2223 13.3333C12.2223 13.186 12.1637 13.0447 12.0596 12.9405C11.9554 12.8363 11.8141 12.7778 11.6667 12.7778Z" fill="currentColor"/>-->
<!--                                <path d="M9.99992 3.33334C8.68138 3.33334 7.39245 3.72434 6.29612 4.45688C5.19979 5.18942 4.34531 6.23061 3.84072 7.44879C3.33614 8.66696 3.20412 10.0074 3.46135 11.3006C3.71859 12.5938 4.35353 13.7817 5.28588 14.7141C6.21823 15.6464 7.40611 16.2813 8.69932 16.5386C9.99253 16.7958 11.333 16.6638 12.5511 16.1592C13.7693 15.6546 14.8105 14.8001 15.5431 13.7038C16.2756 12.6075 16.6666 11.3186 16.6666 10C16.6666 8.2319 15.9642 6.53621 14.714 5.28597C13.4637 4.03572 11.768 3.33334 9.99992 3.33334ZM9.99992 15.5556C8.90114 15.5556 7.82703 15.2297 6.91342 14.6193C5.99981 14.0088 5.28774 13.1412 4.86726 12.126C4.44677 11.1109 4.33675 9.99385 4.55111 8.91617C4.76548 7.8385 5.29459 6.8486 6.07155 6.07164C6.84851 5.29468 7.83841 4.76557 8.91609 4.5512C9.99376 4.33684 11.1108 4.44686 12.1259 4.86735C13.1411 5.28783 14.0087 5.9999 14.6192 6.91351C15.2296 7.82712 15.5555 8.90122 15.5555 10C15.5555 10.7296 15.4118 11.452 15.1326 12.126C14.8534 12.8001 14.4442 13.4125 13.9283 13.9284C13.4124 14.4443 12.8 14.8535 12.1259 15.1327C11.4519 15.4119 10.7295 15.5556 9.99992 15.5556Z" fill="currentColor"/>-->
<!--                            </svg>-->
<!--                        </v-btn>-->
<!--                    </template>-->
<!--                    <p v-if="option">-->
<!--                       {{option.description}}-->
<!--                    </p>-->
<!--                </v-tooltip>-->
<!--                <div class="wrap-btn">-->

<!--                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M9.96113 7.3889C10.3907 7.3889 10.7389 7.04068 10.7389 6.61112C10.7389 6.18157 10.3907 5.83334 9.96113 5.83334C9.53157 5.83334 9.18335 6.18157 9.18335 6.61112C9.18335 7.04068 9.53157 7.3889 9.96113 7.3889Z" fill="currentColor"/>-->
<!--                        <path d="M11.6667 12.7778H10.5556V8.33334H8.88894C8.7416 8.33334 8.60029 8.39188 8.49611 8.49606C8.39192 8.60025 8.33339 8.74156 8.33339 8.8889C8.33339 9.03624 8.39192 9.17755 8.49611 9.28174C8.60029 9.38592 8.7416 9.44445 8.88894 9.44445H9.4445V12.7778H8.33339C8.18605 12.7778 8.04474 12.8363 7.94055 12.9405C7.83636 13.0447 7.77783 13.186 7.77783 13.3333C7.77783 13.4807 7.83636 13.622 7.94055 13.7262C8.04474 13.8304 8.18605 13.8889 8.33339 13.8889H11.6667C11.8141 13.8889 11.9554 13.8304 12.0596 13.7262C12.1637 13.622 12.2223 13.4807 12.2223 13.3333C12.2223 13.186 12.1637 13.0447 12.0596 12.9405C11.9554 12.8363 11.8141 12.7778 11.6667 12.7778Z" fill="currentColor"/>-->
<!--                        <path d="M9.99992 3.33334C8.68138 3.33334 7.39245 3.72434 6.29612 4.45688C5.19979 5.18942 4.34531 6.23061 3.84072 7.44879C3.33614 8.66696 3.20412 10.0074 3.46135 11.3006C3.71859 12.5938 4.35353 13.7817 5.28588 14.7141C6.21823 15.6464 7.40611 16.2813 8.69932 16.5386C9.99253 16.7958 11.333 16.6638 12.5511 16.1592C13.7693 15.6546 14.8105 14.8001 15.5431 13.7038C16.2756 12.6075 16.6666 11.3186 16.6666 10C16.6666 8.2319 15.9642 6.53621 14.714 5.28597C13.4637 4.03572 11.768 3.33334 9.99992 3.33334ZM9.99992 15.5556C8.90114 15.5556 7.82703 15.2297 6.91342 14.6193C5.99981 14.0088 5.28774 13.1412 4.86726 12.126C4.44677 11.1109 4.33675 9.99385 4.55111 8.91617C4.76548 7.8385 5.29459 6.8486 6.07155 6.07164C6.84851 5.29468 7.83841 4.76557 8.91609 4.5512C9.99376 4.33684 11.1108 4.44686 12.1259 4.86735C13.1411 5.28783 14.0087 5.9999 14.6192 6.91351C15.2296 7.82712 15.5555 8.90122 15.5555 10C15.5555 10.7296 15.4118 11.452 15.1326 12.126C14.8534 12.8001 14.4442 13.4125 13.9283 13.9284C13.4124 14.4443 12.8 14.8535 12.1259 15.1327C11.4519 15.4119 10.7295 15.5556 9.99992 15.5556Z" fill="currentColor"/>-->
<!--                    </svg>-->

<!--                    <div v-if="option" class="block-info-option">-->
<!--&lt;!&ndash;                        <img class="avatar" :src="'/assets/games/options/'+((option.img) ? option.img : 'default.svg')"/>&ndash;&gt;-->
<!--                        <img class="avatar" :src="option.img"/>-->
<!--                        <span>{{option.description}}</span>-->
<!--                    </div>-->

<!--                </div>-->


            </div>
            <div class="block-select">
                <DropdownDefault
                        style="width: 100%"
                        color="select_color"
                        v-bind:dropdown="(empty) ? valuse : item.options"
                        value_key="key"
                        title_key="name"
                        v-bind:value.sync="option"
                        type="array"
                        @changeOption = "updateAttribute()"
                >
                    <template v-slot="test">
                        <div class="select">
                            {{test.active_value.name}}
                            <img class="chevron-icon" :class="((test.value_menu) ? 'active' : '')" src="/dist/images/frontend/chevron-down.svg" alt />
                        </div>

                    </template>
                    <template v-slot:option="item">
                        {{item.name}}
                    </template>
                </DropdownDefault>
            </div>
        </div>
    </div>
</template>

<script>

import DropdownDefault from '../../modules/DropdownDefault'

    export default {
        name: 'ItemSelectAttributes',
        data () {
            return {
                show: false,
                test: null,
                option: null,
            }
        },
        methods: {
            updateAttribute() {
                // let newAttributes = {
                //     attributes: this.item.key,
                //     options:this.option.key
                // }
                let option= {
                    "key": this.item.key,
                    "option": this.option.key
                };
                this.$emit('updateAttribute', option);
            }
        },
        mounted() {
            if(this.form && this.form.attributes.length) {
                let attributes= this.$cleanObject(this.form.attributes);

                let index= this.$filterIndexObject(attributes, this.item.key, 'key');
                if(index >= 0) {
                    let active= this.form.attributes[index];
                    let indexOption= this.$filterIndexObject(this.item.options, active.option,'key');
                    this.option= this.item.options[indexOption];
                } else {
                    this.updateAttribute();
                }
            } else if(this.sorting && this.sorting.attr.length) {
                let attributes= this.$cleanObject(this.sorting.attr);

                let index= this.$filterIndexObject(attributes, this.item.key, 'key');
                if(index >= 0) {
                    let active= this.sorting.attr[index];
                    let indexOption= this.$filterIndexObject(this.item.options, active.option,'key');
                    this.option= this.item.options[indexOption];
                } else {
                    this.updateAttribute();
                }
            } else {
                this.updateAttribute();
            }
        },
        computed: {
            valuse() {
                let items= [
                    {
                        'name': 'All',
                        'key': null,
                    }
                ]

                let options= this.$cleanObject(this.item.options);

                return items.concat(options);
            }
        },
        props: {
            item: {
                type: Object,
            },
            form: {
                type: Object,
            },
            empty: {
                type: Boolean,
                default: false
            },
            sorting: {
                type: Object,
            }
        },
        components: {
            DropdownDefault
        }
    }
</script>

<style lang="scss" scoped>

    .dark {
        .item-attributes {
            .block-info {
                margin-right: 16px;
                p {
                    color: #96AFD1;
                }
            }
        }
    }
    .item-attributes {
        display: flex;
        justify-content: space-between;

        .block-info {
            position: relative;
            display: flex;
            align-items: center;
            margin-top: 9px;


            p {
                margin: 0 4px 0 0;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                color: #6886AF;
                text-transform: capitalize;
                cursor: default;
            }


            .wrap-btn {
                margin:0 12px 0 4px;
                display: flex;
                align-items: center;

                svg {
                    color: #6886AF;
                    transition-duration: .3s;

                    &:hover {
                        color: #26A9F4;
                        transition-duration: .3s;
                    }
                }

                &:hover {

                    .block-info-option {
                        opacity: 1;
                        display: flex;
                        transition-duration: .5s;
                        visibility: visible;
                    }
                }
            }



            .block-info-option {
                position: absolute;
                top: 27px;
                left: -15px;
                opacity: 0;
                display: flex;
                visibility: hidden;
                max-height: 110px;
                transition-duration: .3s;
                width: 380px;
                /*width: 100%;*/
                z-index: 10;
                padding: 10px;
                background-color: #FFFFFF;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
                border-radius: 8px;


                img {
                    width: 60px;
                    height: 60px;
                    border-radius: 6px;
                    margin-right: 9px;
                }

                span {
                    /*max-width: 200px;*/
                    width: 100%;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #6886AF;
                }
            }
        }

        .block-select {
            max-width: 230px;
            width: 100%;

            .select {
                padding: 0 4px 0 0;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;
                letter-spacing: 0.4px;
                /*color: #002B50;*/
                margin-top: 12px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    transition-duration: .3s;

                    &.active {
                        transform: rotate(180deg);
                        transition-duration: .3s;
                    }
                }


            }
        }
    }
</style>