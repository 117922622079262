<template>
  <v-dialog v-model="modal" max-width="400" v-on:click:outside="$emit('update:result', false)">
<!--    <div class="head-modal">-->
<!--      <h4>Game deletion confirmation</h4>-->
<!--      <v-btn text icon class="icon" v-on:click="$emit('update:result', false)">-->
<!--        <img src="/dist/images/frontend/close.svg" alt="/" />-->
<!--      </v-btn>-->
<!--    </div>-->

    <div class="modal-confirm">
      <img src="/dist/images/frontend/delete-game-icon.svg" alt />
      <p>{{__('Are you sure you want to delete this game?')}}</p>
      <div
        class="image"
        :style="{background: 'url('+(developers.logo)+') no-repeat', backgroundSize: 'cover'}"
      ></div>
      <span class="game-name">{{developers.name}}</span>
      <div class="wrap-btn">
<!--        <v-btn-->

<!--          text-->
<!--          class="btn-secondary"-->
<!--          v-on:click="$emit('update:result', false)"-->
<!--        >{{__('Cancel')}}</v-btn>-->

        <div style="width: 48%">
          <BaseButton
                  class_btn="btn-liteGrey"
                  v-on:click="$emit('update:result', false)"
          >
            <span>{{__('Cancel')}}</span>
          </BaseButton>
        </div>
<!--        <v-btn-->
<!--          text-->
<!--          class=" btn-delete white&#45;&#45;text"-->
<!--          v-on:click="$emit('update:result', true)"-->
<!--        >{{__('Delete game')}}</v-btn>-->

        <div style="width: 48%">
          <BaseButton
                  class_btn="redBtn"
                  v-on:click="$emit('update:result', true)"
          >
            <span>{{__('Delete game')}}</span>
          </BaseButton>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmActionDefault",
  data() {
    return {
      modal: this.open,
    };
  },
  props: {
    open: {
      type: Boolean,
    },
    text: {
      type: String,
    },
    result: {
      type: Boolean,
    },
    developers: {
      type: Object,
    },
  },
  computed: {},
  methods: {},
  components: {},
};
</script>

<style lang='scss' scoped>
.model_wrapper.v-dialog {
  overflow: unset;
  position: relative;
}

/*.head-modal {*/
/*  background: #F0F6FE;*/
/*  padding: 20px;*/
/*  position: relative;*/

/*  h4 {*/
/*    font-weight: 400;*/
/*    font-size: 18px;*/
/*    line-height: 24px;*/
/*    color: #002b50;*/
/*  }*/

/*  .icon {*/
/*    position: absolute;*/
/*    top: 12px;*/
/*    right: 6px;*/
/*    outline: none;*/

/*    &:hover {*/
/*      img {*/
/*        transform: scale(1.1);*/
/*      }*/
/*    }*/
/*  }*/
/*}*/

.modal-confirm {
  text-align: center;
  padding: 50px 25px 30px;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;

  img {
    margin-bottom: 8px;
  }

  p {
    max-width: 280px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #002B50;
    margin: 0 auto 32px;
  }

  .image {
    min-height: 48px;
    width: 48px;
    border-radius: 8px;
    background-color: red;
    margin: 0 auto 7px;
    position: relative;

    /*<!--&::after {-->*/
    /*<!--  content: "";-->*/
    /*<!--  background-image: url("/dist/images/frontend/close-red.svg");-->*/
    /*<!--  width: 160px;-->*/
    /*<!--  height: 160px;-->*/
    /*<!--  position: absolute;-->*/
    /*<!--  top: -20px;-->*/
    /*<!--  left: -20px;-->*/
    /*<!--}-->*/
  }

  .game-name {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #002B50;
    margin-bottom: 37px;
  }

  .wrap-btn {
    display: flex;
    justify-content: space-between;

    .btn-delete {
      background: #FF4B55;
      border-radius: 6px;
      text-transform: inherit;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      box-shadow: none;
      padding: 0;
      outline: none;
      transition-duration: .3s;

      &:hover {
        background-color: #e94f4e;
        transition-duration: .3s;
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 165px;
      padding: 0;
    }
  }
}
</style>
