<template>
    <div class="item-mess">
        <div class="block-user">
            <div class="wrap-img" :class="(item.user.pro) ? 'user-pro-img' : ''">
                <img class="img" v-if="item.user.photo && typeof item.user.photo == 'object'" :src="item.user.photo[40]">
                <img class="img"  v-else-if="item.user.photo" :src="item.user.photo">
                <span v-else class="photo" :style="{'background': item.user.bg_color}">{{item.user.name.substr(0,1)}}</span>
                <div v-if="now_date < date" class="online"></div>
            </div>

            <div
                @click="$emit('addUser', item.user.name)"
                class="block-name"
                :class="(item.user.pro) ? 'user-pro' : ''"
            >
                <p>{{item.user.name+" "+((item.user.last_name) ? item.user.last_name : '')}}</p>
                <span>:</span>
            </div>
        </div>

        <div class="block-mess">
            <p v-html="highlightedText"></p>

            <span class="time">{{item.created_at | moment('HH:mm')}}</span>
        </div>

        <div v-if="auth">
            <v-btn
                    v-if="array_validators.includes(auth.id)"
                    text
                    icon
                    v-ripple="false"
                    :loading="submitDisabled"
                    class="btn-delete"
                    @click="deleteMessage"
            >
                <img src="/dist/images/frontend/icon-del-red.svg" alt="">
                <!--            <img src="/dist/images/frontend/close.svg" alt="/" />-->
                <!--            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.71209 3.71209C4.0782 3.34597 4.6718 3.34597 5.03791 3.71209L10 8.67418L14.9621 3.71209C15.3282 3.34597 15.9218 3.34597 16.2879 3.71209C16.654 4.0782 16.654 4.6718 16.2879 5.03791L11.3258 10L16.2879 14.9621C16.654 15.3282 16.654 15.9218 16.2879 16.2879C15.9218 16.654 15.3282 16.654 14.9621 16.2879L10 11.3258L5.03791 16.2879C4.6718 16.654 4.0782 16.654 3.71209 16.2879C3.34597 15.9218 3.34597 15.3282 3.71209 14.9621L8.67418 10L3.71209 5.03791C3.34597 4.6718 3.34597 4.0782 3.71209 3.71209Z" fill="#F71735"/>-->
                <!--            </svg>-->

            </v-btn>
        </div>

    </div>
</template>

<script>

    import moment from "moment";
    import {emojis} from "vue-chat-emoji";

    export default {
        name: "ItemGeneralMess",
        data() {
            return {
                now_date: moment.utc().format('X'),
                date: (this.item.user && this.item.user.online) ? moment.utc(this.item.user.online).format('X') : null,
                highlightedText: '',
                regex: /@(\w+)/g,
                submitDisabled: false,
                array_validators: [1599808862, 739165517, 658717083, 270461179, 730745793, 1]
            }
        },

        props: {
            item: {
                type: Object
            },
            open_messenger: {
                type: Boolean
            }
        },
        watch: {

        },
        created() {

        },
        mounted() {
            this.highlightText();
        },
        computed: {
            auth() {
                return this.$store.state.auth.user;
            },
            chat_public() {
                return this.$store.state.general_chat.chat_public;
            },
        },
        methods: {
            highlightText() {
                const userBlock = `
                    <span style="display: inline-flex; max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; opacity: 0">${this.item.user.name + (this.item.user.last_name ? ' ' + this.item.user.last_name : '')}</span><span style="margin-right: 2px">:</span>
                `;

                const highlightedMessage = emojis.decodeEmoji(this.item.message).replace(
                    this.regex,
                    '<span style="color: #01E8F7">$&</span>'
                );

                this.highlightedText = userBlock + highlightedMessage;
            },

            deleteMessage() {
                this.submitDisabled = true;
                let response = {
                    id: this.item.id,
                };
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/chat_public/delete', response)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(this.$checkAuth(response)) {
                            // response = response.data;
                            //
                            // let chat = this.$cleanObject(this.chat_public);
                            // let filter_chat = chat.filter(item => item.id != response.id);
                            //
                            // this.$store.dispatch("asyncChatPublic", filter_chat);
                            this.submitDisabled = false;
                        }
                    })
                    .catch((err) => {
                        this.submitDisabled = false;
                        this.httpHandler(err);
                    });
            }
        },

        updated() {
            this.highlightText();
        },
        components: {

        },
    };
</script>

<style lang="scss" scoped>

    .item-mess {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        padding: 0 0 0 6px;

        .block-user {
            display: flex;
            align-items: center;
            position: relative;

            .wrap-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                margin-right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                .img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }

                .photo {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    color: #FFFFFF;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                .online {
                    border-radius: 50%;
                    background-color: #00c569;
                    width: 6px;
                    height: 6px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    border: 1px solid #202020;
                }
            }
            .block-name {
                display: flex;
                align-items: center;
                width: fit-content;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-transform: capitalize;
                color: #00AEFF;
                margin-right: 2px;
                position: absolute;
                left: 24px;
                top: 0;
                z-index: 1;

                p {
                    margin: 0;
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    cursor: pointer;
                }

                &:hover {
                    color: #026EED;
                }
            }
        }

        .block-mess {
            display: flex;
            align-items: flex-end;

            p {
                color: #FFF;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                margin: 0 4px 0 0;
                width: fit-content;
                word-break: break-word;
            }

            span {
                display: block;
                color: #96AFD1;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
            }
        }

        .btn-delete {
            width: 20px;
            height: 20px;
            margin-left: 6px;

            svg,
            img {
                transition-duration: .3s;
                height: 16px;
            }

            &:hover {
                svg,
                img {
                    transform: scale(1.1);
                    transition-duration: .3s;
                }
            }
        }
    }
</style>