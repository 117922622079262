<template>
    <div class="block-card" :class="(scroll) ? 'block-card--fixed': ''">

        <div class="block-card-head">
          <div class="block-card-head__img" :class="(auth.pro) ? 'user-pro-img' : ''">
            <UploadAvatar
                v-bind:post_params="{'type': 1}"
                v-bind:response.sync="response_upload"
                :url="'/ajax/users/image/upload'"
                style="background-size: cover"
            >
              <img v-if="auth.photo && typeof auth.photo == 'object'" class="avatar" :src="auth.photo.original"/>
              <img v-else-if="auth.photo" class="avatar" :src="auth.photo"/>
              <span v-else class="photo" :style="{'background': auth.bg_color}">{{auth.name.substr(0,1)}}</span>
            </UploadAvatar>
          </div>
           <div>
               <h4 class="block-card-head__name" :class="(auth.pro) ? 'user-pro' : ''">{{setting.name +' '+((setting.last_name) ? setting.last_name : '')}}</h4>
               <span v-if="setting.location" class="block-card-head__location">
                {{$getLocation(setting.location)}}
            </span>

               <div class="block-card-head__social">
                <span class="block-card-head__social-item"  v-for="(social, key) in filterSocials" :key="key">
                    <SocialIcon v-bind:index="key" v-bind:value.sync="social"></SocialIcon>
                </span>
               </div>
           </div>
        </div>
        <div class="block-card-body">

            <div class="block-card-body__email">
                <div class="block-card-body__wrap-icon">
                    <img src="/dist/images/frontend/email.svg" alt />
                </div>

                <div class="block-card-body__block-info">
                    <span>{{__('E-mail')}}</span>
                    <p>{{setting.email}}</p>
                </div>
            </div>

            <div v-if="setting.phone_number" class="block-card-body__phone">
                <div class="block-card-body__wrap-icon">
                    <img src="/dist/images/frontend/Callphone.svg" alt />
                </div>

                <div class="block-card-body__block-info">
                    <span>{{__('Phone')}}</span>
                    <p>+{{setting.phone_code + '' + setting.phone_number}}</p>
                </div>
            </div>

            <div class="block-card-body__page_address">
                <div class="block-card-body__wrap-icon">
                    <img src="/dist/images/frontend/address.svg" alt />
                </div>
                <div class="block-card-body__block-info">
                    <span>{{__('Page address')}}</span>
                    <p>
                        <span style="color: #96AFD1; font-size: 15px">... / </span>{{setting.page}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UploadAvatar from "../../modules/UploadAvatar";
    import SocialIcon from "./SocialIcon";
    // import DropdownLanguages from "../../modules/DropdownLanguages";
    export default {
      name: 'PersonalCard',
      data: function () {
          return {
            // scroll: false,
            response_upload: null,
            items: ['English', 'Русский', 'Укріїнська'],
            e1: "English"
              // social:this.$cleanObject(this.$filterArray(this.$socials, this.index, 'name')),
          }
      },
      props: {
          setting: {
              type: Object
          },
          update_info: {
              type: Function
          },
          scroll: {
              type: Boolean
          }
      },
      computed: {
          filterSocials: function () {
              let result = {};
              Object.keys(this.setting.social).forEach((key) => {
                  if (this.setting.social[key]) {
                      result[key] = this.setting.social[key]
                  }
              });
              return result
          },
        auth() {
          return this.$store.state.auth.user
        },
      },

      methods: {
        sendAjax: function () {
          this.$http
            .get('ajax/logout')
            .then((response) => {
              return response.json();
            })
            .then((response) => {
                this.$checkAuth(response);
                this.$router.push({ name: 'home', params: {lang: this.$store.getters.getLang}});
                location.reload();
            })
            .catch((err) => {
              this.httpHandler(err);
            });
        },
      },

      // created() {
      //   this.$addListener(window,'scroll', () => {
      //       if (document.documentElement.scrollTop > 60) {
      //           this.scroll = true;
      //       } else {
      //           this.scroll = false;
      //       }
      //   });
      // },

      watch: {
        response_upload: function (payload) {
          let setting= this.setting,
              auth= this.auth;

          setting.photo = auth.photo= payload.logo;

          this.$emit('update:setting', setting);
          this.$store.commit('updateUserInfo', auth);
        },


      },
        components: {
            // DropdownLanguages,
            SocialIcon,
            UploadAvatar
        }
    }
</script>

<style lang="scss" scoped>

    .block-card {

        /*&--fixed {*/
            /*position: fixed;*/
            /*top: 88px;*/
            /*right: auto;*/
            /*max-width: 320px;*/
            /*width: 100%;*/
            /*margin-top: 0;*/
        /*}*/

        /*position: fixed;*/
        /*right: auto;*/
        /*top: 148px;*/
        /*bottom: 20px;*/
        border-radius: 4px;
        /*margin-top: 80px;*/
        /*height: 100%;*/
        background: #fff;
        //border-radius: 8px 0px 0px 8px;
        width: 320px;
        /*width: 100%;*/
        padding-bottom: 20px;
        /*height: calc(100vh - 70px);*/
        /*height: 100vh;*/
        /*overflow: auto;*/
    }

    .block-card-head {
        text-align: center;
        /*background-image: url("/dist/images/frontend/bg-head-settings.png");*/
        border-radius: 8px 0 0 0;
        padding: 30px 10px 20px;

        &__img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin: 0 auto 12px;

            .avatar {
                border-radius: 50%;
                width: 120px;
                height: 120px;
            }

            .photo {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                display: flex;
                color: #FFFFFF;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                font-size: 60px;
                font-weight: bold;
                text-transform: uppercase;
                cursor: default;
            }
        }

        &__name {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #002B50;
            margin-bottom: 1px;
        }

        &__location {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #6886AF;
            margin-bottom: 7px;
        }

        &__social {
            display: flex;
            justify-content: center;
        }

        &__social-item {
            margin: 0 15px;
        }
    }

        .block-card-body {
            background-color: transparent;
            border-radius: 0 0 0 8px;
            padding: 10px 20px 0  20px;

            &__email,
            &__phone,
            &__page_address {
                display: flex;
                align-items: center;
                margin-bottom: 14px;
            }

            &__wrap-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 36px;
                height: 36px;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
                border-radius: 8px;
                margin-right: 12px;
            }

            &__block-info {
                span {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 14px;
                    color: #26A9F4;
                }

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 128%;
                    color: #002B50;
                    margin-bottom: 0;
                    max-width: 240px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .block-language {
                  display: flex;
                  align-items: center;
                  max-width: 150px;
                  //margin-left: 27px;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #002B50;

                  img {
                    margin-left: -35px;
                    margin-top: -6px;
                    transition-duration: .3s;
                  }

                  .v-select--is-menu-active ~img {
                    transform: rotate(180deg);
                    transition-duration: .3s;
                  }
                }
            }

            .v-btn {
                display: block;
                margin: 40px auto 0;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 190px;
                    padding: 0 12px;
                }

                img {
                    margin-right: 13.5px;
                }
            }
        }

    @media screen and (max-width: 950px) {

        .block-card {
            width: 100%;
        }

        .block-card-head {
            padding: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__img {
                width: 100px;
                height: 100px;
                margin: 0 20px 0 0;

                .avatar {
                    width: 100px;
                    height: 100px;
                }

                .photo {
                    width: 100px;
                    height: 100px;
                    font-size: 44px;
                }
            }

            &__name {
                text-align: left;
            }

            &__location {
                justify-content: flex-start;
            }

            &__social {
                display: flex;
                justify-content: flex-start;
                margin-left: -12px;
            }

            &__social-item {
                margin: 0 12px;
            }
        }

        .block-card-body {
            display: flex;
            justify-content: space-between;

            &__email,
            &__phone,
            &__page_address {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 768px){
        .block-card {
            .block-card-body {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                &__email,
                &__phone,
                &__page_address {
                    margin-bottom: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 500px) {

        .block-card-head {
            text-align: center;
            flex-direction: column;
            padding: 10px;

            &__img {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                margin: 0 auto 12px;

                .avatar {
                    border-radius: 50%;
                    width: 120px;
                    height: 120px;
                }

                .photo {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                }
            }

            &__name {
                text-align: center;
            }

            &__location {
                align-items: center;
                justify-content: center;

            }

            &__social {
                display: flex;
                justify-content: center;
                margin: 0;
            }

            &__social-item {
                margin: 0 15px;
            }
        }

        .block-card-body {
            flex-direction: column;
            padding: 0 15px 0;

            &__email,
            &__phone,
            &__page_address {
                display: flex;
                align-items: center;
                margin-bottom: 14px;
            }
        }
    }


//dark

.dark .block-card {
    background: #171827;
}

.dark .block-card-head {
    &__name {
        color: #fff;
    }

    &__location {
        color: #96AFD1;
    }
}

.dark .block-card-body__wrap-icon {
    background: rgba(38, 169, 244, 0.1);
}

.dark .block-card-body {
    &__block-info {
        p {
            color: #fff;

            span {
                color: #fff!important;
            }
        }
    }
}
</style>