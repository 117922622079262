<template>
    <div>
        <v-dialog v-model="dialog" max-width="320px">
            <div class="wrap-sorting-blog">
<!--                <div class="search-blog">-->
<!--                    <div class="search-blog__icon">-->
<!--                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <ellipse cx="8.80553" cy="8.80547" rx="7.49046" ry="7.49047" stroke="#6886AF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                            <path d="M14.0153 14.4042L16.952 17.3333" stroke="#6886AF" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                        </svg>-->
<!--                    </div>-->
<!--                    <div class="search-blog__input">-->
<!--                        <InputLabelTextDefault-->
<!--                                v-model="search"-->
<!--                                @change="updateValidate('search')"-->
<!--                                v-bind:errors="formValidate.search"-->
<!--                                class_input="search-sorting"-->
<!--                                placeholder="Search blog"-->
<!--                        ></InputLabelTextDefault>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="sorting-blog-head">
                    <div class="sorting-blog-head__icon">
                        <img src="/dist/images/frontend/filter-icon.svg" alt />
                    </div>
                    <h5>{{__('Filters')}}</h5>
                </div>
                <div class="wrap-sorting-blog__body">

                    <div class="categories">
                        <div class="categories__head">
                            <div class="categories__icon">
                                <img src="/dist/images/frontend/category-icon.svg" alt />
                            </div>
                            <p>{{__('Categories')}}</p>
                        </div>
                        <div class="wrap-checkbox">
                            <div v-for="(item, key) in categories" :key="key">
                                <v-checkbox  v-model="category_id"  color="blue" :value="item.id" :label="item.category"></v-checkbox>
                            </div>
                            <!--                    <v-checkbox v-model="category_id"  color="blue" value="1" :label="__('News')"></v-checkbox>-->
                            <!--                    <v-checkbox v-model="category_id"  color="blue" value="2" :label="__('Game')"></v-checkbox>-->
                            <!--                    <v-checkbox v-model="category_id"  color="blue" value="3" :label="__('Update')"></v-checkbox>-->
                        </div>
                    </div>

                    <div class="wrap-btn">
                        <!--                <v-btn-->
                        <!--                    v-on:click="cancelFilters"-->
                        <!--                    :loading="submitDisableded"-->
                        <!--                    text-->
                        <!--                    class="btn-secondary"-->
                        <!--                >Cancel</v-btn>-->
                        <div style="width: 48%">
                            <BaseButton
                                    v-on:click="cancelFilters"
                                    :loading="submitDisabled"
                                    class_btn="btn-liteGrey"
                            >
                                <span>{{__('Clear')}}</span>
                            </BaseButton>
                        </div>

                        <!--                <v-btn-->
                        <!--                    v-on:click="onSubmit"-->
                        <!--                    :loading="submitDisabled"-->
                        <!--                    v-ripple="{ class:'secondary&#45;&#45;text'}"-->
                        <!--                    text-->
                        <!--                    class="btn-cab white&#45;&#45;text"-->
                        <!--                >Apply</v-btn>-->
                        <div style="width: 48%">
                            <BaseButton
                                    v-on:click="onSubmit"
                                    :loading="submitDisabled"

                            >
                                <span>{{__('Apply')}}</span>
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    // import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {maxLength, minLength} from "vuelidate/lib/validators";

    export default {
        name: "FriendsRequests",
        data() {
            return {
              // search: null,
              category_id: this.sorting.category_id,

              formValidate: {
                // search: null,
                category_id: null,
              },
              submitDisabled: false,
              submitDisableded: false,
              dialog: true,
            }
        },
        validations: {
          sorting: {
            search: {
              maxLength: maxLength(100),
              minLength: minLength(3),
            },
            // category_id: {
            //   numeric
            // },
          },
        },
        computed: {
            category() {
                return this.$route.params.category;
            },
            languages() {
                return this.$store.state.language.lang;
            },
        },
        watch: {
          category(value) {
            if(value) {
              let index= this.$filterIndexObject(this.categories, value, 'category');
              if(index >= 0) {
                  let category= this.categories[index];
                  this.category_id= [category.id];
              }
            }
          },
            dialog() {
                this.$emit("update:modal_sort", false);
            },
        },
        created() {
            if(this.category) {
                let index= this.$filterIndexObject(this.categories, this.category, 'category');
                if(index >= 0) {
                    let category= this.categories[index];
                    this.category_id= [category.id];
                }
            }
        },
        props: {
          categories: {
            type: Array,
          },
          modal_sort: {
              type: Boolean
          },
          sorting: {
              type: Object
          },
          search: {
              type: String
          },
          filter: {
              type: Array
          }
        },

        methods: {
          updateValidate: function (type) {
            this.formValidate = this.$changeValidate(
                this.formValidate,
                this.$v.sorting[type],
                type
            );
          },

          onSubmit: function () {
            let valid = this.$v.sorting;

            if (valid.$invalid) {
              let massages = {
                // search: [],
                category_id: [],

              };
              this.formValidate = this.$formValidate(massages, valid);
            } else {

              // this.$emit('update:news', null);
                let sorting = {
                    category_id: this.category_id.length ? this.category_id : []
                }

              let response = {
                search: this.search,
                category_id: this.category_id.length ? this.category_id : null,
                lang: this.languages
              };
                let count = [];
                Object.keys(sorting).forEach((key) => {
                    if(sorting[key].length) {
                        count.push(sorting[key]);
                    }
                });
              this.$emit('update:filter', count);
              this.submitDisabled = true;
              this.$emit("update:sorting", sorting);
              this.$http.post("ajax/blog", response)
                .then((response) => {
                  return response.json();
                })
                .then((response) => {
                  if(this.$checkAuth(response)) {
                      // response = {
                      //     "data": {
                      //         "category": [
                      //             {
                      //                 "id": 1,
                      //                 "category": "Category"
                      //             },
                      //             {
                      //                 "id": 2,
                      //                 "category": "test2"
                      //             },
                      //             {
                      //                 "id": 5,
                      //                 "category": "test5"
                      //             },
                      //             {
                      //                 "id": 6,
                      //                 "category": "tes5"
                      //             },
                      //             {
                      //                 "id": 7,
                      //                 "category": "qwe4"
                      //             }
                      //         ],
                      //         "blog": {
                      //             "current_page": 1,
                      //             "data": [
                      //                 {
                      //                     "url": "populyarnyie_wer",
                      //                     "category_id": 6,
                      //                     "title": "AAAA",
                      //                     "img": "/assets/games/3.jpg",
                      //                     "description": "populyarnyie igryi onlayn: chervyaki zmeyki tanchiki strelyalki i mnogo drugih igr! igrayte na MindPlays — s druzyami ili drugimi igrokami besplatno i na dengi! bezgranichnyie vozmojnosti!",
                      //                     "created_at": "2020-09-11 18:38:31",
                      //                     "category": "tes5"
                      //                 },
                      //                 {
                      //                     "url": "qwqw",
                      //                     "category_id": 1,
                      //                     "title": "dsasda1",
                      //                     "img": "/assets/games/3.jpg",
                      //                     "description": "dsadsadsasda adssda",
                      //                     "created_at": "2021-03-26 10:24:21",
                      //                     "category": "test"
                      //                 }
                      //             ],
                      //             "first_page_url": "https://mindplay.loc/ajax/blog?page=1",
                      //             "from": 1,
                      //             "last_page": 1,
                      //             "last_page_url": "https://mindplay.loc/ajax/blog?page=1",
                      //             "next_page_url": null,
                      //             "path": "https://mindplay.loc/ajax/blog",
                      //             "per_page": 12,
                      //             "prev_page_url": null,
                      //             "to": 3,
                      //             "total": 3
                      //         }
                      //     },
                      //     "status": 200
                      // };
                    this.submitDisabled = false;
                    this.$emit('updateBlogs', response.data);
                    this.$emit("update:modal_sort", false);

                      // this.$router.push({
                    //     name: "blogs"
                    // });
                  }
                })
                .catch((err) => {
                  this.submitDisabled = false;
                  let response = this.httpHandler(err);
                  if (response.error) {
                    this.formValidate = response.error.message;
                  }
                });
            }
          },
          cancelFilters: function () {
            this.$emit('update:news', null);
            // this.search= null;
            this.category_id = [];
              let sorting = {
                  category_id: [],
              }
              this.$emit("update:sorting", sorting);
              this.onSubmit();
              this.$emit('updateFilter');
          }

        },
        components: {
            // InputLabelTextDefault
        },
    };
</script>


<style lang="scss">

    .dark {
        .wrap-sorting-blog {
            .sorting-blog-head {
                background: #171827;
                border-bottom: 1px solid #2B2B2B;

                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                h5 {
                    color: #fff;
                }
            }

            &__body {
                background: #171827;
            }

            .categories {
                &__icon {
                    img {
                        filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(119deg) brightness(900%) contrast(105%);
                    }
                }

                p {
                    color: #fff;
                }

                .wrap-checkbox {
                    .v-label {
                        color: #96AFD1!important;
                    }
                }
            }
        }
    }

    .wrap-sorting-blog {

        /*position: fixed;*/
        /*top: 82px;*/
        /*right: auto;*/
        max-width: 320px;
        width: 100%;

        .sorting-blog-head {
            padding: 20px;
            display: flex;
            align-items: center;
            //box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
            border-radius: 8px 8px 0px 0px;
            border-bottom: 2px solid #ECF4FF;
            background: #fff;

            &__icon {
                margin-right: 17px;
                display: flex;
                align-items: center;
            }

            h5 {
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
            }
        }

        .search-blog {
            background-color: #fff;
            border-radius: 8px 8px 0 0;
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            padding: 12px 20px;
            box-shadow: 0 2px 0 #F2F3FB;

            &__input {
              width: 100%;
              //border: 1px solid red;
              background: #FFFFFF;
              border-radius: 0 20px 20px 0;
              /*border: 1px solid #ECF4FF;*/
              border-left: none;
              overflow: hidden;
            }

            &__icon {
              /*height: 42px;*/
              /*background-color: #fff;*/
              /*border-radius: 20px 0 0 20px;*/
              /*border: 1px solid #ECF4FF;*/
              /*border-right: none;*/
              /*overflow: hidden;*/
              //margin: 17px 14px 0 20px;
              /*padding: 6px 16px 0 12px;*/
                padding: 0 16px 1px 0;
            }
        }

        &__body {
            padding: 20px 20px 22px;
            background-color: #fff;
            border-radius: 0 0 8px 8px;
        }

        .categories {
            margin-bottom: 12px;

            &__head {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
            }

            &__icon {
                margin: 0 16px 4px 1px;
            }

            p {
              font-weight: 400;
              font-size: 15px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #6886AF;
              margin-bottom: 0;
              margin-top: 4px;
            }

            .wrap-checkbox {
                margin-left: -1px;

                .v-input {
                    margin-top: -7px;
                }

                .v-label {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 16px;
                    letter-spacing: 0.4px;
                    color: #002B50!important;
                    text-transform: capitalize;
                    margin-left: 7px;
                }
            }
        }

        .wrap-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .v-btn {
                width: 48%;
                margin: 0 auto;
                display: block;

                &.v-btn:not(.v-btn--round).v-size--default {
                    height: 40px;
                    min-width: 100px;
                    width: 48%;
                    padding: 0;

                }
            }

            .btn-secondary {
              margin-right: 15px;
            }

        }

    }


    @media screen and (max-width: 1350px) {

        .wrap-sorting-blog {
            max-width: 280px;
        }
    }

</style>