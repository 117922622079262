<template>
  <div :class="(!auth) ? 'dark' : ''">
    <LayoutNavAuth>
      <h1>{{__('Affiliate Program')}}</h1>

      <div slot="right" class="wrap-tabs">
        <tabs
          :tabs="side_tabs"
          :currentTab="currentTab"
          :wrapper-class="'buttons-tabs'"
          :tab-class="'buttons-tabs__item'"
          :tab-active-class="'buttons-tabs__item_active'"
          :line-class="'buttons-tabs__active-line'"
          @onClick="sideChange"
        />
      </div>
      <div slot="right" class="wrap-select">
        <DropdownDefault
                class_modal="modal_game"
                color="select_game"
                v-bind:dropdown="side_tabs"
                v-bind:value.sync="side_tabs.value"
                @updateValue="sideChange"
        />
      </div>
      <div slot="line" class="loading"  :style="{width: progress + '%'}"></div>
    </LayoutNavAuth>
    <div v-if="affiliate && referrals" :class="(currentTab == 'partners') ? 'partner' : ''" class="wrapper-affiliate dashboard-content">
      <div  class="block-affiliate">
        <div v-if="currentTab != 'launchpad'" class="wrap-head-affiliate">
          <div class="head-affiliate">
            <div class="head-affiliate__left">
              <div class="head-affiliate__commission">
                <div class="information">
                  <h3>{{__('20%')}}</h3>
                </div>
              </div>

              <p>{{__('from platform commission')}}</p>
            </div>

            <span class="head-affiliate__link">

              <h6>{{__('Your refferal link')}}</h6>

                <InputLabelTextDefault
                        color="affiliate"
                        class_input="affiliate_input"
                        :value="affiliate.link"
                        readonly
                        copy
                        copyRef
                        :modal.sync="modal_qr_link"
                >
                </InputLabelTextDefault>
            </span>

            <img src="/dist/images/frontend/affiliate-head-img.svg" alt />
          </div>
        </div>
        <h1 v-if="currentTab=='partners'">{{__('Your referral structure')}}</h1>
        <h1 v-if="currentTab=='materials'">{{__('Promo Materials')}}</h1>
        <h1 v-if="currentTab=='banners'">{{__('HTML Banners')}}</h1>
<!--        <h1 v-if="currentTab=='launchpad'">{{__('Launchpad')}}</h1>-->

        <div class="wrap-body-affiliate">
          <div class="body-affiliate">
            <div class="body-affiliate__body">
              <Partners v-if="currentTab=='partners'" :referrals.sync="referrals" :filter_status.sync="filter_status" :sorting_referrals.sync="sorting_referrals" :search.sync="search" :loadingPage.sync="loadingPage" :affiliate="affiliate" @filterReferrals="getReferrals"></Partners>
              <PromoMaterials v-else-if="currentTab=='materials'"></PromoMaterials>
              <Banners v-else-if="currentTab=='banners'" v-bind:affiliate="affiliate"></Banners>
              <Launchpad v-else-if="currentTab=='launchpad' && launchpad" v-bind:launchpad.sync="launchpad"></Launchpad>
            </div>
          </div>
        </div>
      </div>
      <div class="block-sidebar-affiliate">
        <AffiliateSidebar v-bind:affiliate="affiliate" :currentTab.sync="currentTab" v-bind:launchpad.sync="launchpad"></AffiliateSidebar>
      </div>
    </div>
    <div class="dashboard-content" v-else>
      <div class="skeleton-affiliate">
        <div class="left">
          <div class="head">
            <div class="left">
              <SkeletonText :count="3"></SkeletonText>
            </div>
            <div class="center">
              <SkeletonText></SkeletonText>
              <SkeletonImage></SkeletonImage>
            </div>
          </div>
          <div class="body">
            <div class="head">
              <SkeletonListAvatarLine></SkeletonListAvatarLine>
              <SkeletonImage></SkeletonImage>
            </div>
            <SkeletonListAvatarLine :count="5"></SkeletonListAvatarLine>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <SkeletonText class="text-top" :count="2"></SkeletonText>
            <SkeletonText class="text-subtitle" :count="3"></SkeletonText>
            <SkeletonListAvatarLine class="text" :count="4"></SkeletonListAvatarLine>
          </div>
          <div class="body">
            <SkeletonText :count="2" class="text"></SkeletonText>
            <SkeletonText class="text-subtitle" :count="3"></SkeletonText>
          </div>
        </div>
      </div>
    </div>
    <ModalQRLink v-if="modal_qr_link && affiliate" v-bind:affiliate="affiliate" :modal_qr_link.sync="modal_qr_link"></ModalQRLink>
  </div>
</template>

<script>
    import AffiliateSidebar from  './Affiliate/AffiliateSidebar';
    import Partners from './Affiliate/Partners';
    import PromoMaterials from './Affiliate/PromoMaterials';
    import Banners from './Affiliate/Banners';
    import Launchpad from "./Affiliate/Launchpad";
    import InputLabelTextDefault from "../modules/InputLabelTextDefault";
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    // import SearchDefault from "../modules/SearchDefault.vue"
    import ModalQRLink from "./Affiliate/ModalQRLink";
    import DropdownDefault from "../modules/DropdownDefault";
    import SkeletonListAvatarLine from "../modules/SkeletonListAvatarLine";
    import SkeletonText from "../modules/SkeletonText";
    import SkeletonImage from "../modules/SkeletonImage";
    export default {
        name: 'Affiliate',
        data () {
          return {
            launchpad: null,
            progress: 0,
            modal_qr_link: false,
            search: null,
            show: false,
            scroll: false,
            // currentTab: 'partners',
            affiliate: null,
            referrals: null,
            next_page_url: null,
            filter_status: false,
            loadingPage: false,
            sorting_referrals: {
              location: null,
              // level: null,
              active: null,
            },
            side_tabs: [

              {
                  'title': 'Your Referrals',
                  'value': 'partners',
                  // 'exact': true
              },
              // {
              //   'title': 'Promo Materials',
              //   'value': 'materials'
              // },
              {
                  'title': 'HTML Banners',
                  'value': 'banners'
              },
              {
                'title': 'Launchpad',
                'value': 'launchpad'
              },

            ],
            active_search: false,
          }
        },

        watch: {
            search(val) {
                if (val) {
                    this.show = true;
                } else {
                    this.show = false;
                }
            },
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            },
            // currentTab: function () {
            //     return (this.$route.params.current) ? this.$route.params.current : 'partners'
            // },
          currentTab: function () {
            return (this.$route.params.current) ? this.$route.params.current : this.side_tabs[0].value;
          },
        },
        methods: {
            // sideChange(newTab) {
          //     // this.$router.push({name: 'affiliate_current', params: {'current': newTab}});
          //   if (newTab != this.currentTab) {
          //     this.currentTab = newTab;
          //   }
          // },
          getReferrals(url="ajax/affiliate/referrals") {
            const response = this.sorting_referrals;
            response.search = this.search;
            this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get'](url, response)
              .then((response) => {
                return response.json();
              })
              .then((response) => {
                if(this.$checkAuth(response)) {
                  // response = {
                  //   "data": {
                  //     "filter": false,
                  //     "referrals": {
                  //       "current_page": 1,
                  //       "data": [
                  //         {
                  //           "ref_id": 492745513,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 0.2324342324232322,
                  //           "amount_lite": 15.00020342024,
                  //           "amount_two_level": 40.66,
                  //           "count_referral": 3,
                  //           "user": {
                  //             "id": 492745513,
                  //             "name": "Vladyslav",
                  //             "last_name": "Volkonsky",
                  //             "public_id": "492745513",
                  //             "photo": {
                  //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //             },
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 23,
                  //           "amount_lite": 15,
                  //           "amount_two_level": 0,
                  //           "count_referral": 0,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": {
                  //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //             },
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 33,
                  //           "amount_lite": 10,
                  //           "amount_two_level": 0,
                  //           "count_referral": 1,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 3,
                  //           "amount_lite": 5,
                  //           "amount_two_level": 0,
                  //           "count_referral": 0,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 1,
                  //           "amount_lite": 5,
                  //           "amount_two_level": 0,
                  //           "count_referral": 1,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 13,
                  //           "amount_lite": 15,
                  //           "amount_two_level": 0,
                  //           "count_referral": 0,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 4,
                  //           "amount_lite": 5,
                  //           "amount_two_level": 0,
                  //           "count_referral": 1,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 492745513,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 0.2324342324232322,
                  //           "amount_lite": 15.00020342024,
                  //           "amount_two_level": 40.66,
                  //           "count_referral": 3,
                  //           "user": {
                  //             "id": 492745513,
                  //             "name": "Vladyslav",
                  //             "last_name": "Volkonsky",
                  //             "public_id": "492745513",
                  //             "photo": {
                  //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //             },
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 23,
                  //           "amount_lite": 15,
                  //           "amount_two_level": 0,
                  //           "count_referral": 0,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": {
                  //               "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //               "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //             },
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 33,
                  //           "amount_lite": 10,
                  //           "amount_two_level": 0,
                  //           "count_referral": 1,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 3,
                  //           "amount_lite": 5,
                  //           "amount_two_level": 0,
                  //           "count_referral": 0,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 1,
                  //           "amount_lite": 5,
                  //           "amount_two_level": 0,
                  //           "count_referral": 1,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 13,
                  //           "amount_lite": 15,
                  //           "amount_two_level": 0,
                  //           "count_referral": 0,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         },
                  //         {
                  //           "ref_id": 714493921,
                  //           "level": 1,
                  //           "created_at": "01/03/2021",
                  //           "amount_real": 4,
                  //           "amount_lite": 5,
                  //           "amount_two_level": 0,
                  //           "count_referral": 1,
                  //           "user": {
                  //             "id": 714493921,
                  //             "name": "user7",
                  //             "last_name": null,
                  //             "public_id": "714493921",
                  //             "photo": null,
                  //             "bg_color": "#80dbff",
                  //             "online": "2021/01/19 20:18:52",
                  //             "rating_points": 0,
                  //             "location": "UA",
                  //             "location_img": "/dist/images/flags/ua.png",
                  //           }
                  //         }
                  //       ],
                  //       "first_page_url": "https://mindplay.loc/ajax/affiliate/referrals?page=1",
                  //       "from": 1,
                  //       "last_page": 2,
                  //       "last_page_url": "https://mindplay.loc/ajax/affiliate/referrals?page=2",
                  //       "next_page_url": null,
                  //       "path": "https://mindplay.loc/ajax/affiliate/referrals",
                  //       "per_page": 2,
                  //       "prev_page_url": null,
                  //       "to": 2,
                  //       "total": 3
                  //     }
                  //   },
                  //   "status": 200
                  // }
                  this.referrals = response.data.referrals.data;
                  this.filter_status = response.data.filter;
                  this.next_page_url = response.data.referrals.next_page_url;
                  this.loadingPage = false;
                }
              })
              .catch((err) => {
                this.httpHandler(err);
              });
          },

          scrollPage() {
            const progress = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
            if (!this.loadingPage && this.next_page_url) {
              let bottomOfWindow = 0.7 <= progress;
              if (bottomOfWindow) {
                this.loadingPage = true;
                const response = this.sorting_referrals;
                response.search = this.search;
                this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get'](this.next_page_url, response)
                    .then((response) => {
                    return response.json();
                  })
                  .then((response) => {
                    if(this.$checkAuth(response)) {
                      response = response.data;
                      let referrals = this.referrals;
                      this.referrals = referrals.concat(response.referrals.data);
                      this.next_page_url = response.referrals.next_page_url;
                      this.loadingPage = false;
                    }
                  })
                  .catch((err) => {
                    this.httpHandler(err);
                  });
              }
            }
          },

          sideChange(newTab) {
            this.$router.push({
              name: "affiliate_current",
              params: { current: newTab, lang: this.$store.getters.getLang },
            });

            if (newTab == 'partners') {
              this.$addListener(window,'scroll', () => {
                this.scrollPage();
              });
            }
          },
        },

        created() {
          let timeout = setInterval(() => {
            this.progress += 1;
            if(this.progress > 50) {
              clearInterval(timeout)
            }
          },15);
          this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']("ajax/affiliate")
            .then((response) => {
              return response.json();
            })
          .then((response) => {
              if(this.$checkAuth(response)) {
                  // response = {
                  //   "data": {
                  //     "link": "https://mindplay.loc/u/2",
                  //     "total_referrals": 1500,
                  //     "active_referrals": 50,
                  //     "link_clicks": 4,
                  //     "income_sum_real": 0,
                  //     "income_sum_real_frozen": 0,
                  //     "income_sum_lite": 35.01121212,
                  //     "income_sum_lite_frozen": 48.70,
                  //     "income_sum": "1299.63480000",
                  //     "upline": {
                  //         "bg_color": "#31cc41",
                  //         "id": 2037624563,
                  //         "last_name": null,
                  //         "location": "Ukraine",
                  //         "location_img": "/dist/images/flags/ua.png",
                  //         "name": "user1",
                  //         "online": "2021/06/17 11:26:46",
                  //         "photo": {
                  //           "40": "/assets/users/40_a-16305673783HRsmbpEg1.webp",
                  //           "original": "/assets/users/a-16305673783HRsmbpEg1.webp"
                  //         },
                  //         "public_id": "2037624563",
                  //         "rating_points": "34",
                  //     },
                  //     "banners": {
                  //       "125x125": {
                  //         "width": "125",
                  //         "height": "125",
                  //         "img": "<img width='125' height='125' src='https://mindplays.comb/ar_125_en.gif'/>",
                  //         "href": "<a target='_blank' href='https://mindplays.com/u/203094611'><img width='125' height='125' src='https://mindplays.comb/ar_125_en.gif'/></a>"
                  //       },
                  //       "160x600": {
                  //         "width": "160",
                  //         "height": "600",
                  //         "img": "<img width='160' height='600' src='https://mindplays.comb/ar_160_en.gif'/>",
                  //         "href": "<a target='_blank' href='https://mindplays.com/u/203094611'><img width='160' height='600' src='https://mindplays.comb/ar_160_en.gif'/></a>"
                  //       },
                  //       "300x250": {
                  //         "width": "300",
                  //         "height": "250",
                  //         "img": "<img width='300' height='250' src='https://mindplays.comb/ar_300_en.gif'/>",
                  //         "href": "<a target='_blank' href='https://mindplays.com/u/203094611'><img width='300' height='250' src='https://mindplays.comb/ar_300_en.gif'/></a>"
                  //       },
                  //       "468x60": {
                  //         "width": "468",
                  //         "height": "60",
                  //         "img": "<img width='468' height='60' src='https://mindplays.comb/ar_468_en.gif'/>",
                  //         "href": "<a target='_blank' href='https://mindplays.com/u/203094611'><img width='468' height='60' src='https://mindplays.comb/ar_468_en.gif'/></a>"
                  //       },
                  //       "728x90": {
                  //         "width": "728",
                  //         "height": "90",
                  //         "img": "<img width='728' height='90' src='https://mindplays.comb/ar_728_en.gif'/>",
                  //         "href": "<a target='_blank' href='https://mindplays.com/u/203094611'><img width='728' height='90' src='https://mindplays.comb/ar_728_en.gif'/></a>"
                  //       },
                  //       "1920x180": {
                  //         "width": "1920",
                  //         "height": "180",
                  //         "img": "<img width='100%' src='https://mindplays.comb/ar_1920_en.gif'/>",
                  //         "href": "<a target='_blank' href='https://mindplays.com/u/203094611'><img width='1920' height='180' src='https://mindplays.comb/ar_1920_en.gif'/></a>"
                  //       }
                  //     },
                  //
                  //     "income_chart": [
                  //       [
                  //         "2020-10-02",
                  //         "20.00000000"
                  //       ],
                  //       [
                  //         "2020-10-03",
                  //         "9.80000000"
                  //       ],
                  //       [
                  //         "2021-10-03",
                  //         "19.80000000"
                  //       ],
                  //       [
                  //         "2022-10-01",
                  //         "125.00000000"
                  //       ]
                  //     ],
                  //
                  //     "income_chart_lite": [
                  //       [
                  //         "2020-10-02",
                  //         "20.00000000"
                  //       ],
                  //       [
                  //         "2020-10-03",
                  //         "35.80000000"
                  //       ],
                  //       [
                  //         "2021-10-03",
                  //         "67.80000000"
                  //       ],
                  //       [
                  //         "2022-10-01",
                  //         "100.00000000"
                  //       ]
                  //     ],
                  //     "link_count": 4,
                  //     "link_chart": [
                  //       [
                  //         "2021-02-25",
                  //         1
                  //       ],
                  //       [
                  //         "2021-02-26",
                  //         2
                  //       ],
                  //       [
                  //         "2021-02-27",
                  //         1
                  //       ]
                  //     ]
                  //   },
                  //   "status": 200
                  // }

                  this.affiliate = response.data;
                  // this.loadingPage = false;
                  clearInterval(timeout);
                  this.progress = 100;
                  setTimeout(() => {
                    this.progress = 0;
                  }, 500);
              }
          })
          .catch((err) => {
            this.httpHandler(err);
          });

          this.getReferrals();

          this.$addListener(window,'scroll', () => {
            this.scrollPage();
          });


            this.$http
                .get("ajax/launchpad")
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(this.$checkAuth(response)) {
                        this.launchpad = response.data;

                    }
                })
                .catch((err) => {
                    this.httpHandler(err);
                });
        },
      components: {
        InputLabelTextDefault,
        Partners,
        Banners,
        Launchpad,
        PromoMaterials,
        AffiliateSidebar,
        LayoutNavAuth,
        // SearchDefault,
        ModalQRLink,
        DropdownDefault,
        SkeletonListAvatarLine,
        SkeletonText,
        SkeletonImage
      },

    }
</script>

<style lang="scss" scoped>

  .dark .wrapper-affiliate {
    .block-affiliate {
      h1 {
        color: #fff;
      }
    }
  }

  .wrapper-affiliate {
    display: flex;
    padding: 20px 0 0;

    .block-affiliate {
      width: 100%;

      h1 {
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 20px;
      }
    }
    .block-sidebar-affiliate {
      width: 320px;
      margin-left: 20px;
    }

  }

  .wrap-head-affiliate {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .head-affiliate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    height: 126px;
    padding: 0 20px 0 50px;
    position: relative;
    width: 100%;
    /*background: linear-gradient(92.11deg, #026EED 0%, #00AEFF 100%);*/
    background-image: url("/dist/images/frontend/bg-affiliate.png");
    background-position: center;
    background-size: cover;

    &__left {
      position: relative;
      z-index: 1;


      p {
        font-size: 14px;
        line-height: 117.8%;
        color: #E7E7E7;
        margin-bottom: 0;
      }
    }

    &__commission {
      display: flex;
      align-items: center;
      justify-content: center;

      .information {
        display: flex;
        flex-direction: column;

        &:first-child {
          margin-right: 20px;
        }
      }

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 66px;
        line-height: 66px;
        color: #01DF72;
        text-shadow: #01DF72 1px 0 6px;
      }

      span {
        display: block;
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 117.8%;
        text-transform: uppercase;
        color: #E7E7E7;
      }
    }

    &__link {
      display: block;
      width: 100%;
      max-width: 370px;
      margin: 0 5px;

      h6 {
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
        font-weight: normal;
        font-family: UniNeueBook;
        margin-bottom: 7px;
      }
    }

    img {
      margin-bottom: -2px;
    }
  }

  .block-sidebar-affiliate {
    width: 320px;
    flex-shrink: 0;
    margin: 0;
  }

  .block-statistics {
    margin-top: 20px;
  }

  .wrap-body-affiliate {
    display: flex;

    .body-affiliate {
      height: fit-content;
      width: 100%;



      &__head {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 20px;
        min-height: 64px;
        background-color: #fff;
        border-radius: 4px 4px 0 0;
        border-bottom: 2px solid #ECF4FF;

        .block-upline {

          .wrap-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 10px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .photo {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              color: #FFFFFF;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              font-size: 20px;
              font-weight: bold;
              text-transform: uppercase;
              text-decoration: none;
            }
          }

          .v-list-item {

            &__title {
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              text-transform: capitalize;
              color: #002B50;


              a {
                color: #002B50;
                text-decoration: none;


                &:hover {
                  color: #26A9F4;
                  text-decoration: underline;
                }
              }
            }

            &__subtitle {
              font-size: 12px;
              line-height: 16px;
              color: #6886AF;
            }
          }
        }

        .wrap-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;

          .wrap-btn-search {
            display: none;
          }

          .search {
            display: flex;
            align-items: center;
            padding-left: 20px;
            margin-left: 20px;
            border-left: 1px solid #E7F5FF;

            &__icon {
              svg  {
                margin-right: 0;
              }
            }

            .cancel-icon {
              cursor: pointer;
              width: 10px;

              svg {
                transition: .3s;
                margin-right: 0;
                color: #607EA7;
              }

              &:hover {
                svg {
                  transform: scale(1.15);
                  transition: .3s;
                  color: #26A9F4;
                }
              }
            }
          }
        }

        .v-btn.btn-search,
        .v-btn.btn-sorting {
          background: #fff;
          border-radius: 8px;
          transition: .3s;

          &.v-btn:not(.v-btn--round).v-size--default {
            height: 40px;
            min-width: 40px;
            padding: 0;
          }

          &:hover {
            background-color: #E7F5FF;
            transition: .3s;
          }

          &.v-btn:before {
            background-color: #E7F5FF;
            transition: .3s;
          }

        }
      }

      &__body {
        border-radius: 0 0 4px 4px;
      }
    }

    .block-sorting {
      width: 320px;
      flex-shrink: 0;
      overflow: auto;
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1240px){
    .wrapper-affiliate {
      padding: 20px 20px 0;
    }

    .head-affiliate {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: 1200px){
    .head-affiliate {
      justify-content: space-around;
      img {
        display: none;
      }
    }
  }

  @media screen and (max-width: 950px){

    .wrapper-affiliate.partner {
      flex-direction: column-reverse;
    }

    .wrapper-affiliate {
      flex-direction: column-reverse;

      .block-sidebar-affiliate {
        margin-left: 0;
        width: 100%;
      }
    }
    .head-affiliate {
      justify-content: space-between;

      &__left {
        flex-shrink: 0;
      }

      img {
        display: block;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .wrapper-affiliate {
      padding: 20px 15px 0;
    }
    .head-affiliate {
      height: 110px;
      padding: 0 10px;

      &__link {
        margin: 0 0 0 10px;
      }

      img {
        display: none;
      }
    }
    .wrap-body-affiliate {
      .body-affiliate {
        &__head {
          padding: 0 10px;
        }
      }
    }
  }

  @media screen and (max-width: 600px){
    .wrap-body-affiliate {
      .body-affiliate {
        &__head {
          .wrap-btn {
            .search {
              position: absolute;
              left: 0;
              bottom: -41px;
              width: 100%;
              background-color: #fff;
              display: flex;
              align-items: center;
              padding: 0 20px 4px 10px;
              height: 40px;
              transform: translateY(-40px);
              transition: .3s;
              opacity: 0;
              margin: 0;
              border: none;
              border-bottom: 1px solid #ECF4FF;
              z-index: -1;

              &__input {
                width: 100%;
              }
            }

            .search.active {
              transform: translateY(0);
              height: 40px;
              transition: .3s;
              opacity: 1;
              z-index: 10;
            }

            .wrap-btn-search {
              margin-left: 10px;
              display: block;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 550px){
    .head-affiliate {
      padding: 15px 10px;
      height: fit-content;
      flex-direction: column;

      &__left {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
      }

      &__commission {
        margin-bottom: 5px;
        .information {
          align-items: center;

          &:first-child {
            margin-right: 0;
          }
        }
      }

      &__link {
        max-width: 385px;
        margin: 0 10px;
        h6 {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }
</style>
