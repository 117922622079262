<template>
    <div>
        <div class="mainer all-main-content">
            <LayoutNavAuth v-if="auth"></LayoutNavAuth>
            <div class="all-content">
                <div class="wrapper-text">
                    <h1>{{__('For developers!')}}</h1>
                    <p>{{__('You have experience in game development, we offer an amazing opportunity - to develop a game for the "MindPlays" platform with the possibility of receiving a stable income in the form of commissions from each game played. You can develop games for different platforms and OS. Check out our documentation and get income on a permanent basis!')}}</p>
                </div>
                <p class="desc">
                    {{__('Discover a completely new quality of gaming with MINDPLAYS')}}
                    <br />{{__('where excitement is backed up by rewards, and the game itself is an ideal hobby that allows you to make great money!')}}
                </p>
                <router-link :to="{name: 'contact',params: {lang: $store.getters.getLang}}" class="adv">{{__('Build Your Own Permanent Passive Income - Develop a Game for MindPlays')}}</router-link>
                <div class="wrap-start">
                    <h2 class="wrap-start__title">{{__('Launching a game in an iFrame')}}</h2>
                    <span class="wrap-start__desc">
            <p
                    class="mb-0"
            >{{__('As soon as the players have confirmed their participation in the match, the MindPlays service sends the players to a page with an iFrame.')}}</p>{{__('The iFrame window is called with the following GET parameters:')}}
          </span>
                    <div class="wrap-start__info">
                        <span class="green--text bold">user_id</span> - {{__('user id in the MP system')}}
                    </div>
                    <div class="wrap-start__info">
                        <span class="green--text bold">room_id</span> - {{__('id of this room')}}
                    </div>
                    <div class="wrap-start__info">
                        <span class="green--text bold">battle_id</span> - {{__('the identifier of the given match')}}
                    </div>
                    <div class="wrap-start__info mb-4">
                        <span class="green--text bold">hash</span> - md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.
                        <span class="red--text">$secret</span>)
                    </div>
                    <div class="wrap-start__info wrap-start__info--last mb-6">
                        <span class="red--text bold">$secret</span> - {{__('can be found on the developer page for each game it has its own')}}<br>
                        <span class="red--text bold">$game_id</span> - {{__('game id, it can be found on the developer page in the game settings')}}
                    </div>

                    <h2 class="wrap-start__caption mb-4">{{__('Getting information about players')}}</h2>
                    <p class="mb-0">{{__('As soon as the user was redirected to the game page.')}}</p>
                    <p>{{__('You need to send an API request to get information about each of the players:')}}</p>
                    <div class="green--text mb-4 bold">
                        <span class="blue--text">POST</span> https://{{$APP_DOMEN}}/api/v1/info_game
                    </div>
                    <p class="mb-0">{{__('with parameters:')}}</p>
                    <div class="wrap-start__info">
                        <span class="green--text bold">game_id</span>- {{__('can be found on the developer page for each game it has its own')}}
                    </div>
                    <div class="wrap-start__info">
                        <span class="green--text bold">user_id</span> - {{__('user id in the MP system')}}
                    </div>
                    <div class="wrap-start__info">
                        <span class="green--text bold">room_id</span> - {{__('id of this room')}}
                    </div>
                    <div class="wrap-start__info">
                        <span class="green--text bold">battle_id</span> - {{__('the identifier of the given match')}}
                    </div>
                    <div class="wrap-start__info">
                        <span class="green--text bold">timestamp</span> - {{__('timestamp')}}
                    </div>
                    <div class="wrap-start__info mb-6">
                        <span class="green--text bold">hash</span> - md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'.
                        <span class="red--text">$secret</span>)
                    </div>

                    <div class="grid-wrapper">
                        <div class="wrap-answer ">
                            <p class="wrap-answer__caption">
                                {{__('Example')}}
                                <span class="green--text">{{__('successful')}}</span> {{__('answer with MindPlays')}}
                            </p>
                            <div class="block-answer">
                <pre>

      {
         "data": {
           "status": true,
           "msg": "",
           "game_id": "1",
           "room_id": "1804403095",
           "battle_id": "1611161955",
           "user_id": "1",
           "amount": "0.91",
           "lang": "en",
           "game": {
             "attributes": [
                {
                  "key": "map",
                  "option": "poolday"
                },
                {
                  "key": "weapon",
                  "option": "m_16"
                }
             ],
             "wallet": 1
           },
           "user": {
             "name": "Happy Proger",
             "photo": "https://mindplays.com/assets/users/1.jpg",
             "location": "United Kingdom",
             "location_img": "https://mindplays.com/dist/images/flags/de.png",
             "rank": 5,
             "rating_points": 4114
           }
         },
         "status": 200
      }
                </pre>
                            </div>
                        </div>

                        <div class="wrap-answer">
                            <p class="wrap-answer__caption">
                                {{__('Sample MindPlays Response')}}
                                <span class="red--text">{{__('about the error')}}</span>
                            </p>
                            <div class="block-answer">
                <pre>

      {
         "status": 400,
         "error": {
             "message": "hash does not match"
         }
      }
                </pre>
                            </div>
                        </div>
                    </div>

                    <p
                            class="text"
                    >{{__('After receiving full information about the game and the user, if the MP did not return an error. You can let the user play')}}</p>
                </div>


                <div class="wrap-finish">
                    <h2>{{__('Closing a match on error')}}</h2>
                    <p class="ma-0">{{__('If there is some kind of error in the game and the match cannot be completed.')}}</p>
                    <p>{{__('You need to send an API request to close this match')}}</p>
                    <div class="green--text mb-4 bold">
                        <span class="blue--text">POST</span> https://{{$APP_DOMEN}}/api/v1/close_game
                    </div>
                    <p>{{__('with parameters')}}</p>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">user_id</span> - {{__('user id in the MP system')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">game_id</span> - {{__('can be found on the developer page for each game it has its own')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">room_id</span> - {{__('id of this room')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">battle_id</span> - {{__('the identifier of the given match')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">timestamp</span> - {{__('timestamp')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">comment</span> - {{__('comment on why the match was canceled')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">hash</span> - md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'.
                        <span class="red--text">$secret</span>)
                    </div>
                </div>


                <div class="grid-wrapper">
                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">
                            {{__('Example')}}
                            <span class="green--text">{{__('successful')}}</span> {{__('answer with MindPlays')}}
                        </p>
                        <div class="block-answer">
              <pre>

      {
         "data": {
            "status": true,
            "msg": "",
            "game_id": "1",
            "room_id": "1804403095",
            "battle_id": "1611161955",
            "user_id": "1"
         },
         "status": 200
      }
                </pre>
                        </div>
                    </div>

                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">
                            {{__('Sample MindPlays Response')}}
                            <span class="red--text">{{__('about the error')}}</span>
                        </p>
                        <div class="block-answer">
              <pre>

      {
         "status": 400,
         "error": {
            "message": "Hash does not match"
         }
      }
                </pre>
                        </div>
                    </div>
                </div>






                <div class="wrap-finish">
                    <h2>{{__('Finishing the game in an iFrame')}}</h2>
                    <p>{{__('At the end of the game, you must send a request with the results of the match')}}</p>
                    <div class="green--text mb-4 bold">
                        <span class="blue--text">POST</span> https://{{$APP_DOMEN}}/api/v1/result_game
                    </div>
                    <p>{{__('with parameters')}}</p>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">user_id</span> - {{__('user id in the MP system')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">game_id</span> - {{__('can be found on the developer page for each game it has its own')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">room_id</span> - {{__('id of this room')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">battle_id</span> - {{__('the identifier of the given match')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">timestamp</span> - {{__('timestamp')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">result_amount</span> - {{__('the final balance of the user after the game (the initial balance was available in the info_game request)')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">start_timestamp</span> - {{__('Unix game start time')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">finish_timestamp</span> - {{__('Unix game end time')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">hash</span> - md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'.
                        <span class="red--text">$secret</span>)
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">data</span> -
                        <span class="red--text bold">*</span> {{__('an array of data about the actions of the current user against other players, in other words, these are all operations performed by the user and his means against other players')}}
                    </div>
                </div>

                <div class="wrapper d-flex">
                    <div class="block-example">
                        <p class="bold">
                            <span class="red--text">*</span> {{__('Array example')}}
                            <span class="green--text">"data"</span>
                        </p>
                        <pre>
      <span class="green--text bold">data</span> = [
          [
            <span class="green--text bold">operation_type</span> - {{__('type of transaction')}} ({{__('1 - plus operation')}}) ({{__('2 - minus')}}) ({{__('3 - a draw operation')}})
            <span
                    class="green--text bold"
            >amount</span> - {{__('funds that have been transferred from one player to another')}}
            <span
                    class="green--text bold"
            >opponent_id</span> - {{__('enemy id in the MP system')}}
            <span class="green--text bold">comment</span> -  {{__('comment on the current operation')}}
          ],
          [
             ...
          ]
      ]
            </pre>
                    </div>

                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">{{__('Example of transferring data about the end of the game')}}</p>
                        <div class="block-answer block-answer--white mb-5">
              <pre>

      {
         "user_id": "1",
         "game_id": "1",
         "room_id": "1804403095",
         "battle_id": "1203486345",
         "timestamp": "1611161955",
         "result_amount": "0.50",
         "start_timestamp": "1598141690",
         "finish_timestamp": "1598451690",
         "hash": "f52f1935e6180545f203f470aa02983a",
         "data": [
             {
                "operation_type": "2",
                "amount": "0.41",
                "opponent_id": "2",
                "comment": "kill all"
             }
         ]
      }
                </pre>
                        </div>
                    </div>
                </div>
                <span class="block-example__desc">
          {{__('Plus and minus operations during the game should be displayed in the “data” array. The total value of “result_amount” should correspond to the sum: start of the game ± operations in the array date = result_amount!')}}
          <p class="mb-0 mt-4 bold">{{__('Otherwise, the game will be removed from the MindPlays catalog!')}}</p>
        </span>

                <div class="grid-wrapper">
                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">
                            {{__('Example')}}
                            <span class="green--text">{{__('successful')}}</span> {{__('answer with MindPlays')}}
                        </p>
                        <div class="block-answer">
              <pre>

      {
         "data": {
            "status": true,
            "msg": "",
            "game_id": "1",
            "room_id": "1804403095",
            "battle_id": "1611161955",
            "user_id": "1"
         },
         "status": 200
      }
                </pre>
                        </div>
                    </div>

                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">
                            {{__('Sample MindPlays Response')}}

                            <span class="red--text">{{__('about the error')}}</span>
                        </p>
                        <div class="block-answer">
              <pre>

      {
         "status": 400,
         "error": {
            "message": "Game played not found"
         }
      }
                </pre>
                        </div>
                    </div>
                </div>


                <div class="wrap-finish">
                    <h2>{{__('Check user')}}</h2>
                    <p>{{__('MindPlays anyone can send a POST request to get information about the user')}}</p>
                    <div class="green--text mb-4 bold">
                        <span class="blue--text">POST</span> https:// {game.url} /api/v1/check_user
                    </div>
                    <p>{{__('with parameters')}}</p>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">user_id</span> - {{__('user id in the MP system')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">game_id</span> - {{__('can be found on the developer page for each game it has its own')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">room_id</span> - {{__('id of this room')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">battle_id</span> - {{__('the identifier of the given match')}}
                    </div>
                    <div class="wrap-finish__info">
                        <span class="green--text bold">timestamp</span> - {{__('timestamp')}}
                    </div>
                    <!--         <div class="wrap-finish__info">-->
                    <!--           <span class="green&#45;&#45;text bold">result_amount</span> - итоговый баланс пользователя после игры (начальный баланс был доступен в запросе info_game)-->
                    <!--         </div>-->
                    <!--         <div class="wrap-finish__info">-->
                    <!--           <span class="green&#45;&#45;text bold">start_timestamp</span> - Unix-время начала игры-->
                    <!--         </div>-->
                    <!--         <div class="wrap-finish__info">-->
                    <!--           <span class="green&#45;&#45;text bold">finish_timestamp</span> - Unix-время окончания игры-->
                    <!--         </div>-->
                    <div class="wrap-finish__info">
                        <span class="green--text bold">hash</span> - md5($game_id.':'.$user_id.':'.$room_id.':'.$battle_id.':'.$timestamp.':'.
                        <span class="red--text">$secret</span>)
                    </div>
                </div>

                <div class="wrapper d-flex">
                    <!--         <div class="block-example mr-5">-->
                    <!--           <p class="bold">-->
                    <!--             <span class="red&#45;&#45;text">*</span> Пример масива “-->
                    <!--             <span class="green&#45;&#45;text">data</span>”-->
                    <!--           </p>-->
                    <!--           <pre>-->
                    <!--      <span class="green&#45;&#45;text bold">data</span> = [-->
                    <!--          [-->
                    <!--            <span class="green&#45;&#45;text bold">operation_type</span> - тип операции (1  плюсовая операция) (2 минусовая-->
                    <!--    операция)-->
                    <!--            <span-->
                    <!--                class="green&#45;&#45;text bold"-->
                    <!--            >amount</span> - средства которые перешли от одного игрока к другому-->
                    <!--            <span-->
                    <!--                class="green&#45;&#45;text bold"-->
                    <!--            >opponent_id</span> - id противника в системе MP-->
                    <!--            <span class="green&#45;&#45;text bold">comment</span> -  комментарий к текущей операции-->
                    <!--          ],-->
                    <!--          [-->
                    <!--             ...-->
                    <!--          ]-->
                    <!--      ]-->
                    <!--            </pre>-->
                    <!--         </div>-->

                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">{{__('An example of the expected response if the player has finished the game')}}</p>
                        <div class="block-answer block-answer--white mb-5">
              <pre>

      {
         "user_id": "1",
         "game_id": "1",
         "room_id": "1804403095",
         "battle_id": "1203486345",
         "timestamp": "1611161955",
         "result_amount": "0.50",
         "start_timestamp": "1598141690",
         "finish_timestamp": "1598451690",
         "hash": "f52f1935e6180545f203f470aa02983a",
         "data": [
             {
                "operation_type": "2",
                "amount": "0.41",
                "opponent_id": "2",
                "comment": "kill all"
             }
         ]
      }
                </pre>
                        </div>
                    </div>
                </div>

                <div class="grid-wrapper">
                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">
                            {{__('An example of the expected response if the player is still playing')}}
                        </p>
                        <div class="block-answer block-answer--white">
              <pre>

      {
         "user_id": "1",
         "game_id": "1",
         "room_id": "1804403095",
         "battle_id": "1203486345",
         "timestamp": "1611161955",
         "result_amount": null,
         "start_timestamp": "1598141690",
         "finish_timestamp": null,
         "hash": "f52f1935e6180545f203f470aa02983a",
         "data": null
      }
                </pre>
                        </div>
                    </div>

                    <div class="wrap-answer">
                        <p class="wrap-answer__caption">
                            {{__('Example')}} <span class="red--text">{{__('errors')}}</span> {{__('from the game')}}
                        </p>
                        <div class="block-answer">
              <pre>

      {
         "status": 400,
         "error": {
            "message": "Game played not found"
         }
      }
                </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div  class="block-write">
            <p>{{__('If you have any questions please email us')}}</p>

            <BaseButton :to="{name: 'contact',params: {lang: $store.getters.getLang}}" class_btn="btn-guest">
                <span>
                   {{__('Write to us')}}
                </span>
            </BaseButton>

        </div>
        <div class="block-footer">
            <Footer></Footer>
        </div>
    </div>

</template>

<script>
    import Footer from "../modules/Footer";
    import LayoutNavAuth from "../modules/LayoutNavAuth";
    import BaseButton from "../modules/BaseButton";
    export default {
        data() {
            return {

            }
        },
        computed: {
            auth() {
                return this.$store.state.auth.user
            }
        },
        components: {
            LayoutNavAuth,
            Footer,
            BaseButton
        }
    }
</script>

<style lang="scss" scoped>
.mainer {
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 0;
  /*background: linear-gradient(*/
  /*  180deg,*/
  /*  #ffffff 0%,*/
  /*  rgba(255, 255, 255, 0.5) 100%*/
  /*);*/
}

.image {
  position: absolute;
  top: 136px;
  right: 85px;
}
.head {
  display: flex;
  justify-content: space-between;
  padding: 24px 0 12px 10px;
  border-bottom: 1px solid #e4e7f3;
  margin-bottom: 20px;

  &__title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    color: #002b50;
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #96afd1;
    max-width: 530px;
  }
}

.wrapper-text {
    padding-top: 43px;
  h1 {
    color: #fff;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 33px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 770px;
    font-weight: 400;
  }
}

.desc {
  font-weight: bold;
  font-size: 16px;
  line-height: 35px;
  color: #96AFD1;
  margin-bottom: 28px;
}

.adv {
  color: #26A9F4;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 38px;
  display: block;

    &:hover {
        text-decoration: underline;
    }
}

.wrapper-developers {
  display: flex;
  margin-bottom: 60px;
}

.block-developer {
  display: flex;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  img {
    margin-right: 12px;
    border-radius: 6px;
  }

  span {
    font-size: 14px;
    color: #364f6a;

    button {
      color: #26a9f4;
      outline: none;
      margin-left: 10px;
    }
  }

  p {
    font-size: 14px;
    color: #96afd1;
    line-height: 19px;
    margin: 0;
  }
}

h5 {
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: #324661;
  margin-bottom: 20px;
}

.wrap-links {
  display: flex;

  .wrap {
    margin-right: 40px;
    font-size: 14px;

    a {
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.wrap-start {
  font-size: 14px;
  color: #002b50;

    p {
        color: rgba(255, 255, 255, 0.8);
    }

  &__caption {
    color: #fff;
  }

  &__title {
    margin-bottom: 16px;
    color: #fff;
  }

  &__desc {
    margin-bottom: 16px;
    display: block;
    color: rgba(255, 255, 255, 0.8);
  }

  &__info {
    color: rgba(255, 255, 255, 0.8);
    word-wrap: break-word;

    &--last {
      color: #fff;
    }
  }
}

.grid-wrapper {
  display: flex;
  margin-bottom: 20px;
}

.wrap-answer {
    &:first-child {
        margin-right: 20px;
    }
  &__caption {
    color: #fff;
  }
}

.block-answer {
  width: 520px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  font-size: 14px;

  &--white {
    background: rgba(255, 255, 255, 0.9);
    /*border: 2px solid #edeff7;*/
  }

  pre {
    //font-family: "UniNeue";
  }
}

.text {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 68px;
  color: #ff4b55;
}

.wrap-finish {
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;

    h2 {
        margin-bottom: 16px;
        color: #fff;
    }

  &__info {
    color: rgba(255, 255, 255, 0.8);
    word-wrap: break-word;
  }
}

.block-example {
  max-width: 520px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  margin-right: 20px;

    p {
        color: #fff;
    }

  pre {
      color: #fff;
    //font-family: "UniNeue";
  }

  &__desc {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ff4b55;
    margin-bottom: 40px;
  }
}
.bold {
  font-weight: bold;
}

.block-write {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(87.63deg, #1C2530 0.88%, #2C3A4E 99.45%);
    padding: 30px 0;

    p {
        font-size: 16px;
        line-height: 20px;
        color: #fff;
        margin: 0 20px 0 0;
    }
}

@media screen and (max-width: 1100px){
    .grid-wrapper {
        flex-direction: column;
    }

    .wrap-answer {
        &:first-child {
            margin-right: 0;
        }
    }

    .block-answer {
        max-width: fit-content;
        width: auto;
        padding-right: 20px;
        min-width: 500px;
    }

    .wrapper {
        flex-direction: column;
    }

    .block-example {
        max-width: fit-content;
        min-width: 500px;
        margin-right: 0;
    }
}

    @media screen and (max-width: 768px){
        .block-answer {
            padding-right: 0;
        }
    }

    @media screen and (max-width: 550px){
        .block-answer {
            min-width: 300px;
        }

        pre {
            padding-right: 20px;
        }

        .wrapper {
            flex-direction: column;
        }

        .block-example {
            max-width: fit-content;
            min-width: 300px;
        }
    }
</style>