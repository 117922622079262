<template>
    <tr>
        <td>{{log.created_at}}</td>
<!--        <td>{{parseBrowser.getOS().name + ': ' + parseBrowser.getOS().version}}</td>-->
        <td>
            <div class="block-column">
                <span>{{parseBrowser.getOS().name}}</span>
                <span class="version">{{parseBrowser.getOS().version}}</span>
            </div>
        </td>
<!--        <td>{{parseBrowser.getBrowser().name +': '+ parseBrowser.getBrowser().version}}</td>-->
        <td>
            <div class="block-column">
                <span>{{parseBrowser.getBrowser().name }}</span>
                <span class="version">{{parseBrowser.getBrowser().version}}</span>
            </div>
        </td>
        <td>{{log.ip}}</td>
        <td>{{log.location}}</td>
    </tr>
</template>

<script>
    import UAParser from "user-agent-parser";

    export default {
        name: 'SettingsLogsItem',
        data() {
            return {
                parseBrowser: new UAParser().setUA(this.log.browser)
            }
        },

        props: {
            log: {
                type: Object
            }
        },
    }
</script>

<style lang="scss" scoped>


.block-column {
    display: flex;
    flex-direction: column;
}

.version {
    color: #6886AF;
}

.dark .version {
    color: #96AFD1;
}
</style>