import {integer, required, minValue, maxValue} from "vuelidate/lib/validators";
import moment from "moment";

const { ethers } = require('ethers');

export const MarketPlaceMixin = {
    data() {
        return {
            tokenIdShift: 0,
            accounts: null,
            chain: {
                id: 42,
                name: 'Kovan',
                currency: 'eth',
                prefix: 'kovan.',
            },
            zeroAddress: '0x0000000000000000000000000000000000000000',
            waiting: false,
            fee: 10,
            marketPlace: null,
            img: null,
            mime: null,
            createImgUrl: null,
            imgFormat: null,
            sourceImgUrl: null,
            field: null,
            metamask: null,
            modal_cancel: null,
            switch_network: null,
            loading: null
        }
    },
    validations() {
        return {
            sale: {
                price: {
                    required,
                    integer
                },
            },
            auction: {
                start_bid: {
                    required,
                    integer,
                    minValue: minValue(1)
                },
                step: {
                    required,
                    integer,
                    minValue: minValue(1)
                },
                single_price: {
                    integer
                },
                time_start: {
                    required
                },
                time_finish: {
                    required
                },
            },
            lot: {
                bit_sale: {
                    required,
                    integer,
                    minValue: minValue((this.nft && this.nft.type== 'auction') ? this.nft.min_bid : 1),
                    maxValue: maxValue(this.nft && this.nft.price)
                },
            }
        }
    },
    created() {
        console.log('teetteettete');
    },
    computed: {
        startLot() {
            return moment.utc(this.nft.start_time).format('x');
        },
        finishLot() {
            return moment.utc(this.nft.end_time).format('x');
        }
    },
    methods: {
        async approveAddress() {
            this.loading= true;
            const tx = await this.paymentToken.approve(this.marketPlace.address, 1);

            await tx.wait();
            const txReady = await tx.wait(4);

            if (txReady.status !== 1) {
                this.$notify({
                    group: "message",
                    title: "Transaction failed",
                });
                this.loading= false;
                return false;
            }

            this.loading= false;
            let query= {
                'address': tx.from,
                'hash': tx.hash,
            };

            this.$http.post('ajax/nft/approve', query)
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    if(this.$checkAuth(response)) {
                        // let auth= this.$cleanObject(this.auth);
                        //
                        // auth.web3_address= tx.from;
                        //
                        // this.$store.dispatch("asyncUserInfo", {
                        //     "data": auth
                        // });

                        window.location.reload();
                    }
                }).catch(err => {
                this.httpHandler(err);
            });
            console.log('this.accounts');
            console.log(tx);
            console.log(tx.hash);
        },
        validateTime(type) {
            let date_start= this.auction.date_start;
            let time_start=  this.auction.time_start;
            let current_date= moment().utc().format('X');

            let time= moment(date_start+' '+time_start).format('X');

            if(type=='time_start') {

                if(current_date > time) {
                    this.auction.time_start= moment.utc().add('1', 'hours').format('HH:mm');
                }
                this.minStart= moment((this.auction.time_start) ? this.auction.date_start+' '+this.auction.time_start : null).add(1, 'hours').format('YYYY-MM-DD');
            } else {
                let time_finish= moment(this.auction.date_finish+' '+this.auction.time_finish).format('X');

                if(time > time_finish) {
                    this.auction.time_finish= moment.utc(date_start+' '+time_start).add('1', 'hours').format('HH:mm');
                }
            }
        },
        formatDate (date) {
            if (!date) return null;

            const [year, month, day] = date.split('-');
            return `${month}/${day}/${year}`;
        },
        parseDate (date) {
            if (!date) return null;

            const [month, day, year] = date.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        async switchNetwork() {
            this.switch_network= true;

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${this.chain.id.toString(16)}` }],
            });

            return true;
        },
        async connectAccount() {
            this.switch_network= true;

            await window.ethereum.request({ method: 'eth_requestAccounts' });

            return true;
        },
        async createMintingRecord(tokenUri, price) {
            let tx= await this.marketPlace.createMintingRecord(
                tokenUri,
                ethers.utils.parseUnits(price.toString())
            )
                .catch((err) => {
                    console.log('err createMintingRecord');
                    console.log(err);
                    this.updateMinting(tokenUri, 2);
                });

            console.log('createMintingRecord');
            console.log(tokenUri);
            console.log(tx);
            if(tx) {
                this.updateMinting(tokenUri, 1);
            }
            return tx;
        },
        updateValidate: function (type, validate= 'sale') {
            console.log('validate');
            console.log(validate);
            console.log(this.$v);
            this.formValidate = this.$changeValidate(this.formValidate, this.$v[validate][type], type);
        },
        async checkout(i) {
            (async () => {
                if(!this.accounts) {
                    this.metamask= {
                        'error': 'account',
                        'message': "Не вошли в аккаунт MetaMask"
                    };
                    return false;
                }

                if(this.nft.type== 'auction') {
                    let valid = this.$v.lot;

                    if (valid.$invalid) {
                        let massages = {
                            bit_sale: [],
                        };
                        this.formValidate = this.$formValidate(massages, valid);

                        return false;
                    }
                }
                console.log('this.nft.buyer');
                console.log(this.nft.buyer);
                this.switch_network= true;



                if(this.nft.buyer== this.zeroAddress) {
                    const record = await this.marketPlace.getMintingRecordData(i);
                    if (record.owner === this.zeroAddress) return null;
                    // const tokenId = Number(record.tokenId);
                    const accepted = Number(record.accepted);
                    const cancelled = Number(record.cancelled);
                    const completed = Number(record.completed);

                    let owner= record.owner;

                    if(completed > 0) {
                        if(this.nft.token_id) {
                            owner = await this.nftToken.ownerOf(this.nft.token_id);
                        }

                        if(owner.toLowerCase() != this.$contract.marketPlace.address.toLowerCase()) {
                            this.$notify({
                                group: "message",
                                title: "NFT уже продан",
                            });

                            if(this.nft) {
                                this.nft.status= 5;
                                this.nft.buyer= record.buyer;
                                this.modal_cancel= null;
                                return null;
                            }
                        }
                    } else if (!(accepted > 0) || cancelled > 0 || completed > 0) return null;
                    const balance = await this.paymentToken.balanceOf(this.accounts);

                    if (!balance.gte(record.price)) {

                        this.$notify({
                            group: "message",
                            title: "Not enough token balance",
                        });
                        return false;
                    }
                    const allowance = await this.paymentToken.allowance(this.accounts, this.marketPlace.address);

                    if (!allowance.gte(record.price)) {

                        this.$notify({
                            group: "message",
                            title: "Please approve tokens usage to the marketplace contract first",
                        });

                        const tx = await this.paymentToken.approve(this.marketPlace.address, record.price);
                        this.txMessage(tx.hash);
                        await tx.wait();
                        const txReady = await tx.wait(4);

                        if (txReady.status !== 1) {

                            this.$notify({
                                group: "message",
                                title: "Transaction failed",
                            });
                            return false;
                        }


                        let query= {
                            'address': tx.from,
                            'hash': tx.hash,
                        };

                        this.$http.post('ajax/nft/approve', query)
                            .then(response => {
                                return response.json()
                            })
                            .then(response => {
                                if(this.$checkAuth(response)) {
                                    let auth= this.$cleanObject(this.auth);

                                    auth.web3_address= tx.from;

                                    this.$store.dispatch("asyncUserInfo", {
                                        "data": auth
                                    });

                                }
                            }).catch(err => {
                            this.httpHandler(err);
                        });
                    }

                    const tx = await this.marketPlace.getNft(i);

                    console.log('record.buyer');
                    console.log(record.buyer);
                    console.log(tx);
                    // if(tx) {
                    //     let request= {
                    //         "record_id": i,
                    //         "hash": tx.hash
                    //     };
                    //
                    //     this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/nft/tx', request)
                    //         .then(response => {
                    //             return response.json()
                    //         })
                    //         .catch(err => {
                    //             this.httpHandler(err);
                    //         });
                    // }

                    this.txMessage(tx.hash);
                    await tx.wait();
                    const txReady = await tx.wait(4);

                    if (txReady.status !== 1) {
                        this.$notify({
                            group: "message",
                            title: "Transaction failed",
                        });
                        this.modal_cancel= false;
                        return false;
                    }
                    this.nft.owner= this.accounts;
                    this.nft.status= 5;

                    await this.getRecord(i, tx.hash);
                } else if(this.nft.type== 'auction')  {

                    const now = Math.round(Date.now() / 1000);
                    const record = await this.marketPlace.getLotData(this.nft.sale_id);
                    const completed = Number(record.uintValues[8]);
                    const startTime = Number(record.uintValues[1]);
                    const endTime = Number(record.uintValues[2]);
                    if (completed > 0) {
                        this.$notify({
                            group: "message",
                            title: "This lot already completed",
                        });
                        this.modal_cancel= false;
                        return false;
                    }

                    if (!(now > startTime)) {
                        this.$notify({
                            group: "message",
                            title: "This lot is not started yet",
                        });
                        this.modal_cancel= false;
                        return false;
                    }
                    if (!(endTime > now)) {
                        this.$notify({
                            group: "message",
                            title: "This lot already ended",
                        });
                        this.modal_cancel= false;
                        return false;
                    }
                    const step = Number(ethers.utils.formatUnits(record.uintValues[3]));
                    const startBid = Number(ethers.utils.formatUnits(record.uintValues[4]));
                    const lastBid = Number(ethers.utils.formatUnits(record.uintValues[5]));
                    const minBid = lastBid > 0 ? lastBid + step : startBid;

                    let amount = this.lot.bit_sale;
                    console.log('minBid', minBid, 'amount', amount);
                    if (!(amount >= minBid)) {
                        this.$notify({
                            group: "message",
                            title: "Amount should not be less than min bid",
                        });
                        this.modal_cancel= false;
                        return false;
                    }
                    amount = ethers.utils.parseUnits(amount.toString());
                    const balance = await this.paymentToken.balanceOf(this.accounts);
                    if (!balance.gte(amount)) {

                        this.$notify({
                            group: "message",
                            title: "Not enough token balance",
                        });
                        this.modal_cancel= false;
                        return false;
                    }

                    const allowance = await this.paymentToken.allowance(this.accounts, this.marketPlace.address);
                    if (!allowance.gte(amount)) {

                        this.$notify({
                            group: "message",
                            title: "Please approve tokens usage to the marketplace contract first",
                        });

                        const tx = await this.paymentToken.approve(this.marketPlace.address, amount);
                        this.txMessage(tx.hash);
                        await tx.wait();
                        const txReady = await tx.wait(3);
                        if (txReady.status !== 1) {
                            this.$notify({
                                group: "message",
                                title: "Transaction failed",
                            });
                            this.modal_cancel= false;
                            return false;
                        }


                        let query= {
                            'address': tx.from,
                            'hash': tx.hash,
                        };

                        this.$http.post('ajax/nft/approve', query)
                            .then(response => {
                                return response.json()
                            })
                            .then(response => {
                                if(this.$checkAuth(response)) {
                                    let auth= this.$cleanObject(this.auth);

                                    auth.web3_address= tx.from;

                                    this.$store.dispatch("asyncUserInfo", {
                                        "data": auth
                                    });

                                }
                            }).catch(err => {
                            this.httpHandler(err);
                        });
                    }

                    let tx = await this.marketPlace.bid(this.nft.sale_id, amount);

                    this.txMessage(tx.hash);
                    await tx.wait();
                    const txReady = await tx.wait(4);

                    if (txReady.status !== 1) {
                        this.$notify({
                            group: "message",
                            title: "Transaction failed",
                        });
                        this.modal_cancel= false;
                        return false;
                    }

                    if(Number(this.lot.bit_sale) >= Number(this.nft.price)) {
                        this.nft.owner= this.accounts;
                        this.nft.status= 5;
                    } else {
                        this.nft.last_bid_sender= this.accounts;
                        this.nft.status= 3;
                        this.nft.min_bid= Number(this.lot.bit_sale)+ Number(this.nft.step);
                        this.nft.last_bid= this.lot.bit_sale;
                    }

                    await this.getRecord(i, tx.hash);

                    return true;
                } else {
                    let sale_id= this.nft.sale_id;
                    const record = await this.marketPlace.getSaleRecordData(sale_id);
                    if (record.owner === this.zeroAddress) return null;
                    const completed = Number(record.completed);
                    if (completed > 0) return null;
                    const balance = await this.paymentToken.balanceOf(this.accounts);
                    if (!balance.gte(record.price)) {
                        this.$notify({
                            group: "message",
                            title: "Not enough token balance",
                        });
                        this.modal_cancel= false;
                        return false;
                    }

                    const allowance = await this.paymentToken.allowance(this.accounts, this.marketPlace.address);

                    if (!allowance.gte(record.price)) {
                        this.$notify({
                            group: "message",
                            title: "Please approve tokens usage to the marketplace contract first",
                        });

                        const tx = await this.paymentToken.approve(this.marketPlace.address, record.price);
                        this.txMessage(tx.hash);
                        await tx.wait();
                        const txReady = await tx.wait(3);
                        if (txReady.status !== 1) {
                            this.$notify({
                                group: "message",
                                title: "Transaction failed",
                            });
                            this.modal_cancel= false;
                            return false;
                        }


                        let query= {
                            'address': tx.from,
                            'hash': tx.hash,
                        };

                        this.$http.post('ajax/nft/approve', query)
                            .then(response => {
                                return response.json()
                            })
                            .then(response => {
                                if(this.$checkAuth(response)) {
                                    let auth= this.$cleanObject(this.auth);

                                    auth.web3_address= tx.from;

                                    this.$store.dispatch("asyncUserInfo", {
                                        "data": auth
                                    });

                                }
                            }).catch(err => {
                            this.httpHandler(err);
                        });
                    }

                    let tx = await this.marketPlace.buy(this.nft.sale_id);

                    this.txMessage(tx.hash);
                    await tx.wait();
                    const txReady = await tx.wait(4);

                    if (txReady.status !== 1) {
                        this.$notify({
                            group: "message",
                            title: "Transaction failed",
                        });
                        this.modal_cancel= false;
                        return false;
                    }

                    this.nft.owner= this.accounts;
                    this.nft.status= 5;

                    await this.getRecord(i, tx.hash);

                    return true;
                }
            })()
            .catch((error)=> {

                // const message = error?.message ?? (typeof error === 'string' ? error : 'An error occurred');
                console.error('error');
                console.error(error);

                this.modal_cancel= false;
                //
                // this.$notify({
                //     group: "message",
                //     title: message,
                // });
            });
        },
        async cancelNft() {
            if(this.nft.token_id) {

                (async () => {
                    this.switch_network= true;
                    let recordNumber = Number(await this.marketPlace.getUserSaleRecordsNumber(this.accounts));
                    if (!(recordNumber > 0)) recordNumber = 0;
                    for (let i = recordNumber; i > 0; i --) {
                        const id = Number(await this.marketPlace.getUserSaleRecordId(this.accounts, i));

                        const record = await this.marketPlace.getSaleRecordData(id);
                        const completed = Number(record.completed);

                        if(!(completed > 0) && record.tokenId== this.nft.token_id) {
                            console.log('cancelSaleRecord');
                            console.log(id);
                            console.log(i);
                            const tx = await this.marketPlace.cancelSaleRecord(id);

                            console.log('cancelNft');
                            console.log(tx);

                            this.nft.owner= this.accounts;
                            this.nft.status= 5;

                            this.txMessage(tx.hash);
                            const txResult = await tx.wait(4);
                            if (txResult.status !== 1) {
                                this.$notify({
                                    group: "message",
                                    title: "Transaction failed",
                                });
                                this.modal_cancel= false;
                                return  null;
                            }

                            await this.getRecord(this.nft.record_id, tx.hash);
                            return  true;
                        }
                    }

                    this.modal_cancel= false;
                })()
                    .catch((error)=> {

                        // const message = error?.message ?? (typeof error === 'string' ? error : 'An error occurred');
                        console.error('error');
                        console.error(error);

                        this.modal_cancel= false;
                        //
                        // this.$notify({
                        //     group: "message",
                        //     title: message,
                        // });
                    });
            } else {
                this.modal_cancel= false;
            }
        },
        async saleNft(i) {
            console.log('saleNft');
            console.log(i);
            if(this.nft.token_id) {
                let valid = this.$v.sale;

                if (valid.$invalid) {
                    let massages = {
                        price: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);

                } else {

                    (async () => {
                        const price = this.primNum(this.sale.price, 0);
                        const tokenId = this.nft.token_id;
                        this.switch_network= true;

                        if (!(price > 0) || !(tokenId > 0)) {
                            this.$notify({
                                group: "message",
                                title: "Please fill in all fields",
                            });
                            this.modal_cancel= false;
                            return  null;
                        }
                        try {
                            const owner = await this.nftToken.ownerOf(tokenId);

                            console.log('this.accounts.toLowerCase()');
                            console.log(this.accounts.toLowerCase());
                            console.log(owner);

                            if (owner.toLowerCase() !== this.accounts.toLowerCase()) {
                                this.$notify({
                                    group: "message",
                                    title: "Active address does not own this NFT",
                                });
                                this.modal_cancel= false;
                                return  null;
                            }
                            const forAll = await this.nftToken.isApprovedForAll(this.accounts, this.marketPlace.address);
                            const approvedAddress = await this.nftToken.getApproved(tokenId);

                            console.log('forAll');
                            console.log(forAll);
                            console.log(this.marketPlace.address);
                            console.log(approvedAddress);

                            if (!forAll && this.marketPlace.address !== approvedAddress) {
                                this.$notify({
                                    group: "message",
                                    title: "Please approve NFT usage to the marketplace contract first",
                                });

                                const tx = await this.nftToken.approve(this.marketPlace.address, tokenId);
                                this.txMessage(tx.hash);
                                const txResult = await tx.wait(4);
                                if (txResult.status !== 1) {
                                    this.$notify({
                                        group: "message",
                                        title: "Transaction failed",
                                    });
                                    this.modal_cancel= false;
                                    return  null;
                                }

                            }
                        } catch(e) {
                            console.log('not found');
                            console.error(e);
                            this.$notify({
                                group: "message",
                                title: "NFT not found",
                            });
                            this.modal_cancel= false;
                            return  null;
                        }

                        const tx = await this.marketPlace.createSaleRecord(
                            tokenId,
                            ethers.utils.parseUnits(price.toString())
                        );

                        this.nft.owner= this.$contract.marketPlace.address;
                        this.nft.status= 3;
                        this.nft.price= this.sale.price;

                        await this.getRecord(i, tx.hash);
                    })()
                        .catch((error)=> {

                            // const message = error?.message ?? (typeof error === 'string' ? error : 'An error occurred');
                            console.error('error');
                            console.error(error);

                            this.modal_cancel= false;
                            //
                            // this.$notify({
                            //     group: "message",
                            //     title: message,
                            // });
                        });
                }
            } else {
                this.modal_cancel= false;
            }
        },
        async auctionNft(i) {
            console.log('saleNft');
            console.log(i);
            if(this.nft.token_id) {
                let valid = this.$v.auction;

                if (valid.$invalid) {
                    let massages = {
                        start_bid: [],
                        step: [],
                        single_price: [],
                        bit_sale: [],
                        date_start: [],
                        time_start: [],
                        date_finish: [],
                        time_finish: [],
                    };
                    this.formValidate = this.$formValidate(massages, valid);

                } else {
            //
                    (async () => {
                        let date_start= this.auction.date_start+' '+this.auction.time_start,
                            date_finish= this.auction.date_finish+' '+this.auction.time_finish;
                            let startTime= moment.utc(date_start).format('X');
                            let endTime= moment.utc(date_finish).format('X');
                            let timestamp = moment.utc().format('X');

                            const startBid = this.auction.start_bid;
                            const step = this.auction.step;
                            const singlePaymentValue = this.auction.single_price;
                            const tokenId = this.nft.token_id;

                            if (!(endTime > startTime)) {
                                // this.formValidate['dates']= ['End time should be greater than start time'];
                                // return false;
                                [endTime, startTime]= [startTime, endTime];
                            }
                            console.log('startTime');
                            console.log(startTime);
                            console.log(timestamp);

                            if (!(startTime > timestamp)) {
                                this.formValidate['date_finish']= ['Start time should be in future'];
                                return false;
                            }
                            if (!(endTime > startTime)) {
                                this.formValidate['date_finish']= ['End time should be greater than start time'];
                                return false;
                            }
                            if (singlePaymentValue > 0 && !(singlePaymentValue > startBid)) {
                                this.formValidate['single_price']= ['Single payment value should be greater than start bid'];
                                return false;
                            }


                            try {
                                const owner = await this.nftToken.ownerOf(tokenId);

                                console.log('this.accounts.toLowerCase()');
                                console.log(this.accounts.toLowerCase());
                                console.log(owner);

                                if (owner.toLowerCase() !== this.accounts.toLowerCase()) {
                                    this.$notify({
                                        group: "message",
                                        title: "Active address does not own this NFT",
                                    });
                                    this.modal_cancel= false;
                                    return  null;
                                }
                                const forAll = await this.nftToken.isApprovedForAll(this.accounts, this.marketPlace.address);
                                const approvedAddress = await this.nftToken.getApproved(tokenId);

                                if (!forAll && this.marketPlace.address !== approvedAddress) {
                                    this.$notify({
                                        group: "message",
                                        title: "Please approve NFT usage to the marketplace contract first",
                                    });

                                    const tx = await this.nftToken.approve(this.marketPlace.address, tokenId);
                                    this.txMessage(tx.hash);
                                    const txResult = await tx.wait(4);
                                    if (txResult.status !== 1) {
                                        this.$notify({
                                            group: "message",
                                            title: "Transaction failed",
                                        });
                                        this.modal_cancel= false;
                                        return  null;
                                    }

                                }
                            } catch(e) {
                                console.log('not found');
                                console.error(e);
                                this.$notify({
                                    group: "message",
                                    title: "NFT not found",
                                });
                                this.modal_cancel= false;
                                return  null;
                            }

                            const tx = await this.marketPlace.createLot(
                                tokenId,
                                startTime,
                                endTime,
                                ethers.utils.parseUnits(startBid.toString()),
                                ethers.utils.parseUnits(step.toString()),
                                ethers.utils.parseUnits(singlePaymentValue.toString())
                            );

                            this.nft.owner= this.$contract.marketPlace.address;
                            this.nft.buyer= this.$contract.marketPlace.buyer;
                            this.nft.status= 3;
                            this.nft.type= 'auction';
                            this.nft.start_time= date_start;
                            this.nft.end_time= date_finish;
                            this.nft.price= singlePaymentValue;
                            this.nft.step= step;
                            this.nft.last_bid= 0;
                            this.nft.last_bid_sender= this.zeroAddress;
                            this.start_bid.step= startBid;

                            await this.getRecord(i, tx.hash);

            //             const price = this.primNum(this.sale.price, 0);
            //             const tokenId = this.nft.token_id;
            //             this.switch_network= true;
            //
            //             if (!(price > 0) || !(tokenId > 0)) {
            //                 this.$notify({
            //                     group: "message",
            //                     title: "Please fill in all fields",
            //                 });
            //                 this.modal_cancel= false;
            //                 return  null;
            //             }
            //             try {
            //                 const owner = await this.nftToken.ownerOf(tokenId);
            //
            //                 console.log('this.accounts.toLowerCase()');
            //                 console.log(this.accounts.toLowerCase());
            //                 console.log(owner);
            //
            //                 if (owner.toLowerCase() !== this.accounts.toLowerCase()) {
            //                     this.$notify({
            //                         group: "message",
            //                         title: "Active address does not own this NFT",
            //                     });
            //                     this.modal_cancel= false;
            //                     return  null;
            //                 }
            //                 const forAll = await this.nftToken.isApprovedForAll(this.accounts, this.marketPlace.address);
            //                 const approvedAddress = await this.nftToken.getApproved(tokenId);
            //
            //                 console.log('forAll');
            //                 console.log(forAll);
            //                 console.log(this.marketPlace.address);
            //                 console.log(approvedAddress);
            //
            //                 if (!forAll && this.marketPlace.address !== approvedAddress) {
            //                     this.$notify({
            //                         group: "message",
            //                         title: "Please approve NFT usage to the marketplace contract first",
            //                     });
            //
            //                     const tx = await this.nftToken.approve(this.marketPlace.address, tokenId);
            //                     this.txMessage(tx.hash);
            //                     const txResult = await tx.wait(4);
            //                     if (txResult.status !== 1) {
            //                         this.$notify({
            //                             group: "message",
            //                             title: "Transaction failed",
            //                         });
            //                         this.modal_cancel= false;
            //                         return  null;
            //                     }
            //
            //                 }
            //             } catch(e) {
            //                 console.log('not found');
            //                 console.error(e);
            //                 this.$notify({
            //                     group: "message",
            //                     title: "NFT not found",
            //                 });
            //                 this.modal_cancel= false;
            //                 return  null;
            //             }
            //
            //             const tx = await this.marketPlace.createSaleRecord(
            //                 tokenId,
            //                 ethers.utils.parseUnits(price.toString())
            //             );
            //
            //             this.nft.owner= this.$contract.marketPlace.address;
            //             this.nft.status= 3;
            //             this.nft.price= this.sale.price;
            //
            //             await this.getRecord(i, tx.hash);
                    })()
                        .catch((error)=> {

                            // const message = error?.message ?? (typeof error === 'string' ? error : 'An error occurred');
                            console.error('error');
                            console.error(error);

                            this.modal_cancel= false;
                            //
                            // this.$notify({
                            //     group: "message",
                            //     title: message,
                            // });
                        });
                }
            } else {
                this.modal_cancel= false;
            }
        },
        async getRecord(record_id, hash= null) {
            const record = await this.marketPlace.getMintingRecordData(record_id);

            console.log('getRecord');
            console.log(record);

            if(record.buyer) {
                this.nft.buyer= this.accounts;
                let token_id= await this.nftToken.tokenIdByTokenUri(this.nft.token_uri);
                this.nft.token_id= Number(token_id);

                console.log('this.nft');
                console.log(this.nft.token_id);

                this.switch_network= false;

                if(hash) {

                    this.modal_cancel= {
                        'status': 'success',
                        'title': 'Success',
                        'success': true,
                        'message': `Tx hash <a target="_blank" href="https://${this.chain.prefix}etherscan.io/tx/${hash}">${hash}</a> 
                  Transaction completed.`
                    };
                }
            }
            console.log('getRecord');
            console.log(record_id);
        },
        txMessage(hash) {
            this.modal_cancel= {
                'status': true,
                'title': 'Pending',
                'message': `Tx hash <a target="_blank" href="https://${this.chain.prefix}etherscan.io/tx/${hash}">${hash}</a> 
                  Please wait until transaction completed.`
            };
        },
        async details() {
            if(window.ethereum) {
                // this.accounts = window.ethereum.request({
                //     method: 'eth_requestAccounts',
                // });

                let provider = new ethers.providers.Web3Provider(window.ethereum);

                console.log('provider');
                console.log(provider);
                if(provider) {
                    const { chainId } = await provider.getNetwork();


                    console.log('chainId');
                    console.log(chainId);
                    if (chainId !== this.chain.id) {

                        this.metamask= {
                            'error': 'network',
                            'message': `Wrong network. Please switch to the network ${this.chain.name} (chainId ${this.chain.id})`
                        };
                        return false;
                    }


                    if (!window.ethereum.selectedAddress) {

                        this.metamask= {
                            'error': 'account',
                            'message': "Не вошли в аккаунт MetaMask"
                        };
                        return false;
                    }
                    this.accounts = window.ethereum.selectedAddress;

                    this.signer = provider.getSigner();
                    this.paymentToken = new ethers.Contract(
                        this.$contract.paymentToken.address,
                        this.$contract.paymentToken.abi,
                        this.signer
                    );
                    this.marketPlace = new ethers.Contract(
                        this.$contract.marketPlace.address,
                        this.$contract.marketPlace.abi,
                        this.signer
                    );

                    this.nftToken = new ethers.Contract(
                        this.$contract.nftToken.address,
                        this.$contract.nftToken.abi,
                        this.signer
                    );

                    this.metamask= true;

                    return true;
                }
            }
        }
    }
}