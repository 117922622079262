<template>
  <div class="image-crop-upload" v-show="value">
    <div class="vicp-wrap wrap-upload">
      <div class="vicp-close" @click="off">
        <i class="vicp-icon4"></i>
      </div>

      <div class="vicp-step1" v-show="step == 1">
        <div class="vicp-drop-area d-flex" @dragleave="preventDefault" @dragover="preventDefault" @dragenter="preventDefault" @drop="handleChange">
          <slot :handle-click="handleClick">
<!--            <div>-->
<!--              <img-->
<!--                @click="handleClick"-->
<!--                ref="previewCanvas"-->
<!--                class="wrap-upload__img"-->
<!--                :width="width"-->
<!--                :height="height"-->
<!--                src="/assets/games/default-avatar.svg"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="wrap-upload__desc">-->
<!--              <div>-->
<!--                <h4>Add preview for your game</h4>-->
<!--                <p>The image must be of high quality, clear, 180 by 180 pixels</p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <v-btn v-ripple="{ class:'secondary&#45;&#45;text'}" text class="btn-cab white&#45;&#45;text" @click="handleClick">Upload image</v-btn>-->
<!--                <span>or drag and drop here</span>-->
<!--              </div>-->
<!--            </div>-->
          </slot>

          <input type="file" v-show="false" v-if="step == 1" @change="handleChange" ref="fileinput">
        </div>
        <div class="vicp-error" v-show="hasError">
          <i class="vicp-icon2"></i> {{ errorMsg }}
        </div>
      </div>
      <div class="vicp-step2" v-if="step == 2">
        <div class="vicp-crop">
          <div class="vicp-crop-left" v-show="true">
            <div class="vicp-img-container">
              <img :src="sourceImgUrl" :style="sourceImgStyle" class="vicp-img" draggable="false"
                 @drag="preventDefault"
                 @dragstart="preventDefault"
                 @dragend="preventDefault"
                 @dragleave="preventDefault"
                 @dragover="preventDefault"
                 @dragenter="preventDefault"
                 @drop="preventDefault"
                 @touchstart="imgStartMove"
                 @touchmove="imgMove"
                 @touchend="createImg"
                 @touchcancel="createImg"
                 @mousedown="imgStartMove"
                 @mousemove="imgMove"
                 @mouseup="createImg"
                 @mouseout="createImg"
                 ref="img">
              <div class="vicp-img-shade vicp-img-shade-1" :style="sourceImgShadeStyle"></div>
              <div class="vicp-img-shade vicp-img-shade-2" :style="sourceImgShadeStyle"></div>
            </div>

            <div class="vicp-range">
              <input type="range" :value="scale.range" step="1" min="0" max="100" @mousemove="zoomChange">
              <i @mousedown="startZoomSub" @mouseout="endZoomSub" @mouseup="endZoomSub" class="vicp-icon5"></i>
              <i @mousedown="startZoomAdd" @mouseout="endZoomAdd" @mouseup="endZoomAdd" class="vicp-icon6"></i>
            </div>

            <div class="vicp-rotate" v-if="!noRotate">
              <i @click="rotateImg">↻</i>
            </div>
          </div>
          <div class="vicp-crop-right" v-show="true">
            <div class="vicp-preview">
              <div class="vicp-preview-item" v-if="!noSquare">
                <img :src="createImgUrl" :style="previewStyle">
                <span>{{__('preview')}}</span>
              </div>
              <div class="vicp-preview-item vicp-preview-item-circle" v-if="!noCircle">
                <img :src="createImgUrl" :style="previewStyle">
                <span>{{__('preview')}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vicp-operate">
          <a @click="setStep(1)" >{{__('back')}}</a>
        </div>
      </div>
      <canvas v-show="false" :width="width" :height="height" ref="canvas"></canvas>
    </div>
  </div>
</template>


<script>
    import data2blob from "vue-image-crop-upload/utils/data2blob";

    export default {
        props: {
            // 域，上传文件name，触发事件会带上（如果一个页面多个图片上传控件，可以做区分
            field: {
                type: String,
                'default': 'img'
            },
            // 原名key，类似于id，触发事件会带上（如果一个页面多个图片上传控件，可以做区分
            ki: {
                'default': 0
            },
            // 显示该控件与否
            value: {
                'default': true
            },
            // 上传地址
            url: {
                type: String,
                'default': ''
            },
            // 其他要上传文件附带的数据，对象格式
            params: {
                type: Object,
                'default': null
            },
            //Add custom headers
            headers: {
                type: Object,
                'default': null
            },
            // 剪裁图片的宽
            width: {
                type: Number,
                default: 200
            },
            // 剪裁图片的高
            height: {
                type: Number,
                default: 200
            },
            // 不显示旋转功能
            noRotate: {
                type: Boolean,
                default: true
            },
            // 不预览圆形图片
            noCircle: {
                type: Boolean,
                default: false
            },
            // 不预览方形图片
            noSquare: {
                type: Boolean,
                default: false
            },
            // 单文件大小限制
            maxSize: {
                type: Number,
                'default': 10240
            },
            // 语言类型
            langType: {
                type: String,
                'default': 'zh'
            },
            // 语言包
            langExt: {
                type: Object,
                'default': null
            },
            // 图片上传格式
            imgFormat: {
                type: String,
                'default': 'png'
            },
            // 图片背景 jpg情况下生效
            imgBgc: {
                type: String,
                'default': '#fff'
            },
            // 是否支持跨域
            withCredentials: {
                type: Boolean,
                'default': false
            },
            method: {
                type: String,
                'default': 'POST'
            },
        },
        data() {
            let that = this,
                {
                    imgFormat,
                    width,
                    height
                } = that,
                isSupported = true,
                allowImgFormat = [
                    'jpg',
                    'png'
                ],
                tempImgFormat = allowImgFormat.indexOf(imgFormat) === -1 ? 'jpg' : imgFormat;
            // lang = language[langType] ? language[langType] : language['en'],
            let mimes= {
                'jpg': 'image/jpeg',
                'png': 'image/png',
                'gif': 'image/gif',
                'svg': 'image/svg+xml',
                'psd': 'image/photoshop'
            };

            let mime = mimes[tempImgFormat];
            // 规范图片格式
            that.imgFormat = tempImgFormat;

            // if (langExt) {
            //     Object.assign(lang, langExt);
            // }
            if (typeof FormData != 'function') {
                isSupported = false;
            }
            return {
                // 图片的mime
                mime,
                mimes: mimes,
                //
                // // 语言包
                // lang,

                // 浏览器是否支持该控件

                isSupported,
                // 浏览器是否支持触屏事件
                // isSupportTouch: document.hasOwnProperty("ontouchstart"),

                // 步骤
                step: 1, //1选择文件 2剪裁 3上传

                // 上传状态及进度
                loading: 0, //0未开始 1正在 2成功 3错误
                progress: 0,

                // 是否有错误及错误信息
                hasError: false,
                errorMsg: '',

                // 需求图宽高比
                ratio: width / height,

                // 原图地址、生成图片地址
                sourceImg: null,
                sourceImgUrl: '',
                createImgUrl: '',

                // 原图片拖动事件初始值
                sourceImgMouseDown: {
                    on: false,
                    mX: 0, //鼠标按下的坐标
                    mY: 0,
                    x: 0, //scale原图坐标
                    y: 0
                },

                // 生成图片预览的容器大小
                previewContainer: {
                    width: 100,
                    height: 100
                },

                // 原图容器宽高
                sourceImgContainer: { // sic
                    width: 240,
                    height: 184 // 如果生成图比例与此一致会出现bug，先改成特殊的格式吧，哈哈哈
                },

                // 原图展示属性
                scale: {
                    zoomAddOn: false, //按钮缩放事件开启
                    zoomSubOn: false, //按钮缩放事件开启
                    range: 1, //最大100

                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    maxWidth: 0,
                    maxHeight: 0,
                    minWidth: 0, //最宽
                    minHeight: 0,
                    naturalWidth: 0, //原宽
                    naturalHeight: 0
                }
            }
        },
        computed: {
            // 进度条样式
            progressStyle() {
                let {
                    progress
                } = this;
                return {
                    width: progress + '%'
                }
            },
            // 原图样式
            sourceImgStyle() {
                let {
                        scale,
                        sourceImgMasking
                    } = this,
                    top = scale.y + sourceImgMasking.y + 'px',
                    left = scale.x + sourceImgMasking.x + 'px';
                return {
                    top,
                    left,
                    width: scale.width + 'px',
                    height: scale.height + 'px',// 兼容 Opera
                }
            },
            // 原图蒙版属性
            sourceImgMasking() {
                let {
                        width,
                        height,
                        ratio,
                        sourceImgContainer
                    } = this,
                    sic = sourceImgContainer,
                    sicRatio = sic.width / sic.height, // 原图容器宽高比
                    x = 0,
                    y = 0,
                    w = sic.width,
                    h = sic.height,
                    scale = 1;
                if (ratio < sicRatio) {
                    scale = sic.height / height;
                    w = sic.height * ratio;
                    x = (sic.width - w) / 2;
                }
                if (ratio > sicRatio) {
                    scale = sic.width / width;
                    h = sic.width / ratio;
                    y = (sic.height - h) / 2;
                }
                return {
                    scale, // 蒙版相对需求宽高的缩放
                    x,
                    y,
                    width: w,
                    height: h
                };
            },
            // 原图遮罩样式
            sourceImgShadeStyle() {
                let {
                        sourceImgMasking,
                        sourceImgContainer
                    } = this,
                    sic = sourceImgContainer,
                    sim = sourceImgMasking,
                    w = sim.width == sic.width ? sim.width : (sic.width - sim.width) / 2,
                    h = sim.height == sic.height ? sim.height : (sic.height - sim.height) / 2;
                return {
                    width: w + 'px',
                    height: h + 'px'
                };
            },
            previewStyle() {
                let {
                        // width,
                        // height,
                        ratio,
                        previewContainer
                    } = this,
                    pc = previewContainer,
                    w = pc.width,
                    h = pc.height,
                    pcRatio = w / h;
                if (ratio < pcRatio) {
                    w = pc.height * ratio;
                }
                if (ratio > pcRatio) {
                    h = pc.width / ratio;
                }
                return {
                    width: w + 'px',
                    height: h + 'px'
                };
            }
        },
        watch: {
            value(newValue) {
                if (newValue && this.loading != 1) {
                    this.reset();
                }
            }
        },
        methods: {
            effectRipple(e, arg_opts) {
                var opts = Object.assign({
                        ele: e.target,
                        type: 'hit',
                        bgc: 'rgba(0, 0, 0, 0.15)' // 波纹颜色
                    }, arg_opts),
                    target = opts.ele;
                if (target) {
                    var rect = target.getBoundingClientRect(),
                        ripple = target.querySelector('.e-ripple');
                    if (!ripple) {
                        ripple = document.createElement('span');
                        ripple.className = 'e-ripple';
                        ripple.style.height = ripple.style.width = Math.max(rect.width, rect.height) + 'px';
                        target.appendChild(ripple);
                    } else {
                        ripple.className = 'e-ripple';
                    }
                    switch (opts.type) {
                        case 'center':
                            ripple.style.top = (rect.height / 2 - ripple.offsetHeight / 2) + 'px';
                            ripple.style.left = (rect.width / 2 - ripple.offsetWidth / 2) + 'px';
                            break;
                        default:
                            ripple.style.top = (e.pageY - rect.top - ripple.offsetHeight / 2 - document.body.scrollTop) + 'px';
                            ripple.style.left = (e.pageX - rect.left - ripple.offsetWidth / 2 - document.body.scrollLeft) + 'px';
                    }
                    ripple.style.backgroundColor = opts.bgc;
                    ripple.className = 'e-ripple z-active';
                    return false;
                }
            },
            data2blob(data, mime) {
                data = data.split(',')[1];
                data = window.atob(data);
                var ia = new Uint8Array(data.length);
                for (var i = 0; i < data.length; i++) {
                    ia[i] = data.charCodeAt(i);
                }
                // canvas.toDataURL 返回的默认格式就是 image/png
                return new Blob([ia], {
                    type: mime
                });
            },

            // 点击波纹效果
            ripple(e) {
                this.effectRipple(e);
            },
            // 关闭控件
            off() {
                setTimeout(()=> {
                    this.$emit('input', false);
                    if(this.step == 3 && this.loading == 2){
                        this.setStep(1);
                    }
                }, 200);
            },
            // 设置步骤
            setStep(no) {
                // 延时是为了显示动画效果呢，哈哈哈
                setTimeout(()=> {
                    this.step = no;
                }, 200);
            },

            /* 图片选择区域函数绑定
             ---------------------------------------------------------------*/
            preventDefault(e) {
                e.preventDefault();
                return false;
            },
            handleClick(e) {
                if (this.loading !== 1) {
                    if (e.target !== this.$refs.fileinput) {
                        e.preventDefault();
                        if (document.activeElement !== this.$refs) {
                            this.$refs.fileinput.click();
                        }
                    }
                }
            },
            handleChange(e) {
                e.preventDefault();
                if (this.loading !== 1) {
                    let files = e.target.files || e.dataTransfer.files;
                    this.reset();
                    if (this.checkFile(files[0])) {
                        this.setSourceImg(files[0]);
                    }
                }
            },
            /* ---------------------------------------------------------------*/

            // 检测选择的文件是否合适
            checkFile(file) {
                let that = this,
                    {
                        // lang,
                        maxSize
                    } = that;
                // 仅限图片
                if (file.type.indexOf('image') === -1) {
                    that.hasError = true;
                    // that.errorMsg = lang.error.onlyImg;
                    return false;
                }

                // 超出大小
                if (file.size / 1024 > maxSize) {
                    that.hasError = true;
                    // that.errorMsg = lang.error.outOfSize + maxSize + 'kb';
                    return false;
                }
                return true;
            },
            // 重置控件
            reset() {
                let that = this;
                that.loading = 0;
                that.hasError = false;
                that.errorMsg = '';
                that.progress = 0;
            },
            // 设置图片源
            setSourceImg(file) {
                this.$emit('src-file-set', file.name, file.type, file.size);
                let that = this,
                    fr = new FileReader();
                fr.onload = function() {
                    that.sourceImgUrl = fr.result;
                    that.startCrop();
                }
                fr.readAsDataURL(file);
            },
            // 剪裁前准备工作
            startCrop() {
                let that = this,
                    {
                        width,
                        height,
                        ratio,
                        scale,
                        sourceImgUrl,
                        sourceImgMasking,
                        // lang
                    } = that,
                    sim = sourceImgMasking,
                    img = new Image();
                img.src = sourceImgUrl;
                img.onload = function() {
                    let nWidth = img.naturalWidth,
                        nHeight = img.naturalHeight,
                        nRatio = nWidth / nHeight,
                        w = sim.width,
                        h = sim.height,
                        x = 0,
                        y = 0;
                    // 图片像素不达标
                    if (nWidth < width || nHeight < height) {
                        that.hasError = true;
                        // that.errorMsg = lang.error.lowestPx + width + '*' + height;
                        return false;
                    }
                    if (ratio > nRatio) {
                        h = w / nRatio;
                        y = (sim.height - h) / 2;
                    }
                    if (ratio < nRatio) {
                        w = h * nRatio;
                        x = (sim.width - w) / 2;
                    }
                    scale.range = 0;
                    scale.x = x;
                    scale.y = y;
                    scale.width = w;
                    scale.height = h;
                    scale.minWidth = w;
                    scale.minHeight = h;
                    scale.maxWidth = nWidth * sim.scale;
                    scale.maxHeight = nHeight * sim.scale;
                    scale.naturalWidth = nWidth;
                    scale.naturalHeight = nHeight;
                    that.sourceImg = img;
                    that.createImg();
                    that.setStep(2);
                    that.prepareUpload();
                };
            },
            // 鼠标按下图片准备移动
            imgStartMove(e) {
                e.preventDefault();
                // 支持触摸事件，则鼠标事件无效
                // if(this.isSupportTouch && !e.targetTouches){
                //     return false;
                // }
                let et = e.targetTouches ? e.targetTouches[0] : e,
                    {
                        sourceImgMouseDown,
                        scale
                    } = this,
                    simd = sourceImgMouseDown;
                simd.mX = et.screenX;
                simd.mY = et.screenY;
                simd.x = scale.x;
                simd.y = scale.y;
                simd.on = true;
            },
            // 鼠标按下状态下移动，图片移动
            imgMove(e) {
                e.preventDefault();
                // 支持触摸事件，则鼠标事件无效
                // if(this.isSupportTouch && !e.targetTouches){
                //     return false;
                // }
                let et = e.targetTouches ? e.targetTouches[0] : e,
                    {
                        sourceImgMouseDown: {
                            on,
                            mX,
                            mY,
                            x,
                            y
                        },
                        scale,
                        sourceImgMasking
                    } = this,
                    sim = sourceImgMasking,
                    nX = et.screenX,
                    nY = et.screenY,
                    dX = nX - mX,
                    dY = nY - mY,
                    rX = x + dX,
                    rY = y + dY;
                if (!on) return;
                if (rX > 0) {
                    rX = 0;
                }
                if (rY > 0) {
                    rY = 0;
                }
                if (rX < sim.width - scale.width) {
                    rX = sim.width - scale.width;
                }
                if (rY < sim.height - scale.height) {
                    rY = sim.height - scale.height;
                }
                scale.x = rX;
                scale.y = rY;
            },
            // 顺时针旋转图片
            rotateImg() {
                let {
                        sourceImg,
                        scale: {
                            naturalWidth,
                            naturalHeight,
                        }
                    } = this,
                    width = naturalHeight,
                    height = naturalWidth,
                    canvas = this.$refs.canvas,
                    ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;
                ctx.clearRect(0, 0, width, height);

                ctx.fillStyle = 'rgba(0,0,0,0)';
                ctx.fillRect(0, 0, width, height);

                ctx.translate(width, 0);
                ctx.rotate(Math.PI * 90 / 180);

                ctx.drawImage(sourceImg, 0, 0, naturalWidth, naturalHeight);
                let imgUrl = canvas.toDataURL(this.mimes['png']);
                this.sourceImgUrl = imgUrl;
                this.startCrop();
            },

            // 按钮按下开始放大
            startZoomAdd() {
                let that = this,
                    {
                        scale
                    } = that;
                scale.zoomAddOn = true;

                function zoom() {
                    if (scale.zoomAddOn) {
                        let range = scale.range >= 100 ? 100 : ++scale.range;
                        that.zoomImg(range);
                        setTimeout(function() {
                            zoom();
                        }, 60);
                    }
                }
                zoom();
            },
            // 按钮松开或移开取消放大
            endZoomAdd() {
                this.scale.zoomAddOn = false;
            },
            // 按钮按下开始缩小
            startZoomSub() {
                let that = this,
                    {
                        scale
                    } = that;
                scale.zoomSubOn = true;

                function zoom() {
                    if (scale.zoomSubOn) {
                        let range = scale.range <= 0 ? 0 : --scale.range;
                        that.zoomImg(range);
                        setTimeout(function() {
                            zoom();
                        }, 60);
                    }
                }
                zoom();
            },
            // 按钮松开或移开取消缩小
            endZoomSub() {
                let {
                    scale
                } = this;
                scale.zoomSubOn = false;
            },
            zoomChange(e) {
                this.zoomImg(e.target.value);
            },
            // 缩放原图
            zoomImg(newRange) {
                let that = this,
                    {
                        sourceImgMasking,
                        // sourceImgMouseDown,
                        scale
                    } = this,
                    {
                        maxWidth,
                        maxHeight,
                        minWidth,
                        minHeight,
                        width,
                        height,
                        x,
                        y,
                        // range
                    } = scale,
                    sim = sourceImgMasking,
                    // 蒙版宽高
                    sWidth = sim.width,
                    sHeight = sim.height,
                    // 新宽高
                    nWidth = minWidth + (maxWidth - minWidth) * newRange / 100,
                    nHeight = minHeight + (maxHeight - minHeight) * newRange / 100,
                    // 新坐标（根据蒙版中心点缩放）
                    nX = sWidth / 2 - (nWidth / width) * (sWidth / 2 - x),
                    nY = sHeight / 2 - (nHeight / height) * (sHeight / 2 - y);

                // 判断新坐标是否超过蒙版限制
                if (nX > 0) {
                    nX = 0;
                }
                if (nY > 0) {
                    nY = 0;
                }
                if (nX < sWidth - nWidth) {
                    nX = sWidth - nWidth;
                }
                if (nY < sHeight - nHeight) {
                    nY = sHeight - nHeight;
                }

                // 赋值处理
                scale.x = nX;
                scale.y = nY;
                scale.width = nWidth;
                scale.height = nHeight;
                scale.range = newRange;
                setTimeout(function() {
                    if (scale.range == newRange) {
                        that.createImg();
                    }
                }, 300);
            },
            // 生成需求图片
            createImg(e) {
                let that = this,
                    {
                        imgFormat,
                        imgBgc,
                        mime,
                        sourceImg,
                        scale: {
                            x,
                            y,
                            width,
                            height,
                        },
                        sourceImgMasking: {
                            scale
                        }
                    } = that,
                    canvas = that.$refs.canvas,
                    ctx = canvas.getContext('2d');
                if (e) {
                    // 取消鼠标按下移动状态
                    that.sourceImgMouseDown.on = false;
                }
                canvas.width = that.width;
                canvas.height = that.height;
                ctx.clearRect(0, 0, that.width, that.height);

                if(imgFormat == 'png'){
                    ctx.fillStyle = 'rgba(0,0,0,0)';
                } else{
                    // 如果jpg 为透明区域设置背景，默认白色
                    ctx.fillStyle = imgBgc;
                }
                ctx.fillRect(0, 0, that.width, that.height);

                ctx.drawImage(sourceImg, x / scale, y / scale, width / scale, height / scale);
                that.createImgUrl = canvas.toDataURL(mime);
            },
            prepareUpload(){
                let {
                    createImgUrl,
                    imgFormat,
                    field,
                    mime,
                    sourceImgUrl,
                } = this;

                let img= data2blob(createImgUrl, mime);


                this.$emit('crop-success', img, imgFormat, field, sourceImgUrl);
                // if(typeof url == 'string' && url){
                //     this.upload();
                // }else{
                //     this.off();
                // }
            },
        },
        created(){
            // 绑定按键esc隐藏此插件事件
            document.addEventListener('keyup', (e)=>{
                if(this.value && (e.key == 'Escape' || e.keyCode == 27)){
                    this.off();
                }
            })
        }
    }

</script>


<style lang="scss" scoped>

  .dark {
    .wrap-upload {
      border: 1px dashed #363636;

      &__desc {
        h4 {
          color: #fff;
        }

        p {
          color: #96AFD1;
        }

        span {
          color: #96AFD1;
        }

        .text-req {
          color: #ff4b55;
        }
      }
    }
  }

    .wrap-upload {
      position: relative;
      display: flex;
      padding: 20px;
      border: 1px dashed #DAE6F1;
      box-sizing: border-box;
      border-radius: 6px;
      margin-bottom: 30px;

      .block-img {
        height: 180px;
      }

      &__img {
        min-width: 180px;
        height: 180px;
        border-radius: 8px;
        margin-right: 20px;
        cursor: pointer;

        img {
          width: 180px;
          height: 180px;
          border-radius: 8px;
        }
      }

      &__desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        h4 {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 10px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
          margin: 0;
        }

        span {
          margin-left: 15px;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #6886AF;
        }

        .text-req {
          color: #FF4B55;
          position: absolute;
          right: -5px;
          top: -15px;
        }
      }
    }

    @media screen and (max-width: 1024px){
      .wrap-upload {
        padding: 15px;
        margin-bottom: 10px;
        .block-img {
          height: 100px;
          width: fit-content;
        }
        &__img {
          min-width: 100px;
          width: 100px;
          height: 100px;
          border-radius: 4px;
          margin-right: 14px;
          cursor: pointer;

          img {
            width: 100px;
            height: 100px;
            border-radius: 4px;
          }
        }

        &__desc {
          h4 {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 5px;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #6886AF;
            margin-bottom: 4px;
          }

          span {
            margin-left: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #6886AF;
          }

          .text-req {
            font-size: 12px;
          }
        }
      }
    }

    @media screen and (max-width: 500px){
      .wrap-upload {
        .block-img {
          display: none;
        }

        &__desc {
          p {
            margin-bottom: 10px;
          }

          span {
            margin-left: 10px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop {
      overflow: hidden;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left {
      float: left;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container {
      position: relative;
      display: block;
      width: 240px;
      height: 180px;
      background-color: #e5e5e0;
      overflow: hidden;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container .vicp-img {
      position: absolute;
      display: block;
      cursor: move;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container .vicp-img-shade {
      -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
      position: absolute;
      background-color: rgba(241, 242, 243, 0.8);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container .vicp-img-shade.vicp-img-shade-1 {
      top: 0;
      left: 0;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-img-container .vicp-img-shade.vicp-img-shade-2 {
      bottom: 0;
      right: 0;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate {
      position: relative;
      width: 240px;
      height: 18px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate i {
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      line-height: 18px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0.08);
      color: #fff;
      overflow: hidden;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate i:hover {
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.14);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate i:first-child {
      float: left;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-rotate i:last-child {
      float: right;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range {
      position: relative;
      margin: 30px 0 10px 0;
      width: 240px;
      height: 18px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon5,
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6 {
      position: absolute;
      top: 0;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.08);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon5:hover,
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6:hover {
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.14);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon5 {
      left: 0;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon5::before {
      position: absolute;
      content: '';
      display: block;
      left: 3px;
      top: 8px;
      width: 12px;
      height: 2px;
      background-color: #fff;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6 {
      right: 0;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6::before {
      position: absolute;
      content: '';
      display: block;
      left: 3px;
      top: 8px;
      width: 12px;
      height: 2px;
      background-color: #fff;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6::after {
      position: absolute;
      content: '';
      display: block;
      top: 3px;
      left: 8px;
      width: 2px;
      height: 12px;
      background-color: #fff;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range] {
      display: block;
      padding-top: 5px;
      margin: 0 auto;
      width: 180px;
      height: 8px;
      vertical-align: top;
      background: transparent;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      /* 滑块
                       ---------------------------------------------------------------*/
      /* 轨道
                       ---------------------------------------------------------------*/ }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:focus {
      outline: none;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-webkit-slider-thumb {
      -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
      -webkit-appearance: none;
      appearance: none;
      margin-top: -3px;
      width: 12px;
      height: 12px;
      background-color: #61c091;
      border-radius: 100%;
      border: none;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-moz-range-thumb {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
      -moz-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      background-color: #61c091;
      border-radius: 100%;
      border: none;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-ms-thumb {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
      appearance: none;
      width: 12px;
      height: 12px;
      background-color: #61c091;
      border: none;
      border-radius: 100%;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:active::-moz-range-thumb {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
      width: 14px;
      height: 14px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:active::-ms-thumb {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
      width: 14px;
      height: 14px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:active::-webkit-slider-thumb {
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
      margin-top: -4px;
      width: 14px;
      height: 14px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-webkit-slider-runnable-track {
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      width: 100%;
      height: 6px;
      cursor: pointer;
      border-radius: 2px;
      border: none;
      background-color: rgba(68, 170, 119, 0.3);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-moz-range-track {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      width: 100%;
      height: 6px;
      cursor: pointer;
      border-radius: 2px;
      border: none;
      background-color: rgba(68, 170, 119, 0.3);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-ms-track {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
      height: 6px;
      border-radius: 2px;
      border: none;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-ms-fill-lower {
      background-color: rgba(68, 170, 119, 0.3);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]::-ms-fill-upper {
      background-color: rgba(68, 170, 119, 0.15);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:focus::-webkit-slider-runnable-track {
      background-color: rgba(68, 170, 119, 0.5);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:focus::-moz-range-track {
      background-color: rgba(68, 170, 119, 0.5);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:focus::-ms-fill-lower {
      background-color: rgba(68, 170, 119, 0.45);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range input[type=range]:focus::-ms-fill-upper {
      background-color: rgba(68, 170, 119, 0.25);
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right {
      float: right;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview {
      height: 150px;
      overflow: hidden;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item {
      position: relative;
      padding: 5px;
      width: 100px;
      height: 100px;
      float: left;
      margin-right: 16px;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item span {
      position: absolute;
      bottom: -30px;
      width: 100%;
      font-size: 14px;
      color: #bbb;
      display: block;
      text-align: center;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item img {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      padding: 3px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item.vicp-preview-item-circle {
      margin-right: 0;
    }
    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item.vicp-preview-item-circle img {
      border-radius: 100%;
    }
    .image-crop-upload .vicp-wrap .vicp-operate {
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
    .image-crop-upload .vicp-wrap .vicp-operate a {
      color: #6886AF;
      background-color: #fff;
      border: 1px solid #DAE6F1;
      box-sizing: border-box;
      border-radius: 6px;
      display: block;
      width: 100px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      text-transform: capitalize;

      &:hover {
        background-color: #fff;
        color: #26A9F4;
      }
    }

    .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon5, .image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-left .vicp-range .vicp-icon6 {
      position: absolute;
      top: -3px;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background-color: #E7EFFF;

      &:hover {
        background-color: #CDDFF7;
      }
    }

</style>