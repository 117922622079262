<template>
  <div v-if="dialog">
    <v-row justify="center">
      <v-dialog
              content-class="win-forw"
        v-model="dialog" persistent max-width="370"
        v-on:click:outside="$emit('update:modal_cancel', false)"
      >
        <div class="forward-mess white portfolio-window" :class="class_name" style="position: relative">
          <span class="close"></span>
          <div>
            <div class="top lighten-5">
              <SearchDefault class_search="user_search" v-model="search" placeholder="Search ..." v-on:keyup.enter="searchUser()">
                <template v-slot:right>
                  <v-btn tag="a" v-on:click="searchUser()" text  class="search-btn" :disabled="submitDisabled">
                    <img src="/dist/images/frontend/search.svg" alt />
                  </v-btn>
                </template>
              </SearchDefault>
            </div>
            <div v-if="!users_list" class="loading">
              {{__('loading')}}
            </div>
            <div class="body" v-else-if="users_list.total">
              <div ref="users_list" class="users-list" style="" @scroll="scrollUsers(this)">
                <ModalForwardListItem v-for="(item, key) in users_list.data" :key="key" v-bind:item="item" @sendMessages="sendMessages"></ModalForwardListItem>
              </div>
            </div>
            <div v-else>
              {{__('no result')}}
            </div>
          </div>
        </div>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
    import SearchDefault from "../../modules/SearchDefault";
    import ModalForwardListItem from "./ModalForwardListItem";

    export default {
        name: 'ModalForward',
        data: function() {
            return {
                search: null,
                dialog: true,
                submitDisabled: false,
                users_list: false,
                loadingPage: false
            }
        },
        props: {
            modal_cancel: {
                type: [Boolean, Object, Array, String]
            },
            class_name: {
                type: String,
            },
            edit_list: {
                type: Array
            }
        },
        computed: {

        },
        watch: {

        },
        created() {
            this.loadUsers();
        },
        methods: {
            loadUsers($url= null) {
                const response ={
                    search: this.search
                };
                this.submitDisabled= true;

                this.$http.post((($url) ? $url : "ajax/chat/forwards/users"), response)
                    .then(response=> {
                        return response.json()
                    })
                    .then((response)=> {
                        if(this.$checkAuth(response)) {
                            // response=
                            // {
                            //   "data": {
                            //   "current_page": 1,
                            //           "data": [
                            //     {
                            //       "id": 2,
                            //       "public_id": "happy",
                            //       "name": "Happy",
                            //       "last_name": "Proger",
                            //       "photo": "a-1609759324m90pBdwJSb.png",
                            //       "bg_color": "#f9851a",
                            //       "location": "AX",
                            //       "location_img": "de.png",
                            //       "online": "2021/01/27 18:31:24",
                            //       "rating_points": "802"
                            //     },
                            //     {
                            //       "id": 4,
                            //       "public_id": "253950408",
                            //       "name": "First",
                            //       "last_name": "Last",
                            //       "photo": null,
                            //       "bg_color": "#80dbff",
                            //       "location": "Andorra",
                            //       "location_img": "de.png",
                            //       "online": null,
                            //       "rating_points": "568"
                            //     },
                            //     {
                            //       "id": 418079082,
                            //       "public_id": "564961015",
                            //       "name": "1234",
                            //       "last_name": null,
                            //       "photo": null,
                            //       "bg_color": "#041f3d",
                            //       "location": "Brazil",
                            //       "location_img": "br.png",
                            //       "online": "2021/01/21 16:09:43",
                            //       "rating_points": "0"
                            //     },
                            //     {
                            //       "id": 1967725861,
                            //       "public_id": "1859465238",
                            //       "name": "Dark",
                            //       "last_name": "Raven",
                            //       "photo": "a-1606237442ubsmhJ71WP.png",
                            //       "bg_color": "#80dbff",
                            //       "location": "Ukraine",
                            //       "location_img": "gb.png",
                            //       "online": "2021/01/28 07:51:08",
                            //       "rating_points": "94"
                            //     }
                            //   ],
                            //           "first_page_url": "https://mindplays.com/ajax/chat/forwards/users?page=1",
                            //           "from": 1,
                            //           "last_page": 1,
                            //           "last_page_url": "https://mindplays.com/ajax/chat/forwards/users?page=1",
                            //           "next_page_url": null,
                            //           "path": "https://mindplays.com/ajax/chat/forwards/users",
                            //           "per_page": 20,
                            //           "prev_page_url": null,
                            //           "to": 4,
                            //           "total": 4
                            // },
                            //   "status": 200
                            // }
                            response = response.data;

                            if ($url) {
                                let users_list = this.$cleanObject(this.users_list);
                                response.data = users_list.data.concat(response.data);
                            }

                            this.users_list = response;
                            this.submitDisabled = false;
                            this.loadingPage = false;
                        }
                    }).catch(err => {
                        this.loadingPage = false;
                        this.submitDisabled= false;
                        let response= this.httpHandler(err);
                        if(response.error) {
                            this.formValidate = response.error.message;
                        }
                });
            },
            searchUser() {
                this.loadUsers();
            },
            scrollUsers() {
                let users_list= this.$refs.users_list;
                const progress= users_list.scrollTop / (users_list.scrollHeight - users_list.clientHeight);
                if(!this.loadingPage && this.users_list.next_page_url) {
                    if (0.5 >= progress) {
                        this.loadingPage = true;
                        this.loadUsers(this.users_list.next_page_url);
                    }
                }
            },
            sendMessages(item) {
                if(!this.submitDisabled) {
                    this.submitDisabled= true;
                    const response = {
                        messages_id: this.edit_list,
                        user_id: item.id
                    };
                    this.$http.post("ajax/chat/messages/forward", response)
                        .then(response=> {
                            return response.json()
                        })
                        .then((response)=> {
                            if(this.$checkAuth(response)) {
                                this.submitDisabled = false;
                                this.$emit('openMessages');
                            }
                        }).catch(err => {
                        this.submitDisabled= false;
                        let response= this.httpHandler(err);
                        if(response.error) {
                            this.formValidate = response.error.message;
                        }
                    });
                }

            }
        },
        components: {
            ModalForwardListItem,
            SearchDefault
        },
    };
</script>
