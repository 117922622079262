<template>
  <v-dialog v-model="dialog" max-width="500px">
    <div class="head-invite_game">

      <div class="block-game">
          <div class="block-game__img">
              <img v-if="typeof data_invite.game_logo == 'object'" :src="data_invite.game_logo[100]">
              <img v-else :src="data_invite.game_logo">
          </div>
          <div class="block-info">
              <p class="block-info__title">{{game_array.name}}</p>
              <span class="block-info__subtitle">{{__('Invitation to the game')}}</span>
          </div>
      </div>
    </div>
    <div class="body-invite_game">
        <div class="wrap-line-time">
            <div class="line-time" :style="{transform: 'translateX(-'+lineTime+'%)'}"></div>
        </div>
        <div class="body-invite_game__head">
            <div class="block-person">
                <div class="block-person__img" :class="(data_invite.user.pro) ? 'user-pro-img' : ''">
                    <router-link :to="{name: 'profile', params: {id: data_invite.user.id, lang: $store.getters.getLang}}" class="mb-0">
                        <div class="wrap-img">
                            <img v-if="data_invite.user.photo && typeof data_invite.user.photo == 'object'" :src="data_invite.user.photo.original">
                            <img v-else-if="data_invite.user.photo" :src="data_invite.user.photo">
                            <span v-else class="photo" :style="{'background': data_invite.user.bg_color}">{{data_invite.user.name.substr(0,1)}}</span>
                        </div>
                        <span class="online"></span>
                    </router-link>
                </div>

                <div class="block-info">
                    <p class="block-info__title" :class="(data_invite.user.pro) ? 'user-pro' : ''">{{data_invite.user.name +' '+((data_invite.user.last_name) ? data_invite.user.last_name : '')}}</p>

                    <div class="block-info__subtitle">
                        <div class="block-rating">
                            <img src="/dist/images/frontend/fire.svg" alt />
                            <span>{{data_invite.user.rating_points}}</span>
                        </div>
                        <img width="14" :src="data_invite.user.location_img" />
                        <!--                    {{$getLocation(data_invite.user.location)}}-->
                    </div>
                </div>
            </div>

            <div class="block-bet">
                <div class="block-bet__real" v-if="data_invite.wallet == 1">
                    <p>{{__('Match bet')}}</p>
                    <span class="warning--text">
                    <img class="pic" src="/dist/images/frontend/money.svg" alt />
                    {{data_invite.amount}}
                  </span>
                </div>
                <div class="block-bet__lite" v-else>
                    <p>{{__('Match bet')}}</p>
                    <span style="color: #6886AF">
                    <img class="pic" src="/dist/images/frontend/light-coin.svg" alt />
                    {{data_invite.amount}}
                  </span>
                </div>
            </div>
        </div>

        <div class="block-params" v-if="data_invite.attributes && data_invite.attributes.length">
            <div class="block-params__head">
                {{__('game options')}}
            </div>

            <div class="block-params__body">
                <div v-for="(item, key) in data_invite.attributes" :key="key" class="item-params">
                    <span v-if="item.attr_name">{{item.attr_name}}</span>
                    <p>{{item.name}}</p>
                </div>
            </div>
        </div>

      <div>
        <div v-if="timer">
          <p class="text-wait">{{__('Waiting for confirmation...')}}</p>

          <p class="desc-timer">
            <span>{{timer}} {{__('sec')}}</span>
          </p>

          <div class="loader">
            <div class="ellipse"></div>
            <div class="ellipse"></div>
            <div class="ellipse"></div>
            <div class="ellipse"></div>
          </div>

          <div style="max-width: 220px; margin: 0 auto">
            <BaseButton
                class_btn="btn-liteGrey"
                @click="dialog= false"
            >
              <span>{{__('Cancel')}}</span>
            </BaseButton>
          </div>
        </div>
        <div v-else class="block-error">
          <p class="block-error__txt">{{__('Request canceled!')}}</p>
          <img src="/dist/images/frontend/error.svg" alt="/" />
          <div style="max-width: 220px; margin: 0 auto">
            <BaseButton
                class_btn="btn-liteGrey"
                @click="dialog = false"
            >
              <span>{{__('Cancel')}}</span>
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import moment from 'moment';

export default {
  name: "ModalInviteGame",
  data: function () {
    return {
      dialog: true,
      timer: this.$inviteTimer,
      cancel: false,
      interval: null,
      lineTime: 0
    };
  },
  props: {
    game: {
      type: Object,
    },
    invite: {
      type: Object
    },
    user: {
      type: Object
    },
    data_invite: {
      type: Object
    }
  },
  computed: {
    lang() {
      return this.$store.state.language.lang;
    },

    game_array() {
        if (this.$filterArray(this.data_invite.game_translations, this.lang, 'lang')) {
            return this.$filterArray(this.data_invite.game_translations, this.lang, 'lang');
        } else {
            return this.$filterArray(this.data_invite.game_translations, 'en', 'lang');
        }
    },
  },
  watch: {
    dialog(val) {
        if(!val) {
            this.cancelGame();
        }
    },
  },
  methods: {
    cancelGame() {
      if (!this.cancel && !this.invite.cancel) {
        const formData = {
          game_id: this.game.id,
          user_id: this.user ?  this.user.id : this.data_invite.user_id,
          timestamp: this.invite.timestamp,
        };
        this.$http.post("ajax/games/cancel_game", formData)
        .then((response) => {
            return response.json();
        })
        .then((response)=> {
            if(this.$checkAuth(response)) {
                this.dialog = false;
            }
        })
        .catch((err) => {
            this.httpHandler(err);
        });

        // window.Echo.join('play.' + this.auth.id).whisper('play-cancel.'+idCancel, {idCancel} );

      }
      this.$store.dispatch("asyncModalInvite", null);
    },
  },
  beforeDestroy() {
      clearInterval(this.interval);
  },
  created() {
    let timestamp = this.invite.timestamp + this.$inviteTimer;

    this.interval = setInterval(() => {
      let momentUtc= moment.utc().format('X');
      if (timestamp >= momentUtc && !this.invite.cancel) {
        this.timer = this.primNum(timestamp - momentUtc, 0);
        this.lineTime= this.primNum(((this.$inviteTimer - this.timer)/(this.$inviteTimer/100)), 2);
      } else {
          if (!this.invite.cancel) {
              let response = {};
              response.game_id = this.data_invite.game_id;
              response.user_id = this.data_invite.user.id;

              this.$http.post("ajax/games/sleep", response)
                  .then((response) => {
                      return response.json();
                  })
                  .then((response)=> {
                      if(this.$checkAuth(response)) {
                          this.$checkAuth(response)
                      }
                  })
                  .catch((err) => {
                      this.httpHandler(err);
                  });
          }
        this.timer= 0;
        clearInterval(this.interval);
        // setTimeout(this.cancelGame(), 5000);
      }
    }, 1000);
  },
  mounted() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.head-invite_game {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 3px solid rgba(112, 124, 151, 0.1);
  border-radius: 4px 4px 0 0;
  padding: 12px 20px;
  position: relative;

    .block-game {
        display: flex;
        align-items: center;

        &__img {
            margin-right: 12px;
            img {
                filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.05));
                border-radius: 8px;
                width: 40px;
                height: 40px;
            }
        }

        .block-info {
            display: flex;
            flex-direction: column;
            &__title {
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #002B50;
                margin-bottom: 1px;
            }

            &__subtitle {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #607EA7;
                margin: 0;
            }
        }
    }
}

.body-invite_game {
  padding: 0 20px 20px 20px;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  margin-top: -3px;
  overflow: hidden;

    .wrap-line-time {
        overflow: hidden;
        margin: 0 -20px;
        position: relative;

        .line-time {
            height: 3px;
            background: #00AEFF;
            transition: 0.5s;
        }
    }

    &__head {
        padding-top: 16px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .block-person {
            display: flex;
            align-items: center;

            &__img {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 10px;

                a {
                    text-decoration: none;
                }

                .online {
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    right: 2px;
                    bottom: 1px;
                    border: 1px solid #FFFFFF;
                    box-sizing: border-box;
                    background-color: #00C569;
                }

                .wrap-img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .photo {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        color: #FFFFFF;
                        justify-content: center;
                        align-items: center;
                        text-decoration: none;
                        font-size: 20px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }

            .block-info {
                display: flex;
                flex-direction: column;

                &__title {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 19px;
                    color: #002B50;
                    margin-bottom: 2px;
                    max-width: 160px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &__subtitle {
                    display: flex;
                    align-items: center;

                    .block-rating {
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        img {
                            height: 12px;
                            margin-right: 4px;
                        }

                        span {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 0.1px;
                            color: #F6A401;
                        }
                    }
                }
            }
        }

        .block-bet {
            &__real {
                display: flex;
                align-items: center;
                background: rgba(253, 174, 2, 0.1);
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                padding: 10px 18px;
                border-radius: 5px;

                img {
                    height: 20px;
                    margin: 2px 4px 0 10px;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    line-height: 19px;
                    white-space: nowrap;
                }

                span {
                    font-size: 14px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                }
            }

            &__lite {
                display: flex;
                align-items: center;
                background: #F3F7FE;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                padding: 10px 18px;
                border-radius: 5px;

                img {
                    height: 20px;
                    margin: 2px 4px 0 10px;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    line-height: 19px;
                    white-space: nowrap;
                }

                span {
                    font-size: 14px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .block-params {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        cursor: default;
        margin-bottom: 25px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 9px;
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, rgba(0, 174, 255, 0) 0%, rgba(0, 174, 255, 0.6) 36.16%, rgba(0, 174, 255, 0.6) 67.61%, rgba(0, 174, 255, 0) 100.62%);
        }


        &__head {
            background: #fff;
            padding: 1px 6px 1px;
            border: 1px solid #00AEFF;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            color: #002B50;
            text-transform: uppercase;
            margin-bottom: 10px;
            position: relative;
            z-index: 10;
        }

        &__body {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .item-params {
                padding: 6px 12px;
                display: flex;
                align-items: center;
                background: rgba(0, 174, 255, 0.1);
                border-radius: 5px;
                margin: 4px;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                color: #002B50;

                span {
                    margin-right: 6px;
                    text-transform: capitalize;
                }

                p {
                    margin: 0;
                    color: #00AEFF;
                }
            }
        }
    }
}

.text-wait {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #002B50;
    text-align: center;
    margin-bottom: 10px;
}

.desc-timer {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #00AEFF;
    text-align: center;
    margin-bottom: 10px;

  span {
    display: inline-block;
    color: #46bcff;
    min-width: 40px;
    text-align: right;
  }
}

.v-btn.cancel {
  display: block;
  margin: 50px auto 0;
  background: #e5f0ff;
  border-radius: 6px;
  text-transform: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0;
  box-shadow: none;
  padding: 0;

  &.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 220px;
    padding: 0;
  }
}

.cancel {
  margin: 0 auto;
  outline: none;
  &:hover {
    background-color: #cddff7;
  }
}

.block-error {
  margin: 0 auto;
  text-align: center;

    &__txt {
        color: #F42626;
        margin-bottom: 12px;
    }

  img {
    margin-bottom: 25px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  height: 30px;
  align-items: center;
  // width:100%;

  .ellipse {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #96afd1; 
    margin:0 5px;
    animation: loadAnimation 1s infinite;
    animation-direction: alternate;

    &:nth-child(1) {
      animation-delay: 0s;
    }
    &:nth-child(2) {
      animation-delay: 0.25s;
    }
    &:nth-child(3) {
      animation-delay: 0.5s;
    }
    &:nth-child(4) {
      animation-delay: 0.75s;
    }
  }
}

@keyframes loadAnimation {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

@media screen and (max-width: 1024px) {
    .body-invite_game {
        &__head {
            .block-person {
                .block-info {
                    &__title {
                        max-width: 150px;
                    }
                }
            }
        }
    }
}

  @media screen and (max-width: 768px) {

    .head-invite_game {
        padding: 10px 15px;

        .block-game {
            .block-info {
                &__title {
                    max-width: 320px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    .body-invite_game {
      padding: 0 15px 20px;

        .wrap-line-time {
            margin: 0 -15px;
        }
    }
  }

    @media screen and (max-width: 500px) {
        .head-invite_game {
            .block-game {
                .block-info {
                    &__title {
                        max-width: 240px;
                    }
                }
            }
        }

        .body-invite_game {
            &__head {
                flex-direction: column;
                align-items: flex-start;

                .block-person {
                    margin-bottom: 14px;

                    .block-info {
                        &__title {
                            max-width: 200px;
                        }
                    }
                }

                .block-bet {
                    width: 100%;
                }

                .block-bet__lite,
                .block-bet__real {
                    justify-content: center;
                }
            }

            .block-params {
                margin: 14px 0;

                &__body {
                    .item-params {
                        padding: 4px 10px;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }

        .block-error {
            img {
                margin-bottom: 20px;
            }
        }

        .loader {
            margin-bottom: 20px;
        }
    }
</style>
