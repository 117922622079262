<template>
  <v-dialog
    v-model="modal_next"
    max-width="500"
    v-on:click:outside="$store.commit('updateModalNext', null)"
  >
    <div class="white pa-4 wrap-next-page">
      <p>{{__('Are you sure you want to leave this page?')}}</p>
      <div class="text-center">
        <v-btn text class="btn-true white--text"  v-on:click="modalNext(true)">{{__('Ok')}}</v-btn>
        <v-btn text class="btn-false darkGrey--text"  v-on:click="$store.commit('updateModalNext', null)">{{__('Back')}}</v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
    export default {
        name: 'ModalNextPage',
        data () {
            return {
                modal: false
            }
        },
        props: {

        },
        computed: {
            modal_next() {
                return this.$store.state.preference.modal_next;
            },
        },
        methods: {
            modalNext: function (type= false) {
                if(type) {
                    let next= this.modal_next;
                    this.$store.commit('updateModalNext', null);
                    this.$store.commit('updateNextPage', true);
                    window.onbeforeunload= false;

                    return next();
                }
            }
        },
        components: {
        }
    };
</script>

<style lang="scss" scoped>
  .model_wrapper.v-dialog {
    overflow: unset;
    position: relative;
  }
  p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .v-btn {
      outline: none;
      /*text-transform: inherit;*/
      cursor: pointer;
      border-radius: 8px;

    &.v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 100px;
      padding: 0 10px;
    }
  }

  .v-btn.btn-true {
    background-color: #F25958;
    margin-right: 20px;
    outline: none;

    &:hover {
      background-color: #E94F4E;
    }
  }

  .v-btn.btn-false {
    background-color: #E5F0FF;
    outline: none;

    &:hover {
      background-color: #E7EFFF;
    }
  }

</style>
