<template>
    <div class="page-cart">
        <LayoutNavAuth>
            <h1>{{__('Cart')}}</h1>
            <!--            <div class="wrap-tabs">-->
            <!--                <tabs-->
            <!--                        :tabs="side_tabs"-->
            <!--                        :currentTab="currentTab"-->
            <!--                        :wrapper-class="'default-tabs'"-->
            <!--                        :tab-class="'default-tabs__item'"-->
            <!--                        :tab-active-class="'default-tabs__item_active'"-->
            <!--                        :line-class="'default-tabs__active-line'"-->
            <!--                        @onClick="sideChange"-->
            <!--                />-->
            <!--            </div>-->
        </LayoutNavAuth>

        <div class="dashboard-content">
            <div class="page-cart__head">
                <v-btn
                        text
                        v-ripple="false"
                        class="btn-back"
                >
                    <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.90929 7.81789C4.03006 7.69737 4.03025 7.5018 3.90974 7.38101L0.707886 4.1725C0.587442 4.05204 0.587457 3.85608 0.708118 3.73541L3.90974 0.527127C4.03026 0.406358 4.03006 0.210786 3.90929 0.0902487C3.78852 -0.0302577 3.59294 -0.0300569 3.47243 0.0906966L0.271024 3.29876C0.0903256 3.47949 0 3.71681 0 3.95416C0 4.19137 0.0902946 4.42863 0.270807 4.60913L3.47243 7.81744C3.59294 7.93819 3.78852 7.9384 3.90929 7.81789Z" fill="currentColor"/>
                    </svg>
                </v-btn>
                <span>{{__('Back to Store')}}</span>
            </div>

            <div class="page-cart__body">
                <h1 class="cart-title">{{__("Shopping Cart")}}</h1>

                <div class="block-products">
                    <div class="block-products__head">
                        <span class="block-item">{{__('Item')}}</span>
                        <span class="block-price">{{__('Price')}}</span>
                        <span class="block-qty">{{__('Qty')}}</span>
                        <span class="block-subtotal">{{__('Subtotal')}}</span>
                        <span class="block-empty"></span>
                    </div>

                    <div class="list-products">
                        <div class="product-item">
                            <div class="block-product">
                                <div class="block-img">
                                    <img src="/dist/images/frontend/store/flesh.png" alt="">
                                </div>
                                <div class="block-info">
                                    <p>Флешка MINDPLAYS Kingston</p>
                                    <span>DataTraveler Exodia 32GB</span>
                                </div>
                            </div>

                            <div class="block-price">
                                120 K MIND
                            </div>

                            <div class="block-qty">
                                <div class="block-qty__input">
                                    <InputLabelTextDefault
                                            color="store"
                                            type="number"
                                            value="1"
                                    >

                                    </InputLabelTextDefault>
                                </div>
                            </div>

                            <div class="block-subtotal">
                                120 K MIND
                            </div>

                            <div class="block-btn">
                                <v-btn icon text class="btn-link">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.89321 6.00148L11.815 1.07967C12.0617 0.833009 12.0617 0.43312 11.815 0.186487C11.5684 -0.0601686 11.1685 -0.0601686 10.9218 0.186487L5.99999 5.10831L1.07817 0.186487C0.831514 -0.0601686 0.431624 -0.0601686 0.184992 0.186487C-0.0616405 0.433143 -0.061664 0.833033 0.184992 1.07967L5.10681 6.00148L0.184992 10.9233C-0.061664 11.17 -0.061664 11.5698 0.184992 11.8165C0.431648 12.0631 0.831538 12.0631 1.07817 11.8165L5.99999 6.89466L10.9218 11.8165C11.1684 12.0631 11.5684 12.0631 11.815 11.8165C12.0616 11.5698 12.0616 11.1699 11.815 10.9233L6.89321 6.00148Z" fill="#96AFD1"/>
                                    </svg>
                                </v-btn>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-summary">
                    <span class="block-summary__title">{{__('Summary')}}</span>

                    <div class="block-summary__body">
                        <div class="block-left">
                            <div class="block-left__item">
                                <p>{{__('12 товаров на сумму')}}</p>
                                <span>3.567.94 USD</span>
                            </div>
                            <div class="block-left__item">
                                <p>{{__('Стоимость доставки')}}</p>
                                <span>67.94 USD</span>
                            </div>
                        </div>

                        <div class="block-right">
                            <span class="block-right__txt">{{__('К оплате')}}</span>
                            <div class="block-right__price">
                                <p>
                                    1,233,333
                                    <span>MIND</span>
                                </p>
                                <span>12.20 USDT</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-btns">
                    <BaseButton
                        width="260"
                        class_btn="btn-guest"
                        class="mr-5"
                    >
                        <span>{{__('Proceed to MIND token')}}</span>
                    </BaseButton>

                    <BaseButton
                        width="260"
                        class_btn="white-btn-blue-guest"
                    >
                        <span>{{__('Check out with USDT')}}</span>
                    </BaseButton>
                </div>

                <div class="block-step">
                    <div class="block-step__item">
                        <span></span>
                        <p>{{__('Cart')}}</p>
                    </div>

                    <div class="block-step__item">
                        <span></span>
                        <p>{{__('Checkout')}}</p>
                    </div>

                    <div class="block-step__item">
                        <span></span>
                        <p>{{__('Delivery')}}</p>
                    </div>
                </div>

                <div class="block-form">
                    <p class="block-form__title">{{__('Информация о доставке')}}</p>
                    <div class="block-form__item">
                        <span>{{__("Получатель")}}</span>
                        <div class="wrapper-input">
                            <div class="block-name">
                                <InputLabelTextDefault
                                        :placeholder='__("First name")'
                                        color="store"
                                        v-model="form.name"
                                        v-bind:errors="formValidate.email"
                                        @change="updateValidate('name')"
                                >
                                </InputLabelTextDefault>
                            </div>
                            <div class="block-last_name">
                                <InputLabelTextDefault
                                        :placeholder='__("Last name")'
                                        color="store"
                                        v-model="form.last_name"
                                        v-bind:errors="formValidate.last_name"
                                        @change="updateValidate('last_name')"
                                >
                                </InputLabelTextDefault>
                            </div>
                        </div>
                    </div>

                    <div class="block-form__item">
                        <span>{{__("Phone")}}</span>
                        <div class="input-phone">
                            <VuePhoneNumberInput
                                    dark
                                    v-model="phone"
                                    v-bind:error="errorNumber"
                                    v-bind:default-country-code="form.phone_country"
                                    @update="updateNumber"
                            ></VuePhoneNumberInput>
                        </div>
                    </div>

                    <div class="block-form__item">
                        <span>{{__("Email")}}</span>
                        <div class="block-email">
                            <InputLabelTextDefault
                                :placeholder='__("Email")'
                                color="store"
                                v-model="form.email"
                                v-bind:errors="formValidate.email"
                                @change="updateValidate('email')"
                            >
                            </InputLabelTextDefault>
                        </div>
                    </div>

                    <div class="block-form__item">
                        <span>{{__("Country, City")}}</span>
                        <div class="wrapper-input">
                            <div class="location-country">
                                <DropdownCountry
                                        :placeholder='__("Select country")'
                                        v-bind:value.sync="form.country"
                                        @change="updateValidate('country')"
                                        v-bind:errors="formValidate.country"
                                        @updateCountry="updateCountry"
                                ></DropdownCountry>
                            </div>
                            <div class="location-city">
                                <DropdownCities
                                        :placeholder='__("Select city")'
                                        v-bind:value.sync="form.city"
                                        v-bind:country="form.country"
                                        @change="updateValidate('city')"
                                        v-bind:errors="formValidate.city"
                                ></DropdownCities>
                            </div>
                        </div>
                    </div>

                    <div class="block-form__item">
                        <span>{{__("Адрес получателя")}}</span>
                        <div class="block-address">
                            <InputLabelTextDefault
                                    color="store-textarea"
                                    :placeholder='__("Enter your address")'
                                    type="textarea"
                                    v-model="form.email"
                                    v-bind:errors="formValidate.email"
                                    @change="updateValidate('email')"
                            >
                            </InputLabelTextDefault>
                        </div>
                    </div>

                    <div class="block-btn">
                        <div class="block-btn__info">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.105 11.8907C13.7149 11.8907 12.584 13.0216 12.584 14.4118C12.584 15.8019 13.7149 16.9328 15.105 16.9328C16.4954 16.9328 17.626 15.8019 17.626 14.4118C17.626 13.0216 16.4951 11.8907 15.105 11.8907ZM15.105 15.6723C14.4098 15.6723 13.8445 15.1069 13.8445 14.4118C13.8445 13.7166 14.4098 13.1513 15.105 13.1513C15.8002 13.1513 16.3655 13.7166 16.3655 14.4118C16.3655 15.107 15.8002 15.6723 15.105 15.6723Z" fill="#01DF72"/>
                                <path d="M6.49172 11.8907C5.1016 11.8907 3.9707 13.0216 3.9707 14.4118C3.9707 15.8019 5.1016 16.9328 6.49172 16.9328C7.88184 16.9328 9.01273 15.8019 9.01273 14.4118C9.01273 13.0216 7.88184 11.8907 6.49172 11.8907ZM6.49172 15.6723C5.79656 15.6723 5.23121 15.1069 5.23121 14.4118C5.23121 13.7166 5.79656 13.1513 6.49172 13.1513C7.18668 13.1513 7.75223 13.7166 7.75223 14.4118C7.75223 15.107 7.18687 15.6723 6.49172 15.6723Z" fill="#01DF72"/>
                                <path d="M16.8025 4.67477C16.6954 4.46196 16.4775 4.3277 16.2393 4.3277H12.9199V5.58821H15.8506L17.5668 9.00164L18.6932 8.43524L16.8025 4.67477Z" fill="#01DF72"/>
                                <path d="M13.2142 13.8025H8.44531V15.063H13.2142V13.8025Z" fill="#01DF72"/>
                                <path d="M4.60073 13.8025H2.41588C2.06775 13.8025 1.78564 14.0846 1.78564 14.4327C1.78564 14.7809 2.06779 15.063 2.41588 15.063H4.60076C4.94889 15.063 5.231 14.7808 5.231 14.4327C5.231 14.0846 4.94885 13.8025 4.60073 13.8025Z" fill="#01DF72"/>
                                <path d="M19.8676 9.94955L18.6279 8.35291C18.5088 8.19912 18.3249 8.10919 18.1302 8.10919H13.5503V3.69743C13.5503 3.34931 13.2682 3.0672 12.9201 3.0672H2.41588C2.06775 3.0672 1.78564 3.34935 1.78564 3.69743C1.78564 4.04552 2.06779 4.32767 2.41588 4.32767H12.2898V8.73943C12.2898 9.08755 12.572 9.36966 12.9201 9.36966H17.8215L18.7394 10.552V13.8024H16.9957C16.6476 13.8024 16.3655 14.0846 16.3655 14.4327C16.3655 14.7808 16.6476 15.0629 16.9957 15.0629H19.3696C19.7178 15.0629 19.9999 14.7808 19.9999 14.4327V10.3361C19.9999 10.1962 19.9533 10.0601 19.8676 9.94955Z" fill="#01DF72"/>
                                <path d="M4.55871 10.6093H1.65953C1.31141 10.6093 1.0293 10.8914 1.0293 11.2395C1.0293 11.5876 1.31145 11.8697 1.65953 11.8697H4.55868C4.9068 11.8697 5.18891 11.5876 5.18891 11.2395C5.18895 10.8914 4.9068 10.6093 4.55871 10.6093Z" fill="#01DF72"/>
                                <path d="M6.0084 8.13019H0.630234C0.282148 8.13019 0 8.41233 0 8.76045C0 9.10857 0.282148 9.39068 0.630234 9.39068H6.0084C6.35652 9.39068 6.63863 9.10854 6.63863 8.76045C6.63863 8.41237 6.35652 8.13019 6.0084 8.13019Z" fill="#01DF72"/>
                                <path d="M7.0377 5.65125H1.65953C1.31141 5.65125 1.0293 5.93339 1.0293 6.28148C1.0293 6.6296 1.31145 6.91171 1.65953 6.91171H7.0377C7.38582 6.91171 7.66793 6.62957 7.66793 6.28148C7.66797 5.93339 7.38582 5.65125 7.0377 5.65125Z" fill="#01DF72"/>
                            </svg>

                            <p>{{__("Примерный срок доставки: от 3 до 30 дней")}}</p>
                        </div>

                        <BaseButton
                            class_btn="btn-guest"
                        >
                            <span>{{__('Send it, now!')}}</span>
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LayoutNavAuth from "../../modules/LayoutNavAuth";
    import BaseButton from "../../modules/BaseButton";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import DropdownCountry from "../../modules/DropdownCountry";
    import DropdownCities from "../../modules/DropdownCities";
    import InputLabelTextDefault from "../../modules/InputLabelTextDefault";
    import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
    export default {
        data() {
            return {
                form: {
                    name: null,
                    last_name: null,
                    email: null,
                    country: this.$countries[0].value,
                    city: null,
                    phone_number: null,
                    phone_country: null,
                    phone_code: null,
                },
                phone: null,
                phone_number: null,
                errorNumber: null,
                formValidate: {
                    name: null,
                    last_name: null,
                    email: null,
                    country: null,
                    city: null,
                },
            }
        },
        validations: {
            form: {
                name: {
                    required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                last_name: {
                    // required,
                    maxLength: maxLength(50),
                    minLength: minLength(2),
                },
                email: {
                  email,
                  required
                },
                country: {
                    required,
                },
                city: {},
            }
        },
        methods: {
            updateCountry(val) {
                this.form.country= val;
                this.form.city= null;
            },
            updateValidate (type) {
                this.formValidate = this.$changeValidate(this.formValidate, this.$v.form[type], type);
            },
            updateNumber: function (event) {
                this.errorNumber= false;
                this.phone_number= event;
            },
        },
        components: {
            LayoutNavAuth,
            BaseButton,
            VuePhoneNumberInput,
            DropdownCountry,
            DropdownCities,
            InputLabelTextDefault
        }
    }
</script>

<style lang="scss" scoped>
    .page-cart {
        color: #fff;

        &__head {
            margin: 20px 0;
            display: flex;
            align-items: center;

            .btn-back {
                color: #fff;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #171827;
                border: 1px solid #252525;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-right: 10px;

                &:hover {
                    color: #26A9F4;
                    background-color: #262626;
                }

            }

            .v-btn:before {
                background-color: #262626;
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 24px;
                min-width: 24px;
                padding: 0;
            }

            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 16px;
                color: #FFFFFF;
                cursor: default;
                margin: 0;
                display: block;
            }
        }

        &__body {
            background: #171827;
            box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            padding: 33px 40px 40px;
            margin-bottom: 20px;

            .cart-title {
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: #FFFFFF;
                margin-bottom: 30px;
            }

            .block-products {
                margin-bottom: 40px;
                &__head {
                    padding-bottom: 16px;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    display: flex;

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #FFFFFF;
                    }

                    .block-item {
                        width: 54%;
                    }

                    .block-price,
                    .block-qty,
                    .block-subtotal {
                        width: 12%;
                    }

                    .block-empty {
                        width: 10%;
                        padding: 0;
                        margin: 0;
                    }
                }

                .list-products {
                    padding-top: 8px;
                    .product-item {
                        display: flex;
                        align-items: center;
                        padding: 8px 0;
                        .block-product {
                            width: 54%;
                            display: flex;
                            align-items: center;

                            .block-img {
                                background: rgba(255, 255, 255, 0.05);
                                border: 1px solid rgba(255, 255, 255, 0.05);
                                border-radius: 8px;
                                width: 60px;
                                height: 60px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 12px;
                            }

                            .block-info {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 19px;
                                color: #FFFFFF;
                                p {
                                    margin: 0;
                                }
                            }
                        }

                        .block-price,
                        .block-qty,
                        .block-subtotal {
                            width: 12%;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19px;
                            color: #FFFFFF;
                        }

                        .block-qty {
                            &__input {
                                max-width: 70px;
                            }
                        }

                        .block-btn {
                            width: 10%;
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
            }

            .block-summary {
                padding: 25px 30px;
                border: 1px solid rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                margin-bottom: 40px;

                &__title {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 32px;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                    display: block;
                }

                &__body {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .block-left {
                        width: 28%;

                        &__item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 10px;

                            p {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #96AFD1;
                                margin: 0;
                            }

                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                text-align: right;
                                color: #FFFFFF;
                            }
                        }
                    }

                    .block-right {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 25%;

                        &__txt {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            color: #96AFD1;
                            margin-bottom: 10px;
                        }

                        &__price {
                            p {
                                font-weight: 400;
                                font-size: 24px;
                                line-height: 32px;
                                text-align: right;
                                color: #00AEFF;
                                margin-bottom: 0;
                                display: flex;
                                align-items: flex-end;

                                span {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 26px;
                                    color: #00AEFF;
                                    margin-left: 3px;
                                }
                            }
                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 19px;
                                text-align: right;
                                color: #FFFFFF;
                                display: block;
                            }
                        }
                    }
                }
            }

            .block-btns {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 56px;
            }

            .block-step {
                display: flex;
                justify-content: space-between;
                max-width: 530px;
                width: 100%;
                margin: 0 auto;
                position: relative;

                /*&:after {*/
                /*    content: '';*/
                /*    display: block;*/
                /*    position: absolute;*/
                /*    right: calc(50% + 20px);*/
                /*    top: 9px;*/
                /*    border-radius: 2px;*/
                /*    width: calc(50% - 40px);*/
                /*    height: 2px;*/
                /*    background: red;*/
                /*}*/

                /*&:before {*/
                /*    content: '';*/
                /*    display: block;*/
                /*    position: absolute;*/
                /*    left: 50%;*/
                /*    top: 9px;*/
                /*    border-radius: 2px;*/
                /*    width: calc(50% - 40px);*/
                /*    height: 2px;*/
                /*    background: #00AEFF;*/
                /*}*/

                &__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    z-index: +1;

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            right: 50%;
                            top: 9px;
                            border-radius: 2px;
                            width: 140px;
                            height: 2px;
                            background: #00AEFF;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 9px;
                        border-radius: 2px;
                        width: 140px;
                        height: 2px;
                        background: #00AEFF;
                    }

                    span {
                        display: block;
                        width: 20px;
                        height: 20px;
                        background: #00AEFF;
                        border-radius: 50%;
                        margin-bottom: 6px;
                        border: 4px solid #202020;
                        position: relative;
                        z-index: +1;
                    }

                    p {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        color: #F5F5F5;
                        margin: 0;
                    }
                }
            }

            .block-form {
                max-width: 730px;
                width: 100%;
                margin: 40px auto 0;

                &__title {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 210%;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #00AEFF;
                        display: block;
                        width: 40%;
                    }

                    .wrapper-input {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 60%;

                        .block-name {
                            width: 50%;
                            margin-right: 20px;
                        }
                        .block-last_name {
                            width: 50%;
                        }

                        .location-country {
                            width: 50%;
                            margin-right: 20px;
                        }

                        .location-city {
                            width: 50%;
                        }
                    }

                    .input-phone {
                        width: 60%;
                    }

                    .block-email {
                        width: 60%;
                    }

                    .block-address {
                        width: 60%;
                    }
                }
            }

            .block-btn {
                margin-top: 40px;
                &__info {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    svg {
                        margin-right: 10px;
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                        color: #01DF72;
                        margin: 0;
                    }
                }
            }
        }
    }
</style>